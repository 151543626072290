import * as React from 'react'
import PropTypes from 'prop-types'

const Context = React.createContext(undefined)

export function MessagesContextProvider({
  baseUrl,
  archive,
  twilioClient,
  children,
}) {
  return (
    <Context.Provider value={{ baseUrl, archive, twilioClient }}>
      {children}
    </Context.Provider>
  )
}

MessagesContextProvider.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  archive: PropTypes.bool,
  twilioClient: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export function useMessagesContext() {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error(
      'useMessagesContext must be used within a MessagesContextProvider'
    )
  }
  return context
}
