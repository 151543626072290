import { Component } from 'react'
import PropTypes from 'prop-types'
import { faGlobe } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import moment from 'moment'
import { connect } from 'react-redux'
import { fetchUpcomingOnlineNotification } from '~/actions/messages'
import { getUserId } from '~/selectors/auth'
import './OnlineMeetingNotification.less'

class OnlineMeetingNotification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nextOnlineMeeting: null,
    }
  }

  render() {
    //const nextOnlineMeeting = props.nextOnlineMeeting;
    const { nextOnlineMeeting } = this.props
    if (nextOnlineMeeting == null) return null

    const isUpcoming =
      nextOnlineMeeting.meetingDateTime.valueOf() > moment().valueOf()
    const classname = `cc-meeting-status-notification-${
      isUpcoming ? 'upcoming' : 'ongoing'
    }`
    const timeRemaining =
      nextOnlineMeeting.meetingDateTime.valueOf() - moment().valueOf()

    const upcomingText = (
      <span>
        Online Meeting with{' '}
        <span className="cc-meeting-status-contact">
          {nextOnlineMeeting.company}
        </span>{' '}
        will start in {moment.utc(timeRemaining).local().format('m')} minutes
      </span>
    )

    const ongoingText = (
      <span>
        Online Meeting with{' '}
        <span className="cc-meeting-status-contact">
          {nextOnlineMeeting.company}
        </span>{' '}
        has started
      </span>
    )

    return (
      <div
        className={`cc-heading6 cc-meeting-status-notification ${classname}`}
      >
        <FontAwesomeIcon icon={faGlobe} />
        {isUpcoming ? upcomingText : ongoingText}
        <Button className="cc-meeting-status-button" type="primary">
          Go to meeting
        </Button>
      </div>
    )
  }
}

OnlineMeetingNotification.propTypes = {
  nextOnlineMeeting: PropTypes.shape({
    company: PropTypes.object,
    meetingDateTime: PropTypes.object,
  }),
}

const mapStateToProps = (state) => ({
  nextOnlineMeeting: state.messaging.upcomingOnline,
  userId: getUserId(state),
})

const mapDispatchToProps = {
  fetchUpcomingOnlineNotification,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineMeetingNotification)
