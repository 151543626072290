import { api } from './services'

export const getClassActionCases = (page, pageSize, orderBy) =>
  api
    .get('/classactions', {
      params: { page, pageSize, orderBy },
    })
    .then((res) => res.data.result)

export const sendRequestEmail = (classActionId) =>
  api.post(`classactions/${classActionId}/contact`)
