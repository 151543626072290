import * as React from 'react'
import { extent } from 'd3-array'
import { Delaunay } from 'd3-delaunay'
import * as d3ease from 'd3-ease'
import { scaleLinear, scaleTime } from 'd3-scale'
import { select } from 'd3-selection'
import { area, line } from 'd3-shape'
import { timeFormat } from 'd3-time-format'
import { transition } from 'd3-transition'
import flatMap from 'lodash/flatMap'
import uniqueId from 'lodash/uniqueId'
import numeral from 'numeral'

const useConstant = (getValue) => React.useState(getValue)[0]

function getSVGEventCoordinates(event, svg) {
  const matrix = getTransformationMatrix(svg)
  return {
    x: transformTarget(event.clientX, matrix, 'x'),
    y: transformTarget(event.clientY, matrix, 'y'),
  }

  function getTransformationMatrix(svg) {
    return svg.getScreenCTM().inverse()
  }

  function transformTarget(target, matrix, dimension) {
    const { a, d, e, f } = matrix
    return dimension === 'y' ? d * target + f : a * target + e
  }
}

export function useChart(data, { width, height, padding }) {
  const revealClipPathRef = React.useRef()
  const chartId = useConstant(() => uniqueId('discover-fund-chart-'))
  const revealClipPathId = `url(#${chartId})`

  const scale = React.useMemo(() => {
    if (!data) return {}

    const [vami] = data
    const allDataPoints = flatMap(data, 'data')

    return {
      x: scaleTime()
        .domain(extent(vami.data, (d) => d.x))
        .range([padding, width - padding]),
      y: scaleLinear()
        .domain(extent(allDataPoints, (d) => d.y))
        .range([height - padding, padding]),
    }
  }, [data, width, height, padding])

  const shape = React.useMemo(() => {
    if (!scale.x || !scale.y) return {}

    return {
      line: line()
        .x((d) => scale.x(d.x))
        .y((d) => scale.y(d.y)),
      area: area()
        .x((d) => scale.x(d.x))
        .y1((d) => scale.y(d.y))
        .y0(scale.y(scale.y.domain()[0])),
    }
  }, [scale])

  const reveal = React.useCallback(() => {
    if (!revealClipPathRef.current) {
      return
    }

    const t = transition().duration(300).ease(d3ease.easeQuadInOut)
    select(revealClipPathRef.current)
      .attr('width', 0)
      .transition(t)
      .attr('width', width)
  }, [width])

  const revealClipPath = (
    <clipPath id={chartId}>
      <rect
        ref={revealClipPathRef}
        vectorEffect="non-scaling-stroke"
        x="0"
        y="0"
        width="0"
        height={height}
      />
    </clipPath>
  )

  return {
    scale,
    line: shape.line,
    area: shape.area,
    revealClipPath,
    revealClipPathId,
    reveal,
  }
}

const formatDate = timeFormat('%m/%Y')
const formatValue = (val) => numeral(val).format('0,0[.]00')

export function useChartTooltip(data, scale) {
  const svgRoot = React.useRef()
  const [tooltip, setTooltip] = React.useState({
    visible: false,
    x: 0,
    y: 0,
    activePoints: [],
  })

  const delaunay = React.useMemo(() => {
    if (!data) {
      return {
        find: () => -1,
      }
    }

    const [vami] = data
    return Delaunay.from(
      vami.data,
      (d) => scale.x(d.x),
      () => 0
    )
  }, [data, scale])

  function onMouseMove(event) {
    if (!data) {
      return
    }

    const mousePosition = getSVGEventCoordinates(event, svgRoot.current)
    const index = delaunay.find(mousePosition.x, mousePosition.y)
    const activePoints = data.map((d) => d.data[index])
    const title = formatDate(activePoints[0].x)
    const dataPoints = activePoints.map(({ fill, label, y }) => ({
      fill,
      label,
      value: formatValue(y),
    }))

    setTooltip({
      ...mousePosition,
      visible: true,
      title,
      activePoints: dataPoints,
    })
  }

  function onMouseLeave() {
    setTooltip((t) => ({ ...t, visible: false }))
  }

  return {
    tooltip,
    svgRootRef: svgRoot,
    eventListeners: {
      onMouseMove,
      onMouseLeave,
    },
  }
}
