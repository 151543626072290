import * as React from 'react'
import { Button } from '@context365/button'
import { FormLabel, Search } from '@context365/forms'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import uniqWith from 'lodash/uniqWith'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import * as api from '~/api'
import { InputField, SelectField } from '~/components/Form'

export function AddressForm({ className, title }) {
  const { field: postalCodeField } = useController({ name: 'postalCode' })

  const { setValue } = useFormContext()
  const selectedContinent = useWatch({ name: 'continent' })
  const selectedCountry = useWatch({ name: 'country' })

  const { data: continents = [] } = useQuery(
    ['onboarding-continents'],
    () => api.geography.getContinents(),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      select: (continents) =>
        continents.map((name) => ({ label: name, value: name })),
    }
  )
  const { data: countries = [] } = useQuery(
    ['onboarding-countries', selectedContinent],
    () => api.geography.getCountries(selectedContinent),
    {
      enabled: !!selectedContinent,
      cacheTime: Infinity,
      staleTime: Infinity,
      select: (countries) =>
        countries.map(({ value }) => ({ value, label: value })),
    }
  )

  const {
    data: results,
    mutate: search,
    isLoading: loadingSearchResults,
    reset: resetSearchResults,
  } = useMutation(searchByPostalCode)

  function selectSearchResult(result) {
    setValue('city', result.city, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    })
    setValue('stateProvince', result.state, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    })
    resetSearchResults()
  }

  React.useEffect(() => {
    if (!selectedCountry || isEmpty(countries)) {
      return
    }

    const isValidSelection = countries.some(
      ({ value }) => value === selectedCountry
    )
    if (!isValidSelection) {
      setValue('country', '', { shouldDirty: true })
    }
  }, [selectedCountry, countries, setValue])

  return (
    <fieldset className={cx(className, 'flex flex-col gap-1')}>
      {title && (
        <legend className="type-header-xs text-center mb-6">{title}</legend>
      )}
      <SelectField name="continent" label="Continent" options={continents} />
      <SelectField
        name="country"
        label="Country"
        options={countries}
        disabled={isEmpty(countries)}
        placeholder={
          isEmpty(countries) ? 'Select a continent' : 'Select a country'
        }
      />
      <div>
        <FormLabel controlId="postalCode">Postal Code</FormLabel>
        <Search
          id="postalCode"
          disabled={!selectedCountry}
          placeholder={
            selectedCountry
              ? 'Search for City and State/Province'
              : 'Select a country to search for City and State/Province'
          }
          onSearch={(text, e) => {
            e.preventDefault()
            search({ postalCode: text, country: selectedCountry })
          }}
          {...postalCodeField}
        />
      </div>
      <div className={results?.length === 0 ? 'text-red-100' : undefined}>
        {loadingSearchResults && 'Loading search results...'}
        {results?.length === 0 && 'No results found'}
      </div>
      {!isEmpty(results) && (
        <ul className="list-none p-0">
          {results.map((result) => (
            <li key={[result.city, result.state].join('-')}>
              <Button variant="link" onClick={() => selectSearchResult(result)}>
                {[result.city, result.state].filter(Boolean).join(', ')}
              </Button>
            </li>
          ))}
        </ul>
      )}
      <InputField name="city" label="City" />
      <InputField name="stateProvince" label="State/Province" />
      <InputField name="address1" label="Address Line 1" />
      <InputField name="address2" label="Address Line 2" />
      <InputField name="address3" label="Address Line 3" />
    </fieldset>
  )
}

async function searchByPostalCode({ postalCode, country }) {
  const { results = [] } = await api.geography.searchByPostalCode({
    postalCode,
    country,
  })

  const parsedLocalities = results.flatMap((result) => {
    const { address_components, postcode_localities } = result

    const state = findAddressComponent(address_components, [
      { type: 'administrative_area_level_1', property: 'short_name' },
      { type: 'sublocality_level_1', property: 'short_name' },
    ])
    const city = findAddressComponent(address_components, [
      { type: 'postal_town', property: 'long_name' },
      { type: 'locality', property: 'long_name' },
      { type: 'administrative_area_level_3', property: 'long_name' },
    ])

    return isEmpty(postcode_localities)
      ? [{ state, city }]
      : postcode_localities
          .map((locality) => ({ state, city: locality }))
          .concat([{ state, city }])
  })

  const uniqueResults = uniqWith(parsedLocalities, isEqual)
  return sortBy(uniqueResults, ['state', 'city']).filter(
    (result) => result.city || result.state
  )
}

function findAddressComponent(components, criteria) {
  for (const { type, property } of criteria) {
    const component = components.find((c) => c.types.includes(type))
    if (component) {
      return component[property]
    }
  }
  return ''
}
