import PropTypes from 'prop-types'

const Clickable = ({ style, ...props }) => {
  function handleKeyDown(e) {
    if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
      props.onClick(e)
    }
  }

  return (
    <div
      role="button"
      tabIndex="0"
      onKeyDown={handleKeyDown}
      style={{ cursor: 'pointer', ...style }}
      {...props}
    />
  )
}

Clickable.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
}

export default Clickable
