import {
  Alert,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Select,
} from 'antd'
import { ErrorMessage } from 'formik'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import fromPairs from 'lodash/fromPairs'
import get from 'lodash/get'
import has from 'lodash/has'
import includes from 'lodash/includes'
import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import map from 'lodash/map'
import replace from 'lodash/replace'
import values from 'lodash/values'
import moment from 'moment'
import { BASE_URL } from '~/config'
import UploadFileField from './UploadFileField'

const { TextArea } = Input

const FORMATTERS = {
  percentage: (value) => (isNil(value) || isEmpty(value) ? '' : `${value}%`),
}

const PARSERS = {
  percentage: (value) => replace(value, '%', ''),
}

export const renderInput = (
  values,
  errors,
  touched,
  question,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
  fieldDependencies
) => {
  const uploadsUrl = `${BASE_URL}/uploads`

  const classNames = ['FundWizard-field']
  if (question.isRequired) {
    classNames.push('FundWizard-field-required')
  }
  if (question.questionType === 'Boolean') {
    classNames.push('FundWizard-field-boolean')
  }
  const className = join(classNames, ' ')

  const extraProps = {}
  if (has(question, 'attributes.formatter')) {
    extraProps.formatter = get(
      FORMATTERS,
      question.attributes.formatter,
      (value) => value
    )
  }

  if (has(question, 'attributes.parser')) {
    extraProps.parser = get(
      PARSERS,
      question.attributes.parser,
      (value) => value
    )
  }

  switch (question.questionType) {
    case 'Text - Single':
      return showField(question.shortName, fieldDependencies, values) ? (
        <div className={className}>
          {has(question, 'attributes.label') && (
            <label
              htmlFor={question.shortName}
              className="FundWizard-field-label"
            >
              {question.attributes.label}
            </label>
          )}
          <Input
            id={question.shortName}
            name={question.shortName}
            placeholder={question.text}
            value={values[question.shortName]}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage name={question.shortName}>
            {(msg) => <Alert message={msg} type="error" showIcon />}
          </ErrorMessage>
        </div>
      ) : null
    case 'Text - Multiple':
      return showField(question.shortName, fieldDependencies, values) ? (
        <div className={className}>
          <TextArea
            id={question.shortName}
            name={question.shortName}
            placeholder={question.text}
            autoSize={{ minRows: 4, maxRows: 4 }}
            value={values[question.shortName]}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage name={question.shortName}>
            {(msg) => <Alert message={msg} type="error" showIcon />}
          </ErrorMessage>
        </div>
      ) : null
    case 'Checkbox':
      return showField(question.shortName, fieldDependencies, values) ? (
        <div className={className}>
          <label className="FundWizard-field-label">{question.text}</label>
          <Checkbox.Group
            name={question.shortName}
            options={getOptions('checkbox', question.fundQuestionAnswers)}
            className="FundWizard-field-checkbox"
            value={values[question.shortName]}
            onBlur={() => {
              setFieldTouched(question.shortName)
            }}
            onChange={(val) => {
              setFieldValue(question.shortName, val)
            }}
          />
          <ErrorMessage name={question.shortName}>
            {(msg) => <Alert message={msg} type="error" showIcon />}
          </ErrorMessage>
        </div>
      ) : null
    case 'Dropdown':
      return showField(question.shortName, fieldDependencies, values) ? (
        <div className={className}>
          <Select
            id={question.shortName}
            name={question.shortName}
            placeholder={question.text}
            value={get(values, `${question.shortName}.0`)}
            onChange={(val) => {
              setFieldValue(question.shortName, [parseInt(val, 10)])
            }}
          >
            {map(question.fundQuestionAnswers, (qa) => (
              <Select.Option
                key={qa.fundQuestionAnswerId}
                value={qa.fundQuestionAnswerId}
              >
                {qa.answer}
              </Select.Option>
            ))}
          </Select>
          <ErrorMessage name={question.shortName}>
            {(msg) => <Alert message={msg} type="error" showIcon />}
          </ErrorMessage>
        </div>
      ) : null
    case 'Numeric - INT':
      return showField(question.shortName, fieldDependencies, values) ? (
        <div className={className}>
          <InputNumber
            id={question.shortName}
            name={question.shortName}
            placeholder={question.text}
            value={values[question.shortName]}
            onBlur={handleBlur}
            onChange={(val) => {
              setFieldValue(question.shortName, val)
            }}
            {...extraProps}
          />
          <ErrorMessage name={question.shortName}>
            {(msg) => <Alert message={msg} type="error" showIcon />}
          </ErrorMessage>
        </div>
      ) : null
    case 'Numeric - FLOAT':
      return showField(question.shortName, fieldDependencies, values) ? (
        <div className={className}>
          <InputNumber
            id={question.shortName}
            name={question.shortName}
            placeholder={question.text}
            value={values[question.shortName]}
            onBlur={handleBlur}
            onChange={(val) => {
              setFieldValue(question.shortName, val)
            }}
            {...extraProps}
          />
          <ErrorMessage name={question.shortName}>
            {(msg) => <Alert message={msg} type="error" showIcon />}
          </ErrorMessage>
        </div>
      ) : null
    case 'Boolean':
      return showField(question.shortName, fieldDependencies, values) ? (
        <div className={className}>
          <label className="FundWizard-field-label">{question.text}</label>
          <Radio.Group
            className="FundWizard-field-boolean-options"
            name={question.shortName}
            value={
              isBoolean(get(values, question.shortName))
                ? get(values, question.shortName)
                : isNil(get(values, question.shortName))
                ? null
                : get(values, question.shortName) === 'true'
            }
            onChange={(e) => {
              if (hasDependents(question.shortName, fieldDependencies)) {
                forEach(
                  getDependents(question.shortName, fieldDependencies),
                  (name) => {
                    setFieldValue(name, null)
                  }
                )
              }
              handleChange(e)
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
          <ErrorMessage name={question.shortName}>
            {(msg) => <Alert message={msg} type="error" showIcon />}
          </ErrorMessage>
        </div>
      ) : null
    case 'Radio Button':
      return showField(question.shortName, fieldDependencies, values) ? (
        <div className={className}>
          <label className="FundWizard-field-label">{question.text}</label>
          <Radio.Group
            className="FundWizard-field-boolean-options"
            name={question.shortName}
            value={get(values, `${question.shortName}.0`)}
            onBlur={handleBlur}
            onChange={(e) => {
              setFieldValue(question.shortName, [e.target.value])
            }}
          >
            {map(question.fundQuestionAnswers, (qa) => (
              <Radio
                key={qa.fundQuestionAnswerId}
                value={qa.fundQuestionAnswerId}
              >
                {qa.answer}
              </Radio>
            ))}
          </Radio.Group>
          <ErrorMessage name={question.shortName}>
            {(msg) => <Alert message={msg} type="error" showIcon />}
          </ErrorMessage>
        </div>
      ) : null
    case 'Upload - Excel':
      return showField(question.shortName, fieldDependencies, values) ? (
        <div className={className}>
          <label className="FundWizard-field-label">{question.text}</label>
          <UploadFileField
            action={uploadsUrl}
            name={question.shortName}
            value={values[question.shortName]}
            text="Please upload your NET ALL FEES returns using the provided ApexInvest returns excel document below"
            hint="File Types: .xlsx - Max File Size: 4MB"
            onChange={(value) => setFieldValue(question.shortName, value)}
          />
          <ul className="FundWizard-field-footer">
            <li>Backtest or model returns are not accepted as returns</li>
            <li>
              Click{' '}
              <a
                href="https://stapex365backendprod.blob.core.windows.net/public-context/ContextSummitsReturnExample.xlsx"
                rel="noopener noreferrer"
                target="_blank"
              >
                HERE
              </a>{' '}
              to download sample excel
            </li>
            <li>Monthly returns and AUM are required</li>
          </ul>
          <ErrorMessage name={question.shortName}>
            {(msg) => <Alert message={msg} type="error" showIcon />}
          </ErrorMessage>
        </div>
      ) : null
    case 'Upload - File':
      return showField(question.shortName, fieldDependencies, values) ? (
        <div className={className}>
          <label className="FundWizard-field-label">{question.text}</label>
          <UploadFileField
            action={uploadsUrl}
            name={question.shortName}
            value={values[question.shortName]}
            text="Please upload the Marketing Material"
            hint="File Types: .doc,.docx,.pdf - Max File Size: 5.859375MB"
            onChange={(value) => setFieldValue(question.shortName, value)}
          />
          <ul className="FundWizard-field-footer">
            <li>
              You may attach one additional marketing material for this Fund.
            </li>
            <li>
              Please note, attachments will only be displayed to Allocators
              after you approve your tear sheet.
            </li>
          </ul>
          <ErrorMessage name={question.shortName}>
            {(msg) => <Alert message={msg} type="error" showIcon />}
          </ErrorMessage>
        </div>
      ) : null
    case 'Date':
      return showField(question.shortName, fieldDependencies, values) ? (
        <div className="FundWizard-field">
          <DatePicker
            id={question.shortName}
            name={question.shortName}
            placeholder={question.text}
            value={
              isNil(get(values, question.shortName))
                ? null
                : moment
                    .utc(values[question.shortName], moment.HTML5_FMT.DATE)
                    .local()
            }
            onBlur={handleBlur}
            onChange={(date) =>
              setFieldValue(
                question.shortName,
                date.format(moment.HTML5_FMT.DATE)
              )
            }
          />
          <ErrorMessage name={question.shortName}>
            {(msg) => <Alert message={msg} type="error" showIcon />}
          </ErrorMessage>
        </div>
      ) : null
    case 'Static Text':
      return showField(question.shortName, fieldDependencies, values) ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="cc-heading5 cc-lightblack-text">
            {question.name}
          </span>
          <span
            style={{ padding: '16px 0px' }}
            className="cc-body-text cc-text-color-summit"
          >
            {question.text}
          </span>
        </div>
      ) : null
    default:
      break
  }
}

export const getInitialValues = (
  questions,
  answers = [],
  questionsWithOptions
) => {
  const answersLookup = fromPairs(
    map(answers, (a) => [
      a.shortName,
      {
        responseText: a.responseText,
        fundQuestionAnswerIds: a.fundQuestionAnswerIds,
      },
    ])
  )

  const initialState = questions.map((q) => {
    if (questionsWithOptions.has(q.questionTypeId)) {
      return [
        q.shortName,
        get(answersLookup, `${q.shortName}.fundQuestionAnswerIds`, []),
      ]
    }

    return [q.shortName, get(answersLookup, `${q.shortName}.responseText`)]
  })

  return fromPairs(initialState)
}

export const getInitialDealsValues = (
  questions,
  answers = [],
  questionsWithOptions
) => {
  const answersLookup = fromPairs(
    map(answers, (a) => [
      a.shortName,
      {
        responseText: a.responseText,
        questionAnswerIds: a.questionAnswerIds,
      },
    ])
  )

  const initialState = questions.map((q) => {
    if (questionsWithOptions.has(q.questionTypeId)) {
      return [
        q.shortName,
        get(answersLookup, `${q.shortName}.questionAnswerIds`, []),
      ]
    }

    return [q.shortName, get(answersLookup, `${q.shortName}.responseText`)]
  })

  return fromPairs(initialState)
}

export const getOptions = (dropdownOrCheckbox, fundQuestionAnswers) => {
  if (dropdownOrCheckbox === 'checkbox')
    return fundQuestionAnswers.map((fundQuestionAnswer) => ({
      label: fundQuestionAnswer.answer,
      value: fundQuestionAnswer.fundQuestionAnswerId,
    }))
  else
    return fundQuestionAnswers.map((fundQuestionAnswer) => ({
      value: fundQuestionAnswer.fundQuestionAnswerId,
      text: fundQuestionAnswer.answer,
    }))
}

const showField = (fieldName, fieldDependencies, values) => {
  const fieldDependency = get(fieldDependencies, fieldName)
  if (isNil(fieldDependency)) {
    return true
  }

  const depValue = get(values, fieldDependency.field)
  return isEqual(depValue, fieldDependency.showOn)
}

const hasDependents = (fieldName, fieldDependencies) =>
  includes(
    map(values(fieldDependencies), (d) => d.field),
    fieldName
  )

const getDependents = (fieldName, fieldDependencies) =>
  map(
    filter(
      map(fieldDependencies, (v, k) => [v.field, k]),
      (fields) => fields[0] === fieldName
    ),
    (names) => names[1]
  )
