import * as React from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import { DeleteOutline } from '@context365/icons'
import { useDiscoverLists } from '~/hooks/discoverLists'

export default function DeleteListForm({ onDelete, onDismiss }) {
  const { discoverLists } = useDiscoverLists()
  const [listToDelete, setListToDelete] = React.useState(null)

  function beginDelete(listId) {
    setListToDelete(
      discoverLists.find((list) => list.discoverListId === listId)
    )
  }

  function cancelDelete() {
    setListToDelete(null)
  }

  function deleteList() {
    if (!listToDelete) {
      return
    }

    onDelete(listToDelete.discoverListId).then(() => {
      setListToDelete(null)
    })
  }

  if (!discoverLists) {
    return null
  }

  return (
    <>
      <div className="type-body-semibold-md py-5 px-6 border-b">
        Delete List
      </div>
      {listToDelete == null ? (
        <Lists
          lists={discoverLists}
          onDelete={beginDelete}
          onDismiss={onDismiss}
        />
      ) : (
        <Confirm
          list={listToDelete}
          onConfirm={deleteList}
          onCancel={cancelDelete}
        />
      )}
    </>
  )
}

DeleteListForm.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
}

function Lists({ lists, onDelete, onDismiss }) {
  return (
    <>
      <ul className="p-6 m-0 list-none type-body-regular-md">
        {lists.map((list) => (
          <li
            key={list.discoverListId}
            className="p-2 flex items-center justify-between hover:bg-grey-200"
          >
            <span>{list.name}</span>
            <IconButton
              label={`Delete ${list.name} list`}
              icon={<DeleteOutline />}
              variant="link"
              status="error"
              onClick={() => onDelete(list.discoverListId)}
            />
          </li>
        ))}
      </ul>
      <div className="flex justify-end p-4 shadow-0">
        <Button variant="filled" onClick={onDismiss}>
          Done
        </Button>
      </div>
    </>
  )
}

function Confirm({ list, onCancel, onConfirm }) {
  return (
    <div className="p-3">
      <div className="type-body-regular-md text-center">
        Are you sure you want to delete the list named &ldquo;{list.name}
        &rdquo;?
      </div>
      <div className="p-5 flex justify-evenly">
        <Button status="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button status="error" onClick={onConfirm}>
          Delete
        </Button>
      </div>
    </div>
  )
}
