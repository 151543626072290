import { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Popover } from 'antd'
import isNil from 'lodash/isNil'
import './FloatingInput.less'

const { Search } = Input

class FloatingInput extends Component {
  state = {
    focused: false,
  }

  focused = () => {
    this.setState({
      focused: true,
    })
  }

  render() {
    // eslint-disable-next-line prefer-const
    let { value, isValid } = this.props

    value = isNil(value) ? '' : value

    const anyText = !isNil(value) && value !== ''
    let classes = !anyText
      ? 'cc-floating-input-notext'
      : 'cc-floating-input-text'
    classes += ' cc-floating-input'

    let placeholderClasses = !anyText
      ? 'placeholder-movement-notext'
      : 'placeholder-movement-text'
    placeholderClasses += ' cc-floating-placeholder'

    let validationMessage = null
    if (isValid === false && this.state.focused) {
      classes += ' cc-floating-input-invalid'
      validationMessage = this.props.validationMessage
    }

    return (
      <div className="floating-input-row">
        <Popover
          content={this.props.tooltip}
          visible={!isNil(this.props.tooltip)}
        >
          {this.props.isSearch === true ? (
            <Search
              size="large"
              value={value}
              onKeyPress={this.props.onKeyPress}
              placeholder={this.props.name}
              onSearch={this.props.onSearch}
              onChange={
                this.props.handleChange === true
                  ? this.props.onChange.bind(null, this.props.id)
                  : this.props.onChange
              }
              id={this.props.id}
              onBlur={this.props.onBlur}
              onFocus={this.focused}
              enterButton
              loading={this.props.searchLoading}
              className="cc-floating-search"
            />
          ) : (
            <Input
              size="large"
              value={value}
              onKeyPress={this.props.onKeyPress}
              autoComplete={this.props.autoComplete}
              placeholder={
                this.props.placeholder
                  ? this.props.placeholder
                  : this.props.name
              }
              name={this.props.id ? this.props.id : this.props.nameField}
              onChange={
                this.props.handleChange === true
                  ? this.props.onChange.bind(null, this.props.id)
                  : this.props.onChange
              }
              className={classes}
              id={this.props.id}
              onBlur={this.props.onBlur}
              onFocus={this.focused}
              disabled={!isNil(this.props.disabled)}
            />
          )}
        </Popover>
        {/* </Tooltip> */}
        {this.props.isSearch === true ? null : (
          <span className={placeholderClasses}>{this.props.name}</span>
        )}
        {isNil(validationMessage) ? null : (
          <div className="cc-small-font cc-validation-message">
            {validationMessage}
          </div>
        )}
      </div>
    )
  }
}

FloatingInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  nameField: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  isValid: PropTypes.bool,
  validationMessage: PropTypes.string,
  isSearch: PropTypes.bool,
  handleChange: PropTypes.bool,
  disabled: PropTypes.bool,
  searchLoading: PropTypes.bool,
  autoComplete: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onSearch: PropTypes.func,
}

export default FloatingInput
