import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useSelector } from 'react-redux'
import { api as http } from '~/api/services'
import { getCompanyId } from '~/selectors/auth'
import CompanySelectContainer from '../CompanySelectContainer'
import ParticipantsCheckbox from '../ParticipantsCheckbox'
import './InviteCompany.less'

const InviteCompany = ({
  companies,
  meetingId,
  canInviteFunds,
  onlyCFN,
  onChange,
}) => {
  const userCompanyId = useSelector(getCompanyId)
  const [visible, setVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [invitedCompany, setInvitedCompany] = useState(null)
  const [invitedParticipants, setInvitedParticipants] = useState([])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    setInvitedCompany(null)
    setInvitedParticipants([])
  }

  const handleOk = () => {
    setConfirmLoading(true)
    setVisible(true)
    invitedCompany &&
      http
        .post(`/meetings/${meetingId}/invite`, {
          InvitedCompanyId: invitedCompany.companyId,
          InvitedContactIds: invitedParticipants,
          FundId: invitedCompany.fundId,
        })
        .then(() => {
          onChange()
          message.success('Successfully invited company.')
          setVisible(false)
          setConfirmLoading(false)
        })
  }

  useEffect(() => {
    setInvitedParticipants([])
  }, [invitedCompany])

  const handleInvitedParticipants = useCallback(
    (e) => {
      let tmpInvitedParticipants
      if (e.target.checked) {
        tmpInvitedParticipants = [...invitedParticipants, e.target.value]
        setInvitedParticipants(tmpInvitedParticipants)
      } else {
        tmpInvitedParticipants = invitedParticipants.filter(
          (p) => p !== e.target.value
        )
        setInvitedParticipants(tmpInvitedParticipants)
      }
    },
    [invitedParticipants]
  )
  return (
    <>
      <div style={{ marginTop: '10px' }}>
        <div style={{ marginBottom: '10px' }}>
          <span className="type-subtitle-sm text-header">
            INVITE ANOTHER COMPANY
          </span>
        </div>
        <Button type="primary" onClick={showModal}>
          <FontAwesomeIcon
            style={{
              marginRight: '8px',
            }}
            icon={faPlus}
          />
          Add Company
        </Button>
      </div>
      {visible && (
        <Modal
          centered
          destroyOnClose
          title="Add Company"
          visible={visible}
          onOk={handleOk}
          width={600}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          okButtonProps={{
            disabled: isNil(invitedCompany) || isEmpty(invitedParticipants),
          }}
        >
          <CompanySelectContainer
            excludedCompanies={[companies[0].companyId, userCompanyId]}
            setSelectedCompany={setInvitedCompany}
            canInviteFunds={canInviteFunds}
            onlyCFN={onlyCFN}
          />
          <div className="cc-check-participants-wrapper">
            {invitedCompany &&
              Array.isArray(invitedCompany.contacts) &&
              invitedCompany.contacts.length > 0 &&
              map(invitedCompany.contacts, (contact) => (
                <ParticipantsCheckbox
                  key={contact.contactId}
                  participants={invitedParticipants}
                  checked={handleInvitedParticipants}
                  contactId={contact.contactId}
                  companyContactId={contact.companyContactId}
                  contactName={contact.contactName}
                  imageUrl={contact.imageUrl}
                  position={contact.jobTitle}
                />
              ))}
          </div>
        </Modal>
      )}
    </>
  )
}

InviteCompany.propTypes = {
  companies: PropTypes.array,
  meetingId: PropTypes.number,
  onChange: PropTypes.func,
  canInviteFunds: PropTypes.bool.isRequired,
  onlyCFN: PropTypes.bool.isRequired,
}
export default InviteCompany
