import * as React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@context365/button'
import { ChevronLeft, ChevronRight } from '@context365/icons'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import * as api from '~/api'
import { ReactComponent as Loading } from '~/assets/loading-chart.svg'
import IlliquidBarChart from '../IlliquidCharts/IlliquidBarChart'
import './DiscoverIlliquidCharts.less'

const transformChartData = (data, type) => {
  if (isEmpty(data.result.returns)) {
    return null
  }

  const transformed = map(data.result.returns, (ret) => ({
    interval: isNil(ret.quarter)
      ? ` ${ret.year} `
      : `Q${ret.quarter} ${ret.year}`,
    return: ret.metric,
  }))

  return {
    cadence: data.result.returnCadence,
    data: transformed,
    type,
  }
}

const fetchDataArray = [
  {
    request: (fundId) => api.fundCharts.getIlliquidReturns(fundId, 'irr', 4),
    onSuccess: (res) => transformChartData(res.data, 'irr'),
  },
  {
    request: (fundId) => api.fundCharts.getIlliquidReturns(fundId, 'tvpi', 4),
    onSuccess: (res) => transformChartData(res.data, 'tvpi'),
  },
]

const DiscoverIlliquidCharts = ({ fundId }) => {
  const [loading, setLoading] = React.useState(true)
  const [charts, setCharts] = React.useState([])
  const [chartIndex, setChartIndex] = React.useState(0)

  React.useEffect(() => {
    const chartData = []

    if (!isNil(fundId)) {
      Promise.all(map(fetchDataArray, (x) => x.request(fundId)))
        .then((responses) =>
          forEach(responses, (response, i) => {
            const data = fetchDataArray[i].onSuccess(response)
            if (!isNil(data)) {
              chartData.push(data)
            }
          })
        )
        .finally(() => {
          setCharts(chartData)
          setLoading(false)
        })
    }
  }, [fundId])

  return (
    <div className="DiscoverIlliquidCharts">
      {loading ? (
        <Loading aria-label="loading chart data" height={120} width={400} />
      ) : (
        <>
          {charts.length > 0 && (
            <IconButton
              onClick={() => setChartIndex(chartIndex - 1)}
              disabled={chartIndex === 0}
              icon={<ChevronLeft />}
              label="previous chart"
            />
          )}
          <IlliquidBarChart {...charts[chartIndex]} height={120} />
          {charts.length > 0 && (
            <IconButton
              onClick={() => setChartIndex(chartIndex + 1)}
              disabled={chartIndex === charts.length - 1}
              icon={<ChevronRight />}
              label="next chart"
            />
          )}
        </>
      )}
    </div>
  )
}

DiscoverIlliquidCharts.propTypes = {
  fundId: PropTypes.number.isRequired,
}

export default DiscoverIlliquidCharts
