import PropTypes from 'prop-types'
import { Placement, Popover, Tooltip } from '@context365/popovers'
import { Checkbox } from 'antd'
import '../DiscoverFilters.less'

const CheckboxFilter = ({
  id,
  label,
  group,
  value,
  icon,
  onChange,
  tooltip = null,
  popover = null,
}) => {
  const handleChange = (e) => {
    onChange({
      value: e.target.checked,
      id,
      group,
      label,
      type: 'Check',
    })
  }

  const checked = value[0] && (value[0] === true || value[0] === 'true')

  const labelElement = <span style={{ fontSize: '12px' }}>{label}</span>

  const contents = popover ? (
    <Popover interactive content={popover} placement={Placement.RightStart}>
      {labelElement}
    </Popover>
  ) : tooltip ? (
    <Tooltip text={tooltip} placement="right">
      {labelElement}
    </Tooltip>
  ) : (
    labelElement
  )

  return (
    <div style={{ display: 'flex' }}>
      <Checkbox name={label} onChange={handleChange} checked={checked}>
        {icon}
        {contents}
      </Checkbox>
    </div>
  )
}
CheckboxFilter.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  icon: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  popover: PropTypes.object,
}

export default CheckboxFilter
