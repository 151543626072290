import PropTypes from 'prop-types'
import { Checkbox } from '@context365/forms/Checkbox'
import cx from 'classnames'

const RequirableField = ({
  children,
  containerClassName,
  checkboxClassName,
  allowRequirement,
  checked,
  onChange,
}) => (
  <div className={cx('flex gap-4', containerClassName)}>
    {children}
    {allowRequirement && (
      <Checkbox
        className={checkboxClassName}
        label="Required"
        onChange={(e) => onChange(e.target.checked)}
        checked={checked}
      />
    )}
  </div>
)

RequirableField.propTypes = {
  children: PropTypes.node,
  containerClassName: PropTypes.string,
  checkboxClassName: PropTypes.string,
  allowRequirement: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

export default RequirableField
