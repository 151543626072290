import { api } from './services'

export const logProfileView = (profile) =>
  // This is just fire-and-forget, so don't display an error to the user
  api.post('/mandate/viewprofile', profile).catch(() => {})

export const applyToMandate = (
  campaignId,
  fromCampaignId,
  message,
  responses
) =>
  api.post(`campaigns/${campaignId}/apply`, {
    fromCampaignId,
    message,
    responses,
  })

export const getMandateApplicationQuestions = (campaignId) =>
  api
    .get(`mandates/${campaignId}/applicationquestions`)
    .then((res) => res.data.result)

export const getMandateWizard = (mandateId) =>
  api
    .get(`/mandates/wizard${mandateId ? `/${mandateId}` : ''}`)
    .then((res) => res.data.result)

export const saveMandateWizard = (
  campaignId,
  { selectedCommonQuestionIds, selectedCustomQuestions }
) =>
  api.patch(`/mandates/wizard/${campaignId}`, {
    selectedCommonQuestionIds,
    selectedCustomQuestions,
  })
