import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { List, message } from 'antd'
import isNil from 'lodash/isNil'
import { getByZip, getContinents, getCountries } from '~/actions/geography'
import FloatingInput from '../FloatingInput'
import FloatingSelect from '../FloatingSelect'

const OnboardingAddressSection = ({
  value = {},
  enableNext = () => {},
  onChange,
  validationMessage,
}) => {
  const [continents, setContinents] = useState([])
  const [selectedContinent, setSelectedContinent] = useState(null)
  const [continentsLoading, setContinentsLoading] = useState(false)
  const [countriesLoading, setCountriesLoading] = useState(false)
  const [countries, setCountries] = useState(
    isNil(value.country) ? [] : [value.country]
  )
  const [selectedCountry, setSelectedCountry] = useState(value.country)
  const [zipCodeLoading, setZipCodeLoading] = useState(false)
  const [zipCodeStatus, setZipCodeStatus] = useState(null)
  const [zipCodeSuggestions, setZipCodeSuggestions] = useState(null)
  const [questions, setQuestions] = useState(value)

  const loadCountries = useCallback(() => {
    if (selectedContinent) {
      setCountriesLoading(true)
      getCountries(selectedContinent)
        .then((response) => {
          setCountries(response.data.result)
          if (
            !isNil(questions.country) &&
            questions.country !== -1 &&
            !isNil(
              response.data.result.find((x) => x.value === questions.country)
            )
          ) {
            setSelectedCountry(questions.country)
          } else {
            setSelectedCountry(-1)
          }
        })
        .catch(() =>
          message.error('There was an error while attempting to get countries')
        )
        .finally(() => setCountriesLoading(false))
    }
  }, [questions.country, selectedContinent])

  useEffect(() => {
    setContinentsLoading(true)
    getContinents()
      .then((response) => {
        setContinents(response.data.result)
        if (
          !isNil(questions.continent) &&
          questions.continent !== -1 &&
          !isNil(response.data.result.find((x) => x === questions.continent))
        ) {
          setSelectedContinent(questions.continent)
        } else {
          setSelectedContinent(-1)
        }
      })
      .catch(() =>
        message.error('There was an error while attempting to get countries')
      )
      .finally(() => setContinentsLoading(false))
  }, [questions.continent])

  useEffect(() => {
    loadCountries()
  }, [loadCountries])

  const onValueChanged = (newValue, id) => {
    let result = questions
    switch (id) {
      case 'continent':
        setSelectedContinent(newValue)
        setSelectedCountry('')
        result = {
          ...questions,
          continent: newValue,
          country: '',
        }
        setQuestions(result)
        break
      case 'country':
        setSelectedCountry(newValue)
        result = {
          ...questions,
          country: newValue,
        }
        setQuestions(result)
        break
      case 'autocomplete_city_state':
        result = {
          ...questions,
          city: newValue.city,
          stateProvince: newValue.state,
        }
        setQuestions(result)
        break
    }
    onChange({
      ...result,
      isValid: checkAddressValidity(result),
    })
  }

  const checkAddressValidity = (result) =>
    !isNil(result.continent) &&
    result.continent !== '' &&
    result.continent !== -1 &&
    !isNil(result.country) &&
    result.country !== '' &&
    result.country !== -1 &&
    !isNil(result.city) &&
    result.city !== '' &&
    result.city !== -1

  const onTextChanged = (id, e) => {
    const result = {
      ...questions,
      [id]: e.target.value,
    }
    setQuestions(result)
    onChange({
      ...result,
      isValid: checkAddressValidity(result),
    })
  }

  useEffect(() => {
    enableNext(
      isValid(questions.continent) &&
        isValid(questions.country) &&
        isValid(questions.city)
    )
  }, [enableNext, questions])

  const onZipCodeSearch = (paramZipCode = null, paramCountry = null) => {
    if (isNil(paramCountry) && selectedCountry == -1) {
      onValueChanged({ city: '', state: '' }, 'autocomplete_city_state')
      setZipCodeStatus('No results found')
      return
    }
    setZipCodeLoading(true)
    getByZip(
      isNil(paramZipCode) ? questions.zipCode : paramZipCode,
      isNil(paramCountry) ? selectedCountry : paramCountry
    )
      .then(async (response) => {
        const googleResult = response.data.result
        if (googleResult.status === 'ZERO_RESULTS') {
          onValueChanged({ city: '', state: '' }, 'autocomplete_city_state')
          setZipCodeStatus('No results found')
        } else {
          setZipCodeStatus(null)
          if (googleResult.results.length > 1) {
            setZipCodeSuggestions(googleResult.results)
          } else {
            const { address_components } = googleResult.results[0]
            let city
            let state
            let postalTown
            let administrative_3
            let sublocality
            await address_components.forEach((element) => {
              const { types } = element
              types.forEach((type) => {
                if (type === 'locality') {
                  city = element.long_name
                }
                if (type === 'postal_town') {
                  postalTown = element.long_name
                }
                if (type === 'administrative_area_level_1') {
                  state = element.short_name
                }
                if (type === 'administrative_area_level_3') {
                  administrative_3 = element.short_name
                }
                if (type === 'sublocality_level_1') {
                  sublocality = element.short_name
                }
              })
            })

            if (isNil(state)) state = sublocality
            if (isNil(state)) state = city

            if (!isNil(postalTown)) city = postalTown
            if (isNil(city)) city = administrative_3
            if (isNil(city)) city = state

            onValueChanged({ city, state }, 'autocomplete_city_state')
          }
        }
      })
      .catch((err) => {
        console.log(err.response)
      })
      .finally(() => {
        setZipCodeLoading(false)
      })
  }

  const renderSuggestion = (item) => {
    const { address_components } = item
    let city
    let state
    address_components.forEach((element) => {
      const { types } = element
      types.forEach((type) => {
        if (type === 'locality') {
          city = element.long_name
        }
        if (type === 'administrative_area_level_1') {
          state = element.short_name
        }
      })
    })

    return <span>{`${city}, ${state}`}</span>
  }

  const onSuggestion = (val) => {
    const { address_components } = val
    let city
    let state
    address_components.forEach((element) => {
      const { types } = element
      types.forEach((type) => {
        if (type === 'locality') {
          city = element.long_name
        }
        if (type === 'administrative_area_level_1') {
          state = element.short_name
        }
      })
    })

    onValueChanged({ city, state }, 'autocomplete_city_state')
    setZipCodeSuggestions(null)
  }
  const isValid = (e) => !(e === -1 || e === '' || isNil(e))

  return (
    <>
      <FloatingSelect
        name="Continent *"
        handleChange={false}
        value={selectedContinent}
        onChange={onValueChanged}
        id="continent"
        options={continents.map((x) => ({ value: x, text: x }))}
        isValid={isValid(selectedContinent)}
        validationMessage={validationMessage}
        loading={continentsLoading}
      />
      <FloatingSelect
        name="Country *"
        handleChange={false}
        value={selectedCountry}
        onChange={onValueChanged}
        id="country"
        options={countries.map((x) => ({ value: x.value, text: x.value }))}
        isValid={isValid(selectedCountry)}
        //onBlur={onBlur.bind(null, 'country')}
        validationMessage={validationMessage}
        loading={countriesLoading}
      />
      <FloatingInput
        onChange={onTextChanged}
        value={questions.zipCode}
        name="Zip Code"
        id="zipCode"
        handleChange={true}
        //isValid={isValid(questions.zipCode)}
        //onBlur={onBlur.bind(null, 'zipCode')}
        validationMessage={validationMessage}
        isSearch={true}
        onSearch={() => {
          onZipCodeSearch()
        }}
        searchLoading={zipCodeLoading}
      />
      {isNil(zipCodeSuggestions) ? null : (
        <div className="cc-address-zip-code-suggestions">
          <List
            dataSource={zipCodeSuggestions}
            renderItem={(item, index) => (
              <List.Item
                className="cc-address-zip-code-suggestion-item"
                onClick={onSuggestion.bind(null, item, index)}
              >
                {renderSuggestion(item)}
              </List.Item>
            )}
            bordered
          />
        </div>
      )}
      {isNil(zipCodeStatus) ? null : (
        <div className="cc-address-zip-code-message">
          <span>{zipCodeStatus}</span>
        </div>
      )}
      <FloatingInput
        value={questions.stateProvince}
        onChange={onTextChanged}
        id="stateProvince"
        //isValid={stateValid}
        handleChange={true}
        //onBlur={onBlur.bind(null, 'state')}
        validationMessage={validationMessage}
        name="State/Province"
      />
      <FloatingInput
        value={questions.city}
        onChange={onTextChanged}
        name="City *"
        id="city"
        handleChange={true}
        isValid={isValid(questions.city)}
        //onBlur={onBlur.bind(null, 'city')}
        validationMessage={validationMessage}
      />
      <FloatingInput
        onChange={onTextChanged}
        value={questions.address1}
        name="Address 1"
        id="address1"
        handleChange={true}
        //isValid={addressValid}
        //onBlur={onBlur.bind(null, 'address1')}
        validationMessage={validationMessage}
      />
      <FloatingInput
        onChange={onTextChanged}
        value={questions.address2}
        name="Address 2"
        id="address2"
        handleChange={true}
        //isValid={address2Valid}
        // onBlur={onBlur.bind(null, 'address2')}
        validationMessage={validationMessage}
      />
      <FloatingInput
        onChange={onTextChanged}
        value={questions.address3}
        name="Address 3"
        id="address3"
        handleChange={true}
        //isValid={address3Valid}
        // onBlur={onBlur.bind(null, 'address3')}
        validationMessage={validationMessage}
      />
    </>
  )
}

OnboardingAddressSection.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  validationMessage: PropTypes.string.isRequired,
  enableNext: PropTypes.func,
}

export default OnboardingAddressSection
