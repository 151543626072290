import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import qs from 'qs'
import { api } from './services'

export default async function discovery(
  url,
  { page, pageSize, showMatched, filter, orderBy, searchTerm, meetingStatus }
) {
  const params = {
    page,
    pageSize,
    showMatched,
  }

  filter = compact(filter)
  if (!isEmpty(filter)) {
    params.filter = filter
  }
  if (!isEmpty(orderBy)) {
    params.orderby = orderBy
  }
  if (!isEmpty(searchTerm)) {
    params.q = searchTerm.trim()
  }
  if (!isEmpty(meetingStatus)) {
    params.meetingStatus = meetingStatus
  }

  const response = await api.get(url, {
    params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  })
  return response.data
}

discovery.funds = (params) => discovery('/discovery/funds', params)
discovery.similarFunds = (similarFundId) =>
  discovery(`/discovery/funds/${similarFundId}/similar`, {})
discovery.serviceProviders = (params) =>
  discovery('/discovery/serviceProviders', params)
discovery.allocators = (params) => discovery('/discovery/allocators', params)
discovery.mandates = (params) => discovery('/mandates', params)
discovery.mandateFunds = (mandateId, params) =>
  discovery(`/mandates/${mandateId}/funds`, params)
discovery.mandateServiceProviders = (mandateId, params) =>
  discovery(`/mandates/${mandateId}/service-providers`, params)
discovery.mandateAllocators = (mandateId, params) =>
  discovery(`/mandates/${mandateId}/allocators`, params)
discovery.mandateCFNs = (mandateId, params) =>
  discovery(`/mandates/${mandateId}/cfn-members`, params)
discovery.companies = (params) => discovery('/companies/search', params)
discovery.directory = (params) => discovery('/directory/search', params)
discovery.researchCampaigns = (params) =>
  discovery('/researchCampaigns/search', params)
