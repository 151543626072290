import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    // Note: For now, we're assuming that only one modal will be open at a time.

    showContactProfileModal: (state, action) => {
      state.contactProfileModal = {
        visible: true,
        ...action.payload,
      }
      delete state.companyProfileModal
      delete state.indicateInterestModal
      delete state.researchCampaignProfileModal
    },
    showCompanyProfileModal: (state, action) => {
      state.companyProfileModal = {
        visible: true,
        ...action.payload,
      }
      delete state.contactProfileModal
      delete state.indicateInterestModal
      delete state.researchCampaignProfileModal
    },
    showApplyToCampaignModal: (state, action) => {
      state.indicateInterestModal = {
        visible: true,
        ...action.payload,
      }
      delete state.companyProfileModal
      delete state.contactProfileModal
      delete state.researchCampaignProfileModal
    },
    showResearchCampaignProfileModal: (state, action) => {
      state.researchCampaignProfileModal = {
        visible: true,
        ...action.payload,
      }
      delete state.companyProfileModal
      delete state.contactProfileModal
      delete state.indicateInterestModal
    },
    hideModal: () => {
      return initialState
    },
  },
})

export const {
  showContactProfileModal,
  showCompanyProfileModal,
  showApplyToCampaignModal,
  showResearchCampaignProfileModal,
  hideModal,
} = modalsSlice.actions
export default modalsSlice.reducer
