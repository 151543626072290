import qs from 'qs'
import { api } from './services'

export const getEvents = (apiVersion = 1) => api.get('/events', { apiVersion })

export const addFundsToEvent = (eventId, funds, apiVersion = 1) =>
  api.put(`events/${eventId}/addfunds`, { fundIds: funds }, { apiVersion })

export const getSummitDates = (eventId) =>
  api.get(`/events/${eventId}/timeframe`)

export const getEventSchedule = (eventId, timezone) => {
  const params = { timezone }
  return api.get(`events/${eventId}/schedule`, {
    params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  })
}

export const setAvailability = (
  eventId,
  meetingTimeId,
  availability,
  eventCompanyBoothId,
  apiVersion = 1
) => {
  if (availability === true)
    return api.put(
      `events/${eventId}/schedule/meeting-times/${meetingTimeId}/available`,
      {
        eventCompanyBoothId,
      },
      { apiVersion }
    )
  else
    return api.put(
      `events/${eventId}/schedule/meeting-times/${meetingTimeId}/unavailable`,
      {
        eventCompanyBoothId,
      },
      { apiVersion }
    )
}
