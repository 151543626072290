import PropTypes from 'prop-types'
import { Avatar, Row } from 'antd'
import { nameToColor } from '~/utils'
import './AllocatorCard.less'

const AllocatorCard = ({ person, onClick }) => {
  let indexCtr = 0

  const firstLastName = `${person.firstName} ${person.lastName}`

  return (
    person && (
      <div
        onClick={() => onClick(person)}
        onKeyDown={() => onClick(person)}
        className="AllocatorCard AllocatorCard-click"
        id="fixed"
      >
        <div className="AllocatorCard-row">
          <div className="AllocatorCard-avatar">
            <Avatar
              src={person.imageUrl}
              style={{
                marginLeft: '0',
                position: 'relative',
                zIndex: 150 + indexCtr++,
                display: 'inline-block',
                backgroundColor: person.fullName
                  ? nameToColor(person.fullName, person.fullName)
                  : nameToColor(person.firstName, person.lastName),
                fontSize: '13px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
              shape="circle"
              size={72}
            >
              {person.fullName || person.contactName || firstLastName}
            </Avatar>
            <div style={{ marginLeft: '16px' }}>
              <Row className="AllocatorCard-title">
                {person.fullName || person.contactName || firstLastName}
              </Row>
              <Row className="AllocatorCard-subtitle">
                {person.jobPosition || person.jobTitle}
              </Row>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

AllocatorCard.propTypes = {
  person: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

export default AllocatorCard
