import PropTypes from 'prop-types'
import { Col, Divider, Image, Row } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { addLineBreaks, getClickableLink } from '~/utils/helpers'
import './DiscoverServiceProviderProfile.less'

const ServiceProviderProfile = ({ company }) => (
  <div>
    {!isEmpty(company) && (
      <div>
        <Row style={{ marginTop: '20px' }}>
          <Col span={15}>
            <div>
              <span className="type-subtitle-sm text-header">
                Company Description
              </span>
              <div className="cc-profile-info">
                {addLineBreaks(company.description)}
              </div>
            </div>
            {((!isNil(company.videoUrl) && !isEmpty(company.videoUrl)) ||
              (!isEmpty(company.images) && company.images)) && (
              <span
                className="type-subtitle-sm text-header"
                style={{ paddingTop: '15px', display: 'block' }}
              >
                Media
              </span>
            )}
            <Row className="media-row">
              {!isNil(company.videoUrl) && !isEmpty(company.videoUrl) && (
                <Col
                  span={!isEmpty(company.images) && company.images ? 12 : 24}
                >
                  {' '}
                  <div className="video-column">
                    <video
                      autoPlay={false}
                      width="100%"
                      muted={false}
                      style={{
                        borderRadius: '8px',
                        boxShadow: '3px 3px 10px rgba(186, 186, 201, 0.15)',
                      }}
                      controls
                    >
                      <source src={company.videoUrl} />
                    </video>
                  </div>
                </Col>
              )}
              {!isEmpty(company.images) && company.images && (
                <Col
                  span={
                    !isNil(company.videoUrl) && !isEmpty(company.videoUrl)
                      ? 12
                      : 24
                  }
                >
                  <Row
                    style={{
                      height: '100%',
                    }}
                  >
                    <Image.PreviewGroup>
                      {company.images.map((image, id) => (
                        <Col
                          key={id}
                          className="service-image-col"
                          span={
                            company.images.length === 1 ||
                            (id === 2 && company.images.length === 3)
                              ? 24
                              : 12
                          }
                        >
                          <Image src={image} className="service-image" />
                        </Col>
                      ))}
                    </Image.PreviewGroup>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={2}>
            <Divider type="vertical" style={{ height: '100%' }} />
          </Col>
          <Col span={7}>
            {company.serviceProviderCategory && (
              <div>
                <span className="type-subtitle-sm text-header">Category</span>
                <div className="ServiceProviderContent-link">
                  {' '}
                  {company.serviceProviderCategory}
                </div>
              </div>
            )}
            {company.website && (
              <div>
                <span className="type-subtitle-sm text-header">Website</span>
                <div className="ServiceProviderContent-link">
                  {' '}
                  <a
                    href={getClickableLink(company.website)}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {company.website}
                  </a>
                </div>
              </div>
            )}

            {company.imageUrl && (
              <div>
                <span className="type-subtitle-sm text-header">Logo</span>
                <div className="cc-profile-info">
                  <Image width={200} src={company.imageUrl} />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    )}
  </div>
)
ServiceProviderProfile.propTypes = {
  company: PropTypes.shape({
    description: PropTypes.string,
    videoUrl: PropTypes.string,
    images: PropTypes.array,
    serviceProviderCategory: PropTypes.string,
    website: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
}
export default ServiceProviderProfile
