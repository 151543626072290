import { Component } from 'react'
import { faCheckCircle, faFlag } from '@fortawesome/pro-light-svg-icons'
import isNil from 'lodash/isNil'
import moment from 'moment'
import numeral from 'numeral'
import { fetchAllocations } from '~/actions/portfolio'
import { CCTableActionCell } from '../CCTable'
import ContextTable from '../ContextTable'

class PortfolioAllocationsTable extends Component {
  renderInitial = (record) => (
    <span>
      {numeral(record.initialInvestment).format('$ 0,0')}{' '}
      <span className="cc-grey-text">
        {moment.utc(record.startDate).local().format('(MM/YYYY)')}
      </span>
    </span>
  )
  renderCurrency = (record) => {
    if (!isNil(record) && record > 0) return numeral(record).format('$ 0,0.00')
  }
  getPortfolioAllocationsColumns = () => {
    const twoMonthsAgo = new Date().getMonth() - 1
    const oneMonthAgo = new Date().getMonth()
    const year = new Date().getFullYear()
    const menus = [
      {
        icon: faCheckCircle,
        text: 'Make new Investment',
        iconColor: '#2FC5D2',
      },
      {
        icon: faFlag,
        text: 'Sell',
        iconColor: '#F19F00',
      },
    ]
    return [
      {
        key: '1',
        title: 'FUND NAME',
        dataIndex: 'fundName',
        width: '15%',
      },
      {
        key: '2',
        title: 'PORTFOLIO',
        dataIndex: 'portfolioName',
        width: '15%',
      },
      {
        key: '3',
        title: 'SECTOR',
        dataIndex: 'fundSector',
        width: '20%',
      },
      {
        key: '4',
        title: 'INITIAL INVESTMENT',
        render: this.renderInitial,
      },
      {
        key: '5',
        title: 'CURRENT VALUE',
        dataIndex: 'currentValue',
        render: this.renderCurrency,
      },
      {
        key: '6',
        title: `${twoMonthsAgo}/${year}`,
        dataIndex: 'secondToLastMonthProfit',
        render: this.renderCurrency,
      },
      {
        key: '7',
        title: `${oneMonthAgo}/${year}`,
        dataIndex: 'lastMonthProfit',
        render: this.renderCurrency,
      },
      {
        key: '8',
        title: `${year}YTD`,
        render: this.renderCurrency,
      },
      {
        key: '9',
        title: 'ACTIONS',
        cellType: CCTableActionCell,
        menuItems: menus,
      },
    ]
  }

  render() {
    return (
      <ContextTable
        key="id"
        dataMethod={fetchAllocations}
        columns={this.getPortfolioAllocationsColumns()}
        scroll={{ x: 1500 }}
        tableTitle="Allocations"
      />
    )
  }
}

export default PortfolioAllocationsTable
