import * as React from 'react'

export default function useScrollToTop(containerIdOrRef) {
  return React.useCallback(() => {
    const el =
      typeof containerIdOrRef === 'string'
        ? document.getElementById(containerIdOrRef)
        : containerIdOrRef.current
    findScrollableParent(el)?.scrollTo(0, 0)
  }, [containerIdOrRef])
}

// The difference between an element's scrollHeight and clientHeight could be
// off by 1px on top and/or bottom, even if the element isn't scrollable.
const scrollHeightOffset = 2

const couldScroll = (el) =>
  el.scrollHeight > el.clientHeight + scrollHeightOffset

function findScrollableParent(el) {
  if (el == null) {
    return el
  }
  if (isScrollable(el)) {
    return el
  }
  return findScrollableParent(el.parentNode)
}

function isScrollable(el) {
  if (el.scrollTopMax !== undefined) {
    return el.scrollTopMax > 0
  }

  if (el === document.scrollingElement) {
    return couldScroll(el)
  }

  return (
    couldScroll(el) &&
    ['auto', 'scroll'].includes(getComputedStyle(el).overflowY)
  )
}
