import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import qs from 'qs'
import { api } from './services'

export const getCampaign = (campaignId) =>
  api.get(`/campaigns/${campaignId}`).then((res) => res.data.result)

export const logView = (campaignId) => api.post(`/campaigns/${campaignId}/log`)

export const createCampaign = ({
  name,
  notificationFrequencyId,
  campaignEntityTypeId,
}) =>
  api
    .post('/campaigns', {
      name,
      campaignEntityTypeId,
      notificationFrequencyId,
      campaignStatusId: 1,
    })
    .then((res) => res.data.result)

export const getMyCampaigns = (
  companyId,
  { searchTerm = '', pageNumber = 1, pageSize = 10, targetCampaignId, orderBy }
) =>
  api
    .get(`/campaigns/company/${companyId}`, {
      params: {
        q: searchTerm,
        page: pageNumber,
        pageSize,
        targetCampaignId,
        orderBy,
      },
    })
    .then((res) => res.data.result)

export const updateCampaign = (
  campaignId,
  {
    name,
    campaignStatusId,
    campaignTagId,
    notificationFrequencyId,
    notificationMethodId,
  }
) =>
  api
    .patch(`/campaigns/${campaignId}`, {
      name,
      campaignStatusId,
      campaignTagId,
      notificationFrequencyId,
      notificationMethodId,
    })
    .then((res) => res.data.result)

export const getApplications = (
  campaignId,
  { searchTerm, pageNumber = 1, pageSize = 10, orderBy, filter }
) => {
  const params = {
    q: searchTerm,
    page: pageNumber,
    pageSize,
    orderBy,
  }
  const formattedFilter = compact(filter)
  if (!isEmpty(formattedFilter)) {
    params.filter = formattedFilter
  }
  return api
    .get(`/campaigns/${campaignId}/applications`, {
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    .then((res) => res.data.result)
}

export const getSentApplications = (
  campaignId,
  { searchTerm, pageNumber = 1, pageSize = 10, orderBy, filter }
) => {
  const params = {
    q: searchTerm,
    page: pageNumber,
    pageSize,
    orderBy,
  }
  const formattedFilter = compact(filter)
  if (!isEmpty(formattedFilter)) {
    params.filter = formattedFilter
  }
  return api
    .get(`/campaigns/${campaignId}/applications/sent`, {
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    .then((res) => res.data.result)
}

export const updateApplicationStatus = (
  campaignApplicationId,
  statusId,
  feedbackOptionIds,
  message,
  shareFeedback,
  discoverListIds,
  subscribeToFund
) =>
  api
    .patch(
      `campaigns/applications/${campaignApplicationId}/status/${statusId}`,
      {
        feedbackOptionIds,
        feedbackMessage: message,
        shareFeedback,
        discoverListIds,
        subscribeToFund,
      }
    )
    .then((res) => res.data.result)

export const applyToCampaign = ({ toCampaignId, fromCampaignId, message }) =>
  api.post(`/campaigns/${toCampaignId}/apply`, { fromCampaignId, message })

export const checkAllocatorPrompt = () =>
  api.get('/campaigns/allocator-prompt').then((res) => res.data.result)

export const getApplicationFeedbackOptions = () =>
  api.get('/campaigns/applications/feedback').then((res) => res.data.result)

export const getCampaignTagOptions = (campaignEntityTypeId) =>
  api
    .get(`/campaigns/type/${campaignEntityTypeId}/tags`)
    .then((res) => res.data.result)

export const updateCampaignStatus = (campaignId, campaignStatusId) =>
  api
    .patch(`/campaigns/${campaignId}/status`, {
      campaignStatusId,
    })
    .then((res) => res.data.result)

export const updateCampaignTag = (campaignId, campaignTagId) =>
  api
    .patch(`/campaigns/${campaignId}/tag`, {
      campaignTagId,
    })
    .then((res) => res.data.result)

export const getResearchWizardOptions = () =>
  api.get('/researchcampaigns/options').then((res) => res.data.result)

export const getResearchCampaignWizard = (researchCampaignId) =>
  api
    .get(`/researchcampaigns/${researchCampaignId}/wizard`)
    .then((res) => res.data.result)

export const createResearchCampaign = (campaignId, researchCampaign) =>
  api
    .post('/researchcampaigns', { campaignId, ...researchCampaign })
    .then((res) => res.data.result)

export const updateResearchCampaign = (researchCampaignId, researchCampaign) =>
  api
    .patch(`/researchcampaigns/${researchCampaignId}`, researchCampaign)
    .then((res) => res.data.result)

export const getGeneralInterestProfile = (contactId, companyId) =>
  api
    .get(`/allocators/${contactId}/${companyId}/profile`)
    .then((res) => res.data.result)

export const getCampaignApplicationResponses = (campaignApplicationId) =>
  api
    .get(`campaigns/applications/${campaignApplicationId}/responses`)
    .then((res) => res.data.result)

export const logCampaignApplicationView = (campaignApplicationId) =>
  api.post(`/campaigns/applications/${campaignApplicationId}/logview`)

export const getCampaignApplicationCounts = (campaignId) =>
  api
    .get(`campaigns/${campaignId}/applicationcounts`)
    .then((res) => res.data.result)

export const getRemainingCredits = (campaignEntityTypeId, fromCampaignId) =>
  api
    .get(`/campaigns/${campaignEntityTypeId}/${fromCampaignId}/credits`)
    .then((res) => res.data.result)

export const getMandateQuestionnaireResponses = (campaignApplicationId) =>
  api
    .get(`/campaigns/applications/${campaignApplicationId}/responses`)
    .then((res) => res.data.result)
