import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import {
  CheckCircleOutline,
  CompareArrows,
  EmailOutlined,
  Exposure,
  FolderOutlined,
  OpenInNew,
} from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import { ApplyToCampaignButton, ViewApplicationButton } from '../Application'
import FundSubscriptionModal from './FundSubscriptionModal'

const DiscoverFundResultFooter = ({
  fromPreview = false,
  fund,
  isSelectedForCompare,
  onShowAddToListModal,
  onToggleCompare,
}) => {
  const { trackEvent } = useTracking()
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const email = useSelector((state) => state.auth.contact?.email)

  function toggleCompare() {
    trackEvent({
      eventName: 'click',
      element: 'Compare',
      fundId: fund.fundId,
      action: isSelectedForCompare ? 'remove' : 'add',
    })
    onToggleCompare()
  }

  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row justify-start space-x-2">
        {!fromPreview && (
          <Button
            status="secondary"
            iconLeft={<Exposure />}
            onClick={() => onShowAddToListModal()}
          >
            Lists
          </Button>
        )}
        {!fromPreview && (
          <Button
            status={isSelectedForCompare ? 'success' : 'secondary'}
            iconLeft={
              isSelectedForCompare ? <CheckCircleOutline /> : <CompareArrows />
            }
            onClick={toggleCompare}
          >
            Compare
          </Button>
        )}
      </div>
      <div className="flex flex-row space-x-2">
        {!fromPreview && (
          <>
            <Tooltip
              text={
                fund.alreadySubscribed
                  ? 'You are already subscribed to this fund'
                  : 'Subscribe to this fund manager’s email distribution list'
              }
            >
              <div>
                <Button
                  disabled={fund.alreadySubscribed}
                  iconLeft={<EmailOutlined />}
                  onClick={() => {
                    trackEvent({
                      eventName: 'click',
                      element: 'Subscribe to fund',
                      fundId: fund.fundId,
                    })
                    setShowSubscriptionModal(true)
                  }}
                >
                  Subscribe
                </Button>
              </div>
            </Tooltip>

            <Button
              as={Link}
              to={`/fund/${fund.fundId}?tab=attachments`}
              iconLeft={<FolderOutlined />}
              onClick={() => {
                trackEvent({
                  eventName: 'click',
                  element: 'View attachments',
                  fundId: fund.fundId,
                })
              }}
            >
              Open Dataroom
            </Button>

            <ViewApplicationButton
              campaignEntity={{
                ...fund,
                campaignId: fund.campaignId,
                campaignName: fund.name,
              }}
            />
            <ApplyToCampaignButton
              campaignEntity={{
                ...fund,
                campaignId: fund.campaignId,
                campaignName: fund.name,
                campaignThreshold: fund.threshold,
              }}
              campaignType="funds"
            />
          </>
        )}
        {fromPreview && (
          <Button
            variant="filled"
            as={Link}
            to={`/discover/funds?search=${fund.name}&selectedId=${fund.fundId}`}
            target="_blank"
            iconRight={<OpenInNew />}
          >
            Open
          </Button>
        )}
        {showSubscriptionModal && (
          <FundSubscriptionModal
            visible={showSubscriptionModal}
            fundId={fund.fundId}
            email={email}
            onCancel={() => setShowSubscriptionModal(false)}
            onSubscribe={() => {
              setShowSubscriptionModal(false)
            }}
          />
        )}
      </div>
    </div>
  )
}

DiscoverFundResultFooter.propTypes = {
  fromPreview: PropTypes.bool,
  fund: PropTypes.shape({
    fundId: PropTypes.number.isRequired,
    name: PropTypes.string,
    isHiddenForMandate: PropTypes.bool,
    campaignId: PropTypes.number,
    threshold: PropTypes.number,
    alreadySubscribed: PropTypes.bool,
  }).isRequired,
  isSelectedForCompare: PropTypes.bool,
  onShowAddToListModal: PropTypes.func.isRequired,
  onToggleCompare: PropTypes.func.isRequired,
}

export default DiscoverFundResultFooter
