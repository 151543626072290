import * as React from 'react'
import map from 'lodash/map'
import { useFundPerformanceQuery } from '~/lib/fundPerformanceQuery'
import { useCompareFundsContext } from '../compareFundsContext'

export default function createComparisonChart({
  queryFn,
  chartControls: ChartControls,
  ...states
}) {
  function Component() {
    const query = useCompareChartsQuery(queryFn)

    let chartContent
    switch (query.status) {
      case 'loading':
        chartContent = states.loading ?? null
        break
      case 'error':
        chartContent = <states.error error={query.error} />
        break
      case 'success':
        chartContent = <states.success chartData={query.data} />
        break
      default:
        chartContent = null
        break
    }

    return (
      <>
        <ChartControls />
        {chartContent}
      </>
    )
  }

  return React.memo(Component)
}

function useCompareChartsQuery(queryFn) {
  const { funds, options, chartType, onQueryUpdate } = useCompareFundsContext()

  const fundIds = map(funds, 'fundId')
  const query = useFundPerformanceQuery(
    ['compareFunds', fundIds, options, chartType],
    () => fetchChartData(fundIds, options, queryFn),
    {
      // These requests don't change frequently, so we can be less aggressive about re-fetching
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  )

  React.useEffect(() => {
    onQueryUpdate(query)
  }, [query, onQueryUpdate])

  return query
}

function passthrough(fundIds, fetchData) {
  return fetchData(fundIds)
}

async function fetchChartData(fundIds, options, makeRequest) {
  const transformRequest = process.env.REACT_APP_CHARTS_FUND_ID
    ? (await import('../dev_mapFundsComparison')).transformRequest
    : passthrough

  return transformRequest(fundIds, async (fundIds) => {
    const response = await makeRequest(fundIds, options)
    return response.data
  })
}
