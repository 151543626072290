import { useState } from 'react'
import PropTypes from 'prop-types'
import { Input, Modal, Radio, message } from 'antd'
import filter from 'lodash/filter'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import startsWith from 'lodash/startsWith'
import trim from 'lodash/trim'
import { useSelector } from 'react-redux'
import {
  cancelMeeting,
  cancelOnlineMeeting,
  declineMeeting,
  declineOnlineMeeting,
} from '~/actions/meetings'
import {
  selectOnlineCancellationReasons,
  selectSummitCancellationReasons,
} from '~/selectors/meetings'
import Loading from '../Loading'
import './CancelMeetingButton.less'

const RadioGroup = Radio.Group
const { TextArea } = Input

const CancelMeetingButton = ({
  visible,
  closeModal,
  meetingType,
  company,
  cancelText = 'Cancel',
  meetingId,
  meetingStatus = null,
  onCancel,
}) => {
  const [cancellingInProgress, setCancellingInProgress] = useState(false)
  const [selectedReasonId, setSelectedReasonId] = useState(0)
  const [otherReason, setOtherReason] = useState(false)
  const [otherReasonText, setOtherReasonText] = useState('')

  const reasonRequired =
    meetingStatus && meetingStatus.toLowerCase() == 'confirmed'

  const cancellationReasons = useSelector(
    meetingType.toLowerCase() == 'summit'
      ? selectSummitCancellationReasons
      : selectOnlineCancellationReasons
  )

  const handleModalCancel = () => {
    setSelectedReasonId(0)
    setOtherReason(false)
    setOtherReasonText('')
    closeModal()
  }

  const cancelMeetingReq = () => {
    setCancellingInProgress(true)
    if (startsWith(cancelText, 'Cancel')) {
      if (meetingType === 'Summit')
        cancelMeeting(meetingId, selectedReasonId, otherReasonText)
          .then(() => {
            message.success('The meeting has been cancelled')
            handleModalCancel()
            onCancel()
          })
          .catch((err) => {
            if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              message.error(
                <span style={{ fontWeight: 'bold' }}>
                  {err.response.data.message}
                </span>,
                3
              )
            }
          })
          .finally(() => {
            setCancellingInProgress(false)
          })
      else if (meetingType === 'online')
        cancelOnlineMeeting(meetingId)
          .then(() => {
            message.success('The meeting has been cancelled')
            handleModalCancel()
            onCancel()
          })
          .finally(() => {
            setCancellingInProgress(false)
          })
    } else if (meetingType === 'Summit')
      declineMeeting(meetingId)
        .then(() => {
          message.success('The meeting has been declined')
          handleModalCancel()
          onCancel()
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            message.error(
              <span style={{ fontWeight: 'bold' }}>
                {err.response.data.message}
              </span>,
              3
            )
          }
        })
        .finally(() => {
          setCancellingInProgress(false)
        })
    else if (meetingType === 'online')
      declineOnlineMeeting(meetingId)
        .then(() => {
          message.success('The meeting has been declined')
          handleModalCancel()
          onCancel()
        })
        .finally(() => {
          setCancellingInProgress(false)
        })
  }

  let title =
    cancelText.indexOf('Meeting') === -1 ? `${cancelText} Meeting` : cancelText
  if (!isNil(meetingStatus)) {
    if (meetingStatus === 'Pending' && startsWith(cancelText, 'Cancel')) {
      title = 'Cancel Meeting Request'
    } else if (meetingStatus === 'Confirmed') {
      title = 'Cancel Meeting'
    }
  }

  return (
    <Modal
      className="cc-modal-accept"
      title={title}
      visible={visible}
      okText={title}
      cancelText="Close"
      onOk={cancelMeetingReq}
      onCancel={handleModalCancel}
      okButtonProps={{
        loading: cancellingInProgress,
        disabled:
          reasonRequired &&
          (selectedReasonId === 0 ||
            (otherReason && trim(otherReasonText) === '')),
        style: { backgroundColor: '#D62B34', borderStyle: 'none' },
      }}
      cancelButtonProps={{ disabled: false }}
    >
      <Loading spinning={cancellingInProgress}>
        {cancellationReasons.length > 0 && reasonRequired ? (
          <div>
            <p className="cc-body-text cc-lightblack-text">{`Please select the reason you want to ${
              cancelText.indexOf('Meeting') === -1
                ? cancelText.toLowerCase()
                : 'cancel'
            } the meeting${
              cancelText.indexOf('Decline') !== -1 ? ' request' : ''
            }:`}</p>
            <RadioGroup
              style={{ width: '100%' }}
              value={selectedReasonId}
              onChange={(e) => {
                setSelectedReasonId(e.target.value)
                const reason = filter(
                  cancellationReasons,
                  (r) => r.cancellationReasonId == e.target.value
                )
                setOtherReason(reason[0].requiresInput)
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '16px',
                }}
              >
                {map(cancellationReasons, (c) => (
                  <span
                    style={{ marginBottom: '16px' }}
                    className="cancellingReasons"
                  >
                    <Radio
                      key={c.cancellationReasonId.toString()}
                      value={c.cancellationReasonId}
                    >
                      {c.description}
                    </Radio>
                  </span>
                ))}
                {otherReason && (
                  <TextArea
                    placeholder="Please type another reason"
                    rows={4}
                    value={otherReasonText}
                    onChange={({ target: { value } }) => {
                      setOtherReasonText(value)
                    }}
                  />
                )}
              </div>
            </RadioGroup>
          </div>
        ) : (
          <p>
            Are you sure you want to{' '}
            {cancelText.indexOf('Meeting') === -1
              ? cancelText.toLowerCase()
              : 'cancel'}{' '}
            the {meetingType} Meeting with {company}?
          </p>
        )}
      </Loading>
    </Modal>
    // </div>
  )
}

CancelMeetingButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  cancelText: PropTypes.oneOf(['Decline', 'Cancel']),
  meetingType: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  meetingId: PropTypes.number.isRequired,
  meetingStatus: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
}

export default CancelMeetingButton
