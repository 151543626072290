import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faClock,
  faComment,
  faEnvelope,
  faMinusCircle,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Empty,
  List,
  Popover,
  Row,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import times from 'lodash/times'
import trim from 'lodash/trim'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchMeetingsConfirmed } from '~/actions/contextMeetings'
import { fetchUpcomingMeetings, getMeetingList } from '~/actions/meetings'
import { sendMessage, sendMessageToConversation } from '~/actions/messages'
import useSearchParams from '~/hooks/useSearchParams'
import { selectedEventHasEnded } from '~/selectors/events'
import { boldCompanyNameFormat } from '~/utils/helpers'
import {
  canLeaveMeeting,
  getFormatedMeetingTime,
  renderParticipantsAvatars,
} from '~/utils/meetingActions'
import EmptyEventTablesState from '~/views/summits/meetings/EmptyEventTablesState'
import CalendarInvitationDownloadButton from '../CalendarInvitationDownloadButton'
import Loading from '../Loading'
import { CancelSummitMeeting } from '../MeetingActions'
import MeetingsCardView from '../MeetingsCardView'
import MessageModal from '../MessageModal'
import RescheduleEventMeeting from '../RescheduleEventMeeting/RescheduleEventMeeting'
import RescheduleMeeting from '../RescheduleMeeting'
import ResponsiveCard, {
  DATE,
} from '../Responsive/ResponsiveCards/OnlineMeetingRecivedRequestResponsiveCard'
import SearchBox from '../SearchBox/SearchBox'
import TimeDisplay from '../TimeDisplay'
import './ConfirmedMeetingsTable.less'

const tooltTipText = <span>This meeting carries Context Points.</span>

const ConfirmedMeetingsTable = ({
  activeSummit = true, //used just for empty state of tables. Can be true even for online meetings table
  meetingType,
  allowMultipleCompaniesMeeting,
  meetingTypeId = 1,
  eventId,
  width,
  fromSummit,
  refreshTable,
  onCancelRequest,
}) => {
  const [
    showRescheduleOnlineMeetingModal,
    setShowRescheduleOnlineMeetingModal,
  ] = useState(false)
  const [
    showRescheduleSummitMeetingModal,
    setShowRescheduleSummitMeetingModal,
  ] = useState(false)
  const [showCancelOnlineModal, setShowCancelOnlineModal] = useState(false)
  const [messageModalVisible, setMessageModalVisible] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState({})
  const [displayTimezone, setDisplayTimezone] = useState(TimeDisplay.ORIGINAL)
  const [displayTimezoneLabel, setDisplayTimezoneLabel] =
    useState('Event Timezone')
  const [refresh, setRefresh] = useState(refreshTable)
  const [isLoading, setIsLoading] = useState(false)
  const [cancelForAll, setCancelForAll] = useState(false)
  const [allConfirmedList, setAllConfirmedList] = useState([])
  const [hasNext, setHasNext] = useState(false)
  const meetingWithString = boldCompanyNameFormat(selectedRecord)

  const [searchParams, setSearchParams] = useSearchParams()

  const updateQueryParams = React.useCallback(
    (updatedFields) => {
      setSearchParams({
        ...searchParams,
        ...updatedFields,
      })
    },
    [searchParams, setSearchParams]
  )

  const resetPage = React.useCallback(
    (updatedFields = {}) => {
      updateQueryParams({ ...updatedFields, page: 1 })
    },
    [updateQueryParams]
  )

  const { page, searchQuery, pageSize } = React.useMemo(() => {
    const { page = 1, search = '', size = 10 } = searchParams

    return {
      page: parseInt(page, 10),
      searchQuery: search,
      pageSize: parseInt(size, 10),
    }
  }, [searchParams])

  const loadPage = React.useCallback(
    (page, size) => {
      updateQueryParams({ page, size })
    },
    [updateQueryParams]
  )

  const searchByText = React.useCallback(
    (text) => {
      resetPage({ search: text })
    },
    [resetPage]
  )

  const confirmedList = useSelector((state) => {
    if (eventId > 0) {
      return state.meetings.confirmedMeetingsCompanyEventId
    } else if (meetingType.toLowerCase() === 'online')
      return state.meetings.onlineConfirmedMeetings
    else if (meetingType.toLowerCase() === 'roadshow')
      return state.meetings.roadshowConfirmedMeetings
    return state.meetings.confirmedMeetings
  })

  const total = isNil(confirmedList) ? 0 : confirmedList.itemCount

  const pastSummit = useSelector(selectedEventHasEnded)
  const role = useSelector((state) => state.auth.role)

  const dispatch = useDispatch()

  const getConfirmedList = useCallback(
    (page, pageSize) => {
      if (allowMultipleCompaniesMeeting) {
        getMeetingList(page, pageSize, 'confirmed', searchQuery, meetingTypeId)
          .then((response) => {
            page !== 1
              ? setAllConfirmedList((f) =>
                  f.concat(response.data.result.results)
                )
              : setAllConfirmedList(response.data.result.results)
            setHasNext(response.data.result.hasNextPage)
          })
          .catch((error) => {
            if (!error?.response?.data?.reason === 'Tier')
              message.error('Could not load meetings')
          })
      }
    },
    [allowMultipleCompaniesMeeting, searchQuery, meetingTypeId]
  )
  useEffect(() => {
    getConfirmedList(page, pageSize)
  }, [page, pageSize, getConfirmedList])

  useEffect(() => {
    setRefresh(refreshTable)
  }, [refreshTable])

  useEffect(() => {
    const params = { page, pageSize }

    if (!isNil(searchQuery) && !isEmpty(searchQuery)) {
      params.q = trim(searchQuery)
    }
    setIsLoading(true)
    dispatch(
      fetchUpcomingMeetings({
        page: 1,
        pageSize: 4,
      })
    )

    dispatch(
      fetchMeetingsConfirmed({
        meetingTypeId,
        eventId,
        ...params,
      })
    ).finally(() => {
      setIsLoading(false)
    })
  }, [dispatch, eventId, meetingTypeId, page, pageSize, searchQuery, refresh])

  const handleRescheduleClick = (record) => {
    setSelectedRecord(record)
    meetingType === 'Summit'
      ? setShowRescheduleSummitMeetingModal(true)
      : setShowRescheduleOnlineMeetingModal(true)
  }

  const closeModal = () => {
    meetingType === 'Summit'
      ? setShowRescheduleSummitMeetingModal(false)
      : setShowRescheduleOnlineMeetingModal(false)
  }

  const handleCancelClick = (record) => {
    setSelectedRecord(record)
    setCancelForAll(false)
    setShowCancelOnlineModal(true)
  }

  const handleCancelForAllClick = (record) => {
    setSelectedRecord(record)
    setCancelForAll(true)
    setShowCancelOnlineModal(true)
  }

  const handleMessageModalOk = useCallback(
    (msg) => {
      const afterResponse = () => {
        message.success(
          <span>
            Message sent <a href="/messages">Go to conversation</a>
          </span>
        )
      }

      const afterError = () => {
        message.error('There was an error while attempting to send the message')
      }

      const afterBoth = () => {
        setMessageModalVisible(false)
        setLoadingMessage(false)
      }

      const conversationId = get(selectedRecord, 'conversationId', null)

      if (!isNil(conversationId)) {
        setLoadingMessage(true)
        sendMessageToConversation(conversationId, msg)
          .then(afterResponse)
          .catch(afterError)
          .finally(afterBoth)
      } else {
        setLoadingMessage(true)
        sendMessage(selectedRecord.companyEventId, {
          messageBody: msg,
          messageDetails: {
            toCompanyEventID: selectedRecord.companyEventId,
            toCompanyID: selectedRecord.companyId,
            eventId: selectedRecord.eventId,
            meetingType: meetingType.toLowerCase() === 'summit' ? 1 : 3,
          },
        })
          .then((response) => {
            afterResponse(response)
          })
          .catch(afterError)
          .finally(afterBoth)
      }
    },
    [selectedRecord, meetingType]
  )

  const renderRatings = ({ ratings }) => {
    if (role.toLowerCase() === 'manager' && ratings && ratings.rating > 0)
      return (
        <span>
          {times(ratings.rating, () => (
            <FontAwesomeIcon color="#F19F00" icon={faStar} />
          ))}
          {!isEmpty(ratings.comment) && (
            <span style={{ marginLeft: '8px' }}>
              <Tooltip title={ratings.comment}>
                <FontAwesomeIcon color="#C2C6CC" icon={faComment} />
              </Tooltip>
            </span>
          )}
        </span>
      )
    else if (
      role.toLowerCase() === 'allocator' &&
      ratings &&
      ratings.meetingRatingId > 0
    ) {
      const indications = !isEmpty(ratings.ratingIndications)
        ? ratings.ratingIndications.map((i) => i.description)
        : null
      if (!isEmpty(ratings.comment) && !isNil(indications))
        indications.push(`Comment: ${ratings.comment}`)
      return ratings.wouldMeetAgain ? (
        <span style={{ color: '#24C477' }}>
          Yes
          {!isEmpty(ratings.comment) && (
            <span style={{ marginLeft: '8px' }}>
              <Tooltip title={ratings.comment}>
                <FontAwesomeIcon color="#C2C6CC" icon={faComment} />
              </Tooltip>
            </span>
          )}
        </span>
      ) : (
        <span style={{ color: '#D62B34' }}>
          No
          {!isNil(indications) && (
            <span style={{ marginLeft: '8px' }}>
              <Popover
                overlayClassName="Ratings-popover"
                content={
                  <div>
                    <List
                      size="small"
                      dataSource={indications}
                      renderItem={(item) => <List.Item>{item}</List.Item>}
                    />
                  </div>
                }
              >
                <FontAwesomeIcon color="#C2C6CC" icon={faComment} />
              </Popover>
            </span>
          )}
        </span>
      )
    } else return null
  }

  const renderCalendarInvitation = (record) => {
    const shouldShow =
      record.meetingType.toLowerCase() === 'online' &&
      !isNil(record.meetingDateTime) &&
      moment
        .utc(record.meetingDateTime)
        .add(record.meetingDuration, 'minute') >= moment.utc()

    if (shouldShow) {
      return <CalendarInvitationDownloadButton record={record} />
    }

    return null
  }

  const isPhysicalSummitMeeting = (record) => {
    return meetingType.toLowerCase() === 'summit' && !record.virtualSummit
  }

  const renderActions = (record) => {
    let showLeaveButton
    let showCancel
    if (isPhysicalSummitMeeting(record)) {
      showLeaveButton = false
      showCancel = true
    } else {
      showLeaveButton = canLeaveMeeting(record.colleagues, record.participants)

      showCancel = !showLeaveButton || record.isInitiator
    }

    return pastSummit && meetingType.toLowerCase() === 'summit' ? (
      <div>
        <Button
          onClick={(e) => {
            setMessageModalVisible(true)
            setSelectedRecord(record)
            e.stopPropagation()
          }}
          type="primary"
        >
          <FontAwesomeIcon icon={faEnvelope} />
          <span style={{ marginLeft: '4px' }}>Send Message</span>
        </Button>
      </div>
    ) : record.meetingType.toLowerCase() === 'online' &&
      !isNil(record.meetingDateTime) &&
      moment
        .utc(record.meetingDateTime)
        .add(record.meetingDuration, 'minute') <= moment.utc() ? null : (
      <div className="cc-meeting-actions">
        {(!isNil(record.meetingDateTime) ||
          (record.isOptimized && meetingType.toLowerCase() === 'summit')) && (
          <Tooltip placement="top" title="Reschedule">
            <Button
              onClick={(e) => {
                handleRescheduleClick(record)
                e.stopPropagation()
              }}
              className="cc-btn cc-btn-reschedule"
            >
              <FontAwesomeIcon color="#FFFFFF" icon={faClock} />
            </Button>
          </Tooltip>
        )}
        <span className="cc-btn cc-btn-danger">
          {showLeaveButton && (
            <Tooltip placement="top" title="Cancel Participation">
              <Button
                onClick={(e) => {
                  handleCancelClick(record)
                  e.stopPropagation()
                }}
                className="cc-btn cc-btn-danger"
              >
                <i className="cc-btn-bg" />
                <FontAwesomeIcon color="#D62B34" icon={faMinusCircle} />
              </Button>
            </Tooltip>
          )}
        </span>
        <span className="cc-btn cc-btn-danger">
          {showCancel && (
            <Tooltip
              placement="top"
              title={
                isPhysicalSummitMeeting(record) ? 'Cancel' : 'Cancel For All'
              }
            >
              <Button
                onClick={(e) => {
                  record.isInitiator || isPhysicalSummitMeeting(record)
                    ? handleCancelForAllClick(record)
                    : handleCancelClick(record)
                  e.stopPropagation()
                }}
                className="cc-btn cc-btn-red"
              >
                <i className="cc-btn-bg" />
                <FontAwesomeIcon color="#FFF" icon={faTimes} />
              </Button>
            </Tooltip>
          )}
        </span>
      </div>
    )
  }

  const getColumns = () => {
    let columns = [
      {
        title: 'MEETING WITH',
        render: (record) => (
          <div>
            <div>
              <Link
                to={{
                  pathname: `/meeting/${meetingType.toLowerCase()}/${
                    record.meetingId
                  }`,
                  state: { isReceived: false },
                }}
              >
                <span className="cc-body-text">
                  {record.company.companyName}
                </span>
              </Link>
              <div className="ReceivedRequestsTable-category">
                {record.categoryName}
              </div>
            </div>

            {record.carryContextPoints && (
              <span style={{ paddingLeft: '8px' }}>
                <Tooltip placement="top" title={tooltTipText}>
                  <Tag visible={record.carryContextPoints}>
                    + 1000 Context Points
                  </Tag>
                </Tooltip>
              </span>
            )}
          </div>
        ),
      },
      {
        title: 'PARTICIPANTS',
        render: renderParticipantsAvatars,
        width: 350,
      },
      {
        cardType: DATE,
        title: 'Meeting Date & Time ',
        align: 'center',
        dataIndex: 'meetingDateTime',
        width: 255,
        render: (meetingDateTime, meeting) =>
          moment(meetingDateTime).isValid()
            ? getFormatedMeetingTime(
                meeting.meetingTimeZone,
                meeting.virtualSummit,
                meetingDateTime,
                'YYYY-MM-DD h:mm A'
              )
            : 'TBD',
      },
      {
        title: 'RATINGS',
        render: renderRatings,
        align: 'left',
      },
      {
        title: 'ACTIONS',
        render: renderActions,
        align: 'left',
        notRenderInCard: true,
        width: 200,
      },
    ]
    if (role === 'Service Provider')
      columns = columns.filter((column) => column.title !== 'RATINGS')

    const eventName = {
      title: 'Summit',
      className: 'cc-body-text',
      dataIndex: 'eventName',
    }

    if (!fromSummit && meetingTypeId === 1) columns.splice(3, 0, eventName)

    return columns
  }

  const handleInfiniteOnLoad = useCallback(() => {
    if (allConfirmedList?.length > 0 && hasNext) {
      loadPage(page + 1, pageSize)
    }
  }, [allConfirmedList, hasNext, loadPage, page, pageSize])

  const RescheduleModal =
    meetingType === 'Summit' ? (
      <RescheduleEventMeeting
        visible={showRescheduleSummitMeetingModal}
        closeModal={closeModal}
        meetingId={selectedRecord.meetingId}
        eventId={selectedRecord.eventId}
        onRescheduleMeeting={() => {
          getConfirmedList(1, pageSize * page)
          setRefresh(!refresh)
        }}
        participants={
          selectedRecord.colleagues
            ? selectedRecord.colleagues.map((x) => x.contactId)
            : []
        }
        toParticipants={
          selectedRecord.participants
            ? selectedRecord.participants.map((x) => x.contactId)
            : []
        }
        meetingDateTime={selectedRecord?.meetingDateTime}
        meetingDuration={selectedRecord?.meetingDuration}
        timezone={selectedRecord?.meetingTimeZone}
        isVirtualSummit={selectedRecord?.virtualSummit}
      />
    ) : (
      <RescheduleMeeting
        visible={showRescheduleOnlineMeetingModal}
        closeModal={closeModal}
        onlineMeetingId={selectedRecord.meetingId}
        onRescheduleMeeting={() => {
          setRefresh(!refresh)
          getConfirmedList(1, pageSize * page)
        }}
        participants={
          selectedRecord.colleagues
            ? selectedRecord.colleagues.map((x) => x.contactId)
            : []
        }
        toParticipants={
          selectedRecord.participants
            ? selectedRecord.participants.map((x) => x.contactId)
            : []
        }
        meetingDateTime={selectedRecord?.meetingDateTime}
        meetingDuration={selectedRecord?.meetingDuration}
        roadshowId={selectedRecord?.roadshowId}
      />
    )

  if (width < 1025 && !allowMultipleCompaniesMeeting) {
    if (total === 0 && !isLoading)
      return <Empty description="No confirmed meetings." />
    else
      return (
        <Loading spinning={isLoading}>
          <ResponsiveCard
            columns={getColumns()}
            requests={!isNil(confirmedList) ? confirmedList.results : []}
            meetingType={meetingType}
            tableType="Confirmed Meetings"
            displayTimezone={displayTimezone}
            setDisplayTimezone={setDisplayTimezone}
            displayTimezoneLabel={displayTimezoneLabel}
            setDisplayTimezoneLabel={setDisplayTimezoneLabel}
            onRescheduleMeeting={() => {
              setRefresh(!refresh)
            }}
            onCancelMeeting={() => {
              onCancelRequest()
              setRefresh(!refresh)
            }}
          />
        </Loading>
      )
  } else
    return (
      <div
        className={
          allowMultipleCompaniesMeeting
            ? 'ConfirmedMeetingsTableCfn'
            : 'ConfirmedMeetingsTable'
        }
      >
        {RescheduleModal}

        <CancelSummitMeeting
          visible={showCancelOnlineModal}
          closeModal={() => {
            setShowCancelOnlineModal(false)
          }}
          meetingStatus="Confirmed"
          company={meetingWithString}
          meetingId={selectedRecord.meetingId}
          meetingType={meetingType}
          onCancel={() => {
            onCancelRequest()
            setRefresh(!refresh)
            getConfirmedList(1, pageSize * page)
          }}
          canDeclineMeeting={
            !canLeaveMeeting(
              selectedRecord.colleagues,
              selectedRecord.participants
            )
          }
          cancelForAll={cancelForAll}
        />
        <MessageModal
          meeting={selectedRecord}
          visible={messageModalVisible}
          onCancel={() => {
            setMessageModalVisible(false)
          }}
          onOk={handleMessageModalOk}
          loading={loadingMessage}
        />
        <Loading spinning={isLoading}>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px',
            }}
          >
            <h4
              className={`${
                allowMultipleCompaniesMeeting
                  ? 'ConfirmedMeetingsTableCfn'
                  : 'ConfirmedMeetingsTable'
              }-title  cc-heading4`}
            >
              Confirmed Meetings
            </h4>

            <SearchBox onSearch={searchByText} allowClear={true} />
          </Row>
          {!allowMultipleCompaniesMeeting && (
            <Table
              locale={{
                emptyText: (
                  <EmptyEventTablesState activeSummit={activeSummit} />
                ),
              }}
              columns={getColumns()}
              rowKey={(record) => record.meetingId}
              dataSource={confirmedList ? confirmedList.results : []}
              pagination={{
                current: page,
                pageSize,
                total,
              }}
              onChange={(pagination) => {
                loadPage(pagination.current, pagination.pageSize)
              }}
            />
          )}
          {allConfirmedList && allowMultipleCompaniesMeeting && (
            <MeetingsCardView
              meetingList={allConfirmedList}
              hasMore={hasNext}
              isSearching={searchQuery && !isEmpty(searchQuery)}
              isScrollingAllowed={page > 0}
              handleInfiniteOnLoad={handleInfiniteOnLoad}
              renderRatings={renderRatings}
              renderCalendarInvitation={renderCalendarInvitation}
              renderActions={renderActions}
              type="Confirmed"
              loading={isLoading}
            />
          )}
        </Loading>
      </div>
    )
}

ConfirmedMeetingsTable.propTypes = {
  activeSummit: PropTypes.bool,
  meetingType: PropTypes.string.isRequired,
  meetingTypeId: PropTypes.number,
  eventId: PropTypes.number,
  width: PropTypes.number,
  fromSummit: PropTypes.bool,
  refreshTable: PropTypes.bool,
  onCancelRequest: PropTypes.func,
  allowMultipleCompaniesMeeting: PropTypes.bool,
}

export default ConfirmedMeetingsTable
