import PropTypes from 'prop-types'
import { Modal } from 'antd'
import CompareFunds from './CompareFunds'

const CompareFundsModal = ({ visible, funds, onClose }) => {
  return (
    visible && (
      <Modal
        visible
        title="Compare Funds"
        footer={false}
        onCancel={onClose}
        width="1500px"
        bodyStyle={{ padding: 0 }}
      >
        <CompareFunds funds={funds} />
      </Modal>
    )
  )
}

CompareFundsModal.propTypes = {
  visible: PropTypes.bool,
  funds: PropTypes.arrayOf(
    PropTypes.shape({ fundId: PropTypes.number, name: PropTypes.string })
  ),
  onClose: PropTypes.func,
}

export default CompareFundsModal
