import { useContext } from 'react'
import { ExpandMore, InfoOutlined } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import { Alert, Checkbox, Dropdown, Menu } from 'antd'
import { useFormikContext } from 'formik'
import PresentationWizardCtx from '~/utils/contexts/PresentationWizardCtx'
import ProfilePictureGroup from '../../ProfilePictureGroup'
import { PRESENTATION_ROLES } from './presentationRoles'

const ModeratorPresenters = () => {
  const {
    selectedContacts,
    setSelectedContacts,
    selectedGuestRoles,
    setSelectedGuestRoles,
    displayData,
    presentationType,
    userInvites,
    isLive,
  } = useContext(PresentationWizardCtx)

  const { errors, setFieldValue, validateField } = useFormikContext()

  const handleValueSet = (shortName, value) =>
    Promise.resolve(setFieldValue(shortName, value)).then(() =>
      validateField(shortName)
    )

  const handleSelectedContactsChange = (values) => {
    setSelectedContacts(values)
    handleValueSet(
      'attendeesRolesModerator',
      values.map((s) => s.role).filter((a) => a === 1)
    )
    handleValueSet(
      'attendeesRolesPresenters',
      values.map((s) => s.role).filter((a) => a === 2)
    )
    handleValueSet(
      'attendeesRolesAdmin',
      values.map((s) => s.role).filter((a) => a === 4)
    )
    handleValueSet(
      'attendeesInvitesRolesModerator',
      [
        ...selectedGuestRoles,
        ...values.map((contact) => contact.role),
        ...userInvites.map((contact) => contact.role),
      ].filter((contact) => contact === 1)
    )
    handleValueSet(
      'attendeesInvitesRolesPresenters',
      [
        ...selectedGuestRoles,
        ...values.map((contact) => contact.role),
        ...userInvites.map((contact) => contact.role),
      ].filter((contact) => contact === 2)
    )
  }

  const handleGuestRolesChange = (values) => {
    setSelectedGuestRoles(values)
    handleValueSet(
      'attendeesRolesModerator',
      [...values, ...selectedContacts.map((s) => s.role)].filter((a) => a === 1)
    )
    handleValueSet(
      'attendeesRolesPresenters',
      [...values, ...selectedContacts.map((s) => s.role)].filter((a) => a === 2)
    )
  }

  const handleRolesChanged = (contactId, newValue) => {
    const newValues = [...selectedContacts]
    newValues.find((n) => n.contactId === contactId).role = newValue
    handleSelectedContactsChange(newValues)
  }

  const handleGuestRolesChanged = (index, newValue) => {
    const newValues = [...selectedGuestRoles]
    newValues[index] = newValue
    handleGuestRolesChange(newValues)
  }

  const handleCheckboxChange = (e) => {
    const colleague = (presentation) =>
      displayData.colleagues.find((c) => c.contactId === presentation)

    const contacts = e.map((presentation) => ({
      contactId: presentation,
      role:
        selectedContacts.find((s) => s.contactId === presentation)?.role ||
        (colleague(presentation)?.isLoggedInContact ? 1 : 2),

      companyId: colleague(presentation)?.companyId,
      contact: colleague(presentation),
      isSaved:
        selectedContacts.find((s) => s.contactId === presentation)?.isSaved ||
        false,
    }))

    handleSelectedContactsChange(contacts)
  }

  const menu = (key, isGuest = false) => (
    <Menu>
      {displayData.roles.map((d) => (
        <Menu.Item
          key={d.id}
          onClick={() => {
            isGuest
              ? handleGuestRolesChanged(key, d.id)
              : handleRolesChanged(key, d.id)
          }}
        >
          {d.name}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <div className="flex flex-col">
      {(errors.attendeesRolesModerator ||
        errors.attendeesRolesPresenters ||
        errors.attendeesRolesAdmin) && (
        <Alert
          className="mb-6"
          message={
            <div>
              <div>{errors.attendeesRolesModerator}</div>
              <div>{errors.attendeesRolesPresenters}</div>
              <div>{errors.attendeesRolesAdmin}</div>
            </div>
          }
          type="warning"
          showIcon
        />
      )}
      <div className="text-grey-900 type-body-regular-sm mb-6">
        Choose your colleagues that will be part of the presentation and select
        the permission for each of them:
      </div>
      <Checkbox.Group
        className="w-100"
        defaultValue={selectedContacts.map((s) => s.contactId)}
        onChange={(e) => handleCheckboxChange(e)}
      >
        {displayData.colleagues.map((c) => (
          <div key={c.contactId}>
            <div className="flex justify-between">
              <div className="flex items-center">
                <Checkbox className="mr-4" value={c.contactId} />
                <div className="messages-compose-option-contact">
                  <div className="messages-compose-option-contact-image">
                    <ProfilePictureGroup
                      diameter={32}
                      maxImages={1}
                      profiles={[
                        {
                          name: c.contactName,
                          firstName: c.firstName,
                          lastName: c.lastName,
                          ...c,
                        },
                      ]}
                    />
                  </div>
                  <div className="ml-2">
                    <div className="type-body-regular-sm mb-2 messages-compose-option-contact-name">
                      {c.contactName}
                    </div>
                    <div className="type-body-regular-xs messages-compose-option-company-name ">
                      {c.jobTitle} {c.isLoggedInContact && '(YOU)'}
                    </div>
                  </div>
                </div>
              </div>
              {selectedContacts
                .map((s) => s.contactId)
                .includes(c.contactId) && (
                <div className="flex items-center">
                  {isLive &&
                  selectedContacts.find((s) => s.contactId === c.contactId)
                    .isSaved ? (
                    <div className="text-grsey-900 mr-2">
                      {
                        displayData.roles[
                          selectedContacts.find(
                            (s) => s.contactId === c.contactId
                          ).role - 1
                        ]?.name
                      }
                    </div>
                  ) : (
                    <Dropdown
                      overlay={menu(c.contactId, false)}
                      className="text-grey-900"
                    >
                      <div className="ant-dropdown-link flex items-center cursor-pointer">
                        {
                          displayData.roles[
                            selectedContacts.find(
                              (s) => s.contactId === c.contactId
                            ).role - 1
                          ]?.name
                        }
                        <ExpandMore />
                      </div>
                    </Dropdown>
                  )}
                  <Tooltip
                    text={
                      PRESENTATION_ROLES[presentationType - 1][
                        selectedContacts.find(
                          (s) => s.contactId === c.contactId
                        ).role - 1
                      ]
                    }
                  >
                    <InfoOutlined className="text-grey-700" />
                  </Tooltip>
                </div>
              )}
            </div>
            <hr className="cc-light-grey-border my-4" />
          </div>
        ))}
      </Checkbox.Group>
    </div>
  )
}

export default ModeratorPresenters
