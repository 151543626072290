import { Form } from 'antd'

const getConditionalQuestions = (
  questionAnswerId,
  questions,
  question,
  getConditionalFieldComponent,
  errors = []
) =>
  questions
    .filter(
      (conditionalQuestion) =>
        conditionalQuestion.attributes.dependentOnQuestion ===
          question.questionId.toString() &&
        conditionalQuestion.attributes.dependentOnQuestionAnswer ===
          questionAnswerId.toString()
    )
    .map((conditionalQuestion, key) => {
      const Component = getConditionalFieldComponent(conditionalQuestion)
      const error = errors[conditionalQuestion.shortName]
        ? {
            help: errors[conditionalQuestion.shortName],
            validateStatus: 'error',
          }
        : {}
      return (
        <Form.Item
          className="Label"
          key={key}
          label={conditionalQuestion.name}
          required={conditionalQuestion.isRequired}
          {...error}
        >
          <Component question={conditionalQuestion} />
        </Form.Item>
      )
    })

export default getConditionalQuestions
