import PropTypes from 'prop-types'
import cx from 'classnames'
import filter from 'lodash/filter'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import FormField from './Fields/FormField'

const QuestionRow = ({
  half = false,
  touched,
  errors,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  questions,
  fundId,
  isEditing,
  cols,
}) => {
  const rowQuestions = filter(
    questions,
    (q) => !isFunction(q.isVisible) || q.isVisible(values)
  ).map((q, i) => (
    <FormField
      key={i}
      touched={touched}
      errors={errors}
      values={values}
      handleChange={handleChange}
      handleBlur={handleBlur}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      fundId={fundId}
      isEditing={isEditing}
      {...q}
    />
  ))

  return (
    rowQuestions.length !== 0 && (
      <div
        className={cx(
          !isNil(cols)
            ? `md:grid md:grid-cols-${cols} gap-4`
            : half
            ? 'Question-row-half'
            : 'Question-row gap-4'
        )}
      >
        {rowQuestions}
      </div>
    )
  )
}

QuestionRow.propTypes = {
  fundId: PropTypes.number,
  half: PropTypes.bool,
  isEditing: PropTypes.bool,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.objectOf({
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      required: PropTypes.bool,
      half: PropTypes.bool,
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired,
      values: PropTypes.object.isRequired,
      handleChange: PropTypes.func.isRequired,
      handleBlur: PropTypes.func.isRequired,
      setFieldValue: PropTypes.func.isRequired,
      component: PropTypes.node,
      helpText: PropTypes.string,
      extraProps: PropTypes.object,
      isVisible: PropTypes.func,
    })
  ),
  cols: PropTypes.number,
}

export default QuestionRow
