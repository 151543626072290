import PropTypes from 'prop-types'
import { Form } from 'antd'
import { useFormikContext } from 'formik'
import BooleanField from './Fields/BooleanField'
import CheckboxField from './Fields/CheckboxField'
import DateField from './Fields/DateField'
import DropdownField from './Fields/DropdownField'
import InputField from './Fields/InputField'
import InputNumberField from './Fields/InputNumberField'
import LinkListField from './Fields/LinkListField'
import RadioField from './Fields/RadioField'
import StaticText from './Fields/StaticText'
import TextField from './Fields/TextField'
import './Fields.less'

const FormField = ({ question }) => {
  const { errors } = useFormikContext()

  const getComponent = () => {
    let component
    switch (question.questionType) {
      case 'Text - Single':
        component = InputField
        break
      case 'Text - Multiple':
        component = TextField
        break
      case 'Numeric - INT':
        component = InputNumberField
        break
      case 'Group Numeric - INT':
        component = InputNumberField
        break
      case 'Static Text':
        component = StaticText
        break
      case 'Boolean':
        component = BooleanField
        break
      case 'Radio Button':
        component = RadioField
        break
      case 'Checkbox':
        component = CheckboxField
        break
      case 'Dropdown':
        component = DropdownField
        break
      case 'Link List':
        component = LinkListField
        break
      case 'Date':
        component = DateField
        break
      default:
        component = 'div'
    }
    return component
  }
  const Component = getComponent()

  const style = question.questionType.toUpperCase().includes('GROUP')
    ? { style: { width: '50%' } }
    : {}

  const error = errors[question.shortName]
    ? { help: errors[question.shortName], validateStatus: 'error' }
    : {}

  return (
    <Form.Item
      className="Label"
      label={question.name}
      {...style}
      required={question.isRequired}
      {...error}
    >
      <Component question={question} />
    </Form.Item>
  )
}

FormField.propTypes = {
  question: PropTypes.object,
}

export default FormField
