import * as React from 'react'
import PropTypes from 'prop-types'
import { Col, Divider, Input, Row, message } from 'antd'
import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SEARCH_LABEL } from '~/constants/discover'
import { Free } from '~/constants/tiers'
import { usePrefetchDiscoverLists } from '~/hooks/discoverLists'
import useArrayMemo from '~/hooks/useArrayMemo'
import useLiveRef from '~/hooks/useLiveRef'
import useScrollToTop from '~/hooks/useScrollToTop'
import useSearchParams from '~/hooks/useSearchParams'
import { getTier, getUserId } from '~/selectors/auth'
import {
  generateFilterQueryParams,
  generateQuery,
  getActiveFiltersFromQuery,
} from '~/utils/discover'
import DiscoverResultsHeaderRow from '../DiscoverFilters/DiscoverResultsHeaderRow'
import FilterCountLabel from '../DiscoverFilters/FilterCountLabel'
import { Provider } from './context'
import FilterBlurPanel from './FilterBlurPanel'

const { Search } = Input

const DiscoverController = ({
  getResults: getResultsExternal,
  modifyFilterQuery,
  category,
  tab,
  sortFields,
  fundSelect,
  getFilters: getFiltersExternal,
  renderFilters,
  results,
  showTableView = false,
  showMapView = true,
  showDiscoverAlerts = true,
  refetchKeys = undefined,
  onResult,
  clearResult,
  renderModal = () => {},
  defaultSortLabel = 'Matchmaking Score',
  hideListManagement = false,
}) => {
  const tier = useSelector(getTier)

  const [searchParams, setSearchParams] = useSearchParams()

  const [activeTab, setActiveTab] = React.useState('cards')
  const [filters, setFilters] = React.useState([])
  const [searchInput, setSearchInput] = React.useState(null)
  const [meetingStatus, setMeetingStatus] = React.useState(null)

  const getFilters = useLiveRef(getFiltersExternal)
  React.useEffect(() => {
    getFilters
      .current()
      .then((res) => {
        setFilters(res.data.result)
      })
      .catch(() => message.error('Could not retrieve filter data'))
  }, [getFilters])

  const contactId = useSelector(getUserId)

  const updateQueryParams = React.useCallback(
    (updatedFields) => {
      setSearchParams({
        ...searchParams,
        ...updatedFields,
      })
    },
    [setSearchParams, searchParams]
  )

  const resetPage = React.useCallback(
    (updatedFields = {}) => {
      updateQueryParams({ ...updatedFields, page: 1 })
    },
    [updateQueryParams]
  )

  const selectedFilters = useArrayMemo(searchParams.filter)
  const activeFilters = React.useMemo(
    () => getActiveFiltersFromQuery(selectedFilters, filters),
    [selectedFilters, filters]
  )

  const {
    currentPage,
    showOnlyMatched,
    orderBy,
    searchText,
    selectedId,
    pageSize,
  } = React.useMemo(() => {
    const {
      page = 1,
      showMatched = false,
      orderBy = '',
      search = '',
      selectedId,
      size = 10,
    } = searchParams

    setSearchInput(search)
    return {
      currentPage: parseInt(page, 10),
      showOnlyMatched: showMatched === 'true',
      orderBy,
      searchText: search,
      selectedId: parseInt(selectedId, 10),
      pageSize: parseInt(size, 10),
    }
  }, [searchParams])

  const scrollToTop = useScrollToTop('scrollDiv')

  const wasBrowserBack = useHistory().action === 'POP'
  React.useEffect(() => {
    // Use browser's default scroll behavior on back
    if (wasBrowserBack) {
      return
    }

    scrollToTop()
  }, [
    scrollToTop,
    orderBy,
    selectedFilters,
    showOnlyMatched,
    searchText,
    currentPage,
    wasBrowserBack,
  ])

  const generateFilterQuery = React.useCallback(
    (filterParameter) => {
      const filterQuery = filterParameter.map((x) => generateQuery(x))
      modifyFilterQuery?.(filterQuery)
      return filterQuery
    },
    [contactId, modifyFilterQuery]
  )

  const fetchResults = () =>
    getResultsExternal({
      page: currentPage,
      pageSize,
      filter: generateFilterQuery(activeFilters),
      showMatched: showOnlyMatched,
      orderBy,
      searchTerm: searchText,
      meetingStatus,
    })

  const { isLoading, data, isPreviousData } = useQuery(
    [
      'discover',
      category,
      {
        activeFilters,
        showOnlyMatched,
        orderBy,
        searchText,
        meetingStatus,
        activeTab,
        currentPage,
        pageSize,
      },
      refetchKeys,
    ],
    () => fetchResults(),
    {
      keepPreviousData: true,
      onError(error) {
        const msg = isNil(error.response)
          ? get(error, 'message', `Could not retrieve ${category}.`)
          : get(
              error,
              'response.data.message',
              `Could not retrieve ${category}.`
            )
        message.error(msg)
      },
      onSuccess(data) {
        const results = data?.result ?? []
        results.forEach((result) => onResult?.(result))
      },
    }
  )

  const filterResults = data?.result ?? []
  const total = data?.meta.pagination.total ?? 0

  const alertDisabled = isEmpty(activeFilters) && !searchText

  const onTabChange = React.useCallback(
    (e) => {
      setActiveTab(e)
      resetPage()
    },
    [resetPage]
  )

  const loadPage = React.useCallback(
    (page, size) => {
      updateQueryParams({ page, size })
    },
    [updateQueryParams]
  )

  const onToggleMatched = React.useCallback(() => {
    resetPage({ showMatched: !showOnlyMatched })
  }, [resetPage, showOnlyMatched])

  const onChangeSelectedId = React.useCallback(
    (resultId, tabKey) => {
      updateQueryParams({ selectedId: resultId, tabKey })
    },
    [updateQueryParams]
  )

  const searchByText = React.useCallback(
    (text) => {
      resetPage({ search: text })
    },
    [resetPage]
  )

  const onChangeFilters = React.useCallback(
    (value, id, title, type) => {
      const existingFilterIndex = activeFilters.findIndex(
        (x) => x.alertColumnKey.toLowerCase() === id.toLowerCase()
      )
      const filterList = activeFilters
      if (existingFilterIndex > -1) filterList.splice(existingFilterIndex, 1)

      if (id === 'meetingStatus') {
        setMeetingStatus(value)
      }

      const newFilter =
        isEmpty(value) ||
        isNil(value) ||
        isNil(value[0]) ||
        (type === 'Check' && (value[0] === false || value[0] === 'false'))
          ? [...filterList]
          : [
              ...filterList,
              {
                alertColumn: title,
                alertColumnKey: id,
                values: value,
                alertColumnType: type,
              },
            ]
      resetPage({
        filter: generateFilterQueryParams(newFilter),
      })
    },
    [activeFilters, resetPage]
  )

  const setSearchState = React.useCallback(
    (searchFilters, searchText) => {
      const searchQuery = searchFilters
        .filter((x) => !x.isDeleted)
        .map((x) => ({
          alertColumn: x.alertColumn,
          alertColumnKey: x.alertColumnKey,
          values: x.values
            .filter((v) => !v.isDeleted)
            .map((v) => v.filterValue),
          alertColumnType: x.alertColumnType,
        }))
      resetPage({
        filter: generateFilterQueryParams(searchQuery),
        search: searchText,
        showMatched: false,
      })
    },
    [resetPage]
  )

  React.useEffect(() => {
    tab && setActiveTab(tab)
  }, [tab])

  usePrefetchDiscoverLists()

  const clearFilters = React.useCallback(() => {
    setSearchParams({}, { merge: false })
    clearResult?.()
  }, [clearResult, setSearchParams])

  const handleSort = (field, order) => {
    const newOrder = `${order === 'desc' ? '-' : ''}${field}`
    resetPage({ orderBy: newOrder })
  }

  const handleSortReset = () => {
    resetPage({ orderBy: null })
  }

  const context = useCreateContext({
    filterResults,
    activeFilters,
    onChangeFilters,
    showOnlyMatched,
    onToggleMatched,
    searchText,
    activeTab,
    meetingStatus,
    category,
    loading: isLoading || isPreviousData,
    currentPage,
    total,
    loadPage,
    pageSize,
    selectedId,
    onChangeSelectedId,
  })

  return (
    <Provider value={context}>
      <Row type="flex" style={{ alignItems: 'baseline', margin: '4px 0' }}>
        <Col span={4} className="DiscoverContainer-col-filters">
          {fundSelect}
          <Row type="flex" style={{ marginLeft: 10 }}>
            <FilterCountLabel
              filters={activeFilters}
              clearFilters={clearFilters}
              hasSelectedRecord={!isEmpty(refetchKeys?.resultId)}
            />
          </Row>
        </Col>

        <Col
          span={20}
          className="w-full text-center flex flex-row justify-center"
        >
          <div className="w-full">
            <DiscoverResultsHeaderRow
              total={total}
              showDiscoverAlerts={showDiscoverAlerts}
              alertDisabled={alertDisabled}
              filter={activeFilters}
              searchTerm={searchText}
              setSearchState={setSearchState}
              category={camelCase(category)}
              sortFields={sortFields}
              sortOrder={orderBy}
              handleSort={handleSort}
              handleSortReset={handleSortReset}
              defaultSortLabel={defaultSortLabel}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onTabChange={onTabChange}
              includeTableView={showTableView}
              includeMapView={showMapView}
              hideListManagement={hideListManagement}
            />
          </div>
        </Col>
      </Row>
      <div className="flex flex-row items-stretch">
        <Col span={4} className="DiscoverContainer-col-filters">
          <FilterBlurPanel blurred={tier === Free}>
            <Search
              className="DiscoverContainer-col-filters-search"
              placeholder={SEARCH_LABEL}
              value={searchInput}
              allowClear={true}
              size="middle"
              onChange={(e) => setSearchInput(e.target.value)}
              onSearch={searchByText}
            />
            <Divider />
            {renderFilters && renderFilters({ filters })}
          </FilterBlurPanel>
        </Col>
        <Col
          span={20}
          className="w-full text-center flex flex-row justify-center"
        >
          {!isNil(selectedId) &&
            renderModal(filterResults, selectedId, onChangeSelectedId)}
          {results}
        </Col>
      </div>
    </Provider>
  )
}

DiscoverController.propTypes = {
  getResults: PropTypes.func.isRequired,
  modifyFilterQuery: PropTypes.func,
  category: PropTypes.string.isRequired,
  tab: PropTypes.string,
  sortFields: PropTypes.array,
  fundSelect: PropTypes.node,
  getFilters: PropTypes.func.isRequired,
  renderFilters: PropTypes.func,
  results: PropTypes.node,
  showTableView: PropTypes.bool,
  showMapView: PropTypes.bool,
  showDiscoverAlerts: PropTypes.bool,
  refetchKeys: PropTypes.any,
  onResult: PropTypes.func,
  clearResult: PropTypes.func,
  renderModal: PropTypes.func,
  defaultSortLabel: PropTypes.string,
  hideListManagement: PropTypes.bool,
}

export default DiscoverController

function useCreateContext(context) {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- calculating the dependency array for brevity
  return React.useMemo(() => context, getMemoDependencies(context))
}

function getMemoDependencies(obj) {
  return Object.keys(obj)
    .sort()
    .map((key) => obj[key])
}
