import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faBell,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarEdit,
  faCalendarExclamation,
  faCalendarMinus,
  faCalendarPlus,
  faCheck,
  faEnvelope,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import cx from 'classnames'
import format from 'date-fns/format'
import get from 'lodash/get'
import has from 'lodash/has'
import isNil from 'lodash/isNil'
import startsWith from 'lodash/startsWith'
import { useHistory } from 'react-router-dom'
import AlertNotificationModal from '../AlertNotificationModal'
import './NotificationEntry.less'

const NotificationEntry = ({
  notification,
  onMark,
  onItemClick,
  fullScreen = false,
}) => {
  const { push } = useHistory()
  const [showAlertModal, setShowAlertModal] = useState(false)
  const iconMap = {
    MeetingRequested: faCalendarAlt,
    MeetingAccepted: faCalendarCheck,
    MeetingUpdated: faCalendarEdit,
    MeetingCancelled: faCalendarExclamation,
    MeetingDeclined: faCalendarMinus,
    MeetingScheduled: faCalendarDay,
    MeetingAutomaticallyAccepted: faCalendarPlus,
    NewMessage: faEnvelope,
    NewAlert: faBell,
    OnlineMeetingRequested: faCalendarAlt,
    OnlineMeetingConfirmed: faCalendarCheck,
    OnlineMeetingUpdated: faCalendarEdit,
    OnlineMeetingCancelled: faCalendarExclamation,
    OnlineMeetingDeclined: faCalendarMinus,
    OnlineMeetingScheduled: faCalendarDay,
    PresentationRegistrationApproved: faCalendarCheck,
    PresentationInvitationForUser: faCalendarAlt,
    PresentationStarted: faCalendarExclamation,
    NewAllocatorConnectionRequest: faCheck,
    NewManagerConnectionRequest: faCheck,
    AcceptedConnectionRequest: faUsers,
  }

  const icon = get(iconMap, notification.notificationType, faEnvelope)

  const handleClick = useCallback(
    (notificationLogId, type = null, url = null) => {
      if (!isNil(onMark)) {
        onMark(notificationLogId)
      }
      if (!isNil(url)) {
        push(url, {
          isReceived: type.includes('MeetingRequested'),
        })
      }
    },
    [onMark, push]
  )

  const getBody = () => {
    const defaultBody = (
      <>
        <span className="NotificationEntry-subject">{notification.title}</span>
        <span>{notification.body}</span>
        {notification.notificationDateTime && (
          <div className="type-body-regular-xs">
            {format(new Date(notification.notificationDateTime), 'MMMM d yyyy')}
          </div>
        )}
      </>
    )

    if (startsWith(notification.notificationType, 'MeetingIn5')) {
      const url = '/meeting-lobby'
      return (
        <div
          className="NotificationEntry-description-wrapper"
          onClick={() =>
            handleClick(
              notification.notificationLogId,
              notification.notificationType,
              url
            )
          }
        >
          {defaultBody}
        </div>
      )
    }
    if (
      has(notification, 'data.type') &&
      (has(notification, 'data.meetingId') ||
        has(notification, 'data.conversationId'))
    ) {
      const { type, meetingId, conversationId } = notification.data

      const url = startsWith(type, 'Meeting')
        ? `/meeting/summit/${meetingId}`
        : startsWith(type, 'OnlineMeeting') ||
          startsWith(type, 'OnlineParticipationChanged')
        ? `/meeting/online/${meetingId}`
        : notification.isArchived === true
        ? `/messages/${conversationId}/true`
        : `/messages/${conversationId}`

      return (
        <div
          className="NotificationEntry-description-wrapper"
          onClick={() => handleClick(notification.notificationLogId, type, url)}
        >
          {defaultBody}
        </div>
      )
    }

    if (notification.notificationType === 'NewAlert') {
      return (
        <div
          className="NotificationEntry-description-wrapper"
          onClick={() => {
            setShowAlertModal(true)
            onItemClick()
          }}
        >
          {defaultBody}
        </div>
      )
    }

    if (has(notification, 'data.presentationId')) {
      return (
        <div
          className="NotificationEntry-description-wrapper"
          onKeyPress={() =>
            handleClick(
              notification.notificationLogId,
              notification.notificationType,
              `/community/presentations/${notification.data.presentationId}`
            )
          }
          onClick={() =>
            handleClick(
              notification.notificationLogId,
              notification.notificationType,
              `/community/presentations/${notification.data.presentationId}`
            )
          }
        >
          {defaultBody}
        </div>
      )
    }

    if (
      (notification.notificationType === 'NewCampaignInterest' ||
        notification.notificationType === 'CampaignApplicationStatusUpdated') &&
      has(notification, 'data.toCampaignId') &&
      has(notification, 'data.fromCampaignId') &&
      has(notification, 'data.campaignApplicationId')
    ) {
      const notifiedCampaignId =
        notification.notificationType === 'NewCampaignInterest'
          ? notification.data.toCampaignId
          : notification.data.fromCampaignId
      const isSentParam =
        notification.notificationType === 'CampaignApplicationStatusUpdated'
          ? '&isSent=true'
          : ''
      return (
        <div
          className="NotificationEntry-description-wrapper"
          onClick={() =>
            handleClick(
              notification.notificationLogId,
              notification.notificationType,
              `/workspace/${notifiedCampaignId}/pipeline?applicationId=${notification.data.campaignApplicationId}${isSentParam}`
            )
          }
        >
          {defaultBody}
        </div>
      )
    }

    if (
      notification.notificationType === 'NewAllocatorConnectionRequest' ||
      notification.notificationType === 'NewManagerConnectionRequest'
    ) {
      return (
        <div
          className="NotificationEntry-description-wrapper"
          onClick={() => {
            handleClick(
              notification.notificationLogId,
              notification.notificationType,
              '/community/requests'
            )
          }}
        >
          {defaultBody}
        </div>
      )
    }

    if (notification.notificationType === 'AcceptedConnectionRequest') {
      return (
        <div
          className="NotificationEntry-description-wrapper"
          onClick={() => {
            handleClick(
              notification.notificationLogId,
              notification.notificationType,
              '/community/directory?filter=myconnections%3Dtrue'
            )
          }}
        >
          {defaultBody}
        </div>
      )
    }

    return defaultBody
  }

  let entryIcon = (
    <Tooltip title="Mark this notification as read.">
      <Button onClick={() => handleClick(notification.notificationLogId)}>
        <FontAwesomeIcon icon={icon} size="2x" />
      </Button>
    </Tooltip>
  )
  if (notification.isRead) {
    entryIcon = <FontAwesomeIcon icon={icon} size="2x" />
  }

  const handleAlertModalClose = () => {
    setShowAlertModal(false)
  }

  return (
    <div
      className={cx(
        'NotificationEntry flex flex-row items-center my-2',
        !fullScreen && 'w-80',
        notification.isRead && 'NotificationEntry-read'
      )}
    >
      <div className="NotificationEntry-icon">{entryIcon}</div>
      <div className="NotificationEntry-description">{getBody()}</div>
      <AlertNotificationModal
        visible={showAlertModal}
        closeModal={handleAlertModalClose}
        notification={notification}
      />
    </div>
  )
}

NotificationEntry.propTypes = {
  notification: PropTypes.shape({
    notificationLogId: PropTypes.number.isRequired,
    notificationSubscriptionId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    data: PropTypes.shape({
      type: PropTypes.string,
      meetingId: PropTypes.string,
      meetingDateTime: PropTypes.string,
      conversationId: PropTypes.string,
      presentationId: PropTypes.string,
      toCampaignId: PropTypes.number,
      fromCampaignId: PropTypes.number,
      campaignApplicationId: PropTypes.number,
    }),
    notificationType: PropTypes.string.isRequired,
    isRead: PropTypes.bool.isRequired,
    isArchived: PropTypes.bool.isRequired,
    notificationDateTime: PropTypes.string,
  }).isRequired,
  onMark: PropTypes.func,
  onItemClick: PropTypes.func,
  fullScreen: PropTypes.bool,
}

export default NotificationEntry
