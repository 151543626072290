import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faMicrophone,
  faMicrophoneSlash,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { detect } from 'detect-browser'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import Ratio from 'react-ratio'
import Loading from '../../Loading'
import ProfilePictureGroup from '../../ProfilePictureGroup'
import NetworkInicator from '../NetworkIndicator/NetworkIndicator'
import './RemoteParticipant.less'

const browser = detect()

const Participant = ({
  local = false,
  participant,
  contact,
  defaultAudioOutput,
  isScreenShared,
  isDialIn,
}) => {
  const videoRef = useRef()
  const audioRef = useRef()
  const [contactParticipant, setContactParticipant] = useState(null)
  const [videoTrack, setVideoTrack] = useState(null)
  const [audioTrack, setAudioTrack] = useState(null)
  const [trackLoading, setTrackLoading] = useState(false)
  useEffect(() => {
    const getParticipantsName = () =>
      `${get(contact, 'firstName')} ${get(contact, 'lastName')}`
    const getParticipantsImage = () => `${get(contact, 'imageUrl')}`
    const getParticipantsTitle = () => `${get(contact, 'jobTitle')}`
    const getParticipantCompanyName = () => `${get(contact, 'companyName')}`

    local &&
      participant.on('trackPublished', (track) =>
        track.kind === 'video'
          ? setVideoTrack(track.track)
          : setAudioTrack(track.track)
      )
    local &&
      participant.on('trackStopped', (track) => {
        track.kind === 'video' ? setVideoTrack(null) : setAudioTrack(null)
      })

    isEmpty(participant.videoTracks) && setVideoTrack(null)
    isEmpty(participant.audioTracks) && setAudioTrack(null)

    participant.videoTracks.forEach((videoTracks) => {
      videoTracks && videoTracks.track && setVideoTrack(videoTracks.track)
    })
    participant.audioTracks.forEach((audioTracks) => {
      audioTracks && audioTracks.track && setAudioTrack(audioTracks.track)
    })

    !local &&
      participant.on('trackUnsubscribed', (track) =>
        track.kind === 'video' ? setVideoTrack(null) : setAudioTrack(null)
      )

    !local &&
      participant.on('trackSubscribed', (track) =>
        track.kind === 'video' ? setVideoTrack(track) : setAudioTrack(track)
      )

    participant.on('reconnecting', () => setTrackLoading(true))
    participant.on('reconnected', () => setTrackLoading(false))
    const name = contact && getParticipantsName()
    const image = contact && getParticipantsImage()
    const title = contact && getParticipantsTitle()
    const companyName = contact && getParticipantCompanyName()
    contact && setContactParticipant({ name, image, title, companyName })
  }, [participant, local, contact])

  useEffect(() => {
    videoTrack && videoTrack.attach(videoRef.current)
  }, [videoTrack])

  useEffect(() => {
    if (browser.name === 'chrome' || browser.name === 'edge-chromium') {
      audioRef.current = audioTrack && audioTrack.attach()
      audioRef.current && audioRef.current.setSinkId(defaultAudioOutput)
    } else {
      audioTrack && audioTrack.attach(audioRef.current)
    }
  }, [audioTrack, defaultAudioOutput])

  return (
    participant.state === 'connected' &&
    contactParticipant && (
      <div className="cc-participant">
        <div className="cc-participant-small">
          <Loading spinning={trackLoading}>
            <Ratio ratio={4 / 3}>
              <video
                className={`cc-participant-video-small cc-participant-video-small-on ${
                  (isNil(videoTrack) ||
                    (local && isScreenShared) ||
                    videoTrack.name === 'screen') &&
                  'cc-participant-video-small-none'
                }`}
                ref={videoRef}
                autoPlay={true}
              />
              <div className="cc-participant-video-small cc-participant-video-small-off">
                <ProfilePictureGroup
                  diameter={40}
                  profiles={[
                    {
                      name: contactParticipant.name,
                      firstName: contactParticipant.name.split(' ')[0],
                      lastName: contactParticipant.name.split(' ')[1],
                      imageUrl: contactParticipant.image,
                      image: contactParticipant.image,
                    },
                  ]}
                />
              </div>
              <div className="sidebar-network-wrapper">
                <NetworkInicator participant={participant} dominant={false} />
              </div>
            </Ratio>
          </Loading>
          <audio ref={audioRef} autoPlay={true} />
        </div>
        <div className="cc-participant-identity">
          <div className="cc-participant-identity-name">
            {contactParticipant.name}
            {local && ' (You)'}
            {isDialIn && ' (Phone)'}
          </div>

          <div className="cc-participant-identity-title">
            {contactParticipant.title}
          </div>
          <div className="cc-participant-identity-company">
            {contactParticipant.companyName}
          </div>
        </div>
        <div className="cc-participant-audio">
          {!isNil(audioTrack) ? (
            <FontAwesomeIcon icon={faMicrophone} size="lg" />
          ) : (
            <FontAwesomeIcon icon={faMicrophoneSlash} size="lg" />
          )}
        </div>
      </div>
    )
  )
}

Participant.propTypes = {
  participant: PropTypes.shape({
    identity: PropTypes.string.isRequired,
    sid: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    removeAllListeners: PropTypes.func.isRequired,
    videoTracks: PropTypes.object.isRequired,
    audioTracks: PropTypes.object.isRequired,
    on: PropTypes.func.isRequired,
    networkQualityLevel: PropTypes.number,
    networkQualityStats: PropTypes.object,
  }).isRequired,
  contact: PropTypes.object.isRequired,
  defaultAudioOutput: PropTypes.string,
  local: PropTypes.bool.isRequired,
  isScreenShared: PropTypes.bool.isRequired,
  isDialIn: PropTypes.bool,
}
export default Participant
