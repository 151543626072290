import { Button } from '@context365/button'
import { Launch } from '@context365/icons'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import * as api from '~/api'
import WidgetBlock from './WidgetBlock'

const NewFunds = () => {
  const { data = {} } = useQuery(
    'dashboardNewFunds',
    () =>
      api.discovery.funds({
        page: 1,
        pageSize: 5,
        orderBy: '-fundId',
      }),
    {
      keepPreviousData: true,
    }
  )

  const funds = data.result ?? []

  return (
    <WidgetBlock
      headerLeft="New funds"
      headerRight={
        <Button
          variant="link"
          as={Link}
          to="/discover/funds"
          iconRight={<Launch />}
          style={{ padding: 0 }}
          status="secondary"
        >
          See All
        </Button>
      }
      helpContent="The newest funds added to the platform."
    >
      <div className="flex flex-col gap-4">
        {isEmpty(funds) ? (
          <div>No funds have been added recently.</div>
        ) : (
          <div className="divide-y -my-3">
            {map(funds, (fund) => (
              <div
                key={fund.fundId}
                className="flex flex-row justify-between py-3"
              >
                <div className="flex flex-col gap-1">
                  <div className="type-subtitle-xs">{fund.name}</div>
                  <div className="type-body-regular-xs">
                    <b>Company: </b>
                    {fund.company.name}
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button variant="link" as={Link} to={`/fund/${fund.fundId}`}>
                    View Fund
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </WidgetBlock>
  )
}

export default NewFunds
