import { useContext, useEffect, useState } from 'react'
import { Button } from '@context365/button'
import { AddOutlined, ListAlt } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import {
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  TimePicker,
} from 'antd'
import { useFormikContext } from 'formik'
import map from 'lodash/map'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getCompanyFunds } from '~/actions/discover'
import * as api from '~/api'
import { getCompanyId } from '~/selectors/auth'
import PresentationWizardCtx from '~/utils/contexts/PresentationWizardCtx'
import NoData from '../../NoData'
import Timezone from '../../SelectTimezoneComponent/SelectTimezoneComponent'

const { Option } = Select

const PresentationDetails = () => {
  const {
    timezone,
    setTimezone,
    date,
    setDate,
    duration,
    setDuration,
    time,
    setTime,
    title,
    setTitle,
    description,
    setDescription,
    isPublished,
    presentationType,
    fund,
    setFund,
    setFundId,
    deal,
    setDeal,
    setDealId,
    isEdit,
    startTime,
  } = useContext(PresentationWizardCtx)
  const companyId = useSelector(getCompanyId)
  const role = useSelector((state) => state.auth.role)
  const presentationTypeId = presentationType
  const { errors, validateField, setFieldValue } = useFormikContext()
  const [funds, setFunds] = useState([])
  const [deals, setDeals] = useState([])
  const [durationOptions, setDurationOptions] = useState([30, 60, 90, 120])
  const [customDuration, setCustomDuration] = useState(0)
  const [isTimeChanged, setIsTimeChanged] = useState(false)

  const handleValueSet = (shortName, value) =>
    Promise.resolve(setFieldValue(shortName, value)).then(() =>
      validateField(shortName)
    )
  const isTimeChangeDisabled =
    isPublished &&
    !isTimeChanged &&
    moment.duration(moment.utc(startTime).diff(moment.utc())).asHours() < 24

  const dateSelected = () =>
    date === '' ? { value: '' } : { value: moment(date) }

  const timeSelected = () =>
    time === '' ? { value: '' } : { value: moment(time, 'h:mm A') }

  const error = (shortName) =>
    errors[shortName]
      ? { help: errors[shortName], validateStatus: 'error' }
      : {}

  useEffect(() => {
    if (role.toLowerCase() === 'manager' && presentationTypeId === 2)
      getCompanyFunds(companyId).then((response) => {
        setFunds(response.data.result)
      })
  }, [companyId, presentationTypeId, role])

  useEffect(() => {
    if (
      (role.toLowerCase() === 'cfn' ||
        role.toLowerCase() === 'service provider') &&
      presentationTypeId === 3
    )
      api.deals.getMyLiveDeals().then((response) => {
        setDeals(response.data.result)
      })
  }, [companyId, presentationTypeId, role])

  const handleCustomDurationAdded = () => {
    setDurationOptions([...durationOptions, customDuration])
    setCustomDuration(0)
  }

  const handleDurationChanged = (duration) => {
    setDuration(duration)
    handleValueSet('duration', duration)
  }

  const handleTimezoneChanged = (timezone) => {
    setTimezone(timezone)
    setIsTimeChanged(true)
  }

  const handleCustomDurationChanged = (duration) => {
    setCustomDuration(duration)
  }

  return (
    <Form className="ContextForm" layout="vertical">
      <Form.Item
        className="Label text-grey-800 text-md"
        label="Date and time"
        required
      >
        <Tooltip
          text="Date and time cannot be edited 24 hours before presentation starts."
          className={isTimeChangeDisabled ? '' : 'hidden'}
        >
          <div className="flex justify-between">
            <div className="w-80 mr-6">
              <Timezone
                isDisabled={isTimeChangeDisabled}
                value={timezone}
                handleTimezoneChange={handleTimezoneChanged}
              />
            </div>

            <div className="flex">
              <Form.Item {...error('date')}>
                <DatePicker
                  className="mr-4 w-52 cc-shadow-box text-md h-12"
                  onChange={(_, dateString) => {
                    setDate(dateString)
                    handleValueSet('date', dateString)
                    setIsTimeChanged(true)
                  }}
                  disabled={isTimeChangeDisabled}
                  disabledDate={(current) => {
                    return isEdit
                      ? moment(current).diff(moment.utc().tz(timezone), 'h') <=
                          24
                      : current < moment().startOf('day')
                  }}
                  {...dateSelected()}
                />
              </Form.Item>
              <Form.Item {...error('time')}>
                <TimePicker
                  className="cc-shadow-box text-md h-12"
                  disabled={isTimeChangeDisabled}
                  format="h:mm A"
                  use12Hours
                  minuteStep={15}
                  popupClassName="timepicker"
                  onSelect={(value) => {
                    const timeString = moment(value).format('h:mm A')
                    setTime(timeString)
                    handleValueSet('time', timeString)
                    setIsTimeChanged(true)
                  }}
                  onChange={(_, timeString) => {
                    setTime(timeString)
                    handleValueSet('time', timeString)
                  }}
                  {...timeSelected()}
                />
              </Form.Item>
              <Form.Item {...error('duration')}>
                <Select
                  disabled={isTimeChangeDisabled}
                  style={{ width: 170 }}
                  allowClear={true}
                  className="cc-shadow-box SelectCompanyUsers"
                  placeholder="Select duration"
                  optionFilterProp="value"
                  defaultValue={`${duration} minutes`}
                  onChange={handleDurationChanged}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          padding: 8,
                        }}
                      >
                        <InputNumber
                          style={{ flex: 'auto' }}
                          value={customDuration}
                          onChange={handleCustomDurationChanged}
                          min={0}
                        />
                        <Button
                          className="p-0"
                          variant="link"
                          onClick={handleCustomDurationAdded}
                          disabled={customDuration === 0}
                        >
                          <AddOutlined /> Add custom
                        </Button>
                      </div>
                    </div>
                  )}
                >
                  {durationOptions.map((item) => (
                    <Option key={item}>{item} minutes</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        </Tooltip>
      </Form.Item>
      {presentationTypeId === 2 && role.toLowerCase() === 'manager' && (
        <Form.Item
          className="Label text-md text-grey-800"
          label="Fund"
          //required
          {...error('fund')}
        >
          <Select
            disabled={isPublished}
            placeholder="Select a fund"
            defaultValue={fund}
            notFoundContent={
              <NoData title="No available funds" icon={<ListAlt size={48} />} />
            }
            className="select"
            onChange={(e, value) => {
              setFund(e)
              setFundId(value.key)
              handleValueSet('fundId', value.key)
              handleValueSet('fund', e)
            }}
          >
            {funds &&
              map(funds, (f) => (
                <Select.Option
                  key={f.fundId}
                  value={f.name}
                  style={{
                    height: '48px',
                  }}
                >
                  {f.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      )}
      {presentationTypeId === 3 &&
        (role.toLowerCase() === 'cfn' ||
          role.toLowerCase() === 'service provider') && (
          <Form.Item
            className="Label text-md text-grey-800"
            label="Deal"
            //required
            {...error('deal')}
          >
            <Select
              disabled={isPublished}
              placeholder="Select a deal"
              defaultValue={deal}
              notFoundContent={
                <NoData
                  title="No available deals"
                  icon={<ListAlt size={48} />}
                />
              }
              className="select"
              onChange={(e, value) => {
                setDeal(e)
                setDealId(value.key)
                handleValueSet('dealId', value.key)
                handleValueSet('deal', e)
              }}
            >
              {deals &&
                map(deals, (f) => (
                  <Select.Option key={f.dealId} value={f.title}>
                    {f.title}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}
      <Form.Item
        className="Label text-md text-grey-800"
        label="Title"
        required
        {...error('title')}
      >
        <Input
          className="cc-shadow-box text-md h-12"
          placeholder="Enter title"
          defaultValue={title}
          onChange={(e) => {
            setTitle(e.target.value)
            handleValueSet('title', e.target.value)
          }}
        />
      </Form.Item>
      <Form.Item
        className="Label text-md text-grey-800"
        label="Description"
        required
        {...error('description')}
      >
        <Input.TextArea
          className="cc-shadow-box text-md h-12"
          placeholder="Enter description"
          autoSize={{ minRows: 8 }}
          defaultValue={description}
          onChange={(e) => {
            setDescription(e.target.value)
            handleValueSet('description', e.target.value)
          }}
        />
      </Form.Item>
    </Form>
  )
}

export default PresentationDetails
