import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDiscoverLists } from '~/actions/discoverLists'
import * as api from '~/api'
import { getUserCompanyContactId } from '~/selectors/auth'

export function usePrefetchDiscoverLists(visible = true) {
  const companyContactId = useSelector(getUserCompanyContactId)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (visible) {
      dispatch(fetchDiscoverLists())
    }
  }, [dispatch, companyContactId, visible])
}

export function useDiscoverLists() {
  const dispatch = useDispatch()
  const discoverLists = useSelector((state) => state.discoverLists ?? [])

  return {
    discoverLists,
    addDiscoverListLink: React.useCallback(
      (listId, listLinkTargets) => {
        return api.discoverLists
          .addDiscoverListLink(listId, listLinkTargets)
          .then(() => {
            dispatch(fetchDiscoverLists())
          })
      },
      [dispatch]
    ),
    createDiscoverList: React.useCallback(
      (name, listLinkTargets) => {
        return api.discoverLists
          .createDiscoverList(name, listLinkTargets)
          .then(() => {
            dispatch(fetchDiscoverLists())
          })
      },
      [dispatch]
    ),
    deleteDiscoverList: React.useCallback(
      (listId) => {
        return api.discoverLists.deleteDiscoverList(listId).then(() => {
          dispatch(fetchDiscoverLists())
        })
      },
      [dispatch]
    ),
    toggleDiscoverListLink: React.useCallback(
      (listId, listLinkTargets) => {
        return api.discoverLists
          .toggleDiscoverListLink(listId, listLinkTargets)
          .then(() => {
            dispatch(fetchDiscoverLists())
          })
      },
      [dispatch]
    ),
    shareDiscoverList: React.useCallback(
      (listId, targetContact) => {
        return api.discoverLists
          .shareDiscoverList(listId, targetContact)
          .then(() => {
            dispatch(fetchDiscoverLists())
          })
      },
      [dispatch]
    ),
    revokeDiscoverListAccess: React.useCallback(
      (listId, targetCompanyContactId) => {
        return api.discoverLists
          .revokeDiscoverListAccess(listId, targetCompanyContactId)
          .then(() => {
            dispatch(fetchDiscoverLists())
          })
      },
      [dispatch]
    ),
  }
}
