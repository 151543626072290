import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'

const ArrayField = ({
  label,
  name,
  value,
  errors = [],
  setFieldValue,
  subComponent,
  subProps,
  defaultObject,
  allowEmpty = false,
  emptyAddText = '+ Add',
}) => {
  const SubField = subComponent

  const [arrayValue, setArrayValue] = useState(
    isEmpty(value) ? (allowEmpty ? [] : [defaultObject]) : value
  )

  const removeItem = (index) => {
    const arr = arrayValue.slice()
    arr.splice(index, 1)
    setArrayValue(arr)
    setFieldValue(name, arr)
  }

  const handleChange = (newVal, index) => {
    if (isNil(newVal) && index === 0) {
      setArrayValue([defaultObject])
      setFieldValue(name, [])
    } else if (isNil(newVal) && index > 0) {
      removeItem(index)
    } else {
      const arr = arrayValue.slice()
      arr[index] = newVal
      setArrayValue(arr)
      setFieldValue(name, arr)
    }
  }

  const addItem = (item) => {
    const arr = arrayValue.slice()
    isNil(item) ? arr.push({ ...defaultObject }) : arr.push({ ...item })
    setArrayValue(arr)
  }

  return (
    <>
      {map(arrayValue, (val, i) => (
        <SubField
          key={i}
          listNumber={i + 1}
          className="Question-array-field"
          label={label}
          removeItem={() => removeItem(i)}
          addItem={addItem}
          onChange={(newVal) => handleChange(newVal, i)}
          showRemove={allowEmpty || i > 0}
          showAdd={i === arrayValue.length - 1}
          value={val}
          errors={errors[i]}
          isArray
          {...subProps}
        />
      ))}
      {allowEmpty && isEmpty(arrayValue) && (
        <div className="text-left">
          <Button variant="link" onClick={() => addItem()}>
            {emptyAddText}
          </Button>
        </div>
      )}
    </>
  )
}

ArrayField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  errors: PropTypes.array,
  setFieldValue: PropTypes.func,
  getOptionsMethod: PropTypes.func,
  mapOptions: PropTypes.func,
  defaultObject: PropTypes.object,
  subComponent: PropTypes.node,
  subProps: PropTypes.object,
  allowEmpty: PropTypes.bool,
  emptyAddText: PropTypes.string,
}

export default ArrayField
