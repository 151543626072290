import PropTypes from 'prop-types'
import moment from 'moment'
import { MEETING_TYPE } from '~/constants/meetingTypeIds'
import { getFormatedMeetingTime } from '~/utils/meetingActions'

const MeetingDateTime = ({ meeting }) => (
  <div>
    <span className="type-subtitle-sm">
      {meeting.meetingTime ? 'Date and Time' : ''}
    </span>
    <div className="type-body-regular-sm text-body my-2">
      {meeting.meetingTime
        ? meeting.meetingTypeId === MEETING_TYPE.SUMMIT
          ? getFormatedMeetingTime(
              meeting.timeZone,
              meeting.virtualSummit || meeting.isVirtual,
              meeting.meetingTime.meetingDateTime,
              'MMM DD, YYYY [at] h:mm A'
            )
          : moment
              .utc(meeting.meetingTime.meetingDateTime)
              .local()
              .format('MMM DD, YYYY [at] h:mm A')
        : meeting.hasEventEnded
        ? 'The event has ended'
        : ''}
    </div>
  </div>
)

MeetingDateTime.propTypes = {
  meeting: PropTypes.object,
}
export default MeetingDateTime
