import isNil from 'lodash/isNil'
import BooleanFilter from './Controls/BooleanFilter'
import CheckboxFilter from './Controls/CheckboxFilter'
import CheckboxGroupFilter from './Controls/CheckboxGroupFilter'
import ContextSignalFilter from './Controls/ContextSignalFilter'
import RangeFilter from './Controls/RangeFilter'

export default function getFilterComponent({ item, header, ...props }) {
  switch (item.questionType) {
    case 'dropdown':
    case 'dropdownDiscoverLists':
      return (
        <div id={isNil(item.field) ? 'dropdown' : `${item.field}_dropdown`}>
          {header}
          <CheckboxGroupFilter
            className="DiscoverFilters-FilterCheckbox--stacked"
            options={item.values}
            id={item.field}
            label={item.name}
            group={item.questionGroup}
            showClearAll
            {...props}
          />
        </div>
      )
    case 'range':
      return (
        <div>
          {header}
          <RangeFilter
            id={item.field}
            label={item.name}
            formatter={(value) =>
              value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            group={item.questionGroup}
            percentage={false}
            {...props}
          />
        </div>
      )
    case 'bool':
      return (
        <div>
          {header}
          <BooleanFilter
            className="DiscoverFilters-FilterCheckbox--stacked"
            id={item.field}
            label={item.name}
            group={item.questionGroup}
            formattedValues={[
              { label: item.trueLabel || 'Yes', value: 'true' },
              { label: item.falseLabel || 'No', value: 'false' },
            ]}
            {...props}
          />
        </div>
      )
    case 'checkbox':
      if (
        item.questionGroup === 'ApexInvest Signal' ||
        item.questionGroup === 'Trending'
      ) {
        return (
          <ContextSignalFilter
            id={item.field}
            label={item.name}
            group={item.questionGroup}
            {...props}
          />
        )
      } else {
        return (
          <CheckboxFilter
            id={item.field}
            label={item.name}
            group={item.questionGroup}
            {...props}
          />
        )
      }
    default:
      return null
  }
}
