import { api } from './services'

export const getIsIncompleteProfile = () =>
  api.get('/companies/incomplete/profile')

export const getCfnProfile = (companyId) =>
  api.get(`/family-network/company/${companyId}`)

export const getCompanyDetails = (companyId) =>
  api.get(`/companies/${companyId}`).then((res) => res.data.result)

export const getColleagues = (companyId) =>
  getCompanyDetails(companyId).then((res) =>
    res.contacts.map((c) => ({ ...c, companyId }))
  )

export const getCompanyProfileAnswers = () =>
  api.get('companies/profilewizard').then((response) => response.data.result)

export const getCompanyProfileOptions = () =>
  api
    .get('companies/profilewizard/options')
    .then((response) => response.data.result)

export const saveCompanyProfile = (profile) =>
  api.patch('companies/profile', profile)

export const getCompanyProfile = (companyId) =>
  api.get(`companies/${companyId}/profile`).then((res) => res.data.result)

export const getCompanyFilterOptions = () =>
  api.get('companies/filters').then((response) => response.data.result)
