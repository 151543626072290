import PropTypes from 'prop-types'
import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import './SearchBox.less'

const { Search } = Input

const SearchBox = ({ onSearch, allowClear = false }) => (
  <Search
    className="Search"
    placeholder="Search"
    enterButton={false}
    onSearch={onSearch}
    allowClear={allowClear}
    size="large"
    prefix={<SearchOutlined />}
  />
)

SearchBox.propTypes = {
  onSearch: PropTypes.func.isRequired,
  allowClear: PropTypes.bool,
}

export default SearchBox
