import { Badge } from '@context365/badge'
import { Button } from '@context365/button'
import { ArrowForward } from '@context365/icons'
import cx from 'classnames'
import map from 'lodash/map'
import { useTracking } from 'react-tracking'

const SelectCampaignType = ({
  types,
  onSkip,
  selectedType,
  setSelectedType,
}) => {
  const { trackEvent } = useTracking({ component: 'SelectCampaignType' })

  return (
    <div>
      <div className="type-body-semibold-md text-primary-100 mb-8">
        What type of pipeline would you like to create?
      </div>
      <div className="flex flex-col gap-4">
        {map(types, (type) => (
          <Button
            key={type.value}
            as="div"
            variant="none"
            disabled={type.disabled}
            onClick={() => {
              trackEvent({
                eventName: 'click',
                label: type.name,
                value: type.value,
              })
              setSelectedType(type.value)
            }}
            className={cx(
              'px-4 py-2 text-primary-100 border hover:bg-secondary-2 shadow-6 rounded cursor-pointer transition duration-300 ease-in',
              type.value !== selectedType && 'border-primary-5',
              type.value === selectedType &&
                'border-secondary-100 bg-secondary-2',
              type.disabled && 'bg-grey-300'
            )}
          >
            <div className="flex flex-row justify-between my-4">
              <div className="type-body-semibold-sm">{type.name}</div>
              <Badge>{type.label}</Badge>
            </div>
            <div className="type-body-regular-sm mb-4">{type.subtitle}</div>
            <p className="text-sm leading-relaxed">{type.body}</p>
          </Button>
        ))}
        {onSkip && (
          <Button
            variant="link"
            onClick={() => {
              trackEvent({
                eventName: 'click',
                label: 'Skip creating a campaign',
                value: 'skip',
              })
              onSkip()
            }}
            iconRight={<ArrowForward />}
          >
            Skip creating a campaign and add to my general interest pipeline
          </Button>
        )}
      </div>
    </div>
  )
}

export default SelectCampaignType
