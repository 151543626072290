import PropTypes from 'prop-types'
import { Typography } from 'antd'
import filter from 'lodash/filter'
import join from 'lodash/join'
import take from 'lodash/take'
import { useTracking } from 'react-tracking'
import { formatCurrency } from '~/utils/helpers'
import ContactList from '../ContactList'
import { DiscoverResultCard } from '../Discover'
import { DiscoverCardStats } from '../DiscoverCardStats'
import DiscoverResearchCampaignResultFooter from './DiscoverResearchCampaignResultFooter'
import DiscoverResearchCampaignResultHeader from './DiscoverResearchCampaignResultHeader'

const { Text } = Typography

const getRange = (minAllocationRange, maxAllocationRange) => {
  if (minAllocationRange && maxAllocationRange) {
    return `${formatCurrency(minAllocationRange)} - ${formatCurrency(
      maxAllocationRange
    )}`
  } else if (minAllocationRange) {
    return `${formatCurrency(minAllocationRange)} +`
  } else if (maxAllocationRange) {
    return `Up to ${formatCurrency(maxAllocationRange)}`
  } else {
    return 'Not sure yet'
  }
}

const getDetailItems = (researchCampaign) => {
  const items = [
    {
      title: 'Pipeline Type',
      data: 'Research',
      isVisible: true,
    },
    {
      title: 'Allocation Range',
      data: getRange(
        researchCampaign.minAllocationRange,
        researchCampaign.maxAllocationRange
      ),
      isVisible: true,
    },
    {
      title: 'Vertical',
      data: (
        <Text ellipsis={{ tooltip: true }}>
          {join(researchCampaign.verticalInterests, ', ')}
        </Text>
      ),
      isVisible: researchCampaign.verticalInterests?.length > 0,
    },
    {
      title: 'Sector',
      data: (
        <Text ellipsis={{ tooltip: true }}>
          {join(researchCampaign.industryInterests, ', ')}
        </Text>
      ),
      isVisible: researchCampaign.industryInterests?.length > 0,
    },
    {
      title: 'Geo',
      data: (
        <Text ellipsis={{ tooltip: true }}>
          {join(researchCampaign.geographyInterests, ', ')}
        </Text>
      ),
      isVisible: researchCampaign.geographyInterests?.length > 0,
    },
    {
      title: 'Vehicle',
      data: (
        <Text ellipsis={{ tooltip: true }}>
          {join(researchCampaign.vehicleInterests, ', ')}
        </Text>
      ),
      isVisible: researchCampaign.vehicleInterests?.length > 0,
    },
  ]

  return take(filter(items, 'isVisible'), 8)
}

const DiscoverResearchCampaignResult = ({ researchCampaign }) => {
  const { Track, trackEvent } = useTracking({ page: 'ResearchCampaignCard' })
  if (!researchCampaign) {
    return null
  }
  return (
    <div id="fixed" className="discover-fund">
      <Track>
        <DiscoverResultCard
          className="discover-fund-result"
          href={`/research/${researchCampaign.researchCampaignId}`}
          onClick={() => {
            trackEvent({
              eventName: 'click',
              element: 'Research Campaign Open Profile',
            })
          }}
          header={
            <DiscoverResearchCampaignResultHeader
              researchCampaign={researchCampaign}
            />
          }
          body={
            <>
              <DiscoverCardStats items={getDetailItems(researchCampaign)} />
              {!researchCampaign.isAnonymous && (
                <div className="pt-5 text-xs">
                  <ContactList contacts={researchCampaign.contacts} />
                </div>
              )}
            </>
          }
          footer={
            <DiscoverResearchCampaignResultFooter
              researchCampaign={researchCampaign}
            />
          }
        />
      </Track>
    </div>
  )
}

DiscoverResearchCampaignResult.propTypes = {
  researchCampaign: PropTypes.object.isRequired,
}

export default DiscoverResearchCampaignResult
