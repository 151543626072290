import { Divider } from 'antd'
import CampaignCard from './CampaignCard'
import { formatCurrencyRange } from './utils'

const ResearchCampaign = ({ campaign }) => {
  return (
    <CampaignCard
      campaignId={campaign.campaignId}
      name={campaign.name}
      campaignStatusId={campaign.campaignStatusId}
      newActivityCount={campaign.newActivityCount}
    >
      <div className="grid grid-cols-2 lg:grid-cols-6 gap-8">
        <div className="flex flex-col items-center">
          <div className="type-body-regular-lg">
            {campaign.newApplicationCount}
          </div>
          <div className="type-body-regular-xs text-center">
            New Applications
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="type-body-regular-lg">
            {campaign.applicationCount}
          </div>
          <div className="type-body-regular-xs text-center">
            Total Applications
          </div>
        </div>
        <div className="flex justify-center hidden lg:flex">
          <Divider type="vertical" className="h-12 text-grey-300 border-l-2" />
        </div>
        <div className="flex flex-col items-center col-span-2">
          <div className="type-body-regular-lg">
            {formatCurrencyRange(
              campaign.researchCampaignMinAllocationRange,
              campaign.researchCampaignMaxAllocationRange
            ) ?? 'Not sure yet'}
          </div>
          <div className="type-body-regular-xs text-center">
            Allocation Range
          </div>
        </div>
      </div>
    </CampaignCard>
  )
}

export default ResearchCampaign
