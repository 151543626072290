import PropTypes from 'prop-types'
import { Empty } from 'antd'

const EmptyEventTablesState = ({ activeSummit = true }) => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description={
      activeSummit ? (
        'No Data'
      ) : (
        <span>
          No active Events.{' '}
          <a href="/summits">View past and upcoming events here.</a>
        </span>
      )
    }
  />
)

EmptyEventTablesState.propTypes = {
  activeSummit: PropTypes.bool,
}

export default EmptyEventTablesState
