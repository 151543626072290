import { useContext } from 'react'
import { Button } from '@context365/button'
import {
  AddOutlined,
  DeleteOutlined,
  ExpandMore,
  InfoOutlined,
} from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import { Alert, Dropdown, Form, Input, Menu } from 'antd'
import { useFormikContext } from 'formik'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import PresentationWizardCtx from '~/utils/contexts/PresentationWizardCtx'
import SelectCompanyUsers from '../../SelectCompanyUsers'
import { PRESENTATION_ROLES } from './presentationRoles'

const AttendeesInformation = () => {
  const {
    userInvites,
    setUserInvites,
    displayData,
    guestMembers,
    setGuestMembers,
    selectedGuestRoles,
    setSelectedGuestRoles,
    selectedContacts,
    presentationType,
    isLive,
  } = useContext(PresentationWizardCtx)

  const { errors, setFieldValue, validateField, validateForm } =
    useFormikContext()

  const error = (shortName, key) =>
    isNil(errors.guestMembers && errors.guestMembers[key])
      ? {}
      : {
          help: errors.guestMembers[key][shortName],
          validateStatus: errors.guestMembers[key][shortName] && 'error',
        }

  const handleValueSet = (shortName, value) =>
    Promise.resolve(setFieldValue(shortName, value)).then(() =>
      validateField(shortName)
    )
  const handleUserInvites = (values) => {
    setUserInvites(values)
    Promise.resolve(setFieldValue('userInvites', values)).then(() =>
      validateField('userInvites')
    )
  }

  const handleAddNewUserForm = () => {
    const newValues = [
      ...userInvites,
      {
        name: '',
        company: '',
        contactId: 0,
        companyId: 0,
        role: 3,
        id: Math.random(),
        isSaved: false,
      },
    ]
    setFieldValue('userInvites', newValues)
    setUserInvites(newValues)
  }

  const handleDeleteUserForm = (key) => {
    const newValue = userInvites.filter((_, id) => id !== key)
    handleValueSet(
      'attendeesInvitesRolesModerator',
      newValue.map((s) => s.role).filter((a) => a === 1)
    )
    handleValueSet(
      'attendeesInvitesRolesPresenters',
      newValue.map((s) => s.role).filter((a) => a === 2)
    )
    handleUserInvites(newValue)
  }

  const handleUserItemChanged = (index, newValue) => {
    const newValues = [...userInvites]
    newValues[index] = newValue
    handleUserInvites(newValues)
  }

  const handleGuestMembersChange = (values) => {
    setGuestMembers(values)
    Promise.resolve(setFieldValue('guestMembers', values)).then(
      () => !isNil(errors.guestMembers) && validateForm()
    )
  }

  const handleGuestRolesChange = (values) => {
    setSelectedGuestRoles(values)
    handleValueSet(
      'attendeesInvitesRolesModerator',
      [
        ...values,
        ...selectedContacts.map((contact) => contact.role),
        ...userInvites.map((contact) => contact.role),
      ].filter((contact) => contact === 1)
    )
    handleValueSet(
      'attendeesInvitesRolesPresenters',
      [
        ...values,
        ...selectedContacts.map((contact) => contact.role),
        ...userInvites.map((contact) => contact.role),
      ].filter((contact) => contact === 2)
    )
  }
  const handleAddNewForm = () => {
    const newValues = [
      ...guestMembers,
      {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role: 2,
        id: Math.random(),
        isSaved: false,
      },
    ]
    const newRoleValues = [...selectedGuestRoles, 2]
    setFieldValue('guestMembers', newValues)
    setGuestMembers(newValues)
    handleGuestRolesChange(newRoleValues)
  }
  const handleItemChanged = (index, key, newValue) => {
    const newValues = [...guestMembers]
    newValues[index][key] = newValue
    handleGuestMembersChange(newValues)
  }

  const handleDeleteForm = (key) => {
    const newValue = [...guestMembers]
    const newRoleValue = [...selectedGuestRoles]
    handleGuestMembersChange(newValue.filter((_, id) => id !== key))
    handleGuestRolesChange(newRoleValue.filter((_, id) => id !== key))
  }

  const handleUserInvitesChange = (values) => {
    setUserInvites(values)
    handleValueSet(
      'attendeesInvitesRolesModerator',
      [
        ...[...selectedContacts, ...values].map((s) => s.role),
        ...selectedGuestRoles,
      ].filter((a) => a === 1)
    )
    handleValueSet(
      'attendeesInvitesRolesPresenters',
      [
        ...[...selectedContacts, ...values].map((s) => s.role),
        ...selectedGuestRoles,
      ].filter((a) => a === 2)
    )
  }
  const handleRolesChanged = (contactId, newValue) => {
    const newValues = [...userInvites]
    newValues.find((n) => n.contactId === contactId).role = newValue
    handleUserInvitesChange(newValues)
  }

  const handleGuestRolesChanged = (index, newValue) => {
    const newValues = [...selectedGuestRoles]
    newValues[index] = newValue
    handleGuestRolesChange(newValues)
  }

  const menu = (contactId, isGuest = false) => (
    <Menu>
      {displayData.roles
        .filter(
          (existingContact) =>
            existingContact.name !== 'Admin' &&
            (!isGuest || existingContact.name !== 'Attendee')
        )
        .map((existingContact) => (
          <Menu.Item
            key={existingContact.id}
            onClick={() => {
              isGuest
                ? handleGuestRolesChanged(contactId, existingContact.id)
                : handleRolesChanged(contactId, existingContact.id)
            }}
          >
            {existingContact.name}
          </Menu.Item>
        ))}
    </Menu>
  )
  return (
    <div className="flex flex-col">
      {(errors.attendeesInvitesRolesModerator ||
        errors.attendeesInvitesRolesPresenters) && (
        <Alert
          className="mb-6"
          message={
            <div>
              <div>{errors.attendeesInvitesRolesModerator}</div>
              <div>{errors.attendeesInvitesRolesPresenters}</div>
            </div>
          }
          type="warning"
          showIcon
        />
      )}
      <div className="text-grey-900 type-body-regular-sm mb-6">
        To invite someone to your presentation who is already a Context365 by
        Apex member, select &quot;Add ApexInvest user&quot; below and search for
        their profile
      </div>
      <div className="mt-6">
        <div className="text-grey-900 type-header-xs">ApexInvest Users</div>
        {!isEmpty(userInvites) && (
          <div className="grid grid-cols-2 gap-4 mt-2 mb-2">
            <div className="type-body-regular-md text-grey-700">Full name</div>
            <div className="type-body-regular-md text-grey-700">
              Company Name
            </div>
          </div>
        )}
        {userInvites.map((u, key) => (
          <div key={u.id} className="flex items-center">
            <div className="w-full">
              <SelectCompanyUsers
                firstContact={u}
                onUserItemChanged={handleUserItemChanged}
                index={key}
                errors={errors}
                userInvitesIds={userInvites.map((u) => u.contactId)}
                colleagues={displayData.colleagues.map((c) => c.contactId)}
              />
            </div>
            <div className="flex items-center justify-end w-56 mb-6">
              {isLive &&
              userInvites.find((s) => s.contactId === u.contactId).isSaved ? (
                <div className="text-grsey-900 mr-2">
                  {
                    displayData.roles[
                      userInvites.find((s) => s.contactId === u.contactId)
                        .role - 1
                    ]?.name
                  }
                </div>
              ) : (
                <Dropdown
                  overlay={menu(u.contactId)}
                  className="text-grey-900 type-body-regular-sm"
                >
                  <div className="ant-dropdown-link flex items-center cursor-pointer">
                    {
                      displayData.roles[
                        userInvites.find((s) => s.contactId === u.contactId)
                          .role - 1
                      ]?.name
                    }
                    <ExpandMore />
                  </div>
                </Dropdown>
              )}
              <Tooltip
                text={
                  PRESENTATION_ROLES[presentationType - 1][
                    userInvites.find((s) => s.contactId === u.contactId).role -
                      1
                  ]
                }
              >
                <InfoOutlined className="text-grey-700" />
              </Tooltip>
              <Button
                className="p-0"
                status="error"
                variant="link"
                onClick={() => handleDeleteUserForm(key)}
              >
                <DeleteOutlined />
              </Button>
            </div>
          </div>
        ))}
        <div className="flex justify-between">
          <Button
            className="p-0 mt-6 mb-2"
            variant="link"
            onClick={handleAddNewUserForm}
          >
            <AddOutlined />
            Add ApexInvest user
          </Button>
        </div>
      </div>
      <div className="mt-6">
        <div className="text-grey-900 type-header-xs">Non ApexInvest Users</div>
      </div>
      {!isArray(errors.userInvites) && errors.userInvites && (
        <Alert
          className="mt-6"
          message={
            <div>
              <div>{errors.userInvites}</div>
            </div>
          }
          type="warning"
          showIcon
        />
      )}
      {!isEmpty(guestMembers) && (
        <div className="flex">
          <div className="grid grid-cols-4 gap-2 mt-2 mb-2 w-full">
            <div className="type-body-regular-md text-grey-700">First name</div>
            <div className="type-body-regular-md text-grey-700">Last name</div>
            <div className="type-body-regular-md text-grey-700">Email</div>
            <div className="type-body-regular-md text-grey-700">
              Phone number
            </div>
          </div>
          <div className="w-56" />
        </div>
      )}
      {guestMembers.map((g, key) => (
        <div key={g.id} className="flex items-center">
          <div className="grid grid-cols-4 gap-2 mt-2 mb-2 mr-2 w-full	">
            <Form.Item {...error('firstName', key)}>
              <Input
                className="cc-shadow-box text-md h-12"
                placeholder="Enter first name"
                defaultValue={g.firstName}
                onChange={(e) =>
                  handleItemChanged(key, 'firstName', trim(e.target.value))
                }
              />
            </Form.Item>
            <Form.Item {...error('lastName', key)}>
              <Input
                className="cc-shadow-box text-md h-12"
                placeholder="Enter last name"
                defaultValue={g.lastName}
                onChange={(e) =>
                  handleItemChanged(key, 'lastName', trim(e.target.value))
                }
              />
            </Form.Item>
            <Form.Item {...error('email', key)}>
              <Input
                className="cc-shadow-box text-md h-12"
                placeholder="Enter email"
                defaultValue={g.email}
                onChange={(e) =>
                  handleItemChanged(key, 'email', e.target.value)
                }
              />
            </Form.Item>
            <Form.Item>
              <Input
                className="cc-shadow-box text-md h-12"
                placeholder="Enter phone number"
                defaultValue={g.phone}
                onChange={(e) =>
                  handleItemChanged(key, 'phone', e.target.value)
                }
              />
            </Form.Item>
          </div>

          <div className="flex items-center justify-end w-56 mb-6">
            {isLive && g.isSaved ? (
              <div className="text-grsey-900 mr-2">
                {displayData.roles[selectedGuestRoles[key] - 1].name}
              </div>
            ) : (
              <Dropdown overlay={menu(key, true)} className="text-grey-900">
                <div className="ant-dropdown-link flex items-center type-body-regular-sm cursor-pointer">
                  {displayData.roles[selectedGuestRoles[key] - 1].name}
                  <ExpandMore />
                </div>
              </Dropdown>
            )}
            <Tooltip text="Presenters can present the presentation.">
              <InfoOutlined className="text-grey-700" />
            </Tooltip>
            <Button
              className="p-0"
              status="error"
              variant="link"
              onClick={() => handleDeleteForm(key)}
            >
              <DeleteOutlined />
            </Button>
          </div>
        </div>
      ))}
      <div className="flex justify-between">
        <Button
          className="p-0 mt-6 mb-2"
          variant="link"
          onClick={handleAddNewForm}
        >
          <AddOutlined /> Add guest speaker
        </Button>
      </div>
    </div>
  )
}

export default AttendeesInformation
