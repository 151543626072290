import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useRouteMatch } from 'react-router-dom'
import { Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import AuthorizationGate from '~/components/AuthorizationGate'
import useAuth from '~/hooks/useAuth'
import NotFound from '~/views/NotFound'
import MandateProfile from './MandateProfile'
import MandateWizard from './MandateWizard'

export default function MandateRoute() {
  const { showCreateMandate = true } = useFlags()
  const { role } = useAuth()

  return (
    <Routes>
      <Route path=":mandateId" element={<MandateProfile />} />
      {showCreateMandate && (
        <Route
          element={
            <AuthorizationGate
              blockWhen={() =>
                role !== 'Allocator' && role !== 'Service Provider'
              }
              fallback={<Navigate replace to="/" />}
            />
          }
        >
          <Route path=":mandateId/edit/*" element={<MandateWizard />} />
          <Route path="new/*" element={<MandateWizard />} />
        </Route>
      )}

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export function MandateRedirect({ from, to }) {
  const { params } = useRouteMatch(from)

  return <Navigate replace to={to(params)} />
}

MandateRedirect.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.func.isRequired,
}
