import qs from 'qs'
import { api } from './services'

export const getMeetingById = (meetingType, meetingId) =>
  api.get(`meetings/${meetingType.toString().toLowerCase()}/${meetingId}`)

export const getSummitBoothsMeetingParticipants = (
  toCompanyId,
  eventId,
  contactId = null,
  toEventCompanyBoothId = null,
  toEventCompanyBoothFundId = null,
  eventCompanyBoothId = null,
  eventCompanyBoothFundId = null
) =>
  api.get(`companies/${toCompanyId}/meeting-participants`, {
    apiVersion: 3,
    params: {
      eventId: eventId ?? undefined,
      contactId: contactId ?? undefined,
      toEventCompanyBoothId: toEventCompanyBoothId ?? undefined,
      toEventCompanyBoothFundId: toEventCompanyBoothFundId ?? undefined,
      eventCompanyBoothId: eventCompanyBoothId ?? undefined,
      eventCompanyBoothFundId: eventCompanyBoothFundId ?? undefined,
    },
  })
export const sendSummitMeetingRequest = (
  toCompanyId,
  fundId,
  contactIds,
  toContactIds,
  message,
  originalCompanyContactId,
  {
    eventId,
    sendContextPoints,
    eventCompanyBoothId,
    receivingEventCompanyBoothId,
    meetingDateTime,
    meetingDuration,
  } //using objects for optional parameters
) =>
  api.post('meetings', {
    toCompanyId,
    fundId,
    contactIds,
    toContactIds,
    message,
    meetingTypeId: 1,
    originalCompanyContactId,
    eventId,
    meetingDateTime,
    meetingDuration,
    carryContextPoints: sendContextPoints,
    eventCompanyBoothId,
    receivingEventCompanyBoothId,
  })

export const getMeetingStatsByTimeframe = (timeframe) =>
  api.get('/meetings/stats', { params: { timeframe } })

export const getNextMeeting = () => api.get('/meetings/next')

export const getCalendarMeetings = (includeCompanyMeetings, selectedDate) =>
  api.get('/meetings/calendar', {
    params: {
      allCompanyMeetings: includeCompanyMeetings,
      timestamp: selectedDate.format('x'),
    },
  })

//filters: includeSummit, includeOnline, includeActive, includeInactive, campaignId
export const getAllMeetings = (page, pageSize, search, filters) =>
  api.get('/meetings/all', {
    params: {
      page,
      pageSize,
      q: search,
      ...filters,
    },
  })

//filters: includeSummit, includeOnline
export const getRequests = (page, pageSize, search, filters) =>
  api.get('/meetings/requests', {
    params: {
      page,
      pageSize,
      q: search,
      ...filters,
    },
  })

export const downloadInvitation = (meetingId) =>
  api.get(`/calendar-invitations/${meetingId}/download`, {
    responseType: 'blob',
  })

export const getUpcomingMeetings = () =>
  api.get('/meetings/week').then((res) => res.data.result)

export const getAvailableTimeslots = (params) =>
  api.get('meetings/availabilities', {
    params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  })

export const checkCanSendMeetingRequest = ({ toCompanyId, fundId }) =>
  api
    .post('meetings/validation', { toCompanyId, fundId })
    .then((response) => response.data.result)

export const createMeetingFromConversation = (
  conversationId,
  { meetingDateTime, meetingDuration }
) =>
  api
    .post(`/meetings/scheduleFromConversation/${conversationId}`, {
      meetingDateTime,
      meetingDuration,
    })
    .then((res) => res.data.result)
