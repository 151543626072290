import * as React from 'react'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import uniq from 'lodash/uniq'

export default function usePresetValues(filters, presets) {
  const filterTypesById = React.useMemo(() => {
    const allFilters = flatten(filters)
    const ids = uniq(map(allFilters, (f) => f.field))
    return reduce(
      ids,
      (filtersById, id) => {
        filtersById[id] = find(allFilters, (f) => f.field === id).questionType
        return filtersById
      },
      {}
    )
  }, [filters])

  return React.useCallback(
    (id) => {
      const filterType = filterTypesById[id]
      if (!filterType) {
        return []
      }

      if (filterType === 'aggregatedRange') {
        return getPresetAggregatedRangeValue(presets, id)
      } else if (filterType === 'matchmakingRange') {
        return getPresetValue(presets, id)
      } else if (
        filterType.toLowerCase().includes('range') ||
        filterType === 'date'
      ) {
        return getPresetRangeValue(presets, id)
      } else if (filterType === 'customBool') {
        return getPresetCustomBoolValue(presets, id)
      } else {
        return getPresetValue(presets, id)
      }
    },
    [filterTypesById, presets]
  )
}

function getPresetValue(presetFilters, id) {
  return (
    find(
      presetFilters,
      (f) => f.alertColumnKey?.toLowerCase() === id?.toLowerCase()
    )?.values ?? []
  )
}

function getPresetRangeValue(presetFilters, id) {
  const filtersForField = filter(presetFilters, (f) =>
    f.alertColumnKey?.includes(id)
  )
  const min = filtersForField.find((f) => f.alertColumnKey?.includes('Min'))
  const max = filtersForField.find((f) => f.alertColumnKey?.includes('Max'))
  return [min?.values[0], max?.values[0]]
}

function getPresetAggregatedRangeValue(presetFilters, id) {
  const ids = id.split(',')
  const filtersForField = filter(presetFilters, (f) =>
    ids.some((id) => f.alertColumnKey?.includes(id))
  )
  const min = filtersForField.find((f) => f.alertColumnKey?.includes('Min'))
  const max = filtersForField.find((f) => f.alertColumnKey?.includes('Max'))
  return [min?.values[0], max?.values[0]]
}

function getPresetCustomBoolValue(presetFilters, id) {
  const valuesForField = find(
    presetFilters,
    (f) => f.alertColumnKey?.toLowerCase() === id?.toLowerCase()
  )?.values
  return valuesForField?.map((v) => v === true || v === 'true') ?? []
}
