import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'
import moment from 'moment'
import './ProposedSlots.less'

const ProposedTimeSlots = ({ proposedTimeSlots }) =>
  Array.isArray(proposedTimeSlots) &&
  proposedTimeSlots.length > 0 && (
    <div className="proposed-slots">
      <span className="type-subtitle-sm text-header">PROPOSED TIMES</span>
      <div>
        {proposedTimeSlots?.map((proposedTimeSlot) => (
          <p
            className="proposed-slots-text"
            key={proposedTimeSlot.contextMeetingProposedTimeId}
          >
            {!isNil(proposedTimeSlot) &&
              `${moment
                .utc(proposedTimeSlot.proposedMeetingStartTime)
                .local()
                .format('MMM DD, YYYY [at] h:mm A')} - ${moment
                .utc(proposedTimeSlot.proposedMeetingEndTime)
                .local()
                .format('h:mm A')}`}
          </p>
        ))}
      </div>
    </div>
  )

ProposedTimeSlots.propTypes = {
  proposedTimeSlots: PropTypes.array,
}
export default ProposedTimeSlots
