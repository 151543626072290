import { useCallback, useEffect, useState } from 'react'
import {
  faArrowLeft,
  faCheckDouble,
  faCog,
  faEmptySet,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Pagination } from 'antd'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  loadNotificationsPaginated,
  markAllNotificationsAsRead,
  markNotificationAsRead,
} from '~/actions/notifications'
import NoData from '~/components/NoData'
import NotificationEntry from '~/components/NotificationEntry'
import {
  getNotificationCount,
  getPaginatedNotificationsList,
} from '~/selectors/notifications'
import './NotificationEntries.less'

const NotificationEntries = () => {
  const dispatch = useDispatch()
  const { push, goBack } = useHistory()
  const [page, setPage] = useState(1)
  const notifications = useSelector(getPaginatedNotificationsList)
  const count = useSelector(getNotificationCount)

  useEffect(() => {
    const params = { page, pageSize: 10 }
    dispatch(loadNotificationsPaginated(params))
  }, [dispatch, page])

  const handleMarkAllNotificationsAsRead = useCallback(() => {
    dispatch(markAllNotificationsAsRead())
  }, [dispatch])

  const handleMarkNotificationAsRead = useCallback(
    (notificationLogId) => {
      dispatch(markNotificationAsRead(notificationLogId))
    },
    [dispatch]
  )

  let content = map(notifications, (notification) => (
    <NotificationEntry
      key={notification.notificationLogId}
      notification={notification}
      centered={true}
      onMark={handleMarkNotificationAsRead}
      fullScreen={true}
    />
  ))
  if (isEmpty(notifications)) {
    content = (
      <NoData
        title="You have not received any notifications at this time"
        icon={<FontAwesomeIcon icon={faEmptySet} size="lg" />}
      />
    )
  }
  const handlePaginationChange = (e) => {
    setPage(e)
  }

  return (
    <div className="NotificationEntries">
      <div className="NotificationEntries-header">
        <div className="NotificationEntries-header-back">
          <Button className="Back-button" onClick={goBack}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <h3 className="NotificationEntries-header-back-title">
            {' '}
            Notifications
          </h3>
        </div>

        <div className="NotificationEntries-header-actions">
          <Button type="link" onClick={handleMarkAllNotificationsAsRead}>
            <FontAwesomeIcon icon={faCheckDouble} />
            <span className="NotificationEntries-header-actions-btn-text">
              Mark All as Read
            </span>
          </Button>
          <Button
            type="link"
            className="NotificationEntries-header-actions-btn-settings"
            onClick={() => {
              push('/settings/communications')
            }}
          >
            <FontAwesomeIcon icon={faCog} size="lg" />
          </Button>
        </div>
      </div>
      <div>{content}</div>
      <Pagination
        className="Notification-pagination justify-center"
        defaultCurrent={1}
        current={page}
        total={count}
        onChange={handlePaginationChange}
      />
    </div>
  )
}

export default NotificationEntries
