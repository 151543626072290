import { useState } from 'react'
import PropTypes from 'prop-types'
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox } from 'antd'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import './ColumnsPreferences.less'

const ColumnsPreferences = ({ columns, onColumnsRearranged, onCancel }) => {
  const [columnsList, setColumnsList] = useState(
    columns.filter((x) => x.fixed !== 'left')
  )
  const [refresh, setRefresh] = useState(moment())

  const onColumnCheckChange = (title, e) => {
    const currentCol = columnsList.find((x) => x.title === title)
    currentCol.checked = e.target.checked
    setRefresh(moment())
  }

  const moveInArray = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1
      while (k--) {
        arr.push(undefined)
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    return arr // for testing
  }

  const onDragEnd = (result) => {
    const cols = columnsList
    moveInArray(cols, result.source.index, result.destination.index)
    // const newProcessedCols = cols.map(x => ({
    //   title: x.title,
    //   checked: true,
    // }));
    setColumnsList(cols)
  }

  return (
    <div
      className="cc-columns-preferences-container"
      refresh={refresh.format()}
    >
      <div className="cc-columns-preferences-info">
        <span className="cc-heading5 cc-columns-preferences-info-header">
          Select columns you want to show.
        </span>
        <span className="cc-small-font cc-columns-preferences-info-aux">
          You can order them by dragging and dropping.
        </span>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              className="cc-columns-preferences-column-list"
              ref={provided.innerRef}
            >
              {columnsList.map(
                (x, index) =>
                  x.title && (
                    <Draggable
                      key={x.title}
                      draggableId={x.title}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="cc-columns-preferences-item"
                        >
                          <FontAwesomeIcon
                            className="cc-columns-preferences-item-icon"
                            icon={faGripVertical}
                          />
                          <Checkbox
                            checked={x.checked}
                            onChange={onColumnCheckChange.bind(null, x.title)}
                            className="cc-body-text cc-columns-preferences-item-checkbox"
                          >
                            {x.title}
                          </Checkbox>
                        </div>
                      )}
                    </Draggable>
                  )
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="cc-columns-preferences-bottom">
        <Button
          type="primary"
          onClick={() => {
            if (!isNil(onColumnsRearranged)) onColumnsRearranged(columnsList)
          }}
        >
          Save Changes
        </Button>
        <Button
          className="cc-columns-preferences-cancel-button"
          onClick={() => {
            if (!isNil(onCancel)) onCancel()
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

ColumnsPreferences.propTypes = {
  columns: PropTypes.array.isRequired,
  onColumnsRearranged: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ColumnsPreferences
