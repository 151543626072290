import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Divider, Radio, Row } from 'antd'
import FloatingInput from '../FloatingInput'

const HoldingsSection = ({ value, onChange, isRequired }) => {
  const [questions, setQuestions] = useState(value)

  const onAddNewClicked = useCallback(() => {
    const val = value || []
    val.push({ name: '', status: 'current' })
    setQuestions(val)
    onChange(val)
  }, [onChange, value])

  const onRemoveClicked = useCallback(
    (index) => {
      const val = value
      val.splice(index, 1)
      setQuestions(val)
      onChange(val)
    },
    [onChange, value]
  )

  const onHoldingChanged = useCallback(
    (index, newValue) => {
      const val = value
      val[index].name = newValue
      setQuestions(val)
      onChange(val)
    },
    [value, onChange]
  )

  const onStatusChanged = useCallback(
    (index, newValue) => {
      const val = value
      val[index].status = newValue
      setQuestions(val)
      onChange(val)
    },
    [value, onChange]
  )

  return (
    <div>
      {questions &&
        questions.map((x, index) => (
          <div key={index} className="cc-holding-row">
            <Row>
              <Col span={17} className="cc-holding-col">
                <FloatingInput
                  value={x.name}
                  onChange={(e) => {
                    onHoldingChanged(index, e.target.value)
                  }}
                  name={isRequired === true ? 'Holding *' : 'Holding'}
                />
              </Col>
              <Col span={4}>
                <Radio.Group
                  onChange={(e) => {
                    onStatusChanged(index, e.target.value)
                  }}
                  value={x.status}
                >
                  <Radio value="current">Current</Radio>
                  <Radio value="realized">Realized</Radio>
                </Radio.Group>
              </Col>
              <Col span={2}>
                <Button
                  type="link"
                  onClick={() => {
                    onRemoveClicked(index)
                  }}
                >
                  <FontAwesomeIcon
                    className="button-icon"
                    icon={faTrashAlt}
                    color="#D62B34"
                    size="lg"
                  />
                </Button>
              </Col>
            </Row>
            <Divider className="cc-holding-divider" />
          </div>
        ))}
      <div className="cc-holding-add-button">
        <Button type="link" onClick={onAddNewClicked}>
          <FontAwesomeIcon className="button-icon" icon={faPlus} />
          Add Holding
        </Button>
        <Divider className="cc-holding-divider" />
      </div>
    </div>
  )
}

HoldingsSection.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
}

export default HoldingsSection
