import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip, message } from 'antd'
import isNil from 'lodash/isNil'
import set from 'lodash/set'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt, useHistory } from 'react-router-dom'
import { updateUser } from '~/actions/auth'
import * as api from '~/api'
import { userIsGuest } from '~/selectors/auth'
import CCUpload from '../CCUpload'
import FloatingInput from '../FloatingInput'
import FloatingSelect from '../FloatingSelect'
import Loading from '../Loading'
import OnboardingAddressSection from '../OnboardingComponents/OnboardingAddressSection'
import './ContactForm.less'

const ContactForm = ({ contactId }) => {
  const dispatch = useDispatch()
  const isGuest = useSelector(userIsGuest)
  const [contact, setContact] = useState({})
  const [jobLevels, setJobLevels] = useState([])
  const [jobFunctions, setJobFunctions] = useState([])
  const [loading, setLoading] = useState(false)
  const [canPostContact, setCanPostContact] = useState(isGuest)
  const [canPostAddress, setCanPostAddress] = useState(isGuest)
  const [contentChanged, setContentChanged] = useState(false)

  const history = useHistory()

  useEffect(() => {
    const getContactData = async () => {
      setLoading(true)
      try {
        const contactRaw = isNil(contactId)
          ? await api.settings.getUserSettings()
          : await api.settings.getColleagueSettings(contactId)
        setContact(contactRaw.data.result.user)
        setJobLevels(contactRaw.data.result.referenceData.JobLevels)
        setJobFunctions(contactRaw.data.result.referenceData.JobFunctions)
      } catch (error) {
        message.error(
          'There was an error while attempting to fetch contact data'
        )
      } finally {
        setLoading(false)
        setContentChanged(false)
      }
    }

    getContactData()
  }, [contactId])

  const inputFieldChanged = useCallback(
    (name, e) => {
      !isNil(e) && setContentChanged(true)
      const value = (!isNil(e) && e.target.value) || null
      set(contact, name, value)
      setContact({ ...contact })
    },
    [contact]
  )

  const dropdownFieldChanged = useCallback(
    (e, name) => {
      setContentChanged(true)
      contact[name] = e
      setContact({ ...contact })
    },
    [contact]
  )

  const addressChanged = useCallback(
    (address) => {
      setContentChanged(true)
      contact.address = address
      setContact({ ...contact })
    },
    [contact]
  )

  const imageChanged = useCallback(
    (base64Image) => {
      setContentChanged(true)
      contact.imageURL = base64Image
      contact.imageBase64 = base64Image
      setContact({ ...contact })
    },
    [contact]
  )

  const onContactSaved = useCallback(() => {
    const saveContactAction = async () => {
      try {
        setLoading(true)
        if (isNil(contactId)) {
          await api.settings.updateUser(contact)
          dispatch(updateUser(contact))
        } else {
          await api.settings.updateColleague(contactId, contact)
        }
        message.success('Settings have been successfully saved')
      } catch {
        message.error('There was an error while attempting to save')
      } finally {
        setLoading(false)
        setContentChanged(false)
      }
    }

    saveContactAction()
  }, [contact, contactId, dispatch])

  const onCancel = useCallback(() => {
    history.goBack()
  }, [history])

  const isValid = (e) => !(e === -1 || e === '' || isNil(e))

  useEffect(() => {
    setCanPostContact(
      (isValid(contact.firstName) &&
        isValid(contact.lastName) &&
        isValid(contact.email) &&
        isValid(contact.jobTitle) &&
        isValid(contact.jobLevel) &&
        isValid(contact.jobFunction) &&
        isValid(contact.workPhone)) ||
        isGuest
    )
  }, [setCanPostContact, contact, isGuest])

  return (
    <Loading spinning={loading}>
      <Prompt
        message="Are you sure you want to leave without saving?"
        when={contentChanged}
      />

      <div className="form-container">
        <div className="form-container-header">
          <p className="form-container-title">Profile Settings</p>
          <p className="form-container-description">
            Edit your profile information
          </p>
        </div>
        <div className="form-section">
          <div className="form-column-grouping cc-heading5">
            Personal Information
          </div>
          <div className="form-column-inputs">
            <FloatingInput
              name="First Name *"
              id="firstName"
              value={contact.firstName}
              isValid={isValid(contact.firstName)}
              onChange={inputFieldChanged}
              handleChange={true}
            />
            <FloatingInput
              onChange={inputFieldChanged}
              value={contact.lastName}
              isValid={isValid(contact.lastName)}
              name="Last Name *"
              id="lastName"
              handleChange={true}
            />
            <FloatingInput
              onChange={inputFieldChanged}
              value={contact.email}
              isValid={isValid(contact.email)}
              disabled={true}
              name="Email *"
              id="email"
              handleChange={false}
            />
            {!isGuest && (
              <>
                <FloatingInput
                  onChange={inputFieldChanged}
                  value={contact.jobTitle}
                  isValid={isValid(contact.jobTitle)}
                  name="Job Title *"
                  id="jobTitle"
                  handleChange={true}
                />
                <FloatingSelect
                  name="Job Level *"
                  value={contact.jobLevel}
                  isValid={isValid(contact.jobLevel)}
                  onChange={dropdownFieldChanged}
                  id="jobLevel"
                  options={jobLevels.map((x) => ({
                    value: x,
                    text: x,
                  }))}
                />
                <FloatingSelect
                  name="Job Function *"
                  id="jobFunction"
                  value={contact.jobFunction}
                  isValid={isValid(contact.jobFunction)}
                  onChange={dropdownFieldChanged}
                  options={jobFunctions.map((x) => ({
                    value: x,
                    text: x,
                  }))}
                />
                <FloatingInput
                  onChange={inputFieldChanged}
                  value={contact.workPhone}
                  isValid={isValid(contact.workPhone)}
                  name="Work Phone *"
                  id="workPhone"
                  handleChange={true}
                />
                <FloatingInput
                  onChange={inputFieldChanged}
                  value={contact.workExtension}
                  name="Work Extension"
                  id="workExtension"
                  handleChange={true}
                />
              </>
            )}
            <FloatingInput
              onChange={inputFieldChanged}
              value={contact.mobilePhone}
              name="Mobile Phone"
              id="mobilePhone"
              handleChange={true}
            />
          </div>
          <div className="form-column-upload">
            <CCUpload
              onChange={imageChanged}
              imageUrl={contact.imageURL}
              placeholder="Upload Photo"
            />
          </div>
        </div>

        {!isGuest && (
          <div className="form-section">
            <div className="form-column-grouping cc-heading5">Address</div>
            <div className="form-column-inputs">
              {isNil(contact.address) ? null : (
                <OnboardingAddressSection
                  value={contact.address}
                  onChange={addressChanged}
                  enableNext={(enabled) => {
                    setCanPostAddress(enabled)
                  }}
                />
              )}
            </div>
            <div className="form-column-upload" />
          </div>
        )}

        <div className="form-section-submit">
          <div className="empty-column-left" />
          <div className="form-section-submit-buttons">
            <Button
              type="default"
              className="form-section-btn-cancel"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Tooltip
              title={
                (!canPostAddress || !canPostContact) &&
                'Required fields are not completed.'
              }
            >
              <div>
                <Button
                  type="primary"
                  className="form-section-btn-save"
                  onClick={onContactSaved}
                  disabled={!canPostAddress || !canPostContact}
                >
                  Save
                </Button>
              </div>
            </Tooltip>
          </div>
          <div className="empty-column-right" />
        </div>
      </div>
    </Loading>
  )
}

ContactForm.propTypes = {
  contactId: PropTypes.number,
}

export default ContactForm
