import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Input, Modal, Radio, message } from 'antd'
import filter from 'lodash/filter'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import startsWith from 'lodash/startsWith'
import trim from 'lodash/trim'
import { useDispatch, useSelector } from 'react-redux'
import {
  cancelOnlineMeeting,
  cancelOnlineMeetingForAll,
  declineOnlineMeeting,
} from '~/actions/contextMeetings'
import { fetchCancellingReasonsIfNeeded } from '~/actions/meetings'
import { selectOnlineCancellationReasons } from '~/selectors/meetings'
import Loading from '../Loading'

const RadioGroup = Radio.Group
const { TextArea } = Input

const CancelSummitMeeting = ({
  visible,
  closeModal,
  company,
  cancelText = 'Cancel',
  meetingType = 'Summit',
  meetingId,
  meetingStatus = null,
  onCancel,
  canDeclineMeeting = true,
  cancelForAll = false,
}) => {
  const [cancellingInProgress, setCancellingInProgress] = useState(false)
  const [selectedReasonId, setSelectedReasonId] = useState(0)
  const [otherReason, setOtherReason] = useState(false)
  const [otherReasonText, setOtherReasonText] = useState('')
  const dispatch = useDispatch()

  const reasonRequired =
    meetingStatus?.toLowerCase() === 'confirmed' &&
    startsWith(cancelText, 'Cancel')

  const cancellationReasons = useSelector(selectOnlineCancellationReasons)

  useEffect(() => {
    dispatch(fetchCancellingReasonsIfNeeded())
  }, [dispatch])

  const handleModalCancel = useCallback(() => {
    setSelectedReasonId(0)
    setOtherReason(false)
    setOtherReasonText('')
    closeModal()
  }, [closeModal])

  const cancelMeetingReq = useCallback(() => {
    setCancellingInProgress(true)
    if (startsWith(cancelText, 'Cancel')) {
      if (cancelForAll) {
        cancelOnlineMeetingForAll(meetingId, selectedReasonId, otherReasonText)
          .then(() => {
            message.success('The meeting has been cancelled')
            handleModalCancel()
            onCancel()
          })
          .finally(() => {
            setCancellingInProgress(false)
          })
      } else {
        cancelOnlineMeeting(meetingId, selectedReasonId, otherReasonText)
          .then(() => {
            message.success(
              canDeclineMeeting
                ? 'The meeting has been cancelled'
                : 'You have left this meeting'
            )
            handleModalCancel()
            onCancel()
          })
          .finally(() => {
            setCancellingInProgress(false)
          })
      }
    } else {
      declineOnlineMeeting(meetingId)
        .then(() => {
          message.success(
            canDeclineMeeting
              ? 'The meeting has been declined'
              : 'You have left this meeting'
          )
          handleModalCancel()
          onCancel()
        })
        .finally(() => {
          setCancellingInProgress(false)
        })
    }
  }, [
    canDeclineMeeting,
    cancelForAll,
    cancelText,
    handleModalCancel,
    meetingId,
    onCancel,
    otherReasonText,
    selectedReasonId,
  ])

  const title = useCallback(() => {
    let text =
      cancelText.indexOf('Meeting') === -1
        ? canDeclineMeeting || cancelForAll
          ? `${cancelText} Meeting`
          : 'Leave Meeting'
        : cancelText
    if (!isNil(meetingStatus)) {
      if (meetingStatus === 'Confirmed' && startsWith(cancelText, 'Cancel')) {
        text =
          canDeclineMeeting || cancelForAll ? 'Cancel Meeting' : 'Leave Meeting'
      }
      return text
    }
  }, [canDeclineMeeting, cancelForAll, cancelText, meetingStatus])

  return (
    <Modal
      className="cc-modal-accept"
      title={title()}
      visible={visible}
      okText={title()}
      cancelText="Close"
      onOk={cancelMeetingReq}
      onCancel={handleModalCancel}
      okButtonProps={{
        loading: cancellingInProgress,
        disabled:
          reasonRequired &&
          cancellationReasons.length > 0 &&
          (canDeclineMeeting || cancelForAll) &&
          (selectedReasonId === 0 ||
            (otherReason && trim(otherReasonText) === '')),
        style: { backgroundColor: '#D62B34', borderStyle: 'none' },
      }}
      cancelButtonProps={{ disabled: false }}
    >
      <Loading spinning={cancellingInProgress}>
        {cancellationReasons.length > 0 &&
        reasonRequired &&
        (canDeclineMeeting || cancelForAll) ? (
          <div>
            <p className="cc-body-text cc-lightblack-text">{`Please select the reason you want to ${
              cancelText.indexOf('Meeting') === -1
                ? cancelText.toLowerCase()
                : 'cancel'
            } the meeting${
              cancelText.indexOf('Decline') !== -1 ? ' request' : ''
            }:`}</p>
            <RadioGroup
              style={{ width: '100%' }}
              value={selectedReasonId}
              onChange={(e) => {
                setSelectedReasonId(e.target.value)
                const reason = filter(
                  cancellationReasons,
                  (r) => r.cancellationReasonId == e.target.value
                )
                setOtherReason(reason[0].requiresInput)
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '16px',
                }}
              >
                {map(cancellationReasons, (c) => (
                  <span
                    style={{ marginBottom: '16px' }}
                    className="cancellingReasons"
                  >
                    <Radio
                      key={c.cancellationReasonId.toString()}
                      value={c.cancellationReasonId}
                    >
                      {c.description}
                    </Radio>
                  </span>
                ))}
                {otherReason && (
                  <TextArea
                    placeholder="Please type another reason"
                    rows={4}
                    value={otherReasonText}
                    onChange={({ target: { value } }) => {
                      setOtherReasonText(value)
                    }}
                  />
                )}
              </div>
            </RadioGroup>
          </div>
        ) : (
          <p>
            Are you sure you want to{' '}
            {cancelText.indexOf('Meeting') === -1
              ? canDeclineMeeting || cancelForAll
                ? cancelText.toLowerCase()
                : 'leave'
              : 'cancel'}{' '}
            the {meetingType.toLowerCase()} meeting with {company}?
          </p>
        )}
      </Loading>
    </Modal>
    // </div>
  )
}

CancelSummitMeeting.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  cancelText: PropTypes.oneOf(['Decline', 'Cancel']),
  company: PropTypes.string.isRequired,
  meetingId: PropTypes.number.isRequired,
  meetingStatus: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  canDeclineMeeting: PropTypes.bool,
  cancelForAll: PropTypes.bool,
  meetingType: PropTypes.string,
}

export default CancelSummitMeeting
