import { Button } from '@context365/button'
import { Launch } from '@context365/icons'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import * as api from '~/api'
import WidgetBlock from './WidgetBlock'

const NewMandates = () => {
  const { data = {} } = useQuery(
    'dashboardNewMandates',
    () =>
      api.discovery.mandates({
        page: 1,
        pageSize: 5,
        orderBy: '-MandateId',
      }),
    {
      keepPreviousData: true,
    }
  )
  const mandates = data.result ?? []

  return (
    <WidgetBlock
      headerLeft="New Mandates"
      headerRight={
        <Button
          variant="link"
          as={Link}
          to="/discover/mandates"
          iconRight={<Launch />}
          style={{ padding: 0 }}
          status="secondary"
        >
          See All
        </Button>
      }
      helpContent="The newest mandates added to the platform."
    >
      <div className="flex flex-col gap-4">
        {isEmpty(mandates) ? (
          <div>No mandates have been added recently.</div>
        ) : (
          <div className="divide-y -my-3">
            {map(mandates, (mandate) => (
              <div
                key={mandate.mandateId}
                className="flex flex-row justify-between py-3"
              >
                <div className="flex flex-col gap-1">
                  <div className="type-subtitle-xs">{mandate.title}</div>
                  <div className="type-body-regular-xs">
                    <b>Company: </b>
                    {mandate.isAnonymous ? (
                      <i>This mandate is anonymous</i>
                    ) : (
                      mandate.companyName
                    )}
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button
                    variant="link"
                    as={Link}
                    to={`/mandate/${mandate.mandateId}`}
                  >
                    View Mandate
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </WidgetBlock>
  )
}

export default NewMandates
