import { useState } from 'react'
import PropTypes from 'prop-types'
import * as api from '~/api'
import UploadFile from '../../GenericForm/Fields/FileField'
import MissingSection from './MissingSection'
import NextButton from './NextButton'

const MarketingMaterialsUpload = ({
  marketingMaterials,
  setMarketingMaterials,
}) => {
  return (
    <UploadFile
      name="urls"
      value={marketingMaterials}
      hint={['File Types: .pdf', 'Max File Size: 5MB']}
      setFieldValue={(_, value) => setMarketingMaterials(value)}
    />
  )
}

const MissingMarketingMaterials = ({ fundId, ...actionProps }) => {
  const [marketingMaterials, setMarketingMaterials] = useState([])

  return (
    <>
      <p className="text-primary-100 type-body-regular-sm pb-4">
        {
          'Before you can apply to this mandate, upload materials that help tell the story of your fund. '
        }
      </p>
      <MissingSection title="Marketing Materials">
        <MarketingMaterialsUpload
          marketingMaterials={marketingMaterials}
          setMarketingMaterials={setMarketingMaterials}
        />
      </MissingSection>
      <NextButton
        disabled={marketingMaterials.length === 0}
        {...actionProps}
        onNext={() =>
          api.funds
            .saveFundMarketingMaterials(fundId, marketingMaterials)
            .then(actionProps.onNext)
        }
      />
    </>
  )
}

MissingMarketingMaterials.propTypes = {
  fundId: PropTypes.number.isRequired,
}

export default MissingMarketingMaterials
