import { useState } from 'react'
import { Button } from '@context365/button'
import { useFlags } from 'launchdarkly-react-client-sdk'
import UpgradeModal from '~/components/UpgradeModal'
import { Enterprise, Pro } from '~/constants/tiers'

const FilterBlurPanel = ({ blurred, children }) => {
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false)
  const { enterpriseTier } = useFlags()

  if (!blurred) {
    return children
  }
  return (
    <div className="relative">
      <div className="rounded-big shadow-6 p-4 flex flex-col gap-2 items-start absolute bg-white z-1">
        <div className="type-body-semibold-sm">Search & Filters</div>
        <div className="type-body-regular-xs">
          {enterpriseTier
            ? `Keyword search and filters are only available on the ${Pro} and ${Enterprise} tiers.`
            : `Keyword search and filters are only available on the ${Pro} tier.`}
        </div>
        <div className="self-end">
          <Button
            size="small"
            variant="filled"
            onClick={() => setUpgradeModalVisible(true)}
          >
            Learn More
          </Button>
        </div>
      </div>
      <div
        className="filter blur-sm"
        onClickCapture={(e) => {
          e.stopPropagation()
        }}
      >
        {children}
      </div>
      <UpgradeModal
        closable
        visible={upgradeModalVisible}
        onClose={() => {
          setUpgradeModalVisible(false)
        }}
      />
    </div>
  )
}

export default FilterBlurPanel
