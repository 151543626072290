import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  ContentPasteOutlined,
  EventNoteOutlined,
  GroupsOutlined,
  PeopleOutlined,
} from '@context365/icons'
import { TabButton, TabList, TabPanel, Tabs } from '@context365/tabs'
import isEmpty from 'lodash/isEmpty'
import { Link, useRouteMatch } from 'react-router-dom'
import { getEventFunds } from '~/actions/fundList'
import Agenda from '~/components/Agenda'
import AttendeeList from './attendees/AttendeeList'
import EventMeetingsTab from './meetings/EventMeetingsTab'
import Schedule from './Schedule'
import './SummitDetails.less'

const SummitDetails = ({ events, selectedEvent, role, subtab = '' }) => {
  const {
    params: { id, tab },
  } = useRouteMatch()
  const selectedEventId = parseInt(id, 10)
  const isFundManager = role.toLowerCase() === 'manager'
  const filteredEvents = events.filter(
    (item) => item.eventId === selectedEventId
  )
  const { schedulerAccessDate } = isEmpty(filteredEvents)
    ? { schedulerAccessDate: '' }
    : filteredEvents[0]

  const [attendeeAccess, setAttendeeAccess] = useState(true)
  const [defaultTab] = useState(tab || 'attendees')
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    if (isFundManager) {
      getEventFunds(selectedEvent.eventId).then((response) => {
        setAttendeeAccess(response.data.result.length > 0) //cannot access the list if you dont have funds tied to the event
      })
    } else setAttendeeAccess(true)
  }, [selectedEvent, isFundManager, refresh])

  return (
    <div className="SummitDetails">
      <div className="type-subtitle-md px-4 py-2 bg-white md:p-0 md:bg-transparent">
        {selectedEvent.name}
      </div>
      <Tabs selectedTab={defaultTab}>
        <TabList
          className="overflow-x-auto bg-white pb-1 px-4 md:bg-transparent md:px-0"
          variant="underlined"
          size="big"
        >
          <TabButton
            id="attendees"
            as={Link}
            to={`/summits/${selectedEvent.eventId}/attendees`}
            iconLeft={<GroupsOutlined />}
            label="Attendees"
          />
          <TabButton
            id="meetings"
            as={Link}
            to={`/summits/${selectedEvent.eventId}/meetings`}
            iconLeft={<PeopleOutlined />}
            label="Meetings"
          />
          {selectedEvent.amRegistered && (
            <TabButton
              id="schedule"
              as={Link}
              to={`/summits/${selectedEvent.eventId}/schedule`}
              iconLeft={<EventNoteOutlined />}
              label="Schedule"
            />
          )}
          <TabButton
            id="agenda"
            as={Link}
            to={`/summits/${selectedEvent.eventId}/agenda`}
            iconLeft={<ContentPasteOutlined />}
            label="Agenda"
          />
        </TabList>
        <TabPanel tabId="attendees" className="h-full flex flex-col">
          {() => (
            <AttendeeList
              attendeeAccess={attendeeAccess}
              schedulerAccessDate={schedulerAccessDate}
              eventId={selectedEvent.eventId}
              companyEventId={selectedEvent.companyEventId}
              isOptimized={selectedEvent.isOptimized}
              role={role}
              creditsAvailable={selectedEvent.creditsAvailable}
              contextPoints={selectedEvent.contextPoints}
              subtab={subtab}
              onRefresh={() => setRefresh(!refresh)}
              confirmedMeetingLimit={selectedEvent.confirmedMeetingsLimit}
              confirmedMeetings={selectedEvent.confirmedMeetings}
              disabledMeetingRequest={selectedEvent.disabledMeetingRequest}
              boothSP={selectedEvent.booths}
            />
          )}
        </TabPanel>
        <TabPanel tabId="meetings">
          {() => (
            <EventMeetingsTab
              confirmedMeetingLimit={selectedEvent.confirmedMeetingsLimit}
              confirmedMeetings={selectedEvent.confirmedMeetings}
              disabledMeetingRequest={selectedEvent.disabledMeetingRequest}
              eventId={selectedEvent.eventId}
              tab={tab}
              subtab={subtab}
              path={`/summits/${selectedEvent.eventId}/`}
            />
          )}
        </TabPanel>
        {selectedEvent.amRegistered && (
          <TabPanel tabId="schedule">
            {() => (
              <Schedule
                eventId={selectedEvent.eventId}
                companyEventId={selectedEvent.companyEventId}
                defaultTimezone={selectedEvent.timeZone}
                virtualSummit={selectedEvent.virtualSummit}
                optimizationDate={selectedEvent.optimizationDate}
                agendaUrl={selectedEvent?.agendaUrl}
              />
            )}
          </TabPanel>
        )}
        <TabPanel tabId="agenda">
          {() => <Agenda eventId={selectedEvent.eventId} />}
        </TabPanel>
      </Tabs>
    </div>
  )
}

SummitDetails.propTypes = {
  events: PropTypes.arrayOf(PropTypes.any),
  selectedEvent: PropTypes.shape({
    eventId: PropTypes.number.isRequired,
    companyEventId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    isOptimized: PropTypes.bool.isRequired,
    schedulerAccessDate: PropTypes.instanceOf(Date),
    optimizationDate: PropTypes.instanceOf(Date),
    creditsAvailable: PropTypes.number,
    contextPoints: PropTypes.number.isRequired,
    timeZone: PropTypes.string.isRequired,
    confirmedMeetingsLimit: PropTypes.number.isRequired,
    confirmedMeetings: PropTypes.number.isRequired,
    disabledMeetingRequest: PropTypes.bool.isRequired,
    virtualSummit: PropTypes.bool.isRequired,
    amRegistered: PropTypes.bool.isRequired,
    booths: PropTypes.array,
    agendaUrl: PropTypes.string,
  }).isRequired,
  role: PropTypes.string.isRequired,
  subtab: PropTypes.string,
}

export default SummitDetails
