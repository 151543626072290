import * as React from 'react'
import PropTypes from 'prop-types'
import { FormLabel, FormMessage } from '@context365/forms'
import { get, useFieldArray, useFormContext } from 'react-hook-form'
import { useFieldSchema } from '../Form'

export function ArrayFormField({
  name,
  label,
  defaultValue,
  element,
  addButton,
}) {
  const {
    formState: { errors },
  } = useFormContext()
  const error = get(errors, name)
  const { fields, append, remove } = useFieldArray({ name })
  const rules = useFieldSchema(name, label)

  return (
    <div>
      <FormLabel>{label}</FormLabel>
      <FormMessage errorMessage={error?.message} />
      <div className="flex flex-col gap-4">
        {fields.map((field, index) => (
          <div key={field.id}>
            {React.cloneElement(element, {
              name: `${name}.${index}`,
              index,
              removeItem: () => remove(index),
            })}
          </div>
        ))}
      </div>
      {React.cloneElement(addButton, {
        onClick: () => append(defaultValue),
        disabled: fields.length >= rules.max,
      })}
    </div>
  )
}

ArrayFormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.object,
  element: PropTypes.node,
  addButton: PropTypes.node.isRequired,
}
