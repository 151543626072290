import { useCallback, useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { Button, Slider, message } from 'antd'
import numeral from 'numeral'
import { Prompt } from 'react-router-dom'
import { api as http } from '~/api/services'
import Loading from '~/components/Loading'
import './MatchmakingSettings.less'

const MatchmakingSettings = ({ onSaveClicked, fromCfnSettings = false }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [thresholds, setThresholds] = useState(null)
  const [marks, setMarks] = useState(null)
  const [currentThreshold, setCurrentThreshold] = useState(0)
  const [allowedFunds, setAllowedFunds] = useState(0)
  const [allowSave, setAllowSave] = useState(true)
  const [contentChanged, setContentChanged] = useState(false)

  const fetchThresholds = useCallback(() => {
    const getMarks = (thresholds) => {
      let thresholdMarks = {}
      thresholds.forEach((threshold) => {
        const mark = {
          [threshold.thresholdValue * 100]: {
            style: {
              transform: 'translatey(-50px) translate(50%)',
            },
            label: threshold.thresholdName,
          },
        }

        thresholdMarks = { ...thresholdMarks, ...mark }
      })
      setMarks(thresholdMarks)
    }

    setIsLoading(true)
    http
      .get('/investors/matchmakingThreshold')
      .then((response) => {
        setThresholds(response.data.result)
        getMarks(response.data.result.marks)
        setCurrentThreshold(
          response.data.result.matchmakingThreshold * 100 || 0
        )
        const funds = response.data.result.marks.find(
          (x) =>
            x.thresholdValue ===
            (response.data.result.matchmakingThreshold || 0)
        )
        funds && setAllowedFunds(funds.fundPercentage * 100)
      })

      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    fetchThresholds()
  }, [fetchThresholds])

  const handleStepChange = (value) => {
    const funds =
      thresholds.marks.find((x) => x.thresholdValue * 100 === value)
        .fundPercentage * 100
    setAllowedFunds(funds)
    setCurrentThreshold(value)
    setAllowSave(true)
    setContentChanged(true)
  }

  const handleSaveSettings = () => {
    setIsSaving(true)
    const requestEndpoint = '/investors/matchmakingThreshold'
    let threshold = 0
    if (currentThreshold > 0) {
      threshold = currentThreshold / 100
    }
    http
      .patch(requestEndpoint, { Threshold: threshold })
      .then(() => {
        message.success('Matchmaking Settings have been successfully saved')
        setAllowSave(false)
      })
      .catch(() => message.error('There was an error while attempting to save'))
      .finally(() => {
        setIsSaving(false)
        setContentChanged(false)
        fromCfnSettings && onSaveClicked()
      })
  }

  return (
    <Loading spinning={isLoading}>
      <Prompt
        message="Are you sure you want to leave without saving?"
        when={contentChanged}
      />
      <div className="cc-container">
        <div className="cc-matchmaking-settings">
          <p className="cc-matchmaking-settings-title">Matchmaking Settings</p>
          <p className="cc-matchmaking-settings-description">
            <span>
              Your matchmaking threshold limits the amount of funds that can
              reach out to you.
              <br />
              You will be able to reach out to any funds on the platform, and
              can adjust this threshold in your settings anytime.
            </span>
          </p>
          <div className="cc-matchmaking-settings-text">
            I want to allow
            <span className="text-brand-100">
              {` ${numeral(allowedFunds).format('0')}% `}
            </span>
            of funds to reach out to me
          </div>
          {thresholds && marks && (
            <Slider
              className="cc-matchmaking-settings-slider"
              marks={marks}
              step={null}
              defaultValue={thresholds.matchmakingThreshold * 100 || 0}
              included={false}
              onChange={(value) => handleStepChange(value)}
              reverse={true}
              tooltipVisible={false}
            />
          )}
          <Button
            className="cc-matchmaking-settings-button"
            onClick={() => handleSaveSettings()}
            loading={isSaving}
            disabled={!allowSave}
          >
            Save Matchmaking Preferences
          </Button>
        </div>
      </div>
    </Loading>
  )
}
MatchmakingSettings.propTypes = {
  onSaveClicked: PropTypes.func,
  fromCfnSettings: PropTypes.bool,
}

export default MatchmakingSettings
