import PropTypes from 'prop-types'
import './DiscoverCardStat.less'

const DiscoverCardStat = ({ title, data, format }) => (
  <>
    <div className="discover-card-stat-title">{title}</div>
    <div className="discover-card-stat-details">
      {data != null && (format == null ? data : format(data))}
    </div>
  </>
)

DiscoverCardStat.propTypes = {
  title: PropTypes.node,
  data: PropTypes.any,
  format: PropTypes.func,
}

export default DiscoverCardStat
