import { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isNil from 'lodash/isNil'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { changeCompany, reset, updateOnboarded } from '~/actions/auth'
import { getCompaniesToOnboard } from '~/actions/onboarding'
import { getCompanyId, getOnboardStep, hasOnboarded } from '~/selectors/auth'
import { roleFlows } from './OnboardingFlow'

const OnboardingWizard = () => {
  const history = useHistory()
  const [step, setStep] = useState(1)
  const [companyId, setCompanyId] = useState(null)
  const [role, setRole] = useState('Allocator')
  const [companies, setCompanies] = useState(null)
  const [questions, setQuestions] = useState([])
  const [loading, setLoading] = useState(true)
  const [isBackVisible, setBackVisible] = useState(true)
  const [switchingCompanies, setSwitchingCompanies] = useState(false)
  const onboarded = useSelector(hasOnboarded)
  const onboardStep = useSelector(getOnboardStep)
  const loggedCompanyId = useSelector(getCompanyId)
  const userRole = useSelector((state) => state.auth.role)

  const { allowCfnOnboarding } = useFlags()
  const dispatch = useDispatch()

  if (isNil(companies)) {
    getCompaniesToOnboard()
      .then((response) => {
        setCompanies(response.data.result)
      })
      .catch(() =>
        message.error(
          'There was an error while attempting to fetch company data'
        )
      )
      .finally(() => setLoading(false))
  }

  const store = useStore()
  const currentRoleFlow = roleFlows.roles.find((x) => x.role === role)
  let currentStep
  if (!isNil(currentRoleFlow)) {
    currentStep = currentRoleFlow.flow.find((x) => x.stepNumber === step)
    if (!isNil(currentStep)) {
      if (!window.location.pathname.includes(currentStep.url)) {
        window.history.pushState('', '', currentStep.url)
      }
    }
  }

  const getQuestionsForCFN = useCallback(() => {
    if (!companies[0].hasCompanyCFNOnboarded) {
      setStep(companies[0].companyCFNStep + 2)
    } else if (!companies[0].hasCompanyContactOnboarded) {
      setStep(companies[0].companyContactStep + 8)
    }

    if (!isNil(companies) && companies.length === 0) {
      store.dispatch(updateOnboarded())
      history.push('/')
      return
    }

    setRole(companies[0].category)
    setQuestions([])
    setCompanyId(companies[0].companyID)
  }, [companies, history, store])

  useEffect(() => {
    const shouldShowBack =
      step > 1 && !(companies && companies.length === 1 && step === 2)
    setBackVisible(shouldShowBack)
  }, [step, companies])

  useEffect(() => {
    if (!isNil(companies) && companies.length === 1 && step === 1) {
      if (companies[0].category === 'CFN') {
        if (allowCfnOnboarding !== false) {
          if (companies[0].companyID !== loggedCompanyId) {
            setSwitchingCompanies(true)
            dispatch(changeCompany(companies[0].companyID)).then(() => {
              setSwitchingCompanies(false)
            })
          }
          getQuestionsForCFN()
          return
        } else if (allowCfnOnboarding === false) {
          store.dispatch(reset())
          history.push('/login')
        } else return
      }

      if (companies[0].hasCompanyOnboarded === true) {
        if (companies[0].hasContactOnboarded === false) {
          if (companies[0].contactStep == 0) setStep(4)
          else if (companies[0].contactStep == 1) setStep(5)
        } else if (companies[0].hasCompanyContactOnboarded === false) {
          if (companies[0].companyContactStep === 0) setStep(6)
          else if (companies[0].companyContactStep >= 1) setStep(7)
        }
      } else setStep(2)
      setQuestions([])
      setCompanyId(companies[0].companyID)
      setRole(companies[0].category)
    } else if (!isNil(companies) && companies.length === 0 && step < 3) {
      if (onboarded !== true) {
        if (userRole === 'Guest Presentation') {
          setRole(userRole)
          setStep(5)
          return
        }
        setRole('Manager')
        if (onboardStep === 0 || onboardStep === null) setStep(4)
        else if (onboardStep === 1) setStep(5)
      } else {
        store.dispatch(updateOnboarded())
        history.push('/')
      }
    }
  }, [
    allowCfnOnboarding,
    companies,
    dispatch,
    getQuestionsForCFN,
    history,
    loggedCompanyId,
    onboardStep,
    onboarded,
    step,
    store,
  ])

  const getCFNNextStep = useCallback(
    (selectedCompany) => {
      if (step === 7 && selectedCompany.hasCompanyContactOnboarded) {
        store.dispatch(updateOnboarded())
        setCompanies(null)
        setQuestions([])
        setStep(1)
      } else setStep(step + 1)
    },
    [step, store]
  )

  const onNext = useCallback(() => {
    const selectedCompany = companies.find((x) => x.companyID === companyId)
    if (step === 1 && selectedCompany.hasCompanyOnboarded === true) {
      if (selectedCompany.hasContactOnboarded === true) {
        if (selectedCompany.companyContactStep === 0)
          selectedCompany.isCFN ? setStep(7) : setStep(6)
        else if (selectedCompany.companyContactStep === 1)
          selectedCompany.isCFN ? setStep(8) : setStep(7)
      } else if (selectedCompany.contactStep === 0) setStep(4)
      //Determines going to contact allocator
      else if (selectedCompany.contactStep === 1) setStep(5)
    } else if (
      step === 3 &&
      selectedCompany.hasContactOnboarded === true &&
      selectedCompany.category !== 'CFN'
    ) {
      if (selectedCompany.category === 'Allocator') {
        if (selectedCompany.companyContactStep == 0)
          selectedCompany.isCFN ? setStep(7) : setStep(6)
        else if (selectedCompany.companyContactStep == 1)
          selectedCompany.isCFN ? setStep(8) : setStep(7)
        else setStep(step + 1)
      } else {
        store.dispatch(updateOnboarded())
        setCompanies(null)
        setQuestions([])
        setStep(1)
      }
    } else if (step === 3 && selectedCompany.hasContactOnboarded === false) {
      if (selectedCompany.contactStep === 0) setStep(4)
      else if (selectedCompany.contactStep === 1) setStep(5)
    } else {
      selectedCompany?.category === 'CFN'
        ? getCFNNextStep(selectedCompany)
        : setStep(step + 1)
    }
  }, [companies, companyId, getCFNNextStep, step, store])

  const onBack = useCallback(() => {
    if (currentStep.canGoBack === false) return
    if (currentStep.stepNumber !== 3) {
      setQuestions([])
    }
    setStep(step - 1)
  }, [currentStep.canGoBack, currentStep.stepNumber, step])

  const LayoutScreen = currentStep.layoutScreen

  const onCompanyChanged = useCallback(
    (newCompanyId) => {
      if (companyId !== newCompanyId) {
        setCompanyId(newCompanyId)
        setQuestions([])
        const selectedCompany = companies.find(
          (x) => x.companyID === newCompanyId
        )
        const selectedRole = selectedCompany.category
        setRole(selectedRole)
        dispatch(changeCompany(newCompanyId)).then(
          ({ payload: { claims, contact } }) => {
            console.log('CompanySwitchList.js::claims =', claims)
            console.log('CompanySwitchList.js::contact =', contact)
          }
        )
      }
    },
    [companies, companyId, dispatch]
  )

  const onInvestorStatusChange = useCallback(
    (questionShortName, selections) => {
      const currentQuestions = questions.find((x) => x.step === step)
      if (isNil(currentQuestions)) {
        questions.push({
          step: 6,
          questions: [
            {
              entity: 'CompanyContactAllocator',
              shortName: questionShortName,
              answer: selections.map((x) => x.split('|')[1]),
              questionTypeID: -1,
            },
          ],
        })
      } else {
        currentQuestions.questions[0].answer = selections.map(
          (x) => x.split('|')[1]
        )
        currentQuestions.questions[0].shortName = questionShortName
      }
      setQuestions(questions)
    },
    [questions, step]
  )

  const onQuestionChange = useCallback(
    (formQuestions) => {
      const currentQuestions = questions.find((x) => x.step === step)
      if (isNil(currentQuestions)) {
        questions.push({
          step,
          questions: formQuestions,
        })
      } else {
        currentQuestions.questions = formQuestions
      }
      setQuestions(questions)
    },
    [questions, step]
  )

  const clearQuestions = () => {
    setQuestions([])
  }
  return (
    <div>
      {!switchingCompanies && (
        <LayoutScreen
          companies={companies}
          onCompanyChanged={onCompanyChanged}
          component={currentStep.component}
          currentQuestions={questions}
          title={currentStep.title}
          onNext={onNext}
          onBack={onBack}
          backVisible={isBackVisible}
          step={currentStep}
          onChange={onQuestionChange}
          companyId={companyId}
          actionType={currentStep.actionType}
          loading={loading}
          onInvestorStatusChange={onInvestorStatusChange}
          history={history}
          showAlert={currentStep.showAlert}
          setStep={setStep}
          setCompanies={setCompanies}
          clearQuestions={clearQuestions}
        />
      )}
    </div>
  )
}

OnboardingWizard.propTypes = {}

export default OnboardingWizard
