import PropTypes from 'prop-types'
import { faTimesCircle, faUserPlus } from '@fortawesome/pro-regular-svg-icons'
import {
  faCheck,
  faInfoCircle,
  faUserMinus,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isNil from 'lodash/isNil'
import DataTransformation from '../DataTransformations'
import './LogMessageItem.less'

const iconTitleDict = {
  3: {
    title: 'Meeting Requested',
    icon: faInfoCircle,
  },
  4: {
    title: 'Meeting Accepted',
    icon: faCheck,
  },
  5: {
    title: 'Meeting Declined',
    icon: faTimesCircle,
  },
  6: {
    title: 'Meeting Rescheduled',
    icon: faInfoCircle,
  },
  7: {
    title: 'Meeting Cancelled',
    icon: faTimesCircle,
  },
  8: {
    title: 'Conversation Started',
    icon: faInfoCircle,
  },
  9: {
    title: 'New people added to the conversation',
    icon: faUserPlus,
  },
  10: {
    title: '',
    icon: faUserMinus,
  },
  11: {
    title: 'Meeting Scheduled',
    icon: faInfoCircle,
  },
}

const LogMessageItem = ({ message, logTypeID, logType, time }) => {
  // the log message is actually a JSON object that has message and
  // parameters with values to replace placeholders in the message
  let parsedMessage
  try {
    parsedMessage = JSON.parse(message)
    if (isNil(parsedMessage.message))
      parsedMessage.message = parsedMessage.Message
    if (isNil(parsedMessage.parameters))
      parsedMessage.parameters = parsedMessage.Parameters
  } catch {
    parsedMessage = {
      message,
    }
  }
  let params = parsedMessage.parameters
  if (isNil(params)) {
    params = []
  }

  // all the message is put as a string in a variable to be shown as HTML and not as text
  let body = `<span>${parsedMessage.message.replace('\\n', '<br/>')}<span>`

  for (let i = 1; i <= 10; i++) {
    const simpleReplace = `{${i}}`
    const dateTimeReplace = `{{${i}}}`

    if (body.indexOf(dateTimeReplace) >= 0) {
      body = body.replace(
        dateTimeReplace,
        DataTransformation.RenderTimeForLogMessages(params[i - 1])
      )
    } else if (body.indexOf(simpleReplace) >= 0) {
      body = body.replace(simpleReplace, params[i - 1])
    }
  }

  // in case the log is 'Left Conversation', change the layout a bit
  // and display the message in the title
  // (because no two things should be the same as per request!!!!)
  if (logTypeID === 10) {
    return (
      <div className={`messaging-message-log messaging-message-log-${logType}`}>
        <div className="cc-xsmall-font messaging-message-log-time">
          {DataTransformation.RenderTimeForLogMessages(time)}
        </div>
        <div className="messaging-message-log-title">
          <FontAwesomeIcon icon={iconTitleDict[logTypeID].icon} />
          {parsedMessage.message}
        </div>
      </div>
    )
  }

  return (
    <div className={`messaging-message-log messaging-message-log-${logType}`}>
      <div className="cc-xsmall-font messaging-message-log-time">
        {DataTransformation.RenderTimeForLogMessages(time)}
      </div>
      <div className="messaging-message-log-title">
        <FontAwesomeIcon icon={iconTitleDict[logTypeID].icon} />
        {iconTitleDict[logTypeID].title}
      </div>
      {/* message is passed as HTML since it's a rich text message */}
      <div
        className="cc-small-font messaging-message-log-description"
        dangerouslySetInnerHTML={{
          __html: body,
        }}
      />
    </div>
  )
}

LogMessageItem.propTypes = {
  message: PropTypes.string.isRequired,
  logTypeID: PropTypes.number.isRequired,
  logType: PropTypes.string.isRequired,
  time: PropTypes.object.isRequired,
}

export default LogMessageItem
