import PropTypes from 'prop-types'
import {
  faBuilding,
  faCalendarTimes,
  faMapMarkerAlt,
  faTimesHexagon,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Empty, Row, Typography } from 'antd'
import isNil from 'lodash/isNil'
import { useHistory } from 'react-router-dom'
import CountDetails from '~/components/CountDetails'
import SummitDateLabel from './SummitDateLabel'
import './AttendingRoadshow.less'

const { Paragraph } = Typography

const AttendingRoadshow = ({ roadshow, oneEvent }) => {
  const { push } = useHistory()

  if (!isNil(roadshow)) {
    const poster = isNil(roadshow.posterImage) ? (
      <div className="AvailableEvent-poster-empty">
        <FontAwesomeIcon icon={faTimesHexagon} size="5x" color="#EFF1F4" />
      </div>
    ) : (
      <div
        className="AvailableEvent-poster"
        style={{
          //backgroundImage: `url(${event.posterImage})`,
          cursor: 'pointer',
        }}
        onClick={() => push(`/roadshow/${roadshow.roadShowId}`)}
      />
    )
    return (
      <div
        className="AttendingRoadshow"
        style={{ borderRadius: '8px', marginTop: 'none' }}
      >
        {poster}
        <div
          className="AttendingRoadshow-info"
          style={{ padding: '20px 24px', color: 'inherit' }}
        >
          <Row type="flex" justify="space-between">
            <Col span={12}>
              <div style={{ height: '90px' }}>
                <h5
                  style={{
                    lineHeight: '18px',
                    color: 'inherit',
                    fontSize: '16px',
                  }}
                >
                  {roadshow.name}
                </h5>
                <p className="AttendingRoadshow-info-location">
                  <span className="AttendingRoadshow-info-icon">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </span>
                  {roadshow.location}
                  <span
                    style={{ display: 'inline-block', marginRight: '2em' }}
                  />
                  <span className="AttendingRoadshow-info-icon">
                    <FontAwesomeIcon icon={faBuilding} />
                  </span>
                  {/* {event.eventHost} */}
                </p>
              </div>
            </Col>
            <Col span={12}>
              <p style={{ textAlign: 'right' }}>
                <SummitDateLabel
                  startDate={roadshow.dateFromUtc}
                  endDate={roadshow.dateToUtc}
                  showYear={false}
                  isFancy={false}
                />
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              span={oneEvent ? 16 : 24}
              className={!oneEvent && 'AttendingRoadshow-info-details'}
            >
              <CountDetails
                count={roadshow.participants}
                label="Participants"
              />

              <CountDetails
                count={roadshow.confirmed}
                label="Confirmed Meetings"
              />

              <CountDetails
                count={roadshow.requests}
                label="Meeting Requests"
              />
            </Col>
          </Row>

          <div style={{ height: '1em' }} />

          <Row type="flex" justify="end">
            {/* <Col xl={6} lg={6} md={8} sm={24} xs={24}>
                <SummitInfoLabel
                  title="Package"
                  description={event.packageName}
                />
              </Col> */}
            {/* <Col xl={6} lg={6} md={8} sm={24} xs={24}>
                {event.suite && (
                  <SummitInfoLabel
                    title="Suite Location"
                    description={event.suite}
                  />
                )}
              </Col> */}

            <div className="AttendingRoadshow-action">
              <Button
                type="primary"
                size="large"
                onClick={() => push('/meetings/roadshow/received')}
              >
                View Meetings
              </Button>
            </div>
          </Row>
        </div>
      </div>
    )
  } else {
    return (
      <div className="AttendingRoadshow AttendingRoadshow-empty">
        <Empty
          image={
            <FontAwesomeIcon icon={faCalendarTimes} size="3x" color="gray" />
          }
          description={
            <>
              <Paragraph>You are not attending any roadshows.</Paragraph>
              <Paragraph type="warning" strong>
                Please check the available roadshows.
              </Paragraph>
            </>
          }
        />
      </div>
    )
  }
}

AttendingRoadshow.propTypes = {
  roadshow: PropTypes.shape({
    roadshowId: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    location: PropTypes.string,
    dates: PropTypes.string.isRequired,
    dateFromUtc: PropTypes.string.isRequired,
    dateToUtc: PropTypes.string.isRequired,
    // eventParticipants: PropTypes.number.isRequired,
    // confirmedMeetings: PropTypes.number.isRequired,
    // meetingRequests: PropTypes.number.isRequired,
  }),
  push: PropTypes.func.isRequired,
  oneEvent: PropTypes.bool.isRequired,
}

export default AttendingRoadshow
