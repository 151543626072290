import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { api as http } from '~/api/services'
import { addLineBreaks } from '~/utils/helpers'
import '../CompanyProfileDetails/CompanyProfileDetails.less'

const AllocatorInfoProfile = ({ companyId }) => {
  const [investorsProfile, setInvestorsProfile] = useState({})

  useEffect(() => {
    http
      .get(`/investors/${companyId}/profile`)
      .then((response) => setInvestorsProfile(response.data.result))
  }, [companyId])

  const {
    companyDescription,
    minimumInvestmentSize,
    averageInvestmentSize,
    minimumFundSizeInterest,
    maximumFundSizeInterest,
  } = investorsProfile
  return (
    <div style={{ marginTop: '32px' }}>
      {companyDescription !== '' && (
        <div>
          <span className="type-subtitle-sm text-header">
            Company Description
          </span>
          <div className="cc-company-desc">
            {addLineBreaks(companyDescription)}
          </div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          marginTop: '32px',
          width: '50%',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        {(minimumInvestmentSize > 0 || averageInvestmentSize > 0) && (
          <div className="cc-company-desc-numbers-containers">
            <div>
              <span className="type-subtitle-sm text-header">
                Investment Size
              </span>
              <div className="cc-company-desc-numbers">
                {minimumInvestmentSize && minimumInvestmentSize > 0 && (
                  <div>
                    <div className="type-body-regular-lg">
                      {numeral(minimumInvestmentSize).format('($0 a)')}
                    </div>
                    <span className="cc-darkest-grey-text cc-xsmall-font">
                      Minimum
                    </span>
                  </div>
                )}
                {averageInvestmentSize && (
                  <div>
                    <div className="type-body-regular-lg">
                      {numeral(averageInvestmentSize).format('($0 a)')}
                    </div>
                    <span className="cc-darkest-grey-text cc-xsmall-font">
                      Average
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {(minimumFundSizeInterest > 0 || maximumFundSizeInterest > 0) && (
          <div className="cc-company-desc-numbers-containers">
            <div>
              <span className="type-subtitle-sm text-header">
                Fund Size Interest
              </span>
              <div className="cc-company-desc-numbers">
                {minimumFundSizeInterest > 0 && (
                  <div>
                    <div className="type-body-regular-lg">
                      {numeral(minimumFundSizeInterest).format('($0 a)')}
                    </div>
                    <span className="cc-darkest-grey-text cc-xsmall-font">
                      Minimum
                    </span>
                  </div>
                )}
                {maximumFundSizeInterest > 0 && (
                  <div>
                    <div className="type-body-regular-lg">
                      {numeral(maximumFundSizeInterest).format('($0 a)')}
                    </div>
                    <span className="cc-darkest-grey-text cc-xsmall-font">
                      Maximum
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

AllocatorInfoProfile.propTypes = {
  companyId: PropTypes.number.isRequired,
}

export default AllocatorInfoProfile
