import PropTypes from 'prop-types'
import { PictureAsPdfOutlined } from '@context365/icons'
import { faFileExcel, faFileWord } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Empty } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { getFileExtension } from '~/utils/helpers'

const getFileIcon = (fileName) => {
  const ext = getFileExtension(fileName)

  switch (ext) {
    case 'pdf': {
      return <PictureAsPdfOutlined className="text-red-100" size={24} />
    }
    case 'xls':
    case 'xlsx': {
      return (
        <FontAwesomeIcon
          className="text-green-100"
          style={{ width: '24px', height: '24px' }}
          icon={faFileExcel}
        />
      )
    }
    case 'doc':
    case 'docx': {
      return (
        <FontAwesomeIcon
          className="text-blue-100"
          style={{ width: '24px', height: '24px' }}
          icon={faFileWord}
        />
      )
    }
    default:
      return null
  }
}

const FileCollection = ({
  files,
  title,
  entityId,
  type,
  showEmptyState = true,
}) => {
  return (
    <div>
      {!isEmpty(files) &&
        map(files, (x, index) => (
          <a
            className="flex items-center mb-4 bg-white rounded-big border border-grey-300 border-solid shadow-0 hover:border-secondary-100 hover:bg-secondary-2 hover:shadow-6 hover:cursor-pointer"
            key={index}
            href={
              getFileExtension(x.mediaUrl) === 'pdf'
                ? `/attachment/${encodeURIComponent(
                    title
                  )}/${entityId}/${type}?file=${encodeURIComponent(x.mediaUrl)}`
                : x.mediaUrl
            }
            target="_blank"
            rel="noreferrer"
          >
            <div className="ml-4 w-6">{getFileIcon(x.mediaUrl)}</div>
            <div className="p-4 text-md text-secondary-100 break-all">
              {isNil(x.mediaUrl) || x.mediaUrl === ''
                ? 'UNTITLED'
                : decodeURI(x?.mediaUrl.split('/').pop())}
            </div>
          </a>
        ))}
      {isEmpty(files) && showEmptyState && (
        <div className="text-grey-400 my-5 text-xs">
          <Empty description="No documents attached." />
        </div>
      )}
    </div>
  )
}

FileCollection.propTypes = {
  files: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  showEmptyState: PropTypes.bool,
}

export default FileCollection
