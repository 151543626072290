import { useEffect, useState } from 'react'
import { getCompanyFunds } from '~/actions/discover'
import { getDiscoverServiceProviderProfile } from '~/actions/serviceProviders'
import CompanyCategories from '~/constants/companyCategories'

export default function useServiceProvider(
  companyId,
  companyCategoryId,
  userCompanyId,
  userRole
) {
  const [serviceProvider, setServiceProvider] = useState(null)
  const [defaultFund, setDefaultFund] = useState(null)

  useEffect(() => {
    if (
      userRole !== 'Manager' ||
      companyCategoryId !== CompanyCategories.ServiceProvider
    ) {
      return
    }

    getCompanyFunds(userCompanyId).then((response) => {
      setDefaultFund(response.data.result[0])
    })
  }, [userRole, userCompanyId, companyCategoryId])

  useEffect(() => {
    if (companyCategoryId !== CompanyCategories.ServiceProvider) {
      return
    }

    getDiscoverServiceProviderProfile(companyId).then((response) => {
      const { result } = response.data
      setServiceProvider(result)
    })
  }, [companyId, companyCategoryId])

  return { serviceProvider, defaultFund }
}
