import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NotificationsOutlined } from '@context365/icons'
import { Modal } from 'antd'
import get from 'lodash/get'
import numeral from 'numeral'
import { getSearchAlerts } from '~/actions/discover'
import CardSearchAlert from './CardSearchAlert'
import './CreateSearchAlert.less'

const filterMap = {
  1: 'Liquid',
  2: 'Illiquid',
}

const ManageSearchAlert = ({
  visible,
  closeModal,
  setSearchState,
  category,
}) => {
  const [alerts, setAlerts] = useState([])

  const getAlerts = useCallback(() => {
    getSearchAlerts(category).then((response) => {
      setAlerts(response.data.result)
    })
  }, [category])

  useEffect(() => {
    if (visible) {
      getAlerts()
    }
  }, [getAlerts, visible])

  const handleModalCancel = useCallback(() => {
    closeModal()
  }, [closeModal])

  const formatFilters = useCallback((filters) => {
    return filters.map((x) =>
      x.alertColumnType === 'Select'
        ? {
            Column: `${x.alertColumn}:`,
            FilterValue: x.values.map((v) => v.filterValue),
            DisplayValue: x.values.map((v) => v.filterValue),
          }
        : x.alertColumnType === 'Bool' && x.alertColumn !== 'Type'
        ? {
            Column: `${x.alertColumn}:`,
            FilterValue: x.values.map((v) => v.filterValue),
            DisplayValue: x.values.map((v) =>
              v.filterValue === 'true' ? 'Yes' : 'No'
            ),
          }
        : x.alertColumnType === 'Check'
        ? {
            Column: `${x.alertColumn}:`,
            FilterValue: 'Yes',
            DisplayValue: 'Yes',
          }
        : x.alertColumn === 'Type'
        ? {
            Column: `${x.alertColumn}:`,
            FilterValue: x.values.map((v) => v.filterValue),
            DisplayValue: x.values.map((v) => {
              return v.filterValue === true || v.filterValue === 'true'
                ? 'Anonymous'
                : 'Public'
            }),
          }
        : {
            Column: `${x.alertColumn}:`,
            FilterValue:
              x.alertColumnType === 'Type'
                ? x.values.map((v) => get(filterMap, v.filterValue))
                : x.values.map((v) => v.filterValue),
            DisplayValue:
              x.alertColumnType === 'RangePercentage'
                ? x.alertColumnKey.includes('deepestDrawdown') ||
                  x.alertColumnKey.includes('YtdReturn') ||
                  x.alertColumnKey.includes('managementFee') ||
                  x.alertColumnKey.includes('performanceFee')
                  ? x.values.map(
                      (v) => `${numeral(v.filterValue).format('0[.]00')} %`
                    )
                  : x.values.map(
                      (v) =>
                        `${numeral(v.filterValue * 100).format('0[.]00')} %`
                    )
                : x.alertColumnType === 'Type'
                ? x.values.map((v) => get(filterMap, v.filterValue))
                : x.values.map((v) => v.filterValue),
          }
    )
  }, [])

  return (
    <Modal
      destroyOnClose
      className="cc-modal-accept"
      title="Manage Search Alerts"
      visible={visible}
      cancelText="Close"
      onCancel={handleModalCancel}
      width="555px"
      bodyStyle={{ padding: '24px' }}
      footer={null}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <div className="p-8">
          <NotificationsOutlined className="text-brand-100 mb-4" size={48} />
          <div>
            <h4 className="mainHeading">Manage Search Alerts</h4>
            <p className="subInfo">
              We are currently sending you alerts for leads that fit into your
              filter criteria.
            </p>
          </div>
        </div>
      </div>
      {alerts.map((x) => (
        <CardSearchAlert
          filters={formatFilters(x.filters)}
          originalFilters={x}
          setSearchState={setSearchState}
          refreshData={getAlerts}
          key={x.alertEntryId}
        />
      ))}
    </Modal>
  )
}

ManageSearchAlert.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  setSearchState: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
}

export default ManageSearchAlert
