import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faTwitter,
  faWikipediaW,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'antd'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import { getClickableLink } from '~/utils/helpers'
import './ServiceProviderContent.less'
import './ServiceProviderContact.less'

const ServiceProviderContact = ({
  company,
  canSendMeetingRequest,
  onSendRequest = () => {},
  onSendMessage = () => {},
  hideActions,
}) => (
  <Row style={{ minHeight: '200px' }}>
    <Col span={18}>
      {company.city || company.country ? (
        <div>
          <span className="type-subtitle-sm text-header">Address</span>
          <div>
            {join(
              filter(
                [
                  company.address1 || company.address2 || company.address3,
                  company.city,
                  company.stateProvince,
                  company.postalCode,
                  company.country,
                ],
                (f) => !isNil(f) && f !== ''
              ),
              ', '
            )}
          </div>
        </div>
      ) : (
        <div className="ServiceProviderContact-description">
          Don’t hesitate to connect with us on Context365
          {!hideActions && (
            <span>
              {' '}
              by
              <span
                className="ServiceProviderContact-link"
                onClick={() => onSendMessage()}
              >
                {' '}
                sending a message{' '}
              </span>
              {canSendMeetingRequest && (
                <span>
                  or{' '}
                  <span
                    className="ServiceProviderContact-link"
                    onClick={() => onSendRequest()}
                  >
                    a meeting request
                  </span>
                </span>
              )}
            </span>
          )}
          .
        </div>
      )}
    </Col>
    <Col span={6} className="flex items-start">
      {!isNil(company.facebookUrl) && !isEmpty(company.facebookUrl) && (
        <Button
          as="a"
          variant="link"
          href={getClickableLink(company.facebookUrl)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon size="3x" icon={faFacebookSquare} />
        </Button>
      )}
      {((!isNil(company.twitterUrl) && !isEmpty(company.twitterUrl)) ||
        (!isNil(company.twitter) && !isEmpty(company.twitter))) && (
        <Button
          as="a"
          variant="link"
          href={getClickableLink(company.twitterUrl || company.twitter)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon size="3x" icon={faTwitter} />
        </Button>
      )}
      {((!isNil(company.linkedInUrl) && !isEmpty(company.linkedInUrl)) ||
        (!isNil(company.linkedIn) && !isEmpty(company.linkedIn))) && (
        <Button
          as="a"
          variant="link"
          href={getClickableLink(company.linkedInUrl || company.linkedIn)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon size="3x" icon={faLinkedin} />
        </Button>
      )}
      {!isNil(company.instagramUrl) && !isEmpty(company.instagramUrl) && (
        <Button
          as="a"
          variant="link"
          href={getClickableLink(company.instagramUrl)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon size="3x" icon={faInstagram} />
        </Button>
      )}
      {!isNil(company.wikipedia) && !isEmpty(company.wikipedia) && (
        <Button
          as="a"
          variant="link"
          href={getClickableLink(company.wikipedia)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon size="3x" icon={faWikipediaW} />
        </Button>
      )}
    </Col>
  </Row>
)

ServiceProviderContact.propTypes = {
  company: PropTypes.object.isRequired,
  canSendMeetingRequest: PropTypes.bool,
  onSendMessage: PropTypes.func,
  onSendRequest: PropTypes.func,
  hideActions: PropTypes.bool,
}

export default ServiceProviderContact
