export const RESET_APP = 'RESET_APP'

export const FETCH_UPCOMING_MEETINGS = 'FETCH_UPCOMING_MEETINGS'
export const FETCH_ALL_REQUESTED_MEETINGS = 'FETCH_ALL_REQUESTED_MEETINGS'
export const FETCH_SENT_REQUESTS = 'FETCH_SENT_REQUESTS'
export const FETCH_REQUESTED_MEETINGS = 'FETCH_REQUESTED_MEETINGS'
export const FETCH_CONFIRMED_MEETINGS = 'FETCH_CONFIRMED_MEETINGS'
export const FETCH_DECLINED_MEETINGS = 'FETCH_DECLINED_MEETINGS'
export const FETCH_ONLINE_SENT_REQUESTS = 'FETCH_ONLINE_SENT_REQUESTS'
export const FETCH_ONLINE_REQUESTED_MEETINGS = 'FETCH_ONLINE_REQUESTED_MEETINGS'
export const FETCH_ONLINE_CONFIRMED_MEETINGS = 'FETCH_ONLINE_CONFIRMED_MEETINGS'
export const FETCH_ONLINE_DECLINED_MEETINGS = 'FETCH_ONLINE_DECLINED_MEETINGS'
export const FETCH_REQUESTED_MEETINGS_COMPANYEVENTID =
  'FETCH_REQUESTED_MEETINGS_COMPANYEVENTID'
export const FETCH_CONFIRMED_MEETINGS_COMPANYEVENTID =
  'FETCH_CONFIRMED_MEETINGS_COMPANYEVENTID'
export const FETCH_SENT_REQUESTS_COMPANYEVENTID =
  'FETCH_SENT_REQUESTS_COMPANYEVENTID'
export const FETCH_DECLINED_MEETINGS_COMPANYEVENTID =
  'FETCH_DECLINED_MEETINGS_COMPANYEVENTID'
export const FETCH_ONLINE_NOTIFICATION_MEETING =
  'FETCH_ONLINE_NOTIFICATION_MEETING'
export const FETCH_CONTEXT_LIST = 'FETCH_CONTEXT_LIST'
export const FETCH_PORTFOLIO_LIST = 'FETCH_PORTFOLIO_LIST'
export const FETCH_PORTFOLIO_FUNDS = 'FETCH_PORTFOLIO_FUNDS'
export const FETCH_CANCELLATION_REASONS = 'FETCH_CANCELLATION_REASONS'
export const FETCHING_CANCELLATION_REASONS = 'FETCHING_CANCELLATION_REASONS'
export const FETCH_ROADSHOW_SENT_REQUESTS = 'FETCH_ROADSHOW_SENT_REQUESTS'
export const FETCH_ROADSHOW_REQUESTED_MEETINGS =
  'FETCH_ROADSHOW_REQUESTED_MEETINGS'
export const FETCH_ROADSHOW_CONFIRMED_MEETINGS =
  'FETCH_ROADSHOW_CONFIRMED_MEETINGS'
export const FETCH_ROADSHOW_DECLINED_MEETINGS =
  'FETCH_ROADSHOW_DECLINED_MEETINGS'
export const FETCH_DISCOVER_LISTS = 'FETCH_DISCOVER_LISTS'

export const FETCH_USER_ACCESS = 'FETCH_USER_ACCESS'
