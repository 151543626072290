import * as React from 'react'
import PropTypes from 'prop-types'
import { useAxis } from '@context365/charts'
import cx from 'classnames'
import { format } from 'd3-format'
import useElementSize from '~/hooks/useElementSize'
import { numberFormat } from '~/utils/formatters'
import CategoryName from '../../components/CategoryName'
import { useCategoryColors } from '../utils'

// numberFormat.percent's `precision` option doesn't make them required, so it
// drops trailing zeros.
const formatPercent = format('.1%')
const formatPercentDifference = format('+.1%')

export default function AllocationYear({
  year,
  alternativeActualDollars,
  categories,
}) {
  const colors = useCategoryColors(categories)

  return (
    <div className="bg-white border rounded shadow-0 p-6 space-y-4">
      <h3 className="type-header-sm text-header mb-4">{year}</h3>
      <Table>
        <ColumnHeaders>
          <div>Category</div>
          <div>Actual</div>
          <div>% of Alts</div>
          <div className="text-center">Target&nbsp;&nbsp;+&nbsp;/&nbsp;-</div>
        </ColumnHeaders>
        {categories.map((category) => (
          <React.Fragment key={category.categoryId}>
            <CategoryName
              name={category.name}
              backgroundColor={colors[category.categoryId].badgeBackground}
            />
            <div>
              {category.alternativeActualDollars == null
                ? ''
                : numberFormat.currencyLarge(category.alternativeActualDollars)}
            </div>
            <div className="text-right tabular-nums">
              {category.actualPercentage == null
                ? ''
                : formatPercent(category.actualPercentage)}
            </div>
            <AllocationPercentageBar
              target={category.targetPercentage}
              actual={category.actualPercentage}
              color={colors[category.categoryId].data}
            />
          </React.Fragment>
        ))}
      </Table>
      <div className="py-2">
        <hr className="mx-0" />
      </div>
      <Table>
        <ColumnHeaders>
          <div>Sum</div>
          <div>Actual</div>
          <div className="col-span-2" />
        </ColumnHeaders>
        <CategoryName name="Total Alts" abbreviation="alts" />
        <div>
          {alternativeActualDollars == null
            ? ''
            : numberFormat.currencyLarge(alternativeActualDollars)}
        </div>
        <div className="col-span-2" />
      </Table>
    </div>
  )
}

AllocationYear.propTypes = {
  year: PropTypes.number.isRequired,
  alternativeActualDollars: PropTypes.number,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number.isRequired,
      isGlobal: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      alternativeActualDollars: PropTypes.number,
      targetPercentage: PropTypes.number,
      actualPercentage: PropTypes.number,
    })
  ).isRequired,
}

function Table({ children }) {
  return (
    <div
      className="grid gap-x-2 gap-y-4 type-body-regular-sm"
      style={{ gridTemplateColumns: '2fr repeat(2, 1fr) 4fr' }}
    >
      {children}
    </div>
  )
}

function ColumnHeaders({ children }) {
  return (
    <div className="contents type-subtitle-xxs text-grey-800">{children}</div>
  )
}

function AllocationPercentageBar({ target, actual, color }) {
  const container = React.useRef()
  const { width: containerWidth } = useElementSize(container)

  const difference = target == null ? null : (actual - target) / target

  const labelPadding = 60
  const domain = React.useMemo(() => [0, 1], [])
  const range = React.useMemo(
    () => [0, containerWidth - labelPadding],
    [containerWidth, labelPadding]
  )
  const axis = useAxis({
    type: 'linear',
    getValue: (d) => d,
    domain,
    range,
  })

  return (
    <div ref={container} className="relative">
      <div
        className="h-2 rounded-r-full absolute top-1/2 transform -translate-y-1/2"
        style={{ backgroundColor: color, width: axis.getCoordinate(actual) }}
      />
      {target != null && (
        <div
          className="h-6 w-1.5 bg-white px-px absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2"
          style={{ left: axis.getCoordinate(target) }}
        >
          <div className="h-full bg-black rounded-full" />
        </div>
      )}
      {difference != null && (
        <span
          className={cx(
            'type-body-semibold-sm relative',
            difference < 0 ? 'text-red-100' : 'text-green-100'
          )}
          style={{ left: axis.getCoordinate(Math.max(target, actual)) + 8 }}
        >
          {formatPercentDifference(difference)}
        </span>
      )}
    </div>
  )
}
