import PropTypes from 'prop-types'
import get from 'lodash/fp/get'
import AddToListModal from './AddToListModal'

export default function AddServiceProviderToListModal({
  visible,
  serviceProviders,
  onClose,
}) {
  return (
    <AddToListModal
      visible={visible}
      onClose={onClose}
      targetItems={serviceProviders}
      getTargetId={get('companyId')}
      getTargetName={get('companyName')}
      listLinkName="toCompanyId"
    />
  )
}

AddServiceProviderToListModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  serviceProviders: PropTypes.arrayOf(
    PropTypes.shape({
      companyId: PropTypes.number.isRequired,
      companyName: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
}
