import { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import Loading from '~/components/Loading'
import PresentationAttendeesComponent from '~/components/PresentationAttendeesComponent'
import PresentationAttendeesCtx from '~/utils/contexts/PresentationAttendeesCtx'

const PresentationAttendees = () => {
  const { id } = useParams()
  const { goBack } = useHistory()

  const { Track } = useTracking({ page: 'PresentationAttendees' })

  const [presentationParticipants, setPresentationParticipants] = useState([])
  const [title, setTitle] = useState('')
  const [approved, setApproved] = useState(0)
  const [pending, setPending] = useState(0)
  const [rejected, setRejected] = useState(0)
  const [invited, setInvited] = useState(0)
  const [isEnded, setIsEnded] = useState(false)
  const [roles, setRoles] = useState({
    allocator: 0,
    manager: 0,
    serviceProvider: 0,
    cfn: 0,
  })
  const [loading, setLoading] = useState(false)
  const [canceled, setCanceled] = useState(0)
  const [isLive, setIsLive] = useState(false)
  const [isPublished, setIsPublished] = useState(false)
  const [presentationRoles, setPresentationRoles] = useState([])
  const [maxModerators, setMaxModerators] = useState(0)
  const [maxAttendees, setMaxAttendees] = useState(0)
  const [maxPresenters, setMaxPresenters] = useState(0)

  const handleGetPresentationAttendees = useCallback(() => {
    setLoading(true)
    api.presentations
      .getPresentationAttendeesAndRoles(id)
      .then(
        axios.spread((...responses) => {
          const presentationDetails = responses[0]
          setPresentationParticipants(
            presentationDetails.data.result.presentationParticipants
          )
          setTitle(presentationDetails.data.result.title)
          setCanceled(presentationDetails.data.result.isCanceled)
          setIsEnded(presentationDetails.data.result.isEnded)
          setIsLive(presentationDetails.data.result.isLive)
          setIsPublished(presentationDetails.data.result.isPublished)
          setApproved(
            presentationDetails.data.result.presentationParticipants.filter(
              (r) => r.status === 'Approved'
            ).length
          )
          setPending(
            presentationDetails.data.result.presentationParticipants.filter(
              (r) => r.status === 'Pending'
            ).length
          )
          setRejected(
            presentationDetails.data.result.presentationParticipants.filter(
              (r) => r.status === 'Rejected'
            ).length
          )
          setInvited(
            presentationDetails.data.result.presentationParticipants.filter(
              (r) => r.isInvited
            ).length
          )
          setRoles({
            allocator:
              presentationDetails.data.result.presentationParticipants.filter(
                (r) => r.companyRole === 'Allocator'
              ).length,
            manager:
              presentationDetails.data.result.presentationParticipants.filter(
                (r) => r.companyRole === 'Manager'
              ).length,
            serviceProvider:
              presentationDetails.data.result.presentationParticipants.filter(
                (r) => r.companyRole === 'Service Provider'
              ).length,
            cfn: presentationDetails.data.result.presentationParticipants.filter(
              (r) => r.companyRole === 'CFN'
            ).length,
          })
          setPresentationRoles(responses[1].data.result)
          setMaxModerators(presentationDetails.data.result.maxModerators)
          setMaxPresenters(presentationDetails.data.result.maxPresenters)
          setMaxAttendees(presentationDetails.data.result.maxAttendees)
        })
      )
      .catch(() => message.error('Could not get presentation info'))
      .finally(() => setLoading(false))
  }, [id])

  useEffect(() => {
    handleGetPresentationAttendees()
  }, [handleGetPresentationAttendees])

  const presentationAttendeesCtxValue = {
    presentationParticipants,
    title,
    approved,
    pending,
    rejected,
    invited,
    roles,
    isEnded,
    id,
    goBack,
    canceled,
    isLive,
    isPublished,
    handleGetPresentationAttendees,
    presentationRoles,
    maxModerators,
    maxPresenters,
    maxAttendees,
  }

  return (
    <Track>
      <PresentationAttendeesCtx.Provider value={presentationAttendeesCtxValue}>
        <Loading spinning={loading}>
          <PresentationAttendeesComponent />
        </Loading>
      </PresentationAttendeesCtx.Provider>
    </Track>
  )
}

export default PresentationAttendees
