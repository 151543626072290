import PropTypes from 'prop-types'
import { Steps } from 'antd'

const { Step } = Steps

const Wizard = ({ currentStep, onChange }) => (
  <Steps current={currentStep} onChange={onChange} direction="horizontal">
    <Step title="Identification" />
    <Step title="Contact/Location" />
  </Steps>
)

Wizard.propTypes = {
  currentStep: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Wizard
