import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faMicrophone,
  faMicrophoneSlash,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import Loading from '../../Loading'
import ProfilePictureGroup from '../../ProfilePictureGroup'
import './MainParticipant.less'

const MainParticipant = ({
  local = false,
  participant,
  contact,
  sliderButton,
  buttonView,
  visibleDrawer,
  isDialIn,
}) => {
  const videoRef = useRef()
  const [mobile, setMobile] = useState(false)
  const [contactParticipant, setContactParticipant] = useState(null)
  const [videoTrack, setVideoTrack] = useState(null)
  const [audioTrack, setAudioTrack] = useState(null)

  useEffect(() => {
    const getParticipantsName = () =>
      `${get(contact, 'firstName')} ${get(contact, 'lastName')}`
    const getParticipantsImage = () => `${get(contact, 'imageUrl')}`
    const getParticipantsTitle = () => `${get(contact, 'jobTitle')}`

    local &&
      participant.on('trackPublished', (track) =>
        track.kind === 'video'
          ? setVideoTrack(track.track)
          : setAudioTrack(track.track)
      )
    local &&
      participant.on('trackStopped', (track) => {
        track.kind === 'video' ? setVideoTrack(null) : setAudioTrack(null)
      })

    !local &&
      participant.on('trackUnsubscribed', (track) =>
        track.kind === 'video' ? setVideoTrack(null) : setAudioTrack(null)
      )

    !local &&
      participant.on('trackSubscribed', (track) =>
        track.kind === 'video' ? setVideoTrack(track) : setAudioTrack(track)
      )

    isEmpty(participant.videoTracks) && setVideoTrack(null)
    isEmpty(participant.audioTracks) && setAudioTrack(null)

    participant.videoTracks.forEach((videoTracks) => {
      videoTracks && videoTracks.track && setVideoTrack(videoTracks.track)
    })
    participant.audioTracks.forEach((audioTracks) => {
      audioTracks && audioTracks.track && setAudioTrack(audioTracks.track)
    })

    const name = contact && getParticipantsName()
    const image = contact && getParticipantsImage()
    const title = contact && getParticipantsTitle()
    contact && setContactParticipant({ name, image, title })
  }, [participant, local, contact])

  useEffect(() => {
    if (videoTrack) {
      videoTrack.name === 'mobile' && setMobile(true)
      videoTrack.attach(videoRef.current)
    }
  }, [videoTrack])

  return (
    contactParticipant && (
      <div
        className={classNames({
          'cc-participant-big-narrow': visibleDrawer,
          'cc-participant-big-wide': !visibleDrawer,
        })}
        style={{
          display: participant.state === 'disconnected' && 'none',
          left: 0,
        }}
      >
        {buttonView && (
          <div
            className={classNames({
              'VideoRoom-shadow-up-narrow': visibleDrawer,
              'VideoRoom-shadow-up-wide': !visibleDrawer,
            })}
          />
        )}
        <Loading spinning={!contactParticipant}>
          <video
            className={classNames({
              'cc-participant-video-big ': true,
              'cc-participant-video-big-on': true,
              'cc-participant-video-big-none': isEmpty(participant.videoTracks),
              'cc-participant-video-big-web': !mobile,
              'cc-participant-video-big-mobile': mobile,
            })}
            ref={videoRef}
            autoPlay={false}
          />
          <div
            className={classNames({
              'VideoRoom-narrow-name': visibleDrawer,
              'VideoRoom-wide-name': !visibleDrawer,
            })}
          >
            <div
              className={classNames({
                'cc-meeting': true,
              })}
            >
              <div className="cc-participant-info">
                <div>
                  <span className=" cc-meeting-name cc-meeting-name-name">
                    {contactParticipant.name} {isDialIn && ' (Phone)'}
                  </span>
                  {!isNil(audioTrack) ? (
                    <FontAwesomeIcon icon={faMicrophone} size="lg" />
                  ) : (
                    <FontAwesomeIcon icon={faMicrophoneSlash} size="lg" />
                  )}
                </div>
                {buttonView && sliderButton}
              </div>
            </div>
          </div>
          <div className="cc-participant-video-big cc-participant-video-big-off">
            <ProfilePictureGroup
              diameter={40}
              profiles={[
                {
                  name: contactParticipant.name,
                  firstName: contactParticipant.name.split(' ')[0],
                  lastName: contactParticipant.name.split(' ')[1],
                  imageUrl: contactParticipant.image,
                  image: contactParticipant.image,
                },
              ]}
            />
          </div>
        </Loading>
      </div>
    )
  )
}
MainParticipant.propTypes = {
  participant: PropTypes.shape({
    identity: PropTypes.string.isRequired,
    sid: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    removeAllListeners: PropTypes.func.isRequired,
    videoTracks: PropTypes.object.isRequired,
    audioTracks: PropTypes.object.isRequired,
    on: PropTypes.func.isRequired,
    networkQualityLevel: PropTypes.number,
    networkQualityStats: PropTypes.object,
  }).isRequired,
  visibleDrawer: PropTypes.bool,
  local: PropTypes.bool.isRequired,
  contact: PropTypes.object.isRequired,
  sliderButton: PropTypes.bool,
  buttonView: PropTypes.node,
  isDialIn: PropTypes.bool,
}
export default MainParticipant
