import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import { Placement, Popover, Tooltip } from '@context365/popovers'
import filter from 'lodash/filter'
import take from 'lodash/take'
import contextSignals from '~/constants/contextSignals'
import './ContextSignalsContainer.less'

const signals = Object.values(contextSignals)

const ContextSignalsContainer = ({ company, max }) => {
  const allSignals = filter(signals, (signal) => company[signal.key])
  const signalsToDisplay = allSignals.map((signal) => (
    <Tooltip key={signal.key} text={signal.tooltip} placement="top">
      {!signal.icon ? (
        <Badge
          style={{
            border: 'none',
            backgroundColor: signal.backgroundColor,
            color: signal.color,
            marginRight: '20px',
          }}
        >
          {signal.text}
        </Badge>
      ) : (
        <signal.icon
          fill={signal.backgroundColor}
          width="24px"
          height="24px"
          className="ContextSignalContainer-icon"
        />
      )}
    </Tooltip>
  ))
  return (
    <>
      {take(signalsToDisplay, max || signalsToDisplay.length)}
      {max && max < allSignals.length && (
        <Popover
          placement={Placement.Top}
          content={
            <div className="bg-white border-solid border-dark rounded p-2">
              <ContextSignalsContainer company={company} />
            </div>
          }
        >
          <span className="relative" style={{ top: '-5px' }}>{`+ ${
            allSignals.length - max
          }`}</span>
        </Popover>
      )}
    </>
  )
}
ContextSignalsContainer.propTypes = {
  company: PropTypes.object.isRequired,
  max: PropTypes.number,
}

export default ContextSignalsContainer
