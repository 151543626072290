import PropTypes from 'prop-types'

export default function Profile({ header, body, footer }) {
  return (
    <div className="flex-1 bg-white md:-m-8">
      {header}
      {body}
      {footer}
    </div>
  )
}

Profile.propTypes = {
  header: PropTypes.element,
  body: PropTypes.element,
  footer: PropTypes.element,
}
