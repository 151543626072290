import pickBy from 'lodash/pickBy'
import { api } from './services'

export const getCompaniesToOnboard = () =>
  api.get('/on-boarding/companies').then((res) => res.data.result)

export const getOptions = () =>
  api.get('/on-boarding/wizard/options').then((res) => res.data.result)

export const getCompanyAnswers = () =>
  api
    .get('/on-boarding/company/wizard')
    .then((res) => filterNullProperties(res.data.result))

export const saveCompanyAnswers = (answers) =>
  api.post('/on-boarding/company/wizard', answers)

export const getAllocatorCompanyAnswers = (companyId) =>
  api
    .get(`/on-boarding/allocator-company/${companyId}/wizard`)
    .then((res) => filterNullProperties(res.data.result))

export const saveAllocatorCompanyAnswers = (companyId, answers) =>
  api.post(`/on-boarding/allocator-company/${companyId}/wizard`, answers)

export const getManagerCompanyAnswers = (companyId) =>
  api
    .get(`/on-boarding/manager-company/${companyId}/wizard`)
    .then((res) => filterNullProperties(res.data.result))

export const saveManagerCompanyAnswers = (companyId, answers) =>
  api.post(`/on-boarding/manager-company/${companyId}/wizard`, answers)

export const getServiceProviderCompanyAnswers = (companyId) =>
  api
    .get(`/on-boarding/service-provider-company/${companyId}/wizard`)
    .then((res) => filterNullProperties(res.data.result))

export const saveServiceProviderCompanyAnswers = (companyId, answers) =>
  api.post(`/on-boarding/service-provider-company/${companyId}/wizard`, answers)

export const getContactIdentityAnswers = () =>
  api
    .get('/on-boarding/contact-identity/wizard')
    .then((res) => filterNullProperties(res.data.result))

export const saveContactIdentityAnswers = (answers) =>
  api.post('/on-boarding/contact-identity/wizard', answers)

export const getContactInterestAnswers = () =>
  api
    .get('/on-boarding/contact-interest/wizard')
    .then((res) => filterNullProperties(res.data.result))

export const saveContactInterestAnswers = (answers) =>
  api.post('/on-boarding/contact-interest/wizard', answers)

export const getContactEducationAnswers = () =>
  api
    .get('/on-boarding/contact-education/wizard')
    .then((res) => filterNullProperties(res.data.result))

export const saveContactEducationAnswers = (answers) =>
  api.post('/on-boarding/contact-education/wizard', answers)

export const getContactSocialAnswers = () =>
  api
    .get('/on-boarding/contact-social/wizard')
    .then((res) => filterNullProperties(res.data.result))

export const saveContactSocialAnswers = (answers) =>
  api.post('/on-boarding/contact-social/wizard', answers)

export const getContactLocationAnswers = () =>
  api
    .get('/on-boarding/contact-location/wizard')
    .then((res) => filterNullProperties(res.data.result))

export const saveContactLocationAnswers = (answers) =>
  api.post('/on-boarding/contact-location/wizard', answers)

export const getInvestorStatusAnswers = (companyId) =>
  api
    .get(`/on-boarding/investor-status/${companyId}/wizard`)
    .then((res) => filterNullProperties(res.data.result))

export const saveInvestorStatusAnswers = (companyId, answers) =>
  api.post(`/on-boarding/investor-status/${companyId}/wizard`, answers)

export const getInvestorDetailsAnswers = (companyId) =>
  api
    .get(`/on-boarding/investor-details/${companyId}/wizard`)
    .then((res) => filterNullProperties(res.data.result))

export const saveInvestorDetailsAnswers = (companyId, answers) =>
  api.post(`/on-boarding/investor-details/${companyId}/wizard`, answers)

export const getManagerCompanyInterestAnswers = (companyId) =>
  api
    .get(`/on-boarding/manager-investment-interests/${companyId}/wizard`)
    .then((res) => filterNullProperties(res.data.result))

export const saveManagerCompanyInterestAnswers = (companyId, answers) =>
  api.post(
    `/on-boarding/manager-investment-interests/${companyId}/wizard`,
    answers
  )

export const getAllocatorCompanyInterestAnswers = (companyId) =>
  api
    .get(`/on-boarding/allocator-investment-interests/${companyId}/wizard`)
    .then((res) => filterNullProperties(res.data.result))

export const saveAllocatorCompanyInterestAnswers = (companyId, answers) =>
  api.post(
    `/on-boarding/allocator-investment-interests/${companyId}/wizard`,
    answers
  )

function filterNullProperties(obj) {
  return pickBy(obj, (value) => value != null)
}
