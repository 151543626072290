import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Radio, RadioGroup } from '@context365/forms'
import { DatePicker, Divider, InputNumber } from 'antd'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import moment from 'moment'
import LabelField from '~/components/GenericForm/Fields/LabelField'

const QuarterlyInput = ({ returns, type, onChange }) => {
  return (
    <>
      <InputNumber
        style={{ width: '100%', marginBottom: '10px' }}
        placeholder={`Q1 ${type}`}
        onChange={(val) => onChange(val, 1)}
        value={find(returns, { quarter: 1 })?.value}
      />
      <InputNumber
        style={{ width: '100%', marginBottom: '10px' }}
        placeholder={`Q2 ${type}`}
        onChange={(val) => onChange(val, 2)}
        value={find(returns, { quarter: 2 })?.value}
      />
      <InputNumber
        style={{ width: '100%', marginBottom: '10px' }}
        placeholder={`Q3 ${type}`}
        onChange={(val) => onChange(val, 3)}
        value={find(returns, { quarter: 3 })?.value}
      />
      <InputNumber
        style={{ width: '100%', marginBottom: '10px' }}
        placeholder={`Q4 ${type}`}
        onChange={(val) => onChange(val, 4)}
        value={find(returns, { quarter: 4 })?.value}
      />
    </>
  )
}

const AnnualInput = ({ returns, type, onChange }) => (
  <InputNumber
    style={{ width: '100%', marginBottom: '10px' }}
    placeholder={`Annual ${type}`}
    onChange={(val) => onChange(val)}
    value={find(returns, { quarter: null })?.value}
  />
)

const ReturnsField = ({ value, setFieldValue }) => {
  const [irrType, setIrrType] = useState(
    isEmpty(value) || isEmpty(value[0].internalRateOfReturn)
      ? null
      : value[0].internalRateOfReturnIteration
  )

  const [tvpiType, setTvpiType] = useState(
    isEmpty(value) || isEmpty(value[0].totalValuePaidInCapital)
      ? null
      : value[0].totalValuePaidInCapitalIteration
  )
  const [returns, setReturns] = useState(value)

  useEffect(() => {
    setFieldValue('returns', returns)
  }, [returns])

  const newReturn = () => {
    const lastYear = isEmpty(returns) ? null : returns[returns.length - 1].year

    const newVal = {
      year: isNil(lastYear) ? null : lastYear + 1,
      internalRateOfReturn: [],
      totalValuePaidInCapital: [],
      internalRateOfReturnIteration: irrType,
      totalValuePaidInCapitalIteration: tvpiType,
    }

    switch (irrType) {
      case 'Annual':
        newVal.internalRateOfReturn = [{ quarter: null, value: null }]
        break
      case 'Quarterly':
        newVal.internalRateOfReturn = [
          { quarter: 1, value: null },
          { quarter: 2, value: null },
          { quarter: 3, value: null },
          { quarter: 4, value: null },
        ]
        break
      default:
        break
    }

    switch (tvpiType) {
      case 'Annual':
        newVal.totalValuePaidInCapital = [{ quarter: null, value: null }]
        break
      case 'Quarterly':
        newVal.totalValuePaidInCapital = [
          { quarter: 1, value: null },
          { quarter: 2, value: null },
          { quarter: 3, value: null },
          { quarter: 4, value: null },
        ]
        break
      default:
        break
    }

    return newVal
  }

  const addReturn = () => {
    const arr = returns.slice()
    const newVal = newReturn(irrType, tvpiType)

    arr.push(newVal)
    setReturns(arr)
  }

  const removeReturn = (index) => {
    const arr = returns.slice()
    arr.splice(index, 1)
    setReturns(arr)
  }

  const handleYearChange = (value, returnIndex) => {
    const arr = returns.slice()
    arr[returnIndex].year = value
    setReturns(arr)
  }

  const handleReturnChange = (value, returnIndex, field, quarter = null) => {
    const arr = returns.slice()

    const current = find(arr[returnIndex][field], { quarter })

    isNil(current)
      ? arr[returnIndex][field].push({ quarter, value })
      : (current.value = value)

    setReturns(arr)
  }

  const handleIntervalChange = (field, val) => {
    field === 'internalRateOfReturn'
      ? setIrrType(val || null)
      : setTvpiType(val || null)

    const arr = returns.slice()
    forEach(arr, (ret) => {
      switch (val) {
        case 'Annual':
          ret[field] = [{ quarter: null, value: null }]
          break
        case 'Quarterly':
          ret[field] = [
            { quarter: 1, value: null },
            { quarter: 2, value: null },
            { quarter: 3, value: null },
            { quarter: 4, value: null },
          ]
          break
        default:
          ret[field] = []
          break
      }
    })
    setReturns(arr)
  }

  const getInputField = (interval, type, fieldName, returnVal, index) => {
    if (interval === 'Annual') {
      return (
        <AnnualInput
          returns={returnVal[fieldName]}
          type={type}
          onChange={(val) => handleReturnChange(val, index, fieldName)}
        />
      )
    } else if (interval === 'Quarterly') {
      return (
        <QuarterlyInput
          returns={returnVal[fieldName]}
          type={type}
          onChange={(val, quarter) =>
            handleReturnChange(val, index, fieldName, quarter)
          }
        />
      )
    }

    return null
  }

  return (
    <>
      {!isEmpty(returns) && (
        <>
          <div className="Question-row">
            <span className="Question-col" style={{ paddingRight: '10px' }}>
              <LabelField
                title="Return Year"
                className="text-primary-100 type-body-regular-sm"
              />
            </span>
            <span className="Question-col" style={{ paddingRight: '10px' }}>
              <LabelField
                title="TVPI Interval"
                className="text-primary-100 type-body-regular-sm"
              />
              <RadioGroup
                name="tvpiType"
                value={tvpiType ?? ''}
                onChange={(e) =>
                  handleIntervalChange(
                    'totalValuePaidInCapital',
                    e.target.value
                  )
                }
              >
                <div>
                  <Radio value="Quarterly">Quarterly</Radio>
                </div>
                <div>
                  <Radio value="Annual">Annual</Radio>
                </div>
                <div>
                  <Radio value="">None</Radio>
                </div>
              </RadioGroup>
            </span>
            <span className="Question-col" style={{ paddingRight: '10px' }}>
              <LabelField
                title="IRR Interval"
                className="text-primary-100 type-body-regular-sm"
              />
              <RadioGroup
                name="irrType"
                value={irrType ?? ''}
                onChange={(e) =>
                  handleIntervalChange('internalRateOfReturn', e.target.value)
                }
              >
                <div>
                  <Radio value="Quarterly">Quarterly</Radio>
                </div>
                <div>
                  <Radio value="Annual">Annual</Radio>
                </div>
                <div>
                  <Radio value="">None</Radio>
                </div>
              </RadioGroup>
            </span>
          </div>
          <Divider />
          {map(returns, (ret, i) => (
            <div className="pt-2 pb-4">
              <div>
                <span className="type-body-semibold-sm text-primary-100 align-middle">
                  Return {i + 1}
                </span>
                <Button
                  variant="link"
                  status="error"
                  onClick={() => removeReturn(i)}
                >
                  Remove Return
                </Button>
              </div>
              <div className="Question-row">
                <span className="Question-col" style={{ paddingRight: '10px' }}>
                  <LabelField
                    title={i === 0 ? 'Inception Year' : 'Year'}
                    className="text-primary-100 type-body-regular-sm"
                  />
                  <DatePicker
                    picker="year"
                    onChange={(d) => handleYearChange(d.year(), i)}
                    value={
                      isNil(ret.year) ? undefined : moment(ret.year, 'YYYY')
                    }
                  />
                </span>
                <span className="Question-col" style={{ paddingRight: '10px' }}>
                  <LabelField
                    title="TVPI"
                    className="text-primary-100 type-body-regular-sm"
                  />
                  {getInputField(
                    tvpiType,
                    'TVPI',
                    'totalValuePaidInCapital',
                    ret,
                    i
                  )}
                </span>
                <span className="Question-col" style={{ paddingRight: '10px' }}>
                  <LabelField
                    title="IRR"
                    className="text-primary-100 type-body-regular-sm"
                  />
                  {getInputField(
                    irrType,
                    'IRR',
                    'internalRateOfReturn',
                    ret,
                    i
                  )}
                </span>
              </div>
            </div>
          ))}
          <Divider />
        </>
      )}
      <Button variant="link" className="self-start" onClick={addReturn}>
        + Add New Return
      </Button>
    </>
  )
}

ReturnsField.propTypes = {
  value: PropTypes.array,
  setFieldValue: PropTypes.func,
}

export default ReturnsField
