import { Button } from '@context365/button'
import { useTheme } from '@context365/config'
import { Icon } from '@context365/icons'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '~/assets/logo-icon.svg'
import notFoundLarge from '~/assets/not_found/not_found_lg.jpg'
import notFoundMed from '~/assets/not_found/not_found_md.jpg'
import notFoundSmall from '~/assets/not_found/not_found_sm.jpg'

export default function NotFound() {
  const smWidth = useTheme('screens.sm')

  return (
    <div className="w-screen h-screen flex flex-col sm:flex-row">
      <img
        className="h-1/4 sm:h-auto sm:w-1/3 object-cover"
        alt="a person holding a compass"
        src={notFoundLarge}
        srcSet={`
          ${notFoundSmall} 640w,
          ${notFoundMed} 1920w,
          ${notFoundLarge} 2400w
        `}
        sizes={`
          (min-width:${smWidth}) 33.3333vw,
          100vw
        `}
      />
      <div className="flex-grow sm:flex-none sm:w-2/3 flex flex-col p-8 lg:p-16">
        <Icon
          as={Logo}
          className="text-primary-100 w-16 h-16 lg:w-20 lg:h-20 flex-shrink-0"
          viewBox="0 0 1224 1227"
        />
        <h1 className="mt-10 lg:mt-16 xl:mt-48 mb-0 type-header-lg sm:type-header-2xl">
          Not Found
        </h1>
        <p className="text-grey-700">
          Sorry, we couldn&apos;t find the page you&apos;re looking for.
        </p>
        <p className="mt-4">
          <Button as={Link} to="/" variant="filled">
            Go Back
          </Button>
        </p>
      </div>
    </div>
  )
}
