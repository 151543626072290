import { createRef, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button as ContextButton } from '@context365/button'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Modal, Switch, message } from 'antd'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import uniq from 'lodash/uniq'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { sendMessage, sendMessageToConversation } from '~/actions/messages'
import ContextTable from '~/components/ContextTable/ContextTable'
import { CancelSummitMeeting } from '~/components/MeetingActions'
import MeetingDetailsController from '~/components/MeetingDetails/MeetingDetailsController'
import MessageModal from '~/components/MessageModal'
import ProfilePictureGroup from '~/components/ProfilePictureGroup'
import RescheduleEventMeeting from '~/components/RescheduleEventMeeting/RescheduleEventMeeting'
import { selectedEventHasEnded } from '~/selectors/events'
import { boldCompanyNameFormat } from '~/utils/helpers'
import { canLeaveMeeting } from '~/utils/meetingActions'
import { events as eventsClient } from '../../api'
import './ScheduleDay.less'

const ScheduleDay = ({
  eventDate,
  timeSlots,
  eventId,
  refreshSchedule,
  virtualSummit,
  timezone,
}) => {
  const [timeSlot, setTimeSlot] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [messageModalVisible, setMessageModalVisible] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState(false)
  const [showRescheduleModal, setShowRescheduleModal] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [showMeetingDetails, setShowMeetingDetails] = useState(false)
  const [selectedMeeting, setSelectedMeeting] = useState(null)

  const scheduleRef = createRef()
  const pastSummit = useSelector(selectedEventHasEnded)
  const [cancelForAll, setCancelForAll] = useState(false)
  const [showCancelOnlineModal, setShowCancelOnlineModal] = useState(false)
  const meetingWithString = boldCompanyNameFormat(selectedRecord)
  const uniqueBooths = uniq(timeSlot.map((x) => x.boothId))
  const slotTypeSelected = 1
  const role = useSelector((state) => state.auth.role)

  useEffect(() => {
    let slot = []
    slot = timeSlots.map((x) => slot.concat(x.booths))
    setTimeSlot(flatten(slot))
  }, [timeSlots])

  const renderTime = useCallback(
    (eventSlotTime, record, index) => {
      let value
      if (record.type === 'grouping') value = null
      else value = moment(eventSlotTime).format('h:mm A')

      const numberOfBooths = uniqueBooths.length
      const obj = {
        children: value,
        props: {},
      }
      if (index % numberOfBooths === 0) {
        obj.props.rowSpan = numberOfBooths
      } else {
        obj.props.rowSpan = 0
      }

      return obj
    },
    [uniqueBooths.length]
  )

  const getMeeting = useCallback((record) => {
    if (record.meeting.length === 1)
      return (
        <ContextButton
          as="div"
          className="cursor-pointer rounded type-body-regular-sm "
          variant="none"
          onClick={() => {
            setSelectedMeeting(record.meeting[0])
            setShowMeetingDetails(true)
          }}
        >
          <div className="schedule-day-meeting">
            <span className="schedule-day-meeting-title cc-body-text">
              Meeting with:
            </span>
            <div className="schedule-day-meeting-image">
              <ProfilePictureGroup
                diameter={32}
                profiles={record.meeting[0].members.map((x) => ({
                  name: x.contactName,
                  ...x,
                }))}
                maxImages={5}
              />
            </div>

            <div className="schedule-day-meeting-card">
              <span className="schedule-day-meeting-name">
                {record.meeting[0].company.companyName}
              </span>
              <span className="cc-small-font schedule-day-meeting-company">
                {record.meeting[0].company.companyName}
              </span>
            </div>
            {record.meeting[0].boothId && (
              <div className="schedule-day-meeting-card">
                <div className="schedule-day-meeting-title cc-body-text">
                  Booth:
                </div>
                <div className="schedule-day-meeting-name">
                  {record.meeting[0].boothId}
                </div>
              </div>
            )}
          </div>
        </ContextButton>
      )
    else
      return (
        <div>
          {map(record.meeting, (r, ix) => (
            <ContextButton
              as="div"
              className="cursor-pointer rounded type-body-regular-sm "
              variant="none"
              onClick={() => {
                setSelectedMeeting(r)
                setShowMeetingDetails(true)
              }}
            >
              <div className="schedule-day-meeting">
                <span className="schedule-day-meeting-title cc-body-text">
                  Meeting with:
                </span>
                <div className="schedule-day-meeting-image">
                  <ProfilePictureGroup
                    diameter={32}
                    profiles={r.members.map((x) => ({
                      name: x.contactName,
                      ...x,
                    }))}
                    maxImages={5}
                  />
                </div>
                <div className="schedule-day-meeting-card">
                  <span className="schedule-day-meeting-name">
                    {r.company.companyName}
                  </span>
                  <span className="cc-small-font schedule-day-meeting-company">
                    {r.company.companyName}
                  </span>
                </div>
                {r.boothId && (
                  <div className="schedule-day-meeting-card">
                    <div className="schedule-day-meeting-title cc-body-text">
                      Booth:
                    </div>
                    <div className="schedule-day-meeting-name">{r.boothId}</div>
                  </div>
                )}
                {ix !== record.meeting.length - 1 && <Divider />}
              </div>
            </ContextButton>
          ))}
        </div>
      )
  }, [])

  const renderEvent = useCallback(
    (record) => {
      if (record.type === 'grouping') return record.groupName
      else if (record.meeting === null) return record.details
      else if (isEmpty(record.meeting)) return ''
      else return getMeeting(record)
    },
    [getMeeting]
  )

  const reschedule = useCallback((record) => {
    setShowRescheduleModal(true)
    setSelectedRecord(record)
  }, [])

  const cancelModal = useCallback((record) => {
    setSelectedRecord(record)
    setCancelForAll(true)
    setShowCancelOnlineModal(true)
  }, [])

  const closeReschedule = useCallback(() => {
    setShowRescheduleModal(false)
  }, [])

  const onAvailable = useCallback(
    (record, e) => {
      eventsClient
        .setAvailability(
          eventId,
          record.meetingTimeID,
          e,
          record.eventCompanyBoothId === 0 ? null : record.eventCompanyBoothId
        )
        .then(() => {
          const slots = timeSlot

          let changedSlot
          if (record.eventCompanyBoothId === null) {
            changedSlot = slots.find(
              (x) => x.meetingTimeID === record.meetingTimeID
            )
          } else {
            changedSlot = slots.find(
              (x) =>
                x.eventCompanyBoothId === record.eventCompanyBoothId &&
                x.meetingTimeID === record.meetingTimeID
            )
          }
          changedSlot.available = e
          record.available = e
          setRefresh(!refresh)
          setTimeSlot(slots)
        })
        .catch()
    },
    [eventId, refresh, timeSlot]
  )

  const handleMessageModalOk = useCallback(
    (msg) => {
      const afterResponse = () => {
        message.success(
          <span>
            Message sent <a href="/messages">Go to conversation</a>
          </span>
        )
      }

      const afterError = () => {
        message.error('There was an error while attempting to send the message')
      }

      const afterBoth = () => {
        setMessageModalVisible(false)
        setLoadingMessage(false)
      }

      const conversationId = get(selectedRecord, 'meeting.conversationId', null)

      if (isNil(conversationId)) {
        setLoadingMessage(true)
        sendMessage(selectedRecord.companyEventId, {
          messageBody: msg,
          messageDetails: {
            toCompanyEventID: selectedRecord.companyEventId,
            toCompanyID: selectedRecord.companyEventId,
            eventId,
            meetingType: 1,
          },
        })
          .then((response) => {
            afterResponse(response)
          })
          .catch(afterError)
          .finally(afterBoth)
      } else {
        setLoadingMessage(true)
        sendMessageToConversation(conversationId, msg)
          .then(afterResponse)
          .catch(afterError)
          .finally(afterBoth)
      }
    },
    [selectedRecord, eventId]
  )

  const renderStatus = useCallback(
    (record) => {
      if (record.type === 'grouping') return null
      else if (record.meeting !== null && !isEmpty(record.meeting))
        if (pastSummit)
          return (
            <div>
              {map(record.meeting, (r, ix) => (
                <>
                  <Button
                    onClick={() => {
                      setMessageModalVisible(true)
                      setSelectedRecord(r)
                    }}
                    type="primary"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span style={{ marginLeft: '4px' }}>Send Message</span>
                  </Button>
                  {ix !== record.meeting.length - 1 && <Divider />}
                </>
              ))}
            </div>
          )
        else
          return (
            <div>
              {map(record.meeting, (r, ix) => (
                <>
                  <div>
                    <Button
                      onClick={reschedule.bind(null, r)}
                      className="schedule-day-reschedule"
                      type="ghost"
                    >
                      Reschedule
                    </Button>
                    <Button
                      onClick={cancelModal.bind(null, r)}
                      className="schedule-day-cancel"
                      type="ghost"
                    >
                      Cancel
                    </Button>
                  </div>
                  {ix !== record.meeting.length - 1 && <Divider />}
                </>
              ))}
            </div>
          )
      else {
        const t = moment(record.eventSlotTime)
        let outsideOfficeHours = false
        if (t.hours() >= 9 && t.hours() <= 17) outsideOfficeHours = false
        if (t.hours() === 17 && t.minutes() === 30) {
          outsideOfficeHours = false
        }
        return (
          <Switch
            onChange={onAvailable.bind(null, record)}
            className="schedule-day-switch"
            size="large"
            checkedChildren="Available"
            unCheckedChildren="Unavailable"
            checked={outsideOfficeHours ? false : record.available}
          />
        )
      }
    },
    [cancelModal, onAvailable, pastSummit, reschedule]
  )
  const renderLocation = useCallback((record) => {
    return <div>{record.boothId}</div>
  }, [])

  const rowClassMethod = useCallback((record, index) => {
    let result = 'schedule-day-row'
    if (record.type === 'grouping')
      return `schedule-day-row-grouping cc-tabletitle-text ${result}`
    else {
      result = `schedule-day-row-event ${result}`
      if (index % 2 === 0) result += ' schedule-day-row-even'
      else result += ' schedule-day-row-odd'
      return result
    }
  }, [])

  const getColumns = useCallback(
    () => [
      {
        title: 'Time',
        dataIndex: 'eventSlotTime',
        render: renderTime,
        width: '20%',
      },
      role.toLowerCase() === 'manager' ||
      role.toLowerCase() === 'service provider'
        ? {
            title: 'LOCATION',
            render: renderLocation,
            width: '15%',
          }
        : {},
      {
        title: 'EVENT',
        render: renderEvent,
        width: '50%',
      },
      {
        title: 'STATUS',
        align: 'right',
        render: renderStatus,
        width: '15%',
      },
    ],
    [renderTime, role, renderLocation, renderEvent, renderStatus]
  )

  return (
    <>
      <div ref={scheduleRef}>
        <ContextTable
          pagination={false}
          rowClassName={rowClassMethod}
          scroll={{ y: null }}
          dataSource={slotTypeSelected === 1 ? timeSlot : []}
          columns={getColumns()}
          tableTitle={`${moment(eventDate).format('dddd')}'s Schedule`}
          searchVisible={false}
          refresh={refresh}
          isSchedule={true}
          // timezoneShowing={timezoneShowing}
          // setTimezoneShowing={setTimezoneShowing}
          // onRadChange={changeTimeSlots}
        />
      </div>
      {selectedRecord && (
        <CancelSummitMeeting
          visible={showCancelOnlineModal}
          closeModal={() => {
            setShowCancelOnlineModal(false)
          }}
          meetingStatus="Confirmed"
          company={meetingWithString}
          meetingId={selectedRecord.meetingId}
          meetingType={selectedRecord.meetingType}
          onCancel={() => {
            refreshSchedule()
          }}
          canDeclineMeeting={
            !canLeaveMeeting(
              selectedRecord.colleagues,
              selectedRecord.participants
            )
          }
          cancelForAll={cancelForAll}
        />
      )}

      <RescheduleEventMeeting
        visible={showRescheduleModal}
        closeModal={closeReschedule}
        meetingId={isNil(selectedRecord) ? '' : selectedRecord.meetingId}
        eventId={eventId}
        onRescheduleMeeting={refreshSchedule}
        participants={
          selectedRecord && selectedRecord.colleagues
            ? selectedRecord.colleagues.map((x) => x.contactId)
            : []
        }
        toParticipants={
          selectedRecord && selectedRecord.participants
            ? selectedRecord.participants.map((x) => x.contactId)
            : []
        }
        meetingDateTime={moment
          .tz(selectedRecord?.meetingDateTime, moment.tz.guess())
          .utc()
          .format('Y-MM-DD HH:mm:ss')}
        meetingDuration={selectedRecord?.meetingDuration}
        timezone={timezone}
        isVirtualSummit={virtualSummit}
      />
      <MessageModal
        meeting={selectedRecord}
        visible={messageModalVisible}
        onCancel={() => {
          setMessageModalVisible(false)
        }}
        onOk={handleMessageModalOk}
        loading={loadingMessage}
      />
      {showMeetingDetails && (
        <Modal
          visible={showMeetingDetails}
          width="80%"
          footer={null}
          onCancel={() => {
            setShowMeetingDetails(false)
            setSelectedMeeting(null)
          }}
        >
          <MeetingDetailsController
            meetingId={selectedMeeting.meetingId}
            meetingType={selectedMeeting.meetingType}
          />
        </Modal>
      )}
    </>
  )
}

ScheduleDay.propTypes = {
  eventDate: PropTypes.string.isRequired,
  timeSlots: PropTypes.array.isRequired,
  eventId: PropTypes.number.isRequired,
  refreshSchedule: PropTypes.func.isRequired,
  virtualSummit: PropTypes.bool,
  timezone: PropTypes.string,
}
export default ScheduleDay
