import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Typography } from 'antd'
import isNil from 'lodash/isNil'
import './NoData.less'

const { Title, Paragraph } = Typography

const NoData = ({ icon, title, message, action = null }) => (
  <div className="NoData">
    <div className="NoData-icon">{icon}</div>
    <Title level={4} className="NoData-title">
      {title}
    </Title>
    <Paragraph className="NoData-message">{message}</Paragraph>
    {!isNil(action) && (
      <Button
        onClick={isNil(action.onClick) ? null : action.onClick}
        variant="filled"
      >
        {isNil(action.icon) ? null : action.icon}
        <span className="NoData-action-label">{action.label}</span>
      </Button>
    )}
  </div>
)

NoData.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.object,
    onClick: PropTypes.func.isRequired,
  }),
}

export default NoData
