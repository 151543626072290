import PropTypes from 'prop-types'
import { TabButton, TabList, TabPanel, Tabs } from '@context365/tabs'
import map from 'lodash/map'
import { useQuery } from 'react-query'
import * as api from '~/api'
import ProfileDetails from '~/components/ProfileDetails/ProfileDetails'
import DISPLAY_MODES from '~/constants/displayModes'
import VALUE_TYPES from '~/constants/valueTypes'

const CompanyTab = ({ company }) => {
  const allocatorDetails = [
    {
      displayMode: DISPLAY_MODES.COLUMN,
      sections: [
        {
          key: 'companyDescription',
          label: 'About',
          value: company.companyDescription,
          valueType: VALUE_TYPES.PARAGRAPH,
        },
        {
          key: 'primaryInvestorCategory',
          label: 'Investor Category',
          value: company.investorPreferences.primaryInvestorCategory,
        },
      ],
    },
    {
      subGroupName: 'Investment Size',
      displayMode: DISPLAY_MODES.ROW,
      sections: [
        {
          key: 'minimumInvestmentSize',
          label: 'Minimum Investment Size',
          value: company.investorPreferences.minimumInvestmentSize,
          valueType: VALUE_TYPES.CURRENCY,
        },
        {
          key: 'maximumInvestmentSize',
          label: 'Maximum Investment Size',
          value: company.investorPreferences.maximumInvestmentSize,
          valueType: VALUE_TYPES.CURRENCY,
        },
      ],
    },
    {
      subGroupName: 'Fund Size Interest',
      displayMode: DISPLAY_MODES.ROW,
      sections: [
        {
          key: 'minimumFundSizeInterest',
          label: 'Minimum Fund Size Interest',
          value: company.investorPreferences.minimumFundSizeInterest,
          valueType: VALUE_TYPES.CURRENCY,
        },
        {
          key: 'maximumFundSizeInterest',
          label: 'Maximum Fund Size Interest',
          value: company.investorPreferences.maximumFundSizeInterest,
          valueType: VALUE_TYPES.CURRENCY,
        },
      ],
    },
    {
      displayMode: DISPLAY_MODES.ROW,
      sections: [
        {
          key: 'trackRecord',
          label: 'Track Record Length (Years)',
          value: company.investorPreferences.trackRecordLength,
          valueType: VALUE_TYPES.ARRAY,
        },
      ],
    },
    {
      displayMode: DISPLAY_MODES.ROW,
      sections: [
        {
          key: 'liquidBroadStrategyInterest',
          label: 'Liquid Broad Strategy Interest',
          value: company.investorPreferences.liquidBroadStrategyInterest,
          valueType: VALUE_TYPES.ARRAY,
        },
        {
          key: 'illiquidBroadStrategyInterest',
          label: 'Illiquid Broad Strategy Interest',
          value: company.investorPreferences.illiquidBroadStrategyInterest,
          valueType: VALUE_TYPES.ARRAY,
        },
      ],
    },
    {
      displayMode: DISPLAY_MODES.ROW,
      sections: [
        {
          key: 'liquidSubStrategyInterest',
          label: 'Liquid Sub Strategy Interest',
          value: company.investorPreferences.liquidSubStrategyInterest,
          valueType: VALUE_TYPES.ARRAY,
        },
        {
          key: 'illiquidSubStrategyInterest',
          label: 'Illiquid Sub Strategy Interest',
          value: company.investorPreferences.illiquidSubStrategyInterest,
          valueType: VALUE_TYPES.ARRAY,
        },
      ],
    },
    {
      displayMode: DISPLAY_MODES.ROW,
      sections: [
        {
          key: 'investmentDecisionProcessInterest',
          label: 'Investment Decision Process',
          value: company.investorPreferences.investmentDecisionProcessInterest,
          valueType: VALUE_TYPES.ARRAY,
        },
      ],
    },
    {
      displayMode: DISPLAY_MODES.ROW,
      sections: [
        {
          key: 'netDollarExposureInterest',
          label: 'Net Dollar Exposure',
          value: company.investorPreferences.netDollarExposureInterest,
          valueType: VALUE_TYPES.ARRAY,
        },
      ],
    },
    {
      displayMode: DISPLAY_MODES.ROW,
      sections: [
        {
          key: 'liquidityTerms',
          label: 'Liquidity Terms',
          value: company.investorPreferences.liquidityTerms,
          valueType: VALUE_TYPES.ARRAY,
        },
      ],
    },
    {
      displayMode: DISPLAY_MODES.ROW,
      sections: [
        {
          key: 'maximumPercentageOfAFundsAUMYouWouldBeComfortableComprising',
          label: 'Investment cannot be larger than this percentage of a fund',
          value:
            company.investorPreferences
              .maximumPercentageOfAFundsAUMYouWouldBeComfortableComprising,
          valueType: VALUE_TYPES.PERCENT,
        },
      ],
    },
    {
      displayMode: DISPLAY_MODES.ROW,
      sections: [
        {
          key: 'investmentObjectives',
          label: 'Investment Objectives',
          value: company.investorPreferences.investmentObjectives,
          valueType: VALUE_TYPES.ARRAY,
        },
      ],
    },
    {
      displayMode: DISPLAY_MODES.ROW,
      sections: [
        {
          key: 'sectorsInterest',
          label: 'Sectors Interest',
          value: company.investorPreferences.sectorsInterest,
          valueType: VALUE_TYPES.ARRAY,
        },
      ],
    },
    {
      displayMode: DISPLAY_MODES.ROW,
      sections: [
        {
          key: 'geographicConcentrationInterest',
          label: 'Geographic Concentration Interest',
          value: company.investorPreferences.geographicConcentrationInterest,
          valueType: VALUE_TYPES.ARRAY,
        },
      ],
    },
    {
      displayMode: DISPLAY_MODES.ROW,
      sections: [
        {
          key: 'fundAttributes',
          label: 'Fund Attributes',
          value: company.investorPreferences.fundAttributes,
          valueType: VALUE_TYPES.ARRAY,
        },
      ],
    },
    {
      displayMode: DISPLAY_MODES.ROW,
      sections: [
        {
          key: 'mandateOrder',
          label: 'Mandate Order (ranked in order of importance)',
          value: company.investorPreferences.mandateOrder,
          valueType: VALUE_TYPES.ARRAY,
        },
      ],
    },
  ]

  return <ProfileDetails valueGroups={allocatorDetails} />
}

const ContactProfileInvestorPreferences = ({ contactId }) => {
  const { data: companies } = useQuery(
    ['contactAllocatorProfiles', contactId],
    () => api.allocators.getContactAllocatorProfiles(contactId)
  )
  return (
    <Tabs variant="vertical">
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-1">
          <TabList variant="vertical">
            {map(companies, (ac) => (
              <TabButton
                id={ac.companyId}
                key={ac.companyId}
                label={ac.companyName}
              />
            ))}
          </TabList>
        </div>
        <div className="col-span-3">
          {map(companies, (ac) => (
            <TabPanel key={ac.companyId} id={ac.companyId} tabId={ac.companyId}>
              <CompanyTab company={ac} />
            </TabPanel>
          ))}
        </div>
      </div>
    </Tabs>
  )
}

ContactProfileInvestorPreferences.propTypes = {
  contactId: PropTypes.number.isRequired,
}

export default ContactProfileInvestorPreferences
