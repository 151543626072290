import PropTypes from 'prop-types'
import { Button } from 'antd'
import { Formik } from 'formik'
import {
  getInitialValues,
  renderInput,
} from '../../DynamicForm/CompanyContactForm'
import '~/styles/index.less'
import '../index.less'
import './Step1.less'

const Step1 = ({ questions, finish, back }) => {
  const initialState = getInitialValues(questions)
  return (
    <Formik
      initialValues={initialState}
      onSubmit={(values) => {
        finish(values)
      }}
      render={({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} className="cc-initialstep">
          <div className="cc-allocator-header">
            <span className="FormHeader">Contact/Location</span>
            <p
              style={{ paddingTop: '17px' }}
              className="cc-body-text cc-greyscale-darkestblack"
            >
              Enter your contact and location informations
            </p>
          </div>

          {questions.map((question) =>
            renderInput(
              values,
              question,
              handleSubmit,
              handleChange,
              isSubmitting,
              setFieldValue
            )
          )}
          <Button
            type="submit"
            className="ant-btn-primary btn-submit"
            style={{ margin: 'auto', marginBottom: '16px', width: '364px' }}
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {' '}
            Finish{' '}
          </Button>
          <Button disabled={isSubmitting} onClick={back} className="btn-back">
            {' '}
            Back{' '}
          </Button>
        </form>
      )}
    />
  )
}

Step1.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  finish: PropTypes.func,
  back: PropTypes.func,
}

export default Step1
