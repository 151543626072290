import { useEffect, useState } from 'react'
import { Button, IconButton } from '@context365/button'
import {
  CalendarTodayOutlined,
  Circle,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@context365/icons'
import { Calendar, Switch } from 'antd'
import cx from 'classnames'
import filter from 'lodash/filter'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import Loading from '~/components/Loading'
import MeetingCard from '~/components/Meetings/MeetingCard'
import './Calendar.less'

function getMonthName(date) {
  return moment(date).format('MMMM')
}
function getYear(date) {
  return date.format('YYYY')
}
function getFormattedDate(date) {
  return date.format('YYYY MM DD')
}
function compareDates(date1, date2) {
  return getFormattedDate(date1) === getFormattedDate(date2)
}

const CalendarView = () => {
  const { Track, trackEvent } = useTracking({ page: 'Calendar' })
  const [loading, setLoading] = useState(false)
  const [month, setMonth] = useState(getMonthName(moment()))
  const [year, setYear] = useState(getYear(moment()))
  const [selectedDate, setSelectedDate] = useState(moment())
  const [calendarMeetings, setCalendarMeetings] = useState([])
  const [dateMeetings, setDateMeetings] = useState([])
  const [includeCompanyMeetings, setIncludeCompanyMeetings] = useState(false)

  const onDateSelect = (value) => {
    setSelectedDate(value)
    setMonth(getMonthName(value))
    setYear(getYear(value))

    trackEvent({
      element: 'Calendar',
      eventName: 'click',
      date: getFormattedDate(value),
    })
  }

  const getDateMeetings = (date) => {
    const meetings = filter(calendarMeetings, (meeting) =>
      compareDates(moment.utc(meeting.meetingDateTime), date)
    )
    setDateMeetings(sortBy(meetings, (m) => m.meetingDateTime))
    return meetings
  }

  useEffect(() => {
    getDateMeetings(selectedDate)
  }, [selectedDate, calendarMeetings])

  const loadMeetings = () => {
    setLoading(true)
    api.meeting
      .getCalendarMeetings(includeCompanyMeetings, selectedDate)
      .then((response) => {
        setCalendarMeetings(response.data.result)
        getDateMeetings(moment())
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    loadMeetings()
  }, [month, includeCompanyMeetings])

  const onRenderCell = (date) => {
    const meeting = find(calendarMeetings, (meeting) =>
      compareDates(moment.utc(meeting.meetingDateTime), date)
    )
    const hasMeeting = !isNil(meeting)
    return (
      <Circle
        size={8}
        className={cx(
          'calendar-dot',
          hasMeeting ? 'text-secondary-100' : 'text-transparent'
        )}
      />
    )
  }

  return (
    <Track>
      {loading && (
        <div className="absolute w-full text-center">
          <Loading spinning={loading} />
        </div>
      )}
      <div className="grid grid-cols-1 lg:grid-cols-5 xl:grid-cols-4 gap-4 xl">
        <div className="pt-8 col-span-2 xl:col-span-1">
          <div className="flex pb-4 space-x-4">
            <Button variant="outlined" onClick={() => onDateSelect(moment())}>
              Today
            </Button>
            <div>
              <Switch
                id="includeCompanyMeetings"
                size="small"
                checked={includeCompanyMeetings}
                onChange={(checked) => {
                  setIncludeCompanyMeetings(checked)
                  trackEvent({
                    element: 'includeCompanyMeetings',
                    eventName: 'change',
                    checked,
                  })
                }}
              />
              <label
                className="type-body-regular-sm ml-2"
                htmlFor="includeCompanyMeetings"
              >
                Show all company meetings
              </label>
            </div>
          </div>
          <div className="shadow-0">
            <Calendar
              value={selectedDate}
              fullscreen={false}
              onSelect={onDateSelect}
              dateCellRender={onRenderCell}
              headerRender={() => (
                <div className="grid grid-cols-4 text-center type-header-md py-4">
                  <IconButton
                    size="big"
                    style={{ padding: 0 }}
                    label="previous month"
                    variant="link"
                    icon={<KeyboardArrowLeft />}
                    onClick={() => onDateSelect(selectedDate.add(-1, 'month'))}
                  />
                  <div className="col-span-2 type-header-sm mt-1">
                    {month} {year}
                  </div>
                  <IconButton
                    size="big"
                    style={{ padding: 0 }}
                    label="next month"
                    variant="link"
                    icon={<KeyboardArrowRight />}
                    onClick={() => onDateSelect(selectedDate.add(1, 'month'))}
                  />
                </div>
              )}
            />
          </div>
        </div>
        <div className="col-span-3">
          {dateMeetings?.length > 0 ? (
            <div className="mt-10 space-y-4">
              <div className="type-header-sm">
                {`Meetings on ${selectedDate.format('MMMM DD, yyyy')}`}
              </div>
              <div className="space-y-4">
                {dateMeetings.map((meeting) => (
                  <MeetingCard
                    key={meeting.meetingId}
                    meeting={meeting}
                    refreshPage={loadMeetings}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="text-center space-y-8 space-x-8 mt-8 lg:mt-20">
              <CalendarTodayOutlined size={128} color="#757575" />
              <div className="type-header-sm">{`You have no meetings on ${selectedDate.format(
                'MMMM DD, yyyy'
              )}`}</div>
              <div className="flex space-x-4 justify-center">
                <Button as={Link} to="/meetings/requests" variant="outlined">
                  View Meeting Requests
                </Button>
                <Button as={Link} to="/meetings/meetings" variant="filled">
                  View All Meetings
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Track>
  )
}

export default CalendarView
