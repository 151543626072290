import PropTypes from 'prop-types'
import FundWizardUpdateReturns from '../../../UpdateReturnsExcel/FundWizardUpdateReturns'

const ReturnsUpload = ({ fundId, setFieldValue, name, value }) => (
  <FundWizardUpdateReturns
    fundId={fundId}
    value={value}
    onChange={(data) => setFieldValue(name, data)}
  />
)

ReturnsUpload.propTypes = {
  fundId: PropTypes.number,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.object,
}

export default ReturnsUpload
