import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Launch } from '@context365/icons'
import cx from 'classnames'
import compact from 'lodash/compact'

export default function Header({
  actions,
  imageUrl,
  title,
  subtitle,
  description,
  contacts,
  profileUrl,
  className,
}) {
  return (
    <div className={cx(className, 'bg-grey-50')}>
      {profileUrl && (
        <Button
          as="a"
          href={profileUrl}
          target="_blank"
          variant="link"
          size="small"
          iconLeft={<Launch />}
        >
          View Profile
        </Button>
      )}
      <div className="flex p-4 justify-between items-start max-w-5xl mx-auto">
        <div className="flex flex-col gap-1 items-start">
          <div className="flex items-center gap-4">
            {imageUrl && (
              <img
                className="w-20 h-20 rounded-full object-cover"
                src={imageUrl}
                alt=""
              />
            )}
            <div>
              <div className="type-header-sm">{title}</div>
              {subtitle && (
                <div className="type-body-regular-sm mt-1">{subtitle}</div>
              )}
              {description && (
                <div className="type-body-regular-xs">
                  {formatDescription(description)}
                </div>
              )}
              <div className="mt-3">{contacts}</div>
            </div>
          </div>
        </div>
        {actions && <div className="flex gap-4">{actions}</div>}
      </div>
    </div>
  )
}

Header.propTypes = {
  actions: PropTypes.node,
  imageUrl: PropTypes.string,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  description: PropTypes.node,
  contacts: PropTypes.node,
  profileUrl: PropTypes.string,
  className: PropTypes.string,
}

function formatDescription(description) {
  if (!Array.isArray(description)) {
    return description
  }

  return compact(
    description.map((item) =>
      Array.isArray(item) ? compact(item).join(', ') : item
    )
  ).join(' • ')
}
