import * as React from 'react'
import PropTypes from 'prop-types'
import { Stars } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import cx from 'classnames'
import { NavLink } from 'react-router-dom'
import useTracking from '~/hooks/useTracking'

const SidebarLink = ({
  icon,
  label,
  blocked,
  beta,
  visible,
  visibleOnMobile,
  ...props
}) => {
  const { trackEvent } = useTracking({ element: 'SidebarLink' })
  return (
    <li className="p-1 relative">
      <NavLink
        {...props}
        className={(isActive) =>
          cx(
            'py-2 rounded flex flex-col items-center gap-1',
            isActive
              ? 'bg-grey-800 text-brand-100 hover:text-brand-100 cursor-default'
              : 'text-white hover:bg-grey-700 hover:text-white'
          )
        }
        onClick={() => trackEvent.click({ label })}
      >
        {React.cloneElement(icon, { size: 28 })}
        <span className="text-white text-xs">{label}</span>
        {beta && <span className="text-white text-xs">(beta)</span>}
      </NavLink>
      {blocked && <Premium />}
    </li>
  )
}

SidebarLink.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string,
  to: PropTypes.string,
  exact: PropTypes.bool,
  blocked: PropTypes.bool,
  beta: PropTypes.bool,
  visible: PropTypes.any,
  visibleOnMobile: PropTypes.any,
}

export default SidebarLink

function Premium() {
  return (
    <Tooltip text="Premium Feature">
      <Stars className="absolute top-1 right-1 text-gold-100" />
    </Tooltip>
  )
}
