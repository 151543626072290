import { Component } from 'react'
import PropTypes from 'prop-types'
import { PersonPinCircleOutlined, WatchLaterOutlined } from '@context365/icons'
import { Dropdown, Menu } from 'antd'
import TimeDisplay from '../TimeDisplay'
import ScheduleTable from './ScheduleTable'
import './AgendaDay.less'

class AgendaDay extends Component {
  state = {
    displayTimezone: TimeDisplay.ORIGINAL,
    displayTimezoneLabel: 'Event Timezone',
  }

  getColumns() {
    const menu = (
      <Menu>
        <Menu.Item
          key={TimeDisplay.ORIGINAL}
          onClick={(e) =>
            this.setState({
              displayTimezone: e.key,
              displayTimezoneLabel: 'Event Timezone',
            })
          }
        >
          <WatchLaterOutlined />
          <span>
            Show in <b>Event</b> Timezone
          </span>
        </Menu.Item>
        <Menu.Item
          key={TimeDisplay.LOCAL}
          onClick={(e) =>
            this.setState({
              displayTimezone: e.key,
              displayTimezoneLabel: 'Local Timezone',
            })
          }
        >
          <PersonPinCircleOutlined />
          <span>
            Show in your <b>Local</b> Timezone
          </span>
        </Menu.Item>
      </Menu>
    )

    return [
      {
        title: () => (
          <Dropdown overlay={menu}>
            <span className="agenda-day-timezone-column">
              TIME ({this.state.displayTimezoneLabel})
            </span>
          </Dropdown>
        ),
        render: (record) => (
          <>
            <TimeDisplay
              time={record.startDate}
              timezone={this.props.eventTimezone}
              displayInTimezone={this.state.displayTimezone}
              displayFormat="h:mm A"
            />
            <span> - </span>
            <TimeDisplay
              time={record.endDate}
              timezone={this.props.eventTimezone}
              displayInTimezone={this.state.displayTimezone}
              displayFormat="h:mm A"
            />
          </>
        ),
        width: '20%',
      },
      {
        title: 'EVENT',
        dataIndex: 'title',
        width: '25%',
      },
      {
        title: 'LOCATION',
        dataIndex: 'location',
        width: '20%',
      },
      {
        title: 'SPEAKERS',
        render: this.renderSpeakers,
        dataIndex: 'speakers',
        width: '35%',
      },
    ]
  }

  renderSpeakers = (record) => (
    <div className="agenda-day-speakers-container">
      {record === null ? '-' : record.map((x) => this.renderSpeakerRole(x))}
    </div>
  )

  renderSpeakerRole = (speakerRole) => (
    <div className="agenda-day-speaker-role">
      <span className="agenda-day-speaker-role-title">
        {speakerRole.speakerRoleName}
      </span>
      <div className="agenda-day-speakers-people-container">
        {this.renderSpeakerPerson(speakerRole)}
      </div>
    </div>
  )

  renderSpeakerPerson = (person) => (
    <div className="agenda-day-speakers-person">
      <span className="cc-body-text agenda-day-speakers-person-name">
        {person.speakerName}
      </span>
      <span className="cc-small-font agenda-day-speakers-person-title">
        {person.position}
      </span>
    </div>
  )

  render() {
    return (
      <ScheduleTable
        pagination={false}
        dataSource={this.props.items}
        columns={this.getColumns()}
        tableTitle={`${this.props.dayText}’s Agenda`}
        searchVisible={false}
      />
    )
  }
}

AgendaDay.propTypes = {
  dayText: PropTypes.string,
  items: PropTypes.any,
  eventTimezone: PropTypes.string,
}

export default AgendaDay
