import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Empty, Form, Modal, message } from 'antd'
import { Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useHistory, useLocation } from 'react-router-dom'
import * as api from '~/api'
import FundDetailsModalContainer from '~/components/FundDetailsModalContainer'
import QuestionRow from '~/components/GenericForm/QuestionRow'
import StepList from '~/components/StepList'
import { FUND_CONFIRMATION } from '~/constants/disclaimer'
import Loading from '../../Loading'
import './FundWizard.less'

const { confirm } = Modal

const QuestionRows = ({
  title,
  questions,
  questionOptions,
  formProps,
  fundId,
  onSwitchFundType,
  isEditing,
}) => {
  const { validateForm } = formProps
  useEffect(() => {
    if (isEditing) {
      validateForm()
    }
  }, [validateForm, title, isEditing])

  return (
    <>
      <div className="type-header-md p-2 mb-2">{title}</div>
      <div className="FundWizard-form-container space-y-4" id="scrollTop">
        {map(
          questions(formProps.values, questionOptions, onSwitchFundType),
          (question) =>
            question.hideOnCreate && isNil(fundId) ? null : (
              <QuestionRow
                key={question.name}
                {...question}
                {...formProps}
                fundId={fundId}
                isEditing={isEditing}
              />
            )
        )}
      </div>
    </>
  )
}

const FundWizard = ({
  fundId,
  onSave,
  defaultValues,
  steps,
  defaultStep = 0,
  markAsEdited,
  onSwitchFundType,
}) => {
  const [currentStep, setCurrentStep] = useCurrentStep(defaultStep)
  const [stepsInError, setStepsInError] = useState([])
  const [questionOptions, setQuestionOptions] = useState(null)
  const [initialValues, setInitialValues] = useState(defaultValues)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [viewedSteps, setViewedSteps] = useState([])
  const [showPreview, setShowPreview] = useState(false)

  useEffect(() => {
    api.funds.getFundOptions().then((res) => {
      setQuestionOptions(res.data.result)

      if (isNil(fundId)) {
        setLoading(false)
      } else {
        api.funds
          .getProfileWizardAnswers(fundId)
          .then((res) => {
            setViewedSteps(res.data.result.viewedSteps)
            setInitialValues(res.data.result)
          })
          .finally(() => setLoading(false))
      }
    })
  }, [fundId])

  const scrollToTop = useCallback(() => {
    document.getElementById('scrollTop')?.scrollTo?.(0, 0)
  }, [])

  const onNavigation = (step) => {
    if (step >= 0 && step < steps.length) {
      setCurrentStep(step)
    }
    scrollToTop()
  }

  const getStepsInError = (vals) => {
    const errors = []
    for (let i = 0; i < steps.length; i++) {
      if (!isNil(steps[i].validator) && !steps[i].validator.isValidSync(vals)) {
        errors.push(i)
      }
    }

    return errors
  }

  const handleSave = (vals, formFinished) => {
    if (!vals.fundName) {
      return
    }

    setSaving(true)
    const errors = getStepsInError(vals)
    const model = { ...vals, isValidated: isEmpty(errors) }
    if (formFinished) {
      if (isEmpty(errors)) {
        confirm({
          title: FUND_CONFIRMATION,
          okText: 'Yes',
          cancelText: 'No',
          onOk() {
            onSave(model, true, () => setSaving(false))
          },
          onCancel() {
            onSave(model, false, () => setSaving(false))
          },
        })
      } else {
        message.error('Please check highlighted steps.')
        onSave(model, false, () => setSaving(false))
      }
    } else {
      onSave(model, false, () => setSaving(false))
    }

    setStepsInError(errors)
  }

  return loading ? (
    <Loading spinning={loading}>
      <Empty />
    </Loading>
  ) : (
    <>
      <div className="FundWizard">
        <Formik
          initialValues={initialValues}
          validationSchema={steps[currentStep].validator}
        >
          {(formikProps) => (
            <div className="FundWizard-row FundWizard-container">
              <div className="FundWizard-steps">
                <StepList
                  steps={map(steps, 'title')}
                  stepsInError={stepsInError}
                  current={currentStep}
                  onStepChange={(step) => {
                    if (!(formikProps.dirty && !formikProps.isValid)) {
                      if (fundId) {
                        api.funds.trackFundStep(fundId, currentStep)
                      }
                      onNavigation(step)
                      if (formikProps.dirty) {
                        markAsEdited()
                        handleSave(formikProps.values, false)
                      }
                    }
                  }}
                />
              </div>
              <div className="FundWizard-form">
                <Loading spinning={saving}>
                  <Form onFinish={formikProps.handleSubmit}>
                    {!isNil(questionOptions) && (
                      <QuestionRows
                        title={steps[currentStep].title}
                        questionOptions={questionOptions}
                        questions={steps[currentStep].questions}
                        formProps={formikProps}
                        fundId={fundId}
                        onSwitchFundType={onSwitchFundType}
                        isEditing={viewedSteps.includes(currentStep)}
                      />
                    )}
                    <div className="FundWizard-form-footer">
                      <Button
                        disabled={currentStep === 0}
                        onClick={() => {
                          onNavigation(currentStep - 1)
                          if (formikProps.dirty) {
                            markAsEdited()
                            handleSave(formikProps.values, false)
                          }
                        }}
                        status="secondary"
                        variant="filled"
                      >
                        Back
                      </Button>
                      {fundId && (
                        <Button
                          onClick={() => {
                            if (formikProps.dirty) {
                              handleSave(formikProps.values, false)
                            }
                            setShowPreview(true)
                          }}
                          status="secondary"
                          variant="filled"
                        >
                          Preview
                        </Button>
                      )}
                      {currentStep === steps.length - 1 && (
                        <Button
                          onClick={() => {
                            markAsEdited()
                            handleSave(formikProps.values, true)
                          }}
                          loading={loading}
                        >
                          Save
                        </Button>
                      )}
                      {currentStep !== steps.length - 1 && (
                        <Button
                          variant="filled"
                          onClick={() => {
                            if (fundId) {
                              api.funds.trackFundStep(fundId, currentStep)
                              if (!viewedSteps.includes(currentStep)) {
                                const newViewedSteps = viewedSteps.slice()
                                newViewedSteps.push(currentStep)
                                setViewedSteps(newViewedSteps)
                              }
                            }
                            onNavigation(currentStep + 1)
                            if (formikProps.dirty) {
                              markAsEdited()
                              handleSave(formikProps.values, false)
                            }
                          }}
                        >
                          Next
                        </Button>
                      )}
                    </div>
                  </Form>
                </Loading>
              </div>
            </div>
          )}
        </Formik>
      </div>
      <FundDetailsModalContainer
        showFundProfile={showPreview}
        fund={{ fundId }}
        arePointsNear={() => false}
        onClose={() => setShowPreview(false)}
        showDataroom={false}
        canSendMessage={false}
        hideActions
      />
    </>
  )
}

FundWizard.propTypes = {
  fundId: PropTypes.number,
  defaultStep: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  defaultValues: PropTypes.object.isRequired,
  markAsEdited: PropTypes.func.isRequired,
  onSwitchFundType: PropTypes.func.isRequired,
}

export default FundWizard

function useCurrentStep(initialStep) {
  const location = useLocation()
  const { push } = useHistory()

  const { currentStep = initialStep } = location.state ?? {}

  const setCurrentStep = useCallback(
    (step) => {
      push({
        ...location,
        state: {
          ...location.state,
          currentStep: step,
        },
      })
    },
    [location, push]
  )

  return [currentStep, setCurrentStep]
}
