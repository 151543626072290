import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { NotificationImportant } from '@context365/icons'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import useTracking from '~/hooks/useTracking'
import { pluralize } from '~/utils/helpers'
import CampaignStatusSelector from '~/views/workspace/CampaignStatusSelector'

export default function CampaignCard({
  className,
  children,
  campaignId,
  campaignStatusId,
  name,
  newActivityCount,
  updateButton,
}) {
  const { trackEvent } = useTracking()

  return (
    <div
      className={cx(
        className,
        'rounded-big border border-primary-5 bg-white relative'
      )}
    >
      <div className="absolute top-0 left-0 bottom-0 w-1 bg-secondary-100 rounded-l-big" />
      <div className="pl-5 pt-4">
        <Button
          variant="link"
          as={Link}
          style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}
          to={`/workspace/${campaignId}`}
          onClick={() =>
            trackEvent.click({ element: 'campaign-title', campaignId })
          }
        >
          <h2 className="type-header-xxs m-0">{name}</h2>
        </Button>
      </div>
      <div className="p-4 pl-5">{children}</div>
      <div className="p-2 pl-3 border-t border-secondary-5 flex flex-wrap justify-between gap-2">
        <div className="flex flex-wrap gap-2">
          <Button
            as={Link}
            to={`/workspace/${campaignId}`}
            onClick={() =>
              trackEvent.click({ element: 'view-pipeline', campaignId })
            }
          >
            View Pipeline
          </Button>
          <CampaignStatusSelector
            campaignId={campaignId}
            campaignStatusId={campaignStatusId}
          />
        </div>
        <div className="flex flex-wrap gap-2">
          {updateButton}
          {newActivityCount > 0 && (
            <Button
              as={Link}
              variant="filled"
              status="error"
              iconLeft={<NotificationImportant />}
              to={`/workspace/${campaignId}`}
              onClick={() =>
                trackEvent.click({
                  element: 'new-activity',
                  campaignId,
                  newActivityCount,
                })
              }
            >
              View{' '}
              {pluralize(newActivityCount, 'Notification', 'Notifications')}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

CampaignCard.propTypes = {
  children: PropTypes.node.isRequired,
  campaignId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  campaignStatusId: PropTypes.number.isRequired,
  newActivityCount: PropTypes.number.isRequired,
  updateButton: PropTypes.node,
}
