import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { addLineBreaks } from '~/utils/helpers'

const CompanyProfileAbout = ({ company }) => {
  return (
    <div className="flex flex-col gap-8">
      <div>
        <div className="type-body-semibold-sm">Company Bio</div>
        <div>{addLineBreaks(company.description)}</div>
      </div>
      {!isEmpty(company.investmentPhilosophy) && (
        <div>
          <div className="type-body-semibold-sm">Investment Philosophy</div>
          <div>{addLineBreaks(company.investmentPhilosophy)}</div>
        </div>
      )}
    </div>
  )
}

CompanyProfileAbout.propTypes = {
  company: PropTypes.shape({
    description: PropTypes.string,
    investmentPhilosophy: PropTypes.string,
  }).isRequired,
}

export default CompanyProfileAbout
