import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import * as api from '~/api'
import Loading from '~/components/Loading'
import Profile from '~/components/Profile'
import { getResearchCampaignProfileModal, hideModal } from '~/store/modals'
import ResearchCampaignProfileBody from './ResearchCampaignProfileBody'
import ResearchCampaignProfileHeader from './ResearchCampaignProfileHeader'

export default function ResearchCampaignProfileGlobalModal() {
  const dispatch = useDispatch()
  const { visible, researchCampaignId } = useSelector(
    getResearchCampaignProfileModal
  )

  const { data, status } = useQuery(
    ['researchCampaign', researchCampaignId],
    () => api.research.getResearchCampaign(researchCampaignId),
    {
      enabled: visible,
    }
  )

  const isLoading = status === 'loading' || status === 'idle'

  return (
    <Profile.Modal
      visible={visible}
      onClose={() => dispatch(hideModal())}
      header={
        isLoading ? null : (
          <ResearchCampaignProfileHeader
            researchCampaign={data.researchCampaign}
            company={data.company}
          />
        )
      }
      body={
        isLoading ? (
          <Loading />
        ) : (
          <ResearchCampaignProfileBody
            researchCampaign={data.researchCampaign}
            company={data.company}
          />
        )
      }
    />
  )
}
