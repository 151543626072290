import random from 'lodash/random'
import times from 'lodash/times'
import ContentTemplate from './ContentTemplate'
import './Loading.less'

const Container = ({ children, ...styles }) => (
  <div className="content-skeleton-container" style={styles}>
    {children}
  </div>
)

const Circle = ({ size, ...styles }) => (
  <div style={{ height: size, width: size, borderRadius: size, ...styles }} />
)

const Rectangle = (styles) => <div style={{ borderRadius: 4, ...styles }} />

const Lines = ({
  count = random(1, 3),
  minWidth = 70,
  maxWidth = 95,
  lineSpacing = 0,
  ...styles
}) => (
  <>
    {times(count, (i) => (
      <Rectangle
        key={i}
        width={`${random(minWidth, maxWidth)}%`}
        marginBottom={lineSpacing}
        {...styles}
      />
    ))}
  </>
)

const Avatar = ({ minLabelWidth, maxLabelWidth, ...styles }) => (
  <Container display="flex" flexDirection="row" {...styles}>
    <Circle size={36} />
    <Container flex={1} marginLeft={12} marginTop={6}>
      <Lines
        count={2}
        height={8}
        minWidth={minLabelWidth}
        maxWidth={maxLabelWidth}
        lineSpacing={8}
      />
      <div />
    </Container>
  </Container>
)

const Image = (styles) => (
  <Container {...styles}>
    <Rectangle paddingTop="56.25%" />
  </Container>
)

const FeaturedEntrySkeleton = () => (
  <div
    className="content-skeleton"
    style={{ display: 'flex', flexDirection: 'row' }}
  >
    <Container flex={1} paddingTop={68}>
      <Lines count={2} height={20} lineSpacing={16} />
      <Avatar minLabelWidth={10} maxLabelWidth={50} marginTop={24} />
    </Container>
    <Image flex={1} />
  </div>
)

const EntrySkeleton = () => (
  <div className="content-skeleton">
    <Image />
    <Container margin="20px 8px 0">
      <Lines height={12} lineSpacing={8} />
    </Container>
    <Container margin="8px">
      <Avatar minLabelWidth={50} maxLabelWidth={90} />
    </Container>
  </div>
)

const Loading = () => (
  <ContentTemplate
    featuredEntry={<FeaturedEntrySkeleton />}
    entries={times(8, (i) => (
      <EntrySkeleton key={i} />
    ))}
  />
)

export default Loading
