import PropType from 'prop-types'
import DataTransformations from '../DataTransformations'
import './DaySeparator.less'

const DaySeparator = ({ time, dividerTimeStyle }) => (
  <div className="messaging-message-divider">
    <div className="messaging-message-divider-line" />
    <div
      className="messaging-message-divider-time cc-tabletitle-text"
      style={dividerTimeStyle}
    >
      {DataTransformations.RenderStringForDaySeparator(time)}
    </div>
  </div>
)

DaySeparator.propTypes = {
  time: PropType.object.isRequired,
  dividerTimeStyle: PropType.object,
}

export default DaySeparator
