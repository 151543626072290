import PropTypes from 'prop-types'
import { faColumns } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Layout, Row } from 'antd'
import SearchBox from '../SearchBox/SearchBox'
import './TableHeader.less'

const { Header } = Layout

const TableHeader = ({
  searchVisible = true,
  headerTitle,
  onSearch,
  showColumnsFilter = false,
  onColumnsFilter,
  firstCol = 8,
  secondCol = 16,
  isSchedule = false,
}) => (
  <Header
    className="table-header"
    style={{
      background: '#fff',
      padding: 0,
      boxShadow: '0px 8px 13px rgba(0, 0, 0, 0.04)',
    }}
  >
    <Row type="flex" justify="space-around" align="middle">
      <Col className="investors-header-col" span={firstCol}>
        <span className="table-header-text">{headerTitle}</span>
      </Col>

      <Col
        className="investors-header-col"
        span={secondCol}
        style={{ textAlign: isSchedule ? 'left' : 'right' }}
      >
        {/* {isSchedule && !isEmpty(timezoneShowing) && (
          <Timezone
            value={timezoneShowing}
            handleTimezoneChange={setTimezoneShowing}
          />
        )} */}
        {searchVisible ? (
          <SearchBox onSearch={onSearch} allowClear={true} />
        ) : null}
        {showColumnsFilter && (
          <Button
            className="investors-columns"
            type="primary"
            onClick={onColumnsFilter}
          >
            <FontAwesomeIcon
              className="investors-columns-icon"
              icon={faColumns}
            />
          </Button>
        )}
      </Col>
    </Row>
  </Header>
)

TableHeader.propTypes = {
  searchVisible: PropTypes.bool,
  headerTitle: PropTypes.string.isRequired,
  onSearch: PropTypes.func,
  showColumnsFilter: PropTypes.bool,
  onColumnsFilter: PropTypes.func,
  firstCol: PropTypes.number,
  secondCol: PropTypes.number,
  isSchedule: PropTypes.bool,
}

export default TableHeader
