import PropTypes from 'prop-types'
import { IconButton } from '@context365/button'
import { Public } from '@context365/icons'
import {
  faLinkedin,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import Profile from '~/components/Profile'
import { getClickableLink } from '~/utils/helpers'

export default function CompanyProfileHeader({ company, profileUrl }) {
  return (
    <Profile.Header
      profileUrl={profileUrl}
      imageUrl={company.imageUrl}
      title={company.name}
      subtitle={
        <>
          {!isEmpty(company.linkedIn) && (
            <IconButton
              href={getClickableLink(company.linkedIn)}
              as="a"
              target="_blank"
              variant="link"
              status="secondary"
              label="LinkedIn"
              icon={<FontAwesomeIcon icon={faLinkedin} />}
            />
          )}
          {!isEmpty(company.twitter) && (
            <IconButton
              href={getClickableLink(company.twitter)}
              as="a"
              target="_blank"
              variant="link"
              status="secondary"
              label="Twitter"
              icon={<FontAwesomeIcon icon={faTwitter} />}
            />
          )}
          {!isEmpty(company.youTube) && (
            <IconButton
              href={getClickableLink(company.youTube)}
              as="a"
              target="_blank"
              variant="link"
              status="secondary"
              label="YouTube"
              icon={<FontAwesomeIcon icon={faYoutube} />}
            />
          )}
          {!isEmpty(company.website) && (
            <IconButton
              href={getClickableLink(company.website)}
              as="a"
              target="_blank"
              variant="link"
              status="secondary"
              label="Website"
              icon={<Public />}
            />
          )}
        </>
      }
      description={[company.city, company.country]}
    />
  )
}

CompanyProfileHeader.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    categoryName: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    linkedIn: PropTypes.string,
    twitter: PropTypes.string,
    youTube: PropTypes.string,
    website: PropTypes.string,
  }).isRequired,
  profileUrl: PropTypes.string,
}
