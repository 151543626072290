import PropTypes from 'prop-types'
import { Button } from 'antd'
import { Formik } from 'formik'
import {
  getInitialValues,
  renderInput,
} from '../../DynamicForm/CompanyContactForm'
import '~/styles/index.less'
import './InitialStep.less'

const InitialStep = ({
  questions,
  companyName,
  emailValue,
  sendData,
  next,
}) => {
  const initialState = getInitialValues(questions)

  return (
    <Formik
      initialValues={initialState}
      onSubmit={(values) => {
        sendData(values)
        next()
      }}
      render={({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} className="cc-initialstep">
          <div className="cc-allocator-header">
            <span className="FormHeader">{companyName}</span>
            <p
              style={{ paddingTop: '17px' }}
              className="cc-body-text cc-greyscale-darkestblack"
            >
              Enter your personal informations
            </p>
          </div>
          {questions.map((question) =>
            renderInput(
              values,
              question,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              emailValue
            )
          )}
          <Button
            type="submit"
            className="ant-btn-primary"
            style={{ margin: 'auto', width: '364px' }}
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {' '}
            Next{' '}
          </Button>
        </form>
      )}
    />
  )
}

InitialStep.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  companyName: PropTypes.string,
  emailValue: PropTypes.string,
  sendData: PropTypes.func,
  next: PropTypes.func,
}

export default InitialStep
