import PropTypes from 'prop-types'
import { Modal } from 'antd'
import UpdateMonthlyReturns from './UpdateMonthlyReturns'

const UpdateMonthlyReturnsModal = ({
  visible,
  onOk,
  onCancel,
  missingMonths = [],
  fundId,
}) => (
  <Modal
    visible={visible}
    title="Update Returns"
    footer={null}
    onCancel={() => onCancel()}
    destroyOnClose
    width={690}
  >
    <UpdateMonthlyReturns
      fundId={fundId}
      onComplete={() => onOk()}
      missingMonths={missingMonths}
    />
  </Modal>
)

UpdateMonthlyReturnsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  missingMonths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  fundId: PropTypes.number,
}

export default UpdateMonthlyReturnsModal
