import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Exposure } from '@context365/icons'

const DiscoverServiceProviderResultFooter = ({ onShowAddToListModal }) => {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row justify-start space-x-2">
        <Button
          className="color-secondary-90"
          status="secondary"
          iconLeft={<Exposure />}
          onClick={() => onShowAddToListModal()}
        >
          Lists
        </Button>
      </div>
    </div>
  )
}

DiscoverServiceProviderResultFooter.propTypes = {
  serviceProvider: PropTypes.shape({
    companyId: PropTypes.number.isRequired,
    isHiddenForMandate: PropTypes.bool,
  }).isRequired,
  onShowAddToListModal: PropTypes.func.isRequired,
}

export default DiscoverServiceProviderResultFooter
