import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Divider, Row, Tabs } from 'antd'
import { filter, isEmpty, isNil, join, map, reverse } from 'lodash'
import moment from 'moment'
import * as api from '~/api'
import { numberFormat } from '~/utils/formatters'
import { addLineBreaks } from '~/utils/helpers'
import FundAttachments from '../FundAttachments'
import IlliquidBarChart from '../IlliquidCharts/IlliquidBarChart'
import './IlliquidProfileOverview.less'

const { TabPane } = Tabs

const FORMATTERS = {
  date: (data) => (isNil(data) ? '' : moment.utc(data).format('YYYY-MM-DD')),
  currency: (data) => numberFormat.currencyLarge(data) ?? '',
  percent: (data) =>
    numberFormat.decimal(data, { precision: 1, suffix: '%' }) ?? '',
  decimal: (data) => numberFormat.decimal(data) ?? '',
  multiple: (data) => numberFormat.decimal(data, { suffix: 'x' }) ?? '',
}

const PropertyRow = ({ label, value }) => (
  <div className="IlliquidOverview-property-row">
    <span>{label}</span>
    <span className="IlliquidOverview-property-value">{value}</span>
  </div>
)

const StatValue = ({ label, value }) => (
  <>
    <div className="IlliquidOverview-stat-header">{label}</div>
    <div className="IlliquidOverview-stat">{value}</div>
  </>
)

const IlliquidProfileOverview = ({ fundProfile }) => {
  const [irr, setIrr] = useState(null)
  const [tvpi, setTvpi] = useState(null)

  useEffect(() => {
    if (!isNil(fundProfile.fundId)) {
      api.fundCharts
        .getIlliquidReturns(fundProfile.fundId, 'irr', 4)
        .then((response) => {
          const transformed = map(
            reverse(response.data.result.returns),
            (ret) => ({
              interval: isNil(ret.quarter)
                ? ` ${ret.year} `
                : `${ret.year} Q${ret.quarter}`,
              return: ret.metric,
            })
          )

          setIrr({
            cadence: response.data.result.returnCadence,
            type: 'irr',
            data: transformed,
          })
        })

      api.fundCharts
        .getIlliquidReturns(fundProfile.fundId, 'tvpi', 4)
        .then((response) => {
          const transformed = map(
            reverse(response.data.result.returns),
            (ret) => ({
              interval: isNil(ret.quarter)
                ? ` ${ret.year} `
                : `${ret.year} Q${ret.quarter}`,
              return: ret.metric,
            })
          )

          setTvpi({
            cadence: response.data.result.returnCadence,
            type: 'tvpi',
            data: transformed,
          })
        })
    }
  }, [fundProfile.fundId])

  return (
    <div className="IlliquidOverview">
      <Tabs tabPosition="left" size="small">
        <TabPane tab="Fund Overview" key="overview">
          <Row>
            <Col span={13}>
              <div className="IlliquidOverview-header">
                Fund Strategy &amp; Description
              </div>
              <div>{addLineBreaks(fundProfile.tradingMethod)}</div>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: '100%' }} />
            </Col>
            <Col span={10}>
              <Row>
                <Col span={12}>
                  <StatValue
                    label="TVPI (NET)"
                    value={FORMATTERS.multiple(
                      fundProfile.netTotalValuePaidInCapital
                    )}
                  />
                </Col>
                <Col span={12}>
                  <StatValue
                    label="TVPI (Gross)"
                    value={FORMATTERS.multiple(
                      fundProfile.grossTotalValuePaidInCapital
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <StatValue
                    label="RVPI"
                    value={FORMATTERS.multiple(
                      fundProfile.residualValueToPaidIn
                    )}
                  />
                </Col>
                <Col span={12}>
                  <StatValue
                    label="DPI"
                    value={FORMATTERS.multiple(
                      fundProfile.distributionsPaidInCapital
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <StatValue
                    label="IRR (Net)"
                    value={FORMATTERS.percent(
                      fundProfile.netInternalRateOfReturn
                    )}
                  />
                </Col>
                <Col span={12}>
                  <StatValue
                    label="IRR (Gross)"
                    value={FORMATTERS.percent(
                      fundProfile.grossInternalRateOfReturn
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <StatValue
                    label="Target IRR"
                    value={FORMATTERS.percent(
                      fundProfile.expectedTargetedInternalRateOfReturn
                    )}
                  />
                </Col>
                <Col span={12}>
                  <StatValue
                    label="Fund AUM"
                    value={FORMATTERS.currency(fundProfile.fundAum)}
                  />
                </Col>
              </Row>
              <Row>
                {fundProfile.fundId && (
                  <FundAttachments
                    fundId={fundProfile.fundId}
                    showEmptyState={false}
                  />
                )}
              </Row>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Fund Details" key="details">
          <PropertyRow
            label="Inception Date"
            value={FORMATTERS.date(fundProfile.fundInceptionDate)}
          />
          <PropertyRow
            label="Vintage Inception Date"
            value={FORMATTERS.date(fundProfile.fundVintage)}
          />
          <PropertyRow
            label="Target Fund Close Date"
            value={FORMATTERS.date(fundProfile.targetCloseDate)}
          />
          <PropertyRow label="Domicile" value={fundProfile.domicile} />
          <PropertyRow
            label="Manager Location"
            value={fundProfile.companyLocation}
          />
          <PropertyRow
            label="GP Commit"
            value={FORMATTERS.percent(fundProfile.generalPartnerCommitedRatio)}
          />
          <PropertyRow
            label="Target Fund Size"
            value={FORMATTERS.currency(fundProfile.targetFundSize)}
          />
          <PropertyRow label="Fund Term" value={fundProfile.fundTerm} />
          <PropertyRow
            label="Investment Period"
            value={fundProfile.investmentPeriod}
          />
          <PropertyRow
            label="Broad Strategy"
            value={fundProfile.broadStrategy}
          />
          <PropertyRow
            label="Narrow Strategy"
            value={fundProfile.subStrategy}
          />
          <PropertyRow label="Attributes" value={fundProfile.fundAttributes} />
          <PropertyRow
            label="Open ended or closed ended?"
            value={fundProfile.isOpenEnded ? 'Open ended' : 'Closed ended'}
          />
        </TabPane>
        <TabPane tab="Performance" key="performance">
          <div className="IlliquidOverview-ChartsContainer">
            {!isEmpty(tvpi?.data) && (
              <div className="IlliquidOverview-Chart">
                <IlliquidBarChart {...tvpi} />
              </div>
            )}
            {!isEmpty(irr?.data) && (
              <div className="IlliquidOverview-Chart">
                <IlliquidBarChart {...irr} />
              </div>
            )}
          </div>
          <PropertyRow
            label="NAV of Current Assets"
            value={FORMATTERS.currency(fundProfile.currentNetAssetValue)}
          />
          <PropertyRow
            label="Total Commitments"
            value={FORMATTERS.currency(fundProfile.totalCommitments)}
          />
          <PropertyRow
            label="Total Drawdowns Since Inception"
            value={FORMATTERS.currency(
              fundProfile.totalDrawdownsSinceInception
            )}
          />
          <PropertyRow
            label="Percent of Total Drawdowns"
            value={FORMATTERS.percent(fundProfile.totalDrawdownPercentage)}
          />
          <PropertyRow
            label="Remaining Commitments (Dry Powder)"
            value={FORMATTERS.currency(fundProfile.remainingCommitments)}
          />
          <PropertyRow
            label="Percent of Commited Capital"
            value={FORMATTERS.percent(fundProfile.commitedCapitalPercentage)}
          />
          <PropertyRow
            label="Total Number of Investments Since Inception"
            value={fundProfile.totalInvestmentsSinceInception}
          />
          <PropertyRow
            label="Leverage"
            value={FORMATTERS.percent(fundProfile.leverageUtilization)}
          />
        </TabPane>
        {!isEmpty(fundProfile.portfolioCompanies) && (
          <TabPane tab="Positions" key="positions">
            <Row>
              <Col span={17}>
                <table className="Positions-table">
                  <tr>
                    <th>Company</th>
                    <th>Security Type</th>
                    <th>Investment Date</th>
                    <th>Security IRR (SI)</th>
                  </tr>
                  {map(fundProfile.portfolioCompanies, (x) => (
                    <tr>
                      <td>{x.companyName}</td>
                      <td>{x.securityType}</td>
                      <td>{FORMATTERS.date(x.investmentDate)}</td>
                      <td>
                        {FORMATTERS.percent(
                          x.securityInternalRateOfReturnPercentage
                        )}
                      </td>
                    </tr>
                  ))}
                </table>
              </Col>
              <Col span={1}>
                <Divider type="vertical" style={{ height: '100%' }} />
              </Col>
              <Col span={6}>
                <StatValue
                  label="TARGET INVESTMENT HORIZON (YEARS)"
                  value={fundProfile.targetInvestmentHorizonYears}
                />
                <StatValue
                  label="PUBLIC POSITIONS"
                  value={fundProfile.totalPublicPositions}
                />
              </Col>
            </Row>
          </TabPane>
        )}
        <TabPane tab="Terms" key="terms">
          <PropertyRow
            label="Minimum Investment"
            value={FORMATTERS.currency(fundProfile.minimumInvestment)}
          />
          <PropertyRow
            label="Management Fee"
            value={FORMATTERS.percent(fundProfile.managementFee)}
          />
          <PropertyRow
            label="Performance Fee / Carried Interest"
            value={FORMATTERS.percent(fundProfile.performanceFee)}
          />
          <PropertyRow
            label="Preferred Return"
            value={FORMATTERS.percent(fundProfile.preferredReturnPercentage)}
          />
          <PropertyRow
            label="Hurdle Rate"
            value={FORMATTERS.percent(fundProfile.hurdleRate)}
          />
          <PropertyRow
            label="GP Catch Up"
            value={FORMATTERS.percent(fundProfile.catchUp)}
          />
          <PropertyRow
            label="High-Water Mark"
            value={FORMATTERS.currency(fundProfile.highWaterMark)}
          />
          <PropertyRow
            label="Redemption Notice"
            value={fundProfile.daysAdvanceNoticeNeededForRedemption}
          />
          <PropertyRow
            label="Early Redemption Fee"
            value={FORMATTERS.percent(fundProfile.earlyRedemptionFee)}
          />
          <PropertyRow
            label="Annual Withdrawal Not Subject to Redemption Fee"
            value={FORMATTERS.percent(
              fundProfile.annualWithdrawalPercentageWithNoRedemptionFee
            )}
          />
          <PropertyRow
            label="Communication"
            value={fundProfile.limitedPartnerCommunicationFrequency}
          />
          <PropertyRow
            label="Lockup Period"
            value={fundProfile.durationOfCapitalLockup}
          />
          <PropertyRow
            label="Liquidity after Lockup"
            value={fundProfile.postLockupLiquidity}
          />
        </TabPane>
        <TabPane tab="Service Providers" key="serviceproviders">
          <PropertyRow
            label="Prime Broker"
            value={join(
              filter(
                [
                  fundProfile.primeBroker1,
                  fundProfile.primeBroker2,
                  fundProfile.primeBroker3,
                ],
                (x) => !isNil(x)
              ),
              ', '
            )}
          />
          <PropertyRow
            label="Admin Firm"
            value={fundProfile.fundAdministrator}
          />
          <PropertyRow
            label="Law Firm"
            value={fundProfile.externalLegalCounsel}
          />
          <PropertyRow
            label="Investment Advisor"
            value={join(fundProfile.investmentAdvisors, ', ')}
          />
          <PropertyRow
            label="Information Technology"
            value={join(fundProfile.informationTechnologyProviders, ', ')}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}

IlliquidProfileOverview.propTypes = {
  fundProfile: PropTypes.object,
}

export default IlliquidProfileOverview
