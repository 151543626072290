import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, Modal, Tooltip, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { useSelector, useStore } from 'react-redux'
import { Prompt, useHistory } from 'react-router-dom'
import {
  updateIsCFNAllocator,
  updateIsCFNInterestedInDeals,
} from '~/actions/auth'
import {
  getInvestorStatusQuestions,
  postAllocatorContact,
  postAllocatorStatus,
} from '~/actions/onboarding'
import { getInvestorQualification } from '~/actions/settings'
import Loading from '~/components/Loading'
import InvestorDetailsForm from '~/components/OnboardingComponents/InvestorDetailsForm'
import InvestorStatusForm from '~/components/OnboardingComponents/InvestorStatusForm'
import './InvestorPreferences.less'

const InvestorPreferences = ({ fromCfnSettings = false, onNextClicked }) => {
  const [investorStatusVisible, setInvestorStatusVisible] = useState(false)
  const [qualificationText, setQualificationText] = useState(null)
  const [postingInvestorDetails, setPostingInvestorDetails] = useState(false)
  const [canPostInvestorStatus, setCanPostInvestorStatus] = useState(false)
  const [postingInvestorStatus, setPostingInvestorStatus] = useState(false)
  const [investorQuestions, setInvestorQuestions] = useState([])
  const [investorStatusQuestions, setInvestorStatusQuestions] = useState([])

  const [displayInvestorPrefErrors, setDisplayInvestorPrefErrors] =
    useState(false)
  const [displayInvestorStatusErrors, setDisplayInvestorStatusErrors] =
    useState(false)

  const [contentChanged, setContentChanged] = useState(false)
  const [invalidQuestions, setInvalidQuestions] = useState([])
  const history = useHistory()
  const companyId = useSelector((state) => state.auth.company.companyId)
  const isCFNAllocator = useSelector(
    (state) => state.auth.contact.isCFNAllocator
  )
  const role = useSelector((state) => state.auth.role)
  const isCFNIterestedInDeals = useSelector(
    (state) => state.auth.contact.isCFNInterestedInDeals
  )
  const store = useStore()

  const getQualificationMethod = useCallback(async () => {
    try {
      const investorQualificationRaw = await getInvestorQualification(companyId)
      const investorQualificationText = investorQualificationRaw.data.message
      setQualificationText(investorQualificationText)
    } catch (error) {
      message.error(
        'There was an error while attempting to get investor qualification'
      )
    }
  }, [companyId])

  useEffect(() => {
    getQualificationMethod()
  }, [getQualificationMethod])

  const onQualificationChange = useCallback(() => {
    setInvestorStatusVisible(true)
  }, [])

  const onInvestorStatusCanceled = useCallback(() => {
    setInvestorStatusVisible(false)
  }, [])

  const onSaveInvestorStatus = useCallback(async () => {
    try {
      if (!canPostInvestorStatus) {
        setDisplayInvestorStatusErrors(true)
        return
      }

      setPostingInvestorStatus(true)
      await postAllocatorStatus(companyId, investorStatusQuestions)
      await getQualificationMethod()
      message.success('Investor status saved successfully')
      setInvestorStatusVisible(false)
    } catch (error) {
      message.error(
        'There was an error while attempting to save investor status'
      )
    } finally {
      setPostingInvestorStatus(false)
    }
  }, [
    getQualificationMethod,
    canPostInvestorStatus,
    companyId,
    investorStatusQuestions,
  ])

  const onInvestorStatusChange = useCallback(
    (questionShortName, selections) => {
      setInvestorStatusQuestions([
        {
          entity: 'CompanyContactAllocator',
          shortName: questionShortName,
          answer: selections.map((x) => x.split('|')[1]),
          questionTypeID: -1,
        },
      ])
    },
    []
  )

  const onInvestorPrefChange = useCallback((formQuestions) => {
    setInvestorQuestions(formQuestions)
    setContentChanged(true)
  }, [])

  const onSavePreferences = useCallback(async () => {
    try {
      if (!isEmpty(invalidQuestions)) {
        setDisplayInvestorPrefErrors(true)
        return
      }

      setPostingInvestorDetails(true)
      await postAllocatorContact(companyId, investorQuestions)
      if (role === 'CFN' && !isCFNAllocator) {
        store.dispatch(updateIsCFNAllocator())
        if (!isCFNIterestedInDeals) {
          store.dispatch(updateIsCFNInterestedInDeals())
        }
      }
      message.success('Investor preferences saved successfully')
      if (fromCfnSettings) {
        onNextClicked()
      }
    } catch (error) {
      message.error(
        'There was an error while attempting to save investor preferences'
      )
    } finally {
      setPostingInvestorDetails(false)
      setContentChanged(false)
    }
  }, [
    invalidQuestions,
    companyId,
    investorQuestions,
    role,
    isCFNAllocator,
    fromCfnSettings,
    store,
    isCFNIterestedInDeals,
    onNextClicked,
  ])

  const onCancel = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <div className="mt-4">
      <Loading spinning={postingInvestorDetails}>
        <Prompt
          message="Are you sure you want to leave without saving?"
          when={contentChanged}
        />
        <div className="settings-investor-preferences-board">
          <div className="form-container-header">
            <p className="form-container-title">Preferences</p>
            <p className="form-container-description">
              Set your investor preferences
            </p>
          </div>
          {role !== 'CFN' && (
            <div className="cc-settings-investor-status">
              <span className="cc-body-text cc-settings-investor-label">
                Investor Status:
              </span>
              <span className="cc-heading4 cc-settings-investor-label-value">
                {qualificationText}
              </span>
              <Button type="link" onClick={onQualificationChange}>
                Change
              </Button>
            </div>
          )}

          <Modal
            width="848px"
            visible={investorStatusVisible}
            okButtonProps={{
              loading: postingInvestorStatus,
              disabled: !canPostInvestorStatus,
            }}
            onCancel={onInvestorStatusCanceled}
            onOk={onSaveInvestorStatus}
          >
            <div className="cc-settings-status-container">
              <InvestorStatusForm
                companyId={companyId}
                currentQuestions={[]}
                displayErrors={displayInvestorStatusErrors}
                step={{
                  innerStepTitle: 'Investor Status',
                  title: 'Investor Status',
                  renderAction: getInvestorStatusQuestions,
                  action: null,
                  actionType: 4,
                  url: '/onboard/investor-status',
                }}
                enableNext={(enabled) => {
                  setCanPostInvestorStatus(enabled)
                }}
                onInvestorStatusChange={onInvestorStatusChange}
              />
            </div>
          </Modal>

          <InvestorDetailsForm
            currentQuestions={[]}
            companyId={companyId}
            step={{
              // renderAction: getInvestorDetailsQuestions,
              shouldPost: true,
              actionType: 5,
            }}
            setInvalidQuestions={setInvalidQuestions}
            onChange={onInvestorPrefChange}
            displayErrors={displayInvestorPrefErrors}
          />

          <div className="form-section-submit">
            {!fromCfnSettings && (
              <Button
                type="default"
                className="form-section-btn-cancel"
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}
            <Tooltip
              title={
                !isEmpty(invalidQuestions) &&
                'Required fields are not completed.'
              }
            >
              <Button
                type="primary"
                className="form-section-btn-save"
                onClick={onSavePreferences}
                disabled={!isEmpty(invalidQuestions)}
              >
                {fromCfnSettings ? 'Next' : 'Save'}
              </Button>
            </Tooltip>
            {!isEmpty(invalidQuestions) && (
              <Alert
                style={{ textAlign: 'left', marginTop: '40px' }}
                message={<strong>Required fields are not completed.</strong>}
                description={invalidQuestions.map((question, id) => (
                  <div key={id}>{question.label}</div>
                ))}
                type="error"
                showIcon
              />
            )}
          </div>
        </div>
      </Loading>
    </div>
  )
}
InvestorPreferences.propTypes = {
  fromCfnSettings: PropTypes.bool,
  onNextClicked: PropTypes.func,
}
export default InvestorPreferences
