import * as React from 'react'
import { Button } from '@context365/button'
import { FormLabel, FormMessage } from '@context365/forms'
import { RemoveCircleOutline } from '@context365/icons'
import map from 'lodash/map'
import partition from 'lodash/partition'
import { useController, useFieldArray } from 'react-hook-form'
import { InputField, useFieldSchema } from '~/components/Form'

const getNewEducationItem = (isGraduate) => ({
  isGraduate,
  school: '',
  areaOfStudy: '',
})

const EducationItem = ({ fieldPrefix, onRemove }) => (
  <div className="pt-2 pb-2">
    <InputField name={`${fieldPrefix}.school`} label="School" />
    <InputField name={`${fieldPrefix}.areaOfStudy`} label="Area of Study" />
    <div className="pt-2">
      <Button
        iconLeft={<RemoveCircleOutline />}
        variant="outlined"
        status="error"
        onClick={() => onRemove()}
      >
        Remove School
      </Button>
    </div>
  </div>
)

export default function EducationForm({ className }) {
  const rules = useFieldSchema('educations', 'Education')
  const { fields, append, remove } = useFieldArray({ name: 'educations' })
  const {
    fieldState: { error },
  } = useController({ name: 'educations' })
  const [graduateItems, undergradItems] = React.useMemo(
    () =>
      partition(
        map(fields, (f, index) => ({ ...f, index })),
        'isGraduate'
      ),
    [fields]
  )

  const handleAdd = (isGraduate) => append(getNewEducationItem(isGraduate))

  return (
    <div className={className}>
      <FormLabel
        className="type-body-semibold-md mb-0"
        required={rules.required}
      >
        Education
      </FormLabel>
      <FormMessage
        className="mt-0 mb-1 border-b"
        errorMessage={error?.message}
      />
      <div className="flex flex-col pt-2">
        <div>
          <div className="type-body-semibold-sm">Undergrad</div>
          {map(undergradItems, (ui) => (
            <EducationItem
              key={ui.id}
              fieldPrefix={`educations.${ui.index}`}
              onRemove={() => remove(ui.index)}
            />
          ))}
          <Button variant="link" onClick={() => handleAdd(false)}>
            + Add School
          </Button>
        </div>
        <div>
          <div className="type-body-semibold-sm">Graduate</div>
          {map(graduateItems, (gi) => (
            <EducationItem
              key={gi.id}
              fieldPrefix={`educations.${gi.index}`}
              onRemove={() => remove(gi.index)}
            />
          ))}
          <Button variant="link" onClick={() => handleAdd(true)}>
            + Add School
          </Button>
        </div>
      </div>
    </div>
  )
}
