import { api } from './services'

export const getContactAllocatorProfiles = (contactId) =>
  api.get(`allocators/${contactId}/profiles`).then((res) => res.data.result)

export const getAllocatorCompanyProfile = (companyId) =>
  api.get(`allocators/${companyId}`).then((res) => res.data.result)

export const getAllocatorAllocations = (companyId) =>
  api.get(`allocators/${companyId}/allocations`).then((res) => res.data.result)

export const getAllocatorReturns = (companyId) =>
  api.get(`allocators/${companyId}/returns`).then((res) => res.data.result)

export const getAllocatorContacts = (companyId) =>
  api.get(`allocators/${companyId}/people`).then((res) => res.data.result)

export const getAllocatorManagers = (companyId) =>
  api.get(`allocators/${companyId}/managers`).then((res) => res.data.result)
