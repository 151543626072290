import PropTypes from 'prop-types'
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { api as http } from '~/api/services'
import ProfilePictureGroup from '../../ProfilePictureGroup'
import DataTransformations from '../DataTransformations'
import { ContactShape } from '../MessagePropTypes'
import './OtherMessageItem.less'

const OtherMessageItem = ({
  messageElements,
  time,
  contact,
  index,
  useHeader = true,
  isAttachment = false,
  downloadUrl,
}) => (
  <div className="messaging-message-container-other">
    {useHeader && (
      <div className="messaging-message-header">
        <div className="messaging-message-profile-picture">
          <ProfilePictureGroup
            profiles={[contact]}
            diameter={24}
            showImageOnPopover
          />
        </div>
        <div className="messaging-message-name">{contact.contactName}</div>
      </div>
    )}
    <div className="messaging-message-column">
      <span>
        {isAttachment ? (
          <div className="messaging-message-attachment">
            <FontAwesomeIcon
              className="messaging-message-attachment-icon"
              icon={faFilePdf}
            />
            <span className=" cc-small-font messaging-message-attachment-file">
              {messageElements}
            </span>
            <div className="messaging-message-attachment-download-link">
              <Button
                onClick={() => {
                  http.get(downloadUrl).then((response) => {
                    window.open(response.data.message, '_blank')
                  })
                }}
                type="link"
              >
                Download
              </Button>
            </div>
          </div>
        ) : (
          <div className="messaging-message">
            <span message-index={index}>{messageElements}</span>
          </div>
        )}

        <div className="cc-xsmall-font messaging-message-time">
          {DataTransformations.RenderTimeForMessages(time)}
        </div>
      </span>
    </div>
  </div>
)

OtherMessageItem.propTypes = {
  messageElements: PropTypes.arrayOf(PropTypes.element).isRequired,
  time: PropTypes.shape(PropTypes.object).isRequired,
  contact: ContactShape.isRequired,
  index: PropTypes.number,
  useHeader: PropTypes.bool,
  isAttachment: PropTypes.bool,
  downloadUrl: PropTypes.string,
}

export default OtherMessageItem
