import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Input, Popover, Radio, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import numeral from 'numeral'
import { getInvestorDetailsQuestions } from '~/actions/onboarding'
import DragDropContextForm from '../DragDropContextForm'
import FloatingInput from '../FloatingInput'
import FloatingSelect from '../FloatingSelect'
import InvestmentSizeSection from './InvestmentSizeSection'
import InvestmentStrategies from './InvestmentStrategies'
import OnboardingAddressSection from './OnboardingAddressSection'
import OnboardingLoading from './OnboardingLoading'

const { TextArea } = Input

const InvestorDetailsForm = ({
  stepNumber,
  enableNext = () => {},
  onChange,
  companyId,
  setInvalidQuestions = () => {},
}) => {
  const [questions, setQuestions] = useState(null)

  const checkAllQuestions = useCallback(
    (withChange = true) => {
      if (isNil(questions)) return
      const requiredQuestions = questions.filter((x) => x.isRequired)
      const invalidQuestions = requiredQuestions.filter(
        (x) => isNil(x.currentlyValid) || x.currentlyValid === false
      )
      enableNext(invalidQuestions.length === 0)
      if (withChange === true) if (!isNil(onChange)) onChange(questions)
    },
    [enableNext, onChange, questions]
  )
  useEffect(() => {
    if (!isEmpty(questions)) {
      const requiredQuestions = questions.filter((x) => x.isRequired)
      const invalidQuestions = requiredQuestions.filter(
        (x) => x.currentlyValid === false
      )

      setInvalidQuestions(invalidQuestions)
    }
  }, [questions, setInvalidQuestions])

  useEffect(() => {
    getInvestorDetailsQuestions(companyId)
      .then((response) => {
        setQuestions(response.data.result)
      })
      .catch(() =>
        message.error('There was an error while attempting to get questions')
      )
  }, [stepNumber, companyId])
  const renderValidationError = useCallback(
    () => (
      <div className="cc-onboarding-validation-error">
        This field is required
      </div>
    ),
    []
  )

  const onQuestionChange = useCallback(
    (questionTypeID, questionID, shortName, e) => {
      const value = isNil(e.target) ? e : e.target.value
      const currentQuestion = questions.find((x) => x.questionID === questionID)
      if (
        questionTypeID === 5 ||
        questionTypeID === 6 ||
        shortName ===
          'MaximumPercentageOfAFundsAUMYouWouldBeComfortableComprising'
      ) {
        if (!numeral.validate(value) && value !== '') {
          currentQuestion.tooltip = 'Numeric values allowed only'
        } else {
          if (numeral(value).value() > 100) {
            currentQuestion.answer = '100'
          } else {
            currentQuestion.answer = numeral(value).format('0')
          }
          currentQuestion.tooltip = null
        }
      } else if (questionTypeID === 3 || questionTypeID === 14) {
        currentQuestion.answer = value.join(',')
      } else {
        currentQuestion.answer = value
        currentQuestion.tooltip = null
      }
      currentQuestion.currentlyValid = currentQuestion.validation(value)
      currentQuestion.isValid = currentQuestion.validation(value)
      currentQuestion.tooltip = null
      setQuestions(
        questions.map((x) =>
          x.questionID === questionID ? currentQuestion : x
        )
      )
      checkAllQuestions()
    },
    [checkAllQuestions, questions]
  )

  const getOptions = useCallback(
    (dropdownOrCheckbox, companyContactQuestionAnswers) => {
      if (dropdownOrCheckbox === 'ranking') {
        return companyContactQuestionAnswers.map(
          (companyContactQuestionAnswer) => ({
            id: companyContactQuestionAnswer.questionAnswerID,
            content: companyContactQuestionAnswer.questionAnswer,
          })
        )
      } else if (dropdownOrCheckbox === 'checkbox')
        return companyContactQuestionAnswers.map(
          (companyContactQuestionAnswer) => ({
            label: companyContactQuestionAnswer.questionAnswer,
            value: companyContactQuestionAnswer.questionAnswer,
          })
        )
      else
        return companyContactQuestionAnswers.map(
          (companyContactQuestionAnswer) => ({
            value: companyContactQuestionAnswer.questionAnswer,
            text: companyContactQuestionAnswer.questionAnswer,
          })
        )
    },
    []
  )

  const renderManuallyManagedControl = useCallback(
    (shortName, question) => {
      question.validation = () => true
      switch (shortName) {
        case 'InvestmentSizePreferences':
          question.validation = (x) => x?.isValid === true
          question.currentlyValid = question.validation(question.answer)
          return (
            <InvestmentSizeSection
              onChange={onQuestionChange.bind(
                null,
                question.questionTypeID,
                question.questionID,
                question.shortName
              )}
              value={isNil(question.answer) ? {} : question.answer}
            />
          )
        case 'StrategyPreferences':
          question.validation = (x) =>
            !isNil(x) && !isNil(x.strategies) && x.strategies.length > 0
          question.currentlyValid = question.validation(question.answer)
          return (
            <InvestmentStrategies
              onChange={onQuestionChange.bind(
                null,
                question.questionTypeID,
                question.questionID,
                question.shortName
              )}
              value={question.answer}
            />
          )
        case 'MaximumPercentageOfAFundsAUMYouWouldBeComfortableComprising':
          question.validation = (x) => !isNil(x) && x !== ''
          question.currentlyValid = question.validation(question.answer)
          return (
            <div style={{ textAlign: 'left', marginBottom: '24px' }}>
              <div className="cc-heading5 cc-generic-form-checkbox-header">
                {getQuestionName(question)}
              </div>
              <Popover
                content={question.tooltip}
                visible={!isNil(question.tooltip)}
              >
                <Input
                  addonAfter="%"
                  value={question.answer}
                  className={`${
                    question.isValid !== false ? '' : 'cc-onboarding-invalid '
                  }cc-onboarding-percentage`}
                  onChange={onQuestionChange.bind(
                    null,
                    question.questionTypeID,
                    question.questionID,
                    question.shortName
                  )}
                />
              </Popover>

              {question.currentlyValid === false
                ? renderValidationError()
                : null}
            </div>
          )
        case 'MandateOrder':
          question.validation = (x) => {
            const result =
              question.isRequired === false || (!isNil(x) && x.length > 0)
            return result
          }
          question.currentlyValid = question.validation(question.answer)
          return (
            <div style={{ textAlign: 'left' }}>
              <div className="cc-heading5 cc-generic-form-checkbox-header">
                Mandate Order {question.isRequired === true ? '*' : ''}
              </div>
              <div>
                Please rank in order of importance. To rank, drag the items from
                the left column to the right.
              </div>
              <DragDropContextForm
                onChange={(e) => {
                  onQuestionChange(
                    question.questionTypeID,
                    question.questionID,
                    question.shortName,
                    e
                  )
                }}
                value={question.answer}
                options={getOptions('ranking', question.questionAnswers)}
              />

              {question.currentlyValid === false
                ? renderValidationError()
                : null}
            </div>
          )
        case 'AdditionalInvestmentPreferences':
          question.validation = (x) =>
            question.isRequired === false || (!isNil(x) && x !== '')
          question.currentlyValid = question.validation(question.answer)
          return (
            <div style={{ textAlign: 'left' }}>
              <div className="cc-heading5 cc-generic-form-checkbox-header">
                Is there anything else you would like to tell us about your
                investment preferences? (This will help us better match you with
                funds) {question.isRequired === true ? '*' : ''}
              </div>
              <TextArea
                placeholder="Type here..."
                className="cc-onboarding-anything-else-textarea"
                value={question.answer}
                onChange={onQuestionChange.bind(
                  null,
                  question.questionTypeID,
                  question.questionID,
                  question.shortName
                )}
              />
            </div>
          )

        default:
          question.currentlyValid = true
          question.validation = () => true
          return null
      }
    },
    [getOptions, onQuestionChange, renderValidationError]
  )
  const getQuestionName = (question) =>
    question.isRequired === true ? `${question.label} *` : question.label

  const renderControl = useCallback(
    (question) => {
      let input
      const commonFloatInput = (
        <FloatingInput
          tooltip={question.tooltip}
          value={question.answer}
          validationMessage={
            isNil(question.validationMessage)
              ? 'This field is required'
              : question.validationMessage
          }
          isValid={question.isValid === true || isNil(question.isValid)}
          onChange={onQuestionChange.bind(
            null,
            question.questionTypeID,
            question.questionID,
            question.shortName
          )}
          name={getQuestionName(question)}
        />
      )
      switch (question.questionTypeID) {
        case 1:
        case 2:
          input = commonFloatInput
          question.validation = (x) =>
            question.isRequired === false || (!isNil(x) && x !== '')
          break
        case 3:
          input = (
            <div className="cc-generic-form-checkbox-group">
              <span className="cc-heading5 cc-generic-form-checkbox-header">
                {getQuestionName(question)}
              </span>
              <Checkbox.Group
                value={
                  isNil(question.answer)
                    ? question.answer
                    : question.answer.split(',')
                }
                onChange={onQuestionChange.bind(
                  null,
                  question.questionTypeID,
                  question.questionID,
                  question.shortName
                )}
                options={question.questionAnswers.map((x) => ({
                  label: x.questionAnswer,
                  value: x.questionAnswer,
                }))}
              />
              {question.currentlyValid === false
                ? renderValidationError()
                : null}
            </div>
          )
          question.validation = (x) => {
            const result =
              question.isRequired === false || (!isNil(x) && x.length > 0)
            return result
          }
          break
        case 4:
          input = (
            <FloatingSelect
              name={getQuestionName(question)}
              value={question.answer}
              validationMessage={
                isNil(question.validationMessage)
                  ? 'This field is required'
                  : question.validationMessage
              }
              isValid={question.isValid === true || isNil(question.isValid)}
              onChange={onQuestionChange.bind(
                null,
                question.questionTypeID,
                question.questionID,
                question.shortName
              )}
              options={question.questionAnswers.map((x) => ({
                value: x.questionAnswer,
                text: x.questionAnswer,
              }))}
            />
          )
          question.validation = (x) =>
            question.isRequired === false ||
            (!isNil(x) &&
              question.questionAnswers.filter((a) => x === a.questionAnswer)
                .length > 0)
          break
        case 5:
        case 6:
          input = commonFloatInput
          question.validationMessage =
            'This field is required and should have a numeric value'
          question.validation = (x) => numeral.validate(x)
          break
        case 7:
          input = (
            <div className="cc-generic-form-radio-group">
              <span className="cc-heading5 cc-generic-form-radio-header">
                {getQuestionName(question)}
              </span>
              {question.shortName === 'FirstLossCapital' ? (
                <div>Do you only provide first loss capital?</div>
              ) : null}
              <Radio.Group
                onChange={onQuestionChange.bind(
                  null,
                  question.questionTypeID,
                  question.questionID,
                  question.shortName
                )}
                value={question.answer}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>

              {question.currentlyValid === false
                ? renderValidationError()
                : null}
            </div>
          )
          question.validation = (x) => {
            const result =
              question.isRequired === false || (!isNil(x) && x.length > 0)
            return result
          }
          break
        case 12:
          input = (
            <div className="cc-generic-form-radio-group">
              <span className="cc-heading6 cc-generic-form-radio-header">
                {getQuestionName(question)}
              </span>

              <Radio.Group
                onChange={onQuestionChange.bind(
                  null,
                  question.questionTypeID,
                  question.questionID,
                  question.shortName
                )}
                value={question.answer}
              >
                {question.questionAnswers.map((x) => (
                  <Radio value={x.questionAnswer} key={x.questionAnswe}>
                    {x.questionAnswer}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          )
          question.validation = (x) => {
            const result =
              question.isRequired === false || (!isNil(x) && x.length > 0)
            return result
          }
          break
        case 13:
          input = (
            <OnboardingAddressSection
              validationMessage="This field is required"
              onChange={onQuestionChange.bind(
                null,
                question.questionTypeID,
                question.questionID,
                question.shortName
              )}
              value={isNil(question.answer) ? {} : question.answer}
            />
          )
          question.validationMessage = 'This field is required'
          question.currentlyValid = true
          question.validation = (x) => x.answer && x.answer.isValid === true
          break
        case 14:
          input = (
            <div className="cc-generic-form-checkbox-group-grid">
              <span className="cc-heading5 cc-generic-form-checkbox-header">
                {getQuestionName(question)}
              </span>
              <Checkbox.Group
                value={
                  isNil(question.answer)
                    ? question.answer
                    : question.answer.split(',')
                }
                onChange={onQuestionChange.bind(
                  null,
                  question.questionTypeID,
                  question.questionID,
                  question.shortName
                )}
                className="cc-body-text"
                options={question.questionAnswers.map((x) => ({
                  label: x.questionAnswer,
                  value: x.questionAnswer,
                }))}
              />

              {question.currentlyValid === false
                ? renderValidationError()
                : null}
            </div>
          )
          question.validation = (x) => {
            const result =
              question.isRequired === false || (!isNil(x) && x.length > 0)
            return result
          }
          break
        case 15:
          return renderManuallyManagedControl(question.shortName, question)
        default:
          input = commonFloatInput
          question.validation = () => true
          break
      }

      question.currentlyValid = question.validation(question.answer)

      return (
        <>
          <div
            className="cc-generic-form-control-container"
            style={{ width: '100%' }}
          >
            {input}
          </div>
          <div className="cc-investor-details-form-separator" />
        </>
      )
    },
    [onQuestionChange, renderManuallyManagedControl, renderValidationError]
  )

  useEffect(() => {
    if (questions) {
      questions.forEach((q) => {
        q.isValid = !isNil(q.validation) && q.validation(q.answer)
      })
      checkAllQuestions()
    }
  }, [checkAllQuestions, questions])

  return isNil(questions) ? (
    <OnboardingLoading />
  ) : (
    <div className="cc-generic-form-container">
      {questions.map((x) => renderControl(x))}
    </div>
  )
}

InvestorDetailsForm.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  enableNext: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  setInvalidQuestions: PropTypes.func,
}

export default InvestorDetailsForm
