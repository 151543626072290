import { Button } from '@context365/button'

const LiquidFundTypeExplanation = ({ onSwitchFundType }) => (
  <div style={{ paddingBottom: '10px' }}>
    <div>
      Fund Profile Display:{' '}
      <span style={{ fontWeight: '600' }}>Liquid Profile</span>
    </div>
    <div>
      This profile showcases monthly returns and generates a tearsheet. Want to
      change your profile display to the{' '}
      <span style={{ fontWeight: '600' }}>Illiquid fund</span>? (This profile
      showcases quarterly/annual returns, vintage funds, and IRR related
      metrics).{' '}
      <Button
        onClick={() => onSwitchFundType()}
        variant="link"
        style={{
          padding: 0,
          verticalAlign: 'top',
        }}
      >
        Click Here
      </Button>
    </div>
  </div>
)

const IlliquidFundTypeExplanation = ({ onSwitchFundType }) => (
  <div style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
    <div>
      Fund Profile Display:{' '}
      <span style={{ fontWeight: '600' }}>Illiquid Profile</span>
    </div>
    <div>
      This profile showcases quarterly/annual returns, vintage funds, and IRR
      related metrics. Want to change your profile display to the{' '}
      <span style={{ fontWeight: '600' }}>Liquid fund</span>? (This profile
      showcases monthly returns and generates a tearsheet).{' '}
      <Button
        onClick={() => onSwitchFundType()}
        variant="link"
        style={{
          padding: 0,
          verticalAlign: 'top',
        }}
      >
        Click Here
      </Button>
    </div>
  </div>
)

export { LiquidFundTypeExplanation, IlliquidFundTypeExplanation }
