import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import { Input } from '@context365/forms'
import { Circle, Search } from '@context365/icons'
import { TabButton, TabList, TabPanel, Tabs } from '@context365/tabs'
import filter from 'lodash/filter'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import moment from 'moment'
import ToggleMeetingRequests from '~/components/ToggleMeetingRequests'
import SummitAttendeesCtx from '~/utils/contexts/SummitAttendeesCtx'
import SchedulerNotOpen from '../SchedulerNotOpen'
import SummitAttendeeList from './SummitAttendeeList'
import './AttendeeList.less'

const BUTTONS = [
  {
    value: 'allocators',
    text: 'allocators',
  },
  {
    value: 'funds',
    text: 'funds',
  },
  {
    value: 'service-providers',
    text: 'service providers',
  },
]

const AttendeeList = ({
  eventId,
  companyEventId,
  isOptimized,
  role,
  schedulerAccessDate,
  creditsAvailable,
  contextPoints,
  attendeeAccess,
  subtab = '',
  onRefresh,
  confirmedMeetings,
  confirmedMeetingLimit,
  disabledMeetingRequest,
  boothSP = [],
}) => {
  const isFundManager = role.toLowerCase() === 'manager'
  const noAccessForManagersOnly = isFundManager && !attendeeAccess

  const [showSearch, setShowSearch] = useState(false)
  const [mobileSearch, setMobileSearch] = useState(null)
  const [mobileSearchInputValue, setMobileSearchInputValue] = useState('')

  const isSchedulerOpen = useMemo(
    () =>
      moment(schedulerAccessDate).isValid() &&
      moment.utc(schedulerAccessDate).isBefore(moment().utc()),
    [schedulerAccessDate]
  )

  const buttons = useMemo(
    () =>
      filter(BUTTONS, (button) => {
        if (role === 'Allocator' && button.text === 'allocators') {
          return false
        } else if (role === 'Manager' && button.text === 'funds') {
          return false
        } else if (
          role === 'Service Provider' &&
          button.text === 'service providers'
        ) {
          return false
        }
        return true
      }),
    [role]
  )

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setShowSearch(false)
      setMobileSearch(event.target.value)
    }
  }

  return (
    <Tabs>
      <div className="md:grid md:grid-cols-3 my-4 flex justify-between px-2">
        {showSearch ? (
          <>
            <div>
              <Input
                prefix={<Search />}
                value={mobileSearchInputValue}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  setMobileSearchInputValue(e.target.value)
                }}
              />
            </div>
            <Button
              variant="filled"
              status="secondary"
              onClick={() => {
                setShowSearch(false)
                setMobileSearch(mobileSearchInputValue)
              }}
            >
              Search
            </Button>
            <Button
              variant="link"
              status="error"
              onClick={() => {
                setShowSearch(false)
                setMobileSearch('')
                setMobileSearchInputValue('')
              }}
            >
              Clear
            </Button>
          </>
        ) : (
          <>
            <div className="md:px-0 md:col-start-2 md:justify-self-center">
              <TabList status="secondary">
                {map(buttons, (b) => (
                  <TabButton id={b.value} label={startCase(b.text)} />
                ))}
              </TabList>
            </div>
            <div className="relative h-6">
              <IconButton
                icon={<Search />}
                label="search"
                variant="filled"
                status="secondary"
                className="md:hidden"
                onClick={() => setShowSearch(true)}
              />
              {mobileSearch && (
                <div className="absolute -top-2.5 -right-1.5 pointer-events-none">
                  <Circle color="red" size={14} />
                </div>
              )}
            </div>

            <div className="hidden md:block" style={{ textAlign: 'right' }}>
              {!isNil(confirmedMeetingLimit) &&
                !isNil(confirmedMeetings) &&
                !isNil(eventId) &&
                eventId > 0 && (
                  <ToggleMeetingRequests
                    confirmedMeetingLimit={confirmedMeetingLimit}
                    confirmedMeetings={confirmedMeetings}
                    disabledMeetingRequest={disabledMeetingRequest}
                    eventId={eventId}
                    placement="left"
                  />
                )}
            </div>
          </>
        )}
      </div>

      {map(buttons, (b) => (
        <TabPanel tabId={b.value} className="h-full">
          {() =>
            isSchedulerOpen && attendeeAccess ? (
              <SummitAttendeesCtx.Provider
                value={{
                  creditsAvailable,
                  contextPoints,
                  category: b.value,
                  boothSP,
                  mobileSearch,
                }}
              >
                <SummitAttendeeList
                  isOptimized={isOptimized}
                  eventId={eventId}
                  companyEventId={companyEventId}
                  creditsAvailable={creditsAvailable}
                  title={startCase(b.text)}
                  contextPoints={contextPoints}
                  category={b.value}
                />
              </SummitAttendeesCtx.Provider>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <SchedulerNotOpen
                  noAccessForManagers={noAccessForManagersOnly}
                  schedulerAccessDate={schedulerAccessDate}
                  companyEventId={companyEventId}
                  eventId={eventId}
                  onRefresh={onRefresh}
                />
              </div>
            )
          }
        </TabPanel>
      ))}
    </Tabs>
  )
}

AttendeeList.propTypes = {
  eventId: PropTypes.number.isRequired,
  companyEventId: PropTypes.number.isRequired,
  isOptimized: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  schedulerAccessDate: PropTypes.instanceOf(Date),
  creditsAvailable: PropTypes.number.isRequired,
  contextPoints: PropTypes.number.isRequired,
  attendeeAccess: PropTypes.bool.isRequired,
  subtab: PropTypes.string,
  onRefresh: PropTypes.func.isRequired,
  confirmedMeetingLimit: PropTypes.number,
  confirmedMeetings: PropTypes.number,
  disabledMeetingRequest: PropTypes.bool,
  boothSP: PropTypes.array,
}

export default AttendeeList
