import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Input, Radio, RadioGroup } from '@context365/forms'
import { DatePicker, Form, InputNumber } from 'antd'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import moment from 'moment'
import FileField from '~/components/GenericForm/Fields/FileField'
import LabelField from '~/components/GenericForm/Fields/LabelField'

const PortfolioField = ({
  value,
  errors = {},
  onChange,
  addItem,
  showAdd,
  showRemove,
  removeItem,
  securityOptions,
}) => {
  const handleChange = (field, val) => {
    onChange({ ...value, [field]: val })
  }

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <div className="Question-row">
          <span className="Question-col" style={{ paddingRight: '10px' }}>
            <Input
              label="Company Name"
              errorMessage={errors.companyName}
              value={value.companyName}
              onChange={(e) => handleChange('companyName', e.target.value)}
            />
          </span>
          <span className="Question-col" style={{ paddingLeft: '10px' }}>
            <Form.Item
              validateStatus={errors.investmentDate ? 'error' : 'success'}
              help={
                errors.investmentDate &&
                typeof errors.investmentDate === 'string'
                  ? errors.investmentDate
                  : ''
              }
            >
              <LabelField title="Investment Date" />
              <DatePicker
                onChange={(date, dateString) =>
                  handleChange('investmentDate', dateString)
                }
                defaultValue={
                  isNil(value.investmentDate)
                    ? undefined
                    : moment(value.investmentDate)
                }
              />
            </Form.Item>
          </span>
        </div>
        <div className="Question-row">
          <span className="Question-col" style={{ paddingRight: '10px' }}>
            <Form.Item
              validateStatus={errors.securityTypeId ? 'error' : 'success'}
              help={
                errors.securityTypeId &&
                typeof errors.securityTypeId === 'string'
                  ? errors.securityTypeId
                  : ''
              }
            >
              <LabelField title="Security Type" />
              <RadioGroup
                name="securityTypeId"
                value={value.securityTypeId}
                onChange={(e) => handleChange('securityTypeId', e.target.value)}
              >
                {map(securityOptions, (opt) => (
                  <div>
                    <Radio value={opt.value}>{opt.label}</Radio>
                  </div>
                ))}
              </RadioGroup>
            </Form.Item>
          </span>
          <span className="Question-col" style={{ paddingLeft: '10px' }}>
            <LabelField title="Security IRR (SI)" />
            <InputNumber
              style={{ width: '100%' }}
              value={value.securityInternalRateOfReturnPercentage}
              onChange={(val) =>
                handleChange('securityInternalRateOfReturnPercentage', val)
              }
            />
          </span>
        </div>
        <Form.Item
          validateStatus={errors.marketingMaterialUrls ? 'error' : 'success'}
          help={
            errors.marketingMaterialUrls &&
            typeof errors.marketingMaterialUrls === 'string'
              ? errors.marketingMaterialUrls
              : ''
          }
        >
          <LabelField title="Please upload any materials related to the company that LPs and potential LPs should review" />
          <FileField
            name="marketingMaterialUrls"
            value={value.marketingMaterialUrls}
            hint={['File Types: .pdf', 'Max File Size: 5MB']}
            setFieldValue={(name, files) => handleChange(name, files)}
          />
        </Form.Item>
      </div>
      {showRemove && (
        <div>
          <Button variant="link" status="error" onClick={() => removeItem()}>
            Remove
          </Button>
        </div>
      )}
      {showAdd && (
        <div>
          <Button variant="link" onClick={() => addItem()}>
            + Add another portfolio company
          </Button>
        </div>
      )}
    </>
  )
}

PortfolioField.propTypes = {
  value: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  addItem: PropTypes.func,
  showAdd: PropTypes.bool,
  showRemove: PropTypes.bool,
  removeItem: PropTypes.func,
  securityOptions: PropTypes.array,
}

export default PortfolioField
