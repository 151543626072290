import PropTypes from 'prop-types'
import { Divider, Select } from 'antd'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

const FundSelectForMeetingRequest = ({
  textOnly,
  funds,
  selectedFund,
  onChangeFund,
}) => {
  if (textOnly) {
    return (
      <>
        <div className="cc-tabletitle-text cc-darkest-grey-text">
          Fund you are presenting
        </div>
        <Divider style={{ margin: '16px 0px' }} />
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <span className="cc-heading6 cc-darkest-grey-text">
            {selectedFund}
          </span>
        </div>
      </>
    )
  } else {
    return (
      !isEmpty(funds) && (
        <div>
          <div className="cc-heading4">Fund you want to present</div>
          <div style={{ display: 'flex', width: '70%' }}>
            <Select
              className="cc-background-input"
              size="large"
              defaultActiveFirstOption={true}
              defaultValue={funds[0].name}
              style={{ width: '100%', marginTop: '16px' }}
              placeholder="Select fund"
              onChange={(e) => {
                onChangeFund(e)
              }}
            >
              {map(funds, (fund) => (
                <Select.Option key={fund.fundId} value={fund.fundId}>
                  {fund.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      )
    )
  }
}

FundSelectForMeetingRequest.propTypes = {
  textOnly: PropTypes.bool.isRequired,
  selectedFund: PropTypes.string.isRequired,
  selectedFundId: PropTypes.number.isRequired,
  funds: PropTypes.array,
}

export default FundSelectForMeetingRequest
