import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { faClock, faUserFriends } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Empty, Row, Tag } from 'antd'
import concat from 'lodash/concat'
import isEmpty from 'lodash/isEmpty'
import { useTracking } from 'react-tracking'
import SimpleBar from 'simplebar-react'
import { presentations as presentationsClient } from '~/api'
import { api as http } from '~/api/services'
import { DEFAULT_AUDIO_ON, DEFAULT_VIDEO_ON } from '~/config'
import { boldCompanyNameFormat } from '~/utils/helpers'
import Loading from '../Loading'
import VideoSettings from '../VideoSettings'
import ClockTimer from './ClockTimer'
import NextPresentationItem from './NextPresentationItem'
import UpcomingList from './UpcomingList'
import './MeetingLobbyDetails.less'

const MeetingLobbyDetails = ({ saveSettings, settingsSaved }) => {
  const { trackEvent } = useTracking({ component: 'MeetingLobbyDetails' })
  const [meetingAvailable, setMeetingAvailable] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingPresentations, setIsLoadingPresentations] = useState(true)
  const [, setMicOn] = useState(null)
  const [, setVideoOn] = useState(null)
  const [confirmedMeetings, setConfirmedMeetings] = useState([])
  const [presentations, setPresentations] = useState([])
  const [nextPresentation, setNextPresentation] = useState(null)
  const [showSettings, setShowSettings] = useState(false)
  const [selectedMetingType, setSelectedMeetingType] = useState()
  const [selectedMetingId, setSelectedMeetingId] = useState()

  const fetchMeetings = useCallback(() => {
    setIsLoading(true)
    http
      .get('meetings/meetinglobby')
      .then((response) => {
        setConfirmedMeetings(response.data.result.results)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const fetchPresentations = useCallback(() => {
    setIsLoadingPresentations(true)
    presentationsClient
      .getPresentationForLobby()
      .then((response) => {
        setPresentations(response.data.result)
        const livePresentations = response.data.result.find(
          (p) => p.isLive === true
        )
        setNextPresentation(livePresentations || response.data.result[0])
      })
      .finally(() => {
        setIsLoadingPresentations(false)
      })
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchMeetings()
      fetchPresentations()
    }, 300000)
    return () => clearInterval(interval)
  }, [fetchMeetings, fetchPresentations])

  useEffect(() => {
    fetchMeetings()
    fetchPresentations()
    const savedMic = localStorage.getItem(DEFAULT_AUDIO_ON)
    setMicOn(isEmpty(savedMic) ? true : savedMic === 'true')
    const savedVideo = localStorage.getItem(DEFAULT_VIDEO_ON)
    setVideoOn(isEmpty(savedVideo) ? true : savedVideo === 'true')
    // const settingsSaved = localStorage.getItem(SETTINGS_SAVED);
    // setSettingsSaved(isEmpty(settingsSaved) ? false : settingsSaved === 'true');
  }, [fetchMeetings, fetchPresentations])

  const getMultipleCompanyMeeting = useCallback(
    (confirmedMeeting) => (
      <div>
        {confirmedMeeting.companies.map((rec, key) => (
          <>
            {rec.participants.length > 0 && (
              <div key={key}>
                <div className="cc-white-heading1" style={{ fontSize: '30px' }}>
                  {rec.companyName}
                </div>

                <div className="cc-meeting-lobby-body">
                  <FontAwesomeIcon
                    icon={faUserFriends}
                    style={{ marginRight: '8px' }}
                  />
                  {rec.participants.map((x, index) => (
                    <span key={index}>
                      {x.contactName}
                      {index !== rec.participants.length - 1 && <span>,</span>}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </>
        ))}
      </div>
    ),
    []
  )
  const upcomingEvents = concat(
    confirmedMeetings.filter(
      (confirmedMeeting) =>
        confirmedMeeting.meetingDateTime !==
        confirmedMeetings[0].meetingDateTime
    ),
    presentations
      .filter((presentation) => presentation?.id !== nextPresentation?.id)
      .map((presentation) => ({
        ...presentation,
        meetingDateTime: presentation.startTime,
      }))
  )

  return (
    <Loading
      spinning={isLoading || isLoadingPresentations}
      className="cc-center-gif"
    >
      {isEmpty(confirmedMeetings) && isEmpty(presentations) ? (
        !isLoading && (
          <Empty
            style={{ color: 'white' }}
            description="There are no upcoming meetings."
          />
        )
      ) : (
        <Row className="cc-meeting-lobby-header">
          <Col
            xs={{ span: 24 }}
            s={{ span: 24 }}
            md={{ span: 11, offset: 1 }}
            lg={{ span: 12, offset: 3 }}
            xl={{ span: 12, offset: 3 }}
          >
            <SimpleBar className="cc-meeting-lobby-scrollbar">
              {confirmedMeetings
                .filter(
                  (confirmedMeeting) =>
                    confirmedMeeting.meetingDateTime ===
                    confirmedMeetings[0].meetingDateTime
                )
                .map((confirmedMeeting, index) => (
                  <div key={index}>
                    <div style={{ display: 'block', marginTop: '20px' }}>
                      <div
                        className="cc-meeting-lobby-body"
                        style={{ display: 'flex' }}
                      >
                        <span>Next Meeting</span>
                      </div>
                      {confirmedMeeting?.companies ? (
                        getMultipleCompanyMeeting(confirmedMeeting)
                      ) : (
                        <div>
                          <div
                            className="cc-white-heading1"
                            style={{ fontSize: '30px' }}
                          >
                            {boldCompanyNameFormat(confirmedMeeting)}
                          </div>
                          <div className="cc-meeting-lobby-body">
                            <FontAwesomeIcon
                              icon={faUserFriends}
                              style={{ marginRight: '8px' }}
                            />
                            {confirmedMeeting.members.map((x, index) => (
                              <span key={index}>
                                {x.contactName}
                                {index !==
                                  confirmedMeeting.members.length - 1 && (
                                  <span>,</span>
                                )}
                              </span>
                            ))}
                          </div>
                        </div>
                      )}

                      <div
                        className="cc-meeting-lobby-body"
                        style={{ display: 'flex' }}
                      >
                        {!meetingAvailable && (
                          <>
                            <FontAwesomeIcon
                              icon={faClock}
                              style={{ marginRight: '8px' }}
                            />
                            <span style={{ margin: '0px 7px 0px 0px' }}>
                              Starts in{' '}
                            </span>
                            <ClockTimer
                              compact
                              eventDate={confirmedMeeting.meetingDateTime}
                              setMeetingAvailable={setMeetingAvailable}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {meetingAvailable ? (
                      <div>
                        <div style={{ display: 'flex' }}>
                          <Button
                            type="primary"
                            size="large"
                            onClick={() => {
                              if (settingsSaved) {
                                window.open(
                                  `/video-conference/${confirmedMeeting.meetingType}/${confirmedMeeting.meetingId}`,
                                  '_blank'
                                )
                              } else {
                                trackEvent({
                                  eventName: 'popup',
                                  element: 'audio/video settings',
                                })
                                setSelectedMeetingId(confirmedMeeting.meetingId)
                                setSelectedMeetingType(
                                  confirmedMeeting.meetingType
                                )
                                setShowSettings(true)
                              }
                            }}
                          >
                            Join The Meeting
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div style={{ display: 'flex' }}>
                        <Tag
                          color="rgba(0, 141, 202, 0.1)"
                          className="cc-meeting-lobby-tag "
                        >
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            style={{ marginRight: '5px', color: '#008DCA' }}
                          />
                          Meeting will be available soon
                        </Tag>
                      </div>
                    )}
                  </div>
                ))}
              {nextPresentation && (
                <div style={{ display: 'block', marginTop: '20px' }}>
                  <div
                    className="cc-meeting-lobby-body"
                    style={{ display: 'flex' }}
                  >
                    <span>Next Presentation</span>
                  </div>
                  <NextPresentationItem presentation={nextPresentation} />
                </div>
              )}

              {!isEmpty(confirmedMeetings) && (
                <UpcomingList
                  confirmedMeetings={upcomingEvents}
                  settingsSaved={settingsSaved}
                  saveSettings={saveSettings}
                />
              )}
            </SimpleBar>
          </Col>
          <Col
            xs={{ span: 24 }}
            s={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 9 }}
            xl={{ span: 9 }}
          >
            {/* {role !== 'Manager' && (
                <div className="sponsors">
                  <img
                    src={first_summit_sponsors}
                    srcSet={`${first_summit_sponsors} 1x,${first_summit_sponsors2x} 2x`}
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                  />
                </div>
              )} */}
          </Col>
        </Row>
      )}
      <VideoSettings
        visible={showSettings}
        joinMeeting={() => {
          setShowSettings(false)
          window.open(
            `/video-conference/${selectedMetingType}/${selectedMetingId}`,
            '_blank'
          )
        }}
        saveSettings={saveSettings}
        closeModal={() => {
          setShowSettings(false)
        }}
        fromMeeting={true}
      />
    </Loading>
  )
}

MeetingLobbyDetails.propTypes = {
  settingsSaved: PropTypes.bool,
  saveSettings: PropTypes.func,
}

export default MeetingLobbyDetails
