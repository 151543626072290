import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Input } from '@context365/forms'
import { RemoveCircleOutline } from '@context365/icons'

const LinkField = ({
  value,
  onChange,
  showAdd,
  addItem,
  showRemove,
  removeItem,
  errors,
}) => {
  const handleChange = (field, val) => {
    onChange({ ...value, [field]: val })
  }
  return (
    <>
      <div className="mb-8">
        <div className="md:grid md:grid-cols-4 md:gap-4">
          <div>
            <Input
              errorMessage={errors?.title}
              required
              label="Title"
              name="title"
              value={value.title}
              onChange={(e) => handleChange('title', e.target.value)}
            />
          </div>
          <div>
            <Input
              errorMessage={errors?.url}
              required
              label="Url"
              name="url"
              value={value.url}
              onChange={(e) => handleChange('url', e.target.value)}
            />
          </div>
          {showRemove && (
            <div className="hidden md:flex pt-5 col-start-4">
              <Button
                iconLeft={<RemoveCircleOutline />}
                variant="outlined"
                status="error"
                onClick={() => removeItem()}
              >
                Remove Link
              </Button>
            </div>
          )}
        </div>
      </div>
      {showRemove && (
        <div className="md:hidden mb-8">
          <Button
            className="w-full"
            iconLeft={<RemoveCircleOutline />}
            variant="outlined"
            status="error"
            onClick={() => removeItem()}
          >
            Remove Link
          </Button>
        </div>
      )}
      {showAdd && (
        <div>
          <Button variant="link" onClick={() => addItem()}>
            + Add Link
          </Button>
        </div>
      )}
    </>
  )
}

LinkField.propTypes = {
  value: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  showAdd: PropTypes.bool,
  showRemove: PropTypes.bool,
  addItem: PropTypes.func,
  removeItem: PropTypes.func,
}
export default LinkField
