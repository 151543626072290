import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Input, Select } from '@context365/forms'
import { RemoveCircleOutline } from '@context365/icons'
import { Form } from 'antd'
import DateField from './DateField'
import LabelField from './LabelField'

const InvestmentField = ({
  value,
  errors = {},
  onChange,
  addItem,
  showAdd,
  showRemove,
  removeItem,
  securityTypeOptions,
  investmentStatusOptions,
  listNumber,
}) => {
  const handleChange = (field, val) => {
    onChange({ ...value, [field]: val })
  }

  return (
    <>
      <div>
        <div className="flex justify-between">
          <div className="type-body-semibold-md">Investment #{listNumber}</div>
          {showRemove && (
            <div className="hidden md:flex">
              <Button
                variant="outlined"
                status="error"
                iconLeft={<RemoveCircleOutline />}
                onClick={() => removeItem()}
              >
                Remove Holding
              </Button>
            </div>
          )}
        </div>
        <div className="Question-row mb-2">
          <span className="Question-col" style={{ paddingRight: '10px' }}>
            <Input
              errorMessage={errors.name}
              required
              label="Name"
              name="name"
              helpMessage="e.g. Starwood Hotels"
              value={value.name}
              onChange={(e) => handleChange('name', e.target.value)}
            />
          </span>
          <span className="Question-col" style={{ paddingLeft: '10px' }}>
            <Select
              label="Security Type"
              name="securityTypeId"
              value={value.securityTypeId}
              onChange={(val) => handleChange('securityTypeId', val)}
              errorMessage={errors.securityTypeId}
              helpMessage="e.g. Equity"
              required
            >
              {securityTypeOptions.map((option) => (
                <Select.Option
                  key={option.id}
                  className="py-2"
                  value={option.id}
                >
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </span>
        </div>
        <div className="Question-row">
          <span className="Question-col" style={{ paddingRight: '10px' }}>
            <Form.Item
              style={{ marginBottom: 0 }}
              validateStatus={errors.investmentDate ? 'error' : 'success'}
              help={
                errors.investmentDate &&
                typeof errors.investmentDate === 'string'
                  ? errors.investmentDate
                  : ''
              }
            >
              <LabelField title="Investment Date" isRequired />
              <DateField
                name="investmentDate"
                value={value.investmentDate}
                setFieldValue={(name, val) => handleChange(name, val)}
              />
            </Form.Item>
          </span>
          <span className="Question-col" style={{ paddingLeft: '10px' }}>
            <Select
              label="Status"
              name="investmentStatusId"
              value={value.investmentStatusId}
              onChange={(val) => handleChange('investmentStatusId', val)}
              errorMessage={errors.investmentStatusId}
              required
            >
              {investmentStatusOptions.map((option) => (
                <Select.Option
                  key={option.id}
                  className="py-2"
                  value={option.id}
                >
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </span>
        </div>
      </div>
      {showRemove && (
        <div className="md:hidden mb-8">
          <Button
            className="w-full"
            variant="outlined"
            status="error"
            iconLeft={<RemoveCircleOutline />}
            onClick={() => removeItem()}
          >
            Remove Holding
          </Button>
        </div>
      )}
      {showAdd && (
        <div>
          <Button variant="link" onClick={() => addItem()}>
            + Add Holding
          </Button>
        </div>
      )}
    </>
  )
}

InvestmentField.propTypes = {
  value: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  addItem: PropTypes.func,
  showAdd: PropTypes.bool,
  showRemove: PropTypes.bool,
  removeItem: PropTypes.func,
  securityTypeOptions: PropTypes.array,
  investmentStatusOptions: PropTypes.array,
  listNumber: PropTypes.number.isRequired,
}

export default InvestmentField
