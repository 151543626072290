import * as React from 'react'
import { useTheme } from '@context365/config'

/**
 * @param {'sm' | 'md' | 'lg' | 'xl' | '2xl'} breakpoint
 */
export default function useMinScreen(breakpoint) {
  const minWidth = useTheme(['screens', breakpoint])
  const query = `(min-width: ${minWidth})`

  const [matches, setMatches] = React.useState(window.matchMedia(query).matches)

  React.useEffect(() => {
    let mounted = true
    const mediaQueryList = window.matchMedia(query)
    const onChange = () => {
      if (!mounted) {
        return
      }
      setMatches(!!mediaQueryList.matches)
    }

    mediaQueryList.addListener(onChange)
    setMatches(!!mediaQueryList.matches)

    return () => {
      mounted = false
      mediaQueryList.removeListener(onChange)
    }
  }, [query])

  return matches
}
