import map from 'lodash/map'

const FundApplicationFeedback = ({
  applicationFeedbackResponses,
  applicationFeedbackMessage,
}) => {
  return (
    <div className="py-4 px-8 flex flex-col gap-4 mb-8">
      <div className="type-header-xxs">
        Why the campaign owner is not interested at this time:
      </div>
      <ul className="px-6">
        {map(applicationFeedbackResponses, (response, index) => (
          <li key={index}>{response}</li>
        ))}
      </ul>
      {applicationFeedbackMessage && (
        <div className="italic">{`"${applicationFeedbackMessage}"`}</div>
      )}
    </div>
  )
}

export default FundApplicationFeedback
