import { useCallback, useEffect, useState } from 'react'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal, Row, message, notification } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Video from 'twilio-video'
import { getConversation } from '~/actions/messages'
import { api as http } from '~/api/services'
import Banner from '~/components/Banner'
import UpcomingMeetingsBanner from '~/components/Banner/UpcomingMeetingsBanner'
import Loading from '~/components/Loading'
import VideoConferenceRoom from '~/components/VideoConferenceRoom/VideoRoom/VideoRoom'
import {
  DEFAULT_AUDIO_INPUT,
  DEFAULT_AUDIO_ON,
  DEFAULT_AUDIO_OUTPUT,
  DEFAULT_VIDEO_INPUT,
  DEFAULT_VIDEO_ON,
} from '~/config'
import NotificationHub from '~/utils/notificationHub'
import './VideoConference.less'

const notifyNextMeeting = new NotificationHub()

const VideoConference = () => {
  const { meetingType, meetingId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [meetingCompanyName, setMeetingCompanyName] = useState('')
  const [documents, setDocuments] = useState(null)
  const [contactsInMeeting, setContactsInMeeting] = useState([])
  const [token, setToken] = useState('')
  const [nextMeeting, setNextMeeting] = useState(null)
  const [visible, setVisible] = useState(false)
  const [room, setRoom] = useState(null)
  const [conversationID, setConversationID] = useState(null)
  const [leaveCallVisible, setleaveCallVisible] = useState({
    openDialogShow: false,
    connectNextMeeting: false,
  })
  const contactId = useSelector((state) => state.auth.contact.contactId)
  const [micOn, setMicOn] = useState(true)
  const [videoOn, setVideoOn] = useState(true)
  const [noAudioDetected, setNoAudioDetected] = useState(false)
  const [videoDeviceId, setVideoDeviceId] = useState(null)
  const [audioOutputDeviceId, setAudioOutputDeviceId] = useState(null)
  const [audioDeviceId, setAudioDeviceId] = useState(null)
  const [noPermissions, setNoPermissions] = useState(false)
  const [otherError, setOtherError] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    http
      .post(`/meetinglobby/join/${meetingId}`)
      .then((response) => {
        setToken(response.data.result.token)
        setConferenceRoom(
          response.data.result.token,
          response.data.result.companyName,
          response.data.result.companyId,
          response.data.result.eventName
        )
        setContactsInMeeting(
          response.data.result?.participants
            ? response.data.result.participants
            : response.data.result.contacts
        )
        setConversationID(response.data.result.conversationId)
        getConversation(response.data.result.conversationId, true, true).then(
          (res) => {
            const companyName = `${response?.data.result.companyName}${
              isNil(res?.data?.result?.details?.invitedCompanyName)
                ? ''
                : ` & ${res?.data?.result?.details?.invitedCompanyName}`
            }`
            setMeetingCompanyName(companyName)
            setDocuments(res.data.result.details.funds)
          }
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [meetingId, meetingType])

  useEffect(() => {
    const onClose = () => endMeeting()
    window.addEventListener('beforeunload', onClose)

    !notifyNextMeeting.isSubscribed &&
      notifyNextMeeting.notifyNextMeeting(
        Number(meetingId),
        Number(contactId),
        onNextMeetingNotify,
        onNotificatioForDelayFromCompany
      )
    return () => {
      window.removeEventListener('beforeunload', onClose)
    }
  })

  const refreshContacts = useCallback(() => {
    http.get(`/meetinglobby/details/${meetingId}`).then((response) => {
      setContactsInMeeting(
        response.data.result?.participants
          ? response.data.result.participants
          : response.data.result.contacts
      )
    })
  }, [meetingId])

  const setConferenceRoom = (
    newToken,
    calledCompanyName,
    calledCompanyId,
    eventName
  ) => {
    if (isNil(newToken) && isEmpty(newToken)) return

    let videoSettings = true
    let audioSettings = true
    const defaultAudio = localStorage.getItem(DEFAULT_AUDIO_INPUT)
    const defaultAudioOutput = localStorage.getItem(DEFAULT_AUDIO_OUTPUT)
    const defaultVideo = localStorage.getItem(DEFAULT_VIDEO_INPUT)
    const defaultAudioOn = localStorage.getItem(DEFAULT_AUDIO_ON)
    const defaultVideoOn = localStorage.getItem(DEFAULT_VIDEO_ON)
    setMicOn(isEmpty(defaultAudioOn) ? true : defaultAudioOn === 'true')
    setVideoOn(isEmpty(defaultVideoOn) ? true : defaultVideoOn === 'true')
    const configurations = {
      audio: isEmpty(defaultAudio)
        ? false
        : {
            deviceId: defaultAudio,
          },
      video: isEmpty(defaultVideo)
        ? { height: 1080, frameRate: 24, width: 1920 }
        : {
            deviceId: defaultVideo,
            height: 1080,
            frameRate: 24,
            width: 1920,
          },
    }
    setVideoDeviceId(isEmpty(defaultVideo) ? null : defaultVideo)
    setAudioDeviceId(isEmpty(defaultAudio) ? null : defaultAudio)
    setAudioOutputDeviceId(
      isEmpty(defaultAudioOutput) ? null : defaultAudioOutput
    )
    navigator.mediaDevices
      .getUserMedia(configurations)
      .then((media) => {
        media.getTracks().forEach((track) => {
          track.stop()
        })
        videoSettings = true
      })
      .catch(() => {
        videoSettings = false
      })
      .finally(() => {
        connectVideo(
          newToken,
          audioSettings,
          defaultAudio,
          defaultVideo,
          videoSettings,
          calledCompanyName,
          calledCompanyId,
          eventName
        ).catch((error) => {
          if (
            error.message.includes('Could not start audio source') ||
            error.message.includes('device not found')
          ) {
            setNoAudioDetected(true)
            audioSettings = false
            connectVideo(
              newToken,
              audioSettings,
              defaultAudio,
              defaultVideo,
              videoSettings,
              calledCompanyName,
              calledCompanyId,
              eventName
            ).catch((err) => {
              setOtherError(true)
              message.error(`ROOM CONNECTION FAILED: ${err.message}`)
            })
          } else if (error.message.includes('Permission denied')) {
            setNoPermissions(true)
            message.error(`ROOM CONNECTION FAILED: ${error.message}`)
          } else {
            setOtherError(true)
            message.error(`ROOM CONNECTION FAILED: ${error.message}`)
          }
        })
      })
  }

  const connectVideo = useCallback(
    (newToken, audioSettings, defaultAudio, defaultVideo, videoSettings) =>
      Video.connect(newToken, {
        audio: audioSettings
          ? isEmpty(defaultAudio)
            ? true
            : { deviceId: defaultAudio }
          : audioSettings,
        video: videoSettings ? { deviceId: defaultVideo } : videoSettings,
        logLevel: 'info',
        bandwidthProfile: {
          video: {
            mode: 'collaboration',
            maxTracks: 10,
            dominantSpeakerPriority: 'standard',
          },
        },
        dominantSpeaker: true,
        maxAudioBitrate: 16000, //For music remove this line
        preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
        networkQuality: {
          local: 3, // LocalParticipant's Network Quality verbosity [1 - 3]
          remote: 3, // RemoteParticipants' Network Quality verbosity [0 - 3]
        },
      }).then((room) => {
        setRoom(room)
      }),
    []
  )

  const onNextMeetingNotify = (nextMeeting) => {
    setNextMeeting(nextMeeting)
    setVisible(true)
  }

  const onNotificatioForDelayFromCompany = (
    companyName,
    delay,
    contactName
  ) => {
    notification.open({
      message: (
        <h4>
          <strong>Information</strong>
        </h4>
      ),
      description: (
        <h5>
          <strong>{contactName}</strong> from <strong>{companyName}</strong>{' '}
          will join the meeting in <strong>{delay} minutes</strong>
        </h5>
      ),
      icon: (
        <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#008DCA' }} />
      ),
      duration: 10,
      className: 'delay-notify-video-conference',
    })
  }

  const onDelayNotifyOtherParticipants = async (delay) => {
    const notifyDelayFromMeeting = nextMeeting[0]
    setNextMeeting(null)
    setVisible(false)
    await notifyNextMeeting.notifyDelay(
      notifyDelayFromMeeting.meetingId,
      notifyDelayFromMeeting.contactId,
      delay
    )
  }

  const onJoinNextMeeting = () => {
    setleaveCallVisible({ openDialogShow: true, connectNextMeeting: true })
  }

  const connectToNextMeeting = () => {
    const meetingToConnect = nextMeeting[0]
    setNextMeeting(null)
    setVisible(false)
    window.open(
      `/video-conference/${meetingToConnect.meetingTypeName.toLowerCase()}/${
        meetingToConnect.meetingId
      }`,
      '_blank'
    )
  }

  const handleChangeAudioDevices = (selectedAudioInput) => {
    setAudioDeviceId(selectedAudioInput)
  }

  const handleChangeAudioOutputDevices = (deviceId) => {
    setAudioOutputDeviceId(deviceId)
  }

  const handleChangeVideoDevices = (selectedVideoInput) => {
    setVideoDeviceId(selectedVideoInput)
  }

  const endMeeting = () => {
    if (leaveCallVisible.connectNextMeeting) connectToNextMeeting()
    disconnect()
  }

  const disconnect = () => {
    if (room && room.localParticipant.state === 'connected') {
      room.localParticipant.tracks.forEach((trackPublication) => {
        trackPublication.track.stop()
      })
      room.disconnect()
      setRoom(null)
      window.close()

      if (room.participants.size === 0) {
        http.post(`/meetings/${meetingId}/ended`)
      }
    } else {
      window.close()
    }
  }

  return (
    <Loading
      spinning={isLoading}
      style={{ height: '100vh', maxHeight: '100vh' }}
    >
      {nextMeeting && nextMeeting.length === 1 && (
        <Row>
          <Banner
            nextMeeting={nextMeeting[0]}
            isVisible={visible}
            onDelay={onDelayNotifyOtherParticipants}
            onJoinNextMeeting={onJoinNextMeeting}
          />
        </Row>
      )}
      {nextMeeting && nextMeeting.length > 1 && (
        <Row>
          <UpcomingMeetingsBanner
            nextMeetingCount={nextMeeting.length}
            isVisible={visible}
            leaveMeeting={() => {
              window.open('/meeting-lobby', '_blank')
              endMeeting()
            }}
          />
        </Row>
      )}
      <Row className="cc-video-conference-container ">
        {!isEmpty(meetingCompanyName) && !isEmpty(token) && room && (
          <VideoConferenceRoom
            contactsInMeeting={contactsInMeeting}
            room={room}
            meetingCompanyName={meetingCompanyName}
            documents={documents}
            conversationID={conversationID}
            onDisconnectCall={() =>
              setleaveCallVisible({
                openDialogShow: true,
                connectNextMeeting: false,
              })
            }
            defaultMicOn={micOn}
            defaultVideoOn={videoOn}
            videoDeviceId={videoDeviceId}
            audioDeviceId={audioDeviceId}
            audioOutputDeviceId={audioOutputDeviceId}
            meetingType={meetingType}
            refreshContacts={refreshContacts}
            onChangeAudioOutputDevices={handleChangeAudioOutputDevices}
            onChangeAudioDevices={handleChangeAudioDevices}
            onChangeVideoDevices={handleChangeVideoDevices}
          />
        )}
      </Row>
      <Modal
        title="Leave meeting"
        visible={leaveCallVisible.openDialogShow}
        onOk={endMeeting}
        okText="Leave meeting"
        cancelText="Cancel"
        onCancel={() =>
          setleaveCallVisible({
            openDialogShow: false,
            connectNextMeeting: false,
          })
        }
        okButtonProps={{ type: 'danger' }}
      >
        <span style={{ marginBottom: '40px' }}>
          Are you sure you want to leave this meeting?
        </span>
      </Modal>
      <Modal
        title="Warning: No microphone detected"
        visible={noAudioDetected}
        closable={true}
        footer={null}
        onCancel={() => {
          setNoAudioDetected(false)
        }}
      >
        <div
          style={{
            paddingBottom: '40px',
            display: 'block',
          }}
        >
          No microphone device detected!
        </div>
      </Modal>
      <Modal
        title="Error: Device Permission Denied "
        visible={noPermissions}
        closable={false}
        footer={[
          <Button key="submit" type="danger" onClick={endMeeting}>
            Leave meeting
          </Button>,
        ]}
      >
        <span style={{ marginBottom: '40px' }}>
          Please make sure to allow this site access to your video and audio
          devices
        </span>
      </Modal>
      <Modal
        title="Error"
        visible={otherError}
        closable={false}
        footer={[
          <Button key="submit" type="danger" onClick={endMeeting}>
            Leave meeting
          </Button>,
        ]}
      >
        <span style={{ marginBottom: '40px' }}>
          Make sure that the meeting has not already ended.
          <br /> Make sure that you have allowed access from your browser.
          <br /> Make sure that your webcam is plugged in and turned on.
          <br />
          Check the video selection above to use the correct webcam.
          <br />
          Ensure that your webcam is not being used by another application.
          <br />
          Connect your webcam to a different USB port.
        </span>
      </Modal>
    </Loading>
  )
}

VideoConference.propTypes = {}

export default VideoConference
