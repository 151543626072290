import { DetailedAvatar } from '@context365/avatar'
import { Button, IconButton } from '@context365/button'
import { CalendarTodayOutlined, Launch, TodayOutlined } from '@context365/icons'
import { Divider } from 'antd'
import { format, toDate } from 'date-fns-tz'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import * as api from '~/api'
import {
  showCompanyProfileModal,
  showContactProfileModal,
} from '~/store/modals'
import { getInitials } from '~/utils'
import WidgetBlock from './WidgetBlock'

const MeetingListing = ({ meeting }) => {
  const dispatch = useDispatch()
  return (
    <div className="flex flex-col gap-2 items-start">
      <div className="type-body-semibold-md">
        {format(
          toDate(meeting.meetingDateTime, {
            timeZone: 'UTC',
          }),
          'h:mm a z'
        )}
      </div>
      {map(meeting.companies, (company) => (
        <div key={company.companyId} className="flex flex-col gap-2">
          <Button
            variant="link"
            style={{ padding: 0 }}
            onClick={() =>
              dispatch(
                showCompanyProfileModal({ companyId: company.companyId })
              )
            }
          >
            <div className="type-body-regular-md">{company.companyName}</div>
          </Button>
          {map(company.contacts, (contact) => (
            <DetailedAvatar
              key={contact.contactId}
              name={contact.contactName}
              initials={getInitials(contact.contactName)}
              profileImageUrl={contact.imageUrl}
              className="cursor-pointer"
              onClick={() =>
                dispatch(
                  showContactProfileModal({
                    contactId: contact.contactId,
                  })
                )
              }
            />
          ))}
        </div>
      ))}
      <Button
        className="mt-2"
        as={Link}
        to={`/meeting/online/${meeting.meetingId}`}
      >
        Go to Meeting Details
      </Button>
      <Divider className="mt-4 color-grey-300" />
    </div>
  )
}

const UpcomingMeetings = () => {
  const { data: meetings, isLoading } = useQuery(['upcomingMeetings'], () =>
    api.meeting.getUpcomingMeetings()
  )

  const meetingsByDay = groupBy(meetings, (m) =>
    format(toDate(m.meetingDateTime, { timeZone: 'UTC' }), 'MMM do, y')
  )

  return (
    <WidgetBlock
      headerLeft={
        <div className="flex flex-row align-middle gap-2">
          <TodayOutlined />
          Upcoming Meetings
        </div>
      }
      headerRight={
        <IconButton
          variant="link"
          as={Link}
          to="/meetings"
          label="View Calendar"
          icon={<Launch />}
          style={{ padding: 0 }}
          status="secondary"
        />
      }
    >
      {isLoading ? (
        'Loading...'
      ) : (
        <>
          {isEmpty(meetings) && (
            <div className="flex flex-col gap-2 items-start">
              No upcoming meetings.
              <Button as={Link} to="/meetings">
                View Calendar
              </Button>
            </div>
          )}
          {!isEmpty(meetingsByDay) &&
            map(meetingsByDay, (dayMeetings, day) => (
              <>
                <div
                  key={day}
                  className="type-subtitle-md mb-4 underline flex align-middle"
                >
                  <CalendarTodayOutlined className="mr-2" />
                  {day}
                </div>
                {map(dayMeetings, (meeting) => (
                  <MeetingListing key={meeting.meetingId} meeting={meeting} />
                ))}
              </>
            ))}
        </>
      )}
    </WidgetBlock>
  )
}

export default UpcomingMeetings
