import last from 'lodash/last'
import { useQuery } from 'react-query'
import * as api from '~/api'

export function useFundPerformanceQuery(queryKey, queryFn, options = {}) {
  const loginQuery = useQuery('fundPerformanceLogin', () =>
    api.fundCharts.logInIfNeeded()
  )

  const query = useQuery(queryKey, queryFn, {
    ...options,
    enabled: loginQuery.isSuccess && (options.enabled ?? true),
  })

  const status = mergeQueryStatuses(loginQuery, query)
  return {
    ...query,
    status,
    isLoading: status === 'loading',
    isError: status === 'error',
    isSuccess: status === 'success',
    isIdle: status === 'idle',
  }
}

function mergeQueryStatuses(...queries) {
  const statuses = queries.map((q) => q.status)
  if (statuses.includes('loading')) {
    return 'loading'
  }
  if (statuses.includes('error')) {
    return 'error'
  }
  return last(statuses)
}
