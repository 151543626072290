import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { EventBusy } from '@context365/icons'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, DatePicker, Radio, Tooltip, message } from 'antd'
import cx from 'classnames'
import concat from 'lodash/concat'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import SimpleBar from 'simplebar-react'
import * as api from '~/api'
import Loading from '../Loading'
import NoData from '../NoData/NoData'
import Timezone from '../SelectTimezoneComponent/SelectTimezoneComponent'
import './SelectOnlineMeetingDateTime.less'

const RadioGroup = Radio.Group
const RadioButton = Radio.Button

const SelectOnlineMeetingDateTime = ({
  onTimeChanged,
  onDurationChanged,
  participants,
  toParticipants,
  dateTime,
  meetingDuration,
  eventId = null,
  roadshowId = 0,
  defaultTimezone,
  physicalSummit = false,
  meetingId = null,
}) => {
  const [duration, setDuration] = useState(meetingDuration || 30)
  const [date, setDate] = useState(dateTime ? moment.utc(dateTime).local() : '')
  const [loadingSlots, setLoadingSlots] = useState('')
  const [availableDates, setAvailableDates] = useState([])
  const [eventTimeFrame, setEventTimeFrame] = useState(null)
  const [selectedHour, setSelectedHour] = useState(null)
  const [selectedTimezone, setSelectedTimezone] = useState(
    physicalSummit && !isNil(defaultTimezone)
      ? defaultTimezone
      : moment.tz.guess()
  )
  const userFullname = useSelector((state) => state.auth.contact.contactName)

  useEffect(() => {
    if (!isNil(eventId)) {
      api.events.getSummitDates(eventId).then((eventTimeFrameRaw) => {
        const eventTimeFrameResponse = eventTimeFrameRaw.data.result
        setEventTimeFrame(eventTimeFrameResponse)
      })
    }
    // else if (!isNil(roadshowId) && roadshowId > 0) {
    //   http
    //     .get(`/roadshows/${roadshowId}/timeframe`)
    //     .then((eventTimeFrameRaw) => {
    //       setEventTimeFrame(eventTimeFrameRaw.data.result)
    //     })
    // }
  }, [eventId, roadshowId])

  useEffect(() => {
    if (date === '' || date === null) {
      return
    }
    setLoadingSlots(true)

    const params = {
      date: date.format('YYYY-MM-DD'),
      duration: duration === 30 ? 0 : 1,
      contactIDs: concat(participants, toParticipants),
      timeZone: selectedTimezone,
      meetingId,
    }

    if (!isNil(eventId)) {
      params.eventId = eventId
    }

    api.meeting
      .getAvailableTimeslots(params)
      .then((response) => {
        const convertedDates = response.data.result.map((dt) => ({
          displayStartTime: moment
            .tz(dt.startTime, selectedTimezone)
            .format('Y-MM-DD HH:mm:ss'),
          displayEndTime: moment
            .tz(dt.endTime, selectedTimezone)
            .format('Y-MM-DD HH:mm:ss'),
          startTime: dt.startTime,
          endTime: dt.endTime,
          busyContactIds: dt.busyContactIds,
          busyContacts: dt.busyContacts,
        }))
        setAvailableDates(convertedDates)

        if (dateTime) {
          const selectedTime = convertedDates.find(
            (x) =>
              x.displayStartTime ===
              moment
                .tz(moment.utc(dateTime), selectedTimezone)
                .format('Y-MM-DD HH:mm:ss')
          )
          setSelectedHour(selectedTime)
        }
      })
      .catch(() => {
        message.error('Error occurred')
      })
      .finally(() => {
        setLoadingSlots(false)
      })
  }, [
    date,
    dateTime,
    duration,
    eventId,
    meetingId,
    participants,
    selectedTimezone,
    toParticipants,
  ])

  const getTooltipText = useCallback(
    (info) => {
      if (isEmpty(info.busyContacts)) {
        return ''
      } else {
        const busyNumber = info.busyContacts.length - 1
        const isYou =
          `${info.busyContacts[0].firstName} ${info.busyContacts[0].lastName}` ===
          userFullname

        return (
          (isYou &&
            `You are already booked on this time slot ${
              busyNumber > 0
                ? `+ ${busyNumber} other${busyNumber > 1 ? 's' : ''}`
                : ''
            }`) ||
          `${info.busyContacts[0].firstName} ${
            info.busyContacts[0].lastName
          } is already booked on this time slot ${
            busyNumber > 0
              ? `+ ${busyNumber} other${busyNumber > 1 ? 's' : ''}`
              : ''
          }`
        )
      }
    },
    [userFullname]
  )

  const handleTimeSlotChange = useCallback(
    (val) => {
      setSelectedHour(val.target.value)
      onTimeChanged('hour', val.target.value)
    },
    [onTimeChanged]
  )
  const getSelect = useCallback(
    () => (
      <SimpleBar
        style={{
          maxHeight: '345px',
          padding: '16px 0px 4px 0px',
        }}
      >
        {availableDates && (
          <RadioGroup
            onChange={handleTimeSlotChange}
            className="TimeSlots"
            size="large"
            value={selectedHour}
          >
            {availableDates.map((dt) => (
              <div
                key={dt.startTime}
                className="cc-online-meeting-hours-buttons"
              >
                <RadioButton value={dt}>
                  {
                    <div>
                      <Badge
                        count={
                          isEmpty(dt.busyContactIds) ? (
                            0
                          ) : (
                            <Tooltip
                              title={getTooltipText(dt)}
                              placement="topRight"
                            >
                              <FontAwesomeIcon
                                color="#F19F00"
                                icon={faExclamationCircle}
                              />
                            </Tooltip>
                          )
                        }
                        className={cx(
                          'cc-online-meeting-hours-badge',
                          dt === selectedHour && 'text-white'
                        )}
                      >
                        {`${moment(dt.displayStartTime).format(
                          'h:mm a'
                        )} - ${moment(dt.displayEndTime).format('h:mm a')}`}
                      </Badge>
                    </div>
                  }
                </RadioButton>
              </div>
            ))}
          </RadioGroup>
        )}
      </SimpleBar>
    ),
    [availableDates, getTooltipText, handleTimeSlotChange, selectedHour]
  )

  const onDateChange = useCallback(
    (value) => {
      setDate(value)
      onTimeChanged('date', moment.utc(value).local())
    },
    [onTimeChanged]
  )

  const handleTimezoneChange = useCallback((e) => {
    setSelectedTimezone(e)
  }, [])

  const onDurationChange = useCallback(
    (e) => {
      setDuration(e.target.value)
      setSelectedHour(null)
      onTimeChanged('hour', null)
      onDurationChanged(e.target.value)
    },
    [onDurationChanged, onTimeChanged]
  )
  return (
    <>
      <div className="online-meeting-date-time-text">Select date</div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%', paddingRight: '10px' }}>
          <div className="online-meeting-date-time-datePicer">
            <DatePicker
              disabledDate={(current) =>
                (current && current < moment().startOf('day')) ||
                (!isNil(eventTimeFrame) &&
                  (current.format('Y-MM-DD') <
                    moment
                      .utc(eventTimeFrame.startTime)
                      .local()
                      .format('Y-MM-DD') ||
                    current.format('Y-MM-DD') >
                      moment
                        .utc(eventTimeFrame.endTime)
                        .local()
                        .format('Y-MM-DD')))
              }
              style={{ width: '100%' }}
              size="large"
              onChange={onDateChange}
              allowClear={false}
              defaultValue={
                dateTime
                  ? moment.utc(dateTime).local()
                  : isNil(eventTimeFrame)
                  ? null
                  : moment.utc(eventTimeFrame.startTime).local()
              }
            />
          </div>
        </div>
        <div className="online-meeting-date-time-timezone">
          <Timezone
            value={selectedTimezone}
            handleTimezoneChange={handleTimezoneChange}
          />
        </div>
      </div>
      {isNil(eventId) && (
        <>
          {' '}
          <div
            className="online-meeting-date-time-text"
            style={{ marginTop: '30px' }}
          >
            Duration
          </div>
          <div className="online-meeting-date-time-radio-group">
            <Radio.Group
              onChange={onDurationChange}
              value={duration}
              buttonStyle="solid"
              size="large"
            >
              <Radio.Button
                className="online-meeting-date-time-radio-button-wrapper"
                value={30}
              >
                30 minutes
              </Radio.Button>
              <Radio.Button
                className="online-meeting-date-time-radio-button-wrapper"
                value={60}
              >
                1 hour
              </Radio.Button>
            </Radio.Group>
          </div>
        </>
      )}

      <div style={{ marginTop: '20px', lineHeigth: '1.5' }}>
        {availableDates.length > 0 ? (
          <>
            <div
              style={{ marginTop: '30px' }}
              className="online-meeting-date-time-text"
            >
              Time Slot
            </div>
            <Loading spinning={loadingSlots}>{getSelect()}</Loading>
          </>
        ) : (
          !isEmpty(date) &&
          !loadingSlots && (
            <NoData
              icon={<EventBusy size={56} />}
              title="No free slots available."
            />
          )
        )}
      </div>
    </>
  )
}

SelectOnlineMeetingDateTime.propTypes = {
  onTimeChanged: PropTypes.func.isRequired,
  onDurationChanged: PropTypes.func.isRequired,
  participants: PropTypes.array.isRequired,
  toParticipants: PropTypes.array.isRequired,
  dateTime: PropTypes.string,
  meetingDuration: PropTypes.number,
  eventId: PropTypes.number,
  roadshowId: PropTypes.number,
  defaultTimezone: PropTypes.string,
  physicalSummit: PropTypes.bool,
  meetingId: PropTypes.number,
}

export default SelectOnlineMeetingDateTime
