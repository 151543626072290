import { useState } from 'react'
import PropTypes from 'prop-types'
import { Radio, RadioGroup } from '@context365/forms'
import { Modal, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useDispatch } from 'react-redux'
import { changeCompany } from '~/actions/auth'

const SelectCompany = ({ companyId, setCompanyId, companyList }) => (
  <>
    <p>
      In order to view the summit you have to switch to a company that is
      attending the summit.
    </p>
    <p>Choose a company you want to switch to:</p>
    <RadioGroup
      name="companyId"
      value={companyId?.toString()}
      className="flex flex-col gap-3"
      onChange={(e) => {
        setCompanyId(e.target.value)
      }}
    >
      {map(companyList, (c) => (
        <Radio key={c.companyId} value={c.companyId.toString()}>
          {c.companyName}
        </Radio>
      ))}
    </RadioGroup>
  </>
)

const SwitchCompanyModal = ({
  isModalVisible,
  setIsModalVisible,
  currentCompanyName,
  toCompanyId,
  toCompanyText = '',
  companyList = [],
  onSwitchCompany = () => {},
}) => {
  const dispatch = useDispatch()
  const [companyId, setCompanyId] = useState(toCompanyId)

  const ModalBody =
    isEmpty(companyList) || companyList.length === 1 ? (
      <>
        {toCompanyText}
        <p>Do you want to switch companies?</p>
      </>
    ) : (
      <SelectCompany
        companyId={companyId}
        setCompanyId={setCompanyId}
        companyList={companyList}
      />
    )
  return (
    <Modal
      title="Switch Companies"
      visible={isModalVisible}
      centered
      okText="Switch companies"
      onOk={() => {
        dispatch(changeCompany(companyId))
          .then(() => {
            setIsModalVisible(false)
            message.success('Company switched successfully.')
            onSwitchCompany()
          })
          .catch(() => {
            message.error('Could not switch company.')
          })
      }}
      onCancel={() => setIsModalVisible(false)}
    >
      <p>
        Currently you are navigating as
        <span className="font-semibold ml-1">{currentCompanyName}</span>.
      </p>
      {ModalBody}
    </Modal>
  )
}
SwitchCompanyModal.propTypes = {
  isModalVisible: PropTypes.bool,
  currentCompanyName: PropTypes.string,
  toCompanyText: PropTypes.string,
  toCompanyId: PropTypes.number,
  setIsModalVisible: PropTypes.func,
  companyList: PropTypes.array,
  onSwitchCompany: PropTypes.func,
}
export default SwitchCompanyModal
