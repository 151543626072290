import cx from 'classnames'
import { formatPercent } from '~/utils/helpers'
import CampaignCard from './CampaignCard'
import { formatCurrencyRange } from './utils'

const MandateCampaign = ({ campaign }) => {
  return (
    <CampaignCard
      campaignId={campaign.campaignId}
      name={campaign.name}
      campaignStatusId={campaign.campaignStatusId}
      newActivityCount={campaign.newActivityCount}
    >
      <div className="grid grid-cols-6 lg:grid-cols-5 gap-4">
        <Stat
          className="col-span-2 lg:col-span-1"
          label="New Applications"
          value={campaign.newApplicationCount}
        />
        <Stat
          className="col-span-2 lg:col-span-1"
          label="Total Applications"
          value={campaign.applicationCount}
        />
        <Stat
          className="col-span-2 lg:col-span-1"
          label="Views"
          value={campaign.profileViews}
        />
        <Stat
          className="col-span-3 lg:col-span-1 lg:border-l-2"
          label="Size"
          value={formatCurrencyRange(
            campaign.mandateMinSize,
            campaign.mandateMaxSize
          )}
        />
        <Stat
          className="col-span-3 lg:col-span-1"
          label="Target Return"
          value={campaign.mandateTargetReturnPercent}
          format={(value) => formatPercent(value / 100)}
        />
      </div>
    </CampaignCard>
  )
}

export default MandateCampaign

const toString = (value) => `${value}`

function Stat({ className, label, value, format = toString }) {
  return (
    <div className={cx('flex flex-col items-center', className)}>
      <div className="type-body-regular-lg">
        {value == null ? '-' : format(value)}
      </div>
      <div className="type-body-regular-xs">{label}</div>
    </div>
  )
}
