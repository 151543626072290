import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faUpload } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Upload } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import join from 'lodash/join'

const { Dragger } = Upload

const UploadFileField = ({
  action,
  method = 'post',
  name,
  label = null,
  formFieldName = 'file',
  value = null,
  accept = null,
  className = null,
  text = null,
  hint = null,
  icon = null,
  onChange,
}) => {
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    if (!isNil(value)) {
      setFileList([
        {
          uid: 'uff-1',
          name: value,
          status: 'done',
          url: value,
        },
      ])
    }
  }, [value])

  const handleChange = useCallback(
    (info) => {
      const { status, response } = info.file
      setFileList([info.file])
      if (status === 'done' && !isNil(response) && isFunction(onChange)) {
        const { url } = response.result
        onChange(url)
      }
    },
    [onChange]
  )

  const classNames = ['UploadFieldField']
  if (!isNil(className)) {
    classNames.push(className)
  }

  if (isNil(icon)) {
    icon = <FontAwesomeIcon icon={faUpload} size="3x" color="#C2C6CC" />
  }

  const dragger = (
    <Dragger
      action={action}
      method={method}
      accept={accept}
      name={formFieldName}
      data={{ name }}
      fileList={fileList}
      onChange={handleChange}
    >
      <p className="ant-upload-drag-icon">{icon}</p>
      {!isNil(text) && !isEmpty(text) && (
        <p className="ant-upload-text">{text}</p>
      )}
      {!isNil(hint) && !isEmpty(hint) && (
        <p className="ant-upload-hint">{hint}</p>
      )}
    </Dragger>
  )

  if (isNil(label)) {
    return dragger
  }

  return (
    <div className={join(classNames, ' ')}>
      <label className="UploadFieldField-label">{label}</label>
      {dragger}
    </div>
  )
}

UploadFileField.propTypes = {
  action: PropTypes.string.isRequired,
  method: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  formFieldName: PropTypes.string,
  value: PropTypes.string,
  accept: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  hint: PropTypes.string,
  icon: PropTypes.node,
  onChange: PropTypes.func,
}

export default UploadFileField
