import { faCalendar, faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Tooltip } from 'antd'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import uniq from 'lodash/uniq'
import moment from 'moment-timezone'
import AvatarBadgeGroup from '~/components/AvatarBadgeGroup'
import { getInitials } from './helpers'

export const enabledDependsOnCredits = (role, otherRole) =>
  (role.toLowerCase() === 'serviceprovider' &&
    otherRole.toLowerCase() !== 'context') ||
  (role.toLowerCase() === 'manager' && otherRole.toLowerCase() === 'allocator')

export const isElevated = (myRole, otherRole) =>
  otherRole === 'Context' ||
  (myRole === 'Allocator' && otherRole !== 'CFN') ||
  (myRole === 'CFN' && otherRole !== 'Allocator' && otherRole !== 'CFN') ||
  (myRole === 'Manager' && otherRole === 'Service Provider') ||
  (myRole === 'Manager' && otherRole === 'ServiceProvider')

export const getNextAction = (
  meeting,
  role,
  otherRole,
  onSendNewRequest,
  onCancelMeeting,
  onDeclineMeeting,
  creditsAvailable
) => {
  if (isNil(meeting))
    return {
      buttonText: 'Send Meeting Request',
      action: onSendNewRequest,
      enabled: enabledDependsOnCredits(role, otherRole)
        ? creditsAvailable > 0
        : true,
    }
  if (meeting.hasMeeting !== true)
    return {
      buttonText: 'Send Meeting Request',
      action: onSendNewRequest,
      enabled: enabledDependsOnCredits(role, otherRole)
        ? creditsAvailable > 0
        : true,
    }

  if (meeting.meetingStatus.toLowerCase() === 'confirmed')
    return {
      buttonText: 'Cancel Meeting',
      action: onCancelMeeting,
      buttonType: 'danger',
      icon: faTimesCircle,
    }
  else if (
    meeting.meetingStatus.toLowerCase() === 'pending' &&
    meeting.isMeetingSender
  )
    return {
      buttonText: 'Cancel Meeting',
      action: onCancelMeeting,
      buttonType: 'danger',
      icon: faTimesCircle,
    }
  else if (
    meeting.meetingStatus.toLowerCase() === 'pending' &&
    !meeting.isMeetingSender
  )
    return {
      buttonText: 'Decline Meeting',
      action: onDeclineMeeting,
      buttonType: 'danger',
      icon: faTimesCircle,
    }
  else if (
    meeting.meetingStatus.toLowerCase() === 'declined' &&
    !meeting.isMeetingSender
  )
    return {
      buttonText: 'Send Meeting Request',
      action: onSendNewRequest,
      enabled: true,
    }
  else if (
    meeting.meetingStatus.toLowerCase() === 'declined' &&
    meeting.isMeetingSender
  ) {
    if (
      role.toLowerCase() === 'allocator' ||
      role.toLowerCase() === 'context' ||
      (role.toLowerCase() === 'manager' &&
        otherRole.toLowerCase() === 'serviceprovider')
    ) {
      return {
        buttonText: 'Send Meeting Request',
        action: onSendNewRequest,
        enabled: true,
      }
    } else {
      return null
    }
  } else if (meeting.meetingStatus.toLowerCase() === 'cancelled')
    return {
      buttonText: 'Send Meeting Request',
      action: onSendNewRequest,
      enabled: true,
    }
}

export const getNextActionButton = (
  meeting,
  role,
  otherRole,
  onSendNewRequest,
  onCancelMeeting,
  onDeclineMeeting,
  creditsAvailable
) => {
  const action = getNextAction(
    meeting,
    role,
    otherRole,
    onSendNewRequest,
    onCancelMeeting,
    onDeclineMeeting,
    creditsAvailable
  )

  if (isNil(action)) return null

  if (isNil(action.icon)) action.icon = faCalendar

  if (isNil(action.buttonType)) action.buttonType = 'primary'

  return (
    <Tooltip
      title={
        action.enabled === false && enabledDependsOnCredits(role, otherRole)
          ? 'Not enough available credits to send a meeting request'
          : ''
      }
    >
      <Button
        type={action.buttonType}
        onClick={action.action}
        disabled={action.enabled === false}
      >
        <FontAwesomeIcon icon={action.icon} />
        {action.buttonText}
      </Button>
    </Tooltip>
  )
}

export const renderParticipantsAvatars = (record) => (
  <div className="flex">
    <AvatarBadgeGroup
      organizerId={record?.member?.contactId}
      avatars={record?.colleagues?.map((c) => ({
        id: c.contactId,
        img: c.imageUrl,
        description: c.contactName,
        initials: getInitials(c.contactName),
        firstName: c.firstName,
        lastName: c.lastName,
        participantStatusId: c.participantStatusId,
      }))}
    />
    <Divider type="vertical" style={{ color: '#C2C6CC', height: '34px' }} />
    <AvatarBadgeGroup
      organizerId={record?.member?.contactId}
      avatars={record?.participants?.map((c) => ({
        id: c.contactId,
        img: c.imageUrl,
        description: c.contactName,
        initials: getInitials(c.contactName),
        firstName: c.firstName,
        lastName: c.lastName,
        participantStatusId: c.participantStatusId,
      }))}
    />
  </div>
)

export const renderCfnParticipantsAvatars = (record) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {record.companies.map((company, key) => (
        <div
          key={key}
          style={{
            marginBottom: key < record.companies.length - 1 ? '15px' : '0px',
          }}
        >
          <AvatarBadgeGroup
            organizerId={record?.member?.contactId}
            avatars={company.participants.map((c) => ({
              id: c.contactId,
              img: c.imageUrl,
              description: c.contactName,
              initials: getInitials(c.contactName),
              firstName: c.firstName,
              lastName: c.lastName,
              participantStatusId: c.participantStatusId,
            }))}
          />
        </div>
      ))}
    </div>

    <Divider type="vertical" style={{ color: '#C2C6CC', height: '96px' }} />
    <AvatarBadgeGroup
      organizerId={record?.member?.contactId}
      avatars={record?.colleagues?.map((c) => ({
        id: c.contactId,
        img: c.imageUrl,
        description: c.contactName,
        initials: getInitials(c.contactName),
        firstName: c.firstName,
        lastName: c.lastName,
        participantStatusId: c.participantStatusId,
      }))}
    />
  </div>
)

export const getRescheduleButton = (meeting, onRescheduleMeeting) => {
  if (isNil(meeting)) return null

  if (meeting.hasMeeting !== true) return null

  if (meeting.meetingStatus.toLowerCase() === 'confirmed')
    return (
      <Button type="primary" onClick={onRescheduleMeeting}>
        <FontAwesomeIcon icon={faCalendar} />
        Reschedule Meeting
      </Button>
    )

  return null
}

export const getAcceptMeetingButton = (meeting, onAcceptMeeting) => {
  if (isNil(meeting)) return null

  if (meeting.hasMeeting !== true) return null

  if (
    meeting.meetingStatus.toLowerCase() === 'pending' &&
    !meeting.isMeetingSender
  ) {
    return (
      <Button
        style={{ background: '#24c477', color: 'white' }}
        onClick={onAcceptMeeting}
      >
        Accept Meeting
      </Button>
    )
  }

  return null
}

export const canLeaveMeeting = (
  colleagues,
  participants,
  meetingType,
  virtualSummit
) => {
  if (meetingType === 'Summit' && !virtualSummit) {
    return false
  }
  let leave = false

  if (!isEmpty(colleagues) && !isEmpty(participants)) {
    const pendingParticipants = filter(
      colleagues,
      (x) => x.participantStatusId === 2 || x.participantStatusId === 1
    )
    const companiesPending = uniq(
      map(
        filter(
          participants,
          (x) => x.participantStatusId === 2 || x.participantStatusId === 1
        ),
        (m) => m.companyId
      )
    )

    if (
      (!isEmpty(pendingParticipants) && pendingParticipants.length > 1) ||
      (!isEmpty(companiesPending) && companiesPending.length > 1)
    ) {
      leave = true
    }
  }

  return leave
}

export const getCancelButton = (
  colleagues,
  participants,
  isInitiator,
  onClickLeave,
  onClickCancel,
  meetingType,
  virtualSummit
) => {
  let showLeaveButton
  let showCancelButton
  if (meetingType === 'Summit' && !virtualSummit) {
    showLeaveButton = false
    showCancelButton = true
  } else {
    showLeaveButton = canLeaveMeeting(colleagues, participants)

    showCancelButton = !showLeaveButton || isInitiator
  }
  return (
    <>
      {showLeaveButton && (
        <span className="cc-btn cc-btn-danger">
          <Button onClick={onClickLeave} className="cc-btn cc-btn-danger">
            <i className="cc-btn-bg" />
            Leave Meeting
          </Button>
        </span>
      )}
      {showCancelButton && (
        <span className="cc-btn cc-btn-danger">
          <Button onClick={onClickCancel} className="cc-btn cc-btn-danger">
            <i className="cc-btn-bg" />
            Cancel
          </Button>
        </span>
      )}
    </>
  )
}

export const getFormatedMeetingTime = (
  timezone,
  isVirtualSummit,
  meetingDateTime,
  format
) => {
  const meetingTimezone =
    isNil(timezone) || isVirtualSummit ? moment.tz.guess() : timezone
  const convertedTime = moment.utc(meetingDateTime).tz(meetingTimezone)

  return `${convertedTime.format(format)} (GMT${convertedTime.format(
    'Z'
  )}) ${meetingTimezone}`.replace('_', ' ')
}

export const getConvertedMeetingTime = (
  timezone,
  isVirtualSummit,
  meetingDateTime
) => {
  const meetingTimezone =
    isNil(timezone) || isVirtualSummit ? moment.tz.guess() : timezone
  return moment.utc(meetingDateTime).tz(meetingTimezone)
}

export const getFormatedTimezome = (
  timezone,
  isVirtualSummit,
  meetingDateTime
) => {
  const meetingTimezone =
    isNil(timezone) || isVirtualSummit ? moment.tz.guess() : timezone
  const convertedTime = moment.utc(meetingDateTime).tz(meetingTimezone)
  return `(GMT${convertedTime.format('Z')}) ${meetingTimezone}`.replace(
    '_',
    ' '
  )
}
