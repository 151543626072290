import { Input, Radio, RadioGroup } from '@context365/forms'
import { CAMPAIGN_ENTITY_TYPES } from '~/constants/campaignEntityTypes'

const CreateCampaignForm = ({
  campaignId,
  campaignName,
  setCampaignName,
  selectedNotification,
  setSelectedNotification,
  campaignEntityTypeId,
}) => (
  <div>
    {(!campaignId || campaignEntityTypeId !== CAMPAIGN_ENTITY_TYPES.FUND) && (
      <div>
        <Input
          label="Pipeline Name"
          required
          helpMessage="This is your internal pipeline name."
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
        />
      </div>
    )}
    <div className="flex flex-col gap-2">
      <div className="type-body-semibold-md mt-4">Notifications</div>
      <div className="type-body-regular-sm">
        Do you want to be notified via email as soon as a new lead indicates
        interest?
      </div>
      <RadioGroup
        className="flex flex-col gap-2 mt-2"
        value={selectedNotification}
        onChange={(e) => setSelectedNotification(e.target.value)}
        name="notifications"
        size="small"
      >
        <Radio value="1">Yes</Radio>
        <Radio value="4">No</Radio>
      </RadioGroup>
    </div>
  </div>
)

export default CreateCampaignForm
