import PropTypes from 'prop-types'
import { Select } from '@context365/forms'
import { useController } from 'react-hook-form'
import { useFieldSchema } from '../Form'
import { getLabel, getValue, optionShape } from './option-utils'

export function SelectField({ name, label, helpMessage, options, ...props }) {
  const {
    field,
    fieldState: { error },
  } = useController({ name })
  const rules = useFieldSchema(name, label)

  return (
    <Select
      label={label}
      {...props}
      {...field}
      required={rules.required}
      helpMessage={helpMessage}
      errorMessage={error?.message}
    >
      {options.map((option) => (
        <Select.Option key={getValue(option)} value={getValue(option)}>
          {getLabel(option)}
        </Select.Option>
      ))}
    </Select>
  )
}

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helpMessage: PropTypes.string,
  options: PropTypes.arrayOf(optionShape).isRequired,
}
