import {
  getCFNCompanyQuestions,
  getCFNCompanyVisibilityQuestions,
  getCFNContactQuestions,
  getCFNContactVisibilityQuestions,
  getCompanyAllocatorQuestions,
  getCompanyManagerQuestions,
  getCompanyQuestions,
  getCompanyServiceProviderQuestions,
  getContactIdentityQuestions,
  getContactLocationQuestions,
  postCompany,
  postCompanyAllocatorQuestions,
  postCompanyManagerQuestions,
  postCompanyServiceProviderQuestions,
} from '~/actions/onboarding'
import CFNVisibilityEditor from '~/components/CFNVisibilityEditor'
import CompanySelection from '~/components/OnboardingComponents/CompanySelection'
import FamilyNetworkDealsForm from '~/components/OnboardingComponents/FamilyNetworkDealsForm'
import GenericForm from '~/components/OnboardingComponents/GenericForm'
import InvestorDetailsForm from '~/components/OnboardingComponents/InvestorDetailsForm'
import InvestorStatusForm from '~/components/OnboardingComponents/InvestorStatusForm'
import LayoutConfirm from '~/components/OnboardingComponents/LayoutConfirm'
import LayoutDefault from '~/components/OnboardingComponents/LayoutDefault'
import LayoutWizard from '~/components/OnboardingComponents/LayoutWizard'
import MatchmakingSettings from '~/views/settings/MatchmakingSettings'

const sharedSteps = {
  companySelection: {
    stepNumber: 1,
    component: CompanySelection,
    layoutScreen: LayoutDefault,
    title: 'Select Company',
    url: '/onboard/select-company',
  },
  companyInformation: {
    stepNumber: 2,
    renderAction: getCompanyQuestions,
    action: postCompany, //save company draft
    component: GenericForm,
    layoutScreen: LayoutDefault,
    title: 'Company Information',
    url: '/onboard/company-information',
  },
  confirmCompanyInformation: {
    stepNumber: 3,
    layoutScreen: LayoutConfirm,
    actionType: 1,
    confirmStep: [2],
    title: 'Confirm Company Information',
    url: '/onboard/company-confirmation',
  },
}

export const roleFlows = {
  roles: [
    {
      role: 'Manager',
      flow: [
        sharedSteps.companySelection,
        {
          stepNumber: 2,
          renderAction: getCompanyManagerQuestions,
          table: 'CompanyManagerQuestion',
          withCompany: true,
          component: GenericForm,
          layoutScreen: LayoutDefault,
          title: 'Company Information',
          url: '/onboard/company-details',
        },
        {
          ...sharedSteps.confirmCompanyInformation,
          action: postCompanyManagerQuestions,
        },
        {
          stepNumber: 4,
          steps: ['Identification', 'Contact/Location'],
          innerStep: 1,
          innerStepTitle: 'Identification',
          layoutScreen: LayoutWizard,
          title: 'Enter your personal Information',
          component: GenericForm,
          renderAction: getContactIdentityQuestions,
          action: null,
          url: '/onboard/contact-identification',
          canGoBack: false,
          actionType: 2,
        },
        {
          stepNumber: 5,
          steps: ['Identification', 'Contact/Location'],
          innerStep: 2,
          innerStepTitle: 'Contact and Location',
          layoutScreen: LayoutWizard,
          title: 'Enter your contact and location information',
          component: GenericForm,
          renderAction: getContactLocationQuestions,
          action: null,
          shouldPost: true,
          actionType: 3,
          url: '/onboard/contact-location',
        },
      ],
    },
    {
      role: 'Allocator',
      flow: [
        sharedSteps.companySelection,
        {
          stepNumber: 2,
          renderAction: getCompanyAllocatorQuestions,
          table: 'CompanyAllocatorQuestion',
          withCompany: true,
          action: null, //save allocator company details draft,
          component: GenericForm,
          layoutScreen: LayoutDefault,
          title: 'Company Information',
          url: '/onboard/company-details',
        },
        {
          ...sharedSteps.confirmCompanyInformation,
          action: postCompanyAllocatorQuestions,
        },
        {
          stepNumber: 4,
          steps: [
            'Identification',
            'Contact/Location',
            'Investor Status',
            'Investor Details',
            'Matchmaking Settings',
          ],
          innerStep: 1,
          innerStepTitle: 'Identification',
          layoutScreen: LayoutWizard,
          title: 'Enter your personal Information',
          component: GenericForm,
          renderAction: getContactIdentityQuestions,
          action: null,
          url: '/onboard/contact-identification',
          actionType: 2,
          canGoBack: false,
        },
        {
          stepNumber: 5,
          steps: [
            'Identification',
            'Contact/Location',
            'Investor Status',
            'Investor Details',
            'Matchmaking Settings',
          ],
          innerStep: 2,
          innerStepTitle: 'Contact and Location',
          layoutScreen: LayoutWizard,
          title: 'Enter your contact and location information',
          component: GenericForm,
          renderAction: getContactLocationQuestions,
          action: null,
          actionType: 3,
          url: '/onboard/contact-location',
        },
        {
          stepNumber: 6,
          steps: [
            'Identification',
            'Contact/Location',
            'Investor Status',
            'Investor Details',
            'Matchmaking Settings',
          ],
          innerStep: 3,
          innerStepTitle: 'Investor Status',
          layoutScreen: LayoutWizard,
          title: 'Investor Status',
          component: InvestorStatusForm,
          action: null,
          actionType: 4,
          url: '/onboard/investor-status',
        },
        {
          stepNumber: 7,
          steps: [
            'Identification',
            'Contact/Location',
            'Investor Status',
            'Investor Details',
            'Matchmaking Settings',
          ],
          innerStep: 4,
          innerStepTitle: 'InvestorDetails',
          layoutScreen: LayoutWizard,
          title: 'Investor Preferences Questionnaire',
          component: InvestorDetailsForm,
          action: null,
          actionType: 5,
          url: '/onboard/investor-details',
          customWidth: '556px',
        },
        {
          stepNumber: 8,
          steps: [
            'Identification',
            'Contact/Location',
            'Investor Status',
            'Investor Details',
            'Matchmaking Settings',
          ],
          innerStep: 5,
          innerStepTitle: 'MatchmakingSettings',
          layoutScreen: LayoutWizard,
          component: MatchmakingSettings,
          action: null,
          shouldPost: true,
          actionType: 6,
          url: '/onboard/matchmaking-settings',
          customWidth: '1000px',
        },
      ],
    },
    {
      role: 'ServiceProvider',
      flow: [
        sharedSteps.companySelection,
        {
          stepNumber: 2,
          renderAction: getCompanyServiceProviderQuestions,
          table: 'CompanyServiceProviderQuestion',
          withCompany: true,
          action: null, //save allocator company details draft,
          component: GenericForm,
          layoutScreen: LayoutDefault,
          title: 'Company Information',
          url: '/onboard/company-details',
        },
        {
          ...sharedSteps.confirmCompanyInformation,
          action: postCompanyServiceProviderQuestions,
        },
        {
          stepNumber: 4,
          steps: ['Identification', 'Contact/Location'],
          innerStep: 1,
          innerStepTitle: 'Identification',
          layoutScreen: LayoutWizard,
          title: 'Enter your personal Information',
          component: GenericForm,
          renderAction: getContactIdentityQuestions,
          action: null,
          actionType: 2,
          url: '/onboard/contact-identification',
          canGoBack: false,
        },
        {
          stepNumber: 5,
          steps: ['Identification', 'Contact/Location'],
          innerStep: 2,
          innerStepTitle: 'Contact and Location',
          layoutScreen: LayoutWizard,
          title: 'Enter your contact and location information',
          component: GenericForm,
          renderAction: getContactLocationQuestions,
          action: null,
          shouldPost: true,
          actionType: 3,
          url: '/onboard/contact-location',
        },
      ],
    },
    {
      role: 'CFN',
      flow: [
        sharedSteps.companySelection,
        {
          stepNumber: 2,
          steps: [
            'Company',
            'Family',
            'Investments',
            'Address',
            'Leadership',
            'Visibility',
          ],
          innerStep: 1,
          innerStepTitle: 'Company Information',
          layoutScreen: LayoutWizard,
          title: 'Company Information',
          showAlert: true,
          component: GenericForm,
          renderAction: getCFNCompanyQuestions,
          customRender: true,
          customRenderParameter: 1,
          action: null,
          actionType: 7,
          url: '/onboard/cfn-company',
          customWidth: '556px',
        },
        {
          stepNumber: 3,
          steps: [
            'Company',
            'Family',
            'Investments',
            'Address',
            'Leadership',
            'Visibility',
          ],
          innerStep: 2,
          innerStepTitle: 'Family Information',
          layoutScreen: LayoutWizard,
          title: 'Family Information',
          component: GenericForm,
          renderAction: getCFNCompanyQuestions,
          customRender: true,
          customRenderParameter: 2,
          action: null,
          actionType: 7,
          url: '/onboard/cfn-family',
          customWidth: '556px',
        },
        {
          stepNumber: 4,
          steps: [
            'Company',
            'Family',
            'Investments',
            'Address',
            'Leadership',
            'Visibility',
          ],
          innerStep: 3,
          innerStepTitle: 'Investments',
          layoutScreen: LayoutWizard,
          title: 'Investments',
          component: GenericForm,
          renderAction: getCFNCompanyQuestions,
          customRender: true,
          customRenderParameter: 3,
          action: null,
          actionType: 7,
          url: '/onboard/cfn-investments',
          customWidth: '556px',
        },
        {
          stepNumber: 5,
          steps: [
            'Company',
            'Family',
            'Investments',
            'Address',
            'Leadership',
            'Visibility',
          ],
          innerStep: 4,
          innerStepTitle: 'Address',
          layoutScreen: LayoutWizard,
          title: 'Address',
          component: GenericForm,
          renderAction: getCFNCompanyQuestions,
          customRender: true,
          customRenderParameter: 4,
          action: null,
          actionType: 7,
          url: '/onboard/cfn-address',
          customWidth: '556px',
        },
        {
          stepNumber: 6,
          steps: [
            'Company',
            'Family',
            'Investments',
            'Address',
            'Leadership',
            'Visibility',
          ],
          innerStep: 5,
          innerStepTitle: 'Leadership',
          layoutScreen: LayoutWizard,
          title: 'Leadership',
          component: GenericForm,
          renderAction: getCFNCompanyQuestions,
          customRender: true,
          customRenderParameter: 5,
          action: null,
          actionType: 7,
          url: '/onboard/cfn-other',
          customWidth: '556px',
        },
        {
          stepNumber: 7,
          steps: [
            'Company',
            'Family',
            'Investments',
            'Address',
            'Leadership',
            'Visibility',
          ],
          innerStep: 6,
          innerStepTitle: 'Visibility',
          layoutScreen: LayoutWizard,
          title: 'Visibility',
          component: CFNVisibilityEditor,
          renderAction: getCFNCompanyVisibilityQuestions,
          action: null,
          actionType: 9,
          url: '/onboard/cfn-company-visibility',
          customWidth: '556px',
        },
        {
          stepNumber: 8,
          steps: [
            'Identification',
            'About',
            'Deals',
            'Contact/Location',
            'Investments',
            'Social',
            'Visibility',
          ],
          innerStep: 1,
          innerStepTitle: 'Identification',
          layoutScreen: LayoutWizard,
          title: 'Identification',
          component: GenericForm,
          renderAction: getCFNContactQuestions,
          customRender: true,
          customRenderParameter: 1,
          action: null,
          actionType: 8,
          url: '/onboard/cfn-identification',
          canGoBack: false,
          customWidth: '556px',
        },
        {
          stepNumber: 9,
          steps: [
            'Identification',
            'About',
            'Deals',
            'Contact/Location',
            'Investments',
            'Social',
            'Visibility',
          ],
          innerStep: 2,
          innerStepTitle: 'About',
          layoutScreen: LayoutWizard,
          title: 'About',
          component: GenericForm,
          renderAction: getCFNContactQuestions,
          customRender: true,
          customRenderParameter: 2,
          action: null,
          actionType: 8,
          url: '/onboard/cfn-family-identification',
          customWidth: '556px',
        },
        {
          stepNumber: 10,
          steps: [
            'Identification',
            'About',
            'Deals',
            'Contact/Location',
            'Investments',
            'Social',
            'Visibility',
          ],
          innerStep: 3,
          innerStepTitle: 'Deals',
          layoutScreen: LayoutWizard,
          title: 'Deals',
          component: FamilyNetworkDealsForm,
          renderAction: getCFNContactQuestions,
          customRender: true,
          customRenderParameter: 3,
          action: null,
          actionType: 11,
          url: '/onboard/cfn-contact-investor-status',
          customWidth: '556px',
        },
        {
          stepNumber: 11,
          steps: [
            'Identification',
            'About',
            'Deals',
            'Contact/Location',
            'Investments',
            'Social',
            'Visibility',
          ],
          innerStep: 4,
          innerStepTitle: 'Contact/Location',
          layoutScreen: LayoutWizard,
          title: 'Contact/Location',
          component: GenericForm,
          renderAction: getCFNContactQuestions,
          customRender: true,
          customRenderParameter: 4,
          action: null,
          actionType: 8,
          url: '/onboard/cfn-contact-location',
          customWidth: '556px',
        },
        {
          stepNumber: 12,
          steps: [
            'Identification',
            'About',
            'Deals',
            'Contact/Location',
            'Investments',
            'Social',
            'Visibility',
          ],
          innerStep: 5,
          innerStepTitle: 'Investments',
          layoutScreen: LayoutWizard,
          title: 'Investments',
          component: GenericForm,
          renderAction: getCFNContactQuestions,
          customRender: true,
          customRenderParameter: 5,
          action: null,
          actionType: 8,
          url: '/onboard/cfn-contact-investments',
          customWidth: '556px',
        },
        {
          stepNumber: 13,
          steps: [
            'Identification',
            'About',
            'Deals',
            'Contact/Location',
            'Investments',
            'Social',
            'Visibility',
          ],
          innerStep: 6,
          innerStepTitle: 'Social',
          layoutScreen: LayoutWizard,
          title: 'Social',
          component: GenericForm,
          renderAction: getCFNContactQuestions,
          customRender: true,
          customRenderParameter: 6,
          action: null,
          actionType: 8,
          url: '/onboard/cfn-contact-social',
          customWidth: '556px',
        },
        {
          stepNumber: 14,
          steps: [
            'Identification',
            'About',
            'Deals',
            'Contact/Location',
            'Investments',
            'Social',
            'Visibility',
          ],
          innerStep: 7,
          innerStepTitle: 'Visibility',
          layoutScreen: LayoutWizard,
          title: 'Visibility',
          component: CFNVisibilityEditor,
          renderAction: getCFNContactVisibilityQuestions,
          action: null,
          actionType: 10,
          url: '/onboard/cfn-contact-visibility',
          customWidth: '556px',
        },
      ],
    },
    {
      role: 'ServiceProvider',
      flow: [
        sharedSteps.companySelection,
        {
          stepNumber: 2,
          renderAction: getCompanyServiceProviderQuestions,
          table: 'CompanyServiceProviderQuestion',
          withCompany: true,
          action: null, //save allocator company details draft,
          component: GenericForm,
          layoutScreen: LayoutDefault,
          title: 'Company Information',
          url: '/onboard/company-details',
        },
        {
          ...sharedSteps.confirmCompanyInformation,
          action: postCompanyServiceProviderQuestions,
        },
        {
          stepNumber: 4,
          steps: ['Identification', 'Contact/Location'],
          innerStep: 1,
          innerStepTitle: 'Identification',
          layoutScreen: LayoutWizard,
          title: 'Enter your personal Information',
          component: GenericForm,
          renderAction: getContactIdentityQuestions,
          action: null,
          actionType: 2,
          url: '/onboard/contact-identification',
          canGoBack: false,
        },
        {
          stepNumber: 5,
          steps: ['Identification', 'Contact/Location'],
          innerStep: 2,
          innerStepTitle: 'Contact and Location',
          layoutScreen: LayoutWizard,
          title: 'Enter your contact and location information',
          component: GenericForm,
          renderAction: getContactLocationQuestions,
          action: null,
          shouldPost: true,
          actionType: 3,
          url: '/onboard/contact-location',
        },
      ],
    },
    {
      role: 'Context',
      flow: [sharedSteps.companySelection, sharedSteps.companyInformation],
    },
    {
      role: 'Guest Presentation',
      flow: [
        sharedSteps.companySelection,
        sharedSteps.companyInformation,
        {
          stepNumber: 5,
          steps: ['Identification'],
          innerStep: 1,
          innerStepTitle: 'Identification',
          layoutScreen: LayoutWizard,
          title: 'Enter your personal Information',
          component: GenericForm,
          renderAction: getContactIdentityQuestions,
          action: null,
          url: '/onboard/contact-identification',
          canGoBack: false,
          actionType: 2,
          shouldPost: true,
        },
      ],
    },
    {
      role: 'empty',
      flow: [sharedSteps.companySelection, sharedSteps.companyInformation],
    },
  ],
}
