import validator from 'validator'
import * as Yup from 'yup'
import * as locale from 'yup/lib/locale'
import { pluralize } from '~/utils/helpers'

Yup.setLocale({
  mixed: {
    // eslint-disable-next-line no-template-curly-in-string -- yup locale uses template string syntax
    required: '${path} is required',
    notType: (params) => {
      if (params.type === 'number' && params.originalValue === '') {
        return `${params.path} is required`
      }
      return locale.mixed.notType(params)
    },
  },
  array: {
    min: ({ path, min }) =>
      `${path} must have at least ${pluralize(min, 'item', 'items')}`,
  },
})

Yup.addMethod(
  Yup.string,
  'url',
  // eslint-disable-next-line no-template-curly-in-string -- yup locale uses template string syntax
  function checkUrl(message = '${path} must be a valid URL') {
    // eslint-disable-next-line babel/no-invalid-this -- no idea why babel thinks this is invalid
    return this.test('url', message, (value) => {
      return !value || validator.isURL(value)
    })
  }
)
