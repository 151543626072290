import * as React from 'react'
import { useAxis } from '@context365/charts'
import { format as d3format } from 'd3-format'
import { timeFormat, timeParse } from 'd3-time-format'
import zip from 'lodash/zip'

export const CHART_HEIGHT = 384
export const CHART_WIDTH = 1150

export const parseRebaseDate = timeParse('%Y-%m-%d')
export const formatRebaseDate = timeFormat('%b %Y')

const parseMonthYear = timeParse('%Y-%m')
const formatMonthYear = timeFormat('%b %Y')

export function useTimeAxis(data, chart) {
  const dates = React.useMemo(() => data.dates.map(parseMonthYear), [data])

  return useAxis({
    type: 'time',
    getValue: (d) => parseMonthYear(d[0]),
    formatValue: formatMonthYear,
    range: chart.range.horizontal,
    domain: dates,
  })
}

export function createDataset(dates, values) {
  return zip(dates, values).filter(([_, value]) => value !== undefined)
}

export const format = {
  float: d3format(',.2f'),
  floatShort: d3format(',.2~f'),
  decimal: d3format(',d'),
  percentage: d3format('.2%'),
  percentageShort: d3format('.1~%'),
}
