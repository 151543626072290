import { useState } from 'react'
import { Badge } from '@context365/badge'
import { Button } from '@context365/button'
import { MailOutlined } from '@context365/icons'
import { Modal, Pagination } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { useSelector } from 'react-redux'
import CardButton from '~/components/CardButton'
import { MEETING_STATUS } from '~/constants/meetingStatusIds'
import { MEETING_TYPE } from '~/constants/meetingTypeIds'
import { getFormatedMeetingTime } from '~/utils/meetingActions'
import { OnlineMeetingActions, SummitMeetingActions } from '../MeetingActions'
import MeetingDetailsController from '../MeetingDetails'
import CompanyMeetingAvatars from './CompanyMeetingAvatars'

const MeetingListHeader = ({ isRequests }) => (
  <div className="hidden lg:grid lg:grid-cols-7 p-2">
    <div className="type-subtitle-sm underline col-span-2">Attendees</div>
    <div className="type-subtitle-sm underline">
      {isRequests ? 'Request Date' : 'Date/Time'}
    </div>
    <div className="type-subtitle-sm underline">Location</div>
    <div className="type-subtitle-sm underline">Status</div>
    <div className="type-subtitle-sm underline"> </div>
  </div>
)

function getMeetingStatusColor(meetingStatusId, date) {
  if (meetingStatusId === MEETING_STATUS.PENDING) {
    return 'warning'
  }
  if (meetingStatusId === MEETING_STATUS.CONFIRMED) {
    return !isNil(date) && moment(date).isBefore(moment.utc())
      ? 'default' //make it grey if in the past
      : 'success'
  }
  if (meetingStatusId === MEETING_STATUS.EXPIRED) {
    return 'default'
  }
  return 'error'
}

function formatDateTime(dateTime, meetingTypeId, timeZone, virtualSummit) {
  if (dateTime !== null) {
    return meetingTypeId === MEETING_TYPE.SUMMIT
      ? getFormatedMeetingTime(
          timeZone,
          virtualSummit,
          dateTime,
          'MMMM DD, YYYY hh:mm A'
        )
      : moment.utc(dateTime).local().format('MMMM DD, YYYY hh:mm A')
  }
}

const MESSAGE_MAX_LENGTH = 350

const MeetingListBody = ({ meeting, refreshList, useCreateTime }) => {
  const company = useSelector((state) => state.auth.company)
  const [showMeetingDetails, setShowMeetingDetails] = useState(false)
  const [showMore, setShowMore] = useState(false)

  return (
    <div>
      <CardButton
        key={meeting.meetingId}
        onClick={() => setShowMeetingDetails(true)}
        className="p-4 my-4 sm:mx-4"
      >
        <div className="grid grid-cols-1 lg:grid-cols-7 gap-4 space-y-4 lg:space-y-0 items-center">
          <div className="md:col-span-2 flex flex-col md:flex-row md:space-x-4 space-y-2 md:space-y-0">
            {meeting.companies.map((c) => (
              <div key={c.companyId} style={{ width: '8rem' }}>
                <div className="type-body-semibold-sm">{c.companyName}</div>
                <div className="type-subtitle-xxs">{c.category}</div>
                <div>
                  <CompanyMeetingAvatars
                    meeting={meeting}
                    participants={c.participants}
                  />
                </div>
              </div>
            ))}
            <div style={{ width: '8rem' }}>
              <div className="type-body-semibold-sm">{company.name}</div>
              <div className="type-subtitle-xxs">
                {company.category.categoryName}
              </div>
              <div>
                <CompanyMeetingAvatars
                  meeting={meeting}
                  participants={meeting.colleagues}
                />
              </div>
            </div>
          </div>
          {!useCreateTime && (
            <div>
              {formatDateTime(
                meeting.meetingDateTime,
                meeting.meetingTypeId,
                meeting.meetingTimeZone,
                meeting.virtualSummit
              )}
            </div>
          )}
          {useCreateTime && <div>{formatDateTime(meeting.sentDateTime)}</div>}
          <div>{meeting.meetingType}</div>
          <div>
            <Badge
              status={getMeetingStatusColor(
                meeting.meetingStatusId,
                meeting.meetingDateTime
              )}
            >
              {meeting.meetingStatus}
            </Badge>
          </div>
          <div className="md:col-span-2 flex lg:justify-end">
            {meeting.meetingTypeId === MEETING_TYPE.SUMMIT && (
              <SummitMeetingActions
                meeting={meeting}
                refreshMeeting={refreshList}
              />
            )}
            {meeting.meetingTypeId === MEETING_TYPE.ONLINE && (
              <OnlineMeetingActions
                meeting={meeting}
                refreshMeeting={refreshList}
              />
            )}
          </div>
        </div>
        {!isEmpty(meeting.meetingMessage) && (
          <div className="border-t-2 border-grey-100 m-2 p-2">
            <div className="my-2">
              <MailOutlined size={24} />
            </div>
            <div className="leading-5 lg:w-1/2">
              {showMore
                ? meeting.meetingMessage
                : meeting.meetingMessage.substring(0, MESSAGE_MAX_LENGTH)}
            </div>
            {meeting.meetingMessage?.length > MESSAGE_MAX_LENGTH && (
              <Button
                variant="link"
                onClick={(e) => {
                  e.stopPropagation()
                  setShowMore(!showMore)
                }}
                style={{ paddingLeft: 0 }}
              >
                {showMore ? 'Show less...' : 'Show more...'}
              </Button>
            )}
          </div>
        )}
      </CardButton>
      <Modal
        visible={showMeetingDetails}
        width="80%"
        footer={null}
        onCancel={() => {
          refreshList?.()
          setShowMeetingDetails(false)
        }}
      >
        <MeetingDetailsController
          meetingId={meeting.meetingId}
          meetingType={meeting.meetingType}
        />
      </Modal>
    </div>
  )
}

const MeetingListItem = ({
  meetings,
  refreshList,
  page,
  pageSize,
  setPage,
  setPageSize,
  total,
  isRequests = false,
}) => (
  <>
    <MeetingListHeader isRequests={isRequests} />
    <div>
      {meetings.map((m) => (
        <MeetingListBody
          key={m.meetingId}
          meeting={m}
          refreshList={refreshList}
          useCreateTime={isRequests}
        />
      ))}
      <div className="mt-8">
        <Pagination
          current={page}
          pageSize={pageSize}
          total={total}
          size="small"
          onChange={(page, size) => {
            setPage(page)
            setPageSize(size)
          }}
          showSizeChanger={true}
          defaultPageSize={pageSize}
        />
      </div>
    </div>
  </>
)

export default MeetingListItem
