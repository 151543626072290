import { api } from './services'

export async function getDiscoverLists() {
  const response = await api.get('/discoverLists/links')
  return response.data.result
}

///LinkLinkTargets should be of shape:
/// { toFundIds: [], toCompanyContactIds: [], toCompanyIds: [], toMandateIds: [] }

export const createDiscoverList = (name, listLinkTargets = {}) =>
  api.post('discoverLists/', {
    name,
    ...listLinkTargets,
  })

export const toggleDiscoverListLink = (discoverListId, listLinkTargets) =>
  api.post('/discoverLists/toggle', {
    discoverListId,
    ...listLinkTargets,
  })

export const addDiscoverListLink = (discoverListId, listLinkTargets) =>
  api.post('/discoverLists/tag', {
    discoverListId,
    ...listLinkTargets,
  })

export const deleteDiscoverList = (discoverListId) =>
  api.delete('/discoverLists', {
    data: {
      discoverListId,
    },
  })

export const revokeDiscoverListAccess = (discoverListId, companyContactId) =>
  api.delete('/discoverLists', {
    data: {
      discoverListId,
      targetCompanyContactId: companyContactId,
    },
  })

export const shareDiscoverList = (discoverListId, { companyId, contactId }) =>
  api.post('/discoverLists/share', {
    discoverListId,
    targetCompanyId: companyId,
    targetContactId: contactId,
  })
