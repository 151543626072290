import PropTypes from 'prop-types'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactPlayer from 'react-player'
import { useTracking } from 'react-tracking'
import ContactsCard from './ContactsCard'
import './VideoPlayer.less'

const VideoPlayer = ({ mediaUrl, title, author, taggedContacts, onClose }) => {
  const { trackEvent } = useTracking({ component: 'VideoPlayer' })

  return (
    <div className="content-video-player">
      <div className="content-video-player__container">
        <ReactPlayer
          controls
          style={{ backgroundColor: 'black' }}
          url={mediaUrl}
          onPlay={() => trackEvent({ eventName: 'media-play' })}
          onPause={() => trackEvent({ eventName: 'media-pause' })}
          onEnded={() => trackEvent({ eventName: 'media-end' })}
        />
        <ContactsCard
          title={title}
          author={author}
          taggedContacts={taggedContacts}
          className="content-video-player__contacts-info"
        />
        <FontAwesomeIcon
          className="content-video-player__close"
          role="button"
          aria-label="Close"
          aria-hidden="false"
          icon={faTimes}
          size="2x"
          onClick={onClose}
        />
      </div>
    </div>
  )
}

VideoPlayer.propTypes = {
  mediaUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.object,
  taggedContacts: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default VideoPlayer
