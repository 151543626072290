import isEmpty from 'lodash/isEmpty'
import { getLabel, getValue } from '~/components/Form/Fields/option-utils'

function Static({ className, label, value }) {
  return value ? (
    <div className={className}>
      <div className="type-body-semibold-sm text-grey-600">{label}</div>
      <div className="type-body-regular-md">{value}</div>
    </div>
  ) : null
}

Static.Option = ({ className, label, value, options }) => {
  const selectedOption = options.find((o) => getValue(o) === value)
  return (
    <Static
      className={className}
      label={label}
      value={selectedOption ? getLabel(selectedOption) : null}
    />
  )
}

Static.Multi = ({ className, label, value, options }) => {
  if (isEmpty(value)) {
    return null
  }

  const selectedOptions = value.map((id) =>
    options.find((o) => getValue(o) === id)
  )

  return (
    <Static
      className={className}
      label={label}
      value={selectedOptions.map(getLabel).join(', ')}
    />
  )
}

export default Static
