import * as React from 'react'
import PropTypes from 'prop-types'
import { Empty } from 'antd'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import DiscoverMandatesResult from '../DiscoverMandatesResult'
import Loading from '../Loading'

const MandatePreviewList = ({ getMandates }) => {
  const [mandates, setMandates] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    setLoading(true)
    getMandates()
      .then((data) => setMandates(data.result))
      .finally(() => setLoading(false))
  }, [getMandates])

  return (
    <Loading spinning={loading}>
      {isEmpty(mandates) ? (
        <Empty description="This allocator has not posted any mandates yet." />
      ) : (
        map(mandates, (m) => <DiscoverMandatesResult mandate={m} fromPreview />)
      )}
    </Loading>
  )
}

MandatePreviewList.propTypes = {
  getMandates: PropTypes.func.isRequired,
}

export default MandatePreviewList
