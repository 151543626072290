import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import { api as http } from '~/api/services'
import ParticipantsCheckbox from '../ParticipantsCheckbox'
import './MeetingInviteModal.less'

export const MeetingInviteModal = ({
  meetingId,
  visible = false,
  onCancel = () => {},
  onColleaguesInvited = () => {},
}) => {
  const [availableColleagues, setAvailableColleagues] = useState([])
  const [selectedColleagueIds, setSelectedColleagueIds] = useState([])
  //const [contactsLoading, setContactsLoading] = useState(false);
  const [loadingInvite, setLoadingInvite] = useState(false)

  //First render loads list of available contacts
  useEffect(() => {
    if (availableColleagues.length === 0 && visible) {
      const fetchColleagues = async () => {
        const colleaguesRaw = await http.get(`meetings/${meetingId}/invitees`)
        if (colleaguesRaw.data.result.length > 0)
          setAvailableColleagues(colleaguesRaw.data.result)
      }

      fetchColleagues()
    }
  }, [meetingId, availableColleagues, visible])

  //User clicks cancel or closes modal
  const onCancelled = useCallback(() => {
    setSelectedColleagueIds([])
    setAvailableColleagues([])
    onCancel()
  }, [onCancel])

  //User clicks OK and eventually adds people to conversation
  const onOk = useCallback(() => {
    setLoadingInvite(true)
    const inviteRequest = {
      invitedContactIds: selectedColleagueIds,
    }
    http
      .post(`meetings/${meetingId}/participants`, inviteRequest)
      .then(() => {
        message.success('Meeting invitation sent successfully')
        onColleaguesInvited(
          availableColleagues.filter((x) =>
            selectedColleagueIds.includes(x.contactId)
          )
        )
        onCancelled()
      })
      .catch(() => {
        message.error('There was an error while attempting to add colleagues')
      })
      .finally(() => {
        setLoadingInvite(false)
      })
  }, [
    meetingId,
    selectedColleagueIds,
    availableColleagues,
    onCancelled,
    onColleaguesInvited,
  ])

  //Selected users change (either added or removed)
  const onParticipantChecked = useCallback(
    (e) => {
      if (e.target.checked) {
        setSelectedColleagueIds([...selectedColleagueIds, e.target.value])
      } else {
        setSelectedColleagueIds(
          selectedColleagueIds.filter((p) => p !== e.target.value)
        )
      }
    },
    [selectedColleagueIds]
  )

  return (
    <Modal
      title="Add more people"
      visible={visible}
      okButtonProps={{
        disabled: !(selectedColleagueIds && selectedColleagueIds.length > 0),
        loading: loadingInvite,
      }}
      onOk={onOk}
      onCancel={onCancelled}
    >
      <span className="cc-heading5">
        Colleagues you can invite to this meeting
      </span>

      {availableColleagues && availableColleagues.length > 0 && (
        <div className="meeting-invite-colleagues-to-add">
          {/* list of users taken from the response */}
          {availableColleagues.map((x) => (
            <ParticipantsCheckbox
              key={x.contactId}
              contactId={x.contactId}
              contactName={x.contactName}
              imageUrl={x.imageUrl}
              participants={selectedColleagueIds}
              checked={onParticipantChecked}
            />
          ))}
        </div>
      )}

      {/* in case there are no colleagues, the following message is displayed instead of the interface;
        no colleagues means there's nobody else available to be invited to this meeting */}
      {availableColleagues && availableColleagues.length === 0 && (
        <div className="meeting-invite-no-colleagues">
          There are no new colleagues that can be invited to this meeting
        </div>
      )}
    </Modal>
  )
}

MeetingInviteModal.propTypes = {
  meetingId: PropTypes.number.isRequired,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onColleaguesInvited: PropTypes.func,
}

export default MeetingInviteModal
