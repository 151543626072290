import * as React from 'react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import get from 'lodash/get'
import {
  ENVIRONMENT_NAME,
  SENTRY_DSN,
  SENTRY_RELEASE_ID,
  SENTRY_SAMPLE_RATE,
} from '~/config'

// API failures get reported and re-thrown, so don't report them twice
const hasErrorBeenReported = (error) => get(error, 'reported', false)

// We already handle 401 responses by sending the user to the login page
const isErrorIgnored = (error) => get(error, 'response.status', 0) === 401

export function initialize() {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: (ENVIRONMENT_NAME || 'development').toLowerCase(),
    release: SENTRY_RELEASE_ID,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: SENTRY_SAMPLE_RATE,
    beforeSend(event, hint) {
      const error = get(hint, 'originalException')
      if (hasErrorBeenReported(error) || isErrorIgnored(error)) {
        return null
      }

      return event
    },
    ignoreErrors: [
      // This error seems to occur randomly in the Twilio library, which is being replaced
      /Twilsock: request timeout/,

      // Per this comment https://stackoverflow.com/a/50387233
      //   This error means that ResizeObserver was not able to deliver all
      //   observations within a single animation frame. It is benign (your
      //   site will not break).
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  })
}

export function useErrorReporting(email) {
  React.useLayoutEffect(() => {
    if (email) {
      Sentry.setUser({ email })
    } else {
      Sentry.configureScope((scope) => scope.setUser(null))
    }
  }, [email])
}

export function report(error, { fingerprint, extras = {} } = {}) {
  Sentry.withScope((scope) => {
    if (fingerprint) {
      scope.setFingerprint(fingerprint)
      Object.entries(extras).forEach(([key, extra]) => {
        scope.setExtra(key, extra)
      })
      Sentry.captureException(error)
    }
  })
}
