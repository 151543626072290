import * as React from 'react'
import PropTypes from 'prop-types'
import { useLiveRef, useObjectMemo } from '@context365/hooks'

const noop = () => {}

const defaultValue = {
  selectedRows: [],
  getRowId: undefined,
  onToggleSelected: noop,
  onToggleAllSelected: noop,
}

const SelectRowsContext = React.createContext(defaultValue)

export function SelectRowsProvider({
  children,
  selectedRows,
  getRowId,
  onToggleSelected,
  onToggleAllSelected,
}) {
  // Create stable versions of these callbacks so we don't need to memoize them
  // at the call site
  const getRowIdStable = useStableFunction(getRowId)
  const onToggleSelectedStable = useStableFunction(onToggleSelected)
  const onToggleAllSelectedStable = useStableFunction(onToggleAllSelected)

  const contextValue = useObjectMemo({
    selectedRows,
    getRowId: getRowIdStable,
    onToggleSelected: onToggleSelectedStable,
    onToggleAllSelected: onToggleAllSelectedStable,
  })

  return (
    <SelectRowsContext.Provider value={contextValue}>
      {children}
    </SelectRowsContext.Provider>
  )
}

SelectRowsProvider.propTypes = {
  children: PropTypes.node,
  selectedRows: PropTypes.array.isRequired,
  getRowId: PropTypes.func.isRequired,
  onToggleSelected: PropTypes.func.isRequired,
  onToggleAllSelected: PropTypes.func.isRequired,
}

export function useSelectedRows() {
  return React.useContext(SelectRowsContext)
}

function useStableFunction(fn) {
  const fnRef = useLiveRef(fn)
  return React.useCallback((...args) => fnRef.current?.(...args), [fnRef])
}
