import { useEffect, useState } from 'react'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox, Modal, Row, message } from 'antd'
import { getFundEventsByCompany } from '~/actions/fundList'
import { events as eventsClient } from '~/api'
import { FundWizardModal, useFundWizardModal } from '../FundWizard'
import './TieFundsModal.less'

const TieFundsModal = ({
  eventId,
  isModalVisible,
  initialFunds,
  onClose,
  onRefresh,
}) => {
  const [funds, setFunds] = useState([])
  const fundWizardModal = useFundWizardModal('tieFundsModal')
  const [checkedFunds, setCheckedFunds] = useState(
    initialFunds.map((fund) => fund.fundId)
  )
  const getFundEvents = () => {
    getFundEventsByCompany().then((response) => {
      setFunds(response.data.result)
    })
  }

  useEffect(() => {
    getFundEvents()
  }, [])

  const onSubmit = () => {
    eventsClient
      .addFundsToEvent(eventId, checkedFunds)
      .then((response) => {
        if (response.status === 200) message.success('Funds added successfully')
        else message.error('An error occured while adding the fund(s)')
      })
      .catch(() => {
        message.error('An error occured while adding the fund(s)')
      })
      .finally(() => {
        onClose()
        onRefresh()
      })
  }

  const hasMeetings = (fundId) => {
    return initialFunds.find((fund) => fund.fundId === fundId)?.meetingCount > 0
  }

  return (
    <Modal
      visible={isModalVisible}
      onOk={onSubmit}
      okText="Save"
      onCancel={onClose}
      title="Select Funds"
    >
      <div className="TieFundsModal">
        <div className="TieFundsModal-title" style={{ fontWeight: 'bold' }}>
          Select funds that you want to present at the event
        </div>
        <div className="TieFundsModal-subtitle">
          These funds will be displayed to Allocators who are attending the
          event.
        </div>
        <Checkbox.Group
          className="TieFundsModal-group"
          value={checkedFunds}
          onChange={(e) => setCheckedFunds(e)}
        >
          {funds.map((f, i) => (
            <Row key={i}>
              <Checkbox
                id={f.fundId}
                value={f.fundId}
                disabled={hasMeetings(f.fundId)}
              >
                {f.name}
              </Checkbox>
            </Row>
          ))}
        </Checkbox.Group>

        <Button type="link" onClick={() => fundWizardModal.show()}>
          <FontAwesomeIcon className="button-icon" icon={faPlus} />
          Add New Fund
        </Button>
        <FundWizardModal
          visible={fundWizardModal.visible}
          onWizardClosed={() => {
            fundWizardModal.hide()
          }}
          onSave={() => {
            fundWizardModal.hide()
            getFundEvents()
          }}
        />
      </div>
    </Modal>
  )
}

export default TieFundsModal
