import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import {
  faCheckCircle,
  faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row, Table } from 'antd'
import filter from 'lodash/filter'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEventsIfNeeded } from '~/actions/events'
import { fetchCancellingReasonsIfNeeded } from '~/actions/meetings'
import { boldCompanyNameFormat } from '~/utils/helpers'
import {
  canLeaveMeeting,
  getCancelButton,
  getConvertedMeetingTime,
  getFormatedTimezome,
} from '~/utils/meetingActions'
import EmptyEventTablesState from '~/views/summits/meetings/EmptyEventTablesState'
import AcceptMeetingComponent from '../AcceptMeetingComponent'
import DeclineOnlineMeeting from '../DeclineOnlineMeeting'
import { AcceptSummitMeeting, CancelSummitMeeting } from '../MeetingActions'
import RescheduleEventMeeting from '../RescheduleEventMeeting'
import RescheduleMeeting from '../RescheduleMeeting'
import './UpcomingMeetingRequests.less'

const UpcomingMeetingRequests = ({
  onRefresh,
  eventId = null,
  meetings,
  loadingMeetings = false,
  fromDealProfile = false,
}) => {
  const [
    showRescheduleOnlineMeetingModal,
    setShowRescheduleOnlineMeetingModal,
  ] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState({})
  const [showAcceptOnlineRequestModal, setShowAcceptOnlineRequestModal] =
    useState(false)
  const [cancelForAll, setCancelForAll] = useState(false)
  const [showDeclineOnlineModal, setShowDeclineOnlineModal] = useState(false)
  const [showCancelOnlineModal, setShowCancelOnlineModal] = useState(false)
  const [showAcceptRequestModal, setShowAcceptRequestModal] = useState(false)

  const contactId = useSelector((state) => state.auth.contact.contactId)
  const dispatch = useDispatch()
  const role = useSelector((state) => state.auth.role)

  const handleRescheduleClick = useCallback((record) => {
    setSelectedRecord(record)
    setShowRescheduleOnlineMeetingModal(true)
  }, [])

  const handleLeaveClick = useCallback((record) => {
    setSelectedRecord(record)
    setCancelForAll(false)
    setShowCancelOnlineModal(true)
  }, [])

  const handleCancelClick = useCallback((record) => {
    setSelectedRecord(record)
    setCancelForAll(true)
    setShowCancelOnlineModal(true)
  }, [])

  const handleDeclineClick = useCallback((record) => {
    setSelectedRecord(record)
    setShowDeclineOnlineModal(true)
  }, [])

  const cancelMeetingComponent = useCallback(
    (record) => {
      return getCancelButton(
        record.colleagues,
        record.participants,
        record.isInitiator,
        handleLeaveClick.bind(null, record),
        handleCancelClick.bind(null, record),
        record.meetingType,
        record.virtualSummit
      )
    },
    [handleCancelClick, handleLeaveClick]
  )

  const closeModal = useCallback(() => {
    setShowRescheduleOnlineMeetingModal(false)
  }, [])

  const handleRefresh = useCallback(() => {
    onRefresh()
  }, [onRefresh])

  const renderActions = useCallback(
    (record) => {
      const usersParticipantStatusId = filter(
        record.colleagues,
        (c) => c.contactId === contactId
      )[0]?.participantStatusId

      if (record.isParticipant) {
        if (usersParticipantStatusId === 1) {
          return (
            <div className="UpcomingMeetingRequests-meeting-actions">
              <Button
                type="primary"
                onClick={() => {
                  isNil(eventId)
                    ? setShowAcceptOnlineRequestModal(true)
                    : setShowAcceptRequestModal(true)
                  setSelectedRecord(record)
                }}
                className="cc-btn cc-btn-success"
              >
                <i className="cc-btn-bg" />
                Accept
              </Button>
              <Button
                onClick={handleDeclineClick.bind(null, record)}
                className="cc-btn cc-btn-danger"
              >
                <i className="cc-btn-bg" />
                Decline
              </Button>
            </div>
          )
        } else if (record.meetingStatusId === 1) {
          return (
            <div className="cc-meeting-actions">
              {cancelMeetingComponent(record)}
            </div>
          )
        } else {
          return (
            <div className="UpcomingMeetingRequests-meeting-actions">
              {(!isNil(record.meetingDateTime) || record.isOptimized) && (
                <Button
                  onClick={handleRescheduleClick.bind(null, record)}
                  className="cc-btn cc-btn-notice"
                >
                  <i className="cc-btn-bg" />
                  {'Reschedule'}
                </Button>
              )}
              {cancelMeetingComponent(record)}
            </div>
          )
        }
      } else {
        return (
          <span className="cc-small-font cc-dark-grey-text">
            <FontAwesomeIcon icon={faInfoCircle} /> Your colleagues are part of
            this meeting
          </span>
        )
      }
    },
    [
      cancelMeetingComponent,
      contactId,
      eventId,
      handleDeclineClick,
      handleRescheduleClick,
    ]
  )

  const renderContactInformation = useCallback(
    (record) => {
      const convertedMeetingTime =
        record.meetingType === 'Summit'
          ? getConvertedMeetingTime(
              record.meetingTimeZone,
              record.virtualSummit,
              record.meetingDateTime
            )
          : moment.utc(record.meetingDateTime).local()
      return (
        <Row>
          <Col span={4}>
            {!isNil(record.meetingDateTime) ? (
              <div>
                <div className="UpcomingMeetingRequests-date">
                  {convertedMeetingTime.format('DD')}
                </div>
                <div className="UpcomingMeetingRequests-month">
                  {convertedMeetingTime.format('MMM')}
                </div>
              </div>
            ) : (
              <div className="UpcomingMeetingRequests-tbd">TBD</div>
            )}
            {!isNil(record.dealId) && !fromDealProfile && (
              <div className="DealMeetingLabel-smaller cc-xsmall-font">
                DEAL MEETING
              </div>
            )}
          </Col>
          <Col span={20} className="UpcomingMeetingRequests-info">
            <div>
              {!isNil(record.meetingDateTime) && (
                <div className="UpcomingMeetingRequests-hour">
                  {convertedMeetingTime.format('h:mm A')} -{' '}
                  {convertedMeetingTime
                    .add(record.meetingDuration, 'minutes')
                    .format('h:mm A')}
                  <div>
                    {record.meetingType === 'Summit'
                      ? getFormatedTimezome(
                          record.meetingTimeZone,
                          record.virtualSummit,
                          record.meetingDateTime
                        )
                      : ''}
                  </div>
                </div>
              )}
              <div className="UpcomingMeetingRequests-name">
                {record.companies[0].companyName}
                {record.companies.length > 1 &&
                  ` & ${record.companies[1].companyName}`}
              </div>
              <div className="UpcomingMeetingRequests-participants">
                {record.colleagues.concat(record.participants).map(
                  (participant) =>
                    participant.participantStatusId < 3 && (
                      <>
                        <div
                          className={
                            (participant.participantStatusId === 2 &&
                              'UpcomingMeetingRequests-participants-confirmed') ||
                            (participant.participantStatusId === 1 &&
                              'UpcomingMeetingRequests-participants-pending')
                          }
                          key={participant.contactId}
                        >
                          {participant.participantStatusId === 2 ? (
                            <FontAwesomeIcon
                              color="#24C477"
                              icon={faCheckCircle}
                              className="UpcomingMeetingRequests-participants-icons"
                            />
                          ) : (
                            participant.participantStatusId === 1 && (
                              <FontAwesomeIcon
                                color="#F19F00"
                                className="UpcomingMeetingRequests-participants-icons"
                                icon={faQuestionCircle}
                              />
                            )
                          )}
                          {participant.contactName}
                          {participant.contactId === contactId && ' (You)'}
                        </div>
                        <br />
                      </>
                    )
                )}
              </div>
            </div>
          </Col>
        </Row>
      )
    },
    [contactId, fromDealProfile]
  )

  const getColumns = useCallback(
    (title, past) => {
      const columns = [
        {
          key: 'contact',
          title,
          className: 'cc-body-text',
          width: '66%',
          render: renderContactInformation,
        },
        past
          ? {}
          : {
              render: renderActions,
              align: 'right',
              width: '33%',
              notRenderInCard: true,
            },
      ]
      return columns
    },
    [renderContactInformation, renderActions]
  )

  useEffect(() => {
    dispatch(fetchCancellingReasonsIfNeeded())
  }, [dispatch])

  const RescheduleModal = isNil(eventId) ? (
    <RescheduleMeeting
      visible={showRescheduleOnlineMeetingModal}
      closeModal={closeModal}
      onlineMeetingId={selectedRecord.meetingId}
      onRescheduleMeeting={handleRefresh}
      participants={
        selectedRecord.colleagues
          ? selectedRecord.colleagues.map((x) => x.contactId)
          : []
      }
      toParticipants={
        selectedRecord.participants
          ? selectedRecord.participants.map((x) => x.contactId)
          : []
      }
      meetingDateTime={selectedRecord?.meetingDateTime}
      meetingDuration={selectedRecord?.meetingDuration}
      roadshowId={selectedRecord?.roadshowId}
    />
  ) : (
    <RescheduleEventMeeting
      visible={showRescheduleOnlineMeetingModal}
      closeModal={closeModal}
      meetingId={selectedRecord.meetingId}
      eventId={selectedRecord.eventId}
      onRescheduleMeeting={handleRefresh}
      participants={
        selectedRecord.colleagues
          ? selectedRecord.colleagues.map((x) => x.contactId)
          : []
      }
      toParticipants={
        selectedRecord.participants
          ? selectedRecord.participants.map((x) => x.contactId)
          : []
      }
      meetingDateTime={selectedRecord?.meetingDateTime}
      meetingDuration={selectedRecord?.meetingDuration}
      isVirtualSummit={selectedRecord?.virtualSummit}
      timezone={selectedRecord?.meetingTimeZone}
    />
  )

  const meetingWithString = boldCompanyNameFormat(selectedRecord)

  return (
    <div className="UpcomingMeetingRequests">
      {selectedRecord && (
        <AcceptMeetingComponent
          visible={showAcceptRequestModal}
          closeModal={() => {
            setShowAcceptRequestModal(false)
            setSelectedRecord({})
          }}
          meetingType={selectedRecord.meetingType}
          categoryName={selectedRecord.categoryName}
          company={meetingWithString}
          meetingId={isNil(selectedRecord) ? 0 : selectedRecord.meetingId}
          meetingDateTime={
            isNil(selectedRecord) ? 0 : selectedRecord.meetingDateTime
          }
          eventId={isNil(selectedRecord) ? 0 : selectedRecord.eventId}
          onAccept={() => {
            dispatch({ type: 'Accept' })
            onRefresh()
          }}
          participants={
            isNil(selectedRecord)
              ? 0
              : selectedRecord.participants
              ? selectedRecord.participants.map((x) => x.contactId)
              : []
          }
          toParticipants={[contactId]}
          isVirtualSummit={selectedRecord?.virtualSummit}
          isOptimized={selectedRecord?.isOptimized}
          timezone={selectedRecord?.meetingTimeZone}
        />
      )}
      {selectedRecord && (
        <AcceptSummitMeeting
          visible={showAcceptOnlineRequestModal}
          closeModal={() => {
            setShowAcceptOnlineRequestModal(false)
            setSelectedRecord({})
          }}
          company={meetingWithString}
          meetingId={isNil(selectedRecord) ? 0 : selectedRecord.meetingId}
          onAccept={() => {
            dispatch({ type: 'Accept' })
            onRefresh()
          }}
          meetingDateTime={selectedRecord.meetingDateTime}
          role={role}
          haveAnswered={selectedRecord.haveAnswered}
          roadshowId={selectedRecord.roadshowId}
        />
      )}
      {selectedRecord && RescheduleModal}
      {selectedRecord && showCancelOnlineModal && (
        <CancelSummitMeeting
          visible={showCancelOnlineModal}
          closeModal={() => {
            setShowCancelOnlineModal(false)
          }}
          meetingStatus={selectedRecord.meetingStatus}
          company={meetingWithString}
          meetingId={selectedRecord.meetingId}
          meetingType={selectedRecord.meetingType}
          onCancel={() => {
            dispatch({ type: 'Decline/Cancel' })
            dispatch(fetchEventsIfNeeded(eventId))
            onRefresh()
          }}
          canDeclineMeeting={
            !canLeaveMeeting(
              selectedRecord.colleagues,
              selectedRecord.participants
            )
          }
          cancelForAll={cancelForAll}
        />
      )}
      {selectedRecord && showDeclineOnlineModal && (
        <DeclineOnlineMeeting
          visible={showDeclineOnlineModal}
          closeModal={() => {
            setShowDeclineOnlineModal(false)
          }}
          company={meetingWithString}
          meetingId={selectedRecord.meetingId}
          onCancel={() => {
            dispatch({ type: 'Decline/Cancel' })
            dispatch(fetchEventsIfNeeded(eventId))
            onRefresh()
          }}
          meetingType={selectedRecord.meetingType}
        />
      )}
      <Table
        locale={{
          emptyText: <EmptyEventTablesState />,
        }}
        columns={getColumns('Upcoming Meetings & Requests', false)}
        rowKey={(record) => record.meetingId}
        loading={loadingMeetings}
        dataSource={
          (meetings?.upcomingMeetings &&
            meetings.upcomingMeetings.sort((a, b) =>
              moment.utc(a.meetingDateTime).diff(moment.utc(b.meetingDateTime))
            )) ||
          []
        }
        pagination={false}
      />
      <Table
        locale={{
          emptyText: <EmptyEventTablesState />,
        }}
        columns={getColumns('Past Meetings', true)}
        rowKey={(record) => record.meetingId}
        loading={loadingMeetings}
        dataSource={meetings ? meetings.pastMeetings : []}
        pagination={false}
      />
    </div>
  )
}

UpcomingMeetingRequests.propTypes = {
  onRefresh: PropTypes.func,
  eventId: PropTypes.number,
  meetings: PropTypes.shape({
    receivedRequests: PropTypes.array.isRequired,
    upcomingMeetings: PropTypes.array.isRequired,
    sentRequests: PropTypes.array.isRequired,
    pastMeetings: PropTypes.array.isRequired,
  }),
  loadingMeetings: PropTypes.bool,
  fromDealProfile: PropTypes.bool,
}

export default UpcomingMeetingRequests
