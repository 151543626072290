import isNil from 'lodash/isNil'

const setInvalidAnswersList = (
  shortName,
  isRequired,
  responseText = '',
  setInvalidAnswers,
  firstAnswer,
  stepNumber,
  errors
) => {
  const isRequiredValidate =
    !isRequired ||
    (responseText !== '' && responseText !== null) ||
    !isNil(firstAnswer)
  isRequiredValidate && !Object.keys(errors).includes(shortName)
    ? setInvalidAnswers((prevInvalidAnswers) =>
        prevInvalidAnswers.filter((answer) => answer.shortName !== shortName)
      )
    : setInvalidAnswers((prevInvalidAnswers) => [
        ...prevInvalidAnswers,
        { shortName, stepNumber },
      ])
}

export default setInvalidAnswersList
