import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { addLineBreaks } from '~/utils/helpers'

const ContextCompanyProfile = ({ companyDescription, contacts }) => (
  <div>
    {!isEmpty(companyDescription) && (
      <div>
        <span className="type-subtitle-sm text-header">
          Company Description
        </span>
        <div
          style={{
            marginTop: '7px',
            display: 'flex',
            width: '40%',
            justifyContent: 'space-between',
          }}
        >
          {addLineBreaks(companyDescription)}
        </div>
      </div>
    )}
    {!isEmpty(contacts) && (
      <div style={{ marginTop: '32px' }}>
        <span className="type-subtitle-sm text-header">Contacts</span>
        <div
          style={{
            marginTop: '7px',
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {map(contacts, (c) => (
            <div
              key={c.contactId}
              style={{
                marginTop: '7px',
                display: 'flex',
                width: '50%',
                justifyContent: 'space-between',
              }}
            >
              <span>{`${c.firstName} ${c.lastName}:`}</span>
              <span>{c.email}</span>
              <span>{c.mobilePhone}</span>
            </div>
          ))}
        </div>
      </div>
    )}
  </div>
)
ContextCompanyProfile.propTypes = {
  companyDescription: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      jobTitle: PropTypes.string.isRequired,
      mobilePhone: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired
  ),
}
export default ContextCompanyProfile
