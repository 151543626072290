import { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox, Input, Modal, Popover, Select, message } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useSelector } from 'react-redux'
import {
  getColleagues,
  sendOnlineRequestToFund,
} from '~/actions/contextMeetings'
import { getUserId } from '~/selectors/auth'
import MandateViewCtx from '~/utils/contexts/MandateViewCtx'
import CompanySelectContainer from '../CompanySelectContainer'
import Loading from '../Loading'
import MeetingRequestProposedTimes from '../MeetingRequestProposedTimes'
import ParticipantsCheckbox from '../ParticipantsCheckbox'
import './DiscoverFundMeetingRequest.less'

const { TextArea } = Input

const DiscoverFundMeetingRequest = ({
  visible,
  fund,
  onClose,
  onRefresh,
  roadshowList,
  roadshowId = 0,
  meetingTypeId = 3,
  fromMapView = false,
  arePointsNear,
  coordinates = [],
}) => {
  const userId = useSelector((state) => getUserId(state))
  const { role, company } = useSelector((state) => state.auth)
  const [, setCurrentStep] = useState(0)
  const [carryContextPoints, setCarryContextPoints] = useState(false)
  const [loadingContacts, setLoadingContacts] = useState(false)
  const [myColleagues, setMyColleagues] = useState([])
  const [otherColleagues, setOtherColleagues] = useState([])
  const [participants, setParticipants] = useState([userId])
  const [toParticipants, setToParticipants] = useState([])
  const [invitedParticipants, setInvitedParticipants] = useState([])
  const [invitedCompany, setInvitedCompany] = useState(null)
  const [textMessage, setMessage] = useState('')
  const [savingInProggress, setSavinginProggress] = useState(false)
  const [selectedRoadshowId, setSelectedRoadshowId] = useState(0)
  const companyContextPoints = useSelector((state) => state.company.company)
  const [canSendMeetingRequest, setCanSendMeetingRequest] = useState(true)

  const { meetingInvite } = useFlags()
  const sentFromMandateView = useContext(MandateViewCtx)

  const getMeetingRoadshowId = () => {
    if (meetingTypeId === 2) {
      if (!isNil(roadshowId) && roadshowId > 0) {
        return roadshowId
      } else if (!isNil(selectedRoadshowId) && selectedRoadshowId > 0) {
        return selectedRoadshowId
      } else {
        return null
      }
    } else {
      return null
    }
  }

  const meetingRoadshowId = getMeetingRoadshowId()

  const handleOk = useCallback(() => {
    setSavinginProggress(true)

    return sendOnlineRequestToFund(
      fund.companyId,
      fund.fundId,
      participants,
      toParticipants,
      textMessage,
      meetingTypeId,
      meetingRoadshowId,
      carryContextPoints,
      invitedParticipants,
      sentFromMandateView
    )
      .then((response) => {
        const { meetingId } = response.data.result.result
        if (isNil(fund.meetings)) {
          fund.meetingId = meetingId
          fund.meetingStatus = 'Pending'
        } else {
          fund.meetings.push({
            contextMeetingId: meetingId,
            meetingStatus: 'Pending',
            participantStatus: 'Confirmed',
          })
        }
        message.success('Request sent succesfully.')
        onClose()
      })
      .catch((err) => {
        const errorMessage =
          err?.response?.status === 400 ? err?.response?.data?.message : null
        message.error(errorMessage || 'Could not send meeting request.')
      })
      .finally(() => {
        setSavinginProggress(false)
        onRefresh()
      })
  }, [
    carryContextPoints,
    fund,
    invitedParticipants,
    meetingRoadshowId,
    meetingTypeId,
    onClose,
    onRefresh,
    participants,
    textMessage,
    toParticipants,
  ])

  const handleParticipantChecked = useCallback(
    (e) => {
      let tmpparticipants
      if (e.target.checked) {
        tmpparticipants = [...participants, e.target.value]
        setParticipants(tmpparticipants)
      } else {
        tmpparticipants = participants.filter((p) => p !== e.target.value)
        setParticipants(tmpparticipants)
      }
    },
    [participants]
  )

  const handleToParticipantChecked = useCallback(
    (e) => {
      let tmptoParticipants
      if (e.target.checked) {
        tmptoParticipants = [...toParticipants, e.target.value]
        setToParticipants(tmptoParticipants)
      } else {
        tmptoParticipants = toParticipants.filter((p) => p !== e.target.value)
        setToParticipants(tmptoParticipants)
      }
    },
    [toParticipants]
  )

  const handleInvitedParticipants = useCallback(
    (e) => {
      let tmpInvitedParticipants
      if (e.target.checked) {
        tmpInvitedParticipants = [...invitedParticipants, e.target.value]
        setInvitedParticipants(tmpInvitedParticipants)
      } else {
        tmpInvitedParticipants = invitedParticipants.filter(
          (p) => p !== e.target.value
        )
        setInvitedParticipants(tmpInvitedParticipants)
      }
    },
    [invitedParticipants]
  )

  const checkRoleForServiceProvider = useCallback(() => {
    if (role === 'Service Provider') return false
  }, [role])

  useEffect(() => {
    if (
      checkRoleForServiceProvider() ||
      toParticipants.length === 0 ||
      participants.length === 0
    )
      setCanSendMeetingRequest(false)
    else if (meetingTypeId === 2) {
      if (!isNil(selectedRoadshowId) && !isEmpty(coordinates)) {
        const selectedRoadshow = roadshowList.find(
          (x) => x.roadShowId === selectedRoadshowId
        )
        if (!isNil(selectedRoadshow)) {
          if (!arePointsNear(coordinates, selectedRoadshow.coordinates, 10)) {
            message.error(
              'Can not send roadshow meeting request to contact outside of roadshow area!'
            )
            setCanSendMeetingRequest(false)
          } else {
            setCanSendMeetingRequest(true)
          }
        }
      }
    } else setCanSendMeetingRequest(true)
  }, [
    arePointsNear,
    checkRoleForServiceProvider,
    coordinates,
    meetingTypeId,
    participants,
    roadshowList,
    role,
    selectedRoadshowId,
    toParticipants,
  ])

  const footer = (
    <div className="footer">
      <Button
        onClick={() => {
          setCurrentStep(0)
          setToParticipants([])
          setInvitedParticipants([])
          setInvitedCompany(null)
          setParticipants([userId])
          onClose()
        }}
      >
        Cancel
      </Button>
      <Button
        loading={savingInProggress}
        type="primary"
        disabled={!canSendMeetingRequest}
        onClick={() => handleOk()}
      >
        Send Meeting Request
      </Button>
    </div>
  )

  useEffect(() => {
    if (!isEmpty(roadshowList) && selectedRoadshowId === 0) {
      setSelectedRoadshowId(roadshowList[0].roadShowId)
    }
  }, [roadshowList, selectedRoadshowId])

  useEffect(() => {
    setLoadingContacts(true)
    ;(meetingTypeId !== 2 ||
      (meetingTypeId === 2 && (roadshowId || selectedRoadshowId))) &&
      getColleagues(fund.companyId, fund.fundId, null, meetingRoadshowId)
        .then((response) => {
          setMyColleagues(
            response.data.result.find((x) => x.companyId !== fund.companyId)
              .contacts
          )
          const fundcontacts = response.data.result.find(
            (x) => x.companyId === fund.companyId
          ).contacts
          setOtherColleagues(fundcontacts)
          setToParticipants(map(fundcontacts, (c) => c.contactId))
        })
        .catch(() => message.error('Could not retrieve colleagues'))
        .finally(() => {
          setLoadingContacts(false)
        })
  }, [fund, meetingRoadshowId, meetingTypeId, roadshowId, selectedRoadshowId])

  return (
    <Modal
      centered
      className="AllocatorOnlineMR"
      visible={visible}
      width="700px"
      title="Request a Meeting"
      footer={footer}
      afterClose={() => {
        setCurrentStep(0)
        setToParticipants([])
        setParticipants([userId])
        setInvitedParticipants([])
        setInvitedCompany(null)
        onClose()
      }}
      onCancel={() => {
        setCurrentStep(0)
        setToParticipants([])
        setInvitedParticipants([])
        setInvitedCompany(null)
        setParticipants([userId])
        onClose()
      }}
      bodyStyle={{ padding: '16px 32px' }}
    >
      <div>
        <div>
          {!isEmpty(roadshowList) &&
            meetingTypeId === 2 &&
            fromMapView &&
            (roadshowId === 0 || isNil(roadshowId)) && (
              <div
                style={{ marginBottom: '16px' }}
                className="cc-tabletitle-text cc-darkest-grey-text"
              >
                <div style={{ marginTop: '16px' }}>Roadshow Event</div>
                <div style={{ display: 'flex', width: '70%' }}>
                  <Select
                    className="cc-background-input"
                    value={selectedRoadshowId}
                    style={{ width: '100%', marginTop: '16px' }}
                    placeholder="Select Roadshow event"
                    onChange={(e) => {
                      setSelectedRoadshowId(e)
                    }}
                  >
                    {roadshowList &&
                      map(roadshowList, (r) => (
                        <Select.Option key={r.roadShowId} value={r.roadShowId}>
                          {r.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </div>
            )}
        </div>
        <Loading spinning={loadingContacts}>
          <div>
            <div className="cc-heading4 cc-darkest-grey-text">{fund.name}</div>
            <div className="cc-check-participants-wrapper">
              {otherColleagues &&
                map(otherColleagues, (contact) => (
                  <ParticipantsCheckbox
                    key={contact.contactId}
                    participants={toParticipants}
                    checked={handleToParticipantChecked}
                    contactId={contact.contactId}
                    contactName={`${contact.firstName} ${contact.lastName}`}
                    imageUrl={contact.imageUrl}
                    position={contact.jobTitle}
                  />
                ))}
            </div>
            {otherColleagues?.length > 1 && (
              <Button
                style={{ padding: '8px 0px' }}
                type="link"
                onClick={() => {
                  toParticipants?.length > 0
                    ? setToParticipants([])
                    : setToParticipants(
                        map(otherColleagues, (c) => c.contactId)
                      )
                }}
              >
                {toParticipants?.length > 0 ? 'Deselect All' : 'Select All'}
              </Button>
            )}
          </div>
          <div>
            <div
              style={{ marginTop: '16px' }}
              className="cc-heading4 cc-darkest-grey-text"
            >
              Your Colleagues
            </div>
            <div>
              <div className="cc-check-participants-wrapper">
                {myColleagues &&
                  map(myColleagues, (contact) => (
                    <ParticipantsCheckbox
                      key={contact.contactId}
                      participants={participants}
                      checked={handleParticipantChecked}
                      contactId={contact.contactId}
                      contactName={`${contact.firstName} ${contact.lastName}`}
                      imageUrl={contact.imageUrl}
                      position={contact.jobTitle}
                    />
                  ))}
              </div>
            </div>
          </div>
        </Loading>
        {role === 'CFN' &&
          toParticipants.length > 0 &&
          isNil(invitedCompany) &&
          meetingInvite && (
            <div>
              <div
                style={{ marginTop: '16px' }}
                className="cc-heading5 cc-darkest-grey-text"
              >
                Invite Another Company
              </div>
              <div
                style={{
                  marginTop: '10px',
                }}
              >
                <Popover
                  id="popover"
                  destroyTooltipOnHide
                  overlayClassName="Invite-company-button-popover"
                  placement="bottomLeft"
                  trigger="click"
                  content={
                    <CompanySelectContainer
                      excludedCompanies={[company.companyId, fund.companyId]}
                      setSelectedCompany={setInvitedCompany}
                      canInviteFunds={false}
                      isPopover
                    />
                  }
                >
                  <Button
                    type="link"
                    className="Invite-company-button"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0px',
                      fontWeight: '600',
                    }}
                    icon={
                      <FontAwesomeIcon
                        style={{
                          width: '18px',
                          height: 'auto',
                          marginRight: '8px',
                        }}
                        icon={faPlus}
                      />
                    }
                  >
                    Add another company
                  </Button>
                </Popover>
              </div>
            </div>
          )}
        {!isNil(invitedCompany) && meetingInvite && (
          <div>
            <div
              style={{ marginTop: '16px' }}
              className="cc-heading4 cc-darkest-grey-text"
            >
              {invitedCompany.companyName}
              <Button
                className="remove-invited-button cc-text-color-danger"
                icon={
                  <FontAwesomeIcon
                    style={{ marginRight: '8px' }}
                    icon={faTrashAlt}
                  />
                }
                type="link"
                style={{ fontWeight: '600' }}
                onClick={() => {
                  setInvitedCompany(null)
                  setInvitedParticipants([])
                }}
              >
                Remove
              </Button>
            </div>
            <div>
              <div className="cc-check-participants-wrapper">
                {invitedCompany.contacts &&
                  map(invitedCompany.contacts, (contact) => (
                    <ParticipantsCheckbox
                      key={contact.contactId}
                      participants={invitedParticipants}
                      checked={handleInvitedParticipants}
                      contactId={contact.contactId}
                      companyContactId={contact.companyContactId}
                      contactName={contact.contactName}
                      imageUrl={contact.imageUrl}
                      position={contact.jobTitle}
                      useCompanyContactId
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
        <MeetingRequestProposedTimes />
        <div style={{ width: '100%', marginTop: '24px' }}>
          <TextArea
            placeholder="Write a message..."
            rows={4}
            value={textMessage}
            onChange={({ target: { value } }) => {
              setMessage(value)
            }}
          />
        </div>
        {company &&
          company.serviceProviderLevel.toLowerCase() === 'platinum' &&
          companyContextPoints &&
          companyContextPoints.contextPoints >= 1000 && (
            <div style={{ width: '100%', marginTop: '24px' }}>
              <Checkbox
                style={{
                  width: '20px',
                }}
                checked={carryContextPoints}
                onChange={() => {
                  setCarryContextPoints(!carryContextPoints)
                }}
              />
              Send 1,000 Context Points
            </div>
          )}
      </div>
    </Modal>
  )
}

DiscoverFundMeetingRequest.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  fund: PropTypes.object.isRequired,
  onRefresh: PropTypes.func,
  roadshowId: PropTypes.number,
  meetingTypeId: PropTypes.number,
  fromMapView: PropTypes.bool,
  roadshowList: PropTypes.array,
  arePointsNear: PropTypes.func.isRequired,
  coordinates: PropTypes.array,
}

export default DiscoverFundMeetingRequest
