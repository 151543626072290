import PropTypes from 'prop-types'
import { faGlobe, faLocationCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Radio } from 'antd'
import './MeetingTypeSelector.less'

const MeetingTypeSelector = ({
  isVisible,
  onClose,
  onOk,
  meetingTypeId = 3,
  setMeetingTypeId,
}) => (
  <Modal
    centered
    width="440px"
    title="Select meeting request type"
    cancelText="Cancel"
    okText="Continue"
    onOk={onOk}
    visible={isVisible}
    onCancel={onClose}
    bodyStyle={{ padding: 0 }}
  >
    <Radio.Group
      value={meetingTypeId}
      onChange={setMeetingTypeId}
      className="MeetingTypeSelector"
    >
      <Radio.Button
        value={3}
        className="MeetingTypeSelector-button"
        style={{ marginRight: '32px' }}
      >
        <div className="MeetingTypeSelector-button-inner">
          <FontAwesomeIcon
            className="MeetingTypeSelector-button-icon"
            size="3x"
            icon={faGlobe}
          />
          <div>Online Meeting</div>
        </div>
      </Radio.Button>
      <Radio.Button className="MeetingTypeSelector-button" value={2}>
        <div className="MeetingTypeSelector-button-inner">
          <FontAwesomeIcon
            className="MeetingTypeSelector-button-icon"
            size="3x"
            icon={faLocationCircle}
          />
          <div>Roadshow Meeting</div>
        </div>
      </Radio.Button>
    </Radio.Group>
  </Modal>
)

MeetingTypeSelector.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  meetingTypeId: PropTypes.number,
  setMeetingTypeId: PropTypes.func.isRequired,
}

export default MeetingTypeSelector
