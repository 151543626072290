import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Select } from '@context365/forms'
import { useQuery } from 'react-query'
import * as api from '~/api'
import useAuth from '~/hooks/useAuth'

export default function InviteCompanyUser({ onSendInvitation }) {
  const colleagues = useCompanyColleagues()
  const [colleagueId, setColleagueId] = React.useState(null)

  function sendInvitation() {
    const selectedColleague = colleagues.find(
      (c) => c.contactId === colleagueId
    )
    if (!selectedColleague) {
      return
    }

    onSendInvitation({
      contactId: selectedColleague.contactId,
      companyId: selectedColleague.companyId,
    })
    setColleagueId(null)
  }

  return (
    <div className="flex mt-4 items-end gap-6">
      <Select
        className="w-80"
        label="Full Name"
        value={colleagueId}
        onChange={setColleagueId}
      >
        {colleagues.map((user) => (
          <Select.Option key={user.contactId} value={user.contactId}>
            {[user.firstName, user.lastName].join(' ')}
          </Select.Option>
        ))}
      </Select>
      <Button
        variant="filled"
        status="success"
        disabled={!colleagueId}
        onClick={sendInvitation}
      >
        Invite
      </Button>
    </div>
  )
}

InviteCompanyUser.propTypes = {
  onSendInvitation: PropTypes.func.isRequired,
}

function useCompanyColleagues() {
  const { company, contact } = useAuth()
  const { data: colleagues = [] } = useQuery(
    ['colleagues', company.companyId],
    () => api.companies.getColleagues(company.companyId),
    {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  )
  return colleagues.filter((c) => c.contactId !== contact.contactId)
}
