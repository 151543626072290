import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Link, useHistory } from 'react-router-dom'

const NextPresentationActions = ({ presentation }) => {
  const { push } = useHistory()

  const canStart =
    presentation.isPresentationOwner &&
    !presentation.isLive &&
    !presentation.presentationHasEnded
  const canJoin = presentation.isLive
  const canEdit = presentation.isPresentationOwner && !presentation.isEnded
  return (
    <div className="flex gap-2 mx-2">
      {canStart && (
        <Button
          variant="filled"
          as={Link}
          status="success"
          to={`/community/presentations/${presentation.id}/room`}
          target="_blank"
        >
          Start
        </Button>
      )}
      {canJoin && (
        <Button
          as={Link}
          variant="filled"
          status="success"
          to={`/community/presentations/${presentation.id}/room`}
          target="_blank"
        >
          Join presentation
        </Button>
      )}
      {canEdit && (
        <Button
          variant="link"
          status="secondary"
          onClick={() => push(`/edit-presentation/${presentation.id}`)}
        >
          Edit
        </Button>
      )}
    </div>
  )
}

NextPresentationActions.propTypes = {
  presentation: PropTypes.object.isRequired,
}
export default NextPresentationActions
