import * as Yup from 'yup'
import {
  Form,
  InputField,
  NumberInputField,
  TextAreaField,
  UnsavedChangesAlert,
} from '~/components/Form'
import { AddressForm } from '../forms/AddressForm'
import { baseCompanySchema } from './CompanyForm'

const schema = baseCompanySchema.shape({
  firmAUM: Yup.number()
    .min(0)
    .nullable()
    .required()
    .typeError('Please enter a valid number'),
})

export default function ManagerCompanyForm({
  savedAnswers,
  onSubmit,
  ...formProps
}) {
  return (
    <Form
      className="onboarding-form"
      schema={schema}
      defaultValues={savedAnswers}
      onSubmit={onSubmit}
      {...formProps}
    >
      <InputField name="companyName" label="Company Name" autoComplete="off" />
      <TextAreaField
        name="companyDescription"
        label="Company Description"
        rows={5}
        showCharacterCount
      />
      <InputField
        name="companyWebsite"
        label="Company Website"
        inputMode="url"
      />
      <NumberInputField name="firmAUM" label="Firm AUM" type="currency" />
      <AddressForm title="Company Address" className="mt-4" />

      <UnsavedChangesAlert />
    </Form>
  )
}
