import PropTypes from 'prop-types'
import { TabButton, TabList, TabPanel, Tabs } from '@context365/tabs'
import compact from 'lodash/compact'
import join from 'lodash/join'
import map from 'lodash/map'
import moment from 'moment'
import { numberFormat } from '~/utils/formatters'
import { getClickableLink } from '~/utils/helpers'

const Overview = ({ allocator }) => {
  return (
    <div>
      <Tabs>
        <TabList variant="outlined" className="relative z-1">
          {map(allocator.assets, (a) => (
            <TabButton id={a.year} label={a.year} />
          ))}
        </TabList>
        {map(allocator.assets, (a) => (
          <TabPanel
            id={a.year}
            tabId={a.year}
            className="bg-white p-4 border border-grey-400 relative -top-px"
          >
            <div className="grid grid-cols-3 gap-4">
              {map(a.categories, (c) => (
                <div>
                  <div className="type-body-regular-xs text-black">
                    {c.name}
                  </div>
                  <div className="type-body-semibold-lg text-secondary-100">
                    {c.isCurrency
                      ? numberFormat.currencyLarge(c.amounts[0].amount)
                      : c.isPercentage
                      ? numberFormat.percent(c.amounts[0].amount)
                      : numberFormat.thousands(c.amounts[0].amount)}
                  </div>
                  <div className="type-body-regular-xs italic text-grey-600">
                    {moment(c.amounts[0].valuationDate).format('M/YY')}
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
        ))}
      </Tabs>
      <div className="my-12">{allocator.companyDescription}</div>
      {allocator.address1 && <div>{allocator.address1}</div>}
      {allocator.address2 && <div>{allocator.address2}</div>}
      {allocator.address3 && <div>{allocator.address3}</div>}
      <div>
        {join(
          compact([allocator.city, allocator.state, allocator.country]),
          ', '
        )}
      </div>
      <div>{allocator.postalCode}</div>
      <div className="mt-4 flex flex-col space-y-2">
        <div>{allocator.email}</div>
        <div>{allocator.phoneNumber}</div>
        {allocator.website && (
          <a
            href={getClickableLink(allocator.website)}
            target="_blank"
            rel="noreferrer"
          >
            {allocator.website}
          </a>
        )}
        {allocator.linkedIn && (
          <a
            href={getClickableLink(allocator.linkedIn)}
            target="_blank"
            rel="noreferrer"
          >
            linkedin
          </a>
        )}
        {allocator.twitter && (
          <a
            href={getClickableLink(allocator.twitter)}
            target="_blank"
            rel="noreferrer"
          >
            twitter
          </a>
        )}
      </div>
    </div>
  )
}

Overview.propTypes = {
  allocator: PropTypes.object,
}

export default Overview
