import * as React from 'react'
import {
  Axis,
  AxisLabel,
  Chart,
  LineSeries,
  useAxis,
  useChart,
  useChartTooltipState,
  useLineSeries,
} from '@context365/charts'
import { Radio, RadioGroup } from '@context365/forms'
import * as api from '~/api'
import { LineChartPlaceholder } from '~/components/ChartPlaceholder'
import { useCompareFundsContext } from '../compareFundsContext'
import {
  ChartContainer,
  ChartControls,
  TimeSpanSelect,
} from './chartComponents'
import {
  CHART_HEIGHT,
  CHART_WIDTH,
  createDataset,
  format,
  useTimeAxis,
} from './chartHelpers'
import CompareFundsTooltip from './CompareFundsTooltip'
import createComparisonChart from './createComparisonChart'
import { useTrackComparedFunds } from './trackCompareFunds'

const chartHeight = CHART_HEIGHT * 0.75
const verticalPadding = 10

function RollingSharpeChart({ chartData }) {
  const { funds, options, getFundColor } = useCompareFundsContext()
  const comparisonType = getComparisonLabel(options.comparison)
  const { timeSpan = 12 } = options

  const chart = useChart({
    data: chartData,
    height: chartHeight * 2 + verticalPadding * 2,
    width: CHART_WIDTH,
    padding: {
      left: 50,
      right: 20,
      top: 10,
      bottom: 20,
    },
  })
  const [sharpeRange, comparisonRange] = React.useMemo(() => {
    const verticalMidpoint =
      (chart.chartBounds.top + chart.chartBounds.bottom) / 2
    return [
      [verticalMidpoint - verticalPadding, chart.chartBounds.top],
      [chart.chartBounds.bottom, verticalMidpoint + verticalPadding],
    ]
  }, [chart.chartBounds.top, chart.chartBounds.bottom])

  const xAxis = useTimeAxis(chartData, chart)
  const sharpeAxis = useAxis({
    type: 'linear',
    getValue: (d) => d[1],
    formatValue: format.float,
    formatAxis: format.floatShort,
    range: sharpeRange,
  })
  const comparisonAxis = useAxis({
    type: 'linear',
    getValue: (d) => d[1] / 100,
    formatValue: format.percentage,
    formatAxis: format.percentageShort,
    range: comparisonRange,
  })

  const sharpeLines = useLineSeries({
    chart,
    x: xAxis,
    y: sharpeAxis,
    series: Object.keys(chartData.funds).map((fundId) => ({
      key: fundId,
      ...getFundColor(fundId),
      label: funds.find((f) => f.fundId === +fundId)?.name,
      getDataset: (data) =>
        createDataset(data.dates, data.funds[fundId].rollingSharpe ?? []),
    })),
  })
  const comparisonLines = useLineSeries({
    chart,
    x: xAxis,
    y: comparisonAxis,
    series: Object.keys(chartData.funds).map((fundId) => ({
      key: fundId,
      ...getFundColor(fundId),
      label: funds.find((f) => f.fundId === +fundId)?.name,
      getDataset: (data) =>
        createDataset(data.dates, data.funds[fundId].rollingComparison ?? []),
    })),
  })

  const tooltip = useChartTooltipState(chart, sharpeLines)
  const activeDataPoints = tooltip.activeDataPoints.map((d) => {
    const sharpeValue = d.value

    const comparisonDataPoint = comparisonLines.series
      .find((s) => s.key === d.key)
      ._dataPoints.find((p) => p[0] === d.dataPoint[0])
    const comparisonValue = comparisonAxis.format(comparisonDataPoint)

    return {
      ...d,
      value: (
        <>
          <span>Rolling Sharpe: {sharpeValue}</span>
          <br />
          <span>
            {comparisonType}: {comparisonValue}
          </span>
        </>
      ),
    }
  })

  return (
    <ChartContainer>
      <div className="text-center type-header-sm">
        {timeSpan}-Month Rolling Sharpe Decomposed
      </div>
      <Chart {...chart.getChartProps()} className="text-grey-500">
        <Axis {...sharpeLines.getAxisProps('bottom')} />
        <Axis {...sharpeLines.getAxisProps('left')} />
        <Axis {...comparisonLines.getAxisProps('left')} />
        <AxisLabel
          chart={chart}
          position="left"
          className="text-grey-600"
          x={(sharpeRange[0] + sharpeRange[1]) / 2}
        >
          {timeSpan}-Month Rolling Sharpe
        </AxisLabel>
        <AxisLabel
          chart={chart}
          position="left"
          className="text-grey-600"
          x={-(sharpeRange[0] + sharpeRange[1]) / 2}
        >
          {timeSpan}-Month {comparisonType}
        </AxisLabel>
        <LineSeries series={sharpeLines.series} />
        <LineSeries series={comparisonLines.series} />
      </Chart>
      <CompareFundsTooltip
        {...tooltip}
        activeDataPoints={activeDataPoints}
        getRowClass={() => 'pt-2'}
      />
    </ChartContainer>
  )
}

const Comparison = {
  RollingReturn: 'rollingReturn',
  RollingGSD: 'rollingGSD',
}

function RollingSharpeChartControls() {
  const { options, setOption } = useCompareFundsContext()
  const { comparison = Comparison.RollingReturn, timeSpan = 12 } = options
  useTrackComparedFunds()

  return (
    <ChartControls>
      <fieldset>
        <legend className="type-body-semibold-xs">Comparison Type</legend>
        <RadioGroup
          className="flex space-x-4 pt-1"
          name="comparison"
          value={comparison}
          onChange={(e) => setOption({ comparison: e.target.value })}
        >
          <Radio value={Comparison.RollingReturn}>
            {getComparisonLabel(Comparison.RollingReturn)}
          </Radio>
          <Radio value={Comparison.RollingGSD}>
            {getComparisonLabel(Comparison.RollingGSD)}
          </Radio>
        </RadioGroup>
      </fieldset>
      <TimeSpanSelect
        value={timeSpan}
        onChange={(timeSpan) => setOption({ timeSpan })}
      />
    </ChartControls>
  )
}

function getComparisonLabel(comparison) {
  return comparison === Comparison.RollingGSD ? 'Rolling GSD' : 'Rolling Return'
}

async function getRollingSharpeData(fundIds, options) {
  const { comparison = Comparison.RollingReturn, timeSpan = 12 } = options

  const [rollingSharpe, rollingComparison] = await Promise.all([
    api.fundCharts.rollingSharpeChart(fundIds, timeSpan),
    comparison === Comparison.RollingReturn
      ? api.fundCharts.rollingReturnChart(fundIds, timeSpan)
      : api.fundCharts.rollingGSDChart(fundIds, timeSpan),
  ])

  const droppedFunds = [
    ...new Set([
      ...rollingSharpe.data.droppedfunds,
      ...rollingComparison.data.droppedfunds,
    ]),
  ]
  return {
    data: {
      dates: rollingSharpe.data.dates,
      funds: fundIds.reduce((funds, fundId) => {
        funds[fundId] = {
          rollingSharpe: rollingSharpe.data.funds[fundId],
          rollingComparison: rollingComparison.data.funds[fundId],
        }
        return funds
      }, {}),
      droppedfunds: droppedFunds,
    },
  }
}

export default createComparisonChart({
  queryFn: getRollingSharpeData,
  chartControls: RollingSharpeChartControls,
  loading: (
    <div className="w-max mx-auto">
      <LineChartPlaceholder
        animate
        aria-label="loading chart data"
        height={chartHeight}
        width={CHART_WIDTH}
      />
      <div style={{ height: verticalPadding }} />
      <LineChartPlaceholder
        animate
        aria-label="loading chart data"
        height={chartHeight}
        width={CHART_WIDTH}
      />
    </div>
  ),
  error: ({ error }) => (
    <div className="w-max mx-auto relative">
      <LineChartPlaceholder
        aria-label="data not available"
        height={chartHeight}
        width={CHART_WIDTH}
      />
      <div className="flex flex-col absolute inset-0 items-center justify-center">
        <div className="type-body-regular-lg mb-2">
          Unable to load chart data.
        </div>
        <div className="type-body-regular-md">
          Please choose a different benchmark or different funds to compare.
        </div>
        <div className="type-body-regular-xs">
          {error.response.data.message}
        </div>
      </div>
    </div>
  ),
  success: RollingSharpeChart,
})
