import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Chart, Interval, Tooltip } from 'bizcharts'
import isEmpty from 'lodash/isEmpty'
import './IlliquidCharts.less'

const IlliquidBarChart = ({ type, data, cadence, height = 400 }) => {
  const [chartWidth, setChartWidth] = useState(null)

  const ChartRef = useRef(null)

  useEffect(() => {
    setChartWidth(ChartRef.current?.offsetWidth - 50)
  }, [ChartRef, data])

  useLayoutEffect(() => {
    setChartWidth(ChartRef.current?.offsetWidth - 50)
    const handleResize = () => {
      setTimeout(() => {
        setChartWidth(ChartRef.current?.offsetWidth - 50)
      }, 100)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isEmpty(data) ? null : (
    <div className="IlliquidChart" ref={ChartRef}>
      <div className="type-header-xxs">
        {cadence} Returns{' '}
        <span style={{ textTransform: 'uppercase' }}>{type}</span>
      </div>
      <Chart data={data} height={height} width={chartWidth} padding="auto">
        <Tooltip>
          {(title, items) => (
            <div className="IlliquidChart-tooltip">
              <div className="type-header-xs">{title}</div>
              <ul
                className="IlliquidChart-tooltip-bullet type-header-xxs"
                style={{ color: items[0].color }}
              >
                <li>
                  <span className="text-grey-700">
                    {type}: {items[0].value}
                  </span>
                </li>
              </ul>
            </div>
          )}
        </Tooltip>
        <Interval position="interval*return" />
      </Chart>
    </div>
  )
}

IlliquidBarChart.propTypes = {
  type: PropTypes.string.isRequired,
  cadence: PropTypes.string.isRequired,
  data: PropTypes.array,
  height: PropTypes.number,
}

export default IlliquidBarChart
