import { SET_PAGE_TITLE } from '~/constants/types/title'

const initialState = {
  title: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_TITLE:
      return { ...state, title: action.payload }
    default:
      return state
  }
}
