import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { fetchMeetingsForProfile } from '~/actions/meetings'
import * as api from '~/api'
import DISPLAY_MODES from '~/constants/displayModes'
import VALUE_TYPES from '~/constants/valueTypes'
import Loading from '../Loading'
import MandatePreviewList from '../MandatePreviewList'
import MandateQuestionnaireResponses from '../MandateQuestionnaireResponses'
import ProfileBodyWithTabs from '../ProfileBodyWithTabs/ProfileBodyWithTabs'
import ProfileDetails from '../ProfileDetails/ProfileDetails'
import ProfileDisclaimerTab from '../ProfileDisclaimerTab'
import ProfileHeaderWithButtons from '../ProfileHeaderWithButtons'
import ProfileModal from '../ProfileModal/ProfileModal'
import UpcomingMeetingRequests from '../UpcomingMeetingRequests'
import SendMeetingRequestToAllocatorButton from './SendMeetingRequestToAllocatorButton'
import './AllocatorDetailsModal.less'

const DISCLAIMER =
  'The provision of any information regarding any allocator or investor (the "Allocator Information") to you is for informational purposes only. Apex US Distribution LLC makes no representation about, and does not independently verify, the Allocator Information and shall not be responsible for its accuracy, completeness or your reliance thereon. The Allocator Information is self-reported by the associated allocator or investor to Apex US Distribution LLC. Apex US Distribution LLC is not making any recommendation, express or implied, as to the suitability or otherwise of any transaction with, and is not acting as agent, consultant, or representative for any allocator or investor. Funds and fund managers must assess the suitability of any particular allocator or investor and carry out appropriate due diligence on their own behalf in relation to the same and Apex US Distribution LLC provides no advice in that regard. Apex US Distribution LLC makes no representation as to an allocator’s or investor’s compliance with any applicable laws or regulations. Apex US Distribution LLC shall not be liable for any losses that may arise, directly or indirectly, from your introduction to, or any investment made by, any allocator or investor.'
const AllocatorDetailsModal = ({
  company = null,
  fundId,
  sendMeetingRequest,
  visible = false,
  pastSummit = false,
  eventId = null,
  canSendMeetingRequest = true,
  onRefresh = () => {},
  skipValidation = false,
  includeRoadshowMeetings = false,
  cancel = () => {},
  campaignApplicationId = null,
}) => {
  const [refresh, setRefresh] = useState(false)
  const [meetingRequestValidation, setMeetingRequestValidation] = useState({
    isValid: false,
  })
  const [loadingMeetings, setLoadingMeetings] = useState(false)
  const [meetings, setMeetings] = useState(null)
  const role = useSelector((state) => state.auth.role)

  const {
    data: applicationQuestionnaireResponses = [],
    isLoading: isQuestionnaireResponsesLoading,
  } = useQuery(
    ['applicationQuestionnaireResponses', campaignApplicationId],
    () => api.campaigns.getCampaignApplicationResponses(campaignApplicationId),
    {
      enabled: !!campaignApplicationId,
    }
  )

  useEffect(() => {
    if (visible && company.companyId) {
      setLoadingMeetings(true)
      fetchMeetingsForProfile(company.companyId, {
        eventId: isNil(eventId) ? 0 : eventId,
        fundId: isNil(fundId) ? 0 : fundId,
      })
        .then((res) => {
          setMeetings(res.data.result)
        })
        .finally(() => {
          setLoadingMeetings(false)
        })

      if (skipValidation) {
        setMeetingRequestValidation({
          isValid: true,
        })
      } else {
        api.meeting
          .checkCanSendMeetingRequest({
            toCompanyId: company.companyId,
            fundId,
          })
          .then((validationResponse) => {
            setMeetingRequestValidation(validationResponse)
          })
      }
    }
  }, [
    company,
    refresh,
    eventId,
    fundId,
    visible,
    skipValidation,
    includeRoadshowMeetings,
  ])

  const canSPSendMeetingRequest =
    role !== 'Service Provider' ||
    (!isNil(eventId) && !pastSummit) ||
    (isNil(eventId) && company?.canSendMeetingRequest && !company?.isCFN)

  if (isNil(company)) {
    return null
  } else {
    const allocatorDetails = [
      {
        displayMode: DISPLAY_MODES.COLUMN,
        sections: [
          {
            key: 'companyDescription',
            label: 'About',
            value: company.companyDescription,
            valueType: VALUE_TYPES.PARAGRAPH,
          },
          {
            key: 'primaryInvestorCategory',
            label: 'Investor Category',
            value: company.primaryInvestorCategory,
          },
        ],
      },
      {
        subGroupName: 'Investment Size',
        displayMode: DISPLAY_MODES.ROW,
        sections: [
          {
            key: 'minimumInvestmentSize',
            label: 'Minimum Investment Size',
            value: company.minimumInvestmentSize,
            valueType: VALUE_TYPES.CURRENCY,
          },
          {
            key: 'maximumInvestmentSize',
            label: 'Maximum Investment Size',
            value: company.maximumInvestmentSize,
            valueType: VALUE_TYPES.CURRENCY,
          },
        ],
      },
      {
        subGroupName: 'Fund Size Interest',
        displayMode: DISPLAY_MODES.ROW,
        sections: [
          {
            key: 'minimumFundSizeInterest',
            label: 'Minimum Fund Size Interest',
            value: company.minimumFundSizeInterest,
            valueType: VALUE_TYPES.CURRENCY,
          },
          {
            key: 'maximumFundSizeInterest',
            label: 'Maximum Fund Size Interest',
            value: company.maximumFundSizeInterest,
            valueType: VALUE_TYPES.CURRENCY,
          },
        ],
      },
      {
        displayMode: DISPLAY_MODES.ROW,
        sections: [
          {
            key: 'trackRecord',
            label: 'Track Record Length (Years)',
            value: company.trackRecordLength,
            valueType: VALUE_TYPES.ARRAY,
          },
        ],
      },
      {
        displayMode: DISPLAY_MODES.ROW,
        sections: [
          {
            key: 'liquidBroadStrategyInterest',
            label: 'Liquid Broad Strategy Interest',
            value: company.liquidBroadStrategyInterest,
            valueType: VALUE_TYPES.ARRAY,
          },
          {
            key: 'illiquidBroadStrategyInterest',
            label: 'Illiquid Broad Strategy Interest',
            value: company.illiquidBroadStrategyInterest,
            valueType: VALUE_TYPES.ARRAY,
          },
        ],
      },
      {
        displayMode: DISPLAY_MODES.ROW,
        sections: [
          {
            key: 'liquidSubStrategyInterest',
            label: 'Liquid Sub Strategy Interest',
            value: company.liquidSubStrategyInterest,
            valueType: VALUE_TYPES.ARRAY,
          },
          {
            key: 'illiquidSubStrategyInterest',
            label: 'Illiquid Sub Strategy Interest',
            value: company.illiquidSubStrategyInterest,
            valueType: VALUE_TYPES.ARRAY,
          },
        ],
      },
      {
        displayMode: DISPLAY_MODES.ROW,
        sections: [
          {
            key: 'investmentDecisionProcessInterest',
            label: 'Investment Decision Process',
            value: company.investmentDecisionProcessInterest,
            valueType: VALUE_TYPES.ARRAY,
          },
        ],
      },
      {
        displayMode: DISPLAY_MODES.ROW,
        sections: [
          {
            key: 'netDollarExposureInterest',
            label: 'Net Dollar Exposure',
            value: company.netDollarExposureInterest,
            valueType: VALUE_TYPES.ARRAY,
          },
        ],
      },
      {
        displayMode: DISPLAY_MODES.ROW,
        sections: [
          {
            key: 'liquidityTerms',
            label: 'Liquidity Terms',
            value: company.liquidityTerms,
            valueType: VALUE_TYPES.ARRAY,
          },
        ],
      },
      {
        displayMode: DISPLAY_MODES.ROW,
        sections: [
          {
            key: 'maximumPercentageOfAFundsAUMYouWouldBeComfortableComprising',
            label: 'Investment cannot be larger than this percentage of a fund',
            value:
              company.maximumPercentageOfAFundsAUMYouWouldBeComfortableComprising,
            valueType: VALUE_TYPES.PERCENT,
          },
        ],
      },
      {
        displayMode: DISPLAY_MODES.ROW,
        sections: [
          {
            key: 'investmentObjectives',
            label: 'Investment Objectives',
            value: company.investmentObjectives,
            valueType: VALUE_TYPES.ARRAY,
          },
        ],
      },
      {
        displayMode: DISPLAY_MODES.ROW,
        sections: [
          {
            key: 'sectorsInterest',
            label: 'Sectors Interest',
            value: company.sectorsInterest,
            valueType: VALUE_TYPES.ARRAY,
          },
        ],
      },
      {
        displayMode: DISPLAY_MODES.ROW,
        sections: [
          {
            key: 'geographicConcentrationInterest',
            label: 'Geographic Concentration Interest',
            value: company.geographicConcentrationInterest,
            valueType: VALUE_TYPES.ARRAY,
          },
        ],
      },
      {
        displayMode: DISPLAY_MODES.ROW,
        sections: [
          {
            key: 'fundAttributes',
            label: 'Fund Attributes',
            value: company.fundAttributes,
            valueType: VALUE_TYPES.ARRAY,
          },
        ],
      },
      {
        displayMode: DISPLAY_MODES.ROW,
        sections: [
          {
            key: 'mandateOrder',
            label: 'Mandate Order (ranked in order of importance)',
            value: company.mandateOrder,
            valueType: VALUE_TYPES.ARRAY,
          },
        ],
      },
    ]

    const profileTabs = [
      {
        key: 'Responses',
        title: 'Responses',
        component: (
          <MandateQuestionnaireResponses
            questionnaireResponses={applicationQuestionnaireResponses}
          />
        ),
        isVisible: (data) => !isEmpty(data.applicationQuestionnaireResponses),
      },
      {
        key: 'Overview',
        title: 'Overview',
        component: <ProfileDetails valueGroups={allocatorDetails} />,
        isVisible: () => true,
      },
      {
        key: 'Meetings',
        title: 'Meetings',
        component: (
          <UpcomingMeetingRequests
            eventId={eventId}
            meetings={meetings}
            loadingMeetings={loadingMeetings}
            onRefresh={() => {
              setRefresh((prevRefresh) => !prevRefresh)
              onRefresh()
            }}
          />
        ),
        isVisible: () => true,
      },
      {
        key: 'Mandates',
        title: 'Mandates',
        component: (
          <MandatePreviewList
            getMandates={() =>
              api.discovery.mandates({
                filter: [
                  `isAnonymous eq false`,
                  `companyId eq ${company.companyId}`,
                ],
              })
            }
          />
        ),
        isVisible: () => true,
      },
      {
        key: 'Disclaimer',
        title: 'Disclaimer',
        component: <ProfileDisclaimerTab disclaimer={DISCLAIMER} />,
        isVisible: () => true,
      },
    ]

    return (
      <ProfileModal
        visible={visible}
        header={
          <ProfileHeaderWithButtons
            name={company.contactName}
            image={company.imageUrl}
            position={company.investorQualification}
            state={company.state}
            country={company.country}
            companyName={company.companyName}
            onSendRequest={sendMeetingRequest}
            visible={visible}
            canSendMessage={false}
            canSendMeetingRequest={canSendMeetingRequest}
            hideActions={false}
            customMeetingButton={
              <SendMeetingRequestToAllocatorButton
                canSendMeetingRequest={canSendMeetingRequest}
                canSPSendMeetingRequest={canSPSendMeetingRequest}
                pastSummit={pastSummit}
                company={company}
                isMeetingRequestButtonEnabled={
                  !isNil(eventId) || meetingRequestValidation.isValid
                }
                notValidReason={meetingRequestValidation.reason}
                onSendMeetingRequest={sendMeetingRequest}
              />
            }
          />
        }
        body={
          <Loading spinning={isQuestionnaireResponsesLoading}>
            <ProfileBodyWithTabs
              tabs={filter(profileTabs, (pt) =>
                pt.isVisible({ ...company, applicationQuestionnaireResponses })
              )}
              activeTabKey={
                isEmpty(applicationQuestionnaireResponses) ? null : 'Responses'
              }
            />
          </Loading>
        }
        onClose={cancel}
      />
    )
  }
}

AllocatorDetailsModal.propTypes = {
  company: PropTypes.object,
  cancel: PropTypes.func,
  rescheduleMeeting: PropTypes.func,
  acceptMeeting: PropTypes.func,
  sendMessage: PropTypes.func,
  sendMeetingRequest: PropTypes.func,
  width: PropTypes.number,
  visible: PropTypes.bool,
  pastSummit: PropTypes.bool,
  eventId: PropTypes.number,
  onRefresh: PropTypes.func,
  canSendMessage: PropTypes.bool,
  canSendMeetingRequest: PropTypes.bool,
  includeRoadshowMeetings: PropTypes.bool,
  campaignApplicationId: PropTypes.number,
}

export default AllocatorDetailsModal
