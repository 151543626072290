import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import isArray from 'lodash/isArray'
import map from 'lodash/map'

const FilterTag = ({ name, value }) => (
  <span className="mx-1">
    <Badge
      style={{
        backgroundColor: 'rgba(47, 197, 210, 0.1)',
      }}
      className="mx-0.5"
    >
      {name}
    </Badge>
    {isArray(value) ? (
      <span className="">
        {map(value, (v) => (
          <Badge className="pointer-events-none mx-0.5" key={v}>{`${v}`}</Badge>
        ))}
      </span>
    ) : (
      <Badge className="pointer-events-none mx-0.5">{value}</Badge>
    )}
  </span>
)

FilterTag.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.node,
}

export default FilterTag
