export const TIER_CLAIM = 'tier'

export const Free = 'Free'
export const Starter = 'Starter' //TODO: Delete this once we launch the new tiers
export const Pro = 'Pro'
export const Enterprise = 'Enterprise'

const Tiers = {
  Free,
  Pro,
  Enterprise,
}

export default Tiers
