import PropTypes from 'prop-types'
import { Avatar, Button, Dropdown, Menu, Popover } from 'antd'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import xor from 'lodash/xor'
import { nameToColor } from '~/utils'
import './ProfilePictureGroupNamed.less'

const ProfilePictureGroupNamed = ({
  profiles,
  maxImages = 5,
  diameter = 32,
  style,
  showImageOnPopover = true,
}) => {
  const slicedImages = profiles.length > parseInt(maxImages, 10)

  const shownNames = slicedImages ? profiles.slice(0, maxImages) : profiles
  const moreNames = slicedImages ? xor(profiles, shownNames) : []

  let indexCtr = 0
  const avatarList = (
    <Menu>
      {map(moreNames, (x) => (
        <Menu.Item key={x.name}>
          <Popover
            key={x.name}
            overlayClassName={`ProfilePictureGroup-dropdown-popover ProfilePictureGroup-dropdown-popover-${
              (isEmpty(x.profilePicture) && isEmpty(x.image)) ||
              !showImageOnPopover
                ? 'no-'
                : '-'
            }image`}
            content={
              (!isEmpty(x.profilePicture) || !isEmpty(x.image)) &&
              showImageOnPopover && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    style={{ borderRadius: '50px' }}
                    src={
                      isEmpty(x.profilePicture)
                        ? x.image
                        : x.profilePicture.image
                    }
                    alt=""
                    width="100px"
                    height="100px"
                  />
                </div>
              )
            }
          >
            <Avatar
              src={isEmpty(x.profilePicture) ? x.image : x.profilePicture.image}
              style={{
                backgroundColor: nameToColor(x.firstName, x.lastName),
                fontSize: '14px',
              }}
              shape="circle"
              size={diameter}
              className="dropdown-avatar"
            >
              {x.firstName[0] + x.lastName[0]}
            </Avatar>
          </Popover>
          {x.name}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <div style={style}>
      {shownNames.map((x) => (
        <Popover
          title={x.name}
          key={x.name}
          overlayClassName={`ProfilePictureGroup-popover ProfilePictureGroup-popover-${
            (isEmpty(x.profilePicture) && isEmpty(x.image)) ||
            !showImageOnPopover
              ? 'no-'
              : '-'
          }image`}
          content={
            (!isEmpty(x.profilePicture) || !isEmpty(x.image)) &&
            showImageOnPopover && (
              <div style={{ textAlign: 'center' }}>
                <img
                  style={{ borderRadius: '50px' }}
                  src={
                    isEmpty(x.profilePicture) ? x.image : x.profilePicture.image
                  }
                  alt=""
                  width="100px"
                  height="100px"
                />
              </div>
            )
          }
        >
          <div>
            <Avatar
              src={isEmpty(x.profilePicture) ? x.image : x.profilePicture.image}
              style={{
                marginLeft: '0',
                position: 'relative',
                zIndex: 150 + indexCtr++,
                display: 'inline-block',
                backgroundColor: nameToColor(x.firstName, x.lastName),
                fontSize: '13px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
              shape="circle"
              size={diameter}
              className="round-profile-thumbnail-group"
            >
              {`${x.firstName[0]} ${x.lastName[0]}`}
            </Avatar>
            <span className="round-profile-imagegroup-name">{x.name}</span>
          </div>
        </Popover>
      ))}

      {slicedImages && (
        <div>
          <Dropdown overlay={avatarList} style={{ textAlign: 'right' }}>
            <Button
              type="link"
              className="cc-small-font"
              onClick={(e) => e.preventDefault()}
            >
              +{profiles.length - parseInt(maxImages, 10)} More
            </Button>
          </Dropdown>
        </div>
      )}
    </div>
  )
}

ProfilePictureGroupNamed.propTypes = {
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    })
  ).isRequired,
  maxImages: PropTypes.number,
  diameter: PropTypes.number,
  style: PropTypes.object,
  showImageOnPopover: PropTypes.bool,
}

export default ProfilePictureGroupNamed
