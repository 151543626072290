import PropTypes from 'prop-types'
import '../Fields.less'

const StaticText = ({ question }) =>
  question.text && <div className="Text">{question.text}</div>

StaticText.propTypes = {
  question: PropTypes.object,
}

export default StaticText
