import * as React from 'react'
import { timeParse } from 'd3-time-format'
import isNil from 'lodash/isNil'
import * as api from '~/api'
import { chart } from '~/constants/colors'

const parseDate = timeParse('%Y-%m')

function createDataSet(label, dataPoints, fill) {
  return {
    label,
    fill,
    data: dataPoints.map(({ x, y }) => ({ x: parseDate(x), y, label, fill })),
  }
}

function getLabel(index, dataLabel, benchmarkLabel) {
  switch (index) {
    case 1:
      return 'S&P500'
    case 2:
      return benchmarkLabel
    default:
      return dataLabel
  }
}

function getFill(index) {
  switch (index) {
    case 0:
      return chart.vami
    case 1:
      return chart.sAndP
    case 2:
      return chart.benchmark
    default:
      return ''
  }
}

export default function useDiscoverFundData(fundId, indexFund) {
  const [data, setData] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)

  React.useEffect(() => {
    if (!fundId) {
      return
    }

    setData(null)
    setLoading(true)
    setHasError(false)

    api.fundCharts
      .logInIfNeeded()
      .then(() => {
        return api.fundCharts
          .fetchCustomBenchmarkId(fundId)
          .then((customBenchmarkResponse) => {
            const customBenchmark = customBenchmarkResponse.data.result
            return {
              name: customBenchmark?.benchmarkName,
              id:
                isNil(customBenchmark?.benchmarkId) ||
                customBenchmark.benchmarkId === 0
                  ? ''
                  : customBenchmark.benchmarkId,
            }
          })
          .catch(() => ({ id: '' }))
          .then((customBenchmark) => {
            return api.fundCharts
              .fetchFundVami(fundId, indexFund, customBenchmark.id)
              .then((response) => {
                const { datasets } = response.data
                setData(
                  datasets.map(({ label, data }, i) =>
                    createDataSet(
                      getLabel(i, label, customBenchmark.name),
                      data,
                      getFill(i)
                    )
                  )
                )
                return null
              })
          })
          .finally(() => {
            setLoading(false)
            return null
          })
      })
      .catch(() => {
        setHasError(true)
      })
  }, [fundId, indexFund])

  return { data, loading, hasError }
}
