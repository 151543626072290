import PropTypes from 'prop-types'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import isNil from 'lodash/isNil'
import numeral from 'numeral'
import '~/styles/index.less'
import './PortfolioFundDetailsHeader.less'

const renderCurrency = (value) => {
  if (!isNil(value) && value > 0) return numeral(value).format('$ 0,0.00')
}
const renderChange = (currentInvestment, initialInvestment) => {
  const change = currentInvestment - initialInvestment
  const changePercentage = change / initialInvestment
  return (
    <>
      <span className="cc-body-text cc-lightblack-text">
        {numeral(change).format('$ 0,0.00 ')}
      </span>
      <span
        className={` cc-body-text
        ${change > 0 ? 'cc-text-color-success' : 'cc-text-color-danger'}
      `}
      >
        {numeral(changePercentage).format(' (0.0%)')}
      </span>
    </>
  )
}
const PortfolioFundDetailsHeader = ({ fundData, backClick }) => (
  <div className="cc-fund-details-header">
    <Button
      className="cc-shadow-buttons-inner cc-backbutton-meetingdetails-online:hover"
      style={{
        height: 'auto',
        padding: '14px 16px',
        marginRight: '15px',
        borderStyle: 'none',
      }}
      onClick={backClick}
    >
      <FontAwesomeIcon
        style={{ verticalAlign: 'middle', width: '24px', height: '24px' }}
        icon={faArrowLeft}
      />
    </Button>
    <div
      style={{
        display: 'inline-flex',
        flexDirection: 'row',
        width: '95%',
        justifyContent: 'space-between',
        alignSelf: 'center',
      }}
    >
      <span className="cc-heading3" style={{ color: 'black' }}>
        {fundData.portfolioName}
      </span>
      <div className="cc-info-container">
        <div className="cc-info">
          <span className="cc-body-text cc-lightblack-text">
            {renderCurrency(fundData.investment)}
          </span>
          <span className="cc-small-font cc-grey-text">Investment</span>
        </div>
        <div className="cc-info">
          <span className="cc-body-text cc-lightblack-text">
            {renderCurrency(fundData.currentValue)}
          </span>
          <span className="cc-small-font cc-grey-text">Current Value</span>
        </div>
        <div className="cc-info">
          <span>
            {renderChange(fundData.currentValue, fundData.investment)}
          </span>
          <span className="cc-small-font cc-grey-text">Change</span>
        </div>
      </div>
    </div>
  </div>
)

PortfolioFundDetailsHeader.propTypes = {
  fundData: PropTypes.shape({
    portfolioName: PropTypes.string,
    investment: PropTypes.number,
    currentValue: PropTypes.number,
  }).isRequired,
  backClick: PropTypes.func,
}

export default PortfolioFundDetailsHeader
