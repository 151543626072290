import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Input, TextArea } from '@context365/forms'
import { RemoveCircleOutline } from '@context365/icons'

const TestimonialField = ({
  value,
  onChange,
  showAdd,
  addItem,
  showRemove,
  removeItem,
  errors,
  listNumber,
}) => {
  const handleChange = (field, val) => {
    onChange({ ...value, [field]: val })
  }
  return (
    <>
      <div className="mb-8">
        <div className="flex justify-between">
          <div className="type-body-semibold-md">Testimonial #{listNumber}</div>
          {showRemove && (
            <div className="hidden md:flex">
              <Button
                variant="outlined"
                status="error"
                iconLeft={<RemoveCircleOutline />}
                onClick={() => removeItem()}
              >
                Remove Testimonial
              </Button>
            </div>
          )}
        </div>
        <div className="md:grid md:grid-cols-4 md:gap-4">
          <div>
            <Input
              errorMessage={errors?.authorName}
              required
              label="Full Name"
              name="authorName"
              value={value.authorName}
              onChange={(e) => handleChange('authorName', e.target.value)}
            />
          </div>
          <div>
            <Input
              errorMessage={errors?.authorCompany}
              required
              label="Company"
              name="authorCompany"
              value={value.authorCompany}
              onChange={(e) => handleChange('authorCompany', e.target.value)}
            />
          </div>
          <div>
            <Input
              errorMessage={errors?.authorTitle}
              required
              label="Job Position"
              name="authorTitle"
              value={value.authorTitle}
              onChange={(e) => handleChange('authorTitle', e.target.value)}
            />
          </div>

          <div className="col-start-1">
            <Input
              errorMessage={errors?.title}
              required
              label="Testimonial Title"
              name="title"
              value={value.title}
              onChange={(e) => handleChange('title', e.target.value)}
            />
          </div>
        </div>
        <div>
          <TextArea
            rows={5}
            errorMessage={errors?.description}
            required
            label="Testimonial Body"
            name="title"
            value={value.description}
            onChange={(e) => handleChange('description', e.target.value)}
          />
        </div>
      </div>
      {showRemove && (
        <div className="md:hidden mb-8">
          <Button
            className="w-full"
            iconLeft={<RemoveCircleOutline />}
            variant="outlined"
            status="error"
            onClick={() => removeItem()}
          >
            Remove Testimomial
          </Button>
        </div>
      )}
      {showAdd && (
        <div>
          <Button variant="link" onClick={() => addItem()}>
            + Add Testimomial
          </Button>
        </div>
      )}
    </>
  )
}

TestimonialField.propTypes = {
  value: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  showAdd: PropTypes.bool,
  showRemove: PropTypes.bool,
  addItem: PropTypes.func,
  removeItem: PropTypes.func,
  listNumber: PropTypes.number,
}
export default TestimonialField
