import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import { AttachFile, Send } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import { Input, Upload, message as prompt } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { useTracking } from 'react-tracking'

const MessageActions = ({
  messagingDisabled = false,
  blockReason = '',
  loading = false,
  onSendMessage,
}) => {
  const { trackEvent } = useTracking()
  const [message, setMessage] = useState('')

  const sendMessage = useCallback(() => {
    if (isEmpty(message?.trim())) return
    trackEvent({ eventName: 'click', element: 'SendMessageFromConversation' })
    onSendMessage(message.trim())
    setMessage('')
  }, [message, onSendMessage])

  const getBase64 = (file, callback) => {
    const reader = new FileReader()
    reader.onload = () => callback(reader.result)
    reader.readAsDataURL(file)
  }

  const onFileUploaded = (e) => {
    if (e.file.status !== 'done') return
    trackEvent({ eventName: 'click', element: 'UploadPdfMessage' })
    getBase64(e.file.originFileObj, (fileUrl) => {
      onSendMessage(fileUrl, e.file.name, true)
    })
  }

  return (
    <div className="flex items-end space-x-2 bg-white px-8 pb-4">
      <div>
        <Upload
          disabled={messagingDisabled}
          accept=".pdf,application/pdf"
          multiple={false}
          className="messaging-chat-attachment-upload"
          customRequest={(e) => {
            e.onSuccess('ok')
          }}
          onChange={onFileUploaded}
          beforeUpload={(e) => {
            if (e.type !== 'application/pdf') {
              prompt.error('Can only send PDF files')
              return false
            }

            if (e.size > 100 * 1024 * 1024) {
              prompt.error('Cannot upload files over 100MB')
              return false
            }

            return true
          }}
        >
          <Tooltip text="Send document (PDF)">
            <IconButton
              label="Attach File"
              disabled={messagingDisabled}
              icon={<AttachFile />}
            />
          </Tooltip>
        </Upload>
      </div>
      <div className="messaging-chat-message-input">
        <Input.TextArea
          autoSize={{ minRows: 3, maxRows: 3 }}
          disabled={messagingDisabled}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={messagingDisabled ? blockReason : 'Type a message'}
        />
      </div>
      <div className="flex-col space-y-1">
        <Button
          variant="filled"
          onClick={sendMessage}
          disabled={messagingDisabled || loading}
          iconLeft={<Send className="flex-shrink-0" />}
        >
          Send
        </Button>
      </div>
    </div>
  )
}

MessageActions.propTypes = {
  messagingDisabled: PropTypes.bool,
  blockReason: PropTypes.string,
  loading: PropTypes.bool,
  onSendMessage: PropTypes.func.isRequired,
}

export default MessageActions
