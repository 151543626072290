import PropTypes from 'prop-types'
import { VisibilityOff } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import { DiscoverBadges } from '../Discover'

const getSelectedBadges = (researchCampaign) =>
  researchCampaign.campaignApplications?.length > 0
    ? [
        {
          name: 'In Pipeline',
          className: 'bg-green-100 text-white uppercase',
        },
      ]
    : []

const DiscoverResearchCampaignResultHeader = ({ researchCampaign }) => (
  <div>
    <DiscoverBadges badges={getSelectedBadges(researchCampaign)} />
    <div className="type-body-regular-md">{researchCampaign.campaignName}</div>
    {researchCampaign.isAnonymous && (
      <Tooltip
        placement="left-start"
        text="The research campaign provider posted this anonymously. Apply to see if you’re a fit."
      >
        <div className="flex flex-row space-x-2 items-center pt-1">
          <VisibilityOff size="small" />
          <span className="type-body-regular-xs">Anonymous</span>
        </div>
      </Tooltip>
    )}
    {!researchCampaign.isAnonymous && (
      <div className="type-body-regular-sm">{researchCampaign.companyName}</div>
    )}
  </div>
)

DiscoverResearchCampaignResultHeader.propTypes = {
  researchCampaign: PropTypes.object.isRequired,
}

export default DiscoverResearchCampaignResultHeader
