import PropTypes from 'prop-types'
import { MailOutlined } from '@context365/icons'
import { Button, List, Tooltip } from 'antd'
import { isEmpty } from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../Loading'
import MeetingCard from '../MeetingCard'
import NoMeetingsView from '../NoRequestsView/NoMeetingsView'

const MeetingsCardView = ({
  meetingList,
  handleInfiniteOnLoad,
  isSearching,
  hasMore,
  renderRatings,
  renderCalendarInvitation,
  renderActions,
  type,
  loading,
}) => {
  const Card = (record) =>
    type === 'Confirmed' || type === 'Declined' ? (
      <MeetingCard
        record={record}
        renderInfo={renderRatings}
        renderCalendarInvitation={renderCalendarInvitation}
        renderActions={renderActions}
      />
    ) : type === 'Received' ? (
      <MeetingCard
        record={record}
        renderActions={renderActions}
        isReceived={true}
        renderInfo={(record) => (
          <Tooltip
            overlayClassName="MessageTooltip"
            title={
              isEmpty(record.meetingMessage)
                ? 'No message'
                : record.meetingMessage
            }
          >
            <MailOutlined
              size={32}
              className={
                isEmpty(record.meetingMessage)
                  ? undefined
                  : 'text-secondary-100'
              }
            />
          </Tooltip>
        )}
      />
    ) : type === 'Sent' ? (
      <MeetingCard
        record={record}
        renderActions={renderActions}
        renderInfo={null}
      />
    ) : null

  return (
    <div>
      {isEmpty(meetingList) ? (
        <NoMeetingsView meetingType={type} isSearching={isSearching} />
      ) : (
        <InfiniteScroll
          loader={
            loading ? (
              <Loading
                spinning={hasMore}
                style={{
                  bottom: '5px',
                  width: '100%',
                  textAlign: 'center',
                }}
              />
            ) : (
              <div className="discover-load-more">
                <Button
                  className="discover-load-more-button"
                  onClick={() => handleInfiniteOnLoad()}
                >
                  Load More +
                </Button>
              </div>
            )
          }
          hasMore={hasMore}
          next={() => handleInfiniteOnLoad()}
          dataLength={meetingList.length}
          scrollThreshold="80px"
          children={
            <List
              dataSource={Object.values(meetingList)}
              renderItem={(record) => Card(record)}
            />
          }
        />
      )}
    </div>
  )
}

MeetingsCardView.propTypes = {
  meetingList: PropTypes.array.isRequired,
  handleInfiniteOnLoad: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  hasMore: PropTypes.bool.isRequired,
  renderRatings: PropTypes.func,
  renderCalendarInvitation: PropTypes.func,
  renderActions: PropTypes.func,
  type: PropTypes.string,
  loading: PropTypes.bool,
}

export default MeetingsCardView
