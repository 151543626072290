import { Button } from '@context365/button'
import * as Yup from 'yup'
import {
  CheckboxGroupField,
  Form,
  UnsavedChangesAlert,
} from '~/components/Form'
import { ArrayFormField } from '~/components/Form/Fields/ArrayFormField'
import DealField from '../fields/DealField'
import HoldingField from '../fields/HoldingField'
import PartnerField from '../fields/PartnerField'
import { useOnboardingContext } from '../OnboardingContext'

const schema = Yup.object().shape({
  fundAttributeIds: Yup.array().of(Yup.number().required()),
  vehicleIds: Yup.array().of(Yup.number().required()).min(1).required(),
  industryIds: Yup.array().of(Yup.number().required()).min(1).required(),
  geographyIds: Yup.array().of(Yup.number().required()).min(1).required(),
  verticalIds: Yup.array().of(Yup.number().required()).min(1).required(),
  investments: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      securityTypeId: Yup.number().required(),
      investmentDate: Yup.date().required(),
      investmentStatusId: Yup.number().required(),
    })
  ),
  deals: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      securityTypeId: Yup.number().required(),
      investmentDate: Yup.date().required(),
      dealLeadStatusId: Yup.number().required(),
    })
  ),
  partners: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      investorCategoryId: Yup.number().required(),
    })
  ),
})

export default function ManagerCompanyInterestsForm({
  savedAnswers,
  onSubmit,
  ...formProps
}) {
  const { options } = useOnboardingContext()

  return (
    <Form
      className="onboarding-form"
      schema={schema}
      defaultValues={savedAnswers}
      onSubmit={onSubmit}
      {...formProps}
    >
      <CheckboxGroupField
        name="fundAttributeIds"
        label="Fund Attributes"
        options={options.fundAttributes}
      />
      <CheckboxGroupField
        name="vehicleIds"
        label="Investment Vehicle Interests"
        options={options.vehicles}
      />
      <CheckboxGroupField
        name="industryIds"
        label="Sector Interests"
        options={options.industries}
      />
      <CheckboxGroupField
        name="geographyIds"
        label="Geography Interests"
        options={options.geographies}
      />
      <CheckboxGroupField
        name="verticalIds"
        label="Vertical Interests"
        options={options.verticals}
      />
      <ArrayFormField
        name="investments"
        label="Top Holdings"
        element={<HoldingField />}
        addButton={
          <Button variant="link" className="mt-2">
            + Add Holding
          </Button>
        }
      />
      <ArrayFormField
        name="deals"
        label="Deals"
        element={<DealField />}
        addButton={
          <Button variant="link" className="mt-2">
            + Add Deal
          </Button>
        }
      />
      <ArrayFormField
        name="partners"
        label="Common Investment Partners"
        element={<PartnerField />}
        addButton={
          <Button variant="link" className="mt-2">
            + Add Partner
          </Button>
        }
      />
      <UnsavedChangesAlert />
    </Form>
  )
}
