import PropTypes from 'prop-types'
import { Button } from '@context365/button'

const NextButton = ({ isLast, onNext, disabled }) => (
  <Button
    disabled={disabled}
    onClick={() => onNext()}
    variant="filled"
    className="mt-5"
  >
    {isLast ? 'Finish' : 'Next'}
  </Button>
)

NextButton.propTypes = {
  onNext: PropTypes.func,
  isLast: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default NextButton
