import PropTypes from 'prop-types'
import { Avatar, AvatarGroup } from '@context365/avatar'
import { useTheme } from '@context365/config'
import { MoreHoriz } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import cx from 'classnames'
import take from 'lodash/take'
import uniqBy from 'lodash/uniqBy'
import { NavLink, useLocation } from 'react-router-dom-v5-compat'
import { useTracking } from 'react-tracking'
import { getInitials } from '~/utils'
import { useMessagesContext } from './context'
import { format, parseDate } from './utils/dateFormatters'

export default function ConversationListItem({
  className,
  campaigns,
  conversationId,
  contacts,
  title,
  lastMessage,
  lastActivityDate,
  hasUnreadMessages,
}) {
  const { trackEvent } = useTracking()
  const { baseUrl } = useMessagesContext()
  const location = useLocation()
  const firstCampaignName = campaigns?.[0]?.campaignName

  return (
    <NavLink
      className={({ isActive }) =>
        cx(
          className,
          'py-3 pl-1 pr-3 h-28 flex items-center border-l-4 transition-colors',
          isActive
            ? 'border-secondary-100 bg-secondary-5'
            : 'border-transparent hover:bg-secondary-2'
        )
      }
      to={{ ...location, pathname: `${baseUrl}/${conversationId}` }}
      onClick={() => {
        trackEvent({
          eventName: 'click',
          element: 'conversation',
          conversationId,
        })
      }}
    >
      <div
        className={cx(
          'w-3 h-3 rounded-full bg-secondary-100 mr-2 flex-shrink-0',
          !hasUnreadMessages && 'invisible'
        )}
      />
      <ConversationListAvatarGroup contacts={contacts} />
      <div className="flex-1 min-w-0">
        <div className="type-body-semibold-md">{title}</div>
        {lastMessage && (
          <div className="type-body-regular-sm truncate">{lastMessage}</div>
        )}
        {lastActivityDate && (
          <div className="type-body-regular-xs text-grey-600 mt-1">
            {format.lastActivityDateTime(parseDate.fromUTC(lastActivityDate))}
          </div>
        )}
        {firstCampaignName && (
          <div className="flex items-center mt-2">
            <span className="type-subtitle-xs text-grey-600 mr-1">
              {firstCampaignName}
            </span>
            {campaigns?.length > 1 && (
              <Tooltip
                placement="bottom-start"
                text={campaigns.slice(1).map((campaign) => (
                  <div key={campaign.campaignId}>{campaign.campaignName}</div>
                ))}
              >
                <MoreHoriz size={18} className="text-secondary-100" />
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </NavLink>
  )
}

ConversationListItem.propTypes = {
  conversationId: PropTypes.number.isRequired,
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      campaignId: PropTypes.number,
      campaignName: PropTypes.string,
    })
  ),
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.number.isRequired,
      contactName: PropTypes.string.isRequired,
      imageUrl: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  lastMessage: PropTypes.string,
  lastActivityDate: PropTypes.string,
  hasUnreadMessages: PropTypes.bool.isRequired,
}

function ConversationListAvatarGroup({ contacts }) {
  const avatarSpacing = useTheme(
    'avatar.avatarGroup.size.small.layered.true.spacing'
  )

  const uniqueContacts = uniqBy(contacts, 'contactId')

  if (uniqueContacts.length === 1) {
    const [{ contactName, imageUrl }] = uniqueContacts
    return (
      <Avatar
        className="mr-2 flex-shrink-0"
        initials={getInitials(contactName)}
        profileImageUrl={imageUrl}
        size="big"
      />
    )
  }

  const contactsForGroup =
    uniqueContacts.length <= 4 ? uniqueContacts : take(uniqueContacts, 3)
  const overflowCount = uniqueContacts.length - contactsForGroup.length

  return (
    <AvatarGroup
      className="mr-2 flex flex-wrap w-12 flex-shrink-0"
      size="small"
      isLayered
    >
      {contactsForGroup.map(({ contactId, contactName, imageUrl }, i) => (
        <Avatar
          style={{
            marginLeft: i % 2 === 0 ? 0 : undefined,
            marginTop: i > 1 ? avatarSpacing : undefined,
          }}
          key={contactId}
          initials={getInitials(contactName)}
          profileImageUrl={imageUrl}
        />
      ))}
      {overflowCount > 0 && (
        <Avatar
          style={{ marginTop: avatarSpacing }}
          initials={`+${overflowCount}`}
        />
      )}
    </AvatarGroup>
  )
}

ConversationListAvatarGroup.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.number.isRequired,
      contactName: PropTypes.string.isRequired,
      imageUrl: PropTypes.string,
    })
  ).isRequired,
}

function Loading() {
  return (
    <div className="py-3 pl-2 pr-4 h-24 flex items-center text-grey-300">
      <div className="w-3 mr-2 flex-shrink-0" />
      <div className="w-12 h-12 mr-2 rounded-full bg-current flex-shrink-0" />
      <div className="flex-1 min-w-0 space-y-2">
        <div className="h-5 rounded-full bg-current" />
        <div className="h-2 rounded-full bg-current" />
        <div className="h-2 w-24 rounded-full bg-current" />
      </div>
    </div>
  )
}

ConversationListItem.Loading = Loading
