import { NavButton, NavList } from '@context365/tabs'
import { useSelector } from 'react-redux'
import { useLocation, useMatch } from 'react-router-dom-v5-compat'
import MessagesView from '~/components/Messages_V2'
import useMinScreen from '~/hooks/useMinScreen'

export default function Messages() {
  const isConversationList = useMatch('/messages/:conversationId') === null
  const location = useLocation()
  const archive = location.search.includes('archive')
  const twilioClient = useSelector((state) => state.messaging.twilioClient)

  const isDesktop = useMinScreen('md')

  return (
    <>
      {(isDesktop || isConversationList) && (
        <div className="pt-2 pb-4 px-4 md:pt-0 md:px-0">
          <NavList variant="underlined" label="Messages">
            <NavButton
              to="/messages"
              isActive={(_, location) => !location.search.includes('archive')}
            >
              Conversations
            </NavButton>
            <NavButton
              to="/messages?archive=true"
              isActive={(_, location) => location.search.includes('archive')}
            >
              Archive
            </NavButton>
          </NavList>
        </div>
      )}
      <MessagesView
        baseUrl="/messages"
        twilioClient={twilioClient}
        archive={archive}
      />
    </>
  )
}
