import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { report } from '~/utils/errorReporting'

const TestMode = {
  Ready: 'ready',
  Recording: 'recording',
  Playback: 'playback',
  Finished: 'finished',
  Error: 'error',
}

const MAX_TEST_RECORDING_LENGTH_MILLISECONDS = 5 * 1000

export default function AudioDeviceTest({ inputId, outputId }) {
  const [testMode, setTestMode] = React.useState(TestMode.Ready)
  const inputMedia = React.useRef()
  const recorder = React.useRef()
  const audioUrl = React.useRef()

  React.useEffect(() => {
    // If they change their devices, clear the error and try again.
    setTestMode((testMode) =>
      testMode === TestMode.Error ? TestMode.Ready : testMode
    )
  }, [inputId, outputId])

  React.useEffect(() => {
    const audioConstraints = inputId ? { deviceId: inputId } : true
    navigator.mediaDevices
      .getUserMedia({ audio: audioConstraints })
      .then((media) => {
        inputMedia.current = media
      })

    return () => {
      const media = inputMedia.current
      if (!media) {
        return
      }

      media.getTracks().forEach((track) => {
        track.stop()
      })
      inputMedia.current = undefined
    }
  }, [inputId])

  React.useEffect(() => {
    if (testMode !== TestMode.Recording || !inputMedia.current) {
      return undefined
    }

    const mediaRecorder = new MediaRecorder(inputMedia.current)
    recorder.current = mediaRecorder
    mediaRecorder.start()

    const audioChunks = []
    mediaRecorder.addEventListener('dataavailable', (event) => {
      audioChunks.push(event.data)
    })

    mediaRecorder.addEventListener('stop', () => {
      recorder.current = undefined
      const audioBlob = new Blob(audioChunks)
      audioUrl.current = URL.createObjectURL(audioBlob)
      setTestMode(TestMode.Playback)
    })

    const timer = setTimeout(() => {
      mediaRecorder.stop()
    }, MAX_TEST_RECORDING_LENGTH_MILLISECONDS)

    return () => {
      clearTimeout(timer)
      if (mediaRecorder.state !== 'recording') {
        return
      }
      mediaRecorder.stop()
    }
  }, [testMode])

  React.useEffect(() => {
    if (testMode !== TestMode.Playback || !audioUrl.current) {
      return undefined
    }

    const audio = new Audio(audioUrl.current)
    if (outputId) {
      audio.setSinkId(outputId)
    }
    audio.addEventListener('ended', () => {
      setTestMode(TestMode.Finished)
    })
    audio.play().catch((err) => {
      report(err)
      setTestMode(TestMode.Error)
    })

    return () => {
      audio.pause()
      audioUrl.current = undefined
    }
  }, [testMode, outputId])

  function changeTestMode() {
    switch (testMode) {
      case TestMode.Ready:
      case TestMode.Finished:
        setTestMode(TestMode.Recording)
        break
      case TestMode.Recording:
        recorder.current?.stop()
        break
      default:
        setTestMode(TestMode.Ready)
    }
  }

  return (
    <>
      <div className="cc-videoSettings-header">Test Speaker and Microphone</div>
      <div className="mt-3 flex flex-col space-y-1">
        {testMode !== TestMode.Error && (
          <Button onClick={changeTestMode}>{getButtonLabel(testMode)}</Button>
        )}
        <span>{getHelpText(testMode)}</span>
      </div>
    </>
  )
}

AudioDeviceTest.propTypes = {
  inputId: PropTypes.string,
  outputId: PropTypes.string,
}

function getButtonLabel(testMode) {
  switch (testMode) {
    case TestMode.Ready:
      return 'Begin Test'
    case TestMode.Recording:
      return 'Stop Recording'
    case TestMode.Finished:
      return 'Test Again'
    default:
      return 'Reset'
  }
}

function getHelpText(testMode) {
  switch (testMode) {
    case TestMode.Recording:
      return 'Speak into your microphone.'
    case TestMode.Playback:
      return 'How does it sound?'
    case TestMode.Finished:
      return "If everything sounded good, you're all set. Otherwise, check your devices or select a different device from the menu above."
    case TestMode.Error:
      return 'An error occurred during the audio test. Please check your devices or select a different device from the menu above.'
    default:
      return ''
  }
}
