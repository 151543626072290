import { useCallback, useEffect, useState } from 'react'
import { Avatar, AvatarGroup } from '@context365/avatar'
import { Button } from '@context365/button'
import { EmailOutlined } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import { Image } from 'antd'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import pick from 'lodash/pick'
import { useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom-v5-compat'
import { useTracking } from 'react-tracking'
import { logProfileView } from '~/actions/company'
import { fetchMeetingsForProfile } from '~/actions/meetings'
import * as api from '~/api'
import { ApplyToCampaignButton } from '~/components/Application'
import { FundSubscriptionModal } from '~/components/DiscoverFundResult'
import FundAttachments from '~/components/FundAttachments'
import FundCharts from '~/components/FundCharts'
import FundDetailsManagers from '~/components/FundDetailsManagers'
import FundApplicationFeedback from '~/components/FundDetailsModal/FundApplicationFeedback'
import { DISCLAIMER } from '~/components/FundDetailsModal/FundDetailsModal'
import profileToAnswers from '~/components/FundDetailsModal/profileToAnswers'
import { FundDetailsProfile } from '~/components/FundDetailsProfile'
import FundDetailsStaticValues from '~/components/FundDetailsValues/FundDetailsStaticValues'
import FundMedia from '~/components/FundMedia'
import FundPreviewList from '~/components/FundPreviewList'
import IlliquidProfileFirm from '~/components/IlliquidProfileFirm/IlliquidProfileFirm'
import IlliquidProfileOverview from '~/components/IlliquidProfileOverview/IlliquidProfileOverview'
import Loading from '~/components/Loading'
import MandateQuestionnaireResponses from '~/components/MandateQuestionnaireResponses'
import Profile from '~/components/Profile'
import ProfileDisclaimerTab from '~/components/ProfileDisclaimerTab'
import UpcomingMeetingRequests from '~/components/UpcomingMeetingRequests'
import { SP500_FUND_INDEX_ID } from '~/constants/indexFunds'
import useSearchParams from '~/hooks/useSearchParams'
import { getApplyToCampaignModal } from '~/store/modals'

export const LiquidTabs = [
  {
    content: MandateQuestionnaireResponses,
    label: 'Responses',
    id: 'responses',
    getProps: (data) => {
      return {
        questionnaireResponses: get(
          data,
          'applicationQuestionnaireResponses',
          []
        ),
      }
    },
    visible: (data) => !isEmpty(data.applicationQuestionnaireResponses),
  },
  {
    content: FundApplicationFeedback,
    label: 'Feedback',
    id: 'feedback',
    getProps: (data) =>
      pick(data, [
        'applicationFeedbackResponses',
        'applicationFeedbackMessage',
      ]),
    visible: (data) => data.applicationFeedbackResponses,
  },
  {
    content: FundDetailsProfile,
    label: 'Fund Profile',
    id: 'profile',
    getProps: (data) => pick(data, ['fundProfile']),
    visible: () => true,
  },
  {
    content: UpcomingMeetingRequests,
    label: 'Meetings',
    id: 'meetings',
    getProps: (data) =>
      pick(data, ['onRefresh', 'eventId', 'meetings', 'loadingMeetings']),
    visible: (data) => !data.hideActions && !!data.eventId,
  },
  {
    content: FundDetailsStaticValues,
    label: 'Terms',
    id: 'terms',
    getProps: (data) => pick(data, ['answers']),
    visible: () => true,
  },
  {
    content: FundDetailsManagers,
    label: 'Team Bios',
    id: 'firm',
    getProps: (data) => pick(data, ['managerBio', 'loading']),
    visible: () => true,
  },
  {
    content: FundCharts,
    label: 'Performance',
    id: 'performance',
    renderMode: 'lazy',
    getProps: (data) => pick(data, ['fundId', 'indexFund']),
    visible: (data) => data.showCharts,
  },
  {
    content: FundAttachments,
    label: 'Attachments',
    id: 'attachments',
    getProps: (data) => pick(data, ['fundId', 'companyName', 'eventId']),
    visible: () => true,
  },
  {
    content: FundMedia,
    label: 'Media',
    id: 'media',
    getProps: (data) => pick(data, ['media', 'links']),
    visible: (data) => data.showMedia,
  },
  {
    content: FundPreviewList,
    label: 'Similar Funds',
    id: 'similarFunds',
    getProps: (data) => {
      return {
        getFunds: data.getSimilarFunds,
        emptyDescription: 'There are no similar funds.',
        hideMatchmakingScore: true,
      }
    },
    visible: (data) => !data.hideActions,
  },
  {
    content: FundPreviewList,
    label: 'Family of Funds',
    id: 'otherFunds',
    getProps: (data) => {
      return {
        getFunds: data.getFundsAtCompany,
        emptyDescription: 'There are no other funds at this company.',
      }
    },
    visible: (data) => !data.hideActions,
  },
  {
    content: ProfileDisclaimerTab,
    label: 'Disclaimer',
    id: 'disclaimer',
    getProps: (data) => pick(data, ['disclaimer', 'customDisclaimerHtml']),
    visible: () => true,
  },
]

export const IlliquidTabs = [
  {
    content: MandateQuestionnaireResponses,
    label: 'Responses',
    id: 'responses',
    getProps: (data) => {
      return {
        questionnaireResponses: get(
          data,
          'applicationQuestionnaireResponses',
          []
        ),
      }
    },
    visible: (data) => !isEmpty(data.applicationQuestionnaireResponses),
  },
  {
    content: FundApplicationFeedback,
    label: 'Feedback',
    id: 'feedback',
    getProps: (data) =>
      pick(data, [
        'applicationFeedbackResponses',
        'applicationFeedbackMessage',
      ]),
    visible: (data) => !isEmpty(data.applicationFeedbackResponses),
  },
  {
    content: FundDetailsProfile,
    label: 'Fund Profile',
    id: 'profile',
    getProps: (data) => pick(data, ['fundProfile']),
    visible: (data) => !data.illiquidProfileFlag,
  },
  {
    content: IlliquidProfileOverview,
    label: 'Fund Profile',
    id: 'profile',
    getProps: (data) => pick(data, ['fundProfile', 'eventId']),
    visible: (data) => data.illiquidProfileFlag,
  },
  {
    content: UpcomingMeetingRequests,
    label: 'Meetings',
    id: 'meetings',
    getProps: (data) =>
      pick(data, ['onRefresh', 'eventId', 'meetings', 'loadingMeetings']),
    visible: (data) => !data.hideActions && !!data.eventId,
  },
  {
    content: FundDetailsStaticValues,
    label: 'Terms',
    id: 'terms',
    getProps: (data) => pick(data, ['answers']),
    visible: (data) => !data.illiquidProfileFlag,
  },
  {
    content: FundDetailsManagers,
    label: 'Team Bios',
    id: 'teamBios',
    getProps: (data) => pick(data, ['managerBio', 'loading']),
    visible: (data) => !data.illiquidProfileFlag,
  },
  {
    content: IlliquidProfileFirm,
    label: 'Firm',
    id: 'firm',
    getProps: (data) => pick(data, ['fundProfile']),
    visible: (data) => data.illiquidProfileFlag,
  },
  {
    content: FundCharts,
    label: 'Performance',
    id: 'performance',
    renderMode: 'lazy',
    getProps: (data) => pick(data, ['fundId', 'indexFund']),
    visible: (data) => !data.illiquidProfileFlag && data.showCharts,
  },
  {
    content: FundAttachments,
    label: 'Attachments',
    id: 'attachments',
    getProps: (data) => pick(data, ['fundId', 'companyName', 'eventId']),
    visible: () => true,
  },
  {
    content: FundMedia,
    label: 'Media',
    id: 'media',
    getProps: (data) => pick(data, ['media', 'links']),
    visible: (data) => data.showMedia,
  },
  {
    content: FundPreviewList,
    label: 'Similar Funds',
    id: 'similarFunds',
    getProps: (data) => {
      return {
        getFunds: data.getSimilarFunds,
        emptyDescription: 'There are no similar funds.',
      }
    },
    visible: (data) => !data.hideActions,
  },
  {
    content: FundPreviewList,
    label: 'Family of Funds',
    id: 'otherFunds',
    getProps: (data) => {
      return {
        getFunds: data.getFundsAtCompany,
        emptyDescription: 'There are no other funds at this company.',
      }
    },
    visible: (data) => !data.hideActions,
  },
  {
    content: ProfileDisclaimerTab,
    label: 'Disclaimer',
    id: 'disclaimer',
    getProps: (data) => pick(data, ['disclaimer', 'customDisclaimerHtml']),
    visible: () => true,
  },
]

const FundProfile = () => {
  const { trackEvent } = useTracking({ component: 'FundProfile' })
  const { fundId } = useParams()
  const { data: fundProfile, isLoading } = useQuery(
    ['fundProfile', fundId],
    () => {
      return api.funds.getFundProfile(fundId)
    }
  )

  useQuery(
    ['fund-profile-log', fundId, fundProfile?.companyId],
    () => logProfileView({ fundId, companyId: fundProfile?.companyId }),
    { enabled: fundProfile != null }
  )

  const client = useQueryClient()
  const [searchParams, setSearchParams] = useSearchParams()
  const [refresh, setRefresh] = useState(false)
  const [meetings, setMeetings] = useState(null)
  const [loadingMeetings, setLoadingMeetings] = useState(false)
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const answers = profileToAnswers(fundProfile)
  const email = useSelector((state) => state.auth.contact?.email)

  const handleRefresh = useCallback(() => {
    setRefresh((prevRefresh) => !prevRefresh)
  }, [])

  const getSimilarFunds = useCallback(() => {
    return api.discovery.similarFunds(fundId)
  }, [fundId])

  const getFundsAtCompany = useCallback(() => {
    return api.discovery.funds({
      filter: [`companyId eq ${fundProfile.companyId}`, `fundId ne ${fundId}`],
    })
  }, [fundProfile?.companyId, fundId])

  const refreshFund = useCallback(
    () => client.invalidateQueries(['fundProfile', fundId]),
    [client, fundId]
  )

  useEffect(() => {
    if (fundProfile?.companyId && fundId) {
      setLoadingMeetings(true)
      fetchMeetingsForProfile(fundProfile?.companyId, {
        eventId: 0,
        fundId,
      })
        .then((res) => {
          setMeetings(res.data.result)
        })
        .catch(() => {})
        .finally(() => {
          setLoadingMeetings(false)
        })
    }
  }, [fundProfile?.companyId, fundId, refresh])

  const { visible } = useSelector(getApplyToCampaignModal)

  useEffect(() => {
    if (!visible) {
      refreshFund()
    }
  }, [visible, refreshFund])

  const onSubscribe = () => {
    trackEvent({
      eventName: 'click',
      element: 'Subscribe to fund',
      fundId,
    })
    setShowSubscriptionModal(false)
    refreshFund()
  }

  if (isLoading) {
    return <Loading spinning />
  }

  const isSubscribed = fundProfile.alreadySubscribed

  const data = {
    fundId: fundProfile.fundId,
    fundProfile,
    onRefresh: handleRefresh,
    meetings,
    loadingMeetings,
    answers,
    loading: isLoading,
    managerBio: fundProfile.managerBio,
    indexFund: SP500_FUND_INDEX_ID,
    companyName: fundProfile.companyName,
    showCharts: !isEmpty(fundProfile) && !fundProfile.narrative,
    media: fundProfile.media,
    links: fundProfile.links,
    showMedia: !isEmpty(fundProfile.media) || !isEmpty(fundProfile.links),
    disclaimer: DISCLAIMER,
    customDisclaimerHtml: fundProfile.customDisclaimer,
    illiquidProfileFlag: fundProfile.utilizesNewIlliquidProfile,
    getFundsAtCompany,
    getSimilarFunds,
    applicationFeedbackResponses: fundProfile.applicationFeedbackResponses,
    applicationFeedbackMessage: fundProfile.applicationFeedbackMessage,
    applicationQuestionnaireResponses:
      fundProfile.applicationQuestionnaireResponses,
  }

  const tabsForFundType = fundProfile.isLiquid ? LiquidTabs : IlliquidTabs
  const tabs = map(
    filter(tabsForFundType, (x) => x.visible(data)),
    (t) => ({
      content: <t.content {...t.getProps(data)} />,
      label: t.label,
      id: t.id,
      renderMode: t.renderMode,
    })
  )

  return (
    <>
      <Profile.Header
        title={fundProfile.fundName}
        subtitle={fundProfile.companyName}
        contacts={
          <AvatarGroup size="medium" isLayered>
            {map(fundProfile.contacts, (c) => {
              const contactInitials = `${
                c.firstName.charAt(0) + c.lastName.charAt(0)
              }`
              return (
                <Tooltip
                  title={c.contactName}
                  text={
                    <Image
                      width={80}
                      src={c.imageUrl}
                      alt="contact picture"
                      className="mt-2"
                    />
                  }
                >
                  <Avatar
                    initials={contactInitials}
                    profileImageUrl={c.imageUrl}
                  />
                </Tooltip>
              )
            })}
          </AvatarGroup>
        }
        actions={
          <>
            <Button onClick={() => window.open(`/tearsheet/${fundId}`)}>
              View Tearsheet
            </Button>
            <Tooltip
              text={
                isSubscribed
                  ? 'You are already subscribed to this fund'
                  : 'Subscribe to this fund manager’s email distribution list'
              }
            >
              <Button
                disabled={isSubscribed}
                iconLeft={<EmailOutlined />}
                onClick={() => setShowSubscriptionModal(true)}
              >
                Subscribe
              </Button>
            </Tooltip>
            {fundProfile.campaignId && (
              <ApplyToCampaignButton
                campaignEntity={{
                  campaignId: fundProfile.campaignId,
                  campaignName: fundProfile.fundName,
                  campaignApplications: fundProfile.campaignApplications,
                  campaignThreshold: fundProfile.threshold,
                }}
                campaignType="funds"
              />
            )}
          </>
        }
      />
      <Profile.Body
        tabs={tabs}
        onTabChange={(tab) => setSearchParams({ tab })}
        activeTab={
          tabs.find((tab) => tab.id === searchParams.tab)?.id ?? tabs[0].id
        }
        className="bg-white"
      />
      {showSubscriptionModal && (
        <FundSubscriptionModal
          visible={showSubscriptionModal}
          fundId={fundProfile.fundId}
          email={email}
          onCancel={() => setShowSubscriptionModal(false)}
          onSubscribe={onSubscribe}
        />
      )}
    </>
  )
}

export default FundProfile
