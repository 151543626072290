import { MoreHoriz } from '@context365/icons'
import { Menu } from '@context365/menu'
import { useTracking } from 'react-tracking'
import POLICIES from '~/constants/policies'

const PolicyBarCollapsed = () => {
  const { trackEvent } = useTracking({ element: 'PolicyBar' })
  const trackClick = (label) => trackEvent({ eventName: 'click', label })

  return (
    <Menu trigger={<MoreHoriz size={36} className="text-white" />}>
      <Menu.Item
        as="a"
        href={POLICIES.PRIVACY_POLICY}
        target="_blank"
        onClick={() => trackClick('PrivacyPolicy')}
      >
        <div className="text-xs">Privacy Policy</div>
      </Menu.Item>
      <Menu.Item
        as="a"
        href={POLICIES.TERMS_OF_USE}
        target="_blank"
        onClick={() => trackClick('TermsOfUse')}
      >
        <div className="text-xs">Terms of Use</div>
      </Menu.Item>
      <Menu.Item
        as="a"
        href={POLICIES.TERMS_AND_CONDITIONS}
        target="_blank"
        onClick={() => {
          trackClick('TermsAndConditions')
        }}
      >
        <div className="text-xs">Terms & Conditions</div>
      </Menu.Item>
      <Menu.Item
        as="a"
        href={POLICIES.SUBSCRIPTION_AGREEMENT}
        target="_blank"
        onClick={() => trackClick('SubscriptionAgreement')}
      >
        <div className="text-xs">Subscription & Service Agreement</div>
      </Menu.Item>
    </Menu>
  )
}

export default PolicyBarCollapsed
