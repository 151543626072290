import * as React from 'react'

export default function useVisibility(initialValue = false) {
  const [state, setState] = React.useState(initialValue)

  const show = React.useCallback(() => setState(true), [])
  const hide = React.useCallback(() => setState(false), [])
  const toggle = React.useCallback(() => setState((s) => !s), [])

  return React.useMemo(
    () => ({
      visible: state,
      show,
      hide,
      toggle,
    }),
    [state, show, hide, toggle]
  )
}
