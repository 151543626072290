import { useState } from 'react'
import PropTypes from 'prop-types'
import { message } from 'antd'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import isNil from 'lodash/isNil'
import * as api from '~/api'
import FundWizard from '../FundWizard'
import makeWizardSteps from '../makeWizardSteps'
import {
  BasicInfoQuestions,
  CommitmentQuestions,
  FirmInfoQuestions,
  MediaQuestions,
  PortfolioQuestions,
  PositionQuestions,
  ServiceProviderQuestions,
  TermsQuestions,
  ValuationQuestions,
} from './IlliquidStepQuestions'
import {
  BasicInfoValidator,
  CommitmentValidator,
  FirmInfoValidator,
  MediaValidator,
  PortfolioValidator,
  PositionValidator,
  ServiceProviderValidator,
  TermsValidator,
  ValuationValidator,
} from './IlliquidValidatorShape'
import '../FundWizard.less'

const STEPS = [
  {
    title: 'Basic Information',
    questions: BasicInfoQuestions,
    validator: BasicInfoValidator,
  },
  {
    title: 'Firm Information',
    questions: FirmInfoQuestions,
    validator: FirmInfoValidator,
  },
  {
    title: 'Valuation Metrics',
    questions: ValuationQuestions,
    validator: ValuationValidator,
  },
  {
    title: 'Commitments, Drawdowns, & Distributions',
    questions: CommitmentQuestions,
    validator: CommitmentValidator,
  },
  {
    title: 'Terms',
    questions: TermsQuestions,
    validator: TermsValidator,
  },
  {
    title: 'Service Providers',
    questions: ServiceProviderQuestions,
    validator: ServiceProviderValidator,
  },
  {
    title: 'Positions',
    questions: PositionQuestions,
    validator: PositionValidator,
  },
  {
    title: 'Media',
    questions: MediaQuestions,
    validator: MediaValidator,
  },
  {
    title: 'Portfolio',
    questions: PortfolioQuestions,
    validator: PortfolioValidator,
  },
]

export const IlliquidFundStep = makeWizardSteps(
  {
    BasicInfo: 'Basic Information',
    FirmInfo: 'Firm Information',
    Valuation: 'Valuation Metrics',
    Commitment: 'Commitments, Drawdowns, & Distributions',
    Terms: 'Terms',
    ServiceProvider: 'Service Providers',
    Position: 'Positions',
    Media: 'Media',
    Portfolio: 'Portfolio',
  },
  STEPS
)

const DEFAULT_VALUES = {
  fundName: '',
  strategyDescription: '',
  broadStrategyId: null,
  subStrategyId: null,
  inceptionDate: null,
  vintageInceptionDate: null,
  targetCloseDate: null,
  targetFundSize: null,
  fundTerm: null,
  investmentPeriod: null,
  isOpenEnded: null,
  domicileIds: [],
  domicileCountryIds: [],
  attributeIds: [],
  partners: [],
  companyDescription: '',
  firmAUM: null,
  activeFunds: null,
  activePortfolioCompanies: null,
  nthFundByFirm: null,
  industryIds: [],
  geographyIds: [],
  verticalIds: [],
  expectedTargetedInternalRateOfReturn: null,
  netInternalRateOfReturn: null,
  grossInternalRateOfReturn: null,
  netTotalValuePaidInCapital: null,
  grossTotalValuePaidInCapital: null,
  residualValueToPaidIn: null,
  distributionsPaidInCapital: null,
  returns: [],
  currentNetAssetValue: null,
  totalCommitments: null,
  remainingCommitments: null,
  totalDrawdownsSinceInception: null,
  commitedCapitalPercentage: null,
  totalDrawdownPercentage: null,
  generalPartnerCommitedRatio: null,
  minimumInvestment: null,
  performanceFeePercentage: null,
  earlyRedemptionFeePercentage: null,
  hurdleRatePercentage: null,
  catchUpPercentage: null,
  highWaterMark: null,
  hasClawbackProvision: false,
  daysOfCapitalLockup: null,
  postLockupLiquidity: null,
  daysNoticeForRedemption: null,
  annualWithdrawalPercentageWithNoRedemptionFee: null,
  limitedPartnerCommunicationFrequencyId: null,
  benchmarkIndexId: null,
  primeBrokerIds: [],
  fundAdministratorIds: [],
  auditorIds: [],
  custodianIds: [],
  legalCounselIds: [],
  marketerIds: [],
  investmentAdvisorIds: [],
  informationTechnologyProviderIds: [],
  fundTraderIds: [],
  complianceProviderIds: [],
  totalInvestmentsSinceInception: null,
  targetInvestmentHorizonYears: null,
  totalPublicPositions: null,
  totalPrivatePositions: null,
  averagePositionSize: null,
  marketCapitalization: null,
  leverageUtilization: null,
  portfolioCompanies: [],
  marketingMaterialUrls: [],
  media: [],
  links: [],
}

const IlliquidFundWizard = ({
  fundId,
  onSave,
  defaultStepNumber,
  markAsEdited,
  markAsConfirmed,
  onSwitchFundType,
}) => {
  const [formFundId, setFormFundId] = useState(fundId)

  const handleSave = (vals, formFinished, doneSaving) => {
    forEach(vals.returns, (ret) => {
      ret.internalRateOfReturn = filter(
        ret.internalRateOfReturn,
        (x) => !isNil(x.value)
      )
      ret.totalValuePaidInCapital = filter(
        ret.totalValuePaidInCapital,
        (x) => !isNil(x.value)
      )
    })

    const model = {
      ...vals,
      links: filter(vals.links, (x) => !!x.url),
      isDisclaimerConfirmed: formFinished,
    }

    if (isNil(formFundId)) {
      api.funds
        .createIlliquidFund(model)
        .then((res) => {
          if (formFinished) {
            markAsConfirmed()
            message.success('Fund saved successfully.')
            onSave()
          }
          api.funds.trackFundStep(res.data.result.fundId, 0)
          setFormFundId(res.data.result.fundId)
        })
        .catch(() =>
          message.error('An error occurred while attempting to save your fund.')
        )
        .finally(() => doneSaving())
    } else {
      api.funds
        .saveIlliquidFundProfile(formFundId, model)
        .then(() => {
          if (formFinished) {
            markAsConfirmed()
            message.success('Fund saved successfully.')
            onSave()
          }
        })
        .catch(() =>
          message.error('An error occurred while attempting to save your fund.')
        )
        .finally(() => doneSaving())
    }
  }

  return (
    <FundWizard
      fundId={formFundId}
      onSave={handleSave}
      steps={STEPS}
      defaultValues={DEFAULT_VALUES}
      defaultStep={defaultStepNumber}
      markAsEdited={markAsEdited}
      onSwitchFundType={onSwitchFundType}
    />
  )
}

IlliquidFundWizard.propTypes = {
  fundId: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  defaultStepNumber: PropTypes.number,
  markAsEdited: PropTypes.func.isRequired,
  markAsConfirmed: PropTypes.func.isRequired,
  onSwitchFundType: PropTypes.func.isRequired,
}

export default IlliquidFundWizard
