import { Button } from '@context365/button'
import { Launch } from '@context365/icons'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import take from 'lodash/take'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import * as api from '~/api'
import CardButton from '~/components/CardButton'
import WidgetBlock from './WidgetBlock'

const UpcomingEvents = () => {
  const { data: events = [] } = useQuery(
    ['upcomingEvents'],
    () => api.events.getEvents(),
    {
      select: (res) =>
        take(orderBy(res.data.result.upcomingEvents, 'startDate', 'desc'), 4),
    }
  )

  return (
    <WidgetBlock
      headerLeft="Upcoming Events"
      headerRight={
        <Button
          variant="link"
          as={Link}
          to="/summits"
          iconRight={<Launch />}
          style={{ padding: 0 }}
          status="secondary"
        >
          See All
        </Button>
      }
    >
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {isEmpty(events) ? (
          <div>No upcoming events.</div>
        ) : (
          map(events, (e) => (
            <CardButton
              href={
                !e.amRegistered && e.registrationUrl
                  ? e.registrationUrl
                  : '/summits'
              }
              target={!e.amRegistered && e.registrationUrl ? '_blank' : '_self'}
              className="shadow-6 md:shadow-none border-grey-300 space-y-3"
            >
              <div className="type-subtitle-sm text-secondary-100">SUMMIT</div>
              {e.thumbnailImage && (
                <img
                  src={e.thumbnailImage}
                  alt={e.name}
                  style={{ maxHeight: '123px' }}
                  className="block max-w-full"
                />
              )}
              <div className="type-body-semibold-sm">{e.name}</div>
            </CardButton>
          ))
        )}
      </div>
    </WidgetBlock>
  )
}

export default UpcomingEvents
