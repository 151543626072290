import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import numeral from 'numeral'
import './MeetingLobbyDetails.less'

const ClockTimer = ({ eventDate, setMeetingAvailable }) => {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  const getTimeDifference = useCallback(() => {
    const countTo = moment.utc(eventDate)
    const duration = moment.duration(countTo.diff(moment()))
    setDays(duration.get('d'))
    setHours(duration.get('h'))
    setMinutes(duration.get('m'))
    setSeconds(duration.get('s'))
    setMeetingAvailable(duration.as('minutes') <= 10)
  }, [eventDate, setMeetingAvailable])

  useEffect(() => {
    setTimeout(() => getTimeDifference(), 1000)
  }, [getTimeDifference, seconds])

  const timeUnit = useCallback(
    (unit, pluralUnit, count) => (
      <span>
        <span className="text-grey-100">{numeral(count).format('00')}</span>
        <span>{count === 1 ? unit : pluralUnit}</span>
      </span>
    ),
    []
  )

  return (
    <span className="space-x-1">
      {days !== 0 && timeUnit('day,', 'days,', days)}
      {hours !== 0 && timeUnit('hour,', 'hours,', hours)}
      {timeUnit('min,', 'min,', minutes)}
      {timeUnit('sec', 'sec', seconds)}
    </span>
  )
}
ClockTimer.propTypes = {
  eventDate: PropTypes.instanceOf(Date),
  setMeetingAvailable: PropTypes.func.isRequired,
}
export default ClockTimer
