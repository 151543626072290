import { useEffect, useState } from 'react'
import head from 'lodash/head'
import orderBy from 'lodash/orderBy'
import { fetchAllocatorContactProfile } from '~/actions/allocators'
import * as api from '~/api'
import CompanyCategories from '~/constants/companyCategories'

export default function useAllocator(
  companyContactId,
  contactId,
  companyId,
  companyCategoryId,
  userRole
) {
  const [allocator, setAllocator] = useState(null)
  const [defaultFund, setDefaultFund] = useState(null)

  useEffect(() => {
    if (companyCategoryId !== CompanyCategories.Allocator) {
      return
    }

    fetchAllocatorContactProfile(companyId, contactId).then((response) => {
      const { result } = response.data
      setAllocator(result)
    })
  }, [companyId, contactId, companyCategoryId])

  useEffect(() => {
    if (
      userRole !== 'Manager' ||
      companyCategoryId !== CompanyCategories.Allocator ||
      companyContactId == null
    ) {
      return
    }
    api.funds
      .getFundsThatMatchWithAllocator(companyContactId)
      .then((response) => {
        const orderedFunds = orderBy(
          response.data.result,
          'matchmakingScore',
          'desc'
        )
        setDefaultFund(head(orderedFunds))
      })
  }, [userRole, companyContactId, companyCategoryId])

  return { allocator, defaultFund }
}
