import * as React from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import MediaTypes from '~/constants/mediaTypes'
import PdfViewer from './PdfViewer'
import VideoPlayer from './VideoPlayer'
import './MediaViewer.less'

const mediaViewerTypes = {
  [MediaTypes.PDF]: PdfViewer,
  [MediaTypes.Video]: VideoPlayer,
}

const Empty = () => null

const MediaViewer = ({ mediaTypeId, contentEntry, onClose }) => {
  const visible = Boolean(mediaTypeId)

  React.useLayoutEffect(() => {
    if (!visible) {
      return
    }

    const bodyStyle = document.body.style
    const originalOverflow = bodyStyle.getPropertyValue('overflow')
    bodyStyle.setProperty('overflow', 'hidden')
    // eslint-disable-next-line consistent-return
    return () => {
      bodyStyle.setProperty('overflow', originalOverflow)
    }
  }, [visible])

  const Component = mediaViewerTypes[mediaTypeId] ?? Empty

  return createPortal(
    <CSSTransition
      in={visible}
      timeout={150}
      classNames="content-media-viewer"
      mountOnEnter
      unmountOnExit
    >
      <div className="content-media-viewer">
        {contentEntry && (
          <Component
            mediaUrl={contentEntry.media.url}
            title={contentEntry.title}
            author={contentEntry.authorContact}
            taggedContacts={contentEntry.taggedContacts}
            onClose={onClose}
          />
        )}
      </div>
    </CSSTransition>,
    document.body
  )
}

MediaViewer.propTypes = {
  mediaTypeId: PropTypes.oneOf(
    Object.values(MediaTypes).map((id) => id.toString())
  ),
  contentEntry: PropTypes.shape({
    media: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    authorContact: PropTypes.object,
    taggedContacts: PropTypes.array,
  }),
  onClose: PropTypes.func.isRequired,
}

export default MediaViewer
