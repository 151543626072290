import {
  Checkbox,
  CheckboxGroup,
  FormMessage,
  Radio,
  RadioGroup,
} from '@context365/forms'
import { InfoOutlined } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import isEmpty from 'lodash/isEmpty'
import { useController } from 'react-hook-form'
import * as Yup from 'yup'
import * as api from '~/api'
import { UnsavedChangesAlert, useForm } from '~/components/Form'
import Loading from '~/components/Loading'
import { useOnboardingQuery } from '../hooks'
import { useOnboardingContext } from '../OnboardingContext'
import {
  BackButton,
  NextButton,
  OnboardingFooter,
  OnboardingLayout,
  OnboardingSteps,
} from './OnboardingLayout'

const schema = Yup.object().shape({
  investorQualificationTypeId: Yup.number()
    .nullable()
    .required('Please select an investor status.'),
  investorQualificationSubTypes: Yup.array()
    .of(Yup.number().required())
    .required()
    .min(1, 'Please select at least one category.'),
})

export function InvestorStatus({ steps }) {
  const { onNext } = useOnboardingContext()
  const { answers, isLoading, submit } = useOnboardingQuery(
    ['investor-status'],
    {
      getAnswers: api.onboarding.getInvestorStatusAnswers,
      saveAnswers: api.onboarding.saveInvestorStatusAnswers,
    }
  )

  if (isLoading) {
    return (
      <OnboardingLayout
        header={<OnboardingSteps steps={steps} />}
        body={
          <div className="flex items-center justify-center">
            <Loading />
          </div>
        }
      />
    )
  }

  return (
    <OnboardingLayout
      header={<OnboardingSteps steps={steps} />}
      body={
        <InvestorStatusForm
          id="investor-status"
          answers={answers}
          onSubmit={submit}
          onSuccess={onNext}
        />
      }
      footer={
        <OnboardingFooter
          leftContent={<BackButton />}
          rightContent={<NextButton type="submit" form="investor-status" />}
        />
      }
    />
  )
}

const InvestorQualificationType = {
  AccreditedInvestor: 1,
  QualifiedPurchaser: 2,
}

const investorQualificationTypeHints = {
  [InvestorQualificationType.AccreditedInvestor]:
    'This is a lower level than a qualified purchaser',
  [InvestorQualificationType.QualifiedPurchaser]:
    'This is a higher level than an accredited investor',
}

function InvestorStatusForm({ id, answers, onSubmit, onSuccess }) {
  const { options } = useOnboardingContext()

  const { Form, control, setValue } = useForm({
    schema,
    defaultValues: answers,
    onSubmit,
    onSuccess,
  })

  const {
    field: typeField,
    fieldState: { error: typeError },
  } = useController({ name: 'investorQualificationTypeId', control })
  const {
    field: subTypesField,
    fieldState: { error: subTypesError },
  } = useController({ name: 'investorQualificationSubTypes', control })

  const categoryOptions = options.investorQualificationTypes.find(
    (t) => t.id === typeField.value
  )?.children

  return (
    <Form id={id} className="mt-12 max-w-3xl mx-auto">
      <div className="type-header-md text-center mb-3">Investor Status</div>
      <div className="type-body-regular-md text-center mb-8">
        Please select an investor qualification type and at least one category.
      </div>
      <RadioGroup
        id="type-options"
        className="flex"
        {...typeField}
        onChange={(value) => {
          setValue('investorQualificationSubTypes', [])
          typeField.onChange(value)
        }}
      >
        {options.investorQualificationTypes.map(({ id, name, description }) => (
          <Radio key={id} className="flex-1" value={id}>
            <span>{name}</span>
            <Tooltip text={description} placement="bottom">
              <InfoOutlined
                className="inline align-text-bottom ml-2 text-primary-100"
                size={20}
              />
            </Tooltip>
            <br />
            <span className="text-grey-600">
              {investorQualificationTypeHints[id]}
            </span>
          </Radio>
        ))}
      </RadioGroup>
      <FormMessage className="text-center" errorMessage={typeError?.message} />
      {!isEmpty(categoryOptions) && (
        <CheckboxGroup
          className="flex flex-col gap-3 mt-6"
          {...subTypesField}
          selected={subTypesField.value}
        >
          <FormMessage
            className="text-center"
            errorMessage={subTypesError?.message}
          />
          {categoryOptions.map(({ id, name, description }) => (
            <Checkbox key={id} value={id}>
              <span>{name}</span>
              <br />
              <span className="text-grey-600">{description}</span>
            </Checkbox>
          ))}
        </CheckboxGroup>
      )}

      <UnsavedChangesAlert />
    </Form>
  )
}
