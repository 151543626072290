import { api } from './services'

export const getConversations = (archived, campaignId) =>
  api
    .get('/messaging/conversations', {
      params: { showArchived: archived, campaignId },
    })
    .then((response) => response.data.result.conversations)

export const getColleaguesToInvite = (conversationId) =>
  api
    .get(`/messaging/${conversationId}/colleagues`)
    .then((response) => response.data.result)

export const inviteColleagues = ({ conversationId, contactIDs }) =>
  api.post(`/messaging/${conversationId}/users`, {
    contactIDs,
  })

export const leaveConversation = (conversationId) =>
  api.delete(`/messaging/${conversationId}/users`)

export const getConversation = (conversationId) =>
  api
    .get(`/messaging/${conversationId}`, {
      params: { details: true },
    })
    .then((response) => response.data.result)

export const getConversationMessages = (conversationId, { take, upTo } = {}) =>
  api
    .get(`/messaging/${conversationId}/messages`, {
      params: { take, upto: upTo },
    })
    .then((response) => response.data.result)

export const sendMessage = (conversationId, message) =>
  api
    .post(`/messaging/${conversationId}`, { messageBody: message })
    .then((response) => response.data.result)

export const sendAttachment = (
  conversationId,
  { fileName, base64MediaContent }
) =>
  api
    .post(`/messaging/${conversationId}/attachments`, {
      fileName,
      base64MediaContent,
    })
    .then((response) => response.data.result)

export const downloadAttachment = (conversationId, mediaSid) =>
  api
    .get(`/messaging/${conversationId}/attachments/${mediaSid}`)
    .then((response) => response.data.message)
