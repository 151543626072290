import * as React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import CampaignsPremiumTierBody from './CampaignsPremiumTierBody'
import FreeStarterProTiersBody from './FreeStarterProTiersBody'

const UpgradeModal = ({
  container,
  closable = false,
  visible = true,
  onClose = () => {},
}) => {
  const { freeStarterProTiers } = useFlags()

  return (
    <Modal
      visible={visible}
      zIndex={5}
      width="min-content"
      bodyStyle={{ padding: 0 }}
      footer={null}
      closable={closable}
      mask={true}
      getContainer={container}
      onCancel={onClose}
      modalRender={(content) =>
        React.cloneElement(content, {
          className: cx(content.props.className, 'bg-transparent'),
        })
      }
    >
      {freeStarterProTiers ? (
        <FreeStarterProTiersBody />
      ) : (
        <CampaignsPremiumTierBody />
      )}
    </Modal>
  )
}

UpgradeModal.propTypes = {
  container: PropTypes.string,
  closable: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
}

export default UpgradeModal
