import Loading from '~/components/Loading'

const OnboardingLoading = () => (
  <Loading
    spinnerProps={{
      style: { marginLeft: '-17px', width: 48, height: 48 },
    }}
  />
)

export default OnboardingLoading
