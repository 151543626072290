import PropTypes from 'prop-types'
import { Modal } from 'antd'
import noop from 'lodash/noop'

export default function ProfileModal({
  visible = true,
  header,
  body,
  footer = null,
  onClose = noop,
  width = 960,
}) {
  return (
    <Modal
      bodyStyle={{ padding: 0, minHeight: '400px' }}
      visible={visible}
      title={header}
      width={width}
      footer={footer}
      onCancel={onClose}
    >
      {body}
    </Modal>
  )
}

Modal.propTypes = {
  visible: PropTypes.bool,
  header: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
}
