import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faCog } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Popover, Switch, Tooltip, message } from 'antd'
import { useSelector } from 'react-redux'
import { api as http } from '~/api/services'
import './ToggleMeetingRequests.less'

const ToggleMeetingRequests = ({
  disabledMeetingRequest,
  confirmedMeetingLimit,
  confirmedMeetings,
  eventId,
  placement = 'bottom',
}) => {
  const role = useSelector((state) => state.auth.role)
  const [requestLimitPopoverVisible, setRequestLimitPopoverVisible] =
    useState(false)
  const [requestsEnabled, setRequestsEnabled] = useState(
    !disabledMeetingRequest
  )
  const handleDisableMeetingRequest = useCallback(
    (checked) => {
      http
        .patch(`events/${eventId}/disabledrequests`, {
          disabledRequests: checked,
        })
        .then((response) => {
          message.success(
            `Requests are now ${response.data.result ? 'disabled' : 'enabled.'}`
          )
        })
    },
    [eventId]
  )

  useEffect(() => {
    setRequestsEnabled(!disabledMeetingRequest)
  }, [disabledMeetingRequest])

  return (
    role.toLowerCase() === 'allocator' && (
      <Popover
        overlayClassName="popover-limit-request"
        trigger="click"
        placement={placement}
        visible={requestLimitPopoverVisible}
        onVisibleChange={(visible) => setRequestLimitPopoverVisible(visible)}
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>Get meeting requests:</span>
            <Switch
              className="requestsSwitch"
              checked={
                requestsEnabled || confirmedMeetings < confirmedMeetingLimit
              }
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              onChange={(checked) => {
                setRequestsEnabled(checked)
              }}
              disabled={confirmedMeetingLimit > confirmedMeetings}
            />
          </div>
        }
        content={
          <div
            style={{
              width: '272px',
            }}
          >
            {confirmedMeetingLimit > confirmedMeetings && (
              <>
                {`You can't disable meeting requests until you reach
                            the limit of ${confirmedMeetingLimit}.`}
                <Divider style={{ margin: '16px 0px' }} />
              </>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                onClick={() => {
                  setRequestLimitPopoverVisible(false)
                }}
                size="small"
                type="ghost"
              >
                Cancel
              </Button>
              <Button
                size="small"
                disabled={confirmedMeetingLimit > confirmedMeetings}
                onClick={() => {
                  handleDisableMeetingRequest(!requestsEnabled)
                  setRequestLimitPopoverVisible(false)
                }}
                type="primary"
              >
                Save Changes
              </Button>
            </div>
          </div>
        }
      >
        <Tooltip
          placement={placement === 'left' ? 'topRight' : 'top'}
          title="Meeting request settings"
        >
          <Button className="settingsRequest">
            <FontAwesomeIcon className="icon" icon={faCog} />
          </Button>
        </Tooltip>
      </Popover>
    )
  )
}

ToggleMeetingRequests.propTypes = {
  confirmedMeetingLimit: PropTypes.number.isRequired,
  confirmedMeetings: PropTypes.number.isRequired,
  disabledMeetingRequest: PropTypes.bool,
  eventId: PropTypes.number.isRequired,
  placement: PropTypes.string,
}

export default ToggleMeetingRequests
