import PropTypes from 'prop-types'
import useMinScreen from '~/hooks/useMinScreen'

export const labelValuePair = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
})

export const nameIdPair = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
})

export const optionShape = PropTypes.oneOfType([labelValuePair, nameIdPair])

export function getValue(option) {
  return option.value ?? option.id
}

export function getLabel(option) {
  return option.label ?? option.name
}

export function useOptionColumns({ options, columns }) {
  const supportsMultiColumn = useMinScreen('md')
  return supportsMultiColumn ? columns ?? getColumnCount(options) : 1
}

function getColumnCount(options) {
  return options?.length <= 3 ? 1 : 2
}
