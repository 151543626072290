import * as Yup from 'yup'
import * as api from '~/api'
import {
  CheckboxGroupField,
  Form,
  NumberInputField,
  UnsavedChangesAlert,
} from '~/components/Form'
import Loading from '~/components/Loading'
import { useOnboardingQuery } from '../hooks'
import { useOnboardingContext } from '../OnboardingContext'
import {
  BackButton,
  NextButton,
  OnboardingFooter,
  OnboardingLayout,
  OnboardingSteps,
} from './OnboardingLayout'

const schema = Yup.object().shape({
  averageInvestmentSize: Yup.number(),
  industryIds: Yup.array().of(Yup.number().required()),
  geographyIds: Yup.array().of(Yup.number().required()),
  verticalIds: Yup.array().of(Yup.number().required()),
  vehicleIds: Yup.array().of(Yup.number().required()),
})

export function ContactInterests({
  steps,
  includeAverageInvestmentSize = false,
}) {
  const { options, onNext } = useOnboardingContext()
  const { answers, isLoading, submit } = useOnboardingQuery(
    ['contact-interests'],
    {
      getAnswers: () => api.onboarding.getContactInterestAnswers(),
      saveAnswers: (_, answers) =>
        api.onboarding.saveContactInterestAnswers(answers),
    }
  )

  if (isLoading) {
    return (
      <OnboardingLayout
        header={<OnboardingSteps steps={steps} />}
        body={
          <div className="flex items-center justify-center">
            <Loading />
          </div>
        }
      />
    )
  }

  return (
    <OnboardingLayout
      header={<OnboardingSteps steps={steps} />}
      body={
        <Form
          id="contact-interest"
          className="mt-12 onboarding-form"
          schema={schema}
          defaultValues={answers}
          onSubmit={submit}
          onSuccess={onNext}
        >
          <div className="type-header-md text-center mb-4">
            Enter your personal investment interests
          </div>
          {includeAverageInvestmentSize && (
            <NumberInputField
              name="averageInvestmentSize"
              label="Typical Investment Size"
              type="currency"
            />
          )}
          <CheckboxGroupField
            name="industryIds"
            label="Sector Interests"
            options={options.industries}
          />
          <CheckboxGroupField
            name="geographyIds"
            label="Geography Interests"
            options={options.geographies}
          />
          <CheckboxGroupField
            name="verticalIds"
            label="Vertical Interests"
            options={options.verticals}
          />
          <CheckboxGroupField
            name="vehicleIds"
            label="Vehicle Interests"
            options={options.vehicles}
          />
          <UnsavedChangesAlert />
        </Form>
      }
      footer={
        <OnboardingFooter
          leftContent={<BackButton />}
          rightContent={<NextButton type="submit" form="contact-interest" />}
        />
      }
    />
  )
}
