import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import {
  AccountCircleOutlined,
  CreateOutlined,
  EmailOutlined,
  KeyboardBackspace,
  PhoneEnabledOutlined,
  PhoneIphoneOutlined,
} from '@context365/icons'
import { Col, Row } from 'antd'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isAdmin } from '~/selectors/auth'
import './Contact.less'

const Contact = ({ contact, back }) => {
  const isUserAdministrator = useSelector(isAdmin)

  const mobilePhone = !isEmpty(contact.mobilePhone)
    ? parsePhoneNumberFromString(contact.mobilePhone)
    : null

  const workPhone = !isEmpty(contact.workPhone)
    ? parsePhoneNumberFromString(contact.workPhone)
    : null

  const { push } = useHistory()

  return (
    <div className="cc-contact">
      <Row className="cc-contact-header">
        <Col span={2}>
          <IconButton
            icon={<KeyboardBackspace />}
            label="back"
            status="secondary"
            onClick={back}
          />
        </Col>
        <Col span={17}>
          <h3 className="cc-contact-header-title">Colleague</h3>
        </Col>
        <Col span={5} style={{ textAlign: 'right' }}>
          {isUserAdministrator && (
            <Button
              variant="filled"
              iconLeft={<CreateOutlined />}
              onClick={() =>
                push(
                  `/company/${contact.companyId}/contacts/${contact.contactId}/edit`
                )
              }
            >
              Edit User
            </Button>
          )}
        </Col>
      </Row>
      <div className="cc-contact-content">
        <Row>
          <Col span={16} style={{ display: 'flex' }}>
            <div style={{ marginRight: '16px' }}>
              {isNil(contact.imageUrl) || isEmpty(contact.imageUrl) ? (
                <AccountCircleOutlined className="text-white bg-primary-100 h-24 w-24 rounded-full p-2" />
              ) : (
                <div
                  className="cc-contact-content-pic"
                  style={{ backgroundImage: `url(${contact.imageUrl})` }}
                />
              )}
            </div>
            <div>
              <h3
                className="cc-contact-content-fullname"
                style={{ marginTop: '8px' }}
              >
                {contact.firstName} {contact.lastName}
              </h3>
              <p className="cc-contact-content-jobtitle">{contact.jobTitle}</p>
            </div>
          </Col>
          {!isNil(contact.investorQualification) && (
            <Col span={8} style={{ textAlign: 'right' }}>
              <h4
                className="cc-contact-content-qualification-title"
                style={{ marginTop: '8px' }}
              >
                Investor Qualification
              </h4>
              <p className="cc-contact-content-qualification">
                {contact.investorQualification}
              </p>
            </Col>
          )}
        </Row>
        <div className="cc-contact-content-info">
          <h5 className="cc-contact-content-info-header">Contact</h5>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: 'unset',
            }}
          >
            <div
              className="cc-contact-content-info-wrapper"
              style={{
                display: 'flex',
                width: '33%',
              }}
            >
              <EmailOutlined className="text-grey-300 w-10 h-10" />
              <div style={{ marginLeft: '8px' }}>
                <h6 className="cc-contact-content-info-title">Email</h6>
                <div className="cc-contact-content-info-detail">
                  {isNil(contact.email) ? '-' : contact.email}
                </div>
              </div>
            </div>

            <div
              className="cc-contact-content-info-wrapper"
              style={{
                display: 'flex',
                width: '33%',
              }}
            >
              <PhoneIphoneOutlined className="text-grey-300 w-10 h-10" />
              <div style={{ marginLeft: '8px' }}>
                <h6 className="cc-contact-content-info-title">Mobile Phone</h6>
                <span className="cc-contact-content-info-detail">
                  {!isNil(mobilePhone)
                    ? mobilePhone.formatInternational()
                    : !isNil(contact.mobilePhone)
                    ? contact.mobilePhone
                    : '-'}
                </span>
              </div>
            </div>

            <div
              className="cc-contact-content-info-wrapper"
              style={{
                display: 'flex',
                width: '33%',
              }}
            >
              <PhoneEnabledOutlined className="text-grey-300 w-10 h-10" />
              <div style={{ marginLeft: '8px' }}>
                <h6 className="cc-contact-content-info-title">Work Phone</h6>
                <span className="cc-contact-content-info-detail">
                  {!isNil(workPhone)
                    ? workPhone.formatInternational()
                    : !isNil(contact.workPhone)
                    ? contact.workPhone
                    : '-'}
                </span>
              </div>
            </div>
          </Row>
          <h5 className="cc-contact-content-info-header">Address</h5>
          <Row>
            <Col span={6}>
              <div className="cc-contact-content-info-address">
                <h6>Country:</h6>
                <span>
                  {isNil(contact.country) ? 'United States' : contact.country}
                </span>
              </div>
            </Col>
            <Col span={6}>
              <div className="cc-contact-content-info-address">
                <h6>State:</h6>
                <span>{isNil(contact.state) ? '-' : contact.state}</span>
              </div>
            </Col>
            <Col span={6}>
              <div className="cc-contact-content-info-address">
                <h6>City:</h6>
                <span>{isNil(contact.city) ? '-' : contact.city}</span>
              </div>
            </Col>
            <Col span={6}>
              <div className="cc-contact-content-info-address">
                <h6>Address:</h6>
                <span>
                  {isNil(contact.address) || isEmpty(contact.address)
                    ? '-'
                    : contact.address}
                </span>
              </div>
            </Col>
          </Row>
          {/* <h5 className="cc-contact-content-info-header cc-contact-content-info-header-last">
            Mail Updates
          </h5>
          <Row>
            <Col span={8}>
              <div className="cc-contact-content-info-mailupdates">
                <span className="cc-contact-content-info-mailupdates-icon">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
                Corporate and industry updates
              </div>
            </Col>
            <Col span={8}>
              <div className="cc-contact-content-info-mailupdates">
                <span className="cc-contact-content-info-mailupdates-icon">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
                Event news and product updates
              </div>
            </Col>
            <Col span={8}>
              <div className="cc-contact-content-info-mailupdates">
                <span className="cc-contact-content-info-mailupdates-icon">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
                Affiliate and product updates
              </div>
            </Col>
          </Row> */}
        </div>
      </div>
    </div>
  )
}

Contact.propTypes = {
  contact: PropTypes.shape({
    companyId: PropTypes.number.isRequired,
    contactId: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    jobTitle: PropTypes.string,
    workPhone: PropTypes.string,
    mobilePhone: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    stateProvince: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    continent: PropTypes.string,
    jobLevel: PropTypes.string,
    jobFunction: PropTypes.string,
    workExtension: PropTypes.string,
    imageUrl: PropTypes.string,
    investorQualification: PropTypes.string,
  }).isRequired,
  back: PropTypes.func.isRequired,
}

export default Contact
