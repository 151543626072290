import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom-v5-compat'
import * as api from '~/api'
import Loading from '~/components/Loading'
import { formatValuationDate } from '../utils'
import ReturnsChart from './ReturnsChart'
import ReturnsTable from './ReturnsTable'

export default function Returns() {
  const { companyId } = useParams()
  const { data, isLoading } = useQuery(['allocator-returns', companyId], () =>
    api.allocators.getAllocatorReturns(companyId)
  )

  if (isLoading) {
    return (
      <div className="text-center my-16">
        <Loading />
      </div>
    )
  }

  const isDataEmpty =
    data.returns.length === 0 ||
    data.returns.every(
      ({ categories }) =>
        categories.length === 0 ||
        categories.every(({ actualPercentage }) => actualPercentage == null)
    )

  return (
    <div>
      <h2 className="type-header-sm text-header mb-4">Actual Rate of Return</h2>
      <div className="type-body-regular-sm mb-8">
        Latest Valuation date: {formatValuationDate(data.latestValuationDate)}
      </div>
      <div className="rounded bg-white shadow-6 border">
        {isDataEmpty ? (
          <EmptyChart />
        ) : (
          <>
            <ReturnsChart returns={data.returns} />
            <div className="h-5" />
            <ReturnsTable returns={data.returns} />
          </>
        )}
      </div>
    </div>
  )
}

function EmptyChart() {
  return (
    <div className="flex items-center justify-center type-header-xxs py-20">
      No return data available yet.
    </div>
  )
}
