import * as React from 'react'
import { Client } from '@twilio/conversations'
import { message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as api from '~/api'
import useAuth from '~/hooks/useAuth'
import { useMessagesContext } from './context'
import { Message } from './models'

/*
 * Most updates to the conversation queries are handled by receiving events from
 * the Twilio client, so we can tell react-query to wait awhile between updates.
 */
const STABLE_QUERY_STALE_TIME = 20 * 60 * 1000

export function useConversations({
  archive,
  campaignId,
  currentConversationId,
}) {
  const queryClient = useQueryClient()
  const {
    data: conversations,
    error,
    isLoading,
    isError,
    refetch,
  } = useQuery(['conversations', { archive }], () =>
    api.messages.getConversations(archive, campaignId)
  )

  const onMessageAdded = React.useCallback(
    (twilioMessage) => {
      if (!conversations) {
        return
      }

      const updatedConversation = conversations.find(
        (conversation) =>
          conversation.channelSID === twilioMessage.conversation.sid
      )

      if (!updatedConversation) {
        // We got an update for a conversation we don't have, so we'll refresh
        // the list of conversations.
        refetch()
        return
      }

      if (twilioMessage.attributes.IsSystemGenerated) {
        return
      }

      const message = Message.fromTwilioMessage(twilioMessage, {
        ...updatedConversation,
        participants: updatedConversation.contacts,
      })
      const isSelectedConversation =
        updatedConversation.conversationID === currentConversationId

      queryClient.setQueryData(
        ['conversations', { archive }],
        (conversations) =>
          conversations.map((conversation) => {
            if (
              conversation.conversationID !== updatedConversation.conversationID
            ) {
              return conversation
            }

            return {
              ...conversation,
              lastMessage: message,
              sortDate: message.timestamp,
              totalUnreadMessageCount: isSelectedConversation
                ? 0
                : conversation.totalUnreadMessageCount + 1,
            }
          })
      )
    },
    [conversations, refetch, queryClient, archive, currentConversationId]
  )

  const onParticipantAdded = React.useCallback(() => refetch(), [refetch])

  const { twilioClient } = useMessagesContext()
  React.useEffect(() => {
    if (!twilioClient) {
      return undefined
    }

    twilioClient.on(Client.messageAdded, onMessageAdded)
    twilioClient.on(Client.participantJoined, onParticipantAdded)

    return () => {
      twilioClient.off(Client.messageAdded, onMessageAdded)
      twilioClient.off(Client.participantJoined, onParticipantAdded)
    }
  }, [twilioClient, onMessageAdded, onParticipantAdded])

  return {
    conversations,
    error,
    isLoading,
    isError,
    refreshConversations: refetch,
  }
}

export function useConversation(conversationId) {
  const queryClient = useQueryClient()
  const { contact, company } = useAuth()
  const { twilioClient } = useMessagesContext()

  const {
    data: conversation,
    refetch,
    status: conversationStatus,
    error: conversationError,
  } = useQuery(
    ['conversation', { conversationId }],
    () => api.messages.getConversation(conversationId),
    {
      staleTime: STABLE_QUERY_STALE_TIME,
      refetchOnWindowFocus: false,
    }
  )

  const { data: conversationMessages, isLoading: isLoadingMessages } = useQuery(
    [
      'conversationMessages',
      { conversationId, enabled: conversationStatus === 'success' },
    ],
    () => api.messages.getConversationMessages(conversationId),
    {
      enabled: conversationStatus === 'success',
      staleTime: STABLE_QUERY_STALE_TIME,
      initialData: () => {
        if (!conversation) {
          return undefined
        }
        return conversation.messages
      },
      initialDataUpdatedAt: () => {
        if (!conversation) {
          return undefined
        }
        if (conversation.messages.length === conversation.totalMessages) {
          return undefined
        }

        // If we have messages, but not all of them, we need to fetch the
        // remaining messages, so mark the query as stale.
        return Date.now() - STABLE_QUERY_STALE_TIME - 10
      },
    }
  )

  const twilioConversation = useTwilioConversation(conversation?.channelSID)
  const participants = useConversationParticipants(conversation)

  const messages = React.useMemo(() => {
    if (!conversationMessages) {
      return []
    }
    if (isEmpty(participants)) {
      return conversationMessages
    }

    const nonColleagueParticipants = participants.filter(
      (participant) => participant.companyId !== company.companyId
    )
    const mostRecentReadMessageIndex = Math.max(
      ...nonColleagueParticipants.map((p) => p.lastReadMessageIndex)
    )
    if (isNaN(mostRecentReadMessageIndex) || mostRecentReadMessageIndex < 0) {
      return conversationMessages
    }

    return conversationMessages.map((message) => {
      if (
        message.authorContact.contactId !== contact.contactId ||
        message.pending
      ) {
        return message
      }

      return {
        ...message,
        readStatus:
          message.index <= mostRecentReadMessageIndex ? 'read' : 'unread',
      }
    })
  }, [conversationMessages, participants, company.companyId, contact.contactId])

  const handleMessageAdded = React.useCallback(
    (twilioMessage) => {
      if (
        !conversation ||
        twilioMessage.conversation.sid !== conversation.channelSID
      ) {
        return
      }

      if (
        twilioMessage.attributes.IsSystemGenerated ||
        contact.contactId === +twilioMessage.author
      ) {
        // If it's a system message, we don't want to parse the body anymore.
        // Or, if it's a message we sent, we've already optimistically added it
        // to the conversation and (hopefully) refreshed the messages query.
        // But it's possible we're sending messages from multiple app instances
        // at the same time, so we'll refresh just in case.
        queryClient.invalidateQueries([
          'conversationMessages',
          { conversationId, enabled: true },
        ])
        return
      }

      const message = Message.fromTwilioMessage(twilioMessage, conversation)

      queryClient.setQueryData(
        ['conversationMessages', { conversationId, enabled: true }],
        (data) => {
          return [...data, message]
        }
      )
    },
    [conversation, conversationId, queryClient, contact.contactId]
  )

  const handleParticipantUpdated = React.useCallback(
    (update) => {
      if (!update.updateReasons.includes('lastReadMessageIndex')) {
        return
      }
      if (!conversation) {
        return
      }
      if (update.participant.conversation.sid !== conversation.channelSID) {
        return
      }

      const contactId = parseInt(update.participant.identity, 10)
      const newMessageIndex = update.participant.lastReadMessageIndex
      queryClient.setQueryData(
        ['conversationParticipants', { channelSID: conversation?.channelSID }],
        (participants) =>
          participants.map((p) => {
            if (p.contactId !== contactId) {
              return p
            }
            return {
              ...p,
              lastReadMessageIndex: newMessageIndex,
            }
          })
      )
    },
    [conversation, queryClient]
  )

  React.useEffect(() => {
    if (!twilioClient) {
      return undefined
    }

    twilioClient.on(Client.messageAdded, handleMessageAdded)
    twilioClient.on(Client.participantUpdated, handleParticipantUpdated)

    return () => {
      twilioClient.off(Client.messageAdded, handleMessageAdded)
      twilioClient.off(Client.participantUpdated, handleParticipantUpdated)
    }
  }, [twilioClient, handleMessageAdded, handleParticipantUpdated])

  React.useEffect(() => {
    if (conversationStatus !== 'success' || !twilioConversation) {
      return
    }

    twilioConversation.setAllMessagesRead()
    queryClient.setQueriesData(['conversations'], (conversations) =>
      conversations?.map((conversation) => {
        if (conversation.conversationID !== conversationId) {
          return conversation
        }

        return {
          ...conversation,
          totalUnreadMessageCount: 0,
        }
      })
    )
  }, [twilioConversation, conversationStatus, queryClient, conversationId])

  return {
    conversation,
    messages: messages ?? [],
    isLoading: conversationStatus === 'loading' || isLoadingMessages,
    isError: conversationStatus === 'error',
    error: conversationError,
    refreshConversation: refetch,
  }
}

export function useLeaveConversation(conversationId, { onSuccess } = {}) {
  const client = useQueryClient()

  const { mutateAsync: leaveConversation, isLoading } = useMutation(
    () => api.messages.leaveConversation(conversationId),
    {
      onSuccess: () => {
        client.invalidateQueries('conversations')
        onSuccess?.()
      },
    }
  )

  return {
    leaveConversation,
    isLoading,
  }
}

export function useSendMessage({ conversationId, channelSID }) {
  const queryClient = useQueryClient()
  const { contact } = useAuth()

  const conversation = useTwilioConversation(channelSID)

  const conversationKey = ['conversation', { conversationId }]
  const messagesKey = [
    'conversationMessages',
    { conversationId, enabled: true },
  ]

  const { mutate, isLoading } = useMutation(
    async ({ message, attachment }) => {
      return message
        ? await api.messages.sendMessage(conversationId, message)
        : await api.messages.sendAttachment(conversationId, {
            fileName: attachment.fileName,
            base64MediaContent: attachment.fileUrl,
          })
    },
    {
      onMutate: async ({ message, attachment }) => {
        await Promise.all([
          queryClient.cancelQueries(conversationKey),
          queryClient.cancelQueries(messagesKey),
        ])

        const previousMessages = queryClient.getQueryData(messagesKey)

        const newMessage = message
          ? Message.fromText(message, conversationId, contact)
          : Message.fromAttachment(attachment.fileName, conversationId, contact)
        queryClient.setQueryData(messagesKey, (messages = []) => [
          ...messages,
          newMessage,
        ])

        return { previousMessages }
      },
      onError: (error, data, context) => {
        queryClient.setQueryData(messagesKey, context.previousMessages)
      },
      onSuccess: (data, _, context) => {
        // Apparently success doesn't always mean success, so we also have to
        // check the response payload for an extra "isSuccessful" property.
        if (data.isSuccessful) {
          conversation.setAllMessagesRead()
        } else {
          if (data.reason.includes('see inner object')) {
            const reason = data.validationResponse.reason ?? 'Unknown error'
            message.error(`Failed to send message: ${reason}`)
          } else {
            message.error(`Failed to send message: ${data.reason}`)
          }
          queryClient.setQueryData(messagesKey, context.previousMessages)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(conversationKey)
        queryClient.invalidateQueries(messagesKey)
      },
    }
  )

  return {
    isSending: isLoading,
    sendMessage: React.useCallback(
      ({ message, attachment, onSuccess }) => {
        mutate(
          { message, attachment },
          {
            onSuccess: (data) => {
              if (data.isSuccessful) {
                onSuccess?.()
              }
            },
          }
        )
      },
      [mutate]
    ),
  }
}

export function useRefreshConversations() {
  const queryClient = useQueryClient()

  return React.useCallback(() => {
    queryClient.invalidateQueries(['conversations'])
  }, [queryClient])
}

function useTwilioConversation(channelSID) {
  const { twilioClient } = useMessagesContext()
  const { data: conversation } = useQuery(
    ['twilioConversation', { channelSID }],
    () => twilioClient.getConversationBySid(channelSID),
    {
      enabled: channelSID != null && twilioClient != null,
      staleTime: STABLE_QUERY_STALE_TIME,
    }
  )
  return conversation
}

function useConversationParticipants(conversation) {
  const twilioConversation = useTwilioConversation(conversation?.channelSID)
  const { data: participants = [] } = useQuery(
    ['conversationParticipants', { channelSID: conversation?.channelSID }],
    async () => {
      const participants = await twilioConversation.getParticipants()
      return participants.map((participant) => {
        const contactId = parseInt(participant.identity, 10)
        const contact = conversation.details.contacts.find(
          (c) => c.contactId === contactId
        )
        return {
          companyId: contact?.companyId,
          contactId,
          lastReadMessageIndex: participant.lastReadMessageIndex,
        }
      })
    },
    {
      enabled: twilioConversation != null,
      staleTime: STABLE_QUERY_STALE_TIME,
    }
  )

  return participants
}
