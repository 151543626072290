import * as React from 'react'

export default function useMountedState(initialValue) {
  const isMounted = React.useRef(false)
  const [state, rawSetState] = React.useState(initialValue)

  const setState = React.useCallback((...args) => {
    if (isMounted.current) {
      rawSetState(...args)
    }
  }, [])

  React.useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  return [state, setState]
}
