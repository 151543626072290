import { useFlags } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearPresentationNotification,
  sendPresentationNotification,
} from '~/actions/notifications'
import { useSignalr } from '~/components/Presentations/SignalrProvider'
import { NotificationTypes } from './notificationTypes'
import PresentationNotification from './PresentationNotification'

const PresentationNotificationList = () => {
  const notifications = useSelector(
    (state) => state.notifications.presentationNotifications
  )
  const dispatch = useDispatch()

  const { showNewEvent } = useFlags()

  const handlePresentationStarted = (data) => {
    dispatch(
      sendPresentationNotification(data, NotificationTypes.PresentationStarted)
    )
  }

  const handlePresentationEnded = (data) => {
    dispatch(
      sendPresentationNotification(data, NotificationTypes.PresentationEnded)
    )
  }

  const handlePresentationIsComing = (data) => {
    dispatch(
      sendPresentationNotification(data, NotificationTypes.PresentationIsComing)
    )
  }

  useSignalr(NotificationTypes.PresentationStarted, handlePresentationStarted)
  useSignalr(NotificationTypes.PresentationEnded, handlePresentationEnded)
  useSignalr(NotificationTypes.PresentationIsComing, handlePresentationIsComing)

  const handleClose = (id, notificationType) => {
    dispatch(clearPresentationNotification(id, notificationType))
  }

  return (
    <div>
      {showNewEvent && !isEmpty(notifications) && (
        <div className="z-10 w-full p-0">
          {notifications.map((notification) => (
            <PresentationNotification
              key={`${notification.notificationType}-${notification.id}`}
              id={notification.id}
              cloudMeetRoomId={notification.cloudMeetRoomId}
              canViewRecording={notification.canViewRecording}
              title={notification.title}
              type={notification.notificationType}
              currentCompanyId={notification.companyId}
              cloudMeetRoomKey={notification.cloudMeetRoomKey}
              cloudMeetCurrentParticipantKey={
                notification.cloudMeetCurrentParticipantKey
              }
              onClose={handleClose}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default PresentationNotificationList
