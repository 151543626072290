import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import { declineOnlineMeeting } from '~/actions/contextMeetings'
import Loading from '../Loading'

const DeclineOnlineMeeting = ({
  visible,
  closeModal,
  company,
  meetingId,
  meetingType,
  onCancel,
  onForbidden = () => {},
}) => {
  const [cancellingInProgress, setCancellingInProgress] = useState(false)

  const handleModalCancel = useCallback(() => {
    closeModal()
  }, [closeModal])

  const cancelMeetingReq = useCallback(() => {
    setCancellingInProgress(true)
    declineOnlineMeeting(meetingId)
      .then(() => {
        message.success('The meeting has been declined')
        handleModalCancel()
        onCancel()
      })
      .finally(() => {
        setCancellingInProgress(false)
      })
  }, [handleModalCancel, meetingId, onCancel, onForbidden])

  const title = 'Decline Meeting'

  return (
    <Modal
      className="cc-modal-accept"
      title={title}
      visible={visible}
      okText={title}
      cancelText="Close"
      onOk={cancelMeetingReq}
      onCancel={handleModalCancel}
      okButtonProps={{
        loading: cancellingInProgress,
        style: { backgroundColor: '#D62B34', borderStyle: 'none' },
      }}
      cancelButtonProps={{ disabled: false }}
    >
      <Loading spinning={cancellingInProgress}>
        <p>
          Are you sure you want to decline the {meetingType} meeting with{' '}
          {company}?
        </p>
      </Loading>
    </Modal>
  )
}

DeclineOnlineMeeting.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  company: PropTypes.string.isRequired,
  meetingId: PropTypes.number.isRequired,
  meetingType: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onForbidden: PropTypes.func,
}

export default DeclineOnlineMeeting
