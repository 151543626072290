import isNil from 'lodash/isNil'
import { Route } from 'react-router-dom-v5-compat'
import * as api from '~/api'
import AllocatorCompanyForm from '../forms/AllocatorCompanyForm'
import AllocatorCompanyInterestsForm from '../forms/AllocatorCompanyInterestsForm'
import { ContactEducation } from '../pages/ContactEducation'
import { ContactIdentification } from '../pages/ContactIdentification'
import { ContactInterests } from '../pages/ContactInterests'
import { ContactLocation } from '../pages/ContactLocation'
import { ContactSocialMedia } from '../pages/ContactSocialMedia'
import { InvestorDetails } from '../pages/InvestorDetails'
import { InvestorStatus } from '../pages/InvestorStatus'
import { OnboardingStep } from '../pages/OnboardingStep'
import {
  Step,
  getNextStep,
  makeGetNextPagePath,
  makeGetPreviousPagePath,
} from './utils'

const companySteps = [Step.CompanyDetails, Step.CompanyInterests]
const contactSteps = [Step.ContactIdentification, Step.ContactLocation]
const companyContactSteps = [Step.InvestorStatus, Step.InvestorDetails]

const companyWizardSteps = [
  { path: Step.CompanyDetails, title: 'Company Info' },
  { path: Step.CompanyInterests, title: 'Investment Interests' },
]

const steps = [
  ...companySteps,
  ...contactSteps,
  ...companyContactSteps,
  Step.ContactEducation,
  Step.ContactInterest,
  Step.ContactSocialMedia,
]

const wizardSteps = [
  { path: Step.ContactIdentification, title: 'Contact Info' },
  { path: Step.ContactLocation, title: 'Location' },
  { path: Step.InvestorStatus, title: 'Investor Status' },
  { path: Step.InvestorDetails, title: 'Investor Details' },
  { path: Step.ContactEducation, title: 'Education' },
  { path: Step.ContactInterest, title: 'Interests' },
  { path: Step.ContactSocialMedia, title: 'Social Media' },
]

export const allocatorWorkflow = {
  getStartingPagePath: ({ company }) => {
    if (!company.hasCompanyOnboarded) {
      return isNil(company.companyStep)
        ? Step.CompanyDetails
        : companySteps[company.companyStep]
    }
    if (!company.hasContactOnboarded) {
      return getNextStep(contactSteps, null, null)
    }
    return getNextStep(companyContactSteps, null, company.companyContactStep)
  },
  getNextPagePath: makeGetNextPagePath(steps),
  getPreviousPagePath: makeGetPreviousPagePath(steps),

  routes: [
    <Route
      key={Step.CompanyDetails}
      path={Step.CompanyDetails}
      element={
        <OnboardingStep
          steps={companyWizardSteps}
          getSavedAnswers={api.onboarding.getAllocatorCompanyAnswers}
          saveAnswers={api.onboarding.saveAllocatorCompanyAnswers}
          form={<AllocatorCompanyForm />}
          formKey="company-details"
          title="Company Information"
        />
      }
    />,
    <Route
      key={Step.CompanyInterests}
      path={Step.CompanyInterests}
      element={
        <OnboardingStep
          steps={companyWizardSteps}
          getSavedAnswers={api.onboarding.getAllocatorCompanyInterestAnswers}
          saveAnswers={api.onboarding.saveAllocatorCompanyInterestAnswers}
          form={<AllocatorCompanyInterestsForm />}
          formKey="investment-interests"
          title="Investment Interests"
        />
      }
    />,
    <Route
      key={Step.ContactIdentification}
      path={Step.ContactIdentification}
      element={<ContactIdentification steps={wizardSteps} />}
    />,
    <Route
      key={Step.ContactLocation}
      path={Step.ContactLocation}
      element={<ContactLocation steps={wizardSteps} />}
    />,
    <Route
      key={Step.ContactEducation}
      path={Step.ContactEducation}
      element={<ContactEducation steps={wizardSteps} />}
    />,
    <Route
      key={Step.ContactInterest}
      path={Step.ContactInterest}
      element={<ContactInterests steps={wizardSteps} />}
    />,
    <Route
      key={Step.ContactSocialMedia}
      path={Step.ContactSocialMedia}
      element={<ContactSocialMedia steps={wizardSteps} />}
    />,
    <Route
      key={Step.InvestorStatus}
      path={Step.InvestorStatus}
      element={<InvestorStatus steps={wizardSteps} />}
    />,
    <Route
      key={Step.InvestorDetails}
      path={Step.InvestorDetails}
      element={<InvestorDetails steps={wizardSteps} />}
    />,
  ],
}
