import { useContext } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import numberToWords from 'number-to-words'
import PresentationWizardCtx from '~/utils/contexts/PresentationWizardCtx'
import './PresentationWizard.less'

const LearnMoreAboutRolesModal = ({ visible, onClose }) => {
  const { presentationType, displayData } = useContext(PresentationWizardCtx)

  const presentationLimits = displayData.types.find(
    (types) => types.id === presentationType
  )

  return (
    <Modal visible={visible} footer={false} width="50%" onCancel={onClose}>
      <div className="learnMoreBody">
        Minimum event requirements: one <strong>admin</strong>.
        <br />
        Maximum event requirements:{' '}
        {numberToWords.toWords(presentationLimits.maxAdmin ?? 1)}{' '}
        <strong>admin</strong>,{' '}
        {numberToWords.toWords(presentationLimits.maxModerator ?? 1)}
        <strong> moderator</strong>,{' '}
        {numberToWords.toWords(presentationLimits.maxPresenters ?? 5)}
        <strong> presenters</strong>, and{' '}
        {numberToWords.toWords(presentationLimits.maxAttendees ?? 30)} total{' '}
        <strong>attendees</strong>
        .
        <br />
        <br />
        <strong> About Admins: </strong>
        <br />
        Moderators and admins can both run events, but an admin is useful if you
        want someone at your company to help manage the event without being
        featured on the event card
        <ul>
          <li>
            e.g. Analyst at Firm ABC can “admin” the event while the Portfolio
            Manager can “moderate” the event and be featured.
          </li>
          <li>
            note: if you only select an admin they will be featured on the card.
          </li>
        </ul>
        <br />
        <strong> Additional roles:</strong>
        <br />
        <ul>
          <li>
            <strong> Presenter</strong>: Mark someone with this role if you want
            them to be featured as a presenter on the event. This works for both
            guest presenters (people who are not on the Context365 platform) as
            well as full Context365 platform members.
          </li>
          <li>
            <strong> Attendee</strong>: Mark someone with this role if you want
            to invite them to the event but don&apos;t want them featured on the
            card.
          </li>
        </ul>
      </div>
    </Modal>
  )
}

LearnMoreAboutRolesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default LearnMoreAboutRolesModal
