import {
  Apartment,
  AttachFile,
  AutoFixNormalOutlined,
  GroupsOutlined,
  NotificationsOutlined,
  PersonOutline,
  SubscriptionsOutlined,
  WorkOutline,
} from '@context365/icons'
import { NavButton, NavList, NavMenu } from '@context365/tabs'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import ContactForm from '~/components/ContactForm'
import InvestorPreferences from '../settings/InvestorPreferences'
import CompanySettingsContainer from './CompanySettingsContainer'
import CommunitySettings from './forms/CommunitySettings'
import CompanySettings from './forms/CompanySettings'
import CompanySettingsTakeover from './forms/CompanySettingsTakeover'
import ContactSettings from './forms/ContactSettings'
import ContactSettingsTakeover from './forms/ContactSettingsTakeover'
import MediaSettings from './forms/MediaSettings'
import Subscriptions from './forms/Subscriptions'
import NotificationSettings from './NotificationSettings'
import './Settings.css'

const Settings = () => {
  const userRole = useSelector((state) => state.auth.role)

  const { communityModule = true, freeStarterProTiers = true } = useFlags()

  return (
    <div className="flex flex-col md:flex-row md:-m-8">
      <div className="w-60 bg-secondary-2 p-4">
        <div className="hidden md:flex settings-tabs">
          <NavList variant="vertical" label="My Profile">
            <NavButton to="/settings/profile" iconLeft={<PersonOutline />}>
              User Profile
            </NavButton>
            {userRole.toLowerCase() !== 'guest presentation' && (
              <NavButton
                iconLeft={<Apartment />}
                to="/settings/company/company-settings"
              >
                Company Profile
              </NavButton>
            )}
            {userRole === 'Manager' && !communityModule && (
              <NavButton to="/settings/funds" iconLeft={<WorkOutline />}>
                Funds
              </NavButton>
            )}
            {userRole.toLowerCase() !== 'guest presentation' &&
              communityModule && (
                <NavButton iconLeft={<AttachFile />} to="/settings/media">
                  Media, Social, &amp; Links
                </NavButton>
              )}
            {userRole === 'Allocator' && (
              <NavButton
                iconLeft={<AutoFixNormalOutlined />}
                to="/settings/preferences"
              >
                Investor Preferences
              </NavButton>
            )}
            {userRole.toLowerCase() !== 'guest presentation' && (
              <NavButton
                iconLeft={<NotificationsOutlined />}
                to="/settings/communications"
              >
                Notifications
              </NavButton>
            )}
            {communityModule && (
              <NavButton iconLeft={<GroupsOutlined />} to="/settings/community">
                Community
              </NavButton>
            )}
            {freeStarterProTiers && (
              <NavButton
                iconLeft={<SubscriptionsOutlined />}
                to="/settings/subscriptions"
              >
                Subscriptions
              </NavButton>
            )}
          </NavList>
        </div>
        <div className="md:hidden m-4">
          <NavMenu label="Settings" buttonLabel="Select Section">
            <NavButton to="/settings/profile" iconLeft={<PersonOutline />}>
              User Profile
            </NavButton>
            {userRole.toLowerCase() !== 'guest presentation' && (
              <NavButton
                to="/settings/company/company-settings"
                iconLeft={<Apartment />}
              >
                Company Profile
              </NavButton>
            )}
            {userRole === 'Manager' && !communityModule && (
              <NavButton to="/settings/funds" iconLeft={<WorkOutline />}>
                Funds
              </NavButton>
            )}
            {userRole.toLowerCase() !== 'guest presentation' &&
              communityModule && (
                <NavButton to="/settings/media" iconLeft={<AttachFile />}>
                  Media, Social, &amp; Links
                </NavButton>
              )}
            {userRole === 'Allocator' && (
              <NavButton
                to="/settings/preferences"
                iconLeft={<AutoFixNormalOutlined />}
              >
                Allocator Preferences
              </NavButton>
            )}
            {userRole.toLowerCase() !== 'guest presentation' && (
              <NavButton
                to="/settings/communications"
                iconLeft={<NotificationsOutlined />}
              >
                Notifications
              </NavButton>
            )}
            {communityModule && (
              <NavButton to="/settings/community" iconLeft={<GroupsOutlined />}>
                Community
              </NavButton>
            )}
            {freeStarterProTiers && (
              <NavButton
                iconLeft={<SubscriptionsOutlined />}
                to="/settings/subscriptions"
              >
                Subscriptions
              </NavButton>
            )}
          </NavMenu>
        </div>
      </div>
      <div className="container mx-auto">
        <Switch>
          <Route
            path="/settings/profile/first-time"
            children={<ContactSettingsTakeover />}
          />
          <Route
            path="/settings/profile"
            children={communityModule ? <ContactSettings /> : <ContactForm />}
          />
          {userRole.toLowerCase() !== 'guest presentation' && (
            <Route
              path="/settings/company/company-settings/first-time"
              children={<CompanySettingsTakeover />}
            />
          )}
          {userRole.toLowerCase() !== 'guest presentation' && (
            <Route
              path="/settings/company/company-settings"
              children={
                communityModule ? (
                  <CompanySettings />
                ) : (
                  <CompanySettingsContainer type="company" />
                )
              }
            />
          )}
          {userRole.toLowerCase() !== 'guest presentation' && (
            <Route
              path="/settings/company/colleagues"
              children={<CompanySettingsContainer type="company" />}
            />
          )}
          {userRole === 'Manager' && !communityModule && (
            <Route
              path="/settings/funds"
              children={<CompanySettingsContainer type="funds" />}
            />
          )}
          {userRole === 'Allocator' && (
            <Route
              path="/settings/preferences"
              component={InvestorPreferences}
            />
          )}
          {userRole.toLowerCase() !== 'guest presentation' && (
            <Route
              path="/settings/communications"
              component={NotificationSettings}
            />
          )}
          {communityModule && (
            <Route path="/settings/community" component={CommunitySettings} />
          )}
          {communityModule && (
            <Route path="/settings/media" component={MediaSettings} />
          )}
          {freeStarterProTiers && (
            <Route path="/settings/subscriptions" component={Subscriptions} />
          )}
          <Redirect to="/settings/profile" />
        </Switch>
      </div>
    </div>
  )
}

export default Settings
