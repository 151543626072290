import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import map from 'lodash/map'
import without from 'lodash/without'

function getVisibleFields(fields) {
  return map(fields, (field) =>
    isEmpty(field.value) ? null : (
      <div key={field.label}>
        <span className="type-body-semibold-md text-primary-100">
          {field.label}
        </span>
        <div>{field.value}</div>
      </div>
    )
  )
}

const DataRow = ({ fields }) => {
  const visibleFields = without(getVisibleFields(fields), null)
  return isEmpty(visibleFields) ? null : (
    <div className="grid md:grid-cols-2">{visibleFields}</div>
  )
}

const ContactProfileOverview = ({ contact }) => {
  const dataRows = [
    (contact.certifications || contact.finraExams) && [
      {
        label: 'Professional Certifications',
        value: join(contact.certifications, ', '),
      },
      { label: 'Finra', value: join(contact.finraExams, ', ') },
    ],
    (contact.undergrad || contact.graduate) && [
      {
        label: 'Undergrad',
        value: map(contact.undergrad, (x) =>
          isEmpty(x.areaOfStudy) ? (
            <div>{x.school}</div>
          ) : (
            <div>
              {x.school}, {x.areaOfStudy}
            </div>
          )
        ),
      },
      {
        label: 'Graduate',
        value: map(contact.graduate, (x) =>
          isEmpty(x.areaOfStudy) ? (
            <div>{x.school}</div>
          ) : (
            <div>
              {x.school}, {x.areaOfStudy}
            </div>
          )
        ),
      },
    ],
    contact.industryInterests && [
      {
        label: 'Sector Interests',
        value: join(contact.industryInterests, ', '),
      },
    ],
    contact.geographicInterests && [
      {
        label: 'Geographic Interests',
        value: join(contact.geographicInterests, ', '),
      },
    ],
    contact.vehicleInterests && [
      {
        label: 'Vehicle Interests',
        value: join(contact.vehicleInterests, ', '),
      },
    ],
    contact.verticalInterests && [
      {
        label: 'Vertical Interests',
        value: join(contact.verticalInterests, ', '),
      },
    ],
    contact.jobTitle && [
      {
        label: 'Job Title',
        value: contact.jobTitle,
      },
    ],
    contact.jobLevel && [
      {
        label: 'Job Level',
        value: contact.jobLevel,
      },
    ],
    contact.jobFunction && [
      {
        label: 'Job Function',
        value: contact.jobFunction,
      },
    ],
  ]

  return (
    <div className="flex flex-col type-body-regular-md gap-4 md:gap-8 p-2">
      {contact.bio && <div>{contact.bio}</div>}
      {map(without(dataRows, null), (rowFields, i) => (
        <DataRow key={i} fields={rowFields} />
      ))}
    </div>
  )
}

ContactProfileOverview.propTypes = {
  contact: PropTypes.object,
}

export default ContactProfileOverview
