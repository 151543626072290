import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar } from '@context365/avatar'
import { Button, IconButton } from '@context365/button'
import { MoreVert } from '@context365/icons'
import { faCrown, faTrashAlt, faUser } from '@fortawesome/pro-regular-svg-icons'
import { Modal, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { useTable } from 'react-table'
import { deleteColleague, makeAdministrator } from '~/actions/company'
import { isAdmin } from '~/selectors/auth'
import { getInitials } from '~/utils/helpers'
import ActionButton from '../ActionButton'
import CardButton from '../CardButton'
import './SettingsColleagues.less'

const SettingsColleagues = ({ company, push, fetchCompany }) => {
  const [makeAdministratorModalVisible, setMakeAdministratorModalVisible] =
    useState(false)
  const [deleteUserModalVisible, setDeleteUserModalVisible] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState({})
  const isUserAdministrator = useSelector(isAdmin)

  const renderColleagueLink = useCallback(
    (record) => (
      <div className="space-x-2">
        <Avatar
          profileImageUrl={record.imageUrl}
          initials={getInitials(record.contactName)}
        />
        <Button
          variant="link"
          as={Link}
          to={`/company/${company.companyId}/contacts/${record.contactId}`}
        >
          {record.contactName}
        </Button>
      </div>
    ),
    [company]
  )

  const handleViewProfileClick = useCallback(
    (record) =>
      push(`/company/${company.companyId}/contacts/${record.contactId}`),
    [company, push]
  )

  const makeAdminClicked = (record) => {
    if (record.isAdministrator) {
      message.warning(
        `${record.firstName} ${record.lastName} is already an Administrator`
      )
    } else {
      setSelectedRecord(record)
      setMakeAdministratorModalVisible(true)
    }
  }

  const makeAdministratorOk = useCallback(() => {
    makeAdministrator(selectedRecord.contactId).then(() => {
      message.success('User successfully made an administrator')
      fetchCompany(company.companyId)
      setMakeAdministratorModalVisible(false)
    })
  }, [company, fetchCompany, selectedRecord])

  const handleAdminModalCancel = () => {
    setMakeAdministratorModalVisible(false)
  }

  const deleteUserClicked = (record) => {
    setDeleteUserModalVisible(true)
    setSelectedRecord(record)
  }

  const deleteUserOk = useCallback(() => {
    deleteColleague(selectedRecord.contactId).then(() => {
      message.success('User successfully deleted from company')
      fetchCompany(company.companyId)
      setDeleteUserModalVisible(false)
    })
  }, [company, fetchCompany, selectedRecord])

  const handleDeleteUserCancel = () => {
    setDeleteUserModalVisible(false)
  }

  const renderColleaguesActionButton = useCallback(
    (record, vertical) => {
      const ctxMenuItems = []
      ctxMenuItems.push({
        icon: faUser,
        text: 'View Profile',
        clickHandle: handleViewProfileClick.bind(null, record),
      })

      if (isUserAdministrator) {
        ctxMenuItems.push({
          icon: faCrown,
          text: 'Make Admin',
          clickHandle: makeAdminClicked.bind(null, record),
        })
        ctxMenuItems.push({
          icon: faTrashAlt,
          text: 'Delete User',
          clickHandle: deleteUserClicked.bind(null, record),
        })
      }

      return (
        <ActionButton
          menu={ctxMenuItems}
          trigger={
            vertical && (
              <IconButton variant="link" icon={<MoreVert />} label="menu" />
            )
          }
        />
      )
    },
    [handleViewProfileClick, isUserAdministrator]
  )

  const columns = [
    {
      id: 'contactFullname',
      Header: 'ACCOUNT CONTACT',
      accessor: renderColleagueLink,
    },
    {
      id: 'isAdministrator',
      Header: 'ROLE',
      accessor: (record) => {
        return record.isAdministrator ? 'Administrator' : 'User'
      },
    },
    {
      id: 'stateProvince',
      Header: 'STATE',
      accessor: (record) => {
        return isNil(record.stateProvince) ? '-' : record.stateProvince
      },
    },
    {
      id: 'currentlyAttendingEvents',
      Header: 'CURRENTLY ATTENDING',
      accessor: (record) => {
        return isEmpty(record.currentlyAttendingEvents)
          ? '-'
          : map(record.currentlyAttendingEvents, (r, index) => (
              <span className="schedule">
                {`${index === 0 ? '' : ', '}${r.eventName}`}
              </span>
            ))
      },
    },
    {
      id: 'actions',
      Header: 'ACTIONS',
      accessor: (record) => renderColleaguesActionButton(record),
    },
  ]

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: company.contacts,
      autoResetHiddenColumns: false,
    })

  return (
    <>
      <div className="form-container-header">
        <p className="form-container-title">Colleagues</p>
        <p className="form-container-description">
          Change your colleagues settings
        </p>
      </div>
      <div className="overflow-auto hidden md:flex">
        <table
          className="w-full border border-grey-300 border-solid text-left leading-10"
          {...getTableProps()}
        >
          <thead className="border-b border-grey-300">
            {headerGroups.map((headerGroup, hgIndex) => (
              <tr
                className="bg-white"
                {...headerGroup.getHeaderGroupProps()}
                key={hgIndex}
              >
                {headerGroup.headers.map((column, cIndex) => (
                  <th
                    className="capitalize px-4 whitespace-nowrap"
                    key={cIndex}
                  >
                    <div>{column.render('Header')}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr key={i} className="even:bg-white" {...row.getRowProps()}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td
                        key={j}
                        className="px-4 whitespace-nowrap"
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="md:hidden space-y-4 px-4">
        {map(company.contacts, (c) => (
          <CardButton className="p-4 block shadow-6 text-left space-y-4">
            <div className="flex justify-between">
              <div className="flex space-x-2 items-center">
                <Avatar
                  profileImageUrl={c.imageUrl}
                  initials={getInitials(c.contactName)}
                />
                <div className="type-body-semibold-md">{c.contactName}</div>
              </div>
              {renderColleaguesActionButton(c, true)}
            </div>
            <div className="grid grid-cols-2">
              <div className="type-subtitle-xs mb-2">ROLE</div>
              <div className="type-subtitle-xs mb-2">STATE</div>
              <div>{c.isAdministrator ? 'Administrator' : 'User'}</div>
              <div>{isNil(c.stateProvince) ? '-' : c.stateProvince}</div>
            </div>
            <div>
              <div className="type-subtitle-xs mb-2">CURRENTLY ATTENDING </div>
              <div>
                {isEmpty(c.currentlyAttendingEvents)
                  ? '-'
                  : map(c.currentlyAttendingEvents, (r, index) => (
                      <span className="schedule">
                        {`${index === 0 ? '' : ', '}${r.eventName}`}
                      </span>
                    ))}
              </div>
            </div>
          </CardButton>
        ))}
      </div>
      <Modal
        title="Make administrator"
        visible={makeAdministratorModalVisible}
        onOk={makeAdministratorOk}
        onCancel={handleAdminModalCancel}
        okButtonProps={{ disabled: false }}
        cancelButtonProps={{ disabled: false }}
      >
        Do you want to give administrator privileges to the user?
      </Modal>
      <Modal
        title="Delete colleague"
        visible={deleteUserModalVisible}
        onOk={deleteUserOk}
        onCancel={handleDeleteUserCancel}
        okButtonProps={{ disabled: false }}
        cancelButtonProps={{ disabled: false }}
      >
        Do you want to remove the selected colleague?
      </Modal>
    </>
  )
}

SettingsColleagues.propTypes = {
  company: PropTypes.shape({
    companyId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    companyDescription: PropTypes.string.isRequired,
    category: PropTypes.shape({
      categoryId: PropTypes.number.isRequired,
      categoryName: PropTypes.string.isRequired,
    }).isRequired,
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        contactId: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        mobilePhone: PropTypes.string.isRequired,
      })
    ).isRequired,
    funds: PropTypes.arrayOf(
      PropTypes.shape({
        fundId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        investmentVehicle: PropTypes.string.isRequired,
        minimumInvestment: PropTypes.number.isRequired,
        domicile: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  push: PropTypes.func.isRequired,
  fetchCompany: PropTypes.func,
}

export default withRouter(SettingsColleagues)
