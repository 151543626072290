import * as React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

export default function Collapse({
  children,
  open,
  orientation: initialOrientation = 'vertical',
}) {
  const [orientation] = React.useState(initialOrientation)
  const [dimension, scrollDimension] =
    orientation === 'vertical'
      ? ['height', 'scrollHeight']
      : ['width', 'scrollWidth']

  const [size] = React.useState(() => (open ? undefined : 0))
  const ref = React.useRef()
  const previous = usePreviousRef(open)

  React.useLayoutEffect(() => {
    if (!ref.current || open === previous.current) {
      return
    }

    if (open) {
      expand(ref.current, dimension, scrollDimension)
    } else {
      collapse(ref.current, dimension, scrollDimension)
    }
  }, [open, dimension, scrollDimension, previous])

  return (
    <div
      ref={ref}
      className={cx(
        'overflow-hidden duration-200 ease-in-out',
        !open && 'opacity-0'
      )}
      style={{
        [dimension]: size,
        transitionProperty: [dimension, 'opacity'].join(', '),
      }}
    >
      {children}
    </div>
  )
}

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
}

function expand(element, dimension, scrollDimension) {
  const size = element[scrollDimension]
  element.style[dimension] = `${size}px`

  const onTransitionEnd = () => {
    element.style[dimension] = null
    element.removeEventListener('transitionend', onTransitionEnd)
  }

  element.addEventListener('transitionend', onTransitionEnd)
}

function collapse(element, dimension, scrollDimension) {
  const size = element[scrollDimension]
  const transition = element.style.transition
  element.style.transition = null

  requestAnimationFrame(() => {
    element.style[dimension] = `${size}px`
    element.style.transition = transition

    requestAnimationFrame(() => {
      element.style[dimension] = '0px'
    })
  })
}

function usePreviousRef(value) {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  })
  return ref
}
