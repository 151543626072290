import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Layout, Row, Table } from 'antd'
import { isNil } from 'lodash'
import moment from 'moment'
import Loading from '~/components/Loading'
import { transformColumns } from '~/utils/contextTable'
import './ScheduleTable.less'

const ScheduleTable = ({
  scroll,
  pagination,
  tableTitle,
  columns,
  dataSource,
  dataMethod,
  fetchMethod,
  onTableChanged,
  refreshData = false,
  rowSelected,
  clickEvent,
  rowClassName,
}) => {
  const [data, setData] = useState(null)
  const [, setTablePagination] = useState({})
  const [tableParameters, setTableParameters] = useState({
    pagination: {},
    filters: {},
    sorter: {},
    searchTerm: {},
  })
  const [loadData, setLoadData] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [, setInternalRefresh] = useState(false)
  const [spinnerLoading, setSpinnerLoading] = useState(false)

  const { Header } = Layout
  if (isNil(dataSource) && loadData) {
    dataMethod(
      tableParameters.pagination,
      tableParameters.filters,
      tableParameters.sorter,
      tableParameters.searchTerm
    ).then((response) => {
      if (!isNil(onTableChanged)) onTableChanged(response.data.result)

      setLoadData(false)
      setData(response.data.result.result)
      setTablePagination(response.data.result)
    })
  }

  if (!isNil(dataSource) && !isNil(fetchMethod) && loadData) {
    fetchMethod(
      tableParameters.pagination,
      tableParameters.filters,
      tableParameters.sorter,
      tableParameters.searchTerm
    )

    setLoadData(false)
    setSpinnerLoading(true)
  }

  useEffect(() => {
    setLoadData(true)
  }, [refreshData])

  useEffect(() => {
    if (!isNil(data) || !isNil(dataSource)) {
      getColumnFilters()
      setSpinnerLoading(false)
      setLoadData(false)
    }
  }, [data, dataSource])

  const getColumnFilters = () => {
    const filteredColumns = columns.filter((x) => !isNil(x.filterMethod))
    if (!isNil(filteredColumns)) {
      filteredColumns.forEach((column) => {
        if (isNil(filterData[column.key])) {
          column.filterMethod().then((response) => {
            const filter = filterData
            if (!isNil(column.filterMappingMethod))
              filter[column.key] = response.data.result.map((x) =>
                column.filterMappingMethod(x)
              )
            else filter[column.key] = response.data.result
            setFilterData(filter)
            setInternalRefresh(moment())
          })
        }
      })
    }
  }

  const filteredColumns = columns.filter((x) => !isNil(x.filterMethod))
  if (!isNil(filteredColumns)) {
    filteredColumns.forEach((column) => {
      if (!isNil(filterData[column.key])) {
        column.filters = filterData[column.key]
      }
    })
  }

  columns = transformColumns(columns, rowSelected)
  pagination = false

  const tableChanged = (pagination, filters, sorter) => {
    const filterArray = {
      columns: [],
    }

    for (const propertyName in filters) {
      const column = columns.find(
        (x) => x.dataIndex === propertyName || x.key === propertyName
      )
      const { columnType } = column

      filterArray.columns.push({
        column: propertyName,
        values: filters[propertyName],
        columnType,
      })
    }

    pagination.total = null

    setTableParameters({
      pagination,
      filters: filterArray,
      sorter,
      searchTerm: tableParameters.searchTerm,
    })
    setLoadData(true)
  }

  return (
    <>
      <Header
        className="table-header"
        style={{
          background: '#fff',
          padding: 0,
          boxShadow: '0px 8px 13px rgba(0, 0, 0, 0.04)',
        }}
      >
        <Row type="flex" justify="space-around" align="middle">
          <Col
            className="investors-header-col"
            style={{ paddingTop: '31px' }}
            span={6}
          >
            <span className="table-header-text">{tableTitle}</span>
          </Col>
          <Col span={18} />
        </Row>
      </Header>

      <Loading
        spinning={
          (isNil(data) && isNil(dataSource)) ||
          loadData ||
          spinnerLoading === true
        }
        spinnerProps={{
          style: { marginLeft: '-50px', width: '100px', height: '100px' },
        }}
      >
        <Table
          scroll={scroll}
          pagination={pagination}
          rowKey="id"
          columns={columns}
          dataSource={isNil(data) ? dataSource : data}
          rowClassName={rowClassName}
          onChange={tableChanged}
          onRow={(record) => {
            if (clickEvent) {
              return {
                onClick: () => {
                  clickEvent(record)
                },
              }
            }
          }}
        />
      </Loading>
    </>
  )
}
ScheduleTable.propTypes = {
  scroll: PropTypes.object,
  pagination: PropTypes.object,
  tableTitle: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataSource: PropTypes.array,
  fetchMethod: PropTypes.func,
  dataMethod: PropTypes.func,
  onTableChanged: PropTypes.func,
  refreshData: PropTypes.bool,
  rowSelected: PropTypes.func,
  clickEvent: PropTypes.func,
  rowClassName: PropTypes.string,
}

export default ScheduleTable
