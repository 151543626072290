import * as React from 'react'
import cx from 'classnames'
import { format } from 'd3-format'
import sortBy from 'lodash/sortBy'
import unionBy from 'lodash/unionBy'
import { useTable } from 'react-table'
import CategoryName from '../../components/CategoryName'
import { formatValuationDate, useCategoryColors } from '../utils'

export default function ReturnsTable({ returns }) {
  const allCategories = React.useMemo(() => {
    const categories = unionBy(
      ...returns.map(({ categories }) => categories),
      'categoryId'
    )
    return sortBy(categories, 'categoryId')
  }, [returns])

  const categoryColors = useCategoryColors(allCategories)

  const columns = React.useMemo(
    () => [
      {
        id: 'category',
        accessor: (category) => ({
          categoryId: category.categoryId,
          name: category.name,
          color: categoryColors[category.categoryId].badgeBackground,
        }),
        Cell: CategoryCell,
      },
      ...returns.map(({ valuationDate, categories }) => ({
        id: valuationDate,
        accessor: (category) =>
          categories.find((c) => c.categoryId === category.categoryId)
            ?.actualPercentage,
        Header: formatValuationDate(valuationDate),
        Cell: ReturnCell,
      })),
    ],
    [categoryColors, returns]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: allCategories })

  return (
    <div className="w-full max-w-full overflow-x-auto">
      <table
        className="w-full relative"
        style={{ borderSpacing: 0 }}
        {...getTableProps()}
      >
        <thead className="border-b border-primary-90">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={cx(
                    'p-0',
                    column.id === 'category' && 'sticky left-0'
                  )}
                  {...column.getHeaderProps()}
                >
                  <div className="p-2 text-center min-w-max bg-white">
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr
                className="bg-white even:bg-secondary-2"
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return cell.column.id === 'category' ? (
                    <th
                      scope="row"
                      className="sticky left-0 bg-inherit py-2 px-4 min-w-max bg-clip-padding"
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </th>
                  ) : (
                    <td className="p-2 min-w-max" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
        <tbody className="border-t border-b border-primary-90">
          <tr className="bg-white">
            <th scope="row" className="sticky left-0">
              <div className="bg-white py-2 px-4 min-w-max">
                <CategoryName
                  name="Total Alts"
                  abbreviation="alts"
                  backgroundColor={categoryColors.total.badgeBackground}
                />
              </div>
            </th>
            {returns.map(({ valuationDate, actualRateOfReturn }) => (
              <td key={valuationDate} className="p-2 min-w-max">
                <ReturnCell value={actualRateOfReturn} />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  )
}

function CategoryCell({ value: category }) {
  return <CategoryName name={category.name} backgroundColor={category.color} />
}

const formatReturn = format('.1%')

function ReturnCell({ value: returnPercentage }) {
  return (
    <div className="text-right tabular-nums">
      {returnPercentage == null ? '' : formatReturn(returnPercentage)}
    </div>
  )
}

/*
eslint
  react/jsx-key: "off" -- react-table v7 handles keys for us
 */
