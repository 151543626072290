import {
  FUND_PERFORMANCE_API_BASE_URL,
  FUND_PERFORMANCE_API_TOKEN,
} from '~/config'
import { applyAuthTokenFromStorage, createInstance } from './createInstance'

const fundPerformance = createInstance(FUND_PERFORMANCE_API_BASE_URL)
applyAuthTokenFromStorage(
  fundPerformance,
  FUND_PERFORMANCE_API_TOKEN,
  (token) => token
)

export default fundPerformance
