import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Select } from '@context365/forms'
import isEmpty from 'lodash/isNil'

const ServiceProviderField = ({
  className,
  label,
  value,
  options,
  onChange,
  addItem,
  removeItem,
  showRemove,
  showAdd,
}) => {
  return isEmpty(options) ? null : (
    <div className={className}>
      <Select.AutoComplete
        style={{ width: '50%' }}
        value={value}
        onChange={(val) => onChange(val)}
      >
        {({ inputValue }) =>
          options
            .filter((s) =>
              s.name.toLowerCase().includes(inputValue.toLowerCase())
            )
            .map((option) => (
              <Select.Option key={option.id} className="py-2" value={option.id}>
                {option.name}
              </Select.Option>
            ))
        }
      </Select.AutoComplete>
      {showRemove && (
        <Button variant="link" status="error" onClick={() => removeItem()}>
          Remove
        </Button>
      )}
      {showAdd && (
        <Button variant="link" onClick={() => addItem()}>
          + Add another {label}
        </Button>
      )}
    </div>
  )
}

ServiceProviderField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  addItem: PropTypes.func,
  removeItem: PropTypes.func,
  showAdd: PropTypes.bool,
  showRemove: PropTypes.bool,
  options: PropTypes.array,
}

export default ServiceProviderField
