import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@context365/forms'
import cx from 'classnames'
import isNil from 'lodash/isNil'
import map from 'lodash/map'

const CheckBoxField = ({
  name,
  value,
  setFieldValue,
  options,
  getOptionsMethod,
  mappingMethod,
  setFieldTouched,
}) => {
  const [selectOptions, setSelectOptions] = useState([])
  useEffect(() => {
    let mappedOptions = []

    if (isNil(options)) {
      getOptionsMethod().then(
        (res) => (mappedOptions = mappingMethod(res.data.result))
      )
    } else {
      mappedOptions = isNil(mappingMethod)
        ? map(options, (x) => ({ label: x.name, value: x.id }))
        : mappingMethod(options)
    }

    setSelectOptions(mappedOptions)
  }, [getOptionsMethod, mappingMethod, options])

  const handleChange = (val, isChecked) => {
    setFieldTouched(name)
    if (isChecked) {
      setFieldValue(name, [...value, val])
    } else {
      setFieldValue(
        name,
        value.filter((x) => x !== val)
      )
    }
  }

  return (
    <div
      className={cx(
        'grid',
        'gap-2',
        selectOptions.length > 10 && 'md:grid-cols-2',
        selectOptions.length > 20 && 'md:grid-cols-3',
        'mt-2'
      )}
    >
      {map(selectOptions, (opt) => (
        <div key={opt.value}>
          <Checkbox
            label={opt.label}
            name={opt.value}
            onChange={(e) => handleChange(opt.value, e.target.checked)}
            checked={value.includes(opt.value)}
          />
        </div>
      ))}
    </div>
  )
}

CheckBoxField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  options: PropTypes.array,
  getOptionsMethod: PropTypes.func,
  mappingMethod: PropTypes.func,
}

export default CheckBoxField
