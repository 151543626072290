import { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input, Modal, Popover, message } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useSelector } from 'react-redux'
import {
  getColleaguesForServiceProvider,
  sendOnlineMeetingRequestCFN,
} from '~/actions/contextMeetings'
import {
  getCompanyId,
  getUserCompanyContactId,
  getUserId,
} from '~/selectors/auth'
import MandateViewCtx from '~/utils/contexts/MandateViewCtx'
import CompanySelectContainer from '../CompanySelectContainer'
import Loading from '../Loading'
import MeetingRequestProposedTimes from '../MeetingRequestProposedTimes'
import ParticipantsCheckbox from '../ParticipantsCheckbox'

const { TextArea } = Input

const MeetingRequestCFN = ({
  visible,
  cfnCompany,
  onClose,
  onRefresh = () => {},
  dealMeeting = false,
}) => {
  const userId = useSelector(getUserId)
  const userCompanyContactId = useSelector(getUserCompanyContactId)
  const userCompanyId = useSelector(getCompanyId)
  const { role } = useSelector((state) => state.auth)
  const [loadingContacts, setLoadingContacts] = useState(false)
  const [myColleagues, setMyColleagues] = useState([])
  const [otherColleagues, setOtherColleagues] = useState([])
  const [participants, setParticipants] = useState([userId])
  const [toParticipants, setToParticipants] = useState([])
  const [invitedParticipants, setInvitedParticipants] = useState([])
  const [textMessage, setMessage] = useState('')
  const [savingInProggress, setSavinginProggress] = useState(false)
  const [invitedCompany, setInvitedCompany] = useState(null)

  const sentFromMandateView = useContext(MandateViewCtx)
  const { meetingInvite } = useFlags()

  useEffect(() => {
    if (!isNil(cfnCompany.contactId)) {
      setToParticipants([cfnCompany.contactId])
    } else if (!isNil(cfnCompany.dealId)) {
      const dealContacts = map(cfnCompany?.contacts, (c) => c.contactId)
      setToParticipants([...dealContacts])
    }
  }, [cfnCompany])

  const handleOk = useCallback(() => {
    setSavinginProggress(true)
    sendOnlineMeetingRequestCFN(
      cfnCompany.companyId,
      participants,
      toParticipants,
      textMessage,
      3,
      userCompanyContactId,
      invitedParticipants,
      invitedCompany?.fundId,
      cfnCompany.dealId,
      sentFromMandateView
    )
      .then((response) => {
        message.success('Request sent successfully.')

        const { meetingId } = response.data.result.result
        cfnCompany.meetings?.push({
          contextMeetingId: meetingId,
          meetingStatus: 'Pending',
          participantStatus: 'Confirmed',
        })

        onClose()
      })
      .catch((err) => {
        const errorMessage =
          err?.response?.status === 400 ? err?.response?.data?.message : null
        message.error(errorMessage || 'Could not send meeting request.')
      })
      .finally(() => {
        setSavinginProggress(false)
        onRefresh()
      })
  }, [
    cfnCompany,
    invitedCompany?.fundId,
    invitedParticipants,
    onClose,
    onRefresh,
    participants,
    sentFromMandateView,
    textMessage,
    toParticipants,
    userCompanyContactId,
  ])

  const handleParticipantChecked = useCallback(
    (e) => {
      let tmpparticipants
      if (e.target.checked) {
        tmpparticipants = [...participants, e.target.value]
        setParticipants(tmpparticipants)
      } else {
        tmpparticipants = participants.filter((p) => p !== e.target.value)
        setParticipants(tmpparticipants)
      }
    },
    [participants]
  )

  const handleToParticipantChecked = useCallback(
    (e) => {
      let tmptoParticipants
      if (e.target.checked) {
        tmptoParticipants = [...toParticipants, e.target.value]
        setToParticipants(tmptoParticipants)
      } else {
        tmptoParticipants = toParticipants.filter((p) => p !== e.target.value)
        setToParticipants(tmptoParticipants)
      }
    },
    [toParticipants]
  )

  const handleInvitedParticipants = useCallback(
    (e) => {
      let tmpInvitedParticipants
      if (e.target.checked) {
        tmpInvitedParticipants = [...invitedParticipants, e.target.value]
        setInvitedParticipants(tmpInvitedParticipants)
      } else {
        tmpInvitedParticipants = invitedParticipants.filter(
          (p) => p !== e.target.value
        )
        setInvitedParticipants(tmpInvitedParticipants)
      }
    },
    [invitedParticipants]
  )

  const footer = (
    <div className="footer">
      <Button
        onClick={() => {
          setToParticipants([])
          setInvitedParticipants([])
          setInvitedCompany(null)
          setParticipants([userId])
          onClose()
        }}
      >
        Cancel
      </Button>
      <Button
        loading={savingInProggress}
        type="primary"
        disabled={toParticipants.length === 0 || participants.length === 0}
        onClick={handleOk}
      >
        Send Meeting Request
      </Button>
    </div>
  )

  useEffect(() => {
    if (cfnCompany.companyId) {
      setLoadingContacts(true)
      getColleaguesForServiceProvider(cfnCompany.companyId, null, null, null)
        .then((response) => {
          setMyColleagues(
            response.data.result.find(
              (x) => x.companyId !== cfnCompany.companyId
            ).contacts
          )
          setOtherColleagues(
            response.data.result.find(
              (x) => x.companyId === cfnCompany.companyId
            ).contacts
          )
        })
        .catch(() => message.error('Could not retrieve colleagues'))
        .finally(() => {
          setLoadingContacts(false)
        })
    }
  }, [cfnCompany])

  return (
    <Modal
      destroyOnClose
      centered
      className="AllocatorOnlineMR"
      visible={visible}
      width="700px"
      title="Request a Meeting"
      footer={footer}
      afterClose={() => {
        setToParticipants([])
        setInvitedParticipants([])
        setInvitedCompany(null)
        setParticipants([userId])
        onClose()
      }}
      onCancel={() => {
        setToParticipants([])
        setInvitedParticipants([])
        setInvitedCompany(null)
        setParticipants([userId])
        onClose()
      }}
      bodyStyle={{ padding: '16px 32px' }}
    >
      <div>
        <Loading spinning={loadingContacts}>
          <div>
            <div className="cc-heading5 cc-darkest-grey-text">
              {cfnCompany.companyName}
            </div>
            <div className="cc-check-participants-wrapper">
              {otherColleagues &&
                map(otherColleagues, (contact) => (
                  <ParticipantsCheckbox
                    key={contact.contactId}
                    participants={toParticipants}
                    checked={handleToParticipantChecked}
                    contactId={contact.contactId}
                    contactName={`${contact.firstName} ${contact.lastName}`}
                    imageUrl={contact.imageUrl}
                    position={contact.jobTitle}
                    disabled={contact.contactId === cfnCompany.contactId}
                  />
                ))}
            </div>
          </div>
          <div>
            <div
              style={{ marginTop: '16px' }}
              className="cc-heading5 cc-darkest-grey-text"
            >
              Your Colleagues
            </div>
            <div>
              <div className="cc-check-participants-wrapper">
                {myColleagues &&
                  map(myColleagues, (contact) => (
                    <ParticipantsCheckbox
                      key={contact.contactId}
                      participants={participants}
                      checked={handleParticipantChecked}
                      contactId={contact.contactId}
                      contactName={`${contact.firstName} ${contact.lastName}`}
                      imageUrl={contact.imageUrl}
                      position={contact.jobTitle}
                    />
                  ))}
              </div>
            </div>
          </div>
        </Loading>
        {role === 'CFN' && isNil(invitedCompany) && meetingInvite && (
          <div>
            <div
              style={{ marginTop: '16px' }}
              className="cc-heading5 cc-darkest-grey-text"
            >
              Invite Another Company
            </div>
            <div
              style={{
                marginTop: '10px',
              }}
            >
              <Popover
                id="popover"
                destroyTooltipOnHide
                overlayClassName="Invite-company-button-popover"
                placement="bottomLeft"
                trigger="click"
                content={
                  <CompanySelectContainer
                    excludedCompanies={[userCompanyId, cfnCompany.companyId]}
                    setSelectedCompany={setInvitedCompany}
                    isPopover
                    onlyCFN={dealMeeting}
                  />
                }
              >
                <Button
                  type="link"
                  className="Invite-company-button"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0px',
                    fontWeight: '600',
                  }}
                  icon={
                    <FontAwesomeIcon
                      style={{
                        width: '18px',
                        height: 'auto',
                        marginRight: '8px',
                      }}
                      icon={faPlus}
                    />
                  }
                >
                  Add another company
                </Button>
              </Popover>
            </div>
          </div>
        )}
        {!isNil(invitedCompany) && meetingInvite && (
          <div>
            <div
              style={{ marginTop: '16px' }}
              className="cc-heading4 cc-darkest-grey-text"
            >
              {invitedCompany.companyName}
              <Button
                className="remove-invited-button cc-text-color-danger"
                icon={
                  <FontAwesomeIcon
                    style={{ marginRight: '8px' }}
                    icon={faTrashAlt}
                  />
                }
                type="link"
                style={{ fontWeight: '600' }}
                onClick={() => {
                  setInvitedCompany(null)
                  setInvitedParticipants([])
                }}
              >
                Remove
              </Button>
            </div>
            <div>
              <div className="cc-check-participants-wrapper">
                {invitedCompany.contacts &&
                  map(invitedCompany.contacts, (contact) => (
                    <ParticipantsCheckbox
                      key={contact.contactId}
                      participants={invitedParticipants}
                      checked={handleInvitedParticipants}
                      contactId={contact.contactId}
                      companyContactId={contact.companyContactId}
                      contactName={contact.contactName}
                      imageUrl={contact.imageUrl}
                      position={contact.jobTitle}
                      useCompanyContactId
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
        <MeetingRequestProposedTimes />
        <div style={{ width: '100%', marginTop: '24px' }}>
          <TextArea
            placeholder="Write a message..."
            rows={4}
            value={textMessage}
            onChange={({ target: { value } }) => {
              setMessage(value)
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

MeetingRequestCFN.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  cfnCompany: PropTypes.object.isRequired,
  onRefresh: PropTypes.func,
  dealMeeting: PropTypes.bool,
}

export default MeetingRequestCFN
