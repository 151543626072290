import { formatCurrency } from '~/utils/helpers'

export function formatCurrencyRange(min, max) {
  if (min && max) {
    return `${formatCurrency(min)} - ${formatCurrency(max)}`
  } else if (min) {
    return `${formatCurrency(min)} +`
  } else if (max) {
    return `Up to ${formatCurrency(max)}`
  }
  return null
}
