import PropTypes from 'prop-types'
import { Modal } from 'antd'
import isNil from 'lodash/isNil'
import DealProfileContainer from '../DealProfileContainer'

const DealProfileContainerModal = ({
  deal,
  visible,
  onCancel,
  hideActions = false,
  openDataroom = false,
  showMeetings = true,
}) => (
  <Modal
    visible={visible && !isNil(deal)}
    footer={null}
    onCancel={onCancel}
    width="900px"
    bodyStyle={{ padding: 0, minHeight: '400px' }}
  >
    {visible && (
      <DealProfileContainer
        openDataroom={openDataroom}
        deal={deal}
        hideActions={hideActions}
        showMeetings={showMeetings}
        showHeader
      />
    )}
  </Modal>
)

DealProfileContainerModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  deal: PropTypes.object,
  hideActions: PropTypes.bool,
  openDataroom: PropTypes.bool,
  showMeetings: PropTypes.bool,
}

export default DealProfileContainerModal
