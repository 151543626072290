import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faExclamationCircle,
  faMicrophone,
  faVideo,
  faVolume,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Col, Row, Select } from 'antd'
import { detect } from 'detect-browser'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useTracking } from 'react-tracking'
import {
  DEFAULT_AUDIO_INPUT,
  DEFAULT_AUDIO_OUTPUT,
  DEFAULT_VIDEO_INPUT,
} from '~/config'
import Loading from '../Loading'
import './VideoRoomSettings.less'

const browser = detect()

const { Option } = Select

const VideoRoomSettings = ({
  visible,
  audioOutputDeviceId = null,
  changeAudioDevices = () => {},
  changeVideoDevices = () => {},
  changeAudioOutDevices = () => {},
}) => {
  const { trackEvent } = useTracking({ component: 'VideoRoom' })
  const [audioInputs, setAudioInputs] = useState(null)
  const [audioOutputs, setAudioOutputs] = useState([])
  const [videoInputs, setVideoInputs] = useState(null)
  const [defaultAudioInput, setDefaultAudioInput] = useState(null)
  const [defaultAudioOutput, setDefaultAudioOutput] = useState(null)
  const [defaultVideoInput, setDefaultVideoInput] = useState(null)
  const [loading, setLoading] = useState(false)
  const [audioOnly, setAudioOnly] = useState(false)
  const [volume, setVolume] = useState(0)
  const [currentNode, setCurrentNode] = useState(null)
  const [selectedAudioInput, setSelectedAudioInput] = useState('')
  const [selectedVideoInput, setSelectedVideoInput] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [audioErrorMessage, setAudioErrorMessage] = useState(null)
  const [videoErrorMessage, setVideoErrorMessage] = useState(null)
  const videoRef = useRef()
  const audioRef = useRef()

  useEffect(() => {
    if (visible) {
      window.AudioContext = window.AudioContext || window.webkitAudioContext
      setLoading(true)
      getUserMedia(
        {
          audio: true,
          video: { height: 1080, frameRate: 30, width: 1920 },
        },
        true
      )
    }
    return () => {
      closeAudioContext()
      if (!isNil(videoRef.current)) {
        stopCurrentStream()
      }
    }
  }, [visible])

  const closeAudioContext = () => {
    if (!isNil(currentNode)) {
      currentNode.onaudioprocess = null
    }
    if (audioRef.current) {
      if (audioRef.current.state !== 'closed') audioRef.current.close()
    }
  }
  const stopCurrentStream = () => {
    if (!videoRef.current || !videoRef.current.srcObject) {
      return
    }
    videoRef.current.srcObject.getTracks().forEach((track) => {
      track.stop()
    })
    videoRef.current.srcObject = null
  }

  const getUserMedia = (configuration, refreshList = false) => {
    if (!isNil(videoRef.current)) {
      stopCurrentStream()
    }
    navigator.mediaDevices
      .getUserMedia(configuration)
      .then((media) => {
        calculateMicVolume(media)
        if (media && videoRef.current) {
          videoRef.current.srcObject = media
        }
      })
      .catch((err) => {
        if (err.message.includes('video')) {
          setVideoErrorMessage(
            <span>
              There was a problem while getting video
              <br />
              Make sure that you have allowed access from your browser.
              <br /> Make sure that your webcam is plugged in and turned on.
              <br />
              Check the video selection above to use the correct webcam.
              <br />
              Ensure that your webcam is not being used by another application.
              <br />
              Connect your webcam to a different USB port.
              <br />
              Try restarting your browser or clearing your cache
            </span>
          )

          setAudioOnly(true)
          getUserMedia({
            audio: true,
            video: false,
          })
        } else if (err.message.includes('audio')) {
          setAudioErrorMessage(
            <span>
              There was a problem while getting audio
              <br />
              Make sure that you have allowed access from your browser.
              <br />
              Check the microphone selection above to use the correct
              microphone.
              <br />
              Ensure that your microphone is not being used by another
              application.
              <br />
              Try restarting your browser or clearing your cache
            </span>
          )
        } else if (err.message.includes('Permission'))
          setErrorMessage(
            <span>
              Permission denied: Make sure that you have allowed access from
              your browser.
            </span>
          )
        else {
          setErrorMessage(
            <span>
              There was a problem while getting media devices
              <br />
              Make sure that you have allowed access from your browser.
              <br /> Make sure that your webcam is plugged in and turned on.
              <br />
              Check the video selection above to use the correct webcam.
              <br />
              Ensure that your webcam is not being used by another application.
              <br />
              Connect your webcam to a different USB port.
              <br />
              Try restarting your browser or clearing your cache
            </span>
          )
        }
      })
      .finally(() => {
        if (refreshList) {
          populateDevices()
        }
        setLoading(false)
      })
  }

  const populateDevices = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        detectDevices(devices)
      })
      .catch((err) => {
        setErrorMessage(
          `Could not retrieve media devices. Please make sure to allow access: ${err}`
        )
      })
  }

  const detectDevices = (devices) => {
    setDefaultAudioInput(null)
    const audioInputs = devices.filter(
      (d) =>
        d.kind === 'audioinput' &&
        d.deviceId !== 'default' &&
        d.deviceId !== 'communications'
    )

    let defaultAudio = devices.find(
      (d) => d.kind === 'audioinput' && d.deviceId === 'default'
    )

    if (!isEmpty(audioInputs)) {
      if (isNil(defaultAudio)) defaultAudio = first(audioInputs)

      const audioInputInfo = map(audioInputs, (audio) => ({
        groupId: audio.groupId,
        label: audio.label,
        deviceId: audio.deviceId,
      }))

      setAudioInputs(audioInputInfo)
      const savedAudio = localStorage.getItem(DEFAULT_AUDIO_INPUT)
      if (isEmpty(savedAudio)) {
        setDefaultAudioInput(
          audioInputInfo.find((d) => d.groupId === defaultAudio.groupId)
        )
      } else {
        const newAudio = audioInputInfo.find((d) => d.deviceId === savedAudio)
        if (!isEmpty(newAudio)) {
          setDefaultAudioInput(newAudio)
          const configs = {
            audio: {
              deviceId: savedAudio,
            },
            video: { height: 1080, frameRate: 30, width: 1920 },
          }
          if (audioOnly) configs.video = false
          getUserMedia(configs)
        } else {
          setDefaultAudioInput(
            audioInputInfo.find((d) => d.groupId === defaultAudio.groupId)
          )
        }
      }
    } else {
      setErrorMessage('No Microphones Detected')
      setAudioInputs([])
      setDefaultAudioInput(null)
    }
    if (browser.name === 'chrome' || browser.name === 'edge-chromium') {
      const audioOutputsDev = devices.filter(
        (d) =>
          d.kind === 'audiooutput' &&
          d.deviceId !== 'default' &&
          d.deviceId !== 'communications'
      )
      let defaultAudioOut = devices.find(
        (d) => d.kind === 'audiooutput' && d.deviceId === 'default'
      )
      if (!isEmpty(audioOutputsDev)) {
        if (isNil(defaultAudioOut)) defaultAudioOut = first(audioOutputsDev)
        const audioOutputsInfo = map(audioOutputsDev, (audio) => ({
          groupId: audio.groupId,
          label: audio.label,
          deviceId: audio.deviceId,
        }))
        const defaultAudio = audioOutputsInfo.find(
          (d) => d.deviceId === audioOutputDeviceId
        )
        setAudioOutputs(audioOutputsInfo)
        const savedAudioOutput = localStorage.getItem(DEFAULT_AUDIO_OUTPUT)
        if (isEmpty(savedAudioOutput)) {
          setDefaultAudioOutput(
            (defaultAudio && defaultAudio) || defaultAudioOut
          )
        } else {
          const newAudio = audioOutputsInfo.find(
            (d) => d.deviceId === savedAudioOutput
          )
          if (!isEmpty(newAudio)) {
            setDefaultAudioOutput(newAudio)
          } else {
            setDefaultAudioOutput(
              (defaultAudio && defaultAudio) || defaultAudioOut
            )
          }
        }
      } else {
        setErrorMessage('No Speakers Detected')
        setAudioOutputs([])
        setDefaultAudioOutput(null)
      }
    }

    setDefaultVideoInput(null)
    const videoInputs = devices.filter((d) => d.kind === 'videoinput')

    if (!isEmpty(videoInputs)) {
      const vieoInputInfo = map(videoInputs, (video) => ({
        groupId: video.groupId,
        label: video.label,
        deviceId: video.deviceId,
      }))
      setVideoInputs(vieoInputInfo)
      const savedVideo = localStorage.getItem(DEFAULT_VIDEO_INPUT)
      if (isEmpty(savedVideo)) {
        setDefaultVideoInput(first(vieoInputInfo))
      } else {
        const newVideo = vieoInputInfo.find((d) => d.deviceId === savedVideo)
        if (!isEmpty(newVideo)) {
          setDefaultVideoInput(newVideo)
          const configs = {
            audio: true,
            video: {
              deviceId: savedVideo,
              height: 1080,
              frameRate: 30,
              width: 1920,
            },
          }
          if (audioOnly) configs.video = false

          getUserMedia(configs)
        } else {
          setDefaultVideoInput(first(vieoInputInfo))
        }
      }
    } else {
      setErrorMessage('No Video Detected')
      setVideoInputs([])
      setDefaultVideoInput(null)
    }
  }

  const handleCanPlay = () => {
    videoRef.current.play()
  }

  const handleAudioInputChange = (e) => {
    const configs = {
      audio: {
        deviceId: e,
      },
      video: {
        deviceId:
          selectedVideoInput !== '' ? selectedVideoInput : defaultVideoInput,
        height: 1080,
        frameRate: 30,
        width: 1920,
      },
    }
    setSelectedAudioInput(e)
    trackEvent({
      eventName: 'click',
      element: 'change microphone device',
      deviceId: e,
    })
    changeAudioDevices(e)
    localStorage.setItem(DEFAULT_AUDIO_INPUT, e)
    if (audioOnly) configs.video = false
    getUserMedia(configs)
  }

  const handleVideoInputChange = (e) => {
    const configs = {
      audio: {
        deviceId:
          selectedAudioInput !== '' ? selectedAudioInput : defaultAudioInput,
      },
      video: {
        deviceId: e,
        height: 1080,
        frameRate: 30,
        width: 1920,
      },
    }
    setSelectedVideoInput(e)
    trackEvent({
      eventName: 'click',
      element: 'change video device',
      deviceId: e,
    })
    localStorage.setItem(DEFAULT_VIDEO_INPUT, e)
    changeVideoDevices(e)
    if (audioOnly) configs.video = false

    getUserMedia(configs)
  }

  const visualizeSound = () => {
    const bars = []
    for (let i = 0; i < 10; i++) {
      bars.push(
        <div
          className="pid"
          key={i}
          style={{ backgroundColor: volume > i ? '#24C477' : '#e6e7e8' }}
        />
      )
    }
    return bars
  }

  const calculateMicVolume = (media) => {
    if (!isNil(currentNode)) {
      currentNode.onaudioprocess = null
    }

    if (audioRef.current) {
      if (audioRef.current.state !== 'closed') audioRef.current.close()
    }
    const AudioContext =
      window.AudioContext || window.webkitAudioContext || false
    if (AudioContext) {
      const audioContext = new AudioContext()
      const analyser = audioContext.createAnalyser()
      const microphone = audioContext.createMediaStreamSource(media)
      const node = audioContext.createScriptProcessor(2048, 1, 1)
      analyser.smoothingTimeConstant = 0.8
      analyser.fftSize = 1024

      microphone.connect(analyser)
      analyser.connect(node)
      node.connect(audioContext.destination)
      node.onaudioprocess = () => {
        const array = new Uint8Array(analyser.frequencyBinCount)
        analyser.getByteFrequencyData(array)
        let values = 0

        const { length } = array
        for (let i = 0; i < length; i++) {
          values += array[i]
        }

        const average = values / length
        setVolume(Math.round(average))
      }

      setCurrentNode(node)
      audioRef.current = audioContext
      return node
    } else {
      setErrorMessage('An issue occured while getting audio')
    }
  }

  const handleAudioOutputChange = (e) => {
    videoRef.current.setSinkId(e)
    localStorage.setItem(DEFAULT_AUDIO_OUTPUT, e)
    changeAudioOutDevices(e)
    trackEvent({
      eventName: 'click',
      element: 'change speaker device',
      deviceId: e,
    })
  }

  return (
    <Loading spinning={loading}>
      {errorMessage && (
        <Alert
          style={{ marginBottom: '20px' }}
          message={errorMessage}
          type="warning"
          icon={<FontAwesomeIcon color="#F19F00" icon={faExclamationCircle} />}
          showIcon
        />
      )}

      <Row>
        <Col span={24}>
          <div id="VideoRoomSettings">
            <div className="cc-VideoRoomSettings-title">
              <div className="cc-VideoRoomSettings-header">
                <FontAwesomeIcon
                  color="#C2C6CC"
                  icon={faMicrophone}
                  className="cc-VideoRoomSettings-icon"
                />
                Microphone
              </div>
            </div>

            {audioErrorMessage && (
              <Alert
                message={audioErrorMessage}
                type="warning"
                icon={
                  <FontAwesomeIcon color="#F19F00" icon={faExclamationCircle} />
                }
                showIcon
              />
            )}
            {audioInputs && defaultAudioInput && (
              <div>
                <Select
                  getPopupContainer={() =>
                    document.getElementById('VideoRoomSettings')
                  }
                  defaultValue={defaultAudioInput.label}
                  className="cc-VideoRoomSettings-input"
                  size="large"
                  style={{ width: '100%', marginTop: '16px' }}
                  onChange={(e) => {
                    handleAudioInputChange(e)
                  }}
                >
                  {audioInputs &&
                    audioInputs.map((a) => (
                      <Option key={a.groupId} value={a.deviceId}>
                        {a.label}
                      </Option>
                    ))}
                </Select>

                <Row style={{ marginTop: '20px' }}>
                  <div className="pids-wrapper">{visualizeSound()}</div>
                </Row>
              </div>
            )}
          </div>
          {(browser.name === 'chrome' || browser.name === 'edge-chromium') && (
            <Row style={{ marginTop: '20px' }}>
              <div className="cc-VideoRoomSettings-header">
                <FontAwesomeIcon
                  color="#C2C6CC"
                  icon={faVolume}
                  className="cc-VideoRoomSettings-icon"
                />
                Speaker
              </div>
              {defaultAudioOutput && (
                <Select
                  getPopupContainer={() =>
                    document.getElementById('VideoRoomSettings')
                  }
                  defaultValue={defaultAudioOutput.label}
                  className="cc-VideoRoomSettings-input"
                  size="large"
                  style={{ width: '100%', marginTop: '16px' }}
                  onChange={(e) => {
                    handleAudioOutputChange(e)
                  }}
                >
                  {audioOutputs &&
                    audioOutputs.map((a) => (
                      <Option key={a.groupId} value={a.deviceId}>
                        {a.label}
                      </Option>
                    ))}
                </Select>
              )}
            </Row>
          )}
          <div style={{ marginTop: '20px' }}>
            <div className="cc-VideoRoomSettings-title">
              <div className="cc-VideoRoomSettings-header">
                <FontAwesomeIcon
                  color="#C2C6CC"
                  icon={faVideo}
                  className="cc-VideoRoomSettings-icon"
                />
                Video
              </div>
            </div>
            {videoErrorMessage && (
              <Alert
                style={{ marginTop: '16px' }}
                message={videoErrorMessage}
                type="warning"
                icon={
                  <FontAwesomeIcon color="#F19F00" icon={faExclamationCircle} />
                }
                showIcon
              />
            )}
            {!audioOnly && videoInputs && defaultVideoInput && (
              <div>
                <Select
                  getPopupContainer={() =>
                    document.getElementById('VideoRoomSettings')
                  }
                  defaultValue={defaultVideoInput.label}
                  className="cc-VideoRoomSettings-input"
                  size="large"
                  style={{ width: '100%', marginTop: '16px' }}
                  onChange={(e) => {
                    handleVideoInputChange(e)
                  }}
                >
                  {videoInputs &&
                    videoInputs.map((v) => (
                      <Option key={v.groupId} value={v.deviceId}>
                        {v.label}
                      </Option>
                    ))}
                </Select>
              </div>
            )}
          </div>
          {!audioOnly && (
            <Row>
              <video
                ref={videoRef}
                onCanPlay={handleCanPlay}
                autoPlay
                playsInline
                width="100%"
                height="100%"
                muted={true}
                style={{ marginTop: '15px', height: '100%', width: '100%' }}
              />
            </Row>
          )}
        </Col>
      </Row>
    </Loading>
  )
}
VideoRoomSettings.propTypes = {
  visible: PropTypes.bool.isRequired,
  changeAudioDevices: PropTypes.func,
  changeVideoDevices: PropTypes.func,
  changeAudioOutDevices: PropTypes.func,
  audioOutputDeviceId: PropTypes.string,
}

export default VideoRoomSettings
