import * as React from 'react'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import { useCompareFundsContext } from '../compareFundsContext'

export function useTrackComparedFunds(...optionKeys) {
  const { trackEvent } = useTracking()
  const { funds, chartType, options } = useCompareFundsContext()
  const serializedFundIds = map(funds, 'fundId').join(',')

  React.useEffect(() => {
    trackEvent({ eventName: 'view', fundIds: serializedFundIds })
  }, [trackEvent, serializedFundIds])

  React.useEffect(() => {
    trackEvent({ eventName: 'click', element: 'CompareFundsTabs', chartType })
  }, [trackEvent, chartType])

  const optionsToReport = useArrayMemo(optionKeys.map((key) => options[key]))
  React.useEffect(() => {
    api.funds.trackCompareFunds(
      serializedFundIds.split(','),
      chartType,
      ...optionsToReport
    )
  }, [optionsToReport, serializedFundIds, chartType])

  return React.useCallback(
    (element, additionalTraits = {}) => {
      trackEvent({ eventName: 'click', element, ...additionalTraits })
    },
    [trackEvent]
  )
}

function useArrayMemo(arr) {
  const memoized = React.useRef(arr)
  if (!isEqual(arr, memoized.current)) {
    memoized.current = arr
  }
  return memoized.current
}
