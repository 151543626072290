import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import {
  ArrowDownward,
  ArrowUpward,
  ExpandLess,
  ExpandMore,
} from '@context365/icons'
import { Dropdown, Menu, Space, Tooltip } from 'antd'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import fromPairs from 'lodash/fromPairs'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import startsWith from 'lodash/startsWith'
import './Sorter.less'

const Sorter = ({ options, activeField, onSort, onReset, defaultLabel }) => {
  useEffect(() => {
    document.body.style.overflow = 'overlay'
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [])

  const initialTooltipShowState = fromPairs(
    flatten(
      map(options, (opt) => [
        [`${opt.name}`, false],
        [`-${opt.name}`, false],
      ])
    )
  )
  const [showTooltip, setShowTooltip] = useState(initialTooltipShowState)

  const isDescending = startsWith(activeField, '-') || !activeField
  const activeFieldName = isDescending ? activeField?.substring(1) : activeField
  const activeOption = isNil(activeFieldName)
    ? null
    : find(options, (opt) => opt.name === activeFieldName)
  const dropdownLabel = isNil(activeOption)
    ? defaultLabel ?? 'Select a field'
    : activeOption.label
  const selectedKeys = map(
    filter(options, (opt) => opt.name === activeFieldName),
    (opt) => opt.name
  )

  const handleSort = (fieldName, sortType) => {
    setShowTooltip(initialTooltipShowState)
    if (isFunction(onSort)) {
      onSort(fieldName, sortType)
    }
  }

  const handleMouseEnter = (sortKey) => {
    setShowTooltip((prevShowTooltip) => ({
      ...prevShowTooltip,
      [sortKey]: true,
    }))
  }

  const handleMouseLeave = (sortKey) => {
    setShowTooltip((prevShowTooltip) => ({
      ...prevShowTooltip,
      [sortKey]: false,
    }))
  }

  const optionsMenu = (
    <Menu className="Sorter-menu" selectedKeys={selectedKeys}>
      {map(options, (option) => (
        <Menu.Item key={option.name}>
          <div className="Sorter-menu-item">
            <span className="Sorter-menu-item-text">{option.label}</span>
            <Space className="Sorter-menu-item-actions">
              <span
                onMouseEnter={() => handleMouseEnter(`-${option.name}`)}
                onMouseLeave={() => handleMouseLeave(`-${option.name}`)}
              >
                <Tooltip
                  title="Highest to lowest"
                  visible={showTooltip[`-${option.name}`]}
                >
                  <IconButton
                    label="descending"
                    disabled={option.name === activeFieldName && isDescending}
                    icon={<ArrowDownward />}
                    onClick={() => handleSort(option.name, 'desc')}
                  />
                </Tooltip>
              </span>
              <span
                onMouseEnter={() => handleMouseEnter(option.name)}
                onMouseLeave={() => handleMouseLeave(option.name)}
              >
                <Tooltip
                  title="Lowest to highest"
                  visible={showTooltip[option.name]}
                >
                  <IconButton
                    label="ascending"
                    disabled={option.name === activeFieldName && !isDescending}
                    icon={<ArrowUpward />}
                    onClick={() => handleSort(option.name, 'asc')}
                  />
                </Tooltip>
              </span>
            </Space>
          </div>
        </Menu.Item>
      ))}
      <Menu.Item key="reset-btn" style={{ textAlign: 'center' }}>
        <Button variant="link" onClick={onReset}>
          Reset to default sorting
        </Button>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="flex gap-2">
      <span>Sort by:</span>
      <Dropdown overlay={optionsMenu} placement="bottomRight">
        <Button
          className="Sorter-btn"
          style={{
            padding: '0px',
          }}
          variant="link"
          size="big"
          iconRight={isDescending ? <ExpandMore /> : <ExpandLess />}
        >
          {dropdownLabel}
        </Button>
      </Dropdown>
    </div>
  )
}

Sorter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  activeField: PropTypes.string,
  onSort: PropTypes.func,
  onReset: PropTypes.func,
  defaultLabel: PropTypes.string,
}

export default Sorter
