import { useEffect, useState } from 'react'
import { Badge } from '@context365/badge'
import { Button } from '@context365/button'
import { Select, TextArea } from '@context365/forms'
import { Tooltip } from '@context365/popovers'
import { Divider } from 'antd'
import find from 'lodash/find'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import some from 'lodash/some'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import Loading from '~/components/Loading'
import Profile from '~/components/Profile'
import { CAMPAIGN_ENTITY_TYPES } from '~/constants/campaignEntityTypes'
import useAuth from '~/hooks/useAuth'
import { getApplyToCampaignModal, hideModal } from '~/store/modals'
import { shorten } from '~/utils/helpers'
import MatchmakingScore from '../../MatchmakingScore'
import SuccessfulApplicationModal from '../SuccessfulApplicationModal'

export default function ApplyToCampaignModal() {
  const { Track } = useTracking({ component: 'ApplyToCampaignModal' })
  const { company } = useAuth()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const {
    visible,
    campaignName,
    campaignThreshold,
    campaignType,
    campaignId,
    campaignApplications,
    defaultApplyingCampaignId = null,
  } = useSelector(getApplyToCampaignModal)
  const [applyMessage, setApplyMessage] = useState()
  const [selectedCampaignId, setSelectedCampaignId] = useState(
    defaultApplyingCampaignId
  )
  const [appliedWithCampaign, setAppliedWithCampaign] = useState(null)

  useEffect(
    () => setSelectedCampaignId(defaultApplyingCampaignId),
    [defaultApplyingCampaignId]
  )

  const { data: campaignResults, isLoading } = useQuery(
    ['myCampaigns', company.companyId, campaignId],
    () =>
      api.campaigns.getMyCampaigns(company.companyId, {
        searchTerm: '',
        targetCampaignId: campaignId,
      }),
    {
      enabled: visible && campaignId != null,
    }
  )

  const resetForm = () => {
    setApplyMessage(null)
    setSelectedCampaignId(null)
  }

  const { mutateAsync: applyToCampaign } = useMutation(
    () =>
      api.campaigns.applyToCampaign({
        toCampaignId: campaignId,
        fromCampaignId: selectedCampaignId,
        message: applyMessage,
      }),
    {
      onSuccess: (res) => {
        queryClient.setQueriesData(['discover', campaignType], (data) => ({
          ...data,
          result: data.result.map((item) =>
            item.campaignId === campaignId
              ? {
                  ...item,
                  campaignApplications: campaignApplications.concat([
                    {
                      campaignId: selectedCampaignId,
                      applicationId: res.data.result,
                      wasSent: true,
                    },
                  ]),
                }
              : item
          ),
        }))
        resetForm()
        dispatch(hideModal())
        const appliedWithCampaignResult = find(
          campaignResults.results,
          (cr) => cr.campaignId === selectedCampaignId
        )
        setAppliedWithCampaign({
          id: appliedWithCampaignResult.campaignId,
          name: appliedWithCampaignResult.name,
        })
        queryClient.invalidateQueries('discoverRemainingCredits')
      },
    }
  )

  const campaigns = orderBy(
    campaignResults?.results,
    (campaign) => campaign.matchmakingScore ?? 0,
    'desc'
  )

  return (
    <>
      <Track>
        <Profile.Modal
          visible={visible}
          onClose={() => {
            resetForm()
            dispatch(hideModal())
          }}
          header={<div className="type-header-sm">Indicate Interest</div>}
          width={400}
          body={
            isLoading ? (
              <div className="flex justify-center py-20">
                <Loading />
              </div>
            ) : (
              <div className="p-4 flex flex-col gap-4">
                <p>
                  By indicating interest, your company will be added to the
                  campaign poster’s pipeline, and in your pipeline to track your
                  application status.
                </p>
                <div>
                  <div className="type-body-regular-sm">
                    Indicate interest in pipeline
                  </div>
                  <div className="type-body-semibold-md my-2 p-2 bg-grey-50">
                    {campaignName}
                  </div>
                </div>
                <div>
                  <TextArea
                    rows={4}
                    label="Message"
                    value={applyMessage}
                    onChange={(e) => setApplyMessage(e.target.value)}
                  />
                </div>

                <Divider className="m-1" />

                <div className="flex flex-col gap-2">
                  <div className="type-body-regular-sm">Your company </div>
                  <div className="type-body-semibold-md p-2 bg-grey-50">
                    {company.name}
                  </div>
                  <div className="type-body-regular-xs">
                    To <u>apply with a different company</u>, you&apos;ll need
                    to switch.
                  </div>
                </div>
                <div className="flex flex-col gap-2 mt-2">
                  <Select
                    label="Your pipeline"
                    required
                    value={selectedCampaignId}
                    onChange={(value) => setSelectedCampaignId(value)}
                  >
                    {map(campaigns, (campaign) => {
                      const alreadyApplied = some(
                        campaignApplications,
                        (ca) => ca.campaignId === campaign.campaignId
                      )
                      return (
                        <Select.Option
                          key={campaign.campaignId}
                          value={campaign.campaignId}
                          label={campaign.name}
                          disabled={alreadyApplied}
                        >
                          <Tooltip
                            text={
                              alreadyApplied &&
                              `${campaign.name} is already in ${campaignName}'s pipeline`
                            }
                          >
                            <div className="flex flex-row w-80 py-2 justify-between items-center cursor-pointer">
                              <div>{shorten(campaign.name, 30)}</div>
                              <div className="flex flex-row items-center">
                                {campaign.campaignEntityTypeId !==
                                  CAMPAIGN_ENTITY_TYPES.GENERAL_INTEREST && (
                                  <Badge>{campaign.campaignEntityType}</Badge>
                                )}
                                <div className="w-6">
                                  {campaign.matchmakingScore && (
                                    <MatchmakingScore
                                      score={campaign.matchmakingScore}
                                      threshold={campaignThreshold ?? 0}
                                      size={30}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </Tooltip>
                        </Select.Option>
                      )
                    })}
                  </Select>
                  <div className="type-body-regular-xs">
                    Choose where you&apos;d like to track the status of this
                    application.
                  </div>
                </div>
              </div>
            )
          }
          footer={
            <div className="flex flex-row gap-2 justify-end">
              <Button
                onClick={() => {
                  resetForm()
                  dispatch(hideModal())
                }}
              >
                Cancel
              </Button>
              <Button
                variant="filled"
                disabled={!selectedCampaignId}
                onClick={() => applyToCampaign()}
              >
                Indicate Interest
              </Button>
            </div>
          }
        />
      </Track>
      <SuccessfulApplicationModal
        visible={!!appliedWithCampaign}
        applyingCampaignId={appliedWithCampaign?.id}
        applyingCampaignName={appliedWithCampaign?.name}
        onClose={() => setAppliedWithCampaign(null)}
      />
    </>
  )
}
