import * as React from 'react'
import PropTypes from 'prop-types'
import { List, Tabs } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCompanyIfNeeded } from '~/actions/company'
import { getCompanyId } from '~/selectors/auth'
import FiltersDisplay from '../DiscoverFilters/FiltersDisplay'
import SummitAlert from '../DiscoverTabs/SummitAlert'
import Loading from '../Loading'
import NoValuesView from '../NoValuesView'
import Paginator from '../Paginator'
import { useDiscover } from './context'
import useUpcomingEvent from './useUpcomingEvent'

const { TabPane } = Tabs

const DiscoverTabs = ({
  hideEventAlert = false,
  renderResultCard,
  renderTableView,
  renderMapView,
  ...props
}) => {
  const dispatch = useDispatch()
  const companyId = useSelector(getCompanyId)
  const { newEventsPage } = useFlags()

  const {
    filterResults,
    activeFilters,
    showOnlyMatched,
    searchText,
    activeTab,
    meetingStatus,
    category,
    loading,
    currentPage,
    total,
    loadPage,
    selectedId,
    onChangeSelectedId,
    pageSize,
  } = useDiscover(props)

  React.useEffect(() => {
    dispatch(fetchCompanyIfNeeded(companyId))
  }, [dispatch, companyId])

  const upcomingEvent = useUpcomingEvent()

  return (
    <Tabs
      className="w-full"
      renderTabBar={() => null}
      defaultActiveKey="cards"
      activeKey={activeTab}
    >
      <TabPane key="cards" tab="cards">
        {activeTab === 'cards' && (
          <div id="scrollDiv">
            {!isNil(upcomingEvent) && !hideEventAlert && newEventsPage && (
              <SummitAlert
                category={category}
                eventName={upcomingEvent.name}
                isVirtual={upcomingEvent.virtualSummit}
              />
            )}
            {activeFilters?.length > 0 && (
              <FiltersDisplay
                className="text-left mb-5"
                filters={activeFilters}
              />
            )}
            {loading && <Loading />}
            {!loading && isEmpty(filterResults) ? (
              <NoValuesView
                title={`No visible ${category}`}
                subTitle={`Try selecting other filters in order to view ${category}`}
              />
            ) : (
              <>
                <List
                  dataSource={filterResults}
                  renderItem={(item) =>
                    renderResultCard({
                      item,
                      selectedId,
                      onChangeSelectedId,
                    })
                  }
                />
                <Paginator
                  currentPage={currentPage}
                  total={total}
                  loadPage={loadPage}
                  pageSize={pageSize}
                />
              </>
            )}
          </div>
        )}
      </TabPane>

      {renderTableView && (
        <TabPane key="tabular" tab="table">
          {activeFilters?.length > 0 && (
            <FiltersDisplay
              className="text-left mb-5"
              filters={activeFilters}
            />
          )}
          {renderTableView({
            data: filterResults,
            loadPage,
            currentPage,
            total,
            selectedId,
            onChangeSelectedId,
            pageSize,
          })}
        </TabPane>
      )}

      {renderMapView && (
        <TabPane key="map" tab="map">
          {renderMapView({
            activeFilters,
            showOnlyMatched,
            searchText,
            meetingStatus,
          })}
        </TabPane>
      )}
    </Tabs>
  )
}

DiscoverTabs.propTypes = {
  hideEventAlert: PropTypes.bool,
  activeTab: PropTypes.string,
  category: PropTypes.string,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadNextPage: PropTypes.func,
  results: PropTypes.array,
  renderResultCard: PropTypes.func.isRequired,
  renderTableView: PropTypes.func,
  renderMapView: PropTypes.func,
}

export default DiscoverTabs
