import * as React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  destroyTwilioClient,
  fetchConversationCount,
  getMessageAccessToken,
  initializeTwilioClient,
} from '~/actions/messages'
import { fetchAccess } from '~/actions/userAccess'
import OnlineMeetingNotification from '~/components/OnlineMeetingNotification'
import SignalrProvider from '~/components/Presentations/SignalrProvider'
import { getUserId } from '~/selectors/auth'

export default function AuthenticatedAppProvider({
  initializeMessages = true,
  children,
}) {
  useInitializeMessaging(initializeMessages)
  useFetchUserAccess()

  return (
    <SignalrProvider>
      <OnlineMeetingNotification />
      {children}
    </SignalrProvider>
  )
}

AuthenticatedAppProvider.propTypes = {
  initializeMessages: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

function useInitializeMessaging(shouldInitialize) {
  const dispatch = useDispatch()
  const contactId = useSelector(getUserId)

  React.useEffect(() => {
    if (!shouldInitialize) {
      return undefined
    }

    dispatch(fetchConversationCount())
    getMessageAccessToken().then((response) => {
      return dispatch(initializeTwilioClient(contactId, response.data.message))
    })

    return () => {
      dispatch(destroyTwilioClient())
    }
  }, [dispatch, contactId, shouldInitialize])
}

function useFetchUserAccess() {
  const dispatch = useDispatch()
  const hasLoaded = useSelector((state) => state.userAccess.hasLoaded)

  React.useEffect(() => {
    if (hasLoaded) return
    dispatch(fetchAccess())
  }, [dispatch, hasLoaded])
}
