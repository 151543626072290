import PropTypes from 'prop-types'
import { IconButton } from '@context365/button'
import { Tooltip } from '@context365/popovers'
import { Link } from 'react-router-dom'
import { useTracking } from 'react-tracking'

const TopbarLink = ({ icon, label, to, blocked, badge }) => {
  const { trackEvent } = useTracking({ element: 'TopbarLink' })

  const link = (
    <Tooltip text={label}>
      <IconButton
        className="text-primary-100 h-6"
        variant="none"
        icon={icon}
        label={label}
        as={Link}
        to={to}
        disabled={blocked}
        onClick={() => trackEvent({ eventName: 'click', label })}
      />
    </Tooltip>
  )

  if (badge) {
    return (
      <div className="relative h-6">
        {link}
        <div className="absolute -top-3 -right-3 pointer-events-none">
          {badge}
        </div>
      </div>
    )
  }

  return link
}

TopbarLink.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string,
  to: PropTypes.string,
  blocked: PropTypes.bool,
  badge: PropTypes.node,
}

export default TopbarLink
