import { api } from './services'

export const getContinents = () =>
  api.get('/on-boarding/continents').then((res) => res.data.result)

export const getCountries = (continent) =>
  api
    .get(`/on-boarding/continents/${continent}/countries`)
    .then((res) => res.data.result)

export const searchByPostalCode = ({ postalCode, country }) =>
  api
    .get(`/locations/countries/${country}/zipcode/${postalCode}`)
    .then((res) => res.data.result)
