import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Modal, Row } from 'antd'
import { useGoogleMaps } from 'react-hook-google-maps'
import { useSelector } from 'react-redux'
import { api as http } from '~/api/services'
import default_marker from '~/assets/default_marker.svg'
import roadshow_marker from '~/assets/roadshow_marker.svg'
import { GEOCODING_API_KEY } from '~/config'
import useMountedState from '~/hooks/useMountedState'
import MarkersDataList from './MarkersDataList'

const ContextMap = ({
  markers,
  fetchMarkers,
  filter,
  searchTerm = '',
  showMatched,
  selectedFund,
  representedFund,
  fundContacts,
  meetingStatusFilter,
  pageSize,
  isMember,
  resultId,
  filterBy,
}) => {
  const role = useSelector((state) => state.auth.role)

  const isSPTab = window.location.pathname.indexOf('serviceproviders') > 0
  //const isFundsTab = window.location.pathname.indexOf('funds') > 0;

  const [modalVisible, setModalVisible] = useState(false)
  const [selectedMarkerCoord, setSelectedMarkerCoord] = useState([])

  const [mapCenterLat, setMapCenterLat] = useMountedState()
  const [mapCenterLng, setMapCenterLng] = useMountedState()
  const [southWest, setSouthWest] = useMountedState(null)
  const [northEast, setNorthEast] = useMountedState(null)
  const [bounds, setBounds] = useState([])
  const [selectedMarkerIsRoadshow, setSelectedMarkerIsRoadshow] =
    useState(false)
  const [selectedMarkerCity, setSelectedMarkerCity] = useState('')
  // const [pageSize, setPageSize] = useState(1);

  //const [markers, setMarkers] = useState(null);
  //const [roadshowMarkers] = useState(null);

  const [markersOnMap, setMarkersOnMap] = useState([])
  const [mapMargin, setMapMargin] = useState(0)

  const [mapMargininPx] = useState(50)

  const [mapMarginPerc, setMapMarginPerc] = useState(0)
  const [mapDistanceSize, setMapDistanceSize] = useState(0)

  //const contactId = useSelector(getUserId);
  const styles = require('./GoogleMapStyles')
  const { ref, map, google } = useGoogleMaps(GEOCODING_API_KEY, {
    zoom: 8,
    minZoom: 3,
    styles,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    zoomControl: false,
    options: {
      gestureHandling: 'greedy',
    },
  })

  const degreesToRadians = useCallback(
    (degrees) => (degrees * Math.PI) / 180,
    []
  )

  const getDistanceBetweenPoints = useCallback(
    (lat1, lng1, lat2, lng2) => {
      // The radius of the planet earth in meters
      const R = 6378137
      const dLat = degreesToRadians(lat2 - lat1)
      const dLong = degreesToRadians(lng2 - lng1)
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(degreesToRadians(lat1)) *
          Math.cos(degreesToRadians(lat1)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2)

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const distance = R * c

      return distance
    },
    [degreesToRadians]
  )

  const calculateMapMargin = useCallback(() => {
    if (map && mapMargin === 0) {
      const pixelSize = 2 ** -map.getZoom()

      setMapMargin(
        Math.sqrt((mapMargininPx * pixelSize * pixelSize * mapMargininPx) / 2)
      )
    }
  }, [map, mapMargin, mapMargininPx])

  const fetchUserGeoLocation = () => {
    http
      .get('colleagues/location')
      .then((response) => {
        setMapCenterLat(parseFloat(response.data.result.location.lat))
        setMapCenterLng(parseFloat(response.data.result.location.lng))
        setSouthWest(response.data.result.viewport.southwest)
        setNorthEast(response.data.result.viewport.northeast)
      })
      .catch((error) => console.log('c', error))
  }

  //get default map center and boundaries
  useEffect(() => {
    fetchUserGeoLocation()
  }, [])

  useEffect(() => {
    if (
      map &&
      mapCenterLng &&
      mapCenterLat &&
      southWest &&
      northEast &&
      google &&
      !isNaN(parseFloat(mapCenterLng) && !isNaN(parseFloat(mapCenterLat)))
    ) {
      map.setCenter({
        lat: mapCenterLat,
        lng: mapCenterLng,
      })

      //set bounds to state
      if (southWest !== undefined && northEast !== undefined) {
        map.fitBounds(new google.maps.LatLngBounds(southWest, northEast))
        calculateMapMargin()
      }
      google.maps.event.addListener(map, 'idle', () => {
        const mapBounds = map.getBounds()
        const NorthWest = [
          mapBounds.getNorthEast().lat(),
          mapBounds.getSouthWest().lng(),
        ]
        const SouthEast = [
          mapBounds.getSouthWest().lat(),
          mapBounds.getNorthEast().lng(),
        ]

        setBounds([NorthWest, SouthEast])

        const mSize = getDistanceBetweenPoints(
          mapBounds.getNorthEast().lat(),
          mapBounds.getNorthEast().lng(),
          mapBounds.getSouthWest().lat(),
          mapBounds.getSouthWest().lng()
        )
        setMapDistanceSize(mSize)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google, map, mapCenterLat, mapCenterLng, northEast, southWest])

  //calculate mspmarginperc
  useEffect(() => {
    fetchMarkers(filter, searchTerm, showMatched, meetingStatusFilter, bounds)
  }, [
    bounds,
    fetchMarkers,
    filter,
    meetingStatusFilter,
    searchTerm,
    showMatched,
  ])

  //calculate mspmarginperc
  useEffect(() => {
    if (mapMargin !== 0 && mapMarginPerc === 0 && mapDistanceSize !== 0) {
      setMapMarginPerc(mapMargin / mapDistanceSize)
    }
  }, [mapDistanceSize, mapMargin, mapMarginPerc])

  useEffect(() => {
    if (map && google && markersOnMap) {
      let newM = []
      markersOnMap.forEach((item) => {
        item.setMap(null)
      })
      const lookup = []

      if (markers) {
        markers.forEach((item) => {
          const markerIsRoadshow = item.isRoadshow
          const markerCoords = item.coordinate
          const markerCnt = item.count
          const markerCity = item.city
          let finalLatLng = new google.maps.LatLng(
            markerCoords.lat,
            markerCoords.lng
          )

          for (let i = 0, l = lookup.length; i < l; i++) {
            if (
              lookup[i][0] === markerCoords.lat &&
              lookup[i][1] === markerCoords.lng
            ) {
              const newLat = markerCoords.lat + mapMarginPerc * mapDistanceSize // * (Math.random() * (max - min) + min);
              const newLng = markerCoords.lng + mapMarginPerc * mapDistanceSize // * (Math.random() * (max - min) + min);
              finalLatLng = new google.maps.LatLng(newLat, newLng)
            }
          }

          const marker = new google.maps.Marker({
            map,
            position: finalLatLng,
            label: {
              text: markerCnt,
              color: 'white',
            },
            title: markerCity,
            icon: markerIsRoadshow ? roadshow_marker : default_marker,
            zIndex: 10,
            customInfo: markerIsRoadshow,
          })

          //marker.setAnimation(google.maps.Animation.BOUNCE);
          marker.addListener('click', () => {
            setSelectedMarkerCoord(markerCoords)
            setSelectedMarkerIsRoadshow(markerIsRoadshow)
            setSelectedMarkerCity(markerCity)
            setModalVisible(true)
          })
          newM = newM.concat(marker)
          lookup.push([markerCoords.lat, markerCoords.lng])
        })
      }
      setMarkersOnMap(newM)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapDistanceSize, mapMarginPerc, markers])

  return (
    <>
      <Modal
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false)
        }}
        width="460px"
        bodyStyle={{ padding: '24px 0px 24px 0px' }}
        footer={null}
      >
        <MarkersDataList
          coordinates={selectedMarkerCoord}
          bounds={bounds}
          role={role}
          isSPList={isSPTab}
          isRoadshow={selectedMarkerIsRoadshow}
          city={selectedMarkerCity}
          filter={filter}
          searchTerm={searchTerm}
          showMatched={showMatched}
          pageSize={pageSize}
          selectedFund={selectedFund}
          representedFund={representedFund}
          fundContacts={fundContacts}
          meetingStatusFilter={meetingStatusFilter}
          isMember={isMember}
          filterBy={filterBy}
          resultId={resultId}
        />
      </Modal>
      <Row>
        <Col span={24}>
          <div>
            <div ref={ref} style={{ height: `calc(100vh - 200px)` }} />
          </div>
        </Col>
      </Row>
    </>
  )
}
ContextMap.propTypes = {
  markers: PropTypes.array,
  fetchMarkers: PropTypes.func.isRequired,
  filter: PropTypes.array,
  searchTerm: PropTypes.string,
  showMatched: PropTypes.bool,
  selectedFund: PropTypes.number,
  representedFund: PropTypes.string,
  meetingStatusFilter: PropTypes.array,
  resultId: PropTypes.number,
  fundContacts: PropTypes.array,
  pageSize: PropTypes.number,
  filterBy: PropTypes.bool,
  isMember: PropTypes.bool,
}

export default ContextMap
