import * as api from '~/api'
import { api as http } from '~/api/services'
import {
  COMPANY_FETCHING,
  COMPANY_FETCH_FAILURE,
  COMPANY_FETCH_SUCCESS,
} from '~/constants/types/company'

export const getCompany = () => ({
  type: COMPANY_FETCHING,
})

export const getCompanySuccess = (company) => ({
  type: COMPANY_FETCH_SUCCESS,
  payload: company,
})

export const getCompanyFailure = (error) => ({
  type: COMPANY_FETCH_FAILURE,
  payload: error,
})

export const shouldFetchCompany = (state) => !state.company.isFetching

export const fetchCompany = (companyId) => (dispatch) => {
  dispatch(getCompany())

  api.companies
    .getCompanyDetails(companyId)
    .then((company) => dispatch(getCompanySuccess(company)))
    .catch((error) => dispatch(getCompanyFailure(error)))
}

export const fetchCompanyIfNeeded = (companyId) => (dispatch, getState) => {
  if (shouldFetchCompany(getState())) {
    dispatch(fetchCompany(companyId))
  }
}

export const getCompaniesContacts = (firstCompanyId, secondCompanyId) =>
  http.get(`/companies/${firstCompanyId},${secondCompanyId}/contacts`)

export const getCompaniesContactsAllocatorsWithMatchmakingScore = (
  companyId,
  fundId,
  roadshowId
) =>
  http.get(
    `/companies/${companyId}/contacts/matchmaking/${fundId}${
      roadshowId ? `?roadshowId=${roadshowId}` : ''
    }`
  )

export const getCompaniesContactsAllocatorsWithoutMatchmakingScore = (
  companyId
) => http.get(`/companies/${companyId}/contacts/nomatchmaking`)

export const makeAdministrator = (contactId) =>
  http.put(`/colleagues/${contactId}/admin`)

export const deleteColleague = (contactId) =>
  http.delete(`/colleagues/${contactId}`)

export const inviteColleague = (email) =>
  http.post('/colleagues/invite', { email })

export const logProfileView = (profile) =>
  // This is just fire-and-forget, so don't display an error to the user
  http.post('/company/viewprofile', profile).catch(() => {})
