import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MailOutlined } from '@context365/icons'
import { faCheck, faMinusCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Empty, Row, Table, Tag, Tooltip, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchReceivedRequests } from '~/actions/contextMeetings'
import { getMeetingList } from '~/actions/meetings'
import AcceptMeetingComponent from '~/components/AcceptMeetingComponent'
import DeclineAllMeetingsModal from '~/components/DeclineAllMeetingsModal'
import DeclineOnlineMeeting from '~/components/DeclineOnlineMeeting'
import Loading from '~/components/Loading'
import { AcceptSummitMeeting } from '~/components/MeetingActions'
import MeetingsCardView from '~/components/MeetingsCardView'
import ResponsiveCard, {
  DATE,
} from '~/components/Responsive/ResponsiveCards/OnlineMeetingRecivedRequestResponsiveCard'
import SearchBox from '~/components/SearchBox'
import TimeDisplay from '~/components/TimeDisplay'
import useSearchParams from '~/hooks/useSearchParams'
import { getUserId } from '~/selectors/auth'
import { selectedEventHasEnded } from '~/selectors/events'
import { boldCompanyNameFormat } from '~/utils/helpers'
import { renderParticipantsAvatars } from '~/utils/meetingActions'
import EmptyEventTablesState from './EmptyEventTablesState'
import './ReceivedRequestsTable.less'

const tooltTipText = <span>This meeting carries Context Points.</span>

const ReceivedRequestsTable = ({
  activeSummit = true,
  meetingType,
  allowMultipleCompaniesMeeting = false,
  meetingTypeId = 1,
  eventId,
  width,
  fromSummit,
  refreshTable,
  onCancelRequest,
  onAcceptRequest,
}) => {
  const [showDeclineAllModal, setShowDeclineAllModal] = useState(false)
  const [showDeclineOnlineModal, setShowDeclineOnlineModal] = useState(false)
  const [showAcceptRequestModal, setShowAcceptRequestModal] = useState(false)
  const [showAcceptOnlineRequestModal, setShowAcceptOnlineRequestModal] =
    useState(false)
  const [selectedRecord, setSelectedRecord] = useState({})
  const [displayTimezone, setDisplayTimezone] = useState(TimeDisplay.ORIGINAL)
  const [displayTimezoneLabel, setDisplayTimezoneLabel] =
    useState('Event Timezone')
  const [refresh, setRefresh] = useState(refreshTable)
  const [isLoading, setIsLoading] = useState(false)
  const [allRequestsList, setAllRequestsList] = useState([])
  const [hasNext, setHasNext] = useState(false)
  const role = useSelector((state) => state.auth.role)
  const contactId = useSelector(getUserId)

  const [searchParams, setSearchParams] = useSearchParams()

  const updateQueryParams = React.useCallback(
    (updatedFields) => {
      setSearchParams({
        ...searchParams,
        ...updatedFields,
      })
    },
    [searchParams, setSearchParams]
  )

  const resetPage = React.useCallback(
    (updatedFields = {}) => {
      updateQueryParams({ ...updatedFields, page: 1 })
    },
    [updateQueryParams]
  )

  const { page, searchQuery, pageSize } = React.useMemo(() => {
    const { page = 1, search = '', size = 10 } = searchParams

    return {
      page: parseInt(page, 10),
      searchQuery: search,
      pageSize: parseInt(size, 10),
    }
  }, [searchParams])

  const loadPage = React.useCallback(
    (page, size) => {
      updateQueryParams({ page, size })
    },
    [updateQueryParams]
  )

  const searchByText = React.useCallback(
    (text) => {
      resetPage({ search: text })
    },
    [resetPage]
  )

  const requestsList = useSelector((state) => {
    if (eventId > 0) {
      return state.meetings.requestedMeetingsCompanyEventId
    } else if (meetingType.toLowerCase() === 'online')
      return state.meetings.onlineRequestedMeetings
    else if (meetingType.toLowerCase() === 'roadshow')
      return state.meetings.roadshowRequestedMeetings
    return state.meetings.requestedMeetings
  })

  const pastSummit = useSelector(selectedEventHasEnded)

  const total = isNil(requestsList) ? 0 : requestsList.itemCount
  const dispatch = useDispatch()

  const getReceivedList = useCallback(
    (page, pageSize) => {
      if (allowMultipleCompaniesMeeting) {
        getMeetingList(page, pageSize, 'received', searchQuery, meetingTypeId)
          .then((response) => {
            page !== 1
              ? setAllRequestsList((f) =>
                  f.concat(response.data.result.results)
                )
              : setAllRequestsList(response.data.result.results)
            setHasNext(response.data.result.hasNextPage)
          })
          .catch((error) => {
            if (!error?.response?.data?.reason === 'Tier')
              message.error('Could not load meetings')
          })
      }
    },
    [allowMultipleCompaniesMeeting, searchQuery, meetingTypeId]
  )
  useEffect(() => {
    getReceivedList(page, pageSize)
  }, [page, pageSize, getReceivedList])

  useEffect(() => {
    setRefresh(refreshTable)
  }, [refreshTable])

  useEffect(() => {
    const params = { page, pageSize }

    if (!isNil(searchQuery) && !isEmpty(searchQuery)) {
      params.q = trim(searchQuery)
    }

    setIsLoading(true)
    dispatch(
      fetchReceivedRequests({
        meetingTypeId,
        eventId,
        ...params,
      })
    ).finally(() => {
      setIsLoading(false)
    })
  }, [dispatch, eventId, meetingTypeId, page, pageSize, searchQuery, refresh])

  const handleCancelClick = (record) => {
    setSelectedRecord(record)

    setShowDeclineOnlineModal(true)
  }

  const renderActions = (record) => (
    <div className="cc-meeting-actions">
      <Tooltip placement="top" title="Accept">
        {' '}
        <Button
          type="primary"
          onClick={(e) => {
            meetingType === 'Summit'
              ? setShowAcceptRequestModal(true)
              : setShowAcceptOnlineRequestModal(true)
            setSelectedRecord(record)
            e.stopPropagation()
          }}
          className="cc-btn cc-btn-success"
        >
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      </Tooltip>
      <Tooltip placement="top" title="Decline">
        <Button
          onClick={(e) => {
            handleCancelClick(record)
            e.stopPropagation()
          }}
          className="cc-btn cc-btn-danger"
        >
          <i className="cc-btn-bg" />
          <FontAwesomeIcon color="#D62B34" icon={faMinusCircle} />
        </Button>
      </Tooltip>
    </div>
  )

  const handleInfiniteOnLoad = useCallback(() => {
    if (allRequestsList?.length > 0 && hasNext) {
      loadPage(page + 1, pageSize)
    }
  }, [allRequestsList, hasNext, loadPage, page, pageSize])

  const getColumns = () => {
    const columns = [
      // {
      //   title: 'REQUEST FROM',
      //   render: renderMember,
      //   contactDataIndex: 'member',
      // },
      {
        title: 'MEETING WITH',
        width: 400,
        render: (record) => (
          <div>
            <div>
              <Link
                to={{
                  pathname: `/meeting/${meetingType.toLowerCase()}/${
                    record.meetingId
                  }`,
                  state: { isReceived: true },
                }}
              >
                <span className="cc-body-text">
                  {record.company.companyName}
                </span>
              </Link>
              <div className="ReceivedRequestsTable-category">
                {record.categoryName}

                {!isNil(record.invitedByContact) && (
                  <span className="ReceivedRequestsTable-tag">
                    Invited by {record.invitedByContact.contactName}
                  </span>
                )}
              </div>
            </div>
            {record.carryContextPoints && (
              <span>
                <Tooltip placement="top" title={tooltTipText}>
                  <Tag visible={record.carryContextPoints}>
                    + 1000 Context Points
                  </Tag>
                </Tooltip>
              </span>
            )}
          </div>
        ),
      },
      {
        title: 'PARTICIPANTS',
        width: 350,
        render: renderParticipantsAvatars,
      },
      meetingType.toLowerCase() !== 'summit'
        ? {
            cardType: DATE,
            align: 'center',
            title: 'Meeting Date & Time ',
            dataIndex: 'meetingDateTime',
            render: (meetingDateTime) =>
              moment(meetingDateTime).isValid()
                ? moment
                    .utc(meetingDateTime)
                    .local()
                    .format('YYYY-MM-DD h:mm A')
                : 'TBD',
          }
        : {},
      {
        title: 'MESSAGE',
        dataIndex: 'meetingMessage',
        align: 'center',
        render: (value) => (
          <Tooltip
            overlayClassName="MessageTooltip"
            title={isEmpty(value) ? 'No message' : value}
          >
            <MailOutlined
              size={32}
              className={isEmpty(value) ? undefined : 'text-secondary-100'}
            />
          </Tooltip>
        ),
      },
    ]
    const eventName = {
      title: 'Summit',
      className: 'cc-body-text',
      dataIndex: 'eventName',
    }

    const actions = {
      title: 'ACTIONS',
      render: renderActions,
      align: 'left',
      notRenderInCard: true,
    }

    if (pastSummit && meetingType.toLowerCase() === 'summit') return columns
    else if (!fromSummit && meetingTypeId === 1) columns.splice(3, 0, eventName)

    return columns.concat(actions)
  }

  const meetingWithString = boldCompanyNameFormat(selectedRecord)

  if (+width < 1025 && !allowMultipleCompaniesMeeting) {
    if (total === 0 && !isLoading)
      return <Empty description="No received requests." />
    else
      return (
        <Loading spinning={isLoading}>
          <ResponsiveCard
            columns={getColumns()}
            requests={requestsList.results}
            meetingType={meetingType}
            tableType="Received Requests"
            displayTimezone={displayTimezone}
            setDisplayTimezone={setDisplayTimezone}
            displayTimezoneLabel={displayTimezoneLabel}
            setDisplayTimezoneLabel={setDisplayTimezoneLabel}
            onCancelMeeting={() => {
              onCancelRequest()
              setRefresh(!refresh)
            }}
            onAcceptMeeting={() => {
              onAcceptRequest()
              setRefresh(!refresh)
            }}
          />
        </Loading>
      )
  } else
    return (
      <div
        className={
          allowMultipleCompaniesMeeting
            ? 'ReceivedRequestsTableCfn'
            : 'ReceivedRequestsTable'
        }
      >
        <Loading spinning={isLoading}>
          <AcceptSummitMeeting
            visible={showAcceptOnlineRequestModal}
            closeModal={() => {
              setShowAcceptOnlineRequestModal(false)
              setSelectedRecord({})
            }}
            company={meetingWithString}
            meetingId={isEmpty(selectedRecord) ? 0 : selectedRecord.meetingId}
            onAccept={() => {
              onAcceptRequest()
              setRefresh(!refresh)
              getReceivedList(1, pageSize * page)
            }}
            meetingDateTime={selectedRecord.meetingDateTime}
            role={role}
            haveAnswered={selectedRecord.haveAnswered}
            roadshowId={selectedRecord?.roadshowId}
          />
          <AcceptMeetingComponent
            visible={showAcceptRequestModal}
            closeModal={() => {
              setShowAcceptRequestModal(false)
              setSelectedRecord({})
            }}
            categoryName={selectedRecord.categoryName}
            meetingType={meetingType}
            company={meetingWithString}
            meetingId={isEmpty(selectedRecord) ? 0 : selectedRecord.meetingId}
            meetingDateTime={
              isEmpty(selectedRecord) ? 0 : selectedRecord.meetingDateTime
            }
            eventId={isEmpty(selectedRecord) ? 0 : selectedRecord.eventId}
            onAccept={() => {
              onAcceptRequest()
              setRefresh(!refresh)
              getReceivedList(1, pageSize * page)
            }}
            participants={
              isEmpty(selectedRecord)
                ? 0
                : selectedRecord.participants
                ? selectedRecord.participants.map((x) => x.contactId)
                : []
            }
            toParticipants={[contactId]}
            isVirtualSummit={selectedRecord?.virtualSummit}
            isOptimized={selectedRecord?.isOptimized}
            timezone={selectedRecord?.meetingTimeZone}
          />

          <DeclineOnlineMeeting
            visible={showDeclineOnlineModal}
            closeModal={() => {
              setShowDeclineOnlineModal(false)
            }}
            company={meetingWithString}
            meetingId={selectedRecord.meetingId}
            onCancel={() => {
              onCancelRequest()
              setRefresh(!refresh)
              getReceivedList(1, pageSize * page)
            }}
            meetingType={selectedRecord.meetingType}
          />
          <DeclineAllMeetingsModal
            visible={showDeclineAllModal}
            closeModal={() => {
              setShowDeclineAllModal(false)
            }}
            onDeclineAll={() => {
              onCancelRequest()
              setRefresh(!refresh)
              getReceivedList(1, pageSize * page)
            }}
            isSummit={true}
            companyEventId={eventId}
          />
          <Row
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px',
            }}
          >
            <h4
              className={`${
                allowMultipleCompaniesMeeting
                  ? 'ReceivedRequestsTableCfn'
                  : 'ReceivedRequestsTable'
              }-title cc-heading4`}
            >
              Received Requests
            </h4>
            {/* {role === 'Allocator' &&
                meetingType.toLowerCase() === 'summit' &&
                !isNil(requestsList.results) &&
                requestsList.results.length > 0 && (
                  <Button
                    className="ReceivedRequestsTable-DeclineAll cc-btn cc-btn-danger"
                    onClick={() => setShowDeclineAllModal(true)}
                  >
                    <i className="cc-btn-bg" />
                    Decline All
                  </Button>
                )} */}

            <SearchBox onSearch={searchByText} allowClear={true} />
          </Row>
          {!allowMultipleCompaniesMeeting && (
            <Table
              locale={{
                emptyText: (
                  <EmptyEventTablesState activeSummit={activeSummit} />
                ),
              }}
              columns={getColumns()}
              rowKey={(record) => record.meetingId}
              dataSource={requestsList.results}
              pagination={{
                current: page,
                pageSize,
                total,
              }}
              onChange={(pagination) => {
                loadPage(pagination.current, pagination.pageSize)
              }}
            />
          )}
          {allRequestsList && allowMultipleCompaniesMeeting && (
            <MeetingsCardView
              meetingList={allRequestsList}
              hasMore={hasNext}
              isSearching={searchQuery && !isEmpty(searchQuery)}
              isScrollingAllowed={page > 0}
              handleInfiniteOnLoad={handleInfiniteOnLoad}
              renderActions={renderActions}
              type="Received"
              loading={isLoading}
            />
          )}
        </Loading>
      </div>
    )
}

ReceivedRequestsTable.propTypes = {
  activeSummit: PropTypes.bool,
  meetingType: PropTypes.string.isRequired,
  meetingTypeId: PropTypes.number,
  width: PropTypes.number.isRequired,
  fromSummit: PropTypes.bool,
  onCancelRequest: PropTypes.func,
  onAcceptRequest: PropTypes.func,
  allowMultipleCompaniesMeeting: PropTypes.bool,
}
export default ReceivedRequestsTable
