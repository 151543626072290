import { useContext } from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import { useFormikContext } from 'formik'
import parse from 'html-react-parser'
import FormCtx from '~/utils/contexts/FormCtx'
import getConditionalQuestions from '~/utils/form/getConditionalQuestions'
import getRadioFieldComponent from '~/utils/form/getRadioFieldComponent'
import useFormAnswers from '~/utils/hooks/useFormAnswers'
import '../Fields.less'

const RadioField = ({ question }) => {
  const { questions, isEdit } = useContext(FormCtx)
  const { errors } = useFormikContext()

  const { answer, handleOptionsAnswerChange } = useFormAnswers(question)
  const chosenAnswerId = answer.questionAnswerIds[0]

  const disable =
    question.attributes.canEdit === 'false' && isEdit
      ? {
          disabled: true,
        }
      : {
          disabled: false,
        }

  return (
    <>
      {question.text && <div className="Text">{parse(question.text)}</div>}
      <Radio.Group
        className="BooleanField-radio"
        value={chosenAnswerId}
        {...disable}
        onChange={(e) => handleOptionsAnswerChange([e.target.value])}
      >
        {question.questionAnswers.map((q, key) => (
          <div key={key}>
            <Radio value={q.questionAnswerId}>{q.answer}</Radio>
            <div className="BooleanField-radio-conditional">
              {chosenAnswerId === q.questionAnswerId &&
                getConditionalQuestions(
                  q.questionAnswerId,
                  questions,
                  question,
                  getRadioFieldComponent,
                  errors
                )}
            </div>
          </div>
        ))}
      </Radio.Group>
    </>
  )
}

RadioField.propTypes = {
  question: PropTypes.object,
}

export default RadioField
