import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Select } from '@context365/forms'
import flatMap from 'lodash/flatMap'
import * as api from '~/api'
import useAuth from '~/hooks/useAuth'

export default function InvitePlatformUser({ onSendInvitation }) {
  const [selectedUser, setSelectedUser] = React.useState(null)
  const { company } = useAuth()

  function sendInvitation() {
    if (!selectedUser) {
      return
    }

    onSendInvitation(getContactFromKey(selectedUser))
    setSelectedUser(null)
  }

  return (
    <div className="flex mt-4 items-end gap-6">
      <Select.AutoComplete
        className="w-80"
        label="Full Name"
        value={selectedUser}
        onChange={setSelectedUser}
      >
        {({ inputValue }) =>
          inputValue
            ? getUserOptions(inputValue, company.companyId)
            : 'Type a name to search'
        }
      </Select.AutoComplete>
      <Button
        variant="filled"
        status="success"
        disabled={!selectedUser}
        onClick={sendInvitation}
      >
        Invite
      </Button>
    </div>
  )
}

InvitePlatformUser.propTypes = {
  onSendInvitation: PropTypes.func.isRequired,
}

async function getUserOptions(inputValue, userCompanyId) {
  const users = await api.contacts.search(inputValue, { pageSize: 50 })
  const userOptions = flatMap(users, (user) =>
    user.companies.map((company) => ({
      ...user,
      companyName: company.name,
      companyId: company.companyId,
    }))
  ).filter((user) => user.companyId !== userCompanyId)

  return userOptions.map((user) => {
    const key = getCompanyContactKey(user)
    return (
      <Select.Option key={key} value={key} label={user.contactName}>
        <span className="flex flex-col">
          <span>{user.contactName}</span>
          <span className="type-body-regular-xs text-grey-700">
            {user.companyName}
          </span>
        </span>
      </Select.Option>
    )
  })
}

function getCompanyContactKey(contact) {
  return [contact.contactId, contact.companyId].join('-')
}

function getContactFromKey(key) {
  const [contactId, companyId] = key.split('-')
  return { contactId, companyId }
}
