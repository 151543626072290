import rgba from 'polished/lib/color/rgba'
import { chart } from '~/constants/colors'

const gradientId = 'fund-chart-area-fill'

export const GRADIENT_ID = `#${gradientId}`

export const FundChartGradientDef = () => (
  <svg style={{ position: 'fixed', opacity: 0 }}>
    <linearGradient id={gradientId} x1="50%" y1="0%" x2="50%" y2="100%">
      <stop offset="0%" stopColor={rgba(chart.vami, 0.3)} />
      <stop offset="1000%" stopColor={rgba(chart.vami, 0)} />
    </linearGradient>
  </svg>
)
