import { useMemo } from 'react'
import { CheckCircle } from '@context365/icons'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import sample from 'lodash/sample'
import { customerLogos, testimonials } from './data'

export function Testimonial({ className }) {
  // useMemo to make sure the quote doesn't change if something else on the
  // page causes a re-render
  const testimonial = useMemo(() => sample(testimonials), [])
  if (!testimonial) {
    return null
  }

  const { text, author } = testimonial
  return (
    <div className={cx(className, 'text-center text-primary-90')}>
      <h3 className="mb-3 type-subtitle-sm">From Our Clients</h3>
      <div className="type-body-regular-md italic mb-4">&quot;{text}&quot;</div>
      <div className="type-subtitle-xs">{author}</div>
    </div>
  )
}

export function CustomerLogos() {
  if (isEmpty(customerLogos)) {
    return null
  }

  return (
    <div className="mt-8 w-full">
      <h3 className="mb-8 type-subtitle-md text-primary-90 text-center">
        Trusted by
      </h3>
      <div className="max-w-full overflow-hidden flex flex-row flex-wrap justify-center gap-10">
        {customerLogos.map((url) => (
          <img key={url} src={url} alt="" className="h-14" />
        ))}
      </div>
    </div>
  )
}

export function Feature({ children }) {
  return (
    <div className="flex flex-row items-start type-body-regular-md">
      <CheckCircle className="mr-3 text-green-100 flex-shrink-0 relative top-0" />
      {children}
    </div>
  )
}
