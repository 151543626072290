import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import filter from 'lodash/filter'
import get from 'lodash/get'
import map from 'lodash/map'
import maxBy from 'lodash/maxBy'
import { useSelector } from 'react-redux'
import { getMeetingMessage } from '~/actions/messages'
import * as api from '~/api'
import { MEETING_TYPE } from '~/constants/meetingTypeIds'
import { ROLE } from '~/constants/roles'
import useAuth from '~/hooks/useAuth'
import { getUserId } from '~/selectors/auth'
import { OnlineMeetingActions, SummitMeetingActions } from '../MeetingActions'
import MeetingDetails from './MeetingDetails'

const MeetingDetailsController = ({ meetingId, meetingType }) => {
  const userId = useSelector((state) => getUserId(state))
  const { role } = useAuth()
  const [meeting, setMeeting] = useState()
  const [loading, setLoading] = useState(false)
  const [meetingMessages, setMeetingMessages] = useState([])
  const [loadingMessages, setLoadingMessages] = useState(false)
  const [inviteModalVisible, setInviteModalVisible] = useState(false)

  const refreshMeeting = useCallback(() => {
    setLoading(true)
    api.meeting
      .getMeetingById(meetingType, meetingId)
      .then((response) => {
        setMeeting(response.data.result)
      })
      .finally(() => setLoading(false))
  }, [meetingId, meetingType])

  const getMeetingMessages = useCallback(() => {
    setLoadingMessages(true)
    getMeetingMessage(meetingId)
      .then((response) => {
        setMeetingMessages(response.data.result)
      })
      .finally(() => {
        setLoadingMessages(false)
      })
  }, [meetingId])

  useEffect(() => {
    refreshMeeting()
    getMeetingMessages()
  }, [getMeetingMessages, refreshMeeting])

  if (loading || !meeting) {
    return <div>Loading meeting details...</div>
  }

  const colleaguesContactId = map(meeting.colleagues, (c) => c.contactId)
  const participant = filter(
    meeting.colleagues,
    (c) => c.contactId === userId
  )[0]
  const matchmakingScore =
    role === ROLE.ALLOCATOR
      ? get(
          filter(
            meeting.colleagues,
            (c) => c.contactId.toString() === userId.toString()
          )[0],
          'matchmakingScore',
          null
        )
      : get(
          maxBy(meeting.company.contacts, 'matchmakingScore'),
          'matchmakingScore',
          null
        )

  return (
    <>
      <div className="w-full flex self-center justify-between">
        <div className="flex self-center justify-between">
          <div className="cc-heading4">
            <span className="text-header">Meeting with </span>
            {meeting.companies?.length > 1 ? (
              <span>
                {meeting.companies.map((c) => c.companyName).join(' & ')}
              </span>
            ) : (
              <span>{meeting.company.name}</span>
            )}
            <div className="mt-4 type-subtitle-sm">
              <Badge status="default">{meetingType}</Badge>
            </div>
          </div>
        </div>
        <div className="mr-8 space-x-2">
          {meeting.meetingTypeId === MEETING_TYPE.SUMMIT && (
            <SummitMeetingActions
              meeting={meeting}
              refreshMeeting={refreshMeeting}
              inviteModalVisible={inviteModalVisible}
              setInviteModalVisible={setInviteModalVisible}
            />
          )}
          {meeting.meetingTypeId === MEETING_TYPE.ONLINE && (
            <OnlineMeetingActions
              meeting={meeting}
              refreshMeeting={refreshMeeting}
              inviteModalVisible={inviteModalVisible}
              setInviteModalVisible={setInviteModalVisible}
            />
          )}
        </div>
      </div>
      <div style={{ display: 'inline' }}>
        <MeetingDetails
          matchmakingScore={matchmakingScore}
          meeting={meeting}
          userId={userId}
          conversationId={meeting.conversationId}
          colleaguesContactId={colleaguesContactId}
          loadingMessages={loadingMessages}
          participant={participant}
          meetingMessages={meetingMessages}
          setConversationId={() => refreshMeeting()}
          getMeetingMessages={getMeetingMessages}
          onChange={refreshMeeting}
          setInviteModalVisible={setInviteModalVisible}
        />
      </div>
    </>
  )
}

MeetingDetailsController.propTypes = {
  meetingId: PropTypes.number.isRequired,
  meetingType: PropTypes.string.isRequired,
}

export default MeetingDetailsController
