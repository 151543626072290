import { Avatar, AvatarGroup } from '@context365/avatar'
import { Button } from '@context365/button'
import { Launch } from '@context365/icons'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import * as api from '~/api'
import CardButton from '~/components/CardButton'
import { getInitials } from '~/utils/helpers'
import WidgetBlock from './WidgetBlock'

const Presentations = () => {
  const { data: presentations = [] } = useQuery(
    ['dashboardPresentations'],
    () => api.presentations.getPresentations(1, 4),
    {
      select: (res) => res.data.result.items,
    }
  )

  return (
    <WidgetBlock
      headerLeft="Presentations"
      headerRight={
        <Button
          variant="link"
          as={Link}
          to="/community/presentations"
          iconRight={<Launch />}
          style={{ padding: 0 }}
          status="secondary"
        >
          See All
        </Button>
      }
    >
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {isEmpty(presentations) ? (
          <div>No upcoming presentations.</div>
        ) : (
          map(presentations, (p) => (
            <CardButton
              href="/community/presentations"
              className="shadow-6 md:shadow-none p-2 space-y-2 border-grey-300"
            >
              <div className="type-subtitle-sm text-secondary-100">
                {p.presentationTypeName}
              </div>
              <div className="type-body-semibold-sm">{p.title}</div>
              <AvatarGroup>
                {p.moderator && (
                  <Avatar
                    profileImageUrl={p.moderator.imageUrl}
                    initials={getInitials(p.moderator.name)}
                    size="big"
                    isOrganizer
                  />
                )}
                {map(p.presenters, (x) => (
                  <Avatar
                    profileImageUrl={x.imageUrl}
                    initials={getInitials(x.name)}
                    size="big"
                  />
                ))}
              </AvatarGroup>
            </CardButton>
          ))
        )}
      </div>
    </WidgetBlock>
  )
}

export default Presentations
