import PropTypes from 'prop-types'
import CheckboxGroupFilter from './CheckboxGroupFilter'
import '../DiscoverFilters.less'

const answers = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]

const BooleanFilter = ({
  formattedValues = answers,
  onChange,
  type = 'Bool',
  ...props
}) => {
  const handleChange = (params) => onChange({ ...params, type })
  return (
    <CheckboxGroupFilter
      {...props}
      options={formattedValues}
      onChange={handleChange}
    />
  )
}

const formBoolean = PropTypes.oneOfType([
  PropTypes.oneOf(['true', 'false']),
  PropTypes.bool,
])

BooleanFilter.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  formattedValues: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: formBoolean.isRequired,
    })
  ),
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
}

export default BooleanFilter
