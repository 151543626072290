import { faEnvelope, faUser } from '@fortawesome/pro-regular-svg-icons'
import { faCalendarCheck } from '@fortawesome/pro-solid-svg-icons'
import isNil from 'lodash/isNil'

export const showMeetingRequestButton = (record, role, otherRole) =>
  !record.hasMeeting ||
  role === 'manager' ||
  (record.hasMeeting &&
    !isNil(record.meetingStatus) &&
    (record.meetingStatus.toLowerCase() === 'cancelled' ||
      (record.meetingStatus.toLowerCase() === 'declined' &&
        (!record.meetingStatus.isMeetingSender ||
          role === 'allocator' ||
          role === 'context' ||
          (role === 'service provider' && otherRole === 'context')))))

export const showViewMeetingButton = (record) =>
  record.hasMeeting &&
  record.meetingStatus.toLowerCase() !== 'cancelled' &&
  record.meetingStatus.toLowerCase() !== 'declined'

export const getMenuItems = (
  sendMeeting,
  sendMessage,
  viewProfile,
  canFreelyMessage,
  role,
  otherRole,
  record
) => {
  const result = []
  if (
    !isNil(sendMeeting) &&
    showMeetingRequestButton(
      record,
      role.toLowerCase(),
      otherRole.toLowerCase()
    )
  )
    result.push({
      icon: faCalendarCheck,
      disabled:
        sendMeeting === 'NOCREDIT' ||
        (record.meetingStatus?.toUpperCase() === 'DECLINED' &&
          role === 'Service Provider'),
      disabledReason:
        sendMeeting === 'NOCREDIT'
          ? 'Not enough available credits to send a meeting request'
          : record.meetingStatus?.toUpperCase() === 'DECLINED' &&
            role === 'Service Provider'
          ? 'You cannot send another meeting request because your first request has been declined'
          : '',
      text: 'Send Meeting Request',
      clickHandle: sendMeeting,
    })

  if (
    role !== 'Manager' &&
    (role === 'Context' ||
      role === 'Allocator' ||
      (role === 'Service Provider' && !isNil(record?.conversationID)))
  )
    result.push({
      icon: faEnvelope,
      text: 'Send Message',
      clickHandle: sendMessage,
    })

  if (!isNil(viewProfile))
    result.push({
      icon: faUser,
      text: 'Profile',
      clickHandle: viewProfile,
    })

  return result
}
