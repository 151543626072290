import * as React from 'react'
import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import { Button, Col, Collapse, Row, Table } from 'antd'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import { useTracking } from 'react-tracking'
import SimpleBar from 'simplebar-react'
import { listify } from '~/utils'
import ClockTimer from '../MeetingLobbyDetails/ClockTimer'
import VideoSettings from '../VideoSettings'
import NextPresentationActions from './NextPresentationActions'
import './UpcomingList.less'

const { Panel } = Collapse

const UpcomingList = ({ confirmedMeetings, settingsSaved, saveSettings }) => {
  const { trackEvent } = useTracking({ component: 'UpcomingList' })
  const [nextMeetingAvailable, setNextMeetingAvailable] = React.useState(false)
  const [showSettings, setShowSettings] = React.useState(false)
  const [selectedMetingType, setSelectedMeetingType] = React.useState()
  const [selectedMetingId, setSelectedMeetingId] = React.useState()
  const orderedEvents = orderBy(confirmedMeetings, 'meetingDateTime')

  const renderMeetings = (meeting) => {
    return (
      <div>
        <div className="text-brand-100 type-body-regular-xs w-4/5">
          {moment.utc(meeting.meetingDateTime).local().isValid() ? (
            <span>
              {moment
                .utc(meeting.meetingDateTime)
                .local()
                .format('MMMM DD, YYYY [at] h:mm A')}
              {meeting.meetingDateTime === orderedEvents[0].meetingDateTime && (
                <span className="ml-2">
                  <span className="mr-1">(starts in:</span>
                  <ClockTimer
                    eventDate={meeting.meetingDateTime}
                    setMeetingAvailable={setNextMeetingAvailable}
                  />
                  )
                </span>
              )}
            </span>
          ) : (
            'Date and time to be determined.'
          )}
        </div>
        <div className="w-full flex items-start justify-start">
          <div className="w-1/2">
            {meeting?.companies ? (
              meeting.companies
                .filter((c) => c.participants.length > 0)
                .map((company, key) => (
                  <div key={key} className="mt-2">
                    <MeetingParticipants
                      companyName={company.companyName}
                      participants={company.participants}
                    />
                  </div>
                ))
            ) : (
              <div>
                <MeetingParticipants
                  companyName={meeting.company.companyName}
                  participants={meeting.members}
                />
              </div>
            )}
          </div>
          <div>
            <Badge status="primary" size="small">
              {meeting.eventType}
            </Badge>
          </div>
          <div className="cc-button-div mx-10">
            {nextMeetingAvailable &&
              meeting.meetingDateTime === orderedEvents[0].meetingDateTime && (
                <Button
                  type="primary"
                  onClick={() => {
                    if (settingsSaved) {
                      window.open(
                        `/video-conference/${meeting.meetingType}/${meeting.meetingId}`,
                        '_blank'
                      )
                    } else {
                      trackEvent({
                        eventName: 'popup',
                        element: 'audio/video settings',
                      })
                      setSelectedMeetingId(meeting.meetingId)
                      setSelectedMeetingType(meeting.meetingType)
                      setShowSettings(true)
                    }
                  }}
                >
                  Join Meeting
                </Button>
              )}
          </div>
        </div>
      </div>
    )
  }

  const renderPresentations = (presentation) => {
    return (
      <div>
        <div className="text-brand-100 type-body-regular-xs w-4/5">
          <span>
            {moment
              .utc(presentation.startTime)
              .local()
              .format('MMMM DD, YYYY [at] h:mm A')}
            {presentation.startTime === orderedEvents[0].meetingDateTime &&
              !presentation.isLive && (
                <span className="ml-2">
                  <span className="mr-1">(starts in:</span>
                  <ClockTimer
                    eventDate={presentation.startTime}
                    setMeetingAvailable={setNextMeetingAvailable}
                  />
                  )
                </span>
              )}
            {presentation.isLive && (
              <span className="ml-3">
                <Badge status="error" size="medium">
                  Live Now
                </Badge>
              </span>
            )}
          </span>
        </div>
        <div className="w-full flex items-start justify-start">
          <div className="w-1/2">
            <div>
              <div className="type-header-xs text-grey-100">
                {presentation.title}
              </div>
              <div className="type-body-regular-xs text-grey-300">
                Moderator: {presentation.moderator?.name}
              </div>
              <ParticipantList
                title="Presenters"
                names={presentation.presenters.map((p) => p.name)}
              />
            </div>
          </div>
          <Row>
            <Col>
              <Badge
                status="info"
                size="small"
                style={{ backgroundColor: '#6A48C6' }}
              >
                {presentation.eventType}
              </Badge>
            </Col>
            <Col className="cc-button-div">
              <NextPresentationActions presentation={presentation} />
            </Col>
          </Row>
        </div>
      </div>
    )
  }
  const columns = [
    {
      width: '100%',
      className: 'cc-main-div',
      render: (text) =>
        text.eventType === 'Meeting'
          ? renderMeetings(text)
          : renderPresentations(text),
    },
  ]
  return (
    <div style={{ marginTop: '30px' }}>
      <Collapse ghost className="cc-main-collapse">
        <Panel
          className="cc-main-panel"
          header={
            <div className="cc-main-title">
              Upcoming ({orderedEvents.length})
            </div>
          }
        >
          {' '}
          <SimpleBar
            className="cc-main-container"
            autoHide={isEmpty(orderedEvents)}
          >
            <Table
              className="listTable"
              rowClassName="listTableRow"
              size="small"
              pagination={false}
              columns={columns}
              dataSource={orderedEvents}
              showHeader={isEmpty(orderedEvents)}
            />
          </SimpleBar>
        </Panel>
      </Collapse>{' '}
      <VideoSettings
        visible={showSettings}
        joinMeeting={() => {
          setShowSettings(false)
          window.open(
            `/video-conference/${selectedMetingType}/${selectedMetingId}`,
            '_blank'
          )
        }}
        saveSettings={saveSettings}
        closeModal={() => {
          setShowSettings(false)
        }}
        fromMeeting={true}
      />
    </div>
  )
}

UpcomingList.propTypes = {
  confirmedMeetings: PropTypes.array.isRequired,
  settingsSaved: PropTypes.bool,
  saveSettings: PropTypes.func,
}

export default UpcomingList

function MeetingParticipants({ companyName, participants }) {
  return (
    <>
      <div className="type-header-xs text-grey-100">{companyName}</div>
      <ParticipantList
        title="Participants"
        names={participants.map((p) => p.contactName)}
      />
    </>
  )
}

function ParticipantList({ title, names }) {
  return (
    <div className="type-body-regular-xs text-grey-300">
      {title}: {listify(names)}
    </div>
  )
}
