import * as React from 'react'
import { useLiveRef } from '@context365/hooks'

export default function useLocalStorageState(key, initialState, options = {}) {
  if (typeof key !== 'string') {
    throw new Error('localStorage key must be a string.')
  }

  const prevKeyRef = React.useRef(key)
  if (key !== prevKeyRef.current) {
    throw new Error(
      `localStorage key changed from "${prevKeyRef.current}" to "${key}"`
    )
  }
  prevKeyRef.current = key

  const serialize = useLiveRef(options.serialize ?? JSON.stringify)
  const deserialize = useLiveRef(options.deserialize ?? JSON.parse)

  const [state, setState] = React.useState(() => {
    try {
      const storedValue = window.localStorage.getItem(key)
      if (storedValue) {
        return deserialize.current(storedValue)
      }
    } catch {
      // localStorage can throw if the user is in private mode or has
      // storage restrictions. deserialize can also throw
    }

    return typeof initialState === 'function' ? initialState() : initialState
  })

  React.useEffect(() => {
    try {
      if (state === undefined) {
        window.localStorage.removeItem(key)
      } else {
        const serialized = serialize.current(state)
        window.localStorage.setItem(key, serialized)
      }
    } catch {
      // do nothing
    }
  }, [key, state, serialize])

  return [state, setState]
}
