import PropTypes from 'prop-types'
import { DatePicker } from 'antd'
import isNil from 'lodash/isNil'
import moment from 'moment'
import '../DiscoverFilters.less'

const DateRangeFilter = ({ id, group, label, value, onChange }) => {
  const onChangeMin = (e) => {
    onChange({
      value: isNil(e) ? null : e.format(moment.HTML5_FMT.DATE),
      id: `${id} Min`,
      group,
      label: `${label} Min`,
      type: 'Range',
    })
  }

  const onChangeMax = (e) => {
    onChange({
      value: isNil(e) ? null : e.format(moment.HTML5_FMT.DATE),
      id: `${id} Max`,
      group,
      label: `${label} Max`,
      type: 'Range',
    })
  }

  const [minValue, maxValue] = value
  const min = minValue ? moment(minValue) : null
  const max = maxValue ? moment(maxValue) : null

  return (
    <>
      <DatePicker
        className="DiscoverFilters-date"
        suffixIcon
        onChange={onChangeMin}
        value={min}
        size="small"
      />
      <div className="DiscoverFilters-Line" />
      <DatePicker
        className="DiscoverFilters-date"
        suffixIcon
        onChange={onChangeMax}
        value={max}
        size="small"
        disabledDate={(current) => current < min}
      />
    </>
  )
}

DateRangeFilter.propTypes = {
  id: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default DateRangeFilter
