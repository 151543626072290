import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { ArrowBack, ArrowForward, Logout } from '@context365/icons'
import { Steps } from 'antd'
import cx from 'classnames'
import { useDispatch } from 'react-redux'
import { useMatch } from 'react-router-dom-v5-compat'
import { logout } from '~/actions/auth'
import logo from '~/assets/new-logo.svg'
import { useOnboardingContext } from '../OnboardingContext'

export function OnboardingLayout({ header, body, footer }) {
  const dispatch = useDispatch()

  return (
    <div className="px-4 sm:px-0 flex flex-col h-screen">
      <div className="flex-grow overflow-y-auto">
        <div className="container mx-auto">
          <div className="flex items-center py-5 sm:py-10 sm:px-5">
            <div className="hidden sm:block flex-1" />
            <div>
              <img src={logo} className="h-10" alt="Context365" />
            </div>
            <div className="flex-1 text-right">
              <Button
                status="error"
                iconLeft={<Logout />}
                onClick={() => dispatch(logout())}
              >
                Logout
              </Button>
            </div>
          </div>
          <div className="text-center">{header}</div>
          <div className="pb-6">{body}</div>
        </div>
      </div>
      {footer}
    </div>
  )
}

export function OnboardingFooter({ leftContent, rightContent }) {
  return (
    <div className="border-t flex-shrink-0">
      <div className="container mx-auto flex py-4 px-5">
        <div className="flex-1">{leftContent}</div>
        <div className="flex-1 text-right">{rightContent}</div>
      </div>
    </div>
  )
}

export function NextButton({ children = 'Next', ...props }) {
  return (
    <Button variant="filled" iconRight={<ArrowForward />} {...props}>
      {children}
    </Button>
  )
}

export function BackButton({ children = 'Back', ...props }) {
  const { onBack } = useOnboardingContext()

  return (
    <Button iconLeft={<ArrowBack />} onClick={onBack} {...props}>
      {children}
    </Button>
  )
}

export function OnboardingSteps({ className, steps }) {
  const { params } = useMatch('/onboard/:companyId/:step')
  const current = steps.findIndex((s) => s.path === params.step)

  return (
    <Steps
      className={cx(className, 'my-6 mx-auto', steps.length < 3 && 'max-w-md')}
      current={current}
    >
      {steps.map((step) => (
        <Steps.Step key={step.path} title={step.title} />
      ))}
    </Steps>
  )
}

OnboardingSteps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
}
