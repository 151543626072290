import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { TextArea } from '@context365/forms'
import { Modal, message } from 'antd'
import { useQueryClient } from 'react-query'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import ContactName from '../ContactName'

const SendConnectionModal = ({ visible, onClose = () => {}, contact }) => {
  const { trackEvent } = useTracking()
  const [messageBody, setMessageBody] = useState('')
  const [sendingConnection, setSendingConnection] = useState(false)
  const client = useQueryClient()

  const sendConnectionHandler = useCallback(() => {
    setSendingConnection(true)
    trackEvent({ eventName: 'click', element: 'SendConnectionRequest' })

    api.connections
      .sendConnection(contact.contactId, messageBody)
      .then(() => {
        message.success('Connection request sent.')
        client.setQueryData(
          ['contactProfile', contact.contactId],
          (profile) => {
            return {
              ...profile,
              canSendConnection: false,
            }
          }
        )
        client.invalidateQueries(['contactProfile', contact.contactId])
        onClose()
      })
      .catch((err) => message.error(err.response.data))
      .finally(() => setSendingConnection(false))
  }, [trackEvent, contact.contactId, messageBody, onClose])

  return (
    <Modal
      destroyOnClose
      style={{
        minWidth: '646px',
      }}
      visible={visible}
      onOk={sendConnectionHandler}
      onCancel={onClose}
      okButtonProps={{
        loading: sendingConnection,
      }}
      okText="Send"
      title="Send Connection Request"
    >
      <ContactName
        name={contact.fullName}
        position={contact.jobTitle}
        imageUrl={contact.imageUrl}
      />
      <TextArea
        className="py-4"
        placeholder="Send a message with this connection request"
        value={messageBody}
        onChange={(e) => setMessageBody(e.target.value)}
        rows={5}
      />
    </Modal>
  )
}

SendConnectionModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  contact: PropTypes.object,
}

export default SendConnectionModal
