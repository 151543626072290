import PropTypes from 'prop-types'
import { faFile } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Dropdown, Menu, Modal } from 'antd'
import isNil from 'lodash/isNil'
import FundsAndDocuments from '../FundsAndDocuments'
import { CompanyShape } from '../MessagePropTypes'
import DealComponent from './DealComponent'
import './InfoDrawer.less'

const getFundMenu = (funds, onFundClicked) => (
  <Menu>
    {funds.map((x) => (
      <Menu.Item key={x.fundID} onClick={() => onFundClicked(x.fundID)}>
        {x.name}
      </Menu.Item>
    ))}
  </Menu>
)

const InfoDrawer = ({
  company,
  visible,
  onDrawerClose,
  dealInfo,
  onFundClicked,
  onCompanyClicked,
  onInvitedCompanyClicked,
}) => {
  return (
    <Modal
      width={600}
      title="Conversation details"
      onCancel={() => onDrawerClose()}
      visible={visible}
      footer={null}
    >
      <div className="messaging-chat-info-panel">
        <div className="messaging-chat-company-profile">
          {!isNil(company.companyImageURL) && (
            <div className="messaging-chat-company-image">
              <img src={company.companyImageURL} />
            </div>
          )}
          <div className="messaging-chat-company-name">{company.name}</div>
          <div className="messaging-chat-company-title">{company.title}</div>
          {company.companyTypeID !== 1 && (
            <div className="messaging-chat-company-actions">
              <Button
                type="primary"
                className="messaging-chat-company-profile"
                onClick={onCompanyClicked}
              >
                <FontAwesomeIcon icon={faFile} />
                Show Profile
              </Button>
            </div>
          )}

          {company.companyTypeID === 1 &&
            company.funds &&
            company.funds.length > 0 && (
              <div className="messaging-chat-company-actions">
                <Dropdown
                  overlay={getFundMenu(company.funds, onFundClicked)}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <Button
                    type="primary"
                    className="messaging-chat-company-profile"
                  >
                    <FontAwesomeIcon icon={faFile} />
                    Show Profile
                  </Button>
                </Dropdown>
              </div>
            )}

          {company.companyTypeID === 1 &&
            (isNil(company.funds) || company.funds.length === 0) && (
              <div className="cc-body-text messaging-chat-company-no-funds">
                This company has not registered any funds yet
              </div>
            )}
        </div>
        {company.invitedCompanyID !== 0 && (
          <div
            className="messaging-chat-company-profile"
            style={{ marginTop: '25px' }}
          >
            <Divider />
            {!isNil(company.invitedCompanyImageURL) && (
              <div className="messaging-chat-company-image">
                <img src={company.invitedCompanyImageURL} />
              </div>
            )}
            <div className="messaging-chat-company-name">
              {company.invitedCompanyName}
            </div>
            {company.invitedCompanyTypeID !== 1 && (
              <div className="messaging-chat-company-actions">
                <Button
                  type="primary"
                  className="messaging-chat-company-profile"
                  onClick={onInvitedCompanyClicked}
                >
                  <FontAwesomeIcon icon={faFile} />
                  Show Profile
                </Button>
              </div>
            )}
          </div>
        )}
        {dealInfo && dealInfo.dealId && <DealComponent {...dealInfo} />}
        <FundsAndDocuments funds={company.funds} companyName={company.name} />
      </div>
    </Modal>
  )
}

InfoDrawer.propTypes = {
  company: CompanyShape.isRequired,
  visible: PropTypes.bool,
  onDrawerClose: PropTypes.func,
  onFundClicked: PropTypes.func,
  onCompanyClicked: PropTypes.func,
  onInvitedCompanyClicked: PropTypes.func,
  dealInfo: PropTypes.object,
}

export default InfoDrawer
