import isNil from 'lodash/isNil'
import map from 'lodash/map'
import AggregatedRangeFilter from './Controls/AggregatedRangeFilter'
import BooleanFilter from './Controls/BooleanFilter'
import CheckboxFilter from './Controls/CheckboxFilter'
import CheckboxGroupFilter from './Controls/CheckboxGroupFilter'
import ContextSignalFilter from './Controls/ContextSignalFilter'
import DateRangeFilter from './Controls/DateRangeFilter'
import RangeFilter from './Controls/RangeFilter'
import ToggleFilter from './Controls/ToggleFilter'

export default function getFilterComponent({ item, header, ...props }) {
  switch (item.questionType) {
    case 'dropdown':
    case 'dropdownDiscoverLists':
    case 'dropdownCustom':
      return (
        <div id={isNil(item.field) ? 'dropdown' : `${item.field}_dropdown`}>
          {header}
          <CheckboxGroupFilter
            className="DiscoverFilters-FilterCheckbox--stacked"
            id={item.field}
            label={item.name}
            group={item.questionGroup}
            options={item.values}
            showClearAll
            {...props}
          />
        </div>
      )
    case 'range':
      return (
        <div>
          {header}
          <RangeFilter
            id={item.field}
            label={item.name}
            formatter={(value) =>
              value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            group={item.questionGroup}
            percentage={false}
            {...props}
          />
        </div>
      )
    case 'percentageRange':
      return (
        <div>
          {header}
          <RangeFilter
            id={item.field}
            label={item.name}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
            group={item.questionGroup}
            percentage={
              !(
                item.field === 'deepestDrawdown' ||
                item.field.includes('YtdReturn') ||
                item.questionGroup === 'Fees'
              )
            }
            {...props}
          />
        </div>
      )
    case 'percentageFormatRange':
      return (
        <div>
          {header}
          <RangeFilter
            id={item.field}
            label={item.name}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
            group={item.questionGroup}
            percentage={false}
            {...props}
          />
        </div>
      )
    case 'aggregatedRange':
      return (
        <div>
          {header}
          <AggregatedRangeFilter
            id={item.field.split(',')}
            label={item.name}
            formatter={(value) =>
              value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            group={item.questionGroup}
            percentage={false}
            {...props}
          />
        </div>
      )
    case 'date':
      return (
        <div>
          {header}
          <DateRangeFilter
            id={item.field}
            label={item.name}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
            group={item.questionGroup}
            {...props}
          />
        </div>
      )
    case 'customBool':
      return (
        <div>
          {header}
          <BooleanFilter
            className="DiscoverFilters-FilterCheckbox--stacked"
            id={item.field}
            label={item.name}
            group={item.questionGroup}
            formattedValues={map(item.values, (v) => ({
              label: v,
              value: v === 'Anonymous',
            }))}
            {...props}
          />
        </div>
      )
    case 'checkbox':
      if (item.questionGroup === 'ApexInvest Signal') {
        return (
          <ContextSignalFilter
            id={item.field}
            label={item.name}
            group={item.questionGroup}
            {...props}
          />
        )
      } else {
        return (
          <CheckboxFilter
            id={item.field}
            label={item.name}
            group={item.questionGroup}
            {...props}
          />
        )
      }
    case 'toggle':
      return (
        <ToggleFilter
          id={item.field}
          label={item.name}
          group={item.questionGroup}
          {...props}
        />
      )
    default:
      return ''
  }
}
