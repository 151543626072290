import { useEffect, useReducer, useState } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import uniqBy from 'lodash/uniqBy'
import * as types from '~/constants/types/serviceprovideronboarding'

const answersReducer = (state, action) => {
  switch (action.type) {
    case types.ADD:
      return { ...state, ...action.payload }
    case types.APPEND_ANSWERS:
      return {
        ...state,
        answers: uniqBy([...action.payload, ...state.answers], 'questionId'),
        finished: get(action, 'meta.finished', false),
      }
    default:
      return state
  }
}

const answersInitialState = {
  dealName: null,
  answers: [],
  finished: false,
}

const useDealsParsing = (questions) => {
  const [state, dispatch] = useReducer(answersReducer, answersInitialState)
  const [previewObject, setPreviewObject] = useState({})

  useEffect(() => {
    if (!isEmpty(state.answers)) {
      const findSelectValue = (shortName, value) => {
        if (!isEmpty(questions) && !isEmpty(value)) {
          const questionAnswers = questions.find(
            (question) => question.shortName === shortName
          )?.dealQuestionAnswers
          const answer = questionAnswers?.find(
            (answer) => answer.questionAnswerId === value[0]
          )?.answer
          if (answer === undefined) return null
          else return [answer]
        }
      }
      const title = state.answers.find((answer) => answer.shortName === 'Title')
      const manager = state.answers.find(
        (answer) => answer.shortName === 'Manager'
      )

      const opportunity = state.answers.find(
        (answer) => answer.shortName === 'Opportunity'
      )

      const opportunityDetails = state.answers.find(
        (answer) => answer.shortName === 'OpportunityDetails'
      )

      const endDate = state.answers.find(
        (answer) => answer.shortName === 'EndDate'
      )

      const publishDate = state.answers.find(
        (answer) => answer.shortName === 'PublishDate'
      )

      const investmentType = state.answers.find(
        (answer) => answer.shortName === 'InvestmentType'
      )
      const vehicle = state.answers.find(
        (answer) => answer.shortName === 'Vehicle'
      )

      const sector = state.answers.find(
        (answer) => answer.shortName === 'Sector'
      )

      const geography = state.answers.find(
        (answer) => answer.shortName === 'Geography'
      )

      const vertical = state.answers.find(
        (answer) => answer.shortName === 'Vertical'
      )
      const minimumInvestmentSize = state.answers.find(
        (answer) => answer.shortName === 'MinimumInvestmentSize'
      )

      const offerSize = state.answers.find(
        (answer) => answer.shortName === 'OfferSize'
      )

      const committedCapital = state.answers.find(
        (answer) => answer.shortName === 'CommittedCapital'
      )

      const targetIrr = state.answers.find(
        (answer) => answer.shortName === 'TargetIrr'
      )

      const moic = state.answers.find((answer) => answer.shortName === 'Moic')

      const interestRate = state.answers.find(
        (answer) => answer.shortName === 'InterestRate'
      )

      const devidedReturn = state.answers.find(
        (answer) => answer.shortName === 'DevidedReturn'
      )

      const ImageUrl = state.answers.find(
        (answer) => answer.shortName === 'ImageUrl'
      )

      const link1 = state.answers.find((answer) => answer.shortName === 'Link1')

      const link2 = state.answers.find((answer) => answer.shortName === 'Link2')

      const link3 = state.answers.find((answer) => answer.shortName === 'Link3')

      const documents = state.answers.find(
        (answer) => answer.shortName === 'Documents'
      )

      const profile = [
        {
          groupName: 'Basic Information',
          sections: [
            {
              key: 'Title',
              label: 'Deal Title',
              value: title?.responseText,
              type: 'Text - Single',
              order: 1,
            },
            {
              key: 'Manager',
              label: 'Deal Manager',
              value: manager?.responseText,
              type: 'Text - Single',
              order: 2,
            },
            {
              key: 'PublishDate',
              label: 'Release Date',
              value: publishDate?.responseText,
              type: 'Date',
              order: 3,
            },
          ],
        },
        {
          groupName: 'Opportunity Description',
          sections: [
            {
              key: 'Opportunity',
              label: 'Opportunity',
              value: opportunity?.responseText,
              type: 'Text - Single',
              order: 1,
            },
            {
              key: 'OpportunityDetails',
              label: 'Opportunity Details',
              value: opportunityDetails?.responseText,
              type: 'Text - Multiple',
              order: 2,
            },
          ],
        },
        {
          groupName: 'Opportunity Details',
          sections: [
            {
              key: 'InvestmentType',
              label: 'Investment Type',
              value: findSelectValue(
                'InvestmentType',
                investmentType?.questionAnswerIds
              ),

              type: 'Dropdown',
              order: 3,
            },
            {
              key: 'Vehicle',
              label: 'Vehicle',
              value: findSelectValue('Vehicle', vehicle?.questionAnswerIds),
              type: 'Dropdown',
              order: 4,
            },
            {
              key: 'Sector',
              label: 'Sector',
              value: findSelectValue('Sector', sector?.questionAnswerIds),
              type: 'Dropdown',
              order: 5,
            },
            {
              key: 'Geography',
              label: 'Geography',
              value: findSelectValue('Geography', geography?.questionAnswerIds),
              type: 'Dropdown',
              order: 6,
            },
            {
              key: 'Vertical',
              label: 'Vertical',
              value: findSelectValue('Vertical', vertical?.questionAnswerIds),
              type: 'Dropdown',
              order: 7,
            },
            {
              key: 'MinimumInvestmentSize',
              label: 'Minimum Investment Size',
              value: minimumInvestmentSize?.responseText,
              type: 'Numeric - INT',
              order: 8,
            },
            {
              key: 'OfferSize',
              label: 'Offer Size',
              value: offerSize?.responseText,
              type: 'Numeric - INT',
              order: 9,
            },
            {
              key: 'CommittedCapital',
              label: 'Current Raised Investment',
              value: committedCapital?.responseText,
              type: 'Numeric - INT',
              order: 10,
            },
            {
              key: 'InterestRate',
              label: 'Interest Rate',
              value: interestRate?.responseText,
              type: 'Numeric - FLOAT',
              order: 12,
            },
            {
              key: 'DevidedReturn',
              label: 'Dividend Return',
              value: devidedReturn?.responseText,
              type: 'Numeric - FLOAT',
              order: 14,
            },
            {
              key: 'TargetIrr',
              label: 'Target IRR',
              value: targetIrr?.responseText,
            },
            {
              key: 'Moic',
              label: 'MOIC',
              value: moic?.responseText,
            },
          ],
        },
        {
          groupName: 'Content',
          sections: [
            {
              key: 'ImageUrl',
              label: '',
              value: ImageUrl?.responseText,
              type: 'Upload - Image Single',
              order: 1,
            },
            {
              key: 'Link1',
              label: 'Link #1',
              value: link1?.responseText,
              type: 'Text - Single',
              order: 2,
            },
            {
              key: 'Link2',
              label: 'Link #2',
              value: link2?.responseText,
              type: 'Text - Single',
              order: 3,
            },
            {
              key: 'Link3',
              label: 'Link #3',
              value: link3?.responseText,
              type: 'Text - Single',
              order: 4,
            },
          ],
        },
      ]

      const object = {
        title: title?.responseText,
        endDate: endDate?.responseText,
        publishDate: publishDate?.responseText,
        investmentType: findSelectValue(
          'InvestmentType',
          investmentType?.questionAnswerIds
        ),

        vehicle: findSelectValue('Vehicle', vehicle?.questionAnswerIds),
        sector: findSelectValue('Vector', sector?.questionAnswerIds),
        geography: findSelectValue('Geography', geography?.questionAnswerIds),
        vertical: findSelectValue('Vertical', vertical?.questionAnswerIds),
        minimumInvestmentSize: minimumInvestmentSize?.responseText,
        offerSize: offerSize?.responseText,
        committedCapital: committedCapital?.responseText,
        targetIrr: targetIrr?.responseText,
        moic: moic?.responseText,
        interestRate: interestRate?.responseText,
        devidedReturn: devidedReturn?.responseText,
        link1: link1?.responseText,
        link2: link2?.responseText,
        link3: link3?.responseText,
        profile,
        manager: manager?.responseText,
        imageUrl: ImageUrl?.responseText,
        opportunity: opportunity?.responseText,
        opportunityDetails: opportunityDetails?.responseText,
        documents:
          documents?.responseText === '' ||
          isNil(documents) ||
          isNil(documents?.responseText)
            ? []
            : JSON.parse(documents.responseText)?.map((document) => ({
                mediaUrl: document,
              })),
      }
      setPreviewObject(object)
    }
  }, [questions, state])

  return { previewObject, state, dispatch }
}

export default useDealsParsing
