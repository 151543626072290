import { Route } from 'react-router-dom-v5-compat'
import * as api from '~/api'
import { CompanyConfirmForm, CompanyForm } from '../forms/CompanyForm'
import { CompanyDetails } from '../pages/CompanyDetails'
import { Step, makeGetNextPagePath, makeGetPreviousPagePath } from './utils'

const steps = [Step.CompanyDetails]

export const defaultWorkflow = {
  getStartingPagePath: () => Step.CompanyDetails,
  getNextPagePath: makeGetNextPagePath(steps),
  getPreviousPagePath: makeGetPreviousPagePath(steps),

  routes: [
    <Route
      key={Step.CompanyDetails}
      path={Step.CompanyDetails}
      element={
        <CompanyDetails
          getSavedAnswers={api.onboarding.getCompanyAnswers}
          saveAnswers={(_, answers) =>
            api.onboarding.saveCompanyAnswers(answers)
          }
          editForm={<CompanyForm />}
          confirmForm={<CompanyConfirmForm />}
        />
      }
    />,
  ],
}
