import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'

function ErrorFallback() {
  return <h1 style={{ textAlign: 'center' }}>Something went wrong!</h1>
}

export default function ErrorBoundary({ children }) {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
}
