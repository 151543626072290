import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { addLineBreaks } from '~/utils/helpers'
import DataTransformations from '../DataTransformations'
import LogMessageItem from '../LogMessageItem'
import { MessageShape } from '../MessagePropTypes'
import OtherMessageItem from '../OtherMessageItem'
import UserMessageItem from '../UserMessageItem'
import './MessageItem.less'

const MessageItem = ({
  message,
  messageType,
  searchTerm,
  isHighlighted = false,
  isCurrentSearchResult = false,
  isSameContactAsPrevious = false,
}) => {
  // if the message is highlighted, break it down into HTML elements that contain
  // the highlighted and non-highlighted pieces
  let messageElements
  if (!isHighlighted && !isCurrentSearchResult) {
    messageElements = [<b key={1}>{addLineBreaks(message.body)}</b>]
  } else {
    messageElements = DataTransformations.ConvertToHighlightedMessage(
      message.body,
      searchTerm,
      isCurrentSearchResult
    )
  }
  // to use on render when this message is the current result
  const messageToFocus = useRef(null)

  // side effect to scroll to this message's element if it's
  // the current result to show
  useEffect(() => {
    if (isCurrentSearchResult) {
      messageToFocus.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [isCurrentSearchResult])

  // decide which kind of message to display inside a div that
  // serves as the element to scroll to
  let controlToShow
  if (messageType === 'mine')
    controlToShow = (
      <UserMessageItem
        messageElements={messageElements}
        index={message.index}
        status={message.status}
        time={message.timeSent}
        isAttachment={message.isAttachment}
        downloadUrl={message.attachmentUrl}
      />
    )
  else if (messageType === 'others')
    controlToShow = (
      <OtherMessageItem
        messageElements={messageElements}
        index={message.index}
        contact={message.authorContact}
        time={message.timeSent}
        useHeader={!isSameContactAsPrevious}
        isAttachment={message.isAttachment}
        downloadUrl={message.attachmentUrl}
      />
    )
  else
    controlToShow = (
      <LogMessageItem
        message={message.body}
        logType={message.logType}
        logTypeID={message.logTypeID}
        time={message.timeSent}
        isAttachment={message.isAttachment}
      />
    )

  // this div's class decides how the message will be highlighted,
  // i.e. color to highlight with
  return (
    <div
      ref={messageToFocus}
      className={`messaging-message-row${
        isCurrentSearchResult ? ' messaging-message-current-result' : ''
      }${isHighlighted ? ' messaging-highlighted' : ''}`}
    >
      {/* if needed, uncomment below statement to show index with each message */}
      {/* {message.index} */}
      {controlToShow}
    </div>
  )
}

MessageItem.propTypes = {
  message: MessageShape.isRequired,
  messageType: PropTypes.string,
  searchTerm: PropTypes.string,
  isHighlighted: PropTypes.bool,
  isCurrentSearchResult: PropTypes.bool,
  isSameContactAsPrevious: PropTypes.bool,
}

export default MessageItem
