import * as Yup from 'yup'
import * as api from '~/api'
import { Form, InputField, UnsavedChangesAlert } from '~/components/Form'
import Loading from '~/components/Loading'
import { AddressForm } from '../forms/AddressForm'
import { useOnboardingQuery } from '../hooks'
import { useOnboardingContext } from '../OnboardingContext'
import {
  BackButton,
  NextButton,
  OnboardingFooter,
  OnboardingLayout,
  OnboardingSteps,
} from './OnboardingLayout'

const schema = Yup.object().shape({
  workPhone: Yup.string().nullable().required(),
  workExtension: Yup.string().nullable(),
  mobilePhone: Yup.string().nullable(),
  continent: Yup.string().nullable().required(),
  country: Yup.string().nullable().required(),
  postalCode: Yup.string().nullable(),
  city: Yup.string().nullable().required(),
  stateProvince: Yup.string().nullable(),
  address1: Yup.string().nullable(),
  address2: Yup.string().nullable(),
  address3: Yup.string().nullable(),
})

export function ContactLocation({ steps }) {
  const { onNext } = useOnboardingContext()
  const { answers, isLoading, submit } = useOnboardingQuery(
    ['contact-location'],
    {
      getAnswers: () => api.onboarding.getContactLocationAnswers(),
      saveAnswers: (_, answers) =>
        api.onboarding.saveContactLocationAnswers(answers),
    }
  )

  if (isLoading) {
    return (
      <OnboardingLayout
        header={<OnboardingSteps steps={steps} />}
        body={
          <div className="flex items-center justify-center">
            <Loading />
          </div>
        }
      />
    )
  }

  return (
    <OnboardingLayout
      header={<OnboardingSteps steps={steps} />}
      body={
        <Form
          id="contact-location"
          className="mt-12 onboarding-form"
          schema={schema}
          defaultValues={answers}
          onSubmit={submit}
          onSuccess={onNext}
        >
          <div className="type-header-md text-center mb-4">
            Enter your contact and location information
          </div>
          <InputField name="workPhone" inputMode="tel" label="Work Phone" />
          <InputField
            name="workExtension"
            inputMode="tel"
            label="Work Extension"
          />
          <InputField name="mobilePhone" inputMode="tel" label="Mobile Phone" />
          <AddressForm />

          <UnsavedChangesAlert />
        </Form>
      }
      footer={
        <OnboardingFooter
          leftContent={<BackButton />}
          rightContent={<NextButton type="submit" form="contact-location" />}
        />
      }
    />
  )
}
