import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line import/no-extraneous-dependencies -- included with antd
import { isNil } from '@antv/util'
import { Input, Modal } from 'antd'
import { isEmptyArray } from 'formik'
import Loading from '../Loading'
import CompanyContacts from '../MeetingDetails/CompanyContacts'
import './MessageModal.less'

const { TextArea } = Input

const MessageModal = ({
  meeting,
  visible,
  initialMessage = '',
  loading = false,
  onOk,
  onCancel,
  needsHeadsUp,
}) => {
  const [message, setMessage] = useState(initialMessage)

  const handleChange = useCallback((e) => {
    setMessage(e.target.value)
  }, [])

  const handleOk = useCallback(() => {
    onOk(message)
    setMessage('')
  }, [message, onOk])

  const handleCancel = useCallback(() => {
    setMessage('')
    onCancel()
  }, [onCancel])

  const companyObject = {
    companyId: meeting?.company?.companyId,
    companyName: isNil(meeting?.company?.name)
      ? meeting?.company?.companyName
      : meeting?.company?.name,
    participants: isNil(meeting?.company?.contacts)
      ? meeting?.participants
      : meeting?.company?.contacts,
  }

  isEmptyArray(meeting?.companies) && meeting?.companies.push(companyObject)

  return (
    <Modal
      visible={visible}
      title="New Message"
      onOk={handleOk}
      bodyStyle={{ padding: 0 }}
      onCancel={handleCancel}
      okText="Send Message"
      okButtonProps={{ disabled: message.trim() === '' }}
    >
      <Loading spinning={loading}>
        <div style={{ padding: '22px 16px 22px 22px' }}>
          <div className="investors-container">
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                marginBottom: '26px',
              }}
            >
              <CompanyContacts fulWidth={true} companies={meeting?.companies} />
            </div>
          </div>

          {needsHeadsUp && (
            <div className="cc-body-font message-modal-heads-up">
              You are allowed to send one more message since there are no active
              meetings for this conversation. <br />
              After this, the channel will be reactivated when the other party
              comes into contact.
            </div>
          )}

          <div>
            <TextArea
              placeholder="Write a message..."
              rows={8}
              value={message}
              onChange={handleChange}
            />
          </div>
        </div>
      </Loading>
    </Modal>
  )
}

MessageModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  initialMessage: PropTypes.string,
  loading: PropTypes.bool,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  needsHeadsUp: PropTypes.bool,
  meeting: PropTypes.object,
}

export default MessageModal
