import * as React from 'react'

export default function useIntercom() {
  return {
    show: React.useCallback(() => {
      if (!window.Intercom) return
      window.Intercom('update', { hide_default_launcher: false })
    }, []),
    hide: React.useCallback(() => {
      if (!window.Intercom) return
      window.Intercom('update', { hide_default_launcher: true })
    }, []),
  }
}
