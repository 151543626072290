import { useCallback, useEffect, useState } from 'react'
import { Modal } from 'antd'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import uniq from 'lodash/uniq'
import { useSelector } from 'react-redux'
import { api as http } from '~/api/services'
import MeetingRatings from '~/components/MeetingRatings'
import { isImpersonation } from '~/selectors/auth'

const MeetingRatingContainer = () => {
  const [indications, setIndications] = useState([])
  const [ratingType, setRatingType] = useState(null)
  const [meetings, setMeetings] = useState([])
  const [showRatings, setShowRatings] = useState(false)
  const [stickyModal, setStickyModal] = useState(false)
  const [isDoneRating, setIsDoneRating] = useState(false)
  const [isSaving, setIsSaving] = useState(-1)

  const impersonation = useSelector(isImpersonation)

  const getIndications = useCallback(() => {
    http
      .get('/ratings/indications')
      .then((response) => setIndications(response.data.result))
      .catch((err) =>
        console.error(
          '[MeetingRatingContainer]::Could not retrieve rating indications:',
          err
        )
      )
  }, [])

  const getMeetingsToRate = useCallback(() => {
    http
      .get('/ratings/meetings')
      .then((response) => {
        const { hasEventEnded, meetings: meetingsToRate } = response.data.result

        const summitMeetingsToRate =
          filter(meetingsToRate, (f) => f.meetingTypeId === 1).length > 0

        setStickyModal(!impersonation && hasEventEnded && summitMeetingsToRate)
        setMeetings(
          map(meetingsToRate, (m) => ({ ...m, isValid: false, rating: {} }))
        )

        if (isEmpty(meetingsToRate)) {
          setIsDoneRating(true)
        } else {
          const types = uniq(
            map(meetingsToRate, (m) => `${m.categoryName}To${m.toCategoryName}`)
          )
          setRatingType(isEmpty(types) ? '' : types[0])
        }
        setShowRatings(!isEmpty(meetingsToRate))
      })
      .catch((err) =>
        console.error('Could not retrieve meetings to rate:', err)
      )
  }, [impersonation])

  useEffect(() => {
    if (isEmpty(indications)) {
      getIndications()
    }

    if (isEmpty(meetings) && !isDoneRating) {
      getMeetingsToRate()
    }
  }, [getIndications, getMeetingsToRate, indications, meetings, isDoneRating])

  const handleOk = useCallback(() => {
    setIsSaving(0)

    const data = {
      meetingRatings: meetings.map((m) => ({
        ...m.rating,
        contextMeetingId: m.meetingId,
        toCompanyId: m.toCompanyId,
      })),
    }
    const url = ratingType
      .replace(/([A-Z][a-z]+)/g, (m) => `${m.toLowerCase()}-`)
      .replace(/-$/, 's')

    http
      .post(`/ratings/meetings/${url}`, data)
      .then(() => {
        //getMeetingsToRate();
        setIsDoneRating(true)
        setShowRatings(false)
        setMeetings([])
      })
      .catch((err) => console.error('Could not save meeting ratings:', err))
      .finally(() => {
        setIsSaving(-1)
      })
  }, [meetings, ratingType])

  const handleCancel = useCallback(() => {
    setShowRatings(stickyModal)
  }, [stickyModal])

  const handleChange = useCallback(
    (givenRating) => {
      const { meetingId, isValid, rating } = givenRating

      setMeetings(
        map(meetings, (meeting) => {
          if (meeting.meetingId === meetingId) {
            return { ...meeting, isValid, rating }
          }
          return meeting
        })
      )
    },
    [meetings]
  )

  const handleSave = useCallback(
    (meetingRating) => {
      const ratingsDto = {
        contextMeetingId: meetingRating.meetingId,
        ...meetingRating,
      }
      setIsSaving(meetingRating.meetingId)
      const url = ratingType
        .replace(/([A-Z][a-z]+)/g, (m) => `${m.toLowerCase()}-`)
        .replace(/-$/, '')
      http
        .post(`/ratings/meetings/${url}`, ratingsDto)
        .then(() => {
          const meetingsLeftToRate = filter(
            meetings,
            ({ meetingId }) => meetingId !== meetingRating.meetingId
          )
          setMeetings(meetingsLeftToRate)
          setShowRatings(!isEmpty(meetingsLeftToRate))
        })
        .catch((err) => console.error('Could not save meeting ratings:', err))
        .finally(() => {
          setIsSaving(-1)
        })
    },
    [meetings, ratingType]
  )

  if (!ratingType) return null

  return (
    <Modal
      visible={showRatings}
      title="Rate Meetings"
      okText="Save Ratings"
      closable={!stickyModal}
      onOk={handleOk}
      okButtonProps={{
        disabled: !reduce(
          map(meetings, (r) => r.isValid),
          (prev, curr) => prev && curr,
          true
        ),
        loading: isSaving === 0,
      }}
      onCancel={handleCancel}
      cancelButtonProps={{
        disabled: stickyModal,
      }}
      width={720}
      zIndex={998}
    >
      <MeetingRatings
        meetings={meetings}
        indications={indications}
        type={ratingType}
        onChange={handleChange}
        onSave={handleSave}
        isSaving={isSaving}
      />
    </Modal>
  )
}

export default MeetingRatingContainer
