import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faArrowLeft, faPowerOff } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import isNil from 'lodash/isNil'
import { useDispatch } from 'react-redux'
import { logout } from '~/actions/auth'
import loginLogo from '~/assets/new-logo.svg'
import Loading from '~/components/Loading'
import './Onboarding.less'

const LayoutDefault = ({
  component,
  title,
  companies,
  onNext,
  onBack,
  backVisible,
  onCompanyChanged,
  step,
  onChange,
  currentQuestions,
  loading,
  onInvestorStatusChange,
  companyId,
}) => {
  const Component = component
  const [nextEnabled, setNextEnabled] = useState(false)
  const [backEnabled, setBackEnabled] = useState(true)
  const dispatch = useDispatch()

  const logoutUser = useCallback(() => dispatch(logout()), [dispatch])

  useEffect(() => {
    setNextEnabled(true)
    window.scrollTo(0, 0)
  }, [step])

  useEffect(() => {
    if (step.stepNumber === 2 && companies.length <= 1) {
      setBackEnabled(false)
    }
  }, [companies, step.stepNumber])

  const getLayoutHeader = useCallback(
    () => (
      <div className="cc-layout-default-header">
        <div className="cc-layout-default-context">
          <div
            className="cc-login-logo-onboard"
            style={{ backgroundImage: `url(${loginLogo})` }}
          />
        </div>
        <div className="cc-heading1 cc-layout-default-welcome">
          Welcome to ApexInvest
        </div>
      </div>
    ),
    []
  )

  const getLayoutFooter = useCallback(
    () =>
      loading ? null : (
        <div className="cc-layout-default-footer">
          {step.canGoBack === false ? null : (
            <Button
              onClick={onBack}
              hidden={!backVisible || !backEnabled}
              className="cc-layout-footer-back-button"
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              &nbsp;&nbsp;Back
            </Button>
          )}

          <Button
            onClick={onNext}
            disabled={!nextEnabled}
            style={{ width: '364px', height: '48px' }}
            type="primary"
          >
            Next
          </Button>
        </div>
      ),
    [loading, nextEnabled, onNext]
  )

  const getTitle = useCallback(
    () =>
      loading ? null : (
        <div className="cc-heading4 cc-layout-default-title">{title}</div>
      ),
    [loading, title]
  )

  if ((isNil(companies) || companies.length === 0) && step.stepNumber === 1)
    return null

  return (
    <div className="cc-layout-default">
      <Button
        onClick={onBack}
        hidden={!backVisible || !backEnabled}
        className="cc-layout-default-back"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        &nbsp;&nbsp;Back
      </Button>

      <Button
        onClick={() => {
          logoutUser()
        }}
        className="cc-layout-default-logout"
      >
        <FontAwesomeIcon icon={faPowerOff} />
        &nbsp;&nbsp;Logout
      </Button>

      {getLayoutHeader()}
      {getTitle()}
      <div
        className="cc-layout-default-form-space"
        style={{ width: step.stepNumber === '6' ? '556px' : '364px' }}
      >
        {loading ? (
          <Loading />
        ) : isNil(component) ? null : (
          <Component
            step={step}
            onChange={onChange}
            onInvestorStatusChange={onInvestorStatusChange}
            stepNumber={step.stepNumber}
            companies={companies}
            enableNext={(value = true) => setNextEnabled(value)}
            onCompanyChanged={onCompanyChanged}
            maxWidth={step.stepNumber === '6' ? '556px' : '364px'}
            currentQuestions={currentQuestions}
            companyId={companyId}
          />
        )}
        {getLayoutFooter()}
      </div>
    </div>
  )
}

LayoutDefault.propTypes = {
  component: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  companies: PropTypes.array.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  backVisible: PropTypes.bool.isRequired,
  onCompanyChanged: PropTypes.func.isRequired,
  step: PropTypes.shape({
    stepNumber: PropTypes.number.isRequired,
    innerStep: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired,
    shouldPost: PropTypes.bool.isRequired,
    actionType: PropTypes.number.isRequired,
    canGoBack: PropTypes.bool.isRequired,
    customWidth: PropTypes.bool,
    renderAction: PropTypes.func.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  currentQuestions: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onInvestorStatusChange: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
}

export default LayoutDefault
