import * as Yup from 'yup'
import {
  CheckboxGroupField,
  Form,
  InputField,
  NumberInputField,
  SelectField,
  TextAreaField,
  UnsavedChangesAlert,
} from '~/components/Form'
import { AddressForm } from '../forms/AddressForm'
import { useOnboardingContext } from '../OnboardingContext'
import { baseCompanySchema } from './CompanyForm'

const schema = baseCompanySchema.shape({
  investmentPhilosophy: Yup.string().nullable(),
  companyWebsite: Yup.string().nullable().required().url(),
  primaryInvestorCategoryId: Yup.number().nullable().required(),
  secondaryInvestorCategoryIds: Yup.array().of(Yup.number().required()).max(2),
  portfolioSize: Yup.number()
    .nullable()
    .required()
    .min(0)
    .typeError('Please enter a valid number'),
})

export default function AllocatorCompanyForm({
  savedAnswers,
  onSubmit,
  ...formProps
}) {
  const { options } = useOnboardingContext()

  return (
    <Form
      className="onboarding-form"
      schema={schema}
      defaultValues={savedAnswers}
      onSubmit={onSubmit}
      {...formProps}
    >
      <InputField name="companyName" label="Company Name" autoComplete="off" />
      <TextAreaField
        name="companyDescription"
        label="Company Description"
        rows={5}
        showCharacterCount
      />
      <TextAreaField
        name="investmentPhilosophy"
        label="Investment Philosophy"
        rows={5}
      />
      <InputField
        name="companyWebsite"
        label="Company Website"
        inputMode="url"
      />
      <SelectField
        name="primaryInvestorCategoryId"
        label="Primary Investor Category"
        options={options.investorCategories}
      />
      <CheckboxGroupField
        name="secondaryInvestorCategoryIds"
        label="Secondary Investor Categories"
        helpMessage="Choose up to two (optional)"
        options={options.investorCategories}
      />
      <NumberInputField
        name="portfolioSize"
        label="Portfolio Size"
        type="currency"
      />
      <AddressForm title="Company Address" className="mt-4" />
      <UnsavedChangesAlert />
    </Form>
  )
}
