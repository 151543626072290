export const NOTIFICATION_CATEGORY = {
  PUSH: 1,
  TRANSACTIONAL: 2,
  BATCH: 3,
}

export const NOTIFICATION_CATEGORY_NAMES = [
  { id: NOTIFICATION_CATEGORY.PUSH, name: 'Push Notification' },
  { id: NOTIFICATION_CATEGORY.TRANSACTIONAL, name: 'Email' },
  { id: NOTIFICATION_CATEGORY.BATCH, name: 'Email' },
]
