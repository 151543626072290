import { api } from './services'

export const search = (searchTerm, params = {}) =>
  api
    .get('/contacts/search', {
      params: {
        q: searchTerm,
        ...params,
      },
    })
    .then((response) => response.data.result)

export const getContactProfile = ({ contactId }) =>
  api
    .get(`contacts/${contactId}/profile`)
    .then((response) => response.data.result)

export const acceptSubscriptionAgreement = () => api.put('subscription/accept')

export const getContactProfileAnswers = () =>
  api.get('contacts/profilewizard').then((response) => response.data.result)

export const getContactProfileOptions = () =>
  api.get('contacts/options').then((response) => response.data.result)

export const saveContactProfile = (profile) =>
  api.patch('contacts/profile', profile)

export const getDirectoryFilters = () =>
  api.get('directory/filters').then((response) => response.data.result)
