import PropTypes from 'prop-types'
import { Col, Divider, Image, Row } from 'antd'
import { isNil, map } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { getClickableLink } from '~/utils/helpers'
import './FundMedia.less'

const FundMedia = ({ media, links }) => {
  const mediaItem = (media) => {
    return media.type === 'Image' ? (
      <Image src={media.url} className="fund-image" />
    ) : (
      <video
        autoPlay={false}
        width="100%"
        muted={false}
        style={{
          borderRadius: '8px',
          boxShadow: '3px 3px 10px rgba(186, 186, 201, 0.15)',
        }}
        controls
      >
        <source src={media.url} />
      </video>
    )
  }

  return (
    <div>
      <Row style={{ marginTop: '20px' }}>
        {!isNil(media) && !isEmpty(media) && (
          <>
            <Col span={15}>
              <span className="type-subtitle-sm text-header">Media</span>
              <Row className="media-row">
                {media.length > 1 ? (
                  <Image.PreviewGroup>
                    <Col span={12}>{mediaItem(media[0])}</Col>
                    <Col span={12}>
                      <Row>
                        {map(media, (m, i) =>
                          i === 0 ? null : (
                            <Col span={12} className="fund-image-col">
                              {mediaItem(m)}
                            </Col>
                          )
                        )}
                      </Row>
                    </Col>
                  </Image.PreviewGroup>
                ) : (
                  <Col span={24}>{mediaItem(media[0])}</Col>
                )}
              </Row>
            </Col>
            <Col span={2}>
              <Divider type="vertical" style={{ height: '100%' }} />
            </Col>
          </>
        )}
        <Col span={isNil(media) ? 24 : 7}>
          <span className="type-subtitle-sm text-header">External Links</span>
          {map(links, (link) => {
            return (
              <div className="FundMedia-link">
                {' '}
                <a
                  href={getClickableLink(link.url)}
                  rel="noreferrer"
                  target="_blank"
                >
                  {link.title}
                </a>
              </div>
            )
          })}
        </Col>
      </Row>
    </div>
  )
}

FundMedia.propTypes = {
  media: PropTypes.array,
  links: PropTypes.array,
}
export default FundMedia
