import PropTypes from 'prop-types'
import { Axis, Chart, Geom, Legend, Tooltip } from 'bizcharts'
import numeral from 'numeral'

const VamiChart = ({ fundVami = {}, customBenchmark, fundPeriod, width }) => {
  if (!fundVami.datasets) {
    return null
  }

  return (
    <div style={{ paddingTop: '40px' }}>
      <div className="ChartLabel">
        <span>VAMI</span>
      </div>
      <Chart
        data={getVamiData(fundVami.datasets, customBenchmark)}
        height={400}
        width={width}
        scale={getCols(fundVami, fundPeriod)}
        forceFit
      >
        <Legend position="bottom" dy={-20} />
        <Axis name="x" />
        <Axis name="y" />
        <Axis name="label" />
        <Tooltip />
        <Geom
          type="line"
          position="x*y"
          color={['label', ['#2fc5d2', '#f19f00', '#b1347d']]}
        />
        <Geom
          type="point"
          position="x*y"
          color={['label', ['#2fc5d2', '#f19f00', '#b1347d']]}
          size={4}
          shape="circle"
        />
      </Chart>
    </div>
  )
}

VamiChart.propTypes = {
  fundVami: PropTypes.object,
  customBenchmark: PropTypes.object,
  fundPeriod: PropTypes.number,
  width: PropTypes.number,
}

export default VamiChart

function getCols(data, fundPeriod) {
  return {
    x: { alias: data.xAxisLabel, mask: 'MM/YYYY', tickCount: fundPeriod },
    y: {
      alias: data.yAxisLabel,
      formatter: (val) => numeral(val).format('0,0[.]00'),
    },
  }
}

function getVamiData(data, customBenchmark) {
  const labelText = data[0].label
  const fund = data[0].data.map((x) => ({
    x: x.x,
    y: x.y,
    label: labelText,
  }))

  const fundIndex = data[1].data.map((x) => ({
    x: x.x,
    y: x.y,
    label: 'S&P500',
  }))
  let result = fund.concat(fundIndex)
  if (data[2]) {
    const altLabel = customBenchmark.benchmarkName
    const altIndex = data[2].data.map((x) => ({
      x: x.x,
      y: x.y,
      label: altLabel,
    }))
    result = result.concat(altIndex)
  }

  return result
}
