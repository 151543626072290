import * as React from 'react'
import { useTheme } from '@context365/config'
import { timeFormat, timeParse } from 'd3-time-format'
import sortBy from 'lodash/sortBy'

const parseDate = timeParse('%Y-%m-%dT%H:%M:%S')
const formatDate = timeFormat('%b %Y')

export function formatValuationDate(date) {
  return formatDate(parseDate(date))
}

export function useCategoryColors(categories) {
  const colors = useTheme('colors')

  return React.useMemo(() => {
    const globalCategoryColors = [
      'gold',
      'green',
      'secondary',
      'purple',
      'brand',
      'blue',
    ]

    const otherColors = {
      badgeBackground: colors.grey[100],
      data: colors.grey[500],
    }
    const sumColors = {
      badgeBackground: colors.primary[5],
      data: colors.primary[100],
    }

    return sortBy(categories, 'categoryId').reduce(
      (colorSettings, category) => {
        if (category.isGlobal) {
          const nextColor = globalCategoryColors.shift()
          return {
            ...colorSettings,
            [category.categoryId]: {
              badgeBackground: colors[nextColor][5],
              data: colors[nextColor][100],
            },
          }
        } else {
          return {
            ...colorSettings,
            [category.categoryId]: otherColors,
          }
        }
      },
      { total: sumColors }
    )
  }, [categories, colors])
}
