import { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Modal, Select, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useSelector } from 'react-redux'
import {
  getColleaguesForServiceProvider,
  sendOnlineRequestToServiceProvider,
} from '~/actions/contextMeetings'
import { ROLE } from '~/constants/roles'
import { getUserCompanyContactId, getUserId } from '~/selectors/auth'
import MandateViewCtx from '~/utils/contexts/MandateViewCtx'
import Loading from '../Loading'
import MeetingRequestProposedTimes from '../MeetingRequestProposedTimes'
import ParticipantsCheckbox from '../ParticipantsCheckbox'
import './DiscoverServiceProviderMeetingRequest.less'

const { TextArea } = Input

const DiscoverServiceProviderMeetingRequest = ({
  visible,
  serviceProvider,
  onClose,
  onRefresh,
  roadshowList,
  roadshowId = 0,
  meetingTypeId = 3,
  fromMapView = false,
  selectedFund = null,
}) => {
  const userId = useSelector(getUserId)
  const userCompanyContactId = useSelector(getUserCompanyContactId)
  const { role } = useSelector((state) => state.auth)
  const [loadingContacts, setLoadingContacts] = useState(false)
  const [myColleagues, setMyColleagues] = useState([])
  const [otherColleagues, setOtherColleagues] = useState([])
  const [participants, setParticipants] = useState([userId])
  const [toParticipants, setToParticipants] = useState([])
  const [textMessage, setMessage] = useState('')
  const [savingInProggress, setSavinginProggress] = useState(false)
  const [selectedRoadshowId, setSelectedRoadshowId] = useState(0)
  const sentFromMandateView = useContext(MandateViewCtx)

  const handleOk = useCallback(() => {
    setSavinginProggress(true)
    if (role === ROLE.ALLOCATOR) {
      return sendOnlineRequestToServiceProvider(
        serviceProvider.companyId,
        selectedFund,
        participants,
        toParticipants,
        textMessage,
        meetingTypeId,
        meetingTypeId === 2
          ? !isNil(roadshowId) && roadshowId > 0
            ? roadshowId
            : !isNil(selectedRoadshowId) && selectedRoadshowId > 0
            ? selectedRoadshowId
            : null
          : null,
        userCompanyContactId,
        sentFromMandateView
      )
        .then((response) => {
          const { meetingId } = response.data.result.result
          if (isNil(serviceProvider.meetings)) {
            serviceProvider.meetingId = meetingId
            serviceProvider.meetingStatus = 'Pending'
          } else {
            serviceProvider.meetings.push({
              contextMeetingId: meetingId,
              meetingStatus: 'Pending',
              participantStatus: 'Confirmed',
            })
          }
          message.success('Request sent succesfully.')
          onClose()
        })
        .catch((err) => {
          const errorMessage =
            err?.response?.status === 400 ? err?.response?.data?.message : null
          message.error(errorMessage || 'Could not send meeting request.')
        })
        .finally(() => {
          setSavinginProggress(false)
          onRefresh()
        })
    } else {
      return sendOnlineRequestToServiceProvider(
        serviceProvider.companyId,
        selectedFund,
        participants,
        toParticipants,
        textMessage,
        meetingTypeId,
        meetingTypeId === 2
          ? !isNil(roadshowId) && roadshowId > 0
            ? roadshowId
            : !isNil(selectedRoadshowId) && selectedRoadshowId > 0
            ? selectedRoadshowId
            : null
          : null,
        userCompanyContactId,
        sentFromMandateView
      )
        .then((response) => {
          const { meetingId } = response.data.result.result
          if (isNil(serviceProvider.meetings)) {
            serviceProvider.meetingId = meetingId
            serviceProvider.meetingStatus = 'Pending'
          } else {
            serviceProvider.meetings.push({
              contextMeetingId: meetingId,
              meetingStatus: 'Pending',
              participantStatus: 'Confirmed',
            })
          }
          message.success('Request sent succesfully.')
          onClose()
        })
        .catch((err) => {
          const errorMessage =
            err?.response?.status === 400 ? err?.response?.data?.message : null
          message.error(errorMessage || 'Could not send meeting request.')
        })
        .finally(() => {
          setSavinginProggress(false)
          onRefresh()
        })
    }
  }, [
    meetingTypeId,
    onClose,
    onRefresh,
    participants,
    roadshowId,
    role,
    selectedFund,
    selectedRoadshowId,
    serviceProvider,
    textMessage,
    toParticipants,
    userCompanyContactId,
    sentFromMandateView,
  ])

  const handleParticipantChecked = useCallback(
    (e) => {
      let tmpparticipants
      if (e.target.checked) {
        tmpparticipants = [...participants, e.target.value]
        setParticipants(tmpparticipants)
      } else {
        tmpparticipants = participants.filter((p) => p !== e.target.value)
        setParticipants(tmpparticipants)
      }
    },
    [participants]
  )

  const handleToParticipantChecked = useCallback(
    (e) => {
      let tmptoParticipants
      if (e.target.checked) {
        tmptoParticipants = [...toParticipants, e.target.value]
        setToParticipants(tmptoParticipants)
      } else {
        tmptoParticipants = toParticipants.filter((p) => p !== e.target.value)
        setToParticipants(tmptoParticipants)
      }
    },
    [toParticipants]
  )

  const footer = (
    <div className="footer">
      <Button
        onClick={() => {
          setToParticipants([])
          setParticipants([userId])
          onClose()
        }}
      >
        Cancel
      </Button>
      <Button
        loading={savingInProggress}
        type="primary"
        disabled={toParticipants.length === 0 || participants.length === 0}
        onClick={() => handleOk()}
      >
        Send Meeting Request
      </Button>
    </div>
  )

  useEffect(() => {
    if (!isEmpty(roadshowList) && selectedRoadshowId === 0) {
      setSelectedRoadshowId(roadshowList[0].roadShowId)
    }
  }, [roadshowList, selectedRoadshowId])

  useEffect(() => {
    setLoadingContacts(true)
    ;(meetingTypeId !== 2 ||
      (meetingTypeId === 2 && (roadshowId || selectedRoadshowId))) &&
      getColleaguesForServiceProvider(
        serviceProvider.companyId,
        selectedFund,
        null,
        meetingTypeId === 2
          ? !isNil(roadshowId) && roadshowId > 0
            ? roadshowId
            : !isNil(selectedRoadshowId) && selectedRoadshowId > 0
            ? selectedRoadshowId
            : null
          : null
      )
        .then((response) => {
          setMyColleagues(
            response.data.result.find(
              (x) => x.companyId !== serviceProvider.companyId
            ).contacts
          )
          const contacts = response.data.result.find(
            (x) => x.companyId === serviceProvider.companyId
          ).contacts
          setOtherColleagues(contacts)
          setToParticipants(map(contacts, (c) => c.contactId))
        })
        .catch(() => message.error('Could not retrieve colleagues'))
        .finally(() => {
          setLoadingContacts(false)
        })
  }, [
    serviceProvider,
    meetingTypeId,
    roadshowId,
    selectedRoadshowId,
    selectedFund,
  ])

  return (
    <Modal
      destroyOnClose
      centered
      className="AllocatorOnlineMR"
      visible={visible}
      width="700px"
      title="Request a Meeting"
      footer={footer}
      afterClose={() => {
        setToParticipants([])
        setParticipants([userId])
        onClose()
      }}
      onCancel={() => {
        setToParticipants([])
        setParticipants([userId])
        onClose()
      }}
      bodyStyle={{ padding: '16px 32px' }}
    >
      <div>
        <div>
          {!isEmpty(roadshowList) &&
            meetingTypeId === 2 &&
            fromMapView &&
            (roadshowId === 0 || isNil(roadshowId)) && (
              <div
                style={{ marginBottom: '16px' }}
                className="cc-heading5 cc-darkest-grey-text"
              >
                <div style={{ marginTop: '16px' }}>Roadshow Event</div>
                <div style={{ display: 'flex', width: '70%' }}>
                  <Select
                    className="cc-background-input"
                    value={selectedRoadshowId}
                    style={{ width: '100%', marginTop: '16px' }}
                    placeholder="Select Roadshow event"
                    onChange={(e) => {
                      setSelectedRoadshowId(e)
                    }}
                  >
                    {roadshowList &&
                      map(roadshowList, (r) => (
                        <Select.Option key={r.roadShowId} value={r.roadShowId}>
                          {r.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </div>
            )}
        </div>
        <Loading spinning={loadingContacts}>
          <div>
            <div className="cc-heading5 cc-darkest-grey-text">
              {serviceProvider.companyName}
            </div>
            <div className="cc-check-participants-wrapper">
              {otherColleagues &&
                map(otherColleagues, (contact) => (
                  <ParticipantsCheckbox
                    key={contact.contactId}
                    participants={toParticipants}
                    checked={handleToParticipantChecked}
                    contactId={contact.contactId}
                    contactName={`${contact.firstName} ${contact.lastName}`}
                    imageUrl={contact.imageUrl}
                    position={contact.jobTitle}
                  />
                ))}
            </div>
            {otherColleagues?.length > 1 && (
              <Button
                style={{ padding: '8px 0px' }}
                type="link"
                onClick={() => {
                  toParticipants?.length > 0
                    ? setToParticipants([])
                    : setToParticipants(
                        map(otherColleagues, (c) => c.contactId)
                      )
                }}
              >
                {toParticipants?.length > 0 ? 'Deselect All' : 'Select All'}
              </Button>
            )}
          </div>
          <div>
            <div
              style={{ marginTop: '16px' }}
              className="cc-heading5 cc-darkest-grey-text"
            >
              Your Colleagues
            </div>
            <div>
              <div className="cc-check-participants-wrapper">
                {myColleagues &&
                  map(myColleagues, (contact) => (
                    <ParticipantsCheckbox
                      key={contact.contactId}
                      participants={participants}
                      checked={handleParticipantChecked}
                      contactId={contact.contactId}
                      contactName={`${contact.firstName} ${contact.lastName}`}
                      imageUrl={contact.imageUrl}
                      position={contact.jobTitle}
                      //disabled={contact.contactId === userId}
                    />
                  ))}
              </div>
            </div>
          </div>
        </Loading>
        <MeetingRequestProposedTimes />
        <div style={{ width: '100%', marginTop: '24px' }}>
          <TextArea
            placeholder="Write a message..."
            rows={4}
            value={textMessage}
            onChange={({ target: { value } }) => {
              setMessage(value)
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

DiscoverServiceProviderMeetingRequest.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  serviceProvider: PropTypes.object.isRequired,
  onRefresh: PropTypes.func,
  roadshowId: PropTypes.number,
  meetingTypeId: PropTypes.number,
  fromMapView: PropTypes.bool,
  roadshowList: PropTypes.array,
  selectedFund: PropTypes.number,
}

export default DiscoverServiceProviderMeetingRequest
