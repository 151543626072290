import { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import AllocatorDetailsModalContainer from '../AllocatorDetailsModalContainer'
import Clickable from '../Clickable'
import FundDetailsModalContainer from '../FundDetailsModalContainer'
import ServiceProviderModalContainer from '../ServiceProviderModalContainer'
import useAllocator from './useAllocator'
import useFund from './useFund'
import useServiceProvider from './useServiceProvider'

const returnFalse = () => false

const ProfileViewer = ({
  companyCategoryId,
  companyContactId,
  contactId,
  companyId,
  fundId,
  children,
  modalVisible = false,
  onClose = () => null,
}) => {
  const [showModal, setShowModal] = useState(modalVisible)
  const openModal = () => setShowModal(true)
  const closeModal = () => {
    setShowModal(false)
    onClose()
  }

  const role = useSelector((state) => state.auth.role)
  const userCompanyId = useSelector((state) => state.auth.company.companyId)

  const { fund: fundProfile } = useFund(fundId, companyCategoryId, role)

  const { allocator: allocatorProfile, defaultFund: defaultMatchedFund } =
    useAllocator(
      companyContactId,
      contactId,
      companyId,
      companyCategoryId,
      role
    )

  const {
    serviceProvider: serviceProviderProfile,
    defaultFund: defaultManagerFund,
  } = useServiceProvider(companyId, companyCategoryId, userCompanyId, role)

  if (
    !fundProfile &&
    !allocatorProfile &&
    !serviceProviderProfile &&
    children
  ) {
    return children
  }

  return (
    <>
      {children && (
        <Clickable onClick={openModal} aria-label="view profile">
          {children}
        </Clickable>
      )}
      {showModal &&
        (fundProfile ? (
          <FundDetailsModalContainer
            fund={fundProfile}
            showFundProfile={!!fundProfile}
            arePointsNear={returnFalse}
            onClose={closeModal}
            fundProfile={fundProfile}
            canSendMessage={role !== 'Service Provider'}
          />
        ) : allocatorProfile ? (
          <AllocatorDetailsModalContainer
            allocator={allocatorProfile}
            showAllocatorProfile={!!allocatorProfile}
            arePointsNear={returnFalse}
            selectedFund={defaultMatchedFund?.fundId}
            canSendMeetingRequest={allocatorProfile?.canSendMeetingRequest}
            onClose={closeModal}
          />
        ) : serviceProviderProfile ? (
          <ServiceProviderModalContainer
            serviceProvider={serviceProviderProfile}
            selectedFund={defaultManagerFund?.fundId}
            showSPProfile={!!serviceProviderProfile}
            onClose={closeModal}
            serviceProviderProfile={serviceProviderProfile}
          />
        ) : null)}
    </>
  )
}

ProfileViewer.propTypes = {
  companyCategoryId: PropTypes.number.isRequired,
  companyContactId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  fundId: PropTypes.number,
  children: PropTypes.node,
  modalVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ProfileViewer
