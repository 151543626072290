import { useCallback, useEffect, useState } from 'react'
import { Button, Checkbox, Radio, message } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isNil from 'lodash/isNil'
import set from 'lodash/set'
import numeral from 'numeral'
import { useSelector } from 'react-redux'
import { Prompt, useHistory, useRouteMatch } from 'react-router-dom'
import * as api from '~/api'
import FloatingInput from '~/components/FloatingInput'
import FloatingSelect from '~/components/FloatingSelect'
import Loading from '~/components/Loading'
import DomicileSection from '~/components/OnboardingComponents/DomicileSection'
import { validURL } from '~/utils/helpers'
import InvestorPreferences from '../settings/InvestorPreferences'
import MatchmakingSettings from '../settings/MatchmakingSettings'
import CFNAllocatorDealsForm from './CFNAllocatorDealsForm'
import '~/components/CompanyForm/CompanyForm.less'
import '~/views/settings/InvestorPreferences.less'

const tabs = [
  { name: 'company', isDisabled: true },
  { name: 'status', isDisabled: true },
  { name: 'preferences', isDisabled: true },
  { name: 'threshold', isDisabled: true },
]
const CFNAllocatorOnboarding = () => {
  const {
    params: { tab },
  } = useRouteMatch()
  const { push } = useHistory()
  const role = useSelector((state) => state.auth.role)

  const [activeTab, setActiveTab] = useState('company')
  const { cfnAllocatorOnboarding } = useFlags()

  useEffect(() => {
    tab && setActiveTab(tab)
  }, [tab])
  const [loading, setLoading] = useState(false)
  const [company, setCompany] = useState({})
  const [primaryInvCategories, setPrimaryInvCategories] = useState([])
  const [secondaryInvCategories, setSecondaryInvCategories] = useState([])
  const [investmentVehicles, setInvestmentVehicles] = useState([])
  const [contentChanged, setContentChanged] = useState(false)
  const domicileSectionChanged = useCallback(
    (val) => {
      setContentChanged(true)
      company.companyAllocator.fundDomicileInterest = val
      setCompany({ ...company })
    },
    [company]
  )

  useEffect(() => {
    const getCompanyData = async () => {
      setLoading(true)
      try {
        const companyRaw = await api.settings.getCompanySettings()
        setCompany(companyRaw.data.result.company)
        setPrimaryInvCategories(
          companyRaw.data.result.referenceData.PrimaryInvestorCategory
        )
        setSecondaryInvCategories(
          companyRaw.data.result.referenceData.SecondaryInvestorCategory
        )

        setInvestmentVehicles(
          companyRaw.data.result.referenceData.InvestmentVehicle
        )
      } catch (error) {
        message.error(
          'There was an error while attempting to fetch company data'
        )
      } finally {
        setLoading(false)
      }
    }

    getCompanyData()
  }, [])

  const radioHandler = (e) => {
    setActiveTab(e.target.value)
    push(`/investor/${e.target.value}`)
  }

  const firstLossCapitalChanged = useCallback(
    (e) => {
      setContentChanged(true)
      company.companyAllocator.firstLossCapital = e.target.value
      setCompany({ ...company })
    },
    [company]
  )

  const enableTab = (tabName) => {
    tabs.find((item) => item.name === tabName).isDisabled = false
    setActiveTab(tabName)
  }

  const dropdownFieldChanged = useCallback(
    (e, name) => {
      setContentChanged(true)
      set(company, name, e)
      setCompany({ ...company })
    },
    [company]
  )

  const checkboxFieldChanged = useCallback(
    (name, e) => {
      setContentChanged(true)
      if (isNil(name)) return
      let value
      if (name === 'companyAllocator.secondaryInvestorCategory') {
        if (e.length > 2) return
        value = e.join(',')
      }

      if (
        name === 'companyAllocator.investmentVehicle' ||
        name === 'companyManager.fundAttributes'
      ) {
        value = e.join(',')
      }

      set(company, name, value)
      setCompany({ ...company })
    },
    [company]
  )
  const onCompanySaved = useCallback(async () => {
    try {
      enableTab('status')
      setLoading(true)
      await api.settings.updateCompany(company)
      message.success('Settings have been successfully saved')
    } catch {
      message.error('There was an error while attempting to save')
    } finally {
      setLoading(false)
      setContentChanged(false)
    }
  }, [company])

  const inputFieldChanged = useCallback(
    (name, e) => {
      setContentChanged(true)
      let value = isNil(e.target) ? e : e.target.value
      if (
        name === 'companyAllocator.portfolioSize' ||
        name === 'companyManager.firmAum' ||
        name === 'companyManager.cik' ||
        name === 'companyManager.crd'
      )
        value = numeral(value).format('0')

      set(company, name, value)
      setCompany({ ...company })
    },
    [company]
  )
  const isDisabled = (tabName) =>
    tabs.find((item) => item.name === tabName).isDisabled

  return (
    <div>
      {role === 'CFN' && cfnAllocatorOnboarding && (
        <div>
          <Radio.Group
            style={{ marginBottom: '16px' }}
            buttonStyle="solid"
            size="large"
            onChange={radioHandler}
            value={activeTab}
            className="cc-matchmaking-settings-Radio"
          >
            <Radio.Button
              value="company"
              className="cc-matchmaking-settings-Radio-body"
            >
              Company
            </Radio.Button>
            <Radio.Button
              disabled={isDisabled('status')}
              value="status"
              className="cc-matchmaking-settings-Radio-body"
            >
              Investor Status
            </Radio.Button>
            <Radio.Button
              disabled={isDisabled('preferences')}
              value="preferences"
              className="cc-matchmaking-settings-Radio-body"
            >
              Preferences
            </Radio.Button>
            <Radio.Button
              disabled={isDisabled('threshold')}
              value="threshold"
              className="cc-matchmaking-settings-Radio-body"
            >
              Matchmaking
            </Radio.Button>
          </Radio.Group>
          {activeTab === 'company' && (
            <Loading spinning={loading}>
              <Prompt
                message="Are you sure you want to leave without saving?"
                when={contentChanged}
              />
              <div className="settings-investor-preferences-board">
                <div className="form-container-header">
                  <p className="form-container-title">Company</p>
                  <p className="form-container-description">
                    Edit your company information
                  </p>
                </div>
                <div className="form-section">
                  <div className="form-column-inputs">
                    <FloatingSelect
                      name="Primary Investor Category"
                      id="companyAllocator.primaryInvestorCategory"
                      onChange={dropdownFieldChanged}
                      value={company?.companyAllocator?.primaryInvestorCategory}
                      key="PrimaryInvestorCategory"
                      options={primaryInvCategories.map((x) => ({
                        value: x,
                        text: x,
                      }))}
                    />
                    <div className="cc-generic-form-checkbox-group">
                      <span className="cc-heading6 cc-generic-form-checkbox-header">
                        Secondary Investor Category
                      </span>
                      <Checkbox.Group
                        value={
                          isNil(
                            company.companyAllocator?.secondaryInvestorCategory
                          )
                            ? []
                            : company.companyAllocator?.secondaryInvestorCategory
                                .split(',')
                                .slice(0, 2)
                        }
                        onChange={checkboxFieldChanged.bind(
                          null,
                          'companyAllocator.secondaryInvestorCategory'
                        )}
                        options={secondaryInvCategories.map((x) => ({
                          label: x,
                          value: x,
                        }))}
                      />
                    </div>
                    <FloatingInput
                      name="Portfolio Size"
                      id="companyAllocator.portfolioSize"
                      onChange={inputFieldChanged}
                      value={numeral(
                        company.companyAllocator?.portfolioSize
                      ).format(0, 0)}
                      key="PortfolioSize"
                      handleChange={true}
                    />

                    <DomicileSection
                      onChange={domicileSectionChanged}
                      value={company.companyAllocator?.fundDomicileInterest}
                    />

                    <div className="cc-generic-form-checkbox-group">
                      <span className="cc-heading6 cc-generic-form-checkbox-header">
                        Investment Vehicle Interest
                      </span>
                      <Checkbox.Group
                        value={
                          isNil(company.companyAllocator?.investmentVehicle)
                            ? []
                            : company.companyAllocator?.investmentVehicle.split(
                                ','
                              )
                        }
                        onChange={checkboxFieldChanged.bind(
                          null,
                          'companyAllocator.investmentVehicle'
                        )}
                        options={investmentVehicles.map((x) => ({
                          label: x,
                          value: x,
                        }))}
                      />
                    </div>
                    <div className="cc-generic-form-radio-group">
                      <span className="cc-heading6 cc-generic-form-radio-header">
                        First Loss Capital
                      </span>

                      <div className="cc-body-text cc-generic-form-radio-header-capital">
                        Do you only provide first loss capital?
                      </div>
                      <Radio.Group
                        onChange={firstLossCapitalChanged}
                        value={
                          company.companyAllocator?.firstLossCapital === true
                        }
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <div className="form-section-submit">
                  <div className="empty-column-left" />
                  <div className="form-section-submit-buttons">
                    <div>
                      <Button
                        type="primary"
                        className="form-section-btn-save"
                        onClick={onCompanySaved}
                        disabled={!validURL(company.companyWebsite)}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                  <div className="empty-column-right" />
                </div>
              </div>
            </Loading>
          )}
          {activeTab === 'status' && (
            <CFNAllocatorDealsForm
              onNextClicked={() => {
                enableTab('preferences')
              }}
            />
          )}
          {activeTab === 'preferences' && (
            <InvestorPreferences
              fromCfnSettings
              onNextClicked={() => {
                enableTab('threshold')
              }}
            />
          )}
          {activeTab === 'threshold' && (
            <MatchmakingSettings
              fromCfnSettings
              onSaveClicked={() => {
                push('/discover/funds')
              }}
            />
          )}
        </div>
      )}
    </div>
  )
}
export default CFNAllocatorOnboarding
