import { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'
import axios from 'axios'
import { Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import moment from 'moment'
import momentTz from 'moment-timezone'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import Loading from '~/components/Loading'
import PresentationWizard from '~/components/PresentationWizard'
import { schema } from '~/components/PresentationWizard/Validations'
import { getCompanyId, getUserId } from '~/selectors/auth'
import PresentationWizardCtx from '~/utils/contexts/PresentationWizardCtx'
import {
  getAttendanceNumber,
  getParticipantRoleNumber,
  getPresentationTypeId,
} from '~/utils/presentations'

const PresentationWizardForm = () => {
  const { Track, trackEvent } = useTracking({ page: 'PresentationWizardForm' })
  const answers = ''
  const [displayData, setDisplayData] = useState({})
  const {
    params: { id, step },
  } = useRouteMatch()
  const [guestMembers, setGuestMembers] = useState([])
  const [userInvites, setUserInvites] = useState([])
  const [presentationType, setPresentationType] = useState(1)
  const [timezone, setTimezone] = useState(momentTz.tz.guess())
  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const [startTime, setStartTime] = useState('')
  const [options, setOptions] = useState([])
  const [segments, setSegments] = useState([])
  const [fund, setFund] = useState(null)
  const [fundId, setFundId] = useState(0)
  const [deal, setDeal] = useState(null)
  const [dealId, setDealId] = useState(0)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [attendance, setAttendance] = useState(1)
  const [selectedContacts, setSelectedContacts] = useState([])
  const [selectedGuestRoles, setSelectedGuestRoles] = useState([])
  const [currentStep, setCurrentStep] = useState(
    isNil(step) ? 0 : parseInt(step, 10)
  )
  const [isPublished, setIsPublished] = useState(false)
  const [loading, setLoading] = useState(false)
  const [presentationId, setPresentationId] = useState(id || 0)
  const [duration, setDuration] = useState(30)
  const [isLive, setIsLive] = useState(false)

  const history = useHistory()

  const isEdit = !isNil(id)

  const contactId = useSelector(getUserId)
  const companyId = useSelector(getCompanyId)

  const isAvailable = (status) =>
    status !== 'Rejected' && status !== 'Canceled' && status !== 'Removed'

  const initialAnswers = useCallback(
    () => ({
      presentationType,
      title,
      description,
      date,
      time,
      duration,
      attendance,
      segmentsLength: segments.length,
      guestMembers,
      userInvites,
      attendees: [...userInvites],
      attendeesRolesModerator: [
        ...selectedGuestRoles,
        ...selectedContacts.map((s) => s.role),
      ].filter((a) => a === 1),
      attendeesRolesPresenters: [
        ...selectedGuestRoles,
        ...selectedContacts.map((s) => s.role),
      ].filter((a) => a === 2),
      attendeesInvitesRolesModerator: [
        ...selectedGuestRoles,
        ...selectedContacts.map((s) => s.role),
        ...userInvites.map((s) => s.role),
      ].filter((a) => a === 1),
      attendeesInvitesRolesPresenters: [
        ...selectedGuestRoles,
        ...selectedContacts.map((s) => s.role),
        ...userInvites.map((s) => s.role),
      ].filter((a) => a === 2),
      attendeesRolesAdmin: [
        ...selectedGuestRoles,
        ...selectedContacts.map((s) => s.role),
      ].filter((a) => a === 4),
    }),
    [
      date,
      description,
      guestMembers,
      segments,
      attendance,
      presentationType,
      selectedContacts,
      selectedGuestRoles,
      time,
      title,
      userInvites,
      duration,
    ]
  )
  useEffect(() => {
    setLoading(true)
    api.presentations
      .getNewPresentationData()
      .then(
        axios.spread((...responses) => {
          setDisplayData({
            types: responses[0].data.result,
            segments: responses[1].data.result,
            colleagues: responses[2].data.result,
            options: responses[3].data.result,
            roles: responses[4].data.result,
          })

          //logged in user is by default the moderator
          !isEdit &&
            presentationId === 0 &&
            setSelectedContacts(
              responses[2].data.result
                .filter((s) => s.isLoggedInContact)
                .map((p) => ({
                  contactId: p.contactId,
                  companyId: p.companyId,
                  contact: p,
                  role: 4,
                }))
            )
        })
      )
      .then(
        () =>
          isEdit &&
          api.presentations
            .getPresentationAttendees(presentationId)
            .then((response) => {
              const pres = response.data.result
              setPresentationType(getPresentationTypeId(pres.presentationType))
              setTitle(pres.title)
              setDescription(pres.description)
              setTimezone(pres.timezone)
              setDate(
                moment
                  .utc(pres.startTime)
                  .tz(pres.timezone)
                  .format('YYYY-MM-DDTHH:mm')
                  .split('T')[0]
              )
              setTime(
                moment
                  .utc(pres.startTime)
                  .tz(pres.timezone)
                  .format('YYYY-MM-DDTHH:mm')
                  .split('T')[1]
              )
              setStartTime(pres.startTime)
              setSelectedContacts(
                pres.presentationParticipants
                  .filter(
                    (p) =>
                      !p.isGuest &&
                      p.companyId === companyId &&
                      isAvailable(p.status)
                  )
                  .map((p) => ({
                    contactId: p.contactId,
                    companyId: p.companyId,
                    contact: p,
                    role: getParticipantRoleNumber(p.participantRole),
                    isSaved: true,
                  }))
              )
              setGuestMembers(
                pres.presentationParticipants
                  .filter((p) => p.isGuest && isAvailable(p.status))
                  .map((p) => {
                    setSelectedGuestRoles((prev) => [
                      ...prev,
                      getParticipantRoleNumber(p.participantRole),
                    ])
                    return {
                      firstName: p.name.split(' ')[0],
                      lastName: p.name.split(' ')[1],
                      email: p.email,
                      phone: p.phone,
                      role: getParticipantRoleNumber(p.participantRole),
                      isSaved: true,
                    }
                  })
              )
              setAttendance(getAttendanceNumber(pres.attendance))
              setSegments(pres.segments.map((p) => p.id))
              setOptions(pres.options.map((p) => p.id))
              setUserInvites(
                pres.presentationParticipants
                  .filter(
                    (p) =>
                      !p.isGuest &&
                      p.companyId !== companyId &&
                      p.isInvited &&
                      isAvailable(p.status)
                  )
                  .map((p) => ({
                    company: p.companyName,
                    role: getParticipantRoleNumber(p.participantRole),
                    ...p,
                    isSaved: true,
                  }))
              )
              setIsPublished(pres.isPublished)
              setFund(pres.fund?.name)
              setFundId(pres.fund?.id)
              setDeal(pres.deal?.name)
              setDealId(pres.deal?.id)
              setDuration(pres.duration)
              setIsLive(pres.isLive)
            })
      )
      .catch(() => {
        message.error('Could not fetch display data')
      })
      .finally(() => setLoading(false))
  }, [presentationId, isEdit, companyId])

  const presentation = {
    presentationType,
    title,
    description,
    startTime: moment
      .tz(`${date}T${time}`, 'YYYY-MM-DDTh:mm a', timezone)
      .utc()
      .format(),
    attendance,
    userInvites,
    internalParticipants: [...selectedContacts, ...userInvites],
    guestParticipants: guestMembers.map((g, key) => ({
      name: `${g.firstName} ${g.lastName}`,
      email: g.email,
      phone: g.phone,
      role: selectedGuestRoles[key],
    })),
    segments,
    options,
    timezone,
    contactId,
    companyId,
    fundId,
    fund,
    dealId,
    deal,
    duration,
  }

  const handleSaveDraft = () => {
    trackEvent({
      eventName: 'click',
      element: 'Presentation Save',
    })

    const saveDraft = () =>
      presentationId === 0
        ? api.presentations.handleNewPresentationDraftCreate(presentation)
        : api.presentations.handleNewPresentationDraftEdit(
            presentationId,
            presentation
          )

    setLoading(true)
    saveDraft()
      .then((response) => {
        message.success(
          isPublished
            ? 'Presentation saved successfully'
            : `Presentation draft saved successfully`
        )
        presentationId === 0 && setPresentationId(response.data.result)
      })
      .catch(() => message.error('Could not save draft'))
      .finally(() => setLoading(false))
  }

  const handlePublish = () => {
    trackEvent({
      eventName: 'click',
      element: 'Presentation publish',
    })

    setLoading(true)
    api.presentations
      .handleNewPresentationPublish(presentationId, presentation)
      .then(() => {
        !isPublished && message.success(`Presentation published successfully`)
        history.push('/community/presentations')
      })
      .catch(() => message.error('Could not publish presentation'))
      .finally(() => setLoading(false))
  }

  const handleNextStep = () => {
    trackEvent({
      eventName: 'click',
      element: 'Presentation next step',
    })
    setCurrentStep((prevCurrentStep) => prevCurrentStep + 1)
  }

  const handleBackStep = () => {
    trackEvent({
      eventName: 'click',
      element: 'Presentation back step',
    })
    setCurrentStep((prevCurrentStep) => prevCurrentStep - 1)
  }

  const presentationWizardCtxValue = {
    answers,
    guestMembers,
    setGuestMembers,
    presentationType,
    setPresentationType,
    timezone,
    setTimezone,
    date,
    setDate,
    time,
    setTime,
    title,
    setTitle,
    description,
    setDescription,
    selectedContacts,
    setSelectedContacts,
    selectedGuestRoles,
    setSelectedGuestRoles,
    segments,
    setSegments,
    attendance,
    setAttendance,
    userInvites,
    setUserInvites,
    options,
    setOptions,
    currentStep,
    handleBackStep,
    handleNextStep,
    handleSaveDraft,
    displayData,
    presentation,
    handlePublish,
    isEdit,
    isPublished,
    fund,
    setFund,
    fundId,
    setFundId,
    deal,
    setDeal,
    dealId,
    setDealId,
    duration,
    setDuration,
    startTime,
    isLive,
  }
  return (
    <Track>
      <PresentationWizardCtx.Provider value={presentationWizardCtxValue}>
        <Loading spinning={loading}>
          {!isEmpty(displayData) && !loading && (
            <Formik
              initialValues={initialAnswers()}
              validationSchema={schema[currentStep]}
              validateOnChange={false}
            >
              <PresentationWizard />
            </Formik>
          )}
        </Loading>
      </PresentationWizardCtx.Provider>
    </Track>
  )
}

export default PresentationWizardForm
