/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { Empty, message } from 'antd'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { Redirect } from 'react-router-dom'
import { api as http } from '~/api/services'
import { ReactComponent as Logo } from '~/assets/new-logo.svg'
import Loading from '~/components/Loading'
import InitialStep from '~/components/NewCompanyOnboardWizard/InitialStep/InitialStep'
import Step1 from '~/components/NewCompanyOnboardWizard/Step1/Step1'
import Wizard from '~/components/NewCompanyOnboardWizard/wizard'
import { BASE_URL } from '~/config'

const NewCompanyOnboardWizard = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [questions, setQuestions] = useState({})
  const [loading, setLoading] = useState(false)
  const [toLogin, setToLogin] = useState(false)
  const [companyId, setCompanyId] = useState(0)
  const [companyName, setCompanyName] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [answers, setAnswers] = useState([])

  useEffect(() => {
    http
      .get('/on-boarding/company')
      .then((response) => {
        setQuestions(response.data.result)
        setCompanyId(response.data.result.companyId)
        setCompanyName(response.data.result.name)
        setEmailValue(response.data.result.email)
      })
      .catch(() => {
        message.error('Invalid Invitation')
      })
      .finally(() => setLoading(false))
  }, [])

  const onChange = (current) => {
    setCurrentStep(current)
  }

  const getStepsQuestions = (stepNumber) => {
    if (stepNumber === 2) {
      return filter(
        questions.companyContactAllocatorQuestions,
        (question) => question.questionType == 'Radio Button'
      )
    } else if (stepNumber === 3)
      return questions.companyContactAllocatorQuestions
    else
      return filter(
        questions.companyContactQuestions,
        (q) => q.stepNumber === stepNumber
      )
  }

  const next = () => {
    setCurrentStep(currentStep + 1)
  }

  const back = () => {
    setCurrentStep(currentStep - 1)
  }

  const redirectToLogin = () => {
    setTimeout(() => setToLogin(true), 3000)
  }

  const finish = (val) => {
    const jobLevel = questions.companyContactQuestions
      .filter((obj) => obj.shortName == 'JobLevel')[0]
      .companyContactQuestionAnswers.filter(
        (obj) => obj.companyContactQuestionAnswerId == answers[0].JobLevel
      )[0].answer

    const jobFunction = questions.companyContactQuestions
      .filter((obj) => obj.shortName == 'JobFunction')[0]
      .companyContactQuestionAnswers.filter(
        (obj) => obj.companyContactQuestionAnswerId == answers[0].JobFunction
      )[0].answer

    if (answers[0].Email == '') answers[0].Email = emailValue
    const addColleagueRequest = {
      CompanyID: companyId,
      Colleague: {
        FirstName: answers[0].FirstName,
        LastName: answers[0].LastName,
        Email: answers[0].Email,
        JobTitle: answers[0].JobTitle,
        JobLevel: jobLevel,
        JobFunction: jobFunction,
        Address1: val.Address1,
        Address2: val.Address2,
        Address3: val.Address3,
        City: val.City,
        Continent: val.Continent,
        Country: val.Country,
        MobilePhone: val.MobilePhone,
        PhoneNumber: val.PhoneNumber,
        PostalCode: val.PostalCode,
        WorkExtension: val.WorkExtension,
        WorkPhone: val.WorkPhone,
      },
    }

    fetch(`${BASE_URL}/companies/savecontact`, {
      method: 'POST',
      body: JSON.stringify(addColleagueRequest),
      headers: {
        'Content-type': 'application/json;',
      },
    }).then((response) => {
      if (response.status == 200) {
        message.success('Onboarding successfully completed!')
        redirectToLogin()
      } else {
        message.error('Something went wrong, please try again!')
        console.log(response)
      }
    })
  }

  if (isEmpty(questions))
    return (
      <Loading spinning={loading}>
        <Empty />
      </Loading>
    )

  const getData = (val) => {
    setAnswers([...answers, val])
  }

  const steps = {
    0: InitialStep,
    1: Step1,
  }

  const Step = get(steps, currentStep)

  return (
    <div
      className="cc-shadow3"
      style={{
        width: '95%',
        margin: 'auto',
        padding: '25px',
        background: '#FFFFFF',
      }}
    >
      <div style={{ padding: '20px' }}>
                
        <Logo
          className="Logo"
          style={{
            display: 'flex',
            margin: 'auto',
            paddingTop: '40px',
            paddingBottom: '61px',
          }}
        />
               
        <Wizard onChange={onChange} currentStep={currentStep} />
        {' '}
        <div>
          {' '} 
          <Step
            questions={getStepsQuestions(currentStep)}
            companyName={isNil(companyName) ? '' : companyName}
            next={next}
            back={back}
            finish={finish}
            sendData={getData}
            emailValue={emailValue}
          />
          {' '}
          {toLogin ? <Redirect to="/login" /> : null}
        </div>
      </div>
    </div>
  )
}

export default NewCompanyOnboardWizard
