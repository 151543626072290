import PropTypes from 'prop-types'
import { TextArea } from '@context365/forms'
import { get, useFormContext, useWatch } from 'react-hook-form'
import { useFieldSchema } from '../Form'

export function TextAreaField({
  name,
  label,
  showCharacterCount,
  ...inputProps
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const error = get(errors, name)
  const rules = useFieldSchema(name, label)

  const currentCharacterCount = useWatch({ name })?.length ?? 0
  const helpMessage =
    typeof inputProps.helpMessage === 'function'
      ? inputProps.helpMessage(rules, currentCharacterCount)
      : inputProps.helpMessage

  return (
    <TextArea
      label={label}
      required={rules.required}
      skipNativeValidation
      {...inputProps}
      {...register(name, inputProps)}
      helpMessage={
        showCharacterCount
          ? formatHelpMessage({ helpMessage, currentCharacterCount, ...rules })
          : helpMessage
      }
      errorMessage={
        showCharacterCount
          ? formatErrorMessage(error?.message, currentCharacterCount)
          : error?.message
      }
    />
  )
}

TextAreaField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helpMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  showCharacterCount: PropTypes.bool,
}

function formatHelpMessage({ helpMessage, currentCharacterCount, min, max }) {
  const currentCountPhrase = getCurrentCountPhrase(currentCharacterCount)
  if (helpMessage) {
    helpMessage = helpMessage.endsWith('.') ? helpMessage : `${helpMessage}.`
    return `${helpMessage} ${currentCountPhrase}`
  }

  let minMaxPhrase = ''
  if (min && max) {
    minMaxPhrase = `Must be between ${min} and ${max} characters.`
  } else if (min) {
    minMaxPhrase = `Must be at least ${min} characters.`
  } else if (max) {
    minMaxPhrase = `Must be no longer than ${max} characters.`
  }

  return minMaxPhrase
    ? `${minMaxPhrase} ${currentCountPhrase}`
    : currentCountPhrase
}

function formatErrorMessage(errorMessage, currentCharacterCount) {
  if (!errorMessage) {
    return errorMessage
  }

  const currentCountPhrase = getCurrentCountPhrase(currentCharacterCount)
  errorMessage = errorMessage.endsWith('.') ? errorMessage : `${errorMessage}.`
  return `${errorMessage} ${currentCountPhrase}`
}

function getCurrentCountPhrase(characterCount) {
  return `Character count: ${characterCount}`
}
