import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Tabs, Tooltip } from 'antd'
import isNil from 'lodash/isNil'
import { fetchMeetingsForProfile } from '~/actions/meetings'
import { getServiceProviderProfile } from '~/actions/serviceProviders'
import Loading from '../Loading'
import UpcomingMeetingRequests from '../UpcomingMeetingRequests'
import ServiceProviderProfile from './ServiceProviderProfile'
import '~/styles/index.less'
import './ServiceProviderDetailsModal.less'

const { TabPane } = Tabs

const ServiceProviderDetailsModal = ({
  companyId,
  visible,
  onCancel,
  canSendMeetingRequest,
  onSendRequest,
  pastSummit = false,
  isMeetingRequestButtonEnabled = true,
  meeting,
  eventId = null,
  onRefresh = () => {},
}) => {
  const [activeTabKey, setActiveTabKey] = useState('1')
  const [loading, setLoading] = useState(false)
  const [companyProfile, setCompanyProfile] = useState({})
  const [conversationId, setConversationId] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [loadingMeetings, setLoadingMeetings] = useState(false)
  const [meetings, setMeetings] = useState(null)

  useEffect(() => {
    if (visible && companyId) {
      setLoading(true)
      setCompanyProfile({})
      setActiveTabKey('1')
      getServiceProviderProfile(companyId)
        .then((response) => {
          setLoading(false)
          const profile = response.data.result
          setCompanyProfile(profile)
        })
        .catch(() => {
          setLoading(false)
          setCompanyProfile({})
        })

      fetchMeetingsForProfile(companyId, {
        eventId: isNil(eventId) ? 0 : eventId,
      })
        .then((res) => {
          setMeetings(res.data.result)
        })
        .finally(() => {
          setLoadingMeetings(false)
        })
    }
  }, [companyId, eventId, visible, refresh])

  const onTabChange = (tabKey) => {
    setActiveTabKey(tabKey)
  }

  useEffect(() => {
    if (isNil(conversationId)) {
      setConversationId(meeting?.conversationID)
    }
  }, [conversationId, meeting])

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={() => onCancel()}
      width="60%"
      bodyStyle={{ padding: 0 }}
    >
      <div className="service-provider-details-container">
        <Loading spinning={loading}>
          <div className="service-provider-details-header">
            <div className="service-provider-details-title">
              <span className="cc-heading5">{companyProfile.name}</span>
              <br />
              <span className="service-provider-details-location cc-small-font">
                {companyProfile.city &&
                  companyProfile.country &&
                  `${companyProfile.city} ${companyProfile.country}`}
              </span>
            </div>
          </div>

          <div className="service-provider-details-tabs">
            <Tabs activeKey={activeTabKey} onChange={onTabChange}>
              <TabPane tab={<span className="tab-pane">Overview</span>} key="1">
                <ServiceProviderProfile
                  company={companyProfile}
                  companyId={companyId}
                />
              </TabPane>
              <TabPane tab={<span className="tab-pane">Meetings</span>} key="2">
                <UpcomingMeetingRequests
                  onRefresh={() => {
                    setRefresh((prevRefresh) => !prevRefresh)
                    onRefresh()
                  }}
                  eventId={eventId}
                  meetings={meetings}
                  loadingMeetings={loadingMeetings}
                />
              </TabPane>
            </Tabs>
          </div>

          {!pastSummit && (
            <div className="service-provider-details-footer">
              <div className="service-provider-details-footer-buttons">
                <Button type="cancel" onClick={() => onCancel()}>
                  Cancel
                </Button>
                {!isMeetingRequestButtonEnabled || !canSendMeetingRequest ? (
                  <Tooltip title="A meeting between the two companies already exists.">
                    <Button
                      type="primary"
                      onClick={onSendRequest}
                      disabled={
                        !isMeetingRequestButtonEnabled || !canSendMeetingRequest
                      }
                    >
                      Send meeting request
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    type="primary"
                    onClick={onSendRequest}
                    disabled={
                      !isMeetingRequestButtonEnabled || !canSendMeetingRequest
                    }
                  >
                    Send meeting request
                  </Button>
                )}
                {/* </Col>
              )} */}
              </div>
            </div>
          )}
        </Loading>
      </div>
    </Modal>
  )
}

ServiceProviderDetailsModal.propTypes = {
  onSendRequest: PropTypes.func,
  meeting: PropTypes.object,
  visible: PropTypes.bool,
  pastSummit: PropTypes.bool.isRequired,
  eventId: PropTypes.number,
  companyId: PropTypes.number,
  onCancel: PropTypes.func,
  onRefresh: PropTypes.func,
  canSendMeetingRequest: PropTypes.bool,
  isMeetingRequestButtonEnabled: PropTypes.bool,
}

export default ServiceProviderDetailsModal
