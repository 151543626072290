import { useFlags } from 'launchdarkly-react-client-sdk'
import { Route, Routes } from 'react-router-dom-v5-compat'
import NotFound from '~/views/NotFound'
import AllocatorProfile from './AllocatorProfile'

export default function AllocatorRoute() {
  const { institutionalAllocators } = useFlags()

  return (
    <Routes>
      {institutionalAllocators && (
        <Route path=":companyId" element={<AllocatorProfile />} />
      )}

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
