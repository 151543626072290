import * as React from 'react'
import {
  Axis,
  AxisLabel,
  Chart,
  Grid,
  LineSeries,
  useAxis,
  useChart,
  useChartTooltipState,
  useLineSeries,
} from '@context365/charts'
import * as api from '~/api'
import { LineChartPlaceholder } from '~/components/ChartPlaceholder'
import { useCompareFundsContext } from '../compareFundsContext'
import {
  BenchmarkSelect,
  ChartContainer,
  ChartControls,
  TimeSpanSelect,
} from './chartComponents'
import {
  CHART_HEIGHT,
  CHART_WIDTH,
  createDataset,
  format,
  useTimeAxis,
} from './chartHelpers'
import CompareFundsTooltip from './CompareFundsTooltip'
import createComparisonChart from './createComparisonChart'
import { useTrackComparedFunds } from './trackCompareFunds'

const chartHeight = CHART_HEIGHT * 0.75
const verticalPadding = 10

const correlationDomain = [-1, 1]

function RollingCorrelationChart({ chartData }) {
  const { funds, options, getFundColor } = useCompareFundsContext()
  const { timeSpan = 12 } = options

  const chart = useChart({
    data: chartData,
    height: chartHeight * 2 + verticalPadding * 2,
    width: CHART_WIDTH,
    padding: {
      left: 56,
      right: 20,
      top: 10,
      bottom: 20,
    },
  })
  const [rollingRange, weightedRange] = React.useMemo(() => {
    const verticalMidpoint =
      (chart.chartBounds.top + chart.chartBounds.bottom) / 2
    return [
      [verticalMidpoint - verticalPadding, chart.chartBounds.top],
      [chart.chartBounds.bottom, verticalMidpoint + verticalPadding],
    ]
  }, [chart.chartBounds.top, chart.chartBounds.bottom])

  const xAxis = useTimeAxis(chartData, chart)
  const rollingAxis = useAxis({
    type: 'linear',
    getValue: (d) => d[1],
    formatValue: format.float,
    range: rollingRange,
    domain: correlationDomain,
  })
  const weightedAxis = useAxis({
    type: 'linear',
    getValue: (d) => d[1],
    formatValue: format.float,
    range: weightedRange,
    domain: correlationDomain,
  })

  const rollingCorrelation = useLineSeries({
    chart,
    x: xAxis,
    y: rollingAxis,
    series: Object.keys(chartData.funds).map((fundId) => ({
      key: fundId,
      ...getFundColor(fundId),
      label: funds.find((f) => f.fundId === +fundId)?.name,
      getDataset: (data) =>
        createDataset(data.dates, data.funds[fundId].rollingCorrelation ?? []),
    })),
  })
  const rollingWeightedCorrelation = useLineSeries({
    chart,
    x: xAxis,
    y: weightedAxis,
    series: Object.keys(chartData.funds).map((fundId) => ({
      key: fundId,
      ...getFundColor(fundId),
      label: funds.find((f) => f.fundId === +fundId)?.name,
      getDataset: (data) =>
        createDataset(
          data.dates,
          data.funds[fundId].rollingWeightedCorrelation ?? []
        ),
    })),
  })

  const tooltip = useChartTooltipState(chart, rollingCorrelation)
  const activeDataPoints = tooltip.activeDataPoints.map((d) => {
    const rollingCorrelationValue = d.value

    const weightedDataPoint = rollingWeightedCorrelation.series
      .find((s) => s.key === d.key)
      ._dataPoints.find((p) => p[0] === d.dataPoint[0])
    const weightedCorrelationValue = weightedAxis.format(weightedDataPoint)

    return {
      ...d,
      value: (
        <>
          <span>Rolling Correlation: {rollingCorrelationValue}</span>
          <br />
          <span>Rolling Weighted Correlation: {weightedCorrelationValue}</span>
        </>
      ),
    }
  })

  return (
    <ChartContainer>
      <div className="text-center type-header-sm">
        {timeSpan}-Month Rolling Correlation
      </div>
      <Chart {...chart.getChartProps()} className="text-grey-500">
        <Axis {...rollingCorrelation.getAxisProps('bottom')} />
        <Axis {...rollingCorrelation.getAxisProps('left')} />
        <Axis {...rollingWeightedCorrelation.getAxisProps('left')} />
        <AxisLabel
          chart={chart}
          position="left"
          className="text-grey-600"
          x={(rollingRange[0] + rollingRange[1]) / 2}
        >
          Rolling Correlation
        </AxisLabel>
        <AxisLabel
          chart={chart}
          position="left"
          className="text-grey-600"
          x={-(rollingRange[0] + rollingRange[1]) / 2}
        >
          Rolling Weighted Correlation
        </AxisLabel>
        <Grid
          {...rollingCorrelation.getGridProps('horizontal')}
          tickValues={[0]}
        />
        <Grid
          {...rollingWeightedCorrelation.getGridProps('horizontal')}
          tickValues={[0]}
        />
        <LineSeries series={rollingCorrelation.series} />
        <LineSeries series={rollingWeightedCorrelation.series} />
      </Chart>
      <CompareFundsTooltip
        {...tooltip}
        activeDataPoints={activeDataPoints}
        getRowClass={() => 'pt-2'}
      />
    </ChartContainer>
  )
}

function RollingCorrelationChartControls() {
  const { options, setOption, benchmarks } = useCompareFundsContext()
  const { primaryBenchmarkId, timeSpan = 12 } = options
  const trackClick = useTrackComparedFunds('primaryBenchmarkId')

  return (
    <ChartControls>
      <BenchmarkSelect
        benchmarks={benchmarks}
        id="primary-benchmark"
        label="Benchmark"
        selectedId={primaryBenchmarkId}
        onChange={(id) => {
          const update = { primaryBenchmarkId: id }
          trackClick('PrimaryBenchmark', update)
          setOption(update)
        }}
      />
      <TimeSpanSelect
        value={timeSpan}
        onChange={(timeSpan) => setOption({ timeSpan })}
      />
    </ChartControls>
  )
}

async function getRollingCorrelationData(fundIds, options) {
  const { primaryBenchmarkId, timeSpan = 12 } = options

  const [rollingCorrelation, rollingWeightedCorrelation] = await Promise.all([
    api.fundCharts.rollingCorrelation(fundIds, primaryBenchmarkId, timeSpan),
    api.fundCharts.rollingWeightedCorrelation(
      fundIds,
      primaryBenchmarkId,
      timeSpan
    ),
  ])

  const droppedFunds = [
    ...new Set([
      ...rollingCorrelation.data.droppedfunds,
      ...rollingWeightedCorrelation.data.droppedfunds,
    ]),
  ]

  return {
    data: {
      dates: rollingCorrelation.data.dates,
      funds: fundIds.reduce((funds, fundId) => {
        funds[fundId] = {
          rollingCorrelation: rollingCorrelation.data.funds[fundId],
          rollingWeightedCorrelation:
            rollingWeightedCorrelation.data.funds[fundId],
        }
        return funds
      }, {}),
      droppedfunds: droppedFunds,
    },
  }
}

export default createComparisonChart({
  queryFn: getRollingCorrelationData,
  chartControls: RollingCorrelationChartControls,
  loading: (
    <div className="w-max mx-auto">
      <LineChartPlaceholder
        animate
        aria-label="loading chart data"
        height={chartHeight}
        width={CHART_WIDTH}
      />
      <div style={{ height: verticalPadding }} />
      <LineChartPlaceholder
        animate
        aria-label="loading chart data"
        height={chartHeight}
        width={CHART_WIDTH}
      />
    </div>
  ),
  error: ({ error }) => (
    <div className="w-max mx-auto relative">
      <LineChartPlaceholder
        aria-label="data not available"
        height={chartHeight}
        width={CHART_WIDTH}
      />
      <div className="flex flex-col absolute inset-0 items-center justify-center">
        <div className="type-body-regular-lg mb-2">
          Unable to load chart data.
        </div>
        <div className="type-body-regular-md">
          Please choose a different benchmark or different funds to compare.
        </div>
        <div className="type-body-regular-xs">
          {error.response.data.message}
        </div>
      </div>
    </div>
  ),
  success: RollingCorrelationChart,
})
