import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'
import numeral from 'numeral'
import FloatingInput from '../FloatingInput'

const InvestmentSizeSection = ({ value, onChange, displayErrors }) => {
  const [minimumInvestmentSize, setMinimumInvestmentSize] = useState(
    isNil(value) ? '' : value.minimumInvestmentSize
  )

  const [maximumInvestmentSize, setMaximumInvestmentSize] = useState(
    isNil(value) ? '' : value.maximumInvestmentSize
  )

  const [minimumFundSizeInterest, setMinimumFundSizeInterest] = useState(
    isNil(value) ? '' : value.minimumFundSizeInterest
  )

  const [maximumFundSizeInterest, setMaximumFundSizeInterest] = useState(
    isNil(value) ? '' : value.maximumFundSizeInterest
  )

  const [minimumFirmSizeAumInterest, setMinimumFirmSizeAumInterest] = useState(
    isNil(value) ? '' : value.minimumFirmSizeAumInterest
  )

  const [maximumFirmSizeAumInterest, setMaximumFirmSizeAumInterest] = useState(
    isNil(value) ? '' : value.maximumFirmSizeAumInterest
  )

  const [minimumInvestmentSizeValid, setMinimumInvestmentSizeValid] =
    useState(true)

  const [maximumInvestmentSizeValid, setMaximumInvestmentSizeValid] =
    useState(true)

  const [minimumFundSizeInterestValid, setMinimumFundSizeInterestValid] =
    useState(true)

  const [maximumFundSizeInterestValid, setMaximumFundSizeInterestValid] =
    useState(true)

  const [minimumFirmSizeAumInterestValid, setMinimumFirmSizeAumInterestValid] =
    useState(true)

  const [maximumFirmSizeAumInterestValid, setMaximumFirmSizeAumInterestValid] =
    useState(true)

  const [numericTooltip, setNumericTooltip] = useState(null)

  const isValid = (totalValue) =>
    Object.keys(totalValue)
      .map((x) => !isNil(totalValue[x]) && totalValue[x] !== '')
      .filter((x) => x === false).length === 0

  const onValueChange = (id, e) => {
    const { value } = e.target
    if (!numeral.validate(value) && value !== '' && value !== null) {
      setNumericTooltip(id)
      return
    }

    const totalValue = {
      minimumInvestmentSize,
      maximumInvestmentSize,
      minimumFundSizeInterest,
      maximumFundSizeInterest,
      minimumFirmSizeAumInterest,
      maximumFirmSizeAumInterest,
    }

    setNumericTooltip(null)
    switch (id) {
      case 'minimumInvestmentSize':
        setMinimumInvestmentSize(value === '' ? '' : numeral(value).value())
        totalValue.minimumInvestmentSize =
          value === '' ? '' : numeral(value).value()
        break
      case 'maximumInvestmentSize':
        setMaximumInvestmentSize(value === '' ? '' : numeral(value).value())
        totalValue.maximumInvestmentSize =
          value === '' ? '' : numeral(value).value()
        break
      case 'minimumFundSizeInterest':
        setMinimumFundSizeInterest(value === '' ? '' : numeral(value).value())
        totalValue.minimumFundSizeInterest =
          value === '' ? '' : numeral(value).value()
        break
      case 'maximumFundSizeInterest':
        setMaximumFundSizeInterest(value === '' ? '' : numeral(value).value())
        totalValue.maximumFundSizeInterest =
          value === '' ? '' : numeral(value).value()
        break
      case 'minimumFirmSizeAumInterest':
        setMinimumFirmSizeAumInterest(
          value === '' ? '' : numeral(value).value()
        )
        totalValue.minimumFirmSizeAumInterest =
          value === '' ? '' : numeral(value).value()
        break
      case 'maximumFirmSizeAumInterest':
        setMaximumFirmSizeAumInterest(
          value === '' ? '' : numeral(value).value()
        )
        totalValue.maximumFirmSizeAumInterest =
          value === '' ? '' : numeral(value).value()
        break
      default:
        break
    }
    totalValue.isValid = isValid(totalValue)

    onChange(totalValue)
  }

  const onTextBlurred = (id) => {
    switch (id) {
      case 'minimumInvestmentSize':
        setMinimumInvestmentSizeValid(
          !isNil(minimumInvestmentSize) && minimumInvestmentSize !== ''
        )
        break
      case 'maximumInvestmentSize':
        setMaximumInvestmentSizeValid(
          !isNil(maximumInvestmentSize) && maximumInvestmentSize !== ''
        )
        break
      case 'minimumFundSizeInterest':
        setMinimumFundSizeInterestValid(
          !isNil(minimumFundSizeInterest) && minimumFundSizeInterest !== ''
        )
        break
      case 'maximumFundSizeInterest':
        setMaximumFundSizeInterestValid(
          !isNil(maximumFundSizeInterest) && maximumFundSizeInterest !== ''
        )
        break
      case 'minimumFirmSizeAumInterest':
        setMinimumFirmSizeAumInterestValid(
          !isNil(minimumFirmSizeAumInterest) &&
            minimumFirmSizeAumInterest !== ''
        )
        break
      case 'maximumFirmSizeAumInterest':
        setMaximumFirmSizeAumInterestValid(
          !isNil(maximumFirmSizeAumInterest) &&
            maximumFirmSizeAumInterest !== ''
        )
        break
      default:
        break
    }
  }

  const getTooltip = (id) =>
    id !== numericTooltip ? null : 'Numeric values allowed only'

  useEffect(() => {
    if (displayErrors === true) {
      setMinimumInvestmentSizeValid(
        !isNil(minimumInvestmentSize) && minimumInvestmentSize !== ''
      )
      setMaximumInvestmentSizeValid(
        !isNil(maximumInvestmentSize) && maximumInvestmentSize !== ''
      )
      setMinimumFundSizeInterestValid(
        !isNil(minimumFundSizeInterest) && minimumFundSizeInterest !== ''
      )
      setMaximumFundSizeInterestValid(
        !isNil(maximumFundSizeInterest) && maximumFundSizeInterest !== ''
      )
      setMinimumFirmSizeAumInterestValid(
        !isNil(minimumFirmSizeAumInterest) && minimumFirmSizeAumInterest !== ''
      )
      setMaximumFirmSizeAumInterestValid(
        !isNil(maximumFirmSizeAumInterest) && maximumFirmSizeAumInterest !== ''
      )
    }
  }, [
    displayErrors,
    maximumFirmSizeAumInterest,
    maximumFundSizeInterest,
    maximumInvestmentSize,
    minimumFirmSizeAumInterest,
    minimumFundSizeInterest,
    minimumInvestmentSize,
  ])

  useEffect(() => {
    if (isNil(value.isValid)) {
      const val = value
      val.isValid = isValid(val)
    }
  }, [value])

  return (
    <div className="cc-onboard-section">
      <div className="cc-heading4 cc-onboard-section-title">
        Quantitative Interest
      </div>

      <div className="cc-onboard-section-row">
        <div className="cc-heading5 cc-onboard-section-row-title">
          Investment Size *
        </div>
        <div className="cc-onboard-section-row-controls">
          <div className="cc-onboard-section-row-control">
            <FloatingInput
              name="Minimum Investment Size"
              value={
                isNil(minimumInvestmentSize) || minimumInvestmentSize === ''
                  ? ''
                  : numeral(minimumInvestmentSize).format('0,0')
              }
              isValid={minimumInvestmentSizeValid}
              id="minimumInvestmentSize"
              onChange={onValueChange}
              handleChange={true}
              tooltip={getTooltip('minimumInvestmentSize')}
              onBlur={onTextBlurred.bind(null, 'minimumInvestmentSize')}
              validationMessage="This field is required"
            />
          </div>
          <div className="cc-onboard-section-row-control">
            <FloatingInput
              name="Maximum Investment Size"
              value={
                isNil(maximumInvestmentSize) || maximumInvestmentSize === ''
                  ? ''
                  : numeral(maximumInvestmentSize).format('0,0')
              }
              isValid={maximumInvestmentSizeValid}
              id="maximumInvestmentSize"
              onChange={onValueChange}
              handleChange={true}
              tooltip={getTooltip('maximumInvestmentSize')}
              onBlur={onTextBlurred.bind(null, 'maximumInvestmentSize')}
              validationMessage="This field is required"
            />
          </div>
        </div>
        <div
          className="cc-investor-details-form-separator"
          style={{ marginTop: '20px' }}
        />
      </div>

      <div className="cc-onboard-section-row">
        <div className="cc-heading5 cc-onboard-section-row-title">
          Fund Size Interest *
        </div>
        <div className="cc-onboard-section-row-controls">
          <div className="cc-onboard-section-row-control">
            <FloatingInput
              name="Minimum Fund Size Interest"
              value={
                isNil(minimumFundSizeInterest) || minimumFundSizeInterest === ''
                  ? ''
                  : numeral(minimumFundSizeInterest).format('0,0')
              }
              isValid={minimumFundSizeInterestValid}
              id="minimumFundSizeInterest"
              onChange={onValueChange}
              handleChange={true}
              tooltip={getTooltip('minimumFundSizeInterest')}
              onBlur={onTextBlurred.bind(null, 'minimumFundSizeInterest')}
              validationMessage="This field is required"
            />
          </div>
          <div className="cc-onboard-section-row-control">
            <FloatingInput
              name="Maximum Fund Size Interest"
              value={
                isNil(maximumFundSizeInterest) || maximumFundSizeInterest === ''
                  ? ''
                  : numeral(maximumFundSizeInterest).format('0,0')
              }
              isValid={maximumFundSizeInterestValid}
              id="maximumFundSizeInterest"
              onChange={onValueChange}
              handleChange={true}
              tooltip={getTooltip('maximumFundSizeInterest')}
              onBlur={onTextBlurred.bind(null, 'maximumFundSizeInterest')}
              validationMessage="This field is required"
            />
          </div>
        </div>
        <div
          className="cc-investor-details-form-separator"
          style={{ marginTop: '20px' }}
        />
      </div>

      <div className="cc-onboard-section-row">
        <div className="cc-heading5 cc-onboard-section-row-title">
          Firm Size AUM Interest *
        </div>
        <div className="cc-onboard-section-row-controls">
          <div className="cc-onboard-section-row-control">
            <FloatingInput
              name="Minimum Firm Size AUM Interest"
              value={
                isNil(minimumFirmSizeAumInterest) ||
                minimumFirmSizeAumInterest === ''
                  ? ''
                  : numeral(minimumFirmSizeAumInterest).format('0,0')
              }
              isValid={minimumFirmSizeAumInterestValid}
              id="minimumFirmSizeAumInterest"
              onChange={onValueChange}
              handleChange={true}
              tooltip={getTooltip('minimumFirmSizeAumInterest')}
              onBlur={onTextBlurred.bind(null, 'minimumFirmSizeAumInterest')}
              validationMessage="This field is required"
            />
          </div>
          <div className="cc-onboard-section-row-control">
            <FloatingInput
              name="Maximum Firm Size AUM Interest"
              value={
                isNil(maximumFirmSizeAumInterest) ||
                maximumFirmSizeAumInterest === ''
                  ? ''
                  : numeral(maximumFirmSizeAumInterest).format('0,0')
              }
              isValid={maximumFirmSizeAumInterestValid}
              id="maximumFirmSizeAumInterest"
              onChange={onValueChange}
              handleChange={true}
              tooltip={getTooltip('maximumFirmSizeAumInterest')}
              onBlur={onTextBlurred.bind(null, 'maximumFirmSizeAumInterest')}
              validationMessage="This field is required"
            />
          </div>
        </div>
        <div
          className="cc-investor-details-form-separator"
          style={{ marginTop: '20px' }}
        />
      </div>
    </div>
  )
}

InvestmentSizeSection.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  displayErrors: PropTypes.bool,
}

export default InvestmentSizeSection
