import PropTypes from 'prop-types'
import { Avatar, AvatarGroup } from '@context365/avatar'
import { Radio, RadioGroup } from '@context365/forms'
import { Tooltip } from '@context365/popovers'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { getInitials } from '~/utils/helpers'

const BoothSelectForMeetingRequest = ({
  booths,
  selectedBoothId,
  onChangeBooth,
  title = 'Booth where you want to meet',
}) => {
  return (
    !isEmpty(booths) &&
    booths?.length > 1 && (
      <>
        <div className="type-header-xs mt-4">{title}</div>
        <RadioGroup
          size="small"
          className="flex flex-col items-start gap-y-4 mt-4"
          value={selectedBoothId}
          onChange={(e) => onChangeBooth(e)}
        >
          {map(booths, (b) => (
            <Radio
              key={b.eventCompanyBoothFundId}
              value={b.eventCompanyBoothId}
              className="flex flex-row items-center"
            >
              <div className="flex flex-row justify-start items-center  gap-x-4">
                {b.boothId}
                <AvatarGroup isLayered size="small">
                  {map(b.contacts, (contact) => (
                    <Tooltip
                      key={contact.id}
                      text={
                        <>
                          <div>{contact.contactName}</div>
                          {!isEmpty(contact.imageUrl) && (
                            <div className="text-center mt-2">
                              <img
                                alt=""
                                className="rounded-full w-24 h-24"
                                src={contact.imageUrl}
                              />
                            </div>
                          )}
                        </>
                      }
                    >
                      <Avatar
                        key={contact.contactId}
                        size="small"
                        profileImageUrl={contact.imageUrl}
                        initials={getInitials(contact.contactName)}
                        isOrganizer={false}
                      />
                    </Tooltip>
                  ))}
                </AvatarGroup>
              </div>
            </Radio>
          ))}
        </RadioGroup>
      </>
    )
  )
}

BoothSelectForMeetingRequest.propTypes = {
  onChangeBooth: PropTypes.func.isRequired,
  selectedBoothId: PropTypes.number.isRequired,
  booths: PropTypes.array.isRequired,
  title: PropTypes.string,
}

export default BoothSelectForMeetingRequest
