import { Component } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import isNil from 'lodash/isNil'
import './FloatingSelect.less'

const { Option } = Select

class SelectInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: -1,
      focused: false,
    }
  }

  valueChanged = (e) => {
    this.setState({
      value: e,
      focused: false,
    })

    if (typeof this.props.onChange === 'function')
      this.props.onChange(e, this.props.id)
  }

  focused = () => {
    this.setState({
      focused: true,
    })
  }

  blurred = (e) => {
    const propsBlur = this.props.onBlur
    if (!isNil(propsBlur)) propsBlur(e)
  }

  render() {
    const value =
      typeof this.props.value === 'undefined' || this.props.value === null
        ? this.state.value
        : this.props.value
    const { isValid } = this.props

    const anySelection = value !== -1

    let classes = !anySelection
      ? 'cc-floating-select-notext'
      : 'cc-floating-select-text'
    classes += ` ${
      this.state.focused === true
        ? 'cc-floating-select-focused'
        : 'cc-floating-select-nonfocused'
    }`
    classes += ' cc-floating-select'

    let placeholderClasses = !anySelection
      ? 'placeholder-select-movement-notext'
      : 'placeholder-select-movement-text'
    placeholderClasses += ` ${
      this.state.focused === true
        ? 'placeholder-select-focused'
        : 'placeholder-select-nonfocused'
    }`
    placeholderClasses += ' cc-floating-placeholder-select'

    let validationMessage = null
    if (isValid === false && this.state.focused) {
      classes += ' cc-floating-select-invalid'
      validationMessage = this.props.validationMessage
    }

    const options = this.props.options.map((x) => (
      <Option
        key={x.value}
        value={isNil(x.value) ? x : x.value.toString()}
        disabled={x.disabled}
      >
        {x.text}
      </Option>
    ))

    return (
      <div className="floating-select-row">
        <Select
          showSearch
          onFocus={this.focused}
          onBlur={this.blurred}
          size="large"
          placeholder={!this.state.focused && this.props.name}
          name={this.props.id}
          onChange={this.valueChanged}
          onSelect={this.onSelect}
          className={classes}
          value={anySelection ? value.toString() : undefined}
          loading={isNil(this.props.loading) ? false : this.props.loading}
          disabled={isNil(this.props.loading) ? false : this.props.loading}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().startsWith(input.toLowerCase())
          }
        >
          {options}
        </Select>
        <input
          type="hidden"
          value={
            this.props.useText ? this.state.selectedText : this.state.value
          }
          name={this.props.id}
        />
        <span className={placeholderClasses}>{this.props.name}</span>
        {isNil(validationMessage) ? null : (
          <div className="cc-small-font cc-validation-message">
            {validationMessage}
          </div>
        )}
      </div>
    )
  }
}

SelectInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  isValid: PropTypes.bool,
  validationMessage: PropTypes.string,
  loading: PropTypes.bool,
  useText: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

export default SelectInput
