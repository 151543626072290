import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import split from 'lodash/split'
import moment from 'moment'
import numeral from 'numeral'
import VALUE_TYPES from '~/constants/valueTypes'
import { addLineBreaks } from '~/utils/helpers'
import './ProfileDetails.less'

const ProfileDetails = ({ valueGroups }) => {
  const transformValue = (value, valueType) => {
    switch (valueType) {
      case VALUE_TYPES.CURRENCY:
        value = numeral(value).format('($0,0[.]0 a)').toUpperCase()
        break
      case VALUE_TYPES.DATETIME:
        value = moment(value).format('YYYY-MM-DD')
        break
      case VALUE_TYPES.MONTHS:
        value = value + (value === 1 ? ' month' : ' months')
        break
      case VALUE_TYPES.PERCENT:
        value = `${numeral(value).format('0.[00]')} %`
        break
      case VALUE_TYPES.DECIMAL:
        value = numeral(value).format('0.[00]')
        break
      case VALUE_TYPES.PERCENT_MULTIPLIED_100:
        value = numeral(value).format('0.0 %')
        break
      case VALUE_TYPES.ARRAY:
        if (Array.isArray(value)) {
          value = map(value, (x, i) => <div key={i}>‣ {x}</div>)
        } else if (!isNil(value)) {
          value = map(split(value, ','), (x, i) => <div key={i}>‣ {x}</div>)
        }
        break
      case VALUE_TYPES.PARAGRAPH:
        value = addLineBreaks(value)
        break
      case VALUE_TYPES.DAYS:
        value = value + (value === 1 ? ' day' : value > 1 ? ' days' : '')
        break
      default:
        break
    }

    return <span className="profile-details-detail-type">{value}</span>
  }

  return (
    <div className="profile-details-values-container">
      <div className="profile-details-details-container-full">
        {valueGroups.map((x, index) => (
          <div
            key={index}
            className="profile-details-details-section"
            style={{
              width: isNil(x.width) ? '100%' : x.width,
            }}
          >
            {!isNil(x.groupName) && (
              <div className="type-subtitle-md border-b">{x.groupName}</div>
            )}
            {!isNil(x.subGroupName) && (
              <div className="type-body-semibold-md text-header">
                {x.subGroupName}
              </div>
            )}
            <div
              className="profile-details-details-data-section"
              style={{
                flexDirection: x.displayMode === 0 ? 'column' : 'row',
              }}
            >
              {x.sections.map(
                (s) =>
                  s && (
                    <div
                      key={s.key}
                      className="profile-details-detail"
                      style={{
                        width: isNil(s.width)
                          ? `${
                              x.displayMode === 0
                                ? 100
                                : (100 / x.sections.length).toString()
                            }%`
                          : s.width,
                        display: 'inline-block',
                      }}
                    >
                      <div
                        className={
                          isNil(x.subGroupName)
                            ? 'type-body-semibold-sm text-grey-900 py-1'
                            : 'type-body-semibold-sm text-grey-800 py-1'
                        }
                      >
                        {s.label}
                      </div>
                      {!isNil(s.value) && s.value !== 'NULL' && (
                        <div className="type-body-regular-sm text-body">
                          {transformValue(s.value, s.valueType, s.key)}
                        </div>
                      )}
                    </div>
                  )
              )}
            </div>
            {isNil(x.groupName) && index !== valueGroups.length - 1 && (
              <div className="border-b py-1" />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

ProfileDetails.propTypes = {
  valueGroups: PropTypes.array.isRequired,
}

export default ProfileDetails
