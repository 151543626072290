import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import Profile from '~/components/Profile'
import useSearchParams from '~/hooks/useSearchParams'
import CompanyProfileAbout from './Tabs/CompanyProfileAbout'
import CompanyProfileFunds from './Tabs/CompanyProfileFunds'
import CompanyProfileInvestments from './Tabs/CompanyProfileInvestments'
import CompanyProfileLinks from './Tabs/CompanyProfileLinks'
import CompanyProfileMandates from './Tabs/CompanyProfileMandates'
import CompanyProfileMedia from './Tabs/CompanyProfileMedia'
import CompanyProfilePeople from './Tabs/CompanyProfilePeople'

export const getTabs = (company) => {
  const tabs = [
    {
      id: 'about',
      label: 'About',
      content: <CompanyProfileAbout company={company} />,
    },
    {
      id: 'people',
      label: 'People',
      content: <CompanyProfilePeople contacts={company.contacts} />,
    },
  ]

  if (
    !isEmpty(company.investments) ||
    !isEmpty(company.deals) ||
    !isEmpty(company.partners)
  ) {
    tabs.push({
      id: 'investments',
      label: 'Investments',
      content: <CompanyProfileInvestments company={company} />,
    })
  }

  if (!isEmpty(company.funds)) {
    tabs.push({
      id: 'funds',
      label: 'Funds',
      content: <CompanyProfileFunds funds={company.funds} />,
    })
  }

  if (!isEmpty(company.mandates)) {
    tabs.push({
      id: 'mandates',
      label: 'Mandates',
      content: <CompanyProfileMandates mandates={company.mandates} />,
    })
  }

  if (!isEmpty(company.media) || !isEmpty(company.attachments)) {
    tabs.push({
      id: 'media',
      label: 'Media',
      content: (
        <CompanyProfileMedia
          media={company.media}
          attachments={company.attachments}
          companyId={company.companyId}
        />
      ),
    })
  }

  if (!isEmpty(company.links)) {
    tabs.push({
      id: 'links',
      label: 'Links',
      content: <CompanyProfileLinks links={company.links} />,
    })
  }

  return tabs
}

export default function CompanyProfileBody({
  company,
  addTabToQueryString = false,
}) {
  const tabs = getTabs(company)
  const [searchParams, setSearchParams] = useSearchParams()
  return addTabToQueryString ? (
    <Profile.Body
      tabs={tabs}
      activeTab={searchParams.tab ?? tabs[0].id}
      onTabChange={(tab) => setSearchParams({ tab })}
    />
  ) : (
    <Profile.Body tabs={tabs} />
  )
}

CompanyProfileBody.propTypes = {
  company: PropTypes.shape({
    companyId: PropTypes.number.isRequired,
    companyDescription: PropTypes.string.isRequired,
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        contactId: PropTypes.number.isRequired,
        contactName: PropTypes.string.isRequired,
        imageUrl: PropTypes.string,
        jobTitle: PropTypes.string,
        canView: PropTypes.bool.isRequired,
      })
    ).isRequired,
    funds: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    mandates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    media: PropTypes.arrayOf(
      PropTypes.shape({
        companyMediaTypeId: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
    attachments: PropTypes.arrayOf(PropTypes.string),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
    investments: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        investmentDate: PropTypes.object.isRequired,
        securityType: PropTypes.string.isRequired,
        investmentStatus: PropTypes.string.isRequired,
      })
    ),
    deals: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        investmentDate: PropTypes.object.isRequired,
        securityType: PropTypes.string.isRequired,
        dealLeadStatus: PropTypes.string.isRequired,
      })
    ),
    partners: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        investorCategory: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  addTabToQueryString: PropTypes.bool,
}
