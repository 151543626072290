import * as React from 'react'
import PropTypes from 'prop-types'
import { Input, TextArea } from '@context365/forms'
import { Divider, message } from 'antd'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { getAllCountries } from '~/actions/geography'
import * as api from '~/api'
import ArrayField from '~/components/GenericForm/Fields/ArrayField'
import CheckBoxField from '~/components/GenericForm/Fields/CheckBoxField'
import DropDownField from '~/components/GenericForm/Fields/DropDownField'
import EducationField from '~/components/GenericForm/Fields/EducationField'
import HeaderField from '~/components/GenericForm/Fields/HeaderField'
import InputNumberField from '~/components/GenericForm/Fields/InputNumberField'
import ProfilePictureField from '~/components/GenericForm/Fields/ProfilePictureField'
import GenericForm from '~/components/GenericForm/GenericForm'

const validator = () =>
  Yup.object().shape({
    imageUrl: Yup.string().nullable(),
    firstName: Yup.string().required('Required').typeError('Required'),
    lastName: Yup.string().required('Required').typeError('Required'),
    jobTitle: Yup.string().required('Required').typeError('Required'),
    jobLevelId: Yup.number().required('Required').typeError('Required'),
    jobFunctionId: Yup.number().required('Required').typeError('Required'),
    workPhone: Yup.string().required('Required').typeError('Required'),
    workExtension: Yup.string().nullable(),
    mobilePhone: Yup.string().nullable(),
    bio: Yup.string().required('Required').typeError('Required'),
    address1: Yup.string().required('Required').typeError('Required'),
    address2: Yup.string().nullable(),
    address3: Yup.string().nullable(),
    countryId: Yup.number().required('Required').typeError('Required'),
    city: Yup.string().required('Required').typeError('Required'),
    stateProvince: Yup.string().nullable(),
    postalCode: Yup.string().nullable(),
    linkedIn: Yup.string().nullable(),
    twitter: Yup.string().nullable(),
    website: Yup.string().nullable(),
    youtube: Yup.string().nullable(),
    undergradEducation: Yup.array(
      Yup.object().shape({
        school: Yup.string().required('Required'),
        areaOfStudy: Yup.string().required('Required'),
        isGrad: Yup.bool(),
      })
    ),
    gradEducation: Yup.array(
      Yup.object().shape({
        school: Yup.string().required('Required'),
        areaOfStudy: Yup.string().required('Required'),
        isGrad: Yup.bool(),
      })
    ),
    certiificationIds: Yup.array(Yup.number()),
    finraExamIds: Yup.array(Yup.number()),
    minimumInvestmentSize: Yup.number()
      .nullable()
      .min(0, 'Must be a positive number')
      .test(
        'lessThanAverage',
        'Minimum investment size must be less than average',
        (val, testContext) =>
          isNil(testContext.parent.averageInvestmentSize) ||
          val <= testContext.parent.averageInvestmentSize
      ),
    averageInvestmentSize: Yup.number()
      .nullable()
      .min(0, 'Must be a positive number')
      .test(
        'lessThanAverage',
        'Average investment size must be greater than minimum',
        (val, testContext) =>
          isNil(testContext.parent.minimumInvestmentSize) ||
          val >= testContext.parent.minimumInvestmentSize
      ),
    industryIds: Yup.array(Yup.number()),
    geographyIds: Yup.array(Yup.number()),
    verticalIds: Yup.array(Yup.number()),
    vehicleIds: Yup.array(Yup.number()),
  })

const steps = () => [
  {
    title: 'User Profile',
    validator: validator(),
    questions: (values, questionOptions) => [
      {
        questions: [
          {
            name: 'imageUrl',
            title: 'Profile Picture',
            isFormField: true,
            component: ProfilePictureField,
            labelClassName: 'text-center type-body-semibold-md',
            extraProps: {
              help: [
                'Drag or click here to upload a picture',
                '(PNG or JPG / Max File Size 5mb)',
              ],
            },
          },
        ],
      },
      {
        cols: 5,
        questions: [
          {
            component: Input,
            name: 'firstName',
            title: 'First Name',
            required: true,
          },
          {
            component: Input,
            name: 'lastName',
            title: 'Last Name',
            required: true,
          },
          {
            component: Input,
            name: 'jobTitle',
            title: 'Job Title',
            required: true,
          },
          {
            name: 'jobLevelId',
            title: 'Job Level',
            isFormField: true,
            required: true,
            component: DropDownField,
            extraProps: {
              options: questionOptions.jobLevels,
            },
          },
          {
            name: 'jobFunctionId',
            title: 'Job Function',
            isFormField: true,
            required: true,
            component: DropDownField,
            extraProps: {
              options: questionOptions.jobFunctions,
            },
          },
          {
            component: Input,
            name: 'workPhone',
            title: 'Work Phone',
            required: true,
          },
          {
            component: Input,
            name: 'workExtension',
            title: 'Work Extension',
          },
          {
            component: Input,
            name: 'mobilePhone',
            title: 'Mobile Phone',
          },
        ],
      },
      {
        questions: [
          {
            name: 'bio',
            title: 'Bio',
            required: true,
            component: TextArea,
            extraProps: { rows: 5, placeholder: 'Type here' },
          },
        ],
      },
      {
        questions: [
          {
            component: HeaderField,
            extraProps: {
              title: `Address`,
            },
          },
        ],
      },
      {
        cols: 4,
        questions: [
          {
            component: Input,
            name: 'address1',
            title: 'Street Address',
            required: true,
          },
          {
            component: Input,
            name: 'address2',
            title: 'Address 2',
          },
          {
            component: Input,
            name: 'address3',
            title: 'Address 3',
          },
        ],
      },
      {
        cols: 4,
        questions: [
          {
            name: 'countryId',
            title: 'Country',
            required: true,
            component: DropDownField,
            extraProps: {
              showSearch: true,
              getOptionsMethod: getAllCountries,
              mappingMethod: (vals) =>
                map(vals, (x) => ({ label: x.name, value: x.countryId })),
            },
          },
          {
            component: Input,
            required: true,
            name: 'city',
            title: 'City',
          },
          {
            component: Input,
            name: 'stateProvince',
            title: 'State/Province',
          },
          {
            component: Input,
            name: 'zipcode',
            title: 'Zipcode',
          },
        ],
      },
      { questions: [{ component: Divider }] },
      {
        questions: [
          {
            component: HeaderField,
            extraProps: {
              title: `Social Media`,
            },
          },
        ],
      },
      {
        cols: 3,
        questions: [
          {
            component: Input,
            name: 'linkedIn',
            title: 'LinkedIn',
          },
          {
            component: Input,
            name: 'twitter',
            title: 'Twitter',
          },
          {
            component: Input,
            name: 'website',
            title: 'Website',
          },
          {
            component: Input,
            name: 'youtube',
            title: 'YouTube',
          },
        ],
      },
      { questions: [{ component: Divider }] },
      {
        questions: [
          { component: HeaderField, extraProps: { title: 'Education' } },
        ],
      },
      {
        questions: [
          {
            component: () => (
              <div className="type-body-semibold-sm">Undergrad</div>
            ),
          },
        ],
      },
      {
        questions: [
          {
            name: 'undergradEducation',
            component: ArrayField,
            extraProps: {
              allowEmpty: true,
              emptyAddText: '+ Add School',
              subComponent: EducationField,
              subProps: {},
              defaultObject: {
                contactProfileEducationId: null,
                school: '',
                areaOfStudy: '',
                isGraduate: false,
              },
            },
          },
        ],
      },
      {
        questions: [
          {
            component: () => (
              <div className="type-body-semibold-sm">Graduate</div>
            ),
          },
        ],
      },
      {
        questions: [
          {
            name: 'gradEducation',
            component: ArrayField,
            extraProps: {
              allowEmpty: true,
              emptyAddText: '+ Add School',
              subComponent: EducationField,
              subProps: {},
              defaultObject: {
                contactProfileEducationId: null,
                school: '',
                areaOfStudy: '',
                isGraduate: true,
              },
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'certificationIds',
            labelClassName: 'type-body-semibold-md',
            title: 'Professional Certifications',
            isFormField: true,
            component: CheckBoxField,
            extraProps: {
              options: questionOptions.certifications,
            },
          },
        ],
      },
      {
        cols: 4,
        questions: [
          {
            name: 'finraExamIds',
            title: 'FINRA (Select all that apply)',
            component: DropDownField,
            extraProps: {
              mode: 'multiple',
              options: questionOptions.finraExams,
              showSearch: true,
              allowClear: true,
            },
          },
        ],
      },
      { questions: [{ component: Divider }] },
      {
        questions: [
          {
            component: HeaderField,
            extraProps: {
              title: `Typical Investment Size`,
              subtitle: 'Leave blank if not applicable',
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'minimumInvestmentSize',
            title: 'Minimum',
            isFormField: true,
            component: InputNumberField,
            extraProps: {
              type: 'currency',
            },
          },
          {
            name: 'averageInvestmentSize',
            title: 'Average',
            isFormField: true,
            component: InputNumberField,
            extraProps: {
              type: 'currency',
            },
          },
        ],
      },
      { questions: [{ component: Divider }] },
      {
        questions: [
          {
            component: HeaderField,
            extraProps: {
              title: `Investment Interests`,
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'industryIds',
            title: 'Sector Interests',
            labelClassName: 'type-body-semibold-md',
            isFormField: true,
            component: CheckBoxField,
            extraProps: {
              options: questionOptions.industries,
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'geographyIds',
            title: 'Geography Interests',
            labelClassName: 'type-body-semibold-md',
            isFormField: true,
            component: CheckBoxField,
            extraProps: {
              options: questionOptions.geographies,
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'verticalIds',
            title: 'Vertical Interests',
            labelClassName: 'type-body-semibold-md',
            isFormField: true,
            component: CheckBoxField,
            extraProps: {
              options: questionOptions.verticals,
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'vehicleIds',
            title: 'Vehicle Interests',
            labelClassName: 'type-body-semibold-md',
            isFormField: true,
            component: CheckBoxField,
            extraProps: {
              options: questionOptions.investmentVehicles,
            },
          },
        ],
      },
    ],
  },
]

const getInitialValues = () => {
  return new Promise((resolve) => {
    api.contacts
      .getContactProfileAnswers()
      .then((data) => {
        data.undergradEducation = filter(data.education, ['isGraduate', false])
        data.gradEducation = filter(data.education, 'isGraduate')
        resolve(data)
      })
      .catch(() => {
        resolve(null)
      })
  })
}

const ContactSettings = ({ onFinish = () => {} }) => {
  const userRole = useSelector((state) => state.auth.role)
  const [options, setOptions] = React.useState(null)

  const defaultValues = {
    imageUrl: null,
    firstName: null,
    lastName: null,
    jobTitle: null,
    jobLevelId: null,
    jobFunctionId: null,
    workPhone: null,
    workExtension: null,
    mobilePhone: null,
    countryId: null,
    city: null,
    stateProvince: null,
    address1: null,
    address2: null,
    address3: null,
    postalCode: null,
    bio: null,
    linkedIn: null,
    twitter: null,
    website: null,
    youtube: null,
    education: [],
    gradEducation: [],
    certificationIds: [],
    finraExamIds: [],
    industryIds: [],
    geographyIds: [],
    verticalIds: [],
    vehicleIds: [],
    minimumInvestmentSize: null,
    averageInvestmentSize: null,
  }

  React.useEffect(() => {
    api.contacts.getContactProfileOptions().then((opts) => setOptions(opts))
  }, [])

  const handleSave = (vals, formFinished, doneSaving) => {
    vals.education = concat(vals.undergradEducation, vals.gradEducation)
    if (formFinished) {
      api.contacts
        .saveContactProfile(vals)
        .then(() => {
          message.success('Profile saved successfully.')
          onFinish()
        })
        .catch(() =>
          message.error(
            'An error occurred while attempting to save your profile.'
          )
        )
        .finally(() => doneSaving())
    } else {
      doneSaving()
    }
  }

  return (
    <GenericForm
      getInitialValues={getInitialValues}
      defaultValues={defaultValues}
      steps={steps(userRole)}
      questionOptions={options}
      onSave={handleSave}
    />
  )
}

ContactSettings.propTypes = { onFinish: PropTypes.func }

export default ContactSettings
