import PropTypes from 'prop-types'
import { DatePicker } from 'antd'
import isNil from 'lodash/isNil'
import moment from 'moment'

const DateField = ({ name, value, setFieldValue }) => {
  return (
    <div>
      <DatePicker
        onChange={(date, dateString) => setFieldValue(name, dateString)}
        defaultValue={isNil(value) ? undefined : moment(value)}
      />
    </div>
  )
}

DateField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  setFieldValue: PropTypes.func,
}

export default DateField
