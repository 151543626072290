import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'
import GenericForm from './GenericForm'
import InvestorStatusForm from './InvestorStatusForm'

const FamilyNetworkDealsForm = ({
  enableNext,
  step,
  onChange,
  currentQuestions,
  companyId,
  stepNumber,
  fromCfnSettings = false,
  showInterestOptions = true,
  contentChanged,
}) => {
  const isCFNIterestedInDeals = useSelector(
    (state) => state.auth.contact.isCFNInterestedInDeals
  )
  const isCFNAllocator = useSelector(
    (state) => state.auth.contact.isCFNAllocator
  )

  const getStatus = useCallback(
    (questions) => {
      let isInterestedInDeals = questions.find(
        (x) => x.shortName === 'IsInterestedInDeals'
      )
      if (isNil(isInterestedInDeals)) {
        const currentStep = questions.find(
          (x) => x.step === stepNumber
        )?.questions
        if (!isNil(currentStep)) {
          isInterestedInDeals = currentStep.find(
            (x) => x.shortName === 'IsInterestedInDeals'
          )
        }
      }
      return isInterestedInDeals?.answer
    },
    [stepNumber]
  )

  const [showInvestorStatus, setShowInvestorStatus] = useState(
    !showInterestOptions || isCFNIterestedInDeals || isCFNAllocator
  )
  const handleChange = useCallback(
    (formQuestions) => {
      if (!isNil(formQuestions)) {
        const status = getStatus(formQuestions)
        setShowInvestorStatus(status)
        if (!status) enableNext(true)
      }
      onChange(formQuestions)
    },
    [enableNext, getStatus, onChange]
  )

  useEffect(() => {
    if (fromCfnSettings && isNil(currentQuestions)) {
      step
        .renderAction(
          isCFNAllocator || !showInterestOptions
            ? 7
            : step.customRenderParameter
        )
        .then((response) => {
          onChange(response.data.result, false)
        })
    }
  }, [
    currentQuestions,
    fromCfnSettings,
    isCFNAllocator,
    onChange,
    showInterestOptions,
    step,
  ])
  return (
    <>
      {(!fromCfnSettings || currentQuestions) &&
        showInterestOptions &&
        !isCFNAllocator && (
          <GenericForm
            step={step}
            onChange={handleChange}
            stepNumber={step.stepNumber}
            enableNext={() => {}}
            maxWidth={step.customWidth ? step.customWidth : '364px'}
            currentQuestions={currentQuestions}
            companyId={companyId}
            renderAction={step.renderAction}
            contentChanged={contentChanged}
            fromSettings={fromCfnSettings}
            showInvestorStatus={showInvestorStatus}
          />
        )}
      {(!fromCfnSettings || currentQuestions) && showInvestorStatus && (
        <div className="cc-familyNetwork-investment-form">
          {showInterestOptions && !isCFNAllocator && (
            <div className="cc-heading6 cc-familyNetwork-deals-text">
              Awesome! Before you can view private deals, you must select your
              investor eligbility status:
            </div>
          )}
          <InvestorStatusForm
            step={{
              innerStep: 7,
              title: step.title,
              renderAction: step.renderAction,
              customRender: true,
              customRenderParameter: 7,
            }}
            onChange={onChange}
            stepNumber={step.stepNumber}
            enableNext={enableNext}
            maxWidth={step.customWidth ? step.customWidth : '364px'}
            currentQuestions={currentQuestions}
            companyId={companyId}
            customClass={true}
          />
        </div>
      )}
    </>
  )
}
FamilyNetworkDealsForm.propTypes = {
  enableNext: PropTypes.func.isRequired,
  step: PropTypes.shape({
    stepNumber: PropTypes.number,
    innerStep: PropTypes.number.isRequired,
    steps: PropTypes.array,
    shouldPost: PropTypes.bool,
    actionType: PropTypes.number,
    canGoBack: PropTypes.bool,
    customWidth: PropTypes.bool,
    renderAction: PropTypes.func.isRequired,
    customRender: PropTypes.bool,
    customRenderParameter: PropTypes.number,
    title: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  currentQuestions: PropTypes.array.isRequired,
  companyId: PropTypes.number.isRequired,
  stepNumber: PropTypes.number.isRequired,
  fromCfnSettings: PropTypes.bool,
  showInterestOptions: PropTypes.bool,
  contentChanged: PropTypes.bool,
}

export default FamilyNetworkDealsForm
