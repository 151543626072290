import PropTypes from 'prop-types'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import ContactAvatar from './ContactAvatar'
import ProfileViewerProvider from './ProfileViewerProvider'
import './ContactsCard.less'

const ContactsCard = ({ title, author, taggedContacts, className }) => (
  <div className={cx('content-contact-info', className)}>
    <div className="content-contact-info__title-card">
      <div className="content-contact-info__title">{title}</div>
      {author && (
        <ProfileViewerProvider
          companyCategoryId={author.companyCategoryId}
          companyContactId={author.companyContactId}
          contactId={author.contactId}
          companyId={author.companyId}
          fundId={author.fundId}
        >
          <ContactAvatar
            contactName={author.contactName}
            imageUrl={author.contactImageUrl}
            jobTitle={author.contactJobTitle}
          />
        </ProfileViewerProvider>
      )}
    </div>
    {!isEmpty(taggedContacts) && (
      <div className="content-contact-info__contacts-card">
        {map(taggedContacts, (contact) => (
          <ProfileViewerProvider
            key={contact.contactId}
            contactId={contact.contactId}
            companyCategoryId={contact.companyCategoryId}
            companyContactId={contact.companyContactId}
            companyId={contact.companyId}
            fundId={contact.fundId}
          >
            <ContactAvatar
              contactName={contact.contactName}
              imageUrl={contact.contactImageUrl}
              jobTitle={contact.contactJobTitle}
              showProfile
            />
          </ProfileViewerProvider>
        ))}
      </div>
    )}
  </div>
)

ContactsCard.propTypes = {
  title: PropTypes.string.isRequired,
  author: PropTypes.object,
  taggedContacts: PropTypes.array,
}

export default ContactsCard
