import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Drawer, Empty, Table } from 'antd'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import sum from 'lodash/sum'
import without from 'lodash/without'
import moment from 'moment'
import { transformColumns, transformPagination } from '~/utils/contextTable'
import ColumnsPreferences from '../ColumnsPreferences'
import FiltersView from '../FiltersView'
import Loading from '../Loading'
import TableHeader from '../TableHeader'
import './ContextTable.less'

export const CCTableLinkCell = 'CCTABLE_LINK_CELL'
export const CCTableContactLinkCell = 'CCTABLE_CONTACT_LINK_CELL'
export const CCTableTagGroupCell = 'CCTABLE_TAG_GROUP_CELL'
export const CCTableMatchmakingCell = 'CCTABLE_MATCHMAKING_CELL'
export const CCTableActionCell = 'CCTABLE_ACTION_CELL'
export const CCTableProfilePicsCell = 'CCTABLE_PROFILE_PICS_CELL'
export const CCTableNamedProfilePicsCell = 'CCTABLE_NAMED_PROFILE_PICS_CELL'
export const CCTableDateTimeCell = 'CCTABLE_DATETIME_CELL'
export const CCTableCurrencyCell = 'CCTABLE_CURRENCY_CELL'
export const CCTableYesNoCell = 'CCTABLE_YESNO_CELL'
export const CCTableDollarChangeCell = 'CCTABLE_DOLLAR_CHANGE_CELL'
export const CCTableLambdaCell = 'CCTABLE_LAMBDA_CELL'

const ContextTable = ({
  noDataText = 'No Data',
  scroll,
  pagination,
  tableTitle,
  columns,
  dataSource,
  dataMethod,
  fetchMethod,
  onTableChanged,
  refresh,
  refreshData = false,
  rowSelected,
  clickEvent,
  rowClassName,
  searchVisible,
  showTotal,
  showColumnsFilter,
  arrangedColumns,
  onColumnsRearranged = () => {},
  isSchedule = false,
  onRadChange,
  filters = [],
}) => {
  const [data, setData] = useState(null)
  const [tablePagination, setTablePagination] = useState(pagination)
  const [tableParameters, setTableParameters] = useState({
    pagination: {},
    filters: {},
    sorter: {},
    searchTerm: {},
  })
  const [rawFilters, setRawFilters] = useState([])
  const [loadData, setLoadData] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [internalRefresh, setInternalRefresh] = useState(false)
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const [columnsDrawerVisible, setColumnsDrawerVisible] = useState(false)
  // const [arrangedColumnsArray, setArrangedColumnsArray] = useState(
  //   arrangedColumns
  // );

  if (isNil(dataSource) && loadData) {
    dataMethod(
      tableParameters.pagination,
      tableParameters.filters,
      tableParameters.sorter,
      tableParameters.searchTerm
    ).then((response) => {
      if (!isNil(onTableChanged)) onTableChanged(response.data.result)
      setLoadData(false)
      setData(response.data.result.result)
      setTablePagination(response.data.result)
    })
  }

  if (!isNil(dataSource) && !isNil(fetchMethod) && loadData) {
    fetchMethod(
      tableParameters.pagination,
      tableParameters.filters,
      tableParameters.sorter,
      tableParameters.searchTerm
    )

    setLoadData(false)
    setSpinnerLoading(true)
  }

  useEffect(() => {}, [refresh])

  useEffect(() => {
    //setLoadData(true);
  }, [internalRefresh])

  useEffect(() => {
    setLoadData(true)
  }, [refreshData])

  const getColumnFilters = useCallback(() => {
    if (!isNil(filters) && filters.length > 0) {
      setFilterData(filters)
      return
    }
    const filteredColumns = columns.filter((x) => !isNil(x.filterMethod))
    if (!isNil(filteredColumns)) {
      filteredColumns.forEach((column) => {
        if (isNil(filterData[column.key])) {
          column.filterMethod().then((response) => {
            const filter = filterData
            if (!isNil(column.filterMappingMethod))
              filter[column.key] = response.data.result.map((x) =>
                column.filterMappingMethod(x)
              )
            else filter[column.key] = response.data.result
            setFilterData(filter)
            setInternalRefresh(moment())
          })
        }
      })
    }
  }, [columns, filterData, filters])

  useEffect(() => {
    if (!isNil(data) || !isNil(dataSource)) {
      getColumnFilters()
      setSpinnerLoading(false)
      // setLoadData(false);
    }
  }, [data, dataSource, getColumnFilters])

  const filteredColumns = columns.filter((x) => !isNil(x.filterMethod))
  if (!isNil(filteredColumns)) {
    filteredColumns.forEach((column) => {
      if (!isNil(filterData[column.key])) {
        column.filters = filterData[column.key]
      }
    })
  }

  const globallyFilteredColumns = columns.filter(
    (x) => !isNil(x.globallyFiltered) && x.globallyFiltered === true
  )
  if (!isNil(filters)) {
    globallyFilteredColumns.forEach((column) => {
      const filterRecord = filters.filter((x) => x.field === column.key)
      if (filterRecord.length > 0) {
        column.filters = filterRecord[0].values.map((x) => ({
          text: x,
          value: x,
        }))
        column.isFiltered = true
      }
    })
  }

  columns = transformColumns(columns, rowSelected)
  pagination = transformPagination(tablePagination)

  if (showTotal && pagination) {
    pagination.showTotal = (total, range) => {
      const [start, end] = range
      return (
        <div>
          {start}-{end} of <b>{total}</b> items
        </div>
      )
    }
    pagination.showTotal.displayName = 'Pagination'
  }

  const tableChanged = useCallback(
    (pagination, filters, sorter) => {
      const filterArray = {
        columns: [],
      }

      setRawFilters(filters)

      if (filters.length === 0) {
        columns.forEach((col) => {
          col.filteredValue = []
        })
      }

      for (const propertyName in filters) {
        const column = columns.find(
          (x) => x.dataIndex === propertyName || x.key === propertyName
        )
        if (isNil(column)) continue
        const { columnType } = column
        column.filteredValue = filters[propertyName]

        if (!isNil(filters[propertyName]) && filters[propertyName].length > 0) {
          filterArray.columns.push({
            column: propertyName,
            values: filters[propertyName],
            columnType,
          })
        }
      }

      pagination.total = null

      delete sorter.column

      setTableParameters({
        pagination,
        filters: filterArray,
        sorter,
        searchTerm: tableParameters.searchTerm,
      })
      setLoadData(true)
    },
    [columns, tableParameters]
  )

  const searchChanged = (searchTerm) => {
    setTableParameters({
      pagination: { pageSize: tableParameters.pagination.pageSize },
      filters: tableParameters.filters,
      sorter: tableParameters.sorter,
      searchTerm: {
        searchValue: searchTerm,
      },
    })
    setLoadData(true)
  }

  const onRemoveFilter = useCallback(
    (key, val) => {
      const newFilters = {
        ...rawFilters,
        [key]: without(rawFilters[key], val),
      }

      tableChanged(
        tableParameters.pagination,
        newFilters,
        tableParameters.sorter
      )
      setInternalRefresh(moment())
    },
    [tableParameters, rawFilters, tableChanged]
  )
  const onRemoveNumberFilters = useCallback(
    (key) => {
      const newFilters = {
        ...rawFilters,
        [key]: [],
      }

      tableChanged(
        tableParameters.pagination,
        newFilters,
        tableParameters.sorter
      )
      setInternalRefresh(moment())
    },
    [rawFilters, tableChanged, tableParameters]
  )

  const onClearAllFilters = useCallback(() => {
    tableChanged(tableParameters.pagination, [], tableParameters.sorter)
    setInternalRefresh(moment())
  }, [tableParameters, tableChanged])

  let processedColumns = columns
  let totWidth = isNil(scroll) ? 0 : scroll.x

  if (!isNil(arrangedColumns)) {
    processedColumns = columns.filter((x) => x.fixed === 'left')

    arrangedColumns.forEach((x) => {
      const col = columns.find((col) => col.title === x.title)
      col.checked = x.checked
      processedColumns.push(col)
    })

    totWidth = sum(
      processedColumns
        .filter((x) => x.checked === true)
        .map((x) => (isNil(x.width) ? 300 : x.width))
    )
    if (totWidth < 1000) totWidth = 1000
  } else {
    processedColumns.forEach((x) => {
      x.checked = true
    })
  }

  return (
    <>
      <TableHeader
        searchVisible={searchVisible}
        headerTitle={tableTitle}
        onSearch={searchChanged}
        showColumnsFilter={showColumnsFilter}
        onColumnsFilter={() => setColumnsDrawerVisible(true)}
        loading={
          (isNil(data) && isNil(dataSource)) ||
          (loadData && isNil(dataSource)) ||
          spinnerLoading === true
        }
        isSchedule={isSchedule}
        // timezoneShowing={timezoneShowing}
        // setTimezoneShowing={setTimezoneShowing}
        firstCol={isSchedule ? 16 : 8}
        secondCol={isSchedule ? 8 : 16}
        onRadChange={onRadChange}
      />
      {!isEmpty(rawFilters) &&
        !every(rawFilters, (f) => isNil(f) || isEmpty(f)) && (
          <div className="cc-table-filters">
            <span className="cc-tabletitle-text">Filters:</span>
            {map(
              rawFilters,
              (values, key) =>
                !isEmpty(values) && (
                  <span key={key} className="cc-small-font">
                    <FiltersView
                      filterKey={key}
                      filterTitle={
                        key === 'MEETING_STATUS'
                          ? 'Meeting status'
                          : key === 'MATCHMAKING_SCORE'
                          ? 'Matchmaking score'
                          : columns.filter((x) => x.key === key)[0].title
                      }
                      values={values}
                      onRemoveFilter={onRemoveFilter}
                      onRemoveMeetingStatusFilters={onRemoveFilter}
                      onRemoveNumberFilters={onRemoveNumberFilters}
                      isNumber={
                        columns.filter((x) => x.key === key)[0].columnType ===
                          'Double' ||
                        columns.filter((x) => x.key === key)[0].columnType ===
                          'Numeric'
                      }
                      isMeetingStatus={key === 'MEETING_STATUS'}
                      isDateTime={
                        columns.filter((x) => x.key === key)[0].columnType ===
                        'DateTime'
                      }
                      dateTimeFormat={
                        columns.filter((x) => x.key === key)[0].dateTimeFormat
                      }
                    />
                  </span>
                )
            )}
            <Button type="link" onClick={onClearAllFilters}>
              Clear All
            </Button>
          </div>
        )}
      {showColumnsFilter && (
        <Drawer
          visible={columnsDrawerVisible}
          placement="right"
          closable="true"
          onClose={() => {
            setColumnsDrawerVisible(false)
          }}
          title="Customize Columns"
          width="390"
          bodyStyle={{ paddingLeft: '8px', paddingRight: '8px' }}
        >
          <ColumnsPreferences
            columns={processedColumns}
            onColumnsRearranged={(newColumns) => {
              setColumnsDrawerVisible(false)
              setInternalRefresh(moment())
              onColumnsRearranged(newColumns)
            }}
            onCancel={() => setColumnsDrawerVisible(false)}
          />
        </Drawer>
      )}
      <Loading
        spinning={
          (isNil(data) && isNil(dataSource)) ||
          (loadData && isNil(dataSource)) ||
          spinnerLoading === true
        }
      >
        <Table
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={noDataText}
              />
            ),
          }}
          scroll={{ x: totWidth, y: isNil(scroll) ? 0 : scroll.y }}
          pagination={pagination}
          rowKey="id"
          columns={processedColumns.filter(
            (x) => x.checked === true || x.fixed === 'left'
          )}
          dataSource={isNil(data) ? dataSource : data}
          rowClassName={rowClassName}
          onChange={tableChanged}
          className="cc-context-table"
          onRow={(record) => {
            if (clickEvent) {
              return {
                onClick: () => {
                  clickEvent(record)
                },
              }
            }
          }}
        />
      </Loading>
    </>
  )
}
ContextTable.propTypes = {
  noDataText: PropTypes.string,
  scroll: PropTypes.object,
  pagination: PropTypes.object,
  tableTitle: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataSource: PropTypes.array,
  fetchMethod: PropTypes.func,
  dataMethod: PropTypes.func,
  onTableChanged: PropTypes.func,
  refresh: PropTypes.bool,
  refreshData: PropTypes.bool,
  rowSelected: PropTypes.func,
  clickEvent: PropTypes.func,
  filters: PropTypes.array,
  rowClassName: PropTypes.string,
  searchVisible: PropTypes.bool,
  showTotal: PropTypes.bool,
  showColumnsFilter: PropTypes.bool,
  arrangedColumns: PropTypes.any,
  onColumnsRearranged: PropTypes.func,
  isSchedule: PropTypes.bool,
  onRadChange: PropTypes.func,
}

export default ContextTable
