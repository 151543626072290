import { api as http } from '~/api/services'

export const getServiceProvidersList = (
  pagination,
  filters,
  sorter,
  searchTerm
) =>
  http.post('/serviceproviders', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getServiceProviderCategories = () =>
  http.get('/serviceproviders/categories')

export const getServiceProviderProfile = (companyId) =>
  http.get(`serviceproviders/${companyId}`)

export const getDiscoverServiceProviderProfile = (companyId) =>
  http.get(`serviceproviders/profile/${companyId}`)
