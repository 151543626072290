import * as React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  UNSAFE_RouteContext,
  useLocation,
  useNavigate,
} from 'react-router-dom-v5-compat'

export const CONDITIONAL_STEPS = [
  { title: 'Mandate Overview', stepNumber: 1 },
  {
    title: 'Mandate Details - Asset Management',
    stepNumber: 2,
    path: 'details',
    alertMessage:
      'Marking a field as required will prevent funds from applying to your mandate that do not meet your specific criteria. ' +
      'By leaving these fields unchecked, you are indicating that the field is a preference and not a hard requirement.',
  },
  { title: 'Plan Overview', stepNumber: 3, path: 'plan' },
  { title: 'Documentation', stepNumber: 4, path: 'documentation' },
  { title: 'Consultants', stepNumber: 5, path: 'consultants' },
  { title: 'Updates', stepNumber: 6, path: 'updates' },
  { title: 'Notifications', stepNumber: 7, path: 'notifications' },
]

export const QUESTIONNAIRE_STEPS = {
  Common: {
    title: 'Common Questions',
    stepNumber: 8,
    path: 'common-questions',
  },
  Custom: {
    title: 'Custom Questions',
    stepNumber: 9,
    path: 'custom-questions',
  },
}

export const STATIC_STEPS = [
  QUESTIONNAIRE_STEPS.Common,
  QUESTIONNAIRE_STEPS.Custom,
]

export function useWizardSteps(stepNumbers) {
  const { mandateQuestionnaire } = useFlags()

  const getSteps = React.useCallback(
    (stepNumbers) => {
      return CONDITIONAL_STEPS.filter((step) =>
        stepNumbers.includes(step.stepNumber)
      ).concat(mandateQuestionnaire ? STATIC_STEPS : [])
    },
    [mandateQuestionnaire]
  )

  return { steps: getSteps(stepNumbers), getSteps }
}

export function useStepNavigation(steps) {
  const navigate = useNavigate()
  const location = useLocation()
  const currentMatch = useCurrentMatch()
  const currentStepIndex = useCurrentStepIndex(steps)

  const goToStep = (stepIndex, latestSteps = steps) => {
    const step = latestSteps[stepIndex]
    if (!step) {
      return
    }

    const path = step.path
      ? `${currentMatch.pathnameBase}/${step.path}`
      : currentMatch.pathnameBase
    navigate({ ...location, pathname: path })
  }

  const goToNextStep = () => goToStep(currentStepIndex + 1)
  const goToPreviousStep = () => goToStep(currentStepIndex - 1)

  return { goToStep, goToNextStep, goToPreviousStep }
}

export function useBasePath() {
  const currentMatch = useCurrentMatch()
  return currentMatch.pathnameBase
}

export function useCurrentStepIndex(steps) {
  const currentMatch = useCurrentMatch()
  const currentStepPath = currentMatch.params['*']
  if (!currentStepPath) {
    return 0
  }
  return steps.findIndex((step) => step.path === currentStepPath)
}

function getPathContributingMatches(matches) {
  return matches.filter(
    (match, index) =>
      index === 0 ||
      (!match.route.index &&
        match.pathnameBase !== matches[index - 1].pathnameBase)
  )
}

function useCurrentMatch() {
  const { matches } = React.useContext(UNSAFE_RouteContext)
  const { pathname } = useLocation()

  const pathContributingMatches = getPathContributingMatches(matches)

  return pathname.includes('new')
    ? pathContributingMatches.find(
        (match) => !match.params['*'].includes('new')
      )
    : pathContributingMatches.find(
        (match) => 'mandateId' in match.params && '*' in match.params
      )
}
