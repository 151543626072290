const MeetingRequestProposedTimes = () => (
  <div className="mt-8">
    <div className="type-subtitle-sm">Propose Times</div>
    <div className="type-body-regular-xs mt-2">
      You&apos;ll be able to discuss meeting times once the request is accepted.
    </div>
  </div>
)

export default MeetingRequestProposedTimes
