import PropTypes from 'prop-types'
import { DetailedAvatar } from '@context365/avatar'
import { Button } from '@context365/button'
import { Add } from '@context365/icons'
import { Empty } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import useVisibility from '~/hooks/useVisibility'
import { getUserCompanyContactId } from '~/selectors/auth'
import { getInitials } from '~/utils'
import InviteForm from './InviteForm'
import PopConfirm from './PopConfirm'

export default function ListSharing({
  list,
  onRevokeSharingAccess,
  onShareList,
}) {
  const companyContactId = useSelector(getUserCompanyContactId)
  const invitationForm = useVisibility()
  const { name: listName, discoverListId, usersWithAccess } = list
  const sharedWith = usersWithAccess.filter(
    (user) => user.companyContactId !== companyContactId
  )

  return (
    <>
      <div className="flex justify-between">
        <div className="type-subtitle-lg text-header">{listName}</div>
        {!invitationForm.visible && (
          <Button
            variant="filled"
            status="secondary"
            iconLeft={<Add />}
            onClick={invitationForm.show}
          >
            Invite
          </Button>
        )}
      </div>
      {invitationForm.visible && (
        <InviteForm
          onSendInvitation={(contact) => onShareList(discoverListId, contact)}
        />
      )}
      <div className="mt-5">
        <div className="type-body-semibold-md text-header py-1 border-b">
          Shared With
        </div>
        {isEmpty(sharedWith) && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="You haven't shared this list with anyone yet."
          >
            Click the &ldquo;Invite&rdquo; button above to share it.
          </Empty>
        )}
        <ul className="list-none p-0">
          {sharedWith.map((user) => (
            <li
              key={user.companyContactId}
              className="py-2 flex items-center gap-2"
            >
              <DetailedAvatar
                className="flex-1"
                initials={getInitials(user.name)}
                profileImageUrl={user.imageUrl}
                name={user.name}
                company={user.companyName}
              />
              <span className="flex-1 type-body-semibold-sm">{user.email}</span>
              <span>
                <PopConfirm
                  message={`Are you sure you want to remove ${user.name}'s access to the list "${listName}"?`}
                  onConfirm={() =>
                    onRevokeSharingAccess(discoverListId, user.companyContactId)
                  }
                >
                  <Button status="error">Remove Access</Button>
                </PopConfirm>
              </span>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

ListSharing.propTypes = {
  list: PropTypes.shape({
    discoverListId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    usersWithAccess: PropTypes.arrayOf(
      PropTypes.shape({
        companyContactId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  onRevokeSharingAccess: PropTypes.func.isRequired,
  onShareList: PropTypes.func.isRequired,
}
