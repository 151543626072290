import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { useDiscoverLists } from '~/hooks/discoverLists'
import DeleteListForm from './DeleteListForm'

export default function DeleteListModal({ visible, onClose }) {
  const { deleteDiscoverList } = useDiscoverLists()

  function deleteList(discoverListId) {
    return deleteDiscoverList(discoverListId)
  }

  return (
    visible && (
      <Modal
        visible
        footer={null}
        width="440px"
        bodyStyle={{ padding: 0 }}
        onCancel={onClose}
      >
        <DeleteListForm onDelete={deleteList} onDismiss={onClose} />
      </Modal>
    )
  )
}

DeleteListModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
