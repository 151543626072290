import PropTypes from 'prop-types'
import { UserOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import trim from 'lodash/trim'
import values from 'lodash/values'
import { addLineBreaks } from '~/utils/helpers'
import './ContactShortProfile.less'

const SIZES = {
  tiny: 16,
  xs: 24,
  small: 32,
  default: 56,
  large: 72,
  xl: 96,
}

const ContactShortProfile = ({
  firstName = null,
  lastName = null,
  contactName = null,
  jobTitle = null,
  bio = null,
  imageUrl = null,
  size = SIZES.default,
  className = null,
  showAvatar = true,
}) => {
  let name = trim(contactName)
  if (!isNil(firstName) || !isNil(lastName)) {
    name = trim(`${firstName} ${lastName}`)
  }

  if (isNil(name) || isEmpty(name)) {
    name = '-'
  }

  const sizeAsNumber = get(SIZES, size, SIZES.default)

  const classNames = ['ContactShortProfile']
  if (!isNil(className) && !isEmpty(className)) {
    classNames.push(className)
  }

  if (size !== SIZES.default) {
    classNames.push(`ContactShortProfile-${size}`)
  }

  return (
    <>
      <div className={join(classNames, ' ')}>
        {showAvatar && (
          <div className="ContactShortProfile-picture">
            <Avatar
              size={sizeAsNumber}
              icon={<UserOutlined />}
              src={imageUrl}
            />
          </div>
        )}
        <div className="ContactShortProfile-info">
          <h1 className="ContactShortProfile-info-name">{name}</h1>
          <h2 className="ContactShortProfile-info-position">
            {isNil(jobTitle) ? '-' : jobTitle}
          </h2>
        </div>
      </div>
      {!(isNil(bio) || isEmpty(bio)) && (
        <div className="ContactShortProfile-bio">{addLineBreaks(bio)}</div>
      )}
    </>
  )
}

ContactShortProfile.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  contactName: PropTypes.string,
  jobTitle: PropTypes.string,
  imageUrl: PropTypes.string,
  bio: PropTypes.string,
  size: PropTypes.oneOf(values(SIZES)),
  className: PropTypes.string,
  showAvatar: PropTypes.bool,
}

export default ContactShortProfile
