export const SIGNIN_USER = 'SIGNIN_USER'
export const SIGNOUT_USER = 'SIGNOUT_USER'
export const SWITCH_COMPANY = 'SWITCH_COMPANY'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const UPDATE_ONBOARDED_USER = 'UPDATE_ONBOARDED_USER'
export const UPDATE_AGREEMENT_USER = 'UPDATE_AGREEMENT_USER'
export const UPDATE_IS_CFN_ALLOCATOR = 'UPDATE_IS_CFN_ALLOCATOR'
export const UPDATE_IS_CFN_INTERESTED_IN_DEALS =
  'UPDATE_IS_CFN_INTERESTED_IN_DEALS'
export const UPDATE_IS_CFN_NOT_INTERESTED_IN_DEALS =
  'UPDATE_IS_CFN_NOT_INTERESTED_IN_DEALS'
export const UPDATE_USER = 'UPDATE_USER'
