import * as React from 'react'
import { useTracking as useTrackingImpl } from 'react-tracking'

export default function useTracking(data, options) {
  const { trackEvent: trackEventBase, ...rest } = useTrackingImpl(data, options)

  return {
    trackEvent: React.useMemo(
      () => decorateWithCommonEvents(trackEventBase, rest.getTrackingData),
      [trackEventBase, rest.getTrackingData]
    ),
    ...rest,
  }
}

const commonEvents = ['click', 'change']

function decorateWithCommonEvents(trackEventBase, getTrackingData) {
  function trackEvent(eventData) {
    if (
      process.env.NODE_ENV !== 'production' &&
      !(eventData?.eventName || getTrackingData()?.eventName)
    ) {
      // eslint-disable-next-line no-console -- development only
      console.error('trackEvent called without an event name')
    }
    trackEventBase(eventData)
  }

  commonEvents.forEach((eventName) => {
    trackEvent[eventName] = (data) => trackEvent({ eventName, ...data })
  })

  return trackEvent
}
