import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DatePicker, Modal, Radio, Select, message } from 'antd'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getFundsByCompany } from '~/actions/fundList'
import { api as http } from '~/api/services'
import { getUserId } from '~/selectors/auth'
import ParticipantsCheckbox from '../ParticipantsCheckbox'
import './RequestOnlineMeeting.less'

//import TimeSlots from '../TimeSlots/TimeSlots';

const { Option } = Select

const RequestOnlineMeeting = ({
  visible,
  handleOk,
  handleCancel,
  isFundManager,
  company,
  companyTo,
  companyToName,
  onTimeChanged,
  onParticipantsChanged,
  onToParticipantsChanged,
  onFundChanged,
}) => {
  const userId = useSelector((state) => getUserId(state))
  const [participants, setParticipants] = useState([userId])
  const [toParticipants, setToParticipants] = useState([])

  const [duration, setDuration] = useState(-1)
  const [date, setDate] = useState('')
  const [availableDates, setAvailableDates] = useState([])
  const [selectedHour, setSelectedHour] = useState(null)
  const [funds, setFunds] = useState([])

  if (funds.length === 0 && isFundManager) {
    getFundsByCompany().then((response) => {
      setFunds(response.data.result)
    })
  }

  useEffect(() => {
    if ((duration !== 0 && duration !== 1) || date === '' || date === null) {
      return
    }

    let url = `meetings/online/availabilities?date=${date.format(
      'YYYY-MM-DD'
    )}&duration=${duration}&`

    participants.forEach((part) => {
      url = `${url}contactIDs=${part}&`
    })

    toParticipants.forEach((part) => {
      url = `${url}contactIDs=${part}&`
    })

    url = url.substring(0, url.length - 1)

    http
      .get(url)
      .then((response) => {
        setAvailableDates(response.data.result)
      })
      .catch((err) => {
        console.log(err)
        message.error('Error occurred')
      })
  }, [duration, participants, date, toParticipants])

  const getSelect = () => (
    <Select
      style={{ display: 'block', backgroundColor: '#f7f8fa' }}
      onChange={(val) => {
        const selectedValue = availableDates.find((x) => x.startTime === val)
        setSelectedHour(selectedValue.startTime)
        onTimeChanged('hour', selectedValue)
      }}
      placeholder="Choose Time"
    >
      {availableDates.map((dt) => (
        <Option value={dt.startTime} key={dt.startTime}>
          {' '}
          {moment.utc(dt.startTime).local().format('HH:mm')} -{' '}
          {moment.utc(dt.endTime).local().format('HH:mm')}
        </Option>
      ))}
    </Select>
  )

  const handleParticipantChecked = (e) => {
    let tmpparticipants
    if (e.target.checked) {
      tmpparticipants = [...participants, e.target.value]
      setParticipants(tmpparticipants)
    } else {
      tmpparticipants = participants.filter((p) => p !== e.target.value)
      setParticipants(tmpparticipants)
    }

    onParticipantsChanged(tmpparticipants)
  }

  const handleToParticipantChecked = (e) => {
    let tmptoParticipants
    if (e.target.checked) {
      tmptoParticipants = [...toParticipants, e.target.value]
      setToParticipants(tmptoParticipants)
    } else {
      tmptoParticipants = toParticipants.filter((p) => p !== e.target.value)
      setToParticipants(tmptoParticipants)
    }

    onToParticipantsChanged(tmptoParticipants)
  }

  return (
    <Modal
      visible={visible}
      width="628px"
      title="Request a Meeting"
      onOk={() => handleOk(selectedHour)}
      onCancel={() => {
        setDuration(-1)
        setToParticipants([])
        setSelectedHour(null)
        handleCancel()
      }}
      okText="Send Meeting Request"
      bodyStyle={{ padding: 0 }}
    >
      <div style={{ padding: '13px 16px 32px 32px' }}>
        <div>
          <div className="cc-heading4">{companyToName}</div>
          <div className="cc-check-participants-wrapper">
            {companyTo.contacts &&
              companyTo.contacts.map((contact) => (
                <ParticipantsCheckbox
                  key={contact.contactId}
                  {...contact}
                  participants={toParticipants}
                  checked={handleToParticipantChecked}
                />
              ))}
          </div>
        </div>
        <div>
          <div style={{ marginTop: '16px' }} className="cc-heading4 ">
            Your Colleagues
          </div>
          <div>
            <div className="cc-check-participants-wrapper">
              {company.contacts &&
                company.contacts.map((contact) => (
                  <ParticipantsCheckbox
                    participants={participants}
                    checked={handleParticipantChecked}
                    key={contact.contactId}
                    {...contact}
                  />
                ))}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '280px' }}>
            <div style={{ marginTop: '16px' }} className="cc-heading4">
              Select date and time
            </div>
            <div style={{ display: 'flex', width: '70%', marginTop: '16px' }}>
              <DatePicker
                className="cc-background-input"
                size="large"
                width="100%"
                onChange={(value) => {
                  setDate(value)
                  onTimeChanged('date', moment.utc(value).local())
                }}
              />
            </div>
          </div>
          <div style={{ marginLeft: '40px' }}>
            <div style={{ marginTop: '16px' }} className="cc-heading4">
              Meeting Length
            </div>
            <div style={{ display: 'flex', width: '70%', marginTop: '16px' }}>
              <Radio.Group
                onChange={(e) => setDuration(e.target.value)}
                value={duration}
                style={{ display: 'flex' }}
              >
                <Radio value={0}>30 minutes</Radio>
                <Radio value={1}>1 hour</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '20px', lineHeigth: '1.5' }}>
          {availableDates.length > 0 && (
            <>
              <div style={{ marginTop: '16px' }} className="cc-heading4">
                Choose Time
              </div>
              {getSelect()}
            </>
          )}
        </div>
        {isFundManager && (
          <div>
            <div style={{ marginTop: '16px' }} className="cc-heading4">
              Fund you want to present
            </div>
            <div style={{ display: 'flex', width: '70%' }}>
              <Select
                defaultActiveFirstOption={false}
                className="cc-background-input"
                size="large"
                style={{ width: '100%', marginTop: '16px' }}
                placeholder="Select fund"
                onChange={onFundChanged}
              >
                {funds &&
                  funds.map((fund) => (
                    <Option key={fund.fundId} value={fund.fundId}>
                      {fund.name}
                    </Option>
                  ))}
              </Select>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}
RequestOnlineMeeting.defaultProps = {
  isFundManager: false,
  funds: [],
}
RequestOnlineMeeting.propTypes = {
  funds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  companyToName: PropTypes.string.isRequired,
  companyIdTo: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  isFundManager: PropTypes.bool,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  company: PropTypes.object,
  companyTo: PropTypes.object,
  onTimeChanged: PropTypes.func,
  onParticipantsChanged: PropTypes.func,
  onToParticipantsChanged: PropTypes.func,
  onFundChanged: PropTypes.func,
}

export default RequestOnlineMeeting
