import PropTypes from 'prop-types'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert } from 'antd'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import SimpleBar from 'simplebar-react'
import AllocatorToManagerRatingForm from './AllocatorToManagerRatingForm'
import ManagerToAllocatorRatingForm from './ManagerToAllocatorRatingForm'
import './MeetingRatings.less'

const findSession = ({ meetingDateTime }) => {
  const meetingHour = parseInt(
    meetingDateTime.substr(meetingDateTime.indexOf('T') + 1, 2),
    10
  )
  return `${meetingHour > 12 ? 'Evening' : 'Morning'} Session`
}

const MeetingRatings = ({
  meetings,
  indications,
  type,
  onChange,
  onSave,
  isSaving,
}) => {
  const Component =
    type === 'ManagerToAllocator'
      ? ManagerToAllocatorRatingForm
      : AllocatorToManagerRatingForm

  const icon = <FontAwesomeIcon icon={faInfoCircle} />

  return (
    <>
      <Alert
        className="MeetingRatings-notice"
        message="The other party will not see your meeting ratings."
        description="These are used to better match you with meetings in the future."
        type="info"
        showIcon
        icon={icon}
        closable
      />
      <SimpleBar className="MeetingRatings">
        {map(
          groupBy(meetings, findSession),
          (sessionMeetings, sessionTitle) => (
            <div className="MeetingRatings-session" key={sessionTitle}>
              <h4 className="MeetingRatings-session-title">{sessionTitle}</h4>
              {map(sessionMeetings, (meeting) => (
                <Component
                  key={meeting.meetingId}
                  meeting={meeting}
                  indications={indications}
                  onSave={onSave}
                  onChange={onChange}
                  isSaving={isSaving}
                />
              ))}
            </div>
          )
        )}
      </SimpleBar>
    </>
  )
}

MeetingRatings.propTypes = {
  meetings: PropTypes.arrayOf(
    PropTypes.shape({
      meetingId: PropTypes.number.isRequired,
      fundId: PropTypes.number,
      eventId: PropTypes.number,
      companyId: PropTypes.number,
      companyName: PropTypes.string,
      categoryId: PropTypes.number,
      categoryName: PropTypes.string,
      toCompanyId: PropTypes.number.isRequired,
      toCompanyName: PropTypes.string.isRequired,
      toCategoryId: PropTypes.number.isRequired,
      toCategoryName: PropTypes.string.isRequired,
      meetingDateTime: PropTypes.string.isRequired,
      meetingTimeZone: PropTypes.string,
      meetingRatingId: PropTypes.number,
    }).isRequired
  ).isRequired,
  type: PropTypes.oneOf(['ManagerToAllocator', 'AllocatorToManager'])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  indications: PropTypes.arrayOf(
    PropTypes.shape({
      ratingIndicationId: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  isSaving: PropTypes.number.isRequired,
}

export default MeetingRatings
