import PropTypes from 'prop-types'
import { Button, Upload } from 'antd'
import { join, last, split } from 'lodash'
import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'
import { BASE_URL } from '~/config'
import { getFileExtension } from '~/utils/helpers'
import useFormAnswers from '~/utils/hooks/useFormAnswers'
import '../Fields.less'

const InputField = ({ question }) => {
  const { answer, handleTextAnswerChange } = useFormAnswers(question)
  const accessToken = useSelector((state) => state.auth.accessToken)

  const acceptedExtensions = question.attributes?.acceptedExtensions || ['.pdf']

  const isCorrectFileExtension = (file) => {
    const extension = getFileExtension(file.name)
    return acceptedExtensions.includes(`.${extension.toLowerCase()}`)
  }

  const handleChange = (info) => {
    const { status, response } = info.file
    if (status === 'removed') {
      handleTextAnswerChange('')
      return
    }

    const isIncludedInAccept = isCorrectFileExtension(info.file)

    if (!isIncludedInAccept) {
      info.file.status = 'error'
      info.file.response = 'Incorrect file type'
      return
    }

    if (status === 'done' && !isNil(response)) {
      const { url } = response.result
      handleTextAnswerChange(encodeURI(url))
    }
  }
  const url = answer.responseText && new URL(answer.responseText)

  const defaultFileList = url
    ? {
        defaultFileList: [
          {
            url: decodeURI(url.href),
            name: last(split(decodeURI(url.pathname), '/')),
          },
        ],
      }
    : {}

  return (
    <Upload
      accept={join(acceptedExtensions, ',')}
      action={`${BASE_URL}/uploads`}
      headers={{ Authorization: `Bearer ${accessToken}` }}
      {...defaultFileList}
      onChange={handleChange}
    >
      <Button>Browse...</Button>
    </Upload>
  )
}

InputField.propTypes = {
  question: PropTypes.shape({
    name: PropTypes.string,
    attributes: PropTypes.object,
  }),
}

export default InputField
