import { api } from './services'

export const getSummitFiltersData = (category) => {
  const filterCategory =
    category === 'service-providers' ? 'serviceProviders' : category
  return api.get(`/summit/${filterCategory}/filters`, { apiVersion: 2 })
}

export const getDiscoverFiltersData = (category) => {
  return api.get(`/discover/${category}/filters`, { apiVersion: 2 })
}
