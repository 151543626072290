import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import { Button, IconButton } from '@context365/button'
import {
  InfoOutlined,
  Launch,
  Logout,
  MoreVert,
  PersonAddOutlined,
  SearchOutlined,
} from '@context365/icons'
import { Menu } from '@context365/menu'
import { MEETING_TYPE } from '~/constants/meetingTypeIds'
import ProfilePictureGroup from '../../ProfilePictureGroup'
import { ContactShape } from '../MessagePropTypes'
import './ChatHeader.less'

const ChatHeader = ({
  conversationType,
  title,
  contacts,
  onAddMorePeopleClicked,
  onLeaveConversationClicked,
  onTitleClicked,
  onInfoClicked,
  onSearchClicked,
  areFeaturesDisabled = false,
  meeting,
}) => {
  const conversationTypeText =
    conversationType === 'directmessage'
      ? 'Direct Message'
      : conversationType === 'Online'
      ? 'Online Meeting'
      : conversationType

  return (
    <div className="chat-header flex justify-between items-center px-4">
      <div className="flex space-x-4  items-center">
        <div className="chat-header-name cc-heading5">
          {onTitleClicked ? (
            <a onClick={onTitleClicked}>{title}</a>
          ) : (
            <div className="type-body-semibold-md">{title}</div>
          )}
        </div>
        <div>
          <Badge>{conversationTypeText}</Badge>
        </div>
        {meeting && (
          <Button
            variant="link"
            as="a"
            iconLeft={<Launch />}
            href={`/meeting/${
              meeting.meetingTypeId === MEETING_TYPE.SUMMIT
                ? 'summit'
                : 'online'
            }/${meeting.meetingId}`}
          >
            Meeting Details
          </Button>
        )}
      </div>

      <div className="flex space-x-4">
        {!areFeaturesDisabled && (
          <IconButton
            variant="link"
            status="secondary"
            icon={<SearchOutlined />}
            onClick={onSearchClicked}
          />
        )}
        <IconButton
          variant="link"
          status="secondary"
          icon={<InfoOutlined />}
          onClick={onInfoClicked}
        />

        <ProfilePictureGroup
          profiles={contacts.map((c) => ({
            ...c,
            name: c.contactName,
          }))}
          maxImages={10}
          showImageOnPopover
        />
        {!areFeaturesDisabled && (
          <Menu
            className="type-body-regular-xs"
            trigger={
              <IconButton
                icon={<MoreVert />}
                status="secondary"
                variant="link"
                label="Actions"
              />
            }
          >
            <Menu.Item onClick={onAddMorePeopleClicked}>
              <PersonAddOutlined className="mr-2 text-grey-400" />
              Add more people
            </Menu.Item>
            <Menu.Item onClick={onLeaveConversationClicked}>
              <Logout className="mr-2 text-red-100" />
              Leave the conversation
            </Menu.Item>
          </Menu>
        )}
      </div>
    </div>
  )
}

ChatHeader.propTypes = {
  conversationType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(ContactShape).isRequired,
  onAddMorePeopleClicked: PropTypes.func.isRequired,
  onLeaveConversationClicked: PropTypes.func.isRequired,
  onTitleClicked: PropTypes.func,
  onInfoClicked: PropTypes.func.isRequired,
  onSearchClicked: PropTypes.func.isRequired,
  areFeaturesDisabled: PropTypes.bool,
  meeting: PropTypes.object,
}

export default ChatHeader
