import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Menu } from '@context365/menu'
import { Tooltip } from '@context365/popovers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { shorten } from '~/utils/helpers'
import './ActionButton.less'

const ActionButton = ({ trigger, menu, record }) => {
  const MAX_ITEM_NAME_LENGTH = 38

  return (
    <Menu trigger={trigger || <Button variant="link">{'•••'}</Button>}>
      {map(menu, (item, index) => [
        item.hasSubMenu && item.hasSubMenu == true ? (
          <Menu.Submenu
            key={index}
            disabled={item.disabled}
            title={item.text.length > MAX_ITEM_NAME_LENGTH ? item.text : ''}
            label={shorten(item.text, MAX_ITEM_NAME_LENGTH)}
          >
            {map(item.items, (subItem, subItemIndex) => [
              <Menu.Item
                key={subItemIndex}
                title={subItem.name}
                onClick={(e) => {
                  subItem.clickHandle()
                  e.domEvent.stopPropagation()
                }}
              >
                <span className="mr-2">
                  <FontAwesomeIcon icon={item.icon} color={item.iconColor} />
                </span>
                <span
                  title={
                    subItem.name.length > MAX_ITEM_NAME_LENGTH
                      ? subItem.name
                      : ''
                  }
                >
                  {shorten(subItem.name, MAX_ITEM_NAME_LENGTH)}
                </span>
              </Menu.Item>,
            ])}
          </Menu.Submenu>
        ) : (
          <Tooltip
            key={index}
            text={item.disabledReason}
            className={cx(
              (!item.disabled || !item.disabledReason) && 'invisible'
            )}
          >
            <div>
              <Menu.Item
                onClick={
                  isNil(item.clickHandle) || item.disabled
                    ? null
                    : item.clickHandle.bind(null, record)
                }
                disabled={item.disabled}
                style={{ borderTop: 'solid 1px #e0e0e0' }}
              >
                <>
                  <span className="mr-2">
                    <FontAwesomeIcon icon={item.icon} color={item.iconColor} />
                  </span>
                  <span
                    title={
                      item.text.length > MAX_ITEM_NAME_LENGTH ? item.text : ''
                    }
                  >
                    {shorten(item.text, MAX_ITEM_NAME_LENGTH)}
                  </span>
                </>
              </Menu.Item>
            </div>
          </Tooltip>
        ),
      ])}
    </Menu>
  )
}
ActionButton.propTypes = {
  trigger: PropTypes.element,
  menu: PropTypes.array,
  record: PropTypes.object,
}
export default ActionButton
