const NewFlag = () => (
  <svg viewBox="0 0 600 200">
    <path
      d="m0,0l600,0l-100,100l100,100l-600,0l0,-200z"
      stroke="#59CFD9"
      fill="#59CFD9"
    />
    <text
      x="25%"
      y="50%"
      style={{ fontSize: 75 }}
      className="type-subtitle-sm"
      dominantBaseline="middle"
      textAnchor="middle"
    >
      NEW
    </text>
  </svg>
)

export default NewFlag
