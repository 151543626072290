import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { InfoOutlined, Launch, VerifiedOutlined } from '@context365/icons'
import { Col, Divider, Image, Row } from 'antd'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import map from 'lodash/map'
import moment from 'moment'
import numeral from 'numeral'
import defaultImage from '~/assets/no_image_available.png'
import { addLineBreaks, getClickableLink } from '~/utils/helpers'
import './ProfileOverviewTab.less'

const percentageValues = [
  'Target IRR',
  'Interest Rate',
  'Dividend Return',
  'Target Return',
]
const currency = [
  'Minimum Investment Size',
  'Average Investment Size',
  'Portfolio Size',
  'Mandate Size',
  'Offer Size',
  'Current Raised Investment',
  'Fund AUM Minimum',
  'Fund AUM Maximum',
  'Firm AUM Minimum',
  'Firm AUM Maximum',
  'Plan Assets',
  'Mandate Size Minimum',
  'Mandate Size Maximum',
  'Current Assets',
]
const titleAndValue = (label, value, className) => {
  if (value == null) {
    return null
  }

  if (currency.includes(label)) {
    value = numeral(value).format('($0.[0]a)').toUpperCase()
  } else if (isArray(value) && value.length > 1) {
    value = join(value, ', ').replace(/,([^,]*)$/, ' and $1')
  } else if (percentageValues.includes(label)) {
    value = `${value} %`
  } else if (label === 'MOIC') {
    value = `${value}x`
  } else if (label === 'Image Url') {
    return <Image src={value} width={200} fallback={defaultImage} />
  } else if (label.indexOf('Date') > -1) {
    value = moment.utc(value).format('MM/DD/YYYY')
  }

  return (
    <div key={label} className="mb-6">
      {label.indexOf('Link') === -1 && (
        <span className="type-subtitle-sm text-header">{label}</span>
      )}
      {label === 'Company Website' || label.indexOf('Link') > -1 ? (
        <Button
          as="a"
          style={{ margin: 0, padding: 0 }}
          variant="link"
          href={getClickableLink(value)}
          rel="noreferrer"
          target="_blank"
          iconLeft={<Launch />}
        >
          {value}
        </Button>
      ) : (
        <div className={className}>{addLineBreaks(value)}</div>
      )}
    </div>
  )
}

const ProfileOverviewTab = ({
  sections,
  sideSections,
  postedBy = '',
  dealDisclaimer = false,
  onDealDisclaimerClick = () => {},
}) => {
  return (
    <>
      <Row>
        <Col span={!isEmpty(sideSections) && !isNil(sideSections) ? 15 : 24}>
          {isEmpty(sections) ? (
            <div className="profile-empty">
              <InfoOutlined className="text-brand-100" size={36} />
              No data
            </div>
          ) : (
            map(sections, (x) =>
              titleAndValue(x.label, x.value, 'cc-profile-info')
            )
          )}
        </Col>
        {!isEmpty(sideSections) && !isNil(sideSections) && (
          <>
            <Col span={2}>
              <Divider type="vertical" style={{ height: '100%' }} />
            </Col>
            <Col span={7}>
              {map(sideSections, (x) =>
                titleAndValue(x.label, x.value, 'type-body-regular-lg')
              )}
            </Col>
          </>
        )}
      </Row>
      {postedBy && !isEmpty(postedBy) && (
        <Row>
          <Col span={24}>
            <div className="cc-body-text deal-info-text">
              <VerifiedOutlined className="mr-1 align-middle" size={20} />
              Posted by {postedBy.toLowerCase() === 'allocator'
                ? 'an'
                : 'a'}{' '}
              {postedBy}{' '}
              {postedBy.toLowerCase() === 'context family network'
                ? 'member'
                : ''}
            </div>
            {dealDisclaimer && (
              <div className="cc-body-text deal-info-text">
                <Button
                  variant="link"
                  iconLeft={<InfoOutlined />}
                  style={{ margin: 0, padding: 0 }}
                  onClick={onDealDisclaimerClick}
                >
                  Please read this important disclaimer.
                </Button>
              </div>
            )}
          </Col>
        </Row>
      )}
    </>
  )
}

const sectionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
})

ProfileOverviewTab.propTypes = {
  sections: PropTypes.arrayOf(sectionShape),
  sideSections: PropTypes.arrayOf(sectionShape),
  postedBy: PropTypes.string,
  dealDisclaimer: PropTypes.bool,
  onDealDisclaimerClick: PropTypes.func,
}

export default ProfileOverviewTab
