import * as React from 'react'
import {
  DataExplorationOutlined,
  DescriptionOutlined,
  MarkChatUnreadOutlined,
  TaskAlt,
  WorkOutline,
} from '@context365/icons'
import { ROLE } from '~/constants/roles'
import useAuth from '~/hooks/useAuth'
import { ReactComponent as GetStartedHere } from './get-started-here.svg'

export default function CampaignsEmptyState() {
  const { role } = useAuth()
  return role === ROLE.MANAGER ? <ManagerEmptyState /> : <AllocatorEmptyState />
}

function ManagerEmptyState() {
  return (
    <EmptyContainer description="A campaign is a dedicated workspace to manage your capital raise.">
      <BulletPoint icon={<DescriptionOutlined />}>
        Allocators that fit your investment goals can indicate interest.
      </BulletPoint>
      <BulletPoint icon={<TaskAlt />}>
        You can review applications and find leads that best meet your needs.
      </BulletPoint>
      <BulletPoint icon={<DataExplorationOutlined />}>
        Dive into company data, bios, and investor preferences.
      </BulletPoint>
      <BulletPoint icon={<MarkChatUnreadOutlined />}>
        Connect and discuss opportunities privately with potential investment
        partners.
      </BulletPoint>
    </EmptyContainer>
  )
}

function AllocatorEmptyState() {
  return (
    <EmptyContainer description="A campaign is a dedicated workspace to manage your capital allocation.">
      <BulletPoint icon={<DescriptionOutlined />}>
        Funds &amp; allocators that fit your investment goals can indicate
        interest.
      </BulletPoint>
      <BulletPoint icon={<TaskAlt />}>
        You can review applications and find leads that best meet your needs.
      </BulletPoint>
      <BulletPoint icon={<DataExplorationOutlined />}>
        Explore performance data, bios, attachments, and media.
      </BulletPoint>
      <BulletPoint icon={<MarkChatUnreadOutlined />}>
        Connect and discuss opportunities privately with potential investment
        partners.
      </BulletPoint>
    </EmptyContainer>
  )
}

function EmptyContainer({ children, description }) {
  return (
    <div className="relative flex flex-col gap-4">
      <div className="type-body-semibold-sm">What&apos;s a campaign?</div>
      <BulletPoint icon={<WorkOutline />}>{description}</BulletPoint>
      <div className="type-body-semibold-sm">Create your first campaign:</div>
      <div className="space-y-3">{children}</div>
      <GetStartedHere className="hidden xl:block absolute top-0 right-16" />
    </div>
  )
}

function BulletPoint({ icon, children }) {
  return (
    <div className="flex items-center gap-2 pl-5">
      {React.cloneElement(icon, {
        className: 'text-secondary-100 flex-shrink-0',
      })}
      <div className="type-body-regular-sm">{children}</div>
    </div>
  )
}
