import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { WarningAmber } from '@context365/icons'
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons'
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row, Upload, message } from 'antd'
import { forEach, isEmpty, isNil, map } from 'lodash'
import join from 'lodash/join'
import moment from 'moment'
import * as api from '~/api'
import { downloadFile } from '~/utils/files'
import { formatCurrency } from '~/utils/helpers'
import EditReturnsExcelForm from './EditReturnsExcelForm'
import './UpdateReturnsExcel.less'

const { Dragger } = Upload

const UpdateReturnsExcel = ({ fundId, onSave }) => {
  const [excelData, setExcelData] = useState(null)
  const [excelErrors, setExcelErrors] = useState(null)
  const [excelWarnings, setExcelWarnings] = useState(null)
  const [editData, setEditData] = useState(null)
  const [editing, setEditing] = useState(false)

  const validateExcel = useCallback(
    (e) => {
      api.fundCharts.logInIfNeeded().then(() => {
        api.fundCharts
          .validateExcelReturns(fundId, e.file)
          .then((res) => {
            setExcelData(res.data.data)
            setExcelErrors(res.data.errors)
            setExcelWarnings(res.data.warnings)
          })
          .catch((err) => {
            const errorMessage =
              err?.response?.status === 400
                ? err?.response?.data?.message
                : null
            message.error(
              errorMessage ||
                'An error occurred while uploading your excel file.'
            )
          })
      })
    },
    [fundId]
  )

  const switchToEdit = () => {
    const formatted = { returns: [] }
    forEach(excelData.DateTime, (date, i) => {
      const returnValue = {
        monthEndDate: excelData.DateTimeErrors[i] ? null : moment(date),
        return: excelData.Returns[i],
        aum: excelData.AUM[i],
      }
      formatted.returns.push(returnValue)
    })
    setEditData(formatted)
    setEditing(true)
  }

  const onSubmitExcel = () => {
    const returns = {
      DateTime: excelData.DateTime,
      AUM: excelData.AUM,
      Returns: excelData.Returns,
    }
    onSave(returns)
  }

  const onSubmitJson = (vals) => {
    const returnJson = { DateTime: [], Returns: [], AUM: [] }

    forEach(vals, (x) => {
      returnJson.DateTime.push(x.monthEndDate)
      returnJson.Returns.push(x.return)
      returnJson.AUM.push(x.aum)
    })

    api.fundCharts
      .validateReturnJson(fundId, returnJson)
      .then((res) => {
        if (isEmpty(res.data.errors)) {
          onSave(returnJson)
        } else {
          message.error('Please fix all errors before saving')
        }
      })
      .catch(() =>
        message.error('An error has occured while validating your returns')
      )
  }

  const downloadReturnsExcel = (withCurrentReturns) => {
    withCurrentReturns
      ? api.fundCharts
          .getReturnsExcel(fundId)
          .then((res) => {
            downloadFile(
              res.data,
              `Returns_${fundId}_${moment(new Date()).format(
                'YYYYMMDDHHmmSS'
              )}.xlsx`
            )
          })
          .catch(() =>
            message.error(
              'There are no returns currently available for this fund'
            )
          )
      : api.fundCharts
          .getReturnsExcelTemplate(fundId)
          .then((res) => {
            downloadFile(
              res.data,
              `ReturnsTemplate_${fundId}_${moment(new Date()).format(
                'YYYYMMDDHHmmSS'
              )}.xlsx`
            )
          })
          .catch(() => message.error('Error downloading returns template'))
  }

  return (
    <div>
      {isNil(excelData) ? (
        <>
          <div className="UpdateReturnsExcel-step">
            1){' '}
            <Button
              variant="link"
              onClick={() => downloadReturnsExcel()}
              style={{
                padding: '0px',
                verticalAlign: 'top',
                fontWeight: 'normal',
                fontSize: '16px',
              }}
            >
              Download the excel template
            </Button>
            {!isNil(fundId) && (
              <span>
                {' '}
                or your{' '}
                <Button
                  variant="link"
                  onClick={() => downloadReturnsExcel(true)}
                  style={{
                    padding: '0px',
                    verticalAlign: 'top',
                    fontWeight: 'normal',
                    fontSize: '16px',
                  }}
                >
                  current returns file
                </Button>
              </span>
            )}
          </div>
          <div className="UpdateReturnsExcel-step">
            2) Insert your monthly returns (net of all fees) and AUM
          </div>
          <div className="UpdateReturnsExcel-step">
            3) Upload the file using the area below
          </div>
          <Dragger
            accept=".xlsx"
            maxCount={1}
            showUploadList={{ showRemoveIcon: false }}
            beforeUpload={() => false}
            onChange={validateExcel}
          >
            <p className="ant-upload-drag-icon">
              <FontAwesomeIcon icon={faCloudUpload} size="3x" color="#C2C6CC" />
            </p>
            <p className="ant-upload-text">Drag or click here to upload</p>
            <p className="ant-upload-hint">(xlsx / Max file size 4mb)</p>
          </Dragger>
        </>
      ) : (
        <>
          {!isEmpty(excelErrors) && (
            <div className="UpdateReturnsExcel-error container">
              <div className="header">
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{ height: '24px', width: '24px', marginRight: '10px' }}
                  color="#D62B34"
                />
                Errors were found. Please fix below or edit the spreadsheet and{' '}
                <Button
                  variant="link"
                  style={{
                    padding: '0px',
                    height: '24px',
                    marginLeft: '4px',
                    border: '0px',
                  }}
                  onClick={() => {
                    setExcelData(null)
                    setEditing(false)
                  }}
                >
                  {' '}
                  upload it again
                </Button>
              </div>
              {map(excelErrors, (value, key) => {
                return (
                  <div className="row">
                    {key}:{' '}
                    {Array.isArray(value) ? map(value, (x) => `${x} `) : value}
                  </div>
                )
              })}
            </div>
          )}
          {!isEmpty(excelWarnings) &&
            map(excelWarnings, (value, key) => {
              return (
                <div className="text-gold-100 flex">
                  <WarningAmber className="text-gold-100 mr-2" />
                  <div>
                    {key}: {Array.isArray(value) ? join(value, ', ') : value}
                  </div>
                </div>
              )
            })}
          {editing ? (
            <EditReturnsExcelForm
              initialValues={editData}
              onSubmit={onSubmitJson}
            />
          ) : (
            <div className="UpdateReturnsExcel-preview">
              <Row className="UpdateReturnsExcel-preview-row">
                <Col span={8}>Month End Date</Col>
                <Col span={8}>Return</Col>
                <Col span={8}>AUM</Col>
              </Row>
              <div className="UpdateReturnsExcel-preview-data">
                {map(excelData.DateTime, (date, i) => {
                  return (
                    <Row className="UpdateReturnsExcel-preview-row">
                      <Col
                        span={8}
                        className={
                          excelData.DateTimeErrors[i]
                            ? 'UpdateReturnsExcel-error'
                            : ''
                        }
                      >
                        {date}
                      </Col>
                      <Col
                        span={8}
                        className={
                          excelData.ReturnsErrors[i]
                            ? 'UpdateReturnsExcel-error'
                            : ''
                        }
                      >
                        {excelData.ReturnsErrors[i]
                          ? excelData.Returns[i]
                          : `${excelData.Returns[i]} %`}
                      </Col>
                      <Col
                        span={8}
                        className={
                          excelData.AUMErrors[i]
                            ? 'UpdateReturnsExcel-error'
                            : ''
                        }
                      >
                        {excelData.AUMErrors[i]
                          ? excelData.AUM[i]
                          : formatCurrency(excelData.AUM[i])}
                      </Col>
                    </Row>
                  )
                })}
              </div>
            </div>
          )}
          {!editing && (
            <div className="UpdateReturnsExcel-footer">
              {isEmpty(excelErrors) && (
                <Button variant="filled" onClick={onSubmitExcel}>
                  Save
                </Button>
              )}
              <Button variant="filled" onClick={switchToEdit}>
                Edit
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

UpdateReturnsExcel.propTypes = {
  fundId: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default UpdateReturnsExcel
