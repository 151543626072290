import { useCallback } from 'react'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Input, Row, Space } from 'antd'
import every from 'lodash/every'
import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import FiltersView from '~/components/FiltersView/FiltersView'
import './SummitAttendees.less'

const { Search } = Input

const SummitTableHeader = ({
  title,
  handleSearch,
  filterArray,
  setFilter,
  setAllFilters,
}) => {
  const onRemoveFilter = useCallback(
    (key, value) => {
      if (isNil(value)) {
        setFilter(key, null)
      } else {
        const filterVal = find(filterArray, (f) => f.id === key)
        const newFilterVal = isNil(filterVal)
          ? null
          : filter(filterVal.value, (v) => v !== value)
        setFilter(key, newFilterVal)
      }
    },
    [setFilter, filterArray]
  )

  const clearFilters = useCallback(() => setAllFilters([]), [setAllFilters])

  return (
    <>
      <Row width="770px">
        <Col span={12}>
          <h4 className="SummitAttendees-title">{title}</h4>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Space align="center">
            <Search
              placeholder="Search"
              onSearch={handleSearch}
              allowClear={true}
              size="large"
              className="SummitAttendees-search"
              enterButton="Search"
              prefix={<FontAwesomeIcon icon={faSearch} />}
            />
          </Space>
        </Col>
      </Row>
      <Row>
        {!isEmpty(filterArray) &&
          !every(filterArray, (f) => isNil(f) || isEmpty(f)) && (
            <div className="filters">
              <span className="cc-tabletitle-text">Filters:</span>
              {map(filterArray, (filter, key) => {
                return (
                  !isEmpty(filter.value) && (
                    <span key={key} className="cc-small-font">
                      <FiltersView
                        filterKey={filter.id}
                        values={filter.value}
                        onRemoveFilter={onRemoveFilter}
                        onRemoveMeetingStatusFilters={onRemoveFilter}
                        onRemoveNumberFilters={onRemoveFilter}
                      />
                    </span>
                  )
                )
              })}
              <Button type="link" onClick={clearFilters}>
                Clear All
              </Button>
            </div>
          )}
      </Row>
    </>
  )
}
export default SummitTableHeader
