import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Empty } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import moment from 'moment'
import numeral from 'numeral'
import * as api from '~/api'
import { numberFormat } from '~/utils/formatters'
import { addLineBreaks } from '~/utils/helpers'
import Loading from '../Loading'
import './FundDetails.less'

const FundDetails = ({
  fundId = 0,
  fundProfile,
  showTradingMethod,
  showFundName,
  showAUMandPerformance,
  answers,
}) => {
  const [fundProfileData, setFundProfileData] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (fundId === 0 && isEmpty(fundProfile)) return
    if (isEmpty(fundProfile)) {
      api.funds
        .getFundProfile(fundId)
        .then((response) => {
          setFundProfileData(response)
        })
        .finally(() => setLoading(false))
    } else {
      setFundProfileData(fundProfile)
    }
  }, [fundId, fundProfile])

  return isEmpty(fundProfileData) ? (
    <Loading spinning={loading}>
      <Empty />
    </Loading>
  ) : (
    <div>
      <div className="fund-details-profile-container">
        {showFundName && (
          <div className="fund-details-profile-section">
            <div className="type-subtitle-sm text-header">FUND NAME</div>
            <p className="mt-2">
              {isEmpty(answers) ? fundProfileData.fundName : fundProfile.Name}
            </p>
          </div>
        )}
        {isEmpty(answers) && (
          <div className="fund-details-profile-section">
            <div className="type-subtitle-sm text-header">
              COMPANY DESCRIPTION
            </div>
            <p className="leading-5 mt-2">
              {addLineBreaks(fundProfileData.companyDescription)}
            </p>
          </div>
        )}
        {showTradingMethod && (
          <div className="fund-details-profile-section">
            <div className="type-subtitle-sm text-header">
              FUND STRATEGY & DESCRIPTION
            </div>
            <p className="type-body-regular-sm mt-2">
              {addLineBreaks(
                fundProfile.tradingMethod || fundProfile.FundStrategyDescription
              )}
            </p>
          </div>
        )}
      </div>
      {showAUMandPerformance && (
        <div className="fundAUMandPerformance">
          <div className="fundAUMandPerformance-numbers-container">
            <span className="type-subtitle-sm text-header">
              ASSETS UNDER MANAGEMENT
            </span>
            <div
              style={{
                marginTop: '7px',
                display: 'flex',
                width: '40%',
                justifyContent: 'space-between',
              }}
            >
              <div className="fundAUMandPerformance-numbers">
                <div className="type-body-regular-lg">
                  {numeral(fundProfileData?.fundAum || 0).format('($0 a)')}
                </div>
                <span className="cc-darkest-grey-text cc-xsmall-font">USD</span>
              </div>
              {fundProfileData.fundInceptionDate && (
                <div className="fundAUMandPerformance-numbers">
                  <div className="type-body-regular-lg">
                    {moment(
                      fundProfileData.fundInceptionDate,
                      moment.ISO_8601
                    ).format('MMM DD YYYY')}
                  </div>
                  <span className="cc-darkest-grey-text cc-xsmall-font">
                    Inception
                  </span>
                </div>
              )}
            </div>
          </div>
          {!fundProfileData.narrative && (
            <div className="fundAUMandPerformance-numbers-container">
              <span className="type-subtitle-sm text-header">PERFORMANCE</span>
              <div
                style={{
                  marginTop: '7px',
                  display: 'flex',
                  width: '40%',
                  justifyContent: 'space-between',
                }}
              >
                <div className="fundAUMandPerformance-numbers">
                  <div className="type-body-regular-lg">
                    {numeral(fundProfileData.annualizedReturn || 0).format(
                      '0.0 %'
                    )}
                  </div>
                  <span className="cc-darkest-grey-text cc-xsmall-font">
                    Ann. Return
                  </span>
                </div>
                <div className="fundAUMandPerformance-numbers">
                  <div className="type-body-regular-lg">
                    {numeral(fundProfileData.annualVolatility || 0).format(
                      '0.0 %'
                    )}
                  </div>
                  <span className="cc-darkest-grey-text cc-xsmall-font">
                    Ann. Volatility
                  </span>
                </div>
                <div className="fundAUMandPerformance-numbers">
                  {!isNil(fundProfileData.ytdreturn) && (
                    <>
                      <div className="type-body-regular-lg">
                        {numberFormat.decimal(fundProfileData.ytdreturn, {
                          precision: 2,
                          suffix: '%',
                        })}
                      </div>
                      <span className="cc-darkest-grey-text cc-xsmall-font">
                        YTD Return
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

FundDetails.defaultProps = {
  fundProfile: {},
  fundId: 0,
  showTradingMethod: true,
  showFundName: false,
  showAUMandPerformance: false,
}

FundDetails.propTypes = {
  fundProfile: PropTypes.object,
  fundId: PropTypes.number,
  showTradingMethod: PropTypes.bool,
  showFundName: PropTypes.bool,
  showAUMandPerformance: PropTypes.bool,
  answers: PropTypes.any,
}

export default FundDetails
