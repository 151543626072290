import * as React from 'react'
import { FormLabel, FormMessage } from '@context365/forms'
import { East } from '@context365/icons'
import cx from 'classnames'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useController } from 'react-hook-form'
import { useFieldSchema } from '~/components/Form'
import { useOnboardingContext } from '../OnboardingContext'

const ListType = {
  Selected: 'selected',
  Unselected: 'unselected',
}

export default function MandateOrderForm({ className }) {
  const rules = useFieldSchema('mandateOrder', 'Mandate Order')

  const { options } = useOnboardingContext()
  const {
    field: { value = '', onChange, ref },
    fieldState: { error },
  } = useController({ name: 'mandateOrder' })

  const updateSelected = (selected = []) => {
    onChange(selected.map((o) => o.name).join(','))
  }

  const lists = React.useMemo(() => {
    const selectedNames = value.split(',').filter(Boolean)
    const selected = selectedNames.map((name) =>
      options.mandatePreferenceCategories.find((o) => o.name === name)
    )
    const unselected = options.mandatePreferenceCategories.filter(
      (o) => !selectedNames.includes(o.name)
    )

    return {
      [ListType.Selected]: selected,
      [ListType.Unselected]: unselected,
    }
  }, [options, value])

  const onDragEnd = ({ source, destination }) => {
    if (!destination) {
      // dropped outside the list
      return
    }

    if (source.droppableId === destination.droppableId) {
      // prevent re-ordering the unselected list
      if (source.droppableId === ListType.Unselected) {
        return
      }

      const result = reorder(
        lists[source.droppableId],
        source.index,
        destination.index
      )
      updateSelected(result)
    } else {
      const result = move(lists, source, destination)
      updateSelected(result[ListType.Selected])
    }
  }

  return (
    <div ref={ref} className={className}>
      <FormLabel className="mb-0" required={rules.required}>
        Mandate Order
      </FormLabel>
      <FormMessage
        className="mt-0"
        helpMessage="Please rank in order of importance. To rank, drag the items from the left column to the right."
        errorMessage={error?.message}
      />
      <div className="flex items-stretch gap-2 my-2">
        <DragDropContext onDragEnd={onDragEnd}>
          <List
            id={ListType.Unselected}
            title="Available"
            items={lists[ListType.Unselected]}
          />
          <East className="self-center flex-shrink-0" />
          <List
            id={ListType.Selected}
            title="Selected"
            items={lists[ListType.Selected]}
          />
        </DragDropContext>
      </div>
    </div>
  )
}

function reorder(list, sourceIndex, destinationIndex) {
  const result = list.slice()
  const [moved] = result.splice(sourceIndex, 1)
  result.splice(destinationIndex, 0, moved)
  return result
}

function move(lists, source, destination) {
  const sourceClone = lists[source.droppableId].slice()
  const destClone = lists[destination.droppableId].slice()

  const [moved] = sourceClone.splice(source.index, 1)
  destClone.splice(destination.index, 0, moved)

  return {
    [source.droppableId]: sourceClone,
    [destination.droppableId]: destClone,
  }
}

function List({ id, title, items }) {
  return (
    <div className="flex-1 flex flex-col">
      <div className="type-body-semibold-md p-2 text-center">{title}</div>
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={cx(
              'p-2 flex-1',
              snapshot.isDraggingOver ? 'bg-secondary-5' : 'bg-grey-300'
            )}
            style={{ minHeight: 100 }}
          >
            {items.map((item, index) => (
              <Item key={item.id} id={item.id} name={item.name} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

function Item({ id, index, name }) {
  return (
    <Draggable draggableId={id.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className={cx(
            // Combining mb-2 on the Item with p-2 on the List can make the
            // bottom of the list look too big in some browsers, but apparently
            // the dnd library doesn't support any kind of
            // implicit/dynamic/collapsing margins.
            'select-none py-3 px-4 mb-2',
            snapshot.isDragging ? 'bg-secondary-2' : 'bg-white'
          )}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {name}
        </div>
      )}
    </Draggable>
  )
}
