import { Badge } from '@context365/badge'
import { Button } from '@context365/button'
import drop from 'lodash/drop'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import moment from 'moment'
import ActionButton from '~/components/ActionButton'
import AvatarGroup from '~/components/AvatarGroup'
import { DiscoverListsPopover } from '~/components/DiscoverLists'
import MatchmakingScore from '~/components/MatchmakingScore'
import MeetingBadge from '~/components/MeetingBadge'
import ShortenedText from '~/components/ShortenedText'
import RangeFilter from '~/components/SummitFilters/RangeFilter'
import SelectFilter from '~/components/SummitFilters/SelectFilter'
import TagGroup from '~/components/TagGroup'
import MeetingStatuses from '~/constants/meetingStatuses'
import {
  formatCurrency,
  formatDate,
  formatDecimal,
  formatPercent,
} from '~/utils/helpers'

const getActionButtonsMenuFund = (
  fund,
  pastSummit,
  role,
  onSendMeetingRequest,
  onSendMessage,
  onClickRecord,
  onAddToList
) => {
  const fullMenu = [
    {
      text: 'Send Meeting Request',
      clickHandle: onSendMeetingRequest.bind(null, fund),
    },
    {
      text: 'Send Message',
      disabled:
        role === 'Service Provider' && isNil(fund?.meeting?.conversationId),
      disabledReason:
        'You must have a confirmed meeting or active conversation to message this person.',
      clickHandle: onSendMessage.bind(
        null,
        fund,
        get(fund, 'meeting.conversationId', 0)
      ),
    },
    {
      text: 'Profile',
      clickHandle: onClickRecord.bind(null, fund),
    },
    {
      text: 'Add to List',
      clickHandle: onAddToList.bind(null, fund),
    },
  ]
  if (!pastSummit && isNil(fund.meeting)) {
    return fullMenu
  } else {
    return drop(fullMenu)
  }
}

export const getFundColumns = (
  onClickRecord,
  onSendMessage,
  onSendMeetingRequest,
  discoverLists,
  onAddToList,
  dispatch,
  filterOptions,
  role,
  pastSummit
) => [
  {
    id: 'name',
    Header: 'FUND',
    fixed: 'left',
    accessor: (fund) => {
      return (
        <Button
          variant="link"
          onClick={() => {
            onClickRecord(fund)
          }}
        >
          <ShortenedText text={fund.name} maxLength={35} />
        </Button>
      )
    },
  },
  {
    id: 'meetingStatus',
    Header: '',
    fixed: 'left',
    disableSortBy: true,
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={['Confirmed', 'Pending', 'Cancelled', 'Declined']}
        />
      )
    },
    accessor: (record) => {
      return <MeetingBadge meeting={record.hasMeeting && record.meeting} />
    },
  },

  {
    disableSortBy: true,
    id: 'contacts',
    Header: 'CONTACTS',
    fixed: 'left',
    accessor: (fund) => {
      return (
        <div className="whitespace-nowrap">
          <AvatarGroup
            avatars={map(fund.contacts, (contact) => {
              return {
                id: contact.contactId,
                firstName: contact.firstName,
                lastName: contact.lastName,
                initials: `${contact.firstName[0]}${contact.lastName[0]}`,
                description: `${contact.contactName} ${
                  contact.jobTitle ? `(${contact.jobTitle})` : ''
                }`,
                img: contact.imageUrl,
              }
            })}
          />
        </div>
      )
    },
  },
  {
    disableSortBy: true,
    id: 'actions',
    fixed: 'left',
    accessor: (fund) => (
      <ActionButton
        record={fund}
        menu={getActionButtonsMenuFund(
          fund,
          pastSummit,
          role,
          onSendMeetingRequest,
          onSendMessage,
          onClickRecord,
          onAddToList
        )}
      />
    ),
  },
  {
    disableSortBy: true,
    id: 'location',
    Header: 'LOCATION ',
    fixed: 'left',
    accessor: (fund) => {
      return (
        fund.boothId && (
          <Badge className="category-tag mx-1">{fund.boothId}</Badge>
        )
      )
    },
  },
  {
    id: 'matchmakingScore',
    Header: 'MATCHMAKING SCORE',
    title: 'MATCHMAKING SCORE',
    Filter: (table) => {
      return (
        <RangeFilter
          column={table.column}
          type="percentage"
          minVal={0}
          maxVal={100}
        />
      )
    },
    accessor: (fund) => {
      return (
        <MatchmakingScore
          score={fund.matchmakingScore}
          threshold={0.7}
          size={30}
        />
      )
    },
  },
  {
    disableSortBy: true,
    id: 'discoverLists',
    Header: 'LISTS',
    title: 'LISTS',
    Filter: (table) => {
      const listNames = map(discoverLists, (x) => x.name)
      return <SelectFilter column={table.column} options={listNames} />
    },
    accessor: (record) => {
      const lists = filter(discoverLists, (list) =>
        find(list.discoverListLinks, ['toFundId', record.fundId])
      )
      return (
        lists &&
        lists.length > 0 && (
          <DiscoverListsPopover
            lists={lists}
            targetObject={record}
            getTargetModel={() => {
              return { toFundIds: [record.fundId] }
            }}
            onAddToList={onAddToList}
          />
        )
      )
    },
  },
  {
    Header: 'COMPANY',
    title: 'COMPANY',
    id: 'companyName',
    accessor: (fund) => {
      return <ShortenedText text={fund.companyName} maxLength={35} />
    },
  },
  {
    disableSortBy: true,
    id: 'broadStrategy',
    Header: 'BROAD STRATEGY',
    title: 'BROAD STRATEGY',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.broadStrategy : []}
        />
      )
    },
    accessor: (fund) => {
      return (
        fund.broadStrategy && (
          <ShortenedText text={fund.broadStrategy} maxLength={35} />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'subStrategy',
    Header: 'SUB STRATEGY',
    title: 'SUB STRATEGY',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.subStrategy : []}
        />
      )
    },
    accessor: (fund) => {
      return (
        fund.subStrategy && (
          <ShortenedText text={fund.subStrategy} maxLength={35} />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'fundAttributes',
    Header: 'FUND ATTRIBUTES',
    title: 'FUND ATTRIBUTES',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.fundAttributes : []}
        />
      )
    },
    accessor: (fund) => {
      return (
        fund.fundAttributes && (
          <TagGroup items={fund.fundAttributes.split(',')} maxLength={2} />
        )
      )
    },
  },
  {
    id: 'fundAum',
    Header: 'AUM',
    title: 'AUM',
    Filter: (table) => {
      return <RangeFilter column={table.column} type="currency" />
    },
    accessor: (fund) => formatCurrency(fund.fundAum),
  },
  {
    id: 'fundInceptionDate',
    Header: 'INCEPTION DATE',
    title: 'INCEPTION DATE',
    accessor: (fund) => formatDate(fund.fundInceptionDate),
  },
  {
    disableSortBy: true,
    id: 'investmentVehicle',
    Header: 'INVESTMENT VEHICLE',
    title: 'INVESTMENT VEHICLE',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.investmentVehicle : []}
        />
      )
    },
    accessor: (fund) => {
      return (
        fund.investmentVehicle && (
          <TagGroup items={fund.investmentVehicle.split(',')} maxLength={2} />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'geographicConcentration',
    Header: 'GEOGRAPHIC CONCENTRATION',
    title: 'GEOGRAPHIC CONCENTRATION',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.geographicConcentration : []}
        />
      )
    },
    accessor: (fund) => {
      return (
        fund.geographicConcentration && (
          <TagGroup
            items={fund.geographicConcentration.split(',')}
            maxLength={2}
          />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'sectorConcentration',
    Header: 'SECTOR CONCENTRATION',
    title: 'SECTOR CONCENTRATION',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.sectorConcentration : []}
        />
      )
    },
    accessor: (fund) => {
      return (
        fund.sectorConcentration && (
          <TagGroup items={fund.sectorConcentration.split(',')} maxLength={2} />
        )
      )
    },
  },
  {
    id: 'minimumInvestment',
    Header: 'MINIMUM',
    title: 'MINIMUM',
    Filter: (table) => {
      return <RangeFilter column={table.column} type="currency" />
    },
    accessor: (fund) => formatCurrency(fund.minimumInvestment),
  },
  {
    disableSortBy: true,
    id: 'domicile',
    Header: 'FUND DOMICILE',
    title: 'FUND DOMICILE',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.domicile : []}
        />
      )
    },
    accessor: (fund) => {
      return (
        fund.domicile && (
          <TagGroup items={fund.domicile.split(',')} maxLength={2} />
        )
      )
    },
  },
  {
    id: 'managerCity',
    Header: 'MANAGER CITY',
    title: 'MANAGER CITY',
    accessor: (fund) => fund.managerCity,
  },
  {
    id: 'managerCountry',
    Header: 'MANAGER COUNTRY',
    title: 'MANAGER COUNTRY',
    accessor: (fund) => fund.managerCountry,
  },
  {
    id: 'primeBroker',
    Header: 'PRIME BROKER',
    title: 'PRIME BROKER',
    accessor: (fund) => fund.primeBroker,
  },
  {
    id: 'annualizedReturn',
    Header: 'ANN. RETURN',
    title: 'ANN. RETURN',
    Filter: (table) => {
      return <RangeFilter column={table.column} type="percentage" />
    },
    accessor: (fund) =>
      fund.annualizedReturn && formatPercent(fund.annualizedReturn),
  },
  {
    id: 'currentYtdReturn',
    Header: `${moment().format('YYYY')} YTD RETURN`,
    title: `${moment().format('YYYY')} YTD RETURN`,
    Filter: (table) => {
      return <RangeFilter column={table.column} type="percentage" />
    },
    accessor: (fund) =>
      fund.currentYtdReturn && formatPercent(fund.currentYtdReturn / 100),
  },
  {
    id: 'previousYtdReturn',
    Header: `${moment().format('YYYY') - 1} YTD RETURN`,
    title: `${moment().format('YYYY') - 1} YTD RETURN`,
    Filter: (table) => {
      return <RangeFilter column={table.column} type="percentage" />
    },
    accessor: (fund) =>
      fund.previousYtdReturn && formatPercent(fund.previousYtdReturn / 100),
  },
  {
    id: 'sharpe',
    Header: 'SHARPE',
    title: 'SHARPE',
    Filter: (table) => {
      return <RangeFilter column={table.column} />
    },
    accessor: (fund) => fund.sharpe && formatDecimal(fund.sharpe),
  },
  {
    id: 'sortino',
    Header: 'SORTINO',
    title: 'SORTINO',
    Filter: (table) => {
      return <RangeFilter column={table.column} />
    },
    accessor: (fund) => fund.sortino && formatDecimal(fund.sortino),
  },
  {
    id: 'durationOfCapitalLockup',
    Header: 'LOCKUP',
    title: 'LOCKUP',
    Filter: (table) => {
      return <RangeFilter column={table.column} />
    },
    accessor: (fund) => fund.durationOfCapitalLockup,
  },
  {
    id: 'deepestDrawdown',
    Header: 'DEEPEST DRAWDOWN',
    title: 'DEEPEST DRAWDOWN',
    Filter: (table) => {
      return <RangeFilter column={table.column} type="percentage" />
    },
    accessor: (fund) =>
      fund.deepestDrawdown && formatPercent(fund.deepestDrawdown / 100),
  },
  {
    id: 'managementFee',
    Header: 'MANAGEMENT FEE',
    title: 'MANAGEMENT FEE',
    Filter: (table) => {
      return <RangeFilter column={table.column} type="percentage" />
    },
    accessor: (fund) =>
      fund.managementFee && formatPercent(fund.managementFee / 100),
  },
  {
    id: 'performanceFee',
    Header: 'PERFORMANCE FEE',
    title: 'PERFORMANCE FEE',
    Filter: (table) => {
      return <RangeFilter column={table.column} type="percentage" />
    },
    accessor: (fund) =>
      fund.performanceFee && formatPercent(fund.performanceFee / 100),
  },
  {
    id: 'primaryDriverOfInvestmentDecisionProcess',
    Header: 'PRIMARY DRIVER OF INVESTMENT DECISION PROCESS',
    title: 'PRIMARY DRIVER OF INVESTMENT DECISION PROCESS',
    accessor: (fund) => {
      return (
        fund.primaryDriverOfInvestmentDecisionProcess && (
          <ShortenedText
            text={fund.primaryDriverOfInvestmentDecisionProcess}
            maxLength={35}
          />
        )
      )
    },
  },
  {
    id: 'netDollarExposure',
    Header: 'NET DOLLAR EXPOSURE',
    title: 'NET DOLLAR EXPOSURE',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.netDollarExposureInterest : []}
        />
      )
    },
    accessor: (fund) => {
      return <ShortenedText text={fund.netDollarExposure} maxLength={35} />
    },
  },
  {
    disableSortBy: true,
    id: 'primaryInstruments',
    Header: 'PRIMARY INSTRUMENTS',
    title: 'PRIMARY INSTRUMENTS',
    accessor: (fund) => {
      return (
        fund.primaryInstruments && (
          <TagGroup items={fund.primaryInstruments.split(',')} maxLength={2} />
        )
      )
    },
  },
  {
    id: 'firmAum',
    Header: 'FIRM AUM',
    title: 'FIRM AUM',
    Filter: (table) => {
      return <RangeFilter column={table.column} type="currency" />
    },
    accessor: (fund) => formatCurrency(fund.firmAum),
  },
  {
    id: 'createdAt',
    Header: 'DATE ADDED TO SCHEDULE',
    title: 'DATE ADDED TO SCHEDULE',
    accessor: (fund) =>
      fund.createdAt && moment(fund.createdAt).format('MMM DD YYYY'),
  },
  {
    id: 'annualVolatility',
    Header: 'ANNUAL GEOMETRIC SD',
    title: 'ANNUAL GEOMETRIC SD',
    Filter: (table) => {
      return <RangeFilter column={table.column} type="percentage" />
    },
    accessor: (fund) =>
      fund.annualVolatility && formatPercent(fund.annualVolatility),
  },
  {
    id: 'lastMonthUpdate',
    Header: 'LAST RETURN UPDATE MONTH',
    title: 'LAST RETURN UPDATE MONTH',
    accessor: (fund) =>
      fund.lastMonthUpdate && moment(fund.lastMonthUpdate).format('MMM YYYY'),
  },
]

const getActionButtonsMenuSP = (
  record,
  role,
  pastSummit,
  onSendMeetingRequest,
  onSendMessage,
  onClickRecord,
  onAddToList
) => {
  const fullMenu = [
    {
      text: 'Send Meeting Request',
      clickHandle: onSendMeetingRequest.bind(null, record),
    },
    {
      text: 'Send Message',
      clickHandle: onSendMessage.bind(
        null,
        record,
        get(record, 'meeting.conversationId', 0)
      ),
    },
    {
      text: 'Profile',
      clickHandle: onClickRecord.bind(null, record),
    },
    {
      text: 'Add to List',
      clickHandle: onAddToList.bind(null, record),
    },
  ]
  if (pastSummit) {
    return drop(fullMenu, 2)
  } else if (record.meeting) {
    return drop(fullMenu)
  } else {
    return fullMenu
  }
}

export const getSPColumns = (
  onClickRecord,
  onSendMessage,
  onSendMeetingRequest,
  discoverLists,
  onAddToList,
  dispatch,
  filterOptions,
  pastSummit,
  role
) => [
  {
    id: 'companyName',
    Header: 'COMPANY NAME',
    fixed: 'left',
    accessor: (record) => {
      return (
        <Button
          variant="link"
          onClick={() => {
            onClickRecord(record)
          }}
        >
          <ShortenedText text={record.companyName} maxLength={35} />
        </Button>
      )
    },
  },
  {
    disableSortBy: true,
    id: 'contacts',
    Header: 'CONTACTS',
    fixed: 'left',
    accessor: (record) => {
      return (
        <div className="whitespace-nowrap">
          <AvatarGroup
            avatars={map(record.contacts, (contact) => {
              return {
                id: contact.contactId,
                firstName: contact.firstName,
                lastName: contact.lastName,
                initials: `${contact.firstName[0]}${contact.lastName[0]}`,
                description: `${contact.contactName} ${
                  contact.jobTitle ? `(${contact.jobTitle})` : ''
                }`,
                img: contact.imageUrl,
              }
            })}
          />
        </div>
      )
    },
  },
  {
    id: 'meetingStatus',
    Header: '',
    fixed: 'left',
    disableSortBy: true,
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={['Confirmed', 'Pending', 'Cancelled', 'Declined']}
        />
      )
    },
    accessor: (record) => {
      return <MeetingBadge meeting={record.hasMeeting && record.meeting} />
    },
  },
  {
    disableSortBy: true,
    id: 'actions',
    fixed: 'left',
    accessor: (record) => (
      <ActionButton
        record={record}
        menu={getActionButtonsMenuSP(
          record,
          role,
          pastSummit,
          onSendMeetingRequest,
          onSendMessage,
          onClickRecord,
          onAddToList
        )}
      />
    ),
  },
  {
    disableSortBy: true,
    id: 'location',
    Header: 'LOCATION ',
    fixed: 'left',
    accessor: (record) => {
      return (
        record.boothId && (
          <Badge className="category-tag mx-1">{record.boothId}</Badge>
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'discoverLists',
    Header: 'LISTS',
    title: 'LISTS',
    Filter: (table) => {
      const listNames = map(discoverLists, (x) => x.name)
      return <SelectFilter column={table.column} options={listNames} />
    },
    accessor: (record) => {
      const lists = filter(discoverLists, (list) =>
        find(list.discoverListLinks, ['toCompanyId', record.companyId])
      )
      return (
        lists &&
        lists.length > 0 && (
          <DiscoverListsPopover
            lists={lists}
            targetObject={record}
            getTargetModel={() => {
              return { toCompanyIds: [record.companyId] }
            }}
            onAddToList={onAddToList}
          />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'serviceProviderCategory',
    Header: 'SERVICE PROVIDER TYPE',
    title: 'SERVICE PROVIDER TYPE',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.serviceProviderCategory : []}
        />
      )
    },
    accessor: (record) => {
      return (
        record.serviceProviderCategory && (
          <TagGroup
            items={record.serviceProviderCategory.split(',')}
            maxLength={2}
          />
        )
      )
    },
  },
  {
    id: 'Website',
    Header: 'WEBSITE',
    title: 'WEBSITE',
    accessor: (record) => {
      return (
        record.companyWebsite && (
          <a
            href={`http://${record.companyWebsite}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {record.companyWebsite}
          </a>
        )
      )
    },
  },
  {
    id: 'Company Description',
    Header: 'COMPANY DESCRIPTION',
    title: 'COMPANY DESCRIPTION',
    accessor: (record) => {
      return (
        record.companyDescription && (
          <ShortenedText text={record.companyDescription} maxLength={35} />
        )
      )
    },
  },
]

const getActionButtonsMenuAllocators = (
  record,
  creditsAvailable,
  pastSummit,
  role,
  onSendMeetingRequest,
  onSendMessage,
  onClickRecord,
  onAddToList
) => {
  const fullMenu = [
    {
      text: 'Send Meeting Request',
      clickHandle: onSendMeetingRequest.bind(null, record),
      disabled:
        role.toLowerCase() !== 'allocator' &&
        role.toLowerCase() !== 'context' &&
        creditsAvailable < 1,
      disabledReason: 'You have no meeting credits remaining.',
    },
    {
      text: 'Send Message',
      disabled: isNil(record?.meeting?.conversationId),
      disabledReason:
        'You must have a confirmed meeting or active conversation to message this person.',
      clickHandle: onSendMessage.bind(
        null,
        record,
        get(record, 'meeting.conversationId', 0)
      ),
    },
    {
      text: 'Follow Up',
      disabled:
        isNil(record?.meeting?.meetingId) ||
        record?.meeting?.meetingStatusId !== MeetingStatuses.Pending ||
        record?.meeting?.received,
      disabledReason:
        'You must have a pending meeting to send a follow up message.',
      clickHandle: () =>
        window.open(`/meeting/summit/${record.meeting.meetingId}`),
    },
    {
      text: 'Profile',
      clickHandle: onClickRecord.bind(null, record),
    },
    {
      text: 'Add to List',
      clickHandle: onAddToList.bind(null, record),
    },
  ]
  if (pastSummit) {
    return drop(fullMenu, 3)
  } else if (record.meeting) {
    return drop(fullMenu)
  } else {
    return fullMenu
  }
}

export const getAllocatorColumns = (
  onClickRecord,
  onSendMessage,
  onSendMeetingRequest,
  discoverLists,
  onAddToList,
  dispatch,
  filterOptions,
  role,
  creditsAvailable,
  pastSummit
) => [
  {
    id: 'contactName',
    Header: 'CONTACT',
    title: 'CONTACT',
    fixed: 'left',
    accessor: (record) => {
      return (
        <div className="whitespace-nowrap flex">
          <AvatarGroup
            avatars={[
              {
                id: record.contactId,
                firstName: record.firstName,
                lastName: record.lastName,
                initials: `${record.firstName[0]}${record.lastName[0]}`,
                description: `${record.contactName} ${
                  record.jobTitle ? `(${record.jobTitle})` : ''
                }`,
                img: record.imageUrl,
              },
            ]}
          />
          {`${record.firstName} ${record.lastName}`}
        </div>
      )
    },
  },
  {
    id: 'companyName',
    Header: 'COMPANY NAME',
    title: 'COMPANY NAME',
    fixed: 'left',
    accessor: (record) => {
      return (
        <Button
          variant="link"
          onClick={() => {
            onClickRecord(record)
          }}
        >
          <ShortenedText text={record.companyName} maxLength={35} />
        </Button>
      )
    },
  },
  {
    id: 'meetingStatus',
    Header: '',
    fixed: 'left',
    disableSortBy: true,
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={['Confirmed', 'Pending', 'Cancelled', 'Declined']}
        />
      )
    },
    accessor: (record) => {
      return <MeetingBadge meeting={record.hasMeeting && record.meeting} />
    },
  },
  {
    disableSortBy: true,
    id: 'actions',
    fixed: 'left',
    accessor: (record) => (
      <ActionButton
        record={record}
        menu={getActionButtonsMenuAllocators(
          record,
          creditsAvailable,
          pastSummit,
          role,
          onSendMeetingRequest,
          onSendMessage,
          onClickRecord,
          onAddToList
        )}
      />
    ),
  },
  {
    id: 'matchmakingScore',
    Header: 'MATCHMAKING SCORE',
    title: 'MATCHMAKING SCORE',
    Filter: (table) => {
      return (
        <RangeFilter
          column={table.column}
          type="percentage"
          minVal={0}
          maxVal={100}
        />
      )
    },
    accessor: (record) => {
      return (
        <MatchmakingScore
          score={record.matchmakingScore}
          threshold={0.7}
          size={30}
        />
      )
    },
  },
  {
    disableSortBy: true,
    id: 'discoverLists',
    Header: 'LISTS',
    title: 'LISTS',
    Filter: (table) => {
      const listNames = map(discoverLists, (x) => x.name)
      return <SelectFilter column={table.column} options={listNames} />
    },
    accessor: (record) => {
      const lists = filter(discoverLists, (list) =>
        find(list.discoverListLinks, [
          'toCompanyContactId',
          record.companyContactId,
        ])
      )
      return (
        lists &&
        lists.length > 0 && (
          <DiscoverListsPopover
            lists={lists}
            targetObject={record}
            getTargetModel={() => {
              return { toCompanyContactIds: [record.companyContactId] }
            }}
            onAddToList={onAddToList}
          />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'primaryInvestorCategory',
    Header: 'PRIMARY INVESTOR CATEGORY',
    title: 'PRIMARY INVESTOR CATEGORY',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.primaryInvestorCategory : []}
        />
      )
    },
    accessor: (record) => {
      return (
        record.primaryInvestorCategory && (
          <TagGroup
            items={record.primaryInvestorCategory.split(',')}
            maxLength={2}
          />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'secondaryInvestorCategory',
    Header: 'SECONDARY INVESTOR CATEGORY',
    title: 'SECONDARY INVESTOR CATEGORY',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.secondaryInvestorCategory : []}
        />
      )
    },
    accessor: (record) => {
      return (
        record.secondaryInvestorCategory && (
          <TagGroup
            items={record.secondaryInvestorCategory.split(',')}
            maxLength={2}
          />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'liquidBroadStrategyInterest',
    Header: 'LIQUID BROAD STRATEGY INTEREST',
    title: 'LIQUID BROAD STRATEGY INTEREST',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={
            filterOptions ? filterOptions.liquidBroadStrategyInterest : []
          }
        />
      )
    },
    accessor: (record) => {
      return (
        record.liquidBroadStrategyInterest && (
          <TagGroup
            items={record.liquidBroadStrategyInterest.split(',')}
            maxLength={2}
          />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'liquidSubStrategyInterest',
    Header: 'LIQUID SUB STRATEGY INTEREST',
    title: 'LIQUID SUB STRATEGY INTEREST',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.liquidSubStrategyInterest : []}
        />
      )
    },
    accessor: (record) => {
      return (
        record.liquidSubStrategyInterest && (
          <TagGroup
            items={record.liquidSubStrategyInterest.split(',')}
            maxLength={2}
          />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'illiquidBroadStrategyInterest',
    Header: 'ILLIQUID BROAD STRATEGY INTEREST',
    title: 'ILLIQUID BROAD STRATEGY INTEREST',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={
            filterOptions ? filterOptions.illiquidBroadStrategyInterest : []
          }
        />
      )
    },
    accessor: (record) => {
      return (
        record.illiquidBroadStrategyInterest && (
          <TagGroup
            items={record.illiquidBroadStrategyInterest.split(',')}
            maxLength={2}
          />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'illiquidSubStrategyInterest',
    Header: 'ILLIQUID SUB STRATEGY INTEREST',
    title: 'ILLIQUID SUB STRATEGY INTEREST',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={
            filterOptions ? filterOptions.illiquidSubStrategyInterest : []
          }
        />
      )
    },
    accessor: (record) => {
      return (
        record.illiquidSubStrategyInterest && (
          <TagGroup
            items={record.illiquidSubStrategyInterest.split(',')}
            maxLength={2}
          />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'investmentDecisionProcessInterest',
    Header: 'INVESTMENT DECISION PROCESS INTEREST',
    title: 'INVESTMENT DECISION PROCESS INTEREST',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={
            filterOptions ? filterOptions.investmentDecisionProcessInterest : []
          }
        />
      )
    },
    accessor: (record) => {
      return (
        record.investmentDecisionProcessInterest && (
          <TagGroup
            items={record.investmentDecisionProcessInterest.split(',')}
            maxLength={2}
          />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'netDollarExposureInterest',
    Header: 'NET DOLLAR EXPOSURE INTEREST',
    title: 'NET DOLLAR EXPOSURE INTEREST',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.netDollarExposureInterest : []}
        />
      )
    },
    accessor: (record) => {
      return (
        record.netDollarExposureInterest && (
          <TagGroup
            items={record.netDollarExposureInterest.split(',')}
            maxLength={2}
          />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'instrumentInterest',
    Header: 'INSTRUMENT INTEREST',
    title: 'INSTRUMENT INTEREST',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.instrumentInterest : []}
        />
      )
    },
    accessor: (record) => {
      return (
        record.instrumentInterest && (
          <TagGroup
            items={record.instrumentInterest.split(',')}
            maxLength={2}
          />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'sectorsInterest',
    Header: 'SECTORS INTEREST',
    title: 'SECTORS INTEREST',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.sectorsInterest : []}
        />
      )
    },
    accessor: (record) => {
      return (
        record.sectorsInterest && (
          <TagGroup items={record.sectorsInterest.split(',')} maxLength={2} />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'geographicConcentrationInterest',
    Header: 'GEOGRAPHIC CONCENTRATION INTEREST',
    title: 'GEOGRAPHIC CONCENTRATION INTEREST',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={
            filterOptions ? filterOptions.geographicConcentrationInterest : []
          }
        />
      )
    },
    accessor: (record) => {
      return (
        record.geographicConcentrationInterest && (
          <TagGroup
            items={record.geographicConcentrationInterest.split(',')}
            maxLength={2}
          />
        )
      )
    },
  },
  {
    id: 'minimumFundSizeInterest',
    Header: 'MIN FUND SIZE INTEREST',
    title: 'MIN FUND SIZE INTEREST',
    accessor: (record) => formatCurrency(record.minimumFundSizeInterest),
  },
  {
    id: 'maximumFundSizeInterest',
    Header: 'MAX FUND SIZE INTEREST',
    title: 'MAX FUND SIZE INTEREST',
    accessor: (record) => formatCurrency(record.maximumFundSizeInterest),
  },
  {
    id: 'minimumFirmSizeInterest',
    Header: 'MIN FIRM SIZE INTEREST',
    title: 'MIN FIRM SIZE INTEREST',
    accessor: (record) => formatCurrency(record.minimumFirmSizeInterest),
  },
  {
    id: 'maximumFirmSizeInterest',
    Header: 'MAX FIRM SIZE INTEREST',
    title: 'MAX FIRM SIZE INTEREST',
    accessor: (record) => formatCurrency(record.maximumFirmSizeInterest),
  },
  {
    id: 'averageInvestmentSize',
    Header: 'AVG INVESTMENT SIZE',
    title: 'AVG INVESTMENT SIZE',
    accessor: (record) => formatCurrency(record.averageInvestmentSize),
  },
  {
    id: 'minimumInvestmentSize',
    Header: 'MIN INVESTMENT SIZE',
    title: 'MIN INVESTMENT SIZE',
    accessor: (record) => formatCurrency(record.minimumInvestmentSize),
  },
  {
    disableSortBy: true,
    id: 'capitalLockUpPreference',
    Header: 'CAPITAL LOCKUP PREFERENCE',
    title: 'CAPITAL LOCKUP PREFERENCE',
    accessor: (record) => {
      return (
        record.capitalLockUpPreference && (
          <TagGroup
            items={record.capitalLockUpPreference.split(',')}
            maxLength={2}
          />
        )
      )
    },
  },
  {
    id: 'firstLossCapital',
    Header: 'FIRST LOSS CAPITAL',
    title: 'FIRST LOSS CAPITAL',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.firstLossCapital : []}
        />
      )
    },
    accessor: (record) => (record.firstLossCapital ? 'YES' : 'NO'),
  },
  {
    disableSortBy: true,
    id: 'fundAttributes',
    Header: 'FUND ATTRIBUTES',
    title: 'FUND ATTRIBUTES',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.fundAttributes : []}
        />
      )
    },
    accessor: (record) => {
      return (
        record.fundAttributes && (
          <TagGroup items={record.fundAttributes.split(',')} maxLength={2} />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'investmentVehicle',
    Header: 'INVESTMENT VEHICLE',
    title: 'INVESTMENT VEHICLE',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.investmentVehicle : []}
        />
      )
    },
    accessor: (record) => {
      return (
        record.investmentVehicle && (
          <TagGroup items={record.investmentVehicle.split(',')} maxLength={2} />
        )
      )
    },
  },
  {
    disableSortBy: true,
    id: 'fundDomicileInterest',
    Header: 'FUND DOMICILE INTEREST',
    title: 'FUND DOMICILE INTEREST',
    Filter: (table) => {
      return (
        <SelectFilter
          column={table.column}
          options={filterOptions ? filterOptions.fundDomicileInterest : []}
        />
      )
    },
    accessor: (record) => {
      return (
        record.fundDomicileInterest && (
          <TagGroup
            items={record.fundDomicileInterest.split(',')}
            maxLength={2}
          />
        )
      )
    },
  },
  {
    id: 'createdAt',
    Header: 'DATE ADDED TO SCHEDULE',
    title: 'DATE ADDED TO SCHEDULE',
    accessor: (record) =>
      record.createdAt && moment(record.createdAt).format('MMM DD YYYY'),
  },
  {
    id: 'percentageBooked',
    Header: '% OF SCHEDULE BOOKED',
    title: '% OF SCHEDULE BOOKED',
    accessor: (record) => formatPercent(record.percentageBooked),
  },
  {
    id: 'country',
    Header: 'COUNTRY',
    title: 'COUNTRY',
    accessor: (record) => record.country,
  },
  {
    id: 'city',
    Header: 'CITY',
    title: 'CITY',
    accessor: (record) => record.city,
  },
]
