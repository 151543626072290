import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import ErrorBoundary from '~/components/ErrorBoundary'
import AppLayout from '~/components/layouts/AppLayout'
import SideBar from '~/components/SideBar'
import TopBar from '~/components/TopBar'
import TopTabs from '~/components/TopTabs'
import AuthenticatedAppProvider from '~/lib/AuthenticatedAppProvider'
import PresentationNotificationList from './community/presentations/PresentationNotificationList'
import MeetingRatingContainer from './MeetingRatingContainer'
import ReturnApprovalContainer from './ReturnApprovalContainer'

export default function AppChrome({
  children,
  hideSidebar,
  hideTopBar,
  tabs,
  blockAccess,
  preview,
  initializeMessages,
}) {
  const { showMeetingRatings } = useFlags()

  return (
    <AuthenticatedAppProvider initializeMessages={initializeMessages}>
      {!hideTopBar && (
        <>
          {showMeetingRatings && <MeetingRatingContainer />}
          <ReturnApprovalContainer />
        </>
      )}
      <AppLayout
        className="min-h-screen bg-grey-100"
        contentClassName={hideSidebar ? undefined : 'md:p-8'}
        sidebar={!hideSidebar && <SideBar />}
        topBar={
          <>
            <PresentationNotificationList />
            {!hideTopBar && (
              <>
                <TopBar />
                <TopTabs>{tabs}</TopTabs>
              </>
            )}
          </>
        }
      >
        <ErrorBoundary>{blockAccess ? preview : children}</ErrorBoundary>
      </AppLayout>
    </AuthenticatedAppProvider>
  )
}

AppChrome.propTypes = {
  children: PropTypes.node.isRequired,
  hideSidebar: PropTypes.bool,
  hideTopBar: PropTypes.bool,
  tabs: PropTypes.node,
  blockAccess: PropTypes.bool,
  preview: PropTypes.node,
  initializeMessages: PropTypes.bool,
}
