import * as React from 'react'
import PropTypes from 'prop-types'
import join from 'lodash/join'
import map from 'lodash/map'
import * as api from '~/api'
import MandatePreviewList from '~/components/MandatePreviewList'

const ContactProfileMandates = ({ mandates }) => {
  const getMandates = React.useCallback(() => {
    const mandateIds = map(mandates, 'id')
    return api.discovery.mandates({
      filter: [`mandateId in ${join(mandateIds, ',')}`],
    })
  }, [mandates])

  return <MandatePreviewList getMandates={getMandates} />
}

ContactProfileMandates.propTypes = {
  mandates: PropTypes.arrayOf(PropTypes.object),
}

export default ContactProfileMandates
