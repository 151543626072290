import isNil from 'lodash/isNil'
import moment from 'moment'
import { api as http } from '~/api/services'
import { FETCH_ONLINE_NOTIFICATION_MEETING } from '~/constants/types'
import {
  CHANNEL_MEMBER_CONSUMPTION_INDEX,
  CONVERSATIONS_COUNT_ERROR,
  CONVERSATIONS_COUNT_FETCHED,
  CONVERSATIONS_ERROR,
  CONVERSATIONS_FETCHED,
  DESTROY_TWILIO_CLIENT,
  FETCHING_CONVERSATIONS,
  FETCHING_CONVERSATIONS_COUNT,
  FETCH_CONVERSATIONS,
  FETCH_CONVERSATIONS_COUNT,
  INITIALIZE_TWILIO_CLIENT,
  TWILIO_MESSAGE_ADDED,
} from '~/constants/types/messages'

export const getMessageAccessToken = () => http.get('/messaging/token')

export const sendMessage = (companyEventID, sendMessageRequest) =>
  http.post(`/messaging/toSchedule/${companyEventID}`, sendMessageRequest)

export const sendMessageToConversation = (conversationID, message) =>
  http.post(`/messaging/${conversationID}`, { messageBody: message })

export const sendAttachmentToConversation = (
  conversationID,
  fileName,
  base64File
) =>
  http.post(`/messaging/${conversationID}/attachments`, {
    fileName,
    base64MediaContent: base64File,
  })

export const getConversation = (
  conversationID,
  withDetails = false,
  withMessages = false
) =>
  http.get(
    `/messaging/${conversationID}?details=${withDetails}&includeMessages=${withMessages}`
  )

export const getMeetingConversation = (meetingID, withDetails = false) =>
  http.get(`/messaging/requests/${meetingID}?details=${withDetails}`)

export const getMeetingConversationID = (meetingID) =>
  http.get(`/messaging/requests/${meetingID}/conversation`)

export const fetchUpcomingOnlineNotification = (contactId) => (dispatch) =>
  http
    .get(`/messaging/notification?contactId=${contactId}`)
    .then((response) => {
      if (response.data.result !== false) {
        dispatch({
          type: FETCH_ONLINE_NOTIFICATION_MEETING,
          payload: response.data.result,
        })
      }
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error)
    })

export const fetchConversationCount = () => (dispatch) => {
  dispatch({
    type: FETCHING_CONVERSATIONS_COUNT,
  })

  http
    .post('/messaging/conversations/count', {})
    .then((response) => {
      const count = response.data.result
      dispatch({
        type: FETCH_CONVERSATIONS_COUNT,
        payload: count,
      })
    })
    .catch(() => {
      dispatch({
        type: CONVERSATIONS_COUNT_ERROR,
      })
    })
    .finally(() => {
      dispatch({
        type: CONVERSATIONS_COUNT_FETCHED,
      })
    })
}

export const fetchConversations =
  (client, searchTerm, pagination) => (dispatch) => {
    dispatch({
      type: FETCHING_CONVERSATIONS,
    })

    http
      .post('/messaging/conversations', {
        searchTerm: isNil(searchTerm)
          ? null
          : {
              searchValue: searchTerm,
            },
        pagination: isNil(pagination)
          ? {
              current: 1,
              pageSize: 7,
            }
          : pagination,
      })
      .then((response) => {
        const channels = response.data.result
        dispatch({
          type: FETCH_CONVERSATIONS,
          payload: channels,
        })
      })
      .catch(() => {
        dispatch({
          type: CONVERSATIONS_ERROR,
        })
      })
      .finally(() => {
        dispatch({
          type: CONVERSATIONS_FETCHED,
        })
      })
  }

export const getConversations = () =>
  http.post('/messaging/conversations', {
    searchTerm: null,
    pagination: {
      current: 1,
      pageSize: 7,
    },
  })

export const getArchivedMessages = (search, pageNumber) =>
  http.post('/messaging/archives', {
    searchTerm: isNil(search)
      ? null
      : {
          searchValue: search,
        },
    pagination: {
      current: isNil(pageNumber) ? 1 : pageNumber,
      pageSize: 7,
    },
  })

export const getMeetingRequests = (search, pageNumber) =>
  http.post('/messaging/requests', {
    searchTerm: isNil(search) ? null : { searchValue: search },
    pagination: { current: isNil(pageNumber) ? 1 : pageNumber, pageSize: 7 },
  })

export const initializeTwilioClient =
  (contactId, twilioToken) => (dispatch, getState) => {
    const token = twilioToken

    const { Client } = require('@twilio/conversations')

    if (isNil(token)) {
      return
    }

    Client.create(token).then((client) => {
      dispatch({
        type: INITIALIZE_TWILIO_CLIENT,
        payload: { client, contactId },
      })
      dispatch(fetchConversations(client))
      client.on('messageAdded', (x) => {
        const {
          messaging: { channels },
        } = getState()
        const messageConversationID = x.attributes.ConversationID
        const currentChannel = channels.find(
          (x) => x.conversationID === messageConversationID
        )
        if (isNil(currentChannel)) {
          if (
            contactId !== parseInt(x.author, 10) &&
            moment().subtract(30, 'second').isAfter(moment.utc(x.timestamp))
          ) {
            dispatch(fetchConversations(client))
          }
        } else {
          const authorContact = currentChannel.contacts.find(
            (c) => c.contactId === parseInt(x.author, 10)
          )
          x.authorContact = authorContact
          dispatch({
            type: TWILIO_MESSAGE_ADDED,
            payload: x,
          })
        }
      })

      client.on('memberUpdated', (x) => {
        dispatch({
          type: CHANNEL_MEMBER_CONSUMPTION_INDEX,
          payload: {
            channelSID: x.member.channel.sid,
            contactID: parseInt(x.member.identity, 10),
            index: x.member.lastConsumedMessageIndex,
          },
        })
      })

      client.on('tokenAboutToExpire', () => {
        getMessageAccessToken(contactId).then((response) => {
          client.updateToken(response.data.message)
        })
      })

      client.on('tokenExpired', () => {
        console.log('token expired')
      })
    })
  }

export const destroyTwilioClient = () => (dispatch) => {
  dispatch({
    type: DESTROY_TWILIO_CLIENT,
  })
}

export const getColleaguesToAdd = (conversationId) =>
  http.get(`/messaging/${conversationId}/colleagues`)

export const addColleaguesToConversation = (
  conversationId,
  contactIDs,
  startNew,
  messageBody = null
) =>
  http.post(
    `/messaging/${conversationId}/users?startNew=${startNew}${
      isNil(messageBody) ? '' : `&message=${messageBody}`
    }`,
    {
      contactIDs,
    }
  )

export const leaveConversation = (conversationID) =>
  http.delete(`/messaging/${conversationID}/users`)

export const getContactsForCompose = (searchTerm = '') =>
  http.get(`/messaging/compose/contacts?s=${searchTerm}`)

export const getContactsForSearch = (searchTerm = '') =>
  http.get(`/contacts/search?q=${searchTerm}`)

export const getCompanyContactsForCompose = (companyID, eventID) =>
  http.get(
    `/messaging/compose/company/${companyID}/contacts${
      isNil(eventID) ? '' : `?eventId=${eventID}`
    }`
  )

export const composeMessage = (
  contactIDs,
  companyID,
  message,
  eventId,
  mandateId,
  fundId,
  campaignApplicationId
) =>
  http.post(
    `/messaging/compose${isNil(eventId) ? '' : `?eventId=${eventId}`}`,
    { contactIDs, companyID, message, mandateId, fundId, campaignApplicationId }
  )

export const getMeetingMessage = (meetingId) =>
  http.get(`messaging/followup/${meetingId}`)

export const sendFollowUpMessage = (meetingId, messageBody) =>
  http.post(`messaging/followup/${meetingId}`, {
    messageTypeID: 1,
    messageBody,
  })

export const sendMeetingMessage = (meetingId, messageBody) =>
  http.post(`messaging/followup/${meetingId}`, {
    messageTypeID: 0,
    messageBody,
  })

export const replyToMeetingMessage = (meetingId, messageBody) =>
  http.post(`messaging/toMeeting/${meetingId}`, {
    messageBody,
  })

export const downloadDocument = (link) =>
  http.get(`download?link=${encodeURIComponent(link)}`)
//export const downloadDocument = link => http.get(`download/"hello"`);

export const searchConversation = (conversationID, searchTerm) =>
  http.get(`messaging/search/${conversationID}?s=${searchTerm}`)

export const getMessages = (conversationID, upToMessageID, numberToTake) =>
  http.get(
    `messaging/${conversationID}/messages?upto=${upToMessageID}&take=${numberToTake}`
  )

export const getConversationValidation = (conversationID) =>
  http.get(`messaging/${conversationID}/validation`)
