import { Component } from 'react'
import PropTypes from 'prop-types'
import { faArrowDown, faArrowUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isNaN from 'lodash/isNaN'
import numeral from 'numeral'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchPortfolioList } from '~/actions/portfolio'
import ContextTable from '../ContextTable/ContextTable'

class PortfolioTable extends Component {
  static propTypes = {
    fetchPortfolioList: PropTypes.func.isRequired,
    refreshData: PropTypes.bool,
    portfolioList: PropTypes.array.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
  }

  pushFunction = (record) => {
    const {
      history: { push },
      location: { pathname },
    } = this.props
    push(`${pathname}/${record.portfolioId}`, {
      portfolioName: record.name,
      investment: record.initialInvestment,
      currentValue: record.currentInvestment,
    })
  }

  renderChange = ({ change }) => {
    if (change === 0) return '-'
    else
      return (
        <span
          className={` cc-body-text
          ${change > 0 ? 'cc-text-color-success' : 'cc-text-color-danger'}
        `}
        >
          {numeral(change).format('+0.0% ')}
          <FontAwesomeIcon
            style={{ width: '16px', height: '16px', marginLeft: '4px' }}
            icon={change > 0 ? faArrowUp : faArrowDown}
          />
        </span>
      )
  }
  renderInitialInvestment = ({ initialInvestment }) => {
    if (isNaN(initialInvestment)) return '-'
    else return numeral(initialInvestment).format('$ 0,0.00')
  }

  renderCurrentInvestment = ({ currentInvestment }) => {
    if (isNaN(currentInvestment)) return '-'
    else return numeral(currentInvestment).format('$ 0,0.00')
  }

  getPortofolioColumns = () => [
    {
      key: '1',
      title: 'PORTOFOLIO NAME',
      dataIndex: 'name',
      align: 'left',
      width: '25%',
    },
    {
      key: '2',
      title: 'INVESTMENT',
      render: this.renderInitialInvestment,
      align: 'right',
      width: '25%',
    },
    {
      key: '3',
      title: 'CURRENT VALUE',
      render: this.renderCurrentInvestment,
      align: 'right',
      width: '25%',
    },
    {
      key: '4',
      title: 'CHANGE',
      render: this.renderChange,
      align: 'right',
      width: '25%',
    },
  ]

  render() {
    const { portfolioList } = this.props
    return (
      <ContextTable
        key="id"
        dataSource={portfolioList.result}
        pagination={portfolioList}
        fetchMethod={this.props.fetchPortfolioList}
        columns={this.getPortofolioColumns()}
        scroll={{ x: 1500 }}
        tableTitle="Portfolios"
        refreshData={this.props.refreshData}
        clickEvent={(record) => this.pushFunction(record)}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  portfolioList: state.portfolio.portfolioList,
})

const mapDispatchToProps = {
  fetchPortfolioList,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PortfolioTable))
