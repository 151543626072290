import * as React from 'react'
import { useDispatch } from 'react-redux'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useMatch,
  useNavigate,
} from 'react-router-dom-v5-compat'
import { changeCompany, updateOnboarded } from '~/actions/auth'
import ErrorBoundary from '~/components/ErrorBoundary'
import AppLayout from '~/components/layouts/AppLayout'
import Loading from '~/components/Loading'
import { useCompaniesToOnboard, useOnboardingOptions } from './hooks'
import { OnboardingContextProvider } from './OnboardingContext'
import { SelectCompany } from './pages/SelectCompany'
import { getWorkflow } from './workflows'
import './Onboarding.css'

export function Onboarding() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    data,
    isLoading: isLoadingCompanies,
    refreshCompanies,
  } = useCompaniesToOnboard()
  const { data: options, isLoading: isLoadingOptions } = useOnboardingOptions()

  const { workflow, currentStep, selectedCompany, fallback, selectWorkflow } =
    useWorkflow(data)

  const selectCompany = React.useCallback(
    (companyId, navigateOptions) => {
      dispatch(changeCompany(companyId)).then(() => {
        const { workflow, company } = selectWorkflow(companyId)
        const nextPath = workflow.getStartingPagePath({ company })
        navigate(`${companyId}/${nextPath}`, navigateOptions)
      })
    },
    [dispatch, navigate, selectWorkflow]
  )

  const selectCfnCompany = React.useCallback(
    (companyId) => {
      // Switching to a company where the user's role is "CFN" will show the
      // old onboarding flow.
      dispatch(changeCompany(companyId))
    },
    [dispatch]
  )

  const completeOnboarding = React.useCallback(() => {
    dispatch(updateOnboarded())
    refreshCompanies()
    navigate('/')
  }, [dispatch, refreshCompanies, navigate])

  const goBack = () => {
    if (!workflow) {
      return
    }
    const previousPath = workflow.getPreviousPagePath({
      currentPage: currentStep,
      company: selectedCompany,
    })

    if (previousPath) {
      navigate(`/onboard/${selectedCompany.companyID}/${previousPath}`)
    } else {
      // If we don't know where to go back to, go to the select company page.
      navigate('/onboard')
    }
  }

  const goToNext = () => {
    if (!workflow) {
      return
    }
    const nextPath = workflow.getNextPagePath({
      currentPage: currentStep,
      company: selectedCompany,
    })
    if (nextPath) {
      navigate(`/onboard/${selectedCompany.companyID}/${nextPath}`)
    } else {
      // If nextPath isn't defined, we've reached the end of the workflow.
      completeOnboarding()
    }
  }

  const isLoading = isLoadingCompanies || isLoadingOptions

  if (isLoading) {
    return (
      <RootLayout>
        <div
          style={{ height: '50%' }}
          className="flex items-center justify-center"
        >
          <Loading />
        </div>
      </RootLayout>
    )
  }

  return (
    <OnboardingContextProvider
      options={options}
      onBack={goBack}
      onNext={goToNext}
    >
      <Routes>
        <Route element={<RootLayout />}>
          <Route
            index
            element={
              <SelectCompany
                companies={data.nonCfnCompanies}
                cfnCompanies={data.cfnCompanies}
                skipOnboarding={completeOnboarding}
                onSelectCfn={selectCfnCompany}
                onSelectCompany={selectCompany}
              />
            }
          />
          {workflow && <Route path=":companyId">{workflow.routes}</Route>}
          {fallback}
        </Route>
      </Routes>
    </OnboardingContextProvider>
  )
}

function RootLayout({ children = <Outlet /> }) {
  return (
    <AppLayout className="min-h-screen bg-grey-50">
      <ErrorBoundary>{children}</ErrorBoundary>
    </AppLayout>
  )
}

function useWorkflow(data) {
  const match = useMatch('/onboard/:companyId/:step')
  const companyId = match ? parseInt(match.params.companyId, 10) : null
  const [workflow, setWorkflow] = React.useState(null)
  const [fallback, setFallback] = React.useState(null)

  const findCompany = React.useCallback(
    (companyId) => {
      if (typeof companyId !== 'number') {
        companyId = parseInt(companyId, 10)
      }
      return data.nonCfnCompanies.find(
        (company) => company.companyID === companyId
      )
    },
    [data]
  )

  const selectedCompany = React.useMemo(() => {
    if (!data || !companyId) {
      return null
    }
    return findCompany(companyId)
  }, [data, companyId, findCompany])

  const selectWorkflow = React.useCallback(
    (companyId) => {
      const company = companyId === null ? {} : findCompany(companyId)
      const workflow = getWorkflow(company.category)
      setWorkflow(workflow)
      return { workflow, company }
    },
    [findCompany]
  )

  React.useEffect(() => {
    if (!data) {
      return
    }

    if (
      companyId &&
      data.nonCfnCompanies.some((c) => c.companyID === companyId)
    ) {
      selectWorkflow(companyId)
    } else {
      setWorkflow(null)
    }
    setFallback(<Route path="*" element={<Navigate to="/onboard" replace />} />)
  }, [companyId, data, selectWorkflow])

  return {
    workflow,
    currentStep: match?.params.step,
    selectedCompany,
    fallback,
    selectWorkflow,
  }
}
