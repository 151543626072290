import * as React from 'react'
import isEmpty from 'lodash/isEmpty'
import { useHistory, useLocation } from 'react-router-dom'

export function useFundWizardModal(key = 'showFundWizard') {
  const location = useLocation()
  const { push } = useHistory()

  const visible = location.state && location.state[key]

  const show = React.useCallback(() => {
    const state = { ...location.state }
    state[key] = true
    push({
      ...location,
      state,
    })
  }, [location, push])

  const hide = React.useCallback(() => {
    // Once we're done with these, we don't want them to hang around in
    // the location state.
    const nextState = { ...location.state }
    delete nextState[key]
    delete nextState.currentStep

    push({
      ...location,
      state: isEmpty(nextState) ? undefined : nextState,
    })
  }, [location, push])

  return React.useMemo(
    () => ({
      visible,
      show,
      hide,
    }),
    [visible, show, hide]
  )
}
