import * as React from 'react'
import PropTypes from 'prop-types'
import { useObjectMemo } from '@context365/hooks'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'

export const Context = React.createContext(null)

export function MessageSearchProvider({ children, messages, scrollRef }) {
  const [searchText, setSearchText] = React.useState(null)
  const [currentIndex, setCurrentIndex] = React.useState(0)

  const messageRefs = React.useRef(new Map())

  const results = React.useMemo(() => {
    if (isEmpty(searchText) || isEmpty(messages)) {
      return []
    }

    return orderBy(messages, 'timestamp', 'desc')
      .filter((message) => !message.isSystemGenerated)
      .flatMap((message) => {
        const search = searchText.toLowerCase()
        const body = message.body.toLowerCase()
        const indices = []
        let startIndex = 0
        let index
        while ((index = body.indexOf(search, startIndex)) > -1) {
          indices.push(index)
          startIndex = index + searchText.length
        }
        return indices
          .map((index) => ({
            messageID: message.messageID,
            startIndex: index,
          }))
          .reverse()
      })
      .map((result, resultIndex) => ({ ...result, resultIndex }))
  }, [messages, searchText])

  React.useEffect(() => {
    if (isEmpty(searchText) || isEmpty(results)) {
      return
    }

    const scrollParent = scrollRef.current
    const currentMessageId = results[currentIndex].messageID
    const messageNode = messageRefs.current.get(currentMessageId)
    if (!scrollParent || !messageNode) {
      return
    }

    const messageCenter = messageNode.offsetTop + messageNode.offsetHeight / 2
    const scrollPosition =
      messageCenter - scrollParent.getBoundingClientRect().height / 2

    scrollParent.scrollTo({
      top: scrollPosition,
      behavior: 'smooth',
    })
  }, [searchText, results, currentIndex, scrollRef])

  const registerMessage = React.useCallback((messageId, node) => {
    messageRefs.current.set(messageId, node)
  }, [])

  const search = React.useCallback(
    (text) => {
      if (!text) {
        text = null
      }

      if (text === searchText) {
        return
      }

      setSearchText(text)
      setCurrentIndex(0)
    },
    [searchText]
  )

  const goToNextResult = React.useCallback(() => {
    setCurrentIndex((currentIndex) => (currentIndex + 1) % results.length)
  }, [results])

  const goToPreviousResult = React.useCallback(() => {
    setCurrentIndex(
      (currentIndex) => (currentIndex - 1 + results.length) % results.length
    )
  }, [results])

  const context = useObjectMemo({
    searchText,
    currentIndex,
    results,
    registerMessage,
    search,
    goToNextResult,
    goToPreviousResult,
  })

  return <Context.Provider value={context}>{children}</Context.Provider>
}

MessageSearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      messageID: PropTypes.number.isRequired,
      body: PropTypes.string.isRequired,
      isSystemGenerated: PropTypes.bool.isRequired,
      timestamp: PropTypes.string.isRequired,
    })
  ),
  scrollRef: PropTypes.object.isRequired,
}

export function useMessageSearch() {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error(
      'useMessageSearch must be used within a MessageSearchProvider'
    )
  }
  return context
}

export function useMessageSearchResults(messageId) {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error(
      'useMessageSearchResults must be used within a MessageSearchProvider'
    )
  }
  const { searchText, results, currentIndex, registerMessage } = context

  return {
    searchText,
    searchResults: results
      .filter((result) => result.messageID === messageId)
      .map((result) => ({
        start: result.startIndex,
        end: result.startIndex + searchText.length,
        isCurrent: result.resultIndex === currentIndex,
      })),
    register: React.useCallback(() => {
      return {
        ref: (node) => {
          registerMessage(messageId, node)
        },
      }
    }, [registerMessage, messageId]),
  }
}
