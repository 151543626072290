import * as React from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@context365/alert'
import { Button } from '@context365/button'
import { Launch } from '@context365/icons'
import { useFlags } from 'launchdarkly-react-client-sdk'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom-v5-compat'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import { api as http } from '~/api/services'
import {
  DiscoverController,
  DiscoverFilters,
  DiscoverTabs,
  extractQuestionGroups,
} from '~/components/Discover'
import { getDiscoverServiceProvidersFilterComponent } from '~/components/DiscoverFilters'
import SelectFundFilter from '~/components/DiscoverFilters/SelectFundFilter'
import {
  AddServiceProviderToListModal,
  useAddToListModal,
} from '~/components/DiscoverListModals'
import DiscoverServiceProviderResult from '~/components/DiscoverServiceProviderResult/DiscoverServiceProviderResult'
import { ManageFunds } from '~/constants/userAccess'
import DiscoverMapView from '../DiscoverMapView/DiscoverMapView'
import CompanyFundsContext from './companyFundsContext'
import './DiscoverContainer.less'

const DiscoverServiceProvidersContainer = ({
  tab,
  resultId,
  fundId,
  clearResult,
}) => {
  const { Track } = useTracking({ page: 'DiscoverServiceProviders' })
  const { role } = useSelector((state) => state.auth)
  const { activities } = useSelector((state) => state.userAccess)
  const hasManageFundsAccess = activities.includes(ManageFunds)

  const { communityModule } = useFlags()

  const addServiceProvidersToListModal = useAddToListModal()

  const funds = React.useContext(CompanyFundsContext)
  const [selectedFundId, setSelectedFundId] = React.useState(
    !isNil(fundId) && funds.find((x) => x.fundId === fundId)
      ? fundId
      : isEmpty(funds)
      ? null
      : funds[0].fundId
  )

  const modifyFilterQuery = (filters) => {
    if (!isNil(resultId)) {
      filters.push(`companyId eq ${[resultId]}`)
    }
  }

  const representedFund = find(funds, (f) => f.fundId === selectedFundId)
  const representedFundName = representedFund?.name
  const representedFundContacts = representedFund?.contacts

  return communityModule ? (
    <Alert status="info">
      This content has a new home! To find Service Providers please check out
      the Community.
      <Button
        as={Link}
        variant="link"
        to="/community/companies?filter=category%3DService%20Provider"
        iconLeft={<Launch />}
      >
        Go to Community
      </Button>
    </Alert>
  ) : (
    <Track>
      <AddServiceProviderToListModal
        serviceProviders={addServiceProvidersToListModal.items}
        visible={addServiceProvidersToListModal.visible}
        onClose={addServiceProvidersToListModal.hide}
      />
      <DiscoverController
        getResults={(params) => api.discovery.serviceProviders(params)}
        modifyFilterQuery={modifyFilterQuery}
        refetchKeys={{ resultId }}
        category="service providers"
        tab={tab}
        isMatchById={(serviceProvider, companyId) =>
          serviceProvider.companyId === companyId
        }
        fundSelect={
          hasManageFundsAccess && (
            <SelectFundFilter
              selectedFund={selectedFundId}
              funds={funds}
              onChangeFund={(fundId) => {
                setSelectedFundId(fundId)
              }}
            />
          )
        }
        getFilters={() => http.get('/discover/serviceProviders/filters')}
        renderFilters={({ filters }) => (
          <DiscoverFilters
            filters={filters}
            getFilterGroups={extractQuestionGroups('Trending', 'Lists')}
            hideToggleMatches={role !== 'Manager'}
            renderFilterControl={getDiscoverServiceProvidersFilterComponent}
          />
        )}
        results={
          <DiscoverTabs
            renderResultCard={({ item, selectedId, onChangeSelectedId }) => (
              <DiscoverServiceProviderResult
                serviceProvider={item}
                selectedFund={selectedFundId}
                representedFund={representedFundName}
                selectedCompanyId={selectedId}
                onChangeSelectedCompanyId={onChangeSelectedId}
                onAddServiceProviderToList={addServiceProvidersToListModal.show}
              />
            )}
            renderMapView={({ activeFilters, searchText, showOnlyMatched }) => (
              <DiscoverMapView
                filter={activeFilters}
                searchTerm={searchText}
                showMatched={showOnlyMatched}
                resultId={resultId}
                selectedFund={selectedFundId}
                representedFund={representedFundName}
                fundContacts={representedFundContacts}
              />
            )}
          />
        }
        clearResult={clearResult}
      />
    </Track>
  )
}

DiscoverServiceProvidersContainer.propTypes = {
  tab: PropTypes.string,
  resultId: PropTypes.object,
  fundId: PropTypes.number,
  clearResult: PropTypes.func,
}

export default DiscoverServiceProvidersContainer
