export const ENVIRONMENT_NAME =
  process.env.REACT_APP_ENVIRONMENT_NAME || 'Unknown'

export const StorageKeys = {
  accessToken: 'access_token',
  refreshToken: 'refresh_token',
  claims: 'claims',
  contact: 'contact',
  company: 'company',
  role: 'role',
  logout: 'logout',
  deviceToken: 'deviceToken',
  compareFunds: 'context365:compare_funds',
}

export const TrackingAnalytics = {
  enabled: process.env.REACT_APP_INIT_ANALYTICS === '1',
}

export const BASE_URL = process.env.REACT_APP_BASE_URL
export const TIMEOUT = parseInt(process.env.REACT_APP_TIMEOUT || '10000', 10)
export const PLATFORM = 'Web'

export const GEOCODING_API_URL =
  'https://maps.googleapis.com/maps/api/geocode/json'
export const GEOCODING_API_KEY = 'AIzaSyCp-OEZaqzxGK1gNCpr7-S8YlygW5Kgi0I'

export const FUND_PERFORMANCE_API_BASE_URL =
  process.env.REACT_APP_ALPHASERVE_API_URL
export const FUND_PERFORMANCE_API_TOKEN = 'alphaserve_token'

export const DEFAULT_AUDIO_INPUT = 'defaultAudioInput'
export const DEFAULT_AUDIO_OUTPUT = 'defaultAudioOutput'
export const DEFAULT_VIDEO_INPUT = 'defaultVideoInput'
export const DEFAULT_AUDIO_ON = 'defaultAudioOn'
export const DEFAULT_VIDEO_ON = 'defaultVideoOn'
export const SETTINGS_SAVED = 'settingsSaved'

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const SENTRY_SAMPLE_RATE =
  parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE) || 1.0
export const SENTRY_RELEASE_ID = process.env.REACT_APP_SENTRY_RELEASE_ID || '0'

export const FeatureFlags = {
  enabled: process.env.REACT_APP_FEATURE_FLAGS_ENABLED === '1',
  clientSideId: process.env.REACT_APP_FEATURE_FLAGS_CLIENT_SIDE_ID,
}
export const SEATS_PUBLIC_KEY = process.env.REACT_APP_SEATS_PUBLIC_KEY || ''

export const ResponsiveWebEnabled =
  process.env.REACT_APP_RESPONSIVE_WEB_ENABLED === '1'

export const CloudMeetUrl = process.env.REACT_APP_CLOUDMEET_URL || ''

export const NOTIFICATIONS_HUB_URL = `${BASE_URL}/notifications-hub`

export const FirebaseOptions = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FCM_DATABASE_URL,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID,
  measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID,
}

export const VAPID_KEY = process.env.REACT_APP_FCM_VAPID_KEY

export const MarketingContactPage = process.env.REACT_APP_MARKETING_CONTACT_URL
export const MarketingEventPage = process.env.REACT_APP_MARKETING_CONTACT_URL

export const HelpEmail = process.env.REACT_APP_HELP_EMAIL
export const ReturnsEmail = process.env.REACT_APP_RETURNS_EMAIL
