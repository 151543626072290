import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Input } from '@context365/forms'
import { useDiscoverLists } from '~/hooks/discoverLists'

export default function CreateListForm({
  title = 'Create List',
  targetNames,
  targetType,
  onCreate,
  onDismiss,
}) {
  const { discoverLists } = useDiscoverLists()
  const [listName, setListName] = React.useState('')
  const [validationError, setValidationError] = React.useState(null)

  function validateName(name) {
    if (!name) {
      return 'List name is required.'
    }
    if (discoverLists.some((list) => list.name === name)) {
      return 'List names must be unique.'
    }
    return undefined
  }

  function createList(event) {
    event.preventDefault()

    const validationError = validateName(listName)
    if (validationError) {
      setValidationError(validationError)
    } else {
      onCreate(listName)
    }
  }

  return (
    <>
      {title && (
        <div className="type-body-semibold-md py-5 px-6 border-b">{title}</div>
      )}
      <form onSubmit={createList}>
        <div className="p-6 pb-8">
          {targetNames && (
            <div className="type-body-semibold-md mb-4">
              Create new list to add {targetNames} to.
            </div>
          )}
          <Input
            label="List Name"
            required
            name="newListName"
            placeholder="List"
            errorMessage={validationError}
            value={listName}
            onChange={(e) => setListName(e.target.value)}
          />
        </div>
        <div className="flex justify-end p-4 space-x-4 shadow-0">
          <Button type="button" onClick={onDismiss}>
            Cancel
          </Button>
          <Button variant="filled" type="submit" disabled={!listName}>
            Create List
            {targetNames && targetType && (
              <span>&nbsp;&amp; Add {targetType}</span>
            )}
          </Button>
        </div>
      </form>
    </>
  )
}

CreateListForm.propTypes = {
  title: PropTypes.string,
  targetNames: PropTypes.string,
  targetType: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
}
