import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'

const HeaderField = ({ title, subtitle }) => (
  <div>
    <div className="type-body-semibold-md block">{title}</div>
    {!isNil(subtitle) && (
      <div className="type-body-regular-sm block mt-2">{subtitle}</div>
    )}
  </div>
)

HeaderField.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default HeaderField
