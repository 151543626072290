import * as React from 'react'
import PropTypes from 'prop-types'
import startOfDay from 'date-fns/startOfDay'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import orderBy from 'lodash/orderBy'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import useAuth from '~/hooks/useAuth'
import {
  ConversationSystemMessage,
  MeetingSystemMessage,
  UserMessage,
} from './Message'
import { format, parseDate } from './utils/dateFormatters'
import './Messages.css'

export default function ConversationMessages({
  conversationId,
  messages,
  conversationType,
}) {
  const { trackEvent } = useTracking({
    component: 'ConversationMessages',
    conversationId,
  })
  const { contact } = useAuth()
  const messagesByDay = useMessagesGroupedByDay(messages)

  function downloadMessageAttachment(message) {
    if (!message.isAttachment) {
      return
    }

    trackEvent({
      eventName: 'click',
      element: 'download',
      messageId: message.messageID,
      mediaId: message.mediaSid,
    })
    api.messages
      .downloadAttachment(conversationId, message.mediaSid)
      .then((url) => window.open(url, '_blank'))
  }

  return (
    <div className="p-4">
      {messagesByDay.map(({ day, messages }) => (
        <div key={day.getTime()}>
          <DayHeader day={day} />
          {messages.map((message, i) => {
            if (message.isSystemGenerated) {
              if (message.conversationActionMetadata) {
                return (
                  <ConversationSystemMessage
                    key={message.messageID}
                    timestamp={message.timestamp}
                    metadata={message.conversationActionMetadata}
                  />
                )
              } else if (message.meetingActionMetadata) {
                return (
                  <MeetingSystemMessage
                    key={message.messageID}
                    timestamp={message.timestamp}
                    metadata={message.meetingActionMetadata}
                    conversationType={conversationType}
                  />
                )
              } else {
                return null
              }
            } else {
              const previousMessage = messages[i - 1]
              const previousMessageIsFromSameContact =
                previousMessage &&
                !previousMessage.isSystemGenerated &&
                previousMessage.authorContact?.contactId ===
                  message.authorContact.contactId

              return (
                <UserMessage
                  key={message.messageID}
                  messageID={message.messageID}
                  timestamp={message.timestamp}
                  pending={message.pending}
                  userIsAuthor={
                    contact.contactId === message.authorContact.contactId
                  }
                  authorContact={
                    previousMessageIsFromSameContact
                      ? null
                      : message.authorContact
                  }
                  isAttachment={message.isAttachment}
                  body={message.body}
                  readStatus={message.readStatus}
                  onDownloadAttachment={() =>
                    downloadMessageAttachment(message)
                  }
                />
              )
            }
          })}
        </div>
      ))}
    </div>
  )
}

ConversationMessages.propTypes = {
  conversationId: PropTypes.number.isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      messageID: PropTypes.number.isRequired,
      timestamp: PropTypes.string.isRequired,
      authorContact: PropTypes.object,
      isAttachment: PropTypes.bool,
      isSystemGenerated: PropTypes.bool.isRequired,
      conversationActionMetadata: PropTypes.object,
      meetingActionMetadata: PropTypes.object,
      body: PropTypes.string,
    })
  ).isRequired,
  conversationType: PropTypes.string,
}

function DayHeader({ day }) {
  return (
    <div className="message-day-header">{format.messageGroupDate(day)}</div>
  )
}

function useMessagesGroupedByDay(messages) {
  return React.useMemo(() => {
    const messageGroups = groupBy(messages, (message) =>
      dayFromUTC(message.timestamp)
    )
    const sortedMessageGroups = mapValues(messageGroups, (group) =>
      orderBy(group, 'timestamp', 'asc')
    )
    return Object.entries(sortedMessageGroups)
      .map(([day, messages]) => ({
        day: new Date(parseInt(day, 10)),
        messages,
      }))
      .sort((a, b) => a.day - b.day)
  }, [messages])
}

function dayFromUTC(date) {
  return startOfDay(parseDate.fromUTC(date)).getTime()
}

function Loading() {
  return <div className="flex-1 bg-white" />
}

ConversationMessages.Loading = Loading
