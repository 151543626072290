import { useState } from 'react'
import { Button, IconButton } from '@context365/button'
import { TextArea } from '@context365/forms'
import {
  Add,
  ChevronLeft,
  Close,
  DeleteOutlined,
  EditOutlined,
} from '@context365/icons'
import { PopConfirm } from '@context365/popovers'
import filter from 'lodash/filter'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import { useMandateWizardContext } from '../MandateWizardContext'

const CustomQuestions = ({ isLastStep }) => {
  const {
    goToNextStep: onNextStep,
    goToPreviousStep: onPrevStep,
    customQuestions,
    setCustomQuestions,
    stepNumbers,
    invalidAnswers,
    handleSubmit: onSubmit,
  } = useMandateWizardContext()

  const [editItemText, setEditItemText] = useState('')

  const savedQuestions = filter(customQuestions, (q) => !q.isDraft)
  const draft = find(customQuestions, (q) => q.isDraft)

  const submitDisabled =
    !isEmpty(
      invalidAnswers.filter((a) => stepNumbers.includes(a.stepNumber))
    ) || !isNil(draft)

  const compileQuestions = (allQuestions) => {
    const questions = filter(
      allQuestions,
      (q) => !isNil(q) && (q.isDraft || q.text)
    )
    const sorted =
      questions?.length > 0
        ? sortBy(questions, (q) => [
            q.sequence,
            q.campaignApplicationQuestionId,
          ])
        : []

    //fix any gaps in sequence (happens if a question is deleted)
    const reSorted = forEach(
      sorted,
      (question, index) => (question.sequence = index)
    )
    setCustomQuestions(reSorted)
  }

  const handleTextChange = (text) => {
    draft.text = text
    compileQuestions([...savedQuestions, draft])
  }

  const addDraftQuestion = () => {
    if (draft) {
      draft.isDraft = false
    }
    const newQuestion = {
      isDraft: true,
      sequence: customQuestions?.length ?? 0,
    }
    compileQuestions([...savedQuestions, draft, newQuestion])
  }

  const saveDraftQuestion = () => {
    draft.isDraft = false
    compileQuestions([...savedQuestions, draft])
  }

  const editQuestion = (sequence) => {
    const edit = find(savedQuestions, (q) => q.sequence === sequence)
    const rest = filter(savedQuestions, (q) => q.sequence !== sequence)
    edit.isDraft = true
    edit.isEdit = true
    setEditItemText(edit.text)
    compileQuestions([...rest, edit])
  }

  const deleteQuestion = (sequence) => {
    const rest = filter(savedQuestions, (q) => q.sequence !== sequence)
    compileQuestions(rest)
  }

  const cancelDraft = () => compileQuestions(savedQuestions)

  const cancelEdit = () => {
    if (!draft) return
    draft.text = editItemText //set it back to its previous text if they click cancel while editing
    draft.isEdit = false
    draft.isDraft = false
    setEditItemText('')
    compileQuestions([...savedQuestions, draft])
  }

  return (
    <div>
      <div className="type-body-regular-lg">Custom Questions</div>
      <div className="type-body-regular-sm my-4">
        Feel free to add a few more key questions funds are required to answer.
        Recommended amount: 3-5.
      </div>
      <div>
        {map(savedQuestions, (question) => (
          <div
            key={question.sequence}
            className="flex flex-row py-4 border-b-2 border-primary-5 justify-between items-center"
          >
            <div className="text-secondary-110 type-body-regular-md">
              {question.text}
            </div>
            <div className="flex gap-2 justify-end">
              <IconButton
                label="edit"
                icon={<EditOutlined />}
                variant="link"
                status="secondary"
                disabled={!isNil(draft)}
                onClick={() => editQuestion(question.sequence)}
              />
              <PopConfirm
                message="Are you sure you want to delete this question?"
                onCancel={() => {}} //no need to do anything
                onConfirm={() => deleteQuestion(question.sequence)}
                className="shadow-6"
              >
                <IconButton
                  label="delete"
                  icon={<DeleteOutlined />}
                  variant="link"
                  status="secondary"
                  disabled={!isNil(draft)}
                />
              </PopConfirm>
            </div>
          </div>
        ))}
        <div className="mt-4">
          {draft ? (
            <Button
              variant="link"
              status="secondary"
              iconLeft={<Close />}
              onClick={() => (draft.isEdit ? cancelEdit() : cancelDraft())}
            >
              Cancel
            </Button>
          ) : (
            <Button
              variant="link"
              status="secondary"
              iconLeft={<Add />}
              onClick={() => addDraftQuestion()}
            >
              {isEmpty(savedQuestions) ? 'Add' : 'Add another question'}
            </Button>
          )}
        </div>
        {!isNil(draft) && (
          <>
            <TextArea
              autoFocus
              value={draft.text}
              size="big"
              helpMessage={
                draft.text
                  ? 'Enter saves.'
                  : 'Start typing your question. Enter saves.'
              }
              onChange={(e) => handleTextChange(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  saveDraftQuestion()
                }
              }}
            />
            <div className="flex justify-end">
              <Button size="small" onClick={() => saveDraftQuestion()}>
                Save
              </Button>
            </div>
          </>
        )}
      </div>
      <div className="flex flex-row justify-between mt-8">
        <Button variant="link" iconLeft={<ChevronLeft />} onClick={onPrevStep}>
          Back
        </Button>
        {isLastStep ? (
          <Button
            variant="filled"
            type="submit"
            onClick={onSubmit}
            disabled={submitDisabled}
          >
            Submit
          </Button>
        ) : (
          <Button variant="filled" onClick={onNextStep}>
            Next
          </Button>
        )}
      </div>
    </div>
  )
}

export default CustomQuestions
