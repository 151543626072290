import * as React from 'react'
import { Input } from '@context365/forms'
import { Divider, message } from 'antd'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import * as api from '~/api'
import ArrayField from '~/components/GenericForm/Fields/ArrayField'
import ClientField from '~/components/GenericForm/Fields/ClientField'
import HeaderField from '~/components/GenericForm/Fields/HeaderField'
import LinkField from '~/components/GenericForm/Fields/LinkField'
import TestimonialField from '~/components/GenericForm/Fields/TestimonialField'
import UploadMultipleFileField from '~/components/GenericForm/Fields/UploadMultipleFileField'
import GenericForm from '~/components/GenericForm/GenericForm'
import { ROLE } from '~/constants/roles'

const validator = () =>
  Yup.object().shape({
    media: Yup.array(Yup.string()),
    attachments: Yup.array(Yup.string()),
    linkedIn: Yup.string().nullable(),
    twitter: Yup.string().nullable(),
    youtube: Yup.string().nullable(),
    testimonials: Yup.array(
      Yup.object().shape({
        title: Yup.string().required('Required').typeError('Required'),
        description: Yup.string().required('Required').typeError('Required'),
        authorName: Yup.string().required('Required').typeError('Required'),
        authorCompany: Yup.string().required('Required').typeError('Required'),
        authorTitle: Yup.string().required('Required').typeError('Required'),
      })
    ),
    clients: Yup.array(
      Yup.object().shape({
        name: Yup.string().required('Required').typeError('Required'),
        logoUrl: Yup.string().nullable(),
      })
    ),
  })

const steps = (role) => [
  {
    title: 'Media',
    validator: validator(),
    questions: () => [
      {
        questions: [
          {
            component: HeaderField,
            extraProps: {
              title: `Media`,
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'media',
            component: UploadMultipleFileField,
            isFormField: true,
            extraProps: {
              hint: [
                'File Types: .JPG, .PNG, .BMP, .AVI, .MOV, .MP4, .MPEG4',
                'Max File Size: 500MB',
              ],
              accept: '.jpg, .png, .bmp, .mov, .avi, .mp4, .mpeg4',
              listType: 'picture',
              listLength: 5,
            },
          },
        ],
      },
      { questions: [{ component: Divider }] },
      {
        questions: [
          {
            component: HeaderField,
            extraProps: {
              title: `Attachments`,
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'attachments',
            component: UploadMultipleFileField,
            isFormField: true,
            extraProps: {
              hint: ['File Types: .PDF', 'Max File Size: 5MB'],
              accept: '.pdf',
              listType: 'picture',
              listLength: 5,
            },
          },
        ],
      },
      {
        questions: [
          {
            component: HeaderField,
            extraProps: {
              title: `Social Media`,
            },
          },
        ],
      },
      {
        cols: 4,
        questions: [
          {
            component: Input,
            name: 'linkedIn',
            title: 'LinkedIn',
          },
          {
            component: Input,
            name: 'twitter',
            title: 'Twitter',
          },
          {
            component: Input,
            name: 'youtube',
            title: 'YouTube',
          },
        ],
      },
      {
        questions: [
          {
            component: HeaderField,
            extraProps: {
              title: `Links`,
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'links',
            title: 'Links',
            component: ArrayField,
            extraProps: {
              allowEmpty: true,
              emptyAddText: '+ Add Link',
              subComponent: LinkField,
              subProps: {},
              defaultObject: {
                title: null,
                url: null,
              },
            },
          },
        ],
      },
      {
        questions: [
          {
            isVisible: () => role === ROLE.SERVICE_PROVIDER,
            component: HeaderField,
            extraProps: {
              title: `Testimonials`,
            },
          },
        ],
      },
      {
        questions: [
          {
            isVisible: () => role === ROLE.SERVICE_PROVIDER,
            name: 'testimonials',
            title: 'Testimonials',
            component: ArrayField,
            extraProps: {
              allowEmpty: true,
              emptyAddText: '+ Add Testimonial',
              subComponent: TestimonialField,
              subProps: {},
              defaultObject: {
                title: null,
                description: null,
                authorName: null,
                authorCompany: null,
                authorTitle: null,
              },
            },
          },
        ],
      },
      {
        questions: [
          {
            isVisible: () => role === ROLE.SERVICE_PROVIDER,
            component: HeaderField,
            extraProps: {
              title: `Clients`,
            },
          },
        ],
      },
      {
        questions: [
          {
            isVisible: () => role === ROLE.SERVICE_PROVIDER,
            name: 'clients',
            title: 'Clients',
            component: ArrayField,
            extraProps: {
              allowEmpty: true,
              emptyAddText: '+ Add Client',
              subComponent: ClientField,
              subProps: {},
              defaultObject: {
                test: null,
                logoUrl: null,
              },
            },
          },
        ],
      },
    ],
  },
]

const getInitialValues = () => {
  return new Promise((resolve) => {
    api.settings
      .getCompanyMediaSettings()
      .then((data) => {
        resolve(data)
      })
      .catch(() => {
        resolve(null)
      })
  })
}

const MediaSettings = () => {
  const userRole = useSelector((state) => state.auth.role)
  const [options, setOptions] = React.useState(null)

  const defaultValues = {
    media: [],
    attachments: [],
    linkedIn: null,
    twitter: null,
    youtube: null,
    links: [],
    testimonials: [],
    clients: [],
  }

  React.useEffect(() => {
    api.companies.getCompanyProfileOptions().then((opts) => setOptions(opts))
  }, [])

  const handleSave = (vals, formFinished, doneSaving) => {
    if (formFinished) {
      api.settings
        .saveCompanyMediaSettings(vals)
        .then(() => {
          message.success('Profile saved successfully.')
        })
        .catch(() =>
          message.error(
            'An error occurred while attempting to save your profile.'
          )
        )
        .finally(() => doneSaving())
    } else {
      doneSaving()
    }
  }

  return (
    <GenericForm
      defaultValues={defaultValues}
      getInitialValues={getInitialValues}
      steps={steps(userRole)}
      questionOptions={options}
      onSave={handleSave}
    />
  )
}

MediaSettings.propTypes = {}

export default MediaSettings
