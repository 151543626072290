import { SearchOutlined } from '@ant-design/icons'
import { faArrowDown, faArrowUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, DatePicker, InputNumber, Tooltip } from 'antd'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import moment from 'moment'
import numeral from 'numeral'
import ActionButton from '~/components/ActionButton'
import {
  CCTableActionCell,
  CCTableContactLinkCell,
  CCTableCurrencyCell,
  CCTableDateTimeCell,
  CCTableDollarChangeCell,
  CCTableLambdaCell,
  CCTableLinkCell,
  CCTableNamedProfilePicsCell,
  CCTableProfilePicsCell,
  CCTableTagGroupCell,
  CCTableYesNoCell,
} from '~/components/ContextTable/ContextTable'
import ProfilePictureGroup from '~/components/ProfilePictureGroup'
import ProfilePictureGroupNamed from '~/components/ProfilePictureGroupNamed'
import TagGroup from '~/components/TagGroup'
import { numberFormat } from './formatters'

const { YearPicker, MonthPicker, WeekPicker } = DatePicker

export const transformColumns = (columns, rowSelected) => {
  if (isNil(columns)) return columns

  for (let i = 0; i < columns.length; i++) {
    switch (columns[i].cellType) {
      case CCTableLinkCell:
        transformLinkColumn(columns[i], rowSelected, false)
        break
      case CCTableContactLinkCell:
        transformContactLinkColumn(columns[i], rowSelected)
        break
      case CCTableProfilePicsCell:
        transformProfilePictureColumn(columns[i])
        break
      case CCTableNamedProfilePicsCell:
        transformNamedProfilePictureColumn(columns[i])
        break
      case CCTableTagGroupCell:
        transformTagGroupColumn(columns[i])
        break
      case CCTableActionCell:
        transformActionColumn(columns[i])
        break
      case CCTableDateTimeCell:
        transfromDateTimeColumn(columns[i])
        break
      case CCTableCurrencyCell:
        transformCurrencyColumn(columns[i])
        break
      case CCTableYesNoCell:
        transformYesNoColumn(columns[i])
        break
      case CCTableDollarChangeCell:
        transformDollarChangeCell(columns[i])
        break
      case CCTableLambdaCell:
        removeDataIndex(columns[i])
        const { itemMethod } = columns[i]
        columns[i].render = (record) => itemMethod(record)
        const column = columns[i]
        if (column.filterType === 'Double' || column.filterType === 'Numeric') {
          column.filterDropdown = ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) =>
            numericFilterDropdown(
              column.key,
              column.step,
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              1
            )
          column.columnType = column.filterType
        }
        if (column.filterType === 'Percentage') {
          column.filterDropdown = ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) =>
            numericFilterDropdown(
              column.key,
              column.step,
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              100
            )
          column.columnType = 'Double'
        }
        if (column.filterType === 'NegativeDouble') {
          column.filterDropdown = ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) =>
            negativeNumericFilterDropdown(
              column.key,
              column.step,
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              1
            )
          column.columnType = 'Double'
        }
        if (column.filterType === 'NegativePercentage') {
          column.filterDropdown = ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) =>
            negativeNumericFilterDropdown(
              column.key,
              column.step,
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              100
            )
          column.columnType = 'Double'
        }
        break
      default:
        break
    }
  }

  return columns
}

const transformLinkColumn = (column, rowSelected) => {
  const { tooltipMethod } = column
  removeDataIndex(column)

  if (column.hasMeetingStatusFilters === true) {
    column.filters = [
      getStatusFilter('Pending', 1),
      getStatusFilter('Confirmed', 2),
      getStatusFilter('Declined', 3),
      getStatusFilter('Cancelled', 4),
    ]
  }

  column.render = (record) => (
    <Tooltip title={!isNil(tooltipMethod) && tooltipMethod(record)}>
      <div className="cc-column-linkbutton-div">
        <Button
          style={{
            height: 'auto',
            paddingLeft: 0,
            width: '90%',
            textAlign: 'left',
          }}
          type="link"
          onClick={isNil(rowSelected) ? null : rowSelected.bind(null, record)}
          loading={record.recordLoading === true}
        >
          {' '}
          <Tooltip
            placement="topLeft"
            title={
              isNil(column.itemMethod)
                ? get(record, column.key)
                : column.itemMethod(record)
            }
          >
            <span
              style={{
                width: 'inherit',
                verticalAlign: 'middle',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {isNil(column.itemMethod)
                ? get(record, column.key)
                : column.itemMethod(record)}
            </span>
          </Tooltip>
        </Button>

        {record.hasMeeting && !isNil(record.meetingStatus) && (
          <Tooltip title={`Meeting is ${record.meetingStatus}.`}>
            <Avatar
              size={13}
              style={{
                background:
                  record.meetingStatus === 'Pending'
                    ? '#008DCA'
                    : record.meetingStatus === 'Confirmed'
                    ? '#24C477'
                    : record.meetingStatus === 'Cancelled'
                    ? '#F19F00'
                    : record.meetingStatus === 'Expired'
                    ? '#92949c'
                    : '#D62B34',
              }}
            />
          </Tooltip>
        )}
      </div>
    </Tooltip>
  )
}

const transformContactLinkColumn = (column, rowSelected) => {
  const tooltipMethod = column.tooltipMethodd
  const showTooltip = !isNil(tooltipMethod)

  const { itemsMethod } = column

  removeDataIndex(column)

  column.render = (record) => {
    const contact = isNil(itemsMethod)
      ? record[column.key]
      : itemsMethod(record)
    const contactName = isNil(contact.contactName)
      ? `${contact.firstName} ${contact.lastName}`
      : contact.contactName
    return (
      <div style={{ verticalAlign: 'center' }}>
        <ProfilePictureGroup
          diameter={32}
          profiles={[
            {
              name: contactName,
              image: contact.imageURL,
              firstName: contact.firstName,
              lastName: contact.lastName,
            },
          ]}
          maxImages={1}
          style={{ display: 'inline-block', marginTop: '4px' }}
        />
        <Tooltip title={showTooltip && tooltipMethod(record)}>
          <Button
            style={{ padding: '0 6px', verticalAlign: 'bottom' }}
            type="link"
            onClick={rowSelected && rowSelected.bind(record)}
          >
            {contactName}
          </Button>
        </Tooltip>
      </div>
    )
  }
}

const transformProfilePictureColumn = (column) => {
  removeDataIndex(column)

  const { contactsMethod } = column
  column.render = (record) => {
    // var contacts = isNil(contactsMethod) ? eval('record.' + column.key) : contactsMethod(record);
    const contacts = isNil(contactsMethod)
      ? get(record, column.key)
      : contactsMethod(record)
    return (
      <div style={{ verticalAlign: 'center' }}>
        <ProfilePictureGroup
          diameter={32}
          profiles={contacts.map((x) => ({
            name: x.contactName,
            image: x.imageUrl,
            firstName: x.firstName,
            lastName: x.lastName,
          }))}
          showImageOnPopover
          maxImages={2}
        />
      </div>
    )
  }
  column.align = 'left'
}

const transformNamedProfilePictureColumn = (column) => {
  removeDataIndex(column)

  const { contactsMethod } = column

  column.render = (record) => {
    const contacts = isNil(contactsMethod)
      ? get(record, column.key)
      : contactsMethod(record)

    return (
      <ProfilePictureGroupNamed
        diameter={24}
        profiles={contacts.map((x) => ({
          name: x.contactName,
          image: x.imageUrl,
          firstName: x.firstName,
          lastName: x.lastName,
        }))}
        showImageOnPopover
        maxImages={1}
      />
    )
  }
}

const transformTagGroupColumn = (column) => {
  const { itemsMethod } = column
  column.render = (record) => (
    <TagGroup
      color={column.tagGroupColor}
      maxLength={column.tagGroupMaxLength}
      // items={
      //     isNil(itemsMethod) ? eval('record.' + column.key): itemsMethod(record)
      // }
      items={isNil(itemsMethod) ? get(record, column.key) : itemsMethod(record)}
    />
  )
}

const transformActionColumn = (column) => {
  delete column.dataIndex
  column.render = (record) => {
    const menu = isNil(column.menuItemsMethod)
      ? column.menuItems
      : column.menuItemsMethod(record)
    return <ActionButton record={record} menu={menu} />
  }
  column.align = 'center'
}

const transfromDateTimeColumn = (column) => {
  removeDataIndex(column)
  const format = column.dateTimeFormat || 'DD/MM/YYYY'
  column.render = (record) => {
    // const value = eval('record.' + column.key);
    const value = isNil(column.itemMethod)
      ? get(record, column.key)
      : column.itemMethod(record)

    return isNil(value) ? (
      <span />
    ) : (
      <span>{moment.utc(value).local().format(format)}</span>
    )
  }
  column.columnType = 'DateTime'
  if (!isNil(column.isFiltered)) {
    column.filterDropdown = ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) =>
      datePickerFilterDropdown(
        column.key,
        column.datePickerType,
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      )
  }
}

const transformCurrencyColumn = (column) => {
  removeDataIndex(column)

  column.render = (record) => {
    const value = column.itemMethod?.(record) ?? get(record, column.key)
    return numberFormat.currencyLarge(value) ?? '-'
  }

  column.filterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) =>
    numericFilterDropdown(
      column.key,
      column.step,
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    )
  column.columnType = 'Numeric'
}

const transformYesNoColumn = (column) => {
  removeDataIndex(column)
  column.filters = [
    { text: 'YES', value: true },
    { text: 'NO', value: false },
  ]
  column.filterMultiple = false
  column.columnType = 'Boolean'
  column.render = (record) => {
    // const value = eval('record.' + column.key);
    const value = get(record, column.key)
    return <span>{value === true ? 'YES' : 'NO'}</span>
  }
}

const transformDollarChangeCell = (column) => {
  removeDataIndex(column)
  column.render = (record) => {
    const value = get(record, column.key)
    return value > 0 ? (
      <>
        <span className="cc-positive-number">
          +{numberFormat.currencyLarge(value)}
        </span>
        <span className="cc-positive-number">
          <FontAwesomeIcon icon={faArrowUp} color="green" />
        </span>
      </>
    ) : (
      <>
        <span className="cc-negative-number">
          {' '}
          -{numberFormat.currencyLarge(value)}
        </span>
        <span>
          <FontAwesomeIcon icon={faArrowDown} color="red" />
        </span>
      </>
    )
  }
}

const removeDataIndex = (column) => {
  if (!isNil(column.dataIndex)) {
    if (isNil(column.key)) column.key = column.dataIndex
    delete column.dataIndex
  }
}

const datePickerFilterDropdown = (
  columnKey,
  datePickerType,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters
) => {
  const CustomPicker = getDatePicker(datePickerType)

  return (
    <div style={{ padding: 8 }}>
      From:
      <CustomPicker
        disabledDate={(x) => disabledStartDate(x, selectedKeys[1])}
        value={selectedKeys[0]}
        onChange={(value) => setSelectedKeys([value, selectedKeys[1]])}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      To:
      <CustomPicker
        disabledDate={(x) => disabledEndDate(selectedKeys[0], x)}
        value={selectedKeys[1]}
        onChange={(value) => setSelectedKeys([selectedKeys[0], value])}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => {
          confirm()
        }}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button
        onClick={() => {
          clearFilters()
        }}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  )
}

const disabledStartDate = (startValue, endValue) => {
  if (!startValue || !endValue) return false

  return startValue.valueOf() > endValue.valueOf()
}

const disabledEndDate = (startValue, endValue) => {
  if (!startValue || !endValue) return false

  return endValue.valueOf() <= startValue.valueOf()
}

const numericFilterDropdown = (
  columnKey,
  step = 1,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  divideByValue = 1
) => (
  <div style={{ padding: 8 }}>
    From:
    <InputNumber
      step={step}
      value={
        isNil(selectedKeys[0])
          ? selectedKeys[0]
          : selectedKeys[0] * divideByValue
      }
      formatter={(x) => (isNil(x) || x === '' ? '' : numeral(x).format('0,0'))}
      onChange={(value) =>
        setSelectedKeys([value / divideByValue, selectedKeys[1]])
      }
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
    To:
    <InputNumber
      step={step}
      value={
        isNil(selectedKeys[1])
          ? selectedKeys[1]
          : selectedKeys[1] * divideByValue
      }
      formatter={(x) => (isNil(x) || x === '' ? '' : numeral(x).format('0,0'))}
      onChange={(value) =>
        setSelectedKeys([selectedKeys[0], value / divideByValue])
      }
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
    <Button
      type="primary"
      onClick={() => {
        confirm()
      }}
      icon={<SearchOutlined />}
      size="small"
      style={{ width: 90, marginRight: 8 }}
    >
      Search
    </Button>
    <Button
      onClick={() => {
        clearFilters()
      }}
      size="small"
      style={{ width: 90 }}
    >
      Reset
    </Button>
  </div>
)

const negativeNumericFilterDropdown = (
  columnKey,
  step = 1,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  divideByValue = 1
) => (
  <div style={{ padding: 8 }}>
    From:
    <InputNumber
      step={step}
      value={
        isNil(selectedKeys[0])
          ? selectedKeys[0]
          : selectedKeys[0] * divideByValue
      }
      formatter={(value) =>
        value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
      }
      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
      onChange={(value) =>
        setSelectedKeys([value / divideByValue, selectedKeys[1]])
      }
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
    To:
    <InputNumber
      step={step}
      value={
        isNil(selectedKeys[1])
          ? selectedKeys[1]
          : selectedKeys[1] * divideByValue
      }
      formatter={(value) =>
        value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
      }
      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
      onChange={(value) =>
        setSelectedKeys([selectedKeys[0], value / divideByValue])
      }
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
    <Button
      type="primary"
      onClick={() => {
        confirm()
      }}
      icon={<SearchOutlined />}
      size="small"
      style={{ width: 90, marginRight: 8 }}
    >
      Search
    </Button>
    <Button
      onClick={() => {
        clearFilters()
      }}
      size="small"
      style={{ width: 90 }}
    >
      Reset
    </Button>
  </div>
)

const getDatePicker = (pickerType) => {
  switch (pickerType) {
    case 'year':
      return YearPicker
    case 'month':
      return MonthPicker
    case 'week':
      return WeekPicker
    default:
      return DatePicker
  }
}

const getStatusFilter = (status, statusId) => ({
  text: (
    <div style={{ display: 'inline-block' }}>
      <Avatar
        className={`meeting-avatar meeting-avatar-${status.toLowerCase()}`}
        size={13}
        shape="circle"
      />
      <span style={{ marginLeft: '8px' }}>{status}</span>
    </div>
  ),
  value: statusId,
})

export const transformPagination = (pagination) => {
  if (pagination === false) return pagination
  if (typeof pagination === 'object') {
    pagination.size = 'small'
    pagination.showSizeChanger = true
  }
  return pagination
}
