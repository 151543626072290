import { useState } from 'react'
import { Button } from '@context365/button'
import { AdsClickOutlined } from '@context365/icons'
import { Link } from 'react-router-dom'
import CreateOrEditCampaign from '~/components/CreateOrEditCampaign'
import useAuth from '~/hooks/useAuth'
import WidgetBlock from './WidgetBlock'

const QuickLinkItem = ({ label, linkText, path, onClick }) => (
  <div className="flex flex-row gap-3 justify-between items-center py-4 pl-1">
    <span className="type-body-regular-sm flex-grow">{label}</span>
    <Button
      variant="link"
      as={path ? Link : undefined}
      to={path}
      onClick={onClick}
      size="small"
      status="secondary"
      className="flex-none"
    >
      {linkText}
    </Button>
  </div>
)

const QuickLinks = () => {
  const { role: userRole } = useAuth()
  const [showCreateCampaign, setShowCreateCampaign] = useState(false)
  return (
    <WidgetBlock
      headerLeft={
        <div className="flex flex-row items-center gap-2">
          <AdsClickOutlined />
          Quick Links
        </div>
      }
    >
      <div className="divide-y -my-4">
        {userRole === 'Manager' && (
          <>
            <QuickLinkItem
              label="Search for investment mandates"
              linkText="View Mandates"
              path="/discover/mandates"
            />
            <QuickLinkItem
              label="Search for matched allocators"
              linkText="View Allocators"
              path="/community/directory?filter=Categories.Name=Allocator"
            />
            <QuickLinkItem
              label="Search for investment firms"
              linkText="View Companies"
              path="/community/companies"
            />
            <QuickLinkItem
              label="Show me upcoming and past Events"
              linkText="View Events"
              path="/summits"
            />
          </>
        )}
        {userRole === 'Allocator' && (
          <>
            <QuickLinkItem
              label="Search for funds and unique investment strategies"
              linkText="View Funds"
              path="/discover/funds"
            />
            <QuickLinkItem
              label="Search for allocators and their representatives"
              linkText="View Allocators"
              path="/community/directory?filter=Categories.Name=Allocator"
            />
            <QuickLinkItem
              label="View investment fund managers"
              linkText="View Managers"
              path="/community/directory?filter=Categories.Name=Manager"
            />
            <QuickLinkItem
              label="Add an investment mandate"
              linkText="Add Mandate"
              onClick={() => setShowCreateCampaign(true)}
            />
            <QuickLinkItem
              label="Keep your investor profile up to date"
              linkText="Edit Preferences"
              path="/settings/profile"
            />
            <QuickLinkItem
              label="Show upcoming and past Events"
              linkText="View Events"
              path="/summits"
            />
          </>
        )}
      </div>
      <CreateOrEditCampaign
        visible={showCreateCampaign}
        onClose={() => setShowCreateCampaign(false)}
      />
    </WidgetBlock>
  )
}

export default QuickLinks
