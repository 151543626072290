import { useEffect, useReducer, useState } from 'react'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import uniqBy from 'lodash/uniqBy'
import mime from 'mime-types'
import { useSelector } from 'react-redux'
import * as types from '~/constants/types/serviceprovideronboarding'
import { getUserCompanies } from '~/selectors/auth'

const answersReducer = (state, action) => {
  switch (action.type) {
    case types.ADD:
      return { ...state, ...action.payload }
    case types.APPEND_ANSWERS:
      return {
        ...state,
        answers: uniqBy(
          [...action.payload, ...state.answers],
          'serviceProviderQuestionId'
        ),
        finished: get(action, 'meta.finished', false),
      }
    default:
      return state
  }
}

const answersInitialState = {
  serviceProviderName: null,
  answers: [],
  finished: false,
}

const useServiceProviderParsing = () => {
  const company = useSelector(getUserCompanies)
  const [state, dispatch] = useReducer(answersReducer, answersInitialState)
  const [previewObject, setPreviewObject] = useState({})

  useEffect(() => {
    const peopleAnswer = state.answers.find(
      (answer) => answer.shortName === 'People'
    )

    const testimonialsAnswer = state.answers.find(
      (answer) => answer.shortName === 'Testimonials'
    )

    const clientsAnswer = state.answers.find(
      (answer) => answer.shortName === 'Clients'
    )

    const blogLinkAnswer = state.answers.find(
      (answer) => answer.shortName === 'BlogLink'
    )
    const caseStudiesLinkAnswer = state.answers.find(
      (answer) => answer.shortName === 'CaseStudiesLink'
    )

    const webCastsLinkAnswer = state.answers.find(
      (answer) => answer.shortName === 'WebCastsLink'
    )

    const whitePaperGuidesLinkAnswer = state.answers.find(
      (answer) => answer.shortName === 'WhitePaperGuidesLink'
    )

    const facebookPageLinkAnswer = state.answers.find(
      (answer) => answer.shortName === 'FacebookPageLink'
    )
    const twitterProfileLink = state.answers.find(
      (answer) => answer.shortName === 'TwitterProfileLink'
    )

    const linkedInPageLink = state.answers.find(
      (answer) => answer.shortName === 'LinkedInPageLink'
    )

    const instagramProfileLink = state.answers.find(
      (answer) => answer.shortName === 'InstagramProfileLink'
    )

    const logoLink = state.answers.find((answer) => answer.shortName === 'Logo')

    const companyMediaLink = state.answers.find(
      (answer) => answer.shortName === 'CompanyMedia'
    )

    const parsedAnswers = (answers) =>
      isNil(answers) || isNil(answers.responseText)
        ? null
        : JSON.parse(answers.responseText)

    const imagesList = parsedAnswers(companyMediaLink)?.filter(
      (link) => !mime.lookup(link).includes('video')
    )

    const videoLink = parsedAnswers(companyMediaLink)?.filter((link) =>
      mime.lookup(link).includes('video')
    )
    const object = {
      people: parsedAnswers(peopleAnswer),
      testimonials: parsedAnswers(testimonialsAnswer),
      clients: parsedAnswers(clientsAnswer),
      blogUrl: blogLinkAnswer?.responseText,
      caseStudiesUrl: caseStudiesLinkAnswer?.responseText,
      webCastsUrl: webCastsLinkAnswer?.responseText,
      whitepapersAndGuidesUrl: whitePaperGuidesLinkAnswer?.responseText,
      facebookUrl: facebookPageLinkAnswer?.responseText,
      twitterUrl: twitterProfileLink?.responseText,
      linkedInUrl: linkedInPageLink?.responseText,
      instagramUrl: instagramProfileLink?.responseText,
      imageUrl: logoLink?.responseText,
      images: imagesList,
      videoUrl: videoLink,
      companyName: company[0]?.name,
      address: company[0]?.address,
      city: company[0]?.city,
      description: company[0]?.companyDescription,
      website: company[0]?.companyWebsite,
    }
    setPreviewObject(object)
  }, [state, company])

  return { previewObject, state, dispatch }
}

export default useServiceProviderParsing
