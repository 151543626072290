import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getRoadshows } from '~/actions/roadshow'
import ContextTable from '~/components/ContextTable'
import './RoadshowsTable.less'

export const CCTableActionCell = 'CCTABLE_ACTION_CELL'
export const CCTableLinkCell = 'CCTABLE_LINK_CELL'
export const CCTableNumberCell = 'CCTABLE_NUMBER_CELL'

const RoadshowsTable = ({ refreshTable, isPast }) => {
  const [, setRefresh] = useState(refreshTable)

  useEffect(() => {
    setRefresh(refreshTable)
  }, [refreshTable])

  const getColumns = () => {
    const columns = [
      {
        title: 'Date',
        //dataIndex: 'dates',
        sorter: false,
        width: 150,
        render: (record) => (
          <span>{`${moment
            .utc(record.dateFromUtc)
            .local()
            .format('MMM DD')} - ${moment
            .utc(record.dateToUtc)
            .local()
            .format('MMM DD yyyy')}`}</span>
        ),
      },
      {
        title: 'Roadshow Name',
        key: 'name',
        className: 'cc-name-column',
        cellType: CCTableLinkCell,
        width: 150,
        sorter: false,
      },
      {
        title: 'Locations',
        dataIndex: 'location',
        sorter: false,
        width: 150,
      },
      {
        title: 'Meeting Requests',
        dataIndex: 'requests',
        sorter: false,
        step: 10000,
        align: 'center',
        cellType: CCTableNumberCell,
        width: 100,
      },
      {
        title: 'Confirmed Requests',
        dataIndex: 'confirmed',
        sorter: false,
        step: 10000,
        align: 'center',
        cellType: CCTableNumberCell,
        width: 100,
      },
      {
        title: 'NOT RESPONDED',
        dataIndex: 'notResponded',
        sorter: false,
        step: 10000,
        align: 'center',
        cellType: CCTableNumberCell,
        width: 100,
      },
    ]
    return columns
  }

  return (
    <>
      {' '}
      <ContextTable
        tableTitle="Upcoming roadshows"
        columns={getColumns()}
        dataMethod={getRoadshows.bind(null, isPast)}
        scroll={{ x: 1800 }}
        refresh={refreshTable}
        refreshData={refreshTable}
      />
    </>
  )
}

RoadshowsTable.propTypes = {
  refreshTable: PropTypes.bool,
  isPast: PropTypes.bool,
}

export default RoadshowsTable
