import PropTypes from 'prop-types'
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, Col, Row } from 'antd'
import numeral from 'numeral'
import './portfolioCard.less'
import '~/styles/index.less'

const PortfolioCard = ({
  totalInvestment,
  totalValuation,
  change,
  percentageChange,
}) => (
  <div
    style={{
      padding: '24px',
    }}
  >
    <Row gutter={16}>
      <Col span={8}>
        <Card bordered={false} className="cc-portfolio-card cc-shadow2">
          <span className="cc-card-money">$ {totalInvestment}</span>
          <span className="cc-card-money-description">Total Investment</span>
        </Card>
      </Col>
      <Col span={8}>
        <Card bordered={false} className="cc-portfolio-card cc-shadow2">
          <span className="cc-card-money">$ {totalValuation}</span>
          <span className="cc-card-money-description">Total Valuation</span>
        </Card>
      </Col>
      <Col span={8}>
        <Card bordered={false} className="cc-portfolio-card cc-shadow2">
          <div className="cc-portfolio-change-container">
            <div>
              <span className="cc-card-money">$ {change}</span>
              <span className="cc-card-money-description">Change</span>
            </div>
            <div className="cc-percentage-container">
              <span className="cc-percentage-change">
                {numeral(percentageChange).format('+0.0% ')}
              </span>
              {percentageChange > 0 ? (
                <FontAwesomeIcon icon={faAngleUp} color="green" size="2x" />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} color="red" />
              )}
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  </div>
)
PortfolioCard.propTypes = {
  totalInvestment: PropTypes.number,
  totalValuation: PropTypes.number,
  change: PropTypes.number,
  percentageChange: PropTypes.number,
}
export default PortfolioCard
