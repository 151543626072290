import PropTypes from 'prop-types'

export const ContactShape = PropTypes.shape({
  contactId: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  contactName: PropTypes.string,
  imageUrl: PropTypes.string,
})

export const DocumentShape = PropTypes.shape({
  fileName: PropTypes.string.isRequired,
  fileURL: PropTypes.string.isRequired,
  documentDate: PropTypes.object.isRequired,
  extension: PropTypes.string.isRequired,
})

export const FundShape = PropTypes.shape({
  fundID: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(DocumentShape),
})

export const CompanyShape = PropTypes.shape({
  companyID: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  companyTypeID: PropTypes.number.isRequired,
  companyCategory: PropTypes.string.isRequired,
  funds: PropTypes.arrayOf(FundShape),
})

export const MessageShape = PropTypes.shape({
  messageID: PropTypes.number.isRequired,
  index: PropTypes.number,
  status: PropTypes.string.isRequired,
  logTypeID: PropTypes.number,
  logType: PropTypes.string,
  timeSent: PropTypes.object.isRequired,
  body: PropTypes.string.isRequired,
  authorContact: ContactShape.isRequired,
})

export const ConversationShape = PropTypes.shape({
  conversationID: PropTypes.number.isRequired,
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(ContactShape).isRequired,
  lastMessage: MessageShape,
  unreadMessages: PropTypes.number.isRequired,
  sortDate: PropTypes.string,
})

export const ConversationDetailedShape = PropTypes.shape({
  conversationID: PropTypes.number.isRequired,
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(ContactShape).isRequired,
  company: CompanyShape.isRequired,
  messages: PropTypes.arrayOf(MessageShape).isRequired,
})

export const MeetingShape = PropTypes.shape({
  meetingId: PropTypes.number.isRequired,
  meetingType: PropTypes.string.isRequired,
  companyEventId: PropTypes.number,
  eventId: PropTypes.number,
  isOptimized: PropTypes.bool,
})

export const SearchResultShape = PropTypes.shape({})
