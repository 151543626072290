import PropTypes from 'prop-types'
import { Tooltip } from '@context365/popovers'
import { shorten } from '~/utils/helpers'

const ShortenedText = ({
  text,
  maxLength,
  className,
  includeTooltip = true,
}) => {
  const shortenedText = shorten(text, maxLength)
  const textBlock =
    text && maxLength && text.length > maxLength && includeTooltip ? (
      <Tooltip className="break-words" text={text}>
        {shortenedText}
      </Tooltip>
    ) : (
      shortenedText
    )

  return <div className={className}>{textBlock}</div>
}

ShortenedText.propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  className: PropTypes.string,
  includeTooltip: PropTypes.bool,
}
export default ShortenedText
