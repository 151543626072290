import { useState } from 'react'
import PropTypes from 'prop-types'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { faClock, faUserFriends } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tag } from 'antd'
import ClockTimer from '../MeetingLobbyDetails/ClockTimer'
import NextPresentationActions from './NextPresentationActions'

const NextPresentationItem = ({ presentation }) => {
  const [presentationStarted, setPresentationStarted] = useState(false)

  return (
    <div>
      <div className="cc-white-heading1" style={{ fontSize: '30px' }}>
        <b>{presentation.title}</b>
      </div>
      <div className="cc-meeting-lobby-body">
        <FontAwesomeIcon icon={faUserFriends} style={{ marginRight: '8px' }} />
        {presentation.presenters.map((x, index) => (
          <span key={index}>
            {x.name}
            {index !== presentation.presenters.length - 1 && <span>,</span>}
          </span>
        ))}
      </div>
      <div className="cc-meeting-lobby-body" style={{ display: 'flex' }}>
        {!presentationStarted && !presentation.isLive && (
          <>
            <FontAwesomeIcon icon={faClock} style={{ marginRight: '8px' }} />
            <span style={{ margin: '0px 7px 0px 0px' }}>Starts in </span>
            <ClockTimer
              compact
              eventDate={presentation.startTime}
              setMeetingAvailable={setPresentationStarted}
            />
          </>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {!presentation.isLive && (
          <Tag color="rgba(0, 141, 202, 0.1)" className="cc-meeting-lobby-tag ">
            <FontAwesomeIcon
              icon={faInfoCircle}
              style={{ marginRight: '5px', color: '#008DCA' }}
            />
            Presentation hasn&apos;t started yet
          </Tag>
        )}
        <NextPresentationActions presentation={presentation} />
      </div>
    </div>
  )
}

NextPresentationItem.propTypes = {
  presentation: PropTypes.object.isRequired,
}
export default NextPresentationItem
