import * as Yup from 'yup'
import {
  Form,
  InputField,
  TextAreaField,
  UnsavedChangesAlert,
} from '~/components/Form'
import Static from '../fields/Static'
import { AddressForm } from '../forms/AddressForm'

const schema = Yup.object().shape({
  companyName: Yup.string().nullable().required().min(1),
  companyDescription: Yup.string().nullable().required().min(280),
  companyWebsite: Yup.string().nullable().url(),
  continent: Yup.string().nullable().required(),
  country: Yup.string().nullable().required(),
  postalCode: Yup.string().nullable(),
  city: Yup.string().nullable().required(),
  stateProvince: Yup.string().nullable(),
  address1: Yup.string().nullable(),
  address2: Yup.string().nullable(),
  address3: Yup.string().nullable(),
})

export const baseCompanySchema = schema

export function CompanyForm({ savedAnswers, onSubmit, ...formProps }) {
  return (
    <Form
      className="onboarding-form"
      schema={schema}
      defaultValues={savedAnswers}
      onSubmit={onSubmit}
      {...formProps}
    >
      <InputField name="companyName" label="Company Name" autoComplete="off" />
      <TextAreaField
        name="companyDescription"
        label="Company Description"
        rows={5}
        showCharacterCount
      />
      <InputField
        name="companyWebsite"
        label="Company Website"
        inputMode="url"
      />
      <AddressForm title="Company Address" className="my-4" />

      <UnsavedChangesAlert />
    </Form>
  )
}

export function CompanyConfirmForm({ answers }) {
  return (
    <div className="flex flex-col gap-5 max-w-md mx-auto">
      <Static label="Company Name" value={answers.companyName} />
      <Static label="Company Description" value={answers.companyDescription} />
      <Static label="Company Website" value={answers.companyWebsite} />
      <div className="flex">
        <Static
          className="flex-1"
          label="Continent"
          value={answers.continent}
        />
        <Static className="flex-1" label="Country" value={answers.country} />
      </div>
      <div className="flex">
        <Static className="flex-1" label="City" value={answers.city} />
        <Static
          className="flex-1"
          label="State/Province"
          value={answers.stateProvince}
        />
      </div>
      <Static label="Postal Code" value={answers.postalCode} />
      <Static label="Address 1" value={answers.address1} />
      <Static label="Address 2" value={answers.address2} />
      <Static label="Address 3" value={answers.address3} />
    </div>
  )
}
