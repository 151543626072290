import useAuth from '~/hooks/useAuth'
import { ApplyToCampaignModal } from './Application'
import { CompanyProfileModal } from './CompanyProfile'
import { ContactProfileModal } from './ContactProfile'
import { ResearchCampaignProfileGlobalModal } from './ResearchCampaignProfile'

export default function GlobalModals() {
  const { company } = useAuth()
  return (
    company && (
      <>
        <CompanyProfileModal />
        <ContactProfileModal />
        <ApplyToCampaignModal />
        <ResearchCampaignProfileGlobalModal />
      </>
    )
  )
}
