/* eslint-disable no-console */
import { initializeApp } from 'firebase/app'
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging'
import has from 'lodash/has'
import { addNewNotification, postDeviceToken } from './actions/notifications'
import { FirebaseOptions, PLATFORM, VAPID_KEY } from './config'
import store from './store'

isSupported()
  .then((isSupported) => (isSupported ? Promise.resolve() : Promise.reject()))
  .then(() => {
    const firebaseApp = initializeApp(FirebaseOptions)
    const messaging = getMessaging(firebaseApp)

    return getToken(messaging, { vapidKey: VAPID_KEY })
      .then((token) => {
        if (token) {
          console.log('[fcm.js] FCM token received:', token)

          store.dispatch(postDeviceToken(PLATFORM, token))

          navigator.serviceWorker
            .register('/firebase-messaging-sw.js')
            .then((registration) => {
              console.log(
                '[fcm.js] Registration successful, scope is:',
                registration.scope
              )
            })
            .catch((err) => {
              console.error(
                '[index.js] Service worker registration failed, error:',
                err
              )
            })

          onMessage(messaging, (payload) => {
            console.log(
              '[fcm.js]::onMessage::Received notification payload:',
              payload
            )
            if (has(payload, 'data')) {
              const { data } = payload
              store.dispatch(addNewNotification(data))
            }
          })
        }
      })
      .catch((err) => {
        console.error('[fcm.js] Unable to retrieve messaging token.', err)
      })
  })
  .catch((err) => {
    console.error('[fcm.js] Messaging is not supported.', err)
  })
