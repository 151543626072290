import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { ExpandMore, Send } from '@context365/icons'
import cx from 'classnames'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import { getStatusTag } from '~/utils/helpers'
import ActionButton from '../ActionButton'
import ProfilePictureGroup from '../ProfilePictureGroup'
import './ProfileHeaderWithButtons.less'

const ProfileHeaderWithButtons = ({
  name,
  status = null,
  image = null,
  position = null,
  role = null,
  address = null,
  city = null,
  state = null,
  country = null,
  companyName = null,
  hideActions = true,
  canSendMessage = true,
  onSendMessage,
  canSendMeetingRequest,
  customMeetingButton,
  subscribeButton = null,
  onSendRequest,
  sendRequestText = 'Send Meeting Request',
  sendMessageText = 'Send Message',
  contacts,
  actions = [],
}) => (
  <div className="flex p-4 justify-between mr-8">
    <div className="flex">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {image && <img src={image} alt="profile" className="Profile-Picture" />}
        <div className={cx(image && 'my-4')}>
          <div className="type-header-sm">
            {name}
            {status &&
              React.cloneElement(getStatusTag(status), {
                className: 'ml-4 align-middle',
              })}
          </div>
          {companyName && <div className="type-header-xxs">{companyName}</div>}
          <div className="type-body-regular-xs">
            {position && `${position} • `}
            {role && `${role} • `}
            {join(
              filter([address, city, state, country], (f) => !isNil(f)),
              ', '
            )}
          </div>
          {!isEmpty(contacts) && <ProfilePictureGroup profiles={contacts} />}
        </div>
      </div>
    </div>
    <div className="flex">
      {!hideActions && (
        <div className="flex gap-4 items-center">
          {!isEmpty(actions) && (
            <ActionButton
              trigger={
                <Button variant="link" iconRight={<ExpandMore />}>
                  Actions
                </Button>
              }
              menu={actions}
            />
          )}
          {canSendMessage && (
            <Button
              variant="outlined"
              onClick={onSendMessage}
              iconLeft={<Send />}
            >
              {sendMessageText}
            </Button>
          )}
          {subscribeButton}
          {canSendMeetingRequest &&
            (isNil(customMeetingButton) ? (
              <Button variant="filled" onClick={onSendRequest}>
                {sendRequestText}
              </Button>
            ) : (
              customMeetingButton
            ))}
        </div>
      )}
    </div>
  </div>
)

ProfileHeaderWithButtons.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  position: PropTypes.string,
  role: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  status: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  companyName: PropTypes.string,
  hideActions: PropTypes.bool,
  canSendMessage: PropTypes.bool,
  onSendMessage: PropTypes.func,
  canSendMeetingRequest: PropTypes.bool,
  onSendRequest: PropTypes.func,
  sendRequestText: PropTypes.string,
  sendMessageText: PropTypes.string,
  customMeetingButton: PropTypes.node,
  subscribeButton: PropTypes.node,
  contacts: PropTypes.array,
  actions: PropTypes.array,
}

export default ProfileHeaderWithButtons
