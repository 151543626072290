import * as React from 'react'
import PropTypes from 'prop-types'
import differenceInDays from 'date-fns/differenceInDays'
import { useFlags } from 'launchdarkly-react-client-sdk'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import omit from 'lodash/omit'
import without from 'lodash/without'
import { useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import { api as http } from '~/api/services'
import CompareFundsModal from '~/components/CompareFunds'
import { CompareFundsDrawer } from '~/components/CompareFundsDrawer'
import CreateOrEditCampaign from '~/components/CreateOrEditCampaign'
import {
  DiscoverController,
  DiscoverFilters,
  DiscoverTabs,
  extractQuestionGroups,
} from '~/components/Discover'
import { getDiscoverFundsFilterComponent } from '~/components/DiscoverFilters'
import {
  DiscoverFundResult,
  FundChartGradientDef,
} from '~/components/DiscoverFundResult'
import {
  AddFundToListModal,
  useAddToListModal,
} from '~/components/DiscoverListModals'
import { SelectRowsProvider } from '~/components/DiscoverTable'
import DiscoverFundsTable from '~/components/DiscoverTable/DiscoverFundsTable'
import FundDetailsModalContainer from '~/components/FundDetailsModalContainer'
import { StorageKeys } from '~/config'
import { ROLE } from '~/constants/roles'
import { EditInvestorPreferences } from '~/constants/userAccess'
import useLocalStorageState from '~/hooks/useLocalStorageState'
import useSearchParams from '~/hooks/useSearchParams'
import useVisibility from '~/hooks/useVisibility'
import DiscoverMapView from '../DiscoverMapView/DiscoverMapView'
import CreateCampaignPrompt from './CreateCampaignPrompt'
import './DiscoverContainer.less'

const today = new Date()

const ONE_HOUR = 1000 * 60 * 60
const ALLOCATOR_PROMPT_INTERVAL_DAYS = 30

const sortFields = [
  { name: 'fundAUM', label: 'Fund AUM' },
  { name: 'firmAUM', label: 'Firm AUM' },
  { name: 'minimumInvestment', label: 'Minimum Investment' },
  { name: 'annualizedReturn', label: 'Annualized Return' },
  { name: 'fundInceptionDate', label: 'Inception Date' },
  { name: 'sharpe', label: 'Sharpe' },
  { name: 'sortino', label: 'Sortino' },
  { name: 'sterling', label: 'Sterling' },
  { name: 'currentYtdReturn', label: `${today.getFullYear()} YTD Return` },
  { name: 'previousYtdReturn', label: `${today.getFullYear() - 1} YTD Return` },
  { name: 'lastYtdReturn', label: `${today.getFullYear() - 2} YTD Return` },
  { name: 'managementFee', label: 'Management Fee' },
  { name: 'performanceFee', label: 'Performance Fee' },
  { name: 'deepestDrawdown', label: 'Max Drawdown' },
  { name: 'durationOfCapitalLockup', label: 'Capital Lock Up Days' },
]

const DiscoverFundsContainer = ({ tab, resultId, clearResult }) => {
  const { Track } = useTracking({ page: 'DiscoverFunds' })
  const { role } = useSelector((state) => state.auth)
  const { activities } = useSelector((state) => state.userAccess)
  const [searchParams] = useSearchParams()
  const canEditInvestorPreferences = activities.includes(
    EditInvestorPreferences
  )
  const [showCreateCampaign, setShowCreateCampaign] = React.useState(false)
  const [allocatorPromptLastShown, setAllocatorPromptLastShown] =
    React.useState(
      new Date(JSON.parse(localStorage.getItem('allocatorPrompt')))
    )

  const modifyFilterQuery = (filters) => {
    if (!isNil(resultId)) {
      filters.push(`fundId eq ${[resultId]}`)
    }
  }

  const compareFundsModal = useVisibility()

  const { discoverFundsTabularView, allocatorCampaignCreationPrompt = false } =
    useFlags()
  const addFundsToListModal = useAddToListModal()
  const {
    selectedFunds,
    isFundSelected,
    toggleFundSelected,
    toggleAllFundsSelected,
    updateSelectedFund,
  } = useSelectFunds()

  const renderFundModal = React.useCallback(
    (results, selectedFundId, onChangeSelectedFundId) => {
      const selectedResult = find(results, (r) => r.fundId === selectedFundId)
      return isNil(selectedResult) ? null : (
        <FundDetailsModalContainer
          showFundProfile={true}
          showDataroom={searchParams.tabKey === 'attachments'}
          fund={selectedResult}
          arePointsNear={false}
          canSendMessage={role !== ROLE.SERVICE_PROVIDER}
          onClose={() => onChangeSelectedFundId(null)}
        />
      )
    },
    [role, searchParams.tabKey]
  )

  const client = useQueryClient()

  const { data: showAllocatorPrompt = false } = useQuery(
    'checkAllocatorPrompt',
    () =>
      differenceInDays(new Date(), allocatorPromptLastShown) >=
        ALLOCATOR_PROMPT_INTERVAL_DAYS && api.campaigns.checkAllocatorPrompt(),
    {
      enabled: allocatorCampaignCreationPrompt,
      cacheTime: ONE_HOUR,
      staleTime: ONE_HOUR,
      onSuccess: (showAllocatorPrompt) => {
        if (showAllocatorPrompt) {
          const now = new Date()
          localStorage.setItem('allocatorPrompt', JSON.stringify(now))
          setAllocatorPromptLastShown(now)
        }
      },
    }
  )

  return (
    <Track>
      <FundChartGradientDef />
      <AddFundToListModal
        visible={addFundsToListModal.visible}
        funds={addFundsToListModal.items}
        onClose={addFundsToListModal.hide}
      />
      <CompareFundsDrawer
        funds={selectedFunds}
        onCompareFunds={compareFundsModal.show}
        onRemoveFund={toggleFundSelected}
        onRemoveAllFunds={() => toggleAllFundsSelected(selectedFunds, false)}
      />
      <CompareFundsModal
        visible={compareFundsModal.visible}
        funds={selectedFunds}
        onClose={compareFundsModal.hide}
      />
      <CreateCampaignPrompt
        visible={!!showAllocatorPrompt}
        onSubmit={() => {
          client.setQueryData('checkAllocatorPrompt', false)
          setShowCreateCampaign(true)
        }}
      />
      <CreateOrEditCampaign
        visible={showCreateCampaign}
        onClose={() => setShowCreateCampaign(false)}
        onSkip={() => setShowCreateCampaign(false)}
      />
      <DiscoverController
        getResults={(params) => api.discovery.funds(params)}
        onResult={(fund) => updateSelectedFund(fund)}
        modifyFilterQuery={modifyFilterQuery}
        refetchKeys={{ resultId }}
        category="funds"
        tab={tab}
        sortFields={sortFields}
        showTableView={discoverFundsTabularView}
        getFilters={() => http.get('/discover/funds/filters')}
        renderFilters={({ filters }) => (
          <DiscoverFilters
            filters={filters}
            canEditInvestorPreferences={canEditInvestorPreferences}
            getFilterGroups={extractQuestionGroups(
              'Trending',
              'ApexInvest Signal',
              'Lists'
            )}
            renderFilterControl={getDiscoverFundsFilterComponent}
            hideToggleMatches={role === 'Service Provider'}
          />
        )}
        renderModal={renderFundModal}
        results={
          <DiscoverTabs
            renderResultCard={({ item, selectedId, onChangeSelectedId }) => {
              return (
                <DiscoverFundResult
                  fund={item}
                  selectedFundId={selectedId}
                  isSelectedForCompare={isFundSelected(item)}
                  onChangeSelectedFundId={onChangeSelectedId}
                  onAddFundToList={addFundsToListModal.show}
                  onToggleCompare={toggleFundSelected}
                />
              )
            }}
            renderTableView={({
              data,
              loadPage,
              currentPage,
              total,
              selectedId,
              onChangeSelectedId,
              pageSize,
            }) => (
              <SelectRowsProvider
                selectedRows={selectedFunds}
                getRowId={(fund) => fund.fundId}
                onToggleSelected={toggleFundSelected}
                onToggleAllSelected={toggleAllFundsSelected}
              >
                <DiscoverFundsTable
                  fundList={data}
                  loadPage={loadPage}
                  currentPage={currentPage}
                  total={total}
                  selectedFundId={selectedId}
                  onChangeSelectedFundId={onChangeSelectedId}
                  onAddFundsToList={addFundsToListModal.show}
                  onCompareFunds={compareFundsModal.show}
                  pageSize={pageSize}
                />
              </SelectRowsProvider>
            )}
            renderMapView={({ activeFilters, showOnlyMatched, searchText }) => (
              <DiscoverMapView
                filter={activeFilters}
                searchTerm={searchText}
                showMatched={showOnlyMatched}
                resultId={resultId}
              />
            )}
          />
        }
        clearResult={clearResult}
      />
    </Track>
  )
}

DiscoverFundsContainer.propTypes = {
  tab: PropTypes.string,
  resultId: PropTypes.number,
  clearResult: PropTypes.func,
}

export default DiscoverFundsContainer

function useSelectFunds() {
  const [selectedFunds, setSelectedFunds] = useLocalStorageState(
    StorageKeys.compareFunds,
    { fundIds: [], fundsById: {} }
  )

  const isFundSelected = React.useCallback(
    (fund) => {
      return fund.fundId in selectedFunds.fundsById
    },
    [selectedFunds]
  )

  const toggleFundSelected = React.useCallback(
    (fund) => {
      if (isFundSelected(fund)) {
        removeFund(fund)
      } else {
        addFund(fund)
      }

      function addFund(fund) {
        setSelectedFunds((selectedFunds) => ({
          fundIds: [...selectedFunds.fundIds, fund.fundId],
          fundsById: { ...selectedFunds.fundsById, [fund.fundId]: fund },
        }))
      }

      function removeFund(fund) {
        setSelectedFunds((selectedFunds) => ({
          fundIds: without(selectedFunds.fundIds, fund.fundId),
          fundsById: omit(selectedFunds.fundsById, fund.fundId),
        }))
      }
    },
    [isFundSelected, setSelectedFunds]
  )

  const toggleAllFundsSelected = React.useCallback(
    (funds, selected) => {
      if (selected) {
        selectAll(funds)
      } else {
        removeAll(funds)
      }

      function selectAll(funds) {
        const fundsToSelect = funds.filter((f) => !isFundSelected(f))
        setSelectedFunds((selectedFunds) => ({
          fundIds: [
            ...selectedFunds.fundIds,
            ...fundsToSelect.map((f) => f.fundId),
          ],
          fundsById: {
            ...selectedFunds.fundsById,
            ...fundsToSelect.reduce((fundsById, fund) => {
              fundsById[fund.fundId] = fund
              return fundsById
            }, {}),
          },
        }))
      }

      function removeAll(funds) {
        const fundIds = funds.map((f) => f.fundId)
        setSelectedFunds((selectedFunds) => ({
          fundIds: without(selectedFunds.fundIds, ...fundIds),
          fundsById: omit(selectedFunds.fundsById, fundIds),
        }))
      }
    },
    [isFundSelected, setSelectedFunds]
  )

  const updateSelectedFund = React.useCallback(
    (fund) => {
      if (!isFundSelected(fund)) {
        return
      }

      setSelectedFunds((selectedFunds) => ({
        fundIds: selectedFunds.fundIds,
        fundsById: {
          ...selectedFunds.fundsById,
          [fund.fundId]: fund,
        },
      }))
    },
    [isFundSelected, setSelectedFunds]
  )

  return {
    selectedFunds: React.useMemo(
      () =>
        selectedFunds.fundIds.map((fundId) => selectedFunds.fundsById[fundId]),
      [selectedFunds]
    ),
    isFundSelected,
    toggleFundSelected,
    toggleAllFundsSelected,
    updateSelectedFund,
  }
}
