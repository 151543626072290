import * as React from 'react'
import { Info } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import DiscoverTable from '~/components/DiscoverTable'
import {
  AnnualReturns,
  Aum,
  BroadStrategy,
  Contacts,
  FundName,
  InceptionDate,
  Minimum,
  Sharpe,
  Substrategy,
  YearToDateReturn,
} from '~/components/DiscoverTable/DiscoverFundsColumns'
import { useCompareFundsContext } from '../compareFundsContext'
import ChartIndicator from './ChartIndicator'

const baseColumns = [
  FundName,
  Contacts,
  BroadStrategy,
  Substrategy,
  Aum,
  InceptionDate,
  Minimum,
  AnnualReturns,
  YearToDateReturn,
  Sharpe,
]
const staticColumns = ['chartColors']

export default function CompareFundsTable() {
  const { funds, requestStatus, isFundDropped, getFundColor } =
    useCompareFundsContext()

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        id: 'chartColors',
        // eslint-disable-next-line react/no-unstable-nested-components -- it's memoized
        accessor: (fund) => {
          if (requestStatus !== 'success') {
            return <div className="w-3" />
          }

          return isFundDropped(fund.fundId) ? (
            <DroppedFund />
          ) : (
            <ChartIndicator {...getFundColor(fund.fundId)} />
          )
        },
      },
      ...baseColumns,
    ],
    [requestStatus, isFundDropped, getFundColor]
  )

  return (
    <DiscoverTable
      columns={columns}
      data={funds}
      tableTitle="CompareFundsTable"
      useColumnCustomization
      staticColumns={staticColumns}
    />
  )
}

function DroppedFund() {
  return (
    <div className="text-center">
      <Tooltip text="This fund did not report returns in the same time period as other funds in the comparison.">
        <Info className="text-gold-100" size={16} />
      </Tooltip>
    </div>
  )
}
