import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faArrowDown,
  faArrowUp,
  faPaperPlane,
} from '@fortawesome/pro-light-svg-icons'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Button, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import chunk from 'lodash/chunk'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import moment from 'moment'
import ShowMoreText from 'react-show-more-text'
import { getMeetingConversationID } from '~/actions/messages'
import { addLineBreaks } from '~/utils/helpers'
import ContactName from '../ContactName'
import Loading from '../Loading'
import './FollowUpMessages.less'

const FollowUpMessages = ({
  meetingId,
  loading,
  sender,
  meetingStatus,
  participantStatusId,
  messages,
  sendHandler,
  onSend,
  conversationId,
  setConversationId,
  userId = 0,
}) => {
  const [onlyShowingLast, setOnlyShowingLast] = useState(true)
  const [messageWritten, setMessageWritten] = useState('')
  const [numberOfFollowUpLeft, setNumberOfFollowUpLeft] = useState(4)
  const [sendingMessage, setSendingMessage] = useState(false)
  const [messagesShowing, setMessagesShowing] = useState([])

  const send = useCallback(
    (messageWritten, meetingId) => {
      setSendingMessage(true)
      sendHandler(meetingId, messageWritten)
        .then((response) => {
          message.success('Successfully sent message.')
          if (response.data.result && response.data.result.conversationID > 0)
            setConversationId(response.data.result.conversationID)
          else onSend()
          setMessageWritten('')
          setOnlyShowingLast(false)
        })
        .catch((err) => {
          message.error(
            get(err, 'response.data.message', 'Could not send message.')
          )
        })
        .finally(() => {
          setSendingMessage(false)
        })
    },
    [onSend, sendHandler, setConversationId]
  )

  useEffect(() => {
    setNumberOfFollowUpLeft(4 - messages.length)
    setMessagesShowing(
      onlyShowingLast
        ? messages.length <= 1
          ? messages
          : chunk(messages, messages.length - 1)[1]
        : messages
    )
  }, [messages, onlyShowingLast])

  const messageInput = () =>
    //if the user is the meeting request sender and they didn't at the moment of meeting request
    // then they can send a meeting message from this component,
    //if there are no meeting messages, receiver can't do anything
    {
      if (
        meetingStatus?.toLowerCase() !== 'pending' ||
        participantStatusId === 3 ||
        participantStatusId === 4
      ) {
        return undefined
      }

      if (numberOfFollowUpLeft === 0 && sender) {
        return (
          <Alert
            className="FollowUpAlert"
            message="Follow-up messages limit reached!"
            type="warning"
            icon={
              <FontAwesomeIcon color="#F19F00" icon={faExclamationCircle} />
            }
            showIcon
          />
        )
      } else if (
        (sender || !isEmpty(messages)) &&
        (isNil(conversationId) || conversationId === 0)
      ) {
        return (
          <>
            <div style={{ margin: '16px 0px' }}>
              <TextArea
                style={{ backgroundColor: 'white' }}
                placeholder="Write Message..."
                rows={4}
                onChange={({ target: { value } }) => {
                  setMessageWritten(value)
                }}
                value={messageWritten}
              />
            </div>
            <div>
              <Button
                disabled={messageWritten.trim() === ''}
                loading={sendingMessage}
                onClick={() => {
                  if (isNil(sendHandler)) return
                  if (sender)
                    getMeetingConversationID(meetingId).then((response) => {
                      if (response.data.result > 0) {
                        setConversationId(response.data.result)
                        message.info(
                          <span>
                            A conversation already exists.{' '}
                            <a href="/messages">Go to conversation.</a>
                          </span>,
                          5
                        )
                      } else {
                        send(messageWritten, meetingId)
                      }
                    })
                  else {
                    send(messageWritten, meetingId)
                  }
                }}
                type="primary"
              >
                <FontAwesomeIcon icon={faPaperPlane} />
                <span style={{ marginLeft: '8px' }}>
                  {sender ? 'Send follow-up message' : 'Send Reply'}
                </span>
              </Button>
              {sender && (
                <span
                  style={{ marginLeft: '16px' }}
                  className="cc-small-font cc-dark-grey-text"
                >{`${numberOfFollowUpLeft} follow-up message${
                  numberOfFollowUpLeft === 1 ? '' : 's'
                } left`}</span>
              )}
            </div>
          </>
        )
      } else {
        return undefined
      }
    }

  return (
    <Loading spinning={loading}>
      <div className="FollowUpMessages">
        {messages.length > 1 && (
          <>
            <div className="cc-tabletitle-text cc-dark-grey-text">Messages</div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '-24px',
              }}
            >
              <Button
                onClick={() => {
                  setOnlyShowingLast(!onlyShowingLast)
                }}
                className="ShowMoreButton"
                type="link"
              >
                <FontAwesomeIcon
                  icon={onlyShowingLast ? faArrowUp : faArrowDown}
                />
                <span className="cc-body-text" style={{ marginLeft: '8px' }}>
                  {onlyShowingLast
                    ? 'Show previous messages'
                    : 'Show less messages'}
                </span>
              </Button>
            </div>
          </>
        )}
        {!isEmpty(messagesShowing) &&
          map(messagesShowing, (message, index) => {
            const isMe =
              userId > 0 ? message.contact.contactId === userId : sender
            return (
              <div key={index} className="message-container">
                <div className={`contact ${isMe ? 'sender' : 'receiver'}`}>
                  <ContactName
                    reverseOrder={isMe}
                    fontClass="ContactFont"
                    name={message.contact.contactName}
                    imageUrl={message.contact.imageUrl}
                  />
                </div>
                <div
                  className={`message ${
                    isMe ? 'sent-message' : 'received-message'
                  }`}
                >
                  <span className="cc-body-text">
                    <ShowMoreText
                      /* Default options */
                      lines={2}
                      more=" Show more"
                      less=" Show less"
                      anchorClass="cc-show-more"
                      expanded={false}
                    >
                      {addLineBreaks(message.message)}
                    </ShowMoreText>
                  </span>
                </div>
                <div
                  className={`message-date message-date-${
                    isMe ? 'sender' : 'receiver'
                  } cc-xsmall-font`}
                >
                  {moment
                    .utc(message.dateCreated)
                    .local()
                    .format('MMM DD, YYYY [at] h:mm a')}
                </div>
              </div>
            )
          })}
      </div>
      {!(
        meetingStatus?.toLowerCase() !== 'pending' ||
        participantStatusId === 3 ||
        participantStatusId === 4
      ) && <div style={{ marginBottom: '32px' }}>{messageInput()}</div>}
    </Loading>
  )
}

FollowUpMessages.propTypes = {
  meetingId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  sender: PropTypes.bool.isRequired,
  meetingStatus: PropTypes.string.isRequired,
  participantStatusId: PropTypes.number,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      meetingMessageId: PropTypes.number.isRequired,
      meetingId: PropTypes.number.isRequired,
      contact: PropTypes.shape({
        contactId: PropTypes.number.isRequired,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        contactName: PropTypes.string.isRequired,
        imageUrl: PropTypes.string,
      }).isRequired,
      message: PropTypes.string.isRequired,
      messageTypeId: PropTypes.number.isRequired,
      dateCreated: PropTypes.string.isRequired,
    })
  ).isRequired,
  sendHandler: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  conversationId: PropTypes.number,
  setConversationId: PropTypes.func.isRequired,
  userId: PropTypes.number,
}

export default FollowUpMessages
