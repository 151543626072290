import * as Yup from 'yup'
import * as api from '~/api'
import { Form, InputField, UnsavedChangesAlert } from '~/components/Form'
import Loading from '~/components/Loading'
import { useOnboardingQuery } from '../hooks'
import { useOnboardingContext } from '../OnboardingContext'
import { Step } from '../workflows/utils'
import {
  BackButton,
  NextButton,
  OnboardingFooter,
  OnboardingLayout,
  OnboardingSteps,
} from './OnboardingLayout'

const schema = Yup.object().shape({
  twitter: Yup.string(),
  linkedIn: Yup.string(),
  youtube: Yup.string(),
  website: Yup.string().url(),
})

export function ContactSocialMedia({ steps }) {
  const { onNext } = useOnboardingContext()
  const { answers, isLoading, submit } = useOnboardingQuery(
    ['contact-social'],
    {
      getAnswers: () => api.onboarding.getContactSocialAnswers(),
      saveAnswers: (_, answers) =>
        api.onboarding.saveContactSocialAnswers(answers),
    }
  )

  if (isLoading) {
    return (
      <OnboardingLayout
        header={<OnboardingSteps steps={steps} />}
        body={
          <div className="flex items-center justify-center">
            <Loading />
          </div>
        }
      />
    )
  }

  const isFinalStep =
    steps.findIndex((s) => s.path === Step.ContactSocialMedia) ===
    steps.length - 1

  const nextButtonProps = {
    type: 'submit',
    form: 'contact-social',
  }
  if (isFinalStep) {
    nextButtonProps.iconRight = undefined
    nextButtonProps.children = 'Finish'
  }

  return (
    <OnboardingLayout
      header={<OnboardingSteps steps={steps} />}
      body={
        <Form
          id="contact-social"
          className="mt-12 onboarding-form"
          schema={schema}
          defaultValues={answers}
          onSubmit={submit}
          onSuccess={onNext}
        >
          <div className="type-header-md text-center mb-4">
            Enter your social media details
          </div>
          <InputField name="linkedIn" label="LinkedIn" />
          <InputField name="twitter" label="Twitter" />
          <InputField name="website" label="Website" />
          <InputField name="youtube" label="YouTube" />
          <UnsavedChangesAlert />
        </Form>
      }
      footer={
        <OnboardingFooter
          leftContent={<BackButton />}
          rightContent={<NextButton {...nextButtonProps} />}
        />
      }
    />
  )
}
