import * as React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import SimpleBar from 'simplebar-react'
import { fetchCompanyIfNeeded } from '~/actions/company'
import { getCompanyFunds } from '~/actions/discover'
import { getCompanyId } from '~/selectors/auth'
import DiscoverAllocatorsFundSelect from '../discoverAllocators/dicoverAllocatorsFundSelect'
import CompanyFundsContext from './companyFundsContext'

export default function DiscoverSelectFund({ children }) {
  const companyId = useSelector(getCompanyId)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fetchCompanyIfNeeded(companyId))
  }, [companyId, dispatch])

  const {
    data: funds,
    isLoading,
    refetch,
  } = useQuery(['company-funds', companyId], () =>
    getCompanyFunds(companyId).then((res) => res.data.result)
  )

  if (isLoading) {
    return null
  }

  return isEmpty(funds) ? (
    <SimpleBar
      style={{ maxHeight: 'calc(100vh - 50px)', overflowX: 'hidden' }}
      autoHide={false}
    >
      <DiscoverAllocatorsFundSelect onSelectFund={() => refetch()} />
    </SimpleBar>
  ) : (
    <CompanyFundsContext.Provider value={funds}>
      {children}
    </CompanyFundsContext.Provider>
  )
}

DiscoverSelectFund.propTypes = {
  children: PropTypes.node.isRequired,
}
