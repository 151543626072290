import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Card } from 'antd'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getUserId } from '~/selectors/auth'
import { boldCompanyNameFormat } from '~/utils/helpers'
import AcceptMeetingComponent from '../../AcceptMeetingComponent'
import CancelMeetingButton from '../../CancelMeetingButton'
import { CCTableDateTimeCell } from '../../CCTable'
import ContactName from '../../ContactName'
import RescheduleEventMeeting from '../../RescheduleEventMeeting'
import RescheduleMeeting from '../../RescheduleMeeting'
import './OnlineMeetingRecivedRequests.less'

export const CONTACT_NAME = 'CONTACT_NAME'
export const DATE = 'DATE'

const ResponsiveCard = ({
  requests,
  columns,
  meetingType,
  tableType,
  onRescheduleMeeting,
  onCancelMeeting,
  onAcceptMeeting,
}) => {
  const [
    showRescheduleOnlineMeetingModal,
    setShowRescheduleOnlineMeetingModal,
  ] = useState(false)
  const [
    showRescheduleSummitMeetingModal,
    setShowRescheduleSummitMeetingModal,
  ] = useState(false)
  const [showAcceptRequestModal, setShowAcceptRequestModal] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState({})
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [cancelButtonText, setCancelButtonText] = useState('Cancel')
  const contactId = useSelector(getUserId)
  const meetingWithString = boldCompanyNameFormat(selectedRecord)

  const handleRescheduleClick = (record) => {
    setSelectedRecord(record)
    meetingType === 'Summit'
      ? setShowRescheduleSummitMeetingModal(true)
      : setShowRescheduleOnlineMeetingModal(true)
  }
  const closeModal = () => {
    meetingType === 'Summit'
      ? setShowRescheduleSummitMeetingModal(false)
      : setShowRescheduleOnlineMeetingModal(false)
  }

  const RescheduleModal =
    meetingType === 'Summit' ? (
      <RescheduleEventMeeting
        visible={showRescheduleSummitMeetingModal}
        closeModal={closeModal}
        meetingId={selectedRecord.meetingId}
        eventId={selectedRecord.eventId}
        onRescheduleMeeting={onRescheduleMeeting}
        meetingDateTime={selectedRecord?.meetingDateTime}
        meetingDuration={selectedRecord?.meetingDuration}
      />
    ) : (
      <RescheduleMeeting
        visible={showRescheduleOnlineMeetingModal}
        closeModal={closeModal}
        onlineMeetingId={selectedRecord.meetingId}
        onRescheduleMeeting={onRescheduleMeeting}
        meetingDateTime={selectedRecord?.meetingDateTime}
        meetingDuration={selectedRecord?.meetingDuration}
        roadshowId={selectedRecord?.roadshowId}
      />
    )
  return (
    <div>
      <AcceptMeetingComponent
        visible={showAcceptRequestModal}
        closeModal={() => {
          setSelectedRecord({})
          setShowAcceptRequestModal(false)
        }}
        meetingType={meetingType}
        categoryName={selectedRecord.categoryName}
        company={meetingWithString}
        meetingDateTime={
          isNil(selectedRecord) ? 0 : selectedRecord.meetingDateTime
        }
        meetingId={isNil(selectedRecord) ? 0 : selectedRecord.meetingId}
        eventId={isNil(selectedRecord) ? 0 : selectedRecord.eventId}
        companyEventId={
          isNil(selectedRecord) ? 0 : selectedRecord.companyEventId
        }
        onAccept={onAcceptMeeting}
        participants={
          selectedRecord.participants
            ? selectedRecord.participants.map((x) => x.contactId)
            : []
        }
        toParticipants={[contactId]}
        isVirtualSummit={selectedRecord?.virtualSummit}
        isOptimized={selectedRecord?.isOptimized}
      />
      <CancelMeetingButton
        visible={showCancelModal}
        closeModal={() => {
          setShowCancelModal(false)
        }}
        company={meetingWithString}
        meetingId={isNil(selectedRecord) ? 0 : selectedRecord.meetingId}
        meetingType={meetingType}
        meetingStatus={
          isNil(selectedRecord) ? null : selectedRecord.meetingStatus
        }
        cancelText={cancelButtonText}
        onCancel={onCancelMeeting}
      />
      {RescheduleModal}
      {requests !== 'undefined' &&
        requests.map((req) => (
          <Card
            key={req.meetingId}
            className="cc-shadow2"
            style={{
              margin: 'auto',
              marginBottom: '8px',
              borderRadius: '4px',
              maxWidth: '90%',
            }}
          >
            <div className="cc-responsive-card-block">
              {columns.map(
                (col) =>
                  !col.notRenderInCard && (
                    <div className="cc-card-body-group" key={col.key}>
                      <div>
                        <span className="cc-tabletitle-text">{col.title}</span>
                      </div>
                      <div>
                        {col.cardType === CONTACT_NAME ? (
                          <ContactName
                            name={req[col.contactDataIndex]}
                            fontClass="cc-body-text"
                            marginTop={0}
                          />
                        ) : col.cellType === CCTableDateTimeCell ||
                          col.cardType === DATE ? (
                          <div>
                            <span>
                              {req[col.dataIndex] === null
                                ? '-'
                                : moment
                                    .utc(req[col.dataIndex])
                                    .local()
                                    .format('YYYY-MM-DD h:mm A')}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <span
                              className={`${col.className} cc-tabletitle-text`}
                            >
                              {col.render
                                ? col.render(req)
                                : get(req, col.dataIndex)}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  )
              )}
            </div>

            <div className="cc-meeting-actions" style={{ float: 'right' }}>
              {tableType === 'Received Requests' && (
                <>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowAcceptRequestModal(true)
                      setSelectedRecord(req)
                    }}
                    className="cc-btn cc-btn-success"
                  >
                    <i className="cc-btn-bg" />
                    Accept
                  </Button>
                  <Button
                    onClick={() => {
                      setShowCancelModal(true)
                      setSelectedRecord(req)
                      setCancelButtonText('Decline')
                    }}
                    className="cc-btn cc-btn-danger"
                  >
                    <i className="cc-btn-bg" />
                    Decline
                  </Button>
                </>
              )}
              {(meetingType.toLowerCase() == 'online' ||
                tableType === 'Confirmed Meetings') &&
                (!isNil(req.meetingDateTime) ||
                  (req.isOptimized &&
                    meetingType.toLowerCase() === 'summit')) && (
                  <Button
                    type="primary"
                    onClick={handleRescheduleClick.bind(null, req)}
                    className="cc-btn cc-btn-notice"
                  >
                    <i className="cc-btn-bg" />
                    {'Reschedule'}
                  </Button>
                )}
              {tableType !== 'Declined' && tableType !== 'Received Requests' && (
                <Button
                  onClick={() => {
                    setShowCancelModal(true)
                    setSelectedRecord(req)
                    setCancelButtonText('Cancel')
                  }}
                  className="cc-btn cc-btn-danger"
                >
                  <i className="cc-btn-bg" />
                  Cancel
                </Button>
              )}
            </div>
          </Card>
        ))}
    </div>
  )
}
ResponsiveCard.propTypes = {
  requests: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  meetingType: PropTypes.string.isRequired,
  tableType: PropTypes.string.isRequired,
  onRescheduleMeeting: PropTypes.func,
  onCancelMeeting: PropTypes.func,
  onAcceptMeeting: PropTypes.func,
}
export default ResponsiveCard
