import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faMinusCircle, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Empty, Row, Table, Tag, Tooltip, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchSentRequests } from '~/actions/contextMeetings'
import { getMeetingList } from '~/actions/meetings'
import Loading from '~/components/Loading'
import { CancelSummitMeeting } from '~/components/MeetingActions'
import MeetingsCardView from '~/components/MeetingsCardView'
import ResponsiveCard, {
  DATE,
} from '~/components/Responsive/ResponsiveCards/OnlineMeetingRecivedRequestResponsiveCard'
import SearchBox from '~/components/SearchBox/SearchBox'
import TimeDisplay from '~/components/TimeDisplay'
import useSearchParams from '~/hooks/useSearchParams'
import { selectedEventHasEnded } from '~/selectors/events'
import { boldCompanyNameFormat } from '~/utils/helpers'
import {
  canLeaveMeeting,
  renderParticipantsAvatars,
} from '~/utils/meetingActions'
import EmptyEventTablesState from './EmptyEventTablesState'
import './SentRequestsTable.less'

const tooltTipText = <span>This meeting carries Context Points.</span>

const SentRequestsTable = ({
  activeSummit = true,
  meetingType,
  allowMultipleCompaniesMeeting,
  meetingTypeId = 1,
  eventId,
  width,
  fromSummit,
  refreshTable,
  onCancelRequest,
}) => {
  const [showCancelOnlineModal, setShowCancelOnlineModal] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState({})
  const [displayTimezone, setDisplayTimezone] = useState(TimeDisplay.ORIGINAL)
  const [displayTimezoneLabel, setDisplayTimezoneLabel] =
    useState('Event Timezone')
  const [refresh, setRefresh] = useState(refreshTable)
  const [isLoading, setIsLoading] = useState(false)
  const [cancelForAll, setCancelForAll] = useState(false)
  const [allSentList, setAllSentList] = useState([])
  const [hasNext, setHasNext] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()

  const updateQueryParams = React.useCallback(
    (updatedFields) => {
      setSearchParams({
        ...searchParams,
        ...updatedFields,
      })
    },
    [searchParams, setSearchParams]
  )

  const resetPage = React.useCallback(
    (updatedFields = {}) => {
      updateQueryParams({ ...updatedFields, page: 1 })
    },
    [updateQueryParams]
  )

  const { page, searchQuery, pageSize } = React.useMemo(() => {
    const { page = 1, search = '', size = 10 } = searchParams

    return {
      page: parseInt(page, 10),
      searchQuery: search,
      pageSize: parseInt(size, 10),
    }
  }, [searchParams])

  const loadPage = React.useCallback(
    (page, size) => {
      updateQueryParams({ page, size })
    },
    [updateQueryParams]
  )

  const searchByText = React.useCallback(
    (text) => {
      resetPage({ search: text })
    },
    [resetPage]
  )

  const sentRequestsList = useSelector((state) => {
    if (meetingType.toLowerCase() === 'online')
      return state.meetings.onlineSentRequests
    else if (fromSummit) return state.meetings.sentRequestsCompanyEventId
    else if (meetingType.toLowerCase() === 'roadshow')
      return state.meetings.roadshowSentRequests
    else return state.meetings.sentRequests
  })

  const total = isNil(sentRequestsList) ? 0 : sentRequestsList.itemCount
  const role = useSelector((state) => state.auth.role)

  const pastSummit = useSelector(selectedEventHasEnded)

  const dispatch = useDispatch()

  const getSentList = useCallback(
    (page, pageSize) => {
      if (allowMultipleCompaniesMeeting) {
        getMeetingList(page, pageSize, 'sent', searchQuery, meetingTypeId)
          .then((response) => {
            page !== 1
              ? setAllSentList((f) => f.concat(response.data.result.results))
              : setAllSentList(response.data.result.results)
            setHasNext(response.data.result.hasNextPage)
          })
          .catch((error) => {
            if (!error?.response?.data?.reason === 'Tier')
              message.error('Could not load meetings')
          })
      }
    },
    [allowMultipleCompaniesMeeting, searchQuery, meetingTypeId]
  )
  useEffect(() => {
    getSentList(page, pageSize)
  }, [page, pageSize, getSentList])

  useEffect(() => {
    setRefresh(refreshTable)
  }, [refreshTable])

  useEffect(() => {
    const params = { page, pageSize }

    if (!isNil(searchQuery) && !isEmpty(searchQuery)) {
      params.q = trim(searchQuery)
    }

    setIsLoading(true)
    dispatch(fetchSentRequests({ meetingTypeId, eventId, ...params })).finally(
      () => {
        setIsLoading(false)
      }
    )
  }, [dispatch, eventId, meetingTypeId, page, pageSize, searchQuery, refresh])

  const isPhysicalSummitMeeting = (record) => {
    return meetingType.toLowerCase() === 'summit' && !record.virtualSummit
  }
  const renderActions = (record) => {
    let showLeaveButton
    let showCancelButton
    if (isPhysicalSummitMeeting(record)) {
      showLeaveButton = false
      showCancelButton = true
    } else {
      showLeaveButton = canLeaveMeeting(record.colleagues, record.participants)
      showCancelButton = !showLeaveButton || record.isInitiator
    }
    return (
      record.meetingStatus.toLowerCase() !== 'declined' && (
        <div className="cc-meeting-actions">
          <span className="cc-btn cc-btn-danger">
            {showLeaveButton && (
              <Tooltip placement="top" title="Cancel Participation">
                <Button
                  onClick={(e) => {
                    handleLeaveClick(record)
                    e.stopPropagation()
                  }}
                  className="cc-btn cc-btn-danger"
                >
                  <i className="cc-btn-bg" />
                  <FontAwesomeIcon color="#D62B34" icon={faMinusCircle} />
                </Button>
              </Tooltip>
            )}
          </span>
          <span className="cc-btn cc-btn-danger">
            {showCancelButton && (
              <Tooltip
                placement="top"
                title={
                  isPhysicalSummitMeeting(record) ? 'Cancel' : 'Cancel For All'
                }
              >
                <Button
                  onClick={(e) => {
                    record.isInitiator || isPhysicalSummitMeeting(record)
                      ? handleCancelForAllClick(record)
                      : handleCancelClick(record)
                    e.stopPropagation()
                  }}
                  className="cc-btn cc-btn-red"
                >
                  <i className="cc-btn-bg" />
                  <FontAwesomeIcon color="#FFF" icon={faTimes} />
                </Button>
              </Tooltip>
            )}
          </span>
        </div>
      )
    )
  }

  const handleCancelClick = (record) => {
    setSelectedRecord(record)
    setCancelForAll(false)
    setShowCancelOnlineModal(true)
  }
  const handleLeaveClick = (record) => {
    setCancelForAll(false)
    setSelectedRecord(record)

    setShowCancelOnlineModal(true)
  }

  const handleCancelForAllClick = (record) => {
    setSelectedRecord(record)
    setCancelForAll(true)
    setShowCancelOnlineModal(true)
  }
  const meetingWithString = boldCompanyNameFormat(selectedRecord)

  const handleInfiniteOnLoad = useCallback(() => {
    if (allSentList?.length > 0 && hasNext) {
      loadPage(page + 1, pageSize)
    }
  }, [allSentList, hasNext, loadPage, page, pageSize])

  const getColumns = () => {
    const columns = [
      {
        key: '1',
        title: 'MEETING WITH',
        render: (record) => (
          <div>
            <div>
              {' '}
              <Link
                to={{
                  pathname: `/meeting/${meetingType.toLowerCase()}/${
                    record.meetingId
                  }`,
                  state: { isReceived: false },
                }}
              >
                <span className="cc-body-text">
                  {record.company.companyName}
                </span>
              </Link>
              <div className="ReceivedRequestsTable-category">
                {record.categoryName}
              </div>
            </div>

            {record.carryContextPoints && (
              <span style={{ paddingLeft: '8px' }}>
                <Tooltip placement="top" title={tooltTipText}>
                  <Tag visible={record.carryContextPoints}>
                    + 1000 Context Points
                  </Tag>
                </Tooltip>
              </span>
            )}
          </div>
        ),
      },
      {
        key: '2',
        title: 'PARTICIPANTS',
        render: renderParticipantsAvatars,
      },
      meetingType.toLowerCase() !== 'summit'
        ? {
            cardType: DATE,
            align: 'center',
            title: 'Meeting Date & Time ',
            dataIndex: 'meetingDateTime',
            render: (meetingDateTime) =>
              moment(meetingDateTime).isValid()
                ? moment
                    .utc(meetingDateTime)
                    .local()
                    .format('YYYY-MM-DD h:mm A')
                : 'TBD',
          }
        : {},
    ]

    const eventName = {
      title: 'Summit',
      className: 'cc-body-text',
      dataIndex: 'eventName',
    }

    const actions = {
      key: '5',
      title: 'ACTIONS',
      render: renderActions,
      align: 'left',
      notRenderInCard: true,
    }

    if (pastSummit && meetingType.toLowerCase() === 'summit') return columns
    else if (!fromSummit && meetingTypeId === 1) columns.splice(3, 0, eventName)
    return columns.concat(actions)
  }
  if (width < 1025 && !allowMultipleCompaniesMeeting) {
    if (total == 0 && !isLoading)
      return <Empty description="No sent requests." />
    else
      return (
        <Loading spinning={isLoading}>
          <ResponsiveCard
            columns={getColumns()}
            requests={!isNil(sentRequestsList) ? sentRequestsList.results : []}
            meetingType={meetingType}
            tableType="Sent Requests"
            displayTimezone={displayTimezone}
            setDisplayTimezone={setDisplayTimezone}
            displayTimezoneLabel={displayTimezoneLabel}
            setDisplayTimezoneLabel={setDisplayTimezoneLabel}
            onCancelMeeting={() => {
              onCancelRequest()
              setRefresh(!refresh)
            }}
          />
        </Loading>
      )
  }

  return (
    <div
      className={
        allowMultipleCompaniesMeeting
          ? 'SentRequestsTableCfn'
          : 'SentRequestsTable'
      }
    >
      <CancelSummitMeeting
        visible={showCancelOnlineModal}
        closeModal={() => {
          setShowCancelOnlineModal(false)
        }}
        company={meetingWithString}
        meetingId={selectedRecord.meetingId}
        meetingType={meetingType}
        onCancel={() => {
          onCancelRequest()
          setRefresh(!refresh)
          getSentList(1, pageSize * page)
        }}
        canDeclineMeeting={false}
        cancelForAll={cancelForAll}
        meetingStatus={selectedRecord.meetingStatus}
      />
      <Loading spinning={isLoading}>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <h4
            className={`${
              role === 'CFN'
                ? 'DeclinedRequestsTableCfn'
                : 'DeclinedRequestsTable'
            }-title cc-heading4`}
          >
            Sent Requests
          </h4>

          <SearchBox onSearch={searchByText} allowClear={true} />
        </Row>
        {!allowMultipleCompaniesMeeting && (
          <Table
            locale={{
              emptyText: <EmptyEventTablesState activeSummit={activeSummit} />,
            }}
            columns={getColumns()}
            rowKey={(record) => record.meetingId}
            dataSource={sentRequestsList.results}
            pagination={{
              current: page,
              pageSize,
              total,
            }}
            onChange={(pagination) => {
              loadPage(pagination.current, pagination.pageSize)
            }}
          />
        )}
        {allSentList && allowMultipleCompaniesMeeting && (
          <MeetingsCardView
            meetingList={allSentList}
            hasMore={hasNext}
            isSearching={searchQuery && !isEmpty(searchQuery)}
            isScrollingAllowed={page > 0}
            handleInfiniteOnLoad={handleInfiniteOnLoad}
            renderActions={renderActions}
            type="Sent"
            loading={isLoading}
          />
        )}
      </Loading>
    </div>
  )
}

SentRequestsTable.propTypes = {
  activeSummit: PropTypes.bool,
  meetingType: PropTypes.string.isRequired,
  meetingTypeId: PropTypes.number,
  eventId: PropTypes.number,
  fromSummit: PropTypes.bool,
  width: PropTypes.number.isRequired,
  refreshTable: PropTypes.bool,
  onCancelRequest: PropTypes.func,
  allowMultipleCompaniesMeeting: PropTypes.bool,
}

export default SentRequestsTable
