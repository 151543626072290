import { api } from './services'

export const getUserSettings = async () => await api.get('/v1/settings')

export const getColleagueSettings = async (contactId) =>
  await api.get(`/v1/colleague/${contactId}/settings`)

export const updateUser = async (contact) =>
  await api.put('/v1/settings', contact, { timeout: 600000 })

export const updateColleague = async (contactId, contact) =>
  await api.put(`/v1/colleague/${contactId}/settings`, contact)

export const getCompanySettings = async () =>
  await api.get('/v1/settings/company')

export const updateCompany = async (company) =>
  await api.put('/v1/settings/company', company, { timeout: 600000 })

export const saveCommunitySettings = (profile) =>
  api.patch('/v1/settings/community', profile)

export const getCommunitySettings = () =>
  api.get('/v1/settings/community').then((res) => res.data.result)

export const getCompanyMediaSettings = () =>
  api.get(`/v1/settings/media`).then((res) => res.data.result)

export const saveCompanyMediaSettings = (settings) =>
  api.patch(`/v1/settings/media`, settings)

export const getMatchmakingSettings = () =>
  api.get('/investors/matchmakingThreshold').then((res) => res.data.result)

export const saveMatchmakingSettings = (settings) =>
  api.patch('/investors/matchmakingThreshold', settings)
