import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SeatsioSeatingChart } from '@seatsio/seatsio-react'
import { Alert } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import * as api from '~/api'
import Loading from '~/components/Loading'
import { SEATS_PUBLIC_KEY } from '~/config'

const SeatsSelector = ({
  eventId,
  onSeatSelected,
  selectedBooth,
  boothSelectionEnded,
}) => {
  const [eventKey, setEventKey] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    api.booth
      .getEventKey(eventId)
      .then((response) => {
        setEventKey(response.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [eventId])

  const handleSeatSelected = (seat) => {
    if (seat.selectable === false || boothSelectionEnded) return
    if (isFunction(onSeatSelected)) onSeatSelected(seat)
  }

  const handleSeatDeselected = () => {
    if (isFunction(onSeatSelected)) onSeatSelected(null)
  }

  return (
    <>
      {boothSelectionEnded && (
        <Alert
          message="Booth selection is closed"
          type="warning"
          style={{ marginBottom: '20px', marginRight: '5%' }}
        />
      )}
      <Loading spinning={loading}>
        {eventKey &&
          !isNil(SEATS_PUBLIC_KEY) &&
          !isEmpty(SEATS_PUBLIC_KEY) &&
          selectedBooth !== null && (
            <SeatsioSeatingChart
              onObjectSelected={handleSeatSelected}
              onObjectDeselected={handleSeatDeselected}
              publicKey={SEATS_PUBLIC_KEY}
              event={eventKey}
              className="seatChart"
              tooltipInfo={(object) => {
                if (selectedBooth && object.id === selectedBooth)
                  return 'Your booth'
                else return ''
              }}
              maxSelectedObjects="1"
              objectLabel={(object) => object.labels.own}
              region="eu"
              objectColor={(object, defaultColor, extraConfig) => {
                if (object.id === extraConfig.selectedBooth) return '#24C477'
                else return defaultColor
              }}
              extraConfig={{ selectedBooth }}
            />
          )}
      </Loading>
    </>
  )
}
SeatsSelector.propTypes = {
  eventId: PropTypes.number.isRequired,
  onSeatSelected: PropTypes.func.isRequired,
  selectedBooth: PropTypes.string,
  boothSelectionEnded: PropTypes.bool,
}
export default SeatsSelector
