import { useState } from 'react'
import PropTypes from 'prop-types'
import { faUpload } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Upload, message } from 'antd'
import classNames from 'classnames'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import { api as http } from '~/api/services'

const createHtml = (texts) => ({ __html: join(texts, '<br>') })

const FileField = ({
  name,
  value = null,
  help = null,
  icon = null,
  setFieldValue,
}) => {
  const [imageIconVisible, setImageIconVisible] = useState(false)

  const handleUpload = (fileInfo) => {
    const currentFile = fileInfo.file
    const extension = /[.]/.exec(currentFile.name)
      ? /[^.]+$/.exec(currentFile.name)[0]
      : undefined

    const acceptedExtensions = ['.jpg', '.jpeg', '.png']
    const isIncludedInAccept = acceptedExtensions.some(
      (x) => x === `.${extension}`
    )

    if (!isIncludedInAccept) {
      message.error('Incorrect file type')
      return
    }

    const formData = new FormData()
    formData.append('name', 'Profile Picture')
    formData.append('file', currentFile)

    http
      .post('/uploads', formData, { timeout: 600000 })
      .then((response) => {
        currentFile.url = encodeURI(response.data.result.url)
        setFieldValue(name, currentFile.url)
      })
      .catch((err) => {
        console.error('Error in upload:', err)
      })
  }

  if (isNil(icon)) {
    icon = <FontAwesomeIcon icon={faUpload} size="3x" color="white" />
  }

  let helpComponent = null
  if (!(isNil(help) || isEmpty(help))) {
    if (isArray(help)) {
      helpComponent = (
        <p
          className="ant-upload-text text-center"
          dangerouslySetInnerHTML={createHtml(help)}
        />
      )
    } else {
      helpComponent = <p className="ant-upload-text text-center">{help}</p>
    }
  }

  const dragger = (
    <div className="upload-container">
      <Upload
        accept=".jpg,.jpeg,.png"
        className="upload-icon-empty"
        showUploadList={false}
        listType="picture-card"
        customRequest={handleUpload}
        onMouseEnter={() => setImageIconVisible(true)}
        onMouseLeave={() => setImageIconVisible(false)}
      >
        {isNil(value) ? (
          <span key="span" className="upload-icon-upload">
            {icon}
          </span>
        ) : (
          <>
            <img
              style={{
                width: '137px',
                height: '137px',
                borderRadius: '64px',
                objectFit: 'cover',
              }}
              src={value}
              alt="profile"
            />
            {imageIconVisible && (
              <span key="span" className="upload-icon-upload">
                {icon}
              </span>
            )}
          </>
        )}
      </Upload>
    </div>
  )

  return (
    <div className={classNames('Field', 'UploadFileField')}>
      {dragger}
      {helpComponent}
    </div>
  )
}

FileField.propTypes = {
  name: PropTypes.string.isRequired,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  value: PropTypes.string,
  icon: PropTypes.node,
  setFieldValue: PropTypes.func,
}

export default FileField
