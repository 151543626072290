import { message } from 'antd'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import * as api from '~/api'
import Loading from '~/components/Loading'
import { ROLE } from '~/constants/roles'
import useAuth from '~/hooks/useAuth'
import { allocationTypes, fundTypes } from './campaignTypes'
import CreateOrEditModal from './CreateOrEditModal'

const CreateOrEditCampaign = ({ visible, onClose, onSkip, campaignId }) => {
  const { role } = useAuth()
  const history = useHistory()

  const { data: campaign, isLoading } = useQuery(
    ['campaign', campaignId],
    () => campaignId && api.campaigns.getCampaign(campaignId),
    {
      enabled: visible,
      onError: () => {
        message.error('Something went wrong while loading the pipeline.')
        history.replace('/campaigns')
      },
    }
  )

  return isLoading ? (
    <Loading spinning={isLoading} />
  ) : (
    <CreateOrEditModal
      visible={visible}
      campaign={campaign}
      types={role === ROLE.MANAGER ? fundTypes : allocationTypes}
      onClose={onClose}
      onSkip={onSkip}
    />
  )
}

export default CreateOrEditCampaign
