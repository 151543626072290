import * as React from 'react'
import { Button } from '@context365/button'
import { Error, PersonPin } from '@context365/icons'
import { Menu, MenuButton } from '@context365/menu'
import { arc } from 'd3-shape'
import isEmpty from 'lodash/isEmpty'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import * as api from '~/api'
import { UpdateMonthlyReturnsModal } from '~/components/UpdateReturns'
import useVisibility from '~/hooks/useVisibility'
import { numberFormat } from '~/utils/formatters'
import CampaignCard from './CampaignCard'
import './FundCampaign.css'

const FundCampaign = ({ campaign }) => {
  const { data: freshness, refetch: refreshFundFreshness } = useQuery(
    ['dashboardFundFreshness', campaign.fundId],
    () => api.funds.checkFundFreshness(campaign.fundId)
  )
  const { data: fundHealth } = useQuery(
    ['fund-profile-health', campaign.fundId],
    () => api.funds.getFundHealth(campaign.fundId)
  )

  return (
    <CampaignCard
      className="fund-campaign-card"
      campaignId={campaign.campaignId}
      name={campaign.name}
      campaignStatusId={campaign.campaignStatusId}
      newActivityCount={campaign.newActivityCount}
      updateButton={
        <UpdateButton
          fundId={campaign.fundId}
          {...freshness}
          onRefresh={refreshFundFreshness}
        />
      }
    >
      <div className="mt-2 campaign-stats">
        <Stat label="Views" value={campaign.profileViews} />
        <Stat
          label="AUM"
          value={numberFormat.currencyLarge(campaign.fundAum) ?? '-'}
        />
        <Stat
          label="Annual Return"
          value={
            numberFormat.percent(campaign.fundAnnualReturn, {
              precision: 1,
            }) ?? '-'
          }
        />
        <Stat
          label={
            <>
              Completeness Score
              <br />
              <Link
                className="text-secondary-100 underline"
                to={`/workspace/${campaign.campaignId}/analytics`}
              >
                Review
              </Link>
            </>
          }
          value={<CompletenessScore score={fundHealth?.grade} />}
        />
      </div>
    </CampaignCard>
  )
}

export default FundCampaign

function UpdateButton({
  fundId,
  isUpToDate = true,
  areMonthlyReturnsMissing,
  missingMonthlyReturns,
  onRefresh,
}) {
  const updateReturnsModal = useVisibility()

  if (isUpToDate) {
    return null
  }

  if (areMonthlyReturnsMissing && !isEmpty(missingMonthlyReturns)) {
    return (
      <>
        <Menu
          trigger={
            <MenuButton
              className="update-button"
              variant="filled"
              icon={<Error />}
            >
              Update Fund
            </MenuButton>
          }
        >
          <Menu.Item onClick={updateReturnsModal.show}>
            Update Returns
          </Menu.Item>
          <Menu.Item as={Link} to={`/edit-fund/${fundId}`}>
            Update Details
          </Menu.Item>
        </Menu>
        {updateReturnsModal.visible && (
          <UpdateMonthlyReturnsModal
            visible
            fundId={fundId}
            missingMonths={missingMonthlyReturns}
            onOk={() => {
              updateReturnsModal.hide()
              onRefresh()
            }}
            onCancel={updateReturnsModal.hide}
          />
        )}
      </>
    )
  }

  return (
    <Button
      as={Link}
      to={`/edit-fund/${fundId}`}
      variant="filled"
      className="update-button"
      iconLeft={<Error />}
    >
      Update Fund
    </Button>
  )
}

function Stat({ label, value }) {
  return (
    <>
      <div className="campaign-stats__data">{value}</div>
      <div className="campaign-stats__label">{label}</div>
    </>
  )
}

const scoreColors = {
  A: '#45C882',
  B: '#B0C650',
  C: '#E3C86B',
  D: '#E1AC5C',
  F: '#E16363',
}

const scoreArcs = [
  {
    grade: 'F',
    fill: scoreColors.F,
    startAngle: 0,
    endAngle: Math.PI / 5,
  },
  {
    grade: 'D',
    fill: scoreColors.D,
    startAngle: Math.PI / 5,
    endAngle: (2 * Math.PI) / 5,
  },
  {
    grade: 'C',
    fill: scoreColors.C,
    startAngle: (2 * Math.PI) / 5,
    endAngle: (3 * Math.PI) / 5,
  },
  {
    grade: 'B',
    fill: scoreColors.B,
    startAngle: (3 * Math.PI) / 5,
    endAngle: (4 * Math.PI) / 5,
  },
  {
    grade: 'A',
    fill: scoreColors.A,
    startAngle: (4 * Math.PI) / 5,
    endAngle: Math.PI,
  },
]
  // rotate everything by -90 degrees
  .map(({ startAngle, endAngle, ...arc }) => ({
    ...arc,
    startAngle: startAngle - Math.PI / 2,
    endAngle: endAngle - Math.PI / 2,
  }))

const arcPath = arc()
  .innerRadius(30)
  .outerRadius(35)
  .padAngle(Math.PI / 100)
const placeIndicator = arc()
  .innerRadius(arcPath.outerRadius())
  .outerRadius(arcPath.outerRadius())
  .padAngle(arcPath.padAngle())

const nonBreakingSpace = '\u00A0'

function CompletenessScore({ score = nonBreakingSpace }) {
  const indicator = React.useMemo(() => {
    const scoreArc = scoreArcs.find(({ grade }) => grade === score)
    if (!scoreArc) return null

    const [x, y] = placeIndicator.centroid({
      startAngle: scoreArc.startAngle,
      endAngle: scoreArc.endAngle,
    })

    return { x, y }
  }, [score])

  return (
    <div className="relative">
      <svg width="70" height="35">
        <g transform="translate(35, 35)">
          {scoreArcs.map(({ grade, startAngle, endAngle, fill }) => (
            <path
              key={grade}
              d={arcPath({ startAngle, endAngle })}
              fill={fill}
            />
          ))}
        </g>
      </svg>
      {indicator && (
        <div
          className="absolute rounded-full bg-white border flex items-center justify-center text-secondary-90"
          style={{
            height: 18,
            width: 18,
            top: 35 + indicator.y,
            left: 35 + indicator.x,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <PersonPin size={10} />
        </div>
      )}
      <div className="type-body-semibold-lg text-center relative -top-6 -mb-6">
        {score}
      </div>
    </div>
  )
}
