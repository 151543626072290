import { useContext } from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import parse from 'html-react-parser'
import FormCtx from '~/utils/contexts/FormCtx'
import getConditionalQuestions from '~/utils/form/getConditionalQuestions'
import getRadioFieldComponent from '~/utils/form/getRadioFieldComponent'
import useFormAnswers from '~/utils/hooks/useFormAnswers'
import '../Fields.less'

const BooleanField = ({ question }) => {
  const { questions } = useContext(FormCtx)
  const { answer, handleTextAnswerChange } = useFormAnswers(question)

  if (!answer?.responseText && !!question.attributes?.default) {
    handleTextAnswerChange(question.attributes?.default)
  }

  const shownAnswer = answer?.responseText

  return (
    <>
      {question.text && (
        <div className="Text">
          <p>{parse(question.text)}</p>
        </div>
      )}
      <Radio.Group
        className="BooleanField-radio"
        value={shownAnswer}
        onChange={(e) => handleTextAnswerChange(e.target.value)}
      >
        <>
          <Radio value="true">{question.attributes.labels?.true}</Radio>{' '}
          <div className="BooleanField-radio-conditional">
            {shownAnswer === 'true' &&
              getConditionalQuestions(
                1,
                questions,
                question,
                getRadioFieldComponent
              )}
          </div>
          <Radio value="false">{question.attributes.labels?.false}</Radio>{' '}
          <div className="BooleanField-radio-conditional">
            {shownAnswer === 'false' &&
              getConditionalQuestions(
                0,
                questions,
                question,
                getRadioFieldComponent
              )}{' '}
          </div>
        </>
      </Radio.Group>
    </>
  )
}

BooleanField.propTypes = {
  question: PropTypes.object,
}

export default BooleanField
