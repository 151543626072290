import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Col, Row, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { getFundEventsByCompany } from '~/actions/fundList'
import { api as http } from '~/api/services'
import { FundWizardModal, useFundWizardModal } from '~/components/FundWizard'
import Loading from '~/components/Loading'
import './AttachFunds.less'

const AttachFunds = ({ eventId, companyEventId, onRefresh }) => {
  const [funds, setFunds] = useState([])
  const [loading, setLoading] = useState(false)
  const [checkedFunds, setCheckedFunds] = useState([])
  const fundWizardModal = useFundWizardModal('attachFunds')

  useEffect(() => {
    setLoading(true)
    getFundEventsByCompany()
      .then((response) => {
        setFunds(response.data.result)
      })
      .finally(() => setLoading(false))
  }, [companyEventId])

  const onChecked = (e) => {
    let newFunds = checkedFunds.slice()

    if (e.target.checked) {
      newFunds.push(e.target.id)
    } else {
      newFunds = newFunds.filter((x) => x !== e.target.id)
    }

    setCheckedFunds(newFunds)
  }

  const onSubmit = () => {
    http
      .put(
        `events/${eventId}/companyevent/${companyEventId}/addfunds`,
        checkedFunds
      )
      .then((response) => {
        if (response.status === 200) message.success('Funds added successfully')
        else message.error('An error occured while adding the fund(s)')
      })
      .catch(() => {
        message.error('An error occured while adding the fund(s)')
      })
      .finally(() => onRefresh())
  }

  const checkFunds = (funds) =>
    funds.map((f, i) => (
      <Row key={i}>
        <Col span={6} />
        <Col span={18} style={{ textAlign: 'left' }}>
          <Checkbox id={f.fundId} onChange={onChecked}>
            {f.name}
          </Checkbox>
        </Col>
      </Row>
    ))
  return (
    <Loading spinning={loading}>
      {!isEmpty(funds) ? (
        <>
          {checkFunds(funds)}
          <Button
            type="primary"
            className="AttachFunds-Button"
            onClick={onSubmit}
          >
            Add Fund(s)
          </Button>
        </>
      ) : (
        <>
          <div>You have not created a fund yet.</div>
          <Button
            type="primary"
            className="AttachFunds-Button"
            onClick={() => fundWizardModal.show()}
          >
            Create Fund
          </Button>
          {fundWizardModal.visible && (
            <FundWizardModal
              visible
              onWizardClosed={() => {
                fundWizardModal.hide()
              }}
              onSave={() => {
                onRefresh()
                fundWizardModal.hide()
              }}
            />
          )}
        </>
      )}
    </Loading>
  )
}

AttachFunds.propTypes = {
  eventId: PropTypes.number.isRequired,
  companyEventId: PropTypes.number.isRequired,
  onRefresh: PropTypes.func.isRequired,
}

export default AttachFunds
