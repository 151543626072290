import * as Yup from 'yup'
import {
  Form,
  InputField,
  SelectField,
  TextAreaField,
  UnsavedChangesAlert,
} from '~/components/Form'
import Static from '../fields/Static'
import { AddressForm } from '../forms/AddressForm'
import { useOnboardingContext } from '../OnboardingContext'
import { baseCompanySchema } from './CompanyForm'

const schema = baseCompanySchema.shape({
  serviceProviderCategoryId: Yup.number().nullable().required(),
})

export function ServiceProviderCompanyForm({
  savedAnswers,
  onSubmit,
  ...formProps
}) {
  const { options } = useOnboardingContext()

  return (
    <Form
      className="onboarding-form"
      schema={schema}
      defaultValues={savedAnswers}
      onSubmit={onSubmit}
      {...formProps}
    >
      <InputField name="companyName" label="Company Name" autoComplete="off" />
      <TextAreaField
        name="companyDescription"
        label="Company Description"
        rows={5}
        showCharacterCount
      />
      <InputField
        name="companyWebsite"
        label="Company Website"
        inputMode="url"
      />
      <SelectField
        name="serviceProviderCategoryId"
        label="Service Provider Category"
        options={options.serviceProviderCategories}
      />
      <AddressForm title="Company Address" className="my-4" />

      <UnsavedChangesAlert />
    </Form>
  )
}

export function ServiceProviderCompanyConfirmForm({ answers }) {
  const { options } = useOnboardingContext()

  return (
    <div className="flex flex-col gap-5 max-w-md mx-auto">
      <Static label="Company Name" value={answers.companyName} />
      <Static label="Company Description" value={answers.companyDescription} />
      <Static label="Company Website" value={answers.companyWebsite} />
      <div className="flex">
        <Static
          className="flex-1"
          label="Continent"
          value={answers.continent}
        />
        <Static className="flex-1" label="Country" value={answers.country} />
      </div>
      <div className="flex">
        <Static className="flex-1" label="City" value={answers.city} />
        <Static
          className="flex-1"
          label="State/Province"
          value={answers.stateProvince}
        />
      </div>
      <Static label="Postal Code" value={answers.postalCode} />
      <Static label="Address Line 1" value={answers.address1} />
      <Static label="Address Line 2" value={answers.address2} />
      <Static label="Address Line 3" value={answers.address3} />
      <Static.Option
        label="Service Provider Category"
        value={answers.serviceProviderCategoryId}
        options={options.serviceProviderCategories}
      />
    </div>
  )
}
