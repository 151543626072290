import PropTypes from 'prop-types'
import { Button, Modal } from 'antd'
import FloatingInput from '../FloatingInput'

const createPortfolio = ({ visible, handleOk, handleCancel, onChange }) => (
  <Modal
    visible={visible}
    title="New Portofolio"
    onOk={handleOk}
    onCancel={handleCancel}
    footer={[
      <Button key="cancel" onClick={handleCancel} style={{ width: '86px' }}>
        Close
      </Button>,
      <Button
        key="submit"
        type="primary"
        style={{ width: '136px' }}
        onClick={handleOk}
      >
        Create
      </Button>,
    ]}
  >
    <p className="cc-create-label">Create new Portofolio</p>
    <FloatingInput name="Portfolio Name" onChange={onChange} />
  </Modal>
)

createPortfolio.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default createPortfolio
