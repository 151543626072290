import axios from 'axios'
import fromPairs from 'lodash/fromPairs'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import keys from 'lodash/keys'
import map from 'lodash/map'
import {
  ADD_NEW_NOTIFICATION,
  LOAD_NOTIFICATIONS_FAILURE,
  LOAD_NOTIFICATIONS_REQUEST,
  LOAD_NOTIFICATIONS_STATS,
  LOAD_NOTIFICATIONS_SUCCESS,
  MARK_NOTIFICATIONS_AS_READ,
  SEND_DEVICE_TOKEN_FAILURE,
  SEND_DEVICE_TOKEN_REQUEST,
  SEND_DEVICE_TOKEN_SUCCESS,
  SEND_PRESENTATION_REQUEST,
} from '~/constants/types/notifications'
import { pushNotifications } from '../api'

const NOTIFICATION_CLEAR_TIME = 10 * 60 * 1000

export const getNotifications = () => ({
  type: LOAD_NOTIFICATIONS_REQUEST,
})

export const getNotificationsSuccess = ({
  results: notifications,
  hasNextPage,
  hasPreviousPage,
  itemCount,
  page,
  pageCount,
  pageSize,
}) => {
  const notificationsById = fromPairs(
    map(notifications, (notification) => [
      notification.notificationLogId,
      notification,
    ])
  )

  const notificationIds = keys(notificationsById)

  return {
    type: LOAD_NOTIFICATIONS_SUCCESS,
    payload: {
      notificationsById,
      notificationIds,
      pagination: {
        hasNextPage,
        hasPreviousPage,
        itemCount,
        page,
        pageCount,
        pageSize,
      },
    },
  }
}

export const getPaginatedNotificationsSuccess = ({
  results: notifications,
  hasNextPage,
  hasPreviousPage,
  itemCount,
  page,
  pageCount,
  pageSize,
}) => {
  const paginatedNotificationsById = fromPairs(
    map(notifications, (notification) => [
      notification.notificationLogId,
      notification,
    ])
  )

  return {
    type: LOAD_NOTIFICATIONS_SUCCESS,
    payload: {
      paginatedNotificationsById,
      pagination: {
        hasNextPage,
        hasPreviousPage,
        itemCount,
        page,
        pageCount,
        pageSize,
      },
    },
  }
}

export const getNotificationsFailure = (error) => ({
  type: LOAD_NOTIFICATIONS_FAILURE,
  payload: error,
  error: true,
})

export const addNewNotification = ({
  title,
  body,
  type,
  notificationLogId = 0,
  notificationSubscriptionId = 0,
  meetingId = null,
  meetingDateTime = null,
  conversationId = null,
}) => {
  const data = { type }

  if (!isNil(meetingId)) {
    data.meetingId = meetingId
  }

  if (!isNil(meetingDateTime)) {
    data.meetingDateTime = meetingDateTime
  }

  if (!isNil(conversationId)) {
    data.conversationId = conversationId
  }

  const payload = {
    notificationLogId,
    notificationSubscriptionId,
    title,
    body,
    data,
  }

  return {
    type: ADD_NEW_NOTIFICATION,
    payload,
  }
}

export const getNotificationStats = ({ read, unread }) => ({
  type: LOAD_NOTIFICATIONS_STATS,
  payload: { read, unread },
})

export const loadNotificationStats = () => (dispatch) => {
  pushNotifications
    .getNotificationStats()
    .then((response) => dispatch(getNotificationStats(response.data.result)))
}

export const loadNotificationsAndStats =
  (force = false) =>
  (dispatch, getState) => {
    const { notificationsById } = getState().notifications
    if (force || isEmpty(notificationsById)) {
      dispatch(getNotifications())

      axios
        .all([
          pushNotifications.getNotifications(),
          pushNotifications.getNotificationStats(),
        ])
        .then(
          axios.spread((notificationsResponse, statsResponse) => {
            dispatch(getNotificationsSuccess(notificationsResponse.data.result))
            dispatch(getNotificationStats(statsResponse.data.result))
          })
        )
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error('Notification retrieval failed:', err)
          dispatch(getNotificationsFailure('Could not retrieve notifications.'))
        })
    }
  }

export const loadNotificationsPaginated = (params) => (dispatch) => {
  dispatch(getNotifications())

  pushNotifications
    .getNotifications(params)
    .then((notificationsResponse) => {
      dispatch(
        getPaginatedNotificationsSuccess(notificationsResponse.data.result)
      )
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error('Notification retrieval failed:', err)
      dispatch(getNotificationsFailure('Could not retrieve notifications.'))
    })
}

export const sendDeviceToken = () => ({
  type: SEND_DEVICE_TOKEN_REQUEST,
})

export const sendDeviceTokenSuccess = (token) => ({
  type: SEND_DEVICE_TOKEN_SUCCESS,
  payload: token,
})

export const sendDeviceTokenFailure = (error) => ({
  type: SEND_DEVICE_TOKEN_FAILURE,
  payload: error,
  error: true,
})

export const postDeviceToken = (platform, token) => (dispatch) => {
  dispatch(sendDeviceToken())

  pushNotifications
    .storeDeviceToken(platform, token)
    .then((response) => {
      dispatch(
        sendDeviceTokenSuccess(
          token,
          get(response.data, 'message', 'Token successfully sent.')
        )
      )
    })
    .catch((error) => {
      if (isNil(error.response)) {
        dispatch(sendDeviceTokenFailure(error.message))
      } else {
        dispatch(
          sendDeviceTokenFailure(
            get(error.response, 'data.message', 'Could not send device token.')
          )
        )
      }
    })
}

export const markNotificationsAsRead = (notifications) => ({
  type: MARK_NOTIFICATIONS_AS_READ,
  payload: notifications,
})

export const markNotificationAsRead = (notificationLogId) => (dispatch) => {
  pushNotifications
    .markNotificationAsRead(notificationLogId)
    .then((response) => {
      const { notificationLogId, isRead } = response.data.result
      dispatch(markNotificationsAsRead({ [notificationLogId]: isRead }))
      dispatch(loadNotificationStats())
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(
        `[actions/notifications.js]::Could not mark notification ${notificationLogId} as read. Error:`,
        err
      )
    })
}

export const markAllNotificationsAsRead = () => (dispatch) => {
  pushNotifications
    .markAllNotificationsAsRead()
    .then((response) => {
      const { result: notifications } = response.data
      dispatch(
        markNotificationsAsRead(
          fromPairs(map(notifications, (n) => [n.notificationLogId, n.isRead]))
        )
      )
      dispatch(loadNotificationStats())
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(
        '[actions/notifications.js]::Could not mark all notifications as read. Error:',
        err
      )
    })
}

export const clearPresentationNotification =
  (id, notificationType) => (dispatch, getState) => {
    const { presentationNotifications } = getState().notifications

    dispatch({
      type: SEND_PRESENTATION_REQUEST,
      payload: presentationNotifications.filter(
        (p) => !(p.id === id && p.notificationType === notificationType)
      ),
    })
  }
export const sendPresentationNotification =
  (notification, notificationType) => (dispatch, getState) => {
    const { presentationNotifications } = getState().notifications
    dispatch({
      type: SEND_PRESENTATION_REQUEST,
      payload: [
        { notificationType, ...notification },
        ...presentationNotifications.filter((p) => p.id !== notification.id),
      ],
    })

    setTimeout(() => {
      dispatch(clearPresentationNotification(notification.id, notificationType))
    }, NOTIFICATION_CLEAR_TIME)
  }
