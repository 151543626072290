import * as React from 'react'
import PropTypes from 'prop-types'
import { useLiveRef, useObjectMemo } from '@context365/hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'

const FormContext = React.createContext({})

export function Form({ schema, defaultValues, context, ...props }) {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    context,
  })

  return <InnerForm {...props} schema={schema} formMethods={methods} />
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
  schema: PropTypes.instanceOf(Yup.BaseSchema).isRequired,
  defaultValues: PropTypes.object,
  context: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onInvalidForm: PropTypes.func,
}

export function InnerForm({
  children,
  schema,
  onSubmit,
  onSuccess,
  onInvalidForm,
  formMethods,
  ...formProps
}) {
  return (
    <FormProvider {...formMethods}>
      <FormContext.Provider
        value={useObjectMemo({ schemaRef: useLiveRef(schema) })}
      >
        <form
          {...formProps}
          onSubmit={formMethods.handleSubmit(async (data, event) => {
            await onSubmit?.(data, event)
            formMethods.reset(data)
            await onSuccess?.(data, event)
          }, onInvalidForm)}
        >
          {children}
        </form>
      </FormContext.Provider>
    </FormProvider>
  )
}

InnerForm.propTypes = {
  children: PropTypes.node.isRequired,
  schema: PropTypes.instanceOf(Yup.BaseSchema).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onInvalidForm: PropTypes.func,
  formMethods: PropTypes.object.isRequired,
}

export function useFieldSchema(name, label) {
  const { schemaRef } = React.useContext(FormContext)
  if (!schemaRef) {
    throw new Error(`${name} field must be rendered within a Form`)
  }

  React.useEffect(() => {
    if (!name || !label || !schemaRef.current) {
      return
    }

    // If we defined a separate label in the schema, keep that one.
    const existingLabel = getLabel(schemaRef.current, name)
    if (existingLabel) {
      return
    }

    Yup.reach(schemaRef.current, name).withMutation((schema) => {
      schema.label(label)
    })
  }, [name, label, schemaRef])

  return collectFieldRules(schemaRef.current, name)
}

function getLabel(schema, name) {
  const innerSchema = Yup.reach(schema, name)
  if (!innerSchema) {
    return undefined
  }

  return innerSchema.describe().label
}

function collectFieldRules(schema, name) {
  const innerSchema = Yup.reach(schema, name)
  if (!innerSchema) {
    return {}
  }

  const { tests } = innerSchema.describe()

  return {
    required: tests.some((test) => test.name === 'required'),
    min: tests.find((test) => test.name === 'min')?.params?.min,
    max: tests.find((test) => test.name === 'max')?.params?.max,
  }
}
