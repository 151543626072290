import PropTypes from 'prop-types'
import { TabButton, TabList, TabPanel, Tabs } from '@context365/tabs'
import InviteCompanyUser from './InviteCompanyUser'
import InvitePlatformUser from './InvitePlatformUser'

export default function InviteForm({ onSendInvitation }) {
  return (
    <div className="mt-5">
      <div className="type-body-semibold-md text-header py-1 border-b">
        Invite
      </div>
      <Tabs>
        <TabList className="mt-3" variant="filled">
          <TabButton id="my-company" label="My Company" />
          <TabButton id="platform-user" label="Other Platform User" />
        </TabList>
        <TabPanel tabId="my-company">
          <InviteCompanyUser onSendInvitation={onSendInvitation} />
        </TabPanel>
        <TabPanel tabId="platform-user">
          <InvitePlatformUser onSendInvitation={onSendInvitation} />
        </TabPanel>
      </Tabs>
    </div>
  )
}

InviteForm.propTypes = {
  onSendInvitation: PropTypes.func.isRequired,
}
