import { useState } from 'react'
import { faFile } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { api as http } from '~/api/services'
import DealProfileContainerModal from '../../DealProfileContainerModal'

const DealComponent = ({ dealId, dealImageUrl, dealTitle }) => {
  const [deal, setDeal] = useState([])
  const [loading, setLoading] = useState(false)
  const [detailsVisible, setDetailsVisibility] = useState(false)
  const fetchDeal = () => {
    setLoading(true)
    http
      .get(`/deals/${dealId}`)
      .then((response) => setDeal(response.data.result.dealItem))
      .catch(() => message.error('Could not load deals'))
      .finally(() => {
        setLoading(false)
        setDetailsVisibility(true)
      })
  }
  return (
    <>
      <div
        className="messaging-chat-company-profile"
        style={{ marginTop: '25px' }}
      >
        <Divider />
        {!isNil(dealImageUrl) && (
          <div className="messaging-chat-company-image">
            <img src={dealImageUrl} />
          </div>
        )}
        <div className="messaging-chat-company-name">{dealTitle}</div>
        <div className="messaging-chat-company-actions">
          <Button
            type="primary"
            className="messaging-chat-company-profile"
            onClick={fetchDeal}
          >
            <FontAwesomeIcon icon={faFile} />
            Show Profile
          </Button>
        </div>
      </div>
      {!isEmpty(deal) && (
        <DealProfileContainerModal
          deal={deal}
          visible={detailsVisible && !loading}
          onCancel={() => {
            setDetailsVisibility(false)
          }}
          showMeetings={false}
          hideActions
        />
      )}
    </>
  )
}
export default DealComponent
