import * as React from 'react'
import PropTypes from 'prop-types'
import { CompareArrows, ExpandLess, ExpandMore, Icon } from '@context365/icons'
import cx from 'classnames'
import { Dialog, DialogDisclosure, useDialogState } from 'reakit/Dialog'
import { Portal } from 'reakit/Portal'
import useTracking from '~/hooks/useTracking'
import FundList from './FundList'
import Header from './Header'
import './CompareFundsDrawer.css'

export default function CompareFundsDrawer({
  funds,
  onCompareFunds,
  onRemoveFund,
  onRemoveAllFunds,
}) {
  const { trackEvent } = useTracking({ component: 'CompareFundsDrawer' })

  const drawerEmptied = React.useRef(false)
  const state = useDialogState({ modal: false, animated: true })
  const fundCount = funds.length
  const previousFundCount = usePreviousValue(fundCount)

  const { show: showDrawer, hide: hideDrawer } = state
  React.useEffect(() => {
    if (previousFundCount === 0 && fundCount > 0) {
      showDrawer()
    }
  }, [fundCount, previousFundCount, showDrawer])

  React.useEffect(() => {
    let timer = 0
    if (fundCount === 0 && drawerEmptied.current) {
      timer = setTimeout(() => {
        drawerEmptied.current = false
        hideDrawer()
      }, 150)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [fundCount, hideDrawer])

  function compareFunds() {
    trackEvent.click({ element: 'Compare Funds' })
    onCompareFunds()
  }

  function removeFund(fund) {
    trackEvent.click({ element: 'Remove', fundId: fund.fundId })
    onRemoveFund(fund)
  }

  function removeAllFunds() {
    trackEvent.click({ element: 'Clear' })
    drawerEmptied.current = true
    onRemoveAllFunds()
  }

  return (
    <Portal>
      <div
        className={cx(
          'compare-funds-drawer',
          state.visible && 'compare-funds-drawer--open'
        )}
      >
        <div className="compare-funds-drawer__tab">
          <DialogDisclosure
            id="compare-funds-toggle"
            state={state}
            className="compare-funds-drawer__toggle-button"
          >
            <CompareArrows className="mr-2" />
            <span className="space-x-1">
              <span>Compare Funds</span>
              {funds.length > 0 && <span>({funds.length})</span>}
            </span>
            <Icon
              as={state.visible ? ExpandMore : ExpandLess}
              className="text-secondary-100 ml-3"
            />
          </DialogDisclosure>
        </div>
        <div className="compare-funds-drawer__content">
          <Dialog
            className="compare-funds-drawer__dialog"
            state={state}
            hideOnClickOutside={false}
            aria-labelledby="compare-funds-toggle"
            tabIndex={funds.length > 0 ? -1 : 0}
          >
            <div className="h-full flex flex-col">
              <Header
                fundCount={funds.length}
                onCompareFunds={compareFunds}
                onRemoveAllFunds={removeAllFunds}
              />
              <FundList
                funds={funds}
                visible={state.visible}
                onRemoveFund={removeFund}
              />
            </div>
          </Dialog>
        </div>
      </div>
    </Portal>
  )
}

CompareFundsDrawer.propTypes = {
  funds: PropTypes.arrayOf(
    PropTypes.shape({
      fundId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      company: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ).isRequired,
  onCompareFunds: PropTypes.func.isRequired,
  onRemoveFund: PropTypes.func.isRequired,
  onRemoveAllFunds: PropTypes.func.isRequired,
}

function usePreviousValue(value) {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}
