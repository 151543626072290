import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@context365/button'
import { DnsOutlined, ListOutlined, PinDropOutlined } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTracking } from 'react-tracking'
import './DiscoverTabSwitch.less'

const DiscoverTabSwitch = ({
  onChange = () => {},
  value,
  setValue,
  includeTableView,
  includeMapView = true,
}) => {
  const { trackEvent } = useTracking()
  const { discoverMapView } = useFlags()

  const onTabChange = useCallback(
    (value) => {
      trackEvent({ eventName: 'click', element: 'DiscoverSwitch', value })
      onChange(value)
      setValue(value)
    },
    [onChange]
  )

  if (!includeTableView && !includeMapView) {
    //don't render this for only 1 option
    return <></>
  }

  return (
    <div className="DiscoverSwitch">
      <Tooltip text="Card view">
        <IconButton
          label="Card View"
          onClick={() => onTabChange('cards')}
          variant={value === 'cards' ? 'filled' : 'link'}
          icon={<DnsOutlined />}
          className="border-none"
        />
      </Tooltip>
      {includeTableView && (
        <Tooltip text="Table view">
          <IconButton
            label="Table View"
            onClick={() => onTabChange('tabular')}
            variant={value === 'tabular' ? 'filled' : 'link'}
            icon={<ListOutlined />}
            className="ml-1 mr-1 border-none"
          />
        </Tooltip>
      )}
      {includeMapView && discoverMapView && (
        <Tooltip text="Map view">
          <IconButton
            label="Map View"
            onClick={() => onTabChange('map')}
            variant={value === 'map' ? 'filled' : 'link'}
            icon={<PinDropOutlined />}
            className="border-none"
          />
        </Tooltip>
      )}
    </div>
  )
}

DiscoverTabSwitch.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  setValue: PropTypes.func,
  includeTableView: PropTypes.bool,
  includeMapView: PropTypes.bool,
}

export default DiscoverTabSwitch
