import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { List } from 'antd'
import isEqual from 'lodash/isEqual'
import SimpleBar from 'simplebar-react'
import ServiceProviderTestimonialCard from './ServiceProviderTestimonialCard'

const ServiceProviderTestimonials = ({ serviceProviders }) => {
  const [serviceProviderList, setServiceProviderList] = useState([])
  const initialItem = {
    fullName: null,
    jobPosition: null,
    description: null,
    title: null,
  }

  useEffect(() => {
    setServiceProviderList(serviceProviders)
  }, [serviceProviders])

  return (
    <SimpleBar
      style={{ height: 'calc(100vh - 200px)', overflowX: 'hidden' }}
      autoHide={false}
    >
      <List
        dataSource={Object.values(serviceProviderList)}
        renderItem={(item) =>
          !isEqual(item, initialItem) && (
            <ServiceProviderTestimonialCard serviceProvider={item} />
          )
        }
      />
    </SimpleBar>
  )
}

ServiceProviderTestimonials.propTypes = {
  serviceProviders: PropTypes.array.isRequired,
}

export default ServiceProviderTestimonials
