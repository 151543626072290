import * as React from 'react'
import useVisibility from '~/hooks/useVisibility'

export default function useAddToListModal() {
  const [items, setItems] = React.useState([])
  const { visible, show, hide } = useVisibility()

  return {
    visible,
    items,
    show: React.useCallback(
      (items) => {
        setItems(items)
        show()
      },
      [show]
    ),
    hide: React.useCallback(() => {
      setItems([])
      hide()
    }, [hide]),
  }
}
