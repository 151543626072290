import { Button } from '@context365/button'
import { Refresh, Warning } from '@context365/icons'

export default function ConversationError() {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-10">
      <Warning className="text-gold-100" size={64} />
      <div className="max-w-sm text-center space-y-4">
        <div className="type-header-sm">Unable to load conversation</div>
        <div>Please try again or choose a different conversation.</div>
      </div>
      <div>
        <Button
          variant="filled"
          iconLeft={<Refresh />}
          onClick={() => window.location.reload()}
        >
          Try again
        </Button>
      </div>
    </div>
  )
}
