import PropTypes from 'prop-types'
import { Col, Divider, Image, Row } from 'antd'
import isEmpty from 'lodash/isEmpty'
import defaultImage from '~/assets/no_image_available.png'
import { addLineBreaks, getClickableLink } from '~/utils/helpers'
import './AllocatorCompanyProfile.less'

const AllocatorCompanyProfile = ({ company }) => (
  <div>
    {!isEmpty(company) && (
      <div>
        <Row style={{ marginTop: '20px' }}>
          <Col span={15}>
            <div>
              <span className="type-subtitle-sm text-header">
                Company Description
              </span>
              <div className="cc-profile-info">
                {addLineBreaks(company.description)}
              </div>
            </div>
          </Col>
          <Col span={2}>
            <Divider type="vertical" style={{ height: '100%' }} />
          </Col>
          <Col span={7}>
            {company.website && (
              <div>
                <span className="type-subtitle-sm text-header">Website</span>
                <div className="ServiceProviderContent-link">
                  {' '}
                  <a
                    href={getClickableLink(company.website)}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {company.website}
                  </a>
                </div>
              </div>
            )}

            {company.imageUrl && (
              <div>
                <span className="type-subtitle-sm text-header">Logo</span>
                <div className="cc-profile-info allocator-image">
                  <Image
                    width={200}
                    src={company.imageUrl}
                    fallback={defaultImage}
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    )}
  </div>
)
AllocatorCompanyProfile.propTypes = {
  company: PropTypes.shape({
    description: PropTypes.string.isRequired,
    videoUrl: PropTypes.string,
    images: PropTypes.array,
    primaryInvestorCategory: PropTypes.string,
    website: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
}
export default AllocatorCompanyProfile
