import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'
import ComposeMessageModal from '../ComposeMessageModal'
import DiscoverFundMeetingRequest from '../DiscoverFundMeetingRequest'
import { FundSubscriptionModal } from '../DiscoverFundResult'
import FundDetailsModal from '../FundDetailsModal'
import MeetingTypeSelector from '../MeetingTypeSelector'

const defaultFundProfile = {}

const FundDetailsModalContainer = ({
  fund,
  showFundProfile,
  onClose,
  roadshowId = 0,
  fromMapView = false,
  coordinates = [],
  arePointsNear,
  showDataroom,
  hideActions,
  fundProfile = defaultFundProfile,
  canSendMessage,
  campaignApplicationId = null,
}) => {
  const [isRequestMeetingModalVisible, setIsRequestMeetingModalVisible] =
    useState(false)
  const [composeModalVisible, setComposeModalVisible] = useState(false)
  const composeModalClosedHandler = () => {
    setComposeModalVisible(false)
  }

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const email = useSelector((state) => state.auth.contact?.email)

  const [showMeetingTypeSelectorModal, setShowMeetingTypeSelectorModal] =
    useState(false)
  const [selectedMeetingTypeId, setSelectedMeetingTypeId] = useState(3)

  const loadingRoadshowList = useSelector((state) => state.roadshow.loading)
  const roadshowList = useSelector((state) => state.roadshow.roadshowList)

  const { showRoadshow } = useFlags()

  const handleSendRequest = useCallback(() => {
    if (
      showRoadshow &&
      (roadshowId > 0 ||
        (fromMapView && !isEmpty(roadshowList) && !loadingRoadshowList))
    )
      setShowMeetingTypeSelectorModal(true)
    else setIsRequestMeetingModalVisible(true)
  }, [fromMapView, loadingRoadshowList, roadshowId, roadshowList, showRoadshow])

  const handleMeetingTypeIdChange = useCallback((e) => {
    setSelectedMeetingTypeId(e.target.value)
  }, [])

  const handleSaveMeetingTypeId = useCallback(() => {
    setShowMeetingTypeSelectorModal(false)
    setIsRequestMeetingModalVisible(true)
  }, [])
  const handleCancelMeetingTypeSelection = useCallback(() => {
    setShowMeetingTypeSelectorModal(false)
  }, [])

  const handleCancelSendMeetingReq = useCallback(() => {
    setIsRequestMeetingModalVisible(false)
  }, [])

  const handleRefreshofSendMeetingReq = useCallback(() => {
    onClose()
  }, [onClose])

  const handleComposeMessage = useCallback(() => {
    setComposeModalVisible(true)
  }, [])

  return (
    <>
      {showFundProfile && (
        <FundDetailsModal
          fundId={fund.fundId}
          fundName={fund.name}
          fundThreshold={fund.threshold}
          campaignApplications={fund.campaignApplications}
          campaignId={fund.campaignId}
          onSendRequest={handleSendRequest}
          onSendMessage={handleComposeMessage}
          meeting={fund}
          contacts={fund.contacts}
          visible={showFundProfile && !isNil(fund)}
          onClose={onClose}
          includeRoadshowMeetings={fromMapView && showRoadshow}
          showDataroom={showDataroom}
          hideActions={hideActions}
          fullFundProfile={fundProfile}
          canSendMessage={canSendMessage}
          isSubscribed={fund.alreadySubscribed}
          onSubscribe={() => setShowSubscriptionModal(true)}
          campaignApplicationId={campaignApplicationId}
        />
      )}

      {fund && (
        <ComposeMessageModal
          visible={composeModalVisible}
          onClose={composeModalClosedHandler}
          companyID={fund.companyId}
          fromFundId={fund.fundId}
        />
      )}
      {isRequestMeetingModalVisible && (
        <DiscoverFundMeetingRequest
          visible={isRequestMeetingModalVisible}
          onClose={handleCancelSendMeetingReq}
          onRefresh={handleRefreshofSendMeetingReq}
          fund={fund}
          roadshowId={roadshowId}
          roadshowList={roadshowList}
          meetingTypeId={selectedMeetingTypeId}
          fromMapView={fromMapView}
          arePointsNear={arePointsNear}
          coordinates={coordinates}
        />
      )}
      {fromMapView && showRoadshow && (
        <MeetingTypeSelector
          isVisible={showMeetingTypeSelectorModal}
          onClose={handleCancelMeetingTypeSelection}
          meetingTypeId={selectedMeetingTypeId}
          setMeetingTypeId={handleMeetingTypeIdChange}
          onOk={handleSaveMeetingTypeId}
        />
      )}
      {showSubscriptionModal && (
        <FundSubscriptionModal
          visible={showSubscriptionModal}
          fundId={fund.fundId}
          email={email}
          onCancel={() => setShowSubscriptionModal(false)}
          onSubscribe={() => {
            setShowSubscriptionModal(false)
          }}
        />
      )}
    </>
  )
}

FundDetailsModalContainer.propTypes = {
  fund: PropTypes.object,
  showFundProfile: PropTypes.bool,
  onClose: PropTypes.func,
  roadshowId: PropTypes.number,
  campaignApplicationId: PropTypes.number,
  fromMapView: PropTypes.bool,
  arePointsNear: PropTypes.func.isRequired,
  coordinates: PropTypes.array,
  showDataroom: PropTypes.bool,
  hideActions: PropTypes.bool,
  fundProfile: PropTypes.object,
  canSendMessage: PropTypes.bool,
}

export default FundDetailsModalContainer
