import PropTypes from 'prop-types'
import { TabButton, TabList, TabPanel, Tabs } from '@context365/tabs'
import { useTracking } from 'react-tracking'

export default function Body({ tabs = [], activeTab, onTabChange, className }) {
  const { trackEvent } = useTracking({ component: 'ProfileBody' })
  const trackClick = (label) =>
    trackEvent({ eventName: 'click', element: 'ProfileBodyTabs', label })

  return (
    <div className={className}>
      <Tabs selectedTab={activeTab} onSelect={onTabChange}>
        <div className="bg-grey-50">
          <TabList className="max-w-5xl mx-auto px-4" variant="underlined">
            {tabs.map((tab) => (
              <TabButton
                id={tab.id}
                key={tab.id}
                label={tab.label}
                onClick={() => trackClick(tab.label)}
              />
            ))}
          </TabList>
        </div>
        <div className="max-w-5xl mx-auto p-4">
          {tabs.map((tab) => (
            <TabPanel key={tab.id} tabId={tab.id} renderMode={tab.renderMode}>
              {tab.content}
            </TabPanel>
          ))}
        </div>
      </Tabs>
    </div>
  )
}

Body.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ),
  activeTab: PropTypes.string,
  onTabChange: PropTypes.func,
}
