import * as React from 'react'
import useFrameState from '~/hooks/useFrameState'

export function useChartWidth(containerRef, offset = 0) {
  const [width, setWidth] = useFrameState(0)

  React.useEffect(() => {
    const container = containerRef.current
    if (!container) {
      return undefined
    }

    const updateWidth = () => {
      setWidth(container.offsetWidth)
    }

    updateWidth()

    window.addEventListener('resize', updateWidth)
    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [containerRef, setWidth])

  return Math.max(0, width + offset)
}
