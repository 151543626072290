import { useCallback, useEffect, useState } from 'react'
import {
  faEnvelope,
  faFile,
  faMobile,
} from '@fortawesome/pro-regular-svg-icons'
import { Alert, Button, Col, Row, message } from 'antd'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import pickBy from 'lodash/pickBy'
import { isMobile } from 'react-device-detect'
import OtpInput from 'react-otp-input'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import * as Yup from 'yup'
import { login, zendeskSso } from '~/actions/auth'
import { api as http } from '~/api/services'
import ContextLogo from '~/assets/new-logo.svg'
import Loading from '~/components/Loading'
import RadioButtonList from '~/components/RadioButtonList'
import { FeatureFlags, MarketingContactPage } from '~/config'
import useSearchParams from '~/hooks/useSearchParams'
import { generateFlagsIdentity } from '~/utils/flags'
import './Login.less'

const schema = Yup.object({
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required('Your email address is required to sign in.'),
  method: Yup.mixed()
    .oneOf(['Email', 'SMS'], 'Should be an email address or mobile number.')
    .required('Provide a method'),
  otp: Yup.string()
    .matches(/^\d{6}$/gi, 'Invalid Access Code.')
    .required('You must provide the access code you received.'),
})

const iconMap = {
  Email: faEnvelope,
  SMS: faMobile,
}

const Steps = {
  Start: 0,
  RequestContactDetails: 1,
  ContactDetailsReceived: 2,
  RequestOTP: 3,
  OTPRequested: 4,
}

const zendeskRedirectValue = 'zendesk_sso'
const zendeskLoginUrl = 'https://context365.zendesk.com/access/jwt?jwt='

const Login = () => {
  const {
    params: { redirect },
  } = useRouteMatch()

  const maxColSpan = 24
  const [isLoading, setIsLoading] = useState(false)
  const [nextColSpan, setNextColSpan] = useState(maxColSpan)
  const [contactId, setContactId] = useState(0)
  const [requestedForEmail, setRequestedForEmail] = useState(null)
  const [requestedForMethod, setRequestedForMethod] = useState(null)
  const [step, setStep] = useState(Steps.Start)
  const [methods, setMethods] = useState([])

  const [errors, setErrors] = useState({})
  const [email, setEmail] = useState('')
  const [method, setMethod] = useState('')
  const [otp, setOTP] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const { push } = useHistory()

  const { state } = useLocation()

  const dispatch = useDispatch()

  const ldClient = useLDClient()

  const cancelColSpan = maxColSpan - nextColSpan

  const showMethods =
    !isEmpty(email) &&
    !errors.email &&
    !isEmpty(methods) &&
    methods.length !== 1
  const showOTP =
    !isEmpty(method) && !errors.method && step === Steps.OTPRequested

  const disableLogin = step === Steps.OTPRequested && !isEmpty(errors)

  const resetForMethodChange = useCallback(() => {
    setOTP('')
    setErrors((prevErrors) => pickBy(prevErrors, (_, key) => key !== 'email'))
    setStep(Steps.ContactDetailsReceived)
  }, [])

  const resetForEmailChange = useCallback(() => {
    setContactId(0)

    setMethods([])
    setMethod('')

    setRequestedForEmail(null)
    setRequestedForMethod(null)

    setOTP('')

    setStep(Steps.Start)
    setErrors({})
  }, [])

  const getContactDetails = useCallback((email) => {
    setIsLoading(true)

    http
      .post('/auth/contact', { email })
      .then((response) => {
        const { contactId, contactMethods } = response.data.result
        setContactId(contactId)
        setMethods(
          map(contactMethods, ({ method }) => ({
            value: method,
            icon: get(iconMap, method, faFile),
          }))
        )

        setErrors((prevErrors) =>
          pickBy(prevErrors, (_, key) => key !== 'method')
        )

        if (contactMethods.length === 1) {
          setMethod(contactMethods[0].method)
          setStep(Steps.RequestOTP)
        } else {
          setStep(Steps.ContactDetailsReceived)
          setIsLoading(false)
        }
      })
      .catch(() => {
        setMethods([])
        setMethod('')
        setOTP('')
        setErrors({ email: 'Please provide a valid email address.' })
        message.error(
          'Could not retrieve contact details for provided email address.'
        )
        setIsLoading(false)
      })
      .finally(() => {
        setRequestedForEmail(email)
        setNextColSpan(maxColSpan / 2)
      })
  }, [])

  const requestOTP = useCallback((contactId, method) => {
    setIsLoading(true)
    http
      .post('/auth/access-code', { contactId, method })
      .then((response) => {
        const otp = get(response, 'data.result.accessCode', '')
        if (!isNil(otp) && /^\d{6}$/gi.test(otp)) {
          setOTP(otp)
        }

        setErrors((prevErrors) => pickBy(prevErrors, (_, key) => key !== 'otp'))

        setStep(Steps.OTPRequested)
      })
      .catch(() => {
        setOTP('')
        message.error('Could not retrieve access code.')
      })
      .finally(() => {
        setIsLoading(false)
        setRequestedForMethod(method)
      })
  }, [])

  useEffect(() => {
    if (
      step === Steps.RequestContactDetails &&
      !isNil(email) &&
      !isEmpty(email) &&
      email !== requestedForEmail
    ) {
      getContactDetails(email)
    }
  }, [redirect, step, email, getContactDetails, requestedForEmail])

  useEffect(() => {
    if (step === Steps.RequestOTP && contactId !== 0 && !isEmpty(method)) {
      requestOTP(contactId, method)
    }
  }, [redirect, step, contactId, method, requestOTP])

  useEffect(() => {
    if (redirect === zendeskRedirectValue) {
      zendeskSso().then((accessToken) => {
        window.location = `${zendeskLoginUrl}${accessToken}`
      })
    }
  }, [redirect])

  const handleMethodChange = useCallback(
    (value) => {
      setMethod(value)
      Yup.reach(schema, 'method')
        .validate(value)
        .then(() => {
          setErrors((prevErrors) =>
            pickBy(prevErrors, (_, key) => key !== 'method')
          )
          if (!isNil(requestedForMethod) && requestedForMethod !== value) {
            resetForMethodChange()
          }
        })
        .catch((err) => {
          setErrors((prevErrors) => ({ ...prevErrors, method: err.message }))
        })
    },
    [requestedForMethod, resetForMethodChange]
  )

  const handleEmailChange = useCallback(
    (e) => {
      const { value } = e.target

      setEmail(value)
      setErrors((prevErrors) => pickBy(prevErrors, (_, key) => key !== 'email'))
      if (!isNil(requestedForEmail) && requestedForEmail !== value) {
        resetForEmailChange()
      }
    },
    [requestedForEmail, resetForEmailChange]
  )

  const validateEmail = useCallback(async () => {
    try {
      await Yup.reach(schema, 'email').validate(email)
    } catch (err) {
      setErrors((prevErrors) => ({ ...prevErrors, email: err.message }))
    }
  }, [email])

  const handleEmailBlur = useCallback(async () => {
    await validateEmail(email)
  }, [email, validateEmail])

  const handleEmailKeyPress = useCallback(
    async (e) => {
      if (e.key === 'Enter') {
        await validateEmail(email)
      }
    },
    [email, validateEmail]
  )

  const handleOTPChange = useCallback((value) => {
    setOTP(value)
    Yup.reach(schema, 'otp')
      .validate(value)
      .then(() => {
        setErrors((prevErrors) => pickBy(prevErrors, (_, key) => key !== 'otp'))
      })
      .catch((err) => {
        setErrors((prevErrors) => ({ ...prevErrors, otp: err.message }))
      })
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()

    schema
      .validate({ email, method, otp })
      .then(({ otp }) => {
        setIsLoading(true)

        dispatch(
          login(
            contactId,
            otp,
            redirect === zendeskRedirectValue,
            searchParams?.switchToCompany
          )
        )
          .then(({ payload: { accessToken, claims, contact, company } }) => {
            if (FeatureFlags.enabled) {
              const identity = generateFlagsIdentity(claims, contact, company)
              ldClient.identify(identity)
            }

            let nextPath = '/'

            if (!isEmpty(state)) {
              nextPath = state
            }

            if (redirect === zendeskRedirectValue) {
              window.location = `${zendeskLoginUrl}${accessToken}`
            } else {
              push(nextPath)
            }
          })
          .catch((err) => {
            if (err.response) {
              const { message: msg } = err.response.data
              message.error(msg)
            } else {
              message.error('Could not sign in.')
            }
          })
          .finally(() => {
            setIsLoading(false)
            setSearchParams({ ...searchParams, switchToCompany: null })
          })
      })
      .catch((err) => {
        const { path, message } = err
        setErrors((prevErrors) => ({ ...prevErrors, [path]: message }))

        const newErrors = { ...errors, [path]: message }

        if (step === Steps.Start && !newErrors.email) {
          setStep(Steps.RequestContactDetails)
        }

        if (step === Steps.ContactDetailsReceived && !newErrors.method) {
          setStep(Steps.RequestOTP)
        }
      })
  }

  const handleReset = useCallback(() => {
    setEmail('')
    setMethod('')
    setOTP('')
    setContactId(0)
    setRequestedForEmail('')
    setRequestedForMethod('')
    setMethods([])
    setNextColSpan(maxColSpan)
    setStep(Steps.Start)
  }, [])

  const bullets = [
    'Make new connections',
    'Discover  insights',
    'Close deals anywhere',
  ]
  const description =
    'ApexInvest is the online network for professionals in the alternative investment industry.'

  return (
    <div className="Login sm:flex">
      <div className="Login-sidebar hidden sm:flex">
        <div className="Login-sidebar-info">
          <h3 className="Login-sidebar-info-header text-brand-100">Welcome</h3>
          <ul className="pl-4">
            {map(bullets, (bullet) => (
              <li key={bullet} className="type-body-regular-md text-white my-2">
                <div>{bullet}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="Login-sidebar-description w-1/2">{description}</div>
      </div>
      <div className="Login-main p-4 sm:p-0 sm:ml-16">
        <div className="Login-main-content pt-8 sm:pt-24">
          <a href={MarketingContactPage} target="__blank">
            <img
              alt="ApexInvest"
              src={ContextLogo}
              className="w-full h-auto mb-8 sm:mb-24"
            />
          </a>
          <Loading spinning={isLoading} className="Login-main-content-form">
            <h2 className="Login-main-content-form-title">Sign In</h2>

            <form
              onSubmit={handleSubmit}
              onReset={handleReset}
              autoComplete="off"
              noValidate
            >
              <div className="Login-main-content-form-field">
                <input
                  className="Login-field-email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                  onKeyPress={handleEmailKeyPress}
                  onBlur={handleEmailBlur}
                />
                {errors.email && (
                  <Alert type="error" message={errors.email} showIcon />
                )}
              </div>

              {showMethods && (
                <div className="Login-main-content-form-field">
                  <RadioButtonList
                    label="How do you want to receive your Access Code?"
                    value={method}
                    options={methods}
                    onChange={handleMethodChange}
                    allowClear={true}
                  />
                  {errors.method && (
                    <Alert type="error" message={errors.method} showIcon />
                  )}
                </div>
              )}
              {showOTP && (
                <div className="Login-main-content-form-field">
                  <OtpInput
                    containerStyle="Login-main-content-form-field-otp"
                    numInputs={6}
                    name="otp"
                    inputStyle={{
                      width: isMobile ? '38px' : '42px',
                      height: '48px',
                    }}
                    value={otp}
                    onChange={handleOTPChange}
                  />
                  {errors.otp && (
                    <Alert type="error" message={errors.otp} showIcon />
                  )}
                </div>
              )}
              <div className="Login-main-content-form-actions">
                <Row>
                  <Col span={cancelColSpan}>
                    <Button type="default" htmlType="reset" size="large" block>
                      Cancel
                    </Button>
                  </Col>
                  <Col span={nextColSpan}>
                    <Button
                      data-testid="form-submit-btn"
                      type="primary"
                      htmlType="submit"
                      size="large"
                      block
                      loading={isLoading}
                      disabled={disableLogin}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
                {step === Steps.Start && (
                  <Row>
                    <Col
                      span={maxColSpan}
                      className="Login-main-content-form-contact-column"
                    >
                      Not a member?&nbsp;
                      <a
                        href={MarketingContactPage}
                        target="__blank"
                        className="Login-main-content-form-field"
                      >
                        Gain access by contacting us today.
                      </a>
                    </Col>
                  </Row>
                )}
              </div>
            </form>
          </Loading>
        </div>
      </div>
      <div className="Login-sidebar sm:hidden w-screen p-8 mt-4">
        <div className="Login-sidebar-info">
          <div className="Login-sidebar-info-entries">
            {map(bullets, (bullet) => (
              <h6 key={bullet} className="cc-heading6">
                {bullet}
              </h6>
            ))}
          </div>
        </div>
        <div className="Login-sidebar-description w-full">{description}</div>
      </div>
    </div>
  )
}

export default Login
