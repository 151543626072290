import { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@context365/popovers'
import { Modal, Switch } from 'antd'
import cx from 'classnames'
import { FUND_CONFIRMATION } from '~/constants/disclaimer'

const { confirm } = Modal

const FundVisibilityToggle = ({
  defaultChecked,
  disabled,
  tooltip,
  onChange,
  className,
  label,
}) => {
  const [checked, setChecked] = useState(defaultChecked)
  const Wrapper = tooltip ? Tooltip : Passthrough

  return (
    <div className={cx('flex', className)}>
      {label && <span className="pr-2">{label}</span>}
      <Wrapper text={tooltip} placement="bottom" style={{ zIndex: 999 }}>
        <div>
          <Switch
            checked={checked}
            onChange={(event) => {
              if (event) {
                confirm({
                  title: FUND_CONFIRMATION,
                  okText: 'Yes',
                  cancelText: 'No',
                  onOk() {
                    onChange(false)
                    setChecked(true)
                  },
                })
              } else {
                onChange(true)
                setChecked(false)
              }
            }}
            disabled={disabled}
          />
        </div>
      </Wrapper>
    </div>
  )
}

FundVisibilityToggle.propTypes = {
  defaultChecked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  className: PropTypes.string,
}

export default FundVisibilityToggle

function Passthrough({ children }) {
  return children
}
