import { api as http } from '~/api/services'

export const fetchFundDetails = (fundId) => http.get(`funds/${fundId}/details`)

export const getFundsByCompany = () => http.get('companies/funds')

export const getEventFunds = (eventId) =>
  http.get(`companies/events/${eventId}/funds`)

export const getFundEventsByCompany = () => http.get('companies/fundevents')

export const getTearSheet = (fundId) => http.get(`funds/tearsheet/${fundId}`)
