import * as React from 'react'
import { Empty, Timeline } from 'antd'
import camelCase from 'lodash/camelCase'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import partition from 'lodash/partition'
import moment from 'moment'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom-v5-compat'
import * as api from '~/api'
import { api as http } from '~/api/services'
import {
  ApplyToMandateButton,
  ApplyToMandateModal,
} from '~/components/Application'
import FileCollection from '~/components/FileCollection'
import Loading from '~/components/Loading'
import Profile from '~/components/Profile'
import ProfileOverviewTab from '~/components/ProfileOverviewTab'
import useSearchParams from '~/hooks/useSearchParams'
import { getStatusTag } from '~/utils/helpers'

const MandateProfile = () => {
  const { mandateId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: mandate, isLoading } = useQuery(['mandate', mandateId], () =>
    http
      .get(`/mandates/${mandateId}`)
      .then((res) => res.data.result.mandateItem)
  )
  const [isApplyToMandateModalVisible, setIsApplyToMandateModalVisible] =
    React.useState(false)
  const [applyingFundId, setApplyingFundId] = React.useState(null)

  useQuery(
    ['mandate-profile-log-view', mandateId, mandate?.companyId],
    () =>
      api.mandate.logProfileView({ mandateId, companyId: mandate?.companyId }),
    { enabled: !isEmpty(mandate) }
  )

  const PROFILE_LABELS = {
    overview: ['MandateType', 'TargetCompletionDate', 'MinSize'],
    details: ['Size', 'TargetInvestmentRegion'],
    plan: ['PlanAssets'],
  }

  const getMandatesTabsData = () =>
    map(mandate.profile, (p) => {
      const key = camelCase(p.groupName)
      const [sideSections, sections] = partition(p.sections, (s) =>
        PROFILE_LABELS[key]?.includes(s.key)
      )
      return {
        title: p.groupName,
        sections,
        sideSections,
      }
    })

  const getTabs = () => {
    const tabsData = getMandatesTabsData()
    const documentationTab = tabsData.find((t) => t.title === 'Documentation')

    const tabs = [
      ...map(
        tabsData,
        (t) =>
          t.title !== 'Documentation' &&
          t.title !== 'Updates' && {
            id: camelCase(t.title),
            label: t.title,
            content: (
              <ProfileOverviewTab
                sections={t.sections}
                sideSections={t.sideSections}
              />
            ),
          }
      ),
      !isEmpty(documentationTab) && {
        id: 'documentation',
        label: 'Documentation',
        content: (
          <MandateDocuments
            mandate={mandate}
            documentationTab={documentationTab.sections[0]}
          />
        ),
      },
      {
        id: 'updates',
        label: 'Updates',
        content: <MandateUpdates mandate={mandate} />,
      },
    ]
    return compact(tabs)
  }

  const showApplyToMandateModal = React.useCallback(
    (fundId) => {
      setIsApplyToMandateModalVisible(true)
      if (fundId) {
        setApplyingFundId(fundId)
      }
    },
    [setApplyingFundId]
  )

  const hideApplyToMandateModal = React.useCallback(() => {
    setIsApplyToMandateModalVisible(false)
    setApplyingFundId(undefined)
  }, [])

  if (isLoading) {
    return <Loading />
  }

  const tabs = getTabs()

  return (
    <div className="px-4 md:px-0 mt-8 md:mt-0">
      <Profile.Header
        title={
          <div className="flex space-x-3">
            <span className="flex items-center -ml-2">
              {mandate.status && getStatusTag(mandate.status)}
            </span>
            <span>{mandate.title}</span>
          </div>
        }
        actions={
          mandate.canApply && (
            <ApplyToMandateButton
              mandate={mandate}
              onApply={(fundId) => {
                showApplyToMandateModal(fundId)
              }}
            />
          )
        }
      />
      <Profile.Body
        tabs={tabs}
        onTabChange={(tab) => setSearchParams({ tab })}
        activeTab={
          tabs.find((tab) => tab.id === searchParams.tab)?.id ?? tabs[0].id
        }
        className="bg-white"
      />
      <ApplyToMandateModal
        visible={isApplyToMandateModalVisible}
        onClose={hideApplyToMandateModal}
        mandateId={mandate.mandateId}
        campaignId={mandate.campaignId}
        fundId={applyingFundId}
        mandate={mandate}
        onApply={() => {}}
      />
    </div>
  )
}

export default MandateProfile

function MandateUpdates({ mandate }) {
  const updates = [
    ...mandate.mandateUpdates,
    {
      content: 'Mandate added to ApexInvest',
      addedOn: mandate.addedOn,
    },
  ]
  return (
    <Timeline style={{ marginTop: '24px' }}>
      {map(updates, (update, i) => (
        <Timeline.Item key={i} color="grey">
          <p>{moment.utc(update.addedOn).format('MMMM DD, YYYY')}</p>
          <p>{update.content}</p>
        </Timeline.Item>
      ))}
    </Timeline>
  )
}

function MandateDocuments({ mandate, documentationTab }) {
  switch (mandate.documentAddMean) {
    case 'Upload it':
      return isNil(mandate.documentUpload) ? (
        <Empty description="No documents attached." />
      ) : (
        <FileCollection
          files={[{ mediaUrl: mandate.documentUpload }]}
          title={mandate.title}
          entityId={mandate.mandateId}
          type="mandate"
        />
      )
    case 'Write it':
    default:
      return isNil(mandate.documentText) ? (
        <Empty description="No documents linked." />
      ) : (
        <div style={{ marginBottom: '24px' }}>
          <span className="type-subtitle-sm text-header">
            {documentationTab?.label}
          </span>
          <div className="cc-profile-info">{documentationTab?.value}</div>
        </div>
      )
  }
}
