import axios from 'axios'
import qs from 'qs'
import { api } from './services'

export const getPresentationTypes = () => api.get('/presentations/types')

export const getPresentations = (
  page = 1,
  pageSize = 5,
  types = [],
  onlyAttending = false,
  onlyPresenting = false,
  searchQuery = null,
  presentationId = null
) => {
  const params = qs.stringify(
    {
      q: searchQuery,
      type: types,
      page,
      pageSize,
      attending: onlyAttending,
      presenting: onlyPresenting,
      presentationId,
    },
    { skipNulls: true, indices: false }
  )

  return api.get(`/presentations?${params}`)
}

export const getPastPresentations = (
  page = 1,
  pageSize = 5,
  types = [],
  searchQuery = null
) => {
  const params = qs.stringify(
    {
      q: searchQuery,
      type: types,
      page,
      pageSize,
    },
    { skipNulls: true, indices: false }
  )
  return api.get(`/presentations/past?${params}`)
}

export const getMyPresentations = (types = [], searchQuery = null) => {
  const params = qs.stringify({
    q: searchQuery,
    type: types,
  })

  return api.get(`/contacts/presentations?${params}`)
}

export const getPresentationAccessKeys = (presentationId) =>
  api.get(`/presentations/${presentationId}/access-keys`)

export const registerToPresentation = (presentationId) =>
  api.post(`/presentations/${presentationId}/participants`)

export const startPresentation = (presentationId) =>
  api.patch(`/presentations/${presentationId}/room`)

export const getPresentationAttendeesAndRoles = (id) =>
  axios.all([api.get(`/presentations/${id}`), api.get(`/presentations/roles`)])

export const getPresentationAttendees = (id) => api.get(`/presentations/${id}`)

export const getNewPresentationData = () =>
  axios.all([
    api.get('/presentations/types'),
    api.get('/presentations/segments'),
    api.get('/presentations/colleagues'),
    api.get('/presentations/options'),
    api.get('/presentations/roles'),
  ])

export const handleNewPresentationDraftCreate = (presentation) =>
  api.post('/presentations', presentation)

export const handleNewPresentationDraftEdit = (id, presentation) =>
  api.put(`/presentations/${id}`, presentation)

export const handleNewPresentationPublish = (id, presentation) =>
  api.patch(`/presentations/${id}/publish`, presentation)

export const handleAcceptAttendee = (id, participantId) =>
  api.patch(`/presentations/${id}/participants/${participantId}/status`, {
    status: 'approved',
  })

export const handleRejectAttendee = (id, participantId) =>
  api.patch(`/presentations/${id}/participants/${participantId}/status`, {
    status: 'rejected',
  })

export const handleRemoveAttendee = (id, participantId) =>
  api.patch(`/presentations/${id}/participants/${participantId}/status`, {
    status: 'removed',
  })

export const handleCancelAttendee = (id, participantId) =>
  api.patch(`/presentations/${id}/participants/${participantId}/status`, {
    status: 'canceled',
  })

export const handleAcceptDeclinePresentation = (id, answer) =>
  api.patch(`/presentations/${id}/participants/status`, {
    answer,
  })

export const handleLeavePresentation = (id) =>
  api.delete(`/presentations/${id}/participants/attendance`)

export const handleCancelPresentation = (id) =>
  api.patch(`/presentations/${id}/cancel`)

export const handleDeleteDraftPresentation = (id) =>
  api.delete(`/presentations/${id}`)

export const changePresentionParticipantRole = (id, participantId, newRole) =>
  api.patch(`/presentations/${id}/participants/${participantId}/role`, {
    role: newRole,
  })

export const getPresentationForLobby = () => api.get(`/presentations/lobby`)
