import PropTypes from 'prop-types'
import { Modal } from 'antd'
import Linkify from 'react-linkify'

const AlertNotificationModal = ({ visible, closeModal, notification }) => (
  <Modal
    destroyOnClose
    visible={visible}
    onCancel={() => {
      closeModal()
    }}
    width="50%"
    bodyStyle={{ paddingBottom: '32px' }}
    title={notification.title}
    footer={false}
  >
    <Linkify properties={{ target: '_blank', style: { color: 'blue' } }}>
      {notification.body}
    </Linkify>
  </Modal>
)
AlertNotificationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  notification: PropTypes.shape({
    notificationLogId: PropTypes.number.isRequired,
    notificationSubscriptionId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    data: PropTypes.shape({
      type: PropTypes.string,
      meetingId: PropTypes.string,
      meetingDateTime: PropTypes.string,
    }),
    notificationType: PropTypes.string.isRequired,
    isRead: PropTypes.bool.isRequired,
  }).isRequired,
}

export default AlertNotificationModal
