import { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faCheck,
  faFile,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'

const RadioButtonItem = ({
  value,
  selected = false,
  label = null,
  icon = null,
  allowClear = false,
  onSelected = null,
}) => {
  const refClear = useRef()
  const [checkIcon, setCheckIcon] = useState(faCheck)

  const handleClick = useCallback(
    (e) => {
      if (isFunction(onSelected)) {
        const shouldClear =
          !isNil(refClear.current) &&
          (refClear.current === e.target || refClear.current.contains(e.target))
        onSelected(shouldClear ? null : value)
      }
    },
    [onSelected, value]
  )

  const handleMouseEnter = useCallback(() => {
    setCheckIcon(faTimesCircle)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setCheckIcon(faCheck)
  }, [])

  const clearProps = allowClear
    ? {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      }
    : {}

  if (isNil(icon)) {
    icon = faFile
  }
  label = isNil(label) || isEmpty(label) ? value : label

  const itemClass = classNames('RadioButtonItem', {
    'RadioButtonItem-selected': selected,
  })

  return (
    <div className={itemClass} onClick={handleClick} role="option">
      {selected && (
        <span
          className="RadioButtonItem-selected-check"
          ref={refClear}
          {...clearProps}
        >
          <FontAwesomeIcon icon={checkIcon} size="lg" />
        </span>
      )}
      <span className="RadioButtonItem-icon">
        <FontAwesomeIcon icon={icon} size="2x" />
      </span>
      <span className="RadioButtonItem-label">{label}</span>
    </div>
  )
}

RadioButtonItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  selected: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.object,
  allowClear: PropTypes.bool,
  onSelected: PropTypes.func,
}

export default RadioButtonItem
