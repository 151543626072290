import PropTypes from 'prop-types'
import moment from 'moment'
import ContactList from '../ContactList'
import './MeetingWith.less'

const MeetingWith = ({
  companyName,
  meetingDateTime,
  contacts,
  meetingType,
}) => {
  const mt = moment.utc(meetingDateTime).local()
  const startTime = mt.format('h:mm A')
  const endTime = mt.add(30, 'minutes').format('h:mm A')

  return (
    <div className="MeetingWith">
      <div className="MeetingWith-header">{meetingType} meeting with:</div>
      <div className="MeetingWith-details">
        <span className="MeetingWith-details-company">{companyName}</span>
        <span className="MeetingWith-details-at">at</span>
        <span className="MeetingWith-details-time">
          {startTime}
          {' - '}
          {endTime}
        </span>
      </div>
      <ContactList className="MeetingWith-contacts" contacts={contacts} />
    </div>
  )
}

MeetingWith.propTypes = {
  companyName: PropTypes.string.isRequired,
  meetingDateTime: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.number.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      contactName: PropTypes.string.isRequired,
      initials: PropTypes.string.isRequired,
      imageUrl: PropTypes.string,
    }).isRequired
  ).isRequired,
  meetingType: PropTypes.oneOf(['Online', 'Summit', 'Roadshow']),
}

export default MeetingWith
