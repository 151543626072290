import { useContext, useState } from 'react'
import { Button, IconButton } from '@context365/button'
import {
  ArrowBack,
  CheckOutlined,
  CloseOutlined,
  PendingActionsOutlined,
} from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import { Modal, Switch, Table, message } from 'antd'
import startCase from 'lodash/startCase'
import { useSelector } from 'react-redux'
import * as api from '~/api'
import { getUserId } from '~/selectors/auth'
import PresentationAttendeesCtx from '~/utils/contexts/PresentationAttendeesCtx'
import ComposeMessageModal from '../ComposeMessageModal'
import HorizontalInfoGraph from '../HorizontalInfoGraph'
import ProfileViewer from '../ProfileViewer'
import PresentationRoleComponent from './PresentationRoleComponent'
import './PresentationAttendeesComponent.less'

const PresentationAttendeesComponent = () => {
  const {
    presentationParticipants,
    title,
    approved,
    pending,
    rejected,
    invited,
    roles,
    isEnded,
    id,
    goBack,
    handleGetPresentationAttendees,
    canceled,
    isLive,
    isPublished,
  } = useContext(PresentationAttendeesCtx)

  const total = presentationParticipants.length

  const contactId = useSelector(getUserId)

  const getCategory = (role) => {
    switch (role) {
      case 'Manager':
        return 1
      case 'Allocator':
        return 2
      case 'Service Provider':
        return 3
      case 'CFN':
        return 6
      default:
        return 0
    }
  }

  const [page, setPage] = useState(1)
  const [messageModal, setMessageModal] = useState({
    isVisible: false,
    companyId: 0,
  })

  const handleAccept = (record) => {
    api.presentations
      .handleAcceptAttendee(id, record.id)
      .then(() => handleGetPresentationAttendees())
      .catch(() => message.error('Could not accept attendee'))
  }

  const handleReject = (record) => {
    api.presentations
      .handleRejectAttendee(id, record.id)
      .then(() => handleGetPresentationAttendees())
      .catch(() => message.error('Could not reject attendee'))
  }

  const handleStatusChange = (checked, record) =>
    checked ? handleAccept(record) : handleReject(record)

  const canBeRemoved = (record) =>
    record.participantRole !== 'Moderator' &&
    record.participantRole !== 'Presenter' &&
    record.participantRole !== 'Admin' &&
    record.status === 'Approved'

  const canHaveToggle = (record) =>
    (record.status === 'Approved' || record.status === 'Rejected') &&
    record.participantRole === 'Attendee' &&
    !record.isInvited

  const hasToggleElements = (participants) => {
    const filteredParticipants = participants.filter((p) => canHaveToggle(p))
    return filteredParticipants.length > 0 && !isEnded
  }

  const handleCancelPresentation = () => {
    api.presentations
      .handleCancelPresentation(id)
      .then(() => {
        message.success(`Presentation canceled successfully`)
        goBack()
      })
      .catch(() => message.error('Could not cancel presentation'))
  }
  const handleDeleteDraft = () => {
    api.presentations
      .handleDeleteDraftPresentation(id)
      .then(() => {
        message.success(`Draft Presentation deleted successfully`)
        goBack()
      })
      .catch(() => message.error('Could not delete draft presentation'))
  }

  const renderContactActions = (record) =>
    record.isGuest ? (
      <div className="flex ml-3">{record.email}</div>
    ) : (
      <div className="flex">
        {getCategory(record.companyRole) !== 1 && (
          <ProfileViewer
            companyCategoryId={getCategory(record.companyRole)}
            companyContactId={null}
            contactId={record.contactId}
            companyId={record.companyId}
            fundId={record.fundId}
          >
            <Tooltip placement="top" text="View Profile">
              <Button variant="link">View Profile</Button>
            </Tooltip>
          </ProfileViewer>
        )}
        {record.contactId !== contactId && (
          <Tooltip placement="top" text="Send Message">
            <Button
              onClick={(e) => {
                e.stopPropagation()
                setMessageModal({
                  isVisible: true,
                  companyId: record.companyId,
                  contactId: record.contactId,
                })
              }}
              variant="link"
            >
              Send Message
            </Button>
          </Tooltip>
        )}
      </div>
    )
  const confirmAction = (onConfirm, action) => {
    Modal.confirm({
      title:
        action === 'remove'
          ? 'Are you sure you want to remove this participant?'
          : action === 'cancel'
          ? 'Are you sure you want to cancel this invitation?'
          : action === 'cancel presentation'
          ? 'Are you sure you want to cancel this presentation?'
          : action === 'delete draft'
          ? 'Are you sure you want to delete this draft presentation?'
          : `Are you sure you want to ${action} this registration request?`,
      onOk: onConfirm,
      cancelText: 'Close',
      okText: startCase(action),
    })
  }

  const renderToggle = (record) =>
    canHaveToggle(record) && (
      <Tooltip
        placement="top-start"
        text={
          record.status === 'Approved' ? 'Reject request' : 'Approve request'
        }
      >
        <Switch
          defaultChecked={record.status === 'Approved'}
          onChange={(e) => handleStatusChange(e, record)}
        />
      </Tooltip>
    )

  const renderRole = (record) => (
    <PresentationRoleComponent participant={record} />
  )

  const renderStatus = (record) => {
    switch (record.status) {
      case 'Approved':
        return (
          <div className="flex text-green-100">
            <CheckOutlined />
            Accepted
          </div>
        )
      case 'Rejected':
        return (
          <Tooltip
            placement="top-start"
            text={
              record.isInvited ? 'Rejected by participant' : 'Rejected by admin'
            }
          >
            <div className="flex text-red-100">
              <CloseOutlined />
              Rejected
            </div>
          </Tooltip>
        )
      case 'Removed':
        return (
          <Tooltip text="Participant is removed from presentation">
            <div className="flex text-red-100">
              <CloseOutlined />
              Removed
            </div>
          </Tooltip>
        )
      case 'Canceled':
        return (
          <Tooltip text="Invitation for participant is cancelled">
            <div className="flex text-red-100">
              <CloseOutlined />
              Attendee cancelled
            </div>
          </Tooltip>
        )
      case 'Pending':
        return (
          <Tooltip text="Invitation request for participant is still pending">
            <div className="flex text-blue-100">
              <PendingActionsOutlined />
              Pending
            </div>
          </Tooltip>
        )
      default:
        return null
    }
  }

  const renderActions = (record) =>
    record.status === 'Pending' && record.isInvited ? (
      <Tooltip placement="top" text="Cancel Invitation">
        <Button
          variant="filled"
          status="error"
          onClick={(e) => {
            e.stopPropagation()
            const handleReject = () => {
              api.presentations
                .handleCancelAttendee(id, record.id)
                .then(() => handleGetPresentationAttendees())
                .catch(() => message.error('Could not reject attendee'))
            }
            confirmAction(handleReject, 'cancel')
          }}
        >
          Cancel
        </Button>
      </Tooltip>
    ) : record.status === 'Pending' && !record.isInvited ? (
      <div className="cc-meeting-actions">
        <Tooltip placement="top" text="Reject">
          <Button
            variant="link"
            status="error"
            onClick={(e) => {
              e.stopPropagation()
              confirmAction(() => handleReject(record), 'reject')
            }}
          >
            Reject
          </Button>
        </Tooltip>
        <Tooltip placement="top" text="Accept">
          <Button
            type="primary"
            status="success"
            variant="filled"
            onClick={(e) => {
              e.stopPropagation()
              confirmAction(() => handleAccept(record), 'accept')
            }}
          >
            Accept
          </Button>
        </Tooltip>
      </div>
    ) : canBeRemoved(record) ? (
      <Tooltip placement="top" text="Remove attendee from presentation">
        <Button
          onClick={(e) => {
            e.stopPropagation()
            const handleRemove = () => {
              api.presentations
                .handleRemoveAttendee(id, record.id)
                .then(() => handleGetPresentationAttendees())
                .catch(() => message.error('Could not remove attendee'))
            }
            confirmAction(handleRemove, 'remove')
          }}
          status="error"
          variant="filled"
        >
          Remove
        </Button>
      </Tooltip>
    ) : (
      <div>-</div>
    )

  const getColumns = () => {
    const columns = [
      {
        title: 'FULL NAME',
        render: (record) => record.name,
      },
      {
        title: 'PARTICIPANT ROLE',
        // className: 'PresentationRole',
        render: renderRole,
      },
      {
        title: 'COMPANY ROLE',
        render: (record) => (record.isGuest ? 'Guest' : record.companyRole),
      },
      {
        title: 'CONTACT',
        render: renderContactActions,
        align: 'left',
        notRenderInCard: true,
      },
      {
        title: 'STATUS',
        render: renderStatus,
        align: 'left',
        notRenderInCard: true,
        width: 150,
      },
      canceled || isEnded
        ? {}
        : {
            title: 'ACTIONS',
            render: renderActions,
            align: 'center',
            notRenderInCard: true,
            width: 150,
          },
      isEnded
        ? {
            title: 'HAS ATTENDED',
            render: (record) => (record.hasAttended ? 'Yes' : 'No'),
            notRenderInCard: true,
            width: 250,
            align: 'center',
          }
        : {},
    ]
    const changeStatusButton = {
      title: 'CHANGE STATUS',
      render: renderToggle,
      align: 'center',
      notRenderInCard: true,
      width: 50,
    }

    const filteredStatusColumns = hasToggleElements(presentationParticipants)
      ? columns.concat(changeStatusButton)
      : columns

    return filteredStatusColumns
  }

  const headerButtons = () => {
    if (canceled) return <div className="flex text-red-100">Canceled</div>
    if (!isPublished)
      return (
        <Button
          variant="link"
          status="error"
          size="big"
          onClick={(e) => {
            e.stopPropagation()
            confirmAction(() => handleDeleteDraft(), 'delete draft')
          }}
        >
          Delete Draft
        </Button>
      )
    if (!isLive && !isEnded)
      return (
        <Button
          variant="link"
          status="error"
          size="big"
          onClick={(e) => {
            e.stopPropagation()
            confirmAction(
              () => handleCancelPresentation(),
              'cancel presentation'
            )
          }}
        >
          Cancel Presentation
        </Button>
      )
    return <div />
  }

  return (
    <div className="ReceivedRequestsTable">
      <div className="type-header-md mt-2 ">
        <section className="grid grid-cols-12 gap-4">
          <div className="col-span-10">{title} attendees</div>
          {headerButtons()}
        </section>
      </div>
      <div className="grid grid-cols-12 gap-10 mt-6 mb-2 w-full">
        <div className="col-span-6">
          <div className="type-subtitle-sm text-gray-900 mb-2">ATTENDEES</div>
          <HorizontalInfoGraph
            part1={approved}
            part2={pending}
            part3={rejected}
            total={total}
          />
        </div>

        <div className="col-span-6">
          <div className="type-header-xs text-gray-900 mb-2">
            {approved}/{total} Approved
          </div>
          <div className="flex type-body-regular-sm text-gray-900 mb-6">
            <div className="mr-4">{roles.manager} Fund Manager(s)</div>
            <div className="mr-4">{roles.allocator} Allocator(s)</div>
            <div className="mr-4">{roles.cfn} CFN</div>
            <div>{roles.serviceProvider} Service Provider(s)</div>
          </div>
          <div className="type-header-xs mb-2">{invited} Invited</div>
        </div>
      </div>
      <Table
        locale={{
          emptyText: 'No data',
        }}
        columns={getColumns()}
        rowKey={(record) => record.meetingId}
        dataSource={presentationParticipants}
        pagination={{
          current: page,
          pageSize: 10,
          total,
        }}
        onChange={(pagination) => {
          setPage(pagination.current)
        }}
      />
      {messageModal.isVisible && (
        <ComposeMessageModal
          visible={messageModal.isVisible}
          onClose={() =>
            setMessageModal({
              isVisible: false,
              companyId: 0,
            })
          }
          companyID={messageModal.companyId}
          selectAll
          showContactsAttendingEvent={false}
          selectedContactId={messageModal.contactId}
        />
      )}
    </div>
  )
}

export default PresentationAttendeesComponent
