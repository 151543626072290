import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Empty } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchCompanyIfNeeded } from '~/actions/company'
import Loading from '~/components/Loading'
import SettingsColleagues from '~/components/SettingsColleagues'
import SettingsFunds from '~/components/SettingsFunds'
import { getCompanyId } from '~/selectors/auth'

const CompanyContainer = ({ type }) => {
  const { push } = useHistory()

  const isFetching = useSelector((state) => state.company.isFetching)
  const companyId = useSelector(getCompanyId)
  const company = useSelector((state) => state.company.company)

  const dispatch = useDispatch()

  const fetchCompany = useCallback(
    () => dispatch(fetchCompanyIfNeeded(companyId)),
    [dispatch, companyId]
  )

  useEffect(() => fetchCompany(), [companyId, fetchCompany])

  // const tab = get(queryString.parse(search), 'tab', 'colleagues');

  return (
    <Loading spinning={isFetching}>
      {company === null ? (
        <Empty />
      ) : type === 'company' ? (
        <SettingsColleagues
          company={company}
          push={push}
          fetchCompany={fetchCompany}
        />
      ) : (
        <SettingsFunds company={company} push={push} />
      )}
    </Loading>
  )
}

CompanyContainer.propTypes = {
  type: PropTypes.string,
}

export default CompanyContainer
