import { api } from './services'

const logPageView = ({ fundId, mandateId, fileUrl, pageCount, page }) =>
  fireAndForget(
    api.post('reader/log', {
      ToFundId: fundId,
      ToMandateId: mandateId,
      DocumentURL: fileUrl,
      TotalPages: pageCount,
      SelectedPage: page,
      Mobile: false,
    })
  )

export const logFundPageView = (fundId, props) =>
  logPageView({ ...props, fundId, mandateId: null })

export const logMandatePageView = (mandateId, props) =>
  logPageView({ ...props, mandateId, fundId: null })

export const logAttachmentView = {
  fund: (entityId) =>
    fireAndForget(api.post(`funds/marketingmaterials/${entityId}/saveLog`)),
  deal: (entityId) =>
    fireAndForget(api.post(`deals/dealdocuments/${entityId}/saveLog`)),
  mandate: (entityId) =>
    fireAndForget(api.post(`mandates/mandatedocuments/${entityId}/saveLog`)),
}

function fireAndForget(request) {
  return request.catch(() => {
    // do nothing and stop errors reporting to the console
  })
}

export const downloadDocument = (link) =>
  api.get('download', { params: { link } }).then((res) => res.data.result)
