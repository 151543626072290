import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Send } from '@context365/icons'
import { useSelector } from 'react-redux'
import Profile from '~/components/Profile'
import SendConnectionModal from '~/components/SendConnectionModal'
import UpgradeModal from '~/components/UpgradeModal'
import { Free } from '~/constants/tiers'
import useTracking from '~/hooks/useTracking'
import { getTier } from '~/selectors/auth'
import ComposeMessageModal from '../ComposeMessageModal'

export default function ContactProfileHeader({ contact, profileUrl }) {
  const tier = useSelector(getTier)
  const [composeModalVisible, setComposeModalVisible] = useState(false)
  const [sendConnectionModalVisible, setSendConnectionModalVisible] =
    useState(false)
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false)
  const { trackEvent } = useTracking({ element: 'ContactProfile' })

  return (
    <>
      <Profile.Header
        profileUrl={profileUrl}
        imageUrl={contact.imageUrl}
        title={contact.fullName}
        subtitle={contact.jobTitle}
        actions={
          <>
            {contact.isConnected && (
              <Button
                iconLeft={<Send />}
                onClick={() => {
                  trackEvent.click({ label: 'send message' })
                  setComposeModalVisible(true)
                }}
              >
                Send Message
              </Button>
            )}
            {contact.canSendConnection && (
              <Button
                variant="filled"
                onClick={() => {
                  trackEvent.click({ label: 'send connection request' })
                  if (tier === Free) {
                    setUpgradeModalVisible(true)
                  } else {
                    setSendConnectionModalVisible(true)
                  }
                }}
              >
                Send Connection Request
              </Button>
            )}
          </>
        }
      />
      <SendConnectionModal
        visible={sendConnectionModalVisible}
        contact={contact}
        onClose={() => setSendConnectionModalVisible(false)}
      />
      <ComposeMessageModal
        visible={composeModalVisible}
        onClose={() => setComposeModalVisible(false)}
        companyID={null}
        companies={contact.companies}
        requiredContactId={contact.contactId}
        selectAll={false}
      />
      <UpgradeModal
        closable
        visible={upgradeModalVisible}
        onClose={() => {
          setUpgradeModalVisible(false)
        }}
      />
    </>
  )
}

ContactProfileHeader.propTypes = {
  contact: PropTypes.object.isRequired,
  profileUrl: PropTypes.string,
}
