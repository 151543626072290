import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { CheckCircleOutlined } from '@context365/icons'
import { Radio } from 'antd'
import map from 'lodash/map'

const LiquidBullets = [
  'hedge funds and CTAs',
  'funds with lots of liquidity',
  'funds with short lock up periods',
]
const IlliquidBullets = [
  'private equity and tangible assets',
  'funds that lack liquidity',
  'funds with long lock up periods',
]

const Bullets = ({ title, items }) => (
  <>
    <span className="FundTypeStep-BulletHeadline">{title}</span>
    <div className="FundTypeStep-BulletGroup">
      {map(items, (item) => (
        <div className="FundTypeStep-Bullet">
          <CheckCircleOutlined color="green" />
          <span>{item}</span>
        </div>
      ))}
    </div>
  </>
)

const RadioOption = ({
  title,
  description,
  bulletsHeadline,
  bullets,
  value,
}) => (
  <Radio value={value} className="FundTypeStep-Radio pb-4">
    <div className="FundTypeStep-RadioContainer">
      <h3>{title}</h3>
      <p>{description}</p>
      <div>
        <Bullets title={bulletsHeadline} items={bullets} />
      </div>
    </div>
  </Radio>
)

const FundTypeStep = ({ onSave }) => {
  const [fundTypeId, setFundTypeId] = useState(null)

  return (
    <div className="mx-2 mt-4 flex flex-col gap-8 justify-center">
      <div>Which type of fund would you like to create?</div>
      <div>
        <Radio.Group
          className="flex flex-col lg:grid lg:grid-cols-2 gap-8"
          onChange={(e) => setFundTypeId(e.target.value)}
        >
          <RadioOption
            title="Liquid fund"
            description="This profile showcases monthly returns and generates a tearsheet."
            bulletsHeadline="The liquid fund category includes most:"
            bullets={LiquidBullets}
            value={1}
          />
          <RadioOption
            title="Illiquid fund"
            description="This profile showcases quarterly or annual returns, vintage funds, and IRR related metrics."
            bulletsHeadline="The illiquid fund category includes most:"
            bullets={IlliquidBullets}
            value={2}
          />
        </Radio.Group>
      </div>
      <div className="flex justify-center">
        <Button
          onClick={() => onSave(fundTypeId)}
          disabled={!fundTypeId}
          variant="filled"
        >
          Next
        </Button>
      </div>
    </div>
  )
}

FundTypeStep.propTypes = {
  onSave: PropTypes.func,
}

export default FundTypeStep
