import { useEffect, useState } from 'react'
import { Modal } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { getFundsByCompany } from '~/actions/fundList'
import * as api from '~/api'
import ApproveReturns from '~/components/ApproveReturns'

const ReturnApprovalContainer = () => {
  const role = useSelector((state) => state.auth.role)
  const isManager = role === 'Manager'

  const [funds, setFunds] = useState([])
  const [visible, setVisible] = useState(false)
  const [fundUpdates, setFundUpdates] = useState([])

  useEffect(() => {
    if (isManager) {
      getFundsByCompany().then((response) => {
        setFunds(response.data.result)
      })
    }
  }, [isManager])

  useEffect(() => {
    if (isManager && funds.length > 0) {
      const fundIds = funds.map((f) => f.fundId)
      api.fundCharts.logInIfNeeded().then(() => {
        api.fundCharts.getFundEmailUpdates(fundIds).then((response) => {
          if (!isEmpty(response.data)) {
            setFundUpdates(response.data)
            setVisible(true)
          }
        })
      })
    }
  }, [funds, isManager])

  return (
    <Modal
      visible={visible}
      width={720}
      zIndex={998}
      footer={null}
      onCancel={() => setVisible(false)}
      maskClosable={false}
    >
      <ApproveReturns
        fundUpdates={fundUpdates}
        onAllApproved={() => setVisible(false)}
      />
    </Modal>
  )
}

export default ReturnApprovalContainer
