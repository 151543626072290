import PropTypes from 'prop-types'
import { Button, DatePicker, Input } from 'antd'
import isNil from 'lodash/isNil'
import moment from 'moment'
import useFormAnswers from '~/utils/hooks/useFormAnswers'
import '../Fields.less'

const LinkListField = ({ question }) => {
  const { answer, handleTextAnswerChange } = useFormAnswers(question)
  const parsedValue =
    answer.responseText === '' || isNil(answer.responseText)
      ? []
      : JSON.parse(answer.responseText)

  const handleAddNewForm = () => {
    const newValue = [
      ...parsedValue,
      { Content: '', AddedOn: moment().format('YYYY MM DD') },
    ]
    handleTextAnswerChange(JSON.stringify(newValue))
  }

  const handleDeleteForm = (key) => {
    const newValue = parsedValue.filter((_, id) => id !== key)
    handleTextAnswerChange(JSON.stringify(newValue))
  }

  const handleItemChanged = (index, key, newValue) => {
    parsedValue[index][key] = newValue
    handleTextAnswerChange(JSON.stringify(parsedValue))
  }

  const date = (a) =>
    a.AddedOn.length === 0 ? { value: moment() } : { value: moment(a.AddedOn) }

  return (
    <>
      <Button type="link" onClick={handleAddNewForm}>
        + Add Update
      </Button>
      {answer.responseText !== '' &&
        !isNil(answer.responseText) &&
        JSON.parse(answer.responseText).map((a, key) => (
          <div key={key}>
            <div className="LinkList-title">Update #{key + 1}</div>
            <Input.TextArea
              className="TextArea"
              autoSize={{ minRows: 8 }}
              value={a.Content}
              onChange={(e) =>
                handleItemChanged(key, 'Content', e.target.value)
              }
            />
            <div className="LinkList-title">Date</div>
            <DatePicker
              className="DateField"
              name={question.name}
              {...date(a)}
              allowClear={false}
              onChange={(_, dateString) =>
                handleItemChanged(key, 'AddedOn', dateString)
              }
            />
            <div className="LinkList-title">
              <Button type="link" onClick={() => handleDeleteForm(key)} danger>
                - Delete
              </Button>
            </div>
          </div>
        ))}
    </>
  )
}

LinkListField.propTypes = {
  question: PropTypes.shape({
    name: PropTypes.string,
  }),
}

export default LinkListField
