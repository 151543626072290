import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { message } from 'antd'
import { filter } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { api as http } from '~/api/services'
import SummitMeetingRequest from './SummitMeetingRequest'

const SummitMeetingRequestContainer = ({
  visible,
  fund,
  company,
  onClose,
  onRefresh,
  eventId,
  creditsAvailable,
  contextPointsAvailable,
  isOptimized = false,
}) => {
  const role = useSelector((state) => state.auth.role)
  const [funds, setFunds] = useState([])

  useEffect(() => {
    if (role === 'Manager' && visible && company.companyId > 0)
      http
        .get(`companies/events/${eventId}/toCompany/${company.companyId}/funds`)
        .then((response) => {
          const funds = filter(
            response.data.result,
            (x) => x.hasActiveMeetingRequest === false
          )
          if (isEmpty(funds)) {
            message.warning(
              `All of your funds have active or declined meetings with ${
                fund?.name ||
                company?.name ||
                company?.company?.name ||
                company?.companyName
              }`
            )
            onClose()
          } else {
            setFunds(funds)
          }
        })
        .catch((err) => {
          err?.response?.status === 400
            ? message.warning(err.response.data)
            : message.error('Something went wrong.')
        })
  }, [
    company?.name,
    company.companyId,
    company?.company?.name,
    company?.companyName,
    eventId,
    fund,
    role,
    visible,
    onClose,
  ])

  return (
    ((role === 'Manager' && !isEmpty(funds)) || role !== 'Manager') && (
      <SummitMeetingRequest
        visible={visible}
        funds={funds}
        fund={fund}
        company={company}
        onClose={onClose}
        onRefresh={onRefresh}
        eventId={eventId}
        creditsAvailable={creditsAvailable}
        contextPointsAvailable={contextPointsAvailable}
        isOptimized={isOptimized}
      />
    )
  )
}

SummitMeetingRequestContainer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onRefresh: PropTypes.func,
  fund: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  eventId: PropTypes.number,
  creditsAvailable: PropTypes.number.isRequired,
  contextPointsAvailable: PropTypes.number,
  isOptimized: PropTypes.bool,
}

export default SummitMeetingRequestContainer
