import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Col,
  DatePicker,
  InputNumber,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd'
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik'
import map from 'lodash/map'
import SimpleBar from 'simplebar-react'
import * as Yup from 'yup'
import './UpdateReturnsExcel.less'

const { Title, Text } = Typography

const schema = Yup.object().shape({
  returns: Yup.array()
    .of(
      Yup.object().shape({
        monthEndDate: Yup.date()
          .typeError('Please enter a valid date')
          .required('Month end date is required'),
        return: Yup.number('Returns should be a number.')
          .required('Returns is required.')
          .typeError('Returns should be a number.'),
        aum: Yup.number('AUM should be a number.')
          .required('AUM is required.')
          .positive('AUM cannot be negative.')
          .min(1000, 'AUM must be greater than 1000')
          .typeError('AUM should be a number'),
      })
    )
    .required()
    .min(1),
})

const EditReturnsExcelForm = ({ initialValues, onSubmit }) => {
  const handleFormSubmit = useCallback(
    (values) => onSubmit?.(values.returns),
    [onSubmit]
  )

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={false}
      validateOnMount={true}
      validationSchema={schema}
      onSubmit={handleFormSubmit}
    >
      {({ values, submitForm, isSubmitting, setTouched }) => (
        <Form className="EditExcelForm">
          <Row>
            <Col span={8}>
              <Title level={5}>Month End Date</Title>
            </Col>
            <Col span={8}>
              <Title level={5}>Return (%)</Title>
            </Col>
            <Col span={8}>
              <Title level={5}>AUM</Title>
            </Col>
          </Row>
          <hr
            style={{
              border: 0,
              height: 0,
              borderTop: '1px solid rgba(0, 0, 0, 0.1)',
              marginTop: '5px',
              marginBottom: '5px',
            }}
          />
          <SimpleBar style={{ maxHeight: 400 }}>
            <FieldArray
              style={{ overflowX: 'hidden' }}
              name="returns"
              render={({ remove }) => (
                <Space direction="vertical" style={{ width: '100%' }}>
                  {values.returns.map((ret, i) => {
                    return (
                      <Row key={i}>
                        <Col span={6}>
                          <Field name={`returns.${i}.monthEndDate`}>
                            {({ field, form, meta }) => (
                              <DatePicker
                                className={
                                  meta.error
                                    ? 'Field-error full-width'
                                    : 'full-width'
                                }
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(
                                    `returns.${i}.monthEndDate`,
                                    value.endOf('month').startOf('day')
                                  )
                                }}
                                onBlur={() => {
                                  form.setFieldTouched(
                                    `returns.${i}.monthEndDate`
                                  )
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage name={`returns.${i}.monthEndDate`}>
                            {(msg) => <Text type="danger">{msg}</Text>}
                          </ErrorMessage>
                        </Col>
                        <Col span={8}>
                          <Field name={`returns.${i}.return`}>
                            {({ field, form, meta }) => (
                              <InputNumber
                                formatter={(value) =>
                                  value === '' ? value : `${value}%`
                                }
                                parser={(value) =>
                                  value.replace(/[^-\d.]/g, '')
                                }
                                className={
                                  meta.error
                                    ? 'Field-error full-width'
                                    : 'full-width'
                                }
                                value={field.value}
                                onChange={(value) =>
                                  form.setFieldValue(
                                    `returns.${i}.return`,
                                    value
                                  )
                                }
                                onBlur={() =>
                                  form.setFieldTouched(`returns.${i}.return`)
                                }
                              />
                            )}
                          </Field>
                          <ErrorMessage name={`returns.${i}.return`}>
                            {(msg) => <Text type="danger">{msg}</Text>}
                          </ErrorMessage>
                        </Col>
                        <Col span={8}>
                          <Field name={`returns.${i}.aum`}>
                            {({ field, form, meta }) => (
                              <InputNumber
                                formatter={(value) =>
                                  `$ ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                  )
                                }
                                parser={(value) =>
                                  value.replace(/\$\s?|(,*)/g, '')
                                }
                                className={
                                  meta.error
                                    ? 'Field-error full-width'
                                    : 'full-width'
                                }
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(`returns.${i}.aum`, value)
                                }}
                                onBlur={() =>
                                  form.setFieldTouched(`returns.${i}.aum`)
                                }
                              />
                            )}
                          </Field>
                          <ErrorMessage name={`returns.${i}.aum`}>
                            {(msg) => <Text type="danger">{msg}</Text>}
                          </ErrorMessage>
                        </Col>
                        <Col span={2}>
                          <Tooltip title="Don't have returns for this month? Click to remove.">
                            <Button danger type="link">
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                onClick={() => remove(i)}
                                color="#FC1811"
                              />
                            </Button>
                          </Tooltip>
                        </Col>
                      </Row>
                    )
                  })}
                </Space>
              )}
            />
          </SimpleBar>
          <hr
            style={{
              border: 0,
              height: 0,
              borderTop: '1px solid rgba(0, 0, 0, 0.1)',
            }}
          />
          <Row>
            <Col span={16} />
            <Col span={8} className="right-align">
              <Button
                type="primary"
                htmlType="button"
                disabled={isSubmitting}
                onClick={() => {
                  const touchedFields = {
                    returns: map(values.returns, () => ({
                      return: true,
                      aum: true,
                      monthEndDate: true,
                    })),
                  }

                  submitForm().catch(setTouched(touchedFields))
                }}
              >
                Validate Returns
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

EditReturnsExcelForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
}

export default EditReturnsExcelForm
