import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Tooltip } from '@context365/popovers'

const SendMeetingRequestToAllocatorButton = ({
  canSendMeetingRequest,
  canSPSendMeetingRequest,
  pastSummit,
  isMeetingRequestButtonEnabled,
  company,
  notValidReason,
  onSendMeetingRequest,
}) => {
  const showSendMeetingRequestButton =
    canSPSendMeetingRequest &&
    ((pastSummit && canSendMeetingRequest) || !pastSummit)

  const sendMeetingReqButtonDisabled =
    !isMeetingRequestButtonEnabled ||
    !canSendMeetingRequest ||
    (company?.disabledMeetingRequest && !pastSummit)

  return (
    showSendMeetingRequestButton &&
    (sendMeetingReqButtonDisabled ? (
      <Tooltip
        text={
          company?.disabledMeetingRequest
            ? 'Meeting requests are currently disabled for this company.'
            : company?.meeting?.meetingStatus === 'Declined'
            ? 'You cannot send another meeting request because your first request has been declined'
            : notValidReason || 'A meeting already exists'
        }
      >
        <div>
          <Button
            variant="filled"
            onClick={onSendMeetingRequest}
            disabled={true}
            className="discover-details-send-meeting-disabled ml-2"
          >
            Send Meeting Request
          </Button>
        </div>
      </Tooltip>
    ) : (
      <Button
        variant="filled"
        className="discover-details-send-meeting ml-2"
        onClick={onSendMeetingRequest}
      >
        Send Meeting Request
      </Button>
    ))
  )
}

SendMeetingRequestToAllocatorButton.propTypes = {
  onSendMeetingRequest: PropTypes.func,
  isMeetingRequestButtonEnabled: PropTypes.bool,
  canSendMeetingRequest: PropTypes.bool,
  canSPSendMeetingRequest: PropTypes.bool,
  pastSummit: PropTypes.bool,
  company: PropTypes.object,
  notValidReason: PropTypes.string,
}

export default SendMeetingRequestToAllocatorButton
