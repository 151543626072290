import { allocatorWorkflow } from './allocatorWorkflow'
import { defaultWorkflow } from './defaultWorkflow'
import { guestWorkflow } from './guestWorkflow'
import { managerWorkflow } from './managerWorkflow'
import { serviceProviderWorkflow } from './serviceProviderWorkflow'

export function getWorkflow(category) {
  switch (category) {
    case 'Allocator':
      return allocatorWorkflow
    case 'Manager':
      return managerWorkflow
    case 'ServiceProvider':
      return serviceProviderWorkflow
    case 'GuestPresentation':
      return guestWorkflow
    default:
      return defaultWorkflow
  }
}
