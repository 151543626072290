import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import { Button } from '@context365/button'
import { Radio, RadioGroup } from '@context365/forms'
import { Modal } from '@context365/modals'
import { message } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import * as api from '~/api'
import { CAMPAIGN_STATUS } from '~/constants/campaignStatus'

const RadioOption = ({ value, badgeType, label, description }) => (
  <div className="flex flex-col">
    <div className="py-2">
      <Badge status={badgeType}>{label}</Badge>
    </div>
    <Radio value={value}>{description}</Radio>
  </div>
)

const CampaignStatusModal = ({
  visible,
  campaignId,
  onSave,
  defaultStatus = CAMPAIGN_STATUS.OPEN,
}) => {
  const [campaignStatus, setCampaignStatus] = useState(defaultStatus)
  const client = useQueryClient()

  const { mutateAsync: updateCampaignStatus } = useMutation(
    (campaignStatusId) =>
      api.campaigns.updateCampaignStatus(campaignId, campaignStatusId),
    {
      onSuccess: () => {
        client.invalidateQueries('campaign', campaignId)
        client.invalidateQueries('dashboardCampaigns')
      },
      onError: (err) => {
        const errorMessage =
          err?.response?.status === 400 ? err?.response?.data : null
        message.error(errorMessage || 'Could not change status.')
      },
    }
  )

  const handleSave = useCallback(() => {
    updateCampaignStatus(campaignStatus).then(() => onSave())
  }, [campaignStatus, updateCampaignStatus, onSave])

  return (
    <Modal
      title="Set Visibility"
      visible={visible}
      footerRight={
        <Button
          variant="filled"
          onClick={() => handleSave()}
          disabled={!campaignStatus}
        >
          Save
        </Button>
      }
    >
      <RadioGroup
        className="flex flex-col px-12 py-6 space-y-4"
        name="campaignStatus"
        value={campaignStatus}
        onChange={(e) => setCampaignStatus(e.target.value)}
      >
        <RadioOption
          value={CAMPAIGN_STATUS.DRAFT}
          label="Draft"
          description="Hide from the discover page for now"
          badgeType="warning"
        />
        <RadioOption
          value={CAMPAIGN_STATUS.OPEN}
          label="Open"
          description="Publish to the discover page"
          badgeType="success"
        />
        <RadioOption
          value={CAMPAIGN_STATUS.CLOSED}
          label="Closed"
          description="Indicate that you've completed your campaign"
          badgeType="info"
        />
      </RadioGroup>
    </Modal>
  )
}

CampaignStatusModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  campaignId: PropTypes.number.isRequired,
  defaultStatus: PropTypes.number,
}

export default CampaignStatusModal
