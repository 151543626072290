import PropTypes from 'prop-types'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import {
  DiscoverController,
  DiscoverFilters,
  DiscoverTabs,
  extractQuestionGroups,
} from '~/components/Discover'
import { getDiscoverResearchCampaignFilterComponent } from '~/components/DiscoverFilters'
import { DiscoverResearchCampaignResult } from '~/components/DiscoverResearchCampaignResult'
import { CAMPAIGN_ENTITY_TYPES } from '~/constants/campaignEntityTypes'
import CreditsIndicator from './CreditsIndicator'
import './DiscoverContainer.less'

const DiscoverResearchCampaignsContainer = ({ tab, resultId }) => {
  const { Track } = useTracking({ page: 'DiscoverResearch' })

  return (
    <Track>
      <CreditsIndicator campaignEntityType={CAMPAIGN_ENTITY_TYPES.RESEARCH} />
      <DiscoverController
        getResults={(params) => api.discovery.researchCampaigns(params)}
        refetchKeys={{ resultId }}
        category="research"
        tab={tab}
        showTableView={false}
        showMapView={false}
        showDiscoverAlerts={false}
        hideListManagement={true}
        getFilters={() =>
          api.filters.getDiscoverFiltersData('researchcampaigns')
        }
        renderFilters={({ filters }) => (
          <DiscoverFilters
            filters={filters}
            hideToggleMatches
            getFilterGroups={extractQuestionGroups()}
            renderFilterControl={getDiscoverResearchCampaignFilterComponent}
          />
        )}
        results={
          <DiscoverTabs
            hideEventAlert={true}
            renderResultCard={({ item }) => (
              <DiscoverResearchCampaignResult researchCampaign={item} />
            )}
          />
        }
      />
    </Track>
  )
}

DiscoverResearchCampaignsContainer.propTypes = {
  tab: PropTypes.string,
  resultId: PropTypes.number,
}

export default DiscoverResearchCampaignsContainer
