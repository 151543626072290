import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const NetworkInicator = ({ participant, dominant }) => {
  const [netQualityLevel, setNetQualityLevel] = useState(0)

  useEffect(() => {
    const printNetworkQualityStats = (networkQualityLevel) => {
      if (netQualityLevel !== networkQualityLevel)
        setNetQualityLevel(networkQualityLevel)
    }
    participant.on('networkQualityLevelChanged', printNetworkQualityStats)

    printNetworkQualityStats(participant.networkQualityLevel)
  }, [netQualityLevel, participant])

  const networkInicator = () => {
    const bars = []
    const height = dominant ? 5 : 3
    for (let i = 1; i <= 5; i++) {
      const calcHeight = `${i * height}px`
      bars.push(
        <div
          className={dominant ? 'dominant-network' : 'sidebar-network'}
          key={i}
          style={{
            backgroundColor: netQualityLevel >= i ? '#69ce2b' : '#e6e7e8',
            height: calcHeight,
          }}
        />
      )
    }
    return bars
  }

  return networkInicator()
}
NetworkInicator.propTypes = {
  participant: PropTypes.object.isRequired,
}
export default NetworkInicator
