import { Empty as EmptyContainer } from 'antd'

export default function Empty() {
  return (
    <div className="compare-funds-drawer__empty">
      <EmptyContainer
        className="flex items-center space-x-4"
        image={EmptyContainer.PRESENTED_IMAGE_SIMPLE}
        description={
          <div>
            <div className="type-header-md text-grey-600 mb-2">
              There&apos;s nothing here yet
            </div>
            <div className="type-body-regular-sm text-grey-600">
              Select a few funds above to get started.
            </div>
          </div>
        }
      />
    </div>
  )
}
