import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'
import last from 'lodash/last'
import split from 'lodash/split'
import { api as http } from '~/api/services'
import FileCollection from '../FileCollection'
import Loading from '../Loading'

const FundAttachments = ({ fundId }) => {
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (isNil(fundId)) {
      setFileList([])
      setLoading(false)
    } else {
      http
        .get(`funds/marketingmaterials/${fundId}/view`)
        .then((response) => {
          if (!isNil(response.data.result) && response.data.result !== '') {
            const listFiles = []
            try {
              response.data.result.forEach((item) => {
                const urlString = decodeURI(item)
                  .replace('[', '')
                  .replace(']', '')
                try {
                  let mediaUrl = ''
                  if (urlString.startsWith('"')) {
                    mediaUrl = new URL(urlString.slice(1, -1))
                  } else {
                    mediaUrl = new URL(urlString)
                  }
                  listFiles.push({
                    name: last(split(decodeURI(mediaUrl.pathname), '/')),
                    mediaUrl: decodeURI(mediaUrl.href),
                  })
                } catch (e) {
                  console.log(e)
                }
              })
            } catch (e) {
              try {
                const mediaUrl = new URL(decodeURI(response.data.result))
                listFiles.push({
                  name: last(split(decodeURI(mediaUrl.pathname), '/')),
                  mediaUrl: decodeURI(mediaUrl.href),
                })
              } catch (ex) {
                console.log(ex)
              }
            }
            setFileList(listFiles)
          }
        })
        .finally(() => setLoading(false))
    }
  }, [fundId])

  return (
    <Loading spinning={loading}>
      <FileCollection
        files={fileList}
        type="fund"
        title="Fund Attachment"
        entityId={fundId}
      />
    </Loading>
  )
}

FundAttachments.propTypes = {
  fundId: PropTypes.number.isRequired,
}

export default FundAttachments
