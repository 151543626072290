import { useCallback, useEffect, useState } from 'react'
import PropType from 'prop-types'
import { Button, Checkbox, message } from 'antd'
import { api as http } from '~/api/services'
import ProfilePictureGroup from '../ProfilePictureGroup'
import './SettingsFundsManagers.less'

const getFundManagers = (fundId) => http.get(`/funds/${fundId}/employees`)
const saveFundManagers = (fundId, selectedColleagueIds) =>
  http.post(`/funds/${fundId}/employees`, selectedColleagueIds)

const SettingsFundsManagers = ({ fundId, fundName, onCancel = () => {} }) => {
  const [colleagues, setColleagues] = useState([])
  const [selectedColleagueIds, setSelectedColleagueIds] = useState([])
  const [postingManagers, setPostingManagers] = useState(false)

  useEffect(() => {
    const loadManagers = async () => {
      const managersRaw = await getFundManagers(fundId)
      setColleagues(managersRaw.data.result)
      const fundManagers = managersRaw.data.result.filter((x) => x.isManager)
      setSelectedColleagueIds(fundManagers.map((x) => x.contactId))
    }

    loadManagers()
  }, [fundId])

  const onSaveChanges = useCallback(async () => {
    if (selectedColleagueIds.length === 0) return
    setPostingManagers(true)
    try {
      await saveFundManagers(fundId, selectedColleagueIds)
      message.success('Fund managers successfully set')
      onCancel()
    } catch (e) {
      message.error('An error occurred while attempting to set managers')
    } finally {
      setPostingManagers(false)
    }
  }, [fundId, selectedColleagueIds, onCancel])

  const onManagerChanged = useCallback(
    (e, contact) => {
      const { checked } = e.target
      if (checked === true) {
        setSelectedColleagueIds([...selectedColleagueIds, contact.contactId])
      } else {
        setSelectedColleagueIds(
          selectedColleagueIds.filter((x) => x !== contact.contactId)
        )
      }
    },
    [selectedColleagueIds]
  )

  const renderCheckbox = useCallback(
    (contact) => {
      const isChecked = selectedColleagueIds.includes(contact.contactId)
      return (
        <div className="messages-compose-contact-checkbox-card">
          <div className="messages-compose-contact-checkbox">
            <Checkbox
              style={{
                width: '20px',
              }}
              checked={isChecked}
              onChange={(e) => onManagerChanged(e, contact)}
            />
          </div>
          <div className="messages-compose-contact-checkbox-image">
            <ProfilePictureGroup
              diameter={24}
              maxImages={1}
              profiles={[
                {
                  image: contact.imageUrl,
                  ...contact,
                },
              ]}
            />
          </div>
          <div className="messages-compose-contact-checkbox-text">
            <div className="cc-small-font messages-compose-contact-checkbox-name">
              {contact.contactName}
            </div>
            <div className="cc-xsmall-font messages-compose-contact-checkbox-title">
              {contact.jobTitle}
            </div>
          </div>
        </div>
      )
    },
    [onManagerChanged, selectedColleagueIds]
  )

  return (
    <div>
      <div className="cc-heading5 settings-funds-managers-header">
        Edit contacts associated with {fundName}
      </div>
      <div className="settings-funds-managers-contacts">
        <span className="settings-funds-managers-contacts-title">
          Select people who are associated with this this fund:&nbsp;
          <span className="cc-body-text settings-funds-managers-contacts-title-fund">
            {fundName}
          </span>
        </span>

        <div className="settings-funds-managers-contacts-list">
          {colleagues.map((x) => renderCheckbox(x))}
        </div>
      </div>
      <div className="settings-funds-managers-footer">
        <Button className="settings-funds-managers-cancel" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          loading={postingManagers}
          disabled={selectedColleagueIds.length === 0}
          className="settings-funds-managers-save"
          onClick={onSaveChanges}
          type="primary"
        >
          Save Changes
        </Button>
      </div>
    </div>
  )
}

SettingsFundsManagers.propTypes = {
  fundId: PropType.number.isRequired,
  fundName: PropType.string,
  onCancel: PropType.func,
}

export default SettingsFundsManagers
