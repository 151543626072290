import PropTypes from 'prop-types'
import {
  UpdateIlliquidReturns,
  UpdateMonthlyReturns,
} from '../../UpdateReturns'
import MissingSection from './MissingSection'

const MissingReturns = ({
  fundId,
  areMonthlyReturnsMissing,
  missingMonthlyReturns,
  areTVPIReturnsMissing,
  missingTVPIReturns,
  areIRRReturnsMissing,
  missingIRRReturns,
  ...actionProps
}) => (
  <>
    {areMonthlyReturnsMissing && (
      <MissingSection title="Recent Performance">
        <UpdateMonthlyReturns
          fundId={fundId}
          missingMonths={missingMonthlyReturns}
          onComplete={actionProps.onNext}
        />
      </MissingSection>
    )}
    {(areTVPIReturnsMissing || areIRRReturnsMissing) && (
      <MissingSection title="Recent Performance">
        <UpdateIlliquidReturns
          fundId={fundId}
          missingIrrReturns={missingIRRReturns}
          missingTvpiReturns={missingTVPIReturns}
          onComplete={actionProps.onNext}
        />
      </MissingSection>
    )}
  </>
)

MissingReturns.propTypes = {
  fundId: PropTypes.number.isRequired,
  areMonthlyReturnsMissing: PropTypes.bool.isRequired,
  missingMonthlyReturns: PropTypes.array.isRequired,
  areTVPIReturnsMissing: PropTypes.bool.isRequired,
  missingTVPIReturns: PropTypes.array.isRequired,
  areIRRReturnsMissing: PropTypes.bool.isRequired,
  missingIRRReturns: PropTypes.array.isRequired,
  onNext: PropTypes.array.isRequired,
}

export default MissingReturns
