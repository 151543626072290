import { useState } from 'react'
import { Button } from '@context365/button'
import { Modal } from '@context365/modals'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import * as api from '~/api'
import { CAMPAIGN_ENTITY_TYPES } from '~/constants/campaignEntityTypes'
import { ILLIQUID_FUND_TYPE, LIQUID_FUND_TYPE } from '~/constants/funds'
import CreateCampaignForm from './CreateCampaignForm'
import SelectCampaignType from './SelectCampaignType'

const CreateOrEditModal = ({ visible, campaign, types, onClose, onSkip }) => {
  const [selectedTypeValue, setSelectedTypeValue] = useState()

  const selectedType = find(types, (type) => {
    if (campaign) {
      return (
        type.campaignEntityTypeId === campaign.campaignEntityTypeId &&
        (campaign.campaignEntityTypeId === CAMPAIGN_ENTITY_TYPES.MANDATE ||
        type.value === campaign.fundIsLiquid
          ? LIQUID_FUND_TYPE
          : ILLIQUID_FUND_TYPE)
      )
    } else {
      return type.value === selectedTypeValue
    }
  })
  const [campaignName, setCampaignName] = useState(campaign?.name)
  const [selectedNotification, setSelectedNotification] = useState(
    campaign?.notificationFrequencyId?.toString() ?? '1'
  )
  const history = useHistory()

  const [showDetailsSettings, setShowDetailsSettings] = useState(
    !isNil(campaign)
  ) //skip ahead to this step when editing an existing campaign

  const queryClient = useQueryClient()
  const createFund = (campaignId) => {
    if (selectedType.value === LIQUID_FUND_TYPE) {
      api.funds
        .createLiquidFund({ fundName: campaignName, campaignId })
        .then((res) => {
          queryClient.invalidateQueries(['myCampaigns'])
          history.push(`/edit-fund/${res.data.result.fundId}/0`)
        })
    } else {
      api.funds
        .createIlliquidFund({ fundName: campaignName, campaignId })
        .then((res) => {
          queryClient.invalidateQueries(['myCampaigns'])
          history.push(`/edit-fund/${res.data.result.fundId}/0`)
        })
    }
  }

  const createCampaign = () => {
    api.campaigns
      .createCampaign({
        name: campaignName,
        campaignEntityTypeId: selectedType.campaignEntityTypeId,
        notificationFrequencyId: selectedNotification,
      })
      .then((campaignId) => {
        if (selectedType.campaignEntityTypeId === CAMPAIGN_ENTITY_TYPES.FUND) {
          createFund(campaignId)
        } else if (
          selectedType.campaignEntityTypeId === CAMPAIGN_ENTITY_TYPES.RESEARCH
        ) {
          queryClient.invalidateQueries(['myCampaigns'])
          history.push(`/create-research/${campaignId}`)
        } else {
          queryClient.invalidateQueries(['myCampaigns'])
          history.push({
            pathname: '/mandate/new',
            search: `?campaignId=${campaignId}`,
          })
        }
      })
  }

  const updateCampaign = () => {
    api.campaigns
      .updateCampaign(campaign.campaignId, {
        name: campaignName,
        campaignStatusId: campaign.campaignStatusId,
        campaignEntityTypeId: campaign.campaignEntityTypeId,
        campaignTagId: campaign.campaignTagId,
        notificationFrequencyId: selectedNotification,
        notificationMethodId: campaign.notificationMethodId,
      })
      .then(() => {
        queryClient.invalidateQueries('myCampaigns')
        queryClient.invalidateQueries('campaign', campaign.campaignId)
        onClose()
      })
  }

  const nextOrSubmit = () => {
    if (showDetailsSettings) {
      isNil(campaign) ? createCampaign() : updateCampaign()
    } else {
      setShowDetailsSettings(true)
    }
  }

  const modalAction = isNil(campaign) ? 'Create Pipeline' : 'Update Pipeline'

  const modalTitle = `${modalAction}${
    selectedType ? `: ${selectedType?.name}` : ''
  }`

  return (
    <Modal
      title={modalTitle}
      visible={visible}
      maxWidth={600}
      footerRight={
        <>
          <Button
            status="default"
            onClick={() => {
              setSelectedTypeValue(null)
              setCampaignName(campaign?.name)
              setSelectedNotification(
                campaign?.notificationFrequencyId?.toString()
              )
              setShowDetailsSettings(!isNil(campaign))
              onClose()
            }}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            onClick={nextOrSubmit}
            disabled={
              (!showDetailsSettings && !selectedTypeValue) ||
              (showDetailsSettings && (!campaignName || !selectedNotification))
            }
          >
            {showDetailsSettings ? modalAction : 'Next'}
          </Button>
        </>
      }
    >
      {!showDetailsSettings && (
        <SelectCampaignType
          types={types}
          onSkip={onSkip}
          selectedType={selectedTypeValue}
          setSelectedType={setSelectedTypeValue}
        />
      )}
      {showDetailsSettings && (
        <CreateCampaignForm
          campaignId={campaign?.campaignId}
          campaignName={campaignName}
          setCampaignName={setCampaignName}
          selectedNotification={selectedNotification}
          setSelectedNotification={setSelectedNotification}
          campaignEntityTypeId={selectedType?.campaignEntityTypeId}
        />
      )}
    </Modal>
  )
}

export default CreateOrEditModal
