import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import {
  DeleteOutlined,
  GroupsOutlined,
  PersonOutline,
} from '@context365/icons'
import { Empty } from 'antd'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { getUserCompanyContactId } from '~/selectors/auth'
import PopConfirm from './PopConfirm'

export default function Lists({ lists, onDeleteList, onShareList }) {
  const { globalLists = [], myLists = [], sharedLists = [] } = useLists(lists)

  return (
    <>
      <div className="type-subtitle-lg text-header">Lists</div>
      <div className="mt-4">
        <ul className="list-none p-0">
          {globalLists.map((list) => (
            <li
              key={list.discoverListId}
              className="py-1 pr-2 pl-4 flex items-center justify-between even:bg-grey-100"
            >
              <span className="type-body-semibold-sm">{list.name}</span>
              <span className="flex items-center gap-3">
                <PopConfirm
                  message={`Are you sure you want to delete the list named "${list.name}"?`}
                  confirmText="Delete"
                  onConfirm={() => onDeleteList(list.discoverListId)}
                >
                  <IconButton
                    variant="link"
                    status="error"
                    icon={<DeleteOutlined />}
                    label={`Delete ${list.name} list`}
                  />
                </PopConfirm>
                {!list.isGlobal && (
                  <Button
                    size="small"
                    onClick={() => onShareList(list.discoverListId)}
                  >
                    Share
                  </Button>
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-5">
        <ListHeader>
          <PersonOutline />
          Your Lists
        </ListHeader>
        <ul className="list-none p-0">
          {myLists.map((list) => (
            <li
              key={list.discoverListId}
              className="py-1 pr-2 pl-4 flex items-center justify-between even:bg-grey-100"
            >
              <span className="type-body-semibold-sm">{list.name}</span>
              <span className="flex items-center gap-3">
                <PopConfirm
                  message={`Are you sure you want to delete the list named "${list.name}"?`}
                  confirmText="Delete"
                  onConfirm={() => onDeleteList(list.discoverListId)}
                >
                  <IconButton
                    variant="link"
                    status="error"
                    icon={<DeleteOutlined />}
                    label={`Delete ${list.name} list`}
                  />
                </PopConfirm>
                {!list.isGlobal && (
                  <Button
                    size="small"
                    onClick={() => onShareList(list.discoverListId)}
                  >
                    Share
                  </Button>
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-4">
        <ListHeader>
          <GroupsOutlined />
          Shared Lists
        </ListHeader>
        {isEmpty(sharedLists) && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="You don't have any shared lists yet."
          />
        )}
        <ul className="list-none p-0">
          {sharedLists.map((list) => (
            <li
              key={list.discoverListId}
              className="py-1 pr-2 pl-4 flex items-center even:bg-grey-100"
            >
              <span className="flex-1 type-body-semibold-sm">{list.name}</span>
              <span className="flex-1">
                <strong>Owner:</strong>{' '}
                {list.usersWithAccess.find((user) => user.isCreator)?.name}
              </span>
              <span>
                <PopConfirm
                  message={`Are you sure you want to leave the list named "${list.name}"?`}
                  onConfirm={() => onDeleteList(list.discoverListId)}
                >
                  <Button status="error" size="small">
                    Leave List
                  </Button>
                </PopConfirm>
              </span>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

Lists.propTypes = {
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      discoverListId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      isGlobal: PropTypes.bool.isRequired,
      usersWithAccess: PropTypes.arrayOf(
        PropTypes.shape({
          companyContactId: PropTypes.number.isRequired,
          isCreator: PropTypes.bool.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  onDeleteList: PropTypes.func.isRequired,
  onShareList: PropTypes.func.isRequired,
}

function ListHeader({ children }) {
  return (
    <div className="type-body-semibold-md text-header flex items-center gap-2 py-1 border-b">
      {children}
    </div>
  )
}

function useLists(lists) {
  const companyContactId = useSelector(getUserCompanyContactId)
  const userOwnsList = (list) =>
    list.usersWithAccess.some(
      (user) => user.companyContactId === companyContactId && user.isCreator
    )

  return groupBy(lists, (list) => {
    if (list.isGlobal) {
      return 'globalLists'
    } else if (isEmpty(list.usersWithAccess) || userOwnsList(list)) {
      return 'myLists'
    } else {
      return 'sharedLists'
    }
  })
}
