import { DetailedAvatar } from '@context365/avatar'
import { Button } from '@context365/button'
import { CheckCircleOutlined, Help, Launch } from '@context365/icons'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom-v5-compat'
import * as api from '~/api'
import InfoPopover from '~/components/InfoPopover'
import { getInitials } from '~/utils'
import WidgetBlock from './WidgetBlock'

const Connections = () => {
  const navigate = useNavigate()
  const { data: newConnections } = useQuery(['dashboardNewConnections'], () =>
    api.connections
      .getConnectionRequests(1, 2, null, '-updatedAt', {
        filter: ['isSender eq true', 'statusId eq 2'],
      })
      .then((res) => res.data.result.results)
  )

  const { data: connectionRequests } = useQuery(
    ['dashboardConnectionRequests'],
    () =>
      api.connections
        .getConnectionRequests(1, 2, null, '-createdAt', {
          filter: ['isSender eq false', 'statusId eq 1'],
        })
        .then((res) => res.data.result.results)
  )

  const client = useQueryClient()

  const { mutateAsync: acceptRequest } = useMutation(
    (connectionRequestId) =>
      api.connections.acceptConnectionRequest(connectionRequestId),
    {
      onSuccess: () => {
        client.invalidateQueries('dashboardConnectionRequests')
      },
    }
  )

  const { mutateAsync: ignoreRequest } = useMutation(
    (connectionRequestId) =>
      api.connections.ignoreConnectionRequest(connectionRequestId),
    {
      onSuccess: () => {
        client.invalidateQueries('dashboardConnectionRequests')
      },
    }
  )

  return (
    <WidgetBlock
      headerLeft="Connections"
      headerRight={
        <Button
          variant="link"
          as={Link}
          to="/community/directory?filter=myconnections%3Dtrue"
          iconRight={<Launch />}
          style={{ padding: 0 }}
          status="secondary"
        >
          See All
        </Button>
      }
      helpContent='Connections are contacts on the platform that you can freely chat with back and forth. To see who you’re connected with, navigate to Community and then filter on "My Connections."'
    >
      <div className="flex flex-col gap-4 mb-9">
        <div className="type-subtitle-md text-grey-800">New Connections</div>
        {isEmpty(newConnections) ? (
          <div>No new connections.</div>
        ) : (
          map(newConnections, (connection) => (
            <div
              key={connection.connectionRequestId}
              className="flex flex-row justify-between"
            >
              <div className="flex flex-row items-center gap-2">
                <CheckCircleOutlined className="text-green-100" />
                {`${connection.toContact.contactName} has accepted your request`}
              </div>
              <Button
                variant="link"
                as={Link}
                to={`/profile/contact/${connection.toContact.contactId}`}
              >
                View Profile
              </Button>
            </div>
          ))
        )}
      </div>
      <div className="flex flex-col gap-4">
        <div className="type-subtitle-md text-grey-800">
          Connection requests
          <InfoPopover popoverBody="People who have asked to connect with you on the platform.">
            <Help size={16} className="text-primary-100 ml-1.5 cursor-help" />
          </InfoPopover>
        </div>
        {isEmpty(connectionRequests) ? (
          <div>No pending connection requests.</div>
        ) : (
          map(connectionRequests, (request) => (
            <div
              key={request.connectionRequestId}
              className="flex flex-row justify-between"
            >
              <DetailedAvatar
                initials={getInitials(request.fromContact.contactName)}
                name={request.fromContact.contactName}
                company={
                  isEmpty(request.fromContact.companies) ||
                  request.fromContact.companies.length > 1
                    ? null
                    : `${request.fromContact.companies[0].category} with ${request.fromContact.companies[0].name}`
                }
                className="cursor-pointer"
                onClick={() =>
                  navigate(`/profile/contact/${request.fromContact.contactId}`)
                }
              />
              <div className="flex flex-row gap-2">
                <Button
                  onClick={() => ignoreRequest(request.connectionRequestId)}
                >
                  Ignore
                </Button>
                <Button
                  variant="filled"
                  status="success"
                  onClick={() => acceptRequest(request.connectionRequestId)}
                >
                  Accept
                </Button>
              </div>
            </div>
          ))
        )}
      </div>
    </WidgetBlock>
  )
}

export default Connections
