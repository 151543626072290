import * as React from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import { FormGroup, FormMessage, getMessageId } from '@context365/forms'
import { useId } from '@context365/hooks'
import { DeleteOutlined } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputNumber, Typography } from 'antd'
import map from 'lodash/map'
import moment from 'moment'
import {
  FormProvider,
  useController,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import SimpleBar from 'simplebar-react'
import * as Yup from 'yup'
import './ReturnsUpdateForm.css'

const { Title, Text } = Typography

const schema = Yup.object().shape({
  returns: Yup.array()
    .of(
      Yup.object().shape({
        year: Yup.number()
          .required('Year is required.')
          .min(1990, 'Please provide a recent year.'),
        month: Yup.number()
          .required('Month is required.')
          .min(
            1,
            'The month should be a number from 1 (January) to 12 (December).'
          )
          .max(
            12,
            'The month should be a number from 1 (January) to 12 (December).'
          ),
        return: Yup.number('Return should be a number.')
          .label('Return')
          .required('Return is required.'),
        aum: Yup.number('AUM should be a number.')
          .label('AUM')
          .required('AUM is required.')
          .typeError('AUM is required.')
          .positive('AUM cannot be negative.')
          .min(1000, 'AUM must be greater than 1000'),
      })
    )
    .required()
    .min(1),
})

export default function ReturnsUpdateForm({ monthEndDates, onSubmit }) {
  const initialValues = React.useMemo(() => {
    const returns = map(monthEndDates, (monthEndDate) => {
      const date = moment(monthEndDate, 'YYYY-MM-DD')
      return {
        date: date.format('MMM Do, YYYY'),
        year: date.year(),
        month: date.month() + 1,
        return: '',
        aum: null,
      }
    })
    return { returns }
  }, [monthEndDates])

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  })

  const { fields, remove } = useFieldArray({
    control: methods.control,
    name: 'returns',
  })

  return (
    <FormProvider {...methods}>
      <form
        className="returns-update-form"
        onSubmit={methods.handleSubmit((values) => {
          onSubmit?.(values.returns)
        })}
      >
        <SimpleBar
          className="overflow-y-auto overflow-x-hidden border-b pb-1 mb-3"
          style={{ maxHeight: 480 }}
        >
          <table className="w-full">
            <colgroup>
              <col className="w-1/4" />
              <col className="w-1/3" />
              <col className="w-1/3" />
              <col className="w-1/12" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">
                  <div>
                    <Title level={5}>Month End Date</Title>
                  </div>
                </th>
                <th
                  scope="col"
                  id="update-returns-return"
                  className="text-center"
                >
                  <div>
                    <Title level={5}>Return (%)</Title>
                  </div>
                </th>
                <th scope="col" id="update-returns-aum" className="text-center">
                  <div>
                    <Title level={5}>AUM</Title>
                  </div>
                </th>
                <td>
                  <div>
                    <Title level={5}>&nbsp;</Title>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              {fields.map((field, index) => (
                <tr key={field.id} className="pb-1">
                  <th scope="row" id={`update-returns-${field.id}-date`}>
                    <Text strong>{field.date}</Text>
                  </th>
                  <td className="px-1">
                    <NumberInput
                      className="w-full"
                      name={`returns.${index}.return`}
                      aria-labelledby={`update-returns-return update-returns-${field.id}-date`}
                      formatter={(value) =>
                        value === '' ? value : `${value}%`
                      }
                      parser={(value) => value.replace(/[^-\d.]/g, '')}
                    />
                  </td>
                  <td className="px-1">
                    <NumberInput
                      className="w-full"
                      name={`returns.${index}.aum`}
                      aria-labelledby={`update-returns-aum update-returns-${field.id}-date`}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </td>
                  <td className="text-center">
                    <Tooltip text="Don't have returns for this month? Click to remove.">
                      <IconButton
                        variant="link"
                        status="error"
                        label={`Remove ${field.date}`}
                        icon={<DeleteOutlined />}
                        onClick={() => remove(index)}
                      />
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </SimpleBar>
        <Button
          type="submit"
          variant="filled"
          className="block w-fit ml-auto"
          disabled={methods.formState.isSubmitting}
        >
          {methods.formState.isSubmitting ? 'Saving...' : 'Save Returns'}
        </Button>
      </form>
    </FormProvider>
  )
}

ReturnsUpdateForm.propTypes = {
  monthEndDates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onSubmit: PropTypes.func,
}

function NumberInput({ name, ...props }) {
  const id = useId(props.id)
  const {
    field,
    fieldState: { error },
  } = useController({ name })

  return (
    <FormGroup controlId={id}>
      <InputNumber
        {...props}
        {...field}
        id={id}
        aria-describedby={getMessageId(id)}
      />
      <FormMessage errorMessage={error?.message} />
    </FormGroup>
  )
}
