import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'

export function ConditionalField({ on: name, when: test, children }) {
  const value = useWatch({ name })
  return test(value) ? children : null
}

ConditionalField.propTypes = {
  on: PropTypes.string.isRequired,
  when: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}
