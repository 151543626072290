export const LOAD_NOTIFICATIONS_REQUEST = 'LOAD_NOTIFICATIONS_REQUEST'
export const LOAD_NOTIFICATIONS_SUCCESS = 'LOAD_NOTIFICATIONS_SUCCESS'
export const LOAD_NOTIFICATIONS_FAILURE = 'LOAD_NOTIFICATIONS_FAILURE'

export const LOAD_NOTIFICATIONS_STATS = 'LOAD_NOTIFICATIONS_STATS'

export const SEND_DEVICE_TOKEN_REQUEST = 'SEND_DEVICE_TOKEN_REQUEST'
export const SEND_DEVICE_TOKEN_SUCCESS = 'SEND_DEVICE_TOKEN_SUCCESS'
export const SEND_DEVICE_TOKEN_FAILURE = 'SEND_DEVICE_TOKEN_FAILURE'

export const MARK_NOTIFICATIONS_AS_READ = 'MARK_NOTIFICATIONS_AS_READ'

export const ADD_NEW_NOTIFICATION = 'ADD_NEW_NOTIFICATION'

export const SEND_PRESENTATION_REQUEST = 'SEND_PRESENTATION_REQUEST'
