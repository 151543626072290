import * as React from 'react'
import PropTypes from 'prop-types'
import { Input, TextArea } from '@context365/forms'
import { Divider, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import trim from 'lodash/trim'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { getAllCountries } from '~/actions/geography'
import * as api from '~/api'
import ArrayField from '~/components/GenericForm/Fields/ArrayField'
import CheckBoxField from '~/components/GenericForm/Fields/CheckBoxField'
import CommonPartnerField from '~/components/GenericForm/Fields/CommonPartnerField'
import DealField from '~/components/GenericForm/Fields/DealField'
import DropDownField from '~/components/GenericForm/Fields/DropDownField'
import HeaderField from '~/components/GenericForm/Fields/HeaderField'
import InputNumberField from '~/components/GenericForm/Fields/InputNumberField'
import InvestmentField from '~/components/GenericForm/Fields/InvestmentField'
import ProfilePictureField from '~/components/GenericForm/Fields/ProfilePictureField'
import RadioField from '~/components/GenericForm/Fields/RadioField'
import GenericForm from '~/components/GenericForm/GenericForm'
import { ROLE } from '~/constants/roles'

const validator = (role) =>
  Yup.object().shape({
    imageUrl: Yup.string().nullable(),
    name: Yup.string().required('Required').typeError('Required'),
    website: Yup.string().required('Required').typeError('Required'),
    firmAum: Yup.number()
      .nullable()
      .min(0, 'Must be a positive number')
      .test(
        'aumManagerAllocator',
        'Required',
        (val) =>
          (role !== ROLE.ALLOCATOR && role !== ROLE.MANAGER) ||
          ((role === ROLE.ALLOCATOR || role === ROLE.MANAGER) && !isNil(val))
      ),
    portfolioSize: Yup.number()
      .nullable()
      .min(0, 'Must be a positive number')
      .test(
        'portfolioAllocator',
        'Required',
        (val) =>
          role !== ROLE.ALLOCATOR || (role === ROLE.ALLOCATOR && !isNil(val))
      ),
    address1: Yup.string().required('Required').typeError('Required'),
    address2: Yup.string().nullable(),
    address3: Yup.string().nullable(),
    countryId: Yup.number().required('Required').typeError('Required'),
    city: Yup.string().required('Required').typeError('Required'),
    stateProvince: Yup.string().nullable(),
    postalCode: Yup.string().nullable(),
    description: Yup.string()
      .required('Required')
      .typeError('Required')
      .test(
        'len',
        ({ value }) =>
          `Must be at least 280 characters. Character Count: ${
            value?.length ?? 0
          }`,
        (val) => trim(val).length >= 280
      ),
    investmentPhilosophy: Yup.string()
      .required('Required')
      .typeError('Required'),
    minimumInvestmentSize: Yup.number()
      .nullable()
      .min(0, 'Must be a positive number')
      .test(
        'lessThanAverage',
        'Minimum investment size must be less than average',
        (val, testContext) =>
          isNil(testContext.parent.averageInvestmentSize) ||
          val <= testContext.parent.averageInvestmentSize
      ),
    averageInvestmentSize: Yup.number()
      .nullable()
      .min(0, 'Must be a positive number')
      .test(
        'lessThanAverage',
        'Average investment size must be greater than minimum',
        (val, testContext) =>
          isNil(testContext.parent.minimumInvestmentSize) ||
          val >= testContext.parent.minimumInvestmentSize
      ),
    primaryInvestorCategoryId: Yup.number()
      .nullable()
      .test(
        'invesftorCategoryAllocator',
        'Required',
        (val) =>
          role !== ROLE.ALLOCATOR || (role === ROLE.ALLOCATOR && !isNil(val))
      ),
    secondaryInvestorCategoryIds: Yup.array(Yup.number()).nullable(),
    investments: Yup.array(
      Yup.object().shape({
        name: Yup.string().required('Required').typeError('Required'),
        securityTypeId: Yup.number().required('Required').typeError('Required'),
        investmentDate: Yup.date().required('Required').typeError('Required'),
        investmentStatusId: Yup.number()
          .required('Required')
          .typeError('Required'),
      })
    ),
    deals: Yup.array(
      Yup.object().shape({
        name: Yup.string().required('Required').typeError('Required'),
        securityTypeId: Yup.number().required('Required').typeError('Required'),
        investmentDate: Yup.date().required('Required').typeError('Required'),
        dealLeadStatusId: Yup.number()
          .required('Required')
          .typeError('Required'),
      })
    ),
    partners: Yup.array(
      Yup.object().shape({
        name: Yup.string().required('Required').typeError('Required'),
        investorCategoryId: Yup.number()
          .required('Required')
          .typeError('Required'),
      })
    ),
    industryIds: Yup.array(Yup.number()),
    geographyIds: Yup.array(Yup.number()),
    verticalIds: Yup.array(Yup.number()),
    vehicleIds: Yup.array(Yup.number()),
    fundDomicileIds: Yup.array(Yup.number()).test(
      'fundDomicileAllocator',
      'Required',
      (val) =>
        role !== ROLE.ALLOCATOR || (role === ROLE.ALLOCATOR && !isEmpty(val))
    ),
    firstLossCapital: Yup.bool()
      .nullable()
      .test(
        'firstLossAllocator',
        'Required',
        (val) =>
          role !== ROLE.ALLOCATOR || (role === ROLE.ALLOCATOR && !isNil(val))
      ),
  })

const steps = (role) => [
  {
    title: 'Company Profile',
    validator: validator(role),
    questions: (values, questionOptions) => [
      {
        questions: [
          {
            name: 'imageUrl',
            title: 'Company Profile Picture',
            isFormField: true,
            component: ProfilePictureField,
            labelClassName: 'text-center type-body-semibold-md',
            extraProps: {
              help: [
                'Drag or click here to upload a picture',
                '(PNG or JPG / Max File Size 5mb)',
              ],
            },
          },
        ],
      },
      {
        cols: 4,
        questions: [
          {
            component: Input,
            name: 'name',
            title: 'Company Name',
            required: true,
          },
          {
            component: Input,
            name: 'website',
            title: 'Website',
            required: true,
          },
          {
            name: 'firmAum',
            isVisible: () => role === ROLE.MANAGER || role === ROLE.ALLOCATOR,
            title: 'Firm AUM',
            isFormField: true,
            required: true,
            component: InputNumberField,
            extraProps: {
              type: 'currency',
            },
          },
          {
            name: 'portfolioSize',
            isVisible: () => role === ROLE.ALLOCATOR,
            title: 'Portfolio Size',
            isFormField: true,
            required: true,
            component: InputNumberField,
            extraProps: {
              type: 'currency',
            },
          },
        ],
      },
      {
        questions: [
          {
            component: HeaderField,
            extraProps: {
              title: `Company Address`,
            },
          },
        ],
      },
      {
        cols: 4,
        questions: [
          {
            component: Input,
            name: 'address1',
            title: 'Street Address',
            required: true,
          },
          {
            component: Input,
            name: 'address2',
            title: 'Address 2',
          },
          {
            component: Input,
            name: 'address3',
            title: 'Address 3',
          },
        ],
      },
      {
        cols: 4,
        questions: [
          {
            name: 'countryId',
            title: 'Country',
            required: true,
            component: DropDownField,
            extraProps: {
              showSearch: true,
              getOptionsMethod: getAllCountries,
              mappingMethod: (vals) =>
                map(vals, (x) => ({ label: x.name, value: x.countryId })),
            },
          },
          {
            component: Input,
            required: true,
            name: 'city',
            title: 'City',
          },
          {
            component: Input,
            name: 'stateProvince',
            title: 'State/Province',
          },
          {
            component: Input,
            name: 'zipcode',
            title: 'Zipcode',
          },
        ],
      },
      {
        questions: [
          {
            name: 'description',
            title: 'Company Description',
            required: true,
            component: TextArea,
            extraProps: { rows: 5, placeholder: 'Type here' },
          },
        ],
      },
      {
        questions: [
          {
            name: 'investmentPhilosophy',
            title: 'Investment Philosophy',
            required: true,
            component: TextArea,
            extraProps: { rows: 5, placeholder: 'Type here' },
          },
        ],
      },
      { questions: [{ component: Divider }] },
      {
        half: true,
        questions: [
          {
            name: 'primaryInvestorCategoryId',
            title: 'Primary Investor Category',
            isVisible: () => role === ROLE.ALLOCATOR,
            isFormField: true,
            required: true,
            component: DropDownField,
            extraProps: {
              options: questionOptions.investorCategories,
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'secondaryInvestorCategoryIds',
            title: 'Secondary Investor Categories',
            isVisible: () => role === ROLE.ALLOCATOR,
            labelClassName: 'type-body-semibold-md',
            isFormField: true,
            component: CheckBoxField,
            extraProps: {
              options: questionOptions.investorCategories,
            },
          },
        ],
      },
      {
        questions: [
          {
            component: HeaderField,
            extraProps: { title: 'Top Holdings' },
          },
        ],
      },
      {
        questions: [
          {
            name: 'investments',
            component: ArrayField,
            extraProps: {
              allowEmpty: true,
              emptyAddText: '+ Add Holding',
              subComponent: InvestmentField,
              subProps: {
                securityTypeOptions: questionOptions.securityTypes,
                investmentStatusOptions: questionOptions.investmentStatuses,
              },
              defaultObject: {
                companyProfileInvestmentId: null,
                name: '',
                securityTypeId: null,
                investmentDate: null,
                investmentStatusId: null,
              },
            },
          },
        ],
      },
      { questions: [{ component: Divider }] },
      {
        questions: [
          {
            component: HeaderField,
            extraProps: { title: 'Deals' },
          },
        ],
      },
      {
        questions: [
          {
            name: 'deals',
            component: ArrayField,
            extraProps: {
              allowEmpty: true,
              emptyAddText: '+ Add Deal',
              subComponent: DealField,
              subProps: {
                securityTypeOptions: questionOptions.securityTypes,
                dealLeadStatusOptions: questionOptions.dealLeadStatuses,
              },
              defaultObject: {
                companyProfileDealId: null,
                name: '',
                securityTypeId: null,
                investmentDate: null,
                dealLeadStatusId: null,
              },
            },
          },
        ],
      },
      { questions: [{ component: Divider }] },
      {
        questions: [
          {
            component: HeaderField,
            extraProps: { title: 'Common Investment Partners' },
          },
        ],
      },
      {
        questions: [
          {
            name: 'partners',
            component: ArrayField,
            extraProps: {
              allowEmpty: true,
              emptyAddText: '+ Add Investment Partner',
              subComponent: CommonPartnerField,
              subProps: {
                investorCategoryOptions: questionOptions.investorCategories,
              },
              defaultObject: {
                companyProfilePartnerId: null,
                name: '',
                investorCategoryId: null,
              },
            },
          },
        ],
      },
      { questions: [{ component: Divider }] },
      {
        questions: [
          {
            component: HeaderField,
            extraProps: {
              title: `Investment Interests`,
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'industryIds',
            title: 'Sector Interests',
            labelClassName: 'type-body-semibold-md',
            isFormField: true,
            component: CheckBoxField,
            extraProps: {
              options: questionOptions.industries,
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'geographyIds',
            title: 'Geography Interests',
            labelClassName: 'type-body-semibold-md',
            isFormField: true,
            component: CheckBoxField,
            extraProps: {
              options: questionOptions.geographies,
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'verticalIds',
            title: 'Vertical Interests',
            labelClassName: 'type-body-semibold-md',
            isFormField: true,
            component: CheckBoxField,
            extraProps: {
              options: questionOptions.verticals,
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'vehicleIds',
            title: 'Vehicle Interests',
            labelClassName: 'type-body-semibold-md',
            isFormField: true,
            component: CheckBoxField,
            extraProps: {
              options: questionOptions.investmentVehicles,
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'fundDomicileIds',
            title: 'Fund Domicile Interest',
            isVisible: () => role === ROLE.ALLOCATOR,
            labelClassName: 'type-body-semibold-md',
            isFormField: true,
            required: true,
            component: CheckBoxField,
            extraProps: {
              options: questionOptions.fundDomiciles,
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'firstLossCapital',
            title: 'First Loss Capital',
            isVisible: () => role === ROLE.ALLOCATOR,
            labelClassName: 'type-body-semibold-md',
            required: true,
            isFormField: true,
            component: RadioField,
            extraProps: {
              hintText: 'Do you only provide first loss capital?',
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ],
              mappingMethod: (x) => x,
            },
          },
        ],
      },
    ],
  },
]

const getInitialValues = () => {
  return new Promise((resolve) => {
    api.companies
      .getCompanyProfileAnswers()
      .then((data) => {
        resolve(data)
      })
      .catch(() => {
        resolve(null)
      })
  })
}

const CompanySettings = ({ onFinish = () => {} }) => {
  const userRole = useSelector((state) => state.auth.role)
  const [options, setOptions] = React.useState(null)

  const defaultValues = {
    name: null,
    imageUrl: null,
    description: null,
    investmentPhilosophy: null,
    firmAum: null,
    minimumInvestmentSize: null,
    averageInvestmentSize: null,
    website: null,
    investments: [],
    deals: [],
    partners: [],
    geographyIds: [],
    industryIds: [],
    vehicleIds: [],
    verticalIds: [],
    primaryInvestorCategoryId: null,
    secondaryInvestorCategoryIds: [],
    countryId: null,
    city: null,
    stateProvince: null,
    address1: null,
    address2: null,
    address3: null,
    postalCode: null,
    fundDomicileIds: [],
    fundDomicileCountryIds: [],
    firstLossCapital: null,
  }

  React.useEffect(() => {
    api.companies.getCompanyProfileOptions().then((opts) => setOptions(opts))
  }, [])

  const handleSave = (vals, formFinished, doneSaving) => {
    if (formFinished) {
      api.companies
        .saveCompanyProfile(vals)
        .then(() => {
          message.success('Profile saved successfully.')
          onFinish()
        })
        .catch(() =>
          message.error(
            'An error occurred while attempting to save your profile.'
          )
        )
        .finally(() => doneSaving())
    } else {
      doneSaving()
    }
  }

  return (
    <GenericForm
      defaultValues={defaultValues}
      getInitialValues={getInitialValues}
      steps={steps(userRole)}
      questionOptions={options}
      onSave={handleSave}
    />
  )
}

CompanySettings.propTypes = { onFinish: PropTypes.func }

export default CompanySettings
