import { useContext } from 'react'
import PropTypes from 'prop-types'
import { ExpandMore } from '@context365/icons'
import { Dropdown, Menu, message } from 'antd'
import * as api from '~/api'
import PresentationAttendeesCtx from '~/utils/contexts/PresentationAttendeesCtx'

const PresentationRoleComponent = ({ participant }) => {
  const {
    id,
    presentationRoles,
    handleGetPresentationAttendees,
    presentationParticipants,
    maxModerators,
    maxPresenters,
    maxAttendees,
    isLive,
    isEnded,
  } = useContext(PresentationAttendeesCtx)

  const validateLimits = (newRole) => {
    const participantsByRole = presentationParticipants.filter(
      (participant) =>
        participant.participantRole === newRole.name &&
        participant.status === 'Approved'
    ).length
    let isValid = true
    switch (newRole.name) {
      case 'Moderator': {
        if (participantsByRole >= maxModerators) {
          message.error(`You can have up to ${maxModerators} moderator`)
          isValid = false
        }
        break
      }
      case 'Presenter': {
        if (participantsByRole >= maxPresenters) {
          message.error(`You can have up to ${maxPresenters} presenters`)
          isValid = false
        }
        break
      }
      case 'Attendee': {
        if (participantsByRole >= maxAttendees) {
          message.error(`You can have up to ${maxAttendees} attendees`)
          isValid = false
        }
        break
      }
      default: {
        isValid = true
      }
    }
    return isValid
  }

  const handleRolesChanged = (participantId, newRole) => {
    if (validateLimits(newRole) === false) {
      return
    }
    api.presentations
      .changePresentionParticipantRole(id, participantId, newRole.id)
      .then(() => {
        message.success('Participant role updated')
        handleGetPresentationAttendees()
      })
      .catch(() => message.error('Could not update role'))
  }

  const menu = (participantId) => (
    <Menu>
      {presentationRoles
        .filter(
          (role) =>
            role.name !== 'Admin' &&
            (!participant.isGuest || role.name !== 'Attendee')
        )
        .map((role) => (
          <Menu.Item
            key={role.id}
            onClick={() => {
              handleRolesChanged(participantId, role)
            }}
          >
            {role.name}
          </Menu.Item>
        ))}
    </Menu>
  )

  return (
    <div>
      {participant.participantRole === 'Admin' || isLive || isEnded ? (
        <div className="PresentationRoleLabel">
          {participant.participantRole}
        </div>
      ) : (
        <Dropdown
          overlay={menu(participant.id)}
          className="text-grey-900 type-body-regular-sm"
        >
          <div className="ant-dropdown-link flex items-center cursor-pointer">
            {participant.participantRole}
            <ExpandMore />
          </div>
        </Dropdown>
      )}
    </div>
  )
}
PresentationRoleComponent.propTypes = {
  participant: PropTypes.object.isRequired,
}

export default PresentationRoleComponent
