import { Button } from '@context365/button'
import { CheckCircle, SendOutlined } from '@context365/icons'
import { MarketingContactPage } from '~/config'
import { Free, Pro } from '~/constants/tiers'
import useTracking from '~/hooks/useTracking'
import { Testimonial } from './common'

const Feature = ({ text }) => (
  <div className="flex flex-row items-center">
    <CheckCircle size={20} className="mr-4 text-green-100 flex-shrink-0" />
    {text}
  </div>
)

const CampaignsPremiumTierBody = () => {
  const { trackEvent } = useTracking({ component: 'CampaignPremiumTierBody' })
  return (
    <div className="bg-gradient-to-b from-gold-90 to-white rounded border-t-4 border-b-4 border-gold-90">
      <div
        className="py-8 px-12 text-center text-primary-90 space-y-4 rounded"
        style={{
          backgroundImage:
            'linear-gradient(176deg, #FFFCF7 35%, transparent calc(35% + 2px))',
        }}
      >
        <div className="type-header-md">Upgrade your Capital Raise</div>
        <div className="type-body-regular-md">
          {`Contact us to learn about how ${Pro} can help you grow your AUM`}
        </div>
        <div className="flex flex-col lg:flex-row gap-6 items-center">
          <div className="border-2 p-4 rounded-big w-72 bg-white">
            <div className="type-header-md">{Free}</div>
            <div className="flex flex-col gap-4 text-left mt-4">
              <Feature text="One fund" />
              <Feature text="Inbound interest" />
              <Feature text="Access to Events" />
            </div>
            <Button disabled variant="filled" size="big" className="mt-4 w-52">
              Current Plan
            </Button>
          </div>
          <div className="border-2 p-4 rounded-big w-72 shadow-12 bg-white">
            <div className="type-header-md">{Pro}</div>
            <div className="type-body-regular-sm">
              Everything in {Free} plus:
            </div>
            <div className="flex flex-col gap-4 text-left mt-4 type-body-semibold-md">
              <Feature text="Access to private and exclusive mandates" />
              <Feature text="Unlimited fund profiles with interactive visualizations & feedback" />
              <Feature text="View & connect with allocators, managers, and service providers" />
            </div>
            <Button
              as="a"
              href={MarketingContactPage}
              target="_blank"
              variant="filled"
              iconLeft={<SendOutlined />}
              size="big"
              className="mt-4 w-52"
              onClick={() => trackEvent.click({ element: 'ContactUs' })}
            >
              Contact Us
            </Button>
          </div>
        </div>
        <Testimonial />
      </div>
    </div>
  )
}

export default CampaignsPremiumTierBody
