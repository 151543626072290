import * as React from 'react'
import { IconButton } from '@context365/button'
import { ChevronLeft, ChevronRight, Close } from '@context365/icons'
import isEmpty from 'lodash/isEmpty'
import Empty from './Empty'
import usePagedScrolling from './usePagedScrolling'

export default function FundList({ funds, visible, onRemoveFund }) {
  const scrollContainer = React.useRef()
  const { hasPrev, scrollToPrevious, hasNext, scrollToNext } =
    usePagedScrolling(scrollContainer, visible, funds.length)

  return (
    <div className="flex flex-1 relative">
      {isEmpty(funds) && <Empty />}
      <ul ref={scrollContainer} className="compare-funds-drawer__grid">
        {funds.map((fund) => (
          <li key={fund.fundId} className="compare-funds-drawer__fund">
            <div className="min-w-0 py-2 pl-1 flex flex-col">
              <span className="type-subtitle-sm truncate">{fund.name}</span>
              <span className="type-body-regular-xs truncate">
                {fund.company?.name}
              </span>
            </div>
            <IconButton
              className="flex-shrink-0"
              variant="link"
              status="secondary"
              label={`Remove ${fund.name}`}
              icon={<Close />}
              onClick={() => onRemoveFund(fund)}
            />
          </li>
        ))}
      </ul>
      <div className="compare-funds-drawer__page-control compare-funds-drawer__page-control--prev">
        <IconButton
          variant="link"
          size="big"
          disabled={!hasPrev}
          label="Previous"
          icon={<ChevronLeft />}
          onClick={scrollToPrevious}
        />
      </div>
      <div className="compare-funds-drawer__page-control compare-funds-drawer__page-control--next">
        <IconButton
          variant="link"
          size="big"
          disabled={!hasNext}
          label="Next"
          icon={<ChevronRight />}
          onClick={scrollToNext}
        />
      </div>
    </div>
  )
}
