import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import Loading from '~/components/Loading'
import Profile from '~/components/Profile'
import ContactProfileBody from './ContactProfileBody'
import ContactProfileHeader from './ContactProfileHeader'

export default function ContactProfile({ contactId }) {
  const { Track } = useTracking({ page: 'ContactProfile' })
  const { data: contact, isLoading } = useQuery(
    ['contactProfile', contactId],
    () => api.contacts.getContactProfile({ contactId })
  )

  if (isLoading) {
    return <Loading />
  }

  return (
    <Track>
      <Profile
        header={<ContactProfileHeader contact={contact} />}
        body={<ContactProfileBody contact={contact} />}
      />
    </Track>
  )
}

const idPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number])

ContactProfile.propTypes = {
  contactId: idPropType.isRequired,
}
