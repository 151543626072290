import { useCallback, useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import isNil from 'lodash/isNil'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchEventsIfNeeded } from '~/actions/events'
import {
  fetchAllMeetingRequestsByCompany,
  fetchUpcomingMeetings,
} from '~/actions/meetings'
import CenteredPrimaryRadioButtons from '~/components/CenteredPrimaryRadioButtons'
import ConfirmedMeetingsTable from '~/components/ConfirmedMeetingsTable'
import DeclinedRequestsTable from '~/components/DeclinedRequestsTable'
import ToggleMeetingRequests from '~/components/ToggleMeetingRequests'
import useWindowSize from '~/hooks/useWindowSize'
import { existsActiveSummit } from '~/selectors/events'
import ReceivedRequestsTable from './ReceivedRequestsTable'
import SentRequestsTable from './SentRequestsTable'

const { TabPane } = Tabs

const EventMeetingsTab = ({
  eventId = 0,
  subtab = 'received',
  tab = '/events',
  path = '',
  confirmedMeetings = null,
  confirmedMeetingLimit = null,
  disabledMeetingRequest = false,
}) => {
  const { width } = useWindowSize()
  const [activeTab, setActiveTab] = useState(subtab || 'received') // active tab key should correspond to active radio button on CenteredPrimaryRadioButtons

  const requestsNumber = useSelector((state) => {
    if (eventId > 0)
      return state.meetings.requestedMeetingsCompanyEventId.itemCount
    else return state.meetings.requestedMeetings.itemCount
  })
  const { push } = useHistory()
  const radioHandler = (e) => {
    setActiveTab(e.target.value)
    push(`${path}${tab}/${e.target.value}`)
  }
  const initialState = {
    refreshConfirmed: false,
    refreshSent: false,
    refreshCancelled: false,
  }
  const role = useSelector((state) => state.auth.role)

  const dispatch = useDispatch()

  const fetchEvents = useCallback(
    (eventId) => {
      if (eventId === 0) dispatch(fetchEventsIfNeeded())
    },
    [dispatch]
  )

  useEffect(() => fetchEvents(eventId), [fetchEvents, eventId])

  const activeSummit = useSelector(existsActiveSummit)

  const reducer = (state, action) => {
    switch (action.type) {
      case 'Accept':
        return { ...state, refreshConfirmed: !state.refreshConfirmed }
      case 'Decline/Cancel':
        return { ...state, refreshCancelled: !state.refreshCancelled }
      case 'Send':
        return { ...state, refreshSent: !state.refreshSent }
      default:
        return state
    }
  }

  const [refreshTable, dispatchMeetingActions] = useReducer(
    reducer,
    initialState
  )

  const BUTTONS = [
    {
      value: 'received',
      badgeCount: requestsNumber,
    },
    {
      value: 'confirmed',
    },
    {
      value: 'sent',
    },
    {
      value: 'declined',
    },
  ]

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          width: '100%',
        }}
      >
        <div style={{ width: '95%' }}>
          <CenteredPrimaryRadioButtons
            selectedKey={activeTab}
            buttons={BUTTONS}
            OnClickFunction={radioHandler}
          />
        </div>
        <div style={{ width: '5%', textAlign: 'right' }}>
          {!isNil(confirmedMeetingLimit) &&
            !isNil(confirmedMeetings) &&
            !isNil(eventId) &&
            eventId > 0 && (
              <ToggleMeetingRequests
                confirmedMeetingLimit={confirmedMeetingLimit}
                confirmedMeetings={confirmedMeetings}
                disabledMeetingRequest={disabledMeetingRequest}
                eventId={eventId}
                placement="left"
              />
            )}
        </div>
      </div>
      <Tabs
        animated={false}
        tabBarStyle={{ display: 'none' }}
        activeKey={activeTab}
      >
        <TabPane tab="Received" key="received">
          <ReceivedRequestsTable
            confirmedMeetings={confirmedMeetings}
            confirmedMeetingLimit={confirmedMeetingLimit}
            disabledMeetingRequest={disabledMeetingRequest}
            activeSummit={eventId > 0 || activeSummit}
            width={width}
            fromSummit={eventId > 0}
            eventId={eventId}
            meetingType="Summit"
            meetingTypeId={1}
            role={role}
            onAcceptRequest={() => {
              dispatchMeetingActions({ type: 'Accept' })
              dispatch(
                fetchUpcomingMeetings({
                  page: 1,
                  pageSize: 4,
                })
              )
              dispatch(
                fetchAllMeetingRequestsByCompany({
                  page: 1,
                  pageSize: 3,
                })
              )
              dispatch(fetchEventsIfNeeded(eventId))
            }}
            onCancelRequest={() => {
              dispatchMeetingActions({ type: 'Decline/Cancel' })
              dispatch(
                fetchAllMeetingRequestsByCompany({
                  page: 1,
                  pageSize: 3,
                })
              )
            }}
          />
        </TabPane>
        <TabPane tab="Confirmed" key="confirmed">
          <ConfirmedMeetingsTable
            activeSummit={eventId > 0 || activeSummit}
            width={width}
            fromSummit={eventId > 0}
            eventId={eventId}
            meetingType="Summit"
            refreshTable={refreshTable.refreshConfirmed}
            onCancelRequest={() => {
              dispatch(fetchEventsIfNeeded(eventId))
              dispatchMeetingActions({ type: 'Decline/Cancel' })
            }}
          />
        </TabPane>
        <TabPane tab="Sent" key="sent">
          <SentRequestsTable
            activeSummit={eventId > 0 || activeSummit}
            width={width}
            fromSummit={eventId > 0}
            eventId={eventId}
            meetingType="Summit"
            refreshTable={refreshTable.refreshSent}
            onCancelRequest={() => {
              dispatchMeetingActions({ type: 'Decline/Cancel' })
            }}
          />
        </TabPane>
        <TabPane tab="Declined" key="declined">
          <DeclinedRequestsTable
            activeSummit={eventId > 0 || activeSummit}
            width={width}
            fromSummit={eventId > 0}
            eventId={eventId}
            meetingType="Summit"
            refreshTable={refreshTable.refreshCancelled}
            onSendRequest={() => {
              dispatchMeetingActions({ type: 'Send' })
            }}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}

EventMeetingsTab.propTypes = {
  eventId: PropTypes.number,
  subtab: PropTypes.string,
  tab: PropTypes.string,
  path: PropTypes.string,
  confirmedMeetings: PropTypes.number,
  confirmedMeetingLimit: PropTypes.number,
  disabledMeetingRequest: PropTypes.bool,
}
export default EventMeetingsTab
