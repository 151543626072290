import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import {
  Close,
  InfoOutlined,
  PersonRemoveOutlined,
  PictureInPictureOutlined,
  PresentToAllOutlined,
} from '@context365/icons'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CloudMeetUrl } from '~/config'
import { getCompanyId } from '~/selectors/auth'
import { NotificationTypes } from './notificationTypes'

const variants = {
  default: 'bg-grey-300 text-black',
  success: 'bg-green-100 text-white',
  info: 'bg-blue-100 text-white',
  danger: 'bg-red-100 text-white',
  warning: 'bg-gold-110 text-white',
}

const notificationTypeDecorations = {
  [NotificationTypes.PresentationStarted]: {
    Icon: PresentToAllOutlined,
    variant: 'success',
    message: 'has started',
  },
  [NotificationTypes.PresentationEnded]: {
    Icon: PersonRemoveOutlined,
    variant: 'warning',
    message: 'has concluded',
  },
  [NotificationTypes.PresentationIsComing]: {
    Icon: PictureInPictureOutlined,
    variant: 'info',
    message:
      'is starting soon. You will be able to join once the moderator starts the event.',
  },
}

const PresentationNotification = ({
  id,
  cloudMeetRoomId,
  canViewRecording,
  title,
  type,
  onClose = null,
  currentCompanyId = null,
  cloudMeetRoomKey = '',
  cloudMeetCurrentParticipantKey = '',
}) => {
  const companyId = useSelector(getCompanyId)

  const {
    Icon = InfoOutlined,
    variant = 'default',
    message = 'has started',
  } = notificationTypeDecorations[type] ?? {}

  let action = null
  if (
    type === NotificationTypes.PresentationStarted &&
    companyId === currentCompanyId
  ) {
    action = (
      <Button
        as={Link}
        to={`/community/presentations/${id}/room`}
        target="_blank"
        size="small"
        onClick={() => onClose?.(id, type)}
      >
        Join Presentation
      </Button>
    )
  }
  if (type === NotificationTypes.PresentationEnded && canViewRecording) {
    action = (
      <Button
        as="a"
        variant="filled"
        size="small"
        status="default"
        href={`${CloudMeetUrl}/attendee/notes/${cloudMeetRoomId}?roomKey=${cloudMeetRoomKey}${
          isEmpty(cloudMeetCurrentParticipantKey)
            ? ''
            : `&participantKey=${cloudMeetCurrentParticipantKey}`
        }`}
        target="_blank"
      >
        View recording
      </Button>
    )
  }

  return (
    <div
      role="alert"
      className={cx(
        'w-full m-0 p-3 flex justify-center items-center gap-2 relative',
        variants[variant]
      )}
    >
      <Icon />
      <div>
        {title} {message}
      </div>
      {action}
      <IconButton
        variant="none"
        className="absolute inset-y-0 right-4 border-0 cursor-pointer"
        label="Close"
        icon={<Close />}
        onClick={() => onClose?.(id, type)}
      />
    </div>
  )
}

PresentationNotification.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  cloudMeetRoomId: PropTypes.number.isRequired,
  canViewRecording: PropTypes.bool.isRequired,
  type: PropTypes.string,
  onClose: PropTypes.func,
  currentCompanyId: PropTypes.number,
  cloudMeetRoomKey: PropTypes.string,
  cloudMeetCurrentParticipantKey: PropTypes.string,
}

export default PresentationNotification
