import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import trim from 'lodash/trim'

export const getActiveFiltersFromQuery = (queryFilters, filterList) => {
  return Array.isArray(filterList) && !isEmpty(filterList)
    ? map(getQueryFilterArray(queryFilters), (df) => {
        const [fieldName, operator = ''] = df.alertColumnKey.split(' ')
        const column = find(filterList, (f) => {
          const fields = f.field.split(',')
          return find(
            fields,
            (splitField) => splitField.toLowerCase() === fieldName.toLowerCase()
          )
        })

        let columnName

        switch (fieldName) {
          case 'broadStrategy':
            columnName = 'Broad Strategy'
            break
          case 'subStrategy':
            columnName = 'Sub Strategy'
            break
          default:
            columnName = column?.name
        }

        return {
          ...df,
          alertColumn: trim(`${columnName} ${operator}`),
          alertColumnType: getAlertColumnType(column?.questionType),
        }
      })
    : []
}

function getQueryFilterArray(filters) {
  if (Array.isArray(filters)) {
    return map(filters, (filterString) => {
      const [columnKey, values = ''] = filterString.split('=')
      return {
        alertColumnKey: columnKey,
        values: values.split(','),
      }
    })
  } else if (isNil(filters)) {
    return []
  } else {
    const [columnKey, values = ''] = filters.split('=')
    return [
      {
        alertColumnKey: columnKey,
        values: values.split(','),
      },
    ]
  }
}

function getAlertColumnType(questionType) {
  switch (questionType) {
    case 'dropdown':
    case 'dropdownDiscoverLists':
    case 'dropdownLiquid':
    case 'dropdownIlliquid':
    case 'dropdownCustom':
    case 'dropdownConcat':
      return 'Select'
    case 'checkbox':
      return 'Check'
    case 'date':
    case 'range':
      return 'Range'
    case 'aggregatedRange':
      return 'AggregatedRange'
    case 'percentageRange':
      return 'RangePercentage'
    case 'matchmakingRange':
      return 'SliderPercentage'
    case 'bool':
      return 'Bool'
    default:
      return questionType
  }
}
