import { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import isNil from 'lodash/isNil'
import './FloatingTextArea.less'

const { TextArea } = Input

class FloatingTextArea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }
  }

  valueChanged = (e) => {
    this.setState({
      value: e.target.value,
    })

    if (typeof this.props.onChange === 'function')
      this.props.onChange(e.target.value, this.props.id)
  }
  render() {
    const value =
      typeof this.props.value === 'undefined' || this.props.value === null
        ? this.state.value
        : this.props.value

    const { isValid } = this.props

    const anyText = value === null || value === ''
    let classes = anyText
      ? this.props.name.length < 100
        ? 'cc-floating-textarea-notext'
        : 'cc-floating-textarea-notext-long'
      : this.props.name.length < 100
      ? 'cc-floating-textarea-text'
      : 'cc-floating-textarea-long-text'
    classes += ' cc-floating-textarea'

    let placeholderClasses = anyText
      ? 'placeholder-movement-textarea-notext'
      : 'placeholder-movement-textarea-text'
    placeholderClasses += ' cc-floating-textarea-placeholder'

    let validationMessage = null
    if (isValid === false) {
      classes += ' cc-floating-input-invalid'
      placeholderClasses += ' cc-floating-textarea-placeholder-invalid'
      validationMessage = this.props.validationMessage
    }

    return (
      <div className="floating-textarea-row">
        <TextArea
          onChange={this.valueChanged}
          placeholder={this.props.name}
          className={classes}
          style={{ height: '120px' }}
          id={this.props.id}
          value={value}
          //onBlur={this.props.onBlur}
          onBlur={this.props.onBlur}
        />
        <span className={placeholderClasses}>{this.props.name}</span>
        {isNil(validationMessage) ? null : (
          <div className="cc-small-font cc-validation-message">
            {validationMessage}
          </div>
        )}
      </div>
    )
  }
}

FloatingTextArea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  isValid: PropTypes.bool,
  validationMessage: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

export default FloatingTextArea
