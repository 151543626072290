import { BASE_URL, StorageKeys } from '~/config'
import {
  addDefaultAuthTokenFromStorage,
  createInstance,
} from './createInstance'

const api = createInstance(BASE_URL)
addDefaultAuthTokenFromStorage(
  api,
  StorageKeys.accessToken,
  (token) => `Bearer ${token}`
)

api.interceptors.request.use((config) => {
  if (
    'apiVersion' in config &&
    ['string', 'number'].includes(typeof config.apiVersion)
  ) {
    config.params = {
      ...config.params,
      'api-version': config.apiVersion,
    }
  }
  return config
})

export default api
