import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import classNames from 'classnames'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'

const Field = ({
  id = null,
  name,
  label = null,
  className = null,
  value,
  options = null,
  renderOptions,
  isFloating = true,
  disabled = false,
  isRequired = false,
  onBlur,
  onChange,
  tag: FieldTag = Input,
  formatter = null,
  parser = null,
  autosize = null,
  disabledDate = null,
  url = null,
  filterBy = null,
  transform = null,
  onSearchResults = null,
  allowClear = false,
  sortAnswers = false,
  hideLabel,
  yesText = null,
  noText = null,
  help = null,
}) => {
  const [hasValue, setHasValue] = useState(false)
  const [hasFocus, setHasFocus] = useState(false)

  const handleFocus = useCallback(() => {
    setHasFocus(true)
  }, [])

  const handleBlur = useCallback(
    (e) => {
      setHasFocus(false)
      setHasValue(!isNil(value))
      if (isFunction(onBlur)) {
        onBlur(e)
      }
    },
    [onBlur, value]
  )

  const handleChange = useCallback(
    (e) => {
      if (has(e, 'target.value')) {
        setHasValue(!isNil(e.target.value) && !isEmpty(e.target.value))
      } else {
        setHasValue(!isNil(e) && !isEmpty(e))
      }

      if (isFunction(onChange)) {
        onChange(e)
      }
    },
    [onChange]
  )

  useEffect(() => {
    setHasValue(!isNil(value))
  }, [value])

  const labelComponent = isNil(label) ? null : (
    <label
      htmlFor={name}
      className={classNames({
        'Field-label': !isFloating,
        'Field-floating-label': isFloating,
      })}
    >
      {label}
      {isRequired && <b>*</b>}
    </label>
  )

  const extraProps = {}
  if (!isNil(formatter)) {
    extraProps.formatter = formatter
  }

  if (!isNil(parser)) {
    extraProps.parser = parser
  }

  if (!isNil(autosize) || !isEmpty(autosize)) {
    extraProps.autoSize = autosize
  }

  if (!isNil(disabledDate)) {
    extraProps.disabledDate = disabledDate
  }

  if (!isNil(url)) {
    extraProps.url = url
  }

  if (!isNil(filterBy)) {
    extraProps.filterBy = filterBy
  }

  if (!isNil(transform)) {
    extraProps.transform = transform
  }

  if (!isNil(onSearchResults)) {
    extraProps.onSearchResults = onSearchResults
  }
  if (!isNil(allowClear)) {
    extraProps.allowClear = allowClear
  }

  if (!isNil(yesText)) {
    extraProps.yesText = yesText
  }

  if (!isNil(noText)) {
    extraProps.noText = noText
  }

  const renderAnswers = (options) => {
    const sortedOptions = sortAnswers
      ? options.sort((a, b) => a.answer.localeCompare(b.answer))
      : options

    return renderOptions(sortedOptions, extraProps)
  }
  let fieldTag = (
    <FieldTag
      id={isNil(id) ? name : id}
      name={name}
      placeholder=""
      value={value}
      formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      disabled={disabled}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      className={classNames({
        'Field-has-focus': hasFocus && !hideLabel,
        'Field-has-value': hasValue && !hideLabel,
      })}
      {...extraProps}
    />
  )

  if (isFunction(renderOptions)) {
    fieldTag = (
      <FieldTag
        id={isNil(id) ? name : id}
        name={name}
        value={value}
        disabled={disabled}
        onFocus={handleFocus}
        formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        onBlur={handleBlur}
        onChange={handleChange}
        className={classNames({
          'Field-has-focus': hasFocus && !hideLabel,
          'Field-has-value': hasValue && !hideLabel,
        })}
        {...extraProps}
      >
        {renderAnswers(options)}
      </FieldTag>
    )
  }

  return (
    <div
      className={classNames(
        'Field',
        { [className]: !isNil(className) },
        { 'Field-required': isRequired },
        { 'Field-deals': hideLabel }
      )}
    >
      {!isFloating && labelComponent}
      {fieldTag}
      {isFloating && labelComponent}
      {help && <div className="FormField-helpText">{help}</div>}
    </div>
  )
}

Field.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  renderOptions: PropTypes.func,
  isFloating: PropTypes.bool,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  tag: PropTypes.node,
  formatter: PropTypes.func,
  parser: PropTypes.func,
  autosize: PropTypes.shape({
    minRows: PropTypes.number,
    maxRows: PropTypes.number,
  }),
  disabledDate: PropTypes.func,
  url: PropTypes.string,
  filterBy: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  transform: PropTypes.func,
  onSearchResults: PropTypes.func,
  allowClear: PropTypes.bool,
  sortAnswers: PropTypes.bool,
  hideLabel: PropTypes.bool,
  yesText: PropTypes.string,
  noText: PropTypes.string,
  help: PropTypes.string,
}

export default Field
