import * as React from 'react'
import { useLiveRef } from '@context365/hooks'

export default function useLiveCallback(cb) {
  const liveRef = useLiveRef(cb)
  return React.useCallback(
    (...args) => {
      liveRef.current?.(...args)
    },
    [liveRef]
  )
}
