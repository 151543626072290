let refreshTokenPromise = null

export function addRefreshTokenInterceptor(
  axiosInstance,
  { shouldRefresh, refreshToken }
) {
  axiosInstance.interceptors.response.use(null, async (error) => {
    const { config, response } = error
    if (!shouldRefresh(response) || config._retry) {
      return Promise.reject(error)
    }

    config._retry = true
    try {
      refreshTokenPromise = refreshTokenPromise ?? refreshToken()
      await refreshTokenPromise
      return axiosInstance(config)
    } catch (err) {
      return Promise.reject(err)
    } finally {
      refreshTokenPromise = null
    }
  })
}
