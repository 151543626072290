import PropTypes from 'prop-types'
import AddToListModal from './AddToListModal'

export default function AddAllocatorToListModal({
  visible,
  allocators,
  onClose,
}) {
  return (
    <AddToListModal
      visible={visible}
      onClose={onClose}
      targetItems={allocators}
      getTargetId={(allocator) => allocator.companyId}
      getTargetName={(allocator) => allocator.companyName}
      listLinkName="toCompanyId"
    />
  )
}

AddAllocatorToListModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  allocators: PropTypes.arrayOf(
    PropTypes.shape({
      companyId: PropTypes.number.isRequired,
      companyName: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
}
