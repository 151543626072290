import * as React from 'react'
import PropTypes from 'prop-types'
import { Select } from '@context365/forms'
import { map } from 'lodash'
import Paginator from '~/components/Paginator'
import { getInitials } from '~/utils/helpers'
import MobileAttendee from './MobileAttendee'

const PAGE_SIZES = [10, 20, 50, 100]

const SummitAttendeeTableMobile = ({
  category,
  data,
  loadPage,
  currentPage,
  total,
  pageSize,
  onClickRecord,
}) => {
  return (
    <div className="h-full w-full flex flex-col flex-nowrap absolute">
      <div className="grow overflow-auto px-6 bg-white divide-y divide-solid">
        {map(data, (x) =>
          category === 'allocators' ? (
            <MobileAttendee
              name={x.contactName}
              company={x.companyName}
              initials={getInitials(x.contactName)}
              imageUrl={x.imageUrl}
              subTitle={x.primaryInvestorCategory}
              matchmakingScore={x.matchmakingScore}
              meeting={x.meeting}
              onClick={() => onClickRecord(x)}
            />
          ) : category === 'funds' ? (
            <MobileAttendee
              name={x.name}
              company={x.companyName}
              booth={x.boothId}
              subTitle={x.broadStrategy}
              matchmakingScore={x.matchmakingScore}
              meeting={x.meeting}
              onClick={() => onClickRecord(x)}
            />
          ) : (
            <MobileAttendee
              name={x.companyName}
              booth={x.boothId}
              subTitle={x.serviceProviderCategory}
              matchmakingScore={x.matchmakingScore}
              meeting={x.meeting}
              onClick={() => onClickRecord(x)}
            />
          )
        )}
      </div>
      {loadPage && (
        <div className="mt-8 shrink-0 px-3 pb-11">
          <div className="grid grid-cols-12">
            <Paginator
              className="col-span-9"
              currentPage={currentPage}
              total={total}
              loadPage={loadPage}
              pageSize={pageSize}
              showLessItems
            />
            <Select
              value={pageSize}
              onChange={(e) => loadPage(currentPage, e)}
              className="col-span-3"
            >
              {map(PAGE_SIZES, (x) => (
                <Select.Option key={x} value={x}>
                  {`${x} / page`}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      )}
    </div>
  )
}

SummitAttendeeTableMobile.propTypes = {
  category: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  currentPage: PropTypes.number,
  total: PropTypes.number,
  loadPage: PropTypes.func,
  pageSize: PropTypes.number,
  onClickRecord: PropTypes.func.isRequired,
}

export default SummitAttendeeTableMobile
