const VALUE_TYPES = {
  CURRENCY: 'currency',
  DECIMAL: 'decimal',
  PERCENT: 'percent',
  PERCENT_MULTIPLIED_100: 'decimalPercent',
  DATETIME: 'dateTime',
  DAYS: 'day',
  MONTHS: 'month',
  ARRAY: 'array',
  PARAGRAPH: 'paragraph',
}

export default VALUE_TYPES
