import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CaretDownOutlined } from '@ant-design/icons'
import { Button } from '@context365/button'
import { Description } from '@context365/icons'
import { Dropdown, Menu, Tabs } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { events as eventsClient } from '~/api'
import CenteredPrimaryRadioButtons from '~/components/CenteredPrimaryRadioButtons'
import Loading from '~/components/Loading'
import Timezone from '~/components/SelectTimezoneComponent/SelectTimezoneComponent'
import ScheduleDay from './ScheduleDay'
import SchedulerNotOpen from './SchedulerNotOpen'
import './Schedule.less'

const { TabPane } = Tabs

const Schedule = ({
  eventId,
  companyEventId,
  defaultTimezone,
  virtualSummit,
  optimizationDate,
  agendaUrl,
}) => {
  const companyId = useSelector((state) => state.auth.company.companyId)
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [scheduleDays, setScheduleDays] = useState(null)
  const [weeks, setWeeks] = useState(null)
  const [week, setWeek] = useState(null)
  const [activeTab, setActiveTab] = useState('0')
  const [timezone, setTimezone] = useState(defaultTimezone)
  const today = moment()

  const getDayButtons = () => {
    if (scheduleDays === null) return null
    else
      return scheduleDays.map((x, index) => ({
        value: index.toString(),
        text: moment(x.eventDate).format('ddd, MMM DD'),
      }))
  }
  const getWeekText = (week, endOfWeek, startOfWeek) => {
    const firstDay = moment(startOfWeek).format('MMM DD')
    const lastDay = moment(endOfWeek).format('MMM DD YYYY')
    return (
      <span>
        <span className="ScheduleWeekDropdown-week">{week}</span>
        <span className="ScheduleWeekDropdown-text">
          ({firstDay} - {lastDay})
        </span>
      </span>
    )
  }

  useEffect(() => {
    setLoading(true)
    const requestedTimezone =
      virtualSummit || isNil(timezone) ? moment.tz.guess() : timezone

    eventsClient
      .getEventSchedule(eventId, requestedTimezone)
      .then((response) => {
        const weeks = response.data.result
        if (isEmpty(weeks)) {
          setLoading(false)
        } else {
          const firstDay = moment(weeks[0].eventScheduleDays[0].eventDate)
          const lastDay = moment(
            weeks[weeks.length - 1].eventScheduleDays[
              weeks[weeks.length - 1].eventScheduleDays.length - 1
            ].eventDate
          )
          setLoading(false)

          if (weeks.length > 1) {
            setWeeks(weeks)
            if (
              moment(new Date()) >= firstDay &&
              moment(new Date()) <= lastDay
            ) {
              weeks.map((week, index) => {
                return week.eventScheduleDays.map((day, dayIndex) => {
                  if (
                    moment(day.eventDate).format('ddd, MMM DD') ===
                    moment(new Date()).format('ddd, MMM DD')
                  ) {
                    setWeek(
                      getWeekText(
                        weeks[index].week,
                        weeks[index].endOfWeek,
                        weeks[index].startOfWeek
                      )
                    )
                    setActiveTab(`${dayIndex}`)
                    setScheduleDays(weeks[index].eventScheduleDays)
                  }
                  return week
                })
              })
            } else {
              setWeek(
                getWeekText(
                  weeks[0].week,
                  weeks[0].endOfWeek,
                  weeks[0].startOfWeek
                )
              )
              setActiveTab('0')
              setScheduleDays(weeks[0].eventScheduleDays)
            }
          } else {
            setScheduleDays(weeks[0].eventScheduleDays)
            weeks[0].eventScheduleDays.map((day, dayIndex) => {
              if (
                moment(day.eventDate).format('ddd, MMM DD') ===
                moment(new Date()).format('ddd, MMM DD')
              ) {
                setActiveTab(`${dayIndex}`)
              }
              return dayIndex
            })
          }
        }
      })
  }, [eventId, refresh, companyEventId, virtualSummit, timezone])

  const radioHandler = (e) => {
    setActiveTab(e.target.value)
  }

  const dropDownHandler = (week) => {
    setScheduleDays(week.eventScheduleDays)
    setWeek(getWeekText(week.week, week.endOfWeek, week.startOfWeek))
    setActiveTab('0')
  }

  const switcher = weeks && (
    <Menu>
      {weeks.map((week, index) => (
        <Menu.Item
          key={index}
          value={week}
          onClick={() => dropDownHandler(week)}
        >
          {getWeekText(week.week, week.endOfWeek, week.startOfWeek)}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <div style={{ minHeight: '500px' }}>
      <Loading spinning={loading} style={{ marginTop: '250px' }}>
        {isEmpty(scheduleDays) && !loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SchedulerNotOpen
              schedulerAccessDate={null}
              companyEventId={companyEventId}
              eventId={eventId}
            />
          </div>
        ) : (
          <div>
            {!isEmpty(agendaUrl) && (
              <div className="text-md mb-4 flex items-center">
                <Description className="text-primary-100" />
                <Button variant="link" as="a" href={agendaUrl} target="_blank">
                  View Summit Agenda
                </Button>
              </div>
            )}
            {weeks && (
              <div className="ScheduleWeekDropdown">
                <Dropdown overlay={switcher} trigger={['click']}>
                  <Button>
                    {week}
                    <CaretDownOutlined />
                  </Button>
                </Dropdown>
              </div>
            )}
            {scheduleDays && (
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 2 }}>
                  <CenteredPrimaryRadioButtons
                    selectedKey={activeTab}
                    buttons={getDayButtons()}
                    OnClickFunction={radioHandler}
                  />
                </div>

                {/* <div style={{ alignSelf: 'center' }}>
              <Button
                style={{ padding: '0px 12px', height: '40px' }}
                onClick={() => {
                  setShowTimezoneSetting(true);
                }}
                type="primary"
              >
                <FontAwesomeIcon
                  style={{ width: '16px', height: '16px' }}
                  icon={faCog}
                />
              </Button>
            </div> */}
              </div>
            )}

            {scheduleDays && (
              <div>
                <div className="online-meeting-date-time-timezone">
                  <Timezone
                    value={timezone}
                    handleTimezoneChange={(val) => {
                      setTimezone(val)
                    }}
                  />
                </div>
                {/* <div style={{ paddingBottom: '12px' }}>
              <Button type="primary" onClick={exportSchedule}>
                <FontAwesomeIcon
                  icon={faFileExport}
                  style={{ marginRight: '8px' }}
                />{' '}
                Export Meeting Contact Details
              </Button>
            </div> */}
                {!isNil(optimizationDate) && today < moment(optimizationDate) && (
                  <div
                    style={{
                      fontWeight: 'bold',
                      paddingBottom: '12px',
                      fontSize: '18px',
                      color: 'black',
                    }}
                  >
                    All Confirmed Meetings will be Assigned a Date &amp; Time on{' '}
                    {moment(optimizationDate).format('MMMM Do')}
                  </div>
                )}
                <Tabs
                  animated={false}
                  tabBarStyle={{ display: 'none' }}
                  activeKey={activeTab}
                >
                  {scheduleDays &&
                    scheduleDays.map((x, index) => (
                      <TabPane tab={x.eventDate} key={index.toString()}>
                        <ScheduleDay
                          virtualSummit={virtualSummit}
                          timezone={defaultTimezone}
                          // timezoneSetting={timezoneSetting}
                          // timezoneShowing={timezoneShowing}
                          // setTimezoneShowing={setTimezoneShowing}
                          eventDate={x.eventDate}
                          companyId={companyId}
                          eventId={eventId}
                          timeSlots={x.eventScheduleSlots}
                          refreshSchedule={() => {
                            setRefresh(!refresh)
                          }}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </div>
            )}
          </div>
        )}
      </Loading>
      {/* <Modal
        visible={showTimezoneSetting}
        onCancel={() => {
          setShowTimezoneSetting(false);
        }}
        onOk={() => {
          saveScheduleTimezone(timezoneSetting, companyEventId);
        }}
        title="Set default schedule timezone"
        okText="Save Changes"
        bodyStyle={{ padding: '32px' }}
      >
        <Loading spinning={loadingTimezone}>
          <Timezone
            value={timezoneSetting}
            handleTimezoneChange={val => {
              setTimezoneSetting(val);
            }}
          />
        </Loading>
      </Modal> */}
    </div>
  )
}

Schedule.propTypes = {
  eventId: PropTypes.number.isRequired,
  companyEventId: PropTypes.number.isRequired,
  defaultTimezone: PropTypes.string,
  virtualSummit: PropTypes.bool.isRequired,
  optimizationDate: PropTypes.instanceOf(Date),
  agendaUrl: PropTypes.string,
}
export default Schedule
