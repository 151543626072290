import * as React from 'react'
import PropTypes from 'prop-types'
import { InputNumber } from 'antd'
import isNil from 'lodash/isNil'
import numeral from 'numeral'
import useDebounced from '~/hooks/useDebounced'
import { CALLBACK_WAIT_MS } from './constants'
import '../DiscoverFilters.less'

const formatValue = (value, percentage) => {
  if (value == null || value === '') {
    return null
  }

  return percentage
    ? numeral(value / 100).format('0[.]0000')
    : numeral(value).format('0[.]00')
}

const RangeFilter = ({
  id,
  formatter,
  parser,
  group,
  label,
  percentage,
  value,
  onChange,
}) => {
  const [min, setMin] = React.useState()
  const [max, setMax] = React.useState()

  // Note that we only debounce the call to `onChange` and not the state
  // updates. This way, the input's value will continue to update correctly
  // even while we're waiting to send the latest value to the back-end.
  const debouncedOnChange = useDebounced(onChange, CALLBACK_WAIT_MS)

  const onChangeMin = React.useCallback(
    (e) => {
      setMin(formatValue(e))
      debouncedOnChange({
        value: formatValue(e, percentage),
        id: `${id} Min`,
        group,
        label: `${label} Min`,
        type: percentage ? 'RangePercentage' : 'Range',
      })
    },
    [group, debouncedOnChange, id, percentage, label]
  )

  const onChangeMax = React.useCallback(
    (e) => {
      setMax(formatValue(e))
      debouncedOnChange({
        value: formatValue(e, percentage),
        id: `${id} Max`,
        group,
        label: `${label} Max`,
        type: percentage ? 'RangePercentage' : 'Range',
      })
    },
    [group, debouncedOnChange, id, percentage, label]
  )

  React.useEffect(() => {
    const [minValue, maxValue] = value

    setMin(
      isNil(minValue)
        ? null
        : numeral(minValue * (percentage ? 100 : 1)).format('0[.]00')
    )

    setMax(
      isNil(maxValue)
        ? null
        : numeral(maxValue * (percentage ? 100 : 1)).format('0[.]00')
    )
  }, [percentage, value])

  return (
    <>
      <InputNumber
        formatter={formatter}
        parser={parser}
        onChange={onChangeMin}
        className="DiscoverFilters-NumberInput"
        value={min}
        size="small"
      />
      <div className="DiscoverFilters-Line" />
      <InputNumber
        formatter={formatter}
        parser={parser}
        onChange={onChangeMax}
        className="DiscoverFilters-NumberInput"
        value={max}
        size="small"
      />
    </>
  )
}

RangeFilter.propTypes = {
  id: PropTypes.string.isRequired,
  formatter: PropTypes.func.isRequired,
  parser: PropTypes.func.isRequired,
  group: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  percentage: PropTypes.bool.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default RangeFilter
