import qs from 'qs'
import { api } from './services'

export const getNotifications = (params = {}) =>
  api.get('/notifications', {
    params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  })

export const getNotificationStats = () => api.get('/notifications/stats')

export const storeDeviceToken = (platform, token) =>
  api.post('/notifications/tokens', { platform, token })

export const markNotificationAsRead = (notificationLogId) =>
  api.patch(`/notifications/logs/${notificationLogId}`)

export const markAllNotificationsAsRead = () => api.patch('/notifications/logs')
