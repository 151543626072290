import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Slider } from 'antd'
import isNaN from 'lodash/isNaN'
import map from 'lodash/map'
import numeral from 'numeral'
import '../DiscoverFilters.less'

const formatValues = (values, percentage) =>
  map(values, (val) => {
    if (val == null || val === '') {
      return null
    }

    return percentage
      ? numeral(val / 100).format('0[.]0000')
      : numeral(val).format('0[.]00')
  })

const parseValue = (value, percentage) =>
  percentage ? parseFloat(value) * 100 : parseFloat(value)

const SliderFilter = ({
  id,
  formatter,
  group,
  label,
  percentage,
  value,
  onChange,
  defaultMin = null,
  defaultMax = null,
  marks = null,
}) => {
  const minVal = isNaN(parseValue(value[0], percentage))
    ? defaultMin
    : parseValue(value[0], percentage)
  const maxVal = isNaN(parseValue(value[1], percentage))
    ? defaultMax
    : parseValue(value[1], percentage)

  const defaultRange = [minVal, maxVal]

  const onChangeRange = useCallback(
    (e) => {
      onChange({
        value: formatValues(e, percentage),
        id,
        group,
        label,
        type: percentage ? 'SliderPercentage' : 'Slider',
      })
    },
    [group, onChange, id, percentage, label]
  )

  return (
    <Slider
      range
      marks={marks}
      defaultValue={defaultRange}
      tipFormatter={formatter}
      onAfterChange={onChangeRange}
    />
  )
}

SliderFilter.propTypes = {
  id: PropTypes.string.isRequired,
  formatter: PropTypes.func.isRequired,
  group: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  percentage: PropTypes.bool.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultMin: PropTypes.number,
  defaultMax: PropTypes.number,
  marks: PropTypes.object,
}

export default SliderFilter
