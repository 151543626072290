import { Button } from '@context365/button'
import { Launch } from '@context365/icons'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import take from 'lodash/take'
import moment from 'moment'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import * as api from '~/api'
import CardButton from '~/components/CardButton'
import MediaTypes from '~/constants/mediaTypes'
import NewFlag from './NewFlag'
import WidgetBlock from './WidgetBlock'

const Content = () => {
  const { data: contentEntries } = useQuery(
    ['dashboardContent'],
    () => api.content.getEntries(),
    {
      select: (data) => take(orderBy(data, 'publishDateTimeUtc', 'desc'), 4),
    }
  )

  return (
    <WidgetBlock
      headerLeft="Content"
      headerRight={
        <Button
          variant="link"
          as={Link}
          to="/community/content"
          iconRight={<Launch />}
          style={{ padding: 0 }}
          status="secondary"
        >
          See All
        </Button>
      }
    >
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {map(contentEntries, (c) => (
          <CardButton
            key={c.contentEntryId}
            href={
              c.media.contentEntryMediaTypeId === MediaTypes.Website
                ? c.media.url
                : `/community/content/${c.media.contentEntryMediaTypeId}/${c.contentEntryId}`
            }
            target={
              c.media.contentEntryMediaTypeId === MediaTypes.Website
                ? '_blank'
                : '_self'
            }
            className="shadow-6 md:shadow-none border-grey-300 space-y-3"
          >
            <div className="type-subtitle-sm text-secondary-100">
              {c.contentCategories[0]?.name}
            </div>
            <div className="relative inline-block">
              <img
                src={c.coverImageUrl}
                alt="content cover"
                style={{ maxHeight: '123px' }}
                className="block max-w-full"
              />
              {moment().utc().subtract(7, 'days') <
                moment(c.publishDateTimeUtc) && (
                <div className="absolute w-1/2" style={{ top: '10%' }}>
                  <NewFlag />
                </div>
              )}
            </div>
            <div className="type-body-semibold-sm">{c.title}</div>
          </CardButton>
        ))}
      </div>
    </WidgetBlock>
  )
}

export default Content
