import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import qs from 'qs'
import { api } from './services'

export const getList = (
  eventId,
  category,
  { page, pageSize, filter, orderBy, searchQuery }
) => {
  const params = { page, pageSize }

  if (!isNil(searchQuery) && !isEmpty(searchQuery)) {
    params.q = trim(searchQuery)
  }

  if (!isNil(orderBy) && !isEmpty(orderBy)) {
    params.orderby = orderBy
  }

  if (!isNil(filter) && !isEmpty(filter)) {
    params.filter = filter
  }

  return api.get(`/events/${eventId}/${category}`, {
    params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  })
}
