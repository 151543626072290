import { useHistory, useRouteMatch } from 'react-router-dom'
import ContactForm from '~/components/ContactForm'

const ContactContainer = () => {
  const { goBack } = useHistory()
  const {
    params: { contactId },
  } = useRouteMatch()

  return <ContactForm contactId={contactId} back={goBack} />
}

ContactContainer.propTypes = {}

export default ContactContainer
