import PropTypes from 'prop-types'
import {
  faCheck,
  faCheckDouble,
  faFilePdf,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import isNil from 'lodash/isNil'
import { api as http } from '~/api/services'
import DataTransformations from '../DataTransformations'
import './UserMessageItem.less'

const UserMessageItem = ({
  messageElements,
  time,
  status,
  index,
  isAttachment,
  downloadUrl,
}) => {
  let checkIcon = null
  if (!isNil(status)) {
    checkIcon = status === 'read' ? faCheckDouble : faCheck
  }

  return (
    <div className="messaging-message-container-mine">
      <div className="messaging-message-column">
        <span style={{ direction: 'rtl' }}>
          {isAttachment ? (
            <div className="messaging-message-attachment">
              <FontAwesomeIcon
                className="messaging-message-attachment-icon"
                icon={faFilePdf}
              />
              <span className=" cc-small-font messaging-message-attachment-file">
                {messageElements}
              </span>
              <div className="messaging-message-attachment-download-link">
                <Button
                  onClick={() => {
                    http.get(downloadUrl).then((response) => {
                      window.open(response.data.message, '_blank')
                    })
                  }}
                  type="link"
                >
                  Download
                </Button>
              </div>
            </div>
          ) : (
            <div className="messaging-message-mine">
              <span message-index={index}>{messageElements}</span>
            </div>
          )}
          <div className="cc-xsmall-font messaging-message-time">
            <div className="messaging-message-timestamp">
              {DataTransformations.RenderTimeForMessages(time)}
            </div>
            {checkIcon && (
              <span className={`messaging-message-readby-tick ${status}`}>
                <FontAwesomeIcon icon={checkIcon} />
              </span>
            )}
          </div>
        </span>
      </div>
    </div>
  )
}

UserMessageItem.propTypes = {
  messageElements: PropTypes.arrayOf(PropTypes.element).isRequired,
  time: PropTypes.object.isRequired,
  status: PropTypes.string,
  index: PropTypes.number,
  isAttachment: PropTypes.bool,
  downloadUrl: PropTypes.string,
}

export default UserMessageItem
