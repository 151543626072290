import { Event, QuestionAnswerOutlined, TodayOutlined } from '@context365/icons'
import { TabButton, TabList, Tabs } from '@context365/tabs'
import { Link, useParams } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import AllMeetings from './AllMeetings'
import CalendarView from './Calendar'
import Requests from './Requests'

const Meetings = () => {
  const { Track, trackEvent } = useTracking({ page: 'Meetings' })
  const { tabId = 'calendar' } = useParams()

  const trackClick = (label) =>
    trackEvent({ element: 'MeetingsTabs', eventName: 'click', label })

  return (
    <Track>
      <div>
        <Tabs selectedTab={tabId}>
          <TabList variant="underlined">
            <TabButton
              as={Link}
              to="/meetings/calendar"
              id="calendar"
              label="Calendar"
              iconLeft={<TodayOutlined />}
              onClick={() => trackClick('calendar')}
            />
            <TabButton
              as={Link}
              to="/meetings/requests"
              id="requests"
              label="Meeting Requests"
              iconLeft={<QuestionAnswerOutlined />}
              onClick={() => trackClick('requests')}
            />
            <TabButton
              as={Link}
              to="/meetings/meetings"
              id="meetings"
              label="All Meetings"
              iconLeft={<Event />}
              onClick={() => trackClick('allMeetings')}
            />
          </TabList>
        </Tabs>

        {tabId === 'calendar' && <CalendarView />}
        {tabId === 'requests' && <Requests />}
        {tabId === 'meetings' && <AllMeetings />}
      </div>
    </Track>
  )
}

export default Meetings
