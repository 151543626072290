import PropTypes from 'prop-types'
import { DetailedAvatar } from '@context365/avatar'
import { Modal } from '@context365/modals'
import { useTracking } from 'react-tracking'
import { getInitials } from '~/utils'

export default function ConversationParticipantsModal({
  contacts,
  visible,
  onClose,
}) {
  const { trackEvent } = useTracking({
    component: 'ConversationParticipantsModal',
  })

  const handleClick = (contact) => {
    trackEvent({
      eventName: 'click',
      element: 'contact avatar',
      contactId: contact.contactId,
    })
  }

  return (
    <Modal visible={visible} onDismiss={onClose} title="Chat Members">
      <div className="-mx-4">
        <ul className="list-none p-0 m-0 divide-y">
          {contacts.map((contact) => (
            <li key={contact.contactId}>
              <a
                className="block px-4 py-1"
                target="_blank"
                href={`/profile/contact/${contact.contactId}`}
                rel="noreferrer"
                onClick={() => handleClick(contact)}
              >
                <DetailedAvatar
                  name={contact.contactName}
                  initials={getInitials(contact.contactName)}
                  profileImageUrl={contact.imageUrl}
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  )
}

ConversationParticipantsModal.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.number.isRequired,
      contactName: PropTypes.string.isRequired,
      imageUrl: PropTypes.string,
    })
  ).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
