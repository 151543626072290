import * as React from 'react'
import PropTypes from 'prop-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm as useFormHook } from 'react-hook-form'
import useLiveCallback from '~/components/Messages_V2/utils/useLiveCallback'
import { InnerForm } from './Form'

export function useForm({
  schema,
  defaultValues,
  context,
  onSubmit,
  onSuccess,
  onInvalidForm,
}) {
  const methods = useFormHook({
    resolver: yupResolver(schema),
    defaultValues,
    context,
  })

  const onSubmitRef = useLiveCallback(onSubmit)
  const onSuccessRef = useLiveCallback(onSuccess)
  const onInvalidFormRef = useLiveCallback(onInvalidForm)

  const Form = React.useMemo(() => {
    function Form({ children, ...props }) {
      // They can override the callbacks if they want, but we get the final say
      // on the schema and form methods.
      return (
        <InnerForm
          onSubmit={onSubmitRef}
          onSuccess={onSuccessRef}
          onInvalidForm={onInvalidFormRef}
          {...props}
          schema={schema}
          formMethods={methods}
        >
          {children}
        </InnerForm>
      )
    }

    Form.propTypes = {
      children: PropTypes.node.isRequired,
      onSubmit: PropTypes.func,
      onInvalidForm: PropTypes.func,
    }

    return Form
  }, [schema, methods, onSubmitRef, onSuccessRef, onInvalidFormRef])

  const { handleSubmit } = methods

  return {
    Form,
    ...methods,
    submit: React.useCallback(
      (e) => handleSubmit(onSubmitRef, onInvalidFormRef)(e),
      [handleSubmit, onSubmitRef, onInvalidFormRef]
    ),
  }
}
