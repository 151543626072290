import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Check, CheckCircle } from '@context365/icons'
import { Menu, SplitMenuButton } from '@context365/menu'
import map from 'lodash/map'
import some from 'lodash/some'
import { useQuery } from 'react-query'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import { ROLE } from '~/constants/roles'
import useAuth from '~/hooks/useAuth'
import '../ApplyButton.css'

const ONE_HOUR_IN_MILLISECONDS = 1000 * 60 * 60

const ApplyToMandateButton = ({ mandate, onApply }) => {
  const { trackEvent } = useTracking()
  const { role } = useAuth()

  const { data: funds } = useQuery(
    ['myAppliableFunds', mandate.mandateId],
    () => api.funds.getAppliableFundsForMandate(mandate.mandateId),
    {
      staleTime: ONE_HOUR_IN_MILLISECONDS,
      cacheTime: ONE_HOUR_IN_MILLISECONDS,
      enabled: role === ROLE.MANAGER,
    }
  )

  if (role !== ROLE.MANAGER && role !== ROLE.ALLOCATOR) {
    return null
  }

  const fundsWithApplicationStatus = map(funds, (c) => ({
    ...c,
    hasApplied: some(
      mandate.campaignApplications,
      (m) => m.campaignId === c.campaignId
    ),
  }))

  const hasApplied =
    role === ROLE.ALLOCATOR
      ? mandate.campaignApplications.length > 0
      : some(fundsWithApplicationStatus, 'hasApplied')

  const canApplyWithAny =
    fundsWithApplicationStatus.length > 0 &&
    some(fundsWithApplicationStatus, (c) => !c.hasApplied)

  if (!hasApplied) {
    return (
      <Button
        variant="filled"
        onClick={() => {
          trackEvent({
            eventName: 'click',
            element: 'Mandate Open Apply to mandate modal',
          })
          onApply()
        }}
      >
        Indicate Interest
      </Button>
    )
  } else if (canApplyWithAny) {
    return (
      <Menu
        trigger={
          <SplitMenuButton
            variant="filled"
            className="interested-button"
            primaryAction={() => {}}
          >
            Interested
          </SplitMenuButton>
        }
      >
        {map(fundsWithApplicationStatus, (fund) => (
          <Menu.Item
            key={fund.fundId}
            disabled={fund.hasApplied}
            onClick={(e) => {
              trackEvent({
                eventName: 'click',
                element: 'Mandate Open Apply to mandate modal',
              })
              e.stopPropagation()
              onApply(fund.fundId)
            }}
          >
            {fund.hasApplied && (
              <CheckCircle className="text-green-110 mr-2" size="small" />
            )}
            {fund.hasApplied ? 'Indicated Interest' : 'Indicate Interest'} with{' '}
            {fund.fundName}
          </Menu.Item>
        ))}
      </Menu>
    )
  } else {
    return (
      <Button
        variant="filled"
        className="interested-button"
        iconLeft={<Check />}
      >
        Interested
      </Button>
    )
  }
}

ApplyToMandateButton.propTypes = {
  onApply: PropTypes.func,
}

export default ApplyToMandateButton
