import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Spin } from '@context365/loaders'
import { AutoComplete } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { api as http } from '~/api/services'

const AutocompleteField = ({
  id = null,
  name,
  value,
  className,
  url,
  filterBy,
  method = 'get',
  transform,
  onFocus,
  onBlur,
  onChange,
}) => {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState(value)
  const [validOptions, setValidOptions] = useState([])

  const handleSearch = useCallback(
    (val) => {
      setFetching(true)
      const key = method === 'get' ? 'params' : 'data'

      const filterRequestData =
        isNil(filterBy) || isEmpty(filterBy) ? {} : filterBy
      const requestData = { [key]: { q: val, ...filterRequestData } }

      http[method](url, requestData)
        .then((response) => {
          const { result } = response.data
          setOptions(isNil(transform) ? result : transform(result))
          if (!isEmpty(result)) {
            setValidOptions(isNil(transform) ? result : transform(result))
          }
        })
        .finally(() => {
          setFetching(false)
        })
    },
    [url, method, filterBy, transform]
  )

  const handleBlur = () => {
    if (selectedValue !== '') {
      if (!isEmpty(validOptions)) {
        onChange(validOptions[0].value)
      }
    }
    onBlur(name)
  }

  const handleChange = useCallback(
    (val) => {
      setOptions([])
      setSelectedValue(val)
      onChange(val)
      handleSearch(val)
    },
    [onChange, handleSearch]
  )

  return (
    <AutoComplete
      id={isNil(id) ? name : id}
      name={name}
      className={className}
      value={value}
      notFoundContent={
        fetching ? (
          <span>
            <Spin inline /> Loading...
          </span>
        ) : null
      }
      dataSource={options}
      onBlur={handleBlur}
      onFocus={onFocus}
      onChange={handleChange}
      onSearch={handleSearch}
      onClick={() => handleSearch(value)}
    />
  )
}

AutocompleteField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
  method: PropTypes.oneOf(['get', 'post']),
  filterBy: PropTypes.object,
  transform: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

export default AutocompleteField
