import * as React from 'react'
import PropTypes from 'prop-types'
import Loading from '../Loading'
import './FundDetailsManagers.less'

const addLineBreaks = (text) => {
  if (!text) {
    return ''
  }

  const lines = text.split('\n')
  return lines.map((line, i) => (
    <React.Fragment key={i}>
      {line}
      <br />
    </React.Fragment>
  ))
}

const FundDetailsManagers = ({ managerBio, loading }) => {
  return (
    <div className="fund-details-managers" style={{ minHeight: '300px' }}>
      <Loading spinning={loading} style={{ marginTop: '150px' }}>
        <div className="fund-details-manager-container">
          <div className="fund-details-manager-profile">
            <div className="fund-details-manager-description">
              <span>{addLineBreaks(managerBio)}</span>
            </div>
          </div>
        </div>
      </Loading>
    </div>
  )
}

FundDetailsManagers.propTypes = {
  managerBio: PropTypes.string,
  loading: PropTypes.bool,
}

export default FundDetailsManagers
