/* eslint-disable no-case-declarations */
import countBy from 'lodash/countBy'
import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { getConversation } from '~/actions/messages'
import {
  CHANNEL_MEMBER_CONSUMPTION_INDEX,
  CONVERSATIONS_FETCHED,
  DESTROY_TWILIO_CLIENT,
  DISPLAY_MORE_MESSAGES,
  FETCHING_CONVERSATIONS,
  FETCH_CONVERSATIONS,
  FETCH_CONVERSATIONS_COUNT,
  FETCH_OLD_MESSAGES,
  INITIALIZE_TWILIO_CLIENT,
  RESET_CHANNEL_STATUS,
  SWITCH_TWILIO_CHANNEL,
  TWILIO_CHANNEL_UNCONSUMED_MESSAGES_COUNT,
  TWILIO_MESSAGE_ADDED,
  UPDATE_CHANNEL_ADD_CONTACT,
  UPDATE_CHANNEL_STATUS,
} from '~/constants/types/messages'

const initialState = {
  chatAccessToken: null,
  twilioClient: null,
  twilioClientInitialized: false,
  twilioChannelsLoading: false,
  totalUnreadMessages: 0,
  channels: [],
  totalChannels: 0,
  currentTwilioChannel: null,
  lastRefresh: moment(),
  contactId: null,
  needsRefresh: true,
  lastMessage: null,
  currentDisplayedPage: 1,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_TWILIO_CLIENT:
      if (!isNil(state.twilioClient)) {
        try {
          state.twilioClient.disconnect()
        } catch {
          return state
        }
      }

      return {
        ...state,
        twilioClient: action.payload.client,
        twilioClientInitialized: true,
        contactId: action.payload.contactId,
      }

    case DESTROY_TWILIO_CLIENT:
      if (!isNil(state.twilioClient)) {
        state.twilioClient.shutdown()
      }
      return { ...state, twilioClient: null }

    case FETCHING_CONVERSATIONS:
      return { ...state, twilioChannelsLoading: true }

    case FETCH_CONVERSATIONS:
      return {
        ...state,
        channels: action.payload.items.result,
        totalChannels: action.payload.items.total,
        lastMessage: action.payload.lastMessage,
      }

    case FETCH_CONVERSATIONS_COUNT:
      return { ...state, totalUnreadMessages: action.payload }

    case CONVERSATIONS_FETCHED:
      return { ...state, twilioChannelsLoading: false }

    case TWILIO_MESSAGE_ADDED:
      const newMessage = action.payload
      const recipientConversationId = newMessage.attributes.ConversationID
      const recipientChannel = getChannelInRooms(
        state.channels,
        recipientConversationId
      )

      if (isNil(recipientChannel)) {
        return state
      }

      const transformedMessage = {
        body: newMessage.body,
        sentFromContactID: parseInt(newMessage.author, 10),
        time: newMessage.timestamp,
        authorContact: newMessage.authorContact,
        messageTypeID: newMessage.attributes.MessageTypeID,
        messageType: newMessage.attributes.MessageType,
        timestamp: newMessage.timestamp,
      }

      fillMessageInfo(recipientChannel, transformedMessage, state.contactId)
      if (isNil(recipientChannel.messages)) recipientChannel.messages = []
      recipientChannel.messages.push(transformedMessage)
      recipientChannel.totalMessages++

      if (
        isNil(state.currentTwilioChannel) ||
        recipientChannel.conversationID !==
          state.currentTwilioChannel.conversationID
      ) {
        if (
          state.contactId !== transformedMessage.sentFromContactID &&
          transformedMessage.messageTypeID <= 2
        ) {
          recipientChannel.totalUnreadMessageCount = isNil(
            recipientChannel.totalUnreadMessageCount
          )
            ? 1
            : recipientChannel.totalUnreadMessageCount + 1
          state.totalUnreadMessages++
        }
      } else {
        state.twilioClient
          .getConversationBySid(recipientChannel.channelSID)
          .then((conversation) => {
            console.log('consuming all messages')
            conversation.setAllMessagesRead()
            getConversation(recipientChannel.conversationID, true, true)
          })
      }

      if (transformedMessage.messageTypeID <= 2) {
        recipientChannel.lastMessage = {
          ...transformedMessage,
          status: null,
        }
      }

      return {
        ...state,
        totalUnreadMessages: countBy(
          state.channels,
          (x) => x.totalUnreadMessageCount > 0
        ).true,
        //channels: orderedChannels,
        lastMessage:
          state.contactId === transformedMessage.sentFromContactID ||
          transformedMessage.messageTypeID > 2
            ? state.lastMessage
            : transformedMessage,
        lastRefresh: moment(),
      }

    case SWITCH_TWILIO_CHANNEL:
      if (isNil(action.payload)) {
        return {
          ...state,
          currentTwilioChannel: null,
        }
      }
      const currentChannel = getChannelInRooms(state.channels, action.payload)
      if (!isNil(currentChannel)) {
        currentChannel.totalUnreadMessageCount = 0
      }
      return {
        ...state,
        totalUnreadMessages: countBy(
          state.channels,
          (x) => x.totalUnreadMessageCount > 0
        ).true,
        currentTwilioChannel: currentChannel,
      }

    case TWILIO_CHANNEL_UNCONSUMED_MESSAGES_COUNT:
      const updatedRoom = getChannelInRooms(
        state.chatRoomGroups,
        action.payload.conversationId
      )
      updatedRoom.unreadMessages = action.payload.count

      return {
        ...state,
        totalUnreadMessages: state.totalUnreadMessages + action.payload.count,
        lastRefresh: moment(),
      }
    case FETCH_OLD_MESSAGES:
      const oldMessages = action.payload.olderMessages
      const roomToAttach = getChannelInRooms(
        state.channels,
        action.payload.conversationId
      )
      if (isNil(roomToAttach)) return state
      oldMessages.forEach((message) => {
        fillMessageInfo(roomToAttach, message, state.contactId)
      })
      const allMessages = flatten([oldMessages, roomToAttach.messages])
      roomToAttach.messages = allMessages
      return {
        ...state,
        lastRefresh: moment(),
      }
    case UPDATE_CHANNEL_ADD_CONTACT:
      const channelToAddContact = getChannelInRooms(
        state.channels,
        action.payload.conversationId
      )

      channelToAddContact.contacts = channelToAddContact.contacts.concat(
        action.payload.contacts
      )

      return {
        ...state,
        lastRefresh: moment(),
      }
    case DISPLAY_MORE_MESSAGES:
      return {
        ...state,
        currentDisplayedPage: state.currentDisplayedPage + 1,
      }
    case UPDATE_CHANNEL_STATUS:
      const channel = getChannelInRooms(
        state.channels,
        action.payload.conversationID
      )
      channel.lastMessage.status = action.payload.status
      return {
        ...state,
        lastRefresh: moment(),
      }
    case RESET_CHANNEL_STATUS:
      const currentChannelBySID = state.channels.find(
        (x) => x.channelSID === action.payload.channelSID
      )

      if (isNil(currentChannelBySID.lastMessage.status)) return state
      currentChannelBySID.lastMessage.status = null

      return {
        ...state,
        lastRefresh: moment(),
      }
    case CHANNEL_MEMBER_CONSUMPTION_INDEX:
      const channelToUpdateContact = state.channels.find(
        (x) => x.channelSID === action.payload.channelSID
      )

      if (isNil(channelToUpdateContact)) return state

      const contactToUpdate = channelToUpdateContact.contacts.find(
        (x) => x.contactId === action.payload.contactID
      )

      if (isNil(contactToUpdate)) return state

      contactToUpdate.lastConsumedIndex = action.payload.index

      if (
        !isNil(channelToUpdateContact.lastMessage) &&
        channelToUpdateContact.lastMessage.authorContact.contactId ===
          state.contactId
      ) {
        const lastMessageConsumedContacts =
          channelToUpdateContact.contacts.filter(
            (x) =>
              x.lastConsumedIndex >= channelToUpdateContact.totalMessages - 1
          )

        const groupedCompanies = groupBy(
          lastMessageConsumedContacts,
          (x) => x.companyID
        )

        if (Object.keys(groupedCompanies).length > 1) {
          //debugger;
          if (!isNil(channelToUpdateContact.lastMessage))
            channelToUpdateContact.lastMessage.status = 'seen'
        } else if (!isNil(channelToUpdateContact.lastMessage))
          channelToUpdateContact.lastMessage.status = 'unread'
      }

      return {
        ...state,
        lastRefresh: moment(),
      }

    default:
      return state
  }
}

const fillMessageInfo = (channel, message, contactId) => {
  if (isNil(message.authorContact)) {
    message.authorContact = channel.participants.find(
      (x) => x.contactId === message.sentFromContactID
    )
  }
  message.body = isNil(message.message) ? message.body : message.message
  if (isNil(message.attributes)) {
    message.attributes = {
      MessageTypeID: message.messageTypeID,
      MessageType: message.messageType,
    }
  }
  message.type =
    !isNil(message.attributes.MessageTypeID) &&
    message.attributes.MessageTypeID > 2
      ? 'log'
      : message.authorContact.contactId === contactId
      ? 'mine'
      : 'theirs'
  message.messageTypeID = message.attributes.MessageTypeID
  //message.timestamp = moment.utc(message.timestamp);
}

const getChannelInRooms = (channels, conversationId) => {
  const channel = channels.find(
    (x) => !isNil(x) && x.conversationID === conversationId
  )
  return isNil(channel) ? null : channel
}
