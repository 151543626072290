import PropTypes from 'prop-types'
import { History } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import find from 'lodash/find'
import last from 'lodash/last'
import moment from 'moment'
import ContextSignalsContainer from '../ContextSignalsContainer'
import { DiscoverBadges } from '../Discover'
import MatchmakingScore from '../MatchmakingScore'
import MeetingBadge from '../MeetingBadge'

const getSelectedLists = (discoverLists, fundId) =>
  filter(discoverLists, (list) =>
    find(list.discoverListLinks, ['toFundId', fundId])
  )

const formatDate = (date) => moment(date).format('MMMM D, YYYY')

const getSelectedBadges = (discoverLists, fund) => {
  const pipelineBadge =
    fund.campaignApplications?.length > 0
      ? [
          {
            name: 'In Pipeline',
            className: 'bg-green-100 text-white uppercase',
          },
        ]
      : []
  return concat(pipelineBadge, getSelectedLists(discoverLists, fund.fundId))
}

const DiscoverFundResultHeader = ({ discoverLists, fund, isCFNAllocator }) => {
  return (
    <div className="flex justify-between">
      <div>
        <DiscoverBadges badges={getSelectedBadges(discoverLists, fund)} />
        <div className="discover-fund-result-title type-body-regular-md">
          {fund.name}
        </div>
        <div className="type-body-regular-xs">{fund.company.name}</div>
      </div>
      <div
        className={cx(
          'flex flex-row items-center -mr-3',
          getSelectedLists(discoverLists, fund.fundId)?.length > 1
            ? '-mt-12'
            : '-mt-4'
        )}
      >
        <ContextSignalsContainer company={fund} />
        {fund.profileLastViewedDateTime && (
          <Tooltip
            key={`Last Viewed ${fund.fundId}`}
            text={`Last Viewed On ${formatDate(
              fund.profileLastViewedDateTime
            )}`}
            placement="top"
          >
            <History className="ml-1 text-body" />
          </Tooltip>
        )}
        {fund.matchmakingScore != null &&
          !(isCFNAllocator && fund.matchmakingScore === 0) && (
            <MatchmakingScore
              score={fund.matchmakingScore}
              threshold={fund.threshold}
            />
          )}
      </div>
    </div>
  )
}

DiscoverFundResultHeader.propTypes = {
  fund: PropTypes.shape({
    company: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    fundId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    matchmakingScore: PropTypes.number.isRequired,
    meetings: PropTypes.array.isRequired,
    threshold: PropTypes.number.isRequired,
    profileLastViewedDateTime: PropTypes.string,
  }).isRequired,
  discoverLists: PropTypes.arrayOf(PropTypes.object),
  isCFNAllocator: PropTypes.bool,
}

export default DiscoverFundResultHeader
