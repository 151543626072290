import * as React from 'react'
import { Button } from '@context365/button'
import { Checkbox, Toggle } from '@context365/forms'
import {
  NotificationsOffOutlined,
  NotificationsOutlined,
} from '@context365/icons'
import { message } from 'antd'
import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import * as api from '~/api'
import Loading from '~/components/Loading'
import { NOTIFICATION_CATEGORY_NAMES } from '~/constants/notificationCategoryIds'

const NotificationRow = ({ notification, handleChange }) => {
  return (
    <div className="grid grid-cols-1 md:flex md:flex-row md:justify-between md:bg-white md:even:bg-grey-100 p-4 gap-4 md:space-y-0">
      <div className="md:w-1/2">
        <div className="type-body-semibold-md md:type-body-regular-md mb-2">
          {notification.name}
        </div>
        <div className="type-body-regular-sm text-grey-700">
          {notification.description}
        </div>
      </div>
      {notification.notificationCategoryIds.length > 1 ? (
        <>
          <div className="hidden md:flex gap-2">
            {map(notification.notificationCategoryIds, (nCatId) => (
              <Checkbox
                key={nCatId}
                onChange={(e) =>
                  handleChange(notification, nCatId, e.target.checked)
                }
                checked={notification.subscribedCategoryIds.includes(nCatId)}
                label={find(NOTIFICATION_CATEGORY_NAMES, ['id', nCatId])?.name}
              />
            ))}
          </div>
          <div className="md:hidden grid grid-cols-1 gap-4">
            {map(notification.notificationCategoryIds, (nCatId) => (
              <div className="flex justify-between px-2" key={nCatId}>
                <div>
                  {find(NOTIFICATION_CATEGORY_NAMES, ['id', nCatId])?.name}
                </div>
                <Toggle
                  onChange={(e) =>
                    handleChange(notification, nCatId, e.target.checked)
                  }
                  checked={notification.subscribedCategoryIds.includes(nCatId)}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        !isEmpty(notification.notificationCategoryIds) && (
          <div className="flex justify-between px-2 md:px-0 md:gap-2 md:flex-row-reverse">
            <div>
              {
                find(NOTIFICATION_CATEGORY_NAMES, [
                  'id',
                  notification.notificationCategoryIds[0],
                ])?.name
              }
            </div>
            <Toggle
              onChange={(e) =>
                handleChange(
                  notification,
                  notification.notificationCategoryIds[0],
                  e.target.checked
                )
              }
              checked={notification.subscribedCategoryIds.includes(
                notification.notificationCategoryIds[0]
              )}
            />
          </div>
        )
      )}
    </div>
  )
}

const NotificationSettings = () => {
  const [subCategories, setSubCategories] = React.useState([])
  const [subscriptions, setSubscriptions] = React.useState([])

  const [isLoading, setIsLoading] = React.useState(false)

  const fetchSubscriptions = React.useCallback(() => {
    setIsLoading(true)
    api.notifications
      .getSubscriptionsBySubCategory()
      .then((res) => {
        setSubCategories(res.subCategories)
        setSubscriptions(res.subscriptions)
      })
      .catch(() =>
        message.error('Could not retrieve notification subscriptions.')
      )
      .finally(() => setIsLoading(false))
  }, [])

  React.useEffect(() => {
    fetchSubscriptions()
  }, [fetchSubscriptions])

  const handleChange = (notification, notificationCategoryId, isSubscribed) => {
    const newSubs = subscriptions.slice()

    const subToUpdate = find(
      newSubs,
      (x) => x.notificationTypeId === notification.notificationTypeId
    )

    isSubscribed
      ? subToUpdate.subscribedCategoryIds.push(notificationCategoryId)
      : (subToUpdate.subscribedCategoryIds = filter(
          subToUpdate.subscribedCategoryIds,
          (x) => x !== notificationCategoryId
        ))

    setSubscriptions(newSubs)
  }

  const handleSave = React.useCallback(() => {
    setIsLoading(true)

    api.notifications
      .saveSubscriptions(subscriptions)
      .then(() => message.success('Notification settings saved successfully'))
      .catch(() =>
        message.error(
          'An error occurred while attempting to save your settings'
        )
      )
      .finally(() => setIsLoading(false))
  }, [subscriptions])

  const handleToggleAll = React.useCallback(
    (subscribeToAll) => {
      setIsLoading(true)

      api.notifications
        .toggleAllSubscriptions(subscribeToAll)
        .then(() => message.success('Notification settings saved successfully'))
        .catch(() =>
          message.error(
            'An error occurred while attempting to save your settings'
          )
        )
        .finally(() => fetchSubscriptions())
    },
    [fetchSubscriptions]
  )

  return (
    <>
      <div className="md:flex md:place-content-end gap-2 grid grid-cols-1 mt-2 p-2">
        <Button
          disabled={isLoading}
          onClick={() => handleToggleAll(false)}
          variant="outlined"
          iconLeft={<NotificationsOffOutlined />}
        >
          Turn Off All Notifications
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => handleToggleAll(true)}
          variant="filled"
          iconLeft={<NotificationsOutlined />}
        >
          Turn On All Notifications
        </Button>
      </div>
      <div
        className="overflow-y-auto my-4 px-4 md:px-0"
        style={{ height: '75vh' }}
      >
        <Loading spinning={isLoading}>
          {map(subCategories, (cat, i) => (
            <div key={i} className="mb-2 rounded">
              <div className="bg-secondary-2 type-subtitle-md flex justify-between p-2 border-b-2 border-secondary-5">
                <div>{cat.name}</div>
                <div className="hidden md:flex type-body-semibold-sm normal-case">
                  Notification Status
                </div>
              </div>
              <div className="divide-y divide-solid md:divide-none">
                {map(
                  filter(
                    subscriptions,
                    (x) =>
                      x.notificationSubcategoryId ===
                      cat.notificationSubcategoryId
                  ),
                  (sub, j) => (
                    <NotificationRow
                      key={j}
                      notification={sub}
                      handleChange={handleChange}
                    />
                  )
                )}
              </div>
            </div>
          ))}
        </Loading>
      </div>
      <div className="flex p-4 md:p-0 md:place-content-end place-content-center">
        <Button
          className="w-full md:w-fit"
          disabled={isLoading}
          variant="outlined"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </>
  )
}

export default NotificationSettings
