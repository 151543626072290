import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Divider, Form, Row, Tooltip, Typography } from 'antd'
import { Formik } from 'formik'
import findIndex from 'lodash/findIndex'
import fromPairs from 'lodash/fromPairs'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import map from 'lodash/map'
import pick from 'lodash/pick'
import values from 'lodash/values'
import { dividerCols, halfCols } from '~/utils/wizardContainer'
import {
  getInitialDealsValues,
  getInitialValues,
} from '../DynamicForm/DynamicForm'
import { FormField } from '../FundWizard'
import StepList from './StepList'
import './ServiceProviderWizard.less'

const { Title } = Typography

const fieldDependencies = {}

const getSteps = (titles, indexes) =>
  values(pick(fromPairs(map(titles, (title, ix) => [ix, title])), indexes))

const SERVICE_PROVIDER_STEPS = {
  TITLES: [
    'Edit Profile',
    'Media',
    'Logo',
    'People',
    'Testimonials',
    'Clients',
    'Content',
    'Contact',
  ],
  STEPS: [1, 2, 3, 4, 5, 6, 7],
}

const DEALS_STEPS = {
  TITLES: [
    'Deals',
    'Basic Information',
    'Opportunity Description',
    'Content',
    'Dataroom',
    'Disclaimer',
  ],
  STEPS: [1, 2, 3, 4, 5],
}

const ICONS = {
  facebook: faFacebookF,
  instagram: faInstagram,
  linkedin: faLinkedinIn,
  twitter: faTwitter,
}

const ServiceProviderStep = ({
  title,
  className = null,
  questions,
  answers,
  validationSchema,
  questionsWithOptions,
  isLast = false,
  loading = false,
  onNavigation,
  onPreview,
  onStepChange,
  currentStep,
  stepsInError,
  type,
  usage,
  status,
}) => {
  const STEPS =
    type === 'serviceproviders' ? SERVICE_PROVIDER_STEPS : DEALS_STEPS
  const initialValues = getInitialValues(
    questions,
    answers,
    questionsWithOptions
  )
  const initialDealsValues = getInitialDealsValues(
    questions,
    answers,
    questionsWithOptions
  )
  const classNames = ['ServiceProviderWizard-Step']
  if (!isNil(className)) {
    classNames.push(className)
  }
  const finalClassName = join(classNames, ' ')

  const [saveChanges, setSaveChanges] = useState(false)
  const [publish, setPublish] = useState(false)
  const [isValid, setIsValid] = useState(true)

  const steps = getSteps(STEPS.TITLES, STEPS.STEPS)

  const current = findIndex(STEPS.STEPS, (i) => i === currentStep)

  return (
    <Formik
      enableReinitialize={true}
      initialValues={type === 'deals' ? initialDealsValues : initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onNavigation(questions, values, true, saveChanges, publish, isValid)
      }}
      validateOnMount={usage === 'create'}
    >
      {({
        values,
        errors,
        isValid,
        handleBlur,
        handleChange,
        handleSubmit,
        validateForm,
        setFieldValue,
        setFieldTouched,
        setErrors,
      }) => (
        <Row className="ServiceProviderWizard-row">
          <Col className="ServiceProviderWizard-steps" span={8}>
            <StepList
              className="FundWizard-steps"
              steps={steps}
              current={current}
              onStepChange={(step) => {
                validateForm()
                if (isValid) {
                  onNavigation(questions, values, false, false)
                  onStepChange(step)
                }
              }}
              values={values}
              stepsInError={stepsInError}
            />
            <div style={{ textAlign: 'center' }}>
              <Button
                className="ServiceProviderStepPreviewButton"
                ghost
                htmlType="submit"
                type="primary"
                size="large"
                onClick={() => {
                  onNavigation(questions, values, false, false)
                  setSaveChanges(false)
                  setPublish(false)
                  onPreview()
                }}
                style={{
                  display: isLast || 'none',
                }}
                name="preview"
                value="preview"
                loading={loading}
              >
                Preview Changes
              </Button>
            </div>
          </Col>
          <Col span={16} className="ServiceProviderWizard-form">
            <Form
              onFinish={handleSubmit}
              className={finalClassName}
              autoComplete="off"
              layout="vertical"
            >
              <div>
                {!isNil(title) && (
                  <Title level={3} className="ServiceProviderWizard-step-title">
                    {title}
                  </Title>
                )}
                <Row>
                  {map(questions, (question) => (
                    <>
                      <Col
                        style={{
                          marginTop:
                            question.shortName === 'Moic' ? '35px' : '0px',
                        }}
                        span={halfCols(question) ? 12 : 24}
                      >
                        <div className="ServiceProviderStep-container">
                          {question.attributes?.groupByName &&
                            question.shortName === 'TargetIrr' && (
                              <h3 className="ServiceProviderStep-container-title">
                                {question.attributes?.groupByName}
                              </h3>
                            )}
                          {question.attributes?.preIcon && (
                            <div className="ServiceProviderStep-pre-icon">
                              <FontAwesomeIcon
                                icon={ICONS[question.attributes?.preIcon]}
                              />
                            </div>
                          )}

                          <div
                            className={`${
                              question.attributes?.infoIcon &&
                              'ServiceProviderStep-reduced'
                            } ${
                              question.attributes?.preIcon &&
                              'ServiceProviderStep-pre-reduced'
                            }`}
                          >
                            <Form.Item
                              label={
                                type === 'deals' &&
                                question.attributes.showLabel !== 'true' ? (
                                  <div className="ServiceProviderStep-label">
                                    <div>{question.name}</div>
                                    <div className="ServiceProviderStep-label-helper">
                                      {question.attributes.helpText}
                                    </div>
                                  </div>
                                ) : null
                              }
                              required={type === 'deals' && question.isRequired}
                            >
                              <FormField
                                key={question.serviceProviderQuestionId}
                                question={question}
                                values={values}
                                errors={errors}
                                dependencies={fieldDependencies}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                hideLabel={
                                  type === 'deals' &&
                                  question.attributes.showLabel !== 'true'
                                }
                                disabled={
                                  (question.attributes?.canEditAfterPublish ===
                                    'false' &&
                                    status === 'live') ||
                                  (question.attributes?.canEditAfterClose ===
                                    'false' &&
                                    status === 'closed')
                                }
                                isDeal={type === 'deals'}
                              />
                            </Form.Item>
                          </div>
                          {question.attributes?.infoIcon && (
                            <div className="ServiceProviderStep-info">
                              <Tooltip title={question.attributes?.infoIcon}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </Col>
                      {dividerCols(question) && <Divider />}
                    </>
                  ))}
                </Row>
              </div>
              <div className="ServiceProviderWizard-actions">
                {current >= 1 && (
                  <div
                    className="ServiceProviderStepButton-back"
                    onClick={() => {
                      setErrors({})
                      onNavigation(questions, values, false, false, false)
                    }}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} /> Back
                  </div>
                )}
                <div>
                  {(isLast || usage === 'edit') && status === 'draft' && (
                    <Button
                      className="ServiceProviderStepButton ServiceProviderStepButton-save"
                      htmlType="submit"
                      type="primary"
                      size="large"
                      ghost
                      name="save"
                      value="save"
                      disabled={!isValid}
                      loading={loading}
                      style={{
                        display: type === 'deals' ? isLast || 'none' : true,
                      }}
                      onClick={() => {
                        setSaveChanges(true)
                        setPublish(false)
                      }}
                    >
                      {'Save Draft'}
                    </Button>
                  )}
                  <Button
                    className="ServiceProviderStepPublishButton"
                    ghost
                    htmlType="submit"
                    type="primary"
                    size="large"
                    onClick={() => {
                      setSaveChanges(false)
                      setPublish(true)
                      setIsValid(isValid)
                    }}
                    style={{
                      display: isLast || 'none',
                    }}
                    name="preview"
                    value="preview"
                    loading={loading}
                  >
                    Publish
                  </Button>
                </div>{' '}
                {!isLast && (
                  <Button
                    className="ServiceProviderStepButton"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    name="nav"
                    value="next"
                    disabled={!isValid}
                    loading={loading}
                    onClick={() => {
                      setSaveChanges(false)
                      setPublish(false)
                    }}
                  >
                    {'Next'}
                  </Button>
                )}
              </div>
            </Form>
          </Col>
        </Row>
      )}
    </Formik>
  )
}

ServiceProviderStep.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  questions: PropTypes.array,
  answers: PropTypes.array,
  validationSchema: PropTypes.object,
  questionsWithOptions: PropTypes.any.isRequired,
  isLast: PropTypes.bool,
  loading: PropTypes.bool,
  onNavigation: PropTypes.func,
  onPreview: PropTypes.func,
  onStepChange: PropTypes.func,
  currentStep: PropTypes.number.isRequired,
  stepsInError: PropTypes.array,
  type: PropTypes.string,
  usage: PropTypes.string,
  status: PropTypes.string,
}

export default ServiceProviderStep
