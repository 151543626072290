import './fcm'
import { render } from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import { track } from 'react-tracking'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store, { history } from './store'
import * as analytics from './utils/analytics'
import * as errorReporting from './utils/errorReporting'
import './styles/index.less'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
})

errorReporting.initialize()
analytics.subscribeToLocationChanges(store, history)

function dispatchEvent({ eventName, ...eventData }) {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console -- this is only logged during development
    console.log(
      '%ctracking event %c%s',
      'color: #007ff5; font-weight: bold;',
      'font-weight: bold;',
      eventName,
      eventData
    )
  }
  analytics.trackEvent(eventName, eventData)
}

const TrackedApp = track({}, { dispatch: dispatchEvent })(App)

render(
  <Provider store={store}>
    <Router history={history}>
      <CompatRouter>
        <QueryClientProvider client={queryClient}>
          <TrackedApp />
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
      </CompatRouter>
    </Router>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
