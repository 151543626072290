import { Button } from '@context365/button'
import {
  ArrowUpwardOutlined,
  ArticleOutlined,
  CachedOutlined,
  Campaign,
  CorporateFare,
  HowToRegOutlined,
  InsightsOutlined,
  Launch,
  NewReleasesOutlined,
  PriceChange,
  SellOutlined,
  SpaOutlined,
  StarBorderOutlined,
  SwapHorizOutlined,
  VisibilityOutlined,
} from '@context365/icons'
import { useFlags } from 'launchdarkly-react-client-sdk'

const EventPopover = ({ title, subtitle, link, imageUrl = null }) => {
  const { discoverSignalEventLink } = useFlags()
  return (
    <div className="bg-dark text-white rounded p-2 w-48 flex flex-col space-y-4">
      <div className="h-24 w-full relative flex items-center justify-center">
        {imageUrl && (
          <div
            className="absolute inset-0 w-full h-full object-fill object-center bg-cover bg-bottom"
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
        )}
        {!imageUrl && (
          <>
            <div className="absolute inset-0 w-full h-full object-fill object-center bg-opacity-30 bg-cover bg-bottom" />
            <div className="absolute inset-0 w-full h-full bg-secondary-100" />
          </>
        )}
        <div className="z-10 type-subtitle-lg">{subtitle}</div>
      </div>
      <div className="type-body-regular-sm text-center">{title}</div>
      {discoverSignalEventLink && (
        <div className="flex flex-col space-y-2">
          <Button
            size="small"
            variant="filled"
            status="secondary"
            iconRight={<Launch />}
            as="a"
            href={link}
            target="_blank"
          >
            Learn More
          </Button>
        </div>
      )}
    </div>
  )
}

const contextSignals = {
  NewlyAdded: {
    icon: NewReleasesOutlined,
    backgroundColor: '#007AFF',
    color: '#FFFFFF',
    key: 'newlyAdded',
    field: 'NewlyAdded',
    text: 'Newly Added Fund',
    tooltip: 'Newly created fund',
  },
  RecentlyUpdated: {
    icon: CachedOutlined,
    backgroundColor: '#34C759',
    color: '#FFFFFF',
    key: 'recentlyUpdated',
    field: 'RecentlyUpdated',
    text: 'Recently Updated Returns',
    tooltip: 'Fund has updated returns within the last 30 days',
  },
  NewlyAddedAllocator: {
    icon: NewReleasesOutlined,
    backgroundColor: '#007AFF',
    color: '#FFFFFF',
    key: 'newlyAddedAllocator',
    field: 'NewlyAddedAllocator',
    text: 'New Allocator',
    tooltip: 'Created within the last 45 days',
  },
  RecentlyUpdatedAllocator: {
    icon: CachedOutlined,
    backgroundColor: '#34C759',
    color: '#FFFFFF',
    key: 'recentlyUpdatedAllocator',
    field: 'RecentlyUpdatedAllocator',
    text: 'Recently Updated Allocator',
    tooltip: 'Updated profile within the last 45 days',
  },
  esg: {
    icon: SpaOutlined,
    backgroundColor: '#2A9C47',
    color: '#FFFFFF',
    key: 'esg',
    field: 'esg',
    text: 'ESG Fund',
    tooltip:
      'Fund with social responsibility / impact investing / ESG attributes',
  },
  hasPM: {
    icon: HowToRegOutlined,
    backgroundColor: '#1B5BA0',
    color: '#FFFFFF',
    key: 'hasPM',
    field: 'hasPM',
    text: 'Fund Portfolio Manager',
    tooltip: 'Fund with a portfolio manager on the platform',
  },
  isEmerging: {
    icon: ArrowUpwardOutlined,
    backgroundColor: '#5856D6',
    color: '#FFFFFF',
    key: 'isEmerging',
    field: 'isEmerging',
    text: 'Emerging Fund',
    tooltip:
      'Track record shorter than 3 years, firm AUM < 1B and fund AUM < 250MM',
  },
  PreLaunch: {
    icon: Campaign,
    backgroundColor: '#a87a66',
    color: '#FFFFFF',
    key: 'preLaunch',
    field: 'PreLaunch',
    text: 'Pre-Launch Fund',
    tooltip: 'Fund with launch date in the future',
  },
  MostViewd: {
    icon: VisibilityOutlined,
    backgroundColor: '#FF2D55',
    color: '#FFFFFF',
    key: 'mostViewd',
    field: 'MostViewd',
    text: 'Most Viewed Fund',
    tooltip: 'Most viewed fund over the last 60 days',
  },
  UncorrelatedWithSPX: {
    icon: SwapHorizOutlined,
    backgroundColor: '#a09b5d',
    color: '#FFFFFF',
    key: 'uncorrelatedWithSPX',
    field: 'UncorrelatedWithSPX',
    text: 'Less Correlated To SPX',
    tooltip: 'Fund with correlation coefficient to SPX 0.25 or lower',
  },
  CrisisOutperformer: {
    icon: InsightsOutlined,
    backgroundColor: '#ED4040',
    color: '#FFFFFF',
    key: 'crisisOutperformer',
    field: 'CrisisOutperformer',
    text: 'Market Crisis Outperformer',
    tooltip:
      "On average fund has outperformed the SPX when the index has dropped at least 10% in a month, with 3 or more crises happening during the fund's history",
  },
  Institutional: {
    icon: CorporateFare,
    backgroundColor: '#5856D6',
    color: '#FFFFFF',
    key: 'institutional',
    field: 'Institutional',
    text: 'Institutional Allocator',
    tooltip: 'From an institutional category',
  },
  DecisionMaker: {
    icon: StarBorderOutlined,
    backgroundColor: '#FF2D55',
    color: '#FFFFFF',
    key: 'decisionMaker',
    field: 'DecisionMaker',
    text: 'Decision Maker',
    tooltip:
      'Job function relates directly to allocation, job level is MD or higher, based on self reported data',
  },
  topCapitalAccumulator: {
    icon: PriceChange,
    backgroundColor: '#995c98',
    color: '#FFFFFF',
    key: 'topCapitalAccumulator',
    field: 'topCapitalAccumulator',
    text: 'Top Estimated Capital Accumulator',
    tooltip:
      'A calculated estimate based on AUM and performance since inception which may not reflect all fund activity',
  },
  AttendingMiami2022: {
    icon: SellOutlined,
    backgroundColor: '#2A9C47',
    color: '#FFFFFF',
    key: 'attendingMiami2022',
    text: 'Attended Miami 2022',
    tooltip: 'Attended ApexInvest Miami 2022',
    popover: (
      <EventPopover
        title="ApexInvest Miami 2022"
        subtitle="Miami 2022"
        link="https://www.apexinvest.io/miami-2022"
        imageUrl="https://stapex365backendprod.blob.core.windows.net/context365-data/tmp/Miami22_Thumbail%20-%20Event%20400x300_20210830081746.png"
      />
    ),
  },
  AttendingWest2022: {
    icon: SellOutlined,
    backgroundColor: '#f19f00',
    color: '#FFFFFF',
    key: 'attendingWest2022',
    text: 'Attended West 2022',
    tooltip: 'Attended ApexInvest West 2022',
    popover: (
      <EventPopover
        title="ApexInvest West 2022"
        subtitle=""
        link="https://www.apexinvest.io/west-2022"
        imageUrl="https://stapex365backendprod.blob.core.windows.net/context365-data/tmp/West_2022.png"
      />
    ),
  },
  AttendingNYC2022: {
    icon: SellOutlined,
    backgroundColor: '#008dca',
    color: '#FFFFFF',
    key: 'attendingNyc2022',
    text: 'Attended NYC 2022',
    tooltip: 'Attended ApexInvest NYC 2022',
    popover: (
      <EventPopover
        title="ApexInvest NYC 2022"
        subtitle=""
        link="https://www.apexinvest.io/new-york-2022"
        imageUrl="https://stapex365backendprod.blob.core.windows.net/context365-data/tmp/NYC_2022.png"
      />
    ),
  },
  HasPublicMandates: {
    icon: ArticleOutlined,
    backgroundColor: '#008dca',
    color: '#FFFFFF',
    key: 'hasPublicMandates',
    field: 'HasPublicMandates',
    text: 'Posted a mandate',
    tooltip: 'Company has posted a public mandate on ApexInvest',
  },
  AttendingMiami2023: {
    icon: SellOutlined,
    backgroundColor: '#2A9C47',
    color: '#FFFFFF',
    key: 'attendingMiami2023',
    text: 'Attended Miami 2023',
    tooltip: 'Attended ApexInvest Miami 2023',
    popover: (
      <EventPopover
        title="ApexInvest Miami 2023"
        subtitle="Miami 2023"
        link="https://www.apexinvest.io/miami-2023"
        imageUrl="https://stapex365backendprod.blob.core.windows.net/context365-data/tmp/Miami22_Thumbail%20-%20Event%20400x300_20210830081746.png"
      />
    ),
  },
}

export default contextSignals
