import * as React from 'react'
import { FormLabel, FormMessage } from '@context365/forms'
import { CloudUpload } from '@context365/icons'
import { Upload } from 'antd'
import isNil from 'lodash/isNil'
import last from 'lodash/last'
import split from 'lodash/split'
import { useController } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as api from '~/api'
import { useFieldSchema } from '~/components/Form'
import { BASE_URL } from '~/config'

const { Dragger } = Upload

const viableExtensions = '.jpg, .png, .jpeg'

export const ProfilePictureForm = ({ className }) => {
  const rules = useFieldSchema('imageUrl', 'Profile Picture')
  const accessToken = useSelector((state) => state.auth.accessToken)
  const [file, setFile] = React.useState(null)
  const {
    field: { value = null, onChange, ref },
    fieldState: { error },
  } = useController({ name: 'imageUrl' })

  React.useEffect(() => {
    if (!isNil(value)) {
      try {
        const url = new URL(value)

        setFile({
          uid: 'uff-1',
          name: last(split(decodeURI(url.pathname), '/')),
          status: 'done',
          url: decodeURI(url.href),
        })
      } catch (e) {
        console.error('[UploadFileField]::ERROR:', e)
      }
    }
  }, [value])

  const isCorrectFileExtension = (file) => {
    const extension = /[.]/.exec(file.name)
      ? /[^.]+$/.exec(file.name)[0]
      : undefined

    const acceptedExtensions = viableExtensions.replaceAll(' ', '').split(',')
    return acceptedExtensions?.includes(`.${extension.toLowerCase()}`)
  }

  const handleChange = (info) => {
    const { status, response } = info.file

    if (status === 'removed') {
      onChange(null)
      return
    }

    const isIncludedInAccept = isCorrectFileExtension(info.file)

    if (!isIncludedInAccept) {
      info.file.status = 'error'
      info.file.response = 'Incorrect file type'
      return
    }

    setFile(info.file)
    if (status === 'done' && !isNil(response)) {
      const { url } = response.result
      onChange(encodeURI(url))
    }
  }

  const handlePreview = (file) => {
    api.attachments
      .downloadDocument(file.response.result.url)
      .then((response) => {
        window.open(response.data, '_blank')
      })
  }

  const handleRemove = () => {
    setFile(null)
    onChange(null)
  }

  return (
    <div ref={ref} className={className}>
      <FormLabel
        className="type-body-regular-sm mb-0"
        required={rules.required}
      >
        Profile Picture
      </FormLabel>
      <FormMessage
        className="mt-0 mb-1 border-b"
        errorMessage={error?.message}
      />
      <div>
        <Dragger
          className="mb-2"
          method="post"
          action={`${BASE_URL}/uploads`}
          name="file"
          data={{ name: 'imageUrl' }}
          headers={{ Authorization: `Bearer ${accessToken}` }}
          fileList={file ? [file] : []}
          onChange={handleChange}
          onRemove={handleRemove}
          onPreview={handlePreview}
          listType="picture-card"
        >
          <div className="type-body-regular-xs">
            Click or drag and drop an image file to upload it.
          </div>
          <CloudUpload />
        </Dragger>
      </div>
    </div>
  )
}
