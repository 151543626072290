import PropTypes from 'prop-types'
import { Divider } from 'antd'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import { addLineBreaks, formatCurrency } from '~/utils/helpers'

const getRange = (minAllocationRange, maxAllocationRange) => {
  if (minAllocationRange && maxAllocationRange) {
    return `${formatCurrency(minAllocationRange)} - ${formatCurrency(
      maxAllocationRange
    )}`
  } else if (minAllocationRange) {
    return `${formatCurrency(minAllocationRange)} +`
  } else if (maxAllocationRange) {
    return `Up to ${formatCurrency(maxAllocationRange)}`
  } else {
    return 'Not sure yet'
  }
}

const LabelAndValue = ({ label, value, size = 'small' }) => (
  <div className="flex flex-col space-y-1 pb-2">
    <label
      className={cx({
        'type-body-semibold-sm': size === 'small',
        'type-subtitle-md': size === 'big',
      })}
    >
      {label}
    </label>
    <span
      className={cx({
        'type-body-regular-lg': size === 'big',
      })}
    >
      {value}
    </span>
  </div>
)

const ResearchCampaignOverview = ({ researchCampaign }) => {
  return (
    <div className="flex px-4">
      <div className="flex flex-col space-y-4 w-1/2">
        <LabelAndValue
          label="Description"
          value={addLineBreaks(researchCampaign.description)}
        />
        {!isEmpty(researchCampaign.verticalInterests) && (
          <LabelAndValue
            label="Vertical Interests"
            value={join(researchCampaign.verticalInterests, ', ')}
          />
        )}
        {!isEmpty(researchCampaign.industryInterests) && (
          <LabelAndValue
            label="Sector Interests"
            value={join(researchCampaign.industryInterests, ', ')}
          />
        )}
        {!isEmpty(researchCampaign.geographyInterests) && (
          <LabelAndValue
            label="Geographic Interests"
            value={join(researchCampaign.geographyInterests, ', ')}
          />
        )}
        {!isEmpty(researchCampaign.vehicleInterests) && (
          <LabelAndValue
            label="Vehicle Interests"
            value={join(researchCampaign.vehicleInterests, ', ')}
          />
        )}
      </div>
      <div className="px-6">
        <Divider type="vertical" style={{ height: '100%' }} />
      </div>
      <div className="flex flex-col space-y-4">
        <LabelAndValue label="Pipeline Type" value="Research" size="big" />
        <LabelAndValue
          label="Allocation Range"
          value={getRange(
            researchCampaign.minAllocationRange,
            researchCampaign.maxAllocationRange
          )}
          size="big"
        />
      </div>
    </div>
  )
}

ResearchCampaignOverview.propTypes = {
  researchCampaign: PropTypes.shape({
    description: PropTypes.string,
    investmentPhilosophy: PropTypes.string,
    minAllocationRange: PropTypes.number,
    maxAllocationRange: PropTypes.number,
    verticalInterests: PropTypes.arrayOf(PropTypes.string),
    industryInterests: PropTypes.arrayOf(PropTypes.string),
    geographyInterests: PropTypes.arrayOf(PropTypes.string),
    vehicleInterests: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}

export default ResearchCampaignOverview
