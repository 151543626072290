import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Check, CheckCircle } from '@context365/icons'
import { Menu, SplitMenuButton } from '@context365/menu'
import map from 'lodash/map'
import some from 'lodash/some'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import * as api from '~/api'
import useAuth from '~/hooks/useAuth'
import { showApplyToCampaignModal } from '~/store/modals'
import '../ApplyButton.css'

const ONE_HOUR_IN_MILLISECONDS = 1000 * 60 * 60

const ApplyToCampaignButton = ({ campaignEntity, campaignType }) => {
  const dispatch = useDispatch()
  const { company } = useAuth()

  const { data } = useQuery(
    ['myCampaigns', company.companyId],
    () => api.campaigns.getMyCampaigns(company.companyId, { pageSize: 25 }),
    {
      staleTime: ONE_HOUR_IN_MILLISECONDS,
      cacheTime: ONE_HOUR_IN_MILLISECONDS,
    }
  )

  const campaigns = data?.results

  const campaignsWithApplicationStatus = map(campaigns, (c) => ({
    ...c,
    hasApplied: some(
      campaignEntity.campaignApplications,
      (ca) => ca.campaignId === c.campaignId
    ),
  }))

  const hasApplied = some(campaignsWithApplicationStatus, 'hasApplied')
  const canApplyWithAny =
    campaignsWithApplicationStatus.length > 0 &&
    some(campaignsWithApplicationStatus, (c) => !c.hasApplied)

  const handleApplyToCampaign = (applyingCampaignId = null) =>
    dispatch(
      showApplyToCampaignModal({
        campaignName: campaignEntity.campaignName,
        campaignType,
        campaignId: campaignEntity.campaignId,
        campaignApplications: campaignEntity.campaignApplications ?? [],
        defaultApplyingCampaignId: applyingCampaignId,
      })
    )

  if (!hasApplied) {
    return (
      <Button variant="filled" onClick={() => handleApplyToCampaign()}>
        Indicate Interest
      </Button>
    )
  } else if (canApplyWithAny) {
    return (
      <Menu
        trigger={
          <SplitMenuButton
            className="interested-button"
            variant="filled"
            primaryAction={() => {}}
          >
            Interested
          </SplitMenuButton>
        }
      >
        {map(campaignsWithApplicationStatus, (campaign) => (
          <Menu.Item
            key={campaign.campaignId}
            disabled={campaign.hasApplied}
            onClick={(e) => {
              e.stopPropagation()
              handleApplyToCampaign(campaign.campaignId)
            }}
          >
            {campaign.hasApplied && (
              <CheckCircle className="text-green-110 mr-2" size="small" />
            )}
            {campaign.hasApplied ? 'Indicated Interest' : 'Indicate Interest'}{' '}
            with {campaign.name}
          </Menu.Item>
        ))}
      </Menu>
    )
  } else {
    return (
      <Button
        className="interested-button"
        variant="filled"
        iconLeft={<Check />}
      >
        Interested
      </Button>
    )
  }
}

ApplyToCampaignButton.propTypes = {
  campaignEntity: PropTypes.shape({
    campaignId: PropTypes.number.isRequired,
    campaignName: PropTypes.string.isRequired,
    campaignApplications: PropTypes.arrayOf(
      PropTypes.shape({
        campaignId: PropTypes.number.isRequired,
        applicationId: PropTypes.number.isRequired,
        wasSent: PropTypes.bool.isRequired,
      })
    ).isRequired,
    campaignThreshold: PropTypes.number,
  }).isRequired,
  campaignType: PropTypes.string.isRequired,
}

export default ApplyToCampaignButton
