import { useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {
  FundPerformanceChart,
  FundReturnAumChart,
  FundReturnDistributionChart,
  FundReturnSpxChart,
  PerformanceTable,
  VamiChart,
  useChartWidth,
  useFundPerformanceData,
} from '../FundPerformanceCharts'
import Loading from '../Loading'
import './FundCharts.less'

const FundCharts = ({ fundId, indexFund }) => {
  const chartsContainer = useRef(null)
  const chartWidth = useChartWidth(chartsContainer, -50)

  const {
    loading,
    fundMonthlyReturns,
    fundYTDReturns,
    fundReturnDistribution,
    fundReturnAUM,
    fundReturnSPX,
    fundPerformance,
    customBenchmark,
    fundVami,
    fundPeriod,
  } = useFundPerformanceData(fundId, indexFund)

  return (
    <div
      className={cx('charts-container', loading && 'text-center')}
      ref={chartsContainer}
    >
      {loading ? (
        <Loading style={{ margin: '128px 0' }} />
      ) : (
        <>
          <div>
            <div className="ChartLabel">
              <span>Performance</span>
            </div>
            <PerformanceTable
              fundMonthlyReturns={fundMonthlyReturns}
              fundYtdReturns={fundYTDReturns}
            />
          </div>
          <VamiChart
            fundVami={fundVami}
            fundPeriod={fundPeriod}
            customBenchmark={customBenchmark}
            width={chartWidth}
          />
          <FundReturnAumChart
            fundReturnAum={fundReturnAUM}
            width={chartWidth}
          />
          <FundReturnSpxChart
            fundReturnSpx={fundReturnSPX}
            width={chartWidth}
          />
          <FundReturnDistributionChart
            fundReturnDistribution={fundReturnDistribution}
            width={chartWidth}
          />
          <FundPerformanceChart
            fundPerformance={fundPerformance}
            width={chartWidth}
          />
        </>
      )}
    </div>
  )
}

FundCharts.propTypes = {
  fundId: PropTypes.number.isRequired,
  indexFund: PropTypes.number.isRequired,
}

export default FundCharts
