import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Launch } from '@context365/icons'
import map from 'lodash/map'
import { getClickableLink } from '~/utils/helpers'

const CompanyProfileLinks = ({ links }) => {
  return (
    <>
      {map(links, (link) => {
        return (
          <div>
            <Button
              variant="link"
              status="secondary"
              target="_blank"
              href={getClickableLink(link.url)}
              as="a"
              iconRight={<Launch />}
            >
              {link.title}
            </Button>
          </div>
        )
      })}
    </>
  )
}

CompanyProfileLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default CompanyProfileLinks
