import { useCallback } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import RadioButtonItem from './RadioButtonItem'
import './RadioButtonList.less'

const RadioButtonList = ({
  value,
  options,
  label = null,
  allowClear = false,
  onChange,
}) => {
  const handleSelected = useCallback(
    (newValue) => {
      if (isFunction(onChange)) {
        onChange(newValue)
      }
    },
    [onChange]
  )

  const showLabel = !isNil(label) && !isEmpty(label)

  return (
    <div className="RadioButtonList" role="listbox">
      {showLabel && <label className="RadioButtonList-label">{label}</label>}
      <div className="RadioButtonList-items">
        {map(options, (opt) => (
          <RadioButtonItem
            key={opt.value}
            {...opt}
            selected={value === opt.value}
            onSelected={handleSelected}
            allowClear={allowClear}
          />
        ))}
      </div>
    </div>
  )
}

RadioButtonList.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.string,
      icon: PropTypes.object,
    }).isRequired
  ).isRequired,
  label: PropTypes.string,
  allowClear: PropTypes.bool,
  onChange: PropTypes.func,
}

export default RadioButtonList
