import { useContext } from 'react'
import { Radio } from 'antd'
import { useSelector } from 'react-redux'
import PresentationWizardCtx from '~/utils/contexts/PresentationWizardCtx'

const PresentationType = () => {
  const { presentationType, setPresentationType, displayData, isPublished } =
    useContext(PresentationWizardCtx)
  const role = useSelector((state) => state.auth.role)

  const isKeyDisabled = (type) => {
    if (isPublished) return true
    if (type.id === 1) return false
    if (type.id === 2 && role.toLowerCase() === 'manager') return false
    if (
      type.id === 3 &&
      (role.toLowerCase() === 'cfn' ||
        role.toLowerCase() === 'service provider')
    )
      return false
    return true
  }
  return (
    <Radio.Group
      defaultValue={presentationType}
      onChange={(e) => setPresentationType(e.target.value)}
    >
      {displayData.types.map((t, key) => (
        <div key={key}>
          <Radio disabled={isKeyDisabled(t)} value={t.id}>
            <div className="text-md font-semibold">{t.name}</div>
            {/* <div className="text-small text-grey-500">{t.description} </div> */}
          </Radio>
          <hr className="cc-light-grey-border my-3" />
        </div>
      ))}
    </Radio.Group>
  )
}

export default PresentationType
