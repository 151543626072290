import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { fetchCompanyIfNeeded } from '~/actions/company'
import useAuth from '~/hooks/useAuth'
import usePageTitle from '~/hooks/usePageTitle'

const TopTabs = ({ children }) => {
  const title = usePageTitle()
  useKeepCompanyUpToDate(title)

  if (children) {
    return <div className="TopBar-tabs bg-white">{children}</div>
  }

  return null
}

TopTabs.propTypes = {
  children: PropTypes.node,
}

export default TopTabs

function useKeepCompanyUpToDate(title) {
  const { role, company } = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    function fetchCompany() {
      if (
        title === 'Settings' &&
        (role.toLowerCase() === 'allocator' || role.toLowerCase() === 'manager')
      ) {
        dispatch(fetchCompanyIfNeeded(company.companyId))
      }
    }

    fetchCompany()
  }, [dispatch, title, role, company.companyId])
}
