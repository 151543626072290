import groupBy from 'lodash/groupBy'
import isNil from 'lodash/isNil'
import max from 'lodash/max'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import { getFormatedMeetingTime } from '~/utils/meetingActions'

const MessageLogTypes = {
  0: 'MeetingRequestMessage',
  1: 'MeetingFollowUpMessage',
  2: 'ConversationMessage',
  3: 'MeetingLogRequested',
  4: 'MeetingLogAccepted',
  5: 'MeetingLogDeclined',
  6: 'MeetingLogRescheduled',
  7: 'MeetingLogCancelled',
  8: 'ConversationStarted',
  9: 'ConversationInvited',
  10: 'ConversationLeft',
  11: 'MeetingLogSetTime',
}

const DataTransformations = {
  ConvertConversationsForList: (rawConversations) =>
    rawConversations.map((x) => ({
      ...x,
      lastMessage: isNil(x.lastMessage)
        ? null
        : { ...x.lastMessage, timeSent: moment.utc(x.lastMessage.timestamp) },
      unreadMessages: x.totalUnreadMessageCount,
    })),

  ConvertMeetingsForList: (rawMeetings) =>
    rawMeetings.map((x) => ({
      ...x,
      conversationID: x.meetingID,
    })),

  ConvertToHighlightedMessage: (message, searchTerm) => {
    let index = message.toLowerCase().indexOf(searchTerm.toLowerCase())
    let allResults = []
    while (index >= 0) {
      const firstPart = message.substring(0, index)
      const secondPart = message.substring(index, index + searchTerm.length)
      message = message.substring(index + searchTerm.length)
      allResults = [
        ...allResults,
        <b key={`PART_${index}`}>{firstPart}</b>,
        <b key={`FOUND_${index}`} className="message-highlighted-bit">
          {secondPart}
        </b>,
      ]
      index = message.toLowerCase().indexOf(searchTerm.toLowerCase(), index + 1)
    }

    allResults = [...allResults, <b key="last">{message}</b>]
    return allResults
  },

  RenderTimeForMessages: (time) => moment.utc(time).local().format('h:mm A'),

  RenderTimeForLogMessages: (time) => {
    //alwaus show in local timezone
    return getFormatedMeetingTime(
      moment.tz.guess(),
      true,
      time,
      'MMM DD, YYYY h:mm A'
    )
    // moment.utc(time).local().format('MMM DD, YYYY h:mm A') +
    // `(GMT${time.format('Z')}) ${moment.tz.guess()}`.replace('_', ' '),
  },

  RenderTimeForConversation: (time) => {
    const dayDifference = moment().diff(moment(time), 'days')
    const day = moment(time).date()

    let ordinal = 'th'
    if (day === 1 || day === 21 || day === 31) ordinal = 'st'
    else if (day === 2 || day === 22) ordinal = 'nd'
    else if (day === 3 || day === 23) ordinal = 'rd'

    if (dayDifference === 0) return moment.utc(time).local().format('h:mm A')
    else if (dayDifference === 1)
      return moment.utc(time).local().format('[Yesterday at] h:mm A')
    else if (dayDifference < 7) return moment(time).format('dddd [at] h:mm A')
    else return moment(time).format(`MMMM D[${ordinal}], Y [at] h:mm A`)
  },

  RenderStringForDaySeparator: (time) => {
    const formatToShow = {
      0: 'TODAY',
      1: 'YESTERDAY',
      2: moment(time).format('dddd'),
      3: moment(time).format('D MMMM yyyy'),
    }

    let dayDifference = moment().diff(moment(time), 'days')

    if (dayDifference > 1) {
      dayDifference = dayDifference < 7 ? 2 : 3
    }

    const timeStr = formatToShow[dayDifference]
    return timeStr
  },

  groupMessagesIntoDays: (messages) =>
    groupBy(messages, (msg) => moment(msg.timeSent).startOf('day').format()),

  getMessageType: (message, contactID) => {
    if (!isNil(message.logTypeID) && message.logTypeID > 0) return null

    if (message.authorContact.contactId === contactID) return 'mine'
    else return 'others'
  },

  transformOrderAndIndexMessages: (messages, totalMessages, conversationId) => {
    const transformedMessages = messages.map((m) => ({
      messageID: m.messageID,
      logTypeID: isNil(m.logTypeID)
        ? m.messageTypeID > 2
          ? m.messageTypeID
          : null
        : m.logTypeID,
      logType: isNil(m.logType)
        ? m.messageTypeID > 2
          ? m.messageType
          : null
        : m.logType,
      timeSent: isNil(m.timeSent) ? moment.utc(m.timestamp) : m.timeSent,
      body: m.body,
      authorContact: m.authorContact,
      isAttachment: m.isAttachment,
      mediaSid: m.mediaSid,
      attachmentUrl: `messaging/${conversationId}/attachments/${m.mediaSid}`,
    }))

    let orderedMessages = orderBy(transformedMessages, ['timeSent'], 'asc')
    orderedMessages = orderedMessages.map((x, index) => ({
      ...x,
      index: totalMessages - messages.length + index,
    }))

    return orderedMessages
  },

  GetCompanyFromConversation: (conversation) => ({
    companyID: conversation.details.companyID,
    name: conversation.details.companyName,
    title: conversation.details.companyTitle,
    companyTypeID: conversation.details.companyTypeID,
    companyImageURL: conversation.details.companyImageURL,
    companyCategory: conversation.details.companyType,
    funds: isNil(conversation.details.funds)
      ? []
      : conversation.details.funds.map((f) => ({
          fundID: f.fundID,
          name: f.fundName,
          documents: f.documents,
        })),
    invitedCompanyID: conversation.details.invitedCompanyID,
    invitedCompanyTypeID: conversation.details.invitedCompanyTypeID,
    invitedCompanyName: conversation.details.invitedCompanyName,
    invitedCompanyImageURL: conversation.details.invitedCompanyImageURL,
  }),

  GetDealInfoFromConversation: (conversation) => ({
    dealId: conversation.details.dealId,
    dealImageUrl: conversation.details.dealImageUrl,
    dealTitle: conversation.details.dealTitle,
  }),

  TransformTwilioMessage: (
    twilioMessage,
    allTimeParticipants,
    conversationId
  ) => ({
    messageID: twilioMessage.sid,
    index: twilioMessage.index,
    logTypeID:
      twilioMessage.attributes.MessageTypeID > 2
        ? twilioMessage.attributes.MessageTypeID
        : null,
    logType: MessageLogTypes[twilioMessage.attributes.MessageTypeID],
    timeSent: moment.utc(twilioMessage.timestamp),
    body: isNil(twilioMessage.media)
      ? twilioMessage.body
      : twilioMessage.media.filename,
    //must change and not rely on message info
    authorContact: allTimeParticipants.find(
      (x) => x.contactId === parseInt(twilioMessage.author, 10)
    ),
    isAttachment: !isNil(twilioMessage.media),
    mediaSid: isNil(twilioMessage.media) ? null : twilioMessage.media.sid,
    attachmentUrl: isNil(twilioMessage.media)
      ? null
      : `messaging/${conversationId}/attachments/${twilioMessage.media.sid}`,
  }),

  TransformMessagesToMessagesWithStatus: (
    messages,
    consumptionLookup,
    contactID,
    companyID
  ) => {
    const processedMessages = [...messages]
    const otherConsumptionIndexes = consumptionLookup.filter(
      (x) => x.companyID !== companyID
    )
    const maxIndex = max(
      processedMessages
        .filter((x) => x.authorContact.contactId === contactID)
        .map((x) => x.index)
    )

    const getStatus = (message) => {
      if (message.authorContact.contactId !== contactID) return null
      if (
        otherConsumptionIndexes.find(
          (x) => x.lastConsumedMessageIndex === message.index
        )
      )
        return 'read'

      if (message.index === maxIndex) {
        if (
          otherConsumptionIndexes.find(
            (x) => x.lastConsumedMessageIndex > message.index
          )
        )
          return 'read'
        return 'unread'
      }

      return null
    }

    return processedMessages.map((m) => ({
      ...m,
      status: getStatus(m),
    }))
  },
}

export default DataTransformations
