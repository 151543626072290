import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router-dom'
import { Outlet } from 'react-router-dom-v5-compat'

const AuthorizationGate = ({
  children = <Outlet />,
  blockWhen = () => false,
  fallback,
}) => {
  const { params } = useRouteMatch()
  return blockWhen(params) ? fallback : children
}

AuthorizationGate.propTypes = {
  children: PropTypes.node,
  blockWhen: PropTypes.func,
  fallback: PropTypes.node,
}

export default AuthorizationGate
