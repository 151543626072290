import { CAMPAIGN_ENTITY_TYPES } from '~/constants/campaignEntityTypes'
import { ILLIQUID_FUND_TYPE, LIQUID_FUND_TYPE } from '~/constants/funds'

export const allocationTypes = [
  {
    name: 'Mandate',
    value: 'mandate',
    campaignEntityTypeId: CAMPAIGN_ENTITY_TYPES.MANDATE,
    label: 'Allocate',
    body: (
      <div>
        Choose this <b>when you are ready to allocate</b> a specific range of
        capital to a specific strategy when you meet the right investment
        partners.
      </div>
    ),
  },
  {
    name: 'Research',
    value: 'research',
    campaignEntityTypeId: CAMPAIGN_ENTITY_TYPES.RESEARCH,
    label: 'Learn',
    body: (
      <div>
        Choose this when <b>you&apos;re not ready to allocate</b> but you want
        to review strategies and connect with people who invest in specific
        strategies and sectors.
      </div>
    ),
  },
  {
    name: 'Service Provider Hire',
    value: 'hire',
    campaignEntityTypeId: CAMPAIGN_ENTITY_TYPES.MANDATE,
    label: 'Hire',
    body: (
      <div>
        Choose this <b>when you want to hire a service provider</b> like an
        investment consultant, software firm, or auditor.
      </div>
    ),
  },
]

export const fundTypes = [
  {
    name: 'Liquid fund',
    value: LIQUID_FUND_TYPE,
    campaignEntityTypeId: CAMPAIGN_ENTITY_TYPES.FUND,
    label: 'Raise',
    subtitle:
      'This profile showcases monthly returns and generates a tearsheet.',
    body: (
      <div>
        The liquid fund category includes most:
        <ul>
          <li>hedge funds and CTAs</li>
          <li>funds with lots of liquidity</li>
          <li>funds with short lock up periods</li>
        </ul>
      </div>
    ),
  },
  {
    name: 'Illiquid fund',
    value: ILLIQUID_FUND_TYPE,
    campaignEntityTypeId: CAMPAIGN_ENTITY_TYPES.FUND,
    label: 'Raise',
    subtitle:
      'This profile showcases quarterly or annual returns, vintage funds, and IRR related metrics.',
    body: (
      <div>
        The illiquid fund category includes most:
        <ul>
          <li>private equity and tangible assets</li>
          <li>funds that lack liquidity</li>
          <li>funds with long lock up periods</li>
        </ul>
      </div>
    ),
  },
]
