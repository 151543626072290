import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NotificationsOutlined } from '@context365/icons'
import { Input, Modal, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { useTracking } from 'react-tracking'
import { saveSearchAlert } from '~/actions/discover'
import { SEARCH_LABEL } from '~/constants/discover'
import { generateQuery } from '~/utils/discover'
import FilterTag from '../DiscoverFilters/FilterTag'
import formatFilters from '../DiscoverFilters/formatFilters'
import './CreateSearchAlert.less'

const CreateSearchAlert = ({
  visible,
  closeModal,
  filters,
  searchTerm,
  category,
}) => {
  const [acceptingInProgress, setAcceptingInProgress] = useState(false)
  const [alertName, setAlertName] = useState('')
  const [isEmailChecked] = useState(false)
  const [isPushNotificationChecked] = useState(false)
  const [currentFilters, setCurrentFilters] = useState([])
  const [originalFilter, setOriginalFilter] = useState([])

  const { trackEvent } = useTracking({ component: 'CreateSearchAlert' })

  useEffect(() => {
    if (visible) {
      const existingFilterIndex = filters.findIndex(
        (x) => x.alertColumnType === 'Fixed'
      )
      const filterList = [...filters]
      if (existingFilterIndex > -1) filterList.splice(existingFilterIndex, 1)

      const filterWithQueries = filterList.map((x) =>
        isEmpty(x.values)
          ? null
          : {
              ...x,
              filterQuery: generateQuery(x),
              isVisible: x.isVisible ? x.isVisible : true,
              values: x.values.map((v) => ({ filterValue: v })),
            }
      )
      setOriginalFilter(filterWithQueries)
      setCurrentFilters(formatFilters(filterList))
    }
  }, [filters, visible])

  const handleAccept = () => {
    trackEvent({ eventName: 'click', element: 'CreateAlertButton' })
    setAcceptingInProgress(true)
    saveSearchAlert({
      Name: alertName,
      EmailNotificationEnabled: isEmailChecked,
      PushNotificationEnabled: isPushNotificationChecked,
      Filters: originalFilter,
      SearchedCategory: category,
      SearchTerm: searchTerm,
    })
      .then(() => {
        message.success('Search Alert Created!')
        closeModal()
      })
      .catch(() => {
        message.error('Something went wrong!')
      })
      .finally(() => {
        setAcceptingInProgress(false)
      })
  }

  return (
    <Modal
      destroyOnClose
      className="cc-modal-accept"
      title="Create a Search Alert"
      visible={visible}
      okText="Create Alert"
      cancelText="Close"
      onOk={handleAccept}
      onCancel={closeModal}
      okButtonProps={{
        loading: acceptingInProgress,
        disabled: alertName === '',
      }}
      cancelButtonProps={{ disabled: false }}
      width="555px"
      bodyStyle={{ padding: '24px' }}
    >
      <div className="text-center">
        <div className="p-8">
          <NotificationsOutlined className="text-brand-100 mb-4" size={48} />
          <div className="createAlertBody">
            <h4 className="mainHeading">Create a Search Alert</h4>
            <h5 className="subHeading">
              Relax while we do the searching for you
            </h5>
            <p className="subInfo">
              We’ll send you an alert when a new lead meets your search
              criteria. Name your alert and we’ll do the rest.
            </p>
            <Input
              className="inputSearchAlert"
              placeholder="Name your alert"
              onChange={(val) => setAlertName(val.target.value)}
              maxLength={50}
            />
            {searchTerm && <FilterTag name={SEARCH_LABEL} value={searchTerm} />}
            <span className="space-x-2">
              {currentFilters?.filter(Boolean).map((x) => (
                <FilterTag key={x.Column} name={x.Column} value={x.Value} />
              ))}
            </span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

CreateSearchAlert.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  category: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
}

export default CreateSearchAlert
