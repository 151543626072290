import { Button } from '@context365/button'
import { RemoveCircleOutline } from '@context365/icons'
import { InputField, SelectField } from '~/components/Form'
import { DatePickerField } from '~/components/Form/Fields/DatePickerField'
import { useOnboardingContext } from '../OnboardingContext'

export default function HoldingField({ name, index, removeItem }) {
  const { options } = useOnboardingContext()
  return (
    <div>
      <div className="flex justify-between">
        <div>{`Investment #${index + 1}`}</div>
        <Button
          variant="outlined"
          status="error"
          iconLeft={<RemoveCircleOutline />}
          onClick={() => removeItem()}
        >
          Remove Holding
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <InputField
          name={`${name}.name`}
          label="Name"
          autoComplete="off"
          helpMessage="e.g. Starwood Hotels"
        />
        <SelectField
          name={`${name}.securityTypeId`}
          label="Security Type"
          helpMessage="e.g. Equity"
          options={options.securityTypes}
        />
        <DatePickerField
          name={`${name}.investmentDate`}
          label="Investment Date"
        />
        <SelectField
          name={`${name}.investmentStatusId`}
          label="Status"
          options={options.investmentStatuses}
        />
      </div>
    </div>
  )
}
