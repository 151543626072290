import { useContext } from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from 'antd'
import moment from 'moment'
import FormCtx from '~/utils/contexts/FormCtx'
import useFormAnswers from '~/utils/hooks/useFormAnswers'
import '../Fields.less'

const DateField = ({ question }) => {
  const { isEdit } = useContext(FormCtx)
  const { answer, handleTextAnswerChange } = useFormAnswers(question)
  const onChange = (date) => {
    handleTextAnswerChange(date)
  }

  const convertToString =
    question.attributes.convertToUtc === 'true'
      ? (dateString) => moment(dateString).utc().toISOString().replace('Z', '')
      : (dateString) => dateString

  const convertFromString =
    question.attributes.convertToUtc === 'true'
      ? (dateString) => moment.utc(dateString).local()
      : (dateString) => moment(dateString)

  const date =
    answer.responseText.length === 0
      ? {}
      : { value: convertFromString(answer.responseText) }

  const disable =
    question.attributes.canEdit === 'false' && isEdit
      ? {
          disabled: true,
        }
      : {
          disabled: false,
        }

  return (
    <>
      {question.text && <div className="Text">{question.text}</div>}
      <DatePicker
        className="DateField"
        name={question.name}
        {...disable}
        {...date}
        onChange={(_, dateString) => {
          onChange(convertToString(dateString))
        }}
      />
    </>
  )
}

DateField.propTypes = {
  question: PropTypes.object,
}

export default DateField
