import * as React from 'react'
import PropTypes from 'prop-types'
import { useFormState } from 'react-hook-form'
import { Prompt } from 'react-router-dom'

export function UnsavedChangesAlert({
  message = 'Are you sure you want to leave without saving?',
  hasUnsavedChanges,
}) {
  const formState = useFormState()
  const shouldBlock = hasUnsavedChanges ?? formState?.isDirty ?? false

  React.useEffect(() => {
    if (!shouldBlock) {
      return undefined
    }

    const beforeUnload = (e) => {
      preventUnload(e, message)
    }

    window.addEventListener('beforeunload', beforeUnload)

    return () => {
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [shouldBlock, message])

  return <Prompt when={shouldBlock} message={message} />
}

UnsavedChangesAlert.propTypes = {
  message: PropTypes.string,
  hasUnsavedChanges: PropTypes.bool,
}

function preventUnload(event, message) {
  event.preventDefault()
  event.returnValue = ''
  return message
}
