import PropTypes from 'prop-types'
import SystemMessage from './SystemMessage'
import {
  ReceivedAttachment,
  ReceivedMessage,
  SentAttachment,
  SentMessage,
} from './UserMessage'

export function UserMessage({
  messageID,
  timestamp,
  pending,
  userIsAuthor,
  authorContact,
  isAttachment,
  body,
  readStatus,
  onDownloadAttachment,
}) {
  if (userIsAuthor) {
    if (isAttachment) {
      return (
        <SentAttachment
          messageID={messageID}
          timestamp={timestamp}
          pending={pending}
          fileName={body}
          readStatus={readStatus}
          onDownload={onDownloadAttachment}
        />
      )
    } else {
      return (
        <SentMessage
          messageID={messageID}
          timestamp={timestamp}
          pending={pending}
          message={body}
          readStatus={readStatus}
        />
      )
    }
  } else if (isAttachment) {
    return (
      <ReceivedAttachment
        messageID={messageID}
        timestamp={timestamp}
        authorContact={authorContact}
        fileName={body}
        onDownload={onDownloadAttachment}
      />
    )
  } else {
    return (
      <ReceivedMessage
        messageID={messageID}
        timestamp={timestamp}
        authorContact={authorContact}
        message={body}
      />
    )
  }
}

UserMessage.propTypes = {
  messageID: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  pending: PropTypes.bool,
  authorContact: PropTypes.object,
  isAttachment: PropTypes.bool,
  body: PropTypes.string.isRequired,
  readStatus: PropTypes.oneOf(['read', 'unread']),
  onDownloadAttachment: PropTypes.func.isRequired,
}

export function ConversationSystemMessage({ timestamp, metadata }) {
  return metadata.hasLeft ? (
    <SystemMessage.ParticipantLeft
      messageDateTime={timestamp}
      contactName={metadata.actorContactName}
    />
  ) : (
    <SystemMessage.ParticipantsAdded
      messageDateTime={timestamp}
      contactName={metadata.actorContactName}
      inviteeNames={metadata.receiverContactNames}
    />
  )
}

ConversationSystemMessage.propTypes = {
  timestamp: PropTypes.string.isRequired,
  metadata: PropTypes.shape({
    hasLeft: PropTypes.bool.isRequired,
    actorContactName: PropTypes.string.isRequired,
    receiverContactNames: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}

export function MeetingSystemMessage({
  timestamp,
  metadata,
  conversationType,
}) {
  if (metadata.hasRequested) {
    return (
      <SystemMessage.MeetingRequested
        messageDateTime={timestamp}
        contactName={metadata.actorContactName}
        companyName={metadata.actorCompanyName}
        meetingType={conversationType}
      />
    )
  } else if (metadata.hasAccepted) {
    return (
      <SystemMessage.MeetingAccepted
        messageDateTime={timestamp}
        contactName={metadata.actorContactName}
        companyName={metadata.actorCompanyName}
        meetingDate={metadata.scheduledDateTime}
      />
    )
  } else if (metadata.hasDeclined) {
    return (
      <SystemMessage.MeetingDeclined
        messageDateTime={timestamp}
        contactName={metadata.actorContactName}
      />
    )
  } else if (metadata.hasScheduled) {
    return (
      <SystemMessage.MeetingScheduled
        messageDateTime={timestamp}
        contactName={metadata.actorContactName}
        companyName={metadata.actorCompanyName}
        meetingDate={metadata.scheduledDateTime}
      />
    )
  } else if (metadata.hasRescheduled) {
    return (
      <SystemMessage.MeetingRescheduled
        messageDateTime={timestamp}
        contactName={metadata.actorContactName}
        companyName={metadata.actorCompanyName}
        meetingDate={metadata.scheduledDateTime}
      />
    )
  } else if (metadata.hasCancelled) {
    return (
      <SystemMessage.MeetingCancelled
        messageDateTime={timestamp}
        contactName={metadata.actorContactName}
        companyName={metadata.actorCompanyName}
        cancellationReason={metadata.cancellationReason}
      />
    )
  } else {
    return null
  }
}

MeetingSystemMessage.propTypes = {
  timestamp: PropTypes.string.isRequired,
  metadata: PropTypes.shape({
    hasRequested: PropTypes.bool.isRequired,
    hasDeclined: PropTypes.bool.isRequired,
    hasAccepted: PropTypes.bool.isRequired,
    hasCancelled: PropTypes.bool.isRequired,
    hasScheduled: PropTypes.bool.isRequired,
    hasRescheduled: PropTypes.bool.isRequired,
    actorContactName: PropTypes.string.isRequired,
    actorCompanyName: PropTypes.string,
    scheduledDateTime: PropTypes.string,
    cancellationReason: PropTypes.string,
  }).isRequired,
  conversationType: PropTypes.string,
}
