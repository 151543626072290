import * as api from '~/api'
import { useFundPerformanceQuery } from '~/lib/fundPerformanceQuery'

export function useFundPerformanceData(
  fundId,
  indexFundId,
  { includeStats = false } = {}
) {
  const queryOptions = {
    select: (res) => res.data,
  }
  const fundStatsQuery = useFundPerformanceQuery(
    ['fundStats', fundId],
    () => api.fundCharts.fetchFundStats(fundId),
    { ...queryOptions, enabled: includeStats }
  )
  const fundMonthlyReturnsQuery = useFundPerformanceQuery(
    ['fundMonthlyReturns', fundId],
    () => api.fundCharts.fetchFundMonthlyReturns(fundId),
    queryOptions
  )
  const fundYTDReturnsQuery = useFundPerformanceQuery(
    ['fundYTDReturns', fundId],
    () => api.fundCharts.fetchFundYTDReturns(fundId),
    queryOptions
  )
  const fundReturnAUMQuery = useFundPerformanceQuery(
    ['fundReturnAUM', fundId],
    () => api.fundCharts.fetchFundReturnAum(fundId),
    queryOptions
  )
  const fundReturnSPXQuery = useFundPerformanceQuery(
    ['fundReturnSPX', fundId, indexFundId],
    () => api.fundCharts.fetchFundSpxReturn(fundId, indexFundId),
    queryOptions
  )
  const fundReturnDistributionQuery = useFundPerformanceQuery(
    ['fundReturnDistribution', fundId],
    () => api.fundCharts.fetchFundReturnDistribution(fundId),
    queryOptions
  )
  const fundPerformanceQuery = useFundPerformanceQuery(
    ['fundPerformance', fundId, indexFundId],
    () => api.fundCharts.fetchFundPerformance(fundId, indexFundId),
    queryOptions
  )

  const customBenchmarkQuery = useFundPerformanceQuery(
    ['customBenchmark', fundId],
    () => getCustomBenchmark(fundId)
  )
  const customBenchmark = customBenchmarkQuery.data
  const fundVamiQuery = useFundPerformanceQuery(
    ['fundVami', fundId, indexFundId, customBenchmark?.benchmarkId],
    () => getFundVamiData(fundId, indexFundId, customBenchmark?.benchmarkId),
    { enabled: customBenchmarkQuery.isSuccess }
  )
  const benchmarkStatsQuery = useFundPerformanceQuery(
    ['benchmarkStats', fundId, indexFundId, customBenchmark?.benchmarkId],
    () =>
      api.fundCharts.fetchFundBmStats(
        fundId,
        indexFundId,
        customBenchmark?.benchmarkId
      ),
    { ...queryOptions, enabled: includeStats && customBenchmarkQuery.isSuccess }
  )

  const loading = [
    fundStatsQuery,
    fundMonthlyReturnsQuery,
    fundYTDReturnsQuery,
    fundReturnAUMQuery,
    fundReturnSPXQuery,
    fundReturnDistributionQuery,
    fundPerformanceQuery,
    customBenchmarkQuery,
    fundVamiQuery,
    benchmarkStatsQuery,
  ].some((query) => query.isLoading)

  return {
    loading,
    fundStats: fundStatsQuery.data,
    fundMonthlyReturns: fundMonthlyReturnsQuery.data,
    fundYTDReturns: fundYTDReturnsQuery.data,
    fundReturnAUM: fundReturnAUMQuery.data,
    fundReturnSPX: fundReturnSPXQuery.data,
    fundReturnDistribution: fundReturnDistributionQuery.data,
    fundPerformance: fundPerformanceQuery.data,
    customBenchmark,
    fundVami: fundVamiQuery.data?.fundVami,
    fundPeriod: fundVamiQuery.data?.fundPeriod,
    benchmarkStats: benchmarkStatsQuery.data,
  }
}

async function getCustomBenchmark(fundId) {
  try {
    const { data } = await api.fundCharts.fetchCustomBenchmarkId(fundId)
    return data.result
  } catch {
    return undefined
  }
}

const defaultFundPeriod = 10

async function getFundVamiData(fundId, indexFundId, customBenchmarkId) {
  const { data } = await api.fundCharts.fetchFundVami(
    fundId,
    indexFundId,
    customBenchmarkId || ''
  )
  return {
    fundVami: data,
    fundPeriod:
      data?.label == null
        ? defaultFundPeriod
        : (data.label.length / 4).toFixed(1),
  }
}
