import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import * as api from '~/api'
import SeatsSelector from './SeatsSelector'

const { confirm } = Modal

const BoothSelectorContainer = ({
  eventId,
  visible,
  setShowEditSeatModal,
  eventName,
  eventCompanyId,
  selectedBooth,
  editingBooths,
  boothSelectionEnded,
  getEvents,
  eventCompanyBoothId,
}) => {
  const [savingBooths, setSavingBooths] = useState(false)

  const handleCancel = useCallback(() => {
    setShowEditSeatModal(false)
  }, [setShowEditSeatModal])

  const handleUpdateBooth = useCallback(
    (seat) => {
      api.booth
        .updateBooth(eventId, selectedBooth, seat?.id, eventCompanyBoothId)
        .then((response) => {
          if (response.data.result) {
            message.success('The booth was booked successfully')
            setShowEditSeatModal(false)
            getEvents()
          } else {
            message.error('There was an error while attempting to book booths')
          }
        })
        .catch(() => {
          message.error('Failed to book the booth')
        })
        .finally(() => {
          setSavingBooths(false)
        })
    },
    [
      eventCompanyBoothId,
      eventCompanyId,
      eventId,
      getEvents,
      selectedBooth,
      setShowEditSeatModal,
    ]
  )

  const handleBookBooth = useCallback(
    (seat) => {
      api.booth
        .bookBooth(eventId, seat?.id, eventCompanyId)
        .then((response) => {
          if (response.data.result) {
            message.success('The booth was booked successfully')
            setShowEditSeatModal(false)
            getEvents()
          } else {
            message.error('There was an error while attempting to book booths')
          }
        })
        .catch(() => {
          message.error('Failed to book the booth')
        })
        .finally(() => {
          setSavingBooths(false)
        })
    },
    [eventCompanyId, eventId, getEvents, setShowEditSeatModal]
  )

  const showConfirmationDialog = useCallback(
    (seat) => {
      confirm({
        title: (
          <span>{`You are choosing booth ${seat?.label}
           for  event ${eventName}`}</span>
        ),
        icon: '',
        okText: 'Yes',
        cancelText: 'No',
        okButtonProps: { loading: savingBooths },
        onOk() {
          if (
            editingBooths &&
            !isNil(selectedBooth) &&
            !isEmpty(selectedBooth)
          ) {
            return handleUpdateBooth(seat)
          } else {
            return handleBookBooth(seat)
          }
        },
      })
    },
    [
      editingBooths,
      eventName,
      handleBookBooth,
      handleUpdateBooth,
      savingBooths,
      selectedBooth,
    ]
  )

  const handleSeatSelected = useCallback(
    (seat) => {
      if (seat && seat?.selected) {
        showConfirmationDialog(seat)
      }
    },
    [showConfirmationDialog]
  )

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      width="1100px"
      destroyOnClose
      onOk={() => showConfirmationDialog()}
      footer={[
        <Button key="cancel" onClick={handleCancel} style={{ width: '86px' }}>
          Close
        </Button>,
      ]}
    >
      <SeatsSelector
        eventId={eventId}
        onSeatSelected={handleSeatSelected}
        selectedBooth={selectedBooth}
        boothSelectionEnded={boothSelectionEnded}
      />
    </Modal>
  )
}
BoothSelectorContainer.propTypes = {
  eventId: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  setShowEditSeatModal: PropTypes.func.isRequired,
  eventName: PropTypes.string.isRequired,
  eventCompanyId: PropTypes.number.isRequired,
  selectedBooth: PropTypes.string,
  editingBooths: PropTypes.bool,
  boothSelectionEnded: PropTypes.bool,
  getEvents: PropTypes.func,
  eventCompanyBoothId: PropTypes.number,
}
export default BoothSelectorContainer
