import { useEffect } from 'react'
import { Badge } from '@context365/badge'
import { useDispatch, useSelector } from 'react-redux'
import { fetchConversationCount, fetchConversations } from '~/actions/messages'

const UnreadMessages = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchConversations)
    dispatch(fetchConversationCount) //to do ervin messages
  }, [dispatch])

  const totalUnreadMessages = useSelector(
    (state) => state.messaging.totalUnreadMessages
  )

  return (
    totalUnreadMessages > 0 && (
      <Badge status="error" size="small">
        {totalUnreadMessages}
      </Badge>
    )
  )
}

export default UnreadMessages
