import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import cx from 'classnames'
import map from 'lodash/map'

const DiscoverBadges = ({ badges }) =>
  badges?.length > 0 && (
    <div className="flex flex-row space-x-3 my-1 pb-3">
      {map(badges, (badge, index) => (
        <Badge
          key={index}
          className={cx('pointer-events-none', badge.className)}
        >
          {badge.name}
        </Badge>
      ))}
    </div>
  )

DiscoverBadges.propTypes = {
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      className: PropTypes.string,
    })
  ),
}

export default DiscoverBadges
