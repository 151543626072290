import PropTypes from 'prop-types'
import { Axis, Chart, Geom, Legend, Tooltip } from 'bizcharts'
import numeral from 'numeral'

const FundPerformanceChart = ({ fundPerformance = {}, width }) => {
  const getPerformanceCols = (data) => ({
    x: {
      alias: data.xAxisLabel,
      type: 'timeCat',
      mask: 'MM/YYYY',
    },
    y: {
      alias: data.yAxisLabel,

      formatter: (val) => `${numeral(val).format('0,0[.]0')}%`,
    },
  })

  const getPerformanceData = (data) => {
    const labelText = data[0].label
    const fund = data[0].data.map((x) => ({
      x: x.x,
      y: x.y,
      label: labelText,
    }))

    const fundIndex = data[1].data.map((x) => ({
      x: x.x,
      y: x.y,
      label: 'S&P500',
    }))
    return fund.concat(fundIndex)
  }

  if (!fundPerformance.datasets) {
    return null
  }

  return (
    <div style={{ paddingTop: '40px' }}>
      <div className="ChartLabel">
        <span>{'Performance During Worst 10 Months of S&P 500'} </span>
      </div>
      <Chart
        data={getPerformanceData(fundPerformance.datasets)}
        height={400}
        width={width}
        scale={getPerformanceCols(fundPerformance)}
        forceFit
      >
        <Legend position="bottom" dy={-20} />
        <Axis name={fundPerformance.xAxisLabel} />
        <Axis name={fundPerformance.yAxisLabel} />
        <Axis name="label" />
        <Tooltip />
        <Geom
          type="interval"
          position="x*y"
          color={['label', ['#2fc5d2', '#f19f00', '#b1347d']]}
          adjust={[{ type: 'dodge', marginRatio: 1 / 32 }]}
        />
      </Chart>
    </div>
  )
}

FundPerformanceChart.propTypes = {
  fundPerformance: PropTypes.object,
  width: PropTypes.number,
}

export default FundPerformanceChart
