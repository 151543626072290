import * as React from 'react'
import PropTypes from 'prop-types'
import { Input } from '@context365/forms'
import { useController } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useFieldSchema } from '../Form'

// The "prefix" and "suffix" prop on Input collide with the "prefix" and
// "suffix" prop on NumberFormat, so we use this component to rename them.
const InputWithPrefixSuffix = React.forwardRef(
  ({ inputPrefix, inputSuffix, ...props }, ref) => (
    <Input
      {...props}
      ref={ref}
      prefix={inputPrefix}
      suffix={inputSuffix}
      skipNativeValidation
    />
  )
)

export function NumberInputField({
  name,
  label,
  type,
  prefix,
  suffix,
  ...props
}) {
  const {
    field: { ref, onChange, value, ...field },
    fieldState: { error },
  } = useController({ name, ...props })
  const rules = useFieldSchema(name, label)

  const typeOptions = getNumberFormatOptions(type, { prefix, suffix, ...props })
  return (
    <NumberFormat
      getInputRef={ref}
      customInput={InputWithPrefixSuffix}
      label={label}
      required={rules.required}
      {...props}
      {...field}
      {...typeOptions}
      onValueChange={(values) => onChange(values.value)}
      errorMessage={error?.message}
      value={value ?? ''}
      isNumericString
    />
  )
}

NumberInputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['currency']),
  prefix: PropTypes.string,
  suffix: PropTypes.string,
}

function getNumberFormatOptions(type, props) {
  switch (type) {
    case 'currency':
      return {
        thousandSeparator: true,
        inputPrefix: props.prefix ?? 'US$',
      }
    default:
      return {}
  }
}
