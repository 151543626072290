import mapValues from 'lodash/mapValues'

export default function makeWizardSteps(lookup, steps) {
  return mapValues(lookup, (title) => {
    const index = steps.findIndex((step) => step.title === title)
    if (index === -1 && process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console -- only logged in development
      console.error(`FundWizard: step "${title}" not found`)
    }
    return Math.max(index, 0)
  })
}
