export const Message = {
  fromTwilioMessage: (twilioMessage, conversation) => {
    const isAttachment = twilioMessage.media != null
    return {
      conversationID: conversation.conversationID,
      messageID: twilioMessage.sid,
      timestamp: twilioMessage.timestamp,
      authorContact: conversation.participants.find(
        (participant) => participant.contactId === +twilioMessage.author
      ),
      body: isAttachment ? twilioMessage.media.filename : twilioMessage.body,
      isSystemGenerated: false,
      isAttachment,
      mediaSid: isAttachment ? twilioMessage.media.sid : null,
    }
  },

  // For Optimistic Updates
  fromText: (text, conversationId, contact) => {
    return {
      conversationID: conversationId,
      messageID: -1,
      timestamp: now(),
      pending: true,
      authorContact: contact,
      body: text,
      isSystemGenerated: false,
      isAttachment: false,
    }
  },
  fromAttachment: (fileName, conversationId, contact) => {
    return {
      conversationID: conversationId,
      messageID: -1,
      timestamp: now(),
      pending: true,
      authorContact: contact,
      body: fileName,
      isSystemGenerated: false,
      isAttachment: true,
      mediaSid: fileName,
    }
  },
}

export const Company = {
  fromConversationDetails: (conversationDetails) => {
    return {
      companyId: conversationDetails.companyID,
      companyName: conversationDetails.companyName,
    }
  },
  fromConversationDetailsInvited: (conversationDetails) => {
    return conversationDetails.invitedCompanyID == null ||
      conversationDetails.invitedCompanyID === 0
      ? null
      : {
          companyId: conversationDetails.invitedCompanyID,
          companyName: conversationDetails.invitedCompanyName,
        }
  },
}

function now() {
  return new Date().toISOString().slice(0, -1)
}
