import { api as http } from '~/api/services'
import { FETCH_USER_ACCESS } from '~/constants/types'

export const fetchAccess = () => (dispatch) => {
  http.get('/auth/access').then((response) =>
    dispatch({
      type: FETCH_USER_ACCESS,
      payload: response.data.result,
    })
  )
}
