import { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import moment from 'moment'
import { connect } from 'react-redux'
import { getCompaniesContacts } from '~/actions/company'
import { sendOnlineMeetingRequest } from '~/actions/meetings'
import { getServiceProvidersList } from '~/actions/serviceProviders'
import ContextTable from '~/components/ContextTable'
import RequestOnlineMeeting from '~/components/RequestOnlineMeeting'
import { getServiceProviderDefaultColumns } from '~/utils/columns'

const DiscoverServiceProviders = (props) => {
  const initRecord = {
    company: {},
  }
  const initRequestCompanies = [{}, {}]
  const initRequestDateTime = {
    startDateTime: moment(),
    endDateTime: moment(),
  }

  const [selectedRecord, setSelectedRecord] = useState(initRecord)
  const [requestCompanies, setRequestCompanies] = useState(initRequestCompanies)
  const [meetingRequestModalVisible, setMeetingRequestModalVisible] =
    useState(false)
  const [detailsVisible, setDetailsVisibility] = useState(false)
  const [selectedContactList, setSelectedContactList] = useState({})
  const [selectedFundID, setSelectedFundID] = useState(null)
  const [selectedDateTime, setSelectedDateTime] = useState(initRequestDateTime)

  const openRequestModal = (record) => {
    getCompaniesContacts(record.company.companyID, props.companyId).then(
      (response) => {
        setRequestCompanies(response.data.result)
        setSelectedRecord(record)
        setMeetingRequestModalVisible(true)
      }
    )
  }

  const openDetailsModal = (record) => {
    setSelectedRecord(record)
    setDetailsVisibility(true)
  }

  const onParticipantsChanged = (mine, participants) => {
    if (mine)
      setSelectedContactList({
        participants,
        toParticipants: selectedContactList.toParticipants,
      })
    else
      setSelectedContactList({
        participants: selectedContactList.participants,
        toParticipants: participants,
      })
  }

  const onTimeChanged = (changed, newDate) => {
    const date = selectedDateTime.startDateTime
    const endDate = selectedDateTime.endDateTime

    if (changed === 'date') {
      date.set('year', newDate.year())
      date.set('month', newDate.month())
      date.set('date', newDate.date())
      endDate.set('year', newDate.year())
      endDate.set('month', newDate.month())
      endDate.set('date', newDate.date())
      setSelectedDateTime({ startDateTime: date, endDateTime: endDate })
    } else {
      newDate.startTime = moment(newDate.startTime)
      newDate.endTime = moment(newDate.endTime)

      date.set('hour', newDate.startTime.hour())
      date.set('minute', newDate.startTime.minute())
      date.set('second', newDate.startTime.second())
      endDate.set('hour', newDate.endTime.hour())
      endDate.set('minute', newDate.endTime.minute())
      endDate.set('second', newDate.endTime.second())
    }

    setSelectedDateTime({
      startDateTime: date,
      endDateTime: endDate,
    })
  }

  const columns = getServiceProviderDefaultColumns(
    openRequestModal,
    null,
    openDetailsModal
  )

  const onFundChanged = (fundID) => {
    setSelectedFundID(fundID)
  }

  const isManager = props.userRole === 'Manager'
  return (
    <>
      <Modal
        visible={detailsVisible}
        footer={null}
        onCancel={() => setDetailsVisibility(false)}
        width="60%"
      />
      <RequestOnlineMeeting
        visible={meetingRequestModalVisible}
        companyIdTo={selectedRecord.company.companyID}
        companyNameTo={selectedRecord.company.name}
        company={requestCompanies[1]}
        companyTo={requestCompanies[0]}
        fundId={selectedFundID}
        handleOk={() => {
          sendOnlineMeetingRequest(
            'serviceprovider',
            selectedRecord.company.companyID,
            message,
            selectedFundID,
            selectedDateTime.startDateTime.utc().format(),
            selectedDateTime.endDateTime.utc().format(),
            selectedContactList.participants,
            selectedContactList.toParticipants
          )
            .then(() => {
              message.success('Meeting request sent successfully')
              selectedRecord.hasMeeting = true
              selectedRecord.meetingStatus = 'Pending'
            })
            .catch(() => {
              message.error(
                'There was an error while sending the meeting request'
              )
            })
            .finally(() => {
              setMeetingRequestModalVisible(false)
            })
        }}
        handleCancel={() => setMeetingRequestModalVisible(false)}
        onTimeChanged={onTimeChanged}
        onParticipantsChanged={onParticipantsChanged.bind(null, true)}
        onToParticipantsChanged={onParticipantsChanged.bind(null, false)}
        isFundManager={isManager}
        onFundChanged={onFundChanged}
      />
      <ContextTable
        tableTitle="Discover Service Providers"
        columns={columns}
        dataMethod={getServiceProvidersList}
      />
    </>
  )
}

DiscoverServiceProviders.propTypes = {
  companyId: PropTypes.number,
  userRole: PropTypes.string,
}

const mapStateToProps = (state) => ({
  companyId: state.auth.company.companyId,
  userId: state.auth.contact.contactId,
  userRole: state.auth.role,
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiscoverServiceProviders)
