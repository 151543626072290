import * as React from 'react'
import {
  Axis,
  Chart,
  Grid,
  LineSeries,
  useAxis,
  useChart,
  useChartTooltipState,
  useLineSeries,
} from '@context365/charts'
import * as api from '~/api'
import { LineChartPlaceholder } from '~/components/ChartPlaceholder'
import { chart as chartColors } from '~/constants/colors'
import { useCompareFundsContext } from '../compareFundsContext'
import {
  BenchmarkSelect,
  ChartContainer,
  ChartControls,
} from './chartComponents'
import {
  CHART_HEIGHT,
  CHART_WIDTH,
  createDataset,
  format,
  useTimeAxis,
} from './chartHelpers'
import CompareFundsTooltip from './CompareFundsTooltip'
import createComparisonChart from './createComparisonChart'
import { useTrackComparedFunds } from './trackCompareFunds'

const chartHeight = CHART_HEIGHT * 0.75
const verticalPadding = 10

function BenchmarkRelativeChart({ chartData }) {
  const {
    benchmarks: allBenchmarks,
    funds,
    options,
    getFundColor,
  } = useCompareFundsContext()
  const benchmark = allBenchmarks.find(
    (b) => b.fundIndexId === options.primaryBenchmarkId
  )

  const chart = useChart({
    data: chartData,
    height: chartHeight * 2 + verticalPadding * 2,
    width: CHART_WIDTH,
    padding: {
      left: 35,
      right: 20,
      top: 10,
      bottom: 20,
    },
  })
  const [fundsRange, benchmarkRange] = React.useMemo(() => {
    const verticalMidpoint =
      (chart.chartBounds.top + chart.chartBounds.bottom) / 2
    return [
      [verticalMidpoint - verticalPadding, chart.chartBounds.top],
      [chart.chartBounds.bottom, verticalMidpoint + verticalPadding],
    ]
  }, [chart.chartBounds.top, chart.chartBounds.bottom])

  const xAxis = useTimeAxis(chartData, chart)
  const fundsAxis = useAxis({
    type: 'linear',
    getValue: (d) => d[1] / 100,
    formatValue: format.percentage,
    formatAxis: format.percentageShort,
    range: fundsRange,
  })
  const benchmarkAxis = useAxis({
    type: 'linear',
    getValue: (d) => d[1] / 100,
    formatValue: format.percentage,
    formatAxis: format.percentageShort,
    range: benchmarkRange,
  })

  const fundLines = useLineSeries({
    chart,
    x: xAxis,
    y: fundsAxis,
    series: Object.keys(chartData.funds).map((fundId) => ({
      key: fundId,
      ...getFundColor(fundId),
      label: funds.find((f) => f.fundId === +fundId)?.name,
      getDataset: (data) => createDataset(data.dates, data.funds[fundId]),
    })),
  })
  const benchmarkLines = useLineSeries({
    chart,
    x: xAxis,
    y: benchmarkAxis,
    series: {
      key: benchmark?.fundIndexId,
      color: chartColors.sAndP,
      label: benchmark?.name,
      getDataset: (data) =>
        createDataset(data.dates, data.bm[benchmark?.fundIndexId]),
    },
  })

  const tooltip = useChartTooltipState(chart, fundLines)
  const benchmarkDataPoint = benchmarkLines.series[0]._dataPoints.find(
    (d) => xAxis.format(d) === tooltip.title
  )
  const activeDataPoints = benchmarkDataPoint
    ? [
        ...tooltip.activeDataPoints,
        {
          key: benchmark.fundIndexId,
          color: chartColors.sAndP,
          label: benchmark.name,
          value: benchmarkAxis.format(benchmarkDataPoint),
          dataPoint: benchmarkDataPoint,
          series: {
            key: benchmark.fundIndexId,
            label: benchmark.name,
            getSeriesProps: benchmarkLines.series[0].getSeriesProps,
            _dataPoints: benchmarkLines.series[0]._dataPoints,
          },
        },
      ]
    : tooltip.activeDataPoints

  return (
    <ChartContainer>
      <Chart {...chart.getChartProps()} className="text-grey-500">
        <Axis {...fundLines.getAxisProps('bottom')} />
        <Axis {...fundLines.getAxisProps('left')} />
        <Axis {...benchmarkLines.getAxisProps('left')} />
        <Grid {...fundLines.getGridProps('horizontal')} tickValues={[0]} />
        <Grid {...benchmarkLines.getGridProps('horizontal')} tickValues={[0]} />
        <LineSeries series={fundLines.series} />
        <LineSeries series={benchmarkLines.series} />
      </Chart>
      <CompareFundsTooltip
        {...tooltip}
        activeDataPoints={activeDataPoints}
        getRowClass={(key) =>
          key === benchmark.fundIndexId ? 'pt-4' : undefined
        }
      />
    </ChartContainer>
  )
}

function BenchmarkRelativeChartControls() {
  const { options, setOption, benchmarks } = useCompareFundsContext()
  const trackClick = useTrackComparedFunds('primaryBenchmarkId')

  function changeOption(name, key, value) {
    const update = { [key]: value }
    trackClick(name, update)
    setOption(update)
  }

  return (
    <ChartControls>
      <BenchmarkSelect
        benchmarks={benchmarks}
        id="primary-benchmark"
        label="Benchmark"
        indicatorColor={chartColors.sAndP}
        selectedId={options.primaryBenchmarkId}
        onChange={(id) =>
          changeOption('PrimaryBenchmark', 'primaryBenchmarkId', id)
        }
      />
    </ChartControls>
  )
}

export default createComparisonChart({
  queryFn: (fundIds, options) =>
    api.fundCharts.benchmarkRelativeChart(fundIds, options.primaryBenchmarkId),
  chartControls: BenchmarkRelativeChartControls,
  loading: (
    <div className="w-max mx-auto">
      <LineChartPlaceholder
        animate
        aria-label="loading chart data"
        height={chartHeight}
        width={CHART_WIDTH}
      />
      <div style={{ height: verticalPadding }} />
      <LineChartPlaceholder
        animate
        aria-label="loading chart data"
        height={chartHeight}
        width={CHART_WIDTH}
      />
    </div>
  ),
  error: ({ error, primaryBenchmark }) => (
    <div className="w-max mx-auto relative">
      <LineChartPlaceholder
        aria-label="data not available"
        height={chartHeight}
        width={CHART_WIDTH}
      />
      <div className="flex flex-col absolute inset-0 items-center justify-center">
        <div className="type-body-regular-lg mb-2">
          Unable to load chart data.
        </div>
        <div className="type-body-regular-md">
          {primaryBenchmark == null
            ? 'Please select a benchmark or choose a different chart type.'
            : 'Please choose a different benchmark or different funds to compare.'}
        </div>
        <div className="type-body-regular-xs">
          {error.response.data.message}
        </div>
      </div>
    </div>
  ),
  success: BenchmarkRelativeChart,
})
