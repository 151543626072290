import PropTypes from 'prop-types'
import { Alert } from 'antd'
import isEmpty from 'lodash/isEmpty'

const StaticText = ({ labelText, label, alertType = '' }) => {
  if (!isEmpty(alertType)) {
    const message = (
      <span className="cc-heading5 cc-lightblack-text">
        {alertType === 'warning' ? 'Important' : labelText}
      </span>
    )
    const description =
      alertType === 'warning' ? (
        <div
          style={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}
        >
          <span className="cc-body-text cc-lightblack-text">{labelText}</span>
          <span
            className="cc-body-text cc-lightblack-text"
            style={{ fontWeight: 'bold' }}
          >
            {label}
          </span>
        </div>
      ) : (
        label
      )
    return (
      <Alert
        style={{ border: 'none' }}
        type={alertType}
        message={message}
        description={description}
        showIcon
      />
    )
  } else {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span className="cc-heading5 cc-lightblack-text">{labelText}</span>
        <span
          style={{ padding: '16px 0px' }}
          className="cc-body-text cc-text-color-summit"
        >
          {label}
        </span>
      </div>
    )
  }
}

StaticText.propTypes = {
  label: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  alertType: PropTypes.string,
}

export default StaticText
