import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Input, Modal, Radio, message } from 'antd'
import filter from 'lodash/filter'
import map from 'lodash/map'
import trim from 'lodash/trim'
import { useDispatch, useSelector } from 'react-redux'
import { cancelOnlineMeetingForAll } from '~/actions/contextMeetings'
import { fetchCancellingReasonsIfNeeded } from '~/actions/meetings'
import { selectOnlineCancellationReasons } from '~/selectors/meetings'
import Loading from '../Loading'

const RadioGroup = Radio.Group
const { TextArea } = Input

const CancelOnlineMeeting = ({
  visible,
  closeModal,
  company,
  meetingId,
  meetingStatus = null,
  onCancel,
}) => {
  const [cancellingInProgress, setCancellingInProgress] = useState(false)
  const [selectedReasonId, setSelectedReasonId] = useState(0)
  const [otherReason, setOtherReason] = useState(false)
  const [otherReasonText, setOtherReasonText] = useState('')
  const dispatch = useDispatch()

  const reasonRequired = meetingStatus?.toLowerCase() === 'confirmed'

  const cancellationReasons = useSelector(selectOnlineCancellationReasons)

  useEffect(() => {
    dispatch(fetchCancellingReasonsIfNeeded())
  }, [dispatch])

  const handleModalCancel = useCallback(() => {
    setSelectedReasonId(0)
    setOtherReason(false)
    setOtherReasonText('')
    closeModal()
  }, [closeModal])

  const cancelMeetingReq = () => {
    setCancellingInProgress(true)
    cancelOnlineMeetingForAll(meetingId, selectedReasonId, otherReasonText)
      .then(() => {
        message.success('The meeting has been cancelled')
        handleModalCancel()
        onCancel()
      })
      .finally(() => {
        setCancellingInProgress(false)
      })
  }

  const submitDisabled =
    reasonRequired &&
    cancellationReasons.length > 0 &&
    (selectedReasonId === 0 || (otherReason && trim(otherReasonText) === ''))

  return (
    <Modal
      className="cc-modal-accept"
      title="Cancel Meeting"
      visible={visible}
      okText="Cancel Meeting"
      cancelText="Close"
      onOk={cancelMeetingReq}
      onCancel={handleModalCancel}
      okButtonProps={{
        loading: cancellingInProgress,
        disabled: submitDisabled,
        style: {
          backgroundColor: submitDisabled ? 'default' : '#D62B34',
          borderStyle: 'none',
        },
      }}
      cancelButtonProps={{ disabled: false }}
    >
      <Loading spinning={cancellingInProgress}>
        {cancellationReasons.length > 0 && reasonRequired ? (
          <div>
            <p className="cc-body-text cc-lightblack-text">
              Please select the reason you want to cancel the meeting
            </p>
            <RadioGroup
              style={{ width: '100%' }}
              value={selectedReasonId}
              onChange={(e) => {
                setSelectedReasonId(e.target.value)
                const reason = filter(
                  cancellationReasons,
                  (r) => r.cancellationReasonId == e.target.value
                )
                setOtherReason(reason[0].requiresInput)
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '16px',
                }}
              >
                {map(cancellationReasons, (c) => (
                  <span
                    style={{ marginBottom: '16px' }}
                    className="cancellingReasons"
                  >
                    <Radio
                      key={c.cancellationReasonId.toString()}
                      value={c.cancellationReasonId}
                    >
                      {c.description}
                    </Radio>
                  </span>
                ))}
                {otherReason && (
                  <TextArea
                    placeholder="Please type another reason"
                    rows={4}
                    value={otherReasonText}
                    onChange={({ target: { value } }) => {
                      setOtherReasonText(value)
                    }}
                  />
                )}
              </div>
            </RadioGroup>
          </div>
        ) : (
          <p>
            Are you sure you want to cancel the online meeting with {company}
          </p>
        )}
      </Loading>
    </Modal>
  )
}

CancelOnlineMeeting.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  company: PropTypes.string.isRequired,
  meetingId: PropTypes.number.isRequired,
  meetingStatus: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
}

export default CancelOnlineMeeting
