import trim from 'lodash/trim'
import * as Yup from 'yup'
import { validURL } from '~/utils/helpers'

export const BasicInfoValidator = Yup.object().shape({
  fundName: Yup.string().required('Required').typeError('Required'),
  strategyDescription: Yup.string()
    .required('Required')
    .typeError('Required')
    .test(
      'len',
      ({ value }) =>
        `Must be at least 280 characters. Character Count: ${
          value?.length ?? 0
        }`,
      (val) => trim(val).length >= 280
    ),
  attributeIds: Yup.array(Yup.number()),
  investmentVehicleIds: Yup.array(Yup.number())
    .required('Required')
    .min(1, 'Please select at least one'),
})

export const ManagerDetailsValidator = Yup.object().shape({
  managerBiography: Yup.string()
    .required('Required')
    .typeError('Required')
    .test(
      'len',
      ({ value }) =>
        `Must be at least 280 characters. Character Count: ${
          value?.length ?? 0
        }`,
      (val) => trim(val).length >= 280
    ),
  managerCountryId: Yup.number().required('Required').typeError('Required'),
})

export const FundDetailsValidator = Yup.object().shape({
  fundAum: Yup.number()
    .required('Required')
    .typeError('Required')
    .min(0, 'Must be a positive number')
    .test(
      'zeroOrGreaterThan1Mil',
      'Enter the unabbreviated number. Must be 0 or greater than $1,000,000.',
      (val, testContext) =>
        testContext.parent.isOpenEnded || val === 0 || val >= 1_000_000
    ),
  inceptionDate: Yup.date().required('Required').typeError('Required'),
  proprietaryAssetsPercentageOfFundAum: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number')
    .max(100, 'Cannot be greater than 100%'),
  largestNotionalPosition: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number')
    .max(100, 'Cannot be greater than 100%'),
  managementFeePercentage: Yup.number()
    .required()
    .min(0, 'Must be a positive number')
    .max(100, 'Cannot be greater than 100%'),
  performanceFeePercentage: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number')
    .max(100, 'Cannot be greater than 100%'),
  minimumInvestment: Yup.number().required('Required').typeError('Required'),
  daysOfCapitalLockup: Yup.number().required('Required').typeError('Required'),
  isFirstLossCapitalAccepted: Yup.boolean()
    .required('Required')
    .typeError('Required'),
})

export const FundReturnsValidator = Yup.object().shape({
  narrative: Yup.boolean().required('Required').typeError('Required'),
  quantitativeReturns: Yup.object().nullable(),
})

export const MarketingMaterialValidator = Yup.object().shape({
  marketingMaterialUrls: Yup.array(Yup.string()).nullable(),
  distributorEmail: Yup.string().required('Required').typeError('Required'),
})

export const MediaValidator = Yup.object().shape({
  media: Yup.array(Yup.string()).nullable(),
  links: Yup.array(
    Yup.object().shape({
      title: Yup.string().required('Required').typeError('Required'),
      url: Yup.string()
        .required('Required')
        .typeError('Required')
        .test('validUrl', 'Please enter a valid url', (val) => validURL(val)),
    })
  ).nullable(),
})

export const ServiceProviderValidator = Yup.object().shape({
  primeBrokerIds: Yup.array(Yup.number()).nullable(),
  fundAdministratorIds: Yup.array(Yup.number()).nullable(),
  auditorIds: Yup.array(Yup.number()).nullable(),
  legalCounselIds: Yup.array(Yup.number()).nullable(),
  custodianIds: Yup.array(Yup.number()).nullable(),
  marketerIds: Yup.array(Yup.number()).nullable(),
})

export const LiquidFundQuestionsValidator = Yup.object().shape({
  expectedTargetNetAnnualReturn: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number')
    .max(100, 'Cannot be greater than 100%'),
  expectedTargetStandardDeviationOfReturns: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number')
    .max(100, 'Cannot be greater than 100%'),
  broadStrategyId: Yup.number().required('Required').typeError('Required'),
  subStrategyId: Yup.number().nullable(),
  investmentDecisionProcessId: Yup.number()
    .required('Required')
    .typeError('Required'),
  netDollarExposureId: Yup.number().required('Required').typeError('Required'),
  primaryInstrumentIds: Yup.array(Yup.number())
    .required('Required')
    .typeError('Required'),
})

export const ConcentrationValidator = Yup.object().shape({
  geographyIds: Yup.array(Yup.number())
    .nullable()
    .max(2, 'Cannot select more than 2 geographies'),
  industryIds: Yup.array(Yup.number())
    .nullable()
    .max(2, 'Cannot select more than 2 sectors'),
  isPortfolioGeographicallyDiverse: Yup.boolean().nullable(),
  isPortfolioSectorDiverse: Yup.boolean().nullable(),
})
