import trim from 'lodash/trim'
import * as Yup from 'yup'
import { validURL } from '~/utils/helpers'

export const BasicInfoValidator = Yup.object().shape({
  fundName: Yup.string().required('Required').typeError('Required'),
  strategyDescription: Yup.string()
    .required('Required')
    .typeError('Required')
    .test(
      'len',
      ({ value }) =>
        `Must be at least 280 characters. Character Count: ${
          value?.length ?? 0
        }`,
      (val) => trim(val).length >= 280
    ),
  broadStrategyId: Yup.number().required('Required').typeError('Required'),
  subStrategyId: Yup.number().nullable(),
  inceptionDate: Yup.date()
    .required('Required')
    .typeError('Please enter a valid date'),
  vintageInceptionDate: Yup.date()
    .nullable()
    .typeError('Please enter a valid date'),
  targetCloseDate: Yup.date().nullable().typeError('Please enter a valid date'),
  fundAum: Yup.number()
    .required('Required')
    .typeError('Required')
    .min(0, 'Must be a positive number')
    .test(
      'zeroOrGreaterThan1Mil',
      'Enter the unabbreviated number. Must be 0 or greater than $1,000,000.',
      (val, testContext) =>
        testContext.parent.isOpenEnded || val === 0 || val >= 1_000_000
    ),
  targetFundSize: Yup.number()
    .required('Required')
    .typeError('Required')
    .min(0, 'Must be a positive number'),
  fundTerm: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number')
    .test(
      'greaterThanInvestmentPeriod',
      'Fund Term must be greater than or equal to the investment period',
      (val, testContext) =>
        testContext.parent.isOpenEnded ||
        val >= testContext.parent.investmentPeriod
    ),
  investmentPeriod: Yup.number()
    .required('Required')
    .typeError('Required')
    .min(0, 'Must be a positive number')
    .test(
      'lessThanFundTerm',
      'Investment Period must be less than or equal to the fund term',
      (val, testContext) =>
        testContext.parent.isOpenEnded || val <= testContext.parent.fundTerm
    ),
  isOpenEnded: Yup.boolean().required('Required').typeError('Required'),
  domicileIds: Yup.array(Yup.number())
    .required('Required')
    .min(1, 'Please select at least one'),
  domicileCountryIds: Yup.array(Yup.number())
    .required('Required')
    .min(1, 'Please select at least one'),
  attributeIds: Yup.array(Yup.number()),
})

export const FirmInfoValidator = Yup.object().shape({
  partners: Yup.array(
    Yup.object().shape({
      name: Yup.string().required('Required').typeError('Required'),
      countryId: Yup.number().required('Required').typeError('Required'),
      stateProvince: Yup.string().nullable(),
      city: Yup.string().nullable(),
      biography: Yup.string()
        .required('Required')
        .typeError('Required')
        .test(
          'len',
          ({ value }) =>
            `Must be at least 280 characters. Character Count: ${
              value?.length ?? 0
            }`,
          (val) => trim(val).length >= 280
        ),
      isGeneralPartner: Yup.boolean().required(),
    })
  ).min(1, 'You must enter at least one general partner'),
  companyDescription: Yup.string()
    .required('Required')
    .typeError('Required')
    .test(
      'len',
      ({ value }) =>
        `Must be at least 280 characters. Character Count: ${
          value?.length ?? 0
        }`,
      (val) => trim(val).length >= 280
    ),
  firmAUM: Yup.number()
    .required('Required')
    .typeError('Required')
    .min(0, 'Must be a positive number')
    .test(
      'zeroOrMillions',
      'Enter the unabbreviated number. Must be 0 or greater than $1,000,000.',
      (val) => val === 0 || val >= 1_000_000
    ),
  activeFunds: Yup.number()
    .required('Required')
    .typeError('Required')
    .min(0, 'Must be a positive number'),
  activePortfolioCompanies: Yup.number()
    .required('Required')
    .typeError('Required')
    .min(0, 'Must be a positive number'),
  nthFundByFirm: Yup.number()
    .required('Required')
    .typeError('Required')
    .min(1, 'Must be greater than zero'),
  industryIds: Yup.array().max(2, 'Cannot select more than 2 industries'),
  geographyIds: Yup.array().max(2, 'Cannot select more than 2 geographies'),
  verticalIds: Yup.array(),
})

export const ValuationValidator = Yup.object().shape({
  expectedTargetedInternalRateOfReturn: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  netInternalRateOfReturn: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  grossInternalRateOfReturn: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  netTotalValuePaidInCapital: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  grossTotalValuePaidInCapital: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  residualValueToPaidIn: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  distributionsPaidInCapital: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  currentNetAssetValue: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
})

export const CommitmentValidator = Yup.object().shape({
  totalCommitments: Yup.number().nullable().min(0, 'Must be a positive number'),
  remainingCommitments: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  totalDrawdownsSinceInception: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  commitedCapitalPercentage: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number')
    .max(100, 'Cannot be greater than 100%'),
  totalDrawdownPercentage: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number')
    .max(100, 'Cannot be greater than 100%'),
})

export const TermsValidator = Yup.object().shape({
  generalPartnerCommitedRatio: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  minimumInvestment: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  managementFeePercentage: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number')
    .max(100, 'Cannot be greater than 100%'),
  performanceFeePercentage: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number')
    .max(100, 'Cannot be greater than 100%'),
  earlyRedemptionFeePercentage: Yup.number()
    .nullable()
    .min(-100, 'Must be greater than -100%')
    .max(100, 'Cannot be greater than 100%'),
  preferredReturnPercentage: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number')
    .max(100, 'Cannot be greater than 100%'),
  hurdleRatePercentage: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number')
    .max(100, 'Cannot be greater than 100%'),
  catchUpPercentage: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number')
    .max(100, 'Cannot be greater than 100%'),
  highWaterMark: Yup.number().nullable().min(0, 'Must be a positive number'),
  hasClawbackProvision: Yup.boolean().nullable(),
  daysOfCapitalLockup: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  postLockupLiquidity: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  daysNoticeForRedemption: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  annualWithdrawalPercentageWithNoRedemptionFee: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number')
    .max(100, 'Cannot be greater than 100%'),
  limitedPartnerCommunicationFrequencyId: Yup.number().nullable(),
  benchmarkIndexId: Yup.number().nullable(),
})

export const ServiceProviderValidator = Yup.object().shape({
  primeBrokerIds: Yup.array(Yup.number()).nullable(),
  fundAdministratorIds: Yup.array(Yup.number()).nullable(),
  auditorIds: Yup.array(Yup.number()).nullable(),
  legalCounselIds: Yup.array(Yup.number()).nullable(),
  investmentAdvisorIds: Yup.array(Yup.number()).nullable(),
  informationTechnologyProviderIds: Yup.array(Yup.number()).nullable(),
  custodianIds: Yup.array(Yup.number()).nullable(),
  marketerIds: Yup.array(Yup.number()).nullable(),
})

export const PositionValidator = Yup.object().shape({
  totalInvestmentsSinceInception: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  targetInvestmentHorizonYears: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  totalPublicPositions: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  totalPrivatePositions: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  averagePositionSize: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  marketCapitalization: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
  leverageUtilization: Yup.number()
    .nullable()
    .min(0, 'Must be a positive number'),
})

export const MediaValidator = Yup.object().shape({
  media: Yup.array(Yup.string()).nullable(),
  links: Yup.array(
    Yup.object().shape({
      title: Yup.string().required('Required').typeError('Required'),
      url: Yup.string()
        .required('Required')
        .typeError('Required')
        .test('validUrl', 'Please enter a valid url', (val) => validURL(val)),
    })
  ).nullable(),
})

export const PortfolioValidator = Yup.object().shape({
  portfolioCompanies: Yup.array(
    Yup.object().shape({
      companyName: Yup.string().required('Required').typeError('Required'),
      investmentDate: Yup.date().required('Required').typeError('Required'),
      securityTypeId: Yup.number().required('Required').typeError('Required'),
      materials: Yup.array(Yup.string()).nullable(),
      securityInternalRateOfReturnPercentage: Yup.number().nullable(),
    })
  ),
  marketingMaterialUrls: Yup.array(Yup.string()).nullable(),
})
