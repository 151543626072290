import orderBy from 'lodash/orderBy'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom-v5-compat'
import * as api from '~/api'
import Loading from '~/components/Loading'
import { formatValuationDate } from '../utils'
import AllocationYear from './AllocationYear'

export default function Allocations() {
  const { companyId } = useParams()
  const { data, isLoading } = useQuery(
    ['allocator-allocations', companyId],
    () => api.allocators.getAllocatorAllocations(companyId)
  )

  if (isLoading) {
    return (
      <div className="text-center my-16">
        <Loading />
      </div>
    )
  }

  return (
    <div>
      <h2 className="type-header-sm text-header mb-4">
        Alternative Asset Allocations: Actual vs Target
      </h2>
      <div className="type-body-regular-sm mb-8">
        Latest Valuation date: {formatValuationDate(data.latestValuationDate)}
      </div>
      <div className="space-y-4">
        {orderBy(data.allocations, 'year', 'desc').map((allocation) => (
          <AllocationYear key={allocation.year} {...allocation} />
        ))}
      </div>
    </div>
  )
}
