import { Apartment, PersonOutline } from '@context365/icons'
import { Divider, message } from 'antd'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import * as api from '~/api'
import CheckBoxField from '~/components/GenericForm/Fields/CheckBoxField'
import HeaderField from '~/components/GenericForm/Fields/HeaderField'
import RadioField from '~/components/GenericForm/Fields/RadioField'
import GenericForm from '~/components/GenericForm/GenericForm'
import CompanyCategories from '~/constants/companyCategories'
import { ROLE } from '~/constants/roles'

const validator = () =>
  Yup.object().shape({
    showCompanyInDirectory: Yup.bool(),
    companyAllowViewProfileCategoryIds: Yup.array(Yup.number()),
    companyAllowViewFundsCategoryIds: Yup.array(Yup.number()),
    companyAllowViewMandatesCategoryIds: Yup.array(Yup.number()),
    showContactInDirectory: Yup.bool(),
    contactAllowViewProfileCategoryIds: Yup.array(Yup.number()),
    contactAllowViewFundsCategoryIds: Yup.array(Yup.number()),
    contactAllowViewMandatesCategoryIds: Yup.array(Yup.number()),
    allowConnectionRequestsCategoryIds: Yup.array(Yup.number()),
  })

const steps = (role) => [
  {
    title: 'Company Profile',
    validator: validator(),
    questions: () => [
      {
        questions: [
          {
            component: () => (
              <div className="flex gap-2">
                <Apartment />
                <HeaderField title="Company Settings" />
              </div>
            ),
          },
        ],
      },
      {
        questions: [
          {
            name: 'companyAllowViewProfileCategoryIds',
            title: 'Who can view your profile?',
            component: CheckBoxField,
            labelClassName: 'type-body-semibold-md',
            isFormField: true,
            extraProps: {
              options: [
                { label: 'Managers', value: CompanyCategories.Manager },
                { label: 'Allocators', value: CompanyCategories.Allocator },
                {
                  label: 'Service Providers',
                  value: CompanyCategories.ServiceProvider,
                },
              ],
              mappingMethod: (x) => x,
            },
          },
        ],
      },
      /*{
        questions: [
          {
            name: 'companyAllowViewFundsCategoryIds',
            title: 'Who can view your funds?',
            component: CheckBoxField,
            labelClassName: 'type-body-semibold-medium',
            isVisible: () => role === ROLE.MANAGER,
            isFormField: true,
            extraProps: {
              options: [
                { label: 'Managers', value: CompanyCategories.Manager },
                { label: 'Allocators', value: CompanyCategories.Allocator },
                {
                  label: 'Service Providers',
                  value: CompanyCategories.ServiceProvider,
                },
              ],
              mappingMethod: (x) => x,
            },
          },
        ],
      },*/
      /*{
        questions: [
          {
            name: 'companyAllowViewMandatesCategoryIds',
            title: 'Who can view your mandates?',
            component: CheckBoxField,
            labelClassName: 'type-body-semibold-medium',
            isVisible: () => role === ROLE.ALLOCATOR,
            isFormField: true,
            extraProps: {
              options: [
                { label: 'Managers', value: CompanyCategories.Manager },
                { label: 'Allocators', value: CompanyCategories.Allocator },
                {
                  label: 'Service Providers',
                  value: CompanyCategories.ServiceProvider,
                },
              ],
              mappingMethod: (x) => x,
            },
          },
        ],
      },*/
      {
        questions: [
          {
            name: 'showCompanyInDirectory',
            title: 'Hide company profile from the directory?',
            isFormField: true,
            component: RadioField,
            labelClassName: 'type-body-semibold-md',
            extraProps: {
              options: [
                { label: 'Yes', value: false },
                { label: 'No', value: true },
              ],
              mappingMethod: (x) => x,
            },
          },
        ],
      },
      { questions: [{ component: Divider }] },
      {
        questions: [
          {
            component: () => (
              <div className="flex gap-2">
                <PersonOutline />
                <HeaderField title="User Settings" />
              </div>
            ),
          },
        ],
      },
      {
        questions: [
          {
            name: 'contactAllowViewProfileCategoryIds',
            title: 'Who can view your profile?',
            component: CheckBoxField,
            labelClassName: 'type-body-semibold-md',
            isFormField: true,
            extraProps: {
              options: [
                { label: 'Managers', value: CompanyCategories.Manager },
                { label: 'Allocators', value: CompanyCategories.Allocator },
                {
                  label: 'Service Providers',
                  value: CompanyCategories.ServiceProvider,
                },
              ],
              mappingMethod: (x) => x,
            },
          },
        ],
      },
      /*{
        questions: [
          {
            name: 'contactAllowViewFundsCategoryIds',
            title: 'Who can view your funds?',
            component: CheckBoxField,
            labelClassName: 'type-body-semibold-medium',
            isFormField: true,
            extraProps: {
              options: [
                { label: 'Managers', value: CompanyCategories.Manager },
                { label: 'Allocators', value: CompanyCategories.Allocator },
                {
                  label: 'Service Providers',
                  value: CompanyCategories.ServiceProvider,
                },
              ],
              mappingMethod: (x) => x,
            },
          },
        ],
      },*/
      /*{
        questions: [
          {
            name: 'contactAllowViewMandatesCategoryIds',
            title: 'Who can view your mandates?',
            component: CheckBoxField,
            labelClassName: 'type-body-semibold-medium',
            isFormField: true,
            extraProps: {
              options: [
                { label: 'Managers', value: CompanyCategories.Manager },
                { label: 'Allocators', value: CompanyCategories.Allocator },
                {
                  label: 'Service Providers',
                  value: CompanyCategories.ServiceProvider,
                },
              ],
              mappingMethod: (x) => x,
            },
          },
        ],
      },*/
      {
        questions: [
          {
            name: 'allowConnectionRequestsCategoryIds',
            title: 'Who can send you connection requests?',
            component: CheckBoxField,
            labelClassName: 'type-body-semibold-md',
            isFormField: true,
            extraProps: {
              options: [
                { label: 'Managers', value: CompanyCategories.Manager },
                { label: 'Allocators', value: CompanyCategories.Allocator },
                {
                  label: 'Service Providers',
                  value: CompanyCategories.ServiceProvider,
                },
              ],
              mappingMethod: (x) => x,
            },
          },
        ],
      },
      {
        questions: [
          {
            name: 'showContactInDirectory',
            title: 'Hide user profile from the directory?',
            isFormField: true,
            component: RadioField,
            labelClassName: 'type-body-semibold-md',
            extraProps: {
              options: [
                { label: 'Yes', value: false },
                { label: 'No', value: true },
              ],
              mappingMethod: (x) => x,
            },
          },
        ],
      },
    ],
  },
]

const getInitialValues = () => {
  return api.settings.getCommunitySettings().catch(() => null)
}

const CommunitySettings = () => {
  const userRole = useSelector((state) => state.auth.role)

  const defaultValues = {
    companyAllowViewProfileCategoryIds: [],
    companyAllowViewFundsCategoryIds: [],
    companyAllowViewMandatesCategoryIds: [],
    showCompanyInDirectory: true,
    contactAllowViewProfileCategoryIds: [],
    contactAllowViewFundsCategoryIds: [],
    contactAllowViewMandatesCategoryIds: [],
    allowConnectionRequestsCategoryIds: [],
    showContactInDirectory: [],
  }

  const handleSave = (vals, formFinished, doneSaving) => {
    if (formFinished) {
      api.settings
        .saveCommunitySettings(vals)
        .then(() => {
          message.success('Settings saved successfully.')
        })
        .catch(() =>
          message.error(
            'An error occurred while attempting to save your settings.'
          )
        )
        .finally(() => doneSaving())
    } else {
      doneSaving()
    }
  }

  return (
    <GenericForm
      defaultValues={defaultValues}
      questionOptions={{}}
      getInitialValues={getInitialValues}
      steps={steps(userRole)}
      onSave={handleSave}
    />
  )
}

CommunitySettings.propTypes = {}

export default CommunitySettings
