import PropTypes from 'prop-types'
import { Input } from '@context365/forms'
import { get, useFormContext } from 'react-hook-form'
import { useFieldSchema } from '../Form'

export function InputField({ name, label, ...inputProps }) {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const error = get(errors, name)
  const rules = useFieldSchema(name, label)

  return (
    <Input
      label={label}
      required={rules.required}
      skipNativeValidation
      {...inputProps}
      {...register(name, inputProps)}
      errorMessage={error?.message}
    />
  )
}

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}
