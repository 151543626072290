import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  faCalendarPlus,
  faCircleNotch,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import isNil from 'lodash/isNil'
import { api as http } from '~/api/services'

const CalendarInvitationDownloadButton = ({ record, label = null }) => {
  const [isDownloading, setIsDownloading] = useState(false)

  const btnStyles = {
    color: '#92949C',
    border: '1px solid #C2C6CC',
    borderRadius: '4px',
    padding: '4px 9px',
  }

  const iconStyles = { color: '#92949C' }
  const labelStyles = { fontWeight: '600', marginLeft: '8px' }

  if (!isNil(label)) {
    btnStyles.marginRight = '8px'
    btnStyles.border = '0'
    btnStyles.padding = '4px 15px'

    iconStyles.color = '#C2C6CC'
  }

  const icon = isDownloading ? faCircleNotch : faCalendarPlus

  const handleClick = (e) => {
    setIsDownloading(true)

    http
      .get(`/calendar-invitations/${record.meetingId}/download`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `Context365-Meeting-${record.meetingId}.ics`
        )
        link.click()
        window.URL.revokeObjectURL(url)
      })
      .finally(() => {
        setIsDownloading(false)
      })

    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <Tooltip placement="top" title="Add to Calendar">
      <Button style={btnStyles} onClick={handleClick} disabled={isDownloading}>
        <FontAwesomeIcon style={iconStyles} icon={icon} spin={isDownloading} />
        {!isNil(label) && (
          <span className="text-primary-100" style={labelStyles}>
            {label}
          </span>
        )}
      </Button>
    </Tooltip>
  )
}

CalendarInvitationDownloadButton.propTypes = {
  record: PropTypes.shape({
    meetingId: PropTypes.number.isRequired,
  }).isRequired,
  label: PropTypes.string,
}

export default CalendarInvitationDownloadButton
