import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { StorageKeys } from '~/config'
import rootReducer from '~/reducers'
import { cacheAuthTokens } from './subscribers'

export const history = createBrowserHistory()

const logger = createLogger({
  collapsed: true,
  diff: true,
})

const middleware = [thunk, logger]

export const configureStore = (initialState) =>
  createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  )

const store = configureStore({
  auth: {
    accessToken: localStorage.getItem(StorageKeys.accessToken),
    refreshToken: localStorage.getItem(StorageKeys.refreshToken),
    claims: JSON.parse(localStorage.getItem(StorageKeys.claims)),
    contact: JSON.parse(localStorage.getItem(StorageKeys.contact)),
    company: JSON.parse(localStorage.getItem(StorageKeys.company)),
    role: localStorage.getItem(StorageKeys.role),
  },
  notifications: {
    token: localStorage.getItem(StorageKeys.deviceToken),
    notificationsById: {},
    notificationIds: [],
    presentationNotifications: [],
    stats: {},
    error: null,
    loading: false,
  },
})

store.subscribe(cacheAuthTokens(store, history))

export default store
