import * as Yup from 'yup'
import * as api from '~/api'
import {
  CheckboxGroupField,
  Form,
  InputField,
  TextAreaField,
  UnsavedChangesAlert,
} from '~/components/Form'
import Loading from '~/components/Loading'
import NumberRangeField from '../fields/NumberRangeField'
import MandateOrderForm from '../forms/MandateOrderForm'
import StrategyPreferencesForm from '../forms/StrategyPreferencesForm'
import { useOnboardingQuery } from '../hooks'
import { useOnboardingContext } from '../OnboardingContext'
import {
  BackButton,
  NextButton,
  OnboardingFooter,
  OnboardingLayout,
  OnboardingSteps,
} from './OnboardingLayout'

const schema = Yup.object().shape({
  minimumFundSizeInterest: Yup.number().nullable().required().min(0),
  maximumFundSizeInterest: Yup.number().nullable().required().moreThan(
    Yup.ref('minimumFundSizeInterest'),
    // eslint-disable-next-line no-template-curly-in-string
    '${path} must be greater than minimum'
  ),
  minimumFirmSizeInterest: Yup.number().nullable().required().min(0),
  maximumFirmSizeInterest: Yup.number().nullable().required().moreThan(
    Yup.ref('minimumFirmSizeInterest'),
    // eslint-disable-next-line no-template-curly-in-string
    '${path} must be greater than minimum'
  ),
  minimumInvestmentSize: Yup.number().nullable().required().min(0),
  averageInvestmentSize: Yup.number().nullable().required().moreThan(
    Yup.ref('minimumInvestmentSize'),
    // eslint-disable-next-line no-template-curly-in-string
    '${path} must be greater than minimum'
  ),
  trackRecordIds: Yup.array()
    .of(Yup.number().required())
    .required('Select at least one option')
    .min(1, 'Select at least one option'),
  netDollarExposureIds: Yup.array().of(Yup.number().required()),
  liquidityLockupTermIds: Yup.array().of(Yup.number().required()),
  capitalLockUpLength: Yup.array()
    .of(Yup.number().required())
    .required()
    .min(1, 'Select at least one option'),
  maximumPercentageOfAfundsAumyouWouldBeComfortableComprising: Yup.number()
    .label('This field') // Simplify error messages to "This field is ..."
    .nullable()
    .required('Please specify a percentage between 0 and 100')
    .integer('Must be a whole number (no decimals)')
    .min(0, 'Must be a whole number greater than or equal to 0')
    .max(100, 'Must be a whole number less than or equal to 100'),
  investmentObjectiveIds: Yup.array().of(Yup.number().required()),
  industryIds: Yup.array().of(Yup.number().required()),
  geographyIds: Yup.array().of(Yup.number().required()),
  investmentDecisionIds: Yup.array().of(Yup.number().required()),
  fundAttributeIds: Yup.array().of(Yup.number().required()),
  mandateOrder: Yup.string().nullable().required().min(1),
  additionalInvestmentPreferences: Yup.string().nullable(),
  fundStrategyIds: Yup.array()
    .of(Yup.number().required())
    .required()
    .min(1, 'Select at least one strategy and one sub-strategy'),
})

export function InvestorDetails({ steps }) {
  const { onNext } = useOnboardingContext()
  const { answers, isLoading, submit } = useOnboardingQuery(
    ['investor-details'],
    {
      getAnswers: api.onboarding.getInvestorDetailsAnswers,
      saveAnswers: api.onboarding.saveInvestorDetailsAnswers,
    }
  )

  if (isLoading) {
    return (
      <OnboardingLayout
        body={
          <div className="flex items-center justify-center">
            <Loading />
          </div>
        }
      />
    )
  }

  return (
    <OnboardingLayout
      header={<OnboardingSteps steps={steps} />}
      body={
        <InvestorDetailsForm
          id="investor-details"
          answers={answers}
          onSubmit={submit}
          onSuccess={onNext}
        />
      }
      footer={
        <OnboardingFooter
          leftContent={<BackButton />}
          rightContent={<NextButton type="submit" form="investor-details" />}
        />
      }
    />
  )
}

function InvestorDetailsForm({ id, answers, onSubmit, onSuccess }) {
  const { options } = useOnboardingContext()

  return (
    <Form
      id={id}
      className="mt-12 max-w-3xl onboarding-form"
      schema={schema}
      defaultValues={answers}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    >
      <div className="type-header-md text-center mb-4">
        Investor Preferences Questionnaire
      </div>

      <div className="type-header-sm">Quantitative Interest</div>
      <NumberRangeField
        name="investmentSize"
        label="Investment Size"
        maxFieldName="averageInvestmentSize"
        type="currency"
      />
      <NumberRangeField
        name="fundSizeInterest"
        label="Fund Size Interest"
        type="currency"
      />
      <NumberRangeField
        name="firmSizeInterest"
        label="Firm Size AUM Interest"
        type="currency"
      />
      <CheckboxGroupField
        name="trackRecordIds"
        label="Track Record Length"
        options={options.trackRecordBins}
      />

      <div className="my-6 my-2 border-t" />

      <div className="type-header-sm">Qualitative Interest</div>
      <StrategyPreferencesForm />
      <CheckboxGroupField
        name="investmentDecisionIds"
        label="Investment Decision Process"
        options={options.investmentDecisionProcesses}
      />
      <CheckboxGroupField
        name="netDollarExposureIds"
        label="Net Dollar Exposure"
        options={options.dollarExposures}
      />
      <CheckboxGroupField
        name="liquidityLockupTermIds"
        label="Liquidity Terms"
        options={options.liquidityTerms}
      />
      <CheckboxGroupField
        name="capitalLockUpLength"
        label="Capital Lock Up Preference"
        options={options.capitalLockup}
      />
      <InputField
        name="maximumPercentageOfAfundsAumyouWouldBeComfortableComprising"
        label="Investment cannot be larger than this percentage of a fund"
        inputMode="number"
        suffix="%"
      />
      <CheckboxGroupField
        name="investmentObjectiveIds"
        label="Investment Objectives"
        options={options.investmentObjectives}
      />
      <CheckboxGroupField
        name="industryIds"
        label="Sector Interests"
        options={options.industries}
      />
      <CheckboxGroupField
        name="geographyIds"
        label="Geographic Concentration Interests"
        options={options.geographies}
      />
      <CheckboxGroupField
        name="fundAttributeIds"
        label="Fund Attributes"
        options={options.fundAttributes}
      />
      <MandateOrderForm />
      <TextAreaField
        name="additionalInvestmentPreferences"
        label="Is there anything else you would like to tell us about your investment preferences?"
        helpMessage="This will help us better match you with funds."
        rows={5}
      />

      <UnsavedChangesAlert />
    </Form>
  )
}
