import * as React from 'react'
import { useLiveRef } from '@context365/hooks'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router-dom'

export default function useSearchParams(schema) {
  const location = useLocation()
  const schemaRef = useLiveRef(schema)
  const searchParams = React.useMemo(() => {
    const params = parse(location.search)
    return schemaRef.current ? schemaRef.current.cast(params) : params
  }, [location.search, schemaRef])

  const history = useHistory()
  const paramsRef = useLiveRef(searchParams)
  const setSearchParams = React.useCallback(
    (nextParams, { replace = false, merge = true } = {}) => {
      const newSearch = stringify(
        merge ? { ...paramsRef.current, ...nextParams } : nextParams
      )
      if (replace) {
        history.replace({ search: newSearch })
      } else {
        history.push({ search: newSearch })
      }
    },
    [history, paramsRef]
  )

  return [searchParams, setSearchParams]
}

function parse(search) {
  return qs.parse(search, { ignoreQueryPrefix: true })
}

const isEmptyParameter = (value) =>
  isNil(value) || (typeof value === 'string' && isEmpty(value))

function stringify(params) {
  return qs.stringify(omitBy(params, isEmptyParameter), { indices: false })
}
