import * as React from 'react'
import PropTypes from 'prop-types'
import { Empty } from 'antd'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { DiscoverFundResult } from '../DiscoverFundResult'
import Loading from '../Loading'

const FundPreviewList = ({
  getFunds,
  emptyDescription,
  hideMatchmakingScore = false,
}) => {
  const [funds, setFunds] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    setLoading(true)
    getFunds()
      .then((data) => setFunds(data.result))
      .finally(() => setLoading(false))
  }, [getFunds])

  return (
    <Loading spinning={loading}>
      {isEmpty(funds) && !loading ? (
        <Empty description={emptyDescription} className="mb-4" />
      ) : (
        map(funds, (f) => (
          <DiscoverFundResult
            key={f.fundId}
            fund={{
              ...f,
              matchmakingScore: hideMatchmakingScore
                ? null
                : f.matchmakingScore,
            }}
            fromPreview
          />
        ))
      )}
    </Loading>
  )
}

FundPreviewList.propTypes = {
  getFunds: PropTypes.func.isRequired,
  emptyDescription: PropTypes.string,
  hideMatchmakingScore: PropTypes.bool,
}

export default FundPreviewList
