import { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faCog,
  faDesktop,
  faInfoCircle,
  faMicrophone,
  faMicrophoneSlash,
  faPhoneSlash,
  faVideo,
  faVideoSlash,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row, Tooltip } from 'antd'
import classNames from 'classnames'
import filter from 'lodash/filter'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useTracking } from 'react-tracking'
import Video, {
  createLocalAudioTrack,
  createLocalVideoTrack,
} from 'twilio-video'
import { ReactComponent as Logo } from '~/assets/logo.svg'
import VideoRoomSettings from '../../VideoRoomSettings'
import MainParticipant from '../MainParticipant/MainParticipant'
import NetworkInicator from '../NetworkIndicator/NetworkIndicator'
import RemoteParticipant from '../RemoteParticipant/RemoteParticipant'
import Sidebar from '../Sidebar'
import './VideoRoom.less'

const VideoRoom = ({
  room,
  contactsInMeeting,
  meetingCompanyName,
  documents,
  onDisconnectCall,
  conversationID,
  defaultMicOn,
  defaultVideoOn,
  videoDeviceId,
  audioDeviceId,
  audioOutputDeviceId,
  meetingType,
  refreshContacts,
  onChangeAudioOutputDevices,
  onChangeAudioDevices,
  onChangeVideoDevices,
}) => {
  const { trackEvent } = useTracking({ component: 'VideoRoom' })
  const [videoOn, setVideoOn] = useState(defaultVideoOn)
  const [micOn, setMicOn] = useState(defaultMicOn)
  const [participants, setParticipants] = useState([])
  const [dominantSpeaker, setDominantSpeaker] = useState(null)
  const [visibleDrawer, setVisibleDrawer] = useState(true)
  const [videoDisabled, setVideoDisabled] = useState(false)
  const [buttonView, setButtonView] = useState(false)
  const [tooltipView, setTooltipView] = useState(false)
  const [showSettings, setShowSettings] = useState(false)

  const audio = useRef()
  const screenTrack = useRef(null)
  const videoOnRef = useRef(defaultVideoOn)
  const fixedDominant = useRef()

  const changeAudioDevices = useCallback(
    (selectedAudioInput) => {
      onChangeAudioDevices(selectedAudioInput)
      if (micOn && selectedAudioInput !== audioDeviceId) {
        room.localParticipant.audioTracks.forEach((audioTracks) => {
          audioTracks.track.disable()
          audioTracks.track.stop()
          room.localParticipant.unpublishTrack(audioTracks.track)
        })
        createLocalAudioTrack({ deviceId: { exact: selectedAudioInput } }).then(
          (track) => {
            room.localParticipant.publishTrack(track)
          }
        )
      }
    },
    [audioDeviceId, micOn, onChangeAudioDevices, room.localParticipant]
  )

  const changeVideoDevices = useCallback(
    (selectedVideoInput) => {
      onChangeVideoDevices(selectedVideoInput)
      if (videoOn && selectedVideoInput !== videoDeviceId) {
        room.localParticipant.videoTracks.forEach((videoTracks) => {
          videoTracks.track.disable()
          videoTracks.track.stop()
          room.localParticipant.unpublishTrack(videoTracks.track)
        })
        createLocalVideoTrack({
          deviceId: { exact: selectedVideoInput },
        }).then((track) => {
          room.localParticipant.publishTrack(track)
        })
      }
    },
    [videoDeviceId, videoOn, onChangeVideoDevices, room.localParticipant]
  )

  const getContactFromIdentity = useCallback(
    (participant) => {
      if (participant.identity.includes('-'))
        return filter(
          contactsInMeeting,
          (c) =>
            c.contactId === parseInt(participant.identity.split('-')[1], 10)
        )[0]
      else
        return filter(
          contactsInMeeting,
          (c) => c.contactId === parseInt(participant.identity, 10)
        )[0]
    },
    [contactsInMeeting]
  )

  const createTwilioLocalVideoTrack = useCallback(() => {
    if (!isNil(videoDeviceId)) {
      createLocalVideoTrack({
        deviceId: { exact: videoDeviceId },
      }).then((track) => {
        room.localParticipant.publishTrack(track)
      })
    } else {
      createLocalVideoTrack().then((track) => {
        room.localParticipant.publishTrack(track)
      })
    }
  }, [room.localParticipant, videoDeviceId])

  const createTwilioLocalAudioTrack = useCallback(() => {
    if (!isNil(audioDeviceId)) {
      createLocalAudioTrack({ deviceId: { exact: audioDeviceId } }).then(
        (track) => {
          room.localParticipant.publishTrack(track)
        }
      )
    } else {
      createLocalAudioTrack().then((track) => {
        room.localParticipant.publishTrack(track)
      })
    }
  }, [room.localParticipant, audioDeviceId])

  const handleVideo = (video) => {
    setVideoOn(!video)
    videoOnRef.current = !video
    trackEvent({
      eventName: 'click',
      element: 'toggle video',
      status: video ? 'disabled' : 'enabled',
    })
    if (video)
      return room.localParticipant.videoTracks.forEach((videoTracks) => {
        videoTracks.track.disable()
        videoTracks.track.stop()
        room.localParticipant.unpublishTrack(videoTracks.track)
      })
    return createTwilioLocalVideoTrack()
  }

  const handleMic = (mic) => {
    setMicOn(!mic)
    trackEvent({
      eventName: 'click',
      element: 'toggle microphone',
      status: mic ? 'disabled' : 'enabled',
    })
    if (mic)
      return room.localParticipant.audioTracks.forEach((audioTracks) => {
        audioTracks.track.disable()
        audioTracks.track.stop()
        room.localParticipant.unpublishTrack(audioTracks.track)
      })
    return createTwilioLocalAudioTrack()
  }

  const handleDominantSpeakerChanged = (newDominantSpeaker) => {
    !isNil(newDominantSpeaker) &&
      !fixedDominant.current &&
      setDominantSpeaker(newDominantSpeaker)
  }

  const participantDisconnected = (participant) => {
    participant.removeAllListeners()
    participant.videoTracks.forEach((remoteTrackPublication) => {
      if (remoteTrackPublication.publishPriority == 'high') {
        fixedDominant.current = false
        setVisibleDrawer(true)
        setDominantSpeaker(room.dominantSpeaker)
      }
    })

    setParticipants((prevParticipants) =>
      prevParticipants.filter((p) => p.state === 'connected')
    )
    !fixedDominant.current && setDominantSpeaker(null)
  }

  const onErrorRoomConnection = (error) => {
    if (error.code === 53001)
      console.log('Reconnecting your signaling connection!', error.message)
    if (error.code === 53405)
      console.log('Reconnecting your media connection!', error.message)
  }

  useEffect(() => {
    !defaultVideoOn &&
      room.localParticipant.videoTracks.forEach((videoTracks) => {
        videoTracks.track.disable()
        videoTracks.track.stop()
        room.localParticipant.unpublishTrack(videoTracks.track)
      })
  }, [defaultVideoOn, room.localParticipant])

  useEffect(() => {
    !defaultMicOn &&
      room.localParticipant.audioTracks.forEach((audioTracks) => {
        audioTracks.track.disable()
        audioTracks.track.stop()
        room.localParticipant.unpublishTrack(audioTracks.track)
      })
  }, [defaultMicOn, room.localParticipant])

  useEffect(() => {
    const participantConnected = (participant) => {
      refreshContacts()
      audio.current.play()
      participant.videoTracks.forEach((remoteTrackPublication) => {
        if (remoteTrackPublication.publishPriority == 'high') {
          fixedDominant.current = true
          setVisibleDrawer(false)
          setDominantSpeaker(participant)
        }
      })

      participant.on('trackUnpublished', (remoteTrackPublication) => {
        if (
          remoteTrackPublication.publishPriority == 'high' &&
          remoteTrackPublication.kind === 'video'
        ) {
          setDominantSpeaker(room.dominantSpeaker)
          fixedDominant.current = false
          setVisibleDrawer(true)
        }
      })

      participant.on('trackPublished', (remoteTrackPublication) => {
        if (
          remoteTrackPublication.publishPriority == 'high' &&
          remoteTrackPublication.kind === 'video'
        ) {
          fixedDominant.current = true
          setVisibleDrawer(false)
          setDominantSpeaker(participant)
        }
      })

      participant.on('disconnected', participantDisconnected)
      setParticipants((prevParticipants) => [...prevParticipants, participant])
      !fixedDominant.current && setDominantSpeaker(participant)
    }

    fixedDominant.current = false

    room.participants.forEach(participantConnected)

    room.localParticipant.setNetworkQualityConfiguration({
      local: 3,
      remote: 3,
    })

    room.on('participantConnected', participantConnected)
    room.on('dominantSpeakerChanged', handleDominantSpeakerChanged)
    room.on('reconnecting', onErrorRoomConnection)
  }, [room])

  useEffect(() => {
    room.localParticipant.on('trackStopped', (track) => {
      if (track.name === 'screen') {
        room.localParticipant.videoTracks.forEach((tracks) => {
          room.localParticipant.unpublishTrack(tracks.track)
        })
        unPublishScreenShare()
        videoOnRef.current &&
          isNil(screenTrack.current) &&
          createTwilioLocalVideoTrack()
      }
    })
  }, [room.localParticipant, createTwilioLocalVideoTrack])

  useEffect(() => {
    isEmpty(participants) &&
      !fixedDominant.current &&
      setDominantSpeaker(room.localParticipant)
    isNil(dominantSpeaker) &&
      !isEmpty(participants) &&
      !fixedDominant.current &&
      setDominantSpeaker(participants[0])
  }, [room, dominantSpeaker, participants])

  const publishScreenShare = useCallback(async () => {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      video: true,
    })
    const newScreenTrack = first(stream.getVideoTracks())
    screenTrack.current = new Video.LocalVideoTrack(newScreenTrack)
    setVideoDisabled(true)
    room.localParticipant.videoTracks.forEach((tracks) => {
      room.localParticipant.unpublishTrack(tracks.track)
    })
    room.localParticipant.publishTrack(newScreenTrack, {
      priority: 'high',
      name: 'screen',
    })
  }, [room.localParticipant])

  const unPublishScreenShare = async () => {
    if (screenTrack.current) {
      setVideoDisabled(false)
      screenTrack.current.disable()
      screenTrack.current.stop()
      screenTrack.current = null
    }
  }

  const shareScreen = useCallback(async () => {
    const isNullScreenShared = isNil(screenTrack.current)
    if (isNullScreenShared) return await publishScreenShare()
    room.localParticipant.videoTracks.forEach((tracks) => {
      room.localParticipant.unpublishTrack(tracks.track)
    })
    unPublishScreenShare()
    videoOn && createTwilioLocalVideoTrack()
  }, [
    createTwilioLocalVideoTrack,
    publishScreenShare,
    room.localParticipant,
    videoOn,
  ])

  const remoteParticipants = (
    <div>
      {room &&
        [...new Set([room.localParticipant].concat(participants))].map(
          (participant, index) => (
            <RemoteParticipant
              key={participant.sid}
              local={index === 0}
              defaultAudioOutput={audioOutputDeviceId}
              participant={participant}
              isScreenShared={videoDisabled || isEmpty(participants)}
              contact={getContactFromIdentity(participant)}
              isDialIn={participant.identity.includes('-')}
            />
          )
        )}
    </div>
  )

  const sliderButton = !visibleDrawer && (
    <Button
      type="primary"
      size="large"
      className="VideoButton"
      onClick={() => setVisibleDrawer(true)}
    >
      <FontAwesomeIcon icon={faInfoCircle} />
      {' Details'}
    </Button>
  )

  return (
    <Row className="VideoRoom">
      <Col
        span={visibleDrawer ? 18 : 24}
        onMouseEnter={() => setButtonView(true)}
        onMouseLeave={() => setButtonView(false)}
        className={classNames({
          'VideoRoom-narrow': visibleDrawer,
          'VideoRoom-wide': !visibleDrawer,
        })}
      >
        {(!fixedDominant.current || buttonView) && (
          <div className="VideoRoom-title">
            <Logo viewBox="0 0 600 100" />
            <div className="cc-meeting" style={{ textAlign: 'start' }}>
              <span
                className={`cc-meeting-name cc-meeting-name-${meetingType.toLowerCase()}`}
              >
                {meetingType}
              </span>
            </div>
          </div>
        )}
        {room && dominantSpeaker && (
          <MainParticipant
            key={dominantSpeaker.sid}
            participant={dominantSpeaker}
            local={dominantSpeaker.sid === room.localParticipant.sid}
            sliderButton={sliderButton}
            buttonView={buttonView}
            contact={getContactFromIdentity(dominantSpeaker)}
            isDialIn={dominantSpeaker.identity.includes('-')}
            visibleDrawer={visibleDrawer}
          />
        )}

        {buttonView && (
          <div
            className={classNames({
              'VideoRoom-shadow-down-narrow': visibleDrawer,
              'VideoRoom-shadow-down-wide': !visibleDrawer,
            })}
          >
            {room && dominantSpeaker && (
              <div className="dominant-network-wrapper">
                Connection:&nbsp;
                <NetworkInicator
                  participant={dominantSpeaker}
                  dominant={true}
                />
              </div>
            )}
            <div>
              {' '}
              <Button
                size="large"
                ghost={!videoOn}
                type="primary"
                disabled={videoDisabled}
                shape="circle"
                className="VideoButton"
                onClick={() => handleVideo(videoOn)}
              >
                {videoOn ? (
                  <FontAwesomeIcon icon={faVideo} />
                ) : (
                  <FontAwesomeIcon icon={faVideoSlash} />
                )}
              </Button>
              <Button
                size="large"
                ghost={!micOn}
                type="primary"
                shape="circle"
                className="VideoButton"
                onClick={() => handleMic(micOn)}
              >
                {micOn ? (
                  <FontAwesomeIcon icon={faMicrophone} />
                ) : (
                  <FontAwesomeIcon icon={faMicrophoneSlash} />
                )}
              </Button>
              <Tooltip
                visible={videoDisabled && tooltipView}
                placement="top"
                title="Stop screen sharing"
              >
                <Button
                  onMouseEnter={() => setTooltipView(true)}
                  onMouseLeave={() => setTooltipView(false)}
                  size="large"
                  type="primary"
                  disabled={fixedDominant.current}
                  shape="circle"
                  className={!videoDisabled ? 'VideoButton' : 'RedVideoButton'}
                  onClick={() => shareScreen()}
                >
                  <FontAwesomeIcon icon={faDesktop} />
                </Button>
              </Tooltip>
              <Button
                size="large"
                type="primary"
                shape="circle"
                className="RedVideoButton"
                onClick={() => onDisconnectCall()}
              >
                <FontAwesomeIcon icon={faPhoneSlash} />
              </Button>
            </div>

            <Tooltip
              trigger={['click']}
              overlayClassName="VideoRoom-settings"
              placement="top"
              color="#000"
              title={
                <VideoRoomSettings
                  visible={showSettings}
                  audioOutputDeviceId={audioOutputDeviceId}
                  changeAudioDevices={changeAudioDevices}
                  changeAudioOutDevices={onChangeAudioOutputDevices}
                  changeVideoDevices={changeVideoDevices}
                />
              }
              onVisibleChange={(visible) => setShowSettings(visible)}
            >
              <div className="SettingsButton">
                Audio/Video Settings
                <FontAwesomeIcon
                  className="SettingsButton-icon"
                  size="2x"
                  icon={faCog}
                />
              </div>
            </Tooltip>
          </div>
        )}
      </Col>

      <Col span={visibleDrawer ? 6 : 0} style={{ height: '100%' }}>
        <Sidebar
          participants={remoteParticipants}
          length={participants.length}
          meetingCompanyName={meetingCompanyName}
          documents={documents}
          visibleDrawer={visibleDrawer}
          fixedDominant={fixedDominant.current}
          setVisibleDrawer={setVisibleDrawer}
          conversationID={conversationID}
          meetingType={meetingType}
        />
        )
      </Col>

      <audio
        ref={audio}
        src="../../../../context-video-conference.wav"
        autoPlay={false}
      />
    </Row>
  )
}

VideoRoom.propTypes = {
  meetingCompanyName: PropTypes.string.isRequired,
  conversationID: PropTypes.number.isRequired,
  room: PropTypes.object.isRequired,
  contactsInMeeting: PropTypes.array.isRequired,
  onDisconnectCall: PropTypes.func.isRequired,
  documents: PropTypes.array,
  onChangeAudioOutputDevices: PropTypes.func,
  audioOutputDeviceId: PropTypes.string,
  defaultMicOn: PropTypes.bool.isRequired,
  defaultVideoOn: PropTypes.bool.isRequired,
  videoDeviceId: PropTypes.string,
  audioDeviceId: PropTypes.string,
  meetingType: PropTypes.string,
  refreshContacts: PropTypes.func,
  onChangeAudioDevices: PropTypes.func,
  onChangeVideoDevices: PropTypes.func,
}
export default VideoRoom
