import { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { useSelector } from 'react-redux'
import SimpleBar from 'simplebar-react'
import Loading from '../../Loading'
import DataTransformations from '../DataTransformations'
import DaySeparator from '../DaySeparator'
import MessageItem from '../MessageItem'
import { MessageShape } from '../MessagePropTypes'
import './MessageBoard.less'

const MessageBoard = ({
  messages,
  searchTerm,
  searchResults,
  currentSearchIndex,
  onScrolledToTop = () => {},
  loadingOlderMessages = false,
  consumptionLookup,
  needsHeadsUp = false,
  daySeparatorStyle,
}) => {
  const [forceScrollDown, setForceScrollDown] = useState(true)
  const [displayScrollPopup, setDisplayScrollPopup] = useState(false)
  const [lastMessage, setLastMessage] = useState(
    messages[messages.length - 1]?.body
  )
  const bottomDiv = useRef(null)
  const messageBoardDiv = useRef(null)

  // the div that has messages and needs operations
  // based on and towards scroll
  const messageBoardParent = isNil(messageBoardDiv.current)
    ? null
    : messageBoardDiv.current

  // on scroll, if the user has scrolled to top, tell driver component
  // so that older messages can be loaded
  const boardScrollHandler = useCallback(() => {
    if (messageBoardParent.contentWrapperEl.scrollTop === 0) onScrolledToTop()
  }, [messageBoardParent, onScrolledToTop])

  // if scroll down has been requested, scroll to bottom by
  // bringing the bottom div into view
  useEffect(() => {
    if (forceScrollDown) {
      //cannot mock useRef for some fn reason, so it's workaround time
      if (isNil(bottomDiv.current.scrollIntoView)) return
      bottomDiv.current.scrollIntoView(false)
      setForceScrollDown(false)
    }
  }, [forceScrollDown])

  // if starting to load older messages, after the load has been done
  // (hence loadingOlderMessages resets to false)
  // scroll a bit down to allow the user to scroll up and fetch
  // other older messages
  useEffect(() => {
    if (
      loadingOlderMessages === false &&
      !isNil(messageBoardParent) &&
      messageBoardParent.contentWrapperEl.scrollTop === 0
    ) {
      messageBoardParent.contentWrapperEl.scrollTop = 10
    }
  }, [loadingOlderMessages, messageBoardParent])

  // if messages length changes, either a new message has arrived or
  // older messages have been loaded - if the user is 200px close to the bottom
  // scroll down so they can see new messages

  useEffect(() => {
    if (
      !isNil(messageBoardParent) &&
      isNil(searchResults) &&
      messageBoardParent.contentWrapperEl.scrollHeight -
        messageBoardParent.contentWrapperEl.scrollTop -
        messageBoardParent.contentWrapperEl.clientHeight <
        200
    ) {
      bottomDiv.current.scrollIntoView(false)
    } else if (
      !isNil(messageBoardParent) &&
      lastMessage !== messages[messages.length - 1]?.body
    ) {
      setDisplayScrollPopup(true)
      setLastMessage(messages[messages.length - 1].body)
    }
  }, [messageBoardParent, searchResults, lastMessage, messages])

  const newMessagePopupClickHandler = useCallback(() => {
    setDisplayScrollPopup(false)
    setForceScrollDown(true)
  }, [])

  const contactID = useSelector((state) => state.auth.contact.contactId)
  const companyID = useSelector((state) => state.auth.company.companyId)

  // the list of indexes found from searching the conversation
  const messageIndexesFound = isNil(searchResults)
    ? []
    : searchResults.map((x) => x.messageIndex)

  // grouped messages into days to display divisions by day with a separator
  const processedMessages =
    DataTransformations.TransformMessagesToMessagesWithStatus(
      messages,
      consumptionLookup,
      contactID,
      companyID
    )
  const dayGroups = DataTransformations.groupMessagesIntoDays(processedMessages)
  return (
    <SimpleBar
      className="overflow-y-auto w-full bg-white"
      ref={messageBoardDiv}
      onScroll={boardScrollHandler}
      scroll
      style={{
        maxHeight: 'calc(100vh - 360px)',
        minHeight: 'calc(100vh - 360px)',
      }}
    >
      <div className="message-board-loading-older">
        <Loading
          spinning={loadingOlderMessages}
          style={{
            width: '100%',
            height: '20px',
          }}
        />
      </div>

      {/* cycle through days */}
      {Object.keys(dayGroups).map((day) => (
        <>
          {/* for each day, render a separator... */}
          <DaySeparator
            dividerTimeStyle={daySeparatorStyle}
            key={day}
            time={moment(day)}
          />

          {/* ... and then the list of messages in that day */}
          {dayGroups[day].map((message, index) => (
            <MessageItem
              key={message.messageID}
              message={message}
              // this decides if the message is mine or someone else's, or a log message
              messageType={DataTransformations.getMessageType(
                message,
                contactID
              )}
              // search term is passed to decide which part of the messages that could
              // contain it should be highlighted
              searchTerm={searchTerm}
              // if the contact is the same, other people's messages should not display the
              // contact header again
              isSameContactAsPrevious={
                index === 0
                  ? false
                  : message.authorContact.contactId ===
                      dayGroups[day][index - 1].authorContact.contactId &&
                    isNil(dayGroups[day][index - 1].logTypeID)
              }
              // if this message is part of the search results, we mark it as highlighted
              isHighlighted={messageIndexesFound.includes(message.index)}
              // if it's the current search result, the class changes
              // and scrolls the message into view (handled by the item itself on render)
              // note: there's only one search result, unless two messages have the same index
              // which is basically impossible
              isCurrentSearchResult={
                isNil(searchResults) || isNil(searchResults[currentSearchIndex])
                  ? false
                  : message.index ===
                    searchResults[currentSearchIndex].messageIndex
              }
              consumptionLookup={consumptionLookup}
              isAttachment={message.isAttachment}
              attachmentUrl={message.attachmentUrl}
            />
          ))}
        </>
      ))}

      {/* Heads up text, in case there's only one more message to send */}
      {needsHeadsUp && (
        <div className="antTypography message-board-last-message-notification">
          You are allowed to send one more message since there are no active
          meetings for this conversation. <br />
          After this, the channel will be reactivated when the other party comes
          into contact.
        </div>
      )}

      {/* Small icon that allows scrolling down on click */}
      {displayScrollPopup && (
        <div className="messaging-arrow-new-message">
          <button
            className="messaging-arrow-new-message-icon"
            onClick={newMessagePopupClickHandler}
          >
            <FontAwesomeIcon icon={faArrowDown} />
            <span>New Message</span>
          </button>
        </div>
      )}

      {/* an empty div at the bottom to help scroll down easily */}
      <div ref={bottomDiv} />
    </SimpleBar>
  )
}

MessageBoard.propTypes = {
  messages: PropTypes.arrayOf(MessageShape).isRequired,
  searchTerm: PropTypes.string,
  searchResults: PropTypes.arrayOf(PropTypes.object),
  currentSearchIndex: PropTypes.number,
  onScrolledToTop: PropTypes.func,
  loadingOlderMessages: PropTypes.bool,
  consumptionLookup: PropTypes.arrayOf(
    PropTypes.shape({
      ContactID: PropTypes.number,
      LastConsumedMessageIndex: PropTypes.number,
    })
  ),
  needsHeadsUp: PropTypes.bool,
  daySeparatorStyle: PropTypes.object,
}

export default MessageBoard
