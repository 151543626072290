import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'
import { renderCfnParticipantsAvatars } from '~/utils/meetingActions'
import './MeetingCard.less'

const MeetingCard = ({
  record,
  renderInfo,
  renderCalendarInvitation,
  renderActions,
  isReceived = false,
}) => {
  const history = useHistory()

  return (
    <Row
      className="MeetingCard cc-shadow1"
      onClick={() =>
        history.push({
          pathname: `/meeting/online/${record.meetingId}`,
          state: { isReceived },
        })
      }
    >
      <Col span={4}>
        {record.companies.map((rec, key) => (
          <div
            key={key}
            style={{
              marginBottom: key < record.companies.length - 1 ? '15px' : '0px',
            }}
          >
            <div>
              <Link
                to={{
                  pathname: `/meeting/online/${record.meetingId}`,
                  state: { isReceived },
                }}
              >
                <span className="cc-body-text">{rec.companyName}</span>
              </Link>
              <div className="ReceivedRequestsTable-category">
                {rec.category}
              </div>
            </div>
          </div>
        ))}
      </Col>
      <Col span={isNil(renderInfo) ? 10 : 8}>
        {renderCfnParticipantsAvatars(record)}
      </Col>
      <Col className="MeetingCard-center" span={4}>
        {moment(record.meetingDateTime).isValid()
          ? moment
              .utc(record.meetingDateTime)
              .local()
              .format('YYYY-MM-DD h:mm A')
          : 'TBD'}
        {record.dealId && (
          <div className="DealMeetingLabel cc-xsmall-font">DEAL MEETING</div>
        )}
      </Col>
      {renderInfo && (
        <Col className="MeetingCard-center" xs={8} sm={8} md={8} lg={3} xl={3}>
          {renderInfo(record)}
        </Col>
      )}
      {renderCalendarInvitation && (
        <Col
          className="MeetingCard-center"
          xs={24}
          sm={24}
          md={24}
          lg={1}
          xl={1}
        >
          {renderCalendarInvitation(record)}
        </Col>
      )}
      <Col
        className="MeetingCard-center-actions"
        xs={24}
        sm={24}
        md={24}
        lg={4}
        xl={4}
      >
        {renderActions(record)}
      </Col>
    </Row>
  )
}
MeetingCard.propTypes = {
  record: PropTypes.object.isRequired,
  renderInfo: PropTypes.func,
  renderCalendarInvitation: PropTypes.func,
  renderActions: PropTypes.func,
  isReceived: PropTypes.bool,
}

export default MeetingCard
