import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, message } from 'antd'
import isNil from 'lodash/isNil'
import numeral from 'numeral'
import loginLogo from '~/assets/new-logo.svg'
import './Onboarding.less'

const LayoutConfirm = ({
  title,
  companyId,
  onNext,
  onBack,
  backVisible,
  step,
  currentQuestions,
  actionType,
}) => {
  const [formPosting, setFormPosting] = useState(false)
  const [scrolledToTop, setScrolledToTop] = useState(false)

  if (scrolledToTop === false) {
    setScrolledToTop(true)
    window.scrollTo(0, 0)
  }
  const renderQuestionAnswer = useCallback((q) => {
    const width = q.questionTypeID === 2 ? '100%' : '50%'
    let answer =
      q.questionTypeID === 7 ? (q.answer === true ? 'Yes' : 'No') : q.answer

    if (q.questionTypeID === 13 && !isNil(answer)) {
      const translations = {
        continent: 'Continent',
        country: 'Country',
        stateProvince: 'State/Province',
        city: 'City',
        zipCode: 'Zip Code',
        address1: 'Address',
        address2: 'Address 2',
        address3: 'Address 3',
      }
      const addressDetails = Object.keys(answer)
      return addressDetails.map((d) =>
        d === 'isValid' || isNil(answer[d]) || answer[d] === '' ? null : (
          <div className="cc-layout-confirm-question-answer" style={{ width }}>
            <div className="cc-small-font cc-layout-confirm-question">
              {translations[d]}
            </div>
            <div className="cc-body-font cc-layout-confirm-answer">
              {answer[d]}
            </div>
          </div>
        )
      )
    }

    if (
      (q.questionTypeID === 3 || q.shortName === 'FundDomicileInterest') &&
      !isNil(answer)
    )
      answer = answer.replace(/,/g, ', ')

    if (q.questionTypeID === 5 || q.questionTypeID === 6)
      answer = numeral(answer).format('0,0')
    return isNil(answer) ? null : (
      <div className="cc-layout-confirm-question-answer" style={{ width }}>
        <div className="cc-small-font cc-layout-confirm-question">
          {q.label}
        </div>
        <div className="cc-body-font cc-layout-confirm-answer">{answer}</div>
      </div>
    )
  }, [])

  const getLayoutHeader = useCallback(
    () => (
      <div className="cc-layout-default-header">
        <div className="cc-layout-default-context">
          <div
            className="cc-login-logo-onboard"
            style={{ backgroundImage: `url(${loginLogo})` }}
          />
        </div>
        <div className="cc-heading1 cc-layout-default-welcome">
          Welcome to ApexInvest
        </div>
      </div>
    ),
    []
  )

  const onConfirmed = useCallback(() => {
    if (actionType === 1) {
      const { questions } = currentQuestions.find(
        (x) => x.step === step.confirmStep[0]
      )
      step
        .action(companyId, questions)
        .then(() => {
          onNext()
        })
        .catch(() =>
          message.error('There was an error while attempting to save')
        )
        .finally(() => setFormPosting(false))
    } else if (actionType === 2) {
      //saveContact
    }
  }, [actionType, companyId, currentQuestions, onNext, step])

  const getLayoutFooter = useCallback(
    () => (
      <div className="cc-layout-confirm-footer">
        <Button
          onClick={onConfirmed}
          style={{
            width: '364px',
            height: '48px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          type="primary"
          loading={formPosting}
        >
          Confirm
        </Button>
        <Button
          onClick={onBack}
          style={{ width: '364px', height: '48px' }}
          type="default"
        >
          Edit
        </Button>
      </div>
    ),
    [formPosting, onBack, onConfirmed]
  )

  const getTitle = () => (
    <div className="cc-heading4 cc-layout-default-title">{title}</div>
  )

  const getQuestionsAndAnswers = useCallback(() => {
    const result = []
    for (let i = 0; i < step.confirmStep.length; i++) {
      const currentQuestion = currentQuestions.find(
        (x) => x.step === step.confirmStep[i]
      )
      if (isNil(currentQuestion)) return null
      result.push(currentQuestion.questions.map((x) => renderQuestionAnswer(x)))
    }
    return result
  }, [currentQuestions, renderQuestionAnswer, step.confirmStep])

  return (
    <div className="cc-layout-confirm">
      <Button
        onClick={onBack}
        hidden={!backVisible}
        className="cc-layout-default-back"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        &nbsp;&nbsp;Back
      </Button>
      {getLayoutHeader()}
      {getTitle()}
      <div className="cc-layout-confirm-form-space">
        {getQuestionsAndAnswers()}
        {getLayoutFooter()}
      </div>
    </div>
  )
}

LayoutConfirm.propTypes = {
  title: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  backVisible: PropTypes.bool.isRequired,
  step: PropTypes.shape({
    stepNumber: PropTypes.number.isRequired,
    innerStep: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired,
    shouldPost: PropTypes.bool.isRequired,
    actionType: PropTypes.number.isRequired,
    canGoBack: PropTypes.bool.isRequired,
    customWidth: PropTypes.bool,
    action: PropTypes.func.isRequired,
    confirmStep: PropTypes.array,
  }).isRequired,
  currentQuestions: PropTypes.array.isRequired,
  companyId: PropTypes.number.isRequired,
  actionType: PropTypes.number.isRequired,
}

export default LayoutConfirm
