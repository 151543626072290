import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Axis,
  Chart,
  ChartTooltip,
  LineSeries,
  useAxis,
  useChart,
  useChartTooltipState,
  useLineSeries,
} from '@context365/charts'
import { format } from 'd3-format'
import { timeFormat } from 'd3-time-format'
import sortBy from 'lodash/sortBy'
import unionBy from 'lodash/unionBy'
import useElementSize from '~/hooks/useElementSize'
import { useCategoryColors } from '../utils'

export default function ReturnsChart({ returns }) {
  const allCategories = React.useMemo(() => {
    const categories = unionBy(
      ...returns.map(({ categories }) => categories),
      'categoryId'
    )
    return sortBy(categories, 'categoryId')
  }, [returns])

  const categoryColors = useCategoryColors(allCategories)

  const containerRef = React.useRef()
  const { width } = useElementSize(containerRef)

  const chart = useChart({
    data: returns,
    height: 360,
    width,
    padding: {
      left: 50,
      right: 20,
      top: 20,
      bottom: 20,
    },
  })

  const x = useAxis({
    type: 'time',
    getValue: (d) => d.date,
    formatValue: timeFormat('%b %Y'),
    range: chart.range.horizontal,
  })
  const y = useAxis({
    type: 'linear',
    getValue: (d) => d.returnPercentage,
    formatValue: format('+.1%'),
    formatAxis: format('.0%'),
    range: chart.range.vertical,
  })

  const lineSeries = useLineSeries({
    chart,
    x,
    y,
    series: allCategories.map((category) => ({
      key: category.categoryId,
      label: category.name,
      color: categoryColors[category.categoryId].data,
      getDataset: (returns) =>
        returns
          .map((r) => {
            const percentage = r.categories.find(
              (c) => c.categoryId === category.categoryId
            )?.actualPercentage

            return percentage == null
              ? undefined
              : {
                  date: new Date(r.valuationDate),
                  returnPercentage: percentage,
                }
          })
          .filter(Boolean),
    })),
  })

  const tooltip = useChartTooltipState(chart, lineSeries)

  return (
    <div ref={containerRef}>
      <Chart {...chart.getChartProps()}>
        <Axis {...lineSeries.getAxisProps('bottom')} />
        <Axis {...lineSeries.getAxisProps('left')} />
        <LineSeries {...lineSeries} />
      </Chart>
      <ChartTooltip {...tooltip} />
    </div>
  )
}

ReturnsChart.propTypes = {
  returns: PropTypes.arrayOf(
    PropTypes.shape({
      valuationDate: PropTypes.string.isRequired,
      actualRateOfReturn: PropTypes.number,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          categoryId: PropTypes.number.isRequired,
          isGlobal: PropTypes.bool.isRequired,
          name: PropTypes.string.isRequired,
          actualPercentage: PropTypes.number,
        })
      ).isRequired,
    })
  ).isRequired,
}
