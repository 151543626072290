import PropTypes from 'prop-types'
import {
  faFile,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import isNil from 'lodash/isNil'
import { FundShape } from '../MessagePropTypes'
import './FundsAndDocuments.less'

// dictionary for what icon and color to display for each file extension
const extensionIcons = [
  {
    extension: 'pdf',
    icon: faFilePdf,
    styleColor: 'red',
  },
  {
    extension: 'xls',
    icon: faFileExcel,
    styleColor: 'green',
  },
  {
    extension: 'xlsx',
    icon: faFileExcel,
    styleColor: 'green',
  },
  {
    extension: 'csv',
    icon: faFileExcel,
    styleColor: 'green',
  },
  {
    extension: 'doc',
    icon: faFileWord,
    styleColor: 'blue',
  },
  {
    extension: 'docx',
    icon: faFileWord,
    styleColor: 'blue',
  },
  {
    extension: 'ppt',
    icon: faFilePowerpoint,
    styleColor: 'orange',
  },
  {
    extension: 'pptx',
    icon: faFilePowerpoint,
    styleColor: 'orange',
  },
]
const FundsAndDocuments = ({ funds, classExtension = '', companyName }) => (
  <>
    {funds.map((f) => (
      <div className="messaging-chat-documents" key={f.fundID}>
        <div className="messaging-chat-documents-title">{f.name}</div>
        {f.documents.length > 0 &&
          f.documents.map((x) => (
            <div className="messaging-chat-document-container" key={x.fileName}>
              <div className="messaging-chat-document-image">
                <FontAwesomeIcon
                  icon={
                    extensionIcons.find((e) => e.extension === x.extension)
                      ?.icon || faFile
                  }
                  className={`messaging-chat-document-${
                    extensionIcons.find((e) => e.extension === x.extension)
                      ?.styleColor || 'blue'
                  }`}
                />
              </div>
              <div className="messaging-chat-document-desc">
                <div
                  className={`cc-body-text messaging-chat-document-filename${classExtension}`}
                >
                  <Button
                    type="link"
                    onClick={() => {
                      window.open(
                        `/attachment/${encodeURIComponent(companyName)}/${
                          f.fundID
                        }?file=${encodeURIComponent(x.fileURL)}`,
                        '_blank'
                      )
                    }}
                  >
                    {isNil(x.fileName) || x.fileName === ''
                      ? 'UNTITLED'
                      : x.fileName}
                  </Button>
                </div>
                {/* <div className={`cc-xsmall-font${classExtension}`}>
                {moment
                  .utc(x.documentDate)
                  .local()
                  .format('MMM DD, YYYY h:mm a')}
              </div> */}
              </div>
            </div>
          ))}
        {f.documents.length === 0 && (
          <div className="cc-body-text messaging-chat-no-documents">
            No documents attached
          </div>
        )}
      </div>
    ))}
    {/* <PDFViewerContainer
        name={companyName}
        fundId={selectedFundId}
        url={selectedUrl}
        onClose={() => setIsReaderVisible(false)}
        isVisible={isReaderVisible}
      /> */}
  </>
)

FundsAndDocuments.propTypes = {
  funds: PropTypes.arrayOf(FundShape).isRequired,
  classExtension: PropTypes.string,
  companyName: PropTypes.string.isRequired,
}

export default FundsAndDocuments
