import * as React from 'react'
import arrify from 'arrify'
import isEqual from 'lodash/isEqual'

export default function useArrayMemo(value) {
  const arr = arrify(value)
  const memoized = React.useRef(arr)
  if (!isEqual(arr, memoized.current)) {
    memoized.current = arr
  }
  return memoized.current
}
