import * as React from 'react'
import clamp from 'lodash/clamp'
import useFrameState from '~/hooks/useFrameState'

// The list of funds must be able to scroll at least this far for the paging
// button to be enabled
const pageScrollingThreshold = 10

export default function usePagedScrolling(
  scrollContainerRef,
  visible,
  fundCount
) {
  const [hasPrev, setHasPrev] = useFrameState(false)
  const [hasNext, setHasNext] = useFrameState(false)

  const updatePagerButtons = React.useCallback(
    (scroller) => {
      const scrollerLeftEdge = scroller.scrollLeft
      const scrollerRightEdge = scrollerLeftEdge + scroller.clientWidth

      setHasPrev(scrollerLeftEdge > pageScrollingThreshold)
      setHasNext(
        scrollerRightEdge < scroller.scrollWidth - pageScrollingThreshold
      )
    },
    [setHasPrev, setHasNext]
  )

  // Update the buttons whenever the grid scrolls or the window is resized
  React.useEffect(() => {
    const scroller = scrollContainerRef.current
    if (!scroller) {
      return undefined
    }

    const onChange = () => {
      updatePagerButtons(scroller)
    }

    scroller.addEventListener('scroll', onChange)
    window.addEventListener('resize', onChange)

    return () => {
      scroller.removeEventListener('scroll', onChange)
      window.removeEventListener('resize', onChange)
    }
  }, [scrollContainerRef, updatePagerButtons])

  // Update the buttons whenever the number of grid cells changes or the
  // grid becomes visible
  React.useEffect(() => {
    const scroller = scrollContainerRef.current
    if (!scroller || !visible) {
      return
    }

    updatePagerButtons(scroller)
  }, [scrollContainerRef, updatePagerButtons, fundCount, visible])

  function scrollToPrevious() {
    const scrollState = getScrollState(scrollContainerRef)
    if (!scrollState) {
      return
    }

    const { scroller, currentScrollPosition, pageSize } = scrollState
    const currentPage = currentScrollPosition / pageSize
    const prevPagePosition = Math.floor(currentPage) * pageSize

    const scrollPosition =
      currentScrollPosition - prevPagePosition < pageScrollingThreshold
        ? prevPagePosition - pageSize
        : prevPagePosition

    scrollTo(scroller, scrollPosition)
  }

  function scrollToNext() {
    const scrollState = getScrollState(scrollContainerRef)
    if (!scrollState) {
      return
    }

    const { scroller, currentScrollPosition, pageSize } = scrollState
    const currentPage = currentScrollPosition / pageSize
    const nextPagePosition = Math.ceil(currentPage) * pageSize

    const scrollPosition =
      nextPagePosition - currentScrollPosition < pageScrollingThreshold
        ? nextPagePosition + pageSize
        : nextPagePosition

    scrollTo(scroller, scrollPosition)
  }

  function scrollTo(scroller, position) {
    const minScrollPosition = 0
    const maxScrollPosition = scroller.scrollWidth - scroller.clientWidth

    scroller.scrollTo({
      left: clamp(position, minScrollPosition, maxScrollPosition),
      behavior: 'smooth',
    })
  }

  return {
    hasPrev,
    scrollToPrevious,
    hasNext,
    scrollToNext,
  }
}

function getScrollState(scrollContainerRef) {
  const scroller = scrollContainerRef.current
  if (!scroller || scroller.children.length === 0) {
    return null
  }

  const currentScrollPosition = scroller.scrollLeft

  const cardWidth = getLengthFromPx(scroller.firstChild.clientWidth)
  const gridGap = getLengthFromPx(getComputedStyle(scroller).gridColumnGap)
  const pageSize = cardWidth + gridGap

  return { scroller, currentScrollPosition, pageSize }
}

function getLengthFromPx(length) {
  if (typeof length === 'number') {
    return length
  }

  if (length.endsWith('px')) {
    const px = length.replace(/px$/, '')
    return parseInt(px, 10)
  }

  throw new Error(`Expected a length with "px" units but got ${length}`)
}
