import PropTypes from 'prop-types'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import './ForbiddenMessagePanel.less'

const ForbiddenMessagePanel = ({ onRequestUpgrade = () => {} }) => (
  <div className="forbidden-panel-container">
    <div className="forbidden-panel-icon">
      <div className="forbidden-panel-icon-background">
        <img
          className="forbidden-panel-icon-svg"
          src="/static/img/winner 1.svg"
        />
      </div>
    </div>
    <div className="forbidden-panel-text">
      In order to read this conversation you need the <b>Pro Package</b>
    </div>
    <div className="forbidden-panel-upgrade">
      <Button
        type="primary"
        className="forbidden-panel-upgrade-button"
        onClick={onRequestUpgrade}
      >
        <FontAwesomeIcon icon={faStar} />
        Request an Upgrade
      </Button>
    </div>
  </div>
)

ForbiddenMessagePanel.propTypes = {
  onRequestUpgrade: PropTypes.func,
}

export default ForbiddenMessagePanel
