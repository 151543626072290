import PropTypes from 'prop-types'
import { InfoOutlined } from '@context365/icons'
import './NoValuesView.less'

const NoValuesView = ({ title, subTitle, centered = false, small = false }) => (
  <div
    className={`profile-empty ${small && 'profile-small'}`}
    style={{ minHeight: centered && 'calc(100vh - 110px)' }}
  >
    <InfoOutlined className="text-brand-100" size={48} />

    <span className={`title-span ${small && 'title-small'}`}>{title}</span>

    <span className="desc-span">{subTitle}</span>
  </div>
)
NoValuesView.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  centered: PropTypes.bool,
  small: PropTypes.bool,
}
export default NoValuesView
