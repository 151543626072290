import isNil from 'lodash/isNil'
import { getServiceProviderCategories } from '~/actions/serviceProviders'
import {
  CCTableActionCell,
  CCTableLinkCell,
  CCTableProfilePicsCell,
  CCTableTagGroupCell,
} from '~/components/ContextTable/ContextTable'
import { getMenuItems } from './menuItems'

export const getServiceProviderDefaultColumns = (
  requestMeeting,
  sendMessage,
  viewDetails,
  role
) => [
  {
    title: 'COMPANY NAME',
    key: ['company', 'name'],
    className: 'cc-name-column',
    cellType: CCTableLinkCell,
    sorter: true,
    fixed: 'left',
    width: 250,
  },
  {
    title: 'CONTACTS',
    key: 'contacts',
    className: 'cc-contacts-column',
    cellType: CCTableProfilePicsCell,
    width: 85,
    fixed: 'left',
    onCell: () => ({
      className: 'cc-contacts-cell',
    }),
    align: 'left',
  },
  {
    title: 'ACTIONS',
    cellType: CCTableActionCell,
    className: 'cc-actions-column',
    menuItemsMethod: getMenuItems.bind(
      null,
      requestMeeting,
      sendMessage,
      viewDetails,
      true,
      role,
      'service provider'
    ),
    fixed: 'left',
    onCell: () => ({
      className: 'cc-actions-cell',
    }),
    width: 75,
    align: 'left',
  },
  {
    title: 'SERVICE PROVIDER TYPE',
    cellType: CCTableTagGroupCell,
    sorter: false,
    key: 'serviceProviderCategory',
    itemsMethod: (x) =>
      isNil(x.serviceProviderCategory)
        ? []
        : x.serviceProviderCategory.split(','),
    align: 'center',
    tagGroupColor: 'purple',
    filterMethod: getServiceProviderCategories,
    filterMappingMethod: (x) => ({ value: x, text: x }),
    tagGroupMaxLength: 2,
  },
  {
    title: 'WEBSITE',
    dataIndex: ['company', 'companyWebsite'],
  },
]
