import { useContext, useRef, useState } from 'react'
import { Button } from '@context365/button'
import { Divider, message } from 'antd'
import { useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { useHistory } from 'react-router-dom'
import PresentationWizardCtx from '~/utils/contexts/PresentationWizardCtx'
import LearnMoreAboutRolesModal from './LearnMoreAboutRolesModal'
import AdditionalOptions from './Steps/AdditionalOptions'
import AttendeesInformation from './Steps/AttendeesInformation'
import ModeratorPresenters from './Steps/ModeratorPresenters'
import PresentationDetails from './Steps/PresentationDetails'
import PresentationOverview from './Steps/PresentationOverview'
import PresentationType from './Steps/PresentationType'
import WhoCanAttend from './Steps/WhoCanAttend'
import './PresentationWizard.less'

const PresentationWizard = () => {
  const {
    currentStep,
    handleNextStep,
    handleBackStep,
    handleSaveDraft,
    handlePublish,
    isPublished,
  } = useContext(PresentationWizardCtx)
  const scrollRef = useRef(null)
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false)
  const { validateForm } = useFormikContext()
  const history = useHistory()

  const STEPS = [
    {
      title: 'Presentation Type',
      subTitle: 'Select type of presentation you want to create',
      component: <PresentationType />,
    },
    {
      title: 'Presentation Details',
      subTitle: 'Enter title and description of the presentation',
      component: <PresentationDetails />,
    },
    {
      title: 'Moderator and Presenters at your company',
      subTitle:
        'Who will be the moderator and presenters of your presentation?',
      warningTitle: (
        <div>
          You can have up to 1 admin, 1 moderator and 5 presenters
          <Button variant="link" onClick={() => setShowLearnMoreModal(true)}>
            Learn more about roles
          </Button>
        </div>
      ),
      component: <ModeratorPresenters />,
    },
    {
      title: 'Who can attend?',
      subTitle: 'Select the types of people who can attend the presentation',
      component: <WhoCanAttend />,
    },
    {
      title: 'Who can attend?',
      subTitle: 'Invite people to your presentation',
      warningTitle: 'You can have up to 30 attendees',
      component: <AttendeesInformation />,
    },
    {
      title: 'Additional Options',
      subTitle:
        'Who will be the moderator and presenters of your presentation?',
      component: <AdditionalOptions />,
    },
    {
      title: 'Presentation Overview',
      subTitle:
        'Please confirm the details before you publish the presentation',
      component: <PresentationOverview />,
    },
  ]

  return (
    <div>
      <LearnMoreAboutRolesModal
        visible={showLearnMoreModal}
        onClose={() => setShowLearnMoreModal(false)}
      />
      <div className="text-lg font-semibold text-grey-900 flex justify-center mb-6">
        {STEPS[currentStep].title}
      </div>
      <div className="text-sm text-grey-500 flex justify-center mb-6">
        {STEPS[currentStep].subTitle}
      </div>
      {STEPS[currentStep].warningTitle && (
        <div className="text-grey-700 font-semibold flex justify-center mb-3">
          {STEPS[currentStep].warningTitle}
        </div>
      )}
      <div ref={scrollRef} className="PresentationWizard scrollDiv">
        {STEPS[currentStep].component}
      </div>

      <div className="PresentationWizard-footer">
        <Divider className="m-0" />
        <div className="PresentationWizard-footer-button">
          <div>
            {currentStep > 0 && (
              <Button
                size="big"
                className="mr-4"
                status="secondary"
                onClick={handleBackStep}
              >
                Back
              </Button>
            )}
            <Button
              variant="link"
              size="big"
              status="error"
              onClick={() => history.push('/community/presentations')}
            >
              Cancel
            </Button>
          </div>

          {currentStep === 5 ? (
            <Button
              className="ml-auto"
              size="big"
              variant="filled"
              onClick={() => {
                handleSaveDraft()
                handleNextStep()
              }}
            >
              Preview
            </Button>
          ) : currentStep === 6 ? (
            isPublished ? (
              <div className="flex ml-auto">
                <Button
                  size="big"
                  variant="filled"
                  onClick={() => handlePublish()}
                >
                  Save
                </Button>
              </div>
            ) : (
              <div className="flex ml-auto">
                <Button
                  className="mr-4"
                  size="big"
                  onClick={() => {
                    history.push('/community/presentations')
                    message.success('Draft saved successfully')
                  }}
                >
                  Save Draft
                </Button>
                <Button
                  size="big"
                  variant="filled"
                  onClick={() => handlePublish()}
                >
                  Publish
                </Button>
              </div>
            )
          ) : (
            <Button
              className="ml-auto"
              size="big"
              variant="filled"
              type="submit"
              onClick={() =>
                validateForm().then((v) => {
                  isEmpty(v)
                    ? handleNextStep()
                    : scrollRef.current.scrollTo(0, 0)
                })
              }
            >
              Next
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default PresentationWizard
