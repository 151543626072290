import * as React from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import { AttachFile, Close, DescriptionOutlined, Send } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import { Upload, message as prompt } from 'antd'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useTracking } from 'react-tracking'
import useMinScreen from '~/hooks/useMinScreen'
import { useMessagesContext } from './context'
import { useRefreshConversations, useSendMessage } from './queries'

const ATTACHMENT_FILE_SIZE_LIMIT_BYTES = 100 * 1024 * 1024

export default function ConversationActions({
  conversationId,
  channelSID,
  disabled,
  disabledReason,
}) {
  const { trackEvent } = useTracking({
    component: 'ConversationActions',
    conversationId,
  })

  const [attachment, setAttachment] = React.useState(null)
  const [message, setMessage] = React.useState('')

  const navigate = useNavigate()
  const { archive, baseUrl } = useMessagesContext()
  const refreshConversations = useRefreshConversations()

  const { sendMessage, isSending } = useSendMessage({
    conversationId,
    channelSID,
  })
  function send() {
    trackEvent({
      eventName: 'click',
      element: 'send',
      isAttachment: attachment !== null,
    })

    sendMessage({
      message,
      attachment,
      onSuccess: () => {
        setMessage('')
        setAttachment(null)

        if (archive) {
          refreshConversations()
          navigate(`${baseUrl}/${conversationId}`, { replace: true })
        }
      },
    })
  }

  const allDisabled = disabled || isSending

  async function selectAttachment(file) {
    if (file.type !== 'application/pdf') {
      prompt.error('Can only send PDF files')
      return false
    }
    if (file.size > ATTACHMENT_FILE_SIZE_LIMIT_BYTES) {
      prompt.error('Cannot upload files over 100MB')
      return false
    }

    const fileUrl = await convertToBase64(file)
    setAttachment({ fileName: file.name, fileUrl })
    trackEvent({
      eventName: 'click',
      element: 'Attach File',
      type: file.type,
      size: file.size,
      name: file.name,
    })

    return false
  }

  function removeAttachment() {
    trackEvent({ eventName: 'click', element: 'Remove Attachment' })
    setAttachment(null)
  }

  return (
    <Layout
      disabled={disabled}
      input={
        isEmpty(attachment) ? (
          <textarea
            className="appearance-none border-none w-full resize-none focus:outline-none type-body-regular-sm bg-transparent"
            rows={2}
            disabled={allDisabled}
            placeholder={
              disabled
                ? disabledReason
                : 'Type a message or select a file to send'
            }
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        ) : (
          <div>
            <AttachedFile
              name={attachment.fileName}
              onRemove={removeAttachment}
            />
          </div>
        )
      }
      actions={
        <>
          <AddAttachment
            disabled={allDisabled || attachment != null || !isEmpty(message)}
            onAddAttachment={selectAttachment}
          />
          <SendButton
            disabled={allDisabled || (!attachment && isEmpty(message))}
            onClick={() => send()}
          />
        </>
      }
    />
  )
}

ConversationActions.propTypes = {
  conversationId: PropTypes.number.isRequired,
  channelSID: PropTypes.string,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
}

function SendButton({ disabled, onClick }) {
  const isDesktop = useMinScreen('md')

  return isDesktop ? (
    <IconButton
      variant="filled"
      label="Send"
      icon={<Send />}
      disabled={disabled}
      onClick={onClick}
    />
  ) : (
    <Button
      variant="filled"
      disabled={disabled}
      iconLeft={<Send />}
      onClick={onClick}
    >
      Send
    </Button>
  )
}

function Layout({ input, actions, disabled }) {
  return (
    <div className="flex flex-col md:flex-row items-end gap-4 w-full pb-6 px-4">
      <div
        className={cx(
          'w-full md:w-auto md:flex-1 flex flex-col rounded border p-3 gap-2',
          disabled ? 'bg-grey-100 border-grey-400' : 'border-grey-600'
        )}
      >
        {input}
      </div>
      <div className="flex-shrink-0 flex gap-2">{actions}</div>
    </div>
  )
}

function AddAttachment({ disabled, onAddAttachment }) {
  return (
    <Upload
      disabled={disabled}
      accept=".pdf,application/pdf"
      showUploadList={false}
      beforeUpload={onAddAttachment}
    >
      <Tooltip text="Attach PDF Document" placement="top">
        <IconButton
          disabled={disabled}
          label="Attach File"
          icon={<AttachFile />}
        />
      </Tooltip>
    </Upload>
  )
}

function AttachedFile({ name, onRemove }) {
  return (
    <div className="bg-secondary-2 border border-secondary-5 inline-flex items-center px-2 gap-2 text-secondary-100">
      <DescriptionOutlined size={20} />
      <span className="type-body-semibold-sm">{name}</span>
      <IconButton
        variant="link"
        status="secondary"
        label="Remove"
        icon={<Close />}
        onClick={onRemove}
      />
    </div>
  )
}

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

function Loading() {
  return (
    <Layout
      input={
        <textarea
          className="appearance-none border-none w-full resize-none focus:outline-none type-body-regular-sm"
          rows={2}
          disabled
          placeholder="Loading..."
        />
      }
      actions={
        <>
          <AddAttachment disabled />
          <SendButton disabled />
        </>
      }
    />
  )
}

ConversationActions.Loading = Loading
