import PropTypes from 'prop-types'
import {
  Axis,
  AxisLabel,
  Chart,
  ChartTooltip,
  Grid,
  ScatterSeries,
  useAxis,
  useChart,
  useChartTooltipState,
  useScatterSeries,
} from '@context365/charts'
import { useTheme } from '@context365/config'
import { format } from 'd3-format'
import { differenceInMonths, format as formatDate, parse } from 'date-fns'

const formatCurrency = format('$,.2~f')
const formatAUM = (aum) => `${formatCurrency(aum)}M`

const formatPercent = format('.2~%')

const now = new Date()
const parseDate = (date) => parse(date, 'yyyy-MM-dd', now)
function getAgeInMonths(dataPoint) {
  const date = parseDate(dataPoint.date)
  return differenceInMonths(now, date)
}
const ageRange = [1, 0.2]
const ageDomain = [1, 61]

function formatReturnDate(dataPoint) {
  const date = parseDate(dataPoint.date)
  return formatDate(date, "MMM d',' yyyy")
}

const FundReturnAumChart = ({ fundReturnAum, width }) => {
  const dataPointColor = useTheme('colors.brand.100')
  const axisTextColor = useTheme('colors.grey.500')
  const gridLineColor = useTheme('colors.grey.300')

  const chart = useChart({
    data: fundReturnAum,
    height: 400,
    width,
    padding: {
      left: 64,
      right: 12,
      top: 4,
      bottom: 40,
    },
  })

  const aum = useAxis({
    type: 'linear',
    getValue: (d) => d.x,
    formatValue: formatAUM,
    range: chart.range.horizontal,
  })
  const fundReturn = useAxis({
    type: 'linear',
    getValue: (d) => d.y,
    formatValue: formatPercent,
    range: chart.range.vertical,
  })
  const age = useAxis({
    type: 'linear',
    clamp: true,
    getValue: getAgeInMonths,
    range: ageRange,
    domain: ageDomain,
  })

  const scatter = useScatterSeries({
    chart,
    x: aum,
    y: fundReturn,
    r: 4,
    getDataPointKey: (d) => d.date,
    series: {
      key: 'aum',
      getDataset: (d) => d.datasets[0].data,
      color: dataPointColor,
      opacity: age.getCoordinate,
    },
  })

  const tooltip = useChartTooltipState(chart, scatter)

  if (!fundReturnAum?.datasets) {
    return null
  }

  return (
    <div style={{ paddingTop: '40px' }}>
      <div className="ChartLabel">
        <span>Fund Returns vs Fund AUM</span>
      </div>
      <Chart {...chart.getChartProps()}>
        <Grid
          {...scatter.getGridProps('horizontal')}
          tickCount={8}
          color={gridLineColor}
        />
        <Axis
          {...scatter.getAxisProps('left')}
          tickCount={8}
          lineColor="none"
          textColor={axisTextColor}
          fontSize={12}
        />
        <Axis
          {...scatter.getAxisProps('bottom')}
          tickCount={4}
          lineColor={gridLineColor}
          textColor={axisTextColor}
          fontSize={12}
          domainLineWidth={2}
          tickLengthOuter={0}
        />
        <AxisLabel chart={chart} position="left" className="text-grey-600">
          {fundReturnAum.yAxisLabel}
        </AxisLabel>
        <AxisLabel chart={chart} position="bottom" className="text-grey-600">
          {fundReturnAum.xAxisLabel}
        </AxisLabel>
        <ScatterSeries series={scatter.series} />
      </Chart>
      <Legend getOpacity={age.getValue} dataPointColor={dataPointColor} />
      <Tooltip
        formatReturn={fundReturn.format}
        formatAUM={aum.format}
        formatDate={formatReturnDate}
        dataPointColor={dataPointColor}
        {...tooltip}
      />
    </div>
  )
}

FundReturnAumChart.propTypes = {
  fundReturnAum: PropTypes.shape({
    xAxisLabel: PropTypes.string.isRequired,
    yAxisLabel: PropTypes.string.isRequired,
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            x: PropTypes.number.isRequired,
            y: PropTypes.number.isRequired,
            date: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  }),
  width: PropTypes.number,
}

export default FundReturnAumChart

const legendItems = [
  [0, 'Last Month'],
  [1, '1 Year Ago'],
  [2, '2 Years Ago'],
  [3, '3 Years Ago'],
  [4, '4 Years Ago'],
  [5, '5 or More Years Ago'],
].reverse()

function Legend({ getOpacity, dataPointColor }) {
  return (
    <div className="flex space-x-8 justify-center items-center text-sm">
      <span>Date of Return:</span>
      {legendItems.map(([years, label]) => (
        <div key={years} className="flex space-x-2 items-center">
          <div
            className="h-2 w-2 rounded-full"
            style={{
              backgroundColor: dataPointColor,
              opacity: getOpacity(years * 12 + 1),
            }}
          />
          <span>{label}</span>
        </div>
      ))}
    </div>
  )
}

function Tooltip({
  formatAUM,
  formatReturn,
  formatDate,
  dataPointColor,
  ...tooltipProps
}) {
  return (
    <ChartTooltip {...tooltipProps} unstable_portal={false}>
      {(_, activeDataPoints) => (
        <div className="space-y-2">
          {activeDataPoints.map((d) => (
            <div key={d.key} className="flex space-x-2">
              <div
                className="h-2 w-2 rounded-full mt-1"
                style={{ backgroundColor: dataPointColor }}
              />
              <div>
                <ul className="list-none p-0 m-0">
                  <li>Fund: {formatReturn(d.dataPoint)}</li>
                  <li>AUM: {formatAUM(d.dataPoint)}</li>
                  <li>Date: {formatDate(d.dataPoint)}</li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
    </ChartTooltip>
  )
}
