import { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import {
  updateAllMeetingsCompanyEvent,
  updateAllSummitsMeetings,
} from '~/actions/meetings'
import Loading from '../Loading'

const CancelMeetingButton = ({
  visible,
  closeModal,
  isSummit,
  companyEventId,
  onDeclineAll,
}) => {
  const [decliningInProgress, setDecliningInProgress] = useState(false)

  const declineMeetings = () => {
    setDecliningInProgress(true)

    if (isSummit && companyEventId > -1) {
      updateAllMeetingsCompanyEvent(companyEventId, 3)
        .then((res) => {
          if (res.status === 200) {
            message.success('Meetings were declined successfully')
            setDecliningInProgress(false)
            onDeclineAll()
            closeModal()
          } else {
            setDecliningInProgress(false)
            message.error('An error occurred while trying to decline meetings')
          }
        })
        .catch(() => {
          setDecliningInProgress(false)
          message.error('An error occurred while trying to decline meetings')
        })
    } else if (isSummit) {
      updateAllSummitsMeetings(3)
        .then((res) => {
          if (res.status === 200) {
            message.success('Meetings were declined successfully')
            setDecliningInProgress(false)
            onDeclineAll()
            closeModal()
          } else {
            setDecliningInProgress(false)
            message.error('An error occurred while trying to decline meetings')
          }
        })
        .catch(() => {
          setDecliningInProgress(false)
          message.error('An error occurred while trying to decline meetings')
        })
    }
  }

  return (
    <Modal
      className="cc-modal-accept"
      title="Decline All Meeting Requests"
      visible={visible}
      okText="Decline All"
      cancelText="Close"
      onOk={() => declineMeetings()}
      onCancel={() => closeModal()}
      okButtonProps={{
        style: { backgroundColor: '#D62B34', borderStyle: 'none' },
      }}
    >
      <Loading spinning={decliningInProgress}>
        {isSummit && companyEventId > -1 ? (
          <p>
            Are you sure you want to decline all meeting requests for this
            summit?
          </p>
        ) : (
          <p>Are you sure you want to decline all summit meeting requests?</p>
        )}
      </Loading>
    </Modal>
    // </div>
  )
}

CancelMeetingButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isSummit: PropTypes.bool.isRequired,
  companyEventId: PropTypes.number,
  onDeclineAll: PropTypes.func.isRequired,
}

export default CancelMeetingButton
