import * as React from 'react'
import Loading from '~/components/Loading'
import { useOnboardingQuery } from '../hooks'
import { useOnboardingContext } from '../OnboardingContext'
import {
  BackButton,
  NextButton,
  OnboardingFooter,
  OnboardingLayout,
  OnboardingSteps,
} from './OnboardingLayout'

export function OnboardingStep({
  steps,
  form,
  title,
  formKey,
  getSavedAnswers,
  saveAnswers,
}) {
  const { onNext } = useOnboardingContext()
  const { answers, isLoading, submit } = useOnboardingQuery([formKey], {
    getAnswers: getSavedAnswers,
    saveAnswers,
  })

  if (isLoading) {
    return (
      <OnboardingLayout
        header={<OnboardingSteps steps={steps} />}
        body={
          <div className="flex items-center justify-center">
            <Loading />
          </div>
        }
      />
    )
  }

  return (
    <OnboardingLayout
      header={<OnboardingSteps steps={steps} />}
      body={
        <FormContainer
          title={title}
          id={formKey}
          savedAnswers={answers}
          onSubmit={submit}
          onSuccess={onNext}
        >
          {form}
        </FormContainer>
      }
      footer={
        <OnboardingFooter
          leftContent={<BackButton />}
          rightContent={<NextButton type="submit" form={formKey} />}
        />
      }
    />
  )
}

function FormContainer({ children, title, ...formProps }) {
  return (
    <div className="mt-12">
      <div className="type-header-md text-center mb-8">{title}</div>
      {React.cloneElement(children, formProps)}
    </div>
  )
}
