import * as React from 'react'

export default function useFrameState(initialState) {
  const frame = React.useRef(0)
  const [state, setState] = React.useState(initialState)

  const setFrameState = React.useCallback((nextValue) => {
    window.cancelAnimationFrame(frame.current)

    frame.current = window.requestAnimationFrame(() => {
      setState(nextValue)
    })
  }, [])

  React.useEffect(() => {
    // if there's still a pending setState call waiting when we
    // unmount, cancel it
    return () => {
      window.cancelAnimationFrame(frame.current)
    }
  }, [])

  return [state, setFrameState]
}
