import PropTypes from 'prop-types'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Space } from 'antd'
import find from 'lodash/find'
import './DiscoverFilters.less'

const SelectFundFilter = ({ selectedFund, funds, onChangeFund }) => {
  if (funds.length < 2) {
    return null
  }

  const menu = (
    <Menu
      onClick={({ key }) => {
        onChangeFund(parseInt(key, 10))
      }}
    >
      {funds.map((fund) => (
        <Menu.Item key={fund.fundId}>{fund.name}</Menu.Item>
      ))}
    </Menu>
  )

  return (
    <div className="DiscoverContainer-text mb-8">
      <Space wrap>
        Fund:
        <Dropdown
          overlay={menu}
          style={{ width: '100%', marginBottom: '20px' }}
        >
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            {find(funds, { fundId: selectedFund }).name} <DownOutlined />
          </a>
        </Dropdown>
      </Space>
    </div>
  )
}
SelectFundFilter.propTypes = {
  selectedFund: PropTypes.number,
  funds: PropTypes.array,
  onChangeFund: PropTypes.func,
}

export default SelectFundFilter
