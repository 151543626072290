import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'
import AllocatorDetailsModal from '../AllocatorDetailsModal'
import DiscoverAllocatorMeetingRequest from '../DiscoverAllocatorMeetingRequest'
import MeetingRequestCfn from '../MeetingRequestCFN'
import MeetingTypeSelector from '../MeetingTypeSelector'

const AllocatorDetailsModalContainer = ({
  allocator,
  showAllocatorProfile,
  onClose,
  selectedFund,
  representedFund,
  roadshowId = 0,
  fromMapView = false,
  coordinates = [],
  arePointsNear,
  canSendMeetingRequest = true,
  campaignApplicationId = null,
}) => {
  const [isRequestMeetingModalVisible, setIsRequestMeetingModalVisible] =
    useState(false)
  const [showMeetingTypeSelectorModal, setShowMeetingTypeSelectorModal] =
    useState(false)
  const [selectedMeetingTypeId, setSelectedMeetingTypeId] = useState(3)
  const loadingRoadshowList = useSelector((state) => state.roadshow.loading)
  const roadshowList = useSelector((state) => state.roadshow.roadshowList)
  const role = useSelector((state) => state.auth.role)

  const { showRoadshow } = useFlags()

  const hasRoadshows =
    roadshowId > 0 ||
    (fromMapView && !isEmpty(roadshowList) && !loadingRoadshowList)

  const handleSendRequest = useCallback(() => {
    if (
      showRoadshow &&
      (roadshowId > 0 ||
        (fromMapView && !isEmpty(roadshowList) && !loadingRoadshowList))
    )
      setShowMeetingTypeSelectorModal(true)
    else setIsRequestMeetingModalVisible(true)
  }, [fromMapView, loadingRoadshowList, roadshowId, roadshowList, showRoadshow])

  const handleCancelSendMeetingReq = useCallback(() => {
    setIsRequestMeetingModalVisible(false)
    setSelectedMeetingTypeId(3)
  }, [])

  const handleRefreshofSendMeetingReq = useCallback(() => {
    onClose()
  }, [onClose])

  const handleMeetingTypeIdChange = useCallback((e) => {
    setSelectedMeetingTypeId(e.target.value)
  }, [])
  const handleSaveMeetingTypeId = useCallback(() => {
    setShowMeetingTypeSelectorModal(false)
    setIsRequestMeetingModalVisible(true)
  }, [])

  return (
    <>
      {showAllocatorProfile && !isNil(allocator) && (
        <AllocatorDetailsModal
          company={allocator}
          cancel={onClose}
          fundId={selectedFund}
          sendMeetingRequest={handleSendRequest}
          visible={showAllocatorProfile && !isNil(allocator)}
          skipValidation={hasRoadshows && fromMapView && showRoadshow}
          includeRoadshowMeetings={fromMapView && showRoadshow}
          canSendMeetingRequest={canSendMeetingRequest}
          campaignApplicationId={campaignApplicationId}
        />
      )}

      {isRequestMeetingModalVisible &&
        (role === 'CFN' ? (
          <MeetingRequestCfn
            visible={isRequestMeetingModalVisible}
            onClose={handleCancelSendMeetingReq}
            cfnCompany={allocator}
            onRefresh={handleRefreshofSendMeetingReq}
          />
        ) : (
          <DiscoverAllocatorMeetingRequest
            visible={isRequestMeetingModalVisible}
            onClose={handleCancelSendMeetingReq}
            onRefresh={handleRefreshofSendMeetingReq}
            allocator={allocator}
            fund={representedFund}
            selectedFundId={selectedFund}
            roadshowId={roadshowId}
            roadshowList={roadshowList}
            meetingTypeId={selectedMeetingTypeId}
            fromMapView={fromMapView}
            coordinates={coordinates}
            arePointsNear={arePointsNear}
          />
        ))}
      {fromMapView && showRoadshow && (
        <MeetingTypeSelector
          isVisible={showMeetingTypeSelectorModal}
          onClose={() => {
            setShowMeetingTypeSelectorModal(false)
          }}
          meetingTypeId={selectedMeetingTypeId}
          setMeetingTypeId={handleMeetingTypeIdChange}
          onOk={handleSaveMeetingTypeId}
        />
      )}
    </>
  )
}

AllocatorDetailsModalContainer.propTypes = {
  allocator: PropTypes.object.isRequired,
  showAllocatorProfile: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedFund: PropTypes.number,
  representedFund: PropTypes.string,
  roadshowId: PropTypes.number,
  fromMapView: PropTypes.bool,
  coordinates: PropTypes.array,
  arePointsNear: PropTypes.func.isRequired,
  canSendMeetingRequest: PropTypes.bool,
  campaignApplicationId: PropTypes.number,
}

export default AllocatorDetailsModalContainer
