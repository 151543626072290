import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { rescheduleOnlineMeeting } from '~/actions/contextMeetings'
import Loading from '../Loading'
import SelectOnlineMeetingDateTime from '../SelectOnlineMeetingDateTime'
import './RescheduleMeeting.less'

const RescheduleMeeting = ({
  onlineMeetingId,
  visible,
  closeModal,
  onRescheduleMeeting,
  participants,
  toParticipants,
  meetingDateTime,
  meetingDuration,
  roadshowId = 0,
}) => {
  const [reschedulingInProgress, setReschedulingInProgress] = useState(false)
  const [selectedDateTime, setSelectedDateTime] = useState({})
  const [duration, setDuration] = useState()
  const [selectedHour, setSelectedHour] = useState({})

  const onDurationChanged = (value) => {
    setDuration(value)
  }

  useEffect(() => {
    setDuration(meetingDuration || 30)
  }, [meetingDuration])

  const onModalClosed = useCallback(() => {
    closeModal()
    setSelectedDateTime({})
    setDuration(30)
    setSelectedHour({})
  }, [closeModal])

  const onTimeChanged = (changed, newDate) => {
    if (changed === 'date') {
      setSelectedHour({})
    }
    if (changed === 'hour') {
      setSelectedHour(newDate)
    }
    if (!isNil(newDate)) {
      setSelectedDateTime({
        startDateTime: newDate.startTime,
        endDateTime: newDate.endTime,
      })
    }
  }

  const handleModalOk = useCallback(() => {
    setReschedulingInProgress(true)
    rescheduleOnlineMeeting(
      onlineMeetingId,
      selectedDateTime.startDateTime,
      duration
    )
      .then(() => {
        message.success('The meeting has been rescheduled.')
        onModalClosed()
        onRescheduleMeeting(selectedDateTime.startDateTime)
      })
      .finally(() => {
        setReschedulingInProgress(false)
      })
  }, [
    onModalClosed,
    duration,
    onRescheduleMeeting,
    onlineMeetingId,
    selectedDateTime.startDateTime,
  ])

  const isRescheduleDisabled =
    isEmpty(selectedDateTime) || isEmpty(selectedHour)
  return (
    <Modal
      destroyOnClose
      className="cc-modal-reschedule"
      title="Reschedule the Meeting"
      visible={visible}
      okText="Reschedule the Meeting"
      onOk={handleModalOk}
      onCancel={onModalClosed}
      okButtonProps={{
        loading: reschedulingInProgress,
        disabled: isRescheduleDisabled,
        size: 'large',
        style: {
          marginLeft: '24px',
          backgroundColor: isRescheduleDisabled ? 'auto' : '#F19F00',
          borderStyle: 'none',
        },
      }}
      cancelButtonProps={{ disabled: false, size: 'large' }}
      width="758px"
      bodyStyle={{ padding: '30px' }}
    >
      <Loading spinning={reschedulingInProgress}>
        <SelectOnlineMeetingDateTime
          onTimeChanged={onTimeChanged}
          onDurationChanged={onDurationChanged}
          toParticipants={toParticipants}
          participants={participants}
          dateTime={meetingDateTime}
          meetingDuration={meetingDuration}
          roadshowId={roadshowId}
        />
      </Loading>
    </Modal>
  )
}

RescheduleMeeting.propTypes = {
  visible: PropTypes.bool.isRequired,
  onlineMeetingId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  onRescheduleMeeting: PropTypes.func.isRequired,
  participants: PropTypes.array,
  toParticipants: PropTypes.array,
  meetingDateTime: PropTypes.string,
  meetingDuration: PropTypes.number,
  roadshowId: PropTypes.number,
}

export default RescheduleMeeting
