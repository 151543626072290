import { createContext, useContext, useEffect, useState } from 'react'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { ENVIRONMENT_NAME, NOTIFICATIONS_HUB_URL, StorageKeys } from '~/config'
import useLiveRef from '~/hooks/useLiveRef'

const logLevel =
  ENVIRONMENT_NAME === 'Local' ? LogLevel.Debug : LogLevel.Warning

export const SignalrContext = createContext({ connection: null })

const SignalrProvider = ({ children }) => {
  const [connection] = useState(() =>
    new HubConnectionBuilder()
      .configureLogging(logLevel)
      .withUrl(NOTIFICATIONS_HUB_URL, {
        accessTokenFactory: () => localStorage.getItem(StorageKeys.accessToken),
      })
      .withAutomaticReconnect()
      .build()
  )

  useEffect(() => {
    connection.start().then(() => {
      console.log('[SignalrProvider.js]::Connection started')
    })

    return () => {
      connection.stop().then(() => {
        console.log('[SignalrProvider.js]::Connection stopped')
      })
    }
  }, [connection])

  return (
    <SignalrContext.Provider value={{ connection }}>
      {children}
    </SignalrContext.Provider>
  )
}

export const useSignalr = (eventName, eventHandler) => {
  const eventHandlerRef = useLiveRef(eventHandler)
  const { connection } = useContext(SignalrContext)

  useEffect(() => {
    const onUpdate = (...args) => eventHandlerRef.current(...args)
    connection.on(eventName, onUpdate)

    return () => {
      connection.off(eventName, onUpdate)
    }
  }, [connection, eventName, eventHandlerRef])
}

export default SignalrProvider
