import { Button } from '@context365/button'
import { Checkbox } from '@context365/forms'
import { ChevronLeft } from '@context365/icons'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useMandateWizardContext } from '../MandateWizardContext'

const CommonQuestions = ({ isLastStep }) => {
  const {
    goToNextStep: onNextStep,
    goToPreviousStep: onPrevStep,
    commonQuestions,
    selectedCommonQuestions: selected,
    setSelectedCommonQuestions: setSelected,
    stepNumbers,
    invalidAnswers,
    handleSubmit: onSubmit,
  } = useMandateWizardContext()

  const submitDisabled = !isEmpty(
    invalidAnswers.filter((a) => stepNumbers.includes(a.stepNumber))
  )

  const toggleQuestionSelection = (key) => {
    if (selected.includes(key)) {
      setSelected(selected.filter((k) => k !== key))
    } else {
      setSelected([...selected, key])
    }
  }

  return (
    <div>
      <div className="type-body-regular-lg">Common Questions</div>
      <div className="type-body-regular-sm mt-4 mb-8">
        Choose just a few key questions you require fund managers to answer.
        Recommended amount: 3-5.
      </div>
      <div className="space-y-8">
        {map(
          groupBy(commonQuestions, (q) => q.category),
          (questions, category) => (
            <div key={category} className="flex flex-col space-y-4">
              {category && category !== 'null' && (
                <div className="type-subtitle-xs">{category}</div>
              )}
              {map(questions, (question) => (
                <Checkbox
                  key={question.campaignApplicationQuestionId}
                  label={question.text}
                  checked={includes(
                    selected,
                    question.campaignApplicationQuestionId
                  )}
                  onChange={() =>
                    toggleQuestionSelection(
                      question.campaignApplicationQuestionId
                    )
                  }
                />
              ))}
            </div>
          )
        )}
      </div>
      <div className="flex flex-row justify-between mt-8">
        <Button variant="link" iconLeft={<ChevronLeft />} onClick={onPrevStep}>
          Back
        </Button>
        {isLastStep ? (
          <Button
            variant="filled"
            type="submit"
            onClick={onSubmit}
            disabled={submitDisabled}
          >
            Submit
          </Button>
        ) : (
          <Button variant="filled" onClick={onNextStep}>
            Next
          </Button>
        )}
      </div>
    </div>
  )
}

export default CommonQuestions
