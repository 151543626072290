import { BASE_URL, StorageKeys } from '~/config'
/* eslint-disable no-console */
import SignalR from './signalR'

export default class NotifyHub extends SignalR {
  constructor() {
    const accessToken = localStorage.getItem(StorageKeys.accessToken)
    const notifyHubURL =
      BASE_URL.slice(-1) === '/'
        ? `${BASE_URL}notifyHub`
        : `${BASE_URL}/notifyHub`
    super(notifyHubURL, { accessTokenFactory: () => accessToken })
  }

  async notifyNextMeeting(
    meetingId,
    contactId,
    handleNotifyNextMeeting,
    handleNotifyDelayForCompany
  ) {
    const isWithAuthentication = true
    await this.subscribe(isWithAuthentication)
      .then(() =>
        this.notifyHubConnection.invoke(
          'SubscribeToNotifyHub',
          meetingId,
          contactId
        )
      )
      .then((connectionId) => {
        this.connectionId = connectionId
        console.log(`Subscribed to NotifyHub with connection: ${connectionId}`)
      })
      .catch((err) => {
        console.error(`SignalR startup error! ${err}`)
      })

    this.notifyHubConnection.on('NotifyNextMeeting', (data) => {
      handleNotifyNextMeeting(data)
    })

    this.notifyHubConnection.on(
      'NotifyDelayForCompany',
      (companyName, delay, contactName) => {
        handleNotifyDelayForCompany(companyName, delay, contactName)
      }
    )
  }

  notifyDelay(meetingId, contactId, timeDelay) {
    return this.notifyHubConnection.invoke(
      'NotifyOtherMeetingParticipantForDelay',
      meetingId,
      contactId,
      timeDelay
    )
  }
}
