import PropTypes from 'prop-types'
import { Avatar } from '@context365/avatar'
import { Button } from '@context365/button'
import { Attachment, Done, DoneAll, Icon } from '@context365/icons'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import { useDispatch } from 'react-redux'
import { useTracking } from 'react-tracking'
import { showContactProfileModal } from '~/store/modals'
import { getInitials } from '~/utils'
import { useMessageSearchResults } from '../useMessageSearch'
import { format, parseDate } from '../utils/dateFormatters'

export function SentMessage({
  messageID,
  timestamp,
  pending,
  readStatus,
  message,
}) {
  const { searchResults, register } = useMessageSearchResults(messageID)
  const chunks = createChunks(message, searchResults)

  return (
    <div
      className="py-2 flex flex-row-reverse items-start gap-2"
      {...register()}
    >
      <div className="text-right" style={{ maxWidth: '80%' }}>
        <div className="bg-primary-100 text-white py-2 px-3 rounded-big rounded-br-none whitespace-pre-line">
          {renderChunks(chunks, 'bg-primary-90', 'bg-primary-2 text-black')}
        </div>
        <div className="type-body-regular-xs text-grey-500 inline-flex items-center gap-1">
          {readStatus && (
            <Icon as={readStatus === 'read' ? DoneAll : Done} size={16} />
          )}
          {pending ? 'Sending...' : formatMessageTimestamp(timestamp)}
        </div>
      </div>
    </div>
  )
}

SentMessage.propTypes = {
  messageID: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  pending: PropTypes.bool,
  readStatus: PropTypes.oneOf(['read', 'unread']),
  message: PropTypes.string.isRequired,
}

export function ReceivedMessage({
  messageID,
  timestamp,
  authorContact,
  message,
}) {
  const { searchResults, register } = useMessageSearchResults(messageID)
  const chunks = createChunks(message, searchResults)

  return (
    <div className="py-2 flex items-start gap-2" {...register()}>
      <div className="w-7 flex-shrink-0">
        {authorContact && (
          <ContactAvatar
            contactId={authorContact.contactId}
            contactName={authorContact.contactName}
            imageUrl={authorContact.imageUrl}
          />
        )}
      </div>
      <div style={{ maxWidth: '80%' }}>
        {authorContact && (
          <ContactName
            contactId={authorContact.contactId}
            contactName={authorContact.contactName}
          />
        )}
        <div className="bg-grey-100 py-2 px-3 rounded-big rounded-tl-none">
          {renderChunks(chunks, 'bg-primary-5', 'bg-primary-100 text-white')}
        </div>
        <div className="type-body-regular-xs text-grey-500">
          {formatMessageTimestamp(timestamp)}
        </div>
      </div>
    </div>
  )
}

ReceivedMessage.propTypes = {
  messageID: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  authorContact: PropTypes.shape({
    contactId: PropTypes.number.isRequired,
    contactName: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
  }),
  message: PropTypes.string.isRequired,
}

function AttachmentMessage({
  messageID,
  className,
  timestamp,
  pending,
  fileName,
  onDownload,
}) {
  const { searchResults, register } = useMessageSearchResults(messageID)
  const chunks = createChunks(fileName, searchResults)

  return (
    <div className={cx(className, 'flex items-start gap-2')} {...register()}>
      <div style={{ maxWidth: '80%' }}>
        <div className="rounded-big border text-center">
          <div className="pt-3 pb-4 px-6">
            <Attachment className="text-primary-100" size={36} />
            <div className="type-body-regular-sm break-all">
              {renderChunks(
                chunks,
                'bg-primary-5',
                'bg-primary-100 text-white'
              )}
            </div>
          </div>
          <div className="border-b" role="separator" />
          <Button disabled={pending} variant="link" onClick={onDownload}>
            Download
          </Button>
        </div>
        <div className="type-body-regular-xs text-grey-500 inline-flex items-center gap-1">
          {timestamp}
        </div>
      </div>
    </div>
  )
}

export function SentAttachment({
  messageID,
  timestamp,
  pending,
  readStatus,
  fileName,
  onDownload,
}) {
  return (
    <AttachmentMessage
      className="py-2 flex-row-reverse text-right"
      messageID={messageID}
      fileName={fileName}
      onDownload={onDownload}
      timestamp={
        <>
          {readStatus && (
            <Icon as={readStatus === 'read' ? DoneAll : Done} size={16} />
          )}
          {pending ? 'Sending...' : formatMessageTimestamp(timestamp)}
        </>
      }
      pending={pending}
    />
  )
}

SentAttachment.propTypes = {
  messageID: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  pending: PropTypes.bool,
  readStatus: PropTypes.oneOf(['read', 'unread']),
  fileName: PropTypes.string.isRequired,
  onDownload: PropTypes.func.isRequired,
}

export function ReceivedAttachment({
  messageID,
  timestamp,
  authorContact,
  fileName,
  onDownload,
}) {
  return (
    <div className="py-2 flex items-start gap-2">
      <div className="w-7 flex-shrink-0">
        {authorContact && (
          <ContactAvatar
            contactId={authorContact.contactId}
            contactName={authorContact.contactName}
            imageUrl={authorContact.imageUrl}
          />
        )}
      </div>
      <div className="flex-1">
        {authorContact && (
          <ContactName
            contactId={authorContact.contactId}
            contactName={authorContact.contactName}
          />
        )}
        <AttachmentMessage
          messageID={messageID}
          fileName={fileName}
          onDownload={onDownload}
          timestamp={formatMessageTimestamp(timestamp)}
        />
      </div>
    </div>
  )
}

ReceivedAttachment.propTypes = {
  messageID: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  authorContact: PropTypes.shape({
    contactId: PropTypes.number.isRequired,
    contactName: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
  }),
  fileName: PropTypes.string.isRequired,
  onDownload: PropTypes.func.isRequired,
}

function ContactAvatar({ contactId, contactName, imageUrl }) {
  const { trackEvent } = useTracking({ component: 'MessageContactAvatar' })
  const dispatch = useDispatch()

  return (
    <Button
      as="div"
      variant="none"
      className="cursor-pointer"
      onClick={() => {
        trackEvent({ eventName: 'click', contactId })
        dispatch(showContactProfileModal({ contactId }))
      }}
    >
      <Avatar
        size="small"
        initials={getInitials(contactName)}
        profileImageUrl={imageUrl}
      />
    </Button>
  )
}

function ContactName({ contactId, contactName }) {
  const { trackEvent } = useTracking({ component: 'MessageContactName' })
  const dispatch = useDispatch()

  return (
    <Button
      as="div"
      variant="none"
      className="type-body-semibold-xs border-none cursor-pointer"
      onClick={() => {
        trackEvent({ eventName: 'click', contactId })
        dispatch(showContactProfileModal({ contactId }))
      }}
    >
      {contactName}
    </Button>
  )
}

function formatMessageTimestamp(timestamp) {
  return format.messageTimestamp(parseDate.fromUTC(timestamp))
}

function createChunks(message, searchResults) {
  if (isEmpty(searchResults)) {
    return [message]
  }

  const chunks = []
  searchResults
    .sort((a, b) => a.start - b.start)
    .forEach((result, i) => {
      chunks.push(
        message.substring(searchResults[i - 1]?.end ?? 0, result.start)
      )
      chunks.push({
        text: message.substring(result.start, result.end),
        isCurrent: result.isCurrent,
      })
    })
  chunks.push(message.substring(last(searchResults).end))
  return chunks
}

function renderChunks(chunks, highlightClass, currentClass) {
  return chunks.map((chunk, i) =>
    typeof chunk === 'string' ? (
      chunk
    ) : (
      <span key={i} className={chunk.isCurrent ? currentClass : highlightClass}>
        {chunk.text}
      </span>
    )
  )
}
