import * as React from 'react'
import { Collapse, Row } from 'antd'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

const { Panel } = Collapse

const filterKey = (filter) =>
  [filter.discoverFilterId, filter.questionType].join(':')

const GroupHeader = ({ children }) => (
  <Row type="flex" style={{ alignItems: 'stretch', maxWidth: 180, width: 180 }}>
    <div style={{ width: '80%' }}>{children}</div>
  </Row>
)

const FilterGroup = ({ filters, getFilterComponent, ...props }) => {
  if (isEmpty(filters)) {
    return null
  } else if (filters.length === 1) {
    return map(filters, (filter) => (
      <React.Fragment key={filterKey(filter)}>
        <div className="DiscoverFilters-SoloFilter">
          {filter.questionGroup}
          {getFilterComponent(filter, false)}
        </div>
      </React.Fragment>
    ))
  } else {
    return (
      <Panel
        {...props}
        className="DiscoverFilters-CollapsePanel"
        header={<GroupHeader>{filters[0].questionGroup}</GroupHeader>}
      >
        {map(filters, (filter) => (
          <React.Fragment key={filterKey(filter)}>
            {getFilterComponent(filter, true)}
          </React.Fragment>
        ))}
      </Panel>
    )
  }
}

export default FilterGroup
