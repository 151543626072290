import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Exposure, FolderOpenOutlined, OpenInNew } from '@context365/icons'
import isNil from 'lodash/isNil'
import { Link } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import { ApplyToMandateButton, ViewApplicationButton } from '../Application'

const DiscoverMandateResultFooter = ({
  mandate,
  canApplyToMandate,
  onShowApplyToMandateModal,
  onShowAddToListModal,
  fromPreview = false,
}) => {
  const { trackEvent } = useTracking()

  return (
    <div className="flex flex-row justify-between">
      <div className="justify-start">
        {!fromPreview && (
          <Button
            className="color-secondary-90"
            status="secondary"
            iconLeft={<Exposure />}
            onClick={() => onShowAddToListModal()}
          >
            Lists
          </Button>
        )}
      </div>
      <div className="justify-end space-x-2">
        {fromPreview || isNil(mandate.documentAddMean) ? null : (
          <Button
            as={Link}
            to={`/mandate/${mandate.mandateId}?tab=documentation`}
            iconLeft={<FolderOpenOutlined />}
            onClick={() => {
              trackEvent({
                eventName: 'click',
                element: 'Mandate Open Documentation',
              })
            }}
          >
            Open Dataroom
          </Button>
        )}
        {!fromPreview && (
          <ViewApplicationButton
            campaignEntity={{
              ...mandate,
              campaignId: mandate.campaignId,
              campaignName: mandate.title,
            }}
          />
        )}
        {canApplyToMandate && !fromPreview && (
          <ApplyToMandateButton
            mandate={mandate}
            onApply={(fundId) => {
              onShowApplyToMandateModal(fundId)
            }}
          />
        )}
        {fromPreview && (
          <Button
            variant="filled"
            as="a"
            href={`/discover/mandates?search=${mandate.title}&selectedId=${mandate.mandateId}`}
            target="_blank"
            iconRight={<OpenInNew />}
          >
            Open
          </Button>
        )}
      </div>
    </div>
  )
}

DiscoverMandateResultFooter.propTypes = {
  mandate: PropTypes.object.isRequired,
  canApplyToMandate: PropTypes.bool.isRequired,
  onShowApplyToMandateModal: PropTypes.func.isRequired,
  onShowAddToListModal: PropTypes.func.isRequired,
  fromPreview: PropTypes.bool,
}

export default DiscoverMandateResultFooter
