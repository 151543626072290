import PropTypes from 'prop-types'
import { DetailedAvatar } from '@context365/avatar'
import CardButton from '~/components/CardButton'
import { getInitials } from '~/utils'

const ProfilePreview = ({ connection }) => {
  return (
    <CardButton
      href={`/profile/contact/${connection.contactId}`}
      target="_blank"
      className={connection.canView ? 'shadow-0 border' : ''}
      disabled={!connection.canView}
    >
      <DetailedAvatar
        initials={getInitials(connection.contactName)}
        name={connection.contactName}
        profileImageUrl={connection.imageUrl}
        company={connection.jobTitle}
        size="medium"
        className="p-2"
      />
    </CardButton>
  )
}

const ContactProfileConnections = ({ connections }) => (
  <div className="p-3 grid grid-cols-1 lg:grid-cols-3 gap-2">
    {connections.map((connection) => (
      <ProfilePreview key={connection.contactId} connection={connection} />
    ))}
  </div>
)

ContactProfileConnections.propTypes = {
  connections: PropTypes.array,
}

export default ContactProfileConnections
