import PropTypes from 'prop-types'
import findIndex from 'lodash/findIndex'
import fromPairs from 'lodash/fromPairs'
import get from 'lodash/get'
import map from 'lodash/map'
import pick from 'lodash/pick'
import values from 'lodash/values'
import * as types from '~/constants/types/serviceprovideronboarding'
import ServiceProviderStep from './ServiceProviderStep'
import { ServiceProviderValidatorShape as serviceProviderValidatorShape } from './ServiceProviderValidatorShape'
import './ServiceProviderWizard.less'

const getSteps = (titles, indexes) =>
  values(pick(fromPairs(map(titles, (title, ix) => [ix, title])), indexes))

const actionTypesLookup = {
  1: types.APPEND_ANSWERS,
}

const SERVICE_PROVIDER_STEPS = {
  TITLES: [
    'Edit Profile',
    'Media',
    'Logo',
    'People',
    'Testimonials',
    'Clients',
    'Content',
    'Contact',
  ],
  STEPS: [1, 2, 3, 4, 5, 6, 7],
}

const DEALS_STEPS = {
  TITLES: [
    'Deals',
    'Basic Information',
    'Opportunity Description',
    'Content',
    'Dataroom',
    'Disclaimer',
  ],
  STEPS: [1, 2, 3, 4, 5],
}

const ServiceProviderWizard = ({
  currentStep,
  questions,
  onPreview,
  answers,
  questionsWithOptions,
  loading = false,
  onNavigation,
  onStepChange,
  stepsInErrorId,
  type,
  usage,
  status,
}) => {
  const STEPS =
    type === 'serviceproviders' ? SERVICE_PROVIDER_STEPS : DEALS_STEPS

  const title = get(STEPS.TITLES, currentStep)

  const steps = getSteps(STEPS.TITLES, STEPS.STEPS)
  const stepsInError = []
  stepsInErrorId.forEach((errorId) => stepsInError.push(steps[errorId - 1]))

  const current = findIndex(STEPS.STEPS, (i) => i === currentStep)

  const isFirst = current === 0
  const isLast = current === steps.length - 1
  const nextStep = STEPS.STEPS[current + 1]
  const prevStep = STEPS.STEPS[current - 1]

  const handleNavigation = (
    questions,
    values,
    isNext,
    saveChanges,
    publish
  ) => {
    const actionType = get(actionTypesLookup, currentStep, types.APPEND_ANSWERS)
    onNavigation(
      questions,
      values,
      actionType,
      false,
      isLast,
      isNext ? nextStep : prevStep,
      saveChanges,
      publish
    )
  }

  const validationSchema = serviceProviderValidatorShape(questions)

  const className = `ServiceProviderWizard-form ServiceProviderWizard-form-${currentStep}`

  return (
    <div className="ServiceProviderWizard">
      <ServiceProviderStep
        className={className}
        title={title}
        questions={questions}
        answers={answers}
        validationSchema={validationSchema}
        questionsWithOptions={questionsWithOptions}
        isFirst={isFirst}
        isLast={isLast}
        loading={loading}
        onNavigation={handleNavigation}
        onPreview={onPreview}
        onStepChange={onStepChange}
        currentStep={currentStep}
        stepsInError={stepsInError}
        type={type}
        usage={usage}
        status={status}
      />
    </div>
  )
}

ServiceProviderWizard.propTypes = {
  currentStep: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  questionsWithOptions: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  onNavigation: PropTypes.func.isRequired,
  onStepChange: PropTypes.func,
  onPreview: PropTypes.func,
  stepsInErrorId: PropTypes.array,
  type: PropTypes.string,
  usage: PropTypes.string,
  status: PropTypes.string,
}

export default ServiceProviderWizard
