import { useQuery } from 'react-query'
import * as api from '~/api'
import Loading from '~/components/Loading'
import Profile from '~/components/Profile'
import ResearchCampaignProfileBody from './ResearchCampaignProfileBody'
import ResearchCampaignProfileHeader from './ResearchCampaignProfileHeader'

export default function ResearchCampaignProfileModal({
  researchCampaignId,
  visible,
  onClose = () => {},
}) {
  const { data, status } = useQuery(
    ['researchCampaign', researchCampaignId],
    () => api.research.getResearchCampaign(researchCampaignId),
    {
      enabled: visible,
    }
  )

  const isLoading = status === 'loading' || status === 'idle'

  return (
    <Profile.Modal
      visible={visible}
      onClose={() => onClose()}
      header={
        isLoading ? null : (
          <ResearchCampaignProfileHeader
            researchCampaign={data.researchCampaign}
            company={data.company}
          />
        )
      }
      body={
        isLoading ? (
          <Loading />
        ) : (
          <ResearchCampaignProfileBody
            researchCampaign={data.researchCampaign}
            company={data.company}
          />
        )
      }
    />
  )
}
