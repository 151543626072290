import * as React from 'react'
import PropTypes from 'prop-types'
import clamp from 'lodash/clamp'
import throttle from 'lodash/throttle'
import { ReactComponent as Loading } from '~/assets/loading-chart.svg'
import { GRADIENT_ID } from './FundChartGradientDef'
import { useChart, useChartTooltip } from './useDiscoverFundChart'
import useDiscoverFundData from './useDiscoverFundData'
import './DiscoverFundChart.less'

const height = 120
const width = 400
const padding = 10

function useElementDimensions() {
  const ref = React.useRef()
  const [dimensions, setDimensions] = React.useState({})

  const updateDimensions = React.useCallback(() => {
    const { height, width } = ref.current.getBoundingClientRect()
    setDimensions({ height, width })
  }, [])

  React.useLayoutEffect(updateDimensions, [updateDimensions])

  React.useEffect(() => {
    const update = throttle(updateDimensions, 500)

    const observer = new MutationObserver(update)

    observer.observe(ref.current, { childList: true, subtree: true })

    return () => observer.disconnect()
  }, [updateDimensions])

  return [ref, dimensions]
}

const Tooltip = ({ visible, x, y, title, activePoints }) => {
  const [ref, { height = 0 }] = useElementDimensions()

  const anchorSide = x < width / 2 ? 'left' : 'right'
  const tooltipX = x < width / 2 ? x + padding : width - x + padding
  return (
    <div
      ref={ref}
      className="discover-fund-chart-tooltip"
      style={{
        top: clamp(y - height / 3, 10, 50),
        [anchorSide]: tooltipX,
        visibility: visible ? 'visible' : 'hidden',
      }}
    >
      {activePoints.length > 0 && (
        <>
          <div className="discover-fund-chart-tooltip-title">{title}</div>
          <table>
            <tbody>
              {activePoints.map(({ fill, label, value }) => (
                <tr key={label}>
                  <td>
                    <div
                      className="discover-fund-chart-tooltip-legend"
                      style={{ backgroundColor: fill }}
                    />
                  </td>
                  <td>{label}</td>
                  <td className="discover-fund-chart-tooltip-value">{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  )
}

const DiscoverFundChart = ({ fundId, indexFund }) => {
  const { data, loading } = useDiscoverFundData(fundId, indexFund)
  const { scale, area, line, revealClipPath, revealClipPathId, reveal } =
    useChart(data, {
      width,
      height,
      padding,
    })
  const { tooltip, svgRootRef, eventListeners } = useChartTooltip(data, scale)

  React.useEffect(() => {
    if (!loading && data) {
      reveal()
    }
  }, [loading, data, reveal])

  return (
    <div className="discover-fund-chart">
      {loading ? (
        <Loading
          aria-label="loading chart data"
          height={height}
          width={width}
        />
      ) : (
        <div
          className="discover-fund-chart-container"
          style={{ width }}
          {...eventListeners}
        >
          <svg
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            height={height}
            ref={svgRootRef}
          >
            {revealClipPath}
            {!loading && data && (
              <>
                <path
                  fill={`url(${GRADIENT_ID})`}
                  stroke="none"
                  clipPath={revealClipPathId}
                  d={area(data[0].data)}
                />
                {data.map(({ label, fill, data }) => (
                  <path
                    key={label}
                    fill="none"
                    stroke={fill}
                    strokeWidth={3}
                    clipPath={revealClipPathId}
                    d={line(data)}
                  />
                ))}
              </>
            )}
          </svg>
          <Tooltip {...tooltip} />
        </div>
      )}
    </div>
  )
}

DiscoverFundChart.propTypes = {
  fundId: PropTypes.number.isRequired,
  indexFund: PropTypes.number.isRequired,
}

export default DiscoverFundChart
