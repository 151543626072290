import PropTypes from 'prop-types'
import { VisibilityOff } from '@context365/icons'
import Profile from '~/components/Profile'
import { ApplyToCampaignButton } from '../Application'

export default function ResearchCampaignProfileHeader({
  researchCampaign,
  company,
}) {
  return (
    <Profile.Header
      title={researchCampaign.campaignName}
      description={
        company ? (
          [company.name, [company.city, company.country]]
        ) : (
          <div className="flex flex-col pt-1 space-y-1">
            <span className="type-body-regular-sm">Anonymous</span>
            <VisibilityOff />
          </div>
        )
      }
      actions={
        <ApplyToCampaignButton
          campaignEntity={researchCampaign}
          campaignType="research"
        />
      }
    />
  )
}

ResearchCampaignProfileHeader.propTypes = {
  researchCampaign: PropTypes.shape({
    campaignName: PropTypes.string.isRequired,
    campaignId: PropTypes.number.isRequired,
    campaignApplications: PropTypes.arrayOf(
      PropTypes.shape({
        campaignId: PropTypes.number.isRequired,
        applicationId: PropTypes.number.isRequired,
        wasSent: PropTypes.bool.isRequired,
      })
    ).isRequired,
  }),
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    city: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
}
