import { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  faCheckDouble,
  faCog,
  faEmptySet,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import {
  markAllNotificationsAsRead,
  markNotificationAsRead,
} from '~/actions/notifications'
import NoData from '../NoData'
import NotificationEntry from '../NotificationEntry'
import './NotificationList.less'

const NotificationList = ({ notifications, onItemClick }) => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const handleMarkAllNotificationsAsRead = useCallback(() => {
    dispatch(markAllNotificationsAsRead())
  }, [dispatch])

  const handleMarkNotificationAsRead = useCallback(
    (notificationLogId) => {
      dispatch(markNotificationAsRead(notificationLogId))
    },
    [dispatch]
  )

  let content = map(notifications, (notification) => (
    <NotificationEntry
      key={notification.notificationLogId}
      notification={notification}
      onMark={handleMarkNotificationAsRead}
      onItemClick={onItemClick}
    />
  ))
  if (isEmpty(notifications)) {
    content = (
      <NoData
        title="You have not received any notifications at this time"
        icon={<FontAwesomeIcon icon={faEmptySet} size="lg" />}
      />
    )
  }

  return (
    <div className="NotificationList">
      <div className="NotificationList-header">
        <h3>Notifications</h3>
        <div className="NotificationList-header-actions">
          <Button type="link" onClick={handleMarkAllNotificationsAsRead}>
            <FontAwesomeIcon icon={faCheckDouble} />
            <span className="NotificationList-header-actions-btn-text">
              Mark All as Read
            </span>
          </Button>
          <Button
            type="link"
            className="NotificationList-header-actions-btn-settings"
            onClick={() => {
              push('/settings/notifications')
            }}
          >
            <FontAwesomeIcon icon={faCog} size="lg" />
          </Button>
        </div>
      </div>
      <SimpleBar className="NotificationList-entries">{content}</SimpleBar>
      <div className="NotificationList-footer">
        <Button
          onClick={() => {
            push('/notifications')
          }}
          type="link"
        >
          Show All
        </Button>
      </div>
    </div>
  )
}

NotificationList.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      notificationLogId: PropTypes.number.isRequired,
      notificationSubscriptionId: PropTypes.number,
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      data: PropTypes.shape({
        type: PropTypes.string,
        meetingId: PropTypes.string,
        meetingDateTime: PropTypes.string,
        conversationId: PropTypes.number,
      }),
    })
  ),
  onItemClick: PropTypes.func,
}

export default NotificationList
