import { Component } from 'react'
import PropTypes from 'prop-types'
import { faCheckCircle, faFlag } from '@fortawesome/pro-light-svg-icons'
import isNil from 'lodash/isNil'
import moment from 'moment'
import numeral from 'numeral'
import { connect } from 'react-redux'
import { fetchPortfoliosFunds } from '~/actions/portfolio'
import CCTable, { CCTableActionCell } from '../CCTable'

class PortfolioFundsTable extends Component {
  static propTypes = {
    fetchPortfoliosFunds: PropTypes.func.isRequired,
    portfolioFunds: PropTypes.object.isRequired,
    portfolioId: PropTypes.string.isRequired,
  }
  renderInitial = (record) => (
    <span>
      {numeral(record.initialInvestment).format('$ 0,0')}{' '}
      <span className="cc-grey-text">
        {moment.utc(record.startDate).local().format('(MM/YYYY)')}
      </span>
    </span>
  )

  renderCurrency = (record) => {
    if (!isNil(record) && record > 0) return numeral(record).format('$ 0,0.00')
  }

  getPortfolioAllocationsColumns = () => {
    const twoMonthsAgo = new Date().getMonth() - 1
    const oneMonthAgo = new Date().getMonth()
    const year = new Date().getFullYear()
    const menus = [
      {
        icon: faCheckCircle,
        text: 'Make new Investment',
        iconColor: '#2FC5D2',
      },
      {
        icon: faFlag,
        text: 'Sell',
        iconColor: '#F19F00',
      },
    ]
    return [
      {
        key: '1',
        title: 'FUND NAME',
        dataIndex: 'fundName',
        width: '15%',
      },
      {
        key: '2',
        title: 'STRATEGY',
        dataIndex: 'fundStrategy',
        width: '15%',
      },
      {
        key: '3',
        title: 'SECTOR',
        dataIndex: 'fundSector',
        width: '20%',
      },
      {
        key: '4',
        title: 'INITIAL INVESTMENT',
        render: this.renderInitial,
      },
      {
        key: '5',
        title: 'CURRENT VALUE',
        dataIndex: 'currentValue',
        render: this.renderCurrency,
      },
      {
        key: '6',
        title: `${twoMonthsAgo}/${year}`,
        dataIndex: 'secondToLastMonthProfit',
        render: this.renderCurrency,
      },
      {
        key: '7',
        title: `${oneMonthAgo}/${year}`,
        dataIndex: 'lastMonthProfit',
        render: this.renderCurrency,
      },
      {
        key: '8',
        title: `${year}YTD`,
        dataIndex: 'yearYTD',
        render: this.renderCurrency,
      },
      {
        key: '9',
        title: 'ACTIONS',
        cellType: CCTableActionCell,
        menuItems: menus,
      },
    ]
  }
  componentDidMount() {
    const { fetchPortfoliosFunds, portfolioId } = this.props
    if (portfolioId) fetchPortfoliosFunds(portfolioId)
  }
  render() {
    const { fetchPortfoliosFunds, portfolioFunds, portfolioId } = this.props
    return (
      <CCTable
        key="id"
        dataSource={portfolioFunds.result}
        pagination={portfolioFunds}
        fetchMethod={fetchPortfoliosFunds.bind(this, portfolioId)}
        getColumnsMethod={this.getPortfolioAllocationsColumns}
        scroll={{ x: 1500 }}
        tableTitle="Funds"
      />
    )
  }
}
const mapStateToProps = (state) => ({
  portfolioFunds: state.portfolio.portfolioFunds,
})

const mapDispatchToProps = {
  fetchPortfoliosFunds,
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioFundsTable)
