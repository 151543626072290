import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import { contactLogin } from '~/actions/auth'
import { TrackingAnalytics } from '~/config'

const userIsValid = (...attributes) =>
  every(attributes, (attr) => !isEmpty(attr))

const trackingAvailable = () =>
  Boolean(TrackingAnalytics.enabled && window.analytics)

export const trackLogin = (contact, company, role) => {
  if (userIsValid(contact, company, role)) {
    const contactStatusLoggedIn = 1
    const isMobile = false
    contactLogin(contactStatusLoggedIn, isMobile)
  }
}

export const initializeAnalytics = (contact, company, role, impersonation) => {
  if (
    impersonation ||
    !trackingAvailable() ||
    !userIsValid(contact, company, role)
  ) {
    return
  }

  const { analytics } = window
  const visitor = {
    contact_id: contact.contactId,
    email: contact.email,
    first_name: contact.firstName,
    last_name: contact.lastName,
    full_name: contact.contactName,
    role,
    job_title: contact.jobTitle,
    job_level: contact.jobLevel,
    job_function: contact.jobFunction,
    creation_date: contact.createdAtUtc,
    continent: contact.continent,
    country: contact.country,
    state_province: contact.stateProvince,
    city: contact.city,
    pax_id: contact.paxId,
    company_contact_id: contact.companyContactId,
    user_hash: contact.intercomToken,
    user_id: contact.companyContactId,
  }

  const account = {
    id: company.companyId,
    reg_company_id: company.regCompanyId,
    name: company.name,
    type: company.category.categoryName,
    account_creation_date: company.createdAt,
    continent: company.continent,
    country: company.country,
    state_province: company.stateProvince,
    city: company.city,
    tier: contact.tier,
  }

  if (contact.allocatorDetails) {
    account.portfolio_size = contact.allocatorDetails.portfolioSize
    account.primary_investor_category =
      contact.allocatorDetails.primaryInvestorCategory
    account.secondary_investor_category =
      contact.allocatorDetails.secondaryInvestorCategory
    account.minimum_fund_size_interest =
      contact.allocatorDetails.minimumFundSizeInterest
    account.maximum_fund_size_interest =
      contact.allocatorDetails.maximumFundSizeInterest
    account.minimum_firm_size_interest =
      contact.allocatorDetails.minimumFirmSizeInterest
    account.maximum_firm_size_interest =
      contact.allocatorDetails.maximumFirmSizeInterest
    account.minimum_investment_size =
      contact.allocatorDetails.minimumInvestmentSize
    account.average_investment_size =
      contact.allocatorDetails.averageInvestmentSize
    account.intruments_interest = contact.allocatorDetails.instrumentsInterest
    account.sectors = contact.allocatorDetails.sectorsInterest
    account.geographic =
      contact.allocatorDetails.geographicConcentrationInterest
  } else if (contact.managerDetails) {
    account.firm_aum = contact.managerDetails.firmAum
    account.fund_aum = contact.managerDetails.funds.map((f) => f.fundAum)
    account.sub_strategy = contact.managerDetails.funds.map(
      (f) => f.subStrategy
    )
    account.broad_strategy = contact.managerDetails.funds.map(
      (f) => f.broadStrategy
    )
    account.fund_inception_date = contact.managerDetails.funds.map(
      (f) => f.fundInceptionDate
    )
    account.liquid = contact.managerDetails.funds.map((f) => f.publiclyListed)
    account.hasLiquid = contact.managerDetails.funds.some(
      (f) => f.publiclyListed
    )
    account.hasIlliquid = contact.managerDetails.funds.some(
      (f) => !f.publiclyListed
    )
  }

  visitor.company = { ...account }

  for (const [key, value] of Object.entries(account)) {
    visitor[`company_${key}`] = value
  }

  analytics.identify(visitor.company_contact_id, visitor)
}

export const trackEvent = (eventName, extraProps) => {
  if (!trackingAvailable()) {
    return
  }

  window.analytics.track(eventName, extraProps)
}

export function subscribeToLocationChanges(store, history) {
  if (!TrackingAnalytics.enabled) {
    return
  }

  const shouldSkipPageTracking = () =>
    store.getState().auth.claims == null ||
    store.getState().auth.claims.impersonation != null

  if (!shouldSkipPageTracking()) {
    window.analytics.page()
  }

  let prevPath = history.location.pathname
  history.listen(({ pathname }) => {
    if (pathname !== prevPath) {
      if (!shouldSkipPageTracking()) {
        window.analytics.page()
      }
      prevPath = pathname
    }
  })
}
