import * as React from 'react'
import { useObjectMemo } from '@context365/hooks'

const OnboardingContext = React.createContext({})

export function OnboardingContextProvider({ children, ...props }) {
  return (
    <OnboardingContext.Provider value={useObjectMemo(props)}>
      {children}
    </OnboardingContext.Provider>
  )
}

export function useOnboardingContext() {
  const context = React.useContext(OnboardingContext)
  if (context === undefined) {
    throw new Error(
      'useOnboardingContext must be called within an OnboardingContextProvider'
    )
  }
  return context
}
