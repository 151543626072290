import PropTypes from 'prop-types'
import { ApplyToCampaignButton, ViewApplicationButton } from '../Application'

const DiscoverResearchCampaignResultFooter = ({ researchCampaign }) => (
  <div className="flex flex-row justify-end space-x-2">
    <ViewApplicationButton campaignEntity={researchCampaign} />
    <ApplyToCampaignButton
      campaignEntity={researchCampaign}
      campaignType="research"
    />
  </div>
)

DiscoverResearchCampaignResultFooter.propTypes = {
  researchCampaign: PropTypes.object.isRequired,
}

export default DiscoverResearchCampaignResultFooter
