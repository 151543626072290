import { api as http } from '~/api/services'
import {
  LOADING_USERS_ROADSHOWS,
  LOADING_USERS_ROADSHOWS_ERROR,
  LOADING_USERS_ROADSHOWS_SUCCESS,
} from '~/constants/types/roadshow'

export const fetchCompany = (companyId) => http.get(`/companies/${companyId}`)

export const createRoadshow = (roadshowInput) =>
  http.post('/roadshows', roadshowInput)

export const getRoadshows = (isPast, pagination, filters, sorter, searchTerm) =>
  http.post(`roadshows/list/${isPast}`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getCurrentRoadshows = () => http.get('/roadshows/current')

export const getMyRoadshows = () => (dispatch) => {
  dispatch({ type: LOADING_USERS_ROADSHOWS })

  http
    .get('/roadshows')
    .then((response) =>
      dispatch({
        type: LOADING_USERS_ROADSHOWS_SUCCESS,
        payload: response.data.result,
      })
    )
    .catch((error) =>
      dispatch({ type: LOADING_USERS_ROADSHOWS_ERROR, payload: error })
    )
}
