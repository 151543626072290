import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@context365/button'
import { Close, NavigateBefore, NavigateNext } from '@context365/icons'
import { Input } from 'antd'
import './MessageSearchBar.less'

const MessageSearchBar = ({
  currentSearchIndex = 0,
  totalResults = 0,
  hasSearchOccured = false,
  onSearch,
  onNext,
  onPrevious,
  onClose,
}) => {
  const { Search } = Input
  const [searchTerm, setSearchTerm] = useState('')
  const [hasTermChanged, setHasTermChanged] = useState(true)

  // when the user searches, tell the parent component what search to do
  const conversationSearchHandler = useCallback(async () => {
    onSearch(searchTerm)
    setHasTermChanged(false)
  }, [onSearch, searchTerm])

  const searchTermChangedHandler = (e) => {
    setSearchTerm(e.target.value)
    // keep track for the term being changed or not,
    // so we can use Enter to go to the next result and not to re-search;
    // but if the term changes, use Enter to search again
    setHasTermChanged(true)
  }

  return (
    <div className="flex p-2 items-center justify-center w-full space-x-4">
      <div>
        <Search
          className="w-auto lg:w-full"
          value={searchTerm}
          onChange={searchTermChangedHandler}
          onPressEnter={hasTermChanged ? conversationSearchHandler : onNext}
          autoFocus
        />
      </div>

      {hasSearchOccured && (
        <div className="flex">
          <span className="cc-body-text messaging-chat-results-label">
            {totalResults > 0
              ? `${currentSearchIndex + 1} of ${totalResults}`
              : 'No results found'}
          </span>
          {totalResults > 0 && (
            <div className="space-x-2">
              <IconButton
                label="Previous"
                icon={<NavigateBefore />}
                onClick={onPrevious}
                size="small"
                status="secondary"
              />
              <IconButton
                label="Next"
                icon={<NavigateNext />}
                onClick={onNext}
                size="small"
                status="secondary"
              />
            </div>
          )}
        </div>
      )}
      <IconButton
        icon={<Close />}
        label="Close"
        variant="link"
        status="secondary"
        onClick={onClose}
      />
    </div>
  )
}

MessageSearchBar.propTypes = {
  currentSearchIndex: PropTypes.number,
  totalResults: PropTypes.number,
  hasSearchOccured: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default MessageSearchBar
