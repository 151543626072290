import { Modal, Takeover } from '@context365/modals'
import { useNavigate } from 'react-router-dom-v5-compat'
import CompanySettings from './CompanySettings'

const CompanySettingsTakeover = () => {
  const navigate = useNavigate()
  return (
    <Takeover.Container visible={true} tabIndex={0}>
      <Modal.Header className="p-3 md:p-6">
        Fill out your company profile
      </Modal.Header>
      <Modal.Body>
        <CompanySettings onFinish={() => navigate('/community/companies')} />
      </Modal.Body>
    </Takeover.Container>
  )
}

export default CompanySettingsTakeover
