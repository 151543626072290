import isNil from 'lodash/isNil'

export const generateFlagsIdentity = (claims, contact, company) => {
  const identity = {
    key: `${claims.role}-${claims.ccid}-${claims.email}`,

    email: claims.email,
    firstName: contact.firstName,
    lastName: contact.lastName,
    name: contact.contactName,
    custom: {
      role: claims.role,
      tier: claims.tier,
      contactId: claims.nameid,
      companyId: claims.company_id,
      companyContactId: claims.ccid,
      isEligibleForPrivateEquityProfile:
        company.isEligibleForPrivateEquityProfile,
    },
  }

  if (!isNil(contact.imageUrl)) {
    identity.avatar = contact.imageUrl
  }

  if (!isNil(contact.country)) {
    identity.country = contact.country
  }

  return identity
}
