import dropRight from 'lodash/dropRight'
import fromPairs from 'lodash/fromPairs'
import get from 'lodash/get'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import values from 'lodash/values'
import {
  ADD_NEW_NOTIFICATION,
  LOAD_NOTIFICATIONS_FAILURE,
  LOAD_NOTIFICATIONS_REQUEST,
  LOAD_NOTIFICATIONS_STATS,
  LOAD_NOTIFICATIONS_SUCCESS,
  MARK_NOTIFICATIONS_AS_READ,
  SEND_DEVICE_TOKEN_FAILURE,
  SEND_DEVICE_TOKEN_REQUEST,
  SEND_DEVICE_TOKEN_SUCCESS,
  SEND_PRESENTATION_REQUEST,
} from '~/constants/types/notifications'

const initialState = {
  token: null,
  notificationsById: {},
  paginatedNotificationsById: {},
  notificationIds: [],
  presentationNotifications: [],
  read: 0,
  unread: 0,
  pagination: {},
  loading: false,
  error: null,
}

const mapNotificationsById = (notifications, action) =>
  map(values(notifications), (notification) => ({
    ...notification,
    isRead: get(
      action.payload,
      `${notification.notificationLogId}`,
      notification.isRead
    ),
  }))
export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_NOTIFICATIONS_REQUEST:
    case SEND_DEVICE_TOKEN_REQUEST:
      return { ...state, loading: true }
    case LOAD_NOTIFICATIONS_SUCCESS:
      return { ...state, loading: false, ...action.payload }
    case LOAD_NOTIFICATIONS_FAILURE:
    case SEND_DEVICE_TOKEN_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case SEND_DEVICE_TOKEN_SUCCESS:
      return { ...state, loading: false, token: action.payload }
    case LOAD_NOTIFICATIONS_STATS:
      return { ...state, ...action.payload }
    case MARK_NOTIFICATIONS_AS_READ:
      return {
        ...state,
        notificationsById: mapNotificationsById(
          state.notificationsById,
          action
        ),
        paginatedNotificationsById: mapNotificationsById(
          state.paginatedNotificationsById,
          action
        ),
      }
    case ADD_NEW_NOTIFICATION:
      return {
        ...state,
        unread: state.unread + 1,
        notificationsById: fromPairs(
          map(
            [
              action.payload,
              ...dropRight(
                sortBy(
                  values(state.notificationsById),
                  (n) => -n.notificationLogId
                )
              ),
            ],
            (n) => [n.notificationLogId, n]
          )
        ),
      }
    case SEND_PRESENTATION_REQUEST:
      return { ...state, presentationNotifications: action.payload }
    default:
      return state
  }
}
