import { Route } from 'react-router-dom-v5-compat'
import { ContactIdentification } from '../pages/ContactIdentification'
import { Step, makeGetNextPagePath, makeGetPreviousPagePath } from './utils'

const steps = [Step.ContactIdentification]

const wizardSteps = [
  { path: Step.ContactIdentification, title: 'Contact Info' },
]

export const guestWorkflow = {
  getStartingPagePath: () => Step.ContactIdentification,
  getNextPagePath: makeGetNextPagePath(steps),
  getPreviousPagePath: makeGetPreviousPagePath(steps),

  routes: [
    <Route
      key={Step.ContactIdentification}
      path={Step.ContactIdentification}
      element={<ContactIdentification steps={wizardSteps} />}
    />,
  ],
}
