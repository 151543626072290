import { useEffect, useState } from 'react'
import { Empty } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { api as http } from '~/api/services'
import Contact from '~/components/Contact'
import Loading from '~/components/Loading'

const ContactContainer = () => {
  const { goBack } = useHistory()
  const { companyId, contactId } = useParams()
  const [contact, setContact] = useState(null)

  useEffect(() => {
    http
      .get(`/companies/${companyId}/contacts/${contactId}`)
      .then((response) => setContact(response.data.result))
      .catch((error) => console.log(error))
  }, [companyId, contactId])

  if (contact === null) {
    return (
      <Loading>
        <Empty />
      </Loading>
    )
  }

  return <Contact contact={contact} back={goBack} />
}

ContactContainer.propTypes = {}

export default ContactContainer
