import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import moment from 'moment'
import { acceptOnlineMeetingAllocator } from '~/actions/contextMeetings'
import { api as http } from '~/api/services'
import { ROLE } from '~/constants/roles'
import Loading from '../Loading'
import MeetingQuestionAnswer from '../MeetingQuestionAnswer'
import MeetingQuestionsForm from '../MeetingQuestionsForm/MeetingQuestionsForm'

const AcceptOnlineMeeting = ({
  visible,
  closeModal,
  company,
  meetingId,
  onAccept,
  meetingDateTime,
  role,
  haveAnswered,
  onForbidden = () => {},
  roadshowId = 0,
}) => {
  const [acceptingInProgress, setAcceptingInProgress] = useState(false)
  const [selectedDateTime, setSelectedDateTime] = useState({})
  const [questionsForFund, setQuestionsForFund] = useState([])
  const [answers, setAnswers] = useState([])
  const [loadingQuestions, setLoadingQuestions] = useState(false)
  const [selectedProposedTimeSlotId, setSelectedProposedTimeSlotId] =
    useState(null)

  useEffect(() => {
    if (role === ROLE.MANAGER && meetingId) {
      setLoadingQuestions(true)
      http
        .get(`/meeting/questions/${meetingId}`)
        .then((response) => {
          const { result } = response.data
          setQuestionsForFund(result)
          setAnswers(
            map(result, (r) => ({
              meetingQuestionId: r.meetingQuestionId,
              answer: '',
            }))
          )
        })
        .finally(() => {
          setLoadingQuestions(false)
        })
    }
  }, [meetingId, role])

  const handleModalCancel = useCallback(() => {
    setSelectedDateTime({})
    setSelectedProposedTimeSlotId(null)
    closeModal()
  }, [closeModal])

  const handleAcceptMethod = useCallback(() => {
    setAcceptingInProgress(true)
    acceptOnlineMeetingAllocator(
      meetingId,
      selectedDateTime.startDateTime,
      selectedProposedTimeSlotId,
      isEmpty(answers) ? null : answers
    )
      .then(() => {
        message.success('The meeting has been accepted.')
        onAccept(selectedDateTime.startDateTime)
      })
      .finally(() => {
        setAcceptingInProgress(false)
        handleModalCancel()
      })
  }, [
    answers,
    handleModalCancel,
    meetingId,
    onAccept,
    onForbidden,
    selectedDateTime.startDateTime,
    selectedProposedTimeSlotId,
  ])

  return (
    <Modal
      destroyOnClose
      className="cc-modal-accept"
      title="Accept Meeting Request"
      visible={visible}
      okText="Accept Meeting"
      onOk={handleAcceptMethod}
      onCancel={handleModalCancel}
      width="758px"
      bodyStyle={{ padding: '30px' }}
      okButtonProps={{
        style: {
          backgroundColor: '#24c477',
          borderStyle: 'none',
        },
      }}
    >
      <p>
        Are you sure you want to accept the{' '}
        {roadshowId > 0 ? 'roadshow' : 'online'} meeting with {company}
        {moment(meetingDateTime).isValid() && !isNil(meetingDateTime)
          ? ` on
           ${moment
             .utc(meetingDateTime)
             .local()
             .format('MMMM D, YYYY [at] h:mm A')}?`
          : '?'}
      </p>
      {role === ROLE.MANAGER ? (
        <div>
          {!isEmpty(questionsForFund) && !isEmpty(answers) && !haveAnswered && (
            <MeetingQuestionsForm
              questionsForFund={questionsForFund}
              answers={answers}
              setAnswers={setAnswers}
              loadingQuestions={loadingQuestions}
            />
          )}
        </div>
      ) : (
        role === ROLE.SERVICE_PROVIDER && (
          <Loading spinning={acceptingInProgress}>
            <MeetingQuestionAnswer
              meetingId={meetingId}
              acceptingRequest
              companyName={company}
              roadshowId={roadshowId}
            />
          </Loading>
        )
      )}
    </Modal>
  )
}

AcceptOnlineMeeting.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  company: PropTypes.string.isRequired,
  meetingId: PropTypes.number.isRequired,
  onAccept: PropTypes.func.isRequired,
  meetingDateTime: PropTypes.string,
  role: PropTypes.string.isRequired,
  haveAnswered: PropTypes.bool.isRequired,
  onForbidden: PropTypes.func,
  roadshowId: PropTypes.number,
}

export default AcceptOnlineMeeting
