import PropTypes from 'prop-types'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useTracking } from 'react-tracking'
import logo from '~/assets/new-logo-white.png'
import { PagedPdfViewer } from '~/components/PDFViewer'
import ContactsCard from './ContactsCard'

const PdfViewer = ({ title, author, taggedContacts, mediaUrl, onClose }) => {
  const { trackEvent } = useTracking({ component: 'PdfViewer' })

  return (
    <div className="h-screen flex flex-col overflow-hidden">
      <header className="h-12 bg-grey-900 flex justify-center items-center flex-shrink-0 px-2">
        <div className="flex-1">
          <Button type="primary" icon={<ArrowLeftOutlined />} onClick={onClose}>
            Go back
          </Button>
        </div>
        <div className="flex-1 text-center">
          <img src={logo} alt="Context" className="w-64" />
        </div>
        <div className="flex-1" />
      </header>

      <PagedPdfViewer
        fileUrl={mediaUrl}
        onPageChange={(page, pageCount) =>
          trackEvent({
            eventName: 'change',
            element: 'pdf page',
            page,
            pageCount,
          })
        }
      />

      <div className="fixed left-4 top-24">
        <ContactsCard
          title={title}
          author={author}
          taggedContacts={taggedContacts}
        />
      </div>
    </div>
  )
}

PdfViewer.propTypes = {
  mediaUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.object,
  taggedContacts: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default PdfViewer
