import { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Divider, Input, Modal, Select, message } from 'antd'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import size from 'lodash/size'
import trim from 'lodash/trim'
import qs from 'qs'
import { useSelector } from 'react-redux'
import {
  getCompaniesContactsAllocatorsWithMatchmakingScore,
  getCompaniesContactsAllocatorsWithoutMatchmakingScore,
} from '~/actions/company'
import {
  getColleagues,
  sendOnlineRequestToAllocator,
} from '~/actions/contextMeetings'
import { api as http } from '~/api/services'
import { ManageFunds, RoleManager } from '~/constants/userAccess'
import { getUserId } from '~/selectors/auth'
import MandateViewCtx from '~/utils/contexts/MandateViewCtx'
import FundSelectForMeetingRequest from '../FundSelectForMeetingRequest'
import Loading from '../Loading'
import MeetingQuestionsForm from '../MeetingQuestionsForm'
import ParticipantsCheckbox from '../ParticipantsCheckbox'
import './DiscoverAllocatorMeetingRequest.less'

const { TextArea } = Input

const DiscoverAllocatorMeetingRequest = ({
  visible,
  onClose,
  onRefresh,
  allocator,
  selectedFundId,
  fund,
  roadshowList,
  roadshowId = 0,
  meetingTypeId = 3,
  fromMapView = false,
  coordinates = [],
  arePointsNear,
  funds = [],
}) => {
  const userId = useSelector((state) => getUserId(state))
  const company = useSelector((state) => state.auth.company)
  const [currentStep, setCurrentStep] = useState(0)
  const [myColleagues, setMyColleagues] = useState([])
  const [participants, setParticipants] = useState([])
  const [toParticipants, setToParticipants] = useState([])
  const [loadingContacts, setLoadingContacts] = useState(false)
  const [loadingQuestions, setLoadingQuestions] = useState(false)
  const [contacts, setContacts] = useState([])
  const [questionsForFund, setQuestionsForFund] = useState([])
  const { activities } = useSelector((state) => state.userAccess)
  const hasManageFundsAccess = activities.includes(ManageFunds)
  const isRoleManager = activities.includes(RoleManager)
  const [canSendMeetingRequest, setCanSendMeetingRequest] = useState(true)

  const [textMessage, setMessage] = useState('')
  const [answers, setAnswers] = useState([])
  const [savingInProggress, setSavinginProggress] = useState(false)
  const [selectedRoadshowId, setSelectedRoadshowId] = useState(0)
  const [fundId, setFundId] = useState(
    isEmpty(funds) ? selectedFundId : funds[0].fundId
  )

  const [carryContextPoints, setCarryContextPoints] = useState(false)
  const companyContextPoints = useSelector((state) => state.company.company)
  const sentFromMandateView = useContext(MandateViewCtx)

  useEffect(() => {
    if (!isEmpty(roadshowList) && selectedRoadshowId === 0) {
      setSelectedRoadshowId(roadshowList[0].roadShowId)
    }
  }, [roadshowList, selectedRoadshowId])

  useEffect(() => {
    if (meetingTypeId === 2) {
      if (!isNil(selectedRoadshowId) && !isEmpty(coordinates)) {
        const selectedRoadshow = roadshowList.find(
          (x) => x.roadShowId === selectedRoadshowId
        )
        if (!isNil(selectedRoadshow)) {
          if (arePointsNear(coordinates, selectedRoadshow.coordinates, 10)) {
            setCanSendMeetingRequest(true)
          } else {
            setCanSendMeetingRequest(false)
            message.error(
              'Can not send roadshow meeting request to contact outside of roadshow area!'
            )
          }
        }
      }
    }
  }, [
    arePointsNear,
    coordinates,
    meetingTypeId,
    roadshowList,
    selectedRoadshowId,
  ])

  const getMeetingRoadshowId = () => {
    if (meetingTypeId === 2) {
      if (!isNil(roadshowId) && roadshowId > 0) {
        return roadshowId
      } else if (!isNil(selectedRoadshowId) && selectedRoadshowId > 0) {
        return selectedRoadshowId
      } else {
        return null
      }
    } else {
      return null
    }
  }

  const meetingRoadshowId = getMeetingRoadshowId()

  useEffect(() => {
    if (visible) {
      setToParticipants([allocator.contactId])
      setParticipants([userId])
      if (allocator.companyId > 0) {
        setLoadingContacts(true)
        if (isRoleManager) {
          getCompaniesContactsAllocatorsWithMatchmakingScore(
            allocator.companyId,
            fundId,
            meetingRoadshowId
          )
            .then((response) => {
              const contacts = response.data.result
              setContacts(contacts)
              setToParticipants(map(contacts, (c) => c.contactId))
            })
            .finally(() => {
              setLoadingContacts(false)
            })
        } else {
          getCompaniesContactsAllocatorsWithoutMatchmakingScore(
            allocator.companyId
          )
            .then((response) => {
              const contacts = response.data.result
              setContacts(contacts)
              setToParticipants(map(contacts, (c) => c.contactId))
            })
            .finally(() => {
              setLoadingContacts(false)
            })
        }
      }
    }
    return () => {
      setContacts([])
      setQuestionsForFund([])
      setAnswers([])
    }
  }, [
    allocator,
    meetingRoadshowId,
    hasManageFundsAccess,
    fundId,
    userId,
    visible,
  ])

  useEffect(() => {
    if (visible) {
      if (
        !isEmpty(toParticipants) &&
        !isEmpty(contacts) &&
        hasManageFundsAccess
      ) {
        setLoadingQuestions(true)
        const filteredParticipants = map(
          filter(contacts, (c) => toParticipants.includes(c.contactId)),
          (f) => f.companyContactId
        )
        const params = { companyContactIds: filteredParticipants }
        http
          .get('/meeting/questions', {
            params,
            paramsSerializer: (params) =>
              qs.stringify(params, { arrayFormat: 'repeat' }),
          })
          .then((response) => {
            const { result } = response.data
            setQuestionsForFund(result)
            setAnswers(
              map(result, (r) => ({
                meetingQuestionId: r.meetingQuestionId,
                answer: '',
              }))
            )
          })
          .finally(() => {
            setLoadingQuestions(false)
          })
      }
    }
  }, [contacts, hasManageFundsAccess, setAnswers, toParticipants, visible])

  const handleOk = useCallback(() => {
    setSavinginProggress(true)
    sendOnlineRequestToAllocator(
      allocator.companyId,
      fundId,
      participants,
      toParticipants,
      textMessage,
      meetingTypeId,
      answers,
      allocator.companyContactId,
      meetingRoadshowId,
      carryContextPoints,
      sentFromMandateView
    )
      .then((response) => {
        const { meetingId } = response.data.result.result
        allocator?.meetings?.push({
          contextMeetingId: meetingId,
          meetingStatus: 'Pending',
          participantStatus: 'Confirmed',
        })
        message.success('Request sent succesfully.')
        onClose()
      })
      .catch((err) => {
        const errorMessage =
          err?.response?.status === 400 ? err?.response?.data?.message : null
        message.error(errorMessage || 'Could not send meeting request.')
      })
      .finally(() => {
        setSavinginProggress(false)
        onRefresh()
      })
  }, [
    allocator.companyId,
    allocator.companyContactId,
    allocator?.meetings,
    fundId,
    participants,
    toParticipants,
    textMessage,
    meetingTypeId,
    answers,
    meetingRoadshowId,
    carryContextPoints,
    onClose,
    onRefresh,
  ])

  const handleParticipantChecked = useCallback(
    (e) => {
      let tmpparticipants
      if (e.target.checked) {
        tmpparticipants = [...participants, e.target.value]
        setParticipants(tmpparticipants)
      } else {
        tmpparticipants = participants.filter((p) => p !== e.target.value)
        setParticipants(tmpparticipants)
      }
    },
    [participants]
  )

  const handleToParticipantChecked = useCallback(
    (e) => {
      let tmptoParticipants
      if (e.target.checked) {
        tmptoParticipants = [...toParticipants, e.target.value]
        setToParticipants(tmptoParticipants)
      } else {
        tmptoParticipants = toParticipants.filter((p) => p !== e.target.value)
        setToParticipants(tmptoParticipants)
      }
    },
    [toParticipants]
  )

  const getDisabledSendRequest = useCallback(() => {
    if (!canSendMeetingRequest) return true
    else if (size(questionsForFund) === 0) return false
    else return size(filter(answers, (a) => trim(a.answer) === '')) > 0
  }, [answers, canSendMeetingRequest, questionsForFund])

  useEffect(() => {
    hasManageFundsAccess
      ? getColleagues(allocator.companyId, fundId, null, meetingRoadshowId)
          .then((response) => {
            setMyColleagues(
              response.data.result.find(
                (x) => x.companyId !== allocator.companyId
              ).contacts
            )
          })
          .catch(() => message.error('Could not retrieve colleagues'))
      : getColleagues(allocator.companyId, 0, null, meetingRoadshowId)
          .then((response) => {
            setMyColleagues(
              response.data.result.find(
                (x) => x.companyId !== allocator.companyId
              ).contacts
            )
          })
          .catch(() => message.error('Could not retrieve colleagues'))
  }, [allocator, fundId, meetingRoadshowId, hasManageFundsAccess])

  const footer = (
    <div className="footer">
      <Button
        onClick={() => {
          setToParticipants([allocator.contactId])
          setParticipants([userId])
          setQuestionsForFund([])
          setCurrentStep(0)
          onClose()
        }}
      >
        Cancel
      </Button>
      {currentStep === 0 && size(questionsForFund) > 0 && (
        <Button
          type="primary"
          onClick={() => {
            setCurrentStep(1)
          }}
          disabled={!canSendMeetingRequest}
        >
          Next
        </Button>
      )}
      {currentStep === 1 && (
        <Button
          type="primary"
          onClick={() => {
            setCurrentStep(0)
          }}
        >
          Previous
        </Button>
      )}
      {(size(questionsForFund) === 0 || currentStep === 1) && (
        <Button
          loading={savingInProggress}
          type="primary"
          disabled={getDisabledSendRequest()}
          onClick={() => handleOk()}
        >
          Send Meeting Request
        </Button>
      )}
    </div>
  )
  return (
    <Modal
      centered
      className="FundOnlineMR"
      visible={visible}
      width="628px"
      title="Request a Meeting"
      footer={footer}
      onCancel={() => {
        setToParticipants([allocator.contactId])
        setParticipants([userId])
        setQuestionsForFund([])
        setCurrentStep(0)
        onClose()
      }}
      bodyStyle={{ padding: 0 }}
    >
      <Loading
        spinning={loadingQuestions || (isNil(fundId) && hasManageFundsAccess)}
      >
        {currentStep === 0 && (
          <div>
            <div style={{ padding: '16px 32px 0px' }}>
              {hasManageFundsAccess && (
                <FundSelectForMeetingRequest
                  textOnly={isEmpty(funds)}
                  funds={funds}
                  selectedFund={fund}
                  onChangeFund={setFundId}
                />
              )}
              {!isEmpty(roadshowList) &&
                meetingTypeId === 2 &&
                fromMapView &&
                (roadshowId === 0 || isNil(roadshowId)) && (
                  <div className="cc-tabletitle-text cc-darkest-grey-text">
                    <div style={{ marginTop: '16px' }}>Roadshow Event</div>
                    <div style={{ display: 'flex', width: '70%' }}>
                      <Select
                        className="cc-background-input"
                        value={selectedRoadshowId}
                        style={{ width: '100%', marginTop: '16px' }}
                        placeholder="Select Roadshow event"
                        onChange={(e) => {
                          setSelectedRoadshowId(e)
                        }}
                      >
                        {roadshowList &&
                          map(roadshowList, (r) => (
                            <Select.Option
                              key={r.roadShowId}
                              value={r.roadShowId}
                            >
                              {r.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </div>
                  </div>
                )}
            </div>

            <div className="FundOnlineMR-participants">
              <Loading spinning={loadingContacts}>
                <div>
                  <div className="cc-heading4 cc-darkest-grey-text">
                    {allocator.companyName}
                  </div>

                  <div className="cc-check-participants-wrapper">
                    {contacts &&
                      toParticipants &&
                      map(contacts, (contact) => (
                        <ParticipantsCheckbox
                          key={contact.contactId}
                          {...contact}
                          position={contact.jobTitle}
                          participants={toParticipants}
                          checked={handleToParticipantChecked}
                          disabled={contact.contactId === allocator.contactId}
                        />
                      ))}
                  </div>
                  {contacts?.length > 1 && (
                    <Button
                      style={{ padding: '8px 0px' }}
                      type="link"
                      onClick={() => {
                        toParticipants?.length > 1
                          ? setToParticipants([allocator.contactId])
                          : setToParticipants(map(contacts, (c) => c.contactId))
                      }}
                    >
                      {toParticipants?.length > 1
                        ? 'Deselect All'
                        : 'Select All'}
                    </Button>
                  )}
                </div>
                <div>
                  <div
                    style={{ marginTop: '16px' }}
                    className="cc-heading4 cc-darkest-grey-text"
                  >
                    Your Colleagues
                  </div>
                  <div>
                    <div className="cc-check-participants-wrapper">
                      {myColleagues &&
                        map(myColleagues, (contact) => (
                          <ParticipantsCheckbox
                            key={contact.contactId}
                            participants={participants}
                            checked={handleParticipantChecked}
                            contactId={contact.contactId}
                            contactName={`${contact.firstName} ${contact.lastName}`}
                            imageUrl={contact.imageUrl}
                            position={contact.jobTitle}
                            disabled={contact.contactId === userId}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </Loading>
            </div>
            <div style={{ padding: '16px 32px' }}>
              <div className="cc-tabletitle-text cc-darkest-grey-text">
                Send Message
              </div>
              <Divider style={{ margin: '16px 0px' }} />
              <div style={{ width: '100%' }}>
                <TextArea
                  placeholder="Write a message..."
                  rows={4}
                  value={textMessage}
                  onChange={({ target: { value } }) => {
                    setMessage(value)
                  }}
                />
              </div>
              {company &&
                company.serviceProviderLevel.toLowerCase() === 'platinum' &&
                companyContextPoints &&
                companyContextPoints.contextPoints >= 1000 && (
                  <div style={{ width: '100%', marginTop: '24px' }}>
                    <Checkbox
                      style={{
                        width: '20px',
                      }}
                      checked={carryContextPoints}
                      onChange={() => {
                        setCarryContextPoints(!carryContextPoints)
                      }}
                    />
                    Send 1,000 Context Points
                  </div>
                )}
            </div>
          </div>
        )}
        {currentStep === 1 && (
          <div style={{ padding: '16px 32px' }}>
            <MeetingQuestionsForm
              questionsForFund={questionsForFund}
              answers={answers}
              setAnswers={setAnswers}
              loadingQuestions={loadingQuestions}
            />
          </div>
        )}
      </Loading>
    </Modal>
  )
}

DiscoverAllocatorMeetingRequest.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  allocator: PropTypes.object.isRequired,
  fund: PropTypes.string,
  selectedFundId: PropTypes.number,
  fundContacts: PropTypes.array,
  eventId: PropTypes.bool,
  onRefresh: PropTypes.func,
  roadshowId: PropTypes.number,
  meetingTypeId: PropTypes.number,
  fromMapView: PropTypes.bool,
  roadshowList: PropTypes.array,
  coordinates: PropTypes.array,
  arePointsNear: PropTypes.func.isRequired,
  funds: PropTypes.array,
}

export default DiscoverAllocatorMeetingRequest
