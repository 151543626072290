import { useState } from 'react'
import { Button } from '@context365/button'
import { CheckOutlined, CloseOutlined, MailOutlined } from '@context365/icons'
import { message } from 'antd'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import {
  getConversationValidation,
  sendMessageToConversation,
} from '~/actions/messages'
import { MEETING_STATUS } from '~/constants/meetingStatusIds'
import { MEETING_TYPE } from '~/constants/meetingTypeIds'
import { ROLE } from '~/constants/roles'
import useAuth from '~/hooks/useAuth'
import { getUserId } from '~/selectors/auth'
import { boldCompanyNameFormat } from '~/utils/helpers'
import { canLeaveMeeting } from '~/utils/meetingActions'
import AcceptMeetingComponent from '../AcceptMeetingComponent'
import CancelMeetingButton from '../CancelMeetingButton'
import MeetingInviteModal from '../MeetingInviteModal'
import MessageModal from '../MessageModal'
import RescheduleEventMeeting from '../RescheduleEventMeeting'
import CancelSummitMeeting from './CancelSummitMeeting'

const SummitMeetingActions = ({
  meeting,
  refreshMeeting,
  inviteModalVisible,
  setInviteModalVisible,
}) => {
  const { role } = useAuth()
  const contactId = useSelector(getUserId)
  const { trackEvent } = useTracking({ component: 'SummitMeetingActions' })

  const [messageModalVisible, setMessageModalVisible] = useState(false)
  const [showCancelSummitModal, setShowCancelSummitModal] = useState(false)
  const [showDeclineSummitMeeting, setShowDeclineSummitMeeting] =
    useState(false)
  const [showAcceptSummitRequestModal, setShowAcceptSummitRequestModal] =
    useState(false)
  const [needsHeadsUp, setNeedsHeadsUp] = useState(false)
  const [showRescheduleSummit, setShowRescheduleSummit] = useState(false)
  const [cancelText, setCancelText] = useState('Cancel')

  const meetingWithString = boldCompanyNameFormat(meeting)

  function trackClick(name, properties = {}) {
    trackEvent({ eventName: 'click', element: name, ...properties })
  }

  const handleSendMessageClick = () => {
    trackClick('SendMessage')
    if (
      !isNil(meeting.conversationId) &&
      meeting.conversationId > 0 &&
      role !== ROLE.CONTEXT &&
      role !== ROLE.ALLOCATOR
    ) {
      getConversationValidation(meeting.conversationId)
        .then((response) => {
          const validationResponse = response.data.result
          if (validationResponse.canSendMessage) {
            setNeedsHeadsUp(validationResponse.needsHeadsUp)
            setMessageModalVisible(true)
          } else {
            message.error(validationResponse.reason)
            setNeedsHeadsUp(false)
          }
        })
        .catch(() => {
          message.error(
            'There was an error while attempting to get conversation information'
          )
          setNeedsHeadsUp(false)
        })
    } else {
      setMessageModalVisible(true)
    }
    setNeedsHeadsUp(false)
  }

  const onSendMessage = (msg) => {
    const afterResponse = () => {
      message.success(
        <span>
          Message sent. <a href="/messages">Go to conversation</a>
        </span>
      )
      refreshMeeting()
    }

    const afterError = () => {
      message.error('There was an error while attempting to send the message')
    }

    const afterBoth = () => {
      setMessageModalVisible(false)
    }

    if (!isNil(meeting.conversationId)) {
      sendMessageToConversation(meeting.conversationId, msg)
        .then(afterResponse)
        .catch(afterError)
        .finally(afterBoth)
    }
  }

  const handleRescheduleMeetingClick = () => {
    trackClick('SendMessage', { MeetingType: 'Summit' })
    setShowRescheduleSummit(true)
  }

  const handleLeaveMeetingClick = () => {
    setCancelText('Leave')
    trackClick('LeaveMeeting', { MeetingType: 'Summit' })
    setShowCancelSummitModal(true)
  }

  const handleCancelMeetingClick = () => {
    setCancelText('Cancel')
    trackClick('CancelMeeting', { MeetingType: 'Summit' })
    setShowCancelSummitModal(true)
  }

  const handleAcceptMeetingClick = () => {
    trackClick('AcceptMeeting', { MeetingType: 'Summit' })
    setShowAcceptSummitRequestModal(true)
  }

  const handleDeclineMeetingClick = () => {
    trackClick('DeclineMeeting', { MeetingType: 'Summit' })
    setShowDeclineSummitMeeting(true)
  }

  const categoryName = meeting.categoryName || meeting.company.categoryName //inconsistency between DTOs
  const userParticipant = find(
    meeting.colleagues,
    (colleague) => colleague.contactId === contactId
  )
  const canLeave = canLeaveMeeting(
    meeting.colleagues,
    meeting.participants,
    meeting.meetingType,
    meeting.isVirtual
  )

  const showAcceptDeclineMeeting =
    (meeting.meetingStatusId === MEETING_STATUS.PENDING ||
      meeting.meetingStatusId === MEETING_STATUS.CONFIRMED) &&
    userParticipant?.participantStatusId === MEETING_STATUS.PENDING

  const isConfirmed =
    meeting.meetingStatusId === MEETING_STATUS.CONFIRMED &&
    userParticipant?.participantStatusId === MEETING_STATUS.CONFIRMED

  const isMeetingFuture =
    !isNil(meeting.meetingDateTime) &&
    moment(meeting.meetingDateTime).isAfter(moment.utc())

  const showSendMessage =
    meeting.conversationId > 0 &&
    userParticipant.participantStatusId === MEETING_STATUS.CONFIRMED &&
    (meeting.meetingStatusId === MEETING_STATUS.PENDING ||
      meeting.meetingStatusId === MEETING_STATUS.CONFIRMED)

  const showRescheduleMeeting =
    isConfirmed && !meeting.hasEventEnded && meeting.isOptimized

  const showLeaveMeeting = canLeave && isMeetingFuture && !meeting.hasEventEnded

  const showCancelMeeting =
    (meeting.isInitiator ||
      (meeting.meetingStatusId === MEETING_STATUS.PENDING &&
        userParticipant?.participantStatusId === MEETING_STATUS.CONFIRMED) ||
      (isConfirmed && !canLeave)) &&
    !meeting.hasEventEnded

  return (
    <div
      className="flex flex-wrap space-x-2 space-y-2 items-end"
      onClick={(e) => e.stopPropagation()}
    >
      {showSendMessage && (
        <Button
          onClick={() => handleSendMessageClick()}
          variant="filled"
          iconLeft={<MailOutlined />}
          size="small"
        >
          Send Message
        </Button>
      )}
      {showRescheduleMeeting && (
        <Button
          variant="outlined"
          status="secondary"
          onClick={() => handleRescheduleMeetingClick(meeting)}
          size="small"
        >
          Reschedule
        </Button>
      )}
      {showLeaveMeeting && (
        <Button
          variant="outlined"
          status="error"
          onClick={() => {
            handleLeaveMeetingClick(meeting)
          }}
          size="small"
        >
          Leave
        </Button>
      )}
      {showCancelMeeting && (
        <Button
          variant="outlined"
          status="error"
          onClick={() => {
            handleCancelMeetingClick(meeting)
          }}
          size="small"
        >
          Cancel
        </Button>
      )}
      {showAcceptDeclineMeeting && (
        <>
          <Button
            status="success"
            size="small"
            onClick={() => handleAcceptMeetingClick(meeting)}
            iconLeft={<CheckOutlined />}
          >
            Accept
          </Button>
          <Button
            status="error"
            size="small"
            onClick={() => handleDeclineMeetingClick(meeting)}
            iconLeft={<CloseOutlined />}
          >
            Decline
          </Button>
        </>
      )}

      <CancelSummitMeeting
        visible={showCancelSummitModal}
        closeModal={() => {
          setShowCancelSummitModal(false)
        }}
        cancelText={cancelText}
        meetingStatus={meeting.meetingStatus}
        company={meetingWithString}
        meetingType={meeting.meetingType}
        meetingId={meeting.meetingId}
        onCancel={() => {
          refreshMeeting()
        }}
        canDeclineMeeting={!canLeave}
        cancelForAll={
          meeting.isInitiator ||
          (meeting.meetingTypeId === MEETING_TYPE.SUMMIT &&
            !meeting.virtualSummit)
        }
      />
      <AcceptMeetingComponent
        visible={showAcceptSummitRequestModal}
        closeModal={() => {
          setShowAcceptSummitRequestModal(false)
        }}
        categoryName={categoryName}
        meetingType={meeting.meetingType}
        company={meetingWithString}
        meetingId={meeting.meetingId}
        eventId={meeting.eventId}
        meetingDateTime={meeting.meetingDateTime}
        onAccept={refreshMeeting}
        participants={
          meeting.participants
            ? meeting.participants.map((x) => x.contactId)
            : []
        }
        toParticipants={[contactId]}
        isVirtualSummit={meeting?.virtualSummit}
        isOptimized={meeting?.isOptimized}
        timezone={meeting?.meetingTimeZone || meeting?.timeZone}
      />
      <CancelMeetingButton
        visible={showDeclineSummitMeeting}
        closeModal={() => {
          setShowDeclineSummitMeeting(false)
        }}
        meetingType={meeting?.meetingType}
        company={boldCompanyNameFormat(meeting)}
        cancelText="Decline"
        meetingId={meeting?.meetingId}
        meetingStatus={meeting?.meetingStatus}
        onCancel={refreshMeeting}
      />
      <RescheduleEventMeeting
        visible={showRescheduleSummit}
        closeModal={() => setShowRescheduleSummit(false)}
        meetingId={meeting.meetingId}
        eventId={meeting.eventId}
        onRescheduleMeeting={refreshMeeting}
        meetingDateTime={meeting?.meetingDateTime}
        meetingDuration={meeting?.meetingDuration}
        timezone={meeting?.meetingTimeZone || meeting?.timeZone}
      />
      <MessageModal
        meeting={meeting}
        visible={messageModalVisible}
        onCancel={() => {
          setMessageModalVisible(false)
        }}
        onOk={onSendMessage}
        needsHeadsUp={needsHeadsUp}
      />
      <MeetingInviteModal
        meetingId={meeting.meetingId}
        onCancel={() => {
          setInviteModalVisible(false)
        }}
        onColleaguesInvited={refreshMeeting}
        visible={inviteModalVisible}
      />
    </div>
  )
}

export default SummitMeetingActions
