import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Radio, RadioGroup } from '@context365/forms'
import forEach from 'lodash/forEach'
import isNil from 'lodash/isNil'
import map from 'lodash/map'

const RadioField = ({
  name,
  value,
  setFieldValue,
  options,
  getOptionsMethod,
  clearFieldsOnChange = [],
  mappingMethod,
  hintText,
}) => {
  const [radioOptions, setRadioOptions] = useState([])

  useEffect(() => {
    let mappedOptions = []

    if (isNil(options)) {
      getOptionsMethod().then((res) => {
        mappedOptions = mappingMethod(res.data.result)
        setRadioOptions(mappedOptions)
      })
    } else {
      mappedOptions = isNil(mappingMethod)
        ? map(options, (x) => ({ label: x.name, value: x.id }))
        : mappingMethod(options)

      setRadioOptions(mappedOptions)
    }
  }, [])

  const handleChange = (val) => {
    setFieldValue(name, val)
    forEach(clearFieldsOnChange, (x) => setFieldValue(x, null))
  }

  return (
    <>
      {hintText && <div className="mb-2">{hintText}</div>}
      <RadioGroup
        name={name}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        className="space-y-2"
      >
        {map(radioOptions, (opt) => (
          <div key={opt.value}>
            <Radio value={opt.value}>{opt.label}</Radio>
          </div>
        ))}
      </RadioGroup>
    </>
  )
}

RadioField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.bool]),
  setFieldValue: PropTypes.func,
  options: PropTypes.array,
  getOptionsMethod: PropTypes.func,
  clearFieldsOnChange: PropTypes.arrayOf(PropTypes.string),
  mappingMethod: PropTypes.func,
  hintText: PropTypes.string,
}

export default RadioField
