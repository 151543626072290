import PropTypes from 'prop-types'
import { Switch } from 'antd'
import '../DiscoverFilters.less'

const ToggleFilter = ({ id, label, group, value = [], onChange }) => {
  const handleChange = (isToggled) => {
    onChange({
      value: isToggled,
      id,
      group,
      label,
      type: 'Check',
    })
  }

  const checked = value[0] && (value[0] === true || value[0] === 'true')

  return (
    <div className="flex justify-between">
      <div className="DiscoverFilter-header">{label}</div>
      <Switch
        className="ml-auto self-center"
        size="small"
        checked={checked}
        onChange={handleChange}
      />
    </div>
  )
}
ToggleFilter.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
}

export default ToggleFilter
