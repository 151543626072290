import * as React from 'react'
import { useState } from 'react'
import {
  CheckOutlined,
  CloseOutlined,
  Launch,
  MeetingRoomOutlined,
  ScheduleOutlined,
} from '@context365/icons'
import { Menu, MenuButton } from '@context365/menu'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import { MEETING_STATUS } from '~/constants/meetingStatusIds'
import { MEETING_TYPE } from '~/constants/meetingTypeIds'
import useAuth from '~/hooks/useAuth'
import { getUserId } from '~/selectors/auth'
import { boldCompanyNameFormat } from '~/utils/helpers'
import DeclineOnlineMeeting from '../DeclineOnlineMeeting'
import MeetingInviteModal from '../MeetingInviteModal'
import DownloadInvitationButton from '../Meetings/DownloadInvitationButton'
import AcceptOnlineMeeting from './AcceptOnlineMeeting'
import CancelOnlineMeeting from './CancelOnlineMeeting'
import RescheduleOnlineMeeting from './RescheduleOnlineMeeting'

const OnlineMeetingActions = ({
  meeting,
  refreshMeeting,
  inviteModalVisible,
  setInviteModalVisible,
}) => {
  const { role } = useAuth()
  const contactId = useSelector(getUserId)
  const { trackEvent } = useTracking({ component: 'OnlineMeetingActions' })
  function trackClick(name, properties = {}) {
    trackEvent({ eventName: 'click', element: name, ...properties })
  }

  const [showAcceptOnlineRequestModal, setShowAcceptOnlineRequestModal] =
    React.useState(false)
  const [showDeclineOnlineModal, setShowDeclineOnlineModal] =
    React.useState(false)
  const [showCancelOnlineModal, setShowCancelOnlineModal] = useState(false)
  const [showRescheduleOnline, setShowRescheduleOnline] = useState(false)

  const handleAcceptMeetingClick = () => {
    trackClick('AcceptMeeting', { MeetingType: 'Online' })
    setShowAcceptOnlineRequestModal(true)
  }

  const handleDeclineMeetingClick = () => {
    trackClick('DeclineMeeting', { MeetingType: 'Online' })
    setShowDeclineOnlineModal(true)
  }

  const handleCancelMeetingClick = () => {
    trackClick('CancelMeeting', { MeetingType: 'Online' })
    setShowCancelOnlineModal(true)
  }

  const handleRescheduleMeetingClick = () => {
    setShowRescheduleOnline(true)
    trackClick('SendMessage', { MeetingType: 'Online' })
  }

  const userParticipant = find(
    meeting.colleagues,
    (colleague) => colleague.contactId === contactId
  )

  const isPendingForParticipant =
    meeting.meetingStatusId === MEETING_STATUS.PENDING ||
    (userParticipant?.participantStatusId === MEETING_STATUS.PENDING &&
      meeting.meetingStatusId === MEETING_STATUS.CONFIRMED)
  const isConfirmed =
    meeting.meetingStatusId === MEETING_STATUS.CONFIRMED &&
    userParticipant?.participantStatusId === MEETING_STATUS.CONFIRMED
  const isMeetingActive =
    meeting.meetingStatusId === MEETING_STATUS.PENDING ||
    meeting.meetingStatusId === MEETING_STATUS.CONFIRMED
  const isMeetingUnscheduled = isEmpty(meeting.meetingDateTime)
  const isMeetingFuture =
    !isMeetingUnscheduled &&
    moment(meeting.meetingDateTime).isAfter(moment.utc())
  const isMeetingPast =
    !isMeetingUnscheduled &&
    moment(meeting.meetingDateTime).isBefore(moment.utc())

  const showIcsDownload =
    meeting.isParticipant &&
    meeting.meetingTypeId === MEETING_TYPE.ONLINE &&
    meeting.meetingStatusId === MEETING_STATUS.CONFIRMED &&
    userParticipant.participantStatusId === MEETING_STATUS.CONFIRMED &&
    isMeetingFuture
  const showJoinMeeting = meeting.startsSoon
  const showRescheduleMeeting = isConfirmed && isMeetingFuture
  const showCancelMeeting =
    meeting.isInitiator && !isMeetingPast && isMeetingActive
  const showAcceptMeeting =
    !meeting.isInitiator && isPendingForParticipant && !isMeetingPast
  const showDeclineMeeting =
    !meeting.isInitiator && !isMeetingPast && isMeetingActive
  const showConversationLink = meeting.conversationId > 0
  const showProposeTimeButton =
    isConfirmed && isMeetingUnscheduled && meeting.conversationId > 0

  const actionsDisabled =
    !showJoinMeeting &&
    !showRescheduleMeeting &&
    !showCancelMeeting &&
    !showAcceptMeeting &&
    !showDeclineMeeting &&
    !showConversationLink &&
    !showProposeTimeButton

  return (
    <div
      className="flex flex-wrap space-x-2 space-y-2 items-end"
      onClick={(e) => e.stopPropagation()}
    >
      <Menu
        trigger={
          <MenuButton variant="filled" size="small" disabled={actionsDisabled}>
            Actions
          </MenuButton>
        }
      >
        {showJoinMeeting && (
          <Menu.Item
            as={Link}
            target="_blank"
            to="/meeting-lobby"
            className="text-black"
          >
            <MeetingRoomOutlined className="mr-2 text-green-100" />
            Join Meeting
          </Menu.Item>
        )}
        {showAcceptMeeting && (
          <Menu.Item
            onClick={() => handleAcceptMeetingClick(meeting)}
            className="text-black"
          >
            <CheckOutlined className="mr-2 text-green-100" />
            Accept
          </Menu.Item>
        )}
        {showDeclineMeeting && (
          <Menu.Item
            onClick={() => handleDeclineMeetingClick(meeting)}
            className="text-black"
          >
            <CloseOutlined className="mr-2 text-red-100" />
            Decline
          </Menu.Item>
        )}
        {showRescheduleMeeting && (
          <Menu.Item
            onClick={() => handleRescheduleMeetingClick(meeting)}
            className="text-black"
          >
            <ScheduleOutlined className="mr-2 text-gold-100" />
            Reschedule
          </Menu.Item>
        )}
        {showCancelMeeting && (
          <Menu.Item
            onClick={() => {
              handleCancelMeetingClick(meeting)
            }}
            className="text-black"
          >
            <CloseOutlined className="mr-2 text-red-100" />
            Cancel meeting
          </Menu.Item>
        )}
        {meeting.conversationId > 0 && (
          <Menu.Item
            as={Link}
            to={`/messages/${meeting.conversationId}`}
            className="text-black"
          >
            <Launch className="mr-2" />
            Go to conversation
          </Menu.Item>
        )}
        {showProposeTimeButton && (
          <Menu.Item
            as={Link}
            to={`/messages/${meeting.conversationId}`}
            className="text-black"
          >
            <ScheduleOutlined className="mr-2 text-gold-100" />
            Schedule meeting
          </Menu.Item>
        )}
        {showIcsDownload && (
          <DownloadInvitationButton meeting={meeting} asMenuItem={true} />
        )}
      </Menu>

      <CancelOnlineMeeting
        visible={showCancelOnlineModal}
        closeModal={() => {
          setShowCancelOnlineModal(false)
        }}
        meetingStatus={meeting.meetingStatus}
        company={boldCompanyNameFormat(meeting)}
        meetingType={meeting.meetingType}
        meetingId={meeting.meetingId}
        onCancel={() => {
          refreshMeeting()
        }}
      />
      <AcceptOnlineMeeting
        visible={showAcceptOnlineRequestModal}
        closeModal={() => {
          setShowAcceptOnlineRequestModal(false)
        }}
        company={boldCompanyNameFormat(meeting)}
        meetingId={meeting?.meetingId}
        onAccept={refreshMeeting}
        meetingDateTime={meeting?.meetingDateTime}
        role={role}
        haveAnswered={meeting?.haveAnswered}
        roadshowId={meeting?.roadshowId}
      />
      <DeclineOnlineMeeting
        visible={showDeclineOnlineModal}
        closeModal={() => {
          setShowDeclineOnlineModal(false)
        }}
        company={boldCompanyNameFormat(meeting)}
        meetingId={meeting.meetingId}
        onCancel={() => {
          refreshMeeting()
        }}
        meetingType={meeting.meetingType}
      />
      <RescheduleOnlineMeeting
        visible={showRescheduleOnline}
        closeModal={() => setShowRescheduleOnline(false)}
        onlineMeetingId={meeting.meetingId}
        onRescheduleMeeting={refreshMeeting}
        meetingDateTime={meeting?.meetingDateTime}
        meetingDuration={meeting?.meetingDuration}
        roadshowId={meeting?.roadshowId}
      />
      <MeetingInviteModal
        meetingId={meeting.meetingId}
        onCancel={() => {
          setInviteModalVisible(false)
        }}
        onColleaguesInvited={refreshMeeting}
        visible={inviteModalVisible}
      />
    </div>
  )
}

export default OnlineMeetingActions
