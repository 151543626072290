import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import {
  AccountCircleOutlined,
  CheckCircle,
  ExpandMore,
  NotificationsOutlined,
} from '@context365/icons'
import { Menu } from '@context365/menu'
import cx from 'classnames'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import noop from 'lodash/noop'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { changeCompany, logout } from '~/actions/auth'
import { fetchConversationCount, fetchConversations } from '~/actions/messages'
import { loadNotificationsAndStats } from '~/actions/notifications'
import { FeatureFlags } from '~/config'
import { Enterprise, Free, Pro } from '~/constants/tiers'
import { getCompanyId, getUserCompanies, userIsGuest } from '~/selectors/auth'
import { generateFlagsIdentity } from '~/utils/flags'

const CompanySwitcher = ({ tier }) => {
  const {
    contact: { contactName, initials: userInitials, imageUrl },
    role,
  } = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  const selectedCompanyId = useSelector(getCompanyId)
  const companies = useSelector(getUserCompanies)
  const { push } = useHistory()
  const ldClient = useLDClient()
  const [hasImageError, setHasImageError] = useState(false)
  const queryClient = useQueryClient()
  const isGuest = useSelector(userIsGuest)

  const changeCompanyCb = (companyId) => {
    dispatch(changeCompany(companyId)).then(
      async ({ payload: { claims, contact, company } }) => {
        if (FeatureFlags.enabled) {
          const identity = generateFlagsIdentity(claims, contact, company)
          await ldClient.identify(identity)
        }

        queryClient.resetQueries()

        push('/')
        dispatch(fetchConversationCount())
        dispatch(fetchConversations())
        dispatch(loadNotificationsAndStats(true))
      }
    )
  }

  const logoutUser = useCallback(() => {
    queryClient.clear()
    dispatch(logout())
  }, [dispatch, queryClient])

  const getTagColor = (tier) => {
    switch (tier) {
      case Free:
        return 'default'
      case Pro:
        return 'warning'
      case Enterprise:
        return 'info'
      default:
        return undefined
    }
  }

  const tagName = role === 'CFN' ? 'CFN' : tier

  const avatar =
    isEmpty(imageUrl) || hasImageError ? (
      <div
        className="w-8 h-8 rounded-full bg-primary-100 text-white text-center align-middle mr-1"
        style={{ lineHeight: '2rem' }}
      >
        {userInitials}
      </div>
    ) : (
      <img
        style={{ borderRadius: '50px' }}
        alt=""
        src={imageUrl}
        width="30px"
        height="30px"
        onError={() => setHasImageError(true)}
      />
    )

  return (
    <div className="flex items-center cursor-pointer">
      <Menu
        trigger={
          <div className="flex items-center">
            {avatar}
            <ExpandMore />
          </div>
        }
      >
        <div className="p-4 border-b">
          {contactName}
          <Badge status={getTagColor(tier)} className="ml-2">
            {tagName}
          </Badge>
        </div>
        <Menu.Item as={Link} to="/settings/profile">
          <div className="type-body-semibold-md text-secondary-100 text-sm flex items-center">
            <AccountCircleOutlined className="mr-2" />
            Account Settings
          </div>
        </Menu.Item>
        {!isGuest && (
          <Menu.Item as={Link} to="/settings/communications">
            <div className="type-body-semibold-md text-secondary-100 text-sm flex items-center">
              <NotificationsOutlined className="mr-2" />
              Notification Settings
            </div>
          </Menu.Item>
        )}
        <div className="p-4 border-t">
          <div className="type-subtitle-md text-grey-500">Company</div>
        </div>
        {map(companies, (company) => {
          const isSelected = company.companyId === selectedCompanyId

          return (
            <Menu.Item
              key={company.companyId}
              className={cx('h-auto', isSelected && 'bg-secondary-2')}
              onClick={
                isSelected ? noop : () => changeCompanyCb(company.companyId)
              }
            >
              <div className="flex flex-row justify-between w-full items-center">
                <div className="flex flex-col">
                  <div className="type-body-semibold-sm">{company.name}</div>
                  <div className="text-xs">{company.category.categoryName}</div>
                </div>
                {isSelected && (
                  <div className="justify-end">
                    <CheckCircle size={20} className="text-green-100 ml-4" />
                  </div>
                )}
              </div>
            </Menu.Item>
          )
        })}
        <Menu.Item onClick={logoutUser}>
          <div className="type-body-semibold-sm text-red-100">Logout</div>
        </Menu.Item>
      </Menu>
    </div>
  )
}

CompanySwitcher.propTypes = {
  tier: PropTypes.string.isRequired,
}

export default CompanySwitcher
