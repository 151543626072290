import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import { api as http } from '~/api/services'
import CompanyProfileDetails from '../CompanyProfileDetails'
import DealProfileContainer from '../DealProfileContainer'
import './MeetingDetails.less'

const MeetingDetailsProfiles = ({ meeting, companies }) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState(
    companies[0].companyId
  )
  const [dealProfile, setDealProfile] = useState(null)

  const radioHandler = (e) => {
    setSelectedCompanyId(e.target.value)
  }
  const { dealId } = meeting
  const selectedCompany = companies.filter(
    (c) => c.companyId === selectedCompanyId
  )[0]

  useEffect(() => {
    dealId &&
      http.get(`deals/${dealId}`).then((response) => {
        setDealProfile(response.data.result.dealItem)
      })
  }, [dealId])

  return (
    <div style={{ marginBlock: '24px' }}>
      {dealProfile && (
        <div style={{ marginBottom: '32px' }}>
          <div className="cc-heading4" style={{ margin: '24px' }}>
            Deal Details
          </div>
          <div>
            <DealProfileContainer
              deal={dealProfile}
              showMeetings={false}
              hideActions
              showHeader
            />
          </div>
        </div>
      )}
      {companies.length > 1 && (
        <div className={meeting.dealId ? '' : 'cc-meeting-details-tabs'}>
          {meeting.dealId && (
            <span className="cc-heading4" style={{ margin: '24px' }}>
              Company Details
            </span>
          )}
          <div className="cc-meeting-details-tabs-radio-buttons">
            <Radio.Group
              buttonStyle="solid"
              size="large"
              defaultValue={selectedCompanyId}
              onChange={radioHandler}
            >
              {companies.map((company) => (
                <Radio.Button
                  value={company.companyId}
                  key={company.companyId.toString()}
                >
                  {company.companyName}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>
        </div>
      )}
      {meeting.dealId && companies.length === 1 && (
        <div className="cc-heading4" style={{ margin: '24px' }}>
          Company Details
        </div>
      )}
      <div style={{ marginTop: '24px' }}>
        <CompanyProfileDetails
          showAnswers={meeting.meetingType.toLowerCase() !== 'summit'}
          meetingId={meeting.meetingId}
          company={{
            categoryName: selectedCompany.category,
            ...selectedCompany,
          }}
          fundId={meeting.fundId}
        />
      </div>
    </div>
  )
}

MeetingDetailsProfiles.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      companyId: PropTypes.number.isRequired,
      companyName: PropTypes.string,
      category: PropTypes.string.isRequired,
      categoryId: PropTypes.number.isRequired,
      participants: PropTypes.arrayOf(
        PropTypes.shape({
          contactId: PropTypes.number.isRequired,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          contactName: PropTypes.string.isRequired,
          imageUrl: PropTypes.string,
        }).isRequired
      ).isRequired,
    })
  ).isRequired,
  meeting: PropTypes.shape({
    meetingType: PropTypes.string.isRequired,
    meetingId: PropTypes.number.isRequired,
    fundId: PropTypes.number,
    dealId: PropTypes.number,
  }),
}

export default MeetingDetailsProfiles
