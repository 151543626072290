import * as React from 'react'
import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import * as api from '~/api'

export default function useUpcomingEvent() {
  const [upcomingEvent, setUpcomingEvent] = React.useState(null)

  React.useEffect(() => {
    api.events.getEvents().then((response) => {
      const { upcomingEvents } = response.data.result

      const soonCutoffDate = moment.utc().add(60, 'day')
      const soonUpcomingEvents = filter(
        upcomingEvents,
        (ue) => ue.startDate && moment(ue.startDate).isBefore(soonCutoffDate)
      )

      if (!isEmpty(soonUpcomingEvents)) {
        const featured = find(soonUpcomingEvents, (event) => event.isFeatured)
        setUpcomingEvent(featured ?? soonUpcomingEvents[0])
      }
    })
  }, [])

  return upcomingEvent
}
