import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'
import moment from 'moment'
import numeral from 'numeral'
import { fetchFundDetails } from '~/actions/fundList'
import { numberFormat } from '~/utils/formatters'
import Loading from '../Loading'
import './FundDetailsDetails.less'

const FundDetailsValues = ({ fundId, useLoader }) => {
  const [fundDetails, setFundDetails] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetchFundDetails(fundId)
      .then((response) => {
        setLoading(false)
        const details = response.data.result
        for (let i = 0; i < details.length; i++) details[i].index = i
        setFundDetails(details)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [fundId])

  const transformValueByName = (value, key) => {
    if (isNil(value)) return ' '
    else
      switch (key) {
        case 'annualizedReturn':
        case 'annualizedVolatility':
          return numeral(value).format('0.0 %')
        case 'ytdReturn':
        case 'previousYtdReturn':
        case 'lastYtdReturn':
          return numberFormat.decimal(value, { precision: 2, suffix: '%' })
        case 'managementFee':
        case 'performanceFee':
          return numberFormat.decimal(value, { precision: 2, suffix: '%' })
        case 'sharpe':
        case 'sortino':
        case 'sterling':
          return numberFormat.decimal(value)
        case 'lengthOfLongestDrawdown':
          return value + (value === 1 ? ' month' : ' months')
        case 'redemptions':
          return value + (value === 1 ? ' day' : value > 1 ? ' days' : '')
        case 'deepestDrawdown':
          return numberFormat.decimal(value, { precision: 1, suffix: '%' })
        default:
          return value
      }
  }

  const transformValue = (value, valueType, key) => {
    if (valueType === 'Currency' || valueType === 'BoldCurrency') {
      value = numberFormat.currencyLarge(value)
    } else if (valueType === 'DateTime' || valueType === 'BoldDateTime') {
      value = moment.utc(value).local().format('YYYY-MM-DD')
    }

    return (
      <span className="fund-details-detail-type">
        {transformValueByName(value, key)}
      </span>
    )
  }

  return (
    <Loading spinning={loading && useLoader}>
      <div className="fund-details-values-container">
        <div className="fund-details-details-container">
          {fundDetails
            .filter((x) => x.index % 2 === 0)
            .map((x) => (
              <div key={x.index} className="fund-details-details-section">
                <div className="red-100 type-subtitle-md border-b">
                  {x.groupName}
                </div>
                <div
                  className="fund-details-details-data-section"
                  style={{
                    flexDirection: x.displayMode === 0 ? 'column' : 'row',
                  }}
                >
                  {x.sections.map((s) => (
                    <div
                      key={s.key}
                      className="fund-details-detail"
                      style={{
                        width: `${
                          x.displayMode === 0
                            ? 100
                            : (100 / x.sections.length).toString()
                        }%`,
                        display: 'inline-block',
                      }}
                    >
                      <div className="type-body-semibold-sm py-1">
                        {s.label}
                      </div>
                      <div className="type-body-regular-sm">
                        {transformValue(s.value, s.valueTypeStr, s.key)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>

        <div className="fund-details-details-container">
          {fundDetails
            .filter((x) => x.index % 2 === 1)
            .map((x) => (
              <div key={x.index} className="fund-details-details-section">
                <div className="type-subtitle-md border-b">{x.groupName}</div>
                <div
                  className="fund-details-details-data-section"
                  style={{
                    flexDirection: x.displayMode === 0 ? 'column' : 'row',
                  }}
                >
                  {x.sections.map((s) => (
                    <div
                      key={s.key}
                      className="fund-details-detail"
                      style={{
                        width: `${
                          x.displayMode === 0
                            ? 100
                            : (100 / x.sections.length).toString()
                        }%`,
                      }}
                    >
                      <div className="type-body-semibold-sm py-1">
                        {s.label}
                      </div>
                      <div className="type-body-regular-sm">
                        {transformValue(s.value, s.valueTypeStr, s.key)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Loading>
  )
}
FundDetailsValues.propTypes = {
  fundId: PropTypes.number.isRequired,
  useLoader: PropTypes.bool,
}
export default FundDetailsValues
