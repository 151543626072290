import PropTypes from 'prop-types'
import { Image } from 'antd'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import FileCollection from '~/components/FileCollection'
import CompanyMediaTypes from '~/constants/companyMediaTypes'

const Video = ({ url }) => (
  <video
    autoPlay={false}
    width="100%"
    muted={false}
    style={{
      borderRadius: '8px',
      boxShadow: '3px 3px 10px rgba(186, 186, 201, 0.15)',
    }}
    controls
  >
    <source src={url} />
  </video>
)

const CompanyProfileMedia = ({ companyId, media, attachments }) => {
  const files = map(attachments, (a) => {
    return {
      mediaUrl: a,
    }
  })

  return (
    <>
      {!isEmpty(media) && (
        <Image.PreviewGroup>
          <div className="grid grid-cols-2">
            {media[0].companyMediaTypeId === CompanyMediaTypes.Video ? (
              <Video url={media[0].url} />
            ) : (
              <Image alt="company media" src={media[0].url} />
            )}
            <div className="grid grid-cols-2 gap-2">
              {map(media, (m, i) =>
                i > 0 ? (
                  m.companyMediaTypeId === CompanyMediaTypes.Video ? (
                    <Video url={m.url} />
                  ) : (
                    <div>
                      <Image alt="company media" src={m.url} />
                    </div>
                  )
                ) : null
              )}
            </div>
          </div>
        </Image.PreviewGroup>
      )}
      {!isEmpty(files) && (
        <div className="mt-4">
          <FileCollection
            files={files}
            type="company"
            entityId={companyId}
            title="Company Attachments"
          />
        </div>
      )}
    </>
  )
}

CompanyProfileMedia.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      companyMediaTypeId: PropTypes.number,
      url: PropTypes.string,
    })
  ),
  attachments: PropTypes.arrayOf(PropTypes.string),
  companyId: PropTypes.number,
}

export default CompanyProfileMedia
