import { Component } from 'react'
import PropTypes from 'prop-types'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tabs, message } from 'antd'
import sumBy from 'lodash/sumBy'
import { connect } from 'react-redux'
import { createNewPorfolio } from '~/actions/portfolio'
import PortfolioAllocationsTable from '~/components/CCPortfolioAllocationsTable'
import PortfolioCard from '~/components/CCPortfolioCard'
import PortfolioTable from '~/components/CCPortfolioTable'
import CreatePortfolio from '~/components/CCPortofolioModal'
import CenteredPrimaryRadioButtons from '~/components/CenteredPrimaryRadioButtons'

const { TabPane } = Tabs

const BUTTONS = [
  {
    value: '1',
    text: 'portfolios',
  },
  {
    value: '2',
    text: 'allocations',
  },
]

class PortfolioContainer extends Component {
  state = {
    activeTab: '1',
    createPortfolioModal: false,
    newPortofolioName: '',
    refreshData: false,
  }
  static propTypes = {
    portfolioList: PropTypes.array.isRequired,
  }

  radioHandler = (e) => {
    this.setState({
      activeTab: e.target.value,
    })
  }

  formatNumber = (num) =>
    num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

  handleOk = () => {
    const { newPortofolioName } = this.state
    if (newPortofolioName !== '') {
      createNewPorfolio(newPortofolioName).then(() => {
        message.success('New portfolio created')
        this.handleCancel()
        this.setState((state) => ({
          refreshData: !state.refreshData,
        }))
      })
    }
  }

  handleCancel = () => {
    this.setState((state) => ({
      createPortfolioModal: !state.createPortfolioModal,
    }))
  }

  handleChange = (val) => {
    this.setState({
      newPortofolioName: val,
    })
  }

  render() {
    const totalInvestment = sumBy(this.props.portfolioList, 'initialInvestment')
    const totalValue = sumBy(this.props.portfolioList, 'currentInvestment')
    const change = sumBy(this.props.portfolioList, 'profitLoss')
    const percentageChange = sumBy(this.props.portfolioList, 'change')
    return (
      <>
        <PortfolioCard
          totalInvestment={this.formatNumber(totalInvestment)}
          totalValuation={this.formatNumber(totalValue)}
          change={this.formatNumber(change)}
          percentageChange={percentageChange}
        />
        <CenteredPrimaryRadioButtons
          selectedKey={this.state.activeTab}
          buttons={BUTTONS}
          OnClickFunction={this.radioHandler}
        />
        <Tabs
          animated={false}
          tabBarStyle={{ display: 'none' }}
          activeKey={this.state.activeTab}
        >
          <TabPane tab="Allocators" key="1">
            <PortfolioTable refreshData={this.state.refreshData} />
            <Button
              type="primary"
              className="company-colleagues-add"
              onClick={this.handleCancel}
            >
              <FontAwesomeIcon
                className="button-icon"
                icon={faPlus}
                size="1x"
              />
              Create New Portofolio
            </Button>{' '}
          </TabPane>
          <TabPane tab="Confirmed" key="2">
            <PortfolioAllocationsTable />
          </TabPane>
        </Tabs>
        <CreatePortfolio
          visible={this.state.createPortfolioModal}
          handleCancel={this.handleCancel}
          handleOk={this.handleOk}
          onChange={(val) => this.handleChange(val)}
        />
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  portfolioList: state.portfolio.portfolioList.result,
})

export default connect(mapStateToProps)(PortfolioContainer)
