import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Empty, Form, message } from 'antd'
import { Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import Loading from '~/components/Loading'
import StepList from '~/components/StepList'
import QuestionRow from './QuestionRow'
import './GenericForm.less'

const QuestionRows = ({
  title,
  questions,
  questionOptions,
  formProps,
  showTitle,
}) => {
  return (
    <>
      {showTitle && <div className="GenericForm-form-header">{title}</div>}
      <div className="GenericForm-form-container space-y-4" id="scrollTop">
        {map(questions(formProps.values, questionOptions), (question) => (
          <QuestionRow key={question.name} {...question} {...formProps} />
        ))}
      </div>
    </>
  )
}

const GenericForm = ({
  onSave,
  defaultValues,
  getInitialValues,
  steps,
  defaultStep = 0,
  questionOptions = null,
}) => {
  const [currentStep, setCurrentStep] = useState(defaultStep)
  const [stepsInError, setStepsInError] = useState([])
  const [initialValues, setInitialValues] = useState(defaultValues)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (!isNil(getInitialValues)) {
      setLoading(true)
      getInitialValues()
        .then((data) => {
          if (!isNil(data)) {
            setInitialValues(data)
          }
        })
        .finally(() => setLoading(false))
    }
  }, [getInitialValues])

  const scrollToTop = useCallback(() => {
    document.getElementById('scrollTop')?.scrollTo?.(0, 0)
  }, [])

  const onNavigation = (step) => {
    if (step >= 0 && step < steps.length) {
      setCurrentStep(step)
    }
    scrollToTop()
  }

  const getStepsInError = (vals) => {
    const errors = []
    for (let i = 0; i < steps.length; i++) {
      if (!isNil(steps[i].validator) && !steps[i].validator.isValidSync(vals)) {
        errors.push(i)
      }
    }

    return errors
  }

  const handleSave = (vals, formFinished) => {
    setSaving(true)
    const errors = getStepsInError(vals)
    if (formFinished) {
      if (isEmpty(errors)) {
        onSave(vals, true, () => setSaving(false))
      } else {
        message.error('Please check highlighted steps.')
        onSave(vals, false, () => setSaving(false))
      }
    } else {
      onSave(vals, false, () => setSaving(false))
    }
    setStepsInError(errors)
  }

  return loading ? (
    <Loading spinning={loading}>
      <Empty />
    </Loading>
  ) : (
    <div className="GenericForm">
      <Formik
        initialValues={initialValues}
        validationSchema={steps[currentStep].validator}
      >
        {(formikProps) => (
          <div className="GenericForm-row GenericForm-container">
            {!isEmpty(steps) && steps.length > 1 && (
              <div className="GenericForm-steps">
                <StepList
                  steps={map(steps, 'title')}
                  stepsInError={stepsInError}
                  current={currentStep}
                  onStepChange={(step) => {
                    if (!(formikProps.dirty && !formikProps.isValid)) {
                      onNavigation(step)
                      if (formikProps.dirty) {
                        handleSave(formikProps.values, false)
                      }
                    }
                  }}
                />
              </div>
            )}
            <div className="GenericForm-form">
              <Loading spinning={saving}>
                <Form onFinish={formikProps.handleSubmit}>
                  {!isNil(questionOptions) && (
                    <QuestionRows
                      title={steps[currentStep].title}
                      questionOptions={questionOptions}
                      questions={steps[currentStep].questions}
                      formProps={formikProps}
                      showTitle={steps.length > 1}
                    />
                  )}
                  <div className="GenericForm-form-footer">
                    {currentStep !== steps.length - 1 && (
                      <Button
                        variant="filled"
                        onClick={() => {
                          onNavigation(currentStep + 1)
                          if (formikProps.dirty) {
                            handleSave(formikProps.values, false)
                          }
                        }}
                      >
                        Next
                      </Button>
                    )}
                    {currentStep === steps.length - 1 && (
                      <Button
                        onClick={() => {
                          handleSave(formikProps.values, true)
                        }}
                        loading={loading}
                      >
                        Save
                      </Button>
                    )}
                    {steps.length > 1 && (
                      <Button
                        disabled={currentStep === 0}
                        onClick={() => {
                          onNavigation(currentStep - 1)
                          if (formikProps.dirty) {
                            handleSave(formikProps.values, false)
                          }
                        }}
                        variant="filled"
                      >
                        Back
                      </Button>
                    )}
                  </div>
                </Form>
              </Loading>
            </div>
          </div>
        )}
      </Formik>
    </div>
  )
}

GenericForm.propTypes = {
  defaultStep: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  defaultValues: PropTypes.object.isRequired,
  getInitialValues: PropTypes.func,
  questionOptions: PropTypes.object,
}

export default GenericForm
