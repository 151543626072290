import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faInfoCircle, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row, Tabs, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { getCurrentRoadshows, getRoadshows } from '~/actions/roadshow'
import CenteredPrimaryRadioButtons from '~/components/CenteredPrimaryRadioButtons'
import Loading from '~/components/Loading'
import AttendingRoadshow from './AttendingRoadshow'
import RoadshowDetail from './RoadshowDetail'
import RoadshowsTable from './RoadshowsTable'
import '~/styles/discoverLanding.less'
import './Roadshows.less'

const Roadshows = (props) => {
  const [showCreateRoadshowModal, setShowCreateRoadshowModal] = useState(false)
  //const userId = useSelector(state => getUserId(state));
  const isManager = props.userRole === 'Manager'
  const [refresh, setRefresh] = useState(false)
  const [activeTab, setActiveTab] = useState('upcoming')
  const [currentRoadshows, setCurrentRoadshows] = useState([])
  const [hasRoadshows, setHasRoadshows] = useState(false)

  const [loadingCurr, setLoadingCurr] = useState(true)

  const [loadingPastUpcoming, setLoadingPastUpcoming] = useState(true)

  const { TabPane } = Tabs

  const BUTTONS = [
    {
      value: 'upcoming',
      text: 'upcoming',
    },
    {
      value: 'past',
      text: 'past',
    },
  ]

  const radioHandler = (e) => {
    setActiveTab(e.target.value)
  }

  const onCreated = () => {
    setRefresh(!refresh)
    setHasRoadshows(true)
    setShowCreateRoadshowModal(false)
  }

  useEffect(() => {
    setLoadingPastUpcoming(true)
    //check past
    getRoadshows(true)
      .then((response) => {
        if (
          response.data.result.result &&
          response.data.result.result.length > 0
        ) {
          setLoadingPastUpcoming(false)
          setHasRoadshows(true)
        } else {
          setLoadingPastUpcoming(true)

          //check upcoming
          getRoadshows(false)
            .then((response) => {
              setHasRoadshows(
                response.data.result.result &&
                  response.data.result.result.length > 0
              )
              setLoadingPastUpcoming(false)
            })
            .catch(() => message.error('Could not load roadshows'))
        }
      })
      .catch(() => message.error('Could not load roadshows'))
  }, [])

  useEffect(() => {
    setLoadingCurr(true)
    getCurrentRoadshows()
      .then((response) => {
        setCurrentRoadshows(response.data.result)
      })
      .catch(() => message.error('Could not load roadshows'))
      .finally(() => setLoadingCurr(false))
  }, [refresh])

  return (
    <Loading spinning={loadingCurr || loadingPastUpcoming}>
      <RoadshowDetail
        visible={showCreateRoadshowModal}
        closeModal={() => {
          setShowCreateRoadshowModal(false)
        }}
        isManager={isManager}
        onCreated={onCreated}
      />
      {!loadingPastUpcoming && !loadingCurr && (
        <div className="roadshow-container">
          {(hasRoadshows ||
            (!isEmpty(currentRoadshows) && currentRoadshows.length !== 0)) && (
            <>
              <div className="roadshow-header">
                Your roadshows
                <Button
                  style={{ float: 'right', marginRight: '35px' }}
                  type="primary"
                  size="large"
                  onClick={() => {
                    setShowCreateRoadshowModal(true)
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="roadshow-emptybody-footer-icon"
                  />
                  Create roadshow
                </Button>
              </div>
              <div>
                <Row style={{ padding: '25px', width: '100%' }}>
                  {currentRoadshows.map((roadshow, index) => (
                    <Col
                      key={index}
                      span={currentRoadshows.length === 1 ? 24 : 12}
                      id={index}
                    >
                      <AttendingRoadshow
                        roadshow={roadshow}
                        oneEvent={currentRoadshows.length === 1}
                      />
                    </Col>
                  ))}
                </Row>
                <CenteredPrimaryRadioButtons
                  selectedKey={activeTab}
                  buttons={BUTTONS}
                  OnClickFunction={radioHandler}
                />
                <Row style={{ padding: '25px' }}>
                  <Tabs
                    animated={false}
                    tabBarStyle={{ display: 'none' }}
                    activeKey={activeTab}
                  >
                    <TabPane tab="UPCOMING" key="upcoming">
                      <RoadshowsTable
                        isPast={activeTab === 'past'}
                        //onLoad={onLoad}
                        refreshTable={refresh}
                      />
                    </TabPane>
                    <TabPane tab="PAST" key="past">
                      <RoadshowsTable
                        isPast={activeTab === 'past'}
                        refreshTable={refresh}
                      />
                    </TabPane>
                  </Tabs>
                </Row>
              </div>
            </>
          )}

          {!hasRoadshows &&
            isEmpty(currentRoadshows) &&
            currentRoadshows.length === 0 && (
              <>
                <div className="roadshow-header">Your roadshows</div>
                <Row style={{ paddingTop: '20px' }}>
                  <Col span={12} offset={6}>
                    <Row className="roadshow-emptybody-header">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#92949C"
                        size="3x"
                        className="roadshow-emptybody-icon"
                      />
                    </Row>
                    <Row className="roadshow-emptybody-header">
                      You don’t have roadshow yet
                    </Row>
                    <Row className="roadshow-emptybody-body">
                      You can create your first roadshow by using button below
                    </Row>
                    <Row className="roadshow-emptybody-footer">
                      <Button
                        type="primary"
                        size="large"
                        onClick={() => {
                          setShowCreateRoadshowModal(true)
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="roadshow-emptybody-footer-icon"
                        />
                        Create roadshow
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
        </div>
      )}
    </Loading>
  )
}

Roadshows.propTypes = {
  userRole: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  companyId: state.auth.company?.companyId,
  userId: state.auth.contact?.contactId,
  userRole: state.auth.role,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Roadshows)
