import PropTypes from 'prop-types'
import { Steps } from 'antd'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import map from 'lodash/map'

const { Step } = Steps

const StepList = ({
  className = null,
  steps,
  current = 0,
  onStepChange,
  stepsInError,
}) => {
  const classNames = ['StepList']
  if (!isNil(className)) {
    classNames.push(className)
  }
  const finalClassName = join(classNames, ' ')

  return (
    <div className={finalClassName}>
      <Steps current={current} direction="vertical" onChange={onStepChange}>
        {map(steps, (step, key) => (
          <Step
            key={key}
            title={step}
            description={step}
            status={stepsInError.includes(key) ? 'error' : ''}
          />
        ))}
      </Steps>
    </div>
  )
}

StepList.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  current: PropTypes.number,
  onStepChange: PropTypes.func,
  stepsInError: PropTypes.array,
}

export default StepList
