import PropTypes from 'prop-types'
import map from 'lodash/map'
import {
  formatCurrency,
  formatDate,
  formatDecimal,
  formatMonthYear,
  formatMultiple,
  formatPercent,
} from '~/utils/helpers'
import DiscoverCardStat from './DiscoverCardStat'

export const statFormat = {
  date: (data) => formatDate(data),
  monthYear: (data) => formatMonthYear(data),
  currency: (data) => formatCurrency(data),
  percent: (data) => formatPercent(data),
  decimal: (data) => formatDecimal(data),
  multiple: (data) => formatMultiple(data),
}

export const DiscoverCardStats = ({ items }) => (
  <div className="discover-card-stat-container">
    {map(items, (item, index) => (
      <DiscoverCardStat
        key={index}
        title={item.title}
        data={item.data}
        format={item.format}
      />
    ))}
  </div>
)

DiscoverCardStats.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      data: PropTypes.any,
      format: PropTypes.func,
    })
  ),
}
