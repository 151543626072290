import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Image, Row } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { getServiceProviderProfile } from '~/actions/serviceProviders'
import { addLineBreaks, getClickableLink } from '~/utils/helpers'
import Loading from '../Loading'
import './ServiceProviderProfile.less'

const Profile = ({ company, companyId = 0 }) => {
  const [serviceProviderInfo, setServiceProviderInfo] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!isEmpty(company)) {
      setServiceProviderInfo(company)
    } else if (companyId !== 0) {
      setLoading(true)
      getServiceProviderProfile(companyId)
        .then((response) => {
          setServiceProviderInfo(response.data.result)
        })
        .finally(() => setLoading(false))
    }
  }, [company, companyId])

  return (
    <Loading spinning={loading}>
      {!isEmpty(serviceProviderInfo) && (
        <div>
          <Row>
            <Col span={16}>
              <Col span={24}>
                <span className="type-subtitle-sm text-header">
                  Company Description
                </span>
                <div className="cc-profile-info">
                  {addLineBreaks(serviceProviderInfo.companyDescription)}
                </div>
              </Col>
              <Row className="media-row">
                {!isNil(serviceProviderInfo.companyServiceProvider.videoUrl) &&
                  !isEmpty(
                    serviceProviderInfo.companyServiceProvider.videoUrl
                  ) && (
                    <Col
                      span={
                        !isEmpty(
                          serviceProviderInfo.companyServiceProvider.images
                        )
                          ? 12
                          : 24
                      }
                    >
                      {' '}
                      <div className="video-column">
                        <video
                          autoPlay={false}
                          width="100%"
                          muted={false}
                          style={{
                            borderRadius: '8px',
                            boxShadow: '3px 3px 10px rgba(186, 186, 201, 0.15)',
                          }}
                          controls
                        >
                          <source
                            src={
                              serviceProviderInfo.companyServiceProvider
                                .videoUrl
                            }
                          />
                        </video>
                      </div>
                    </Col>
                  )}
                {!isEmpty(serviceProviderInfo.companyServiceProvider.images) &&
                  serviceProviderInfo.companyServiceProvider.images && (
                    <Col
                      span={
                        !isEmpty(
                          serviceProviderInfo.companyServiceProvider.videoUrl
                        )
                          ? 12
                          : 24
                      }
                    >
                      <Row>
                        <Image.PreviewGroup>
                          {serviceProviderInfo.companyServiceProvider.images.map(
                            (image, id) => (
                              <Col
                                key={id}
                                className="service-image-col"
                                span={
                                  !isEmpty(
                                    serviceProviderInfo.companyServiceProvider
                                      .videoUrl
                                  ) &&
                                  serviceProviderInfo.companyServiceProvider
                                    .images.length < 2
                                    ? 24
                                    : 12
                                }
                              >
                                <Image src={image} className="service-image" />
                              </Col>
                            )
                          )}
                        </Image.PreviewGroup>
                      </Row>
                    </Col>
                  )}
              </Row>
            </Col>
            <Col span={8}>
              <div>
                <span className="type-subtitle-sm text-header">Category</span>
                <div className="cc-profile-info">
                  {
                    serviceProviderInfo.companyServiceProvider
                      .serviceProviderCategory
                  }
                </div>
              </div>

              <div>
                <span className="type-subtitle-sm text-header">Website</span>
                <div className="cc-profile-info">
                  <a
                    href={getClickableLink(serviceProviderInfo.companyWebsite)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {serviceProviderInfo.companyWebsite}{' '}
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </Loading>
  )
}
Profile.propTypes = {
  company: PropTypes.shape({
    companyDescription: PropTypes.string.isRequired,
    companyServiceProvider: PropTypes.shape({
      serviceProviderCategory: PropTypes.string.isRequired,
    }).isRequired,
    companyWebsite: PropTypes.string.isRequired,
  }),
  companyId: PropTypes.number,
}
export default Profile
