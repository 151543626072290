import { useState } from 'react'
import PropTypes from 'prop-types'
import { NotificationsOutlined } from '@context365/icons'
import { Menu, MenuButton } from '@context365/menu'
import isEmpty from 'lodash/isEmpty'
import CreateSearchAlert from '../SearchAlert/CreateSearchAlert'
import ManageSearchAlert from '../SearchAlert/ManageSearchAlert'
import './DiscoverFilters.less'

const DiscoverAlerts = ({
  filter,
  searchTerm,
  alertDisabled = isEmpty(filter) && !searchTerm,
  setSearchState,
  category,
}) => {
  const [showSearchAlert, setShowSearchAlert] = useState(false)
  const [showModifyAlert, setShowModifyAlert] = useState(false)

  return (
    <>
      <Menu
        trigger={
          <MenuButton
            variant="outlined"
            size="small"
            iconLeft={<NotificationsOutlined />}
          >
            Alerts
          </MenuButton>
        }
      >
        <Menu.Item
          disabled={alertDisabled}
          onClick={() => setShowSearchAlert(true)}
        >
          Create Alert
        </Menu.Item>
        <Menu.Item onClick={() => setShowModifyAlert(true)}>
          Manage Alerts
        </Menu.Item>
      </Menu>
      <CreateSearchAlert
        visible={showSearchAlert}
        closeModal={() => setShowSearchAlert(false)}
        filters={filter}
        searchTerm={searchTerm}
        category={category}
      />
      <ManageSearchAlert
        visible={showModifyAlert}
        closeModal={() => setShowModifyAlert(false)}
        setSearchState={(searchFilters, searchText) => {
          setSearchState(searchFilters, searchText)
          setShowModifyAlert(false)
        }}
        category={category}
      />
    </>
  )
}

DiscoverAlerts.propTypes = {
  filter: PropTypes.array,
  searchTerm: PropTypes.string,
  alertDisabled: PropTypes.bool,
  setSearchState: PropTypes.func,
  category: PropTypes.string,
}

export default DiscoverAlerts
