import { Checkbox, DatePicker, Radio } from 'antd'
import DragDropContextForm from '../DragDropContextForm'
import FloatingInput from '../FloatingInput'
import FloatingSelect from '../FloatingSelect'
import FloatingTextArea from '../FloatingTextArea'
import './CompanyContactAllocator.less'

export const renderInput = (
  values,
  question,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  emailValue
) => {
  switch (question.questionType) {
    case 'Text - Single':
      return (
        <div className="cc-allocator-input">
          {question.shortName == 'Email' ? (
            <FloatingInput
              name={question.text}
              nameField={question.shortName}
              value={emailValue}
              onChange={(e) => {
                setFieldValue(question.shortName, e)
              }}
            />
          ) : (
            <FloatingInput
              name={question.text}
              nameField={question.shortName}
              onChange={(e) => {
                setFieldValue(question.shortName, e)
              }}
            />
          )}
        </div>
      )
    case 'Text - Multiple':
      return (
        <div className="cc-allocator-input">
          <FloatingTextArea
            name={question.text}
            nameField={question.shortName}
            onChange={(e) => {
              setFieldValue(question.shortName, e)
            }}
          />
        </div>
      )
    case 'Checkbox':
      return (
        <div>
          <div>
            <span className="cc-heading5">{question.text}</span>
          </div>
          <div className="cc-step2-section1">
            <Checkbox.Group
              options={getOptions(
                'checkbox',
                question.companyContactQuestionAnswers
              )}
              className="cc-step2-section1-checkbox flexcontainer"
            />
          </div>
        </div>
      )
    case 'Dropdown':
      return (
        <FloatingSelect
          name={question.text}
          id={question.shortName}
          key={question.shortName}
          options={getOptions(
            'dropdown',
            question.companyContactQuestionAnswers
          )}
          onChange={(e) => {
            setFieldValue(question.shortName, e)
          }}
        />
      )
    case 'Numeric - INT':
      return (
        <div className="cc-allocator-input">
          <FloatingInput
            name={question.text}
            nameField={question.shortName}
            onChange={(e) => {
              setFieldValue(question.shortName, e)
            }}
          />
        </div>
      )
    case 'Numeric - FLOAT':
      return (
        <div className="cc-allocator-input">
          <FloatingInput
            name={question.text}
            nameField={question.shortName}
            onChange={(e) => {
              setFieldValue(question.shortName, e)
            }}
          />
        </div>
      )
    case 'Boolean': {
      return (
        <div>
          <div className="cc-allocator-p">
            <span className="cc-body-text">{question.text}</span>
          </div>
          <div className="cc-allocator-radio">
            <Radio.Group
              onChange={(e) =>
                setFieldValue(question.shortName, e.target.value)
              }
              value={values[question.shortName]}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </div>
        </div>
      )
    }

    case 'Ranking': {
      return (
        <div>
          <div>
            <span className="cc-heading5">{question.text}</span>
          </div>
          <div
            style={{
              display: 'inline-flex',
              margin: 'auto',
            }}
          >
            <DragDropContextForm
              options={getOptions(
                'ranking',
                question.companyContactQuestionAnswers
              )}
            />
          </div>
        </div>
      )
    }

    case 'Date':
      return (
        <div className="cc-allocator-input">
          <DatePicker
            onChange={(date) => setFieldValue(question.shortName, date._d)}
            placeholder={question.text}
          />
        </div>
      )
    default:
      break
  }
}

export const getInitialValues = (questions) => {
  const answerIsArray = [3, 9, 10]
  const arrayofInitialstate = questions.map((q) => ({
    [q.shortName]: answerIsArray.includes(q.questionTypeId)
      ? []
      : q.questionTypeId === 7
      ? false
      : '',
  }))
  return Object.assign({}, ...arrayofInitialstate)
}

export const getOptions = (
  dropdownOrCheckbox,
  companyContactQuestionAnswers
) => {
  if (dropdownOrCheckbox === 'checkbox')
    return companyContactQuestionAnswers.map(
      (companyContactQuestionAnswer) => ({
        label: companyContactQuestionAnswer.answer,
        value: companyContactQuestionAnswer.companyContactQuestionAnswerId,
      })
    )
  else
    return companyContactQuestionAnswers.map(
      (companyContactQuestionAnswer) => ({
        value: companyContactQuestionAnswer.companyContactQuestionAnswerId,
        text: companyContactQuestionAnswer.answer,
      })
    )
}
