import PropTypes from 'prop-types'
import contextSignals from '~/constants/contextSignals'
import CheckboxFilter from './CheckboxFilter'
import '../DiscoverFilters.less'

function getContextSignal(id) {
  const contextSignal = contextSignals[id]
  if (!contextSignal) {
    return {}
  }

  const icon = contextSignal.icon ? (
    <contextSignal.icon
      className="DiscoverFilters-Checkbox-icon"
      fill={contextSignal.backgroundColor}
    />
  ) : null

  return {
    icon,
    tooltip: contextSignal.tooltip,
    popover: contextSignal.popover,
  }
}

const ContextSignalFilter = ({ id, label, group, value, onChange }) => {
  const { icon, tooltip, popover } = getContextSignal(id)

  return (
    <CheckboxFilter
      id={id}
      label={label}
      icon={icon}
      group={group}
      value={value}
      onChange={onChange}
      tooltip={tooltip}
      popover={popover}
    />
  )
}

ContextSignalFilter.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ContextSignalFilter
