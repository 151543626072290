/*
 * If this array is empty, its section in the modal will be hidden. Otherwise,
 * a random item will be displayed from the list.
 */
export const testimonials = [
  {
    text: 'Context has provided a platform to build relationships with a wide variety of allocators and investors. It has helped us share our story and track record and raise a significant amount of capital.',
    author: 'Managing Director, Fund',
  },

  /* Begin test data */
  /* IMPORTANT: These are fake quotes and should not be displayed in production! */
  ...(process.env.NODE_ENV === 'development'
    ? [
        {
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
          author: 'Chief Quotation Officer, Definitely a Real Company',
        },
      ]
    : []),
]

/*
 * If this array is empty, its section in the modal will be hidden. Otherwise,
 * an image will be displayed for each URL in the list. All images are
 * displayed at a set height and the width adjusts to maintain aspect ratio.
 */
export const customerLogos = [
  /* Begin test data */
  /* IMPORTANT: These images were randomly pulled from the web and should not be displayed in production! */
  ...(process.env.NODE_ENV === 'development'
    ? [
        'https://th.bing.com/th/id/OIP.KlkBlgljE5McdSjL7aZWEAAAAA?pid=ImgDet&rs=1',
        'https://th.bing.com/th/id/R.e730c09a7a534f5f39dc8c94a49826df?rik=fQ3qfO3ftQyJyA&pid=ImgRaw&r=0',
        'https://th.bing.com/th/id/R.3453922e02205c646a6046aebe70b780?rik=SzBLeytKMtE5zQ&pid=ImgRaw&r=0',
        'https://th.bing.com/th/id/R.06bb4b4f735ac95f0a65bdafc5e75599?rik=cUZLxjEeQCy%2blA&riu=http%3a%2f%2fimg2.wikia.nocookie.net%2f__cb20120822024149%2flogopedia%2fimages%2f7%2f72%2fPrudential_Financial.svg.png&ehk=Zamvr7DkgL2elZSHk1c8bwAzmZYVvZTlwXprsdFm2PM%3d&risl=&pid=ImgRaw&r=0',
        'https://th.bing.com/th/id/OIP.HPKuc2OJ_YE760lNzRd0DAHaD3?pid=ImgDet&rs=1',
      ]
    : []),
]
