import PropTypes from 'prop-types'
import {
  CheckOutlined,
  Close,
  DoNotDisturbOnOutlined,
  HelpOutlineOutlined,
  QueryBuilder,
} from '@context365/icons'
import { Avatar, Badge, Popover } from 'antd'
import cs from 'classnames'
import './AvatarBadgeGroup.less'

const AvatarBadgeGroup = ({ avatars, organizerId, className }) => {
  const showBadgeIcon = (participantStatusId) => {
    switch (participantStatusId) {
      case 1:
        return (
          <div className="flex items-center rounded-full justify-center w-4 h-4 bg-gold-100 border border-solid border-white">
            <HelpOutlineOutlined className="text-white" />
          </div>
        )
      case 2:
        return (
          <div className="flex items-center rounded-full justify-center w-4 h-4 bg-green-100 border border-solid border-white">
            <CheckOutlined className="text-white" />
          </div>
        )
      case 3:
        return (
          <div className="flex items-center rounded-full justify-center w-4 h-4 bg-red-100 border border-solid border-white">
            <DoNotDisturbOnOutlined className="text-white" />
          </div>
        )
      case 4:
        return (
          <div className="flex items-center rounded-full justify-center w-4 h-4 bg-red-100 border border-solid border-white">
            <Close className="text-white" />
          </div>
        )
      case 5:
        return (
          <div className="flex items-center rounded-full justify-center w-4 h-4 bg-grey-500 border border-solid border-white">
            <QueryBuilder className="text-white" />
          </div>
        )
    }
  }

  const showBadgeTitle = (participantStatusId) => {
    switch (participantStatusId) {
      case 1:
        return 'Pending'
      case 2:
        return 'Confirmed'
      case 3:
        return 'Declined'
      case 4:
        return 'Cancelled'

      case 5:
        return 'Expired'
    }
  }

  return (
    <div className={cs('AvatarGroup', className)}>
      <Avatar.Group
        maxCount={avatars.length > 3 ? 2 : 3}
        size="large"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {avatars.map((avatar, id) => (
          <Popover
            key={avatar.id}
            overlayClassName="AvatarGroup-popover AvatarGroup-popover--image"
            content={
              <div style={{ textAlign: 'center', color: 'white' }}>
                {avatar.description} -{' '}
                {showBadgeTitle(avatar.participantStatusId)}
                {avatar.id === organizerId && <i> (Organizer)</i>}
              </div>
            }
          >
            <div
              className="AvatarGroup-badge-avatar"
              style={{
                marginBottom: id > 1 && avatars.length > 3 ? '4px' : '0px',
              }}
            >
              <Badge
                offset={[-5, 30]}
                count={showBadgeIcon(avatar.participantStatusId)}
              >
                <Avatar
                  style={{
                    border: avatar.id === organizerId && '3px solid #2FC5D2',
                  }}
                  src={avatar.img}
                >
                  {avatar.initials}
                </Avatar>
              </Badge>
              {id > 1 && avatars.length > 3 && (
                <div style={{ marginLeft: '2px', color: '#7F848A' }}>
                  {avatar.description}{' '}
                </div>
              )}
            </div>
          </Popover>
        ))}
      </Avatar.Group>
    </div>
  )
}

AvatarBadgeGroup.propTypes = {
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      img: PropTypes.string,
      description: PropTypes.string,
      initials: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      participantStatusId: PropTypes.number,
    }).isRequired
  ),

  organizerId: PropTypes.number,
}

export default AvatarBadgeGroup
