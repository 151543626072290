import {
  COMPANY_FETCHING,
  COMPANY_FETCH_FAILURE,
  COMPANY_FETCH_SUCCESS,
} from '~/constants/types/company'

const initialState = {
  isFetching: false,
  company: null,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case COMPANY_FETCHING:
      return { ...state, isFetching: true }
    case COMPANY_FETCH_SUCCESS:
      return { ...state, company: action.payload, isFetching: false }
    case COMPANY_FETCH_FAILURE:
      return { ...state, error: action.payload, isFetching: false }
    default:
      return state
  }
}
