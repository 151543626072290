import PropTypes from 'prop-types'
import { FormLabel, FormMessage, Radio, RadioGroup } from '@context365/forms'
import cx from 'classnames'
import { useController } from 'react-hook-form'
import { useFieldSchema } from '../Form'
import {
  getLabel,
  getValue,
  optionShape,
  useOptionColumns,
} from './option-utils'

export function RadioGroupField({
  name,
  label,
  helpMessage,
  options,
  columns,
  inline = false,
  ...props
}) {
  const {
    field,
    fieldState: { error },
  } = useController({ name })
  const rules = useFieldSchema(name, label)

  return (
    <RadioGroup as="fieldset" {...props} {...field}>
      {label && (
        <FormLabel as="legend" required={rules.required}>
          {label}
        </FormLabel>
      )}
      <FormMessage helpMessage={helpMessage} errorMessage={error?.message} />

      <div
        className={cx(inline && 'flex flex-wrap gap-4')}
        style={{
          columns: useOptionColumns({ options, columns: inline ? 1 : columns }),
        }}
      >
        {options.map((option) => (
          <Radio
            key={getValue(option)}
            className={cx(!inline && 'block')}
            value={getValue(option)}
          >
            {getLabel(option)}
          </Radio>
        ))}
      </div>
    </RadioGroup>
  )
}

RadioGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpMessage: PropTypes.string,
  options: PropTypes.arrayOf(optionShape).isRequired,
  columns: PropTypes.number,
  inline: PropTypes.bool,
}
