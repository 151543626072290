export const downloadFile = (blobData, fileName) => {
  const blob = new Blob([blobData])

  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName

  document.body.appendChild(link)

  link.click()

  document.body.removeChild(link)
  window.URL.revokeObjectURL(blob)
}
