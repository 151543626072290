import PropTypes from 'prop-types'
import { InfoOutlined } from '@context365/icons'
import { Button } from 'antd'
import { NavLink } from 'react-router-dom'
import './NoMeetingsView.less'

const NoMeetingView = ({ meetingType, isSearching }) => (
  <div className="profile-empty">
    <InfoOutlined className="text-brand-100" size={36} />
    {isSearching ? (
      <span className="title-span">No Results</span>
    ) : (
      <>
        <span className="title-span">No {meetingType} Requests</span>
        <span className="desc-span">
          You don’t have any {meetingType} request.
        </span>
        <span className="desc-span">
          You can discover companies by using the button below and then you can
          send meeting requests.
        </span>
        <NavLink to="/discover/allocators">
          <Button
            style={{ margin: '8px 0' }}
            className="ant-btn-primary btn-submit"
          >
            Discover
          </Button>
        </NavLink>
      </>
    )}
  </div>
)
NoMeetingView.propTypes = {
  meetingType: PropTypes.string,
  isSearching: PropTypes.bool,
}
export default NoMeetingView
