export default {
  'Text - Single': { string: null },
  'Text - Multiple': { string: null },
  'Checkbox': { array: null },
  'Dropdown': { array: null },
  'Numeric - INT': {
    number: null,
    typeError: 'This field accepts only numbers',
    integer: 'This field accepts whole numbers only',
  },
  'Numeric - FLOAT': {
    number: null,
    typeError: 'This field accepts only numbers',
  },
  'Boolean': { boolean: null, typeError: 'This fields should be Yes or No' },
  //Ranking: 'string',
  'Upload - Excel': { object: null },
  'Upload - File': { string: null },
  'Date': { string: null },
  'Radio Button': { array: null },
  //Address: 'string',
  //'Checkbox - Grid': 'string',
  //'Manually Handled': 'string',
  //Strategy: 'string',
  'Static Text': { string: true },
  'Upload - Image': { array: null },
  'Upload - Image Single': { string: null },
  'People List': { string: null },
}
