import * as React from 'react'
import useLiveCallback from './useLiveCallback'

export default function useStickyScrolling(
  ref,
  { messageCount, onNewMessage, onScrollToBottom }
) {
  const isSticky = React.useRef(true)
  const hasDoneInitialScroll = React.useRef(false)

  useScrolling(ref, messageCount > 0, () => {
    const { scrollHeight, clientHeight, scrollTop } = ref.current
    const distanceToBottom = scrollHeight - scrollTop - clientHeight
    const isNearBottom = distanceToBottom < 200

    isSticky.current = isNearBottom

    if (distanceToBottom < 20) {
      onScrollToBottom()
    }
  })

  const scrollToBottom = React.useCallback(
    (animated = true) => {
      if (ref.current) {
        ref.current.scrollTo({
          top: ref.current.scrollHeight,
          behavior: animated ? 'smooth' : 'auto',
        })
      }
    },
    [ref]
  )

  const onNewMessageRef = useLiveCallback(onNewMessage)
  React.useEffect(() => {
    if (messageCount === 0) {
      return
    }

    if (isSticky.current) {
      scrollToBottom(hasDoneInitialScroll.current)
      hasDoneInitialScroll.current = true
    } else {
      onNewMessageRef()
    }
  }, [scrollToBottom, messageCount, onNewMessageRef])

  return { scrollToBottom }
}

function useScrolling(ref, enabled, onScroll) {
  const onScrollRef = useLiveCallback(onScroll)

  React.useLayoutEffect(() => {
    const scrollingElement = ref.current
    if (!scrollingElement) {
      return undefined
    }

    scrollingElement.addEventListener('scroll', onScrollRef)

    return () => {
      scrollingElement.removeEventListener('scroll', onScrollRef)
    }
  }, [ref, onScrollRef, enabled])
}
