import isNil from 'lodash/isNil'
import map from 'lodash/map'

export const generateQuery = (filter) => {
  switch (filter?.alertColumnType) {
    case 'Select':
    case 'Type':
    case 'Bool':
    case 'customBool':
      return `${filter.alertColumnKey} in ${filter.values.map((v) => `${v}`)}`
    case 'Fixed':
      return `${filter.alertColumnKey} eq ${filter.values.map((v) => `${v}`)}`
    case 'Check':
    case 'toggle':
      return filter.values[0] === true || filter.values[0] === 'true'
        ? `${filter.alertColumnKey} eq ${filter.values.map((v) => `${v}`)}`
        : `${filter.alertColumnKey} in true,false`
    case 'Range':
    case 'RangePercentage':
    case 'AggregatedRange':
      return getRangeQuery(filter)
    case 'SliderPercentage':
      return getSliderQuery(filter)
    default:
      return getURLFilterQueryForApi(filter)
  }
}

export const generateFilterQueryParams = (filters) =>
  map(filters, (f) => `${f.alertColumnKey}=${map(f.values, (v) => v)}`)

function getURLFilterQueryForApi(filter) {
  if (isNil(filter)) {
    return undefined
  } else {
    const [columnName, columnOperator = ''] = filter.alertColumnKey.split(' ')
    if (columnOperator === '') {
      return `${columnName} in ${filter.values.map((v) => `${v}`)}`
    } else {
      return columnOperator.toLowerCase() === 'min'
        ? `${columnName} ge ${filter.values[0]}`
        : `${columnName} le ${filter.values[0]}`
    }
  }
}

function getRangeQuery(filter) {
  const [columnName, columnOperator = ''] = filter.alertColumnKey.split(' ')
  return columnOperator.toLowerCase() === 'min'
    ? `${columnName} ge ${filter.values[0]}`
    : `${columnName} le ${filter.values[0]}`
}

function getSliderQuery(filter) {
  const [columnName] = filter.alertColumnKey.split(' ')
  return `${columnName} ge ${filter.values[0]} && ${columnName} le ${filter.values[1]}`
}
