import PropTypes from 'prop-types'
import { NavButton, NavList } from '@context365/tabs'
import { Redirect, Route, Switch } from 'react-router-dom'
import CompanyForm from '~/components/CompanyForm'
import CompanyContainer from './CompanyContainer'
import '../settings/MatchmakingSettings.less'

const CompanySettingsContainer = ({ type }) => {
  return (
    <div className="cc-container">
      {type === 'company' ? (
        <div className="cc-matchmaking-settings">
          <div className="flex justify-center mt-4">
            <NavList>
              <NavButton to="/settings/company/company-settings">
                Company Settings
              </NavButton>
              <NavButton to="/settings/company/colleagues">
                Colleagues
              </NavButton>
            </NavList>
          </div>
          <Switch>
            <Route
              exact
              path="/settings/company"
              children={<CompanyContainer type={type} />}
            />
            <Route
              path="/settings/company/company-settings"
              component={CompanyForm}
            />
            <Route
              path="/settings/company/colleagues"
              children={<CompanyContainer type={type} />}
            />
            <Redirect to="/settings/company" />
          </Switch>
        </div>
      ) : (
        <CompanyContainer type={type} />
      )}
    </div>
  )
}
CompanySettingsContainer.propTypes = {
  type: PropTypes.string,
}

export default CompanySettingsContainer
