import PropTypes from 'prop-types'
import { DetailedAvatar } from '@context365/avatar'
import map from 'lodash/map'
import CardButton from '~/components/CardButton'
import { getInitials } from '~/utils'

const ContactPreview = ({ contact }) => (
  <CardButton
    href={`/profile/contact/${contact.contactId}`}
    target="_blank"
    className={contact.canView ? 'shadow-0 border' : ''}
    disabled={!contact.canView}
  >
    <DetailedAvatar
      initials={getInitials(contact.contactName)}
      name={contact.contactName}
      profileImageUrl={contact.imageUrl}
      company={contact.jobTitle}
      size="medium"
      className="p-2"
    />
  </CardButton>
)

const CompanyProfilePeople = ({ contacts }) => {
  return (
    <div className="p-3 grid grid-cols-1 lg:grid-cols-3 gap-2">
      {map(contacts, (contact) => (
        <ContactPreview key={contact.contactId} contact={contact} />
      ))}
    </div>
  )
}

CompanyProfilePeople.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      investmentPhilosophy: PropTypes.string,
    })
  ).isRequired,
}

export default CompanyProfilePeople
