import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faUpload } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Upload, message } from 'antd'
import classNames from 'classnames'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import last from 'lodash/last'
import map from 'lodash/map'
import split from 'lodash/split'
import { useSelector } from 'react-redux'
import { downloadDocument } from '~/actions/messages'
import { BASE_URL } from '~/config'

const { Dragger } = Upload

const createHtml = (texts) => ({ __html: join(texts, '<br>') })

const UploadFileField = ({
  action = null,
  method = 'post',
  name,
  label = null,
  formFieldName = 'file',
  value = null,
  accept = null,
  help = null,
  hint = null,
  info = null,
  icon = null,
  listType = 'text',
  disabled = false,
  onChange,
}) => {
  const accessToken = useSelector((state) => state.auth.accessToken)
  const headers = { Authorization: `Bearer ${accessToken}` }
  const [fileList, setFileList] = useState([])

  if (isNil(action)) {
    action = `${BASE_URL}/uploads`
  }

  useEffect(() => {
    if (!isNil(value)) {
      try {
        const url = new URL(value)

        setFileList([
          {
            uid: 'uff-1',
            name: last(split(decodeURI(url.pathname), '/')),
            status: 'done',
            // url: encodeURI(value),
            url: decodeURI(url.href),
          },
        ])
      } catch (e) {
        console.error('[UploadFileField]::ERROR:', e)
      }
    }
  }, [value])

  const beforeUpload = (file) => {
    const isCorrect = isCorrectFileExtension(file)

    if (!isCorrect) {
      message.error(`You can only upload ${accept} file!`)
    }

    return isCorrect
  }

  const isCorrectFileExtension = (file) => {
    const extension = /[.]/.exec(file.name)
      ? /[^.]+$/.exec(file.name)[0]
      : undefined

    const acceptedExtensions = accept.replaceAll(' ', '').split(',')
    return acceptedExtensions?.includes(`.${extension.toLowerCase()}`)
  }

  const handleChange = (info) => {
    const { status, response } = info.file
    if (status === 'removed') {
      onChange(null)
      return
    }

    const isIncludedInAccept = isCorrectFileExtension(info.file)

    if (!isIncludedInAccept) {
      info.file.status = 'error'
      info.file.response = 'Incorrect file type'
      return
    }

    setFileList([info.file])
    if (status === 'done' && !isNil(response) && isFunction(onChange)) {
      const { url } = response.result
      onChange(encodeURI(url))
    }
  }

  if (isNil(icon)) {
    icon = <FontAwesomeIcon icon={faUpload} size="3x" color="#C2C6CC" />
  }
  let helpComponent = null
  if (!(isNil(help) || isEmpty(help))) {
    if (isArray(help)) {
      helpComponent = (
        <p
          className="ant-upload-text"
          dangerouslySetInnerHTML={createHtml(help)}
        />
      )
    } else {
      helpComponent = <p className="ant-upload-text">{help}</p>
    }
  }

  let hintComponent = null
  if (!(isNil(hint) || isEmpty(hint))) {
    if (isArray(hint)) {
      hintComponent = (
        <p
          className="ant-upload-hint"
          dangerouslySetInnerHTML={createHtml(hint)}
        />
      )
    } else {
      hintComponent = <p className="ant-upload-hint">{hint}</p>
    }
  }

  let infoComponent = null
  if (!(isNil(info) || isEmpty(info))) {
    infoComponent = (
      <ul className="Field-info">
        {map(info, (row) => (
          <li key={row} dangerouslySetInnerHTML={{ __html: row }} />
        ))}
      </ul>
    )
  }

  const handlePreview = (file) => {
    downloadDocument(file.url).then((response) => {
      window.open(response.data.result, '_blank')
    })
  }

  const handleRemove = () => {
    setFileList([])
  }

  const dragger = (
    <>
      <Dragger
        action={action}
        method={method}
        name={formFieldName}
        headers={headers}
        data={{ name }}
        fileList={fileList}
        onChange={handleChange}
        onPreview={handlePreview}
        onRemove={handleRemove}
        beforeUpload={beforeUpload}
        listType={listType}
        disabled={disabled}
      >
        <p className="ant-upload-drag-icon">{icon}</p>
        {helpComponent}
        {hintComponent}
      </Dragger>
      {infoComponent}
    </>
  )

  if (isNil(label)) {
    return dragger
  }

  return (
    <div className={classNames('Field', 'UploadFileField')}>
      <label className="Field-label">{label}</label>
      {dragger}
    </div>
  )
}

UploadFileField.propTypes = {
  action: PropTypes.string,
  method: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  formFieldName: PropTypes.string,
  value: PropTypes.string,
  accept: PropTypes.string,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  hint: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  info: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  icon: PropTypes.node,
  listType: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default UploadFileField
