import { useContext } from 'react'
import { Badge } from '@context365/badge'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import PresentationWizardCtx from '~/utils/contexts/PresentationWizardCtx'
import ProfilePictureGroup from '../../ProfilePictureGroup'

const PresentationOverview = () => {
  const { displayData, presentation, presentationType, selectedContacts } =
    useContext(PresentationWizardCtx)

  const dateOnTimezone = moment
    .utc(presentation.startTime)
    .tz(presentation.timezone)

  const onOffStatus = (isOn) =>
    isOn ? (
      <Badge status="success" className="mr-2">
        ON
      </Badge>
    ) : (
      <Badge status="error" className="mr-2">
        OFF
      </Badge>
    )

  return (
    <div className="flex flex-col px-20">
      <div className="text-grey-900 type-header-xs mb-8 flex justify-center">
        {displayData.types[presentationType - 1].name}
      </div>
      {presentation.fund && (
        <div className="mb-8">
          <div className="type-subtitle-xs grey-900 mb-3">PRESENTING FUND</div>
          <div className="type-body-regular-sm">{presentation.fund}</div>
        </div>
      )}
      {presentation.deal && (
        <div className="mb-8">
          <div className="type-subtitle-xs grey-900 mb-3">PRESENTING DEAL</div>
          <div className="type-body-regular-sm">{presentation.deal}</div>
        </div>
      )}
      <div className="mb-8">
        <div className="type-subtitle-xs grey-900 mb-3">PRESENTATION TITLE</div>
        <div className="type-body-regular-sm">{presentation.title}</div>
      </div>
      <div className="mb-8">
        <div className="type-subtitle-xs grey-900 mb-3">Date/Time</div>
        <div className="type-body-regular-sm">
          {dateOnTimezone.format('MMMM DD, YYYY ')}
          at
          {dateOnTimezone.format(' h:mm A ')}GMT {dateOnTimezone.format(' Z')}
        </div>
      </div>
      <div className="mb-8">
        <div className="type-subtitle-xs grey-900 mb-3">DESCRIPTION</div>
        <div className="type-body-regular-sm">{presentation.description}</div>
      </div>
      <div className="mb-8">
        <div className="type-subtitle-xs grey-900 mb-3">
          PRESENTERS AND MODERATOR
        </div>
        <div className="type-body-regular-sm">
          {selectedContacts.map((c) => {
            const contact = displayData.colleagues.find(
              (d) => c.contactId === d.contactId
            )
            return (
              <div className="flex justify-between my-3" key={c.contactId}>
                <div className="messages-compose-option-contact">
                  <div className="messages-compose-option-contact-image">
                    <ProfilePictureGroup
                      diameter={32}
                      maxImages={1}
                      profiles={[
                        {
                          name: contact.contactName,
                          firstName: contact.firstName,
                          lastName: contact.lastName,
                          ...contact,
                        },
                      ]}
                    />
                  </div>
                  <div className="ml-2">
                    <div className="type-body-regular-sm mb-3 messages-compose-option-contact-name">
                      {contact.contactName}
                    </div>
                    <div className="type-body-regular-xs messages-compose-option-company-name ">
                      {contact.jobTitle}
                      {contact.isLoggedInContact && '(YOU)'}
                    </div>
                  </div>
                </div>
                {selectedContacts
                  .map((s) => s.contactId)
                  .includes(c.contactId) && (
                  <div className="flex items-center">
                    {
                      displayData.roles[
                        selectedContacts.find(
                          (s) => s.contactId === c.contactId
                        ).role - 1
                      ]?.name
                    }
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <div className="mb-8">
        <div className="type-subtitle-xs grey-900 mb-3">ATTENDANCE</div>
        {!isEmpty(presentation.segments) && (
          <div className="type-body-regular-sm mb-3">
            Open to:
            <ul className="mt-2">
              {presentation.segments.map((s, key) => (
                <li key={key}>
                  {displayData.segments.find((d) => d.id === s)?.name}
                </li>
              ))}
            </ul>
          </div>
        )}
        {!isEmpty(presentation.guestParticipants) && (
          <div className="type-body-regular-sm mb-3">
            Guest Participants:
            <ul className="mt-2">
              {presentation.guestParticipants.map((u, key) => (
                <li key={key}>{u.name}</li>
              ))}
            </ul>
          </div>
        )}
        {!isEmpty(presentation.userInvites) && (
          <div className="type-body-regular-sm mb-3">
            Invited:
            <ul className="mt-2">
              {presentation.userInvites.map((u, key) => (
                <li key={key}>{u.name}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="mb-8">
        <div className="type-subtitle-xs grey-900 mb-3">RECORDING</div>
        <div className="type-body-regular-sm mb-3 flex align-center">
          {onOffStatus(presentation.options.includes(1))}
          <div>Record the presentation and allow notes</div>
        </div>
        <div className="type-body-regular-sm mb-3 flex">
          {onOffStatus(presentation.options.includes(2))}
          <div>Approve attendee registration</div>
        </div>
      </div>
    </div>
  )
}

export default PresentationOverview
