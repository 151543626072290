import PropTypes from 'prop-types'
import { InputNumber } from 'antd'
import isEmpty from 'lodash/isEmpty'

const FORMATTERS = {
  currency: (value) =>
    isEmpty(value) ? '' : `$${value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
  percentage: (value) => (isEmpty(value) ? '' : `${value}%`),
  number: (value) => value,
  multiple: (value) => (isEmpty(value) ? '' : `${value}x`),
}

const PARSERS = {
  currency: (value) => value.replace(/\$\s?|(,*)/g, ''),
  percentage: (value) => value.replace('%', ''),
  number: (value) => value,
  multiple: (value) => value.replace('x', ''),
}

const PLACEHOLDERS = {
  currency: 'US$',
  percentage: '%',
  number: '',
  multiple: '',
}

const InputNumberField = ({ name, type, value, setFieldValue, onBlur }) => {
  return (
    <InputNumber
      name={name}
      formatter={FORMATTERS[type]}
      placeholder={PLACEHOLDERS[type]}
      parser={PARSERS[type]}
      value={value}
      style={{ width: '100%' }}
      onChange={(val) => setFieldValue(name, val)}
      onBlur={onBlur}
    />
  )
}

InputNumberField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  setFieldValue: PropTypes.func,
  onBlur: PropTypes.func,
}

export default InputNumberField
