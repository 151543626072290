import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Input, Select } from '@context365/forms'
import { RemoveCircleOutline } from '@context365/icons'

const CommonPartnerField = ({
  value,
  errors = {},
  onChange,
  addItem,
  showAdd,
  showRemove,
  removeItem,
  investorCategoryOptions,
  listNumber,
}) => {
  const handleChange = (field, val) => {
    onChange({ ...value, [field]: val })
  }

  return (
    <div className="mb-6 md:grid md:grid-cols-2 md:gap-4">
      <div className="type-body-semibold-md">
        Investment Partner #{listNumber}
      </div>
      {showRemove && (
        <div className="hidden md:flex md:place-self-end">
          <Button
            variant="outlined"
            status="error"
            iconLeft={<RemoveCircleOutline />}
            onClick={() => removeItem()}
          >
            Remove Investment Partner
          </Button>
        </div>
      )}
      <div>
        <Input
          label="Person or Firm"
          name="name"
          required
          value={value.name}
          onChange={(e) => handleChange('name', e.target.value)}
          errorMessage={errors.name}
        />
      </div>
      <div>
        <Select
          label="Investor Category"
          name="investorCategoryId"
          value={value.investorCategoryId}
          onChange={(val) => handleChange('investorCategoryId', val)}
          errorMessage={errors.investorCategoryId}
          required
        >
          {investorCategoryOptions.map((option) => (
            <Select.Option key={option.id} className="py-2" value={option.id}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
        <div />
      </div>
      {showRemove && (
        <div className="md:hidden my-8">
          <Button
            className="w-full"
            variant="outlined"
            status="error"
            iconLeft={<RemoveCircleOutline />}
            onClick={() => removeItem()}
          >
            Remove Investment Partner
          </Button>
        </div>
      )}
      {showAdd && (
        <div>
          <Button variant="link" onClick={() => addItem()}>
            + Add Investment Partner
          </Button>
        </div>
      )}
    </div>
  )
}

CommonPartnerField.propTypes = {
  value: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  addItem: PropTypes.func,
  showAdd: PropTypes.bool,
  showRemove: PropTypes.bool,
  removeItem: PropTypes.func,
  investorCategoryOptions: PropTypes.array,
  listNumber: PropTypes.number.isRequired,
}

export default CommonPartnerField
