import * as React from 'react'
import PropTypes from 'prop-types'
import useFrameState from '~/hooks/useFrameState'
import UpgradeModal from '../UpgradeModal'

export default function BlockView({ image }) {
  const ref = React.useRef()
  const parentPadding = useParentPadding(ref)

  return (
    <div
      ref={ref}
      id="cc-block-view"
      className="flex-1 bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage: `url(${image})`,
        ...getInverseMargins(parentPadding),
      }}
    >
      <UpgradeModal container="#cc-block-view" />
    </div>
  )
}

BlockView.propTypes = {
  image: PropTypes.string,
}

function getInverseMargins(parentPadding) {
  return Object.entries(parentPadding).reduce((margins, [side, padding]) => {
    if (!padding) {
      return margins
    }

    const marginKey = `margin${side.charAt(0).toUpperCase()}${side.slice(1)}`
    margins[marginKey] = `-${padding}`
    return margins
  }, {})
}

function useParentPadding(ref) {
  const [parentPadding, setParentPadding] = useFrameState({})

  React.useLayoutEffect(() => {
    const parent = ref.current?.parentNode
    if (!parent || typeof window.ResizeObserver === 'undefined') {
      return undefined
    }

    function updateParentPadding() {
      const parentStyle = window.getComputedStyle(parent)
      setParentPadding({
        top: parentStyle.getPropertyValue('padding-top'),
        right: parentStyle.getPropertyValue('padding-right'),
        bottom: parentStyle.getPropertyValue('padding-bottom'),
        left: parentStyle.getPropertyValue('padding-left'),
      })
    }

    const observer = new ResizeObserver(updateParentPadding)
    observer.observe(parent)
    updateParentPadding()

    return () => {
      observer.disconnect()
    }
  }, [ref, setParentPadding])

  return parentPadding
}
