import { useContext } from 'react'
import { Alert, Checkbox, Radio, Space } from 'antd'
import { useFormikContext } from 'formik'
import PresentationWizardCtx from '~/utils/contexts/PresentationWizardCtx'

const WhoCanAttend = () => {
  const {
    attendance,
    setAttendance,
    displayData,
    segments,
    setSegments,
    isPublished,
  } = useContext(PresentationWizardCtx)

  const { errors, setFieldValue, validateField } = useFormikContext()

  const handleValueSet = (shortName, value) =>
    Promise.resolve(setFieldValue(shortName, value)).then(() =>
      validateField(shortName)
    )

  return (
    <div className="flex flex-col">
      <Radio.Group
        defaultValue={attendance}
        disabled={isPublished}
        onChange={(e) => {
          setAttendance(e.target.value)
          handleValueSet('attendance', e.target.value)
          if (e.target.value === 3)
            setSegments(displayData.segments.map((option) => option.id))
          else setSegments([])
        }}
      >
        <Space direction="vertical" size="large">
          <Radio value={1}>
            <div className="text-md font-semibold">Invite-only</div>
            <div className="text-sm text-grey-500 ">
              I want to specify certain people who can attend
            </div>
          </Radio>
          <Radio value={2}>
            <div className="text-md font-semibold">
              Open to certain segments
            </div>
            <div className="text-sm text-grey-500 ">
              I want to display the presentation to certain segments on the
              platform so they can request to attend (e.g. I want Allocators to
              see my presentation)
            </div>
          </Radio>
          {attendance === 2 && (
            <div className="mt-2 ml-6">
              <div className="text-sm mb-6 text-grey-700">
                Which segments would you like to display the presentation to?
              </div>
              <Checkbox.Group
                defaultValue={segments}
                disabled={isPublished}
                onChange={(e) => {
                  setSegments(e)
                  handleValueSet('segmentsLength', e.length)
                }}
                className="flex flex-col"
              >
                {displayData.segments.map((option) => (
                  <Checkbox
                    className="text-header mb-2"
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </div>
          )}
          <Radio value={3}>
            <div className="text-md font-semibold">Open to all</div>
            <div className="text-sm text-grey-500 ">
              I want to display the presentation to all segments on the platform
              so everyone can request to attend
            </div>
          </Radio>
        </Space>
      </Radio.Group>

      {errors.segmentsLength && (
        <Alert
          className="mt-10"
          message="Please select one of the segments"
          type="warning"
          showIcon
        />
      )}
    </div>
  )
}

export default WhoCanAttend
