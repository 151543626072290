import * as React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@context365/button'
import {
  AddCircleOutline,
  ChevronLeft,
  ChevronRight,
  PrintOutlined,
  RemoveCircleOutline,
} from '@context365/icons'
import Pdf from '@mikecousins/react-pdf'
import noop from 'lodash/noop'
import Loading from '../Loading'

export default function PDFViewer({
  name,
  fileUrl,
  onPageChange = noop,
  onDownload,
}) {
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [pageCount, setPageCount] = React.useState(0)

  const handleDocumentLoad = React.useCallback((pdfDocument) => {
    setLoading(false)
    setPageCount(pdfDocument.numPages)
  }, [])

  const handleDocumentError = React.useCallback(() => {
    setError('An error occurred while downloading the file.')
  }, [])

  const pagination = useStepper({ initial: 1, min: 1, max: pageCount })
  const scaling = useStepper({ initial: 100, min: 10, max: 200, increment: 10 })

  function previousPage() {
    if (!pagination.hasPrevious) {
      return
    }

    const page = pagination.previous()
    onPageChange(page, pageCount)
    pagination.set(page)
  }

  function nextPage() {
    if (!pagination.hasNext) {
      return
    }

    const page = pagination.next()
    onPageChange(page, pageCount)
    pagination.set(page)
  }

  function zoomOut() {
    if (!scaling.hasPrevious) {
      return
    }

    scaling.set(scaling.previous())
  }

  function zoomIn() {
    if (!scaling.hasNext) {
      return
    }

    scaling.set(scaling.next())
  }

  if (error) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        {error}
      </div>
    )
  }

  return (
    <div className="h-full flex flex-col overflow-hidden">
      {loading && (
        <div className="h-full w-full flex justify-center items-center">
          <Loading />
        </div>
      )}
      {pageCount > 0 && (
        <div
          className="h-10 flex flex-shrink-0 text-white items-center px-20"
          style={{ backgroundColor: '#2d3239' }}
        >
          <div className="flex-1">{name}</div>
          <div className="flex-2 flex items-center justify-center space-x-6">
            <div className="flex items-center space-x-1">
              {pageCount > 1 && (
                <IconButton
                  variant="link"
                  status="secondary"
                  label="Previous page"
                  icon={<ChevronLeft />}
                  disabled={!pagination.hasPrevious}
                  onClick={previousPage}
                />
              )}
              <span>
                Page {pagination.current} of {pageCount}
              </span>
              {pageCount > 1 && (
                <IconButton
                  variant="link"
                  status="secondary"
                  label="Next page"
                  icon={<ChevronRight />}
                  disabled={!pagination.hasNext}
                  onClick={nextPage}
                />
              )}
            </div>
            <div className="flex items-center space-x-1">
              <IconButton
                variant="link"
                status="secondary"
                label="Zoom out"
                icon={<RemoveCircleOutline />}
                disabled={!scaling.hasPrevious}
                onClick={zoomOut}
              />
              <span>{Math.round(scaling.current)}%</span>
              <IconButton
                variant="link"
                status="secondary"
                label="Zoom in"
                icon={<AddCircleOutline />}
                disabled={!scaling.hasNext}
                onClick={zoomIn}
              />
            </div>
          </div>
          <div className="flex-1 text-right">
            {onDownload && (
              <IconButton
                style={{ color: 'white' }}
                variant="link"
                label="Download"
                icon={<PrintOutlined />}
                onClick={onDownload}
              />
            )}
          </div>
        </div>
      )}
      <div className="flex flex-1 py-2 justify-center items-center overflow-y-auto">
        {pageCount > 1 && (
          <IconButton
            variant="link"
            size="big"
            label="Previous page"
            icon={
              <ChevronLeft
                style={{ height: 80, width: 80 }}
                size={80}
                color={pagination.hasPrevious ? 'white' : undefined}
              />
            }
            disabled={!pagination.hasPrevious}
            onClick={previousPage}
          />
        )}
        <div className="max-h-full">
          <Pdf
            file={fileUrl}
            page={pagination.current}
            scale={scaling.current / 100}
            onDocumentLoadSuccess={handleDocumentLoad}
            onDocumentLoadFail={handleDocumentError}
          />
        </div>
        {pageCount > 1 && (
          <IconButton
            variant="link"
            size="big"
            label="Next page"
            icon={
              <ChevronRight
                style={{ height: 80, width: 80 }}
                size={80}
                color={pagination.hasNext ? 'white' : undefined}
              />
            }
            disabled={!pagination.hasNext}
            onClick={nextPage}
          />
        )}
      </div>
    </div>
  )
}

PDFViewer.propTypes = {
  name: PropTypes.string,
  fileUrl: PropTypes.string.isRequired,
  onPageChange: PropTypes.func,
  onDownload: PropTypes.func,
}

function useStepper({ initial, min, max, increment = 1 }) {
  const [current, setCurrent] = React.useState(initial)
  const hasNext = current + increment <= max
  const hasPrevious = current - increment >= min

  function next() {
    return hasNext ? current + increment : current
  }

  function previous() {
    return hasPrevious ? current - increment : current
  }

  return {
    current,
    next,
    previous,
    hasNext,
    hasPrevious,
    set: setCurrent,
  }
}
