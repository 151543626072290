import PropTypes from 'prop-types'
import { UserOutlined } from '@ant-design/icons'
import {
  faCheckCircle,
  faClock,
  faMinusCircle,
  faQuestionCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Badge, Popover, Typography } from 'antd'
import isEmpty from 'lodash/isEmpty'
import './ContactName.less'

const { Text } = Typography

const ContactName = ({
  name,
  position,
  imageUrl = '',
  fontClass = 'cc-small-font',
  marginTop = '15px',
  reverseOrder = false,
  showImageOnPopover = true,
  showStatusBadge = false,
  participantStatusId = 0,
  participantStatus = '',
}) => (
  <div
    style={{
      marginTop,
      display: 'flex',
      flexDirection: reverseOrder ? 'row-reverse' : 'row',
    }}
  >
    <Popover
      key={name}
      title={name}
      overlayClassName={`ContactAvatar-popover ContactAvatar-popover-${
        isEmpty(imageUrl) || !showImageOnPopover ? 'no-' : '-'
      }image`}
      content={
        !isEmpty(imageUrl) &&
        showImageOnPopover && (
          <div style={{ textAlign: 'center' }}>
            <img
              alt="avatar"
              style={{ borderRadius: '50px', objectFit: 'cover' }}
              src={imageUrl}
              width="100px"
              height="100px"
            />
            <div style={{ color: 'white' }}>{participantStatus}</div>
          </div>
        )
      }
    >
      <div>
        {showStatusBadge && participantStatusId > 0 ? (
          <Badge
            count={
              <FontAwesomeIcon
                icon={
                  participantStatusId === 1
                    ? faQuestionCircle
                    : participantStatusId === 2
                    ? faCheckCircle
                    : participantStatusId === 5
                    ? faClock
                    : faMinusCircle
                }
                color={
                  participantStatusId === 1
                    ? 'orange'
                    : participantStatusId === 2
                    ? 'green'
                    : participantStatusId === 5
                    ? 'grey'
                    : 'red'
                }
              />
            }
          >
            <Avatar icon={<UserOutlined />} src={imageUrl} size="small" />
          </Badge>
        ) : (
          <Avatar icon={<UserOutlined />} src={imageUrl} size="small" />
        )}
      </div>
    </Popover>
    <div className="ContactAvatar-info">
      <div
        style={{
          marginLeft: reverseOrder ? 0 : '8px',
          marginRight: reverseOrder ? '8px' : '0px',
        }}
        className={fontClass}
      >
        {name}
      </div>
      <Text
        style={{
          marginLeft: '8px',
        }}
        className="cc-xsmall-font cc-grey-text "
        ellipsis={{ tooltip: true }}
      >
        {position}
      </Text>
    </div>
  </div>
)

ContactName.propTypes = {
  name: PropTypes.string.isRequired,
  fontClass: PropTypes.string,
  position: PropTypes.string,
  imageUrl: PropTypes.string,
  marginTop: PropTypes.string,
  showImageOnPopover: PropTypes.bool,
  reverseOrder: PropTypes.bool,
  showStatusBadge: PropTypes.bool,
  participantStatusId: PropTypes.number,
  participantStatus: PropTypes.string,
}

export default ContactName
