import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { useDiscoverLists } from '~/hooks/discoverLists'
import CreateListForm from './CreateListForm'

export default function CreateListModal({ visible, onClose }) {
  const { createDiscoverList } = useDiscoverLists()

  function createList(name) {
    createDiscoverList(name).then(() => {
      onClose()
    })
  }

  return (
    visible && (
      <Modal
        visible
        footer={null}
        width="540px"
        bodyStyle={{ padding: 0 }}
        onCancel={onClose}
      >
        <CreateListForm onCreate={createList} onDismiss={onClose} />
      </Modal>
    )
  )
}

CreateListModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
