import { useEffect, useState } from 'react'
import includes from 'lodash/includes'
import * as api from '~/api'
import Loading from '~/components/Loading'
import MeetingList from '~/components/Meetings/MeetingList'
import { MEETING_STATUS } from '~/constants/meetingStatusIds'
import { MEETING_TYPE } from '~/constants/meetingTypeIds'
import './Calendar.less'

const AllMeetings = ({ campaignId, hideLocationFilter }) => {
  const [meetings, setMeetings] = useState([])

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState()
  const [meetingStatusIds, setMeetingStatusIds] = useState([
    MEETING_STATUS.PENDING.toString(),
  ])
  const [meetingTypeIds, setMeetingTypeIds] = useState([
    MEETING_TYPE.SUMMIT.toString(),
    MEETING_TYPE.ONLINE.toString(),
  ])
  const [search, setSearch] = useState()

  const loadMeetings = () => {
    setLoading(true)
    const includeActive = includes(
      meetingStatusIds,
      MEETING_STATUS.PENDING.toString()
    )
    const includeInactive = includes(
      meetingStatusIds,
      MEETING_STATUS.DECLINED.toString()
    )
    const includeSummits = includes(
      meetingTypeIds,
      MEETING_TYPE.SUMMIT.toString()
    )
    const includeOnline = includes(
      meetingTypeIds,
      MEETING_TYPE.ONLINE.toString()
    )
    if (
      (!includeActive && !includeInactive) ||
      (!includeSummits && !includeOnline)
    ) {
      setMeetings([])
      setTotalCount(0)
      setLoading(false)
    } else {
      api.meeting
        .getAllMeetings(page, pageSize, search, {
          includeActive,
          includeInactive,
          includeSummits,
          includeOnline,
          campaignId,
        })
        .then((response) => {
          setMeetings(response.data.result.results)
          setTotalCount(response.data.result.itemCount)
        })
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    loadMeetings()
  }, [search, meetingStatusIds, meetingTypeIds, page, pageSize])

  return (
    <div className="p-4 lg:p-8">
      {loading && (
        <div className="absolute w-full text-center">
          <Loading spinning={loading} />
        </div>
      )}
      <MeetingList
        meetings={meetings}
        emptyText="No meetings to show that match the current filters"
        meetingStatusIds={meetingStatusIds}
        setMeetingStatusIds={setMeetingStatusIds}
        meetingTypeIds={meetingTypeIds}
        hideLocationFilter={hideLocationFilter}
        setMeetingTypeIds={setMeetingTypeIds}
        setSearch={setSearch}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        total={totalCount}
        refreshList={loadMeetings}
      />
    </div>
  )
}

export default AllMeetings
