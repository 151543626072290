import { Input, TextArea } from '@context365/forms'
import find from 'lodash/find'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { getAllContinents, getAllCountries } from '~/actions/geography'
import ArrayField from '~/components/GenericForm/Fields/ArrayField'
import CheckBoxField from '~/components/GenericForm/Fields/CheckBoxField'
import DateField from '~/components/GenericForm/Fields/DateField'
import DropDownField from '~/components/GenericForm/Fields/DropDownField'
import FileField from '~/components/GenericForm/Fields/FileField'
import HeaderField from '~/components/GenericForm/Fields/HeaderField'
import InputNumberField from '~/components/GenericForm/Fields/InputNumberField'
import LinkField from '~/components/GenericForm/Fields/LinkField'
import RadioField from '~/components/GenericForm/Fields/RadioField'
import StaticText from '~/components/GenericForm/Fields/StaticText'
import UploadMultipleFileField from '~/components/GenericForm/Fields/UploadMultipleFileField'
import { HelpEmail, ReturnsEmail } from '~/config'
import { LiquidFundTypeExplanation } from '../../FundTypeExplanation'
import ServiceProviderField from '../Fields/ServiceProviderField'
import ReturnsField from './ReturnsUpload'

export const BasicInfoQuestions = (
  values,
  questionOptions,
  onSwitchFundType
) => [
  {
    hideOnCreate: true,
    questions: [
      {
        component: LiquidFundTypeExplanation,
        extraProps: {
          onSwitchFundType,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'fundName',
        title: 'Fund Name',
        required: true,
        component: Input,
      },
    ],
  },
  {
    questions: [
      {
        name: 'strategyDescription',
        title: 'Fund Strategy & Description',
        required: true,
        component: TextArea,
        helpText: `Character Count: ${
          values.strategyDescription?.length ?? 0
        }. Please input at least 280 characters of text. This will help people find your fund by keyword search.`,
        extraProps: { rows: 5 },
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'attributeIds',
        title: 'Fund Attributes',
        isFormField: true,
        component: CheckBoxField,
        extraProps: {
          options: questionOptions.attributes,
        },
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'investmentVehicleIds',
        title: 'Investment Vehicles',
        required: true,
        isFormField: true,
        component: CheckBoxField,
        extraProps: {
          options: questionOptions.investmentVehicles,
        },
      },
    ],
  },
]

export const ManagerDetailsQuestions = (values) => [
  {
    questions: [
      {
        name: 'managerBiography',
        title: 'Manager Bio',
        helpText: `Character Count: ${
          values.managerBiography?.length ?? 0
        }. Please input at least 280 characters of text. This will help people find your fund by keyword search.`,
        required: true,
        component: TextArea,
      },
    ],
  },
  {
    questions: [
      {
        name: 'managerContinentId',
        title: 'Manager Continent',
        component: DropDownField,
        extraProps: {
          showSearch: true,
          getOptionsMethod: getAllContinents,
          mappingMethod: (vals) =>
            map(vals, (x) => ({ label: x.name, value: x.continentId })),
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'managerCountryId',
        title: 'Manager Country',
        required: true,
        component: DropDownField,
        extraProps: {
          showSearch: true,
          getOptionsMethod: getAllCountries,
          mappingMethod: (vals) =>
            map(vals, (x) => ({ label: x.name, value: x.countryId })),
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'managerStateProvince',
        title: 'Manager State Province',
        component: Input,
      },
    ],
  },
  {
    questions: [
      {
        name: 'managerCity',
        title: 'Manager City',
        component: Input,
      },
    ],
  },
]

export const FundDetailsQuestions = (values, questionOptions) => [
  {
    questions: [
      {
        name: 'inceptionDate',
        title: 'Fund Inception Date',
        required: true,
        isFormField: true,
        component: DateField,
        helpText:
          'This date can be a date in the future if this fund has not launched yet.',
      },
    ],
  },
  {
    questions: [
      {
        name: 'CIK',
        title: 'CIK Number',
        isFormField: true,
        component: InputNumberField,
      },
    ],
  },
  {
    questions: [
      {
        name: 'CRD',
        title: 'CRD Code',
        isFormField: true,
        component: InputNumberField,
      },
    ],
  },
  {
    questions: [
      {
        name: 'domicileIds',
        title: 'Fund Domicile',
        required: true,
        isFormField: true,
        component: CheckBoxField,
        extraProps: {
          options: questionOptions.domiciles,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'domicileCountryIds',
        title: 'Domicile Country',
        required: true,
        component: DropDownField,
        helpText: 'Country where fund is legally domiciled',
        isVisible: (values) => includes(values.domicileIds, 2),
        extraProps: {
          showSearch: true,
          mode: 'multiple',
          getOptionsMethod: getAllCountries,
          mappingMethod: (vals) =>
            map(vals, (x) => ({ label: x.name, value: x.countryId })),
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'fundAum',
        title: 'Fund AUM (USD)',
        required: true,
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'currency',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'aumCapacity',
        title: 'AUM Capacity (USD)',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'currency',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'proprietaryAssetsPercentageOfFundAum',
        title: 'Proprietary Assets Percentage of Fund AUM (%)',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'proportionOfPortfolioEasilyPriced',
        title: 'Proportion of Portfolio Easily Priced (%)',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'leverageUtilization',
        title:
          'How much leverage does the portfolio utilize, in multiples of AUM? (1 = no leverage)',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'largestNotionalPosition',
        title: 'Largest notional position in the portfolio (%)',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'minimumInvestment',
        title: 'Minimum Investment (USD)',
        required: true,
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'currency',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'minimumInvestmentForManagedAccounts',
        title: 'Minimum Investment for Managed Accounts (USD)',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'currency',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'daysOfCapitalLockup',
        title: 'Capital Lockup (Days)',
        required: true,
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'postLockupLiquidityId',
        title: 'Post Lock-Up Liquidity',
        component: DropDownField,
        extraProps: {
          options: questionOptions.liquidityLockups,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'daysNoticeForRedemption',
        title: 'Advance Notice Needed for Redemption (in Days)',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'managementFeePercentage',
        title: 'Management Fee (%)',
        required: true,
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'performanceFeePercentage',
        title: 'Performance Fee (%)',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'performanceHurdleRate',
        title: 'Performance Hurdle Rate?',
        isFormField: true,
        component: RadioField,
        extraProps: {
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          mappingMethod: (x) => x,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'isFirstLossCapitalAccepted',
        title: 'Do you accept first loss capital?',
        required: true,
        isFormField: true,
        component: RadioField,
        extraProps: {
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          mappingMethod: (x) => x,
        },
      },
    ],
  },
]

export const FundReturnQuestions = () => [
  {
    questions: [
      {
        name: 'narrative',
        title: 'Tearsheet type',
        required: true,
        isFormField: true,
        component: RadioField,
        extraProps: {
          options: [
            {
              label: 'Narrative, with no returns or AUM informed.',
              value: true,
            },
            {
              label:
                'Quantitative, with returns (%) and AUM in a periodic basis (monthly, quarterly, etc.)',
              value: false,
            },
          ],
          mappingMethod: (x) => x,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'quantitativeReturns',
        isFormField: true,
        component: ReturnsField,
        isVisible: (values) => values.narrative === false,
      },
    ],
  },
]

export const MarketingMaterialQuestions = () => [
  {
    questions: [
      {
        name: 'marketingMaterialUrls',
        title:
          'Please upload marketing materials. You may upload multiple files.',
        isFormField: true,
        component: FileField,
        extraProps: {
          hint: ['File Types: .pdf', 'Max File Size: 5MB'],
        },
      },
    ],
  },
  {
    questions: [
      {
        component: HeaderField,
        extraProps: {
          title: 'Add us to your distribution list',
        },
      },
    ],
  },
  {
    questions: [
      {
        component: StaticText,
        extraProps: {
          label: `Add ${ReturnsEmail} to your email distribution list. This helps our team keep your profile up to date and stay aware of any changes at your firm.`,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'distributorEmail',
        title: 'Email address your updates are sent from',
        required: true,
        component: Input,
      },
    ],
  },
  {
    questions: [
      {
        component: StaticText,
        extraProps: {
          label:
            'You have requested that Apex US Distribution LLC input certain information that you have provided, including updated fund(s) performance information, into the ApexInvest software platform. Accordingly, you agree to promptly review such information as it appears in the ApexInvest software platform and immediately notify Apex US Distribution LLC of any errors or omissions resulting from Apex US Distribution LLC’s entry of the associated data. You further acknowledge that your sole remedy for any such errors or omissions is for Apex US Distribution LLC to promptly correct such errors or omissions upon receipt of notice from you and Apex shall have no further liability with respect thereto. You always retain the ability to update the fund(s) information yourself, if you choose.',
          alertType: 'info',
        },
      },
    ],
  },
]

export const MediaQuestions = (values) => [
  {
    questions: [
      {
        name: 'media',
        isFormField: true,
        component: UploadMultipleFileField,
        extraProps: {
          hint: [
            'File Types: .JPG, .PNG, .BMP, .AVI, .MOV, .MP4, .MPEG4',
            'Max File Size: 500MB',
          ],
          accept: '.jpg, .png, .bmp, .mov, .avi, .mp4, .mpeg4',
          listType: 'picture-card',
          listLength: 5,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'links',
        title: 'External Links',
        component: ArrayField,
        className: isEmpty(values.media) ? null : 'pt-24',
        extraProps: {
          allowEmpty: true,
          emptyAddText: '+ Add Link',
          subComponent: LinkField,
          subProps: {},
          defaultObject: {
            title: null,
            url: null,
          },
        },
      },
    ],
  },
]

export const ServiceProviderQuestions = (values, questionOptions) => [
  {
    questions: [
      {
        component: HeaderField,
        extraProps: { title: 'Auditors' },
      },
    ],
  },
  {
    questions: [
      {
        name: 'auditorIds',
        title: 'Auditor',
        component: ArrayField,
        extraProps: {
          allowEmpty: true,
          emptyAddText: '+ Add Auditor',
          subComponent: ServiceProviderField,
          subProps: {
            title: 'Auditor',
            options: questionOptions.auditors,
          },
          defaultObject: null,
        },
      },
    ],
  },
  {
    questions: [
      {
        component: HeaderField,
        extraProps: { title: 'Prime Brokers' },
      },
    ],
  },
  {
    questions: [
      {
        name: 'primeBrokerIds',
        title: 'Prime Broker',
        component: ArrayField,
        extraProps: {
          allowEmpty: true,
          emptyAddText: '+ Add Prime Broker',
          subComponent: ServiceProviderField,
          subProps: {
            options: questionOptions.primeBrokers,
          },
          defaultObject: null,
        },
      },
    ],
  },
  {
    questions: [
      {
        component: HeaderField,
        extraProps: { title: 'Fund Administrators' },
      },
    ],
  },
  {
    questions: [
      {
        name: 'fundAdministratorIds',
        title: 'Fund Administrator',
        component: ArrayField,
        extraProps: {
          allowEmpty: true,
          emptyAddText: '+ Add Fund Administrator',
          subComponent: ServiceProviderField,
          subProps: {
            options: questionOptions.administrators,
          },
          defaultObject: null,
        },
      },
    ],
  },
  {
    questions: [
      {
        component: HeaderField,
        extraProps: { title: 'Custodians' },
      },
    ],
  },
  {
    questions: [
      {
        name: 'custodianIds',
        title: 'Custodian',
        component: ArrayField,
        extraProps: {
          allowEmpty: true,
          emptyAddText: '+ Add Custodian',
          subComponent: ServiceProviderField,
          subProps: {
            options: questionOptions.custodians,
          },
          defaultObject: null,
        },
      },
    ],
  },
  {
    questions: [
      {
        component: HeaderField,
        extraProps: { title: 'External Legal Counsel' },
      },
    ],
  },
  {
    questions: [
      {
        name: 'legalCounselIds',
        title: 'External Legal Counsel',
        component: ArrayField,
        extraProps: {
          allowEmpty: true,
          emptyAddText: '+ Add External Legal Counsel',
          subComponent: ServiceProviderField,
          subProps: {
            options: questionOptions.legalCounsels,
          },
          defaultObject: null,
        },
      },
    ],
  },
  {
    questions: [
      {
        component: HeaderField,
        extraProps: { title: 'Marketers' },
      },
    ],
  },
  {
    questions: [
      {
        name: 'marketerIds',
        title: 'Marketer',
        component: ArrayField,
        extraProps: {
          allowEmpty: true,
          emptyAddText: '+ Add Marketer',
          subComponent: ServiceProviderField,
          subProps: {
            options: questionOptions.marketers,
          },
          defaultObject: null,
        },
      },
    ],
  },
  {
    questions: [
      {
        component: StaticText,
        extraProps: {
          labelText: `Don't see a service provider?`,
          label: `Send an email to ${HelpEmail} and we'll add it. In the meantime, please continue adding fund details.`,
          alertType: 'info',
        },
      },
    ],
  },
]

export const LiquidFundQuestions = (values, questionOptions) => [
  {
    questions: [
      {
        name: 'expectedTargetNetAnnualReturn',
        title: 'Expected / Target Net Annual Return',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'expectedTargetStandardDeviationOfReturns',
        title: 'Expected / Target Standard Deviation of Returns',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'benchmarkIndexId',
        title: 'Select a custom benchmark (optional)',
        helpText:
          'By default, we will use the S&P 500 Index. To showcase your fund against another index, please select a different index below.',
        component: DropDownField,
        extraProps: {
          options: questionOptions.fundIndices,
          showSearch: true,
          allowClear: true,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'expectedTargetBetaToYourBenchMark',
        title: 'Expected Target Beta To Your Bench Mark',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'broadStrategyId',
        title: 'Broad Strategy',
        required: true,
        component: DropDownField,
        extraProps: {
          options: questionOptions.strategies,
          clearFieldsOnChange: ['subStrategyId'],
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'subStrategyId',
        title: 'Sub Strategy',
        required: true,
        component: DropDownField,
        extraProps: {
          options: isNil(values.broadStrategyId)
            ? []
            : find(questionOptions.strategies, { id: values.broadStrategyId })
                ?.children,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'investmentDecisionProcessId',
        title: 'Primary Driver of Investment Decision Process',
        required: true,
        component: DropDownField,
        extraProps: {
          options: questionOptions.investmentDecisions,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'netDollarExposureId',
        title: 'Net Dollar Exposure',
        required: true,
        component: DropDownField,
        extraProps: {
          options: questionOptions.dollarExposures,
        },
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'primaryInstrumentIds',
        title: 'Primary Instruments',
        required: true,
        isFormField: true,
        component: CheckBoxField,
        extraProps: {
          options: questionOptions.instruments,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'portfolioTurnedOverYearlyCount',
        title: 'How many times is the portfolio turned over yearly?',
        required: true,
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'tradingDaysNeededToUnwindMostIlliquidPosition',
        title: 'Trading days needed to unwind most illiquid position',
        required: true,
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
    ],
  },
]

export const ConcentrationQuestions = (values, questionOptions) => [
  {
    questions: [
      {
        name: 'isPortfolioGeographicallyDiverse',
        title:
          'Do you manage a portfolio that is diversified by geographic exposure?',
        isFormField: true,
        component: RadioField,
        extraProps: {
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          mappingMethod: (x) => x,
        },
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'geographyIds',
        title: 'Geography Concentration',
        isFormField: true,
        component: CheckBoxField,
        isVisible: (values) =>
          values.isPortfolioGeographicallyDiverse === false,
        extraProps: {
          options: questionOptions.geographies,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'isPortfolioSectorDiverse',
        title:
          'Do you manage a portfolio that is diversified by sector exposure?',
        isFormField: true,
        component: RadioField,
        extraProps: {
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          mappingMethod: (x) => x,
        },
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'industryIds',
        title: 'Sector Concentration',
        isFormField: true,
        component: CheckBoxField,
        isVisible: (values) => values.isPortfolioSectorDiverse === false,
        extraProps: {
          options: questionOptions.industries,
        },
      },
    ],
  },
]
