import PropTypes from 'prop-types'
import { faClock, faGlobe } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Drawer, Row } from 'antd'
import './Banner.less'

const Banner = ({
  nextMeeting,
  isVisible = false,
  onJoinNextMeeting,
  onDelay,
}) => (
  <Drawer
    getContainer={false}
    placement="top"
    closable={false}
    visible={isVisible}
    height={64}
    headerStyle={{ display: 'none' }}
    bodyStyle={{ background: '#2D81A8', padding: 0 }}
    mask={false}
    maskClosable={false}
  >
    <Row className="banner">
      <Col
        xl={{ span: 12, offset: 3 }}
        lg={{ span: 12, offset: 1 }}
        md={{ span: 16 }}
        className="flex-childs"
      >
        <FontAwesomeIcon
          size="lg"
          icon={faGlobe}
          className="logo-icon-space"
          color="white"
        />
        <h4>
          Meeting with <strong>{nextMeeting.toCompanyName}</strong> has started
        </h4>
        <Button
          type="primary"
          size="default"
          className="space-around"
          onClick={() => onJoinNextMeeting()}
        >
          Join Meeting
        </Button>
      </Col>

      <Col
        xl={{ span: 8, offset: 1 }}
        lg={{ span: 8, offset: 1 }}
        md={{ span: 8 }}
        className="flex-childs"
      >
        <h5>Will join in:</h5>
        <Button
          ghost
          size="default"
          className="space-around"
          onClick={() => onDelay(5)}
        >
          <FontAwesomeIcon icon={faClock} className="button-icon-space" />5 mins
        </Button>
        <Button
          ghost
          size="default"
          className="space-around"
          onClick={() => onDelay(10)}
        >
          <FontAwesomeIcon icon={faClock} className="button-icon-space" />
          10 mins
        </Button>
      </Col>
    </Row>
  </Drawer>
)

Banner.propTypes = {
  nextMeeting: PropTypes.object,
  isVisible: PropTypes.bool,
  onJoinNextMeeting: PropTypes.func.isRequired,
  onDelay: PropTypes.func.isRequired,
}

export default Banner
