import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { useDiscoverLists } from '~/hooks/discoverLists'
import AddToListForm from './AddToListForm'

export default function AddToListModal({
  visible,
  onClose,
  targetItems,
  getTargetId,
  getTargetName,
  listLinkName,
  linkTargetName = `${listLinkName}s`,
  isTogglingListDisabled = () => false,
}) {
  const targetIds = targetItems.map(getTargetId)
  const targetNames = targetItems.map(getTargetName)

  const { addDiscoverListLink, createDiscoverList, toggleDiscoverListLink } =
    useDiscoverLists()

  function toggleListSelected(listId) {
    const toggle =
      targetItems.length > 1 ? addDiscoverListLink : toggleDiscoverListLink
    return toggle(listId, { [linkTargetName]: targetIds })
  }

  function createList(name) {
    return createDiscoverList(name, { [linkTargetName]: targetIds })
  }

  function isListChecked(list) {
    return targetItems.every((item) =>
      list.discoverListLinks.some(
        (link) => link[listLinkName] === getTargetId(item)
      )
    )
  }

  return (
    visible && (
      <Modal
        visible
        footer={null}
        onCancel={onClose}
        width="540px"
        bodyStyle={{ padding: 0 }}
      >
        <AddToListForm
          targetNames={listify(targetNames)}
          isListSelected={isListChecked}
          isTogglingListDisabled={isTogglingListDisabled}
          onToggleList={toggleListSelected}
          onCreateList={createList}
          onDismiss={onClose}
        />
      </Modal>
    )
  )
}

AddToListModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  targetItems: PropTypes.array.isRequired,
  getTargetId: PropTypes.func.isRequired,
  getTargetName: PropTypes.func.isRequired,
  listLinkName: PropTypes.string.isRequired,
  linkTargetName: PropTypes.string,
  isTogglingListDisabled: PropTypes.func,
}

function listify(items) {
  switch (items.length) {
    case 0:
      return ''
    case 1:
      return items[0]
    case 2:
      return items.join(' and ')
    default:
      return [items.slice(0, -1).join(', '), items[items.length - 1]].join(
        ', and '
      )
  }
}
