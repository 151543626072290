import { FETCH_PORTFOLIO_FUNDS, FETCH_PORTFOLIO_LIST } from '~/constants/types'
import {
  LOAD_PORTFOLIO_INVESTMENTS_FAILURE,
  LOAD_PORTFOLIO_INVESTMENTS_REQUEST,
  LOAD_PORTFOLIO_INVESTMENTS_SUCCESS,
} from '~/constants/types/portfolio'

const initialState = {
  portfolioList: { result: [] },
  portfolioFunds: [],
  nvestmentsById: {},
  investmentIds: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PORTFOLIO_LIST:
      return { ...state, portfolioList: action.payload }
    case FETCH_PORTFOLIO_FUNDS:
      return { ...state, portfolioFunds: action.payload }
    case LOAD_PORTFOLIO_INVESTMENTS_REQUEST:
      return { ...state, loading: true }
    case LOAD_PORTFOLIO_INVESTMENTS_SUCCESS:
      return { ...state, loading: false, ...action.payload }
    case LOAD_PORTFOLIO_INVESTMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}
