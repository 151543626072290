import get from 'lodash/get'
import isNil from 'lodash/isNil'
import numeral from 'numeral'

const filterMap = {
  1: 'Liquid',
  2: 'Illiquid',
}

export default function formatFilters(filters) {
  return filters.map((x) =>
    isNil(x.isVisible) || x.isVisible === true
      ? x.alertColumnType === 'Select'
        ? {
            Column: `${x.alertColumn}:`,
            Value: x.values.map((v) => v),
          }
        : x.alertColumnType === 'Bool' && x.alertColumn !== 'Type'
        ? {
            Column: `${x.alertColumn}:`,
            Value: x.values.map((v) => (v === 'true' ? 'Yes' : 'No')),
          }
        : x.alertColumnType === 'Check'
        ? {
            Column: `${x.alertColumn}:`,
            Value: 'Yes',
          }
        : x.alertColumn === 'Type'
        ? {
            Column: `${x.alertColumn}:`,
            Value: x.values.map((v) =>
              v === true || v === 'true' ? 'Anonymous' : 'Public'
            ),
          }
        : x.alertColumnType === 'SliderPercentage'
        ? {
            Column: `${x.alertColumn}:`,
            Value: `${numeral(x.values[0] * 100).format('0[.]00')}% - ${numeral(
              x.values[1] * 100
            ).format('0[.]00')}%`,
          }
        : {
            Column: `${x.alertColumn}: `,
            Value:
              x.alertColumnType === 'RangePercentage'
                ? x.alertColumnKey.includes('deepestDrawdown') ||
                  x.alertColumnKey.includes('YtdReturn') ||
                  x.alertColumnKey.includes('managementFee') ||
                  x.alertColumnKey.includes('performanceFee')
                  ? x.values.map((v) => ` ${numeral(v).format('0[.]00')} % `)
                  : x.values.map(
                      (v) => ` ${numeral(v * 100).format('0[.]00')} % `
                    )
                : x.alertColumnType === 'Type'
                ? x.values.map((v) => ` ${get(filterMap, v)}`)
                : x.values.map((v) => ` ${v}`),
          }
      : null
  )
}
