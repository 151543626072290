import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'
import { useDispatch, useSelector } from 'react-redux'
import SimpleBar from 'simplebar-react'
import { fetchCompanyIfNeeded } from '~/actions/company'
import { getCompanyFunds } from '~/actions/discover'
import { getCompanyId } from '~/selectors/auth'
import DiscoverAllocatorsFundSelect from '../discoverAllocators/dicoverAllocatorsFundSelect'
import CompanyFundsContext from './companyFundsContext'

const DiscoverSelectFund = ({ tab, resultId, fundId, Component }) => {
  const companyId = useSelector(getCompanyId)
  const dispatch = useDispatch()
  const [funds, setFunds] = useState([])
  const [fundFilter, setFundFilter] = useState(null)

  const fetchCompany = useCallback(
    () => dispatch(fetchCompanyIfNeeded(companyId)),
    [companyId, dispatch]
  )
  const fetchCompanyFunds = useCallback(() => {
    getCompanyFunds(companyId).then((response) => {
      setFunds(response.data.result)
      if (response.data.result && response.data.result.length > 0)
        setFundFilter({
          alertColumn: 'Fund Id',
          alertColumnKey: 'fundId',
          values:
            !isNil(fundId) &&
            response.data.result.find((x) => x.fundId === parseInt(fundId, 10))
              ? [fundId]
              : [response.data.result[0].fundId],
          alertColumnType: 'Fixed',
        })
    })
  }, [companyId, fundId])

  useEffect(() => fetchCompany(), [companyId, fetchCompany])
  useEffect(() => {
    fetchCompanyFunds()
  }, [fetchCompanyFunds])

  return (
    <div>
      {funds.length < 1 ? (
        <SimpleBar
          style={{
            maxHeight: 'calc(100vh - 50px)',
            overflowX: 'hidden',
          }}
          autoHide={false}
        >
          <DiscoverAllocatorsFundSelect
            onSelectFund={() => fetchCompanyFunds()}
          />
        </SimpleBar>
      ) : (
        <div>
          {funds.length > 0 && fundFilter && (
            <CompanyFundsContext.Provider value={funds}>
              <Component tab={tab} resultId={resultId} fundId={fundId} />
            </CompanyFundsContext.Provider>
          )}
        </div>
      )}
    </div>
  )
}

DiscoverSelectFund.propTypes = {
  tab: PropTypes.string,
  resultId: PropTypes.number,
  fundId: PropTypes.number,
  Component: PropTypes.elementType.isRequired,
}

export default DiscoverSelectFund
