import { Button } from '@context365/button'
import { useTheme } from '@context365/config'
import { LocalPolice } from '@context365/icons'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Link } from 'react-router-dom'
import { Enterprise, Free, Pro } from '~/constants/tiers'
import useMinScreen from '~/hooks/useMinScreen'
import useTracking from '~/hooks/useTracking'
import { CustomerLogos, Feature, Testimonial } from './common'

export default function FreeStarterProTiersBody() {
  const { enterpriseTier } = useFlags()
  const { Track } = useTracking({ component: 'FreeStarterProTiersBody' })
  return (
    <Track>
      <div className="rounded-big bg-white relative isolate overflow-hidden p-6 pb-0 space-y-6">
        <div className="text-center">
          <h2 className="mb-3 type-header-sm md:type-header-lg">
            Upgrade your Capital Raise
          </h2>
          <div className="type-body-regular-md">
            Contact us to learn about how Pro can help you grow your AUM
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center gap-4">
          <Tier
            name={Free}
            topBand="bg-brand-100"
            height="short"
            features={[
              'Unlimited Campaigns',
              'Community Access',
              'Apply to up to five campaigns',
            ]}
            footer={
              <Button disabled variant="filled" className="w-full">
                Current Plan
              </Button>
            }
          />
          <Tier
            name={Pro}
            topBand="bg-secondary-100"
            expandsOn={Free}
            features={[
              'Unlimited community interaction',
              'Send connection requests',
              'Apply to unlimited mandates',
              'Basic analytics',
            ]}
            footer={<LearnMoreButton tier={Pro} />}
          />
          {enterpriseTier && (
            <Tier
              name={Enterprise}
              topBand="bg-primary-100"
              height="tall"
              expandsOn={Pro}
              features={[
                'Advanced analytics',
                'Featured fund intro video',
                'Strategy interview',
                'Newsletter feature',
              ]}
              footer={<LearnMoreButton tier={Enterprise} />}
            />
          )}
        </div>
        <Testimonial className="max-w-3xl mx-auto" />
        <CustomerLogos />
        <div className="h-1 -mx-6 bg-gradient-to-r from-brand-100 via-secondary-100 to-primary-100" />
        <Background />
      </div>
    </Track>
  )
}

function Tier({ name, topBand, height, expandsOn, features, footer }) {
  const isMobile = !useMinScreen('md')
  const spacingClassName = isMobile
    ? 'space-y-6'
    : height === 'short'
    ? 'space-y-4'
    : height === 'tall'
    ? 'space-y-12'
    : 'space-y-6'

  return (
    <div className="w-72 rounded-big shadow-12">
      <div className={cx(topBand, 'h-3 rounded-t-big')} />
      <div
        className={cx(
          'bg-white rounded-b-big border-2 border-t-0 border-secondary-2 pt-2 pb-5 px-4',
          spacingClassName
        )}
      >
        <div className="text-center">
          <h3 className="type-header-sm mb-0">{name}</h3>
          {expandsOn && (
            <div className="type-body-regular-sm mt-2">
              Everything in {expandsOn} plus:
            </div>
          )}
        </div>
        <div className="space-y-4">
          {features.map((text, i) => (
            <Feature key={i}>{text}</Feature>
          ))}
        </div>
        <div className="text-center mx-4">{footer}</div>
      </div>
    </div>
  )
}

function LearnMoreButton({ tier }) {
  const { trackEvent } = useTracking({ element: 'LearnMoreButton' })
  return (
    <Button
      className="w-full"
      variant="filled"
      iconLeft={<LocalPolice />}
      as={Link}
      to="/settings/subscriptions"
      onClick={() => trackEvent.click({ tier })}
    >
      Learn More
    </Button>
  )
}

function Background() {
  return useMinScreen('md') ? <WaveBackground /> : <GradientBackground />
}

function WaveBackground() {
  const gradientStart = useTheme('colors.brand.100')
  const gradientMiddle = useTheme('colors.secondary.100')
  const gradientEnd = useTheme('colors.primary.100')

  return (
    <svg
      className="w-full absolute inset-0 bottom-auto"
      style={{ zIndex: -1, margin: 0 }}
      height={400}
      viewBox="0 0 950 400"
      preserveAspectRatio="none"
    >
      <mask
        id="wave-mask"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="950"
        height="400"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m0 371.086 53.042 8.835c52.25 8.836 158.333 26.506 263.625 17.671 105.291-8.835 211.375-44.177 316.666-61.848 105.292-17.67 211.375-17.67 263.625-17.67H950V0H0v371.086Z"
          fill="#041D3A"
        />
      </mask>

      <defs>
        <linearGradient
          id="wave-gradient"
          x1="0"
          x2="950"
          y1="201"
          y2="201"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={gradientStart} />
          <stop offset={0.5} stopColor={gradientMiddle} />
          <stop offset={1} stopColor={gradientEnd} />
        </linearGradient>
      </defs>

      <g mask="url(#wave-mask)">
        <rect
          width="950"
          height="403"
          fill="url(#wave-gradient)"
          fillOpacity="0.86"
        />
        <rect width="950" height="403" fill="white" fillOpacity="0.6" />
      </g>
    </svg>
  )
}

function GradientBackground() {
  const gradientStart = useTheme('colors.brand.100')
  const gradientMiddle = useTheme('colors.secondary.100')
  const gradientEnd = useTheme('colors.primary.100')

  return (
    <>
      <div
        className="absolute inset-0 bottom-1/4 bg-gradient-to-b"
        style={{
          'margin': 0,
          'zIndex': -1,
          'opacity': '86%',
          // Tailwind only supports up to 3 color stops, and doesn't support
          // mixing an alpha channel with an existing color. So here we
          // manually override the --tw-gradient-stops variable.
          '--tw-gradient-stops': [
            `${gradientStart} 0%`,
            `${gradientMiddle} 50%`,
            `${gradientEnd} 86%`,
            `${gradientEnd}00 99%`,
          ].join(','),
        }}
      />
      <div
        className="absolute inset-0 bg-white bg-opacity-60"
        style={{ margin: 0, zIndex: -1 }}
      />
    </>
  )
}
