import { Button } from '@context365/button'
import { DownloadOutlined } from '@context365/icons'
import { Menu } from '@context365/menu'
import { useTracking } from 'react-tracking'
import * as api from '~/api'

const DownloadInvitationButton = ({ meeting, asMenuItem = false }) => {
  const { trackEvent } = useTracking()

  const handleCalendarDownload = (e, meeting) => {
    trackEvent({
      component: 'DownloadInvitationButton',
      element: 'ics download',
      eventName: 'click',
    })
    api.meeting.downloadInvitation(meeting.meetingId).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Context365-Meeting-${meeting.meetingId}.ics`
      )
      link.click()
      window.URL.revokeObjectURL(url)
    })

    e.stopPropagation()
    e.preventDefault()
  }

  return asMenuItem ? (
    <Menu.Item onClick={(e) => handleCalendarDownload(e, meeting)}>
      <DownloadOutlined className="mr-2" />
      Calendar invite (.ics)
    </Menu.Item>
  ) : (
    <Button
      variant="link"
      size="small"
      iconLeft={<DownloadOutlined />}
      onClick={(e) => handleCalendarDownload(e, meeting)}
    >
      Calendar Invite
    </Button>
  )
}

export default DownloadInvitationButton
