import PropTypes from 'prop-types'
import { Avatar, Dropdown, Menu, Popover } from 'antd'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import xor from 'lodash/xor'
import { nameToColor } from '~/utils'
import './ProfilePictureGroup.less'

function initials(profile) {
  return [profile.firstName, profile.lastName]
    .filter(Boolean)
    .map((s) => s[0])
    .join('')
}

const ProfilePictureGroup = ({
  profiles,
  maxImages = 5,
  type = 'summit',
  diameter = 32,
  style,
  showImageOnPopover = true,
}) => {
  const ellipsisClassName = `round-profile-thumbnail-group profile-ellipsis-${type}`

  const slicedImages = profiles.length > parseInt(maxImages, 10)

  const shownNames = slicedImages ? profiles.slice(0, maxImages) : profiles
  const moreNames = slicedImages ? xor(profiles, shownNames) : []

  let indexCtr = 0
  const avatarList = (
    <Menu>
      {map(moreNames, (x) => (
        <Menu.Item key={x.name}>
          <Popover
            key={x.name}
            overlayClassName={`ProfilePictureGroup-dropdown-popover ProfilePictureGroup-dropdown-popover-${
              (isNil(x.profilePicture) && isNil(x.imageUrl)) ||
              !showImageOnPopover
                ? 'no-'
                : '-'
            }image`}
            content={
              (!isNil(x.profilePicture) || !isNil(x.imageUrl)) &&
              showImageOnPopover && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    style={{ borderRadius: '50px' }}
                    src={
                      isNil(x.profilePicture)
                        ? x.imageUrl
                        : x.profilePicture.imageURL
                    }
                    alt=""
                    width="100px"
                    height="100px"
                  />
                </div>
              )
            }
          >
            <Avatar
              src={
                isNil(x.profilePicture) ? x.imageUrl : x.profilePicture.image
              }
              style={{
                backgroundColor: nameToColor(x.firstName, x.lastName),
                fontSize: '14px',
              }}
              shape="circle"
              size={diameter}
              className="dropdown-avatar"
            >
              {initials(x)}
            </Avatar>
          </Popover>
          {x.name}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <div className="min-w-16 round-profile-imagegroup" style={style}>
      {shownNames.map((x) => (
        <Popover
          title={x.name}
          key={x.name}
          overlayClassName={`ProfilePictureGroup-popover ProfilePictureGroup-popover-${
            (isNil(x.profilePicture) && isNil(x.imageUrl)) ||
            !showImageOnPopover
              ? 'no-'
              : '-'
          }image`}
          content={
            (!isNil(x.profilePicture) || !isNil(x.imageUrl)) &&
            showImageOnPopover && (
              <div style={{ textAlign: 'center' }}>
                <img
                  style={{ borderRadius: '50px' }}
                  src={
                    isNil(x.profilePicture)
                      ? x.imageUrl
                      : x.profilePicture.image
                  }
                  alt=""
                  width="100px"
                  height="100px"
                />
              </div>
            )
          }
        >
          <Avatar
            src={isNil(x.profilePicture) ? x.imageUrl : x.profilePicture.image}
            style={{
              position: 'relative',
              zIndex: 150 + indexCtr++,
              display: 'inline-block',
              backgroundColor: nameToColor(x.firstName, x.lastName),
              fontSize: '14px',
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
            shape="circle"
            size={diameter}
            className="round-profile-thumbnail-group"
          >
            {initials(x)}
          </Avatar>
        </Popover>
      ))}
      {slicedImages && (
        <Dropdown overlay={avatarList}>
          <Avatar
            style={{
              position: 'relative',
              zIndex: 150 + indexCtr++,
              display: 'inline-block',
              fontSize: '14px',
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
            shape="circle"
            size={diameter}
            className={ellipsisClassName}
          >
            +{profiles.length - parseInt(maxImages, 10)}
          </Avatar>
        </Dropdown>
      )}
    </div>
  )
}

ProfilePictureGroup.propTypes = {
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      imageUrl: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
  maxImages: PropTypes.number,
  type: PropTypes.string,
  diameter: PropTypes.number,
  style: PropTypes.object,
  showImageOnPopover: PropTypes.bool,
}

export default ProfilePictureGroup
