import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import {
  LightbulbOutlined,
  PsychologyOutlined,
  ThumbUpAltOutlined,
} from '@context365/icons'
import { Avatar, Divider, Modal } from 'antd'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import { useTracking } from 'react-tracking'
import { getInitials } from '~/utils'
import { pluralize } from '~/utils/helpers'
import './ReactionModal.less'

const ReactionModal = ({ reactions, contentEntryId }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const { trackEvent } = useTracking({ element: 'View Reactions' })

  const trackClick = () => trackEvent({ eventName: 'click', contentEntryId })

  const totalReactions = reactions?.length
  const totalLike = filter(reactions, ['reactionTypeId', 1]).length
  const totalInsight = filter(reactions, ['reactionTypeId', 2]).length
  const totalCurious = filter(reactions, ['reactionTypeId', 3]).length

  return (
    totalReactions > 0 && (
      <>
        <Button
          className="reactionButton"
          variant="link"
          size="small"
          onClick={() => {
            setIsModalVisible(true)
            trackClick()
          }}
        >
          <div className="content-entry__reactionCount">
            {totalLike > 0 && (
              <div className="bg-primary-100 text-white text-center align-middle pt-1 pl-1 rounded-full">
                <ThumbUpAltOutlined size="16px" />
              </div>
            )}
            {totalInsight > 0 && (
              <div className="bg-secondary-100 text-white align-middle -ml-2 pt-1 pl-1 rounded-full">
                <LightbulbOutlined size="16px" />
              </div>
            )}
            {totalCurious > 0 && (
              <div className="bg-brand-100 text-white align-middle -ml-2 pt-1 pl-1 rounded-full">
                <PsychologyOutlined size="16px" />
              </div>
            )}
            {totalReactions > 0 && (
              <span className="type-body-regular-xs ml-1">{`${pluralize(
                totalReactions,
                'reaction',
                'reactions'
              )}`}</span>
            )}
          </div>
        </Button>
        <Modal
          title="Reactions"
          visible={isModalVisible}
          onOk={() => {
            setIsModalVisible(false)
          }}
          onCancel={() => {
            setIsModalVisible(false)
          }}
          footer={null}
        >
          <div className="reactionModal-top">
            {totalReactions > 0 && (
              <>
                All <p className="reactionModal-num">{totalReactions}</p>
                <Divider type="vertical" />
              </>
            )}
            {totalLike > 0 && (
              <>
                <ThumbUpAltOutlined size="20px" /> {totalLike}
                <Divider type="vertical" />
              </>
            )}
            {totalInsight > 0 && (
              <>
                <LightbulbOutlined size="20px" /> {totalInsight}
                <Divider type="vertical" />
              </>
            )}
            {totalCurious > 0 && (
              <>
                <PsychologyOutlined size="20px" /> {totalCurious}
                <Divider type="vertical" />
              </>
            )}
          </div>

          <div>
            <table className="modalTable">
              <tbody>
                {reactions.map((item) => {
                  return (
                    <tr className="modalTable-row" key={item.companyContactId}>
                      <td>
                        <Avatar
                          className="modalTable-avatar"
                          size={45}
                          src={
                            isEmpty(item.contactImageUrl)
                              ? undefined
                              : item.contactImageUrl
                          }
                        >
                          {getInitials(item.contactName)}
                        </Avatar>
                      </td>
                      <td className="modalTable-name">
                        {item.contactName}
                        <br />
                        <p className="modalTable-job">{item.contactJobTitle}</p>
                      </td>
                      <td className="modalTable-icons">
                        {item.reactionTypeId === 1 && (
                          <ThumbUpAltOutlined style={{ fontSize: '25px' }} />
                        )}
                        {item.reactionTypeId === 2 && (
                          <LightbulbOutlined style={{ fontSize: '25px' }} />
                        )}
                        {item.reactionTypeId === 3 && (
                          <PsychologyOutlined style={{ fontSize: '25px' }} />
                        )}
                      </td>
                      <td className="modalTable-empty" />
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Modal>
      </>
    )
  )
}

ReactionModal.propTypes = {
  reactions: PropTypes.array.isRequired,
  contentEntryId: PropTypes.number.isRequired,
}

export default ReactionModal
