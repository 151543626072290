import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'

const Response = ({ questionText, responseText }) => (
  <div className="py-2 type-body-regular-sm">
    <span className="font-semibold">{`Q: ${questionText}`}</span>
    <div className="pt-4 pb-2">
      <span className="font-semibold">{'A: '}</span>
      <span>{responseText}</span>
    </div>
  </div>
)

const MandateQuestionnaireResponses = ({ questionnaireResponses }) => (
  <>
    {map(groupBy(questionnaireResponses, 'category'), (responses, category) => (
      <div className="px-16 pb-2">
        <span className="type-subtitle-xs">{category}</span>
        {map(responses, (response) => (
          <Response
            questionText={response.questionText}
            responseText={response.responseText}
          />
        ))}
      </div>
    ))}
  </>
)

MandateQuestionnaireResponses.propTypes = {
  questionnaireResponses: PropTypes.arrayOf(
    PropTypes.shape({
      questionText: PropTypes.string.isRequired,
      category: PropTypes.string,
      responseText: PropTypes.string.isRequired,
    })
  ),
}

export default MandateQuestionnaireResponses
