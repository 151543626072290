import * as React from 'react'

const MandateWizardContext = React.createContext()

export function MandateWizardContextProvider({ children, value }) {
  return (
    <MandateWizardContext.Provider value={value}>
      {children}
    </MandateWizardContext.Provider>
  )
}

export function useMandateWizardContext() {
  const context = React.useContext(MandateWizardContext)
  if (context === undefined) {
    throw new Error(
      'useMandateWizardContext must be used within a MandateWizardContextProvider'
    )
  }
  return context
}
