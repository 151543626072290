import qs from 'qs'
import { api } from './services'

export const sendConnection = (contactId, message = null) =>
  api.post(`/connections/requests/send/${contactId}`, { message })

export const getConnectionRequests = (
  page,
  pageSize,
  search,
  orderBy,
  filters
) =>
  api.get('/connections/requests', {
    params: {
      page,
      pageSize,
      q: search,
      orderBy,
      ...filters,
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  })

export const acceptConnectionRequest = (connectionRequestId) =>
  api.patch(`/connections/requests/${connectionRequestId}/accept`)

export const ignoreConnectionRequest = (connectionRequestId) =>
  api.patch(`/connections/requests/${connectionRequestId}/ignore`)

export const cancelConnectionRequest = (connectionRequestId) =>
  api.patch(`/connections/requests/${connectionRequestId}/cancel`)

export const removeConnection = (connectionId) =>
  api.delete(`/connections/${connectionId}`)

export const viewConnectionRequest = (connectionRequestId) =>
  api.post(`/connections/requests/${connectionRequestId}/view`)

export const getUnreadConnectionRequests = () =>
  api
    .get(`/connections/requests/unread`)
    .then((res) => res.data.result.unreadCount)
