import isNil from 'lodash/isNil'
import { Route } from 'react-router-dom-v5-compat'
import * as api from '~/api'
import { ContactInterests } from '~/views/Onboarding/pages/ContactInterests'
import ManagerCompanyForm from '../forms/ManagerCompanyForm'
import ManagerCompanyInterestsForm from '../forms/ManagerCompanyInterestsForm'
import { ContactEducation } from '../pages/ContactEducation'
import { ContactIdentification } from '../pages/ContactIdentification'
import { ContactLocation } from '../pages/ContactLocation'
import { ContactSocialMedia } from '../pages/ContactSocialMedia'
import { OnboardingStep } from '../pages/OnboardingStep'
import {
  Step,
  getNextStep,
  makeGetNextPagePath,
  makeGetPreviousPagePath,
} from './utils'

const companySteps = [Step.CompanyDetails, Step.CompanyInterests]

const contactSteps = [
  Step.ContactIdentification,
  Step.ContactLocation,
  Step.ContactEducation,
  Step.ContactInterest,
  Step.ContactSocialMedia,
]

const steps = [...companySteps, ...contactSteps]

const companyWizardSteps = [
  { path: Step.CompanyDetails, title: 'Company Info' },
  { path: Step.CompanyInterests, title: 'Investment Interests' },
]

const contactWizardSteps = [
  { path: Step.ContactIdentification, title: 'Contact Info' },
  { path: Step.ContactLocation, title: 'Location' },
  { path: Step.ContactEducation, title: 'Education' },
  { path: Step.ContactInterest, title: 'Interests' },
  { path: Step.ContactSocialMedia, title: 'Social Media' },
]

export const managerWorkflow = {
  getStartingPagePath: ({ company }) => {
    return company.hasCompanyOnboarded
      ? getNextStep(contactSteps, null, null)
      : isNil(company.companyStep)
      ? Step.CompanyDetails
      : companySteps[company.companyStep]
  },
  getNextPagePath: makeGetNextPagePath(steps),
  getPreviousPagePath: makeGetPreviousPagePath(steps),

  routes: [
    <Route
      key={Step.CompanyDetails}
      path={Step.CompanyDetails}
      element={
        <OnboardingStep
          getSavedAnswers={api.onboarding.getManagerCompanyAnswers}
          saveAnswers={api.onboarding.saveManagerCompanyAnswers}
          steps={companyWizardSteps}
          form={<ManagerCompanyForm />}
          formKey="company-details"
          title="Company Information"
        />
      }
    />,
    <Route
      key={Step.CompanyInterests}
      path={Step.CompanyInterests}
      element={
        <OnboardingStep
          getSavedAnswers={api.onboarding.getManagerCompanyInterestAnswers}
          saveAnswers={api.onboarding.saveManagerCompanyInterestAnswers}
          steps={companyWizardSteps}
          form={<ManagerCompanyInterestsForm />}
          formKey="investment-interests"
          title="Investment Interests"
        />
      }
    />,
    <Route
      key={Step.ContactIdentification}
      path={Step.ContactIdentification}
      element={<ContactIdentification steps={contactWizardSteps} />}
    />,
    <Route
      key={Step.ContactLocation}
      path={Step.ContactLocation}
      element={<ContactLocation steps={contactWizardSteps} />}
    />,
    <Route
      key={Step.ContactEducation}
      path={Step.ContactEducation}
      element={<ContactEducation steps={contactWizardSteps} />}
    />,
    <Route
      key={Step.ContactInterest}
      path={Step.ContactInterest}
      element={
        <ContactInterests
          steps={contactWizardSteps}
          includeAverageInvestmentSize={true}
        />
      }
    />,
    <Route
      key={Step.ContactSocialMedia}
      path={Step.ContactSocialMedia}
      element={<ContactSocialMedia steps={contactWizardSteps} />}
    />,
  ],
}
