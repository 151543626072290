import PropTypes from 'prop-types'
import { Tooltip } from '@context365/popovers'
import { Progress } from 'antd'
import cx from 'classnames'
import './MatchmakingScore.less'

const MatchmakingScore = ({
  className,
  score,
  threshold,
  size = 40,
  title = 'Matchmaking Score',
}) => {
  const isMatch = score >= threshold
  return (
    <Tooltip placement="top" text={title}>
      <span>
        <Progress
          className={cx(
            className,
            'matchmaking-score',
            isMatch && 'matchmaking-score--match',
            'pl-2'
          )}
          type="circle"
          percent={Math.round(score * 100)}
          format={(percent) => `${percent}%`}
          width={size}
          strokeColor={isMatch ? '#24C477' : '#F19F00'}
        />
      </span>
    </Tooltip>
  )
}

MatchmakingScore.propTypes = {
  score: PropTypes.number,
  threshold: PropTypes.number,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default MatchmakingScore
