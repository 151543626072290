import PropTypes from 'prop-types'
import { Avatar } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { getInitials } from '~/utils'
import './ContactAvatar.less'

const ContactAvatar = ({ contactName, imageUrl, jobTitle }) => (
  <div className="content-contact-avatar">
    <Avatar
      className="content-contact-avatar__image"
      size={36}
      src={isEmpty(imageUrl) ? undefined : imageUrl}
    >
      {getInitials(contactName)}
    </Avatar>
    <div>
      <div className="content-contact-avatar__name">{contactName}</div>
      {jobTitle && (
        <div className="content-contact-avatar__job-title">{jobTitle}</div>
      )}
    </div>
  </div>
)

ContactAvatar.propTypes = {
  contactName: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  jobTitle: PropTypes.string,
}

export default ContactAvatar
