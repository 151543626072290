import isNil from 'lodash/isNil'
import sum from 'lodash/sum'

export const nameToColor = (name, lastName) => {
  const colorLimit = 50
  const minValue = colorLimit
  const maxValue = 255 - colorLimit

  let r = isNil(name) ? 0 : sum(name.split('').map((x) => x.charCodeAt()))
  let g = isNil(lastName)
    ? 0
    : sum(lastName.split('').map((x) => x.charCodeAt()))
  let b = r + g //isNil(name + lastName) ? 0 : sum((name + lastName).split('').map(x => x.charCodeAt()));

  r = (r % (maxValue - minValue)) + minValue
  g = (g % (maxValue - minValue)) + minValue
  b = (b % (maxValue - minValue)) + minValue

  //return `rgb(${r}, ${g}, ${b});`;
  return rgbToHex(r, g, b)
}

function componentToHex(c) {
  const hex = c.toString(16)
  return hex.length === 1 ? `0${hex}` : hex
}

function rgbToHex(r, g, b) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}
