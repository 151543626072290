import * as React from 'react'
import useFrameState from './useFrameState'

export default function useWindowSize() {
  const [size, setSize] = useFrameState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  React.useLayoutEffect(() => {
    function onResize() {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [setSize])

  return size
}
