import PropTypes from 'prop-types'
import { Radio } from 'antd'
import isNil from 'lodash/isNil'
import OnboardingLoading from './OnboardingLoading'

const CompanySelection = ({ onCompanyChanged, enableNext, companies }) => {
  const onChange = (e) => {
    const companyId = e.target.value
    if (companyId > 0) {
      enableNext()
      onCompanyChanged(companyId)
    }
  }

  if (isNil(companies)) return <OnboardingLoading />

  return (
    <Radio.Group onChange={onChange} style={{ marginBottom: '-24px' }}>
      {companies.map((x) => (
        <Radio
          className="cc-company-selection-item"
          value={x.companyID}
          key={x.companyID}
        >
          <div className="cc-company-selection-item-text">
            <div className="cc-body-text cc-company-selection-name">
              {x.name}
            </div>
            <div className="cc-small-font cc-company-selection-type">
              {x.category}
            </div>
          </div>
        </Radio>
      ))}
    </Radio.Group>
  )
}

CompanySelection.propTypes = {
  onCompanyChanged: PropTypes.func.isRequired,
  enableNext: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
}

export default CompanySelection
