const POLICIES = {
  TERMS_AND_CONDITIONS:
    'https://media.apexinvest.io/context365-data/policies/TermsAndConditions.pdf',
  SUBSCRIPTION_AGREEMENT:
    'https://media.apexinvest.io/context365-data/policies/SubscriptionAgreement.pdf',
  PRIVACY_POLICY:
    'https://media.apexinvest.io/context365-data/policies/PrivacyPolicy.pdf',
  TERMS_OF_USE:
    'https://media.apexinvest.io/context365-data/policies/TermsofUse.pdf',
}

export default POLICIES
