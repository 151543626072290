import { useMemo } from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import FilterTag from './FilterTag'
import formatFilters from './formatFilters'

const FiltersDisplay = ({ className, filters }) => {
  const formattedFilters = useMemo(() => {
    const filterList = filter(filters, (f) => f.alertColumnType !== 'Fixed')
    return formatFilters(filterList)
  }, [filters])

  return (
    <div className={`${className} -mx-2`}>
      {formattedFilters?.filter(Boolean).map((x) => (
        <FilterTag key={x.Column} name={x.Column} value={x.Value} />
      ))}
    </div>
  )
}

FiltersDisplay.propTypes = {
  filters: PropTypes.array.isRequired,
  className: PropTypes.string,
}

export default FiltersDisplay
