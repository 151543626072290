import PropTypes from 'prop-types'
import { Divider } from 'antd'
import filter from 'lodash/filter'
import map from 'lodash/map'
import FloatingTextArea from '../FloatingTextArea'
import Loading from '../Loading'

const MeetingQuestionsForm = ({
  loadingQuestions,
  questionsForFund,
  answers,
  setAnswers,
}) => (
  <Loading spinning={loadingQuestions}>
    <div className="cc-tabletitle-text cc-darkest-grey-text">
      Answer these questions
    </div>
    <Divider style={{ margin: '16px 0px' }} />
    <div>
      {map(questionsForFund, (q) => (
        <FloatingTextArea
          name={q.question}
          nameField={q.meetingQuestionId}
          value={
            answers &&
            filter(
              answers,
              (a) => a.meetingQuestionId === q.meetingQuestionId
            )[0]?.answer
          }
          onChange={(value) => {
            const unchangedAnswers = filter(
              answers,
              (a) => a.meetingQuestionId !== q.meetingQuestionId
            )
            const changedAnswers = [
              ...unchangedAnswers,
              { meetingQuestionId: q.meetingQuestionId, answer: value },
            ]
            setAnswers(changedAnswers)
          }}
        />
      ))}
    </div>
  </Loading>
)

MeetingQuestionsForm.propTypes = {
  loadingQuestions: PropTypes.bool.isRequired,
  questionsForFund: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  setAnswers: PropTypes.func.isRequired,
}
export default MeetingQuestionsForm
