import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { RightOutlined } from '@ant-design/icons'
import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

const DragDropContextForm = ({ options, value = [], onChange = null }) => {
  const [unselected, setUnselected] = useState(
    !isEmpty(value)
      ? filter(options, (x) => isNil(find(value, (v) => v.id === x.id)))
      : options
  )
  const [selected, setSelected] = useState(value)

  // reordering the result
  const reorder = useCallback((list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }, [])

  //Moves an item from one list to another list.
  const move = useCallback(
    (source, destination, droppableSource, droppableDestination) => {
      const sourceClone = Array.from(source)
      const destClone = Array.from(destination)
      const [removed] = sourceClone.splice(droppableSource.index, 1)

      destClone.splice(droppableDestination.index, 0, removed)

      const result = {}
      result[droppableSource.droppableId] = sourceClone
      result[droppableDestination.droppableId] = destClone

      return result
    },
    []
  )

  const getItemStyle = useCallback(
    (isDragging, draggableStyle) => ({
      userSelect: 'none',
      padding: 16,
      margin: '0 0 8px 0',
      background: isDragging ? 'lightgreen' : '#FFFFFF',
      ...draggableStyle,
    }),
    []
  )

  const getListStyle = useCallback(
    (isDraggingOver) => ({
      background: isDraggingOver ? 'lightblue' : '#EFF1F4',
      padding: 8,
      width: '40%',
      margin: '15px',
      display: 'inline-block',
      verticalAlign: 'top',
    }),
    []
  )

  const getList = useCallback(
    (id) => (id === 'unselected' ? unselected : selected),
    [unselected, selected]
  )

  const onDragEnd = useCallback(
    (result) => {
      const { source, destination } = result

      // dropped outside the list
      if (!destination) {
        return
      }

      if (source.droppableId === destination.droppableId) {
        const reorderedItems = reorder(
          getList(source.droppableId),
          source.index,
          destination.index
        )

        if (source.droppableId === 'selected') {
          setSelected(reorderedItems)
          if (!isNil(onChange)) {
            onChange(reorderedItems)
          }
        }
      } else {
        const result = move(
          getList(source.droppableId),
          getList(destination.droppableId),
          source,
          destination
        )
        setUnselected(result.unselected)
        setSelected(result.selected)

        if (!isNil(onChange)) {
          onChange(result.selected)
        }
      }
    },
    [getList, move, onChange, reorder]
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="unselected">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <h4 style={{ textAlign: 'left' }}>Available</h4>
            {map(unselected, (item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <RightOutlined style={{ marginTop: '150px' }} />
      <Droppable style={{ width: '20%' }} droppableId="selected">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <h4 style={{ textAlign: 'left' }}>Selected</h4>
            {map(selected, (item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

DragDropContextForm.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
}
export default DragDropContextForm
