import InputField from '~/components/ContextForm/Fields/InputField'

const getConditionalRadioFieldComponent = (question) => {
  let component
  switch (question.questionType) {
    case 'Text - Single':
      component = InputField
      break
    default:
      component = 'div'
  }
  return component
}

export default getConditionalRadioFieldComponent
