import identity from 'lodash/identity'
import numeral from 'numeral'

export const currencyLarge = withNumeralFormat('($0,0[.]0 a)', {
  transform: (s) => s.toUpperCase().trim(),
})

export const currency = withNumeralFormat('($0,0[.]00)')

export const thousands = withNumeralFormat('0,0')

export function decimal(value, { precision = 2, ...options } = {}) {
  const format = `0.[${'0'.repeat(precision)}]`
  return formatValue(value, format, options)
}

export function percent(value, { precision = 2, ...options } = {}) {
  const format = `0.[${'0'.repeat(precision)}]%`
  return formatValue(value, format, options)
}

function withNumeralFormat(format, defaultOptions = {}) {
  return (value, options) =>
    formatValue(value, format, { ...defaultOptions, ...options })
}

function formatValue(
  value,
  format,
  { transform = identity, suffix = '', defaultValue }
) {
  const n = numeral(value)
  if (value == null || !isValid(n)) {
    return defaultValue
  }

  return transform(n.format(format)) + suffix
}

function isValid(numeral) {
  const value = numeral.value()
  return typeof value === 'number' && !isNaN(value)
}
