import * as React from 'react'
import PropTypes from 'prop-types'
import join from 'lodash/join'
import map from 'lodash/map'
import * as api from '~/api'
import FundPreviewList from '~/components/FundPreviewList'

const CompanyProfileFunds = ({ funds }) => {
  const getFunds = React.useCallback(() => {
    const fundIds = map(funds, 'id')
    return api.discovery.funds({
      filter: [`fundId in ${join(fundIds, ',')}`],
    })
  }, [funds])

  return <FundPreviewList getFunds={getFunds} />
}

CompanyProfileFunds.propTypes = {
  funds: PropTypes.arrayOf(PropTypes.object),
}

export default CompanyProfileFunds
