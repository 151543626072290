import PropTypes from 'prop-types'
import { Input } from 'antd'
import useFormAnswers from '~/utils/hooks/useFormAnswers'
import RequirableField from './RequirableField'
import '../Fields.less'

const InputField = ({ question }) => {
  const {
    answer,
    handleTextAnswerChange,
    isRequiredForApplication,
    handleRequirementChange,
  } = useFormAnswers(question)

  return (
    <RequirableField
      allowRequirement={question.allowRequirementSelection}
      checked={isRequiredForApplication}
      onChange={handleRequirementChange}
      containerClassName="items-center"
    >
      <Input
        className="InputField"
        name={question.name}
        value={answer.responseText}
        onChange={(e) => handleTextAnswerChange(e.target.value)}
      />
    </RequirableField>
  )
}

InputField.propTypes = {
  question: PropTypes.shape({
    questionId: PropTypes.number,
    name: PropTypes.string,
    shortName: PropTypes.string,
    isRequired: PropTypes.bool,
    allowRequirementSelection: PropTypes.bool,
  }),
}

export default InputField
