import { DetailedAvatar } from '@context365/avatar'
import { Button } from '@context365/button'
import { Launch } from '@context365/icons'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom-v5-compat'
import * as api from '~/api'
import { getInitials } from '~/utils'
import WidgetBlock from './WidgetBlock'

const NewPeople = () => {
  const navigate = useNavigate()

  const { data = {} } = useQuery(
    'dashboardDirectory',
    () =>
      api.discovery.directory({
        page: 1,
        pageSize: 5,
        orderBy: '-ContactId',
      }),
    {
      keepPreviousData: true,
    }
  )
  const contacts = data.result ?? []
  return (
    <WidgetBlock
      headerLeft="New People"
      headerRight={
        <Button
          variant="link"
          as={Link}
          to="/community/directory"
          iconRight={<Launch />}
          style={{ padding: 0 }}
          status="secondary"
        >
          See All
        </Button>
      }
    >
      <div className="flex flex-col gap-4">
        {map(contacts, (contact) => (
          <div
            key={contact.contactId}
            className="flex flex-row justify-between"
          >
            <DetailedAvatar
              initials={getInitials(contact.contactName)}
              name={contact.contactName}
              company={
                isEmpty(contact.companies) || contact.companies.length > 1
                  ? null
                  : contact.companies[0].name
              }
              className="cursor-pointer"
              onClick={() => navigate(`/profile/contact/${contact.contactId}`)}
            />
            <div className="flex justify-end">
              <Button
                variant="link"
                as={Link}
                to={`/profile/contact/${contact.contactId}`}
              >
                View Profile
              </Button>
            </div>
          </div>
        ))}
      </div>
    </WidgetBlock>
  )
}

export default NewPeople
