import PropTypes from 'prop-types'
import { Check } from '@context365/icons'
import { Modal } from 'antd'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom-v5-compat'

const SuccessfulApplicationModal = ({
  visible,
  onClose,
  applyingCampaignId,
  applyingCampaignName,
}) => {
  const sentPipelineUrl = `/workspace/${applyingCampaignId}/pipeline?isSent=true`
  const history = useHistory()

  return (
    <Modal
      title="Sent"
      visible={visible}
      okText="Continue in Discover"
      cancelText="View Pipeline"
      onCancel={() => {
        onClose()
        history.push(sentPipelineUrl)
      }}
      onOk={() => {
        onClose()
        window.location.reload() //gets around a bug where the page is unscrollable
      }}
    >
      <div className="flex flex-col items-center text-center px-12">
        <Check color="green" size={48} />
        <div className="pt-2 pb-5 type-body-regular-lg">
          Interest sent and added to your{' '}
          <Link to={sentPipelineUrl} onClick={() => onClose()}>
            {applyingCampaignName} Pipeline
          </Link>
          .
        </div>
        <div>Manage, track, and monitor your outreach in one place.</div>
      </div>
    </Modal>
  )
}

SuccessfulApplicationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  applyingCampaignId: PropTypes.number,
  applyingCampaignName: PropTypes.string,
}

export default SuccessfulApplicationModal
