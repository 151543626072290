import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import { FilterAlt } from '@context365/icons'
import { Menu } from '@context365/menu'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

const SelectFilter = ({ column: { filterValue, setFilter, id }, options }) => {
  const [value, setValue] = useState(filterValue || [])
  const updateArray = (val) => {
    const index = value.indexOf(val)
    const arr = value.slice()
    index === -1 ? arr.push(val) : arr.splice(index, 1)
    setValue(arr)
  }
  return (
    <Menu
      trigger={
        <IconButton
          className="mr-2"
          size="small"
          status={isEmpty(filterValue) ? 'secondary' : 'default'}
          variant="link"
          label="Filter"
          icon={<FilterAlt />}
        />
      }
    >
      <div style={{ maxHeight: '250px', overflow: 'auto' }}>
        {map(options, (opt) => (
          <Menu.Item
            key={id}
            onClick={(e) => {
              e.preventDefault()
              updateArray(opt)
            }}
          >
            <label className="cursor-pointer">
              <input
                type="checkbox"
                className="cursor-pointer"
                checked={value.indexOf(opt) > -1}
                onClick={(e) => {
                  e.stopPropagation()
                  updateArray(opt)
                }}
              />
              <span className="ml-1 text-md">{opt}</span>
            </label>
          </Menu.Item>
        ))}
      </div>
      <div className="flex justify-between" style={{ padding: '10px' }}>
        <Button
          onClick={() => setFilter([])}
          status="error"
          variant="link"
          disabled={isEmpty(value)}
        >
          Reset
        </Button>
        <Button onClick={() => setFilter(value)} variant="filled">
          OK
        </Button>
      </div>
    </Menu>
  )
}

SelectFilter.propTypes = {
  column: PropTypes.object.isRequired,
  options: PropTypes.array,
}

export default SelectFilter
