import { useState } from 'react'
import { Button } from '@context365/button'
import { MoreTime } from '@context365/icons'
import { useTracking } from 'react-tracking'
import SetMeetingTimeModal from './SetMeetingTimeModal'

const ScheduleMeeting = ({ meetingId, onSubmit }) => {
  const { trackEvent } = useTracking()
  const [proposeTimeVisible, setProposeTimeVisible] = useState(false)
  return (
    <>
      <Button
        className="flex-shrink-0"
        variant="outlined"
        onClick={() => setProposeTimeVisible(true)}
        iconLeft={<MoreTime className="flex-shrink-0" />}
      >
        Set Meeting Time
      </Button>

      <SetMeetingTimeModal
        visible={proposeTimeVisible}
        meetingId={meetingId}
        onSubmit={() => {
          trackEvent({ eventName: 'click', element: 'SetMeetingTime' })
          onSubmit()
          setProposeTimeVisible(false)
        }}
        onClose={() => setProposeTimeVisible(false)}
      />
    </>
  )
}

export default ScheduleMeeting
