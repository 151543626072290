import { useState } from 'react'
import { Button } from '@context365/button'
import { Add } from '@context365/icons'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useQuery } from 'react-query'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import CreateOrEditCampaign from '~/components/CreateOrEditCampaign'
import Loading from '~/components/Loading'
import { CAMPAIGN_ENTITY_TYPES } from '~/constants/campaignEntityTypes'
import useAuth from '~/hooks/useAuth'
import { SeeAllLink, WidgetBlock } from '../WidgetBlock'
import CampaignsEmptyState from './CampaignsEmptyState'
import FundCampaign from './FundCampaign'
import MandateCampaign from './MandateCampaign'
import ResearchCampaign from './ResearchCampaign'

const MyCampaigns = () => {
  const { company } = useAuth()

  const { data: campaigns = [], isLoading } = useQuery(
    ['dashboardCampaigns', company.companyId],
    () =>
      api.campaigns.getMyCampaigns(company.companyId, {
        pageNumber: 1,
        pageSize: 3,
        orderBy: '-LastAccessDate',
      }),
    {
      select: (data) => data.results.filter(isCampaignDisplayable),
    }
  )
  const { Track, trackEvent } = useTracking({ component: 'MyCampaigns' })
  const [showCreateCampaign, setShowCreateCampaign] = useState(false)
  const trackClick = (label) => trackEvent({ eventName: 'click', label })

  return (
    <Track>
      <WidgetBlock
        headerLeft="My Campaigns"
        helpContent="Campaigns provide you with a pipeline to manage, track, and monitor capital raise and capital deployment efforts with your peers. Pipeline types include Mandates, Research, and Funds."
        headerRight={
          <>
            <SeeAllLink to="/campaigns" />
            <Button
              variant="filled"
              status="secondary"
              onClick={() => {
                trackClick('new-presentation')
                setShowCreateCampaign(true)
              }}
              iconLeft={<Add />}
            >
              Create Pipeline
            </Button>
          </>
        }
      >
        <Loading spinning={isLoading}>
          <div className="flex flex-col gap-4">
            {isLoading ? (
              // give the card some extra height, so it doesn't shift too much
              // once the data loads
              <div style={{ height: 450 }} />
            ) : isEmpty(campaigns) ? (
              <CampaignsEmptyState />
            ) : (
              map(campaigns, (campaign) =>
                campaign.campaignEntityTypeId === CAMPAIGN_ENTITY_TYPES.FUND ? (
                  <FundCampaign key={campaign.campaignId} campaign={campaign} />
                ) : campaign.campaignEntityTypeId ===
                  CAMPAIGN_ENTITY_TYPES.MANDATE ? (
                  <MandateCampaign
                    key={campaign.campaignId}
                    campaign={campaign}
                  />
                ) : campaign.campaignEntityTypeId ===
                  CAMPAIGN_ENTITY_TYPES.RESEARCH ? (
                  <ResearchCampaign
                    key={campaign.campaignId}
                    campaign={campaign}
                  />
                ) : null
              )
            )}
          </div>
        </Loading>
        <CreateOrEditCampaign
          visible={showCreateCampaign}
          onClose={() => setShowCreateCampaign(false)}
        />
      </WidgetBlock>
    </Track>
  )
}

export default MyCampaigns

function isCampaignDisplayable(campaign) {
  return [
    CAMPAIGN_ENTITY_TYPES.FUND,
    CAMPAIGN_ENTITY_TYPES.MANDATE,
    CAMPAIGN_ENTITY_TYPES.RESEARCH,
  ].includes(campaign.campaignEntityTypeId)
}
