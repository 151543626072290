import { useEffect, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import includes from 'lodash/includes'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import Loading from '~/components/Loading'
import MeetingList from '~/components/Meetings/MeetingList'
import { MEETING_TYPE } from '~/constants/meetingTypeIds'
import './Calendar.less'

const Requests = () => {
  const { Track } = useTracking({ page: 'Meeting Requests' })
  const [meetingRequests, setMeetingRequests] = useState([])

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState()
  const [meetingTypeIds, setMeetingTypeIds] = useState([
    MEETING_TYPE.SUMMIT.toString(),
  ])
  const [sentRequestsOnly, setSentRequestsOnly] = useState(false)
  const [search, setSearch] = useState()

  const loadMeetingRequests = () => {
    setLoading(true)
    const includeSummits = includes(
      meetingTypeIds,
      MEETING_TYPE.SUMMIT.toString()
    )
    const includeOnline = includes(
      meetingTypeIds,
      MEETING_TYPE.ONLINE.toString()
    )
    if (!includeSummits && !includeOnline) {
      setMeetingRequests([])
      setTotalCount(0)
      setLoading(false)
    } else {
      api.meeting
        .getRequests(page, pageSize, search, {
          includeSummits,
          includeOnline,
          sentOnly: sentRequestsOnly,
        })
        .then((response) => {
          setMeetingRequests(response.data.result.results)
          setTotalCount(response.data.result.itemCount)
        })
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    loadMeetingRequests()
  }, [search, meetingTypeIds, sentRequestsOnly, page, pageSize])

  return (
    <Track>
      <div className="p-4 lg:p-8">
        {loading && (
          <div className="absolute w-full text-center">
            <Loading spinning={loading} />
          </div>
        )}
        <MeetingList
          meetings={meetingRequests}
          emptyText="No meetings to show that match the current filters"
          meetingTypeIds={meetingTypeIds}
          setMeetingTypeIds={setMeetingTypeIds}
          sentRequestsOnly={sentRequestsOnly}
          setSentRequestsOnly={setSentRequestsOnly}
          hideLocationFilter={true}
          setSearch={setSearch}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          total={totalCount}
          refreshList={loadMeetingRequests}
          isRequests={true}
        />
      </div>
    </Track>
  )
}

export default Requests
