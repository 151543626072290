import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import * as api from '~/api'
import Loading from '~/components/Loading'
import Profile from '~/components/Profile'
import { getCompanyProfileModal, hideModal } from '~/store/modals'
import CompanyProfileBody from './CompanyProfileBody'
import CompanyProfileHeader from './CompanyProfileHeader'

export default function CompanyProfileModal() {
  const dispatch = useDispatch()
  const { visible, companyId } = useSelector(getCompanyProfileModal)

  const { data: company, status } = useQuery(
    ['companyProfile', companyId],
    () => api.companies.getCompanyProfile(companyId),
    {
      enabled: visible,
    }
  )

  const isLoading = status === 'loading' || status === 'idle'

  return (
    <Profile.Modal
      visible={visible}
      onClose={() => dispatch(hideModal())}
      header={
        isLoading ? null : (
          <CompanyProfileHeader
            company={company}
            profileUrl={`/profile/company/${companyId}`}
          />
        )
      }
      body={isLoading ? <Loading /> : <CompanyProfileBody company={company} />}
    />
  )
}
