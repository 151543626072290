import * as React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from '@context365/avatar'
import { Button, IconButton } from '@context365/button'
import {
  CheckCircleOutlined,
  MoreHoriz,
  TimerOutlined,
} from '@context365/icons'
import { Menu } from '@context365/menu'
import { Modal } from '@context365/modals/Modal'
import { Tag } from '@context365/tag'
import { message } from 'antd'
import map from 'lodash/map'
import { useDispatch } from 'react-redux'
import MatchmakingScore from '~/components/MatchmakingScore'
import useTracking from '~/hooks/useTracking'
import {
  showCompanyProfileModal,
  showContactProfileModal,
} from '~/store/modals'
import { getInitials } from '~/utils/helpers'
import { useRemoveConnection } from './useConnections'
import { getCategoryDisplay } from './util'

const ContactCard = ({
  contactId,
  isPendingConnection,
  connectionId,
  contactName,
  jobTitle,
  imageUrl,
  companies,
  matchmakingScore,
}) => {
  const { trackEvent } = useTracking()

  const dispatch = useDispatch()
  const removeConnection = useRemoveConnection(connectionId)
  const [showRemoveConnectionModal, setShowRemoveConnectionModal] =
    React.useState(false)

  return (
    <>
      <div className="p-4 border bg-white rounded">
        <div className="flex flex-col gap-4 text-center">
          <div className="text-center relative">
            <Avatar
              size="big"
              profileImageUrl={imageUrl}
              initials={getInitials(contactName)}
              isOrganizer={false}
            />

            <div className="flex absolute right-0 top-0 gap-2">
              {matchmakingScore > 0 && (
                <MatchmakingScore score={matchmakingScore} threshold={0.5} />
              )}
              {connectionId && (
                <Menu
                  trigger={
                    <IconButton
                      variant="link"
                      icon={<MoreHoriz />}
                      label="actions"
                    />
                  }
                >
                  <Menu.Item onClick={() => setShowRemoveConnectionModal(true)}>
                    Remove Connection
                  </Menu.Item>
                </Menu>
              )}
            </div>
          </div>
          <div className="type-body-semibold-md">{contactName}</div>
          {jobTitle && <div className="type-body-regular-sm">{jobTitle}</div>}
          {connectionId && (
            <div className="flex justify-center">
              <Tag color="green" size="small">
                <CheckCircleOutlined className="mr-2" />
                Connected
              </Tag>
            </div>
          )}
          {isPendingConnection && (
            <div className="flex justify-center">
              <Tag color="yellow" size="small">
                <TimerOutlined className="mr-2" />
                Pending
              </Tag>
            </div>
          )}
          <div className="flex justify-center text-left">
            <div className="flex flex-col">
              {map(companies, (company) => (
                <Button
                  variant="link"
                  status="secondary"
                  style={{ whiteSpace: 'normal' }}
                  onClick={() => {
                    trackEvent.click({
                      label: 'view company',
                      companyId: company.companyId,
                    })
                    dispatch(
                      showCompanyProfileModal({
                        companyId: company.companyId,
                      })
                    )
                  }}
                >{`${getCategoryDisplay(company.category)} at ${
                  company.name
                }`}</Button>
              ))}
            </div>
          </div>
          <div className="justify-center flex flex-col gap-4 items-center">
            <Button
              onClick={() => {
                trackEvent.click({ label: 'view profile', contactId })
                dispatch(
                  showContactProfileModal({
                    contactId,
                  })
                )
              }}
            >
              View Profile
            </Button>
          </div>
        </div>
      </div>
      <Modal
        visible={showRemoveConnectionModal}
        onDismiss={() => setShowRemoveConnectionModal(false)}
        title="Remove Connection"
        footerLeft={
          <Button onClick={() => setShowRemoveConnectionModal(false)}>
            Cancel
          </Button>
        }
        footerRight={
          <Button
            variant="filled"
            status="error"
            onClick={() => {
              trackEvent.click({ label: 'remove connection', connectionId })
              removeConnection().then(
                () => {
                  message.success('Connection removed.')
                },
                () => message.error('Could not remove connection.')
              )
            }}
          >
            Remove
          </Button>
        }
      >
        Are you sure you want to remove this connection?
      </Modal>
    </>
  )
}

ContactCard.propTypes = {
  contactId: PropTypes.number.isRequired,
  isPendingConnection: PropTypes.bool,
  connectionId: PropTypes.number,
  contactName: PropTypes.string.isRequired,
  jobTitle: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  companies: PropTypes.array.isRequired,
  matchmakingScore: PropTypes.number.isRequired,
}

export default ContactCard
