import { useEffect, useState } from 'react'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { presentations as presentationsClient } from '~/api'
import { CloudMeetUrl } from '~/config'
import CloudMeetFrame from './presentations/CloudMeetFrame'

const PresentationRoom = () => {
  const { id } = useParams()
  const [roomId, setRoomId] = useState(null)
  const [roomKey, setRoomKey] = useState(null)
  const [participantKey, setParticipantKey] = useState(null)
  const [host, setHost] = useState()
  const notifications = useSelector(
    (state) => state.notifications.presentationNotifications
  )

  useEffect(() => {
    presentationsClient.getPresentationAccessKeys(id).then((resp) => {
      const {
        result: { id, roomKey, participantKey },
      } = resp.data

      setRoomId(id)
      setRoomKey(roomKey)
      setParticipantKey(participantKey)
    })
  }, [id])

  useEffect(() => {
    if (
      every(
        [roomId, roomKey, participantKey],
        (elem) => !(isNil(elem) || isEmpty(elem))
      )
    ) {
      const host = `${CloudMeetUrl}?roomId=${roomId}&roomKey=${roomKey}&participantKey=${participantKey}`
      setHost(host)
    }
  }, [participantKey, roomId, roomKey])

  return (
    <div
      className="w-full overflow-y-hidden"
      style={{ height: `calc(100vh - ${notifications.length * 50}px)` }}
    >
      <CloudMeetFrame src={host} />
    </div>
  )
}

export default PresentationRoom
