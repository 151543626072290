const slice = (getModalsState) => (state) => getModalsState(state.modals)

export const getContactProfileModal = slice(
  (state) => state.contactProfileModal ?? { visible: false }
)

export const getCompanyProfileModal = slice(
  (state) => state.companyProfileModal ?? { visible: false }
)

export const getApplyToCampaignModal = slice(
  (state) => state.indicateInterestModal ?? { visible: false }
)

export const getResearchCampaignProfileModal = slice(
  (state) => state.researchCampaignProfileModal ?? { visible: false }
)
