import PropTypes from 'prop-types'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input, Rate } from 'antd'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import trim from 'lodash/trim'
import * as yup from 'yup'
import MeetingWith from './MeetingWith'

const { TextArea } = Input

const validator = yup.object().shape({
  rating: yup.number().min(1).max(5).required(),
  comment: yup.string().when('rating', {
    is: (val) => val <= 3,
    then: yup.string().trim().required(),
  }),
})

const ManagerToAllocatorRatingForm = ({
  meeting,
  onChange,
  onSave,
  isSaving,
}) => {
  const {
    meetingId,
    meetingDateTime,
    toCompanyId,
    toCompanyName,
    contacts,
    rating,
    isValid,
    meetingType,
  } = meeting
  const ratingValue = get(rating, 'rating', 0)
  const comment = get(rating, 'comment', '')

  const showCommentHelp =
    ratingValue !== 0 && ratingValue <= 3 && isEmpty(trim(comment))

  const handleRatingChange = (givenRating) => {
    const newRatingValue = {
      rating: givenRating,
      comment,
    }
    onChange({
      meetingId,
      isValid: validator.isValidSync(newRatingValue),
      rating: newRatingValue,
    })
  }

  const handleCommentChange = (e) => {
    const { value } = e.target
    const newRatingValue = {
      rating: ratingValue,
      comment: value,
    }
    onChange({
      meetingId,
      isValid: validator.isValidSync(newRatingValue),
      rating: newRatingValue,
    })
  }

  const handleSave = () => {
    onSave({
      meetingId,
      toCompanyId,
      ...rating,
    })
  }

  return (
    <div className="ManagerToAllocatorRatingForm">
      <MeetingWith
        companyName={toCompanyName}
        meetingDateTime={meetingDateTime}
        contacts={contacts}
        meetingType={meetingType}
      />
      <div className="ManagerToAllocatorRatingForm-rating">
        <p className="ManagerToAllocatorRatingForm-rating-header">
          Rate the meeting:
        </p>
        <Rate onChange={handleRatingChange} defaultValue={ratingValue} />
      </div>
      <div className="ManagerToAllocatorRatingForm-comment">
        <TextArea
          value={comment}
          onChange={handleCommentChange}
          rows={3}
          placeholder="Leave a comment ..."
        />
        {showCommentHelp && (
          <p className="ManagerToAllocatorRatingForm-comment-help">
            <FontAwesomeIcon icon={faInfoCircle} />
            <span>Comments are mandatory for ratings less than 4 stars</span>
          </p>
        )}
      </div>
      <div className="ManagerToAllocatorRatingForm-actions">
        <Button
          type="primary"
          onClick={handleSave}
          disabled={!isValid || isSaving >= 0}
          loading={isSaving === meetingId}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

ManagerToAllocatorRatingForm.propTypes = {
  meeting: PropTypes.shape({
    meetingId: PropTypes.number.isRequired,
    fundId: PropTypes.number,
    eventId: PropTypes.number,
    companyId: PropTypes.number,
    companyName: PropTypes.string,
    categoryId: PropTypes.number,
    categoryName: PropTypes.string,
    toCompanyId: PropTypes.number.isRequired,
    toCompanyName: PropTypes.string.isRequired,
    toCategoryId: PropTypes.number.isRequired,
    toCategoryName: PropTypes.string.isRequired,
    meetingDateTime: PropTypes.string.isRequired,
    meetingTimeZone: PropTypes.string,
    meetingRatingId: PropTypes.number,
    meetingType: PropTypes.oneOf(['Online', 'Summit', 'Roadshow']),
    contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
    isValid: PropTypes.bool.isRequired,
    rating: PropTypes.shape({
      rating: PropTypes.number,
      comment: PropTypes.string,
      wouldMeetAgain: PropTypes.bool,
      ratingIndicationIds: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.number.isRequired,
}

export default ManagerToAllocatorRatingForm
