import { Redirect, Route } from 'react-router-dom'
import Discover, { RedirectMandates } from './Discover'
import OldDiscover from './DiscoverContainer'

export { Discover, OldDiscover }
export const discoverRedirects = [
  <Route key="mymandates" path="/mymandates">
    <Redirect to="/campaigns" />
  </Route>,
  <Route
    key="old-mandates"
    path="/mandates/:resultId?/:fundId?"
    component={RedirectMandates}
  />,
]
