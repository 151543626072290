import PropTypes from 'prop-types'
import { Tag } from '@context365/tag'

export default function CategoryName({
  name,
  abbreviation = getDefaultAbbreviation(name),
  backgroundColor,
}) {
  return (
    <div className="whitespace-nowrap">
      <Tag
        className="mr-1 rounded-full"
        size="small"
        backgroundColor={backgroundColor}
      >
        {abbreviation.toUpperCase()}
      </Tag>
      <span className="type-body-regular-md">{name}</span>
    </div>
  )
}

CategoryName.propTypes = {
  name: PropTypes.string.isRequired,
  abbreviation: PropTypes.string,
  backgroundColor: PropTypes.string,
}

function getDefaultAbbreviation(name) {
  const words = name.split(' ')
  return words.length === 1
    ? name.substring(0, 3)
    : words.map((word) => word[0]).join('')
}
