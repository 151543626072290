import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Input, Tag, message } from 'antd'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import replace from 'lodash/replace'
import trim from 'lodash/trim'
import { deleteSearchAlert, editSearchAlert } from '~/actions/discover'
import { SEARCH_LABEL } from '~/constants/discover'
import { generateQuery } from '~/utils/discover'
import './CardSearchAlert.less'

const ColumnTag = ({ children }) => <Tag className="bg-brand-2">{children}</Tag>

const CardSearchAlert = ({
  filters,
  originalFilters,
  setSearchState,
  refreshData,
}) => {
  const [currentFilters, setCurrentFilters] = React.useState(filters)
  const [filterRequest, setFilterRequest] = React.useState(originalFilters)
  const [isEditing, setIsEditing] = React.useState(false)

  React.useEffect(() => {
    setCurrentFilters(filters)
    setFilterRequest(originalFilters)
  }, [filters, originalFilters])

  const removeFilter = React.useCallback(
    (f) => {
      const array = [...currentFilters]
      const index = array.indexOf(f)
      if (index !== -1) {
        array.splice(index, 1)
        setCurrentFilters(array)
      }
      const deletedEntry = { ...filterRequest.filters[index], isDeleted: true }
      const newRequest = { ...filterRequest }
      newRequest.filters[index] = deletedEntry
      setFilterRequest(newRequest)
    },
    [currentFilters, filterRequest]
  )

  const removeSelectedFilter = React.useCallback(
    (f, value, index) => {
      const array = [...currentFilters]
      let deleteFilter = false
      const filterIndex = array.indexOf(f)

      if (filterIndex !== -1) {
        array[filterIndex].FilterValue.splice(index, 1)
        array[filterIndex].DisplayValue.splice(index, 1)

        if (array[filterIndex].FilterValue.length === 0) {
          array.splice(filterIndex, 1)
          deleteFilter = true
        }

        setCurrentFilters(array)

        const filterIndexInReq = map(
          filterRequest.filters,
          (x) => x.alertColumn
        ).indexOf(trim(replace(f.Column, ':', '')))

        const valueIndex = map(
          filterRequest.filters[filterIndexInReq].values,
          (x) => x.filterValue
        ).indexOf(value)

        const deletedEntry = {
          ...filterRequest.filters[filterIndexInReq].values[valueIndex],
          isDeleted: true,
        }

        const newRequest = { ...filterRequest }
        newRequest.filters[filterIndexInReq].values[valueIndex] = deletedEntry

        if (deleteFilter) {
          newRequest.filters[filterIndexInReq] = {
            ...filterRequest.filters[filterIndexInReq],
            isDeleted: true,
          }
        }

        const searchQuery = {
          alertColumn: newRequest.filters[filterIndexInReq].alertColumn,
          alertColumnKey: newRequest.filters[filterIndexInReq].alertColumnKey,
          values: newRequest.filters[filterIndexInReq].values
            .filter((x) => !x.isDeleted)
            .map((x) => x.filterValue),
          alertColumnType: newRequest.filters[filterIndexInReq].alertColumnType,
        }

        newRequest.filters[filterIndexInReq].filterQuery =
          generateQuery(searchQuery)
        setFilterRequest(newRequest)
      }
    },
    [currentFilters, filterRequest]
  )

  const removeSearchTerm = React.useCallback(() => {
    const newRequest = { ...filterRequest, searchTerm: null }
    setFilterRequest(newRequest)
  }, [filterRequest])

  const handleAccept = React.useCallback(() => {
    editSearchAlert(filterRequest, filterRequest.alertEntryId)
      .then(() => {
        message.success('Search Alert Updated!')
        setIsEditing(false)
        refreshData()
      })
      .catch(() => {
        message.error('Something went wrong!')
      })
  }, [filterRequest, refreshData])

  const handleDelete = React.useCallback(() => {
    deleteSearchAlert(filterRequest.alertEntryId)
      .then(() => {
        message.success('Search Alert Deleted!')
      })
      .catch(() => {
        message.error('Something went wrong!')
      })
      .finally(() => {
        setIsEditing(false)
        refreshData()
      })
  }, [filterRequest, refreshData])

  const handleChangeValue = React.useCallback(
    (e, id) => {
      e.target
        ? setFilterRequest({ ...filterRequest, [e.target.id]: e.target.value })
        : setFilterRequest({ ...filterRequest, [id.target.id]: e })
    },
    [filterRequest]
  )

  return (
    <div className="cardSearchAlert">
      {isEditing ? (
        <>
          <Input
            className="inputSearchAlert"
            placeholder="Name your alert"
            value={filterRequest.name}
            id="name"
            onChange={(e) => handleChangeValue(e)}
            maxLength={50}
          />
          {filterRequest.searchTerm && (
            <React.Fragment>
              <ColumnTag>{SEARCH_LABEL}</ColumnTag>
              <Tag
                closable
                onClose={(e) => {
                  e.preventDefault()
                  removeSearchTerm()
                }}
              >
                {filterRequest.searchTerm}
              </Tag>
            </React.Fragment>
          )}
          {currentFilters &&
            currentFilters.map((x) => (
              <React.Fragment key={x}>
                <ColumnTag>{x.Column}</ColumnTag>
                <div>
                  {isArray(x.DisplayValue) ? (
                    map(x.DisplayValue, (v, index) => (
                      <Tag
                        key={v}
                        closable
                        onClose={(e) => {
                          e.preventDefault()
                          removeSelectedFilter(x, x.FilterValue[index], index)
                        }}
                      >
                        {`${v}`}
                      </Tag>
                    ))
                  ) : (
                    <Tag
                      closable
                      onClose={(e) => {
                        e.preventDefault()
                        removeFilter(x)
                      }}
                    >
                      {x.DisplayValue}
                    </Tag>
                  )}
                </div>
              </React.Fragment>
            ))}
        </>
      ) : (
        <>
          <h5 className="cardSearchAlert-mainHeading">{filterRequest.name}</h5>
          {filterRequest.searchTerm && (
            <React.Fragment>
              <ColumnTag>{SEARCH_LABEL}</ColumnTag>
              <Tag>{filterRequest.searchTerm}</Tag>
            </React.Fragment>
          )}
          {currentFilters &&
            currentFilters.map((x) => (
              <React.Fragment key={x.Column}>
                <ColumnTag>{x.Column}</ColumnTag>
                {isArray(x.DisplayValue) ? (
                  <span className="space-x-1">
                    {map(x.DisplayValue, (v) => (
                      <Tag key={v}>{`${v}`}</Tag>
                    ))}
                  </span>
                ) : (
                  <Tag>{x.DisplayValue}</Tag>
                )}
              </React.Fragment>
            ))}
        </>
      )}

      <hr style={{ border: '0.5px solid #EFF1F4', marginTop: '18px' }} />
      {isEditing ? (
        <div className="pb-2 flex justify-between">
          <Button status="error" onClick={handleDelete}>
            Delete
          </Button>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              onClick={() => {
                setCurrentFilters(filters)
                setFilterRequest(originalFilters)
                setIsEditing(false)
              }}
            >
              Cancel
            </Button>
            <Button variant="filled" onClick={handleAccept}>
              Save Changes
            </Button>
          </div>
        </div>
      ) : (
        <div className="pb-2 flex justify-between">
          <Button
            variant="link"
            status="secondary"
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
          {setSearchState && (
            <Button
              variant="filled"
              onClick={() => {
                setSearchState(
                  originalFilters.filters,
                  filterRequest.searchTerm
                )
              }}
            >
              Run Search
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

CardSearchAlert.propTypes = {
  filters: PropTypes.array.isRequired,
  originalFilters: PropTypes.object.isRequired,
  setSearchState: PropTypes.func,
  refreshData: PropTypes.func.isRequired,
}

export default CardSearchAlert
