import { useCallback } from 'react'
import { DetailedAvatar } from '@context365/avatar'
import { CheckCircleOutline } from '@context365/icons'
import { Menu, MenuButton } from '@context365/menu'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import map from 'lodash/map'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { changeCompany, logout } from '~/actions/auth'
import { fetchConversationCount, fetchConversations } from '~/actions/messages'
import { loadNotificationsAndStats } from '~/actions/notifications'
import { FeatureFlags } from '~/config'
import useAuth from '~/hooks/useAuth'
import { getUserCompanies } from '~/selectors/auth'
import { generateFlagsIdentity } from '~/utils/flags'
import { getInitials } from '~/utils/helpers'

const CompanySelect = () => {
  const { contact, company: currentCompany } = useAuth()
  const companies = useSelector(getUserCompanies)
  const dispatch = useDispatch()
  const ldClient = useLDClient()
  const queryClient = useQueryClient()
  const { push } = useHistory()

  const changeCompanyCb = (companyId) => {
    dispatch(changeCompany(companyId)).then(
      async ({ payload: { claims, contact, company } }) => {
        if (FeatureFlags.enabled) {
          const identity = generateFlagsIdentity(claims, contact, company)
          await ldClient.identify(identity)
        }

        await queryClient.resetQueries()

        push('/')
        dispatch(fetchConversationCount())
        dispatch(fetchConversations())
        dispatch(loadNotificationsAndStats(true))
      }
    )
  }

  const logoutUser = useCallback(() => {
    queryClient.clear()
    dispatch(logout())
  }, [dispatch, queryClient])

  return (
    <div className="flex flex-col gap-4 items-center">
      <DetailedAvatar
        profileImageUrl={contact.imageUrl}
        initials={getInitials(contact.contactName)}
        name={contact.contactName}
        size="big"
      />
      <Menu
        trigger={
          <MenuButton>
            <div className="w-48 xl:w-60">{currentCompany.name}</div>
          </MenuButton>
        }
      >
        {map(companies, (company) => (
          <Menu.Item
            key={company.companyId}
            className="h-auto"
            onClick={() => changeCompanyCb(company.companyId)}
          >
            <div className="flex flex-col">
              <div className="flex items-center">
                <div className="font-semibold">{company.name}</div>
                {company.companyId === currentCompany.companyId && (
                  <CheckCircleOutline
                    size="small"
                    className="text-green-100 ml-2"
                  />
                )}
              </div>
              <div className="text-xs">{company.category.categoryName}</div>
            </div>
          </Menu.Item>
        ))}
        <Menu.Item onClick={logoutUser}>
          <div className="text-red-100 text-sm">Logout</div>
        </Menu.Item>
      </Menu>
    </div>
  )
}

export default CompanySelect
