export const getParticipantRoleNumber = (role) => {
  switch (role) {
    case 'Moderator':
      return 1
    case 'Presenter':
      return 2
    case 'Admin':
      return 4
    default:
      return 3
  }
}

export const getAttendanceNumber = (attendance) => {
  switch (attendance) {
    case 'InviteOnly':
      return 1
    case 'OpenToSegments':
      return 2
    case 'OpenToAll':
      return 3
    default:
      return 1
  }
}

export const getPresentationTypeId = (type) => {
  switch (type) {
    case 'Roundtable':
      return 1
    case 'Fund Presentation':
      return 2
    case 'Deal Overview':
      return 3
    case 'Ask Me Anything':
      return 4
    case 'Investor Update':
      return 5
    case 'Bake off (Final Presentations)':
      return 6
    default:
      return 1
  }
}
