import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import qs from 'qs'
import { api as http } from '~/api/services'
import ContextMap from '~/components/ContextMap/ContextMap'
import { generateQuery } from '~/utils/discover'
import './DiscoverMapView.less'

const DiscoverMapView = ({
  filter,
  searchTerm = '',
  showMatched,
  selectedFund,
  representedFund,
  fundContacts,
  meetingStatusFilter,
  resultId,
}) => {
  const isSPTab = window.location.pathname.indexOf('serviceproviders') > 0
  const isFundsTab = window.location.pathname.indexOf('funds') > 0

  const [boundaries, setBoundaries] = useState([])

  const [pageSize, setPageSize] = useState(1)

  const [markers, setMarkers] = useState(null)

  const checkFilters = (params) => {
    return 'NWBound' in params || 'SEBound' in params
  }

  const fetchMarkersData = useCallback(
    (filter, searchTerm = '', showMatched, meetingStatus, bounds) => {
      if (!isNil(bounds) && bounds.length === 2) {
        setBoundaries(bounds)
      }
      const filterQuery = generateFilterQuery(filter)

      //sp
      if (isSPTab) {
        const params = {
          filterQuery,
          showMatched,
        }
        if (!isNil(bounds) && bounds.length === 2) {
          ;[params.NWBound, params.SEBound] = bounds
        }
        if (!isNil(searchTerm) && !isEmpty(searchTerm)) {
          params.q = trim(searchTerm)
        }

        if (!isNil(meetingStatus) && !isEmpty(meetingStatus)) {
          params.meetingStatus = meetingStatus
        }
        if (!isNil(filterQuery) && !isEmpty(filterQuery)) {
          params.filter = filterQuery
        }
        if (checkFilters(params))
          http
            .get('discovery/spsmarkers', {
              params,
              paramsSerializer: (params) =>
                qs.stringify(params, { arrayFormat: 'repeat' }),
            })
            .then((response) => {
              setMarkers(response.data.result)
              setPageSize(response.data.result[0].pageSize)
            })
            .catch((error) => console.log('c', error))
      }

      //funds
      else if (isFundsTab) {
        if (!isNil(resultId)) {
          const resultFilter = `fundId eq ${[resultId]}`
          filterQuery.push(resultFilter)
        }

        const params = { filterQuery, showMatched }
        if (!isNil(searchTerm) && !isEmpty(searchTerm)) {
          params.q = trim(searchTerm)
        }

        if (!isNil(meetingStatusFilter) && !isEmpty(meetingStatusFilter)) {
          params.meetingStatus = meetingStatusFilter
        }

        if (!isNil(bounds) && bounds.length === 2) {
          ;[params.NWBound, params.SEBound] = bounds
        }
        if (!isNil(filterQuery) && !isEmpty(filterQuery)) {
          params.filter = filterQuery
        }
        if (checkFilters(params))
          http
            .get('discovery/fundsmarkers', {
              params,
              paramsSerializer: (params) =>
                qs.stringify(params, { arrayFormat: 'repeat' }),
            })
            .then((response) => {
              setMarkers(response.data.result)
              setPageSize(response.data.result[0]?.pageSize)
            })
            .catch((error) => console.log('c', error))
      }
      //allocators
      else {
        if (!isNil(resultId)) {
          const resultFilter = `companyContactId eq ${[resultId]}`
          filterQuery.push(resultFilter)
        }

        const params = { filterQuery }
        if (!isNil(searchTerm) && !isEmpty(searchTerm)) {
          params.q = trim(searchTerm)
        }

        if (!isNil(bounds) && bounds.length === 2) {
          ;[params.NWBound, params.SEBound] = bounds
        }
        if (!isNil(meetingStatus) && !isEmpty(meetingStatus)) {
          params.meetingStatus = meetingStatus
        }
        if (!isNil(filterQuery) && !isEmpty(filterQuery)) {
          params.filter = filterQuery
        }
        if (checkFilters(params))
          http
            .get('discovery/investorsmarkers', {
              params,
              paramsSerializer: (params) =>
                qs.stringify(params, { arrayFormat: 'repeat' }),
            })
            .then((response) => {
              setMarkers(response.data.result)
              setPageSize(response.data.result[0].pageSize)
            })
            .catch((error) => console.log('c', error))
      }
    },
    [isSPTab, isFundsTab, filter, meetingStatusFilter]
  )

  const generateFilterQuery = useCallback(
    (filterParameter) => {
      if (filterParameter) {
        const filterQuery = filterParameter.map((x) => generateQuery(x))
        if (isSPTab) {
          if (!isNil(resultId)) {
            const resultFilter = `companyId eq ${[resultId]}`
            filterQuery.push(resultFilter)
          }
        } else if (isFundsTab) {
          if (!isNil(resultId)) {
            const resultFilter = `fundId eq ${[resultId]}`
            filterQuery.push(resultFilter)
          }
        } else if (!isNil(resultId)) {
          const resultFilter = `companyContactId eq ${[resultId]}`
          filterQuery.push(resultFilter)
        }

        return filterQuery
      }
    },
    [resultId]
  )

  //filters
  useEffect(() => {
    fetchMarkersData(
      filter,
      searchTerm,
      showMatched,
      meetingStatusFilter,
      boundaries
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchMarkersData,
    filter,
    searchTerm,
    showMatched,
    meetingStatusFilter,
    //boundaries,
  ])

  return (
    <ContextMap
      markers={markers}
      fetchMarkers={fetchMarkersData}
      pageSize={pageSize}
      filter={filter}
      searchTerm={searchTerm}
      showMatched={showMatched}
      selectedFund={selectedFund}
      representedFund={representedFund}
      meetingStatusFilter={meetingStatusFilter}
      fundContacts={fundContacts}
      resultId={resultId}
    />
  )
}

DiscoverMapView.propTypes = {
  filter: PropTypes.array,
  searchTerm: PropTypes.string,
  showMatched: PropTypes.bool,
  selectedFund: PropTypes.number,
  representedFund: PropTypes.string,
  meetingStatusFilter: PropTypes.array,
  resultId: PropTypes.number,
  fundContacts: PropTypes.array,
}

export default DiscoverMapView
