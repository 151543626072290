import * as React from 'react'
import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import concat from 'lodash/concat'
import flatten from 'lodash/flatten'
import map from 'lodash/map'
import { useFlexLayout, useTable } from 'react-table'
import {
  formatCurrency,
  formatMonthYear,
  formatPercent,
  formatYear,
} from '~/utils/helpers'

const ManagerCategory = ({ categoryName, managers }) => {
  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        Header: '',
        accessor: (record) =>
          record.isFund ? (
            <span className="ml-6">{record.name}</span>
          ) : (
            <span className="type-body-semibold-sm">{record.name}</span>
          ),
        width: 125,
      },
      {
        id: 'totalInvestments',
        Header: 'Actual ($M)',
        accessor: (record) =>
          record.totalInvestments == null
            ? ''
            : formatCurrency(record.totalInvestments),
        width: 50,
      },
      {
        id: 'percentageOfCategory',
        Header: `% of ${categoryName}`,
        accessor: (record) =>
          record.percentageOfCategory == null
            ? ''
            : formatPercent(record.percentageOfCategory),
        width: 50,
      },
      {
        id: 'startYear',
        Header: `Start`,
        accessor: (record) => record.year,
        width: 50,
      },
      {
        id: 'renewalDate',
        Header: `Renewal`,
        accessor: (record) =>
          record.renewalDate == null ? '' : formatMonthYear(record.renewalDate),
        width: 50,
      },
      {
        id: 'status',
        Header: `Status`,
        accessor: (record) =>
          record.isOnWatch ? (
            <Badge status="error" size="small">
              On Watch
            </Badge>
          ) : null,
        width: 50,
      },
    ],
    [categoryName]
  )

  const managersAndFunds = React.useMemo(() => {
    return flatten(
      map(managers, (m, managerIndex) => {
        return concat(
          [{ ...m, isLast: managerIndex === managers.length - 1 }],
          map(m.funds, (f, fundIndex) => ({
            ...f,
            year: f.investmentDate == null ? '' : formatYear(f.investmentDate),
            renewalDate: null,
            isOnWatch: null,
            isFund: true,
            isLast: fundIndex === m.funds.length - 1,
          }))
        )
      })
    )
  }, [managers])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: managersAndFunds }, useFlexLayout)

  return (
    <div className="w-full max-w-full overflow-x-auto">
      <table
        className="w-full relative"
        style={{ borderSpacing: 0 }}
        {...getTableProps()}
      >
        <thead className="border-b border-primary-90">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="p-0" {...column.getHeaderProps()}>
                  <div className="py-2 min-w-max bg-white">
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowNumber) => {
            prepareRow(row)
            return (
              <tr
                key={rowNumber}
                className="bg-white even:bg-secondary-2"
                {...row.getRowProps()}
              >
                {row.cells.map((cell, cellNumber) => (
                  <td
                    className="p-2 min-w-max"
                    key={cellNumber}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

ManagerCategory.propTypes = {
  categoryName: PropTypes.string.isRequired,
  managers: PropTypes.arrayOf(
    PropTypes.shape({
      vettedCompanyManagerId: PropTypes.number,
      name: PropTypes.string.isRequired,
      isOnWatch: PropTypes.bool.isRequired,
      year: PropTypes.number,
      totalInvestments: PropTypes.number,
      percentageOfCategory: PropTypes.number,
      renewalDate: PropTypes.string,
      funds: PropTypes.arrayOf(
        PropTypes.shape({
          vettedFundId: PropTypes.number,
          name: PropTypes.string.isRequired,
          investmentDate: PropTypes.string,
          totalInvestments: PropTypes.number,
          percentageOfCategory: PropTypes.number,
        })
      ).isRequired,
    })
  ).isRequired,
}

export default ManagerCategory
