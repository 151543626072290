import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DatePicker, Modal, message } from 'antd'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getCompanyFunds } from '~/actions/discover'
import { createRoadshow, fetchCompany } from '~/actions/roadshow'
import FloatingInput from '~/components/FloatingInput/FloatingInput'
import FloatingSelect from '~/components/FloatingSelect/FloatingSelect'
import ParticipantsCheckbox from '~/components/ParticipantsCheckbox/ParticipantsCheckbox'
import { getCompanyId, getUserId } from '~/selectors/auth'
import './RoadshowDetail.less'
import '~/styles/discoverLanding.less'

const RoadshowDetail = ({
  value,
  visible,
  closeModal,
  isManager,
  onCreated,
}) => {
  const userId = useSelector((state) => getUserId(state))
  const companyId = useSelector((state) => getCompanyId(state))

  const [funds, setFunds] = useState([])
  const [company, setCompany] = useState({})
  const dateFormat = moment().format('MMM Do')
  const [rangeClasses, setRangeClasses] = useState('')

  const [roadshowName, setRoadshowName] = useState(
    isNil(value) ? '' : value.name
  )
  const [roadshowNameValid, setRoadshowNameValid] = useState(true)

  const [selectedFundId, setSelectedFundId] = useState(value.fundId)
  const [fundIdValid, setFundIdValid] = useState(true)

  const [location, setLocation] = useState(isNil(value) ? '' : value.location)
  const [locationValid, setLocationValid] = useState(true)
  const [roadshowValue, setRoadshowValue] = useState({})

  const [startDate, setStartDate] = useState(
    isNil(value) ? '' : value.startDate
  )
  const [endDate, setEndDate] = useState(isNil(value) ? '' : value.endDate)
  const [, setStartDateValid] = useState(true)
  const [, setEndDateValid] = useState(true)

  const [participants, setParticipants] = useState([])
  const [, setParticipantsValid] = useState(true)
  const [enableSave, setEnableSave] = useState(false)

  const onRoadshowSaved = useCallback(() => {
    const saveRoadshowAction = async () => {
      try {
        //setLoading(true);
        if (!isNil(roadshowValue) && roadshowValue.isValid) {
          await createRoadshow(roadshowValue)
          message.success('Roadshow was saved successfully!')
          onCreated()
        } else {
          message.error('Please fill all fields!')
        }
      } catch (e) {
        message.error(
          'There was an error while attempting to save roadshow data'
        )
      } finally {
        handleClose()
        setEnableSave(true)
        //setLoading(false);
      }
    }
    setEnableSave(false)
    saveRoadshowAction()
  }, [onCreated, roadshowValue])

  const checkValid = useCallback(
    (result) => {
      if (
        result !== undefined &&
        result.contactIds != undefined &&
        result.contactIds.length > 0 &&
        result.location !== '' &&
        result.location !== undefined &&
        result.name !== '' &&
        result.name !== undefined &&
        result.startDate !== undefined &&
        result.endDate !== undefined &&
        ((isManager && result.fundId !== undefined) || !isManager)
      ) {
        setEnableSave(true)
      } else setEnableSave(false)
    },
    [isManager]
  )

  useEffect(() => {
    fetchCompany(companyId).then((response) => {
      setCompany(response.data.result)
    })

    if (funds.length === 0 && isManager) {
      getCompanyFunds(companyId)
        .then((response) => {
          setFunds(response.data.result)
        })
        .catch(() => message.error('Could not load funds'))
    }
    if (participants != null && participants.length === 0) {
      setParticipants([userId])
      setRoadshowValue({
        ...roadshowValue,
        contactIds: [userId],
      })
    }
    if (!isNil(value)) {
      setRoadshowNameValid(!isNil(roadshowName) && roadshowName !== '')
      setFundIdValid(!isNil(selectedFundId) && isManager)
      setLocationValid(!isNil(location) && location !== '')
      setStartDateValid(!isNil(startDate) && startDate !== '')
      setEndDateValid(!isNil(endDate) && endDate !== '')
    }
  }, [])

  const onTextChange = useCallback(
    (id, e) => {
      let result = roadshowValue
      switch (id) {
        case 'name':
          setRoadshowName(e.target.value)
          setRoadshowNameValid(!isNil(e.target.value) && e.target.value !== '')
          result = {
            ...roadshowValue,
            name: e.target.value,
            isValid: !isNil(e.target.value) && e.target.value !== '',
          }
          setRoadshowValue(result)
          break
        case 'location':
          setLocation(e.target.value)
          setLocationValid(!isNil(e.target.value) && e.target.value !== '')
          result = {
            ...roadshowValue,
            location: e.target.value,
            isValid: !isNil(e.target.value) && e.target.value !== '',
          }
          setRoadshowValue(result)
          break
        default:
          break
      }
      checkValid(result)
    },
    [checkValid, roadshowValue]
  )

  const onBlur = (id) => {
    switch (id) {
      case 'name':
        setRoadshowNameValid(!isNil(roadshowName) && roadshowName !== '')
        break
      case 'location':
        setLocationValid(!isNil(location) && location !== '')
        break
      case 'fundId':
        setFundIdValid(!isNil(selectedFundId))
        break
      case 'daterange':
        setRangeClasses(
          !isNil(startDate) &&
            startDate !== '' &&
            !isNil(moment.utc(startDate, dateFormat)) &&
            !isNil(endDate) &&
            endDate !== '' &&
            !isNil(moment.utc(endDate, dateFormat))
            ? ''
            : 'cc-floating-input-invalid'
        )
        break
      default:
        break
    }
    checkValid(roadshowValue)
  }

  const onValueChange = useCallback(
    (newValue, id) => {
      let result = roadshowValue

      switch (id) {
        case 'fundId':
          setSelectedFundId(newValue)
          setFundIdValid(!isNil(newValue))
          result = {
            ...roadshowValue,
            fundId: newValue,
            isValid: !isNil(newValue),
          }
          setRoadshowValue(result)
          break
        default:
          break
      }
      checkValid(result)
    },
    [checkValid, roadshowValue]
  )

  const onDateChange = useCallback(
    (range) => {
      let result = roadshowValue
      setStartDate(moment(range[0], dateFormat))
      setStartDateValid(
        !isNil(range[0]) &&
          range[0] !== '' &&
          !isNil(moment.utc(range[0], dateFormat))
      )
      setEndDate(moment(range[1], dateFormat))
      setEndDateValid(
        !isNil(range[1]) &&
          range[1] !== '' &&
          !isNil(moment.utc(range[1], dateFormat))
      )
      result = {
        ...roadshowValue,
        startDate: moment.utc(range[0]),
        endDate: moment.utc(range[1]),
        isValid:
          !isNil(range[0]) &&
          !isNil(range[1]) &&
          range[0] !== '' &&
          range[1] !== '' &&
          !isNil(moment(range[0], dateFormat)) &&
          !isNil(moment(range[1], dateFormat)),
      }
      setRangeClasses(
        !isNil(range[0]) &&
          range[0] !== '' &&
          !isNil(moment.utc(range[0], dateFormat)) &&
          !isNil(range[1]) &&
          range[1] !== '' &&
          !isNil(moment.utc(range[1], dateFormat))
          ? ''
          : 'cc-floating-input-invalid'
      )
      setRoadshowValue(result)
      checkValid(result)
    },
    [checkValid, dateFormat, roadshowValue]
  )

  const handleParticipantChecked = useCallback(
    (e) => {
      let result = roadshowValue
      let tmpparticipants
      if (e.target.checked) {
        tmpparticipants = [...participants, e.target.value]
        setParticipants(tmpparticipants)
      } else {
        tmpparticipants = participants.filter((p) => p !== e.target.value)
        setParticipants(tmpparticipants)
      }

      setParticipantsValid(tmpparticipants.length > 0)
      result = {
        ...roadshowValue,
        contactIds: tmpparticipants,
        isValid: tmpparticipants.length > 0,
      }
      setRoadshowValue(result)
      checkValid(result)
    },
    [checkValid, participants, roadshowValue]
  )

  const handleClose = () => {
    setRoadshowName('')
    setLocation('')
    setSelectedFundId(null)
    setStartDate(null)
    setEndDate(null)
    setParticipants([userId])
    closeModal()
  }

  const { RangePicker } = DatePicker

  return (
    <Modal
      destroyOnClose
      visible={visible}
      width="628px"
      title="Create roadshow"
      onOk={onRoadshowSaved}
      okButtonProps={{
        disabled: !enableSave,
      }}
      onCancel={handleClose}
      okText="Create roadshow"
      bodyStyle={{ padding: 0 }}
      cancelButtonProps={{ disabled: false }}
    >
      <div style={{ padding: '13px 16px 32px 32px' }}>
        <div style={{ width: '90%', marginTop: '16px' }}>
          <FloatingInput
            name="Roadshow name"
            id="name"
            onChange={onTextChange}
            onBlur={onBlur.bind(null, 'name')}
            handleChange={true}
            style={{ width: '100%' }}
            isValid={roadshowNameValid}
            //validationMessage="This field is required"
            value={
              isNil(roadshowName) || roadshowName === '' ? '' : roadshowName
            }
          />
        </div>

        {isManager && (
          <div>
            <div style={{ width: '90%', marginTop: '16px' }}>
              <FloatingSelect
                name="Select fund"
                handleChange={false}
                value={isNil(selectedFundId) ? '' : selectedFundId}
                onBlur={onBlur.bind(null, 'fundId')}
                onChange={onValueChange}
                id="fundId"
                options={funds.map((x) => ({ value: x.fundId, text: x.name }))}
                isValid={fundIdValid}
                //validationMessage="This field is required"
              />
            </div>
          </div>
        )}

        <div style={{ width: '90%', marginTop: '16px' }}>
          <RangePicker
            disabledDate={(current) =>
              current && current < moment().startOf('day')
            }
            size="large"
            onChange={onDateChange}
            onBlur={onBlur.bind(null, 'daterange')}
            className={`cc-background-input ${
              isNil(rangeClasses) ? '' : rangeClasses
            }`}
            id="daterange"
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ width: '90%', marginTop: '16px' }}>
          <FloatingInput
            name="Location"
            id="location"
            placeholder="City, State"
            onChange={onTextChange}
            onBlur={onBlur.bind(null, 'location')}
            handleChange={true}
            style={{ width: '100%' }}
            isValid={locationValid}
            //validationMessage="This field is required"
            value={isNil(location) || location === '' ? '' : location}
          />
        </div>
        <div style={{ width: '90%', marginTop: '50px' }}>
          <div className="cc-check-participants-wrapper">
            {company.contacts &&
              company.contacts.map((contact) => (
                <ParticipantsCheckbox
                  contactId={contact.contactId}
                  contactName={`${contact.firstName} ${contact.lastName}`}
                  imageUrl={contact.imageUrl}
                  participants={participants}
                  checked={handleParticipantChecked}
                  key={contact.contactId}
                  {...contact}
                />
              ))}
          </div>
        </div>
      </div>
    </Modal>
  )
}

RoadshowDetail.propTypes = {
  value: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isManager: PropTypes.bool.isRequired,
  onCreated: PropTypes.func,
}

export default RoadshowDetail
