import { Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import AuthorizationGate from '~/components/AuthorizationGate'
import useAuth from '~/hooks/useAuth'
import NotFound from '~/views/NotFound'
import FundProfile from './FundProfile'

export default function FundRoute() {
  const { role } = useAuth()
  return (
    <Routes>
      <Route
        element={
          <AuthorizationGate
            blockWhen={() => role === 'Manager'}
            fallback={<Navigate replace to="/" />}
          />
        }
      >
        <Route path=":fundId" element={<FundProfile />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
