import { FETCH_DISCOVER_LISTS } from '~/constants/types'

const initialState = []

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DISCOVER_LISTS:
      return action.payload
    default:
      return state
  }
}
