import Body from './Body'
import Header from './Header'
import Modal from './Modal'
import Profile from './Profile'

Profile.Header = Header
Profile.Body = Body
Profile.Modal = Modal

export default Profile
