import PropTypes from 'prop-types'
import { PersonOutline } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import { Avatar, Dropdown, Menu } from 'antd'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'
import map from 'lodash/map'
import slice from 'lodash/slice'
import { getColor } from '~/utils/helpers'
import './AvatarGroup.less'

const AvatarGroup = ({
  avatars,
  size = 'default',
  missingIcon = <PersonOutline className="mt-0.5" />,
  className = null,
  style = {},
  maxAvatars = 3,
  showInitials = false,
  showImageOnPopover = true,
}) => {
  if (isNil(avatars) || isEmpty(avatars)) {
    return null
  }

  const leftPulls = { small: 8, default: 12, large: 16 }
  const leftPull = isNumber(size) ? size * 0.333 : get(leftPulls, size, 12)

  const hasMoreThanMax = avatars.length > maxAvatars
  let moreAvatars = null

  if (hasMoreThanMax) {
    const avatarList = (
      <Menu>
        {map(slice(avatars, maxAvatars), (avatar) => (
          <Menu.Item key={avatar.id}>
            <Tooltip
              key={avatar.id}
              className={`${isEmpty(avatar.img) && 'invisible'}`}
              text={
                !isEmpty(avatar.img) &&
                showImageOnPopover && (
                  <div className="text-center">
                    <img
                      className="rounded-full w-24 h-24"
                      alt=""
                      src={avatar.img}
                    />
                  </div>
                )
              }
            >
              <Avatar
                icon={
                  !showInitials && !isEmpty(avatar.initials)
                    ? missingIcon
                    : null
                }
                src={avatar.img}
                className="mr-4"
                style={{
                  backgroundColor: getColor(avatar.firstName, avatar.lastName),
                }}
              >
                {showInitials && !isEmpty(avatar.initials)
                  ? avatar.initials
                  : ''}
              </Avatar>
            </Tooltip>
            {avatar.description}
          </Menu.Item>
        ))}
      </Menu>
    )

    moreAvatars = (
      <Dropdown overlay={avatarList}>
        <Avatar
          className="AvatarGroup-avatar AvatarGroup-avatar-plus"
          size={size}
          style={{ marginLeft: `-${leftPull}px` }}
        >
          <b>{avatars.length - maxAvatars}+</b>
        </Avatar>
      </Dropdown>
    )
  }

  return (
    <div
      className={`AvatarGroup ${isNil(className) ? '' : className}`}
      style={style}
    >
      {map(slice(avatars, 0, maxAvatars), (avatar, i) => (
        <Tooltip
          key={avatar.id}
          text={
            <>
              <div>{avatar.description}</div>
              {!isEmpty(avatar.img) && showImageOnPopover && (
                <div className="text-center mt-2">
                  <img
                    alt=""
                    className="rounded-full w-24 h-24"
                    src={avatar.img}
                  />
                </div>
              )}
            </>
          }
        >
          <Avatar
            className="AvatarGroup-avatar"
            icon={
              !showInitials && !isEmpty(avatar.initials) ? missingIcon : null
            }
            src={avatar.img}
            size={size}
            style={{
              marginLeft: `-${i === 0 ? 0 : leftPull}px`,
              backgroundColor: getColor(avatar.firstName, avatar.lastName),
            }}
          >
            {showInitials && !isEmpty(avatar.initials) ? avatar.initials : ''}
          </Avatar>
        </Tooltip>
      ))}
      {moreAvatars}
    </div>
  )
}

AvatarGroup.propTypes = {
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      img: PropTypes.string,
      description: PropTypes.string,
      initials: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }).isRequired
  ),
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['default', 'small', 'large']),
  ]),
  missingIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  maxAvatars: PropTypes.number,
  showInitials: PropTypes.bool,
  showImageOnPopover: PropTypes.bool,
}

export default AvatarGroup
