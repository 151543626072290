import * as React from 'react'
import useFrameState from './useFrameState'

export default function useElementSize(ref) {
  if (!ref) {
    throw new Error('useElementSize requires a ref')
  }

  const [size, setSize] = useFrameState({ width: 0, height: 0 })

  React.useLayoutEffect(() => {
    const el = ref.current
    if (!el) {
      return undefined
    }

    const onResize = () =>
      setSize((oldSize) => {
        const newHeight = el.clientHeight
        const newWidth = el.clientWidth

        if (oldSize.height !== newHeight || oldSize.width !== newWidth) {
          return { width: newWidth, height: newHeight }
        }
        return oldSize
      })

    const resizeObserver = new ResizeObserver(onResize)
    resizeObserver.observe(el)

    return () => resizeObserver.disconnect()
  }, [ref, setSize])

  return size
}
