import PropTypes from 'prop-types'
import cx from 'classnames'

export default function AppLayout({
  children,
  className,
  contentClassName,
  sidebar,
  topBar,
}) {
  return (
    <div data-layout="app" className={cx(className, 'w-full flex flex-row')}>
      {sidebar && (
        <div className="fixed inset-y-0 left-0 w-0 md:w-24 z-10">{sidebar}</div>
      )}
      <div
        className={cx('flex-1 flex flex-col min-w-0', sidebar && 'md:pl-24')}
      >
        {topBar}
        <main className={cx(contentClassName, 'w-full flex-1 flex flex-col')}>
          {children}
        </main>
      </div>
    </div>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  sidebar: PropTypes.node,
  topBar: PropTypes.node,
}
