import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Col, Row } from 'antd'
import { ApplyToCampaignButton } from '../Application'
import ComposeMessageModal from '../ComposeMessageModal'
import ProfilePictureGroup from '../ProfilePictureGroup'
import './FundMiniProfile.less'

const FundMiniProfile = ({ fund, hideActions = false }) => {
  const [composeModalVisible, setComposeModalVisible] = useState(false)

  return (
    <>
      <div>
        <div className="FundMiniProfile">
          <Row className="FundMiniProfile-Row" type="flex">
            <Col span={7}>
              <ProfilePictureGroup
                profiles={fund.contacts.map((c) => ({
                  ...c,
                  name: c.contactName,
                }))}
                maxImages={20}
                showImageOnPopover
              />
            </Col>
            <Col span={17}>
              <div className="cc-small-font FundMiniProfile-Title">
                {fund.name}
              </div>
              <div className="cc-small-font FundMiniProfile-SubTitle">
                {fund.companyName}
              </div>
            </Col>
          </Row>
          {!hideActions && (
            <div className="mt-4 pb-4">
              <ApplyToCampaignButton
                campaignEntity={fund}
                campaignType="fund"
              />
            </div>
          )}
        </div>
        {!hideActions && (
          <div className="FundMiniProfile">
            <Button
              className="FundMiniProfile-Button"
              onClick={() => setComposeModalVisible(true)}
            >
              Send a Message
            </Button>
          </div>
        )}
      </div>
      {fund && (
        <ComposeMessageModal
          visible={composeModalVisible}
          onClose={() => setComposeModalVisible(false)}
          companyID={fund.companyId}
          fromFundId={fund.fundId}
        />
      )}
    </>
  )
}

FundMiniProfile.propTypes = {
  fund: PropTypes.object.isRequired,
  hideActions: PropTypes.bool,
}

export default FundMiniProfile
