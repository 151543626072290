import { api as http } from '~/api/services'

export const getCompaniesToOnboard = () => http.get('/on-boarding/companies')

export const getCompanyQuestions = () =>
  http.get('/on-boarding/questions/company')

export const postCompany = () => http.post('/on-boarding/company')

export const getCompanyAllocatorQuestions = (companyId) =>
  http.get(`/on-boarding/questions/allocator-company/${companyId}`)

export const postCompanyAllocatorQuestions = (companyId, questions) =>
  http.post(`/on-boarding/allocator-company/${companyId}`, questions)

export const getCompanyManagerQuestions = (companyId) =>
  http.get(`/on-boarding/questions/manager-company/${companyId}`)

export const postCompanyManagerQuestions = (companyId, questions) =>
  http.post(`/on-boarding/manager-company/${companyId}`, questions)

export const getCompanyServiceProviderQuestions = (companyId) =>
  http.get(`/on-boarding/questions/service-provider-company/${companyId}`)

export const postCompanyServiceProviderQuestions = (companyId, questions) =>
  http.post(`/on-boarding/service-provider-company/${companyId}`, questions)

export const getContactIdentityQuestions = () =>
  http.get('/on-boarding/questions/contact-identity')

export const getContactLocationQuestions = () =>
  http.get('/on-boarding/questions/contact-location')

export const getInvestorStatusQuestions = (companyId) =>
  http.get(`/on-boarding/questions/investor-status/${companyId}`)

export const getInvestorDetailsQuestions = (companyId) =>
  http.get(`/on-boarding/questions/investor-details/${companyId}`)

export const getInvestmentStrategies = () =>
  http.get('/on-boarding/questions/investor-details/strategies')

export const postContactBasic = (questions) =>
  http.post('/on-boarding/contact-basic', questions)

export const postContact = (questions) =>
  http.post('/on-boarding/contact', questions)

export const postAllocatorStatus = (companyId, questions) =>
  http.post(`/on-boarding/allocator-status/${companyId}`, questions)

export const postAllocatorContact = (companyId, questions) =>
  http.post(`/on-boarding/allocator-contact/${companyId}`, questions)

export const postCFNContact = (stepNumber, questions) =>
  http.post(`/cfn/contact/${stepNumber}`, questions)

export const postCFNCompany = (stepNumber, questions) =>
  http.post(`/cfn/company/${stepNumber}`, questions)

export const getDomicileCountries = () =>
  http.get('/on-boarding/questions/domicile-countries')

export const getCFNContactQuestions = (stepNumber) =>
  http.get(`/cfn/questions/contact/${stepNumber}`)

export const getCFNCompanyQuestions = (stepNumber) =>
  http.get(`/cfn/questions/company/${stepNumber}`)

export const getCFNCompanyVisibilityQuestions = () =>
  http.get('/cfn/visibility/company')

export const getCFNContactVisibilityQuestions = () =>
  http.get('/cfn/visibility/contact')

export const postCFNCompanyVisibility = (visibilityPreferences) =>
  http.post('/cfn/visibility/company', visibilityPreferences)

export const postCFNContactVisibility = (visibilityPreferences) =>
  http.post('/cfn/visibility/contact', visibilityPreferences)
