import { Fragment } from 'react'
import { Badge } from '@context365/badge'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import map from 'lodash/map'
import random from 'lodash/random'
import replace from 'lodash/replace'
import split from 'lodash/split'
import sum from 'lodash/sum'
import take from 'lodash/take'
import trim from 'lodash/trim'
import moment from 'moment'
import validator from 'validator'
import { numberFormat } from './formatters'

export const getInitials = (fullname) =>
  map(take(trim(fullname).split(/\s+/gi), 2), (part) =>
    part.substr(0, 1).toUpperCase()
  ).join('')

export const getColor = (firstName, lastName) => {
  const fullname = trim(
    `${isEmpty(firstName) ? '' : firstName}${isEmpty(lastName) ? '' : lastName}`
  )

  const minCodeValue = 50
  const maxCodeValue = 255 - minCodeValue

  const red = isEmpty(firstName)
    ? 0
    : sum(map(replace(firstName, /\s+/gi, ''), (c) => c.charCodeAt(0)))
  const green = isEmpty(lastName)
    ? 0
    : sum(map(replace(lastName, /\s+/gi, ''), (c) => c.charCodeAt(0)))
  const blue = isEmpty(fullname)
    ? 0
    : sum(map(replace(fullname, /\s+/gi, ''), (c) => c.charCodeAt(0)))

  const hexCode = join(
    map([red, green, blue], (c) =>
      ((c % (maxCodeValue - minCodeValue)) + minCodeValue).toString(16)
    ),
    ''
  ).toUpperCase()

  return `#${hexCode}`
}

export const validURL = (str = '') =>
  str === '' || isNil(str) ? true : validator.isURL(str)

export const getClickableLink = (link) =>
  link?.startsWith('http://') || link?.startsWith('https://')
    ? link
    : `http://${link}`

const isEmptyOrNull = (companies) => isNil(companies) || isEmpty(companies)
export const boldCompanyNameFormat = (meeting) =>
  isEmptyOrNull(meeting?.companies)
    ? meeting?.company?.name || meeting?.company?.companyName
    : meeting?.companies?.map(({ companyName }, index) => (
        <span key={index}>
          <b>{companyName}</b> {index < meeting?.companies.length - 1 && 'and'}{' '}
        </span>
      ))

export const getStatusTag = (status) => {
  let color
  switch (status) {
    case 'Draft':
      color = 'default'
      break
    case 'Live':
    case 'Open':
    case 'Potential':
      color = 'success'
      break
    case 'Closed':
    case 'Closed - Unfilled':
    case 'Closed - Filled':
      color = 'error'
    default:
      break
  }
  return (
    <Badge status={color} size="small" className="ml-2">
      {status}
    </Badge>
  )
}
export const addLineBreaks = (text) => {
  if (!text) {
    return ''
  }

  const lines = split(text, '\n')
  return map(lines, (line, i) => (
    <Fragment key={i}>
      {line}
      <br />
    </Fragment>
  ))
}

/*export const formatMoney = (number) => {
  if (number >= 1_000_000_000) {
    return `${number / 1_000_000_000} B`
  } else if (number >= 1_000_000) {
    return `${number / 1_000_000} M`
  } else return `${number || 0}`
}*/

export const formatCurrency = numberFormat.currencyLarge

export const formatDate = (date) => moment.utc(date).format('MMM DD YYYY')

export const formatMonthYear = (date) => moment.utc(date).format('YYYY MMM')

export const formatYear = (date) => moment.utc(date).format('YYYY')

export const formatPercent = (data) =>
  numberFormat.percent(data, { precision: 1 })

export const formatDecimal = (data) => numberFormat.decimal(data)

export const formatMultiple = (data) =>
  numberFormat.decimal(data, { suffix: 'x' })

export const pluralize = (count, singular, plural) =>
  [count, count === 1 ? singular : plural].join(' ')

export const shorten = (string, maxLength) =>
  typeof string === 'string' && string.length > maxLength
    ? `${string.substring(0, maxLength - 3)}...`
    : string

export const getFileExtension = (fileName) => fileName.split('.').pop()

export const generateRandomIdentifier = () => random(0, 999999999).toString()
