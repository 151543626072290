import * as React from 'react'
import PropTypes from 'prop-types'
import { primaryScale, secondaryScale } from '@context365/charts'
import { useObjectMemo } from '@context365/hooks'
import { TabButton, TabList, TabPanel, Tabs } from '@context365/tabs'
import { useQuery } from 'react-query'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import { SP500_FUND_INDEX_ID } from '~/constants/indexFunds'
import {
  BenchmarkRelativeChart,
  RollingCorrelationChart,
  RollingSharpeChart,
  UnderwaterChart,
  VamiChart,
} from './charts'
import { CompareFundsContextProvider } from './compareFundsContext'
import CompareFundsTable from './Table'
import useOptions from './useOptions'

const allColors = [...primaryScale, ...secondaryScale]

const ChartType = {
  CompareFunds: 'compare',
  Underwater: 'uwchart',
  BenchmarkRelative: 'bmrel',
  RollingSharpe: 'rollingSharpe',
  RollingCorrelation: 'rollingCorrelation',
}

const CompareFunds = ({ funds }) => {
  const { Track } = useTracking({ component: 'CompareFunds' })
  const [chartType, setChartType] = React.useState(ChartType.CompareFunds)
  const { data: benchmarks } = useQuery(
    ['benchmarks'],
    api.fundCharts.getBenchmarks,
    {
      // This doesn't change frequently, so we can be less aggressive about re-fetching
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  )

  const [options, setOption] = useOptions({
    primaryBenchmarkId: SP500_FUND_INDEX_ID,
    secondaryBenchmarkId: undefined,
  })

  const fundColors = React.useMemo(() => {
    return funds.reduce((lines, fund, i) => {
      lines[fund.fundId] = {
        color: allColors[i % allColors.length],
        dashed: i >= allColors.length,
      }
      return lines
    }, {})
  }, [funds])
  const getFundColor = React.useCallback(
    (fundId) => fundColors[fundId],
    [fundColors]
  )

  const [requestStatus, setRequestStatus] = React.useState('idle')
  const [droppedFunds, setDroppedFunds] = React.useState()
  const isFundDropped = React.useCallback(
    (fundId) => droppedFunds?.includes(fundId) ?? false,
    [droppedFunds]
  )

  const onQueryUpdate = React.useCallback((query) => {
    setRequestStatus(query.status)
    setDroppedFunds(query.data?.droppedfunds)
  }, [])

  const context = useObjectMemo({
    chartType,
    funds,
    benchmarks,
    options,
    setOption,
    getFundColor,
    requestStatus,
    isFundDropped,
    onQueryUpdate,
  })

  return (
    <Track>
      <CompareFundsContextProvider value={context}>
        <div className="p-8 pt-4 bg-grey-50">
          <Tabs
            variant="underlined"
            selectedTab={chartType}
            onSelect={(tab) => setChartType(tab)}
          >
            <TabList className="pb-2">
              <TabButton id={ChartType.CompareFunds} label="VAMI" />
              <TabButton id={ChartType.Underwater} label="Underwater" />
              <TabButton
                id={ChartType.BenchmarkRelative}
                label="Benchmark Relative"
              />
              <TabButton id={ChartType.RollingSharpe} label="Rolling Sharpe" />
              <TabButton
                id={ChartType.RollingCorrelation}
                label="Rolling Correlation"
              />
            </TabList>
            <TabPanel tabId={ChartType.CompareFunds}>
              {() => <VamiChart />}
            </TabPanel>
            <TabPanel tabId={ChartType.Underwater}>
              {() => <UnderwaterChart />}
            </TabPanel>
            <TabPanel tabId={ChartType.BenchmarkRelative}>
              {() => <BenchmarkRelativeChart />}
            </TabPanel>
            <TabPanel tabId={ChartType.RollingSharpe}>
              {() => <RollingSharpeChart />}
            </TabPanel>
            <TabPanel tabId={ChartType.RollingCorrelation}>
              {() => <RollingCorrelationChart />}
            </TabPanel>
          </Tabs>
        </div>
        <CompareFundsTable />
      </CompareFundsContextProvider>
    </Track>
  )
}

CompareFunds.propTypes = {
  funds: PropTypes.array,
}

export default CompareFunds
