import PropTypes from 'prop-types'
import { Switch } from 'antd'
import './DiscoverFilters.less'

const ToggleFilter = ({ label, checked, onChange }) => (
  <div className="DiscoverFilters-switch flex justify-between">
    <div className="DiscoverFilters-header">{label}</div>
    <Switch
      className="DiscoverFilters-switch-item self-center"
      size="small"
      checked={checked}
      onChange={onChange}
    />
  </div>
)

ToggleFilter.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ToggleFilter
