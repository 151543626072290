import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'
import * as api from '~/api'
import ComposeMessageModal from '../ComposeMessageModal'
import DiscoverServiceProviderDetailsModal from '../DiscoverServiceProviderDetailsModal/DiscoverServiceProviderDetailsModal'
import DiscoverServiceProviderMeetingRequest from '../DiscoverServiceProviderMeetingRequest'
import MeetingRequestCfn from '../MeetingRequestCFN'

const defaultServiceProviderProfile = {}

const ServiceProviderModalContainer = ({
  serviceProvider,
  selectedFund,
  representedFund,
  showSPProfile = false,
  onClose,
  serviceProviderProfile = defaultServiceProviderProfile,
}) => {
  const [detailsVisible, setDetailsVisibility] = useState(showSPProfile)
  const [isRequestMeetingModalVisible, setIsRequestMeetingModalVisible] =
    useState(false)
  const [composeModalVisible, setComposeModalVisible] = useState(false)
  const composeModalClosedHandler = () => {
    setComposeModalVisible(false)
  }

  const detailModalClosedHandler = () => {
    setDetailsVisibility(false)
    if (!isNil(onClose)) onClose()
  }

  const { role } = useSelector((state) => state.auth)

  const validateMeeting = useCallback(() => {
    api.meeting
      .checkCanSendMeetingRequest({
        toCompanyId: serviceProvider.companyId,
        fundId: selectedFund,
      })
      .then((validationResponse) => {
        if (validationResponse.isValid) {
          setIsRequestMeetingModalVisible(true)
        } else {
          message.error(validationResponse.reason)
        }
      })
  }, [selectedFund, serviceProvider.companyId])

  return (
    <>
      {(detailsVisible || showSPProfile) && !isNil(serviceProvider) && (
        <Modal
          visible={(detailsVisible || showSPProfile) && !isNil(serviceProvider)}
          footer={null}
          onCancel={detailModalClosedHandler}
          width="900px"
          bodyStyle={{ padding: 0 }}
        >
          <DiscoverServiceProviderDetailsModal
            onSendRequest={validateMeeting}
            onSendMessage={() => setComposeModalVisible(true)}
            serviceProvider={serviceProvider}
            canSendMeetingRequest={!!selectedFund}
            companyId={serviceProvider.companyId}
            visible={
              (detailsVisible || showSPProfile) && !isNil(serviceProvider)
            }
            onCancel={detailModalClosedHandler}
            selectedFundId={role === 'Manager' ? selectedFund : 0}
            fullServiceProviderProfile={serviceProviderProfile}
          />
        </Modal>
      )}
      {serviceProvider && (
        <ComposeMessageModal
          visible={composeModalVisible}
          onClose={composeModalClosedHandler}
          companyID={serviceProvider.companyId}
        />
      )}
      {isRequestMeetingModalVisible &&
        (role !== 'CFN' ? (
          <DiscoverServiceProviderMeetingRequest
            visible={isRequestMeetingModalVisible}
            onClose={() => setIsRequestMeetingModalVisible(false)}
            serviceProvider={serviceProvider}
            onRefresh={() => setDetailsVisibility(false)}
            selectedFund={selectedFund}
            representedFund={representedFund}
            fromMapView
          />
        ) : (
          <MeetingRequestCfn
            visible={isRequestMeetingModalVisible}
            onClose={() => setIsRequestMeetingModalVisible(false)}
            onRefresh={() => setDetailsVisibility(false)}
            cfnCompany={serviceProvider}
          />
        ))}
    </>
  )
}

ServiceProviderModalContainer.propTypes = {
  serviceProvider: PropTypes.object.isRequired,
  selectedFund: PropTypes.number,
  representedFund: PropTypes.string,
  showSPProfile: PropTypes.bool,
  onClose: PropTypes.func,
  serviceProviderProfile: PropTypes.object,
}

export default ServiceProviderModalContainer
