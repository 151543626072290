import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Launch, Refresh, Warning } from '@context365/icons'
import isEmpty from 'lodash/isEmpty'
import { Link, Route, Routes, useParams } from 'react-router-dom-v5-compat'
import useMinScreen from '~/hooks/useMinScreen'
import { MessagesContextProvider } from './context'
import Conversation from './Conversation'
import ConversationList from './ConversationList'
import { ReactComponent as MessageIcon } from './Messages.svg'
import { useConversations } from './queries'
import useInnerScrolling from './utils/useInnerScrolling'

export default function Messages({ baseUrl, twilioClient, archive }) {
  useInnerScrolling()
  const showSplitView = useMinScreen('md')

  return (
    <MessagesContextProvider baseUrl={baseUrl} twilioClient={twilioClient}>
      <div className="flex-1 overflow-hidden md:-mb-8">
        {showSplitView ? (
          <Routes>
            <Route index element={<MessagesSplitView archive={archive} />} />
            <Route
              path=":conversationId"
              element={<MessagesSplitView archive={archive} />}
            />
          </Routes>
        ) : (
          <MessagesPagedView archive={archive} />
        )}
      </div>
    </MessagesContextProvider>
  )
}

Messages.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  twilioClient: PropTypes.object,
  archive: PropTypes.bool,
}

function MessagesSplitView({ archive }) {
  const { conversationId, campaignId } = useParams()
  const { conversations, isLoading, isError } = useConversations({
    archive,
    campaignId,
    currentConversationId: parseInt(conversationId, 10),
  })

  if (isError) {
    return <ConversationsError />
  }

  if (!isLoading && isEmpty(conversations)) {
    return <Empty />
  }

  return (
    <div className="flex h-full overflow-hidden">
      {isLoading ? (
        <ConversationList.Loading />
      ) : (
        <ConversationList conversations={conversations} />
      )}
      {conversationId == null ? (
        <NoSelection />
      ) : (
        <Conversation key={conversationId} />
      )}
    </div>
  )
}

function MessagesPagedView({ archive }) {
  const { campaignId } = useParams()
  const { conversations, isLoading, isError } = useConversations({
    archive,
    campaignId,
  })

  if (isError) {
    return <ConversationsError />
  }

  if (!isLoading && isEmpty(conversations)) {
    return <Empty />
  }

  return (
    <Routes>
      <Route
        index
        element={
          isLoading ? (
            <ConversationList.Loading />
          ) : (
            <ConversationList conversations={conversations} />
          )
        }
      />
      <Route path=":conversationId" element={<Conversation />} />
    </Routes>
  )
}

function NoSelection() {
  return (
    <div className="w-full h-full flex flex-col gap-10 items-center justify-center bg-white border border-b-0 py-10">
      <MessageIcon />
      <h2 className="type-header-sm">Select a conversation to get started.</h2>
    </div>
  )
}

function Empty() {
  return (
    <div className="bg-white border border-b-0 w-full h-full flex flex-col items-center justify-center py-10">
      <MessageIcon className="mb-10" />
      <div className="text-center mb-8 space-y-2">
        <h2 className="type-header-sm text-grey-700">No messages to display</h2>
        <p className="type-body-regular-sm text-grey-600">
          View your connections to start a discussion.
        </p>
      </div>
      <Button
        as={Link}
        to="/community/directory?filter=myconnections%3Dtrue"
        iconLeft={<Launch />}
      >
        View My Network
      </Button>
    </div>
  )
}

function ConversationsError() {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-10 bg-white border rounded">
      <Warning className="text-gold-100" size={64} />
      <div className="max-w-sm text-center space-y-4">
        <div className="type-header-sm">Unable to load conversations</div>
        <div>Please try again.</div>
      </div>
      <div>
        <Button
          variant="filled"
          iconLeft={<Refresh />}
          onClick={() => window.location.reload()}
        >
          Try again
        </Button>
      </div>
    </div>
  )
}
