import PropTypes from 'prop-types'
import { Col, Divider, Row } from 'antd'
import { join, map } from 'lodash'
import { numberFormat } from '~/utils/formatters'
import { addLineBreaks } from '~/utils/helpers'
import ContactShortProfile from '../ContactShortProfile'
import './IlliquidProfileFirm.less'

const StatValue = ({ label, value }) => (
  <>
    <div className="IlliquidFirm-stat-header">{label}</div>
    <div className="IlliquidFirm-stat">{value}</div>
  </>
)

const IlliquidProfileFirm = ({ fundProfile }) => {
  return (
    <div className="IlliquidFirm">
      <Row>
        <Col span={17}>
          <div className="IlliquidFirm-header">Team Bios</div>
          {map(fundProfile.partners, (x) => (
            <div className="ContactWrapper">
              <ContactShortProfile
                firstName={x.name}
                lastName=""
                jobTitle={x.isGeneralPartner ? 'GP' : null}
                bio={x.biography}
                showAvatar={false}
              />
            </div>
          ))}
          <div className="IlliquidFirm-header">Company Description</div>
          <div>{addLineBreaks(fundProfile.companyDescription)}</div>
          <div className="IlliquidFirm-header">Industries of Interest</div>
          <div>{join(fundProfile.industries, ', ')}</div>
          <div className="IlliquidFirm-header">Geographies of Interest</div>
          <div>{join(fundProfile.geographies, ', ')}</div>
          <div className="IlliquidFirm-header">Verticals of Interest</div>
          <div>{join(fundProfile.verticals, ', ')}</div>
        </Col>
        <Col span={1}>
          <Divider type="vertical" style={{ height: '100%' }} />
        </Col>
        <Col span={6}>
          <StatValue
            label="FIRM AUM"
            value={numberFormat.currencyLarge(fundProfile.firmAUM)}
          />
          <StatValue label="ACTIVE FUNDS" value={fundProfile.activeFunds} />
          <StatValue
            label="ACTIVE AND CLOSED FUNDS"
            value={fundProfile.activeFunds + fundProfile.closedFunds}
          />
          <StatValue
            label="ACTIVE PORTFOLIO COMPANIES"
            value={fundProfile.activePortfolioCompanies}
          />
        </Col>
      </Row>
    </div>
  )
}

IlliquidProfileFirm.propTypes = {
  fundProfile: PropTypes.object,
}

export default IlliquidProfileFirm
