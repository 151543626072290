import isNil from 'lodash/isNil'
import CheckboxFilter from './Controls/CheckboxFilter'
import CheckboxGroupFilter from './Controls/CheckboxGroupFilter'
import ContextSignalFilter from './Controls/ContextSignalFilter'

export default function getFilterComponent({ item, header, ...props }) {
  switch (item.questionType) {
    case 'dropdown':
    case 'dropdownCustom':
    case 'dropdownDiscoverLists':
      return (
        <div id={isNil(item.field) ? 'dropdown' : `${item.field}_dropdown`}>
          {header}
          <CheckboxGroupFilter
            className="DiscoverFilters-FilterCheckbox--stacked"
            options={item.values}
            id={item.field}
            label={item.name}
            group={item.questionGroup}
            showClearAll
            {...props}
          />
        </div>
      )
    case 'checkbox':
      if (
        item.questionGroup === 'ApexInvest Signal' ||
        item.questionGroup === 'Trending'
      ) {
        return (
          <ContextSignalFilter
            id={item.field}
            label={item.name}
            group={item.questionGroup}
            {...props}
          />
        )
      } else {
        return (
          <CheckboxFilter
            id={item.field}
            label={item.name}
            group={item.questionGroup}
            {...props}
          />
        )
      }
    default:
      return null
  }
}
