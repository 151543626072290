import isNil from 'lodash/isNil'
import { combineReducers } from 'redux'
import { RESET_APP } from '~/constants/types'
import modals from '~/store/modals'
import auth from './auth'
import company from './company'
import discoverLists from './discoverLists'
import events from './events'
import meetings from './meetings'
import messaging from './messaging'
import notifications from './notifications'
import portfolio from './portfolio'
import roadshow from './roadshow'
import title from './title'
import userAccess from './userAccess'

const appReducer = combineReducers({
  auth,
  company,
  events,
  meetings,
  discoverLists,
  notifications,
  messaging,
  portfolio,
  title,
  roadshow,
  userAccess,
  modals,
})

export default function rootReducer(state, action) {
  if (action.type === RESET_APP) {
    if (!isNil(state.messaging) && !isNil(state.messaging.twilioClient)) {
      // eslint-disable-next-line no-console
      console.log('Twilio client disconnecting...')
      state.messaging.twilioClient.shutdown()
    }
    state = undefined
  }

  return appReducer(state, action)
}
