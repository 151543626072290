export const INITIALIZE_TWILIO_CLIENT = 'INITIALIZE_TWILIO_CLIENT'
export const FETCHING_CONVERSATIONS = 'FETCHING_CONVERSATIONS'
export const FETCH_CONVERSATIONS = 'FETCH_CONVERSATIONS'
export const CONVERSATIONS_FETCHED = 'CONVERSATIONS_FETCHED'
export const CONVERSATIONS_ERROR = 'CONVERSATIONS_ERROR'

export const FETCHING_CONVERSATIONS_COUNT = 'FETCHING_CONVERSATIONS_COUNT'
export const FETCH_CONVERSATIONS_COUNT = 'FETCH_CONVERSATIONS_COUNT'
export const CONVERSATIONS_COUNT_FETCHED = 'CONVERSATIONS_COUNT_FETCHED'
export const CONVERSATIONS_COUNT_ERROR = 'CONVERSATIONS_COUNT_ERROR'

export const TWILIO_MESSAGE_ADDED = 'TWILIO_MESSAGE_ADDED'
export const DESTROY_TWILIO_CLIENT = 'DESTROY_TWILIO_CLIENT'
export const SWITCH_TWILIO_CHANNEL = 'SWITCH_TWILIO_CHANNEL'
export const TWILIO_CHANNEL_UNCONSUMED_MESSAGES_COUNT =
  'TWILIO_CHANNEL_UNCONSUMED_MESSAGES_COUNT'
export const FETCH_OLD_MESSAGES = 'FETCH_OLD_MESSAGES'
export const UPDATE_CHANNEL_ADD_CONTACT = 'UPDATE_CHANNEL_ADD_CONTACT'
export const DISPLAY_MORE_MESSAGES = 'DISPLAY_MORE_MESSAGES'
export const UPDATE_CHANNEL_STATUS = 'UPDATE_CHANNEL_STATUS'
export const RESET_CHANNEL_STATUS = 'RESET_CHANNEL_STATUS'
export const CHANNEL_MEMBER_CONSUMPTION_INDEX =
  'CHANNEL_MEMBER_CONSUMPTION_INDEX'
