import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { find, isEmpty, isNil } from 'lodash'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AvatarGroup from '~/components/AvatarGroup'
import SwitchCompanyModal from '~/components/SwitchCompanyModal'
import { MarketingEventPage } from '~/config'
import { getCompanyId, getCurrentCompanyName } from '~/selectors/auth'
import { getClickableLink } from '~/utils/helpers'
import FeaturedEvent from './FeaturedEvent'
import './EventListing.less'

const ParticipantsFromMyCompanyAvatars = ({ event }) => {
  const avatars = event.participantsFromMyCompany.map((p) => {
    return {
      id: p.contactId,
      firstName: p.firstName,
      lastName: p.lastName,
      initials: `${p.firstName[0]}${p.lastName[0]}`,
      description: `${p.contactName} (${p.jobTitle})`,
      img: p.imageUrl,
    }
  })

  return <AvatarGroup showInitials={true} size="small" avatars={avatars} />
}

const ViewSummitButton = ({
  amRegistered,
  isCfnEvent,
  cfnSummitDetails,
  event,
  isLoggedInAsCFN,
  setIsSwitchCompanyVisible,
  pastSummit = false,
}) => {
  const companyId = useSelector(getCompanyId)
  const { push } = useHistory()

  return (amRegistered ||
    (pastSummit && !isEmpty(event.participantsFromMyCompany))) &&
    (!isCfnEvent || (isLoggedInAsCFN && cfnSummitDetails)) ? (
    <Button
      size="small"
      onClick={() =>
        find(event.registeredWithCompanies, (c) => c.companyId === companyId)
          ? push(`/summits/${event.eventId}`)
          : setIsSwitchCompanyVisible(true)
      }
    >
      View Summit
    </Button>
  ) : (
    <div />
  )
}

const NotRegisteredUpcomingEventItemActions = ({ event }) => (
  <>
    {!isEmpty(event.participantsFromMyCompany) && (
      <ParticipantsFromMyCompanyAvatars event={event} />
    )}
    {!isNil(event.registrationUrl) && (
      <Button
        as="a"
        type="primary"
        className="EventListing-registration-btn"
        size="small"
        href={getClickableLink(event.registrationUrl)}
        target="_blank"
        rel="noopener"
      >
        Register
      </Button>
    )}
    {!isNil(event.websiteUrl) && (
      <Button
        as="a"
        variant="link"
        size="small"
        className="EventListing-link"
        href={getClickableLink(event.websiteUrl)}
        target="_blank"
        rel="noopener"
      >
        Learn More
      </Button>
    )}
  </>
)

const RegisteredUpcomingEventItemActions = ({
  event,
  cfnSummitDetails,
  isLoggedInAsCFN,
  setIsSwitchCompanyVisible,
}) => (
  <>
    <ParticipantsFromMyCompanyAvatars event={event} />
    <div className="EventListing-registration-text">
      <FontAwesomeIcon icon={faCheckCircle} />
      <span>Registered</span>
    </div>
    <ViewSummitButton
      amRegistered={event.amRegistered}
      event={event}
      isCfnEvent={event.isCfnEvent}
      cfnSummitDetails={cfnSummitDetails}
      isLoggedInAsCFN={isLoggedInAsCFN}
      setIsSwitchCompanyVisible={setIsSwitchCompanyVisible}
    />
  </>
)

const RegisteredPastEventItemActions = ({
  event,
  cfnSummitDetails,
  isLoggedInAsCFN,
  setIsSwitchCompanyVisible,
}) => (
  <>
    <ParticipantsFromMyCompanyAvatars event={event} />
    <ViewSummitButton
      amRegistered={event.amRegistered}
      event={event}
      isCfnEvent={event.isCfnEvent}
      cfnSummitDetails={cfnSummitDetails}
      isLoggedInAsCFN={isLoggedInAsCFN}
      setIsSwitchCompanyVisible={setIsSwitchCompanyVisible}
      pastSummit
    />
  </>
)

const Item = ({
  event,
  isPast = false,
  onEditBooth,
  onSelectBooth,
  onGetEvents,
}) => {
  let ItemActions = RegisteredPastEventItemActions

  const [isSwitchCompanyVisible, setIsSwitchCompanyVisible] = useState(false)
  const [visible, setVisible] = useState(false)

  const { cfnSummitDetails } = useFlags()

  const role = useSelector((state) => state.auth.role)
  const name = useSelector(getCurrentCompanyName)
  const { push } = useHistory()

  if (!isPast) {
    ItemActions = event.amRegistered
      ? RegisteredUpcomingEventItemActions
      : NotRegisteredUpcomingEventItemActions
  }
  return (
    <div className="EventListing-item">
      <Button
        variant="link"
        className="EventListing-item-name"
        onClick={() => {
          setVisible(true)
        }}
      >
        {event.name}
      </Button>
      <div className="EventListing-item-actions">
        <ItemActions
          event={event}
          cfnSummitDetails={cfnSummitDetails}
          isLoggedInAsCFN={role === 'CFN'}
          setIsSwitchCompanyVisible={setIsSwitchCompanyVisible}
        />
      </div>
      <Modal
        width={1080}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <FeaturedEvent
          isOnModal
          isPast={isPast}
          event={event}
          onEditBooth={onEditBooth}
          onSelectBooth={onSelectBooth}
          onGetEvents={onGetEvents}
        />
      </Modal>
      <SwitchCompanyModal
        isModalVisible={isSwitchCompanyVisible}
        setIsModalVisible={setIsSwitchCompanyVisible}
        currentCompanyName={name}
        companyList={event.registeredWithCompanies}
        toCompanyId={event.registeredWithCompanies[0]?.companyId}
        toCompanyText={
          <p>
            In order to view the summit you have to switch to
            <span className="font-semibold ml-1 mr-1">
              {event.registeredWithCompanies[0]?.companyName}
            </span>
            since that company is attending the summit.
          </p>
        }
        onSwitchCompany={() => {
          push(`/summits/${event.eventId}`)
        }}
      />
    </div>
  )
}

Item.propTypes = {
  event: PropTypes.shape({
    eventId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    websiteUrl: PropTypes.string,
    registrationUrl: PropTypes.string,
    participantsFromMyCompany: PropTypes.array,
    amRegistered: PropTypes.bool.isRequired,
    registeredWithCompanies: PropTypes.array.isRequired,
  }).isRequired,
  isPast: PropTypes.bool,
  onSelectBooth: PropTypes.func,
  onEditBooth: PropTypes.func,
  onGetEvents: PropTypes.func,
}

const EventListing = ({
  title,
  events,
  arePastEvents = false,
  noEventsTitle = null,
  noEventsDescription = null,
  noEventIcon = null,
  onEditBooth,
  onSelectBooth,
  onGetEvents,
}) => {
  const Icon = noEventIcon

  return (
    <div className="EventListing">
      <div className="EventListing-header">
        <h1>{title}</h1>
      </div>
      <div className="EventListing-body">
        {isEmpty(events) ? (
          <div className="py-4">
            {noEventIcon && (
              <div className="flex justify-center mb-3">
                <Icon size={36} className="text-primary-100" />
              </div>
            )}
            <div className="flex justify-center type-header-xs text-grey-800 mb-3">
              {noEventsTitle}
            </div>
            {noEventsDescription && (
              <div className="flex justify-center type-body-regular-sm text-grey-600 mb-3 text-center">
                <div className="max-w-sm leading-5"> {noEventsDescription}</div>
              </div>
            )}
            {!arePastEvents && (
              <div className="flex justify-center mb-4">
                <a
                  href={MarketingEventPage}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Button variant="filled">Visit website</Button>
                </a>
              </div>
            )}
          </div>
        ) : (
          <>
            {events.map((ev) => (
              <Item
                key={ev.eventId}
                event={ev}
                isPast={arePastEvents}
                onEditBooth={onEditBooth}
                onSelectBooth={onSelectBooth}
                onGetEvents={onGetEvents}
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

EventListing.propTypes = {
  title: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      registrationUrl: PropTypes.string,
    }).isRequired
  ).isRequired,
  arePastEvents: PropTypes.bool,
  noEventsTitle: PropTypes.string,
  noEventsDescription: PropTypes.string,
  noEventIcon: PropTypes.node,
  onSelectBooth: PropTypes.func,
  onEditBooth: PropTypes.func,
  onGetEvents: PropTypes.func,
}

export default EventListing
