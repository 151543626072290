import PropTypes from 'prop-types'
import { DatePicker } from '@context365/forms'
import isNil from 'lodash/isNil'
import { useController } from 'react-hook-form'
import { useFieldSchema } from '../Form'

export function DatePickerField({ name, label, ...props }) {
  const {
    field,
    fieldState: { error },
  } = useController({ name })
  const rules = useFieldSchema(name, label)

  return (
    <DatePicker
      label={label}
      {...field}
      value={isNil(field.value) ? null : new Date(field.value)}
      required={rules.required}
      errorMessage={error?.message}
      {...props}
    />
  )
}

DatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}
