import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import * as api from '~/api'
import Loading from '~/components/Loading'
import Profile from '~/components/Profile'
import CompanyProfileBody from './CompanyProfileBody'
import CompanyProfileHeader from './CompanyProfileHeader'

export default function CompanyProfile({ companyId }) {
  const { data: company, isLoading } = useQuery(
    ['companyProfile', companyId],
    () => api.companies.getCompanyProfile(companyId)
  )

  if (isLoading) {
    return <Loading />
  }

  return (
    <Profile
      header={<CompanyProfileHeader company={company} />}
      body={<CompanyProfileBody company={company} addTabToQueryString />}
    />
  )
}

CompanyProfile.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}
