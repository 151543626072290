import {
  FETCHING_CANCELLATION_REASONS,
  FETCH_ALL_REQUESTED_MEETINGS,
  FETCH_CANCELLATION_REASONS,
  FETCH_CONFIRMED_MEETINGS,
  FETCH_CONFIRMED_MEETINGS_COMPANYEVENTID,
  FETCH_DECLINED_MEETINGS,
  FETCH_DECLINED_MEETINGS_COMPANYEVENTID,
  FETCH_ONLINE_CONFIRMED_MEETINGS,
  FETCH_ONLINE_DECLINED_MEETINGS,
  FETCH_ONLINE_REQUESTED_MEETINGS,
  FETCH_ONLINE_SENT_REQUESTS,
  FETCH_REQUESTED_MEETINGS,
  FETCH_REQUESTED_MEETINGS_COMPANYEVENTID,
  FETCH_ROADSHOW_CONFIRMED_MEETINGS,
  FETCH_ROADSHOW_DECLINED_MEETINGS,
  FETCH_ROADSHOW_REQUESTED_MEETINGS,
  FETCH_ROADSHOW_SENT_REQUESTS,
  FETCH_SENT_REQUESTS,
  FETCH_SENT_REQUESTS_COMPANYEVENTID,
  FETCH_UPCOMING_MEETINGS,
} from '~/constants/types'

const initialState = {
  upcomingMeetings: [],
  upcomingMeetingAllocator: [],
  upcomingMeetingFund: [],
  upcomingMeetingSP: [],
  allRequestedMeetings: [],
  calendarMeetings: [],
  requestedMeetings: { results: [] },
  sentRequests: { results: [] },
  confirmedMeetings: { results: [] },
  declinedMeetings: { results: [] },
  onlineRequestedMeetings: { results: [] },
  onlineSentRequests: { results: [] },
  onlineConfirmedMeetings: { results: [] },
  onlineDeclinedMeetings: { results: [] },
  roadshowRequestedMeetings: { results: [] },
  roadshowSentRequests: { results: [] },
  roadshowConfirmedMeetings: { results: [] },
  roadshowDeclinedMeetings: { results: [] },
  requestedMeetingsCompanyEventId: { results: [] },
  sentRequestsCompanyEventId: { results: [] },
  confirmedMeetingsCompanyEventId: { results: [] },
  declinedMeetingsCompanyEventId: { results: [] },
  onlineMeetingParticipants: { results: [] },
  isFetchingCancellationReasons: false,
  cancellationReasons: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_UPCOMING_MEETINGS:
      return { ...state, upcomingMeetings: action.payload }
    case FETCH_ALL_REQUESTED_MEETINGS:
      return { ...state, allRequestedMeetings: action.payload }
    case FETCH_REQUESTED_MEETINGS:
      return { ...state, requestedMeetings: action.payload }
    case FETCH_SENT_REQUESTS:
      return { ...state, sentRequests: action.payload }
    case FETCH_CONFIRMED_MEETINGS:
      return { ...state, confirmedMeetings: action.payload }
    case FETCH_DECLINED_MEETINGS:
      return { ...state, declinedMeetings: action.payload }
    case FETCH_ONLINE_SENT_REQUESTS:
      return { ...state, onlineSentRequests: action.payload }
    case FETCH_ONLINE_REQUESTED_MEETINGS:
      return { ...state, onlineRequestedMeetings: action.payload }
    case FETCH_ONLINE_CONFIRMED_MEETINGS:
      return { ...state, onlineConfirmedMeetings: action.payload }
    case FETCH_ONLINE_DECLINED_MEETINGS:
      return { ...state, onlineDeclinedMeetings: action.payload }
    case FETCH_ROADSHOW_SENT_REQUESTS:
      return { ...state, roadshowSentRequests: action.payload }
    case FETCH_ROADSHOW_REQUESTED_MEETINGS:
      return { ...state, roadshowRequestedMeetings: action.payload }
    case FETCH_ROADSHOW_CONFIRMED_MEETINGS:
      return { ...state, roadshowConfirmedMeetings: action.payload }
    case FETCH_ROADSHOW_DECLINED_MEETINGS:
      return { ...state, roadshowDeclinedMeetings: action.payload }
    case FETCH_REQUESTED_MEETINGS_COMPANYEVENTID:
      return { ...state, requestedMeetingsCompanyEventId: action.payload }
    case FETCH_CONFIRMED_MEETINGS_COMPANYEVENTID:
      return { ...state, confirmedMeetingsCompanyEventId: action.payload }
    case FETCH_SENT_REQUESTS_COMPANYEVENTID:
      return { ...state, sentRequestsCompanyEventId: action.payload }
    case FETCH_DECLINED_MEETINGS_COMPANYEVENTID:
      return { ...state, declinedMeetingsCompanyEventId: action.payload }
    case FETCH_CANCELLATION_REASONS:
      return {
        ...state,
        cancellationReasons: action.payload,
        isFetchingCancellationReasons: false,
      }
    case FETCHING_CANCELLATION_REASONS:
      return { ...state, isFetchingCancellationReasons: true }
    default:
      return state
  }
}
