import { Badge } from '@context365/badge'
import { Button } from '@context365/button'
import { PlaylistAdd } from '@context365/icons'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment/moment'
import {
  AddAllocatorToListModal,
  useAddToListModal,
} from '~/components/DiscoverListModals'
import { useDiscoverLists } from '~/hooks/discoverLists'

export default function Sidebar({ className, allocator }) {
  return (
    <div className={cx(className, 'space-y-4')}>
      <ListsSection allocator={allocator} />
      <UnderfundedSection allocator={allocator} />
      <NextBoardMeetingSection allocator={allocator} />
    </div>
  )
}

function ListsSection({ allocator }) {
  const addAllocatorToListModal = useAddToListModal()
  const { discoverLists } = useDiscoverLists()

  const selectedLists = getSelectedLists(discoverLists, allocator.companyId)

  return (
    <>
      <Section title="Lists">
        {!isEmpty(selectedLists) && (
          <div className="space-y-1">
            {selectedLists.map((list) => (
              <Badge key={list.name}>{list.name}</Badge>
            ))}
          </div>
        )}
        <Button
          iconLeft={<PlaylistAdd />}
          onClick={() => addAllocatorToListModal.show([allocator])}
        >
          Add to list
        </Button>
      </Section>
      <AddAllocatorToListModal
        allocators={addAllocatorToListModal.items}
        visible={addAllocatorToListModal.visible}
        onClose={addAllocatorToListModal.hide}
      />
    </>
  )
}

function getSelectedLists(discoverLists = [], companyId) {
  return discoverLists.filter((list) =>
    list.discoverListLinks.some((link) => link.toCompanyId === companyId)
  )
}

function NextBoardMeetingSection({ allocator }) {
  if (!allocator.nextBoardMeeting) {
    return null
  }

  return (
    <Section title="Next Board Meeting">
      <div className="type-body-regular-sm text-primary-100">
        {moment(allocator.nextBoardMeeting).format('MM/DD/YYYY')}
      </div>
    </Section>
  )
}

function UnderfundedSection({ allocator }) {
  if (isEmpty(allocator.underfundedClasses)) {
    return null
  }

  return (
    <Section title="Underfunded Classes">
      {allocator.underfundedClasses.map((name) => (
        <div key={name} className="type-body-regular-sm">
          {name}
        </div>
      ))}
      <Button>View Allocations</Button>
    </Section>
  )
}

function Section({ title, children }) {
  return (
    <div className="space-y-2">
      <div className="type-subtitle-xs text-primary-100">{title}</div>
      {children}
    </div>
  )
}
