import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Divider, Input, Popover, Radio, message } from 'antd'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import numeral from 'numeral'
import { Prompt } from 'react-router-dom'
import { INVALID_URL } from '~/constants/validationMessages'
import { validURL } from '~/utils/helpers'
import CCUpload from '../CCUpload'
import FloatingInput from '../FloatingInput'
import FloatingSelect from '../FloatingSelect'
import FloatingTextArea from '../FloatingTextArea'
import DomicileSection from './DomicileSection'
import HoldingsSection from './HoldingsSection'
import InvestmentPolicySection from './InvestmentPolicySection'
import OnboardingAddressSection from './OnboardingAddressSection'
import OnboardingLoading from './OnboardingLoading'

const GenericForm = ({
  step,
  stepNumber,
  enableNext = () => {},
  onChange,
  currentQuestions,
  companyId,
  fromSettings = false,
  setContentChanged = () => {},
  contentChanged,
  activeTab = null,
  showInvestorStatus = true,
}) => {
  const [questions, setQuestions] = useState(null)

  const checkAllQuestions = useCallback(
    (withChange = true) => {
      if (fromSettings) {
        if (withChange === true) {
          onChange(questions)
          setContentChanged(true)
        }
      } else {
        if (isNil(questions)) return
        const requiredQuestions = questions.filter(
          (x) => x.isRequired && x.shortName !== 'Address'
        )
        const invalidQuestions = requiredQuestions.filter(
          (x) => isNil(x.currentlyValid) || x.currentlyValid === false
        )
        const website =
          (typeof questions.find((q) => q.shortName === 'CompanyWebsite') !==
            'undefined' &&
            questions.find((q) => q.shortName === 'CompanyWebsite')?.answer) ||
          ''
        let validAddress = true
        const address = questions.find((q) => q.shortName === 'Address')
        if (address) {
          if (address.answer) {
            if (
              address.answer.city === '' ||
              isNil(address.answer.city) ||
              address.answer.country === '' ||
              isNil(address.answer.country) ||
              address.answer.continent === '' ||
              isNil(address.answer.continent)
            )
              validAddress = false
          }
        }
        validURL(website) &&
          enableNext(invalidQuestions.length === 0 && validAddress)

        const holdings = questions.find(
          (q) => q.shortName === 'Holdings'
        )?.answer
        holdings && enableNext(validateHoldings(holdings))
        if (withChange === true) {
          if (!isNil(onChange)) {
            onChange(questions)
          }
        }
      }
    },
    [enableNext, fromSettings, onChange, questions, setContentChanged]
  )

  useEffect(() => {
    const stepQuestions = !fromSettings
      ? currentQuestions.find((x) => x.step === stepNumber)
      : currentQuestions
    if (!isNil(stepQuestions)) {
      !fromSettings
        ? setQuestions(stepQuestions.questions)
        : setQuestions(stepQuestions)
    } else if (!contentChanged) {
      setQuestions(null)
      if (step.customRender === true && step.customRenderParameter) {
        if (isNil(activeTab) || activeTab === stepNumber) {
          step
            .renderAction(step.customRenderParameter)
            .then((response) => {
              setQuestions(response.data.result)
            })
            .catch(() =>
              message.error(
                'There was an error while attempting to get questions'
              )
            )
        }
      } else {
        step
          .renderAction(companyId)
          .then((response) => {
            setQuestions(response.data.result)
          })
          .catch(() =>
            message.error(
              'There was an error while attempting to get questions'
            )
          )
      }
    }
  }, [
    stepNumber,
    companyId,
    currentQuestions,
    step,
    fromSettings,
    activeTab,
    contentChanged,
  ])

  const onQuestionChange = useCallback(
    (questionTypeID, questionID, shortName, e) => {
      const value = isNil(e.target) ? e : e.target.value
      if (shortName === 'SecondaryInvestorCategory') {
        if (value.length > 2) return
      }
      const currentQuestion = questions.find((x) => x.questionID === questionID)
      if (
        questionTypeID === 5 ||
        questionTypeID === 6 ||
        shortName === 'MaxPercentageOfAFundsAUMTheyWouldBeComfortableComprising'
      ) {
        if (!numeral.validate(value) && value !== '') {
          currentQuestion.tooltip = 'Numeric values allowed only'
        } else {
          currentQuestion.answer =
            value === '' ? value : numeral(value).format('0')
          currentQuestion.tooltip = null
        }
      } else if (questionTypeID === 3 || questionTypeID === 23) {
        currentQuestion.answer = value.join(',')
      } else if (questionTypeID === 24 || questionTypeID === 27) {
        currentQuestion.answer =
          value === '' || isNil(value) ? null : numeral(value).format('0')
      } else {
        currentQuestion.answer = value
        currentQuestion.tooltip = null
      }
      currentQuestion.currentlyValid = currentQuestion.validation(value)
      currentQuestion.isValid = currentQuestion.validation(value)
      currentQuestion.tooltip = null
      setQuestions(
        questions.map((x) =>
          x.questionID === questionID ? currentQuestion : x
        )
      )
      checkAllQuestions()
    },
    [checkAllQuestions, questions]
  )

  const validateHoldings = (values) => {
    const selectedValues = values.filter((value) => value.name === '')
    return isEmpty(selectedValues)
  }

  const renderManuallyManagedControl = useCallback(
    (shortName, question) => {
      question.validation = () => true
      switch (shortName) {
        case 'MaxPercentageOfAFundsAUMTheyWouldBeComfortableComprising':
          question.validation = (x) => !isNil(x) && x !== '' && !isNaN(x)
          question.currentlyValid = question.validation(question.answer)
          return (
            <div style={{ textAlign: 'left', marginBottom: '24px' }}>
              <div className="cc-heading5 cc-generic-form-checkbox-header">
                Max percentage of a funds AUM they would be comfortable
                comprising {question.isRequired ? ' *' : ''}
              </div>
              <Popover
                content={question.tooltip}
                visible={!isNil(question.tooltip)}
              >
                <Input
                  addonAfter="%"
                  value={question.answer}
                  className={`${
                    question.isValid !== false ? '' : 'cc-onboarding-invalid '
                  }cc-onboarding-percentage`}
                  onChange={onQuestionChange.bind(
                    null,
                    question.questionTypeID,
                    question.questionID,
                    question.shortName
                  )}
                />
              </Popover>
            </div>
          )
        case 'FundDomicileInterest':
          question.validation = (x) => !isNil(x) && x !== ''
          question.currentlyValid = question.validation(question.answer)
          return (
            <DomicileSection
              isRequired={question.isRequired}
              onChange={onQuestionChange.bind(
                null,
                question.questionTypeID,
                question.questionID,
                question.shortName
              )}
              value={question.answer}
            />
          )
        case 'Holdings':
          question.validation = (x) => !isNil(x) && validateHoldings(x)
          question.currentlyValid = question.validation(question.answer)
          return (
            <div className="cc-generic-form-checkbox-group">
              <span className="cc-heading5 cc-generic-form-checkbox-header">
                {question.label}
              </span>
              <HoldingsSection
                isRequired={question.isRequired}
                enableNext={enableNext}
                onChange={onQuestionChange.bind(
                  null,
                  question.questionTypeID,
                  question.questionID,
                  question.shortName
                )}
                value={question.answer}
              />
            </div>
          )
        case 'InvestmentPolicy':
          question.validation = (x) => !isNil(x) && x !== ''
          question.currentlyValid = question.validation(question.answer)
          return (
            <div className="cc-generic-form-checkbox-group">
              <span className="cc-heading5 cc-generic-form-checkbox-header">
                {question.isRequired === true
                  ? `${question.label} *`
                  : question.label}
              </span>
              <InvestmentPolicySection
                isRequired={question.isRequired}
                onChange={onQuestionChange.bind(
                  null,
                  question.questionTypeID,
                  question.questionID,
                  question.shortName
                )}
                value={question.answer}
              />
            </div>
          )
        default:
          question.currentlyValid = true
          question.validation = () => true
          return null
      }
    },
    [onQuestionChange]
  )

  const validationMessage = (question) => {
    if (question.shortName !== 'CompanyWebsite') {
      return isNil(question.validationMessage)
        ? 'This field is required'
        : question.validationMessage
    } else {
      return INVALID_URL
    }
  }

  const isValidated = (question) => {
    if (question.shortName !== 'CompanyWebsite') {
      return (
        question.isRequired === false ||
        (!isNil(question.answer) && question.answer !== '')
      )
    } else {
      return validURL(question.answer)
    }
  }

  const checkChildVisibility = useCallback(
    (question) => {
      if (questions) {
        const attributes = JSON.parse(question.attributes)
        const dependentQuestion = questions.find(
          (x) => x.questionID == attributes.dependentOnQuestion
        )

        if (dependentQuestion) {
          const selectedAnswers = dependentQuestion.answer
          if (!selectedAnswers && question.answer) {
            onQuestionChange(
              question.questionTypeID,
              question.questionID,
              question.shortName,
              []
            )
          }

          if (selectedAnswers) {
            const answers = selectedAnswers.split(',')
            const answerIds = []
            forEach(answers, (a) => {
              const answerId = dependentQuestion.questionAnswers.find(
                (x) => x.questionAnswer === a
              )
              if (answerId) answerIds.push(`${answerId.questionAnswerID}`)
            })
            const hasDependentAnswers = answerIds.includes(
              attributes.dependentOnQuestionAnswer
            )
            if (
              !hasDependentAnswers &&
              question.answer !== '' &&
              !isNil(question.answer)
            ) {
              onQuestionChange(
                question.questionTypeID,
                question.questionID,
                question.shortName,
                []
              )
            }
            return hasDependentAnswers
          }
        }
      }
      return false
    },
    [onQuestionChange, questions]
  )

  const isLastChild = useCallback(
    (question) => {
      if (questions) {
        const attributes = JSON.parse(question.attributes)
        const dependentQuestion = questions.find(
          (x) => x.questionID == attributes.dependentOnQuestion
        )
        if (dependentQuestion) {
          if (dependentQuestion && dependentQuestion.questionTypeID === 23)
            return true
        }
      }
      return false
    },
    [questions]
  )

  const getGroupQuestionLabel = useCallback((question) => {
    const attributes = JSON.parse(question.attributes)
    if (attributes.groupName) {
      return attributes.groupName
    }
  }, [])

  const getGroupQuestions = useCallback(
    (question) => {
      const attributes = JSON.parse(question.attributes)
      const groupQuestions = [question]
      if (attributes.groupName) {
        const childQuestions = questions.filter((x) => x.questionTypeID === 27)
        forEach(childQuestions, (child) => {
          const childAttributes = JSON.parse(child.attributes)
          if (
            childAttributes.groupName &&
            childAttributes.groupName === attributes.groupName
          )
            groupQuestions.push(child)
        })
      }
      return groupQuestions
    },
    [questions]
  )

  const renderControl = (question) => {
    let input
    const commonFloatInput = (
      <FloatingInput
        tooltip={question.tooltip}
        value={question.answer}
        validationMessage={validationMessage(question)}
        isValid={isValidated(question)}
        onChange={onQuestionChange.bind(
          null,
          question.questionTypeID,
          question.questionID,
          question.shortName
        )}
        autoComplete={question.label === 'Company Name' ? 'off' : undefined}
        name={
          question.isRequired === true ? `${question.label} *` : question.label
        }
      />
    )
    question.validation = () => true
    switch (question.questionTypeID) {
      case 1:
        input = commonFloatInput
        question.validation = (x) =>
          question.isRequired === false || (!isNil(x) && x !== '')
        break
      case 2:
        input = (
          <FloatingTextArea
            tooltip={question.tooltip}
            value={question.answer}
            validationMessage={
              isNil(question.validationMessage)
                ? 'This field is required'
                : question.validationMessage
            }
            isValid={question.isValid === true || isNil(question.isValid)}
            onChange={onQuestionChange.bind(
              null,
              question.questionTypeID,
              question.questionID,
              question.shortName
            )}
            name={
              question.isRequired === true
                ? `${question.label} *`
                : question.label
            }
          />
        )
        question.validation = (x) =>
          question.isRequired === false || (!isNil(x) && x !== '')
        break
      case 3:
        input = (
          <div className="cc-generic-form-checkbox-group">
            <span className="cc-heading5 cc-generic-form-checkbox-header">
              {question.isRequired === true
                ? `${question.label} *`
                : question.label}
              {question.shortName === 'SecondaryInvestorCategory' ? (
                <span className="cc-small-text cc-additional-info">
                  Choose up to two (optional)
                </span>
              ) : null}
            </span>
            <Checkbox.Group
              value={
                isNil(question.answer)
                  ? question.answer
                  : question.shortName === 'SecondaryInvestorCategory'
                  ? question.answer.split(',').slice(0, 2)
                  : question.answer.split(',')
              }
              onChange={onQuestionChange.bind(
                null,
                question.questionTypeID,
                question.questionID,
                question.shortName
              )}
              // style={{ marginTop: '12px', paddingLeft: '12px' }}
              options={question.questionAnswers.map((x) => ({
                label: x.questionAnswer,
                value: x.questionAnswer,
              }))}
            />
            <Divider />
          </div>
        )
        if (question.shortName === 'SecondaryInvestorCategory') {
          question.validation = (x) => {
            if (Array.isArray(x)) {
              const result =
                question.isRequired === false ||
                (!isNil(x) && x.length > 0 && x.length <= 2)
              return result
            } else {
              const result =
                question.isRequired === false ||
                (!isNil(x) &&
                  x.split(',').length > 0 &&
                  x.split(',').length <= 2)
              return result
            }
          }
        } else {
          question.validation = (x) => {
            const result =
              question.isRequired === false || (!isNil(x) && x.length > 0)
            return result
          }
        }
        break
      case 4:
        input = (
          <FloatingSelect
            name={
              question.isRequired === true
                ? `${question.label} *`
                : question.label
            }
            value={question.answer}
            validationMessage={
              isNil(question.validationMessage)
                ? 'This field is required'
                : question.validationMessage
            }
            isValid={
              question.isRequired === false ||
              (!isNil(question.answer) &&
                question.questionAnswers.filter(
                  (a) => question.answer === a.questionAnswer
                ).length > 0)
            }
            onChange={onQuestionChange.bind(
              null,
              question.questionTypeID,
              question.questionID,
              question.shortName
            )}
            options={question.questionAnswers.map((x) => ({
              value: x.questionAnswer,
              text: x.questionAnswer,
            }))}
          />
        )
        question.validation = (x) =>
          question.isRequired === false ||
          (!isNil(x) &&
            question.questionAnswers.filter((a) => x === a.questionAnswer)
              .length > 0)
        break
      case 5:
      case 6:
        input = (
          <FloatingInput
            tooltip={question.tooltip}
            value={
              isNil(question.answer) || question.answer === ''
                ? ''
                : numeral(question.answer).format('0,0')
            }
            validationMessage={
              isNil(question.validationMessage)
                ? 'This field is required'
                : question.validationMessage
            }
            isValid={question.isValid === true || isNil(question.isValid)}
            onChange={onQuestionChange.bind(
              null,
              question.questionTypeID,
              question.questionID,
              question.shortName
            )}
            name={
              question.isRequired === true
                ? `${question.label} *`
                : question.label
            }
          />
        )
        question.validationMessage =
          'This field is required and should have a numeric value'
        question.validation = (x) =>
          !isNil(x) && x !== '' && numeral.validate(x)
        break
      case 7:
        input = (
          <div className="cc-generic-form-radio-group">
            <span className="cc-heading6 cc-generic-form-radio-header">
              {question.isRequired === true
                ? `${question.label} *`
                : question.label}
            </span>
            {question.shortName === 'FirstLossCapital' ? (
              <div className="cc-body-text cc-generic-form-radio-header-capital">
                Do you only provide first loss capital?
              </div>
            ) : null}
            <Radio.Group
              onChange={onQuestionChange.bind(
                null,
                question.questionTypeID,
                question.questionID,
                question.shortName
              )}
              value={question.answer}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </div>
        )
        question.validation = () =>
          !question.isRequired ||
          (question.isRequired && !isNil(question.answer))
        break
      case 12:
        input = (
          <div className="cc-generic-form-radio-group">
            <span className="cc-heading6 cc-generic-form-radio-header">
              {question.isRequired === true
                ? `${question.label} *`
                : question.label}
            </span>
            <Radio.Group
              onChange={onQuestionChange.bind(
                null,
                question.questionTypeID,
                question.questionID,
                question.shortName
              )}
              value={question.answer}
            >
              {question.questionAnswers.map((x) => (
                <Radio value={x.questionAnswer} key={x.questionAnswer}>
                  {x.questionAnswer}{' '}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        )
        question.validation = () => true
        break
      case 13:
        input = (
          <OnboardingAddressSection
            validationMessage="This field is required"
            onChange={onQuestionChange.bind(
              null,
              question.questionTypeID,
              question.questionID,
              question.shortName
            )}
            value={isNil(question.answer) ? {} : question.answer}
          />
        )
        question.validationMessage = 'This field is required'
        question.validation = (x) => !isNil(x) && x.isValid === true
        break
      case 15:
        return renderManuallyManagedControl(question.shortName, question)
      case 19:
        input = (
          <div style={{ marginBottom: '15px' }}>
            <CCUpload
              placeholder={question.label}
              imageUrl={question.answer}
              onChange={onQuestionChange.bind(
                null,
                question.questionTypeID,
                question.questionID,
                question.shortName
              )}
            />
          </div>
        )
        break
      case 23:
        if (checkChildVisibility(question))
          input = (
            <span>
              <div className="cc-generic-form-checkbox-group">
                <div
                  className={
                    isLastChild(question)
                      ? 'cc-generic-form-child-checkbox'
                      : 'cc-generic-form-parent-checkbox'
                  }
                >
                  <span className="cc-heading6 cc-generic-form-checkbox-header">
                    {question.isRequired === true
                      ? `${question.label} *`
                      : question.label}
                  </span>
                  <Checkbox.Group
                    value={
                      isNil(question.answer)
                        ? question.answer
                        : question.shortName === 'SecondaryInvestorCategory'
                        ? question.answer.split(',').slice(0, 2)
                        : question.answer.split(',')
                    }
                    onChange={onQuestionChange.bind(
                      null,
                      question.questionTypeID,
                      question.questionID,
                      question.shortName
                    )}
                    // style={{ marginTop: '12px', paddingLeft: '12px' }}
                    options={question.questionAnswers.map((x) => ({
                      label: x.questionAnswer,
                      value: x.questionAnswer,
                    }))}
                  />
                </div>
                <Divider />
              </div>
            </span>
          )
        break
      case 24: {
        input = (
          <div className="cc-generic-form-checkbox-group">
            <span className="cc-heading5 cc-generic-form-checkbox-header">
              {getGroupQuestionLabel(question)}
            </span>
            <div style={{ display: 'flex' }}>
              {getGroupQuestions(question) &&
                getGroupQuestions(question).map((childQuestion) => (
                  <div
                    className="cc-generic-form-floating-input-group"
                    key={childQuestion.questionID}
                  >
                    <FloatingInput
                      tooltip={childQuestion.tooltip}
                      value={
                        isNil(childQuestion.answer) ||
                        childQuestion.answer === ''
                          ? ''
                          : numeral(childQuestion.answer).format('0,0')
                      }
                      validationMessage={
                        isNil(childQuestion.validationMessage)
                          ? 'This field is required'
                          : childQuestion.validationMessage
                      }
                      isValid={
                        childQuestion.isValid === true ||
                        isNil(childQuestion.isValid)
                      }
                      onChange={onQuestionChange.bind(
                        null,
                        childQuestion.questionTypeID,
                        childQuestion.questionID,
                        childQuestion.shortName
                      )}
                      name={
                        childQuestion.isRequired === true
                          ? `${childQuestion.label} *`
                          : childQuestion.label
                      }
                    />
                  </div>
                ))}
            </div>
          </div>
        )
        question.validationMessage = 'This field should have a numeric value'
        question.validation = (x) => isNil(x) || x === '' || numeral.validate(x)
        break
      }
      case 27:
        break
      default:
        input = commonFloatInput
        question.validation = () => true
        break
    }

    question.currentlyValid = question.validation(question.answer)
    checkAllQuestions(false)

    return (
      input && (
        <div
          className="cc-generic-form-control-container"
          style={{ width: '100%' }}
        >
          {input}
        </div>
      )
    )
  }

  useEffect(() => {
    if (!fromSettings) {
      if (step.title !== 'Deals' || !showInvestorStatus) {
        checkAllQuestions(true)
        if (questions)
          questions.forEach((q) => {
            q.isValid = !isNil(q.validation) && q.validation(q.answer)
          })
      }
    }
  }, [checkAllQuestions, fromSettings, questions])

  return (
    <>
      <Prompt
        message="Are you sure you want to leave without saving?"
        when={contentChanged}
      />
      {isNil(questions) ? (
        <OnboardingLoading />
      ) : (
        <div className="cc-generic-form-container">
          {questions.map((x) => renderControl(x))}
        </div>
      )}
    </>
  )
}

GenericForm.propTypes = {
  step: PropTypes.shape({
    stepNumber: PropTypes.number,
    innerStep: PropTypes.number.isRequired,
    steps: PropTypes.array,
    shouldPost: PropTypes.bool,
    actionType: PropTypes.number,
    canGoBack: PropTypes.bool,
    customWidth: PropTypes.bool,
    renderAction: PropTypes.func.isRequired,
    customRender: PropTypes.bool,
    customRenderParameter: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  stepNumber: PropTypes.number.isRequired,
  enableNext: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  currentQuestions: PropTypes.array,
  companyId: PropTypes.number,
  fromSettings: PropTypes.bool,
  activeTab: PropTypes.number,
  contentChanged: PropTypes.bool,
  setContentChanged: PropTypes.func,
  showInvestorStatus: PropTypes.bool,
}

export default GenericForm
