import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import { Button } from '@context365/button'
import { Add, PlaylistAddCheck } from '@context365/icons'
import { Placement, Popover } from '@context365/popovers'
import map from 'lodash/map'
import { useDiscoverLists } from '~/hooks/discoverLists'

const DiscoverListsPopover = ({
  lists,
  targetObject,
  getTargetModel,
  onAddToList,
}) => {
  const { toggleDiscoverListLink } = useDiscoverLists()

  return (
    <div className="relative top-2 cursor-pointer">
      <Popover
        interactive
        placement={Placement.RightStart}
        content={
          <div className="text-white bg-dark p-4 rounded p-2">
            Added to the following lists:
            {map(lists, (list) => (
              <div key={list.discoverListId} className="group">
                <Badge size="small">{list.name}</Badge>
                <Button
                  className="invisible group-hover:visible"
                  variant="link"
                  status="error"
                  onClick={() => {
                    toggleDiscoverListLink(
                      list.discoverListId,
                      getTargetModel(targetObject)
                    )
                  }}
                >
                  Remove
                </Button>
              </div>
            ))}
            <div className="mt-4">
              <Button
                variant="filled"
                size="small"
                status="secondary"
                iconLeft={<Add />}
                onClick={() => {
                  onAddToList(targetObject)
                }}
              >
                Add to List
              </Button>
            </div>
          </div>
        }
      >
        <PlaylistAddCheck />
      </Popover>
    </div>
  )
}

DiscoverListsPopover.propTypes = {
  onAddToList: PropTypes.func,
  targetObject: PropTypes.any,
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      discoverListId: PropTypes.number.isRequired,
    })
  ),
  getTargetModel: PropTypes.func,
}

export default DiscoverListsPopover
