import jwtDecode from 'jwt-decode'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import map from 'lodash/map'
import take from 'lodash/take'
import trim from 'lodash/trim'

export const getInitials = (fullname) =>
  map(take(trim(fullname).split(/\s+/gi), 2), (part) =>
    part.substr(0, 1).toUpperCase()
  ).join('')

export const getCompany = (accessToken) => {
  if (isNil(accessToken)) {
    return { companyId: 0, role: null }
  }

  const claims = jwtDecode(accessToken)
  const companyId = get(claims, 'companies[0].id', 0)
  const role = get(claims, 'companies[0].category', 0)
  return { companyId, role }
}

export const getUserId = (accessToken) => {
  if (isNil(accessToken)) {
    return 0
  }

  const claims = jwtDecode(accessToken)
  return get(claims, 'nameid', 0)
}

export const generateId = (length) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length

  const generatedChars = []
  for (let i = 0; i < length; i++) {
    generatedChars.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    )
  }

  return join(generatedChars, '')
}

export function listify(items) {
  switch (items.length) {
    case 0:
      return ''
    case 1:
      return items[0]
    case 2:
      return items.join(' and ')
    default:
      return [items.slice(0, -1).join(', '), items[items.length - 1]].join(
        ', and '
      )
  }
}

export { nameToColor } from './nameToColor'
