import { FETCH_USER_ACCESS } from '~/constants/types'
import { SWITCH_COMPANY } from '~/constants/types/auth'

const initialState = {
  hasLoaded: false,
  activities: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_ACCESS:
      return { activities: action.payload, hasLoaded: true }
    case SWITCH_COMPANY:
      return initialState
    default:
      return state
  }
}
