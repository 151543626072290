import 'moment-timezone'
import PropTypes from 'prop-types'
import { SelectTimezone } from '@capaj/react-select-timezone'
import { useTheme } from '@context365/config'
import '~/styles/index.less'

const Timezone = ({
  setCustomStyle = false,
  value,
  handleTimezoneChange,
  isDisabled,
}) => {
  const selectColor = useTheme('colors.primary.100')
  const hoverColor = useTheme('colors.secondary.2')
  const textColor = useTheme('textColors.body')

  const customStyle = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected || state.isFocused ? '#fff' : '#F7F8FA',
      borderStyle: 'none',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: () => ({
      color: selectColor,
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: '0px',
      color: selectColor,
      font: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0px',
    }),
    container: (provided) => ({
      ...provided,
      borderStyle: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      boxShadow: 'inset 0px -1px 0px #EFF1F4',
      padding: '12px 16px',
      backgroundColor: state.isSelected ? hoverColor : '#fff',
      color: textColor,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 999,
      marginTop: '1px',
    }),
  }

  const defaultStyle = {
    menu: (provided) => ({
      ...provided,
      zIndex: 999,
      marginTop: '1px',
    }),
    container: (provided) => ({
      ...provided,
    }),
    control: (provided) => ({
      ...provided,
      border: 'none',
      height: '48px',
      // boxShadow: '0px 16px 28px rgba(47, 56, 62, 0.08)',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: () => ({
      color: selectColor,
      display: 'flex',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: selectColor,
      font: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
    }),
    option: (provided, state) => ({
      ...provided,
      boxShadow: 'inset 0px -1px 0px #EFF1F4',
      padding: '12px 16px',
      backgroundColor: state.isSelected ? hoverColor : '#fff',
      color: textColor,
    }),
  }

  return (
    <SelectTimezone
      styles={setCustomStyle ? customStyle : defaultStyle}
      theme={(theme) => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary: selectColor,
        },
      })}
      classNamePrefix="cc-timezone"
      value={value}
      isDisabled={isDisabled}
      guess
      onChange={handleTimezoneChange}
    />
  )
}

Timezone.propTypes = {
  setCustomStyle: PropTypes.bool,
  value: PropTypes.string.isRequired,
  handleTimezoneChange: PropTypes.func,
  isDisabled: PropTypes.bool,
}

export default Timezone
