import { Button } from '@context365/button'
import { Help, Launch } from '@context365/icons'
import { Link } from 'react-router-dom'
import InfoPopover from '~/components/InfoPopover'

function SeeAllLink({ to, iconRight = <Launch />, children = 'See All' }) {
  return (
    <Button
      variant="link"
      as={Link}
      to={to}
      iconRight={iconRight}
      style={{ padding: 0 }}
      status="secondary"
    >
      {children}
    </Button>
  )
}

const WidgetBlock = ({ headerLeft, headerRight, helpContent, children }) => {
  return (
    <div className="shadow-0 rounded-big">
      <div className="flex flex-row justify-between py-3 px-4 bg-secondary-2 rounded-t-big items-center border-b border-secondary-5">
        <div className="justify-start type-header-xs">
          {headerLeft}
          {helpContent && (
            <InfoPopover popoverBody={helpContent}>
              <Help size={16} className="text-primary-100 ml-1.5 cursor-help" />
            </InfoPopover>
          )}
        </div>
        <div className="justify-end space-x-5">{headerRight}</div>
      </div>
      <div className="p-4 bg-white rounded-b-big">{children}</div>
    </div>
  )
}

// The default export is deprecated. Use these named exports instead.
export { SeeAllLink, WidgetBlock }
export default WidgetBlock
