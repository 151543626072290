import PropTypes from 'prop-types'
import { Input } from 'antd'
import useFormAnswers from '~/utils/hooks/useFormAnswers'
import '../Fields.less'

const TextField = ({ question }) => {
  const { answer, handleTextAnswerChange } = useFormAnswers(question)

  return (
    <Input.TextArea
      className="TextArea"
      autoSize={{ minRows: 8 }}
      name={question.name}
      value={answer.responseText}
      onChange={(e) => handleTextAnswerChange(e.target.value)}
    />
  )
}

TextField.propTypes = {
  question: PropTypes.shape({
    name: PropTypes.string,
    shortName: PropTypes.string,
    isRequired: PropTypes.bool,
  }),
}

export default TextField
