import PropTypes from 'prop-types'
import { Row } from 'antd'
import './ServiceProviderPeopleCard.less'

const ServiceProviderTestimonialCard = ({ serviceProvider }) => {
  if (!serviceProvider) {
    return null
  }

  return (
    <div className="discover-allocator" id="fixed">
      <div className="ServiceProviderPeopleCard">
        <Row className="ServiceProviderPeopleCard-title">
          {serviceProvider.title}
        </Row>

        <Row className="ServiceProviderPeopleCard-description">
          {serviceProvider.description}
        </Row>
        <Row className="ServiceProviderPeopleCard-subtitle">
          {serviceProvider.jobPosition} , {serviceProvider.fullName}
        </Row>
      </div>
    </div>
  )
}

ServiceProviderTestimonialCard.propTypes = {
  serviceProvider: PropTypes.object.isRequired,
}

export default ServiceProviderTestimonialCard
