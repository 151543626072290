import { api as http } from '~/api/services'
import { FETCH_PORTFOLIO_FUNDS, FETCH_PORTFOLIO_LIST } from '~/constants/types'

export const fetchPortfolioList =
  (pagination, filters, sorter, searchTerm) => (dispatch) => {
    http
      .post('/portfolio/list', {
        pagination,
        filters,
        sorter,
        searchTerm,
      })
      .then((response) =>
        dispatch({
          type: FETCH_PORTFOLIO_LIST,
          payload: response.data.result,
        })
      )
  }
export const fetchPortfoliosFunds =
  (portfolioId, pagination, filters, sorter, searchTerm) => (dispatch) => {
    http
      .post(`/portfolio/${portfolioId}/funds`, {
        pagination,
        filters,
        sorter,
        searchTerm,
      })
      .then((response) =>
        dispatch({
          type: FETCH_PORTFOLIO_FUNDS,
          payload: response.data.result,
        })
      )
  }

export const createNewPorfolio = (portfolioName) =>
  http.post('/portfolio', { portfolioName })

export const fetchAllocations = (pagination, filters, sorter, searchTerm) =>
  http.post('/portfolio/allocations', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getPortfolioReturns = (portfolioId) =>
  http.get(`/portfolio/${portfolioId}/returns`)
