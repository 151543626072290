import * as React from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import filter from 'lodash/filter'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import take from 'lodash/take'
import { useTracking } from 'react-tracking'
import MandateTypes from '~/constants/mandateTypes'
import { useDiscoverLists } from '~/hooks/discoverLists'
import { ApplyToMandateModal, SuccessfulApplicationModal } from '../Application'
import ContactList from '../ContactList'
import { DiscoverResultCard } from '../Discover'
import { DiscoverCardStats, statFormat } from '../DiscoverCardStats'
import MandateProfileModal from '../MandateProfile/MandateProfileModal'
import DiscoverMandateResultFooter from './DiscoverMandateResultFooter'
import DiscoverMandateResultHeader from './DiscoverMandateResultHeader'

const { Text } = Typography

const getDetailItems = (mandate) => {
  const items = [
    {
      title: 'Mandate Type',
      data: mandate.mandateType,
      isVisible: !isNil(mandate.mandateType),
    },
    {
      title: 'Target Completion Date',
      data: mandate.targetCompletionDate,
      format: statFormat.date,
      isVisible: !isNil(mandate.targetCompletionDate),
    },
    {
      title: 'Mandate Size',
      data: isNil(mandate.maxSize)
        ? `${statFormat.currency(mandate.minSize)} (min)`
        : `${statFormat.currency(mandate.minSize ?? 0)} - ${statFormat.currency(
            mandate.maxSize
          )}`,
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        (mandate.minSize > 0 || !isNil(mandate.maxSize)),
    },
    {
      title: 'Broad Strategy',
      data: (
        <Text ellipsis={{ tooltip: true }}>
          {join(mandate.strategyInterest, ', ')}
        </Text>
      ),
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        mandate.strategyInterest?.length > 0,
    },
    {
      title: 'Fund AUM Range',
      data: isNil(mandate.maxFundAum)
        ? `${statFormat.currency(mandate.minFundAum)} (min)`
        : `${statFormat.currency(
            mandate.minFundAum ?? 0
          )} - ${statFormat.currency(mandate.maxFundAum)}`,
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        (mandate.minFundAum > 0 || !isNil(mandate.maxFundAum)),
    },
    {
      title: 'Manager Track Record Range',
      data: isNil(mandate.managerMaxTrackRecord)
        ? `${mandate.managerMinTrackRecord} years (min)`
        : `${mandate.managerMinTrackRecord ?? 0} - ${
            mandate.managerMaxTrackRecord
          } years`,
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        (mandate.managerMinTrackRecord > 0 ||
          !isNil(mandate.managerMaxTrackRecord)),
    },
    {
      title: 'Target Return',
      data: mandate.targetReturn / 100.0,
      format: statFormat.percent,
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        !isNil(mandate.targetReturn),
    },
    {
      title: 'Benchmark',
      data: mandate.benchmark,
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        !isNil(mandate.benchmark),
    },
    {
      title: 'Attributes',
      data: (
        <Text ellipsis={{ tooltip: true }}>
          {join(mandate.attributes, ', ')}
        </Text>
      ),
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        mandate.attributes?.length > 0,
    },
    {
      title: 'Current Assets',
      data: mandate.currentAssets,
      format: statFormat.currency,
      isVisible: !isNil(mandate.currentAssets),
    },
    {
      title: 'Current Assets',
      data: mandate.currentAssetsRange,
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        !isNil(mandate.currentAssetsRange),
    },
  ]

  return take(filter(items, 'isVisible'), 8)
}

const DiscoverMandatesResult = ({
  mandate,
  selectedFund,
  selectedMandateId,
  onChangeSelectedMandateId,
  onAddMandateToList,
  fromPreview = false,
}) => {
  const { Track, trackEvent } = useTracking({ page: 'MandateCard' })

  const [isApplyToMandateModalVisible, setIsApplyToMandateModalVisible] =
    React.useState(false)
  const [appliedWithCampaign, setAppliedWithCampaign] = React.useState(null)
  const [applyingFundId, setApplyingFundId] = React.useState(selectedFund)

  const { discoverLists } = useDiscoverLists()

  const isProfileModalVisible = isNil(mandate)
    ? false
    : mandate.mandateId === selectedMandateId

  const hideProfileModal = React.useCallback(() => {
    onChangeSelectedMandateId(null)
  }, [onChangeSelectedMandateId])

  const showSentApplicationModal = React.useCallback(
    (applyingCampaignId, applyingCampaignName) => {
      hideProfileModal()
      const campaign = applyingCampaignId
        ? {
            id: applyingCampaignId,
            name: applyingCampaignName,
          }
        : null
      setAppliedWithCampaign(campaign)
    },
    [setAppliedWithCampaign, hideProfileModal]
  )

  const showApplyToMandateModal = React.useCallback(
    (fundId) => {
      trackEvent({
        eventName: 'click',
        element: 'Mandate open apply modal from profile',
      })
      setIsApplyToMandateModalVisible(true)
      if (fundId) {
        setApplyingFundId(fundId)
      }
    },
    [trackEvent]
  )

  const hideApplyToMandateModal = React.useCallback(() => {
    setIsApplyToMandateModalVisible(false)
    setApplyingFundId(selectedFund)
  }, [selectedFund])

  if (!mandate) {
    return null
  }

  return (
    <div id="fixed" className="discover-fund">
      <Track>
        <DiscoverResultCard
          className="discover-fund-result"
          href={`/mandate/${mandate.mandateId}`}
          onClick={() => {
            trackEvent({
              eventName: 'click',
              element: 'Mandate Open Profile',
            })
          }}
          header={
            <DiscoverMandateResultHeader
              mandate={mandate}
              discoverLists={discoverLists}
            />
          }
          body={
            <>
              <DiscoverCardStats items={getDetailItems(mandate)} />
              {!mandate.isAnonymous && (
                <div className="pt-5 text-xs">
                  <ContactList contacts={mandate.contacts} />
                </div>
              )}
            </>
          }
          footer={
            <DiscoverMandateResultFooter
              mandate={mandate}
              canApplyToMandate={mandate.canApply}
              onShowApplyToMandateModal={showApplyToMandateModal}
              onShowAddToListModal={() => onAddMandateToList([mandate])}
              fromPreview={fromPreview}
            />
          }
        />
      </Track>
      <MandateProfileModal
        mandateId={mandate.mandateId}
        visible={isProfileModalVisible}
        onCancel={hideProfileModal}
        hideActions={mandate.myMandate}
      />
      <ApplyToMandateModal
        visible={isApplyToMandateModalVisible}
        onClose={hideApplyToMandateModal}
        mandateId={mandate.mandateId}
        campaignId={mandate.campaignId}
        fundId={applyingFundId}
        mandate={mandate}
        onApply={showSentApplicationModal}
      />
      <SuccessfulApplicationModal
        visible={!!appliedWithCampaign}
        applyingCampaignId={appliedWithCampaign?.id}
        applyingCampaignName={appliedWithCampaign?.name}
        onClose={() => setAppliedWithCampaign(null)}
      />
    </div>
  )
}

DiscoverMandatesResult.propTypes = {
  mandate: PropTypes.object.isRequired,
  selectedFund: PropTypes.number,
  selectedMandateId: PropTypes.number,
  onChangeSelectedMandateId: PropTypes.func,
  onAddMandateToList: PropTypes.func.isRequired,
  fromPreview: PropTypes.bool,
}

export default DiscoverMandatesResult
