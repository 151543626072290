import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import { FilterAlt } from '@context365/icons'
import { Menu } from '@context365/menu'
import { InputNumber } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { numberFormat } from '~/utils/formatters'

const FORMATTERS = {
  percentage: {
    formatter: (value) => (isEmpty(value) ? '' : `${value}%`),
    parser: (value) => value.replace('%', ''),
  },
  currency: {
    formatter: (value) => numberFormat.currency(value),
  },
  default: {},
}

const RangeFilter = ({
  column: { filterValue, setFilter },
  minVal,
  maxVal,
  type = 'default',
}) => {
  const [min, setMin] = useState()
  const [max, setMax] = useState()

  useEffect(() => {
    if (!isNil(filterValue)) {
      const [minValue, maxValue] = filterValue
      setMin(minValue)
      setMax(maxValue)
    }
  }, [filterValue])

  return (
    <Menu
      trigger={
        <IconButton
          className="mr-2"
          size="small"
          status={isEmpty(filterValue) ? 'secondary' : 'default'}
          variant="link"
          label="Filter"
          icon={<FilterAlt />}
        />
      }
    >
      <div style={{ textAlign: 'right', padding: '10px' }}>
        Min:{' '}
        <InputNumber
          style={{ width: '75%' }}
          onChange={(val) => setMin(val)}
          value={min}
          size="small"
          min={minVal}
          {...FORMATTERS[type]}
        />
      </div>
      <div style={{ textAlign: 'right', padding: '10px' }}>
        Max:{' '}
        <InputNumber
          style={{ width: '75%' }}
          onChange={(val) => setMax(val)}
          value={max}
          max={maxVal}
          size="small"
          {...FORMATTERS[type]}
        />
      </div>
      <div className="flex justify-between" style={{ padding: '10px' }}>
        <Button
          onClick={() => setFilter([])}
          disabled={isNil(min) && isNil(max)}
          variant="link"
          status="error"
        >
          Reset
        </Button>
        <Button onClick={() => setFilter([min, max])} variant="filled">
          OK
        </Button>
      </div>
    </Menu>
  )
}

RangeFilter.propTypes = {
  column: PropTypes.object.isRequired,
  minVal: PropTypes.number,
  maxVal: PropTypes.number,
  type: PropTypes.string,
}

export default RangeFilter
