import PropTypes from 'prop-types'
import CompanyContact from './CompanyContact'

const CompanyContacts = ({ companies, fulWidth }) => (
  <div
    className="cc-meetingdetails-participants-container"
    style={{ width: '100%' }}
  >
    <div style={{ flex: 2 }}>
      {Array.isArray(companies) &&
        companies.length > 0 &&
        companies.map((company) => (
          <div key={company.companyId.toString()}>
            <span className="type-subtitle-sm text-header">
              {`Participants from ${company.companyName}`}
            </span>
            <CompanyContact
              contacts={company.participants}
              fulWidth={fulWidth}
            />
          </div>
        ))}
    </div>
  </div>
)

CompanyContacts.propTypes = {
  fulWidth: PropTypes.bool,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      companyId: PropTypes.number.isRequired,
      name: PropTypes.string,
      categoryName: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      companyEventId: PropTypes.number,
      companyDescription: PropTypes.string,
      participants: PropTypes.arrayOf(
        PropTypes.shape({
          contactId: PropTypes.number.isRequired,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          contactName: PropTypes.string.isRequired,
          imageUrl: PropTypes.string,
        }).isRequired
      ).isRequired,
    })
  ).isRequired,
}
export default CompanyContacts
