import { useCallback, useEffect, useState } from 'react'
import { InfoOutlined, LanguageOutlined } from '@context365/icons'
import { Col, Divider, Row, message } from 'antd'
import * as api from '~/api'
import Loading from '~/components/Loading'
import BoothSelectorContainer from './BoothSelectorContainer'
import EventListing from './EventListing'
import FeaturedEvent from './FeaturedEvent'

const Summits = () => {
  const [featuredEvents, setFeaturedEvents] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [showEditSeatModal, setShowEditSeatModal] = useState(false)
  const [selectedBooth, setSelectedBooth] = useState(false)
  const [editingBooths, setEditingBooth] = useState(false)
  const [loading, setLoading] = useState(false)

  const getEvents = useCallback(() => {
    api.events
      .getEvents()
      .then((resp) => {
        const { pastEvents, upcomingEvents } = resp.data.result

        const featured = upcomingEvents.filter((ev) => ev.isFeatured)

        setPastEvents(pastEvents)
        setUpcomingEvents(upcomingEvents)
        setFeaturedEvents(featured)
      })
      .catch(() => message.error('Could not fetch events'))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    setLoading(true)
    getEvents()
  }, [getEvents])

  const handleEditBooth = useCallback((booth) => {
    setSelectedBooth(booth)
    setShowEditSeatModal(true)
    setEditingBooth(true)
  }, [])

  const handleSelectBooth = useCallback((booth) => {
    setSelectedBooth(booth)
    setEditingBooth(false)
    setShowEditSeatModal(true)
  }, [])

  if (loading) {
    return (
      <Row align="middle" justify="center">
        <Loading />
      </Row>
    )
  }

  return (
    <>
      <div>
        <Row>
          {featuredEvents.map((e) => (
            <Col key={e.eventId} span={24}>
              <FeaturedEvent
                event={e}
                onEditBooth={handleEditBooth}
                onSelectBooth={handleSelectBooth}
                onGetEvents={getEvents}
              />
              <Divider />
            </Col>
          ))}
        </Row>
        <Row style={{ marginBottom: '20px' }}>
          <Col span={12}>
            <EventListing
              title="Upcoming Events"
              events={upcomingEvents}
              defaultFilter="All"
              filters={[
                { name: 'All' },
                { name: 'Next Month' },
                { name: 'Next Year' },
              ]}
              noEventsTitle="Check our website for upcoming events"
              noEventIcon={LanguageOutlined}
              onEditBooth={handleEditBooth}
              onSelectBooth={handleSelectBooth}
              onGetEvents={getEvents}
            />
          </Col>
          <Col span={12}>
            <EventListing
              title="Events You've Attended"
              events={pastEvents}
              arePastEvents={true}
              noEventsTitle="No event attended yet"
              noEventsDescription="All events that you attend will be listed here after the event finishes."
              noEventIcon={InfoOutlined}
            />
          </Col>
        </Row>
      </div>
      <BoothSelectorContainer
        visible={showEditSeatModal}
        setShowEditSeatModal={setShowEditSeatModal}
        eventId={selectedBooth.eventId}
        eventName={selectedBooth.eventName}
        eventCompanyId={selectedBooth.eventCompanyId}
        selectedBooth={selectedBooth.boothId}
        editingBooths={editingBooths}
        boothSelectionEnded={selectedBooth.boothSelectionEnded}
        getEvents={getEvents}
        eventCompanyBoothId={selectedBooth.eventCompanyBoothId}
      />
    </>
  )
}

export default Summits
