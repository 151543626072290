import { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Divider, Input, Modal, message } from 'antd'
import find from 'lodash/find'
import get from 'lodash/get'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import BoothSelectForMeetingRequest from '~/components/BoothSelectForMeetingRequest/BoothSelectForMeetingRequest'
import ContactName from '~/components/ContactName'
import FundSelectForMeetingRequest from '~/components/FundSelectForMeetingRequest'
import Loading from '~/components/Loading'
import { getUserCompanyContactId } from '~/selectors/auth'
import SummitAttendeesCtx from '~/utils/contexts/SummitAttendeesCtx'

const { TextArea } = Input

const getContextPointsCheck = (
  role,
  contextPointsAvailable,
  setSendContextPoints,
  sendContextPoints
) => (
  <div>
    {role.toLowerCase() === 'service provider' &&
      contextPointsAvailable >= 1000 && (
        <div style={{ marginTop: '20px' }}>
          <Checkbox
            onChange={(e) => {
              setSendContextPoints(e.target.checked)
            }}
            checked={sendContextPoints}
          >
            <span className="cc-body-text cc-light-black">Send </span>
            <span style={{ fontStyle: 'bold', fontWeight: '800' }}>
              1,000 Context Points{' '}
            </span>
            <span className="cc-body-text cc-light-black">
              with this request{' '}
            </span>
            <span className="cc-grey-text" style={{ fontStyle: 'italic' }}>
              (you have {contextPointsAvailable} points)
            </span>
          </Checkbox>
        </div>
      )}
  </div>
)

const getCreditBanner = (creditsAvailable, role) =>
  !isNil(creditsAvailable) &&
  role !== 'Allocator' && (
    <div className="cc-credit-wrapper" style={{ marginTop: '15px' }}>
      <span className="cc-credit-cost">
        This request will cost you 1 Credits
      </span>
      <span className="cc-credit-left">
        You have {creditsAvailable} Credits left.
      </span>
    </div>
  )

const SummitMeetingRequest = ({
  visible,
  fund,
  funds,
  company,
  onClose,
  onRefresh,
  eventId,
  creditsAvailable,
  contextPointsAvailable,
}) => {
  const role = useSelector((state) => state.auth.role)
  const companyContactId = useSelector((state) =>
    getUserCompanyContactId(state)
  )
  const { boothSP } = useContext(SummitAttendeesCtx)
  const [myColleagues, setMyColleagues] = useState([])
  const [receivingParticipants, setReceivingParticipants] = useState([])
  const [textMessage, setMessage] = useState('')
  const [sendContextPoints, setSendContextPoints] = useState(false)
  const [savingInProggress, setSavinginProggress] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedFundId, setSelectedFundId] = useState(
    isEmpty(funds) ? null : funds[0].fundId
  )
  const [eventCompanyBoothFundId, setEventCompanyBoothFundId] = useState(
    isEmpty(funds)
      ? company?.eventCompanyBoothFundId
      : isEmpty(funds?.find((f) => f.fundId === selectedFundId)?.booths)
      ? null
      : funds?.find((f) => f.fundId === selectedFundId).booths[0]
          .eventCompanyBoothFundId
  )

  const [eventCompanyBoothId, setEventCompanyBoothId] = useState()
  const [receivingEventCompanyBoothId, setReceivingEventCompanyBoothId] =
    useState(company.eventCompanyBoothId)

  const { Track, trackEvent } = useTracking({
    component: 'SummitMeetingRequest',
  })

  useEffect(() => {
    setReceivingEventCompanyBoothId(company.eventCompanyBoothId)
    switch (role) {
      case 'Manager': {
        const eventCompanyBoothFund = head(
          find(funds, { fundId: selectedFundId })?.booths
        )
        setEventCompanyBoothFundId(
          eventCompanyBoothFund?.eventCompanyBoothFundId
        )
        setEventCompanyBoothId(eventCompanyBoothFund?.eventCompanyBoothId)
        break
      }
      case 'Service Provider': {
        setEventCompanyBoothId(boothSP[0]?.eventCompanyBoothId)
        break
      }
      default: {
        setEventCompanyBoothId(null)
        break
      }
    }
    return () => {}
  }, [boothSP, company.eventCompanyBoothId, funds, role, selectedFundId])

  const handleOk = () => {
    trackEvent({ eventName: 'click', element: 'send meeting request' })
    setSavinginProggress(true)

    const toParticipants = map(receivingParticipants, (p) =>
      get(p, 'contactId')
    )
    const fundId = fund ? fund.fundId : selectedFundId

    return api.meeting
      .sendSummitMeetingRequest(
        company.companyId,
        fundId,
        myColleagues,
        toParticipants,
        textMessage,
        companyContactId,
        {
          eventId,
          sendContextPoints,
          eventCompanyBoothId,
          receivingEventCompanyBoothId,
        }
      )
      .then(() => {
        message.success('Request sent succesfully.')
        onClose()
        onRefresh()
      })
      .catch((err) => {
        const errorMessage =
          err?.response?.status === 400 ? err?.response?.data?.message : null
        message.error(errorMessage || 'Could not send meeting request.')
        onClose()
      })
      .finally(() => {
        setSavinginProggress(false)
      })
  }
  const getParticipants = useCallback(
    (
      companyId,
      fundId,
      eventId,
      contactId,
      toEventCompanyBoothId,
      toEventCompanyBoothFundId,
      eventCompanyBoothId,
      eventCompanyBoothFundId
    ) =>
      api.meeting.getSummitBoothsMeetingParticipants(
        companyId,
        eventId,
        contactId,
        toEventCompanyBoothId,
        toEventCompanyBoothFundId,
        eventCompanyBoothId,
        eventCompanyBoothFundId
      ),
    []
  )

  useEffect(() => {
    const fundId = fund ? fund.fundId : selectedFundId

    let toEventCompanyBoothFundId
    let sendingEventCompanyBoothFundId

    if (isEmpty(funds)) {
      toEventCompanyBoothFundId = eventCompanyBoothFundId
      sendingEventCompanyBoothFundId = null
    } else {
      toEventCompanyBoothFundId = null
      sendingEventCompanyBoothFundId = eventCompanyBoothFundId
    }

    if (fundId > 0 && !(toEventCompanyBoothFundId || eventCompanyBoothFundId)) {
      return
    }

    setLoading(true)
    getParticipants(
      company.companyId,
      fundId,
      eventId,
      company.contactId,
      receivingEventCompanyBoothId,
      toEventCompanyBoothFundId,
      eventCompanyBoothId,
      sendingEventCompanyBoothFundId
    )
      .then((response) => {
        const colleagues = map(
          response.data.result.find((x) => x.companyId !== company.companyId)
            .contacts,
          (c) => get(c, 'contactId')
        )
        const otherCompanyColleagues = response.data.result.find(
          (x) => x.companyId === company.companyId
        ).contacts

        setMyColleagues(colleagues)
        setReceivingParticipants(otherCompanyColleagues)
      })
      .catch(() => message.error('Could not retrieve colleagues'))
      .finally(() => {
        setLoading(false)
      })
  }, [
    company,
    eventCompanyBoothFundId,
    eventCompanyBoothId,
    eventId,
    fund,
    funds,
    getParticipants,
    receivingEventCompanyBoothId,
    selectedFundId,
  ])

  const footer = (
    <div className="footer">
      <Button
        onClick={() => {
          trackEvent({ eventName: 'click', element: 'close modal' })
          onClose()
        }}
      >
        Cancel
      </Button>
      <Button loading={savingInProggress} type="primary" onClick={handleOk}>
        Send Meeting Request
      </Button>
    </div>
  )

  return (
    <Track>
      <Modal
        destroyOnClose
        centered
        className="AllocatorOnlineMR"
        visible={visible}
        width="600px"
        title="Request a Meeting"
        footer={footer}
        onCancel={onClose}
        bodyStyle={{ padding: '0px' }}
      >
        <Loading spinning={loading}>
          <div style={{ padding: '16px 32px' }}>
            {role === 'Manager' && (
              <FundSelectForMeetingRequest
                textOnly={isEmpty(funds)}
                funds={funds}
                selectedFund={selectedFundId}
                onChangeFund={setSelectedFundId}
              />
            )}
            {
              <BoothSelectForMeetingRequest
                booths={
                  role === 'Manager'
                    ? funds?.find((f) => f.fundId === selectedFundId).booths
                    : role === 'Service Provider'
                    ? boothSP
                    : []
                }
                title={
                  role === 'Service Provider'
                    ? 'Booth where you want to meet'
                    : 'Booth that will participate in the meeting'
                }
                selectedBoothId={eventCompanyBoothId}
                onChangeBooth={(e) => {
                  if (!isEmpty(funds))
                    setEventCompanyBoothFundId(
                      funds
                        ?.find((f) => f.fundId === selectedFundId)
                        .booths?.find(
                          (f) => f.eventCompanyBoothId === e.target.value
                        )?.eventCompanyBoothFundId
                    )
                  setEventCompanyBoothId(e.target.value)
                }}
              />
            }
            <div style={{ marginBlock: '16px' }}>
              <div className="cc-heading4 cc-darkest-grey-text">
                {fund?.name ||
                  company?.name ||
                  company?.company?.name ||
                  company?.companyName}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBlock: '16px',
                }}
              >
                {receivingParticipants &&
                  map(receivingParticipants, (contact) => (
                    <ContactName
                      key={contact.contactId}
                      contactId={contact.contactId}
                      name={`${contact.firstName} ${contact.lastName}`}
                      imageUrl={contact.imageUrl}
                      position={contact.jobTitle}
                    />
                  ))}
              </div>
            </div>
            {getContextPointsCheck(
              role,
              contextPointsAvailable,
              setSendContextPoints,
              sendContextPoints
            )}
            <div style={{ width: '100%', marginTop: '24px' }}>
              <div className="cc-tabletitle-text cc-darkest-grey-text">
                Send Message
              </div>
              <Divider style={{ margin: '16px 0px' }} />
              <TextArea
                placeholder="Write a message..."
                rows={4}
                value={textMessage}
                onChange={({ target: { value } }) => {
                  setMessage(value)
                }}
              />
            </div>
          </div>
          {getCreditBanner(creditsAvailable, role)}
        </Loading>
      </Modal>
    </Track>
  )
}

SummitMeetingRequest.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onRefresh: PropTypes.func,
  fund: PropTypes.object,
  company: PropTypes.object.isRequired,
  eventId: PropTypes.number,
  creditsAvailable: PropTypes.number.isRequired,
  contextPointsAvailable: PropTypes.number,
  funds: PropTypes.array,
}

export default SummitMeetingRequest
