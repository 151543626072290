import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import { Popover } from '@context365/popovers'
import './TagGroup.less'

const TagGroup = ({ maxLength, items, color }) => {
  let clipButton = null

  let maxLen = items.length
  if (typeof maxLength !== 'undefined') maxLen = maxLength

  function getTagColor() {
    switch (color) {
      case 'yellow':
        return '#FEFFE6'
      case 'green':
        return '#F6FFED'
      case 'blue':
        return '#E6F7FF'
      case 'purple':
        return '#F9F0FF'
      default:
        return '#EEEEEE'
    }
  }

  if (items.length > maxLen) {
    const content = (
      <span className="-mx-1">
        {items.slice(maxLength - items.length).map((x) => (
          <Badge
            key={x}
            className="category-tag mx-1"
            style={{ backgroundColor: getTagColor() }}
          >
            {x}
          </Badge>
        ))}
      </span>
    )

    clipButton = (
      <Popover
        content={content}
        className="tag-overflow-popover bg-white p-2 rounded-sm shadow-6 max-w-sm"
      >
        <Badge style={{ backgroundColor: getTagColor() }} className="mx-1">
          <span>•••</span>
        </Badge>
      </Popover>
    )
  }

  return (
    <span className="-mx-1">
      {items.slice(0, maxLen).map(
        (item) =>
          item && (
            <Badge
              key={item}
              className="category-tag mx-1"
              style={{ backgroundColor: getTagColor() }}
            >
              {item}
            </Badge>
          )
      )}
      {clipButton}
    </span>
  )
}

TagGroup.propTypes = {
  maxLength: PropTypes.number,
  color: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
}

export default TagGroup
