import PropTypes from 'prop-types'
import cx from 'classnames'

export default function FilterControlLayout({
  children,
  className,
  filters,
  controls,
}) {
  return (
    <div
      data-layout="filter-control"
      className={cx(className, 'flex flex-col gap-4 lg:flex-row')}
    >
      <div className="lg:w-1/4 2xl:w-1/5">{filters}</div>
      <div className="border-b lg:hidden" />
      <div className="flex flex-col gap-2 lg:flex-1 lg:gap-4">
        {controls}
        {children}
      </div>
    </div>
  )
}

FilterControlLayout.propTypes = {
  children: PropTypes.node,
  filters: PropTypes.node,
  controls: PropTypes.node,
}
