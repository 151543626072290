import { useEffect, useState } from 'react'
import isNil from 'lodash/isNil'
import { useHistory, useParams } from 'react-router-dom'
import * as api from '~/api'
import CampaignStatusModal from '~/components/CampaignStatusModal'
import FundTypeStep from '~/components/FundWizard/FundTypeStep'
import {
  IlliquidFundWizard,
  LiquidFundWizard,
} from '~/components/FundWizard/New'
import { ILLIQUID_FUND_TYPE, LIQUID_FUND_TYPE } from '~/constants/funds'
import '~/components/FundWizard/FundWizard.less'

const FundWizard = () => {
  const [loading, setLoading] = useState(true)
  const [needsConfirmation, setNeedsConfirmation] = useState(false)
  const [showCampaignStatusModal, setShowCampaignStatusModal] = useState(false)
  const [campaignId, setCampaignId] = useState(null)

  const { fundId, step = '0' } = useParams()
  const [newFundTypeId, setNewFundTypeId] = useState()

  const currentStep = parseInt(step)

  const history = useHistory()

  useEffect(() => {
    if (isNil(fundId)) {
      setLoading(false)
    } else {
      api.funds
        .getFundType(fundId)
        .then((res) => {
          setNewFundTypeId(res.data.result.id)
        })
        .finally(() => setLoading(false))

      api.funds.getCampaign(fundId).then((res) => {
        setCampaignId(res.data.result)
      })
    }
  }, [fundId])

  const markAsConfirmed = () => setNeedsConfirmation(false)

  const markAsEdited = () => {
    if (!needsConfirmation) {
      setNeedsConfirmation(true)
    }
  }

  const onSave = () => {
    history.push(`/workspace/${campaignId}`)
  }

  return loading ? null : (
    <>
      <div>
        {isNil(newFundTypeId) && (
          <FundTypeStep
            onSave={(fundTypeId) => {
              setNewFundTypeId(fundTypeId)
              history.push(`/edit-fund/${fundId}/0`)
            }}
          />
        )}
        {newFundTypeId === ILLIQUID_FUND_TYPE && (
          <IlliquidFundWizard
            fundId={fundId}
            onSave={() =>
              campaignId ? setShowCampaignStatusModal(true) : onSave()
            }
            defaultStepNumber={currentStep}
            markAsEdited={markAsEdited}
            markAsConfirmed={markAsConfirmed}
            onSwitchFundType={() => setNewFundTypeId(null)}
          />
        )}
        {newFundTypeId === LIQUID_FUND_TYPE && (
          <LiquidFundWizard
            fundId={fundId}
            onSave={() =>
              campaignId ? setShowCampaignStatusModal(true) : onSave()
            }
            defaultStepNumber={currentStep}
            markAsEdited={markAsEdited}
            markAsConfirmed={markAsConfirmed}
            onSwitchFundType={() => setNewFundTypeId(null)}
          />
        )}
      </div>
      <CampaignStatusModal
        visible={showCampaignStatusModal}
        campaignId={campaignId}
        onSave={onSave}
      />
    </>
  )
}

export default FundWizard
