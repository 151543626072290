import PropTypes from 'prop-types'
import {
  Axis,
  AxisLabel,
  Chart,
  ChartTooltip,
  Grid,
  ScatterSeries,
  useAxis,
  useChart,
  useChartTooltipState,
  useScatterSeries,
} from '@context365/charts'
import { useTheme } from '@context365/config'
import { format } from 'd3-format'

const formatCurrency = format('$,.2~f')
const formatAUM = (aum) => `${formatCurrency(aum / 1e6)}M`

const formatPercent = format('.2~%')

const aumRange = [3, 25]
const aumDomain = [50e6, 5e9]

const FundReturnSpxChart = ({ fundReturnSpx, width }) => {
  const dataPointColor = useTheme('colors.gold.100')
  const axisTextColor = useTheme('colors.grey.500')
  const gridLineColor = useTheme('colors.grey.300')

  const chart = useChart({
    data: fundReturnSpx,
    height: 400,
    width,
    padding: {
      left: 64,
      right: 12,
      top: 12,
      bottom: 40,
    },
  })

  const indexReturn = useAxis({
    type: 'linear',
    getValue: (d) => d.x / 100,
    formatValue: formatPercent,
    range: chart.range.horizontal,
  })
  const fundReturn = useAxis({
    type: 'linear',
    getValue: (d) => d.y / 100,
    formatValue: formatPercent,
    range: chart.range.vertical,
  })
  const aum = useAxis({
    type: 'sqrt',
    clamp: true,
    getValue: (d) => d.aum,
    formatValue: formatAUM,
    range: aumRange,
    domain: aumDomain,
  })

  const scatter = useScatterSeries({
    chart,
    x: indexReturn,
    y: fundReturn,
    r: aum,
    getDataPointKey: (d) => d.date,
    series: {
      key: 'spx',
      getDataset: (d) => d.datasets[0].data,
      color: dataPointColor,
    },
  })

  const tooltip = useChartTooltipState(chart, scatter)

  if (!fundReturnSpx?.datasets) {
    return null
  }

  return (
    <div style={{ paddingTop: '40px' }}>
      <div className="ChartLabel">
        <span>Fund Returns vs SPX Returns</span>
      </div>
      <Chart {...chart.getChartProps()}>
        <Grid
          {...scatter.getGridProps('horizontal')}
          tickCount={8}
          color={gridLineColor}
        />
        <Grid
          {...scatter.getGridProps('horizontal')}
          tickValues={[0]}
          color={gridLineColor}
          lineWidth={2}
        />
        <Grid
          {...scatter.getGridProps('vertical')}
          tickValues={[0]}
          color={gridLineColor}
          lineWidth={2}
        />
        <Axis
          {...scatter.getAxisProps('left')}
          tickCount={8}
          lineColor="none"
          textColor={axisTextColor}
          fontSize={12}
        />
        <Axis
          {...scatter.getAxisProps('bottom')}
          tickCount={4}
          lineColor={gridLineColor}
          textColor={axisTextColor}
          fontSize={12}
          domainLineWidth={2}
          tickLengthOuter={0}
        />
        <AxisLabel chart={chart} position="left" className="text-grey-600">
          {fundReturnSpx.yAxisLabel}
        </AxisLabel>
        <AxisLabel chart={chart} position="bottom" className="text-grey-600">
          {fundReturnSpx.xAxisLabel}
        </AxisLabel>
        <ScatterSeries series={scatter.series} />
      </Chart>
      <Legend getRadius={aum.getValue} dataPointColor={dataPointColor} />
      <Tooltip
        formatAUM={aum.format}
        formatIndexReturn={indexReturn.format}
        formatFundReturn={fundReturn.format}
        dataPointColor={dataPointColor}
        {...tooltip}
      />
    </div>
  )
}

FundReturnSpxChart.propTypes = {
  fundReturnSpx: PropTypes.shape({
    xAxisLabel: PropTypes.string.isRequired,
    yAxisLabel: PropTypes.string.isRequired,
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            x: PropTypes.number.isRequired,
            y: PropTypes.number.isRequired,
            date: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  }),
  width: PropTypes.number,
}

export default FundReturnSpxChart

const legendItems = [
  [50e6, '$50M or less'],
  [100e6, '$100M'],
  [250e6, '$250M'],
  [500e6, '$500M'],
  [1e9, '$1B'],
  [2.5e9, '$2.5B'],
  [5e9, '$5B or more'],
]

function Legend({ getRadius, dataPointColor }) {
  return (
    <div className="flex space-x-8 justify-center items-center text-sm">
      <span>AUM:</span>
      {legendItems.map(([aum, label]) => {
        const radius = getRadius(aum)
        return (
          <div key={label} className="flex space-x-2 items-center">
            <div
              className="rounded-full"
              style={{
                backgroundColor: dataPointColor,
                height: radius * 2,
                width: radius * 2,
              }}
            />
            <span>{label}</span>
          </div>
        )
      })}
    </div>
  )
}

function Tooltip({
  formatAUM,
  formatFundReturn,
  formatIndexReturn,
  dataPointColor,
  ...tooltipProps
}) {
  return (
    <ChartTooltip {...tooltipProps} unstable_portal={false}>
      {(_, activeDataPoints) => (
        <div className="space-y-2">
          {activeDataPoints.map((d) => (
            <div key={d.key} className="flex space-x-2">
              <div
                className="h-2 w-2 rounded-full mt-1"
                style={{ backgroundColor: dataPointColor }}
              />
              <div>
                <ul className="list-none p-0 m-0">
                  <li>Fund: {formatFundReturn(d.dataPoint)}</li>
                  <li>SPX: {formatIndexReturn(d.dataPoint)}</li>
                  <li>AUM: {formatAUM(d.dataPoint)}</li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
    </ChartTooltip>
  )
}
