import { Collapse } from 'antd'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom-v5-compat'
import * as api from '~/api'
import Loading from '~/components/Loading'
import CategoryName from '../../components/CategoryName'
import { useCategoryColors } from '../utils'
import ManagerCategory from './ManagerCategory'
import './Managers.css'

const Managers = () => {
  const { companyId } = useParams()
  const { data: managerCategories, isLoading } = useQuery(
    ['allocator-managers', companyId],
    async () => {
      const managerCategories = await api.allocators.getAllocatorManagers(
        companyId
      )
      return orderBy(
        managerCategories.map((c) => ({
          categoryId: c.alternativeCategoryId,
          categoryName: c.alternativeCategoryName,
          ...c,
        })),
        'categoryId',
        'asc'
      )
    }
  )

  const colors = useCategoryColors(managerCategories)

  if (isLoading) {
    return (
      <div className="text-center my-16">
        <Loading />
      </div>
    )
  }

  return (
    <div>
      <h2 className="type-header-sm text-header mb-4">
        Managers and Funds by Asset CLass
      </h2>
      <div className="type-body-regular-sm mb-8">Dollars in millions</div>
      {map(managerCategories, (c) => (
        <Collapse className="allocator-managers mb-4">
          <Collapse.Panel
            key={c.categoryId}
            header={
              <CategoryName
                name={c.categoryName}
                backgroundColor={colors[c.categoryId].badgeBackground}
              />
            }
          >
            <ManagerCategory {...c} />
          </Collapse.Panel>
        </Collapse>
      ))}
    </div>
  )
}

export default Managers
