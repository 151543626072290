import PropTypes from 'prop-types'
import { Button } from '@context365/button'

export default function Header({
  fundCount,
  onCompareFunds,
  onRemoveAllFunds,
}) {
  return (
    <div className="p-4 pb-0 sm:p-6 sm:pb-0 flex flex-col sm:flex-row justify-between flex-shrink-0">
      <h2 className="type-header-xs">Funds to Compare</h2>
      <div className="flex justify-between sm:justify-end items-center space-x-3">
        <span className="type-body-semibold-sm">Count: {fundCount}</span>
        <span className="space-x-3">
          <Button disabled={fundCount === 0} onClick={onRemoveAllFunds}>
            Clear
          </Button>
          <Button
            disabled={fundCount === 0}
            variant="filled"
            onClick={onCompareFunds}
          >
            Compare Funds
          </Button>
        </span>
      </div>
    </div>
  )
}

Header.propTypes = {
  fundCount: PropTypes.number.isRequired,
  onCompareFunds: PropTypes.func.isRequired,
  onRemoveAllFunds: PropTypes.func.isRequired,
}
