import { Badge } from '@context365/badge'
import { Button } from '@context365/button'
import {
  CheckCircleOutlined,
  Flare,
  Grid4x4,
  SpaOutlined,
} from '@context365/icons'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { MarketingContactPage } from '~/config'
import { Enterprise, Free, Pro } from '~/constants/tiers'
import useTracking from '~/hooks/useTracking'
import { getTier } from '~/selectors/auth'

export default function Subscriptions() {
  const tier = useSelector(getTier)
  const { enterpriseTier, showPresentations } = useFlags()
  const { Track } = useTracking({ component: 'Subscriptions' })
  return (
    <Track>
      <div className="flex flex-col items-stretch flex-1 mt-4 mx-4 lg:flex-row gap-4">
        <Tier
          name={Free}
          topBand="bg-brand-100"
          headerColor="bg-brand-5"
          headerIcon={
            <Flare
              className="absolute top-0 -left-1 text-brand-100 opacity-10"
              size={128}
            />
          }
          color="text-brand-100"
          headline="Get started"
          features={[
            'Rich profile with data visualizations',
            'Limited community interaction',
            'View & accept connection requests',
            'Read content posted by other ApexInvest users',
            'Indicate interest in to up to 5 mandates per month',
            'Create unlimited pipelines',
            'Unlimited messages & meetings',
            'Weekly newsletter',
          ]}
          footer={tier === Free && <CurrentSubscription />}
          isSelected={tier === Free}
        />
        <Tier
          name={Pro}
          topBand="bg-secondary-100"
          headerColor="bg-secondary-5"
          headerIcon={
            <Grid4x4
              className="absolute top-0 -left-2 text-secondary-90 opacity-10 transform -rotate-12 z-0"
              size={128}
            />
          }
          pricing={
            <div className="flex flex-col">
              <div className="type-body-semibold-sm">$500 / MONTH</div>
              <div className="type-body-regular-xs">Billed Annually</div>
            </div>
          }
          color="text-secondary-100"
          headline={`Everything in ${Free} plus...`}
          features={
            showPresentations
              ? [
                  'Unlimited community interaction',
                  'Send connection requests',
                  'Post content',
                  'Apply to unlimited mandates',
                  'Basic analytics',
                  'View allocator profiles',
                  'Attend & host presentations',
                  'Filtering, keyword search, & search alerts',
                  'List creation & sharing',
                  'White glove concierge service',
                ]
              : [
                  'Unlimited community interaction',
                  'Send connection requests',
                  'Post content',
                  'Apply to unlimited mandates',
                  'Basic analytics',
                  'View allocator profiles',
                  'Filtering, keyword search, & search alerts',
                  'List creation & sharing',
                  'White glove concierge service',
                ]
          }
          footer={
            tier === Pro ? (
              <CurrentSubscription />
            ) : (
              <ActionButton text={`Upgrade to ${Pro}`} status="secondary" />
            )
          }
          isSelected={tier === Pro}
        />
        {enterpriseTier && (
          <Tier
            name={Enterprise}
            topBand="bg-primary-100"
            headerColor="bg-primary-5"
            headerIcon={
              <SpaOutlined
                className="absolute top-0 -left-2 text-primary-90 opacity-10 transform rotate-45"
                size={128}
              />
            }
            pricing={
              <div className="type-body-regular-sm">Contact us for pricing</div>
            }
            color="text-primary-100"
            headline={`Everything in ${Pro} plus...`}
            features={[
              'Advanced analytics',
              'Featured fund intro video',
              'Strategy interview',
              'Newsletter feature',
              'Priority access to events',
            ]}
            footer={
              tier === Enterprise ? (
                <CurrentSubscription />
              ) : (
                <ActionButton
                  text="Contact Us For Enterprise"
                  status="primary"
                />
              )
            }
            isSelected={tier === Enterprise}
          />
        )}
      </div>
    </Track>
  )
}

function Tier({
  name,
  topBand,
  headerColor,
  headerIcon,
  pricing,
  color,
  headline,
  features,
  footer,
  isSelected = false,
}) {
  return (
    <div className="w-96 pb-24 rounded bg-white border border-t-0 items-start relative">
      <div className={cx(topBand, 'h-4 rounded-t')} />
      <div className={cx(headerColor, 'h-28 p-4')}>
        <div className="flex flex-row gap-4 items-center justify-between">
          {headerIcon}
          <h3 className="type-header-md mb-0">{name}</h3>
          {isSelected && (
            <Badge size="small" status="warning">
              Current Subscription
            </Badge>
          )}
        </div>
        <div className="mt-2">{pricing}</div>
      </div>
      <div className="flex flex-col gap-6 p-4 h-full">
        {headline && (
          <div className={cx(color, 'type-header-xs text-center')}>
            {headline}
          </div>
        )}
        {features.map((text, i) => (
          <div key={i} className="flex flex-row items-start">
            <CheckCircleOutlined className={cx(color, 'mr-2 flex-shrink-0')} />
            {text}
          </div>
        ))}
        <div className="text-center absolute bottom-0 inset-x-4 mb-4">
          {footer}
        </div>
      </div>
    </div>
  )
}

function CurrentSubscription() {
  return (
    <Badge status="warning" className="mb-3">
      Current Subscription
    </Badge>
  )
}

function ActionButton({ text, status }) {
  const { trackEvent } = useTracking({ element: 'ActionButton' })
  return (
    <Button
      className="w-full h-12"
      variant="filled"
      status={status}
      as={Link}
      to={{ pathname: MarketingContactPage }}
      target="_blank"
      onClick={() => trackEvent.click({ text })}
    >
      {text}
    </Button>
  )
}
