import PropTypes from 'prop-types'
import SetMeetingTimeModal from './SetMeetingTimeModal'

const RescheduleOnlineMeeting = ({
  onlineMeetingId,
  meetingDateTime,
  visible,
  closeModal,
  onRescheduleMeeting,
}) => {
  return (
    <SetMeetingTimeModal
      visible={visible}
      meetingId={onlineMeetingId}
      onSubmit={() => {
        onRescheduleMeeting()
        closeModal()
      }}
      onClose={() => closeModal()}
      previousDateTime={meetingDateTime}
    />
  )
}

RescheduleOnlineMeeting.propTypes = {
  visible: PropTypes.bool.isRequired,
  onlineMeetingId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  onRescheduleMeeting: PropTypes.func.isRequired,
  meetingDateTime: PropTypes.string,
}

export default RescheduleOnlineMeeting
