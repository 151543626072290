import { Button } from '@context365/button'
import { ArrowBack } from '@context365/icons'
import { useHistory, useRouteMatch } from 'react-router-dom'
import MeetingDetailsController from '~/components/MeetingDetails'

const MeetingDetails = () => {
  const {
    params: { meetingType = 'summit', meetingId },
  } = useRouteMatch()

  const history = useHistory()

  return (
    <>
      {history.length > 1 && (
        <div className="mb-2">
          <Button
            variant="outlined"
            iconLeft={<ArrowBack />}
            onClick={() => history.goBack()}
          >
            Go Back
          </Button>
        </div>
      )}
      <div className="p-8 bg-white">
        <MeetingDetailsController
          meetingId={meetingId}
          meetingType={meetingType}
        />
      </div>
    </>
  )
}

export default MeetingDetails
