import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import { Popover, Tag } from 'antd'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import moment from 'moment'
import { formatCurrency } from '~/utils/helpers'

export const NUMBER_FIELDS = ['sharpe', 'sortino', 'durationOfCapitalLockup']

export const CURRENCY_FIELDS = [
  'firmAum',
  'minimumFundSizeInterest',
  'maximumFundSizeInterest',
  'minimumFirmSizeInterest',
  'maximumFirmSizeInterest',
  'averageInvestmentSize',
  'minimumInvestmentSize',
  'fundAum',
  'minimumInvestment',
]

export const PERCENTAGE_FIELDS = [
  'MATCHMAKING_SCORE',
  'fund.annualizedReturn',
  'fund.annualVolatility',
  'managementFee',
  'performanceFee',
  'currentYtdReturn',
  'previousYtdReturn',
  'lastYtdReturn',
  'annualVolatility',
  'annualizedReturn',
  'matchmakingScore',
  'deepestDrawdown',
]

export const LIST_FIELDS = [
  'meetingStatus',
  'fundAttributes',
  'domicile',
  'auditor',
  'fundAdministrator',
  'externalLegalCounsel',
  'discoverLists',
  'geographicConcentration',
  'sectorConcentration',
  'investmentVehicle',
  'netDollarExposureInterest',
  'fundDomicileInterest',
  'secondaryInvestorCategory',
  'investmentDecisionProcessInterest',
  'sectorsInterest',
  'geographicConcentrationInterest',
  'capitalLockUpPreference',
  'liquidBroadStrategyInterest',
  'liquidSubStrategyInterest',
  'illiquidBroadStrategyInterest',
  'illiquidSubStrategyInterest',
]

export const BOOLEAN_FILTERS = ['firstLossCapital']

export const MEETING_STATUS_LIST = [
  { value: 1, text: 'Pending' },
  { value: 2, text: 'Confirmed' },
  { value: 3, text: 'Declined' },
  { value: 4, text: 'Cancelled' },
]

const formatNumberRange = (values, type) => {
  const formatter =
    type === 'percentage'
      ? (x) => `${x} %`
      : type === 'currency'
      ? formatCurrency
      : (x) => x

  if (values[0] && isNil(values[1])) {
    return `>= ${formatter(values[0])}`
  } else if (isNil(values[0]) && !isNil(values[1])) {
    return `<= ${formatter(values[1])}`
  } else {
    return `${formatter(values[0])} - ${formatter(values[1])}`
  }
}

const FiltersView = ({
  filterKey,
  filterTitle,
  values,
  onRemoveFilter,
  onRemoveMeetingStatusFilters,
  onRemoveNumberFilters,
  isNumber = false,
  isMeetingStatus = false,
  isDateTime = false,
  dateTimeFormat,
}) => {
  const valuesPopupContent =
    values.length > 3 && filterKey.toLowerCase() !== 'meetingstatusid'
      ? map(values, (v) => (
          <Tag
            key={v}
            className="bg-primary-2 border-none"
            closable
            onClose={(e) => {
              e.preventDefault()
              onRemoveFilter(filterKey, v)
            }}
          >
            <span>{v}</span>
          </Tag>
        ))
      : null

  return (
    <span>
      <Tag>
        {isNil(filterTitle)
          ? filterKey.toLowerCase() === 'meetingstatusid'
            ? 'Meetings'
            : startCase(filterKey)
          : startCase(filterTitle)}
        :
      </Tag>
      {filterKey.toLowerCase() === 'meetingstatusid' || isMeetingStatus ? (
        map(
          filter(MEETING_STATUS_LIST, (m) => includes(values, m.value)),
          (m) => (
            <Tag
              key={m.value}
              className="bg-primary-2 border-none"
              closable
              onClose={(e) => {
                e.preventDefault()
                onRemoveMeetingStatusFilters(filterKey, m.value)
              }}
            >
              <span>{m.text}</span>
            </Tag>
          )
        )
      ) : includes(NUMBER_FIELDS, filterKey) ||
        includes(PERCENTAGE_FIELDS, filterKey) ||
        includes(CURRENCY_FIELDS, filterKey) ||
        isNumber ||
        isDateTime ? (
        <Tag
          style={{
            border: 'none',
            backgroundColor: 'rgba(47, 197, 210, 0.1)',
          }}
          closable
          onClose={(e) => {
            e.preventDefault()
            onRemoveNumberFilters(filterKey)
          }}
        >
          <span>
            {!isNumber &&
              !isDateTime &&
              (includes(PERCENTAGE_FIELDS, filterKey)
                ? formatNumberRange(values, 'percentage')
                : includes(CURRENCY_FIELDS, filterKey)
                ? formatNumberRange(values, 'currency')
                : formatNumberRange(values))}

            {isNumber &&
              (includes(PERCENTAGE_FIELDS, filterKey)
                ? formatNumberRange(values, 'percentage')
                : includes(CURRENCY_FIELDS, filterKey)
                ? formatNumberRange(values, 'currency')
                : formatNumberRange(values))}

            {isDateTime &&
              `${moment(values[0]).format(
                dateTimeFormat || 'DD/MM/YYYY'
              )} - ${moment(values[1]).format(dateTimeFormat || 'DD/MM/YYYY')}`}
          </span>
        </Tag>
      ) : (
        map(values.slice(0, 3), (v) => (
          <Tag
            key={v}
            className="bg-primary-2 border-none"
            closable
            onClose={(e) => {
              e.preventDefault()
              onRemoveFilter(filterKey, v)
            }}
          >
            <span>{v}</span>
          </Tag>
        ))
      )}
      {!isNil(valuesPopupContent) &&
        filterKey.toLowerCase() !== 'meetingstatusid' && (
          <Popover content={valuesPopupContent}>
            <Badge className="mr-2 bg-primary-2">
              <span>•••</span>
            </Badge>
          </Popover>
        )}
    </span>
  )
}

FiltersView.propTypes = {
  filterKey: PropTypes.string,
  filterTitle: PropTypes.string,
  values: PropTypes.array.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  onRemoveMeetingStatusFilters: PropTypes.func.isRequired,
  onRemoveNumberFilters: PropTypes.func.isRequired,
  isNumber: PropTypes.bool,
  isMeetingStatus: PropTypes.bool,
  isDateTime: PropTypes.bool,
  dateTimeFormat: PropTypes.string,
}

export default FiltersView
