import { Button } from '@context365/button'
import { RemoveCircleOutline } from '@context365/icons'
import { InputField, SelectField } from '~/components/Form'
import { useOnboardingContext } from '../OnboardingContext'

export default function PartnerField({ name, index, removeItem }) {
  const { options } = useOnboardingContext()
  return (
    <div>
      <div className="flex justify-between">
        <div>{`Partner #${index + 1}`}</div>
        <Button
          variant="outlined"
          status="error"
          iconLeft={<RemoveCircleOutline />}
          onClick={() => removeItem()}
        >
          Remove Partner
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <InputField name={`${name}.name`} label="Person or Firm" />
        <SelectField
          name={`${name}.investorCategoryId`}
          label="Investor Category"
          options={options.investorCategories}
        />
      </div>
    </div>
  )
}
