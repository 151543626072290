import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import { useTracking } from 'react-tracking'
import * as api from '~/api'

const FundSubscriptionModal = ({
  visible,
  fundId,
  email,
  onCancel,
  onSubscribe,
}) => {
  const { trackEvent } = useTracking({ component: 'FundSubscriptionModal' })

  const queryClient = useQueryClient()

  const { mutate: subscribe } = useMutation(
    () => api.funds.subscribeToFund(fundId),
    {
      onSuccess() {
        message.success(
          "You have successfully subscribed to this fund's distribution list."
        )
        queryClient.setQueriesData(['discover', 'funds'], (data) => ({
          ...data,
          result: data.result.map((fund) =>
            fund.fundId === fundId ? { ...fund, alreadySubscribed: true } : fund
          ),
        }))
        onSubscribe()
      },
    }
  )

  return (
    <Modal
      title="Confirm Distribution List Subscription"
      visible={visible}
      okText="Subscribe to Distribution List"
      onCancel={onCancel}
      onOk={() => {
        trackEvent({
          eventName: 'click',
          element: 'Confirm subscribe to fund',
          fundId,
        })
        subscribe()
      }}
    >
      <div>
        By subscribing to this fund&apos;s distribution list, your email address{' '}
        <strong>{email}</strong> will be shared with the fund manager
      </div>
    </Modal>
  )
}

FundSubscriptionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  fundId: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubscribe: PropTypes.func.isRequired,
}

export default FundSubscriptionModal
