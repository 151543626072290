import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Image, Modal, message } from 'antd'
import filter from 'lodash/filter'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import last from 'lodash/last'
import Clamp from 'react-multiline-clamp'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { logProfileView } from '~/actions/company'
import * as api from '~/api'
import { useDiscoverLists } from '~/hooks/discoverLists'
import ComposeMessageModal from '../ComposeMessageModal'
import ContactList from '../ContactList'
import ContextSignalsContainer from '../ContextSignalsContainer'
import { DiscoverBadges, DiscoverResultCard } from '../Discover'
import DiscoverServiceProviderDetailsModal from '../DiscoverServiceProviderDetailsModal/DiscoverServiceProviderDetailsModal'
import DiscoverServiceProviderMeetingRequest from '../DiscoverServiceProviderMeetingRequest'
import MatchmakingScore from '../MatchmakingScore'
import MeetingBadge from '../MeetingBadge'
import MeetingRequestCfn from '../MeetingRequestCFN'
import DiscoverServiceProviderResultFooter from './DiscoverServiceProviderResultFooter'
import './DiscoverServiceProviderResult.less'

const getSelectedLists = (discoverLists, companyId) =>
  filter(discoverLists, (list) =>
    find(list.discoverListLinks, ['toCompanyId', companyId])
  )

const DiscoverServiceProviderResult = ({
  serviceProvider,
  selectedFund,
  representedFund,
  showSPProfile = false,
  onClose,
  onToggleHidden,
  selectedCompanyId,
  onChangeSelectedCompanyId,
  onAddServiceProviderToList,
}) => {
  const { Track } = useTracking({
    component: 'DiscoverServiceProviderResult',
  })

  const discoverModalVisible = isNil(serviceProvider)
    ? false
    : selectedCompanyId === serviceProvider.companyId
  const [isRequestMeetingModalVisible, setIsRequestMeetingModalVisible] =
    useState(false)
  const [composeModalVisible, setComposeModalVisible] = useState(false)
  const composeModalClosedHandler = () => {
    setComposeModalVisible(false)
  }

  const detailModalClosedHandler = () => {
    onChangeSelectedCompanyId(null)
    if (!isNil(onClose)) onClose()
  }

  const { role } = useSelector((state) => state.auth)

  const { discoverLists } = useDiscoverLists()

  const validateMeeting = useCallback(() => {
    api.meeting
      .checkCanSendMeetingRequest({
        toCompanyId: serviceProvider.companyId,
        fundId: selectedFund,
      })
      .then((validationResponse) => {
        if (validationResponse.isValid) {
          setIsRequestMeetingModalVisible(true)
        } else {
          message.error(validationResponse.reason)
        }
      })
  }, [selectedFund, serviceProvider.companyId])

  if (!serviceProvider) {
    return null
  }

  const detailsModalVisible = discoverModalVisible

  return (
    <div
      id="fixed"
      className="discover-service-provider"
      style={{ display: showSPProfile ? 'none' : 'block' }}
    >
      {detailsModalVisible && (
        <Modal
          visible={detailsModalVisible}
          footer={null}
          onCancel={detailModalClosedHandler}
          width="900px"
          bodyStyle={{ padding: 0 }}
        >
          <DiscoverServiceProviderDetailsModal
            onSendRequest={validateMeeting}
            onSendMessage={() => setComposeModalVisible(true)}
            serviceProvider={serviceProvider}
            canSendMeetingRequest={true}
            companyId={serviceProvider.companyId}
            visible={detailsModalVisible}
            onCancel={detailModalClosedHandler}
            selectedFundId={role === 'Manager' ? selectedFund : 0}
          />
        </Modal>
      )}
      {!showSPProfile && (
        <Track>
          <DiscoverResultCard
            className="discover-service-provider-result"
            onClick={() => {
              onChangeSelectedCompanyId(serviceProvider.companyId)
              const profile = {
                companyId: serviceProvider.companyId,
                fundId: serviceProvider.fundId,
              }
              logProfileView(profile)
            }}
            header={
              <div className="flex justify-between">
                <div>
                  <DiscoverBadges
                    badges={getSelectedLists(
                      discoverLists,
                      serviceProvider.companyId
                    )}
                  />
                  <div className="type-body-regular-md">
                    {serviceProvider.companyName}
                    <MeetingBadge meeting={last(serviceProvider.meetings)} />
                  </div>
                  <div className="type-body-regular-xs text-left pt-1 text-grey-500">
                    {serviceProvider.serviceProviderCategory}
                  </div>
                </div>
                <div>
                  <div className="discover-service-provider-menu flex">
                    <ContextSignalsContainer company={serviceProvider} />
                    {role === 'Manager' && (
                      <MatchmakingScore
                        score={serviceProvider.matchmakingScore}
                        threshold={serviceProvider.matchmakingThreshold}
                      />
                    )}
                  </div>
                </div>
              </div>
            }
            body={
              <div className="pt-4 flex justify-between">
                <div className="w-2/3">
                  <div className="discover-service-provider-description">
                    <div className="type-body-regular-xs text-primary-100 mb-1.5 text-left">
                      Company Description
                    </div>
                    <div className="type-body-regular-xs text-justify break-words">
                      {serviceProvider.companyDescription && (
                        <Clamp lines={4}>
                          {serviceProvider.companyDescription}
                        </Clamp>
                      )}
                    </div>
                  </div>
                  <div className="pt-6">
                    <ContactList contacts={serviceProvider.contacts} />
                  </div>
                </div>
                <div className="discover-service-provider-text-image">
                  <Image src={serviceProvider.imageUrl} preview={false} />
                </div>
              </div>
            }
            footer={
              <DiscoverServiceProviderResultFooter
                serviceProvider={serviceProvider}
                onToggleHidden={onToggleHidden}
                onSendMessage={() => setComposeModalVisible(true)}
                onSendMeetingRequest={validateMeeting}
                onShowAddToListModal={() =>
                  onAddServiceProviderToList([serviceProvider])
                }
              />
            }
          />
        </Track>
      )}
      <ComposeMessageModal
        visible={composeModalVisible}
        onClose={composeModalClosedHandler}
        companyID={serviceProvider.companyId}
      />
      {isRequestMeetingModalVisible &&
        (role === 'CFN' ? (
          <MeetingRequestCfn
            visible={isRequestMeetingModalVisible}
            onClose={() => setIsRequestMeetingModalVisible(false)}
            onRefresh={detailModalClosedHandler}
            cfnCompany={serviceProvider}
          />
        ) : (
          <DiscoverServiceProviderMeetingRequest
            visible={isRequestMeetingModalVisible}
            onClose={() => setIsRequestMeetingModalVisible(false)}
            serviceProvider={serviceProvider}
            onRefresh={detailModalClosedHandler}
            selectedFund={selectedFund}
            representedFund={representedFund}
            fromMapView
          />
        ))}
    </div>
  )
}

DiscoverServiceProviderResult.propTypes = {
  serviceProvider: PropTypes.object.isRequired,
  selectedFund: PropTypes.number,
  representedFund: PropTypes.string,
  showSPProfile: PropTypes.bool,
  onClose: PropTypes.func,
  onToggleHidden: PropTypes.func,
  selectedCompanyId: PropTypes.number,
  onChangeSelectedCompanyId: PropTypes.func,
  onAddServiceProviderToList: PropTypes.func.isRequired,
}

export default DiscoverServiceProviderResult
