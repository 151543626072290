import { useCallback, useEffect } from 'react'
import { Badge } from '@context365/badge'
import { IconButton } from '@context365/button'
import { NotificationsOutlined } from '@context365/icons'
import { Popover } from '@context365/popovers'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { loadNotificationsAndStats } from '~/actions/notifications'
import NotificationList from '~/components/NotificationList'
import { getNotificationsList } from '~/selectors/notifications'

const NotificationListContainer = () => {
  const unread = useSelector((state) => state.notifications.unread)
  const dispatch = useDispatch()

  const loadNotifications = useCallback(
    (force = false) => dispatch(loadNotificationsAndStats(force)),
    [dispatch]
  )
  const notifications = useSelector(getNotificationsList)

  useEffect(() => loadNotifications(), [loadNotifications])

  return (
    <Popover
      interactive
      content={
        <NotificationList
          notifications={notifications}
          onItemClick={() => {}}
        />
      }
    >
      <div className="relative h-6">
        <IconButton
          variant="none"
          icon={<NotificationsOutlined className="text-primary-100" />}
          label="notifications"
          as={Link}
          to="/notifications"
        />
        {unread > 0 && (
          <div className="absolute -top-3 -right-3 pointer-events-none">
            <Badge status="error" size="small">
              {unread}
            </Badge>
          </div>
        )}
      </div>
    </Popover>
  )
}

export default NotificationListContainer
