import * as React from 'react'
import PropTypes from 'prop-types'
import { Col, Divider, Row } from 'antd'
import isNil from 'lodash/isNil'
import moment from 'moment'
import numeral from 'numeral'
import { numberFormat } from '~/utils/formatters'
import './FundDetailsProfile.less'

function addLineBreaks(text) {
  if (!text) {
    return ''
  }

  const lines = text.split('\n')
  return lines.map((line, i) => (
    <React.Fragment key={i}>
      {line}
      <br />
    </React.Fragment>
  ))
}

const FundDetailsProfile = ({ fundProfile }) => {
  return (
    <div>
      <Row>
        <Col span={11}>
          <span className="type-subtitle-sm text-header">
            Assets Under Management
          </span>
          <Row>
            <Col span={10}>
              <div className="fundAUMandPerformance-numbers">
                <div className="cc-text-color-summit cc-heading3">
                  {numeral(fundProfile?.fundAum || 0).format('($0 a)')}
                </div>
                <span className="cc-darkest-grey-text cc-xsmall-font">USD</span>
              </div>
            </Col>
            <Col span={14}>
              {fundProfile.fundInceptionDate && (
                <div className="fundAUMandPerformance-numbers">
                  <div className="cc-text-color-summit cc-heading3">
                    {moment(
                      fundProfile.fundInceptionDate,
                      moment.ISO_8601
                    ).format('MMM DD YYYY')}
                  </div>
                  <span className="cc-darkest-grey-text cc-xsmall-font">
                    Inception
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={2}>
          <Divider type="vertical" style={{ height: '68px' }} />
        </Col>
        {!fundProfile.narrative && (
          <Col span={11}>
            <span className="type-subtitle-sm text-header">Performance</span>
            <Row>
              <Col span={8}>
                {!isNil(fundProfile.annualizedReturn) && (
                  <>
                    <div className="cc-text-color-summit cc-heading3">
                      {numeral(fundProfile?.annualizedReturn || 0).format(
                        '0.0 %'
                      )}
                    </div>
                    <span className="cc-darkest-grey-text cc-xsmall-font">
                      Ann. Return
                    </span>
                  </>
                )}
              </Col>
              <Col span={8}>
                {!isNil(fundProfile.annualVolatility) && (
                  <>
                    <div className="cc-text-color-summit cc-heading3">
                      {numeral(fundProfile?.annualVolatility || 0).format(
                        '0.0 %'
                      )}
                    </div>
                    <span className="cc-darkest-grey-text cc-xsmall-font">
                      Ann. Volatility
                    </span>
                  </>
                )}
              </Col>
              <Col span={8}>
                {!isNil(fundProfile.ytdreturn) && (
                  <>
                    <div className="cc-text-color-summit cc-heading3">
                      {numberFormat.decimal(fundProfile.ytdreturn ?? 0, {
                        precision: 2,
                        suffix: '%',
                      })}
                    </div>
                    <span className="cc-darkest-grey-text cc-xsmall-font">
                      YTD Return
                    </span>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Divider className="discover-fund-details-profile-divider" />
      <div className="discover-fund-details-profile-container">
        <div className="discover-fund-details-profile-section">
          <div className="type-subtitle-sm text-header">
            Company Description
          </div>
          <p className="fund-details-section-content">
            {addLineBreaks(fundProfile.companyDescription)}
          </p>
        </div>
      </div>
      {!isNil(fundProfile.tradingMethod) && (
        <>
          <Divider className="discover-fund-details-profile-divider" />
          <div className="discover-fund-details-profile-container">
            <div className="discover-fund-details-profile-section">
              <div className="type-subtitle-sm text-header">
                Fund Strategy &amp; Description
              </div>
              <p className="fund-details-section-content">
                {addLineBreaks(fundProfile.tradingMethod)}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

FundDetailsProfile.propTypes = {
  fundProfile: PropTypes.object,
}

export default FundDetailsProfile
