import * as React from 'react'
import PropTypes from 'prop-types'
import uniqueId from 'lodash/uniqueId'

const ChartIndicator = ({ color, dashed }) => {
  const [id] = React.useState(uniqueId)

  return (
    <div className="text-center">
      <svg width={12} height={12} viewBox="0 0 11 9">
        {dashed && (
          <mask id={`stripes${id}`}>
            {/* fill with white to make everything visible by default */}
            <rect x={0} y={0} width={11} height={9} fill="white" />

            {/* black stripes create transparent areas in the masked shape */}
            <rect
              x={3}
              y={-10}
              height={30}
              width={2}
              transform="rotate(30)"
              fill="black"
            />
            <rect
              x={8}
              y={-10}
              height={30}
              width={2}
              transform="rotate(30)"
              fill="black"
            />
          </mask>
        )}

        <path
          mask={dashed ? `url(#stripes${id})` : undefined}
          d="M8.534.49c-.21-.297-.56-.49-.95-.49L1.166.006C.525.006 0 .525 0 1.166V7c0 .642.525 1.16 1.167 1.16l6.416.007c.391 0 .741-.193.951-.49l2.31-3.255a.578.578 0 0 0 0-.677L8.534.49Z"
          fill={color}
        />
      </svg>
    </div>
  )
}

ChartIndicator.propTypes = {
  color: PropTypes.string.isRequired,
  dashed: PropTypes.bool.isRequired,
}

export default ChartIndicator
