import {
  LOADING_USERS_ROADSHOWS,
  LOADING_USERS_ROADSHOWS_ERROR,
  LOADING_USERS_ROADSHOWS_SUCCESS,
} from '~/constants/types/roadshow'

const initialState = {
  roadshowList: [],
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_USERS_ROADSHOWS:
      return { ...state, loading: true }
    case LOADING_USERS_ROADSHOWS_SUCCESS:
      return { ...state, loading: false, roadshowList: action.payload }
    case LOADING_USERS_ROADSHOWS_ERROR:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}
