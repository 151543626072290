import { api as http } from '~/api/services'

export const getSearchAlerts = (category) =>
  http.get(`/discover/alert/${category}`)

export const getSearchAlertById = (alertId) =>
  http.get(`/discover/alertEntry/${alertId}`)

export const saveSearchAlert = (alertFilterRequest) =>
  http.post('/discover/alert', alertFilterRequest)

export const editSearchAlert = (alertFilterRequest, alertId) =>
  http.patch(`/discover/alert/${alertId}`, alertFilterRequest)

export const deleteSearchAlert = (alertId) =>
  http.delete(`/discover/alert/${alertId}`)

export const getCompanyFunds = (companyId) =>
  http.get(`/companies/${companyId}/funds`)

export const getContextSignals = (category) =>
  http.get(`/discover/${category}/signals`)
