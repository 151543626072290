import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import {
  CheckBoxOutlined,
  ExpandLess,
  ExpandMore,
  UnfoldMore,
} from '@context365/icons'
import { Menu } from '@context365/menu'
import { Drawer } from 'antd'
import cx from 'classnames'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import {
  useColumnOrder,
  useFilters,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useTracking } from 'react-tracking'
import ColumnsPreferences from '~/components/ColumnsPreferences'
import IndeterminateCheckbox from '~/components/DiscoverTable/IndeterminateCheckbox'
import Paginator from '~/components/Paginator'
import SummitTableHeader from './SummitTableHeader'
import './SummitAttendeeTable.less'

const SummitAttendeeTable = ({
  columns,
  data,
  loadPage,
  currentPage,
  total,
  bulkActions = null,
  tableTitle = null,
  useColumnCustomization = false,
  staticColumns = [],
  pageSize,
  onTableChange,
  onSearch,
}) => {
  const { Track } = useTracking({
    component: 'SummitAttendeeTable',
    tableTitle,
  })

  const [columnDrawerVisible, setColumnDrawerVisible] = React.useState(false)

  const storageKey = `Summits_${tableTitle}_Columns`
  let columnPreferences
  let hiddenColumns
  let columnOrder
  try {
    columnPreferences =
      JSON.parse(localStorage.getItem(storageKey)) ||
      forEach(columns, (c) => (c.checked = true))
    hiddenColumns = map(
      filter(columnPreferences, (x) => !x.checked),
      'id'
    )
    columnOrder = map(staticColumns, 'id').concat(map(columnPreferences, 'id'))
  } catch {
    columnPreferences = forEach(columns, (c) => (c.checked = true))
    hiddenColumns = []
    columnOrder = []
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state,
    setFilter,
    setAllFilters,
    setColumnOrder,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns, columnOrder },
      manualSortBy: true,
      manualFilters: true,
      autoResetSortBy: false,
      autoResetFilters: false,
    },
    useColumnOrder,
    useFilters,
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (bulkActions && bulkActions.length > 0) {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ])
      }
    }
  )

  const updateColumnPreferences = (cols) => {
    const orderedCols = map(staticColumns, 'id').concat(map(cols, 'id'))
    const hiddenCols = map(
      filter(cols, (c) => !c.checked),
      'id'
    )
    setColumnOrder(orderedCols)
    setHiddenColumns(hiddenCols)
    localStorage.setItem(storageKey, JSON.stringify(cols))
  }

  React.useEffect(() => {
    const sortOrder = isEmpty(state.sortBy)
      ? []
      : {
          order: state.sortBy[0].desc ? 'descend' : 'ascend',
          field: state.sortBy[0].id,
        }
    const filterObj = {}
    forEach(state.filters, (x) => {
      filterObj[x.id] = x.value
    })

    onTableChange({ current: 1, pageSize }, filterObj, sortOrder)
  }, [state.sortBy, state.filters])

  return (
    <Track>
      <SummitTableHeader
        filterArray={state.filters}
        setFilter={setFilter}
        setAllFilters={setAllFilters}
        handleSearch={onSearch}
        title={tableTitle}
      />
      <div
        className={cx(
          'SummitAttendeeTable text-xs text-left p-1 pl-4 width-100 flex justify-between items-center',
          selectedFlatRows.length > 0 && 'bg-purple-5'
        )}
      >
        <div className="justify-start">
          {selectedFlatRows.length > 0
            ? `${selectedFlatRows.length} selected`
            : ''}
        </div>
        <div className="justify-end">
          {useColumnCustomization && (
            <Button
              className="mr-2"
              size="small"
              onClick={() => setColumnDrawerVisible(true)}
              iconLeft={<CheckBoxOutlined />}
            >
              Customize Columns
            </Button>
          )}
          <Drawer
            visible={columnDrawerVisible}
            placement="right"
            closable="true"
            onClose={() => {
              setColumnDrawerVisible(false)
            }}
            title="Customize Columns"
            width="390"
            bodyStyle={{ paddingLeft: '8px', paddingRight: '8px' }}
          >
            <ColumnsPreferences
              columns={columnPreferences}
              onColumnsRearranged={updateColumnPreferences}
              onCancel={() => setColumnDrawerVisible(false)}
            />
          </Drawer>
          {bulkActions && (
            <Menu
              trigger={
                <Button
                  className="action-menu-button"
                  size="small"
                  iconRight={<ExpandMore />}
                  disabled={selectedFlatRows.length < 1}
                >
                  Actions
                </Button>
              }
            >
              {bulkActions.map((action) => (
                <Menu.Item
                  key={action.title}
                  onClick={() => action.onClick(selectedFlatRows)}
                  disabled={action.isDisabled(selectedFlatRows)}
                >
                  <div className="flex text-md">
                    {action.icon}
                    {action.title}
                  </div>
                </Menu.Item>
              ))}
            </Menu>
          )}
        </div>
      </div>

      <div className="overflow-auto">
        <table
          className="SummitAttendeeTable w-full border border-grey-300 border-solid text-left leading-10 min-w-1500"
          {...getTableProps()}
        >
          <thead className="border-b border-grey-300">
            {headerGroups.map((headerGroup, hgIndex) => (
              <tr
                className="bg-white"
                {...headerGroup.getHeaderGroupProps()}
                key={hgIndex}
              >
                {headerGroup.headers.map((column, cIndex) => (
                  <th
                    className="capitalize px-4 whitespace-nowrap"
                    key={cIndex}
                  >
                    <div className="SummitAttendeeTable-header">
                      {column.render('Header')}
                      {column.canSort &&
                      column.isSorted &&
                      column.isSortedDesc ? (
                        <ExpandMore
                          className="text-primary-100"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        />
                      ) : column.isSorted ? (
                        <ExpandLess
                          className="text-primary-100"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        />
                      ) : (
                        column.canSort && (
                          <UnfoldMore
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          />
                        )
                      )}
                      {column.canFilter && !isNil(column.Filter)
                        ? column.render('Filter')
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <tr
                  className={
                    selectedFlatRows.includes(row)
                      ? 'bg-purple-5'
                      : 'even:bg-white'
                  }
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className="px-4 whitespace-nowrap"
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {loadPage && (
        <Paginator
          className="mt-8"
          currentPage={currentPage}
          total={total}
          loadPage={loadPage}
          pageSize={pageSize}
        />
      )}
    </Track>
  )
}

SummitAttendeeTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  currentPage: PropTypes.number,
  total: PropTypes.number,
  loadPage: PropTypes.func,
  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  tableTitle: PropTypes.string,
  useColumnCustomization: PropTypes.bool,
  staticColumns: PropTypes.arrayOf(PropTypes.string),
  pageSize: PropTypes.number,
  onTableChange: PropTypes.func.isRequired,
  filters: PropTypes.array,
  onSearch: PropTypes.func.isRequired,
}

export default SummitAttendeeTable
