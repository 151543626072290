import * as React from 'react'
import partition from 'lodash/partition'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom-v5-compat'
import * as api from '~/api'

export function useCompaniesToOnboard() {
  const client = useQueryClient()

  const query = useQuery(
    ['companiesToOnboard'],
    () => api.onboarding.getCompaniesToOnboard(),
    {
      refetchOnWindowFocus: false,
      select: (data) => {
        const [cfnCompanies, nonCfnCompanies] = partition(
          data,
          (company) => company.companyCategory === 'CFN'
        )
        return { cfnCompanies, nonCfnCompanies }
      },
    }
  )

  return {
    ...query,
    refreshCompanies: React.useCallback(() => {
      client.invalidateQueries(['companiesToOnboard'])
    }, [client]),
  }
}

export function useOnboardingOptions() {
  return useQuery(['onboarding-options'], () => api.onboarding.getOptions(), {
    // We'll assume these options don't change frequently, so we only need to
    // fetch them once.
    cacheTime: Infinity,
    staleTime: Infinity,
  })
}

export function useOnboardingQuery(queryKey, { getAnswers, saveAnswers }) {
  const { companyId } = useParams()
  const client = useQueryClient()
  const key = [...arrify(queryKey), companyId]

  const { data: answers, status: answersStatus } = useQuery(
    key,
    () => getAnswers(companyId),
    {
      // answers are unlikely to change unless we submit
      refetchOnWindowFocus: false,
    }
  )

  const { mutateAsync, status: saveStatus } = useMutation(
    (answers) => saveAnswers(companyId, answers),
    {
      onSuccess: () => {
        client.invalidateQueries(key)
      },
    }
  )

  return {
    answers,
    isLoading: answersStatus === 'loading',
    isError: answersStatus === 'error',
    isSuccess: answersStatus === 'success',
    submit: mutateAsync,
    isSubmitting: saveStatus === 'loading',
  }
}

function arrify(value) {
  return Array.isArray(value) ? value : [value]
}
