import PropTypes from 'prop-types'
import { Axis, Chart, Geom, Tooltip } from 'bizcharts'
import numeral from 'numeral'

const FundReturnDistributionChart = ({
  fundReturnDistribution = {},
  width,
}) => {
  const getDistributionCols = (data) => ({
    x: { alias: data.xAxisLabel },
    y: {
      alias: data.yAxisLabel,
      formatter: (val) => numeral(val).format('0,0[.]00'),
    },
  })

  if (!fundReturnDistribution.datasets) {
    return null
  }

  return (
    <div style={{ paddingTop: '40px' }}>
      <div className="ChartLabel">
        <span>Distribution of Returns</span>
      </div>
      <Chart
        data={fundReturnDistribution.datasets[0].data}
        height={400}
        width={width}
        scale={getDistributionCols(fundReturnDistribution)}
        forceFit
      >
        <Axis
          name="x"
          label={{ rotate: 120, style: { textAlign: 'start' } }}
          title={{ offset: 70 }}
        />
        <Axis name="y" title />
        <Tooltip
          showTitle={false}
          itemTpl='<li data-index={index} style="margin-bottom:4px;"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{value}</li>'
        />
        <Geom
          type="interval"
          position="x*y"
          color="#2fc5d2"
          tooltip={[
            'x*y',
            (returns, frequency) => ({
              value: frequency,
            }),
          ]}
        />
      </Chart>
    </div>
  )
}

FundReturnDistributionChart.propTypes = {
  fundReturnDistribution: PropTypes.object,
  width: PropTypes.number,
}

export default FundReturnDistributionChart
