import * as React from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import { Input } from '@context365/forms'
import { Add, CheckBox, CheckBoxOutlineBlank } from '@context365/icons'
import cx from 'classnames'
import { useDiscoverLists } from '~/hooks/discoverLists'

export default function AddToListForm({
  targetNames,
  isListSelected,
  isTogglingListDisabled,
  onToggleList,
  onCreateList,
  onDismiss,
}) {
  const { discoverLists } = useDiscoverLists()
  const [createNewList, setCreateNewList] = React.useState(false)
  const [newListName, setNewListName] = React.useState('')
  const [validationError, setValidationError] = React.useState(null)

  function showCreateListForm() {
    setCreateNewList(true)
  }

  function dismissCreateListForm() {
    setCreateNewList(false)
    setNewListName('')
  }

  function validateListName(name) {
    if (!name) {
      return 'List name is required.'
    }
    if (discoverLists.some((list) => list.name === name)) {
      return 'List names must be unique.'
    }
    return null
  }

  function submit() {
    if (createNewList) {
      const validationError = validateListName(newListName)
      if (validationError) {
        setValidationError(validationError)
      } else {
        onCreateList(newListName).then(() => onDismiss())
      }
    } else {
      onDismiss()
    }
  }

  return (
    <>
      <div className="type-body-semibold-md py-5 px-6 border-b">Lists</div>
      <div className="py-4 px-6">
        <AllLists
          targetNames={targetNames}
          lists={discoverLists}
          isListSelected={isListSelected}
          isListDisabled={isTogglingListDisabled}
          onToggleList={onToggleList}
        />
        {createNewList ? (
          <CreateList
            validationError={validationError}
            listName={newListName}
            onChange={setNewListName}
            onHide={dismissCreateListForm}
          />
        ) : (
          <Button
            variant="link"
            onClick={showCreateListForm}
            iconLeft={<Add />}
          >
            Add to New List
          </Button>
        )}
      </div>
      <div className="flex justify-end p-4 shadow-0">
        <Button variant="filled" onClick={submit}>
          Done
        </Button>
      </div>
    </>
  )
}

AddToListForm.propTypes = {
  targetNames: PropTypes.string.isRequired,
  isListSelected: PropTypes.func.isRequired,
  isTogglingListDisabled: PropTypes.func.isRequired,
  onToggleList: PropTypes.func.isRequired,
  onCreateList: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
}

function AllLists({
  targetNames,
  lists,
  isListSelected,
  isListDisabled,
  onToggleList,
}) {
  return (
    <>
      <div className="type-body-semibold-md">
        Which lists should {targetNames} be on?
      </div>
      <ul className="list-none p-0 pt-6 type-body-regular-md">
        {lists.map((list) => {
          const isChecked = isListSelected(list)
          const isToggleDisabled = isListDisabled(list, isChecked)
          return (
            <List
              key={list.discoverListId}
              name={list.name}
              isChecked={isChecked}
              isToggleDisabled={isToggleDisabled}
              onClick={() => onToggleList(list.discoverListId)}
            />
          )
        })}
      </ul>
    </>
  )
}

function List({ name, isChecked, isToggleDisabled, onClick }) {
  const [showAsChecked, setShowAsChecked] = React.useState(isChecked)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    // whenever the server updates, get back in sync
    setShowAsChecked(isChecked)
  }, [isChecked])

  const toggle = () => {
    setShowAsChecked((showAsChecked) => !showAsChecked)
    setLoading(true)
    onClick().finally(() => setLoading(false))
  }

  const disabled = isToggleDisabled || loading

  return (
    <Button
      className={cx(
        'py-3 px-2 flex items-center justify-between even:bg-grey-100',
        disabled
          ? 'cursor-default opacity-60'
          : 'cursor-pointer hover:bg-grey-200'
      )}
      as="li"
      variant="none"
      disabled={disabled}
      aria-label={
        showAsChecked ? `Remove from ${name} list` : `Add to ${name} list`
      }
      onClick={toggle}
    >
      <span>{name}</span>
      {showAsChecked ? (
        <CheckBox className="text-primary-100" />
      ) : (
        <CheckBoxOutlineBlank />
      )}
    </Button>
  )
}

function CreateList({ validationError, listName, onChange, onHide }) {
  return (
    <div className="px-2 pb-6 pr-0.5">
      <div className="type-body-semibold-md mb-3">Create New List</div>
      <div className="flex justify-between items-end">
        <Input
          label="List Name"
          required
          placeholder="List"
          errorMessage={validationError}
          value={listName}
          onChange={(e) => onChange(e.target.value)}
        />
        <IconButton
          variant="link"
          label="Cancel"
          icon={<CheckBox />}
          onClick={onHide}
        />
      </div>
    </div>
  )
}
