import { message } from 'antd'
import jwtDecode from 'jwt-decode'
import filter from 'lodash/filter'
import first from 'lodash/first'
import isNil from 'lodash/isNil'
import { api as http } from '~/api/services'
import { PLATFORM, StorageKeys } from '~/config'
import { RESET_APP } from '~/constants/types'
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SWITCH_COMPANY,
  UPDATE_AGREEMENT_USER,
  UPDATE_IS_CFN_ALLOCATOR,
  UPDATE_IS_CFN_INTERESTED_IN_DEALS,
  UPDATE_IS_CFN_NOT_INTERESTED_IN_DEALS,
  UPDATE_ONBOARDED_USER,
  UPDATE_USER,
} from '~/constants/types/auth'

export const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: user,
})

export const signInUser = ({
  accessToken,
  refreshToken,
  contact,
  claims,
  company,
  role,
}) => ({
  type: SIGNIN_USER,
  payload: { accessToken, refreshToken, contact, claims, company, role },
})

export const signOutUser = () => ({
  type: SIGNOUT_USER,
  payload: {
    accessToken: null,
    refreshToken: null,
    contact: null,
    claims: null,
    company: null,
    role: null,
  },
})

export const updateOnboarded = () => ({
  type: UPDATE_ONBOARDED_USER,
})

export const updateAgreement = () => ({
  type: UPDATE_AGREEMENT_USER,
})

export const updateIsCFNAllocator = () => ({
  type: UPDATE_IS_CFN_ALLOCATOR,
})

export const updateIsCFNInterestedInDeals = () => ({
  type: UPDATE_IS_CFN_INTERESTED_IN_DEALS,
})

export const updateIsCFNNotInterestedInDeals = () => ({
  type: UPDATE_IS_CFN_NOT_INTERESTED_IN_DEALS,
})

export const contactLogin = (contactLogStatusId, isMobile) =>
  http.post('/auth/logcontact', { contactLogStatusId, isMobile })

export const reset = () => ({
  type: RESET_APP,
})

export const switchCompany = ({
  accessToken,
  claims,
  contact,
  company,
  role,
}) => ({
  type: SWITCH_COMPANY,
  payload: { accessToken, claims, contact, company, role },
})

export const login =
  (contactId, token, isZendesk, companyId) => async (dispatch) => {
    const response = await http.post(
      isZendesk ? '/auth/zendesk' : '/auth/login',
      { contactId, token, companyId }
    )
    const { accessToken, refreshToken, contact } = response.data.result
    const claims = jwtDecode(accessToken)

    const company = first(
      filter(
        contact.companies,
        (company) => company.companyId === parseInt(claims.company_id, 10)
      )
    )
    const role = company.category.categoryName
    localStorage.setItem(StorageKeys.logout, 'false')

    return dispatch(
      signInUser({
        accessToken,
        refreshToken,
        contact,
        claims,
        company,
        role,
      })
    )
  }

export const zendeskSso = async () => {
  const response = await http.post('/auth/zendesksso', {})
  return response.data.message
}

export const logout = () => (dispatch) => {
  http
    .post('/auth/logout', {
      platform: PLATFORM,
    })
    // .then(response => {
    //   const { message: msg } = response.data;
    //   message.info(msg);
    // })
    .catch((err) => {
      if (err.response) {
        const { message: msg } = err.response.data
        message.error(msg)
      }
    })
    .finally(() => {
      dispatch(reset())
      localStorage.setItem(StorageKeys.logout, 'true')
    })
}

export const changeCompany =
  (companyId, token = null) =>
  async (dispatch) => {
    const requestConfig = isNil(token)
      ? {}
      : { headers: { Authorization: `Bearer ${token}` } }

    const response = await http.post(
      '/auth/switch',
      {
        companyId,
      },
      requestConfig
    )

    const { accessToken, contact } = response.data.result
    const claims = jwtDecode(accessToken)

    const company = first(
      filter(
        contact.companies,
        (company) => company.companyId === parseInt(claims.company_id, 10)
      )
    )
    const role = company.category.categoryName

    return dispatch(
      switchCompany({
        accessToken,
        claims,
        contact,
        company,
        role,
      })
    )
  }
