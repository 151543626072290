import filter from 'lodash/filter'
import { createSelector } from 'reselect'

const cancellationReasons = (state) => state.meetings.cancellationReasons

export const selectSummitCancellationReasons = createSelector(
  cancellationReasons,
  (cancellationReasons) =>
    filter(cancellationReasons, (r) => r.meetingTypeId === 1)
)

export const selectOnlineCancellationReasons = createSelector(
  cancellationReasons,
  (cancellationReasons) =>
    filter(cancellationReasons, (r) => r.meetingTypeId === 3)
)
