import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import values from 'lodash/values'
import { createSelector } from 'reselect'

const notificationsById = (state) => state.notifications.notificationsById

const pagination = (state) => state.notifications.pagination

const paginatedNotificationsById = (state) =>
  state.notifications.paginatedNotificationsById

export const getNotificationsList = createSelector(notificationsById, (byId) =>
  sortBy(values(byId), (n) => -n.notificationLogId)
)

export const getNotificationCount = createSelector(pagination, (pagination) =>
  get(pagination, 'itemCount', 0)
)

export const getPaginatedNotificationsList = createSelector(
  paginatedNotificationsById,
  (byId) => sortBy(values(byId), (n) => -n.notificationLogId)
)
