import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import {
  Popover,
  PopoverArrow,
  PopoverDisclosure,
  usePopoverState,
} from 'reakit/Popover'
import './PopConfirm.css'

export default function PopConfirm({
  children,
  message,
  confirmText = 'Yes',
  cancelText = 'Cancel',
  onConfirm,
  ...props
}) {
  const popover = usePopoverState(props)

  return (
    <>
      <PopoverDisclosure {...popover} ref={children.ref} {...children.props}>
        {(disclosureProps) => React.cloneElement(children, disclosureProps)}
      </PopoverDisclosure>
      <Popover
        {...popover}
        className="pop-confirm"
        aria-label={getLabel(children)}
      >
        <PopoverArrow {...popover} className="pop-confirm__arrow" size={24} />
        <div>
          <div className="pop-confirm__message">{message}</div>
          <div className="pop-confirm__actions">
            <Button
              status="secondary"
              size="small"
              onClick={() => popover.hide()}
            >
              {cancelText}
            </Button>
            <Button
              variant="filled"
              status="error"
              size="small"
              onClick={() => {
                onConfirm()
                popover.hide()
              }}
            >
              {confirmText}
            </Button>
          </div>
        </div>
      </Popover>
    </>
  )
}

PopConfirm.propTypes = {
  children: PropTypes.node.isRequired,
  message: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
}

function getLabel(children) {
  if (!React.isValidElement(children)) {
    return undefined
  }

  return children.props.children ?? children.props.label
}
