import PropTypes from 'prop-types'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import AttachFunds from './AttachFunds'

const SchedulerNotOpen = ({
  schedulerAccessDate,
  noAccessForManagers,
  eventId,
  companyEventId,
  onRefresh,
}) => {
  const text = noAccessForManagers ? (
    <>
      <div>You do not have any funds tied to this event.</div>
      <div>Please select fund(s) to attach.</div>
    </>
  ) : moment(schedulerAccessDate).isValid() ? (
    `Summit's Schedule will open on ${moment
      .utc(schedulerAccessDate)
      .local()
      .format('MMMM DD, YYYY')}`
  ) : (
    "Summit's Schedule is not opened yet."
  )

  return (
    <div
      className="cc-container"
      style={{
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'column',
        width: '40%',
      }}
    >
      <div style={{ textAlign: 'center', margin: '40px 24px' }}>
        <FontAwesomeIcon
          className="cc-text-color-summit"
          size="4x"
          icon={faInfoCircle}
        />
      </div>
      <div
        style={{ textAlign: 'center' }}
        className="cc-heading5 cc-lightblack-text"
      >
        {text}
      </div>
      <div
        style={{ textAlign: 'center', marginTop: '16px', marginBottom: '40px' }}
        className="cc-body-text cc-dark-grey-text"
      >
        {noAccessForManagers ? (
          <AttachFunds
            eventId={eventId}
            companyEventId={companyEventId}
            onRefresh={onRefresh}
          />
        ) : (
          'You will be able to view attendees and send meeting requests once the schedule is opened'
        )}
      </div>
    </div>
  )
}

SchedulerNotOpen.propTypes = {
  schedulerAccessDate: PropTypes.instanceOf(Date),
  noAccessForManagers: PropTypes.bool.isRequired,
  eventId: PropTypes.number.isRequired,
  companyEventId: PropTypes.number.isRequired,
  onRefresh: PropTypes.func.isRequired,
}

export default SchedulerNotOpen
