import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import './DiscoverResultCard.css'

const DiscoverResultCard = ({
  className,
  href,
  header,
  body,
  footer,
  onClick,
}) => {
  const asLink = Boolean(href)
  const as = asLink ? Link : 'div'

  const self = React.useRef()

  function isNestedClickableObject(element) {
    if (element === self.current) {
      return false
    }

    return (
      ['BUTTON', 'INPUT', 'A'].includes(element.tagName) ||
      isNestedClickableObject(element.parentElement)
    )
  }

  return (
    <Button
      as={as}
      ref={self}
      className={cx(
        'group',
        'discover-result-card',
        (onClick || asLink) && 'cursor-pointer',
        'text-left',
        className
      )}
      variant="none"
      to={href}
      onClick={(event, ...args) => {
        if (isNestedClickableObject(event.target)) {
          if (asLink) {
            event.preventDefault()
          }
          return
        }
        onClick?.(event, ...args)
      }}
    >
      {header && <div className="discover-result-card__header">{header}</div>}
      <div className="discover-result-card__body">{body}</div>
      {footer && <div className="discover-result-card__footer">{footer}</div>}
    </Button>
  )
}

DiscoverResultCard.propTypes = {
  href: PropTypes.string,
  header: PropTypes.node,
  body: PropTypes.node.isRequired,
  footer: PropTypes.node,
  onClick: PropTypes.func,
}

export default DiscoverResultCard
