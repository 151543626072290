const CompanyCategories = {
  Manager: 1,
  Allocator: 2,
  ServiceProvider: 3,
  Context: 4,
  Media: 5,
  CFN: 6,
}

export default CompanyCategories
