import { useState } from 'react'
import { Radio, RadioGroup, TimePicker, Toggle } from '@context365/forms'
import { DatePicker, Modal } from 'antd'
import { getHours, getMinutes, getTime } from 'date-fns'
import moment from 'moment'
import { rescheduleOnlineMeeting } from '~/actions/contextMeetings'

const SetMeetingTimeModal = ({
  visible,
  meetingId,
  onSubmit,
  onClose,
  previousDateTime = null,
}) => {
  const [selectedDate, setSelectedDate] = useState(moment())
  const [selectedTime, setSelectedTime] = useState()
  const [duration, setDuration] = useState('30')
  const [format24hour, setFormat24Hour] = useState(false)

  const selectedTimeParsed = getTime(new Date(selectedTime))
  const meetingDateTime =
    selectedDate &&
    selectedTime &&
    selectedDate.set({
      hour: getHours(selectedTimeParsed),
      minute: getMinutes(selectedTimeParsed),
      second: 0,
    })

  const currentTimezone = new Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.replace('_', ' ')

  const isRescheduling = previousDateTime !== null

  return (
    <Modal
      width={500}
      title={`${isRescheduling ? 'Reschedule' : 'Schedule'} meeting`}
      visible={visible}
      onOk={() => {
        rescheduleOnlineMeeting(
          meetingId,
          meetingDateTime,
          duration,
          !isRescheduling
        )
          .then(() => {
            setSelectedDate(moment())
            setSelectedTime(null)
          })
          .finally(() => {
            onSubmit()
          })
      }}
      okText="Submit"
      okButtonProps={{
        disabled: !meetingDateTime || meetingDateTime <= moment(),
      }}
      cancelText="Cancel"
      onCancel={() => {
        setSelectedDate(moment())
        setSelectedTime(null)
        onClose()
      }}
    >
      <div className="flex-col space-y-8 items-center">
        <div>
          <div className="type-body-regular-sm mb-2">Meeting date</div>
          <DatePicker
            className="w-full"
            id="datePicker"
            value={selectedDate}
            disabledDate={(current) => current < moment().subtract(1, 'days')}
            onChange={(value) => setSelectedDate(value)}
          />
        </div>
        <div>
          <div className="w-full flex justify-between items-center">
            <div className="type-body-regular-sm">Start time</div>
            <Toggle
              checked={format24hour}
              onChange={(e) => setFormat24Hour(e.target.checked)}
              label="24 hour format"
              size="small"
              className=" mb-2"
            />
          </div>
          <TimePicker
            className="w-full"
            onChange={(value) => setSelectedTime(value)}
            value={selectedTime}
            twelveHour={!format24hour}
          />

          <div className="type-body-regular-xs">
            Use current timezone: {currentTimezone}
          </div>
        </div>
        <div>
          <div className="type-body-regular-sm mb-2">Meeting length</div>
          <RadioGroup
            name="duration"
            className="flex space-x-4"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          >
            <Radio value="30">30 minutes</Radio>
            <Radio value="60">1 hour</Radio>
          </RadioGroup>
        </div>
        {selectedDate && selectedTime && (
          <div className="type-subtitle-xs">{`Meeting will be ${
            isRescheduling ? 'moved to' : 'scheduled for'
          } ${meetingDateTime.format(
            `MMMM DD, YYYY ${format24hour ? 'HH:mm' : 'hh:mmA'}`
          )}`}</div>
        )}
      </div>
    </Modal>
  )
}

export default SetMeetingTimeModal
