import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { api as http } from '~/api/services'

const AllocatorInfoDetails = ({ companyId }) => {
  const [investorsDetails, setInvestorsDetails] = useState({})

  useEffect(() => {
    http
      .get(`/investors/${companyId}/details`)
      .then((response) => setInvestorsDetails(response.data.result))
  }, [companyId])

  const {
    secondaryInvestorCategory,
    investmentDecisionProcessInterest,
    illiquidBroadStrategyInterest,
    netDollarExposureInterest,
    liquidBroadStrategyInterest,
  } = investorsDetails
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '18px',
        marginTop: '32px',
        flexWrap: 'wrap',
      }}
    >
      <div style={{ width: '50%', marginRight: '70px' }}>
        {!isNil(secondaryInvestorCategory) && (
          <div className="cc-container-compdetails-list">
            <span className="cc-text-color-summit cc-tabletitle-text">
              Investor Category:
            </span>
            <Divider
              className="cc-divider-companydetails"
              style={{ backgroundColor: '#2FC5D2' }}
            />
            <ul className="cc-ul-comp-details">
              {secondaryInvestorCategory.split(',').map((item, index) => (
                <li className="cc-ul-comp-details-items" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        )}
        {!isEmpty(investmentDecisionProcessInterest) && (
          <div className="cc-container-compdetails-list">
            <span className="cc-text-color-online cc-tabletitle-text">
              INVESTMENT DECISION PROCESS:
            </span>
            <Divider
              className="cc-divider-companydetails"
              style={{ backgroundColor: '#6A48C6' }}
            />
            <ul className="cc-ul-comp-details">
              {investmentDecisionProcessInterest.map((item, index) => (
                <li className="cc-ul-comp-details-items" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        )}
        {!isEmpty(illiquidBroadStrategyInterest) && (
          <div className="cc-container-compdetails-list">
            <span className="cc-text-color-summit cc-tabletitle-text">
              ILLIQUID STRATEGY INTERESTS:
            </span>
            <Divider
              className="cc-divider-companydetails"
              style={{ backgroundColor: '#2FC5D2' }}
            />
            <ul className="cc-ul-comp-details">
              {illiquidBroadStrategyInterest.map((item, index) => (
                <li className="cc-ul-comp-details-items" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div style={{ width: '50%', marginRight: '150px' }}>
        {!isEmpty(netDollarExposureInterest) && (
          <div className="cc-container-compdetails-list">
            <span className="cc-text-color-secondary cc-tabletitle-text">
              NET DOLLAR EXPOSURE:
            </span>
            <Divider
              className="cc-divider-companydetails"
              style={{ backgroundColor: '#2D81A8' }}
            />
            <ul className="cc-ul-comp-details">
              {netDollarExposureInterest.map((item, index) => (
                <li className="cc-ul-comp-details-items" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        )}
        {!isEmpty(liquidBroadStrategyInterest) && (
          <div className="cc-container-compdetails-list">
            <span className="cc-text-color-roadshow cc-tabletitle-text">
              LIQUID STRATEGY INTERESTS:
            </span>
            <Divider
              className="cc-divider-companydetails"
              style={{ backgroundColor: '#962C6A' }}
            />
            <ul className="cc-ul-comp-details">
              {liquidBroadStrategyInterest.map((item, index) => (
                <li className="cc-ul-comp-details-items" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

AllocatorInfoDetails.propTypes = {
  companyId: PropTypes.number.isRequired,
}

export default AllocatorInfoDetails
