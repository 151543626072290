import { useEffect } from 'react'
import { Button } from '@context365/button'
import { useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { updateAgreement } from '~/actions/auth'
import * as api from '~/api'
import loginLogo from '~/assets/new-logo.svg'
import PDFViewer from '~/components/PDFViewer/PDFViewer'
import POLICIES from '~/constants/policies'
import useAuth from '~/hooks/useAuth'
import './SubscriptionAgreement.less'

const SubscriptionAgreement = () => {
  const history = useHistory()
  const store = useStore()
  const { contact } = useAuth()

  const handleAgree = () => {
    api.contacts.acceptSubscriptionAgreement().then(() => {
      store.dispatch(updateAgreement())
    })
  }

  useEffect(() => {
    if (contact.subscriptionAgreementApproved) {
      history.replace('/')
    }
  }, [contact.subscriptionAgreementApproved])

  return (
    <div style={{ padding: '32px' }}>
      <div style={{ textAlign: 'center', paddingBottom: '12px' }}>
        <img src={loginLogo} alt="" style={{ paddingBottom: '12px' }} />
        <div style={{ fontWeight: 'bold' }}>
          Please review and accept the following agreement to continue:
        </div>
      </div>
      <div>
        <PDFViewer
          name="Subscription Agreement"
          fileUrl={POLICIES.SUBSCRIPTION_AGREEMENT}
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        <Button variant="filled" onClick={() => handleAgree()}>
          <span>I AGREE TO THE SUBSCRIPTION AND SERVICE AGREEMENT</span>
        </Button>
      </div>
    </div>
  )
}

export default SubscriptionAgreement
