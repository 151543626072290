import PropTypes from 'prop-types'
import { List } from '@context365/icons'
import { Menu, MenuButton } from '@context365/menu'
import { useFlags } from 'launchdarkly-react-client-sdk'
import useVisibility from '~/hooks/useVisibility'
import { pluralize } from '~/utils/helpers'
import {
  CreateListModal,
  DeleteListModal,
  ManageListsModal,
} from '../DiscoverListModals'
import DiscoverTabSwitch from '../DiscoverTabs/DiscoverTabSwitch'
import Sorter from '../Sorter'
import DiscoverAlerts from './DiscoverAlerts'

const DiscoverResultsHeaderRow = ({
  total,
  showDiscoverAlerts,
  hideListManagement = false,
  alertDisabled,
  filter,
  searchTerm,
  setSearchState,
  category,
  sortFields,
  sortOrder,
  handleSort,
  handleSortReset,
  defaultSortLabel,
  activeTab,
  setActiveTab,
  onTabChange,
  includeTableView,
  includeMapView = true,
}) => {
  return (
    <div className="flex flex-row items-center mb-2 justify-between">
      <div className="flex space-x-2">
        <span className="DiscoverContainer-text">
          {pluralize(Math.max(total || 0, 0), 'Result', 'Results')}
        </span>
        <div className="w-0 self-stretch border-grey-300 border-r" />
        {showDiscoverAlerts && (
          <div>
            <DiscoverAlerts
              filter={filter}
              searchTerm={searchTerm}
              alertDisabled={alertDisabled}
              setSearchState={setSearchState}
              category={category}
            />
          </div>
        )}
        {!hideListManagement && (
          <div>
            <DiscoverListManagement />
          </div>
        )}
      </div>
      <div className="flex flex-row items-center space-x-2">
        {sortFields && (
          <Sorter
            options={sortFields}
            activeField={sortOrder}
            onSort={handleSort}
            onReset={handleSortReset}
            defaultLabel={defaultSortLabel}
          />
        )}
        {activeTab && (
          <DiscoverTabSwitch
            onChange={onTabChange}
            value={activeTab}
            setValue={setActiveTab}
            includeTableView={includeTableView}
            includeMapView={includeMapView}
          />
        )}
      </div>
    </div>
  )
}

DiscoverResultsHeaderRow.propTypes = {
  total: PropTypes.number,
  showDiscoverAlerts: PropTypes.bool,
  hideListManagement: PropTypes.bool,
  alertDisabled: PropTypes.bool,
  filter: PropTypes.array,
  searchTerm: PropTypes.string,
  setSearchState: PropTypes.func,
  category: PropTypes.string,
  sortFields: PropTypes.array,
  sortOrder: PropTypes.string,
  handleSort: PropTypes.func,
  handleSortReset: PropTypes.func,
  defaultSortLabel: PropTypes.string,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  onTabChange: PropTypes.func,
  includeTableView: PropTypes.bool,
  includeMapView: PropTypes.bool,
}

export default DiscoverResultsHeaderRow

function DiscoverListManagement() {
  const { listSharing } = useFlags()
  const addListModal = useVisibility()
  const deleteListModal = useVisibility()
  const manageListsModal = useVisibility()

  return (
    <>
      <Menu
        trigger={
          <MenuButton variant="outlined" size="small" iconLeft={<List />}>
            Lists
          </MenuButton>
        }
      >
        <Menu.Item onClick={() => addListModal.show()}>Create List</Menu.Item>
        {listSharing ? (
          <Menu.Item onClick={() => manageListsModal.show()}>
            Manage Lists
          </Menu.Item>
        ) : (
          <Menu.Item onClick={() => deleteListModal.show()}>
            Delete List
          </Menu.Item>
        )}
      </Menu>
      <CreateListModal
        visible={addListModal.visible}
        onClose={addListModal.hide}
      />
      <DeleteListModal
        visible={deleteListModal.visible}
        onClose={deleteListModal.hide}
      />
      <ManageListsModal
        visible={manageListsModal.visible}
        onClose={manageListsModal.hide}
      />
    </>
  )
}
