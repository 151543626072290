import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { CheckBoxOutlined, ExpandMore } from '@context365/icons'
import { Menu } from '@context365/menu'
import { Drawer } from 'antd'
import cx from 'classnames'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import {
  actions as tableActions,
  useColumnOrder,
  useRowSelect,
  useTable,
} from 'react-table'
import { useTracking } from 'react-tracking'
import ColumnsPreferences from '../ColumnsPreferences'
import Paginator from '../Paginator'
import IndeterminateCheckbox from './IndeterminateCheckbox'
import { useSelectedRows } from './selectRowsContext'
import './DiscoverTable.less'

const DiscoverTable = ({
  columns,
  data,
  loadPage,
  currentPage,
  total,
  bulkActions = null,
  tableTitle = null,
  useColumnCustomization = false,
  staticColumns = [],
  pageSize,
}) => {
  const { Track } = useTracking({
    component: 'DiscoverTable',
    tableTitle,
  })

  const { selectedRows, getRowId, onToggleSelected, onToggleAllSelected } =
    useSelectedRows()

  const [columnDrawerVisible, setColumnDrawerVisible] = React.useState(false)

  const storageKey = `${tableTitle}_Columns`
  let columnPreferences
  let hiddenColumns
  let columnOrder
  try {
    columnPreferences =
      JSON.parse(localStorage.getItem(storageKey)) ||
      forEach(columns, (c) => (c.checked = true))
    hiddenColumns = map(
      filter(columnPreferences, (x) => !x.checked),
      'id'
    )
    columnOrder = map(staticColumns, 'id').concat(map(columnPreferences, 'id'))
  } catch {
    columnPreferences = forEach(columns, (c) => (c.checked = true))
    hiddenColumns = []
    columnOrder = []
  }

  const selectedRowIds = React.useMemo(
    () =>
      selectedRows.reduce(
        (ids, row) => ({
          ...ids,
          [getRowId(row)]: true,
        }),
        {}
      ),
    [selectedRows, getRowId]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    setColumnOrder,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      getRowId,
      initialState: {
        hiddenColumns,
        columnOrder,
        selectedRowIds,
      },
      stateReducer: (nextState, action) => {
        if (action.type === tableActions.toggleRowSelected) {
          const { id } = action
          const fundToToggle = data.find((row) => getRowId?.(row) === id)

          if (fundToToggle) {
            // Apparently, react-table is calling this stateReducer function from
            // within a setState callback, so we can't update our own state from
            // there. Instead, we'll queue the state update for the next tick.
            setTimeout(() => {
              onToggleSelected(fundToToggle)
            }, 0)
          }
        } else if (action.type === tableActions.toggleAllRowsSelected) {
          const { value: isSelected } = action
          setTimeout(() => {
            onToggleAllSelected(data, isSelected)
          })
        }
        return nextState
      },
      useControlledState: (state) =>
        React.useMemo(
          () => ({
            ...state,
            selectedRowIds,
          }),
          // eslint-disable-next-line react-hooks/exhaustive-deps -- the nested hook here confuses this plugin
          [state, selectedRowIds]
        ),
    },
    useColumnOrder,
    useRowSelect,
    (hooks) => {
      if (bulkActions && bulkActions.length > 0) {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ])
      }
    }
  )

  const updateColumnPreferences = (cols) => {
    const orderedCols = map(staticColumns, 'id').concat(map(cols, 'id'))
    const hiddenCols = map(
      filter(cols, (c) => !c.checked),
      'id'
    )
    setColumnOrder(orderedCols)
    setHiddenColumns(hiddenCols)
    localStorage.setItem(storageKey, JSON.stringify(cols))
  }

  return (
    <Track>
      <div
        className={cx(
          'DiscoverTable text-xs text-left p-1 pl-4 width-100 flex justify-between items-center',
          selectedFlatRows.length > 0 && 'bg-purple-5'
        )}
      >
        <div className="justify-start">
          {selectedFlatRows.length > 0
            ? `${selectedFlatRows.length} selected`
            : ''}
        </div>
        <div className="justify-end">
          {useColumnCustomization && (
            <Button
              className="mr-2"
              size="small"
              onClick={() => setColumnDrawerVisible(true)}
              iconLeft={<CheckBoxOutlined />}
            >
              Customize Columns
            </Button>
          )}
          <Drawer
            visible={columnDrawerVisible}
            placement="right"
            closable="true"
            onClose={() => {
              setColumnDrawerVisible(false)
            }}
            title="Customize Columns"
            width="390"
            bodyStyle={{ paddingLeft: '8px', paddingRight: '8px' }}
          >
            <ColumnsPreferences
              columns={columnPreferences}
              onColumnsRearranged={updateColumnPreferences}
              onCancel={() => setColumnDrawerVisible(false)}
            />
          </Drawer>
          {bulkActions && (
            <Menu
              trigger={
                <Button
                  className="action-menu-button"
                  size="small"
                  iconRight={<ExpandMore />}
                  disabled={selectedFlatRows.length < 1}
                >
                  Actions
                </Button>
              }
            >
              {bulkActions.map((action) => (
                <Menu.Item
                  key={action.title}
                  onClick={() => action.onClick(selectedFlatRows)}
                  disabled={action.isDisabled(selectedFlatRows)}
                >
                  <div className="flex text-md">
                    {action.icon}
                    {action.title}
                  </div>
                </Menu.Item>
              ))}
            </Menu>
          )}
        </div>
      </div>

      <div className="overflow-auto">
        <table
          className="DiscoverTable w-full border border-grey-300 border-solid text-left leading-10 min-w-1500"
          {...getTableProps()}
        >
          <thead className="border-b border-grey-300">
            {headerGroups.map((headerGroup) => (
              <tr className="bg-white" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className="capitalize px-4 whitespace-nowrap"
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <tr
                  className={
                    selectedFlatRows.includes(row)
                      ? 'bg-purple-5'
                      : 'even:bg-white'
                  }
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className="px-4 whitespace-nowrap"
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {loadPage && (
        <Paginator
          className="mt-8"
          currentPage={currentPage}
          total={total}
          loadPage={loadPage}
          pageSize={pageSize}
        />
      )}
    </Track>
  )
}

DiscoverTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  currentPage: PropTypes.number,
  total: PropTypes.number,
  loadPage: PropTypes.func,
  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  tableTitle: PropTypes.string,
  useColumnCustomization: PropTypes.bool,
  staticColumns: PropTypes.arrayOf(PropTypes.string),
  pageSize: PropTypes.number,
}

export default DiscoverTable
