import * as React from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Tabs, message } from 'antd'
import forEach from 'lodash/forEach'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import moment from 'moment'
import * as api from '~/api'
import UpdateReturnsExcel from '../../UpdateReturnsExcel'
import ReturnsUpdateForm from './ReturnsUpdateForm'
import './UpdateMonthlyReturns.less'

const { TabPane } = Tabs

const UpdateMonthlyReturns = ({
  fundId,
  missingMonths,
  onComplete = () => {},
}) => {
  const monthEndDates = React.useMemo(
    () => map(missingMonths, (x) => moment(x, 'YYYY-MM-DD').endOf('month')),
    [missingMonths]
  )

  const [lastMonthUpdate, setLastMonthUpdate] = React.useState(null)

  const defaultTab = missingMonths?.length > 0 ? 'manual' : 'excel'

  const handleMonthlySave = React.useCallback(
    (values) => {
      const returns = {
        month: [],
        year: [],
        return: [],
        aum: [],
      }

      forEach(values, (val) => {
        returns.month.push(val.month)
        returns.year.push(val.year)
        returns.return.push(val.return)
        returns.aum.push(val.aum)
      })

      api.fundCharts.logInIfNeeded().then(() => {
        api.fundCharts
          .updateMultipleMonthReturns(fundId, returns)
          .then(() => {
            message.success('Successfully updated returns')
            api.fundCharts.updateFundStats(fundId)
            api.fundCharts.updateFundReturns(fundId, false, {
              month: returns.month,
              year: returns.year,
            })
          })
          .catch(() => {
            message.error('An error occurred while updating returns')
          })
          .finally(() => onComplete())
      })
    },
    [fundId, onComplete]
  )

  const handleExcelSave = React.useCallback(
    (values) => {
      api.fundCharts.logInIfNeeded().then(() => {
        api.fundCharts
          .saveJsonReturns(fundId, values)
          .then(() => {
            message.success('Successfully updated returns')
            api.fundCharts.updateFundStats(fundId)
          })
          .catch(() => {
            message.error('An error occurred while updating returns')
          })
          .finally(() => onComplete())
      })
    },
    [fundId, onComplete]
  )

  React.useEffect(() => {
    api.fundCharts
      .logInIfNeeded()
      .then(() => api.fundCharts.getLatestReturnUpdate(fundId))
      .then((res) => setLastMonthUpdate(res.data[0]))
  }, [fundId])

  return (
    <div className="UpdateReturns">
      {!isNil(lastMonthUpdate) && (
        <>
          <div className="UpdateReturnsModal-header">Last Updated Month</div>
          <div className="UpdateReturnsModal-preview">
            <Row className="UpdateReturnsModal-preview-row">
              <Col span={8}>Month End Date</Col>
              <Col span={8}>Return</Col>
              <Col span={8}>AUM</Col>
            </Row>
            <Row className="UpdateReturnsModal-preview-row">
              <Col span={8}>{lastMonthUpdate.returndate}</Col>
              <Col span={8}>{lastMonthUpdate.returnpercent}</Col>
              <Col span={8}>{lastMonthUpdate.aum}</Col>
            </Row>
          </div>
          {!isNil(lastMonthUpdate.updatedat) && (
            <div className="UpdateReturnsModal-updateline">
              Last updated on {lastMonthUpdate.updatedat}
            </div>
          )}
        </>
      )}
      <div className="UpdateReturnsModal-header">Pending Updates</div>
      <Tabs type="card" defaultActiveKey={defaultTab}>
        <TabPane tab="Update Manually" key="manual">
          <ReturnsUpdateForm
            fundId={fundId}
            monthEndDates={monthEndDates}
            onSubmit={handleMonthlySave}
          />
        </TabPane>
        <TabPane tab="Upload a Spreadsheet" key="excel">
          <UpdateReturnsExcel fundId={fundId} onSave={handleExcelSave} />
        </TabPane>
      </Tabs>
    </div>
  )
}

UpdateMonthlyReturns.propTypes = {
  onComplete: PropTypes.func,
  missingMonths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  fundId: PropTypes.number.isRequired,
}

export default UpdateMonthlyReturns
