import { api as http } from '~/api/services'

export const getContinents = () => http.get('/on-boarding/continents')

export const getCountries = (continent) =>
  http.get(`/on-boarding/continents/${continent}/countries`)

export const getAllCountries = () => http.get(`/locations/countries`)

export const getAllContinents = () => http.get(`/locations/continents/all`)

export const getByZip = (zipCode, country) =>
  http.get(`/locations/countries/${country}/zipcode/${zipCode}`)
