import { useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from '@context365/forms'
import isNil from 'lodash/isNil'
import * as api from '~/api'
import { currency } from '~/utils/formatters/numbers'
import MissingSection from './MissingSection'
import NextButton from './NextButton'

const CurrencyInput = ({ value, setValue }) => (
  <Input
    prefix="US$"
    className="w-1/2"
    value={currency(value)}
    onChange={(e) => {
      const value = e.target.value.replace(/\D/g, '')
      setValue(value ? value : null)
    }}
  />
)

const MissingFundDetails = ({ fundId, ...actionProps }) => {
  const [fundAum, setFundAum] = useState(null)

  return (
    <>
      <p className="text-primary-100 type-body-regular-sm pb-4">
        {
          'Before you can apply to this mandate, please take a few moments to keep your current data up to date.'
        }
      </p>
      <MissingSection title="Fund AUM">
        <CurrencyInput value={fundAum} setValue={setFundAum} />
      </MissingSection>
      <NextButton
        disabled={isNil(fundAum) || fundAum < 0}
        {...actionProps}
        onNext={() =>
          api.funds.saveFundAum(fundId, fundAum).then(actionProps.onNext)
        }
      />
    </>
  )
}

MissingFundDetails.propTypes = {
  fundId: PropTypes.number,
}

export default MissingFundDetails
