import PropTypes from 'prop-types'
import { Avatar } from '@context365/avatar'
import { Badge } from '@context365/badge'
import { Button } from '@context365/button'
import { ArrowForwardIos } from '@context365/icons'
import { isNil } from 'lodash'
import MatchmakingScore from '~/components/MatchmakingScore'
import ShortenedText from '~/components/ShortenedText'

const meetingBadgeStatus = {
  Pending: 'info',
  Confirmed: 'success',
  Cancelled: 'error',
  Expired: 'error',
  default: 'default',
}

const MobileAttendee = ({
  imageUrl,
  initials,
  name,
  company,
  subTitle,
  meeting,
  matchmakingScore,
  onClick,
  booth,
}) => {
  return (
    <Button
      className="border-0 flex flex-row items-center justify-between py-6 w-full"
      variant="none"
      onClick={onClick}
    >
      <div className="flex flex-row">
        {!isNil(booth) ? (
          <div className="type-body-semibold-sm w-12 mr-6 self-center">
            {booth}
          </div>
        ) : (
          (!isNil(imageUrl) || !isNil(initials)) && (
            <Avatar
              size="big"
              initials={initials}
              profileImageUrl={imageUrl}
              style={{ minWidth: '48px' }}
            />
          )
        )}
        <div className="flex flex-col items-start px-4 text-left">
          <ShortenedText
            className="type-body-regular-xl"
            maxLength={16}
            text={name}
          />
          <ShortenedText
            className="type-body-semibold-sm"
            maxLength={25}
            text={company}
          />
          <ShortenedText
            className="type-subtitle-sm text-grey-600"
            maxLength={25}
            text={subTitle}
          />
          <div>
            {!isNil(meeting) && (
              <Badge
                status={
                  meetingBadgeStatus[
                    meeting.meetingStatus ?? meetingBadgeStatus.default
                  ]
                }
                size="small"
              >
                {meeting.meetingStatus}
              </Badge>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-between gap-2">
        <MatchmakingScore score={matchmakingScore} threshold={0.7} />
        <ArrowForwardIos />
      </div>
    </Button>
  )
}

MobileAttendee.propTypes = {
  imageUrl: PropTypes.string,
  initials: PropTypes.string,
  name: PropTypes.string,
  company: PropTypes.string,
  subTitle: PropTypes.string,
  meeting: PropTypes.object,
  matchmakingScore: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  booth: PropTypes.string,
}

export default MobileAttendee
