import { useEffect, useState } from 'react'
import * as api from '~/api'
import CompanyCategories from '~/constants/companyCategories'

export default function useFund(
  fundId,
  contentContactCompanyCategoryId,
  userRole
) {
  const [fund, setFund] = useState(null)

  useEffect(() => {
    if (
      userRole === 'Manager' ||
      contentContactCompanyCategoryId !== CompanyCategories.Manager ||
      !fundId
    ) {
      return
    }

    api.funds.getFundProfile(fundId).then((response) => {
      setFund(response)
    })
  }, [fundId, userRole, contentContactCompanyCategoryId])

  return { fund }
}
