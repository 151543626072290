import entries from 'lodash/entries'
import fromPairs from 'lodash/fromPairs'
import map from 'lodash/map'
import upperFirst from 'lodash/upperFirst'

const profileToAnswers = (fundProfile) => {
  const answers = fromPairs(
    map(entries(fundProfile), (kvp) => [upperFirst(kvp[0]), kvp[1]])
  )

  answers.FundAUM = answers.FundAum || answers.FundAUM
  answers.AnnualizedVolatility = answers.AnnualVolatility
  answers.YtdReturn = answers.Ytdreturn

  return answers
  // return {
  //   FundAUM: fundProfile.fundAum,
  //   FundInceptionDate: fundProfile.FundInceptionDate,
  //   Domicile: fundProfile.domicile,
  //   FundAttributes: fundProfile.fundAttributes,
  //   DeepestDrawdown: fundProfile.deepestDrawdown,
  //   LengthOfLongestDrawdown: fundProfile.lengthOfLongestDrawdown,
  //   ManagementFee: fundProfile.managementFee,
  //   PerformanceFee: fundProfile.performanceFee,
  //   MinimumInvestment: fundProfile.minimumInvestment,
  //   DaysAdvanceNoticeNeededForRedemption:
  //     fundProfile.daysAdvanceNoticeNeededForRedemption,
  //   GeographicConcentration: fundProfile.geographicConcentration,
  //   SectorConcentration: fundProfile.sectorConcentration,
  //   AnnualizedReturn: fundProfile.annualizedReturn,
  //   AnnualizedVolatility: fundProfile.annualizedVolatility,
  //   YtdReturn: fundProfile.ytdReturn,
  //   Sharpe: fundProfile.sharpe,
  //   Sortino: fundProfile.sortino,
  //   Sterling: fundProfile.sterling,
  //   PrimeBroker1: fundProfile.primeBroker,
  //   FundAdministrator: fundProfile.fundAdministrator,
  //   Auditor: fundProfile.auditor,
  //   ExternalLegalCounsel: fundProfile.externalLegalCounsel,
  //   DurationOfCapitalLockup: fundProfile.durationOfCapitalLockup,
  //   PostLockupLiquidity: fundProfile.postLockupLiquidity,
  //   BroadStrategy: fundProfile.broadStrategy,
  //   SubStrategy: fundProfile.subStrategy,
  //   NetDollarExposure: fundProfile.netDollarExposure,
  //   PrimaryDriverOfInvestmentDecisionProcess:
  //     fundProfile.primaryDriverOfInvestmentDecisionProcess,
  //   PrimaryInstruments: fundProfile.primaryInstruments,
  // };
}

export default profileToAnswers
