import { Button } from '@context365/button'
import { Business, SendOutlined } from '@context365/icons'
import { Modal } from '@context365/modals'
import { message } from 'antd'
import { format } from 'date-fns'
import * as api from '~/api'
import { numberFormat } from '~/utils/formatters'

const ClassActionContactModal = ({ visible, onClose, classActionCase }) => {
  return (
    <Modal
      title="Contact"
      onDismiss={() => onClose()}
      footerRight={
        <div className="space-x-3">
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button
            variant="filled"
            iconRight={<SendOutlined />}
            onClick={() =>
              api.classActions
                .sendRequestEmail(classActionCase.classActionId)
                .then(() => {
                  message.success('Request sent.')
                  onClose()
                })
            }
          >
            Send Request
          </Button>
        </div>
      }
      visible={visible}
    >
      {classActionCase && (
        <div className="px-2 mb-2">
          <div className="type-body-regular-md mb-4 pb-4 border-b border-brand-90">
            Request more information from Battea about this case?
          </div>
          <span className="flex mb-5 text-grey-800">
            <Business />
            <span className="type-subtitle-md ml-3">
              {classActionCase.name}
            </span>
          </span>
          <div className="grid grid-cols-2 gap-4">
            <span>
              <div className="type-body-semibold-sm">Industry</div>
              <div className="type-body-regular-sm">
                {classActionCase.industry ?? '-'}
              </div>
            </span>
            <span>
              <div className="type-body-semibold-sm">Settlement Fund Size</div>
              <div className="type-body-regular-sm">
                {numberFormat.currencyLarge(classActionCase.settlementFundSize)}
              </div>
            </span>
            <span>
              <div className="type-body-semibold-sm">Class Period Start</div>
              <div className="type-body-regular-sm">
                {format(
                  new Date(classActionCase.classPeriodStart),
                  'MMM do, y'
                )}
              </div>
            </span>
            <span>
              <div className="type-body-semibold-sm">Class Period End</div>
              <div className="type-body-regular-sm">
                {format(new Date(classActionCase.classPeriodEnd), 'MMM do, y')}
              </div>
            </span>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default ClassActionContactModal
