import PropTypes from 'prop-types'
import { Toggle } from '@context365/forms'
import { QuestionAnswerOutlined } from '@context365/icons'
import { Input, Select } from 'antd'
import MeetingListItem from './MeetingListItem'

const MeetingList = ({
  meetings,
  emptyText,
  refreshList,
  meetingStatusIds,
  setMeetingStatusIds,
  meetingTypeIds,
  setMeetingTypeIds,
  hideLocationFilter = false,
  sentRequestsOnly,
  setSentRequestsOnly,
  setSearch,
  page,
  pageSize,
  setPage,
  setPageSize,
  total,
  isRequests = false,
}) => {
  const { Option } = Select
  const { Search } = Input

  const handleSearch = (search) => {
    setPage(1)
    setSearch(search)
  }

  return (
    <>
      <div className="mb-8 grid grid-cols-1 lg:grid-cols-2 space-x-0 lg:space-x-4 space-y-4 lg:space-y-0">
        <div className="flex-col">
          <div className="type-body-regular-xs">Search</div>
          <Search placeholder="Search" onSearch={handleSearch} enterButton />
        </div>
        <div className="grid grid-cols-2 space-x-4">
          {setMeetingStatusIds && (
            <div className="flex-col">
              <div className="type-body-regular-xs">Meeting Status</div>
              <Select
                mode="multiple"
                allowClear={false}
                style={{ width: '100%' }}
                placeholder="Statuses"
                defaultValue={meetingStatusIds}
                onChange={setMeetingStatusIds}
              >
                <Option value="1">Active / Pending</Option>
                <Option value="3">Cancelled / Declined</Option>
              </Select>
            </div>
          )}
          {!hideLocationFilter && (
            <div className="flex-col">
              <div className="type-body-regular-xs">Meeting Location</div>
              <Select
                mode="multiple"
                allowClear={false}
                style={{ width: '100%' }}
                placeholder="Meeting types"
                defaultValue={meetingTypeIds}
                onChange={setMeetingTypeIds}
              >
                <Option value="1">Summit</Option>
                <Option value="3">Online</Option>
              </Select>
            </div>
          )}
          {setSentRequestsOnly && (
            <div className="flex-col">
              <div className="type-body-regular-xs">Sent Requests</div>
              <Toggle
                size="small"
                checked={sentRequestsOnly}
                onChange={(e) => setSentRequestsOnly(e.target.checked)}
              />
            </div>
          )}
        </div>
      </div>
      {meetings?.length > 0 ? (
        <MeetingListItem
          meetings={meetings}
          refreshList={refreshList}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          total={total}
          isRequests={isRequests}
        />
      ) : (
        <div className="text-center space-y-8 space-x-8 mt-8 lg:mt-20">
          <QuestionAnswerOutlined size={128} color="#757575" />
          <div className="type-header-sm">
            {emptyText || 'Nothing to show here'}
          </div>
        </div>
      )}
    </>
  )
}

MeetingList.propTypes = {
  meetings: PropTypes.array,
  emptyText: PropTypes.string,
  refreshList: PropTypes.func,
  meetingStatusIds: PropTypes.array,
  setMeetingStatusIds: PropTypes.func,
  meetingTypeIds: PropTypes.array,
  hideLocationFilter: PropTypes.bool,
  setMeetingTypeIds: PropTypes.func,
  sentRequestsOnly: PropTypes.bool,
  setSentRequestsOnly: PropTypes.func,
  setSearch: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  setPage: PropTypes.func,
  setPageSize: PropTypes.func,
  total: PropTypes.number,
  isRequests: PropTypes.bool,
}

export default MeetingList
