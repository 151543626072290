import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faEye,
  faPencilAlt,
  faPlus,
  faUser,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal, Table, message } from 'antd'
import isNil from 'lodash/isNil'
import numeral from 'numeral'
import { withRouter } from 'react-router-dom'
import * as api from '~/api'
import ActionButton from '../ActionButton'
import FundDetailsModalContainer from '../FundDetailsModalContainer'
import FundVisibilityToggle from '../FundVisibilityToggle'
import { FundWizardModal, useFundWizardModal } from '../FundWizard'
import SettingsFundsManagers from '../SettingsFundsManagers'
import './SettingsFunds.less'

const { Column } = Table

const SettingsFunds = ({ company }) => {
  const [selectedFundId, setSelectedFundId] = useState(null)
  const [selectedFundName, setSelectedFundName] = useState(null)
  const [showFundProfile, setShowFundProfile] = useState(false)
  const fundWizardModal = useFundWizardModal('settingsFunds')
  const [managersModalVisible, setManagersModalVisible] = useState(false)

  const onEditManagersClicked = useCallback(() => {
    setManagersModalVisible(true)
  }, [])

  const onEditManagersClosed = useCallback(() => {
    setManagersModalVisible(false)
    setSelectedFundId(null)
    setSelectedFundName(null)
  }, [])

  const renderColleaguesActionButton = useCallback(
    (record) => {
      const ctxMenuItems = []
      ctxMenuItems.push({
        icon: faEye,
        text: 'Preview',
        clickHandle: () => {
          setSelectedFundId(record.fundId)
          setShowFundProfile(true)
        },
      })

      ctxMenuItems.push({
        icon: faPencilAlt,
        text: 'Edit',
        clickHandle: () => {
          setSelectedFundId(record.fundId)
          fundWizardModal.show()
        },
      })

      ctxMenuItems.push({
        icon: faUser,
        text: 'Edit Contacts',
        clickHandle: () => {
          setSelectedFundId(record.fundId)
          setSelectedFundName(record.name)
          onEditManagersClicked()
        },
      })

      return <ActionButton menu={ctxMenuItems} />
    },
    [onEditManagersClicked, fundWizardModal]
  )

  const handleVisibilityChange = useCallback((record, isInvisible) => {
    api.funds
      .toggleFundVisibility(record.fundId, isInvisible)
      .then(() => {
        message.success('Visibility has been successfully saved')
      })
      .catch(() => message.error('There was an error while attempting to save'))
  }, [])

  const renderVisibleToggle = useCallback(
    (record) => {
      const tooltip = record.isValidated
        ? null
        : 'You must fill out all required fields before your fund can be made visible'
      return (
        <FundVisibilityToggle
          tooltip={tooltip}
          disabled={!record.isValidated}
          defaultChecked={!record.isInvisible && record.isValidated}
          onChange={(e) => handleVisibilityChange(record, e)}
        />
      )
    },
    [handleVisibilityChange]
  )

  return (
    <>
      <div className="form-container-header">
        <p className="form-container-title">Funds</p>
        <p className="form-container-description">Manage funds</p>
      </div>
      <Table
        dataSource={company.funds}
        className="cc-company-colleagues-table"
        rowKey={(record) => record.fundId.toString()}
      >
        <Column
          title="Fund Name"
          key="name"
          render={(record) => (
            <Button
              type="link"
              onClick={() => {
                setSelectedFundId(record.fundId)
                setShowFundProfile(true)
              }}
            >
              {record.name}
            </Button>
          )}
        />
        <Column
          title="Investment Vehicle"
          dataIndex="investmentVehicle"
          key="investmentVehicle"
        />
        <Column
          title="Minimum Investment"
          dataIndex="minimumInvestment"
          key="minimumInvestment"
          render={(record) => numeral(record).format('$0 a').toUpperCase()}
        />
        <Column title="Domicile" dataIndex="domicile" key="domicile" />
        <Column
          title="Visible"
          key="isInvisible"
          render={renderVisibleToggle}
        />
        <Column
          title="Actions"
          key="actions"
          render={renderColleaguesActionButton}
        />
      </Table>
      <FundDetailsModalContainer
        hideActions
        fund={{ fundId: selectedFundId, companyId: company.companyId }}
        showFundProfile={showFundProfile && !isNil(selectedFundId)}
        onClose={() => {
          setShowFundProfile(false)
          setSelectedFundId(null)
        }}
      />

      <Modal
        destroyOnClose
        visible={managersModalVisible && !isNil(selectedFundId)}
        onCancel={onEditManagersClosed}
        footer={null}
        bodyStyle={{ paddingBottom: '32px' }}
        width="600px"
      >
        <SettingsFundsManagers
          fundId={selectedFundId}
          fundName={selectedFundName}
          onCancel={onEditManagersClosed}
        />
      </Modal>

      {fundWizardModal.visible && (
        <FundWizardModal
          visible
          fundId={selectedFundId}
          onWizardClosed={() => {
            fundWizardModal.hide()
            setSelectedFundId(null)
          }}
          onSave={() => {
            fundWizardModal.hide()
            setSelectedFundId(null)
          }}
        />
      )}
      <Button
        type="primary"
        className="company-colleagues-add"
        onClick={() => fundWizardModal.show()}
      >
        <FontAwesomeIcon className="button-icon" icon={faPlus} />
        Add New Fund
      </Button>
    </>
  )
}

SettingsFunds.propTypes = {
  company: PropTypes.shape({
    companyId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    companyDescription: PropTypes.string.isRequired,
    category: PropTypes.shape({
      categoryId: PropTypes.number.isRequired,
      categoryName: PropTypes.string.isRequired,
    }).isRequired,
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        contactId: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        mobilePhone: PropTypes.string.isRequired,
      })
    ).isRequired,
    funds: PropTypes.arrayOf(
      PropTypes.shape({
        fundId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        investmentVehicle: PropTypes.string.isRequired,
        minimumInvestment: PropTypes.number.isRequired,
        domicile: PropTypes.string.isRequired,
        isValidated: PropTypes.bool.isRequired,
        isInvisible: PropTypes.bool.isRequired,
      })
    ),
  }).isRequired,
}

export default withRouter(SettingsFunds)
