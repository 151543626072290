import * as React from 'react'
import { NavButton, NavList } from '@context365/tabs'
import { useFlags } from 'launchdarkly-react-client-sdk'
import qs from 'qs'
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom'
import { ROLE } from '~/constants/roles'
import { usePrefetchDiscoverLists } from '~/hooks/discoverLists'
import useAuth from '~/hooks/useAuth'
import useSearchParams from '~/hooks/useSearchParams'
import DiscoverAllocatorsContainer from './DiscoverAllocatorsContainer'
import DiscoverFundsContainer from './DiscoverFundsContainer'
import DiscoverMandatesContainer from './DiscoverMandatesContainer'
import DiscoverResearchCampaignsContainer from './DiscoverResearchCampaignsContainer'
import DiscoverServiceProvidersContainer from './DiscoverServiceProvidersContainer'
import RequireFunds from './RequireFunds'

const DiscoverFunds = withSearchParams(DiscoverFundsContainer)
const DiscoverMandates = withSearchParams(DiscoverMandatesContainer)
const DiscoverAllocators = withSearchParams(DiscoverAllocatorsContainer)
const DiscoverResearch = withSearchParams(DiscoverResearchCampaignsContainer)
const DiscoverServiceProviders = withSearchParams(
  DiscoverServiceProvidersContainer
)

export default function Discover() {
  const { institutionalAllocators } = useFlags()
  const { pathname } = useLocation()
  const { role } = useAuth()
  const discoverFeatures = useDiscoverFeatures()
  usePrefetchDiscoverLists()

  const showNavigation =
    pathname !== '/discover' || ![ROLE.MANAGER, ROLE.ALLOCATOR].includes(role)

  return (
    <div className="md:-mt-4">
      {showNavigation && (
        <NavList variant="underlined" label="Discover">
          {discoverFeatures.includes('funds') && (
            <NavButton to="/discover/funds">Funds</NavButton>
          )}
          {discoverFeatures.includes('mandates') && (
            <NavButton to="/discover/mandates">Mandates</NavButton>
          )}
          {discoverFeatures.includes('allocators') &&
            institutionalAllocators && (
              <NavButton to="/discover/allocators">
                Institutional Allocators
              </NavButton>
            )}
          {discoverFeatures.includes('research') && (
            <NavButton to="/discover/research">Research</NavButton>
          )}
          {discoverFeatures.includes('serviceProviders') && (
            <NavButton to="/discover/service-providers">
              Service Providers
            </NavButton>
          )}
        </NavList>
      )}
      <div className="pt-4">
        <Switch>
          <Route path="/discover" exact component={DiscoverIndex} />

          {discoverFeatures.includes('funds') && (
            <Route path="/discover/funds" exact component={DiscoverFunds} />
          )}
          {discoverFeatures.includes('mandates') && (
            <Route
              path="/discover/mandates"
              exact
              component={DiscoverMandates}
            />
          )}
          {discoverFeatures.includes('allocators') &&
            institutionalAllocators && (
              <Route
                path="/discover/allocators"
                exact
                component={DiscoverAllocators}
              />
            )}
          {discoverFeatures.includes('research') && (
            <Route
              path="/discover/research"
              exact
              component={DiscoverResearch}
            />
          )}
          {discoverFeatures.includes('serviceProviders') && (
            <Route
              path="/discover/service-providers"
              exact
              component={DiscoverServiceProviders}
            />
          )}

          {/* Redirect from any bookmarked routes to the new ones */}
          <Route
            path="/discover/serviceproviders"
            component={RedirectServiceProviders}
          />
          <Route
            path="/discover/:mainTab/search/:alertId?/:resultId?/:fundId?"
            component={RedirectDiscoverSearch}
          />

          {/* Fallback to redirect anything else to /discover */}
          <Route path="/discover">
            <Redirect to="/discover" />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

function useDiscoverFeatures() {
  const { role } = useAuth()

  return [
    role !== ROLE.MANAGER && 'funds',
    'mandates',
    'allocators',
    'serviceProviders',
    role === ROLE.MANAGER && 'research',
  ].filter(Boolean)
}

function withSearchParams(Component) {
  function WrappedComponent(props) {
    const { role } = useAuth()
    const location = useLocation()
    const [{ resultId, fundId }] = useSearchParams()

    const Wrapper = role === ROLE.MANAGER ? RequireFunds : React.Fragment

    return (
      <Wrapper>
        <Component
          resultId={resultId}
          fundId={fundId}
          tab={location.state?.tab}
          {...props}
        />
      </Wrapper>
    )
  }

  WrappedComponent.displayName = `withSearchParams(${
    Component.displayName || Component.name || 'Component'
  })`

  return WrappedComponent
}

function DiscoverIndex() {
  const { role } = useAuth()

  if (role === ROLE.ALLOCATOR) {
    return <Redirect to="/discover/funds" />
  } else {
    return <Redirect to="/discover/mandates" />
  }
}

function RedirectDiscoverSearch() {
  const [searchParams] = useSearchParams()
  const { mainTab, resultId, fundId } = useParams()
  const search = qs.stringify(
    { ...searchParams, resultId, fundId },
    { indices: false }
  )

  return <Redirect to={{ pathname: `/discover/${mainTab}`, search }} />
}

function RedirectServiceProviders() {
  const location = useLocation()

  return (
    <Redirect
      to={{
        ...location,
        pathname: location.pathname.replace(
          /serviceproviders/i,
          'service-providers'
        ),
      }}
    />
  )
}

export function RedirectMandates() {
  const [searchParams] = useSearchParams()
  const { resultId, fundId } = useParams()
  const search = qs.stringify(
    {
      ...searchParams,
      resultId,
      fundId,
    },
    { indices: false }
  )

  return <Redirect to={{ pathname: '/discover/mandates', search }} />
}
