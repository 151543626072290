import PropTypes from 'prop-types'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import { api as http } from '~/api/services'
import {
  DiscoverController,
  DiscoverFilters,
  DiscoverTabs,
  extractQuestionGroups,
} from '~/components/Discover'
import DiscoverAllocatorResult from '~/components/DiscoverAllocatorResult'
import { getDiscoverAllocatorsFilterComponent } from '~/components/DiscoverFilters'
import {
  AddAllocatorToListModal,
  useAddToListModal,
} from '~/components/DiscoverListModals'
import './DiscoverContainer.less'

const DiscoverAllocatorsContainer = ({ tab, resultId, clearResult }) => {
  const { Track } = useTracking({ page: 'DiscoverAllocators' })
  const addAllocatorToListModal = useAddToListModal()

  return (
    <Track>
      <AddAllocatorToListModal
        allocators={addAllocatorToListModal.items}
        visible={addAllocatorToListModal.visible}
        onClose={addAllocatorToListModal.hide}
      />
      <DiscoverController
        getResults={(params) => api.discovery.allocators(params)}
        refetchKeys={{ resultId }}
        category="allocators"
        tab={tab}
        showTableView={false}
        showMapView={false}
        showDiscoverAlerts={false}
        getFilters={() => http.get('/discover/allocators/filters')}
        renderFilters={({ filters }) => (
          <DiscoverFilters
            filters={filters}
            hideToggleMatches
            getFilterGroups={extractQuestionGroups('Lists')}
            renderFilterControl={getDiscoverAllocatorsFilterComponent}
          />
        )}
        results={
          <DiscoverTabs
            renderResultCard={({ item }) => (
              <DiscoverAllocatorResult
                allocator={item}
                onAddAllocatorToList={addAllocatorToListModal.show}
              />
            )}
          />
        }
        clearResult={clearResult}
      />
    </Track>
  )
}

DiscoverAllocatorsContainer.propTypes = {
  tab: PropTypes.string,
  resultId: PropTypes.number,
  clearResult: PropTypes.func,
}

export default DiscoverAllocatorsContainer
