import * as React from 'react'
import PropTypes from 'prop-types'
import FilterControlLayout from '~/components/layouts/FilterControlLayout'

const ContentTemplate = ({ filters, controls, entries }) => (
  <FilterControlLayout filters={filters} controls={controls}>
    {React.Children.count(entries) > 0 && (
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {entries}
      </div>
    )}
  </FilterControlLayout>
)

ContentTemplate.propTypes = {
  filters: PropTypes.element,
  controls: PropTypes.element,
  entries: PropTypes.arrayOf(PropTypes.element).isRequired,
}

export default ContentTemplate
