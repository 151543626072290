import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import moment from 'moment'
import qs from 'qs'
import { useDispatch } from 'react-redux'
import { getMyRoadshows } from '~/actions/roadshow'
import { api as http } from '~/api/services'
import { generateQuery } from '~/utils/discover'
import { numberFormat } from '~/utils/formatters'
import AllocatorDetailsModalContainer from '../AllocatorDetailsModalContainer'
import DiscoverServiceProviderResult from '../DiscoverServiceProviderResult/DiscoverServiceProviderResult'
import FundDetailsModalContainer from '../FundDetailsModalContainer'
import Loading from '../Loading'
import './MarkersDataList.less'

const MarkersDataList = ({
  role,
  coordinates,
  bounds,
  isRoadshow,
  filter,
  searchTerm = '',
  showMatched,
  pageSize,
  city,
  selectedFund,
  representedFund,
  meetingStatusFilter,
  resultId,
}) => {
  const [markerItems, setMarkerItems] = useState()
  const [roadshowItems, setRoadshowItems] = useState()
  const [filteredMarkerItems, setFilteredMarkerItems] = useState()
  const [filteredRoadshowItems, setFilteredRoadshowItems] = useState()
  const [modalSearch, setModalSearch] = useState('')
  const [searchPlaceholder, setSearchPlaceholder] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [selFund, setSelFund] = useState(null)
  const [selAllocatorCompany, setSelAllocatorCompany] = useState(null)
  const [selSPCompany, setSelSPCompany] = useState(null)

  const [selectedRoadshow, setSelectedRoadshow] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const isSPTab = window.location.pathname.indexOf('serviceproviders') > 0
  const isFundsTab = window.location.pathname.indexOf('funds') > 0
  const isAllocatorsTab = window.location.pathname.indexOf('allocators') > 0

  const generateFilterQuery = useCallback(
    (filterParamter) => {
      let filterQuery = []
      if (filterParamter)
        filterQuery = filterParamter.map((x) => generateQuery(x))

      if (isSPTab) {
        if (!isNil(resultId)) {
          const resultFilter = `companyId eq ${[resultId]}`
          filterQuery.push(resultFilter)
        }
      } else if (isFundsTab) {
        if (!isNil(resultId)) {
          const resultFilter = `fundId eq ${[resultId]}`
          filterQuery.push(resultFilter)
        }
      } else if (!isNil(resultId)) {
        const resultFilter = `companyContactId eq ${[resultId]}`
        filterQuery.push(resultFilter)
      }

      return filterQuery
    },
    [resultId]
  )

  const fetchMarkersList = useCallback(
    (lat, lng, filter, searchTerm = '', showMatched, meetingStatusFilter) => {
      setLoading(true)
      const filterQuery = generateFilterQuery(filter)

      if (isSPTab) {
        //sp
        const params = {
          pageSize,
          filterQuery,
          showMatched,
        }
        if (!isNil(searchTerm) && !isEmpty(searchTerm)) {
          params.q = trim(searchTerm)
        }

        if (!isNil(meetingStatusFilter) && !isEmpty(meetingStatusFilter)) {
          params.meetingStatus = meetingStatusFilter
        }

        params.coordinates = [lat, lng]
        if (!isNil(bounds) && bounds.length === 2) {
          ;[params.NWBound, params.SEBound] = bounds
        }
        if (!isNil(filterQuery) && !isEmpty(filterQuery)) {
          params.filter = filterQuery
        }

        http
          .get('discovery/spsByCity', {
            params,
            paramsSerializer: (params) =>
              qs.stringify(params, { arrayFormat: 'repeat' }),
          })
          .then((response) => {
            setMarkerItems(response.data.result)
            setFilteredMarkerItems(response.data.result)
          })
          .finally(() => {
            setLoading(false)
          })
      } else if (isFundsTab) {
        const params = { pageSize, showMatched }
        params.filter = filterQuery

        if (!isNil(searchTerm) && !isEmpty(searchTerm)) {
          params.q = trim(searchTerm)
        }

        if (!isNil(meetingStatusFilter) && !isEmpty(meetingStatusFilter)) {
          params.meetingStatus = meetingStatusFilter
        }

        params.coordinates = [lat, lng]
        if (!isNil(bounds) && bounds.length === 2) {
          ;[params.NWBound, params.SEBound] = bounds
        }
        params.isRoadshow = isRoadshow

        http
          .get('discovery/fundsByCity', {
            params,
            paramsSerializer: (params) =>
              qs.stringify(params, { arrayFormat: 'repeat' }),
          })
          .then((response) => {
            setMarkerItems(response.data.result.funds)
            setRoadshowItems(response.data.result.roadshows)
            setFilteredMarkerItems(response.data.result.funds)
            setFilteredRoadshowItems(response.data.result.roadshows)
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        const params = { pageSize }
        params.filter = filterQuery

        if (!isNil(searchTerm) && !isEmpty(searchTerm)) {
          params.q = trim(searchTerm)
        }

        params.coordinates = [lat, lng]
        if (!isNil(bounds) && bounds.length === 2) {
          // params['NWBound'] = bounds[0];
          // params['SEBound'] = bounds[1];
          ;[params.NWBound, params.SEBound] = bounds
        }

        if (!isNil(meetingStatusFilter) && !isEmpty(meetingStatusFilter)) {
          params.meetingStatus = meetingStatusFilter
        }

        params.isRoadshow = isRoadshow

        http
          .get('discovery/investorsByCity', {
            params,
            paramsSerializer: (params) =>
              qs.stringify(params, { arrayFormat: 'repeat' }),
          })
          .then((response) => {
            setMarkerItems(response.data.result.allocators)
            setRoadshowItems(response.data.result.roadshows)
            setFilteredMarkerItems(response.data.result.allocators)
            setFilteredRoadshowItems(response.data.result.roadshows)
          })
          .catch((error) => console.log('c', error))
          .finally(() => {
            setLoading(false)
          })
      }
    },
    [isSPTab, isFundsTab, pageSize, bounds, isRoadshow]
  )

  useEffect(() => {
    dispatch(getMyRoadshows())
  }, [dispatch])

  useEffect(() => {
    if (coordinates) {
      const { lat } = coordinates
      const { lng } = coordinates
      const fetchData = async () => {
        fetchMarkersList(
          lat,
          lng,
          filter,
          searchTerm,
          showMatched,
          meetingStatusFilter
        )
      }
      fetchData()
    }
  }, [
    coordinates,
    fetchMarkersList,
    filter,
    isRoadshow,
    meetingStatusFilter,
    searchTerm,
    showMatched,
  ])

  useEffect(() => {
    if (isRoadshow) {
      if (roadshowItems && roadshowItems.length > 0) {
        let noofElems = 0
        for (let i = 0; i < roadshowItems.length; i++) {
          noofElems += roadshowItems[i].roadshows.length
        }
        setModalTitle(
          `${
            //roadshowItems.length +
            noofElems +
            (isAllocatorsTab
              ? ' Allocators Roadshows in '
              : ' Fund Roadshows in ') +
            roadshowItems[0].roadshows[0].location
          } City`
        )
        setSearchPlaceholder(
          `Search roadshows in ${roadshowItems[0].roadshows[0].location}`
        )
      }
    } else if (markerItems && markerItems.length > 0) {
      if (isSPTab) {
        setModalTitle(
          `${`${markerItems.length} Service Providers in ${city}`} City`
        )
        setSearchPlaceholder(`Search Service Providers in ${city}`)
      } else if (isFundsTab) {
        if (markerItems && markerItems.length > 0) {
          setModalTitle(`${`${markerItems.length} Funds in ${city}`} City`)
          setSearchPlaceholder(`Search Funds in ${city}`)
        }
      } else {
        setModalTitle(`${`${markerItems.length} Allocator(s) in ${city}`} City`)
        setSearchPlaceholder(`Search Allocator(s) in ${city}`)
      }
    }
  }, [
    isRoadshow,
    modalSearch,
    roadshowItems,
    markerItems,
    city,
    isSPTab,
    isAllocatorsTab,
    isFundsTab,
  ])

  useEffect(() => {
    if (modalSearch && modalSearch.length > 2) {
      let filteredResults = isRoadshow ? roadshowItems : markerItems

      filteredResults = filteredResults.filter(
        (m) =>
          (m.contactName != undefined &&
            m.contactName.toLowerCase().includes(modalSearch.toLowerCase())) ||
          (m.companyName != undefined &&
            m.companyName.toLowerCase().includes(modalSearch.toLowerCase())) ||
          (m.allocator != undefined &&
            m.allocator.contactName
              .toLowerCase()
              .includes(modalSearch.toLowerCase())) ||
          (m.allocator != undefined &&
            m.allocator.companyName
              .toLowerCase()
              .includes(modalSearch.toLowerCase())) ||
          (m.roadshows != undefined &&
            m.roadshows.filter((r) =>
              r.roadshowName.toLowerCase().includes(modalSearch.toLowerCase())
            ).length > 0) ||
          (m.fund &&
            m.fund.name != undefined &&
            m.fund.name.toLowerCase().includes(modalSearch.toLowerCase())) ||
          (m.name != undefined &&
            m.name.toLowerCase().includes(modalSearch.toLowerCase()))
      )
      if (isRoadshow) {
        setFilteredRoadshowItems(filteredResults)
      } else {
        setFilteredMarkerItems(filteredResults)
      }
    } else {
      setFilteredMarkerItems(markerItems)
      setFilteredRoadshowItems(roadshowItems)
    }
  }, [isRoadshow, markerItems, modalSearch, roadshowItems])

  const openDetails = (item) => {
    setModalVisible(true)
    setSelFund(isFundsTab ? item : null)
    setSelSPCompany(isSPTab ? item : null)
    setSelAllocatorCompany(!isSPTab && !isFundsTab ? item : null)
  }

  const handleSearch = useCallback((e) => {
    setModalSearch(e.target.value)
  }, [])

  const handleCloseDetailsModal = useCallback(() => {
    setModalVisible(false)
    setSelectedRoadshow(null)
    if (isSPTab) setSelSPCompany(null)
    else if (isFundsTab) setSelFund(null)
    else setSelAllocatorCompany(null)
  }, [isFundsTab, isSPTab])

  const arePointsNear = useCallback((checkPoint, centerPoint, km) => {
    const ky = 40000 / 360
    const kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky
    const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx
    const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky
    return Math.sqrt(dx * dx + dy * dy) <= km
  }, [])

  return (
    <>
      {selSPCompany && modalVisible && (
        <DiscoverServiceProviderResult
          serviceProvider={selSPCompany}
          selectedFund={selectedFund}
          representedFund={representedFund}
          showSPProfile={modalVisible && !isNil(selSPCompany)}
          onClose={handleCloseDetailsModal}
          fromMapView
        />
      )}

      {selFund && (
        <FundDetailsModalContainer
          fund={selFund}
          showFundProfile={modalVisible && !isNil(selFund)}
          onClose={handleCloseDetailsModal}
          roadshowId={selectedRoadshow}
          fromMapView
          coordinates={coordinates}
          arePointsNear={arePointsNear}
          canSendMessage={role !== 'Service Provider'}
        />
      )}

      {selAllocatorCompany && (
        <AllocatorDetailsModalContainer
          allocator={selAllocatorCompany}
          showAllocatorProfile={modalVisible && !isNil(selAllocatorCompany)}
          onClose={handleCloseDetailsModal}
          selectedFund={selectedFund}
          representedFund={representedFund}
          roadshowId={selectedRoadshow}
          fromMapView
          coordinates={coordinates}
          arePointsNear={arePointsNear}
        />
      )}
      <div className="marker-list">
        <Loading spinning={loading}>
          <div className="marker-header">
            <div className="marker-title">{modalTitle}</div>
            <Input
              className="modalSearch"
              prefix={<FontAwesomeIcon icon={faSearch} />}
              placeholder={searchPlaceholder}
              allowClear={true}
              enterButton="Search"
              size="large"
              onChange={handleSearch}
            />
          </div>
          {isAllocatorsTab &&
            isRoadshow &&
            filteredRoadshowItems &&
            filteredRoadshowItems.length > 0 &&
            filteredRoadshowItems.map((rItem) => (
              <>
                {rItem.roadshows.map((roadshow) => (
                  <div
                    className="marker-item"
                    onClick={() => {
                      openDetails(rItem.allocator)
                      setSelectedRoadshow(roadshow.roadshowId)
                    }}
                  >
                    <div className="marker-item-roadshowDates">
                      {moment.utc(roadshow.dateFrom).local().format('D MMM')} -
                      {moment.utc(roadshow.dateTo).local().format('D MMM')}
                    </div>

                    <div className="marker-item-name">
                      {roadshow.roadshowName}
                    </div>
                    <div className="marker-item-companyname">
                      {`${rItem.allocator.contactName} (${rItem.allocator.companyName})`}
                    </div>

                    <div className="marker-item-footer">
                      <div className="marker-item-footer-broad">
                        <label className="marker-item-footer-title">
                          Primary Investor Category
                        </label>
                        <br />
                        {rItem.allocator.primaryInvestorCategory}
                      </div>
                      <div className="marker-item-companyname">
                        {`${rItem.allocator.contactName} (${rItem.allocator.companyName})`}
                      </div>
                      <div />
                    </div>
                  </div>
                ))}
              </>
            ))}
          {isRoadshow &&
            isFundsTab &&
            filteredRoadshowItems &&
            filteredRoadshowItems.length > 0 &&
            filteredRoadshowItems.map((fItem) => (
              <>
                {fItem.roadshows.map((roadshow) => (
                  <div
                    className="marker-item"
                    onClick={() => {
                      openDetails(fItem.fund)
                      //setRoadshowItems(roadshow.roadshowId);
                      setSelectedRoadshow(roadshow.roadshowId)
                    }}
                  >
                    <div className="marker-item-roadshowDates">
                      {moment.utc(roadshow.dateFrom).local().format('D MMM')} -
                      {moment.utc(roadshow.dateTo).local().format('D MMM')}
                    </div>
                    <div className="marker-item-name">
                      {roadshow.roadshowName}
                    </div>
                    <div className="marker-item-companyname">
                      {fItem.fund.name}
                    </div>
                    <div className="marker-item-footer">
                      <div className="marker-item-footer-broad">
                        <label className="marker-item-footer-title-roadshow">
                          Broad Strategy
                        </label>
                        <br />
                        {fItem.fund.broadStrategy}
                      </div>
                      <div className="marker-item-footer-match">
                        <label className="marker-item-footer-title-roadshow">
                          Match Score
                        </label>
                        <br />
                        {numberFormat.percent(fItem.fund.matchmakingScore, {
                          precision: 1,
                        }) ?? ''}
                      </div>
                      <div />
                    </div>
                  </div>
                ))}
              </>
            ))}
          {!isRoadshow &&
            isAllocatorsTab &&
            filteredMarkerItems &&
            filteredMarkerItems.length > 0 &&
            filteredMarkerItems.map((item) => [
              <div className="marker-item" onClick={() => openDetails(item)}>
                <div className="marker-item-name">{item.contactName}</div>
                <div className="marker-item-companyname">
                  {item.companyName}
                </div>
                <div className="marker-item-footer">
                  <div className="marker-item-footer-broad">
                    <label className="marker-item-footer-title">
                      Primary Investor Category
                    </label>
                    <br />
                    {item.primaryInvestorCategory}
                  </div>
                  {role !== 'Service Provider' && (
                    <div className="marker-item-footer-match">
                      <label className="marker-item-footer-title">
                        Match Score
                      </label>
                      <br />
                      {numberFormat.percent(item.matchmakingScore, {
                        precision: 1,
                      }) ?? ''}
                    </div>
                  )}
                </div>
              </div>,
            ])}
          {!isRoadshow &&
            isFundsTab &&
            filteredMarkerItems &&
            filteredMarkerItems.length > 0 &&
            filteredMarkerItems.map((item) => [
              <div className="marker-item" onClick={() => openDetails(item)}>
                <div className="marker-item-name">{item.name}</div>
                <div className="marker-item-companyname">
                  {item.company.name}
                </div>
                <div className="marker-item-footer">
                  <div className="marker-item-footer-broad">
                    <label className="marker-item-footer-title">
                      Broad Strategy
                    </label>
                    <br />
                    {item.broadStrategy}
                  </div>
                  <div className="marker-item-footer-match">
                    <label className="marker-item-footer-title">
                      Match Score
                    </label>
                    <br />
                    {numberFormat.percent(item.matchmakingScore, {
                      precision: 1,
                    }) ?? ''}
                  </div>
                  <div />
                </div>
              </div>,
            ])}
          {!isRoadshow &&
            isSPTab &&
            filteredMarkerItems &&
            filteredMarkerItems.length > 0 &&
            filteredMarkerItems.map((item) => [
              <div className="marker-item" onClick={() => openDetails(item)}>
                <div className="marker-item-name">{item.contactName}</div>
                <div className="marker-item-companyname">
                  {item.companyName}
                </div>
                <div className="marker-item-footer">
                  <div className="marker-item-footer-broad">
                    <label className="marker-item-footer-title">
                      Service Provider Category
                    </label>
                    <br />
                    {item.serviceProviderCategory}
                  </div>
                  {role === 'Manager' && (
                    <div className="marker-item-footer-match">
                      <label className="marker-item-footer-title">
                        Match Score
                      </label>
                      <br />
                      {numberFormat.percent(item.matchmakingScore, {
                        precision: 1,
                      }) ?? ''}
                    </div>
                  )}
                  <div />
                </div>
              </div>,
            ])}{' '}
        </Loading>
      </div>
    </>
  )
}

MarkersDataList.propTypes = {
  coordinates: PropTypes.array.isRequired,
  bounds: PropTypes.array.isRequired,
  isRoadshow: PropTypes.bool.isRequired,
  filter: PropTypes.array,
  searchTerm: PropTypes.string,
  showMatched: PropTypes.bool,
  pageSize: PropTypes.number,
  city: PropTypes.string,
  selectedFund: PropTypes.number,
  representedFund: PropTypes.string,
  meetingStatusFilter: PropTypes.array,
  role: PropTypes.string,
  resultId: PropTypes.object,
}

export default MarkersDataList
