import * as React from 'react'

const CompareFundsContext = React.createContext(null)
CompareFundsContext.displayName = 'CompareFundsContext'

export const CompareFundsContextProvider = CompareFundsContext.Provider

export function useCompareFundsContext() {
  const context = React.useContext(CompareFundsContext)
  if (!context) {
    throw new Error(
      'useCompareFundsContext must be used with CompareFundsContextProvider.'
    )
  }
  return context
}
