import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import isNil from 'lodash/isNil'
import AllocatorDetailsModal from '~/components/AllocatorDetailsModal'
import FundDetailsModal from '~/components/FundDetailsModal'
import ServiceProviderDetailsModal from '~/components/ServiceProviderDetailsModal/ServiceProviderDetailsModal'

const showMeetingRequestButton = (record, role) =>
  !record.hasMeeting ||
  role === 'manager' ||
  (record.hasMeeting &&
    !isNil(record?.meeting?.meetingStatus) &&
    (record.meeting.meetingStatus.toLowerCase() === 'cancelled' ||
      record.meeting.meetingStatus.toLowerCase() === 'declined'))

const SummitAttendeeProfilesContainer = ({
  category,
  role,
  selectedRecord,
  visible,
  hideModal,
  onSendMessage,
  onSendMeetingRequest,
  pastSummit,
  eventId,
}) => {
  const canSendMeetingRequest = useCallback(
    () =>
      (!selectedRecord?.hasMeeting ||
        selectedRecord?.meeting.meetingStatus.toUpperCase() === 'CANCELLED' ||
        selectedRecord?.meeting.meetingStatus.toUpperCase() === 'DECLINED' ||
        role === 'Manager') &&
      !pastSummit &&
      !(
        selectedRecord?.meeting?.meetingStatus.toUpperCase() === 'DECLINED' &&
        selectedRecord?.meeting?.sent &&
        role === 'Service Provider'
      ),
    [pastSummit, role, selectedRecord]
  )
  switch (category) {
    case 'allocators':
      return (
        <AllocatorDetailsModal
          company={selectedRecord}
          canSendMessage={
            role !== 'Manager' &&
            (role === 'Context' ||
              (role === 'Service Provider' &&
                !isNil(selectedRecord?.meeting) &&
                !isNil(selectedRecord?.meeting.conversationId)))
          }
          canSendMeetingRequest={canSendMeetingRequest()}
          onSendMessage={onSendMessage}
          cancel={hideModal}
          sendMeetingRequest={onSendMeetingRequest}
          visible={visible}
          pastSummit={pastSummit}
          eventId={eventId}
        />
      )
    case 'funds':
      return (
        <Modal
          visible={visible}
          footer={null}
          onCancel={hideModal}
          width="60%"
          bodyStyle={{ padding: 0 }}
        >
          <FundDetailsModal
            onClose={hideModal}
            fundId={selectedRecord.fundId}
            onSendRequest={onSendMeetingRequest}
            onSendMessage={onSendMessage}
            canSendMeetingRequest={showMeetingRequestButton(
              selectedRecord,
              role.toLowerCase()
            )}
            canSendMessage={
              role === 'Allocator' ||
              role === 'Context' ||
              (role === 'Service Provider' &&
                !isNil(selectedRecord?.meeting) &&
                !isNil(selectedRecord?.meeting.conversationId))
            }
            visible={visible}
            meeting={selectedRecord?.meeting}
            contacts={selectedRecord.contacts}
            pastSummit={pastSummit}
            blockedRequest={
              selectedRecord?.meeting?.meetingStatus?.toUpperCase() ===
                'DECLINED' &&
              role === 'Service Provider' &&
              selectedRecord?.meeting?.sent
            }
            eventId={eventId}
          />
        </Modal>
      )
    case 'service-providers':
      return (
        <ServiceProviderDetailsModal
          company={selectedRecord}
          companyId={selectedRecord.companyId}
          visible={visible}
          meeting={selectedRecord.meeting}
          contacts={selectedRecord.contacts}
          role={role}
          canSendMeetingRequest={showMeetingRequestButton(
            selectedRecord,
            role.toLowerCase()
          )}
          onSendRequest={onSendMeetingRequest}
          onSendMessage={onSendMessage}
          footer={false}
          pastSummit={pastSummit}
          eventId={eventId}
          onCancel={hideModal}
          canSendMessage={
            role === 'Allocator' || role === 'Context' || role === 'Manager'
          }
        />
      )
    default:
  }
  return null
}

SummitAttendeeProfilesContainer.propTypes = {
  category: PropTypes.oneOf([
    'allocators',
    'funds',
    'service-providers',
    'contexts',
  ]).isRequired,
  role: PropTypes.oneOf(['Allocator', 'Fund', 'Service Provider', 'Context'])
    .isRequired,
  selectedRecord: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  hideModal: PropTypes.func.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  onSendMeetingRequest: PropTypes.func.isRequired,
  pastSummit: PropTypes.bool,
  eventId: PropTypes.number.isRequired,
}
export default SummitAttendeeProfilesContainer
