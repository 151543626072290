import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Empty, Tabs } from 'antd'
import moment from 'moment'
import { getEventAgenda } from '~/actions/events'
import AgendaDay from '../AgendaDay'
import CenteredPrimaryRadioButtons from '../CenteredPrimaryRadioButtons'
import Loading from '../Loading'
import './Agenda.less'

const { TabPane } = Tabs

const Agenda = ({ eventId }) => {
  const [loading, setLoading] = useState(false)
  const [agendaDays, setAgendaDays] = useState(null)
  const [activeTab, setActiveTab] = useState('0')

  const getDayButtons = () => {
    if (agendaDays === null) return null
    else
      return agendaDays.map((x, index) => ({
        value: index.toString(),
        text: moment.utc(x.eventDate).format('dddd'),
      }))
  }

  useEffect(() => {
    setLoading(true)
    getEventAgenda(eventId)
      .then((response) => {
        setAgendaDays(response.data.result)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [eventId])

  return (
    <div style={{ minHeight: '500px' }}>
      <Loading spinning={loading}>
        {agendaDays && agendaDays.length > 0 && (
          <CenteredPrimaryRadioButtons
            selectedKey={activeTab}
            buttons={getDayButtons()}
            OnClickFunction={(e) => {
              setActiveTab(e.target.value)
            }}
          />
        )}
        {agendaDays && agendaDays.length > 0 ? (
          <Tabs
            animated={false}
            tabBarStyle={{ display: 'none' }}
            activeKey={activeTab.toString()}
          >
            {agendaDays.map((x, index) => (
              <TabPane tab={x.eventDate} key={index.toString()}>
                <AgendaDay
                  dayText={moment.utc(x.eventDate).format('dddd')}
                  items={x.eventAgendas}
                  eventId={eventId}
                  eventTimezone={x.eventTimezone}
                />
              </TabPane>
            ))}
          </Tabs>
        ) : !loading ? (
          <div className="empty-div">
            <Empty description="There is no agenda for this summit." />
          </div>
        ) : (
          <></>
        )}
      </Loading>
    </div>
  )
}

Agenda.propTypes = {
  eventId: PropTypes.number.isRequired,
}

export default Agenda
