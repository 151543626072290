import { DetailedAvatar } from '@context365/avatar'
import { Button } from '@context365/button'
import { Launch } from '@context365/icons'
import map from 'lodash/map'
import { useQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom-v5-compat'
import * as api from '~/api'
import { getInitials } from '~/utils'
import WidgetBlock from './WidgetBlock'

const NewCompanies = () => {
  const navigate = useNavigate()

  const { data = {} } = useQuery(
    'dashboardCompanies',
    () =>
      api.discovery.companies({
        page: 1,
        pageSize: 5,
        orderBy: '-CompanyId',
      }),
    {
      keepPreviousData: true,
    }
  )
  const companies = data.result?.results ?? []

  return (
    <WidgetBlock
      headerLeft="New Companies"
      headerRight={
        <Button
          variant="link"
          as={Link}
          to="/community/companies"
          iconRight={<Launch />}
          style={{ padding: 0 }}
          status="secondary"
        >
          See All
        </Button>
      }
    >
      <div className="flex flex-col gap-4">
        {map(companies, (company) => (
          <div
            key={company.companyId}
            className="flex flex-row justify-between"
          >
            <DetailedAvatar
              initials={getInitials(company.name)}
              name={company.name}
              company={company.category}
              className="cursor-pointer"
              onClick={() => navigate(`/profile/company/${company.companyId}`)}
            />
            <div className="flex justify-end">
              <Button
                variant="link"
                as={Link}
                to={`/profile/company/${company.companyId}`}
              >
                View Profile
              </Button>
            </div>
          </div>
        ))}
      </div>
    </WidgetBlock>
  )
}
export default NewCompanies
