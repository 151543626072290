import PropTypes from 'prop-types'
import { Empty, Tabs } from 'antd'
import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'
import AllocatorInfoDetails from '../AllocatorInfoDetails'
import AllocatorInfoProfile from '../AllocatorInfoProfile'
import ContextCompanyProfile from '../ContextCompanyProfile'
import { FundDetails } from '../FundDetailsProfile'
import FundDetailsValues from '../FundDetailsValues'
import MeetingQuestionAnswer from '../MeetingQuestionAnswer'
import Profile from '../ServiceProviderDetailsModal/ServiceProviderProfile'
import './CompanyProfileDetails.less'

const { TabPane } = Tabs

const CompanyProfileDetails = ({ company, fundId, showAnswers, meetingId }) => {
  const { categoryName } = company
  const role = useSelector((state) => state.auth.role)

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab={<span className="tab-pane">Profile</span>} key="1">
        {categoryName === 'Allocator' && (
          <AllocatorInfoProfile companyId={company.companyId} />
        )}
        {categoryName === 'Manager' &&
          (!isNil(fundId) ? (
            <div>
              <FundDetails
                fundId={fundId}
                showFundName
                showAUMandPerformance
                showTradingMethod={false}
              />
            </div>
          ) : (
            <Empty description="No fund chosen." />
          ))}
        {categoryName === 'Service Provider' && (
          <Profile companyId={company.companyId} />
        )}
        {categoryName === 'Context' && (
          <ContextCompanyProfile
            companyDescription={company.companyDescription}
            contacts={company.contacts}
          />
        )}
      </TabPane>
      {categoryName !== 'Service Provider' && categoryName !== 'Context' && (
        <TabPane tab={<span className="tab-pane">Details</span>} key="2">
          {categoryName === 'Allocator' && (
            <AllocatorInfoDetails companyId={company.companyId} />
          )}
          {categoryName === 'Manager' &&
            (!isNil(fundId) ? (
              <FundDetailsValues fundId={fundId} />
            ) : (
              <Empty description="No fund chosen." />
            ))}
        </TabPane>
      )}
      {showAnswers &&
        meetingId > 0 &&
        categoryName !== 'Service Provider' &&
        role !== 'Service Provider' &&
        categoryName !== 'Context' && (
          <TabPane tab={<span className="tab-pane">Answers</span>} key="3">
            <MeetingQuestionAnswer meetingId={meetingId} />
          </TabPane>
        )}
    </Tabs>
  )
}

CompanyProfileDetails.defaultTypes = {
  company: {},
  fundId: 0,
  showAnswers: false,
  meetingId: 0,
}
CompanyProfileDetails.propTypes = {
  company: PropTypes.object,
  fundId: PropTypes.number,
  showAnswers: PropTypes.bool,
  meetingId: PropTypes.number,
}

export default CompanyProfileDetails
