import qs from 'qs'
import { api as http } from '~/api/services'
import {
  FETCHING_CANCELLATION_REASONS,
  FETCH_ALL_REQUESTED_MEETINGS,
  FETCH_CANCELLATION_REASONS,
  FETCH_UPCOMING_MEETINGS,
} from '~/constants/types'

const getRequestParams = (params) => ({
  params,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
})

//latest meetings tab
export const fetchUpcomingMeetings = (params) => (dispatch) => {
  http
    .get('/meetings/upcoming/new', getRequestParams(params))
    .then((response) =>
      dispatch({
        type: FETCH_UPCOMING_MEETINGS,
        payload: response.data.result,
      })
    )
}

export const fetchMeetingsForProfile = (companyId, params) =>
  http.get(`/company/${companyId}/meetings`, getRequestParams(params))

export const fetchAllMeetingRequestsByCompany = (params) => (dispatch) => {
  http.get('/meetings/requests', getRequestParams(params)).then((response) =>
    dispatch({
      type: FETCH_ALL_REQUESTED_MEETINGS,
      payload: response.data.result,
    })
  )
}

export const sendOnlineMeetingRequest = (
  toCompanyCategory,
  toCompanyID,
  message,
  fundID,
  meetingDateTime,
  meetingEndDateTime,
  contactIDs,
  toContactIDs
) =>
  http.post(`/online/meetwith/${toCompanyCategory}/${toCompanyID}`, {
    message,
    fundID,
    meetingDateTime,
    meetingEndDateTime,
    contactIDs,
    toContactIDs,
  })

export const cancelMeeting = (
  meetingId,
  cancellationReasonId,
  cancellationReasonText
) =>
  http.delete(`/meetings/${meetingId}`, {
    data: { cancellationReasonId, cancellationReasonText },
  })

export const cancelOnlineMeeting = (meetingId) =>
  http.delete(`/meetings/online/${meetingId}`)

export const declineMeeting = (meetingId) =>
  http.patch(`/meetings/${meetingId}/decline`)

export const declineOnlineMeeting = (meetingId) =>
  http.put(`/meetings/online/${meetingId}/decline`)

export const updateAllMeetingsCompanyEvent = (
  companyEventId,
  meetingStatusId
) =>
  http.put(`/meetings/companyevent/${companyEventId}/status`, {
    MeetingStatusId: meetingStatusId,
  })

export const updateAllSummitsMeetings = (meetingStatusId) =>
  http.put('/meetings/summits/status', { MeetingStatusId: meetingStatusId })

export const getCancellationReasons = () => ({
  type: FETCHING_CANCELLATION_REASONS,
})

export const shouldFetchCancellationReasons = (state) =>
  !state.meetings.isFetchingCancellationReasons

export const fetchCancellingReasonsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchCancellationReasons(getState())) {
    dispatch(fetchCancellingReasons())
  }
}

export const fetchCancellingReasons = () => (dispatch) => {
  dispatch(getCancellationReasons())

  http.get('/meetings/cancellationReasons').then((response) =>
    dispatch({
      type: FETCH_CANCELLATION_REASONS,
      payload: response.data.result,
    })
  )
}

export const getMeetingList = (
  page,
  pageSize,
  type,
  searchQuery,
  meetingTypeId
) => {
  const params = { page, pageSize, meetingTypeId, q: searchQuery }

  return http.get(`meetings/${type}`, {
    params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  })
}
