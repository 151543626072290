import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Tabs } from 'antd'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import './ProfileBodyWithTabs.less'

const { TabPane } = Tabs

const ProfileBodyWithTabs = ({
  tabs,
  activeTabKey = null,
  onTabChange = null,
}) => {
  const [activeTab, setActiveTabKey] = useState(tabs[0].key || '0')

  const handleChange = useCallback((tabKey) => {
    setActiveTabKey(tabKey)
  }, [])

  const visibleActiveTabKey = isNil(
    //don't try to select a hidden tab
    find(tabs, (tab) => tab.key === activeTabKey)
  )
    ? activeTab
    : activeTabKey

  return (
    <Row>
      <Tabs
        className="Tab"
        activeKey={visibleActiveTabKey}
        onChange={onTabChange || handleChange}
      >
        {map(tabs, (tab, index) => (
          <TabPane
            tab={<span className="tab-pane">{tab.title}</span>}
            key={tab.key || index}
          >
            {tab.component}
          </TabPane>
        ))}
      </Tabs>
    </Row>
  )
}

ProfileBodyWithTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string.isRequired,
      component: PropTypes.object,
    })
  ),
  activeTabKey: PropTypes.string,
  onTabChange: PropTypes.func,
}

export default ProfileBodyWithTabs
