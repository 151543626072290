import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { getClickableLink } from '~/utils/helpers'
import './ServiceProviderContent.less'

const ServiceProviderContent = ({ serviceProviders }) => (
  <div className="ServiceProviderContent">
    {!isNil(serviceProviders.blogUrl) && !isEmpty(serviceProviders.blogUrl) && (
      <div className="ServiceProviderContent-content">
        <div className="ServiceProviderContent-title">BLOG</div>

        <a
          href={getClickableLink(serviceProviders.blogUrl)}
          rel="noreferrer"
          target="_blank"
          className="ServiceProviderContent-link"
        >
          {serviceProviders.blogUrl}
        </a>
      </div>
    )}
    {!isNil(serviceProviders.caseStudiesUrl) &&
      !isEmpty(serviceProviders.caseStudiesUrl) && (
        <div className="ServiceProviderContent-content">
          <div className="ServiceProviderContent-title">CASE STUDIES</div>

          <a
            href={getClickableLink(serviceProviders.caseStudiesUrl)}
            rel="noreferrer"
            target="_blank"
            className="ServiceProviderContent-link"
          >
            {serviceProviders.caseStudiesUrl}
          </a>
        </div>
      )}
    {!isNil(serviceProviders.webCastsUrl) &&
      !isEmpty(serviceProviders.webCastsUrl) && (
        <div className="ServiceProviderContent-content">
          <div className="ServiceProviderContent-title">WEBCASTS</div>
          <a
            href={getClickableLink(serviceProviders.webCastsUrl)}
            rel="noreferrer"
            target="_blank"
            className="ServiceProviderContent-link"
          >
            {serviceProviders.webCastsUrl}
          </a>
        </div>
      )}
    {!isNil(serviceProviders.whitepapersAndGuidesUrl) &&
      !isEmpty(serviceProviders.whitepapersAndGuidesUrl) && (
        <div className="ServiceProviderContent-content">
          <div className="ServiceProviderContent-title">
            {'WHITEPAPERS & GUIDES'}
          </div>
          <a
            href={getClickableLink(serviceProviders.whitepapersAndGuidesUrl)}
            rel="noreferrer"
            target="_blank"
            className="ServiceProviderContent-link"
          >
            {serviceProviders.whitepapersAndGuidesUrl}
          </a>
        </div>
      )}
  </div>
)

ServiceProviderContent.propTypes = {
  serviceProviders: PropTypes.object.isRequired,
}

export default ServiceProviderContent
