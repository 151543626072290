import * as Yup from 'yup'
import * as api from '~/api'
import {
  CheckboxGroupField,
  Form,
  UnsavedChangesAlert,
} from '~/components/Form'
import Loading from '~/components/Loading'
import EducationForm from '../forms/EducationForm'
import { useOnboardingQuery } from '../hooks'
import { useOnboardingContext } from '../OnboardingContext'
import {
  BackButton,
  NextButton,
  OnboardingFooter,
  OnboardingLayout,
  OnboardingSteps,
} from './OnboardingLayout'

const schema = Yup.object().shape({
  educations: Yup.array().of(
    Yup.object().shape({
      school: Yup.string().required(),
      areaOfStudy: Yup.string().required(),
    })
  ),
  finraExamIds: Yup.array().of(Yup.number().required()),
  certificationIds: Yup.array().of(Yup.number().required()),
})

export function ContactEducation({ steps }) {
  const { options, onNext } = useOnboardingContext()
  const { answers, isLoading, submit } = useOnboardingQuery(
    ['contact-education'],
    {
      getAnswers: () => api.onboarding.getContactEducationAnswers(),
      saveAnswers: (_, answers) =>
        api.onboarding.saveContactEducationAnswers(answers),
    }
  )

  if (isLoading) {
    return (
      <OnboardingLayout
        header={<OnboardingSteps steps={steps} />}
        body={
          <div className="flex items-center justify-center">
            <Loading />
          </div>
        }
      />
    )
  }

  return (
    <OnboardingLayout
      header={<OnboardingSteps steps={steps} />}
      body={
        <Form
          id="contact-education"
          className="mt-12 onboarding-form"
          schema={schema}
          defaultValues={answers}
          onSubmit={submit}
          onSuccess={onNext}
        >
          <div className="type-header-md text-center mb-4">
            Enter your education and certifications
          </div>
          <EducationForm />
          <CheckboxGroupField
            name="certificationIds"
            label="Professional Certifications"
            options={options.certifications}
          />
          <CheckboxGroupField
            name="finraExamIds"
            label="FINRA"
            options={options.finraExams}
          />
          <UnsavedChangesAlert />
        </Form>
      }
      footer={
        <OnboardingFooter
          leftContent={<BackButton />}
          rightContent={<NextButton type="submit" form="contact-education" />}
        />
      }
    />
  )
}
