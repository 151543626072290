const HorizontalInfoGraph = ({ part1, part2, part3, total }) => {
  return (
    <div className="type-body-regular-sm">
      <div className="flex mb-2">
        <div
          className="h-16 bg-success-action"
          style={{ width: `calc(${(part1 * 100) / total}%)` }}
        />
        <div
          className="h-16 bg-info-action"
          style={{ width: `calc(${(part2 * 100) / total}%)` }}
        />
        <div
          className="h-16 bg-error-action"
          style={{ width: `calc(${(part3 * 100) / total}%)` }}
        />
      </div>
      <div className="flex mb-2 items-center">
        <div className="h-3 w-3 bg-success-action mr-2" />
        {part1} Approved
      </div>
      <div className="flex mb-2 items-center">
        <div className="h-3 w-3 bg-info-action mr-2" />
        {part2} Pending
      </div>
      <div className="flex mb-2 items-center">
        <div className="h-3 w-3 bg-error-action mr-2" />
        {part3} Rejected
      </div>
    </div>
  )
}

export default HorizontalInfoGraph
