import { Button } from '@context365/button'
import { Modal } from '@context365/modals'
import { Tag } from '@context365/tag'
import { useTracking } from 'react-tracking'

const CreateCampaignPrompt = ({ visible, onSubmit }) => {
  const { trackEvent } = useTracking({ component: 'CreateCampaignPrompt' })

  return (
    <Modal.Container visible={visible} maxWidth={500}>
      <Modal.Body>
        <div className="space-y-6">
          <Tag color="blue">Finder</Tag>
          <div className="type-header-md">
            Which funds are a fit for my investment goals?
          </div>
          <div className="type-body-regular-md">
            Answer a few short questions to find relevant funds based on your
            current interests. You can create as many of these pipelines as
            you&apos;d like.
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-end">
        <Button
          variant="filled"
          onClick={() => {
            trackEvent({
              eventName: 'click',
              label: 'Complete Pipeline Questionnaire',
            })
            onSubmit()
          }}
        >
          Complete Pipeline Questionnaire
        </Button>
      </Modal.Footer>
    </Modal.Container>
  )
}

export default CreateCampaignPrompt
