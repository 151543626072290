import formatRelative from 'date-fns/formatRelative'
import enUS from 'date-fns/locale/en-US'
import { format as formatDate, toDate } from 'date-fns-tz'

export const parseDate = {
  fromUTC: (dateString) => toDate(dateString, { timeZone: 'UTC' }),
}

export const format = {
  systemEventDateTime: (date) =>
    formatDate(date, 'MMM d, yyyy h:mm a zzzz (OOOO)'),
  messageTimestamp: (date) => formatDate(date, 'h:mm a'),
  lastActivityDateTime: (date) => formatRelativeWithTime(date),
  messageGroupDate: (date) => formatRelativeWithoutTime(date),
}

const formatRelativeWithTimeTokens = {
  lastWeek: "eeee 'at' h:mmaaa",
  yesterday: "'Yesterday at' h:mmaaa",
  today: "'Today at' h:mmaaa",
  tomorrow: "'Tomorrow at' h:mmaaa",
  nextWeek: "eeee 'at' h:mmaaa",
  other: "M/d/yyyy 'at' h:mmaaa",
}

const formatRelativeWithTimeLocale = {
  ...enUS,
  formatRelative: (token) => formatRelativeWithTimeTokens[token],
}

function formatRelativeWithTime(date) {
  return formatRelative(date, new Date(), {
    locale: formatRelativeWithTimeLocale,
  })
}

const formatRelativeWithoutTimeTokens = {
  lastWeek: 'eeee',
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: 'd MMMM yyyy',
}

const formatRelativeWithoutTimeLocale = {
  ...enUS,
  formatRelative: (token) => formatRelativeWithoutTimeTokens[token],
}

function formatRelativeWithoutTime(date) {
  return formatRelative(date, new Date(), {
    locale: formatRelativeWithoutTimeLocale,
  })
}
