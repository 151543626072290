import { useState } from 'react'
import PropTypes from 'prop-types'
import { List } from 'antd'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import SimpleBar from 'simplebar-react'
import AllocatorDetailsModalContainer from '../AllocatorDetailsModalContainer'
import AllocatorCard from './AllocatorCard'

const AllocatorCardsList = ({ allocators }) => {
  const [selectedPerson, setSelectedPerson] = useState(null)
  const [detailsVisible, setDetailsVisibility] = useState(false)

  const initialItem = {
    fullName: null,
    jobPosition: null,
    description: null,
    imageUrl: null,
  }

  const onItemClick = (person) => {
    setDetailsVisibility(true)
    setSelectedPerson(person)
  }

  return (
    <>
      <SimpleBar
        style={{ maxHeight: 'calc(100vh - 200px)', overflowX: 'hidden' }}
        autoHide={false}
      >
        <List
          dataSource={Object.values(allocators)}
          renderItem={(item) =>
            !isEqual(item, initialItem) && (
              <AllocatorCard person={item} onClick={onItemClick} />
            )
          }
        />
      </SimpleBar>

      {detailsVisible && !isNil(selectedPerson) && (
        <AllocatorDetailsModalContainer
          allocator={selectedPerson}
          showAllocatorProfile={detailsVisible}
          onClose={() => {
            setDetailsVisibility(false)
            setSelectedPerson(null)
          }}
        />
      )}
    </>
  )
}

AllocatorCardsList.propTypes = {
  allocators: PropTypes.array.isRequired,
}

export default AllocatorCardsList
