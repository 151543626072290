import { FETCH_DISCOVER_LISTS } from '~/constants/types'
import { discoverLists } from '../api'

export const fetchDiscoverLists = () => (dispatch) => {
  discoverLists.getDiscoverLists().then((lists) =>
    dispatch({
      type: FETCH_DISCOVER_LISTS,
      payload: lists,
    })
  )
}
