import * as React from 'react'

export default function useOptions(defaultOptions = {}) {
  const [options, setOptions] = React.useState(defaultOptions)

  const setOption = React.useCallback((patch) => {
    setOptions((prevState) => ({
      ...prevState,
      ...(typeof patch === 'function' ? patch(prevState) : patch),
    }))
  }, [])

  return [options, setOption]
}
