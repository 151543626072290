import { useState } from 'react'
import { Button } from '@context365/button'
import {
  PlaceOutlined,
  ScheduleOutlined,
  TodayOutlined,
} from '@context365/icons'
import { Modal } from 'antd'
import moment from 'moment'
import MeetingDetailsController from '../MeetingDetails/MeetingDetailsController'
import CompanyMeetingAvatars from './CompanyMeetingAvatars'
import DownloadInvitationButton from './DownloadInvitationButton'

const MeetingCard = ({ meeting, refreshPage }) => {
  const [showMeetingDetails, setShowMeetingDetails] = useState(false)

  return (
    <>
      <Button
        as="div"
        className="cursor-pointer rounded p-4 my-4 type-body-regular-sm bg-white border border-white hover:border-secondary-100 hover:bg-secondary-2 hover:shadow-0 transition duration-300 ease-in"
        variant="none"
        key={meeting.meetingId}
        disabled={!meeting.isParticipant}
        onClick={() => setShowMeetingDetails(true)}
      >
        <div className="flex border-b p-4 pb-2 type-subtitle-xs">
          <div className="flex space-x-2 lg:space-x-8 w-full lg:w-1/2">
            <div className="flex">
              <PlaceOutlined size={16} className="mr-2 relative -top-0.5" />
              {meeting.meetingType}
            </div>
            <div className="flex">
              <TodayOutlined size={16} className="mr-2 relative -top-0.5" />
              {moment
                .utc(meeting.meetingDateTime)
                .local()
                .format('MMMM DD, YYYY')}
            </div>
            <div className="flex">
              <ScheduleOutlined size={16} className="mr-2 relative -top-0.5" />
              {moment.utc(meeting.meetingDateTime).local().format('hh:mm A')}
            </div>
          </div>
          <div className="flex justify-end w-full lg:w-1/2">
            {meeting.isParticipant && (
              <div className="flex text-right align-top">
                <DownloadInvitationButton meeting={meeting} />
              </div>
            )}
          </div>
        </div>
        <div className="p-4">
          <div className="flex space-x-4">
            {meeting.companies.map((c) => (
              <div key={c.companyId}>
                <div className="type-body-semibold-sm">{c.companyName}</div>
                <div className="type-subtitle-xxs">{c.category}</div>
                <div>
                  <CompanyMeetingAvatars
                    meeting={meeting}
                    participants={c.participants}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Button>
      {showMeetingDetails && (
        <Modal
          visible={showMeetingDetails}
          width="80%"
          footer={null}
          onCancel={() => {
            setShowMeetingDetails(false)
            refreshPage()
          }}
        >
          <MeetingDetailsController
            meetingId={meeting.meetingId}
            meetingType={meeting.meetingType}
          />
        </Modal>
      )}
    </>
  )
}

export default MeetingCard
