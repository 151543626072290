import PropTypes from 'prop-types'
import { Form } from 'antd'
import cx from 'classnames'
import LabelField from './LabelField'

const FormField = ({
  name,
  title,
  required = false,
  touched,
  errors,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  component,
  helpText = '',
  extraProps,
  fundId,
  isFormField = false,
  isEditing,
  labelClassName,
  className,
}) => {
  const Field = component

  const showError = (isEditing || touched[name]) && errors[name]

  return isFormField ? (
    <Form.Item
      name={name}
      className={cx('Question-col', className)}
      validateStatus={showError ? 'error' : 'success'}
      help={
        showError && typeof errors[name] === 'string' ? errors[name] : helpText
      }
    >
      {title && (
        <LabelField
          title={title}
          isRequired={required}
          className={labelClassName}
        />
      )}
      <Field
        name={name}
        title={title}
        errors={errors[name]}
        value={values[name]}
        values={values}
        onChange={handleChange}
        onBlur={handleBlur}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        fundId={fundId}
        {...extraProps}
      />
    </Form.Item>
  ) : (
    <div className={cx('Question-col pr-2.5', className)}>
      <Field
        name={name}
        errors={showError ? errors[name] : undefined}
        value={values[name]}
        values={values}
        onChange={handleChange}
        onBlur={handleBlur}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        errorMessage={showError ? errors[name] : undefined}
        helpMessage={helpText}
        label={title}
        required={required}
        {...extraProps}
      />
    </div>
  )
}

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  component: PropTypes.node,
  helpText: PropTypes.string,
  extraProps: PropTypes.object,
  fundId: PropTypes.number,
  isFormField: PropTypes.bool,
  isEditing: PropTypes.bool,
  labelClassName: PropTypes.string,
}

export default FormField
