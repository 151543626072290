import { useState } from 'react'
import PropTypes from 'prop-types'
import { message } from 'antd'
import filter from 'lodash/filter'
import isNil from 'lodash/isNil'
import * as api from '~/api'
import FundWizard from '../FundWizard'
import makeWizardSteps from '../makeWizardSteps'
import {
  BasicInfoQuestions,
  ConcentrationQuestions,
  FundDetailsQuestions,
  FundReturnQuestions,
  LiquidFundQuestions,
  ManagerDetailsQuestions,
  MarketingMaterialQuestions,
  MediaQuestions,
  ServiceProviderQuestions,
} from './LiquidStepQuestions'
import {
  BasicInfoValidator,
  ConcentrationValidator,
  FundDetailsValidator,
  FundReturnsValidator,
  LiquidFundQuestionsValidator,
  ManagerDetailsValidator,
  MarketingMaterialValidator,
  MediaValidator,
  ServiceProviderValidator,
} from './LiquidValidatorShape'
import '../FundWizard.less'

const STEPS = [
  {
    title: 'Basic Information',
    questions: BasicInfoQuestions,
    validator: BasicInfoValidator,
  },
  {
    title: 'Manager Details',
    questions: ManagerDetailsQuestions,
    validator: ManagerDetailsValidator,
  },
  {
    title: 'Fund Details',
    questions: FundDetailsQuestions,
    validator: FundDetailsValidator,
  },
  {
    title: 'Fund Returns',
    questions: FundReturnQuestions,
    validator: FundReturnsValidator,
  },
  {
    title: 'Marketing Material',
    questions: MarketingMaterialQuestions,
    validator: MarketingMaterialValidator,
  },
  {
    title: 'Media',
    questions: MediaQuestions,
    validator: MediaValidator,
  },
  {
    title: 'Service Providers',
    questions: ServiceProviderQuestions,
    validator: ServiceProviderValidator,
  },
  {
    title: 'Liquid Fund Questions',
    questions: LiquidFundQuestions,
    validator: LiquidFundQuestionsValidator,
  },
  {
    title: 'Sector and Geographic Concentration',
    questions: ConcentrationQuestions,
    validator: ConcentrationValidator,
  },
]

export const LiquidFundStep = makeWizardSteps(
  {
    BasicInfo: 'Basic Information',
    ManagerDetails: 'Manager Details',
    FundDetails: 'Fund Details',
    FundReturns: 'Fund Returns',
    MarketingMaterial: 'Marketing Material',
    Media: 'Media',
    ServiceProviders: 'Service Providers',
    LiquidFundQuestions: 'Liquid Fund Questions',
    Concentration: 'Sector and Geographic Concentration',
  },
  STEPS
)

const DEFAULT_VALUES = {
  fundName: '',
  strategyDescription: '',
  attributeIds: [],
  investmentVehicleIds: [],
  managerBiography: null,
  managerContinentId: null,
  managerCountryId: null,
  managerStateProvince: null,
  managerCity: null,
  inceptionDate: null,
  CIK: null,
  CRD: null,
  domicileIds: [],
  domicileCountryIds: [],
  fundAum: null,
  aumCapacity: null,
  proprietaryAssetsPercentageOfFundAum: null,
  proportionOfPortfolioEasilyPriced: null,
  leverageUtilization: null,
  largestNotionalPosition: null,
  minimumInvestment: null,
  minimumInvestmentForManagedAccounts: null,
  daysOfCapitalLockup: null,
  postLockupLiquidityId: null,
  daysNoticeForRedemption: null,
  managementFeePercentage: null,
  performanceFeePercentage: null,
  performanceHurdleRate: null,
  isFirstLossCapitalAccepted: null,
  quantitativeReturns: null,
  marketingMaterialUrls: [],
  distributorEmail: null,
  auditorIds: [],
  primeBrokerIds: [],
  fundAdministratorIds: [],
  custodianIds: [],
  legalCounselIds: [],
  marketerIds: [],
  fundTraderIds: [],
  complianceProviderIds: [],
  expectedTargetNetAnnualReturn: null,
  expectedTargetStandardDeviationOfReturns: null,
  benchmarkIndexId: null,
  expectedTargetBetaToYourBenchMark: null,
  broadStrategyId: null,
  subStrategyId: null,
  investmentDecisionProcessId: null,
  netDollarExposureId: null,
  primaryInstrumentIds: [],
  portfolioTurnedOverYearlyCount: null,
  tradingDaysNeededToUnwindMostIlliquidPosition: null,
  isPortfolioGeographicallyDiverse: null,
  geographyIds: [],
  isPortfolioSectorDiverse: null,
  industryIds: [],
  media: [],
  links: [],
}

const LiquidFundWizard = ({
  fundId,
  onSave,
  defaultStepNumber,
  markAsConfirmed,
  markAsEdited,
  onSwitchFundType,
}) => {
  const [formFundId, setFormFundId] = useState(fundId)

  const handleSave = (vals, formFinished, doneSaving) => {
    const model = {
      ...vals,
      links: filter(vals.links, (x) => !!x.url),
      isDisclaimerConfirmed: formFinished,
    }

    if (isNil(formFundId)) {
      api.funds
        .createLiquidFund(model)
        .then((res) => {
          if (formFinished) {
            markAsConfirmed()
            message.success('Fund saved successfully')
            onSave()
          }
          api.funds.trackFundStep(res.data.result.fundId, 0)
          setFormFundId(res.data.result.fundId)
        })
        .catch(() =>
          message.error('An error occurred while attempting to save your fund.')
        )
        .finally(() => doneSaving())
    } else {
      api.funds
        .saveLiquidFundProfile(formFundId, model)
        .then(() => {
          if (formFinished) {
            markAsConfirmed()
            message.success('Fund saved successfully')
            onSave()
          }
        })
        .catch(() =>
          message.error('An error occurred while attempting to save your fund.')
        )
        .finally(() => doneSaving())
    }
  }
  return (
    <FundWizard
      fundId={formFundId}
      onSave={handleSave}
      steps={STEPS}
      defaultValues={DEFAULT_VALUES}
      defaultStep={defaultStepNumber}
      markAsEdited={markAsEdited}
      onSwitchFundType={onSwitchFundType}
    />
  )
}

LiquidFundWizard.propTypes = {
  fundId: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  defaultStepNumber: PropTypes.number,
  markAsEdited: PropTypes.func.isRequired,
  markAsConfirmed: PropTypes.func.isRequired,
  onSwitchFundType: PropTypes.func.isRequired,
}

export default LiquidFundWizard
