import isEmpty from 'lodash/isEmpty'
import { getInitials } from '~/utils/helpers'
import AvatarBadgeGroup from '../AvatarBadgeGroup'

const CompanyMeetingAvatars = ({ meeting, participants }) => {
  const avatars = participants.map((p) => {
    return {
      id: p.contactId,
      firstName: p.firstName,
      lastName: p.lastName,
      initials: getInitials(p.contactName),
      description: `${p.contactName} ${
        !isEmpty(p.jobTitle) ? `(${p.jobTitle})` : ''
      }`,
      img: p.imageUrl,
      participantStatusId: p.participantStatusId,
    }
  })

  return (
    <div className="mt-2">
      {isEmpty(avatars) ? (
        <div>This meeting has no participants yet</div>
      ) : (
        <AvatarBadgeGroup
          organizerId={meeting?.member?.contactId}
          avatars={avatars}
          className="AvatarGroup-start"
        />
      )}
    </div>
  )
}

export default CompanyMeetingAvatars
