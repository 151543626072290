import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Empty, Modal, message } from 'antd'
import axios from 'axios'
import filter from 'lodash/filter'
import fromPairs from 'lodash/fromPairs'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchCompanyIfNeeded } from '~/actions/company'
import { setPageTitle } from '~/actions/title'
import { api as http } from '~/api/services'
import DealProfileContainer from '~/components/DealProfileContainer/DealProfileContainer'
import DiscoverServiceProviderDetailsModal from '~/components/DiscoverServiceProviderDetailsModal/DiscoverServiceProviderDetailsModal'
import { getInitialValues } from '~/components/DynamicForm/DynamicForm'
import Loading from '~/components/Loading'
import ServiceProviderWizard from '~/components/ServiceProviderWizard'
import { ServiceProviderValidatorShape } from '~/components/ServiceProviderWizard/ServiceProviderValidatorShape'
import * as types from '~/constants/types/serviceprovideronboarding'
import { getCompanyId } from '~/selectors/auth'
import useDealsParsing from '~/utils/hooks/useDealsParsing'
import useServiceProviderParsing from '~/utils/hooks/useServiceProviderParsing'
import { dealsAnswers, serviceProvidersAnswers } from '~/utils/wizardContainer'

const converters = {
  'Numeric - INT': (n) => {
    if (isNil(n)) {
      return null
    }
    return parseInt(n, 10)
  },
  'Numeric - FLOAT': (n) => {
    if (isNil(n)) {
      return null
    }
    return parseFloat(n)
  },
  'Boolean': (b) => {
    if (isNil(b)) {
      return null
    }
    return b === 'true'
  },
  'Upload - Excel': (v) => {
    if (isNil(v)) {
      return v
    }
    return encodeURI(v)
  },
  'Upload - File': (v) => {
    if (isNil(v)) {
      return v
    }
    return encodeURI(v)
  },
}

const preSaveConverters = {
  'Upload - Excel': (v) => {
    if (isNil(v)) {
      return v
    }
    return decodeURI(v)
  },
  'Upload - File': (v) => {
    if (isNil(v)) {
      return v
    }
    return decodeURI(v)
  },
}

const getPreSaveData = (data, questions, converters) => {
  const typeLookup = fromPairs(
    map(questions.serviceProviderQuestions, (q) => [
      q.serviceProviderQuestionId,
      get(converters, q.questionType, (elem) => elem),
    ])
  )

  return {
    ...data,
    answers: map(data.answers, (a) => ({
      ...a,
      responseText: get(
        typeLookup,
        a.serviceProviderQuestionId,
        (i) => i
      )(a.responseText),
    })),
  }
}

const getConvertedAnswers = (answers, questions, converters) => {
  const typeLookup = fromPairs(
    map(questions, (q) => [
      q.questionId,
      get(converters, q.questionType, (elem) => elem),
    ])
  )

  const convertedAnswers = map(answers, (answer) => ({
    ...answer,
    responseText: get(
      typeLookup,
      answer.serviceProviderQuestionId,
      (i) => i
    )(answer.responseText), // typeLookup[answer.fundQuestionId](answer.responseText),
  }))
  return convertedAnswers
}

const ServiceProviderWizardContainer = ({
  onWizardClosed = () => {},
  usage = 'create',
  type = 'deals',
  status = 'draft',
  dealId = null,
}) => {
  const { push } = useHistory()

  const companyId = useSelector(getCompanyId)
  const [questionTypes, setQuestionTypes] = useState([])
  const [currentStep, setCurrentStep] = useState(1)
  const [questions, setQuestions] = useState({})
  const [loading, setLoading] = useState(false)
  const [showServiceProviderProfile, setShowServiceProviderProfile] =
    useState(false)
  const parsedSPAnswers = useServiceProviderParsing()
  const parsedDealsAnswers = useDealsParsing(questions)
  const method = usage === 'create' ? 'post' : 'patch'

  const { previewObject, state, dispatch } =
    type === 'deals' ? parsedDealsAnswers : parsedSPAnswers
  const [finished, setFinished] = useState(false)
  const publishText = type === 'deals' ? 'Deal' : 'Profile'

  const [stepsInErrorId, setStepsInErrorId] = useState([])
  const reduxDispatch = useDispatch()

  const questionsWithOptions = new Set(
    map(
      filter(questionTypes, (qt) => qt.answers),
      (qt) => qt.questionTypeId
    )
  )

  useEffect(
    () => () => {
      reduxDispatch(setPageTitle(null))
    },
    [reduxDispatch]
  )

  useEffect(() => {
    setLoading(true)

    const requests = [
      http.get('/questions/types'),
      http.get(`/${type}/questions`),
    ]

    if (type === 'serviceproviders' && !isNil(companyId) && usage === 'edit') {
      requests.push(http.get(`/serviceproviders/${companyId}/answers`))
    }

    if (type === 'deals' && usage === 'edit')
      requests.push(http.get(`/deals/answers/${dealId}`))

    axios
      .all(requests)
      .then(
        axios.spread(
          (questionTypesResponse, questionsResponse, answersResponse) => {
            setQuestionTypes(questionTypesResponse.data.result)
            setQuestions(questionsResponse.data.result)

            const serviceProviderQuestions = questionsResponse.data.result

            if (!isNil(answersResponse)) {
              const { serviceProviderName, answers } =
                answersResponse.data.result

              dispatch({
                type: types.ADD,
                payload: { serviceProviderName },
              })

              reduxDispatch(setPageTitle(serviceProviderName))

              dispatch({
                type: types.APPEND_ANSWERS,
                payload: getConvertedAnswers(
                  answers,
                  serviceProviderQuestions,
                  converters
                ),
              })
            }
          }
        )
      )
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('[ServiceProviderWizardContainer]::Error', err)
      })
      .finally(() => setLoading(false))
  }, [companyId, dealId, dispatch, reduxDispatch, type, usage])

  useEffect(() => {
    const { ...data } = state
    if (finished) {
      setLoading(true)
      setFinished(false)
      const serviceProviderEndpoint = isNil(companyId)
        ? '/serviceproviders/new'
        : `/serviceproviders/${companyId}`

      const dealsEndpoint =
        usage === 'create'
          ? '/deals?publishDeal=false'
          : `/deals/${dealId}?publishDeal=false`
      http[method](
        type === 'deals' ? dealsEndpoint : serviceProviderEndpoint,
        getPreSaveData(data, questions, preSaveConverters)
      )
        .then(() => {
          message.success(`${publishText} saved successfully.`)
          onWizardClosed()
          reduxDispatch(setPageTitle(null))
        })
        .catch((err) => {
          message.error(`Could not save ${publishText}.`, err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [
    state,
    push,
    companyId,
    questions,
    reduxDispatch,
    finished,
    usage,
    dealId,
    type,
    method,
    publishText,
    onWizardClosed,
  ])

  const getStepQuestions = (stepNumber) =>
    filter(questions, (q) => q.stepNumber === stepNumber)

  const getStepAnswers = () => state.answers

  const changeSteps = (step) => {
    const calculatedStep = step + 1
    setCurrentStep(calculatedStep)
  }

  const handleNavigation = (
    questions,
    values,
    actionType,
    isInitial = false,
    isLast = false,
    nextStep = null,
    saveChanges = false,
    publish = false,
    isValid = true
  ) => {
    if (isInitial) {
      dispatch({
        type: types.ADD,
        payload: {
          serviceProviderName: values.Name,
        },
      })

      reduxDispatch(setPageTitle(values.Name))
    }
    const answers =
      type === 'deals'
        ? dealsAnswers(questions, values, questionsWithOptions)
        : serviceProvidersAnswers(questions, values, questionsWithOptions)
    const action = {
      type: actionType,
      payload: answers,
    }

    if (publish) {
      setLoading(true)
      if (isValid) {
        const serviceProviderAnswersData = [
          ...filter(
            state.answers,
            (ans) =>
              !includes(
                map(answers, (ans) => ans.serviceProviderQuestionId),
                ans.serviceProviderQuestionId
              )
          ),
          ...answers,
        ]
        const dealsAnswersData = [
          ...filter(
            state.answers,
            (ans) =>
              !includes(
                map(answers, (ans) => ans.questionId),
                ans.questionId
              )
          ),
          ...answers,
        ]

        const answersData =
          type === 'deals' ? dealsAnswersData : serviceProviderAnswersData
        const url =
          type === 'deals'
            ? usage === 'create'
              ? '/deals?publishDeal=true'
              : `/deals/${dealId}?publishDeal=true`
            : `/serviceproviders/${companyId}/publish`
        http[type === 'deals' ? method : 'post'](url, {
          answers: answersData.filter(
            (answer) => answer.responseText !== undefined
          ),
        })
          .then(() => {
            message.success(
              type === 'deals'
                ? usage === 'create'
                  ? 'Success! Your deal has been added and will appear in the search results on the Release date.'
                  : 'Success! Your deal has been edited successfully'
                : 'Profile published successfully'
            )
            reduxDispatch(fetchCompanyIfNeeded(companyId))
            setShowServiceProviderProfile(false)
            onWizardClosed()
            setLoading(false)
          })
          .catch(() => {
            message.error(`Could not publish ${publishText}`)
            setLoading(false)
          })
      } else {
        setLoading(false)
        message.error(
          `Could not publish ${publishText}. Please check highlighted steps.`
        )
      }
    }

    if (saveChanges) {
      if (isValid) {
        action.meta = {
          finished: true,
        }
        dispatch(action)
        setFinished(true)
      } else {
        message.error(
          `Could not save ${publishText}. Please check highlighted steps.`
        )
      }
    }

    if (!isEmpty(stepsInErrorId)) {
      validateBeforeSave(answers, [...state.answers])
        .then(() => {
          setStepsInErrorId([])
        })
        .catch((err) => {
          getStepInError(err.inner)
        })
    }

    dispatch(action)

    if (!isLast) {
      if (isNil(nextStep)) {
        // if (isNil(fundId)) {
        //   setCurrentStep(0);
        // }
        setCurrentStep(0)
      } else {
        setCurrentStep(nextStep)
      }
    }
  }
  if (isEmpty(questions)) {
    return (
      <Loading spinning={loading}>
        <Empty />
      </Loading>
    )
  }
  const allQuestions = [...questions]

  const validateBeforeSave = (answers, allAnswers) => {
    answers.forEach((newAnswer) => {
      const match = allAnswers.find(
        (ans) => ans.shortName === newAnswer.shortName
      )
      const index = allAnswers.indexOf(match)
      allAnswers.splice(index, 1, newAnswer)
    })
    const answersLookup = getInitialValues(
      allQuestions,
      allAnswers,
      questionsWithOptions
    )

    const validator = ServiceProviderValidatorShape(allQuestions)

    return validator.validate(answersLookup, { abortEarly: false })
  }

  const calculateStep = (step) => {
    if (step >= 8) return step - 1
    else return step
  }

  const getStepInError = (errorList) => {
    const errorStepsList = []
    errorList.forEach((error) => {
      const step = allQuestions.find(
        (a) => a.shortName === error.path
      ).stepNumber
      errorStepsList.push(calculateStep(step))
    })
    setStepsInErrorId(errorStepsList)
  }

  return (
    <>
      <ServiceProviderWizard
        currentStep={currentStep}
        questions={getStepQuestions(currentStep)}
        companyId={companyId}
        answers={getStepAnswers(currentStep)}
        questionsWithOptions={questionsWithOptions}
        loading={loading}
        onNavigation={handleNavigation}
        onPreview={() => setShowServiceProviderProfile(true)}
        onStepChange={changeSteps}
        stepsInErrorId={stepsInErrorId}
        type={type}
        usage={usage}
        status={status.toLowerCase()}
      />
      {showServiceProviderProfile && (
        <Modal
          visible={showServiceProviderProfile}
          footer={null}
          onCancel={() => setShowServiceProviderProfile(false)}
          width="900px"
          bodyStyle={{ padding: 0 }}
          centered
        >
          {type === 'serviceproviders' ? (
            <DiscoverServiceProviderDetailsModal
              serviceProvider={previewObject}
              hideActions={true}
              isOnPreview={true}
              visible={showServiceProviderProfile}
              companyId={companyId}
              onCancel={() => setShowServiceProviderProfile(false)}
            />
          ) : (
            <DealProfileContainer
              deal={previewObject}
              visible={showServiceProviderProfile}
              onCancel={() => setShowServiceProviderProfile(false)}
              showHeader={true}
              hideActions={true}
              showMeetings={false}
            />
          )}
        </Modal>
      )}
    </>
  )
}

ServiceProviderWizardContainer.propTypes = {
  onWizardClosed: PropTypes.func,
  usage: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
  dealId: PropTypes.number,
}

export default ServiceProviderWizardContainer
