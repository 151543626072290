import {
  REFRESH_TOKEN,
  SIGNIN_USER,
  SIGNOUT_USER,
  SWITCH_COMPANY,
  UPDATE_AGREEMENT_USER,
  UPDATE_IS_CFN_ALLOCATOR,
  UPDATE_IS_CFN_INTERESTED_IN_DEALS,
  UPDATE_IS_CFN_NOT_INTERESTED_IN_DEALS,
  UPDATE_ONBOARDED_USER,
  UPDATE_USER,
} from '~/constants/types/auth'

const initialState = {
  accessToken: null,
  refreshToken: null,
  claims: null,
  contact: null,
  company: null,
  role: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SIGNIN_USER:
    case SIGNOUT_USER:
    case SWITCH_COMPANY:
    case REFRESH_TOKEN:
      return { ...state, ...action.payload }
    case UPDATE_ONBOARDED_USER:
      return { ...state, contact: { ...state.contact, hasOnBoarded: true } }
    case UPDATE_AGREEMENT_USER:
      return {
        ...state,
        contact: { ...state.contact, subscriptionAgreementApproved: true },
      }
    case UPDATE_IS_CFN_ALLOCATOR:
      return {
        ...state,
        contact: { ...state.contact, isCFNAllocator: true },
      }
    case UPDATE_IS_CFN_INTERESTED_IN_DEALS:
      return {
        ...state,
        contact: { ...state.contact, isCFNInterestedInDeals: true },
      }
    case UPDATE_IS_CFN_NOT_INTERESTED_IN_DEALS:
      return {
        ...state,
        contact: { ...state.contact, isCFNInterestedInDeals: false },
      }
    case UPDATE_USER: {
      const { imageURL, imageBase64, address, ...contact } = action.payload
      return {
        ...state,
        contact: {
          ...state.contact,
          ...contact,
          imageUrl: imageURL ?? imageBase64 ?? null,
          ...address,
        },
      }
    }
    default:
      return state
  }
}
