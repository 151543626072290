import PropTypes from 'prop-types'
import { Button, Checkbox, Input, Radio } from 'antd'
import get from 'lodash/get'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import * as yup from 'yup'
import MeetingWith from './MeetingWith'

const { TextArea } = Input

const validator = yup.object().shape({
  wouldMeetAgain: yup.boolean().required(),
  ratingIndicationIds: yup.array(yup.number()).when('wouldMeetAgain', {
    is: false,
    then: yup.array(yup.number()).required(),
  }),
  comment: yup.string(),
})

const AllocatorToManagerRatingForm = ({
  meeting,
  indications,
  onChange,
  onSave,
  isSaving,
}) => {
  const {
    meetingId,
    meetingDateTime,
    toCompanyId,
    toCompanyName,
    contacts,
    isValid,
    rating,
    meetingType,
  } = meeting

  const comment = get(rating, 'comment', '')
  const wouldMeetAgain = get(rating, 'wouldMeetAgain', null)
  const ratingIndicationIds = get(rating, 'ratingIndicationIds', [])

  const indicationOptions = sortBy(
    map(indications, (i) => ({
      label: i.description,
      value: i.ratingIndicationId,
    })),
    (opt) => (opt.value === 0 ? 9999 : opt.value)
  )

  const showIndications = wouldMeetAgain === false

  const handleFollowupChange = (e) => {
    const { value } = e.target
    const newRatingValues = {
      wouldMeetAgain: value,
      ratingIndicationIds: value ? [] : ratingIndicationIds,
      comment,
    }
    onChange({
      meetingId,
      isValid: validator.isValidSync(newRatingValues),
      rating: newRatingValues,
    })
  }

  const handleIndicationChange = (indicationIds) => {
    const newRatingValues = {
      wouldMeetAgain,
      ratingIndicationIds: indicationIds,
      comment,
    }
    onChange({
      meetingId,
      isValid: validator.isValidSync(newRatingValues),
      rating: newRatingValues,
    })
  }

  const handleCommentChange = (e) => {
    const { value } = e.target
    const newRatingValues = {
      wouldMeetAgain,
      ratingIndicationIds,
      comment: value,
    }
    onChange({
      meetingId,
      isValid: validator.isValidSync(newRatingValues),
      rating: newRatingValues,
    })
  }

  const handleSave = () => {
    onSave({
      meetingId,
      toCompanyId,
      ...rating,
    })
  }

  return (
    <div className="AllocatorToManagerRatingForm">
      <MeetingWith
        companyName={toCompanyName}
        meetingDateTime={meetingDateTime}
        contacts={contacts}
        meetingType={meetingType}
      />
      <div className="AllocatorToManagerRatingForm-rating">
        <p className="AllocatorToManagerRatingForm-rating-label">
          Would you take a follow up meeting with {toCompanyName}?
        </p>
        <Radio.Group onChange={handleFollowupChange} value={wouldMeetAgain}>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
        <div
          className="AllocatorToManagerRatingForm-rating-indication"
          style={{ display: showIndications ? 'block' : 'none' }}
        >
          <p className="AllocatorToManagerRatingForm-rating-label">
            Please indicate why below:
          </p>
          <Checkbox.Group
            options={indicationOptions}
            onChange={handleIndicationChange}
            value={ratingIndicationIds}
          />
        </div>
        <div className="AllocatorToManagerRatingForm-rating-comment">
          <TextArea
            rows={1}
            value={comment}
            onChange={handleCommentChange}
            placeholder="Type here ..."
          />
        </div>
      </div>
      <div className="AllocatorToManagerRatingForm-actions">
        <Button
          type="primary"
          onClick={handleSave}
          disabled={!isValid || isSaving >= 0}
          loading={isSaving === meetingId}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

AllocatorToManagerRatingForm.propTypes = {
  meeting: PropTypes.shape({
    meetingId: PropTypes.number.isRequired,
    fundId: PropTypes.number,
    eventId: PropTypes.number,
    companyId: PropTypes.number,
    companyName: PropTypes.string,
    categoryId: PropTypes.number,
    categoryName: PropTypes.string,
    toCompanyId: PropTypes.number.isRequired,
    toCompanyName: PropTypes.string.isRequired,
    toCategoryId: PropTypes.number.isRequired,
    toCategoryName: PropTypes.string.isRequired,
    meetingDateTime: PropTypes.string.isRequired,
    meetingTimeZone: PropTypes.string.isRequired,
    meetingRatingId: PropTypes.number,
    meetingType: PropTypes.oneOf(['Online', 'Summit', 'Roadshow']),
    contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
    isValid: PropTypes.bool.isRequired,
    rating: PropTypes.shape({
      rating: PropTypes.number,
      comment: PropTypes.string,
      wouldMeetAgain: PropTypes.bool,
      ratingIndicationIds: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
  }).isRequired,
  indications: PropTypes.arrayOf(
    PropTypes.shape({
      ratingIndicationId: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.number.isRequired,
}

export default AllocatorToManagerRatingForm
