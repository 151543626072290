import * as React from 'react'
import { useLiveRef } from '@context365/hooks'
import debounce from 'lodash/debounce'

export default function useDebounced(fn, wait = 0) {
  const fnRef = useLiveRef(fn)
  const isMounted = useIsMounted()

  return React.useMemo(
    () =>
      debounce((...args) => {
        if (!isMounted()) {
          return undefined
        }
        return fnRef.current(...args)
      }, wait),
    [fnRef, isMounted, wait]
  )
}

function useIsMounted() {
  const isMounted = React.useRef(false)

  React.useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return React.useCallback(() => isMounted.current, [])
}
