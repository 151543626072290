import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import toPairs from 'lodash/toPairs'
import { api as http } from '~/api/services'
import AllocatorCardsList from '../AllocatorCardsList/AllocatorCardsList'
import Loading from '../Loading'
import ProfileBodyWithTabs from '../ProfileBodyWithTabs'
import ProfileHeaderWithButtons from '../ProfileHeaderWithButtons'
import ProfileOverviewTab from '../ProfileOverviewTab'
import AllocatorCompanyProfile from './AllocatorCompanyProfile'
import './AllocatorCompanyDetailsModal.less'

const ABOUT_TAB_KEYS = [
  'portfolioSize',
  'fundDomicileInterest',
  'investmentVehicle',
  'minimumInvestmentSize',
  'averageInvestmentSize',
]
const ABOUT_TAB_KEYS_SIDE = [
  'primaryInvestorCategory',
  'secondaryInvestorCategory',
  'website',
]

const getAllocatorCompanyProfile = (companyId) =>
  http.get(`allocators/${companyId}`)

const AllocatorCompanyDetailsModal = ({ companyId, visible }) => {
  const [loading, setLoading] = useState(false)
  const [companyProfile, setCompanyProfile] = useState({})

  useEffect(() => {
    if (visible) {
      setLoading(true)
      setCompanyProfile({})

      getAllocatorCompanyProfile(companyId)
        .then((response) => {
          setLoading(false)
          const profile = response.data.result
          setCompanyProfile(profile)
        })
        .catch(() => {
          setLoading(false)
          setCompanyProfile({})
        })
    }
  }, [companyId, visible])

  const getAllocatorData = useCallback(
    () =>
      map(toPairs(companyProfile), (f) => ({
        label: startCase(f[0]),
        value: f[1],
        name: f[0],
      })),
    [companyProfile]
  )

  const getTabs = useCallback(() => {
    const allocatorData = getAllocatorData()
    const tabs = [
      {
        title: 'Overview',
        component: <AllocatorCompanyProfile company={companyProfile} />,
      },
      {
        title: 'Details',
        component: (
          <ProfileOverviewTab
            postedBy="allocator"
            sections={allocatorData.filter(
              (f) => ABOUT_TAB_KEYS.indexOf(f.name) > -1
            )}
            sideSections={allocatorData.filter(
              (f) =>
                ABOUT_TAB_KEYS_SIDE.indexOf(f.name) > -1 &&
                !isNil(f.value) &&
                !isEmpty(f.value)
            )}
          />
        ),
      },
      {
        title: 'Allocators',
        component: <AllocatorCardsList allocators={companyProfile.contacts} />,
      },
    ]

    return filter(tabs, (t) => !isNil(t))
  }, [companyProfile, getAllocatorData])

  return (
    <div className="discover-service-provider-details-container">
      {!isEmpty(companyProfile) && (
        <Loading spinning={loading}>
          <ProfileHeaderWithButtons
            name={companyProfile.companyName}
            address={companyProfile.address}
            city={companyProfile.city}
            state={companyProfile.state}
            hideActions={true}
          />
          <ProfileBodyWithTabs tabs={getTabs()} />
        </Loading>
      )}
    </div>
  )
}

AllocatorCompanyDetailsModal.propTypes = {
  visible: PropTypes.bool,
  companyId: PropTypes.number,
}

export default AllocatorCompanyDetailsModal
