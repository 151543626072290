import * as yup from 'yup'

export const schema = yup.object().shape({
  ConsultantEmail: yup.string().email('Enter valid email address!').nullable(),
  MaxFundAUM: yup
    .number()
    .nullable()
    .moreThan(
      yup.ref('MinFundAUM'),
      'Fund AUM Maximum cannot be less than Fund AUM Minimum'
    ),
  MaxFirmAUM: yup
    .number()
    .moreThan(
      yup.ref('MinFirmAUM'),
      'Firm AUM Maximum cannot be less than Firm AUM Minimum'
    )
    .nullable(),
})
export const validateAnswer = (answer, value) =>
  schema.isValid({
    [answer]: value,
  })
