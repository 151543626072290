import PropTypes from 'prop-types'
import ContactName from '../../ContactName/ContactName'

const CompanyContact = ({ contacts, fulWidth }) => (
  <div
    className="cc-meetingdetails-participants-container"
    style={{ width: fulWidth ? '100%' : '50%' }}
  >
    {Array.isArray(contacts) &&
      contacts.length > 0 &&
      contacts.map((contact) => (
        <div
          className="cc-meetingdetails-participants"
          key={contact.contactId.toString()}
        >
          <ContactName
            showStatusBadge
            name={`${contact.firstName} ${contact.lastName}`}
            imageUrl={contact.imageUrl}
            position={contact.jobTitle}
            participantStatus={contact.participantStatus}
            participantStatusId={contact.participantStatusId}
          />
        </div>
      ))}
  </div>
)

CompanyContact.propTypes = {
  fulWidth: PropTypes.bool,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.number.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      contactName: PropTypes.string.isRequired,
      imageUrl: PropTypes.string,
    }).isRequired
  ).isRequired,
}
export default CompanyContact
