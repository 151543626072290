import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { useDiscoverLists } from '~/hooks/discoverLists'
import ManageLists from './ManageLists'

export default function ManageListsModal({ visible, onClose }) {
  const {
    discoverLists,
    deleteDiscoverList,
    shareDiscoverList,
    revokeDiscoverListAccess,
  } = useDiscoverLists()

  return (
    visible && (
      <Modal
        visible
        footer={null}
        width="720px"
        bodyStyle={{ padding: 0 }}
        onCancel={onClose}
      >
        <ManageLists
          lists={discoverLists}
          onDismiss={onClose}
          onDeleteList={deleteDiscoverList}
          onRevokeSharingAccess={revokeDiscoverListAccess}
          onShareList={shareDiscoverList}
        />
      </Modal>
    )
  )
}

ManageListsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
