import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons'
import { faCalendar, faCheckCircle } from '@fortawesome/pro-regular-svg-icons'
import { faMapMarkerAlt, faStore } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row, Tooltip } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import map from 'lodash/map'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import defaultImage from '~/assets/no_image_available.png'
import AvatarGroup from '~/components/AvatarGroup'
import SwitchCompanyModal from '~/components/SwitchCompanyModal'
import TieFundsModal from '~/components/TieFundsModal/TieFundsModal'
import { covidPolicies } from '~/constants/summitAgendas'
import { getCompanyId, getCurrentCompanyName } from '~/selectors/auth'
import { getClickableLink } from '~/utils/helpers'
import {
  getConvertedMeetingTime,
  getFormatedMeetingTime,
} from '~/utils/meetingActions'
import './FeaturedEvent.less'

const FeaturedEvent = ({
  event,
  onSelectBooth,
  onEditBooth,
  onGetEvents,
  isOnModal = false,
  isPast = false,
}) => {
  const [isSwitchCompanyVisible, setIsSwitchCompanyVisible] = useState(false)
  const name = useSelector(getCurrentCompanyName)
  const companyId = useSelector(getCompanyId)
  const rolesAllowedToBookSeats = ['manager', 'service provider']
  const role = useSelector((state) => state.auth.role)
  const posterUrl = get(event, 'posterImage', defaultImage)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { cfnSummitDetails, boothManagement = false } = useFlags()
  const { push } = useHistory()

  const locationKeys = [
    'venue',
    'address1',
    'address2',
    'address3',
    'city',
    'state',
    'postalCode',
    'country',
  ]

  const address = join(
    map(
      filter(locationKeys, (key) => !isEmpty(get(event, key))),
      (key) => get(event, key)
    ),
    ', '
  )

  const fmt = 'MMMM Do, YYYY'
  const startDateFormatted = getConvertedMeetingTime(
    event.timeZone,
    event.virtualSummit,
    event.startDate
  ).format(fmt)

  const endDateFormatted = getFormatedMeetingTime(
    event.timeZone,
    event.virtualSummit,
    event.endDate,
    fmt
  )
  const avatars = event.participantsFromMyCompany.map((p) => {
    return {
      id: p.contactId,
      firstName: p.firstName,
      lastName: p.lastName,
      initials: `${p.firstName[0]}${p.lastName[0]}`,
      description: `${p.contactName} (${p.jobTitle})`,
      img: p.imageUrl,
    }
  })

  let registerBtn = !isNil(event.registrationUrl) && (
    <Button
      as="a"
      variant="filled"
      className="FeaturedEvent-registration-btn"
      href={getClickableLink(event.registrationUrl)}
      target="_blank"
      rel="noopener"
    >
      Register
    </Button>
  )
  let viewBtn = null

  if (event.amRegistered) {
    registerBtn = (
      <div className="FeaturedEvent-registration-text">
        <FontAwesomeIcon icon={faCheckCircle} />
        <span>Registered</span>
      </div>
    )
  }
  if (
    event.amRegistered &&
    (!event.isCfnEvent || (role === 'CFN' && cfnSummitDetails))
  ) {
    viewBtn = (
      <Button
        onClick={() =>
          find(event.registeredWithCompanies, (c) => c.companyId === companyId)
            ? push(`/summits/${event.eventId}`)
            : setIsSwitchCompanyVisible(true)
        }
      >
        View Summit
      </Button>
    )
  }
  const canBookMoreBooths =
    event.boothCount >
      event.booths?.filter((booth) => !isEmpty(booth.boothName))?.length ||
    isEmpty(event.booths)
  const start = moment(event.boothSelectionStartDate, moment.ISO_8601)
  const end = moment(event.boothSelectionEndDate, moment.ISO_8601)
  const now = moment.utc()
  const canSelect =
    boothManagement &&
    now.isAfter(start) &&
    now.isBefore(end) &&
    canBookMoreBooths

  const booths = event.booths ? (
    event.booths.map((booth) =>
      boothManagement ? (
        <Button
          variant="link"
          key={booth.boothId}
          onClick={() => {
            if (isFunction(onEditBooth)) {
              onEditBooth({
                eventId: event.eventId,
                eventName: event.name,
                eventCompanyId: event.eventCompanyId,
                boothId: booth.boothId,
                boothSelectionEnded: !(now.isAfter(start) && now.isBefore(end)),
                eventCompanyBoothId: booth.eventCompanyBoothId,
              })
            }
          }}
        >
          {booth.boothId}
        </Button>
      ) : (
        <span>{booth.boothId}</span>
      )
    )
  ) : (
    <span>To be determined</span>
  )

  const funds = isEmpty(event.funds) ? (
    <span>To be determined</span>
  ) : (
    <Tooltip
      title={join(
        event.funds.map((fund) => fund.name),
        ', '
      )}
      style={{ marginLeft: 0 }}
    >
      <>
        {event.funds.map(
          (fund, key) =>
            key < 3 && (
              <span key={key} style={{ marginLeft: '5px' }}>
                {fund.name}
                {key !== event.funds.length - 1 && <>,</>}
              </span>
            )
        )}
      </>
      {event.funds.length > 3 && <>...</>}
    </Tooltip>
  )

  const boothSelection =
    !isNil(event.boothSelectionStartDate) &&
    !isNil(event.boothSelectionEndDate) &&
    canSelect ? (
      <span className="FeaturedEvent-booth-selection">
        <FontAwesomeIcon icon={faExclamationTriangle} />
        <span>Booth selection is open.</span>
        <Button
          variant="link"
          onClick={() => {
            if (isFunction(onSelectBooth)) {
              onSelectBooth({
                eventId: event.eventId,
                eventName: event.name,
                eventCompanyId: event.eventCompanyId,
                boothId: '',
              })
            }
          }}
        >
          Select a booth
        </Button>
      </span>
    ) : null

  const fundSelection = isEmpty(event.funds) ? (
    <span className="FeaturedEvent-booth-selection">
      <FontAwesomeIcon icon={faExclamationTriangle} />
      <span>Select a fund(s) that you want to present at the event.</span>
      <Button variant="link" onClick={() => setIsModalVisible(true)}>
        Select a fund
      </Button>
    </span>
  ) : (
    <Button variant="link" onClick={() => setIsModalVisible(true)}>
      Add or Edit Funds
    </Button>
  )

  const covidUrl = find(covidPolicies, ['eventId', event.eventId])?.url

  return (
    <div className="FeaturedEvent" style={{ boxShadow: isOnModal && 'none' }}>
      <div className="FeaturedEvent-header">Featured Summit</div>
      <Row className="FeaturedEvent-body">
        <Col span={8}>
          <div
            className="FeaturedEvent-poster"
            style={{
              backgroundImage: `url(${posterUrl})`,
            }}
          />
        </Col>
        <Col span={16} className="FeaturedEvent-content">
          <div className="FeaturedEvent-info">
            <h2 className="FeaturedEvent-info-title">{event.name}</h2>
            <p className="FeaturedEvent-info-description">
              {event.eventDescription}
            </p>
            <div className="FeaturedEvent-info-date">
              <FontAwesomeIcon icon={faCalendar} />
              <span>
                {startDateFormatted} - {endDateFormatted}
              </span>
            </div>
            {address && (
              <div className="FeaturedEvent-info-location">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <span>{address}</span>
              </div>
            )}
            {!isEmpty(event.participantsFromMyCompany) &&
              !isPast &&
              !event.virtualSummit &&
              !event.packageName?.toLowerCase().includes('networking') &&
              rolesAllowedToBookSeats.includes(role.toLowerCase()) && (
                <div className="FeaturedEvent-info-booths">
                  <FontAwesomeIcon icon={faStore} />
                  <span>Booth:</span>
                  {booths}
                  {boothSelection}
                </div>
              )}
            {role.toLowerCase() === 'manager' &&
              !isPast &&
              !isEmpty(event.participantsFromMyCompany) && (
                <div className="FeaturedEvent-info-booths">
                  <FontAwesomeIcon icon={faStore} />
                  <span>Funds:</span>
                  {funds}
                  {fundSelection}
                </div>
              )}
          </div>
          <div className="FeaturedEvent-footer">
            {!isPast && (
              <div className="FeaturedEvent-actions">
                {registerBtn}

                {viewBtn}

                {event.agendaUrl && (
                  <Button
                    as="a"
                    variant="link"
                    href={event.agendaUrl}
                    target="_blank"
                    className="ml-2"
                  >
                    View Agenda
                  </Button>
                )}

                {covidUrl && (
                  <Button
                    as="a"
                    variant="link"
                    href={covidUrl}
                    target="_blank"
                    className="ml-2"
                  >
                    COVID-19 Policy
                  </Button>
                )}

                {!isNil(event.websiteUrl) && (
                  <Button
                    as="a"
                    variant="link"
                    href={getClickableLink(event.websiteUrl)}
                    target="_blank"
                    rel="noopener"
                  >
                    Learn More
                  </Button>
                )}
              </div>
            )}
            <div className="FeaturedEvent-colleagues">
              <AvatarGroup showInitials={true} avatars={avatars} />
            </div>
          </div>
        </Col>
      </Row>
      <TieFundsModal
        eventId={event.eventId}
        isModalVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onRefresh={() => onGetEvents()}
        initialFunds={event.funds}
      />
      <SwitchCompanyModal
        isModalVisible={isSwitchCompanyVisible}
        setIsModalVisible={setIsSwitchCompanyVisible}
        currentCompanyName={name}
        companyList={event.registeredWithCompanies}
        toCompanyId={event.registeredWithCompanies[0]?.companyId}
        toCompanyText={
          <p>
            In order to view the summit you have to switch to
            <span className="font-semibold ml-1">
              {event.registeredWithCompanies[0]?.companyName}
            </span>
            since that company is attending the summit.
          </p>
        }
        onSwitchCompany={() => {
          push(`/summits/${event.eventId}`)
        }}
      />
    </div>
  )
}

FeaturedEvent.propTypes = {
  event: PropTypes.objectOf({
    eventId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    eventDescription: PropTypes.string,
    venue: PropTypes.string.isRequired,
    venueDescription: PropTypes.string,
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    address3: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    stateProvince: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    headerImage: PropTypes.string,
    footerImage: PropTypes.string,
    posterImage: PropTypes.string,
    thumbnailImage: PropTypes.string,
    eventStatus: PropTypes.number.isRequired,
    isFeatured: PropTypes.bool.isRequired,
    amRegistered: PropTypes.bool.isRequired,
    websiteUrl: PropTypes.string,
    registrationUrl: PropTypes.string,
    boothSelectionStartDate: PropTypes.string,
    boothSelectionEndDate: PropTypes.string,
    participantsFromMyCompany: PropTypes.arrayOf(
      PropTypes.shape({
        contactId: PropTypes.number.isRequired,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        contactName: PropTypes.string,
        jobTitle: PropTypes.string,
        imageUrl: PropTypes.string,
      })
    ),
    booths: PropTypes.arrayOf(
      PropTypes.shape({
        boothId: PropTypes.string.isRequired,
        boothName: PropTypes.string.isRequired,
      })
    ),
  }),
  onSelectBooth: PropTypes.func,
  onEditBooth: PropTypes.func,
  onGetEvents: PropTypes.func,
  isOnModal: PropTypes.bool,
  isPast: PropTypes.bool,
}

export default FeaturedEvent
