import { Component } from 'react'
import PropTypes from 'prop-types'
import { faUpload } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Image, Upload } from 'antd'
import isNil from 'lodash/isNil'
import './CCUpload.less'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

class CCUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imagePreview: null,
      //imagePreviewVisible: null,
      imagePreviewVisible: true,
      loading: false,
      imageUrl: null,
      imageIconVisible: false,
    }
  }

  componentDidUpdate() {
    if (this.state.imageUrl !== this.props.imageUrl) {
      this.setState({
        imageUrl: this.props.imageUrl,
      })
    }
  }

  handleChange = (info) => {
    getBase64(info.file.originFileObj, (imageUrl) => {
      const isImage = info.file.type.includes('image')
      this.setState({
        imageUrl: isImage ? imageUrl : null,
        loading: false,
        imagePreviewVisible: isImage,
      })

      if (typeof this.props.onChange === 'function' && isImage) {
        this.props.onChange(imageUrl)
      }
    })
  }

  fileRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  render() {
    const placeholder =
      this.props.placeholder === null ? 'Upload Photo' : this.props.placeholder
    const imageContent =
      this.state.imagePreviewVisible && !isNil(this.props.imageUrl) ? (
        [
          <Image
            key="img"
            preview={false}
            style={{
              width: '137px',
              height: '137px',
              borderRadius: '64px',
              objectFit: 'cover',
            }}
            src={this.state.imageUrl}
            onError={() =>
              this.setState({
                imagePreviewVisible: false,
                imageUrl: null,
              })
            }
          />,
          this.state.imageIconVisible && (
            <span
              key="span"
              className="upload-icon-upload"
              onClick={this.handleCancel}
            >
              <FontAwesomeIcon icon={faUpload} size="3x" color="white" />
            </span>
          ),
        ]
      ) : (
        <div className="upload-icon-text cc-body-text">
          <FontAwesomeIcon icon={faUpload} />
          <span>{placeholder}</span>
        </div>
      )

    return (
      <div className="upload-container">
        <Upload
          className="upload-icon-empty"
          showUploadList={false}
          listType="picture-card"
          onChange={this.handleChange}
          customRequest={this.fileRequest}
          onMouseEnter={() =>
            this.setState({
              imageIconVisible: true,
            })
          }
          onMouseLeave={() =>
            this.setState({
              imageIconVisible: false,
            })
          }
        >
          {imageContent}
        </Upload>
      </div>
    )
  }
}

CCUpload.propTypes = {
  placeholder: PropTypes.string,
  imageUrl: PropTypes.string,
  onChange: PropTypes.func,
}

export default CCUpload
