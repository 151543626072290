import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faBriefcase, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { fetchCompanyIfNeeded } from '~/actions/company'
import { getCompanyFunds } from '~/actions/discover'
import { api as http } from '~/api/services'
import { FundWizardModal, useFundWizardModal } from '~/components/FundWizard'
import Loading from '~/components/Loading'
import { getCompanyId } from '~/selectors/auth'
import './discoverAllocatorsFundSelect.less'

const DiscoverAllocatorsFundSelect = ({ onSelectFund = () => {} }) => {
  const { trackEvent } = useTracking({
    component: 'TieFundToManagerInDiscover',
  })

  const [selectedFunds, setSelectedFunds] = useState([])
  const [isSelected, setIsSelected] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const fundWizardModal = useFundWizardModal('discoverAllocatorsFundSelect')
  const company = useSelector((state) => state.company.company)
  const companyId = useSelector(getCompanyId)
  const contactId = useSelector((state) => state.auth.contact.contactId)
  const dispatch = useDispatch()
  const fetchCompany = useCallback(
    () => dispatch(fetchCompanyIfNeeded(companyId)),
    [companyId, dispatch]
  )
  useEffect(() => fetchCompany(), [companyId, fetchCompany])

  const refreshCompanyFunds = useCallback(() => {
    setIsLoading(true)
    getCompanyFunds(companyId)
      .then((response) => {
        !isEmpty(response.data.result) && setIsSelected(true)
      })
      .catch(() => message.error('Could not load funds'))
      .finally(() => setIsLoading(false))
  }, [companyId])

  useEffect(() => {
    refreshCompanyFunds()
  }, [refreshCompanyFunds])

  const onChange = (checkedValues) => {
    setSelectedFunds(
      checkedValues.map((value) =>
        company.funds.find((fund) => fund.fundId === value)
      )
    )
  }

  const handleContinue = (funds) => {
    trackEvent({ eventName: 'click', element: 'Save Fund Managers' })
    funds.forEach((fund) => {
      http
        .get(`/funds/${fund.fundId}/employees`)
        .then((response) => {
          const fundManagers = response.data.result.filter((x) => x.isManager)
          http
            .post(`/funds/${fund.fundId}/employees`, [
              ...fundManagers.map((manager) => manager.contactId),
              contactId,
            ])
            .then(() => {
              onSelectFund()
            })
        })
        .catch(() => message.error('Could not tie funds'))
        .finally(() => {
          setIsSelected(true)
          message.success('Successfully tied to fund')
        })
    })
  }
  return (
    <Loading spinning={isLoading} style={{ marginTop: '250px' }}>
      {!isSelected && !isLoading && (
        <div className="cc-discover-fund-select">
          <FontAwesomeIcon
            className="cc-discover-fund-select-icon"
            icon={faBriefcase}
            size="3x"
          />
          <p className="cc-discover-fund-select-name">
            Please select the fund(s) you manage
          </p>
          <p className="cc-discover-fund-select-description">
            Select from the funds of your company or create a new one
          </p>
          <div className="cc-discover-fund-select-group">
            {company && (
              <Checkbox.Group
                className="cc-discover-fund-select-checkbox"
                options={company.funds.map((fund) => ({
                  value: fund.fundId,
                  label: fund.name,
                }))}
                onChange={onChange}
              />
            )}
            <div
              onClick={() => {
                trackEvent({ eventName: 'click', element: 'Create New Fund' })
                fundWizardModal.show()
              }}
              className="cc-discover-fund-select-new"
            >
              <FontAwesomeIcon icon={faPlus} /> Create new fund
            </div>
            <Button
              type="primary"
              className="cc-discover-fund-select-button"
              disabled={isEmpty(selectedFunds)}
              onClick={() => handleContinue(selectedFunds)}
            >
              Continue
            </Button>
          </div>
        </div>
      )}
      {fundWizardModal.visible && (
        <FundWizardModal
          visible
          onWizardClosed={() => {
            fundWizardModal.hide()
          }}
          onSave={() => {
            refreshCompanyFunds()
            fundWizardModal.hide()
          }}
        />
      )}
    </Loading>
  )
}

DiscoverAllocatorsFundSelect.propTypes = {
  onSelectFund: PropTypes.func,
}

export default DiscoverAllocatorsFundSelect
