import PropTypes from 'prop-types'
import { Col, Row } from 'antd'

const ProfileDisclaimerTab = ({ disclaimer, customDisclaimerHtml }) => {
  return (
    <Row>
      <Col span={24}>
        <span className="type-subtitle-sm text-header">
          IMPORTANT DISCLAIMER
        </span>
        <div
          className="cc-profile-info"
          style={{ paddingBottom: '16px', wordBreak: 'normal' }}
        >
          {disclaimer}
        </div>
        {customDisclaimerHtml && (
          <div dangerouslySetInnerHTML={{ __html: customDisclaimerHtml }} />
        )}
      </Col>
    </Row>
  )
}

ProfileDisclaimerTab.propTypes = {
  disclaimer: PropTypes.string,
  customDisclaimerHtml: PropTypes.string,
}

export default ProfileDisclaimerTab
