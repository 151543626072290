import PropTypes from 'prop-types'
import { faGlobe } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Drawer, Row } from 'antd'
import './Banner.less'

const UpcomingMeetingsBanner = ({
  isVisible = false,
  leaveMeeting,
  nextMeetingCount,
}) => (
  <Drawer
    getContainer={false}
    placement="top"
    closable={false}
    visible={isVisible}
    height={64}
    headerStyle={{ display: 'none' }}
    bodyStyle={{ background: '#2D81A8', padding: 0 }}
    mask={false}
    maskClosable={false}
  >
    <Row className="banner">
      <Col
        xl={{ span: 12, offset: 3 }}
        lg={{ span: 12, offset: 1 }}
        md={{ span: 16 }}
        className="flex-childs"
      >
        <FontAwesomeIcon
          size="lg"
          icon={faGlobe}
          className="logo-icon-space"
          color="white"
        />
        <h4>
          You have <strong>{nextMeetingCount} Upcoming meetings</strong> that
          just started
        </h4>
        <Button
          type="primary"
          size="default"
          className="space-around blue"
          onClick={() => leaveMeeting()}
        >
          Go to Lobby
        </Button>
      </Col>
    </Row>
  </Drawer>
)

UpcomingMeetingsBanner.propTypes = {
  isVisible: PropTypes.bool,
  leaveMeeting: PropTypes.func.isRequired,
  nextMeetingCount: PropTypes.number.isRequired,
}

export default UpcomingMeetingsBanner
