import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as api from '~/api'

export function useConnectionRequests({
  page,
  pageSize,
  search,
  sortBy = '-createdAt',
  isSender = false,
  statusId = 1,
}) {
  return useQuery(
    [
      'connectionRequests',
      { page, pageSize, search, sortBy, isSender, statusId },
    ],
    () =>
      api.connections
        .getConnectionRequests(page, pageSize, search, sortBy, {
          filter: [`isSender eq ${isSender}`, `statusId eq ${statusId}`],
        })
        .then((res) => res.data.result),
    {
      keepPreviousData: true,
    }
  )
}

export function useRemoveConnection(connectionId) {
  const client = useQueryClient()
  const { mutateAsync } = useMutation(
    () => api.connections.removeConnection(connectionId),
    {
      onSuccess: () => client.invalidateQueries('directory'),
    }
  )
  return mutateAsync
}

export function useConnectionRequestResponses(connectionRequestId) {
  const client = useQueryClient()

  const { mutateAsync: acceptRequest } = useMutation(
    () => api.connections.acceptConnectionRequest(connectionRequestId),
    {
      onSuccess: () => {
        client.invalidateQueries('connectionRequests')
        client.invalidateQueries('connections')
        client.invalidateQueries('unreadConnectionRequests')
      },
    }
  )

  const { mutateAsync: ignoreRequest } = useMutation(
    () => api.connections.ignoreConnectionRequest(connectionRequestId),
    {
      onSuccess: () => {
        client.invalidateQueries('connectionRequests')
        client.invalidateQueries('unreadConnectionRequests')
      },
    }
  )

  const { mutateAsync: cancelRequest } = useMutation(
    () => api.connections.cancelConnectionRequest(connectionRequestId),
    {
      onSuccess: () => {
        client.invalidateQueries('connectionRequests')
        client.invalidateQueries('unreadConnectionRequests')
      },
    }
  )

  const { mutateAsync: viewRequest } = useMutation(
    () => api.connections.viewConnectionRequest(connectionRequestId),
    {
      onSuccess: () => {
        client.invalidateQueries('unreadConnectionRequests')
      },
    }
  )

  return {
    acceptRequest,
    ignoreRequest,
    cancelRequest,
    viewRequest,
  }
}
