import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Switch, Tooltip, message } from 'antd'
import groupBy from 'lodash/groupBy'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { Prompt } from 'react-router-dom'
import Loading from '../Loading'
import './CFNVisibilityEditor.less'

const CFNVisibilityEditor = ({
  enableNext,
  step,
  onChange,
  companySettings,
  fromSettings = false,
  onLoad,
  contentChanged,
}) => {
  const [options, setOptions] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [preferences, setPreferences] = useState()

  useEffect(() => {
    if (isNil(options)) {
      setIsLoading(true)
      step
        .renderAction()
        .then((response) => {
          const groupedResult = groupBy(response.data.result, (x) => x.section)
          setOptions(Object.values(groupedResult))
          setPreferences(response.data.result)
          enableNext(true)

          if (fromSettings) {
            onLoad(response.data.result)
          } else {
            onChange(response.data.result)
          }
        })
        .catch(() =>
          message.error('There was an error while attempting to get questions')
        )
        .finally(() => setIsLoading(false))
    }
  }, [
    enableNext,
    fromSettings,
    onChange,
    onLoad,
    options,
    step,
    step.renderAction,
  ])

  const onTogglePrederence = useCallback(
    (checked, preferenceId, index) => {
      options[index].find((x) => x.preferenceId === preferenceId).value =
        checked
      setOptions(options)
      preferences.find((x) => x.preferenceId === preferenceId).value = checked
      setPreferences(preferences)
      onChange(preferences)
    },
    [onChange, options, preferences]
  )

  return (
    <>
      <Prompt
        message="Are you sure you want to leave without saving?"
        when={contentChanged}
      />
      <Loading spinning={isLoading}>
        <div className="cc-body cc-black-text">
          Choose the information you want to display on your{' '}
          {companySettings ? 'company' : 'personal'} profile. The more
          information you display, the easier it will be for other family
          offices to discover you.
        </div>
        {options &&
          map(options, (optionSection, index) => (
            <div>
              <div className="cfnVisibilityEditor-header">
                <div className="cc-heading5 cc-black-text">
                  {optionSection[0].section}
                </div>
              </div>

              <Divider className="cfnVisibilityEditor-divider" />
              {map(optionSection, (option) => (
                <div>
                  <div className="cfnVisibilityEditor-body">
                    <span className="cc-body-text cc-lightblack-text">
                      {option.label}
                      {option.info && (
                        <Tooltip title={option.info}>
                          <span className="cfnVisibilityEditor-info-icon">
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </span>
                        </Tooltip>
                      )}
                    </span>
                    <Tooltip title={option.cannotChangeReason}>
                      <Switch
                        defaultChecked={option.value}
                        disabled={!option.canChange}
                        onChange={(checked) => {
                          onTogglePrederence(
                            checked,
                            option.preferenceId,
                            index
                          )
                        }}
                      />
                    </Tooltip>
                  </div>
                  <Divider className="cfnVisibilityEditor-divider" />
                </div>
              ))}
            </div>
          ))}
      </Loading>
    </>
  )
}

CFNVisibilityEditor.propTypes = {
  enableNext: PropTypes.func,
  step: PropTypes.shape({
    renderAction: PropTypes.func.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  companySettings: PropTypes.bool.isRequired,
  fromSettings: PropTypes.bool,
  onLoad: PropTypes.func,
  contentChanged: PropTypes.bool,
}

export default CFNVisibilityEditor
