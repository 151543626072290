import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Input } from '@context365/forms'
import { message } from 'antd'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import some from 'lodash/some'
import * as api from '~/api'

const QuarterlyInput = ({ quarter, value, onChange }) => (
  <Input
    style={{ width: '100%', marginBottom: '10px' }}
    type="number"
    prefix={`Q${quarter}`}
    onChange={(e) => onChange(e.target.value, quarter)}
    value={value}
  />
)

const AnnualInput = ({ value, onChange }) => (
  <Input
    style={{ width: '100%' }}
    type="number"
    prefix="Annual"
    onChange={(e) => onChange(e.target.value)}
    value={value}
  />
)

const Returns = ({ returns, year, type, onChange }) => (
  <>
    {map(orderBy(returns, 'quarter', 'desc'), (r, i) =>
      r.quarter ? (
        <QuarterlyInput
          key={i}
          value={r.value}
          quarter={r.quarter}
          onChange={(value, quarter) => onChange(year, quarter, type, value)}
        />
      ) : (
        <AnnualInput
          key={i}
          value={r.value}
          onChange={(value) => onChange(year, null, type, value)}
        />
      )
    )}
  </>
)

function getReturnsByYear(missingIrrReturns, missingTvpiReturns) {
  const combinedMissingReturns = concat(
    map(missingIrrReturns, (irr) => ({
      year: irr.year,
      quarter: irr.quarter,
      value: null,
      type: 'IRR',
    })),
    map(missingTvpiReturns, (tvpi) => ({
      year: tvpi.year,
      quarter: tvpi.quarter,
      value: null,
      type: 'TVPI',
    }))
  )

  const groupedByYear = groupBy(combinedMissingReturns, 'year')

  return map(groupedByYear, (returns, year) => ({
    year,
    totalValuePaidInCapital: filter(returns, (r) => r.type === 'TVPI'),
    internalRateOfReturn: filter(returns, (r) => r.type === 'IRR'),
  }))
}

const UpdateIlliquidReturns = ({
  fundId,
  missingIrrReturns,
  missingTvpiReturns,
  onComplete = () => {},
}) => {
  const [returnsByYear, setReturnsByYear] = useState(
    getReturnsByYear(missingIrrReturns, missingTvpiReturns)
  )

  const handleReturnChange = (year, quarter, type, value) => {
    const arr = returnsByYear.slice()

    const currentYear = find(arr, { year })
    const currentEntry = find(currentYear[type], { quarter })
    currentEntry.value = value === '' ? null : value

    setReturnsByYear(arr)
  }

  const handleSave = () => {
    api.funds
      .addMissingIlliquidReturns(fundId, returnsByYear)
      .then(() => {
        message.success('Returns updated successfully')
        onComplete()
      })
      .catch(() => message.error('Error updating funds'))
  }

  return (
    <div className="p-3 w-1/2">
      <div className="flex py-2 font-semibold">
        <span className="w-1/6">{'Year'}</span>
        {missingTvpiReturns?.length > 0 && (
          <span className="w-1/3">{'TVPI'}</span>
        )}
        {missingIrrReturns?.length > 0 && (
          <span className="w-1/3">{'IRR'}</span>
        )}
      </div>
      {map(orderBy(returnsByYear, 'year', 'desc'), (ret) => (
        <div className="flex py-2">
          <span className="w-1/6">{ret.year}</span>
          {missingTvpiReturns?.length > 0 && (
            <div className="w-1/3 pr-2">
              <Returns
                returns={ret.totalValuePaidInCapital}
                year={ret.year}
                type="totalValuePaidInCapital"
                onChange={handleReturnChange}
              />
            </div>
          )}
          {missingIrrReturns?.length > 0 && (
            <div className="w-1/3">
              <Returns
                returns={ret.internalRateOfReturn}
                year={ret.year}
                type="internalRateOfReturn"
                onChange={handleReturnChange}
              />
            </div>
          )}
        </div>
      ))}
      <Button
        variant="filled"
        onClick={() => handleSave()}
        disabled={some(
          returnsByYear,
          (rvy) =>
            some(rvy.totalValuePaidInCapital, (tvpi) => isNil(tvpi.value)) ||
            some(rvy.internalRateOfReturn, (irr) => isNil(irr.value))
        )}
      >
        {'Save Returns'}
      </Button>
    </div>
  )
}

const timePeriod = PropTypes.shape({
  year: PropTypes.number.isRequired,
  quarter: PropTypes.oneOf([1, 2, 3, 4]).isRequired,
})

UpdateIlliquidReturns.propTypes = {
  fundId: PropTypes.number.isRequired,
  missingIrrReturns: PropTypes.arrayOf(timePeriod),
  missingTvpiReturns: PropTypes.arrayOf(timePeriod),
  onComplete: PropTypes.func,
}

export default UpdateIlliquidReturns
