import * as React from 'react'
import PropTypes from 'prop-types'
import Loading from '~/components/Loading'
import { useOnboardingQuery } from '../hooks'
import { useOnboardingContext } from '../OnboardingContext'
import {
  BackButton,
  NextButton,
  OnboardingFooter,
  OnboardingLayout,
} from './OnboardingLayout'

const FormMode = {
  Loading: 'loading',
  Edit: 'edit',
  Confirm: 'confirm',
}

export function CompanyDetails({
  editForm,
  confirmForm,
  getSavedAnswers,
  saveAnswers,
}) {
  const { onNext } = useOnboardingContext()
  const { mode, answers, goToConfirm, edit, init } =
    useCompanyDetailsFormState()

  const { answers: savedAnswers, submit } = useOnboardingQuery(
    ['onboarding-company-details'],
    {
      getAnswers: getSavedAnswers,
      saveAnswers,
    }
  )

  React.useEffect(() => {
    if (savedAnswers) {
      init(savedAnswers)
    }
  }, [savedAnswers, init])

  switch (mode) {
    case FormMode.Edit:
      return (
        <OnboardingLayout
          header={<h1 className="type-header-lg">Welcome to ApexInvest</h1>}
          body={
            <FormContainer
              title="Company Information"
              id="company-details"
              savedAnswers={answers}
              onSubmit={(answers) => goToConfirm(answers)}
            >
              {editForm}
            </FormContainer>
          }
          footer={
            <OnboardingFooter
              rightContent={<NextButton type="submit" form="company-details" />}
            />
          }
        />
      )
    case FormMode.Confirm:
      return (
        <OnboardingLayout
          header={<h1 className="type-header-lg">Welcome to Context365</h1>}
          body={
            <FormContainer
              title="Confirm Company Information"
              answers={answers}
            >
              {confirmForm}
            </FormContainer>
          }
          footer={
            <OnboardingFooter
              rightContent={
                <NextButton
                  iconRight={undefined}
                  onClick={() => submit(answers).then(onNext)}
                >
                  Confirm
                </NextButton>
              }
              leftContent={<BackButton onClick={() => edit()}>Edit</BackButton>}
            />
          }
        />
      )
    default:
      return (
        <OnboardingLayout
          header={<h1 className="type-header-lg">Welcome to Context365</h1>}
          body={
            <div
              className="flex items-center justify-center"
              style={{ height: '50%' }}
            >
              <Loading />
            </div>
          }
        />
      )
  }
}

CompanyDetails.propTypes = {
  editForm: PropTypes.node.isRequired,
  confirmForm: PropTypes.node.isRequired,
  getSavedAnswers: PropTypes.func.isRequired,
  saveAnswers: PropTypes.func.isRequired,
}

function useCompanyDetailsFormState() {
  const [state, dispatch] = React.useReducer(
    (state, action) => {
      switch (action.type) {
        case 'continue':
          return { mode: FormMode.Confirm, answers: action.answers }
        case 'edit':
          return { ...state, mode: FormMode.Edit }
        case 'init':
          return { mode: FormMode.Edit, answers: action.answers }
        default:
          return state
      }
    },
    { mode: FormMode.Loading }
  )

  return {
    mode: state.mode,
    answers: state.answers,
    goToConfirm: React.useCallback(
      (answers) => dispatch({ type: 'continue', answers }),
      []
    ),
    edit: React.useCallback(() => dispatch({ type: 'edit' }), []),
    init: React.useCallback(
      (answers) => dispatch({ type: 'init', answers }),
      []
    ),
  }
}

function FormContainer({ children, title, ...formProps }) {
  return (
    <div className="mt-12">
      <div className="type-header-md text-center mb-8">{title}</div>
      {React.cloneElement(children, formProps)}
    </div>
  )
}
