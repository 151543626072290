import * as React from 'react'
import { Alert } from '@context365/alert'
import { Button } from '@context365/button'
import { ArrowDownward } from '@context365/icons'
import compact from 'lodash/compact'
import { useParams } from 'react-router-dom-v5-compat'
import SimpleBar from 'simplebar-react'
import ConversationActions from './ConversationActions'
import ConversationError from './ConversationError'
import ConversationHeader from './ConversationHeader'
import ConversationMessages from './ConversationMessages'
import LockedMessage from './LockedMessage'
import { Company } from './models'
import { useConversation } from './queries'
import { MessageSearchProvider } from './useMessageSearch'
import useStickyScrolling from './utils/useStickyScrolling'

export default function Conversation() {
  const scrollingParentRef = React.useRef()
  const conversationId = parseInt(useParams().conversationId, 10)
  const {
    conversation,
    messages,
    error,
    isLoading,
    isError,
    refreshConversation,
  } = useConversation(conversationId)
  const [hasNewMessages, setHasNewMessages] = React.useState(false)

  const { scrollToBottom } = useStickyScrolling(scrollingParentRef, {
    messageCount: messages.length,
    onNewMessage: () => {
      setHasNewMessages(true)
    },
    onScrollToBottom: () => {
      setHasNewMessages(false)
    },
  })

  if (isLoading) {
    return <Loading />
  }

  if (isError) {
    return error.response?.status === 403 ? (
      <LockedMessage />
    ) : (
      <ConversationError />
    )
  }

  return (
    <MessageSearchProvider messages={messages} scrollRef={scrollingParentRef}>
      <div className="h-full w-full flex flex-col bg-white">
        <div className="flex-shrink-0">
          <ConversationHeader
            conversationId={conversationId}
            companies={compact([
              Company.fromConversationDetails(conversation.details),
              Company.fromConversationDetailsInvited(conversation.details),
            ])}
            companyId={conversation.details.companyID}
            title={conversation.details.companyName}
            contacts={conversation.details.contacts}
            meetingDateTime={conversation.upcomingMeetingDateTime}
            refreshConversation={refreshConversation}
          />
        </div>
        <div className="flex-1 relative overflow-hidden md:border-l md:border-r">
          <SimpleBar
            className="overflow-y-auto h-full"
            scrollableNodeProps={{
              ref: scrollingParentRef,
            }}
          >
            <ConversationMessages
              conversationId={conversationId}
              messages={messages}
              conversationType={conversation.conversationType}
            />
          </SimpleBar>
          <NewMessageIndicator
            visible={hasNewMessages}
            onScroll={() => scrollToBottom()}
          />
        </div>
        {conversation.validationResponse?.needsHeadsUp && <LastMessageAlert />}
        <div className="flex-shrink-0 md:border-l md:border-r">
          <ConversationActions
            conversationId={conversationId}
            channelSID={conversation?.channelSID}
            disabled={!conversation.validationResponse?.canSendMessage}
            disabledReason={conversation.validationResponse?.reason}
          />
        </div>
      </div>
    </MessageSearchProvider>
  )
}

function NewMessageIndicator({ visible, onScroll }) {
  return (
    <div
      className="absolute bottom-4 bg-secondary-2 rounded border-2 border-secondary-5 transition-transform ease-in-out-back duration-300"
      style={{
        left: '50%',
        transform: `translate(-50%, ${visible ? 0 : '200%'})`,
      }}
    >
      <Button
        variant="link"
        status="secondary"
        iconLeft={<ArrowDownward />}
        onClick={onScroll}
      >
        New Message
      </Button>
    </div>
  )
}

function LastMessageAlert() {
  return (
    <div className="flex-shrink-0 py-2 px-4 flex justify-center md:border-l md:border-r">
      <Alert className="w-fit" status="warning">
        <div>
          <p>
            You are allowed to send one more message because there are no active
            meetings for this conversation.
          </p>
          <p className="m-0">
            After that, the channel will be re-activated when the other party
            replies.
          </p>
        </div>
      </Alert>
    </div>
  )
}

function Loading() {
  return (
    <div className="h-full w-full flex flex-col bg-white">
      <ConversationHeader.Loading />
      <ConversationMessages.Loading />
      <ConversationActions.Loading />
    </div>
  )
}

Conversation.Loading = Loading
