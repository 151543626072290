import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import _filter from 'lodash/filter'
import { pluralize } from '~/utils/helpers'
import './DiscoverFilters.less'

const FilterCountLabel = ({
  filters,
  clearFilters,
  hasSelectedRecord = false,
}) => {
  const filtersMinusFund = _filter(filters, (f) => {
    return f.alertColumnKey !== 'fundId'
  })
  const hasFiltersApplied = filtersMinusFund.length > 0 || hasSelectedRecord
  const activeFilterCount = hasSelectedRecord ? 1 : filtersMinusFund.length

  return hasFiltersApplied ? (
    <div className="DiscoverContainer-text DiscoverContainer-filterLabel">
      {pluralize(activeFilterCount, 'Filter ', 'Filters ')}
      applied
      <Button variant="link" status="error" onClick={() => clearFilters()}>
        Reset All
      </Button>
    </div>
  ) : (
    <div className="DiscoverContainer-text DiscoverContainer-filterLabel">
      Filters
    </div>
  )
}
FilterCountLabel.propTypes = {
  filters: PropTypes.array,
  clearFilters: PropTypes.func,
  hasSelectedRecord: PropTypes.bool,
}

export default FilterCountLabel
