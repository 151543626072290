import { useCallback, useEffect, useState } from 'react'
import { Button } from '@context365/button'
import { faCog, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Divider, Row } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import { fetchCancellingReasonsIfNeeded } from '~/actions/meetings'
import { ReactComponent as Logo } from '~/assets/logo.svg'
import MeetingLobbyDetails from '~/components/MeetingLobbyDetails/MeetingLobbyDetails'
import VideoSettings from '~/components/VideoSettings'
import { SETTINGS_SAVED } from '~/config'
import './meetingLobby.less'

const MeetingLobby = () => {
  const { trackEvent } = useTracking({ component: 'MeetingLobby' })
  const [showSettings, setShowSettings] = useState(false)
  const [settingsSaved, setSettingsSaved] = useState(false)

  const dispatch = useDispatch()

  const saveSettings = useCallback((save) => {
    localStorage.setItem(SETTINGS_SAVED, save)
    setSettingsSaved(save)
  }, [])

  useEffect(() => {
    const settingsSaved = localStorage.getItem(SETTINGS_SAVED)
    setSettingsSaved(isEmpty(settingsSaved) ? false : settingsSaved === 'true')
  }, [])

  useEffect(() => {
    dispatch(fetchCancellingReasonsIfNeeded())
  }, [dispatch])

  return (
    <div className="cc-meeting-lobby-container ">
      <Row justify="center">
        <Col
          xs={{ span: 24 }}
          s={{ span: 24 }}
          md={{ span: 10 }}
          lg={{ span: 10 }}
          xl={{ span: 10 }}
        >
          <div className="cc-meeting-lobby-container-logo">
            <Logo viewBox="0 0 1000 100" />
            <Divider
              className="cc-meeting-lobby-container-divider"
              type="vertical"
            />
            <div className="cc-grey-heading3" style={{ marginLeft: '10px' }}>
              Meeting Lobby
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          s={{ span: 24 }}
          md={{ span: 10 }}
          lg={{ span: 10 }}
          xl={{ span: 10 }}
        >
          <div className="cc-meeting-lobby-container-settings">
            <Button
              variant="filled"
              status="secondary"
              size="big"
              iconLeft={<FontAwesomeIcon icon={faCog} />}
              onClick={() => {
                trackEvent({
                  eventName: 'click',
                  element: 'audio/video settings',
                })
                setShowSettings(true)
              }}
            >
              Audio/Video Settings
            </Button>
            <Link to="/">
              <FontAwesomeIcon
                icon={faTimes}
                color="#62717F"
                size="3x"
                style={{ marginLeft: '60px' }}
              />
            </Link>
          </div>
        </Col>
      </Row>
      <MeetingLobbyDetails
        saveSettings={saveSettings}
        settingsSaved={settingsSaved}
      />

      <VideoSettings
        visible={showSettings}
        closeModal={() => {
          setShowSettings(false)
        }}
        saveSettings={saveSettings}
      />
    </div>
  )
}

export default MeetingLobby
