import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Input } from '@context365/forms'
import { RemoveCircleOutline } from '@context365/icons'

const EducationField = ({
  value,
  errors = {},
  onChange,
  addItem,
  showAdd,
  showRemove,
  removeItem,
}) => {
  const handleChange = (field, val) => {
    onChange({ ...value, [field]: val })
  }

  return (
    <>
      <div className="mb-8">
        <div className="md:grid md:grid-cols-4 md:gap-4">
          <div>
            <Input
              label="School"
              name="school"
              value={value.school}
              required
              errorMessage={errors.school}
              onChange={(e) => handleChange('school', e.target.value)}
            />
          </div>
          <div>
            <Input
              label="Area of Study"
              name="areaOfStudy"
              value={value.areaOfStudy}
              required
              errorMessage={errors.areaOfStudy}
              onChange={(e) => handleChange('areaOfStudy', e.target.value)}
            />
          </div>
          {showRemove && (
            <div className="hidden md:flex pt-5 col-start-4">
              <Button
                iconLeft={<RemoveCircleOutline />}
                variant="outlined"
                status="error"
                onClick={() => removeItem()}
              >
                Remove School
              </Button>
            </div>
          )}
        </div>
      </div>
      {showRemove && (
        <div className="md:hidden mb-8">
          <Button
            className="w-full"
            iconLeft={<RemoveCircleOutline />}
            variant="outlined"
            status="error"
            onClick={() => removeItem()}
          >
            Remove School
          </Button>
        </div>
      )}
      {showAdd && (
        <div>
          <Button variant="link" onClick={() => addItem()}>
            + Add School
          </Button>
        </div>
      )}
    </>
  )
}

EducationField.propTypes = {
  value: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  addItem: PropTypes.func,
  showAdd: PropTypes.bool,
  showRemove: PropTypes.bool,
  removeItem: PropTypes.func,
}

export default EducationField
