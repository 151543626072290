import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Add } from '@context365/icons'
import { faComment } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tag, Tooltip } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {
  replyToMeetingMessage,
  sendFollowUpMessage,
  sendMeetingMessage,
} from '~/actions/messages'
import { MEETING_STATUS } from '~/constants/meetingStatusIds'
import { ROLE } from '~/constants/roles'
import ContactName from '../ContactName/ContactName'
import FollowUpMessages from '../FollowUpMessages'
import InviteCompany from '../InviteCompany'
import MatchmakingScore from '../MatchmakingScore'
import CompanyMeetingAvatars from '../Meetings/CompanyMeetingAvatars'
import MeetingDateTime from './MeetingDateTime'
import MeetingDetailsProfiles from './MeetingDetailsProfiles'
import ProposedTimeSlots from './ProposedSlots/ProposedSlots'
import './MeetingDetails.less'

const CPTooltipText = <span>This meeting carries Context Points.</span>

const MeetingDetails = ({
  matchmakingScore,
  meeting,
  userId,
  setInviteModalVisible,
  conversationId,
  colleaguesContactId,
  loadingMessages,
  participant,
  meetingMessages,
  setConversationId,
  getMeetingMessages,
  onChange,
}) => {
  const role = useSelector((state) => state.auth.role)
  const { meetingInvite } = useFlags()
  const { companies, colleagues, meetingType } = meeting

  const getBooth = () => {
    return (
      !isEmpty(meeting.newBoothId) && (
        <div>
          <span className="type-subtitle-sm text-header">Booth</span>
          <div
            style={{ verticalAlign: 'end' }}
            className="type-subtitle-sm text-header"
          >
            {meeting.newBoothId}
          </div>
        </div>
      )
    )
  }
  const getMeetingDate = () => {
    if (meeting.meetingStatusId === 1 || meeting.meetingStatusId === 5) {
      return (
        <div className="cc-meetingdetails-items">
          <ProposedTimeSlots proposedTimeSlots={meeting.proposedTimeSlots} />
        </div>
      )
    } else if (meeting.meetingStatusId === MEETING_STATUS.CONFIRMED) {
      return <MeetingDateTime meeting={meeting} />
    } else {
      return (
        <div>
          <MeetingDateTime meeting={meeting} />
          <div className="mb-4">
            <span className="type-subtitle-sm text-header">
              {`This meeting was ${meeting.meetingStatus.toLowerCase()} by`}
            </span>
            <div className="flex">
              <ContactName
                name={
                  meeting.cancellationReasons.userThatCancelledOrDeclined
                    .contactName
                }
                position={
                  meeting.cancellationReasons.userThatCancelledOrDeclined
                    .jobTitle
                }
                imageUrl={
                  meeting.cancellationReasons.userThatCancelledOrDeclined
                    .imageUrl
                }
              />
              {meeting.cancellationReasons.cancellationReasonId > 0 && (
                <span style={{ marginLeft: '8px', marginTop: '8px' }}>
                  <Tooltip
                    title={meeting.cancellationReasons.cancellationReasonText}
                  >
                    <FontAwesomeIcon color="#92949C" icon={faComment} />
                  </Tooltip>
                </span>
              )}
            </div>
          </div>
        </div>
      )
    }
  }

  const canInviteColleague =
    participant.participantStatusId !== MEETING_STATUS.CANCELLED &&
    participant.participantStatusId !== MEETING_STATUS.DECLINED &&
    meeting.meetingStatusId !== MEETING_STATUS.CANCELLED &&
    meeting.meetingStatusId !== MEETING_STATUS.DECLINED &&
    meeting.meetingStatusId !== MEETING_STATUS.EXPIRED &&
    (isEmpty(meeting.meetingDateTime) ||
      moment(meeting.meetingDateTime).isAfter(moment.utc()))

  return (
    <div className="cc-container" style={{ marginTop: '26px' }}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '80%' }}>
          <div>
            <span className="type-subtitle-sm">your colleagues</span>
            <div className="space-y-8">
              <div>
                <div>
                  <CompanyMeetingAvatars
                    meeting={meeting}
                    participants={colleagues}
                  />
                </div>
                {canInviteColleague && (
                  <div className="mt-2 mb-4">
                    <Button
                      size="small"
                      status="secondary"
                      onClick={() => {
                        setInviteModalVisible(true)
                      }}
                      iconLeft={<Add />}
                    >
                      Invite Colleagues
                    </Button>
                  </div>
                )}
              </div>
              {companies.map((c) => (
                <div key={c.companyId}>
                  <div className="type-subtitle-sm">{c.companyName}</div>
                  <div className="type-subtitle-xxs">{c.category}</div>
                  <div>
                    <CompanyMeetingAvatars
                      meeting={meeting}
                      participants={c.participants}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          {getMeetingDate()}
          {getBooth()}
          {role === ROLE.MANAGER && !isEmpty(meeting.fundName) && (
            <div>
              <span className="text-header cc-tabletitle-text">Fund</span>
              <div
                style={{ verticalAlign: 'end' }}
                className="type-subtitle-sm text-header"
              >
                {meeting.fundName}
              </div>
            </div>
          )}
          <div className="cc-meetingdetails-items">
            {meeting.carryContextPoints && (
              <div style={{ paddingTop: '16px' }}>
                <Tooltip placement="top" title={CPTooltipText}>
                  <Tag visible={meeting.carryContextPoints}>
                    + 1000 Context Points
                  </Tag>
                </Tooltip>
              </div>
            )}
            {!isNil(matchmakingScore) &&
              meetingType?.toLowerCase() === 'online' && (
                <div style={{ paddingTop: '16px' }}>
                  <div className="type-subtitle-sm text-header mb-2">
                    Matchmaking Score
                  </div>
                  <MatchmakingScore
                    score={matchmakingScore}
                    threshold={meeting.matchmakingThreshold}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
      {meetingInvite &&
        role.toLowerCase() === 'cfn' &&
        companies.length === 1 && (
          <InviteCompany
            companies={companies}
            meetingId={meeting.meetingId}
            onChange={onChange}
            canInviteFunds={isNil(meeting.fundId)}
            onlyCFN={!isNil(meeting.dealId)}
          />
        )}
      {/* show messages  if meeting comes from a physical summit, or it is pending or declined and has no conversation*/}
      {((isNil(conversationId) &&
        (meeting.meetingStatus.toLowerCase() === 'pending' ||
          meeting.meetingStatus.toLowerCase() === 'declined')) ||
        (meetingType?.toLowerCase() === 'summit' && !meeting.isVirtual)) && (
        <FollowUpMessages
          meetingId={meeting.meetingId}
          sender={colleaguesContactId.includes(meeting.contactId)}
          loading={loadingMessages}
          meetingStatus={meeting.meetingStatus}
          participantStatusId={participant.participantStatusId}
          messages={meetingMessages}
          sendHandler={
            colleaguesContactId.includes(meeting.contactId)
              ? meetingMessages.length === 0
                ? sendMeetingMessage
                : sendFollowUpMessage
              : replyToMeetingMessage
          }
          onSend={() => {
            getMeetingMessages()
          }}
          conversationId={conversationId}
          setConversationId={setConversationId}
          userId={userId}
        />
      )}
      <MeetingDetailsProfiles meeting={meeting} companies={companies} />
    </div>
  )
}

MeetingDetails.propTypes = {
  matchmakingScore: PropTypes.number,
  userId: PropTypes.number.isRequired,
  meeting: PropTypes.object.isRequired,
  setInviteModalVisible: PropTypes.func,
  proposedTimeSlots: PropTypes.array,
  conversationId: PropTypes.number,
  colleaguesContactId: PropTypes.array,
  loadingMessages: PropTypes.bool,
  participant: PropTypes.object,
  meetingMessages: PropTypes.array,
  setConversationId: PropTypes.func,
  getMeetingMessages: PropTypes.func,
  onChange: PropTypes.func,
}
export default MeetingDetails
