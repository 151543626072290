import * as yup from 'yup'

export const schema = [
  yup.object().shape({}),
  yup.object().shape({
    title: yup.string().required('Please enter a title'),
    description: yup.string().required('Please enter a description'),
    date: yup.string().required('Please select a date'),
    time: yup.string().required('Please select a time'),
    duration: yup
      .number()
      .required('Please enter a duration')
      .min(1, 'Please enter a valid duration')
      .max(720, 'Please enter a valid duration'),
    fundId: yup.number(),
    dealId: yup.number(),
    deal: yup.string(),
    fund: yup.string(),
  }),
  yup.object().shape({
    attendeesRolesPresenters: yup
      .array()
      .max(5, 'There can only be 5 Presenters'),
    attendeesRolesModerator: yup
      .array()
      .max(1, 'There can only be one Moderator'),
    attendeesRolesAdmin: yup
      .array()
      .min(1, 'There needs to be at least one Admin')
      .max(1, 'There can only be one Admin'),
    attendeesInvitesRolesPresenters: yup.array(),
    attendeesInvitesRolesModerator: yup.array(),
  }),
  yup.object().shape({
    attendance: yup.number(),
    segmentsLength: yup.number().when('attendance', {
      is: 2,
      then: yup.number().min(1),
      otherwise: yup.number().min(0),
    }),
  }),
  yup.object().shape({
    guestMembers: yup.array().of(
      yup.object().shape({
        firstName: yup.string().required('Please enter first name'),
        lastName: yup.string().required('Please enter last name'),
        email: yup
          .string()
          .email('Please enter a valid email address')
          .required('Please enter guest email address'),
      })
    ),
    attendance: yup.number(),
    userInvites: yup.array().of(
      yup.object().shape({
        name: yup.string().required('Please enter user full name').nullable(),
      })
    ),
    attendeesInvitesRolesPresenters: yup
      .array()
      .max(5, 'There can only be 5 Presenters'),
    attendeesInvitesRolesModerator: yup
      .array()
      .max(1, 'There can only be one Moderator'),
    attendees: yup.array().max(30),
  }),
]
