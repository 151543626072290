import { ChartTooltip } from '@context365/charts'
import cx from 'classnames'

export default function CompareFundsTooltip({
  getRowClass = () => {},
  ...props
}) {
  return (
    <ChartTooltip
      {...props}
      unstable_portal={false}
      renderDataPoint={({ series, label, value }) => {
        const { strokeWidth, stroke, strokeDasharray } = series.getSeriesProps()
        const className = getRowClass(series.key)
        return (
          <tr>
            <td className={className}>
              <div>
                <svg height={12} width={36}>
                  <line
                    x1={0}
                    x2={36}
                    y1={6}
                    y2={6}
                    strokeWidth={strokeWidth}
                    stroke={stroke}
                    strokeDasharray={strokeDasharray}
                  />
                </svg>
              </div>
            </td>
            <td className={cx(className, 'chart-tooltip__table-label')}>
              {label}
            </td>
            <td className={cx(className, 'chart-tooltip__table-value')}>
              {value}
            </td>
          </tr>
        )
      }}
    />
  )
}
