import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Radio } from 'antd'
import isNil from 'lodash/isNil'
import { BASE_URL } from '~/config'
import FloatingTextArea from '../FloatingTextArea'
import { UploadFileField } from '../FundWizard'

const InvestmentPolicySection = ({ value, onChange }) => {
  const [questions, setQuestions] = useState(
    value || { type: null, content: null }
  )
  const [confirmationVisible, setConfirmationVisible] = useState(false)
  const [type, setType] = useState(null)

  const onContentChanged = useCallback(
    (newValue) => {
      const val = value || { type: null, content: null }
      val.content = newValue
      setQuestions(val)
      onChange(val)
    },
    [value, onChange]
  )

  const setNewType = useCallback(
    (newValue) => {
      const val = value || { type: null, content: null }
      val.type = newValue || type
      val.content = null
      setQuestions(val)
      onChange(val)
    },
    [onChange, type, value]
  )

  const onTypeChanged = useCallback(
    (newValue) => {
      setType(newValue)
      const val = value || { type: null, content: null }
      if (newValue === 'string' && !isNil(val.content)) {
        setConfirmationVisible(true)
      } else {
        setNewType(newValue)
      }
    },
    [value, setNewType]
  )

  return (
    <div>
      <div className="cc-investmet-policy-radio-group">
        <Radio.Group
          onChange={(e) => {
            onTypeChanged(e.target.value)
          }}
          value={value ? value.type : ''}
        >
          <Radio value="string" className="cc-investmet-policy-radio-item">
            Open Text Field
          </Radio>
          <Radio value="file" className="cc-investmet-policy-radio-item">
            Upload PDF
          </Radio>
        </Radio.Group>
      </div>
      {value && value.type === 'string' && (
        <FloatingTextArea
          value={questions.content}
          onChange={(e) => {
            onContentChanged(e)
          }}
          name="Investment Policy"
        />
      )}
      {value && value.type === 'file' && (
        <div className="cc-investment-policy-upload">
          <UploadFileField
            accept=".pdf"
            listType="picture"
            hint="File Types: .PDF Max File Size: 4MB"
            action={`${BASE_URL}/uploads`}
            value={questions.content}
            name="Investment Policy"
            onChange={(e) => {
              onContentChanged(e)
            }}
          />
        </div>
      )}
      <Modal
        title="Are you sure?"
        visible={confirmationVisible}
        onOk={() => {
          setNewType()
          setConfirmationVisible(false)
        }}
        okText="Switch"
        cancelText="Cancel"
        onCancel={() => setConfirmationVisible(false)}
        okButtonProps={{ type: 'danger' }}
      >
        <span style={{ marginBottom: '40px' }}>
          Are you sure you want to change to free text? It will delete your
          selected PDF file.
        </span>
      </Modal>
    </div>
  )
}

InvestmentPolicySection.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
}

export default InvestmentPolicySection
