import axios from 'axios'
import { TIMEOUT } from '~/config'
import * as errorReporting from '~/utils/errorReporting'

export function createInstance(baseURL) {
  const api = axios.create({
    baseURL,
    timeout: TIMEOUT,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  })

  api.interceptors.response.use(null, reportError)

  return api
}

function reportError(err) {
  const fingerprint = getIssueFingerprint(err)
  const requestConfig = getIssueRequestInfo(err.config)

  errorReporting.report(err, {
    fingerprint,
    extras: { requestConfig },
  })

  // Re-throw the error so downstream code can still use it, but mark it as
  // reported so the `onunhandledrejection` handler doesn't report a duplicate.
  err.reported = true
  throw err
}

function getIssueFingerprint(error) {
  const { url, method } = error.config

  return [sanitizeUrl(url), method, error.response?.status].filter(Boolean)
}

// Replaces any IDs in the request path with a placeholder
// e.g. /users/1234 -> /users/:id
function sanitizeUrl(url) {
  const pattern = /(\/)\d+(\/?)/

  while (pattern.test(url)) {
    url = url.replace(pattern, '$1:id$2')
  }

  // Don't include the query string or hash in the fingerprint
  return url.split(/[?#]/)[0]
}

function getIssueRequestInfo(config) {
  const { url, method, params, data } = config

  const info = { url, method }
  if (params) {
    info.params = params
  }
  if (data) {
    info.data = data
  }

  return info
}

export function addDefaultAuthTokenFromStorage(api, storageKey, formatToken) {
  const storedToken = getToken(storageKey)
  if (!storedToken) {
    return
  }

  api.defaults.headers.common.Authorization = formatToken(storedToken)
}

export function applyAuthTokenFromStorage(api, storageKey, formatToken) {
  api.interceptors.request.use((config) => {
    const storedToken = getToken(storageKey)
    if (!storedToken) {
      return config
    }

    config.headers.Authorization = formatToken(storedToken)
    return config
  })
}

function getToken(storageKey) {
  try {
    return localStorage.getItem(storageKey)
  } catch {
    return undefined
  }
}
