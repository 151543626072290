import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { isAuthenticated } from '~/selectors/auth'

export default function RequireAuth({ children }) {
  const location = useLocation()
  const userAuthenticated = useSelector(isAuthenticated)
  if (!userAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          search: location.search,
          state: location.pathname + location.search,
        }}
      />
    )
  }

  return children
}

RequireAuth.propTypes = {
  children: PropTypes.node,
}
