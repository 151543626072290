import {
  Axis,
  Chart,
  Grid,
  LineSeries,
  useAxis,
  useChart,
  useChartTooltipState,
  useLineSeries,
} from '@context365/charts'
import compact from 'lodash/compact'
import * as api from '~/api'
import { LineChartPlaceholder } from '~/components/ChartPlaceholder'
import { chart as chartColors } from '~/constants/colors'
import { useCompareFundsContext } from '../compareFundsContext'
import {
  BenchmarkSelect,
  ChartContainer,
  ChartControls,
} from './chartComponents'
import {
  CHART_HEIGHT,
  CHART_WIDTH,
  createDataset,
  format,
  formatRebaseDate,
  parseRebaseDate,
  useTimeAxis,
} from './chartHelpers'
import CompareFundsTooltip from './CompareFundsTooltip'
import createComparisonChart from './createComparisonChart'
import { useTrackComparedFunds } from './trackCompareFunds'

function VamiChart({ chartData }) {
  const {
    benchmarks: allBenchmarks,
    funds,
    options,
    getFundColor,
  } = useCompareFundsContext()

  /* eslint-disable eqeqeq -- we're comparing keys to numbers here */
  const findBenchmark = (id) => allBenchmarks.find((b) => b.fundIndexId == id)
  const findFund = (id) => funds.find((f) => f.fundId == id)
  const isPrimaryBenchmark = (id) => options.primaryBenchmarkId == id
  /* eslint-enable eqeqeq */

  const chart = useChart({
    height: CHART_HEIGHT,
    width: CHART_WIDTH,
    padding: {
      left: 45,
      right: 20,
      top: 10,
      bottom: 20,
    },
    data: chartData,
  })

  const xAxis = useTimeAxis(chartData, chart)
  const yAxis = useAxis({
    type: 'linear',
    getValue: (d) => d[1] * 1000,
    formatValue: format.float,
    formatAxis: format.decimal,
    range: chart.range.vertical,
  })

  const lines = useLineSeries({
    chart,
    x: xAxis,
    y: yAxis,
    series: Object.keys(chartData.funds)
      .map((fundId) => ({
        key: fundId,
        ...getFundColor(fundId),
        label: findFund(fundId)?.name,
        getDataset: (data) => createDataset(data.dates, data.funds[fundId]),
      }))
      .concat(
        Object.keys(chartData.bm).map((benchmarkId) => ({
          key: benchmarkId,
          color: isPrimaryBenchmark(benchmarkId)
            ? chartColors.sAndP
            : chartColors.benchmark,
          label: findBenchmark(benchmarkId)?.name,
          getDataset: (data) => createDataset(data.dates, data.bm[benchmarkId]),
        }))
      ),
  })

  const tooltip = useChartTooltipState(chart, lines)
  const rebaseDate = parseRebaseDate(chartData.rebasedate)

  return (
    <ChartContainer>
      <Chart {...chart.getChartProps()}>
        <Axis className="text-grey-500" {...lines.getAxisProps('bottom')} />
        <Axis className="text-grey-500" {...lines.getAxisProps('left')} />
        <Grid
          className="text-grey-400"
          {...lines.getGridProps('vertical')}
          tickValues={[rebaseDate]}
        />
        <LineSeries series={lines.series} />
      </Chart>
      <CompareFundsTooltip {...tooltip} />
      <div className="mt-2 text-center text-xs">
        Returns rebased to 1,000 at {formatRebaseDate(rebaseDate)}
      </div>
    </ChartContainer>
  )
}

function VamiChartControls() {
  const { options, setOption, benchmarks } = useCompareFundsContext()
  const trackClick = useTrackComparedFunds(
    'primaryBenchmarkId',
    'secondaryBenchmarkId'
  )

  function changeOption(name, key, value) {
    const update = { [key]: value }
    trackClick(name, update)
    setOption(update)
  }

  return (
    <ChartControls>
      <BenchmarkSelect
        benchmarks={benchmarks}
        id="primary-benchmark"
        label="Benchmark"
        indicatorColor={chartColors.sAndP}
        selectedId={options.primaryBenchmarkId}
        onChange={(id) =>
          changeOption('PrimaryBenchmark', 'primaryBenchmarkId', id)
        }
      />
      <BenchmarkSelect
        benchmarks={benchmarks}
        id="secondary-benchmark"
        label="Secondary Benchmark"
        indicatorColor={chartColors.benchmark}
        selectedId={options.secondaryBenchmarkId}
        onChange={(id) =>
          changeOption('SecondaryBenchmark', 'secondaryBenchmarkId', id)
        }
      />
    </ChartControls>
  )
}

export default createComparisonChart({
  queryFn: (fundIds, options) =>
    api.fundCharts.compareFunds(
      fundIds,
      ...compact([options.primaryBenchmarkId, options.secondaryBenchmarkId])
    ),
  chartControls: VamiChartControls,
  loading: (
    <ChartContainer>
      <LineChartPlaceholder
        animate
        aria-label="Loading chart data"
        height={CHART_HEIGHT}
        width={CHART_WIDTH}
      />
    </ChartContainer>
  ),
  error: ({ error }) => (
    <ChartContainer className="relative">
      <LineChartPlaceholder
        aria-label="data not available"
        height={CHART_HEIGHT}
        width={CHART_WIDTH}
      />
      <div className="flex flex-col absolute inset-0 items-center justify-center">
        <div className="type-body-regular-lg mb-2">
          Unable to load chart data.
        </div>
        <div className="type-body-regular-md">
          Please choose a different benchmark or different funds to compare.
        </div>
        <div className="type-body-regular-xs">
          {error.response.data.message}
        </div>
      </div>
    </ChartContainer>
  ),
  success: VamiChart,
})
