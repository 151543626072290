import isNil from 'lodash/isNil'
import qs from 'qs'
import { api as http } from '~/api/services'
import {
  FETCH_CONFIRMED_MEETINGS,
  FETCH_CONFIRMED_MEETINGS_COMPANYEVENTID,
  FETCH_DECLINED_MEETINGS,
  FETCH_DECLINED_MEETINGS_COMPANYEVENTID,
  FETCH_ONLINE_CONFIRMED_MEETINGS,
  FETCH_ONLINE_DECLINED_MEETINGS,
  FETCH_ONLINE_REQUESTED_MEETINGS,
  FETCH_ONLINE_SENT_REQUESTS,
  FETCH_REQUESTED_MEETINGS,
  FETCH_REQUESTED_MEETINGS_COMPANYEVENTID,
  FETCH_ROADSHOW_CONFIRMED_MEETINGS,
  FETCH_ROADSHOW_DECLINED_MEETINGS,
  FETCH_ROADSHOW_REQUESTED_MEETINGS,
  FETCH_ROADSHOW_SENT_REQUESTS,
  FETCH_SENT_REQUESTS,
  FETCH_SENT_REQUESTS_COMPANYEVENTID,
} from '~/constants/types'

const getRequestParams = (params) => ({
  params,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
})

//actions
export const sendOnlineRequestToAllocator = (
  toCompanyId,
  fundId,
  contactIds,
  toContactIds,
  message,
  meetingTypeId,
  answers,
  originalCompanyContactId,
  roadshowId,
  carryContextPoints,
  sentFromMandateView
) =>
  http.post('meetings', {
    toCompanyId,
    fundId,
    message,
    MeetingTypeId: meetingTypeId,
    contactIds,
    toContactIds,
    answers,
    originalCompanyContactId,
    roadshowId,
    carryContextPoints,
    sentFromMandateView,
  })

const getMeetingParticipants = (toCompanyId, params) =>
  // eslint-disable-next-line local-rules/api-requests -- the function in api/meeting.js uses API v2
  http.get(`companies/${toCompanyId}/meeting-participants`, { params })

export const getColleagues = (toCompanyId, fundId, eventId, roadshowId) => {
  if (!isNil(roadshowId)) {
    return getMeetingParticipants(toCompanyId, { fundId, roadshowId })
  } else if (isNil(eventId)) {
    return getMeetingParticipants(toCompanyId, { fundId })
  } else if (isNil(fundId)) {
    return getMeetingParticipants(toCompanyId, { eventId })
  } else {
    return getMeetingParticipants(toCompanyId, { fundId, eventId })
  }
}

export const getColleaguesForServiceProvider = (
  toCompanyId,
  fundId,
  eventId,
  roadshowId
) => {
  if (!isNil(roadshowId)) {
    return getMeetingParticipants(toCompanyId, { fundId, roadshowId })
  } else if (isNil(eventId) && isNil(fundId)) {
    return getMeetingParticipants(toCompanyId)
  } else if (isNil(eventId)) {
    return getMeetingParticipants(toCompanyId, { fundId })
  } else if (isNil(fundId)) {
    return getMeetingParticipants(toCompanyId, { eventId })
  } else {
    return getMeetingParticipants(toCompanyId, { fundId, eventId })
  }
}

export const sendOnlineRequestToFund = (
  toCompanyId,
  fundId,
  contactIds,
  toContactIds,
  message,
  meetingTypeId,
  roadshowId,
  carryContextPoints,
  invitedCompanyContactIds,
  sentFromMandateView
) =>
  http.post('meetings', {
    toCompanyId,
    fundId,
    message,
    meetingTypeId,
    contactIds,
    toContactIds,
    roadshowId,
    carryContextPoints,
    invitedCompanyContactIds,
    sentFromMandateView,
  })

export const sendOnlineRequestToServiceProvider = (
  toCompanyId,
  fundId,
  contactIds,
  toContactIds,
  message,
  meetingTypeId,
  roadshowId,
  originalCompanyContactId,
  sentFromMandateView
) =>
  http.post('meetings', {
    toCompanyId,
    fundId,
    message,
    meetingTypeId,
    contactIds,
    toContactIds,
    roadshowId,
    originalCompanyContactId,
    sentFromMandateView,
  })

export const sendOnlineMeetingRequestCFN = (
  toCompanyId,
  contactIds,
  toContactIds,
  message,
  meetingTypeId,
  originalCompanyContactId,
  invitedCompanyContactIds,
  fundId,
  dealId,
  sentFromMandateView
) =>
  http.post('meetings', {
    toCompanyId,
    message,
    meetingTypeId,
    contactIds,
    toContactIds,
    originalCompanyContactId,
    invitedCompanyContactIds,
    fundId,
    dealId,
    sentFromMandateView,
  })

export const acceptSummitMeetingAllocator = (
  meetingId,
  meetingDateTime,
  meetingDuration,
  eventId
) =>
  http.patch(`meetings/${meetingId}/accept`, {
    meetingId,
    meetingDateTime,
    meetingDuration,
    eventId,
  })

export const acceptOnlineMeetingAllocator = (
  meetingId,
  meetingDateTime,
  meetingDuration,
  proposedMeetingDateTimeId,
  answers
) =>
  http.patch(`meetings/${meetingId}/accept`, {
    meetingId,
    meetingDateTime,
    meetingDuration,
    proposedMeetingDateTimeId,
    answers,
  })

export const declineOnlineMeeting = (meetingId) =>
  http.patch(`meetings/${meetingId}/decline`)

export const cancelOnlineMeeting = (
  meetingId,
  cancellationReasonId,
  cancellationReasonText
) =>
  http.patch(`meetings/${meetingId}/cancel`, {
    cancellationReasonId,
    cancellationReasonText,
  })

export const cancelOnlineMeetingForAll = (
  meetingId,
  cancellationReasonId,
  cancellationReasonText
) =>
  http.patch(`meetings/${meetingId}/cancelForAll`, {
    cancellationReasonId,
    cancellationReasonText,
  })

export const rescheduleOnlineMeeting = (
  meetingId,
  meetingDateTime,
  meetingDuration,
  isSetTime = false
) =>
  http.patch(`meetings/${meetingId}/reschedule`, {
    meetingDateTime,
    meetingDuration,
    isSetTime,
  })

//lists

export const fetchReceivedRequests = (params) => (dispatch) =>
  http.get('meetings/received', getRequestParams(params)).then((response) => {
    if (params.eventId && params.eventId > 0)
      dispatch({
        type: FETCH_REQUESTED_MEETINGS_COMPANYEVENTID,
        payload: response.data.result,
      })
    else if (params.meetingTypeId === 1) {
      dispatch({
        type: FETCH_REQUESTED_MEETINGS,
        payload: response.data.result,
      })
    } else if (params.meetingTypeId === 2) {
      dispatch({
        type: FETCH_ROADSHOW_REQUESTED_MEETINGS,
        payload: response.data.result,
      })
    } else if (params.meetingTypeId === 3) {
      dispatch({
        type: FETCH_ONLINE_REQUESTED_MEETINGS,
        payload: response.data.result,
      })
    }
  })
export const fetchMeetingsConfirmed = (params) => (dispatch) =>
  http.get('meetings/confirmed', getRequestParams(params)).then((response) => {
    if (params.eventId && params.eventId > 0)
      dispatch({
        type: FETCH_CONFIRMED_MEETINGS_COMPANYEVENTID,
        payload: response.data.result,
      })
    else if (params.meetingTypeId === 1) {
      dispatch({
        type: FETCH_CONFIRMED_MEETINGS,
        payload: response.data.result,
      })
    } else if (params.meetingTypeId === 2) {
      dispatch({
        type: FETCH_ROADSHOW_CONFIRMED_MEETINGS,
        payload: response.data.result,
      })
    } else if (params.meetingTypeId === 3) {
      dispatch({
        type: FETCH_ONLINE_CONFIRMED_MEETINGS,
        payload: response.data.result,
      })
    }
  })
export const fetchSentRequests = (params) => (dispatch) =>
  http.get('meetings/sent', getRequestParams(params)).then((response) => {
    if (params.eventId && params.eventId > 0)
      dispatch({
        type: FETCH_SENT_REQUESTS_COMPANYEVENTID,
        payload: response.data.result,
      })
    else if (params.meetingTypeId === 1) {
      dispatch({
        type: FETCH_SENT_REQUESTS,
        payload: response.data.result,
      })
    } else if (params.meetingTypeId === 2) {
      dispatch({
        type: FETCH_ROADSHOW_SENT_REQUESTS,
        payload: response.data.result,
      })
    } else if (params.meetingTypeId === 3) {
      dispatch({
        type: FETCH_ONLINE_SENT_REQUESTS,
        payload: response.data.result,
      })
    }
  })
export const fetchDeclinedRequests = (params) => (dispatch) =>
  http.get('meetings/declined', getRequestParams(params)).then((response) => {
    if (params.eventId && params.eventId > 0)
      dispatch({
        type: FETCH_DECLINED_MEETINGS_COMPANYEVENTID,
        payload: response.data.result,
      })
    else if (params.meetingTypeId === 1) {
      dispatch({
        type: FETCH_DECLINED_MEETINGS,
        payload: response.data.result,
      })
    } else if (params.meetingTypeId === 2) {
      dispatch({
        type: FETCH_ROADSHOW_DECLINED_MEETINGS,
        payload: response.data.result,
      })
    } else if (params.meetingTypeId === 3) {
      dispatch({
        type: FETCH_ONLINE_DECLINED_MEETINGS,
        payload: response.data.result,
      })
    }
  })
