import camelCase from 'lodash/camelCase'
import { NumberInputField } from '~/components/Form'

export default function NumberRangeField({
  className,
  name,
  minFieldName = camelCase(`minimum ${name}`),
  maxFieldName = camelCase(`maximum ${name}`),
  label,
  minFieldLabel = `Minimum ${label}`,
  maxFieldLabel = `Maximum ${label}`,
  ...inputProps
}) {
  return (
    <div className={className}>
      <div className="type-body-semibold-md">{label}</div>
      <div className="grid grid-cols-2 gap-4">
        <NumberInputField
          name={minFieldName}
          label={minFieldLabel}
          {...inputProps}
        />
        <NumberInputField
          name={maxFieldName}
          label={maxFieldLabel}
          {...inputProps}
        />
      </div>
    </div>
  )
}
