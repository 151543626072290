import PropTypes from 'prop-types'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import './DiscoverFilters.less'

const CollapseToggle = ({ isActive }) => {
  return isActive ? (
    <UpOutlined className="ant-collapse-arrow collapse-header-icon" />
  ) : (
    <DownOutlined className="ant-collapse-arrow collapse-header-icon" />
  )
}
CollapseToggle.propTypes = {
  isActive: PropTypes.bool,
}

export default CollapseToggle
