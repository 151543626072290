import isNil from 'lodash/isNil'
import { generateFlagsIdentity } from './flags'

export const loadFlagsIdentity = () => {
  const contact = JSON.parse(localStorage.getItem('contact'))
  const claims = JSON.parse(localStorage.getItem('claims'))
  const company = JSON.parse(localStorage.getItem('company'))

  if (isNil(contact) || isNil(claims) || isNil(company)) {
    return null
  }

  const identity = generateFlagsIdentity(claims, contact, company)
  return identity
}
