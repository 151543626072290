import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Option, Select } from '@context365/forms'
import { faBookmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, Input, Modal, Tooltip, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useTracking } from 'react-tracking'
import {
  composeMessage,
  getCompanyContactsForCompose,
  sendMessageToConversation,
} from '~/actions/messages'
import ContactName from '../ContactName'
import ProfilePictureGroup from '../ProfilePictureGroup'
import './ComposeMessageModal.less'

const { TextArea } = Input

const ComposeMessageModal = ({
  visible,
  onClose,
  companyID = null,
  companies = [],
  conversationId = null,
  companyName = null,
  eventID = null,
  selectAll = true,
  selectedContactId = null,
  showContactsAttendingEvent = true,
  fromMandateId = null,
  fromFundId = null,
  fromCampaignApplicationId = null,
  requiredContactId = null,
}) => {
  const { trackEvent } = useTracking()
  const [otherContacts, setOtherContacts] = useState([])
  const [selectedContactIDs, setSelectedContactIDs] = useState([])
  const [messageBody, setMessageBody] = useState('')
  const [sendingMessageLoading, setSendingMessageLoading] = useState(false)
  const [selectedCompanyId, setSelectedCompanyId] = useState(
    companyID || companies[0]?.companyId
  )

  useEffect(() => {
    if (visible && selectedCompanyId) {
      getCompanyContactsForCompose(selectedCompanyId, eventID)
        .then((response) => {
          const contacts = response.data.result
          const selectedContacts = isNil(selectedContactId)
            ? contacts
            : contacts.filter((c) => c.contactId === selectedContactId)
          setOtherContacts(selectedContacts)
          selectAll
            ? setSelectedContactIDs(map(selectedContacts, (c) => c.contactId))
            : setSelectedContactIDs([requiredContactId])
        })
        .catch(() => {
          message.error('An error occurred while fetching colleagues')
        })
    }
  }, [selectedCompanyId, visible, eventID, selectAll, selectedContactId])

  // one of the colleagues has been selected / deselected
  const participantChangedHandler = useCallback(
    (e, contact) => {
      const { checked } = e.target
      if (checked === true) {
        setSelectedContactIDs([...selectedContactIDs, contact.contactId])
      } else {
        setSelectedContactIDs(
          selectedContactIDs.filter((x) => x !== contact.contactId)
        )
      }
    },
    [selectedContactIDs]
  )

  // method that resets data for next compose
  const recycleUI = () => {
    setOtherContacts([])
    setSelectedContactIDs([])
    setMessageBody('')
  }

  const handleMessageSent = () => {
    recycleUI()
    onClose()
    message.success('Successfully sent message.')
  }

  // user decides to send message
  const sendMessageHandler = useCallback(async () => {
    setSendingMessageLoading(true)
    trackEvent({ eventName: 'click', element: 'SendMessageFromModal' })
    try {
      ;(isNil(conversationId)
        ? composeMessage(
            selectedContactIDs,
            selectedCompanyId,
            messageBody,
            eventID,
            fromMandateId,
            fromFundId,
            fromCampaignApplicationId
          )
        : sendMessageToConversation(conversationId, messageBody)
      ).then(() => {
        handleMessageSent()
      })
    } catch (error) {
      message.error('There was an error while attempting to send the message')
    } finally {
      setSendingMessageLoading(false)
    }
  }, [
    selectedContactIDs,
    messageBody,
    onClose,
    selectedCompanyId,
    eventID,
    conversationId,
  ])

  // renders one checkbox for each contact in the colleagues list
  const renderCheckbox = (contact) => {
    const isChecked = selectedContactIDs.includes(contact.contactId)
    return (
      <div className="messages-compose-contact-checkbox-card">
        <div className="messages-compose-contact-checkbox">
          <Checkbox
            style={{
              width: '20px',
            }}
            disabled={requiredContactId === contact.contactId}
            checked={isChecked}
            onChange={(e) => participantChangedHandler(e, contact)}
          />
        </div>
        <div className="messages-compose-contact-checkbox-image">
          <ProfilePictureGroup
            diameter={24}
            maxImages={1}
            profiles={[
              {
                image: contact.imageUrl,
                ...contact,
              },
            ]}
          />
        </div>
        <div className="messages-compose-contact-checkbox-text">
          <div className="cc-small-font messages-compose-contact-checkbox-name">
            {contact.contactName}
          </div>
          <div className="cc-xsmall-font messages-compose-contact-checkbox-title">
            {contact.jobTitle}
          </div>
        </div>
        <div className="messages-compose-contact-checkbox-summit-presence">
          {contact.eventID > 0 && showContactsAttendingEvent ? (
            <Tooltip title={`Attending the ${contact.eventName} summit`}>
              <FontAwesomeIcon icon={faBookmark} />
            </Tooltip>
          ) : null}
        </div>
      </div>
    )
  }

  return (
    <Modal
      style={{
        minWidth: '646px',
      }}
      visible={visible}
      onOk={sendMessageHandler}
      onCancel={() => {
        recycleUI()
        onClose()
      }}
      okButtonProps={{
        disabled:
          !(!isNil(messageBody) && messageBody !== '') ||
          (isNil(conversationId) && isEmpty(selectedContactIDs)),
        loading: sendingMessageLoading,
      }}
      okText="Send Message"
      title="Send Message"
    >
      <div className="messages-compose-container">
        {!companyID && companies.length > 1 && (
          <Select
            label="Select which company you wish to message"
            value={selectedCompanyId}
            onChange={(value) => setSelectedCompanyId(value)}
          >
            {map(companies, (company) => (
              <Option key={company.companyId} value={company.companyId}>
                {`${company.name} (${company.category})`}
              </Option>
            ))}
          </Select>
        )}
        {otherContacts.length > 0 && (
          <div className="messages-compose-more-contacts">
            {isNil(selectedContactId) && (
              <div>
                {isNil(conversationId) ? (
                  <>
                    <div className="cc-heading5 messages-compose-title">
                      Select participants
                    </div>
                    <div className="messages-compose-more-contacts-checks">
                      {otherContacts.map((x) => renderCheckbox(x))}
                    </div>
                  </>
                ) : (
                  <div className="investors-container">
                    <h3>{companyName}</h3>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                        marginBottom: '26px',
                      }}
                    >
                      {otherContacts.map((investor) => (
                        <div
                          key={investor.contactId}
                          style={{ width: '33%', padding: '2px' }}
                        >
                          <ContactName
                            name={investor.contactName}
                            position={investor.jobTitle}
                            imageUrl={investor.imageUrl}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* textarea for message is also shown only if an initial contact is selected */}
            <div
              className={`cc-heading5 ${
                isNil(conversationId) && 'messages-compose-title'
              }`}
            >
              Enter the message you want to send:
            </div>
            <div className="messages-compose-textbox">
              <TextArea
                value={messageBody}
                onChange={(e) => {
                  setMessageBody(e.target.value)
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

ComposeMessageModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  companyID: PropTypes.number,
  companies: PropTypes.array,
  eventID: PropTypes.number,
  selectedContactId: PropTypes.number,
  conversationId: PropTypes.number,
  companyName: PropTypes.string,
  selectAll: PropTypes.bool,
  showContactsAttendingEvent: PropTypes.bool,
  fromMandateId: PropTypes.number,
  fromFundId: PropTypes.number,
  fromCampaignApplicationId: PropTypes.number,
  requiredContactId: PropTypes.number,
}

export default ComposeMessageModal
