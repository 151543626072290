import jwtDecode from 'jwt-decode'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { FUND_PERFORMANCE_API_TOKEN } from '~/config'
import { api, fundPerformance } from './services'
import { addRefreshTokenInterceptor } from './services/refreshTokenInterceptor'

const overrideFundId = process.env.REACT_APP_CHARTS_FUND_ID || undefined

addRefreshTokenInterceptor(fundPerformance, {
  shouldRefresh: (response) => response && [401, 403].includes(response.status),
  refreshToken: () => getToken(),
})

export function logInIfNeeded() {
  return isTokenAlive() ? Promise.resolve() : getToken()
}

function isTokenAlive() {
  const token = localStorage.getItem(FUND_PERFORMANCE_API_TOKEN)
  if (isNil(token) || token === 'alphaserve_token') {
    return false
  }
  const decodedToken = jwtDecode(token)
  const now = moment().unix()
  return typeof decodedToken.exp !== 'undefined' && decodedToken.exp > now
}

function getToken() {
  return api
    .post('/charts/login')
    .then((response) => {
      if (!isNil(response?.data.message)) {
        localStorage.setItem(FUND_PERFORMANCE_API_TOKEN, response.data.message)
      }
      return Promise.resolve()
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const fetchFundReturnDistribution = (fundId) =>
  fundPerformance.get('singlefundanalysis/testhist', {
    params: { fundid: overrideFundId ?? fundId },
  })

export const fetchFundVami = (fundId, indexFund, altId) =>
  fundPerformance.get('singlefundanalysis/vamialt', {
    params: {
      fundid: overrideFundId ?? fundId,
      fundindexid: indexFund,
      altid: isNil(altId) || altId === 0 ? undefined : altId,
    },
  })

export const fetchFundReturnAum = (fundId) =>
  fundPerformance.get('singlefundanalysis/fundreturnaum', {
    params: { fundid: overrideFundId ?? fundId },
  })

export const fetchFundSpxReturn = (fundId, indexFund) =>
  fundPerformance.get('singlefundanalysis/fundspxreturn', {
    params: {
      fundid: overrideFundId ?? fundId,
      fundindexid: indexFund,
    },
  })

export const fetchFundPerformance = (fundId, indexFund) =>
  fundPerformance.get('singlefundanalysis/fundperformance', {
    params: {
      fundid: overrideFundId ?? fundId,
      fundindexid: indexFund,
    },
  })

export const fetchFundMonthlyReturns = (fundId) =>
  fundPerformance.get('singlefundanalysis/monthlyreturns', {
    params: { fundid: overrideFundId ?? fundId },
  })

export const fetchFundMissingMonths = (fundids) =>
  fundPerformance.post('ts/ood', { fundids })

export const fetchLastMonthUpdatedList = (fundids) =>
  fundPerformance.post('ts/maxmonthlist', { fundids })

export const fetchFundStats = (fundId) =>
  fundPerformance.get('ts/stats', {
    params: { fundid: overrideFundId ?? fundId },
  })

export const fetchFundBmStats = (fundId, altId) =>
  fundPerformance.get('ts/bmstats', {
    params: {
      fundid: overrideFundId ?? fundId,
      altid: isNil(altId) || altId === 0 ? undefined : altId,
    },
  })

export const updateMultipleMonthReturns = (fundId, returns) =>
  fundPerformance.post('ts/updatearray', returns, {
    params: { fundid: fundId },
  })

export const getFundEmailUpdates = (fundid) =>
  fundPerformance.post('ts/emailupdatefd', { fundid })

export const approveEmailReturnMultiMonth = (fundId, dates) =>
  fundPerformance.post(
    'ts/approveemailmulti',
    { ...dates },
    {
      params: { fundid: fundId },
    }
  )

export const updateFundStats = (fundId) =>
  api.post(`funds/updateStats/${fundId}`)

export const updateFundReturns = (fundId, fromEmail, dates) =>
  api.post(`funds/updateReturns/${fundId}`, { fromEmail, ...dates })

export const fetchFundYTDReturns = (fundId) =>
  fundPerformance.get('ts/ytdpercent', {
    params: { fundid: overrideFundId ?? fundId },
  })

export const validateExcelReturns = (fundId, excel) => {
  const form = new FormData()
  form.append('file[]', excel)
  return fundPerformance.post('ts/xlval', form, {
    params: { fundid: fundId },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const fetchCustomBenchmarkId = (fundId) =>
  api.get(`funds/custombenchmarkindex/${overrideFundId ?? fundId}`)

export const validateReturnJson = (fundId, returns) =>
  fundPerformance.post('ts/jsval', returns, {
    params: { fundid: fundId },
  })

export const getLatestReturnUpdate = (fundId) =>
  fundPerformance.get('ts/maxmonthrow', {
    params: { fundid: fundId },
  })

export const getReturnsExcel = (fundId) =>
  api.get(`/funds/${fundId}/download/returns`, { responseType: 'blob' })

export const getReturnsExcelTemplate = (fundId) =>
  api.get(`/funds/${fundId}/download/returns/template`, {
    responseType: 'blob',
  })

export const saveJsonReturns = (fundId, returns) =>
  fundPerformance.post('ts/jsloadsource', returns, {
    params: {
      sourceid: 1,
      fundid: fundId,
    },
  })

export const getIlliquidReturns = (fundId, type, limit) =>
  api.get(`funds/${fundId}/returns/${type}`, {
    params: {
      limit: limit ?? undefined,
    },
  })

export async function getBenchmarks() {
  const response = await api.get('/benchmarks')
  return response.data.result
}

// Due to a technical limitation in the fund performance API, it's not possible
// to insert `null` or `undefined` into an array of floats. Our workaround is
// to use the string literal "null," which we replace with undefined here
// before resolving the request.
const replaceNullStringWithUndefined = (response) =>
  JSON.parse(response, (key, value) => (value === 'null' ? undefined : value))

export const compareFunds = (fundIds, altId, allocId) =>
  fundPerformance.post(
    'peergroupanalysis/compchart',
    { fundid: fundIds },
    {
      params: {
        altid: altId,
        allocid: allocId,
      },
      transformResponse: replaceNullStringWithUndefined,
    }
  )

export const underwaterChart = (fundIds, altId, allocId) =>
  fundPerformance.post(
    'peergroupanalysis/uwchart',
    { fundid: fundIds },
    {
      params: { altid: altId, allocid: allocId },
      transformResponse: replaceNullStringWithUndefined,
    }
  )

export const benchmarkRelativeChart = (fundIds, altId) =>
  fundPerformance.post(
    'peergroupanalysis/bmrelchart',
    { fundid: fundIds },
    {
      params: { altid: altId },
      transformResponse: replaceNullStringWithUndefined,
    }
  )

export const rollingSharpeChart = (fundIds, timeSpan) =>
  fundPerformance.post(
    'peergroupanalysis/rollsharpe',
    { fundid: fundIds },
    {
      params: { n: timeSpan },
      transformResponse: replaceNullStringWithUndefined,
    }
  )

export const rollingReturnChart = (fundIds, timeSpan) =>
  fundPerformance.post(
    'peergroupanalysis/rollgreturn',
    { fundid: fundIds },
    {
      params: { n: timeSpan },
      transformResponse: replaceNullStringWithUndefined,
    }
  )

export const rollingGSDChart = (fundIds, timeSpan) =>
  fundPerformance.post(
    'peergroupanalysis/rollgsd',
    { fundid: fundIds },
    {
      params: { n: timeSpan },
      transformResponse: replaceNullStringWithUndefined,
    }
  )

export const rollingCorrelation = (fundIds, altId, timeSpan) =>
  fundPerformance.post(
    'peergroupanalysis/rollcorr',
    { fundid: fundIds },
    {
      params: {
        n: timeSpan,
        altid: altId,
      },
      transformResponse: replaceNullStringWithUndefined,
    }
  )

export const rollingWeightedCorrelation = (fundIds, altId, timeSpan) =>
  fundPerformance.post(
    'peergroupanalysis/rollwcorr',
    { fundid: fundIds },
    {
      params: {
        n: timeSpan,
        altid: altId,
      },
      transformResponse: replaceNullStringWithUndefined,
    }
  )
