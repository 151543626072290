import { getTabs as getCompanyTabs } from '~/components/CompanyProfile/CompanyProfileBody'
import Profile from '~/components/Profile'
import ResearchCampaignOverview from './Tabs/ResearchCampaignOverview'

export default function ResearchCampaignProfileBody({
  researchCampaign,
  company,
}) {
  const tabs = [
    {
      id: 'overview',
      label: 'overview',
      content: <ResearchCampaignOverview researchCampaign={researchCampaign} />,
    },
  ]
  if (company) {
    tabs.push(...getCompanyTabs(company))
  }
  return <Profile.Body tabs={tabs} />
}
