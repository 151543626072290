import { Component } from 'react'
import PropTypes from 'prop-types'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Empty, Tabs } from 'antd'
import { Axis, Chart, Geom, Legend, Tooltip } from 'bizcharts'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import numeral from 'numeral'
import { withRouter } from 'react-router-dom'
import { getPortfolioReturns } from '~/actions/portfolio'
import PortfolioFundsTable from '~/components/CCPortfolioFundsTable/CCPortfolioFundsTable'
import CreatePortfolio from '~/components/CCPortofolioModal'
import CenteredPrimaryRadioButtons from '~/components/CenteredPrimaryRadioButtons'
import Loading from '~/components/Loading'
import PortfolioFundDetailsHeader from '~/components/PortfolioFundDetailsHeader/PortfolioFundDetailsHeader'

const { TabPane } = Tabs

const BUTTONS = [
  {
    value: '1',
    text: 'funds',
  },
  {
    value: '2',
    text: 'returns',
  },
]

class PortfolioContainer extends Component {
  state = {
    activeTab: '1',
    createPortfolioModal: false,
    newPortofolioName: '',
    returns: [],
    chartsLoading: true,
  }
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      goBack: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.object.isRequired,
    }).isRequired,
  }
  radioHandler = (e) => {
    this.setState({
      activeTab: e.target.value,
    })
  }

  componentDidMount() {
    getPortfolioReturns(this.props.match.params.id)
      .then((response) => {
        this.setState({ returns: response.data.result })
      })
      .finally(() => {
        this.setState({ chartsLoading: false })
      })
  }

  handleOk = () => {
    console.log('portfolio', this.state.newPortofolioName, 'created')
  }

  handleCancel = () => {
    this.setState((state) => ({
      createPortfolioModal: !state.createPortfolioModal,
    }))
  }

  handleChange = (e) => {
    this.setState({
      newPortofolioName: e.target.value,
    })
  }

  render() {
    const cols = {
      date: {
        range: [0, 1],
      },
    }

    const portfolioId = this.props.match.params.id
    const { returns, chartsLoading } = this.state

    return (
      <>
        <PortfolioFundDetailsHeader
          fundData={{ ...this.props.location.state }}
          backClick={this.props.history.goBack}
        />
        <CenteredPrimaryRadioButtons
          selectedKey={this.state.activeTab}
          buttons={BUTTONS}
          OnClickFunction={this.radioHandler}
        />
        <Tabs
          animated={false}
          tabBarStyle={{ display: 'none' }}
          activeKey={this.state.activeTab}
        >
          <TabPane tab="Funds" key="1">
            <PortfolioFundsTable portfolioId={portfolioId} />
            <Button
              type="primary"
              className="company-colleagues-add"
              //onClick={this.handleCancel}
            >
              <FontAwesomeIcon
                className="button-icon"
                icon={faPlus}
                size="1x"
              />
              Add Investment
            </Button>
          </TabPane>
          <TabPane tab="Returns" key="2">
            {isEmpty(returns) ? (
              <Loading spinning={chartsLoading}>
                <Empty />
              </Loading>
            ) : (
              <div className="cc-container">
                <Chart
                  height={483}
                  data={returns}
                  padding={{ top: 20, right: 30, bottom: 100, left: 30 }}
                  scale={cols}
                  forceFit
                  onTooltipChange={(ev) => {
                    const { items } = ev
                    items.forEach((i) => {
                      i.title = moment.utc(i.title).local().format('M/YYYY')
                      i.value = numeral(i.value).format('$ 0,0.00')
                    })
                  }}
                >
                  <Legend />
                  <Axis
                    name="date"
                    label={{
                      formatter(text) {
                        return moment.utc(text).local().format('M/YYYY')
                      },
                    }}
                    line={{
                      fill: '#92949C',
                      lineWidth: 1,
                    }}
                  />
                  <Axis name="currentValue" label={null} />
                  <Tooltip
                    containerTpl={
                      '<div class="g2-tooltip"><div class="g2-tooltip-title cc-heading6 cc-black-text" style="margin-bottom: 4px;"></div>' +
                      '<ul class="g2-tooltip-list"></ul>' +
                      '</div>'
                    }
                    crosshairs={{
                      type: 'y',
                    }}
                  />
                  <Geom
                    type="line"
                    position="date*currentValue"
                    size={2}
                    color="fundName"
                  />
                  <Geom
                    type="point"
                    position="date*currentValue"
                    size={4}
                    shape="circle"
                    color="fundName"
                  />
                </Chart>
              </div>
            )}
          </TabPane>
        </Tabs>
        <CreatePortfolio
          visible={this.state.createPortfolioModal}
          handleCancel={this.handleCancel}
          handleOk={this.handleOk}
          onChange={(val) => this.handleChange(val)}
        />
      </>
    )
  }
}

export default withRouter(PortfolioContainer)
