import PropTypes from 'prop-types'
import { faEmptySet, faUsersSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './EmptyChannelList.less'

const EmptyChannelList = ({ type }) => (
  <div className="empty-messages-main-container">
    <div className="empty-messages-icon">
      {type === 'participants' && <FontAwesomeIcon icon={faUsersSlash} />}
      {type === 'information' && <FontAwesomeIcon icon={faEmptySet} />}
    </div>
    <div className="empty-messages-text">
      {type === 'participants' && 'No users have joined the room'}
      {type === 'information' && 'No materials for this meeting'}
    </div>
  </div>
)

EmptyChannelList.propTypes = {
  type: PropTypes.string,
}

export default EmptyChannelList
