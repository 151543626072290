import PropTypes from 'prop-types'
import moment from 'moment'
import './SummitDateLabel.less'

const SummitDateLabel = ({
  startDate,
  endDate,
  showYear = true,
  isFancy = true,
}) => {
  const start = moment.utc(startDate).local()
  const end = moment.utc(endDate).local()
  const classNames = ['cc-summit-date-label']

  const dateSpan = (
    <>
      <span className="cc-summit-date-label-days">{start.format('D')}</span>
      <span className="cc-summit-date-label-month">
        {start.format('MMM') === end.format('MMM') ? '' : start.format('MMM')}
      </span>
      - <span className="cc-summit-date-label-days">{end.format('D')}</span>
      <span className="cc-summit-date-label-month">
        {start.format('MMM') === end.format('MMM') ? '' : end.format('MMM')}
      </span>
      <span className="cc-summit-date-label-month">
        {start.format('MMM') === end.format('MMM') ? start.format('MMM') : ''}
        {showYear && start.format(', YYYY')}
      </span>
    </>
  )

  if (isFancy) {
    classNames.push('cc-summit-date-label-fancy')
  }

  return (
    <span className={classNames.join(' ')}>
      {dateSpan}

      {/* <span className="cc-summit-date-label-days">
        {start.format('D')}-{end.format('D')}
      </span>
      <span className="cc-summit-date-label-month">
        {start.format('MMM')}
        {showYear && start.format(', YYYY')}
      </span> */}
    </span>
  )
}

SummitDateLabel.defaultProps = {
  showYear: true,
  isFancy: true,
}

SummitDateLabel.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  showYear: PropTypes.bool,
  isFancy: PropTypes.bool,
}

export default SummitDateLabel
