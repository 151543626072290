import { api } from './services'

export const getSubscriptionsBySubCategory = (apiVersion = 2) =>
  api
    .get('/notifications/subscriptions', { apiVersion })
    .then((res) => res.data.result)

export const saveSubscriptions = (subscriptions) =>
  api.patch('notifications/subscriptions', { subscriptions })

export const toggleAllSubscriptions = (subscribeToAll) =>
  api.post('notifications/subscribe/toggleall', { subscribeToAll })
