import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faLock } from '@fortawesome/pro-regular-svg-icons'
import { faArrowLeft, faPowerOff } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Button, Steps, message } from 'antd'
import isNil from 'lodash/isNil'
import { useDispatch, useSelector, useStore } from 'react-redux'
import {
  logout,
  updateIsCFNInterestedInDeals,
  updateIsCFNNotInterestedInDeals,
  updateOnboarded,
} from '~/actions/auth'
import {
  postAllocatorContact,
  postAllocatorStatus,
  postCFNCompany,
  postCFNCompanyVisibility,
  postCFNContact,
  postCFNContactVisibility,
  postContact,
  postContactBasic,
} from '~/actions/onboarding'
import loginLogo from '~/assets/new-logo.svg'
import './Onboarding.less'

const { Step } = Steps

const LayoutWizard = ({
  component,
  title,
  companies,
  onNext,
  onBack,
  backVisible,
  onCompanyChanged,
  step,
  onChange,
  currentQuestions,
  onInvestorStatusChange,
  companyId,
  setStep,
  clearQuestions,
  setCompanies,
  showAlert = false,
}) => {
  const Component = component
  const [nextEnabled, setNextEnabled] = useState(false)
  const [posting, setPosting] = useState(false)
  const store = useStore()
  const userRole = useSelector((state) => state.auth.role)

  const dispatch = useDispatch()

  const logoutUser = useCallback(() => dispatch(logout()), [dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  const getLayoutHeader = useCallback(
    () => (
      <div className="cc-layout-default-header">
        <div className="cc-layout-default-context">
          <div
            className="cc-login-logo-onboard"
            style={{ backgroundImage: `url(${loginLogo})` }}
          />
        </div>
        {userRole === 'CFN' && (
          <div>
            <div className="cc-heading1 cc-layout-default-welcome">
              Context365 Family Network
            </div>
          </div>
        )}
        <div
          className={
            step.steps.length < 6
              ? 'cc-layout-wizard-steps'
              : 'cc-layout-wizard-steps-big'
          }
        >
          <Steps current={step.innerStep - 1}>
            {step.steps.map((x) => (
              <Step title={x} key={x} />
            ))}
          </Steps>
        </div>
      </div>
    ),
    [step.innerStep, step.steps, userRole]
  )

  const redirect = useCallback(() => {
    setPosting(false)
    store.dispatch(updateOnboarded())
    setCompanies(null)
    setStep(1)
  }, [setCompanies, setStep, store])

  const postContactData = useCallback(() => {
    const contactQuestions = currentQuestions.filter((x) => x.step > 3)
    setPosting(true)

    const flattened = [].concat(...contactQuestions.map((x) => x.questions))
    switch (step.actionType) {
      case 2:
        //post contact settings (ready)
        postContactBasic(flattened)
          .then(() => {
            if (step.shouldPost) {
              redirect()
            } else {
              clearQuestions()
              onNext()
            }
          })
          .catch(() =>
            message.error('There was an error while attempting to save')
          )
          .finally(() => setPosting(false))
        break
      case 3:
        //post contact location (ready)
        postContact(flattened)
          .then(() => {
            if (step.shouldPost) {
              redirect()
            } else {
              clearQuestions()
              onNext()
            }
          })
          .catch(() =>
            message.error('There was an error while attempting to save')
          )
          .finally(() => setPosting(false))
        break
      case 4:
        postAllocatorStatus(companyId, flattened)
          .then(() => {
            clearQuestions()
            onNext()
          })
          .catch(() =>
            message.error('There was an error while attempting to save')
          )
          .finally(() => setPosting(false))
        break
      case 5:
        postAllocatorContact(companyId, flattened)
          .then(() => {
            clearQuestions()
            onNext()
          })
          .catch(() =>
            message.error('There was an error while attempting to save')
          )
          .finally(() => setPosting(false))
        break
      case 6:
        clearQuestions()
        redirect()
        break
      case 7: {
        const stepQuestions = currentQuestions.filter(
          (x) => x.step === step.stepNumber
        )
        const questionsToPost = [].concat(
          ...stepQuestions.map((x) => x.questions)
        )

        postCFNCompany(step.innerStep, questionsToPost, companyId)
          .then(() => {
            onNext()
          })
          .catch(() =>
            message.error('There was an error while attempting to save')
          )
          .finally(() => setPosting(false))
        break
      }
      case 8: {
        const stepQuestions = currentQuestions.filter(
          (x) => x.step === step.stepNumber
        )
        const questionsToPost = [].concat(
          ...stepQuestions.map((x) => x.questions)
        )
        postCFNContact(step.innerStep, questionsToPost, companyId)
          .then(() => {
            onNext()
          })
          .catch(() =>
            message.error('There was an error while attempting to save')
          )
          .finally(() => setPosting(false))
        break
      }
      case 9: {
        const stepQuestions = currentQuestions.filter(
          (x) => x.step === step.stepNumber
        )
        const questionsToPost = [].concat(
          ...stepQuestions.map((x) => x.questions)
        )
        postCFNCompanyVisibility(questionsToPost, companyId)
          .then(() => {
            clearQuestions()
            onNext()
          })
          .catch(() =>
            message.error('There was an error while attempting to save')
          )
          .finally(() => setPosting(false))
        break
      }
      case 10: {
        const stepQuestions = currentQuestions.filter(
          (x) => x.step === step.stepNumber
        )
        const questionsToPost = [].concat(
          ...stepQuestions.map((x) => x.questions)
        )
        postCFNContactVisibility(questionsToPost, companyId)
          .then(() => {
            clearQuestions()
            redirect()
          })
          .catch(() =>
            message.error('There was an error while attempting to save')
          )
          .finally(() => setPosting(false))
        break
      }
      case 11: {
        const stepQuestions = currentQuestions.filter(
          (x) => x.step === step.stepNumber
        )
        const questionsToPost = [].concat(
          ...stepQuestions.map((x) => x.questions)
        )
        postCFNContact(step.innerStep, questionsToPost, companyId)
          .then(() => {
            const isInterestedInDeals = questionsToPost.find(
              (x) => x.shortName === 'IsInterestedInDeals'
            )?.answer
            if (isInterestedInDeals || isNil(isInterestedInDeals))
              store.dispatch(updateIsCFNInterestedInDeals())
            else store.dispatch(updateIsCFNNotInterestedInDeals())
            onNext()
          })
          .catch(() =>
            message.error('There was an error while attempting to save')
          )
          .finally(() => setPosting(false))
        break
      }
    }
  }, [
    clearQuestions,
    companyId,
    currentQuestions,
    onNext,
    redirect,
    step.actionType,
    step.innerStep,
    step.shouldPost,
    step.stepNumber,
  ])

  const getLayoutFooter = useCallback(
    () => (
      <div className="cc-layout-default-footer">
        {step.canGoBack === false ? null : (
          <Button
            onClick={onBack}
            hidden={!backVisible}
            className="cc-layout-footer-back-button"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            &nbsp;&nbsp;Back
          </Button>
        )}

        <Button
          onClick={postContactData}
          disabled={!nextEnabled}
          style={{ width: '364px', height: '48px' }}
          type="primary"
          loading={posting}
        >
          {step.shouldPost === true ? 'Finish' : 'Next'}
        </Button>
      </div>
    ),
    [nextEnabled, postContactData, posting, step.shouldPost]
  )

  const getTitle = useCallback(() => {
    if (title)
      return <div className="cc-heading4 cc-layout-default-title">{title}</div>
  }, [title])

  return (
    <div className="cc-layout-default">
      {step.canGoBack === false ? null : (
        <Button
          onClick={onBack}
          hidden={!backVisible}
          className="cc-layout-default-back"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          &nbsp;&nbsp;Back
        </Button>
      )}
      <Button
        onClick={() => {
          logoutUser()
        }}
        className="cc-layout-default-logout"
      >
        <FontAwesomeIcon icon={faPowerOff} />
        &nbsp;&nbsp;Logout
      </Button>
      {getLayoutHeader()}
      {getTitle()}
      {showAlert && (
        <Alert
          className="cc-layout-default-alert"
          message={<strong>We take your privacy seriously</strong>}
          description="For the best experience, we recommend completing all of the fields. By completing the fields, you can best leverage our investor connectivity features and be matched with relevant deals, events, and investors. On the last step, you will be able to choose which info you want kept private. These settings can be changed anytime.
        "
          type="success"
          showIcon
          closable
          icon={<FontAwesomeIcon icon={faLock} />}
        />
      )}
      <div
        className="cc-layout-default-form-space"
        style={{
          width: step.customWidth ? step.customWidth : '364px',
        }}
      >
        {isNil(component) ? null : (
          <Component
            step={step}
            onChange={onChange}
            stepNumber={step.stepNumber}
            companies={companies}
            enableNext={(value) => setNextEnabled(value)}
            onCompanyChanged={onCompanyChanged}
            maxWidth={step.customWidth ? step.customWidth : '364px'}
            currentQuestions={currentQuestions}
            onInvestorStatusChange={onInvestorStatusChange}
            companyId={companyId}
            renderAction={step.renderAction}
          />
        )}
        {getLayoutFooter()}
      </div>
    </div>
  )
}

LayoutWizard.propTypes = {
  component: PropTypes.elementType.isRequired,
  title: PropTypes.string,
  companies: PropTypes.array.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  backVisible: PropTypes.bool.isRequired,
  onCompanyChanged: PropTypes.func.isRequired,
  step: PropTypes.shape({
    stepNumber: PropTypes.number.isRequired,
    innerStep: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired,
    shouldPost: PropTypes.bool.isRequired,
    actionType: PropTypes.number.isRequired,
    canGoBack: PropTypes.bool.isRequired,
    customWidth: PropTypes.bool,
    renderAction: PropTypes.func,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  currentQuestions: PropTypes.array.isRequired,
  onInvestorStatusChange: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  clearQuestions: PropTypes.func.isRequired,
  setCompanies: PropTypes.func.isRequired,
  showAlert: PropTypes.bool,
}

export default LayoutWizard
