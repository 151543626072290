import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Exposure } from '@context365/icons'
import { isNil } from 'lodash'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import find from 'lodash/find'
import join from 'lodash/join'
import take from 'lodash/take'
import { useTracking } from 'react-tracking'
import { useDiscoverLists } from '~/hooks/discoverLists'
import { DiscoverBadges, DiscoverResultCard } from '../Discover'
import { DiscoverCardStats, statFormat } from '../DiscoverCardStats'
import './DiscoverAllocatorResult.less'

const getSelectedLists = (discoverLists, companyId) =>
  filter(discoverLists, (list) =>
    find(list.discoverListLinks, ['toCompanyId', companyId])
  )

const getDetailItems = (allocator) => {
  const items = [
    {
      title: 'Total Assets',
      data: statFormat.currency(allocator.totalAssets),
      isVisible: !isNil(allocator.totalAssets),
    },
    {
      title: 'Alternatives',
      data: statFormat.currency(allocator.totalAlternativeAssets),
      isVisible: !isNil(allocator.totalAlternativeAssets),
    },
    {
      title: 'Participants',
      data: allocator.participantsCount,
      isVisible: !isNil(allocator.participantsCount),
    },
    {
      title: 'YTD Returns',
      data: statFormat.percent(allocator.ytdReturnPercentage),
      isVisible: !isNil(allocator.ytdReturnPercentage),
    },
    {
      title: 'Funded Status',
      data: statFormat.percent(allocator.fundedStatusPercentage),
      isVisible: !isNil(allocator.fundedStatusPercentage),
    },
  ]

  return take(filter(items, 'isVisible'), 8)
}

const getSubText = (allocator) => {
  const locationString = join(
    compact([allocator.city, allocator.state, allocator.country]),
    ', '
  )

  let subText = ''

  if (!isNil(allocator.primaryInvestorCategory)) {
    subText = isNil(locationString)
      ? allocator.primaryInvestorCategory
      : `${allocator.primaryInvestorCategory} - `
  }

  return subText + locationString
}

const DiscoverAllocatorResult = ({ allocator, onAddAllocatorToList }) => {
  const { Track } = useTracking({
    component: 'DiscoverAllocatorResult',
  })

  const { discoverLists } = useDiscoverLists()

  if (!allocator) {
    return null
  }

  return (
    <div className="discover-allocator" id="fixed">
      <Track>
        <DiscoverResultCard
          href={`/allocator/${allocator.companyId}`}
          header={
            <div className="flex justify-between pb-3">
              <div>
                <DiscoverBadges
                  badges={getSelectedLists(discoverLists, allocator.companyId)}
                />
                <div className="text-primary-100 type-header-sm">
                  {allocator.companyName}
                </div>
                <div className="text-primary-100 type-body-regular-sm italic">
                  {getSubText(allocator)}
                </div>
              </div>
            </div>
          }
          body={<DiscoverCardStats items={getDetailItems(allocator)} />}
          footer={
            <div className="flex flex-row justify-end space-x-2">
              <Button
                className="color-secondary-90"
                status="secondary"
                iconLeft={<Exposure />}
                onClick={() => onAddAllocatorToList([allocator])}
              >
                Lists
              </Button>
              {allocator.mandatesCount > 0 && (
                <Button variant="filled" status="primary">
                  {`${allocator.mandatesCount} Mandates`}
                </Button>
              )}
            </div>
          }
        />
      </Track>
    </div>
  )
}

DiscoverAllocatorResult.propTypes = {
  allocator: PropTypes.object.isRequired,
  onAddAllocatorToList: PropTypes.func.isRequired,
}

export default DiscoverAllocatorResult
