import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import Loading from '~/components/Loading'
import Profile from '~/components/Profile'
import { getContactProfileModal, hideModal } from '~/store/modals'
import ContactProfileBody from './ContactProfileBody'
import ContactProfileHeader from './ContactProfileHeader'

export default function ContactProfileModal() {
  const { Track } = useTracking({ page: 'ContactProfileModal' })
  const dispatch = useDispatch()
  const { visible, contactId } = useSelector(getContactProfileModal)
  const { data: contact, status } = useQuery(
    ['contactProfile', contactId],
    () => api.contacts.getContactProfile({ contactId }),
    {
      enabled: visible,
    }
  )

  const isLoading = status === 'loading' || status === 'idle'

  return (
    <Track>
      <Profile.Modal
        visible={visible}
        onClose={() => dispatch(hideModal())}
        header={
          isLoading ? null : (
            <ContactProfileHeader
              contact={contact}
              profileUrl={`/profile/contact/${contact.contactId}`}
            />
          )
        }
        body={
          isLoading ? (
            <div className="flex justify-center py-20">
              <Loading />
            </div>
          ) : (
            <ContactProfileBody contact={contact} />
          )
        }
      />
    </Track>
  )
}
