import isNil from 'lodash/isNil'

const getQuestionAnswer = (question, answers) => {
  const selectedAnswers = answers.find(
    (answer) => answer.questionId === question.questionId
  )

  return isNil(selectedAnswers)
    ? {
        responseText: '',
        questionAnswerIds: [],
      }
    : selectedAnswers
}

export default getQuestionAnswer
