import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Input, Select, TextArea } from '@context365/forms'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { getAllCountries } from '~/actions/geography'

const GeneralPartnerField = ({
  value,
  errors = {},
  onChange,
  addItem,
  showAdd,
  showRemove,
  removeItem,
}) => {
  const [locations, setLocations] = useState([])

  const handleChange = (field, val) => {
    onChange({ ...value, [field]: val })
  }

  const handleAdd = (isGP) => {
    addItem({
      name: '',
      countryId: null,
      stateProvince: '',
      city: '',
      bio: '',
      isGeneralPartner: isGP,
    })
  }

  useEffect(() => {
    getAllCountries().then((res) => {
      const countries = map(res.data.result, (x) => ({
        label: x.name,
        value: x.countryId,
      }))
      setLocations(countries)
    })
  }, [])

  return (
    <>
      <div className="Question-row">
        <span
          className="Question-col Nested-field"
          style={{ paddingRight: '10px' }}
        >
          <Input
            required
            label={value.isGeneralPartner ? 'General Partner' : 'Contact'}
            errorMessage={errors.name}
            value={value.name}
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </span>
        <span
          className="Question-col Nested-field"
          style={{ paddingLeft: '10px' }}
        >
          {isEmpty(locations) ? null : (
            <Select.AutoComplete
              required
              label={
                value.isGeneralPartner
                  ? 'General Partner Location'
                  : 'Contact Location'
              }
              options={locations}
              value={value.countryId}
              onChange={(val) => handleChange('countryId', val)}
            >
              {({ inputValue }) =>
                locations
                  .filter((s) =>
                    s.label.toLowerCase().includes(inputValue.toLowerCase())
                  )
                  .map((option) => (
                    <Select.Option
                      key={option.value}
                      className="py-2"
                      value={option.value}
                    >
                      {option.label}
                    </Select.Option>
                  ))
              }
            </Select.AutoComplete>
          )}
        </span>
      </div>
      <div className="Question-row">
        <span className="Question-col" style={{ paddingRight: '10px' }}>
          <Input
            label={
              value.isGeneralPartner
                ? 'General Partner State/Province'
                : 'Contact State/Province'
            }
            errorMessage={errors.stateProvince}
            value={value.stateProvince}
            onChange={(e) => handleChange('stateProvince', e.target.value)}
          />
        </span>
        <span className="Question-col" style={{ paddingLeft: '10px' }}>
          <Input
            label={
              value.isGeneralPartner ? 'General Partner City' : 'Contact City'
            }
            errorMessage={errors.city}
            value={value.city}
            onChange={(e) => handleChange('city', e.target.value)}
          />
        </span>
      </div>
      <TextArea
        required
        rows={4}
        label={value.biography ? 'General Partner Bio' : 'Contact Bio'}
        errorMessage={errors.biography}
        helpMessage={`Character Count: ${
          value.biography?.length ?? 0
        }. Please input at least 280 characters of text. This will help people find your fund by keyword search.`}
        value={value.biography}
        onChange={(e) => handleChange('biography', e.target.value)}
      />
      {showRemove && (
        <div>
          <Button variant="link" status="error" onClick={() => removeItem()}>
            Remove
          </Button>
        </div>
      )}
      {showAdd && (
        <div>
          <Button variant="link" onClick={() => handleAdd(true)}>
            + Add another GP
          </Button>
        </div>
      )}
      {showAdd && (
        <div>
          <Button variant="link" onClick={() => handleAdd(false)}>
            + Add another non-GP contact
          </Button>
        </div>
      )}
    </>
  )
}

GeneralPartnerField.propTypes = {
  value: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  addItem: PropTypes.func,
  showAdd: PropTypes.bool,
  showRemove: PropTypes.bool,
  removeItem: PropTypes.func,
}

export default GeneralPartnerField
