import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { rescheduleOnlineMeeting } from '~/actions/contextMeetings'
import Loading from '../Loading'
import SelectOnlineMeetingDateTime from '../SelectOnlineMeetingDateTime'
import './RescheduleEventMeeting.less'

const RescheduleEventMeeting = ({
  visible,
  closeModal,
  meetingId,
  eventId,
  onRescheduleMeeting,
  participants,
  toParticipants,
  meetingDateTime,
  meetingDuration,
  timezone,
  isVirtualSummit = false,
}) => {
  const [reschedulingInProgress, setReschedulingInProgress] = useState(false)
  const [selectedDateTime, setSelectedDateTime] = useState({})
  const [duration, setDuration] = useState()
  const [selectedHour, setSelectedHour] = useState({})

  const onDurationChanged = (value) => {
    setDuration(value)
  }

  useEffect(() => {
    setDuration(meetingDuration || 30)
  }, [meetingDuration])

  const onModalClosed = useCallback(() => {
    closeModal()
    setSelectedDateTime({})
    setDuration(30)
    setSelectedHour({})
  }, [closeModal])

  const onTimeChanged = (changed, newDate) => {
    if (changed === 'date') {
      setSelectedHour({})
    }
    if (changed === 'hour') {
      setSelectedHour(newDate)
    }
    if (!isNil(newDate)) {
      setSelectedDateTime({
        startDateTime: newDate.startTime,
        endDateTime: newDate.endTime,
      })
    }
  }

  const handleModalOk = useCallback(() => {
    setReschedulingInProgress(true)
    rescheduleOnlineMeeting(meetingId, selectedDateTime.startDateTime, duration)
      .then(() => {
        message.success('The meeting has been rescheduled.')
        onModalClosed()
        onRescheduleMeeting(selectedDateTime.startDateTime)
      })
      .finally(() => {
        setReschedulingInProgress(false)
      })
  }, [
    onModalClosed,
    duration,
    onRescheduleMeeting,
    meetingId,
    selectedDateTime.startDateTime,
  ])

  return (
    <Modal
      destroyOnClose
      className="cc-modal-reschedule"
      title="Reschedule the Meeting"
      visible={visible}
      okText="Reschedule the Meeting"
      onOk={handleModalOk}
      onCancel={onModalClosed}
      okButtonProps={{
        loading: reschedulingInProgress,
        disabled: isEmpty(selectedDateTime) || isEmpty(selectedHour),
        size: 'large',
        style: {
          marginLeft: '24px',
          backgroundColor: '#F19F00',
          borderStyle: 'none',
        },
      }}
      cancelButtonProps={{ disabled: false, size: 'large' }}
      width="758px"
      bodyStyle={{ padding: '30px' }}
    >
      <Loading spinning={reschedulingInProgress}>
        <SelectOnlineMeetingDateTime
          onTimeChanged={onTimeChanged}
          onDurationChanged={onDurationChanged}
          toParticipants={toParticipants}
          participants={participants}
          dateTime={meetingDateTime}
          meetingDuration={meetingDuration}
          eventId={eventId}
          defaultTimezone={timezone}
          physicalSummit={!isVirtualSummit && !isNil(eventId)}
          meetingId={meetingId}
        />
      </Loading>
    </Modal>
  )
}

RescheduleEventMeeting.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onRescheduleMeeting: PropTypes.func.isRequired,
  meetingId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  participants: PropTypes.array,
  toParticipants: PropTypes.array,
  meetingDateTime: PropTypes.string,
  meetingDuration: PropTypes.number,
  timezone: PropTypes.string,
  isVirtualSummit: PropTypes.bool,
}

export default RescheduleEventMeeting
