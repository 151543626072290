import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { ChevronLeft } from '@context365/icons'
import Lists from './Lists'
import ListSharing from './ListSharing'

export default function ManageLists({
  lists,
  onDismiss,
  onDeleteList,
  onRevokeSharingAccess,
  onShareList,
}) {
  const [listIdToShare, setListIdToShare] = React.useState(null)
  const listToShare = lists.find(
    (list) => list.discoverListId === listIdToShare
  )

  return (
    <>
      <div className="type-body-semibold-md py-5 px-6 border-b">
        {listToShare == null ? 'Manage Lists' : 'List Sharing'}
      </div>
      <div className="py-4 px-6">
        {listToShare == null ? (
          <Lists
            lists={lists}
            onDeleteList={onDeleteList}
            onShareList={(listId) => setListIdToShare(listId)}
          />
        ) : (
          <ListSharing
            list={listToShare}
            onRevokeSharingAccess={onRevokeSharingAccess}
            onShareList={onShareList}
          />
        )}
      </div>
      <div className="flex justify-between p-4 shadow-0">
        <span>
          {listToShare != null && (
            <Button
              status="secondary"
              iconLeft={<ChevronLeft />}
              onClick={() => setListIdToShare(null)}
            >
              Return to Lists
            </Button>
          )}
        </span>
        <Button variant="filled" onClick={onDismiss}>
          Done
        </Button>
      </div>
    </>
  )
}

ManageLists.propTypes = {
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      discoverListId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      usersWithAccess: PropTypes.arrayOf(
        PropTypes.shape({
          companyContactId: PropTypes.number.isRequired,
          isCreator: PropTypes.bool.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  onDismiss: PropTypes.func.isRequired,
  onDeleteList: PropTypes.func.isRequired,
  onRevokeSharingAccess: PropTypes.func.isRequired,
  onShareList: PropTypes.func.isRequired,
}
