import * as React from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'antd'
import arrify from 'arrify'
import groupBy from 'lodash/groupBy'
import initial from 'lodash/initial'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import last from 'lodash/last'
import map from 'lodash/map'
import { useTracking } from 'react-tracking'
import useLiveRef from '~/hooks/useLiveRef'
import { generateRandomIdentifier } from '~/utils/helpers'
import CollapseToggle from '../DiscoverFilters/CollapseToggle'
import FilterGroup from '../DiscoverFilters/FilterGroup'
import ToggleFilter from '../DiscoverFilters/ToggleFilter'
import usePresetValues from '../DiscoverFilters/usePresetValues'
import { useDiscover } from './context'

const serializeArray = (arr) => (isEmpty(arr) ? '[]' : arr.join(','))
const serializeValue = (value) =>
  Array.isArray(value) ? serializeArray(value) : value

const DiscoverFilters = ({
  filters,
  getFilterGroups: getFilterGroupsExternal = extractQuestionGroups(),
  renderFilterControl,
  canEditInvestorPreferences = false,
  hideToggleMatches = false,
  ...props
}) => {
  const { trackEvent } = useTracking({ component: 'DiscoverFilters' })
  const trackFilterChange = React.useCallback(
    ({ value, ...attrs }) => {
      trackEvent({
        eventName: 'change',
        ...attrs,
        value: serializeValue(value),
      })
    },
    [trackEvent]
  )

  const { onChangeFilters, activeFilters, showOnlyMatched, onToggleMatched } =
    useDiscover(props)

  const getFilterGroups = useLiveRef(getFilterGroupsExternal)

  const [highlightedGroups, otherGroups] = React.useMemo(() => {
    const questionGroups = groupBy(filters, (f) => {
      if (isNil(f.questionGroup) || f.questionGroup === '') {
        return f.field + generateRandomIdentifier()
      } else {
        return f.questionGroup
      }
    })
    const filterGroups = getFilterGroups.current(questionGroups)
    return filterGroups.length < 2
      ? [[], last(filterGroups)]
      : [initial(filterGroups), last(filterGroups)]
  }, [filters, getFilterGroups])

  const onFilterChange = React.useCallback(
    ({ value, id, group, label, type } = {}) => {
      trackFilterChange({ group, element: id, value })
      onChangeFilters(arrify(value), id, label, type)
    },
    [onChangeFilters, trackFilterChange]
  )

  const getPresetValue = usePresetValues(filters, activeFilters)

  const getFilterComponent = (item, showHeader) =>
    renderFilterControl({
      item,
      canEditInvestorPreferences,
      header: showHeader ? (
        <div className="DiscoverFilters-text">{item.name}</div>
      ) : undefined,
      value: getPresetValue(item.field),
      onChange: onFilterChange,
    })

  function toggleMatchedOnly(checked, ...args) {
    trackFilterChange({ element: 'Show only matches', value: checked })
    onToggleMatched(checked, ...args)
  }

  if (filters?.length < 1) {
    return <div />
  }

  return (
    <div className="DiscoverFilters">
      {map(
        highlightedGroups,
        (group) =>
          !isEmpty(group) && (
            <Collapse
              key={group[0].order ?? group[0].field}
              bordered={false}
              expandIcon={({ isActive }) => (
                <CollapseToggle isActive={isActive} />
              )}
              expandIconPosition="right"
              className="DiscoverFilters-CollapseHeader"
              defaultActiveKey={[group[0].order]}
            >
              <FilterGroup
                key={group[0].order}
                filters={group}
                getFilterComponent={getFilterComponent}
              />
            </Collapse>
          )
      )}

      {!hideToggleMatches && (
        <ToggleFilter
          label="Show Only Matches"
          checked={showOnlyMatched}
          onChange={toggleMatchedOnly}
        />
      )}

      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => <CollapseToggle isActive={isActive} />}
        expandIconPosition="right"
        className="DiscoverFilters-CollapseHeader"
      >
        {map(otherGroups, (group) => (
          <FilterGroup
            key={group[0].order ?? group[0].field}
            filters={group}
            getFilterComponent={getFilterComponent}
          />
        ))}
      </Collapse>
    </div>
  )
}

DiscoverFilters.propTypes = {
  filters: PropTypes.array,
  getFilterGroups: PropTypes.func,
  renderFilterControl: PropTypes.func.isRequired,
  hideToggleMatches: PropTypes.bool,
  canEditInvestorPreferences: PropTypes.bool,
}

export default DiscoverFilters

export function extractQuestionGroups(...names) {
  return (groups) => {
    const result = []
    names.forEach((name) => {
      result.push(groups[name])
    })
    result.push(
      Object.entries(groups)
        .filter(([key]) => !names.includes(key))
        .map(([, value]) => value)
    )
    return result
  }
}
