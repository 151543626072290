import PropTypes from 'prop-types'
import cx from 'classnames'

const LabelField = ({ title, className, isRequired = false }) => (
  <label className={cx('block', className)}>
    {title}
    {isRequired && (
      <span className="text-red-100 type-body-semibold-sm">*</span>
    )}
  </label>
)

LabelField.propTypes = {
  title: PropTypes.string,
  isRequired: PropTypes.bool,
  className: PropTypes.bool,
}

export default LabelField
