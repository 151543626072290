import * as signalR from '@microsoft/signalr'

export default class SignalR {
  constructor(url, newOptions) {
    this.connectionId = null
    this.isSubscribed = false
    const protocol = new signalR.JsonHubProtocol()
    const options = Object.assign(this.createBaseOption(), newOptions)
    this.notifyHubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url, options)
      .withHubProtocol(protocol)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (retryContext.elapsedMilliseconds < 60000)
            return Math.random() * 10000
          else return null
        },
      })
      .configureLogging(signalR.LogLevel.Information)
      .build()

    this.notifyHubConnection.stopDuringStartError = true

    this.notifyHubConnection.onreconnecting((error) => {
      console.assert(
        this.notifyHubConnection.state ===
          signalR.HubConnectionState.Reconnecting,
        `Connection lost due to error "${error}". Reconnecting.`
      )
    })

    this.notifyHubConnection.onreconnected((connectionId) => {
      console.assert(
        this.notifyHubConnection.state === signalR.HubConnectionState.Connected,
        `Connection reestablished. Connected with connectionId "${connectionId}".`
      )
    })

    this.notifyHubConnection.onclose((error) => {
      console.assert(
        this.notifyHubConnection.state ===
          signalR.HubConnectionState.Disconnected,
        `Connection closed due to error "${error}". Trying to reconnect.`
      )
    })
  }

  createBaseOption() {
    const transport = signalR.HttpTransportType.WebSockets

    return {
      transport,
      logMessageContent: true,
      logger: signalR.LogLevel.Information,
    }
  }

  async subscribe(isWithCrendentials = false) {
    if (!this.connectionId) {
      if (
        this.notifyHubConnection.state === signalR.HubConnectionState.Connected
      )
        await this.notifyHubConnection.stop()
      this.isSubscribed = true
      return this.notifyHubConnection.start({
        withCredentials: isWithCrendentials,
      })
    }
  }

  async unsubscribe() {
    this.connectionId = null
    this.isSubscribed = false
    await this.notifyHubConnection.stop()
  }
}
