import PropTypes from 'prop-types'
import { Badge, Radio } from 'antd'
import './CenteredPrimaryRadioButtons.less'

const RadioGroup = Radio.Group
const RadioButton = Radio.Button

const CenteredPrimaryRadioButtons = (props) => (
  <div>
    <div className="cc-container-radio-group">
      <RadioGroup
        size="large"
        value={props.selectedKey}
        onChange={props.OnClickFunction}
      >
        {props.buttons.map(({ value, text, badgeCount }) => (
          <RadioButton
            key={value}
            className="cc-radio-button-wrapper"
            value={value}
          >
            {text ? text : value}
            {badgeCount > 0 && (
              <Badge className="cc-requests-count" count={badgeCount} />
            )}
          </RadioButton>
        ))}
      </RadioGroup>
    </div>
  </div>
)

CenteredPrimaryRadioButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string,
    })
  ).isRequired,
  selectedKey: PropTypes.string,
  OnClickFunction: PropTypes.func.isRequired,
}

export default CenteredPrimaryRadioButtons
