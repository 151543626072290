import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { faComment } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Empty, Row, Table, Tooltip, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import moment from 'moment'
import qs from 'qs'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchDeclinedRequests } from '~/actions/contextMeetings'
import { api as http } from '~/api/services'
import useSearchParams from '~/hooks/useSearchParams'
import {
  getFormatedMeetingTime,
  renderParticipantsAvatars,
} from '~/utils/meetingActions'
import EmptyEventTablesState from '~/views/summits/meetings/EmptyEventTablesState'
import ContactName from '../ContactName'
import Loading from '../Loading'
import MeetingsCardView from '../MeetingsCardView'
import ResponsiveCard, {
  DATE,
} from '../Responsive/ResponsiveCards/OnlineMeetingRecivedRequestResponsiveCard'
import SearchBox from '../SearchBox/SearchBox'
import TimeDisplay from '../TimeDisplay'
import './DeclinedRequestsTable.less'

const DeclinedRequestsTable = ({
  activeSummit = true,
  meetingType,
  allowMultipleCompaniesMeeting,
  meetingTypeId = 1,
  eventId,
  width,
  fromSummit,
  refreshTable,
}) => {
  const [displayTimezone, setDisplayTimezone] = useState(TimeDisplay.ORIGINAL)
  const [displayTimezoneLabel, setDisplayTimezoneLabel] =
    useState('Event Timezone')
  const [refresh, setRefresh] = useState(refreshTable)
  const [isLoading, setIsLoading] = useState(false)
  const [allDeclinedList, setAllDeclinedList] = useState([])
  const [hasNext, setHasNext] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()

  const updateQueryParams = React.useCallback(
    (updatedFields) => {
      setSearchParams({
        ...searchParams,
        ...updatedFields,
      })
    },
    [searchParams, setSearchParams]
  )

  const resetPage = React.useCallback(
    (updatedFields = {}) => {
      updateQueryParams({ ...updatedFields, page: 1 })
    },
    [updateQueryParams]
  )

  const { page, searchQuery, pageSize } = React.useMemo(() => {
    const { page = 1, search = '', size = 10 } = searchParams

    return {
      page: parseInt(page, 10),
      searchQuery: search,
      pageSize: parseInt(size, 10),
    }
  }, [searchParams])

  const loadPage = React.useCallback(
    (page, size) => {
      updateQueryParams({ page, size })
    },
    [updateQueryParams]
  )

  const searchByText = React.useCallback(
    (text) => {
      resetPage({ search: text })
    },
    [resetPage]
  )

  const declinedRequestsList = useSelector((state) => {
    if (meetingType.toLowerCase() === 'online')
      return state.meetings.onlineDeclinedMeetings
    else if (fromSummit) return state.meetings.declinedMeetingsCompanyEventId
    else if (meetingType.toLowerCase() === 'roadshow')
      return state.meetings.roadshowDeclinedMeetings
    else return state.meetings.declinedMeetings
  })

  const total = isNil(declinedRequestsList) ? 0 : declinedRequestsList.itemCount

  const dispatch = useDispatch()

  useEffect(() => {
    if (allowMultipleCompaniesMeeting) {
      const params = { page, pageSize, meetingTypeId }
      if (!isNil(searchQuery) && !isEmpty(searchQuery)) {
        params.q = trim(searchQuery)
      }
      http
        .get('meetings/declined', {
          params,
          paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: 'repeat' }),
        })
        .then((response) => {
          page !== 1
            ? setAllDeclinedList((f) => f.concat(response.data.result.results))
            : setAllDeclinedList(response.data.result.results)
          loadPage(response.data.result.page, pageSize)
          setHasNext(response.data.result.hasNextPage)
        })
        .catch((error) => {
          if (!error?.response?.data?.reason === 'Tier')
            message.error('Could not load meetings')
        })
    }
  }, [
    page,
    pageSize,
    allowMultipleCompaniesMeeting,
    searchQuery,
    meetingTypeId,
    refresh,
  ])

  useEffect(() => {
    setRefresh(refreshTable)
    setAllDeclinedList([])
  }, [refreshTable])

  useEffect(() => {
    const params = { page, pageSize }

    if (!isNil(searchQuery) && !isEmpty(searchQuery)) {
      params.q = trim(searchQuery)
    }

    setIsLoading(true)
    dispatch(
      fetchDeclinedRequests({ meetingTypeId, eventId, ...params })
    ).finally(() => {
      setIsLoading(false)
    })
  }, [dispatch, eventId, meetingTypeId, page, pageSize, searchQuery, refresh])

  const renderDeclinedStatus = (record) => (
    <span
      className={`cc-body-text cc-status-${record.meetingStatus.toLowerCase()}`}
    >
      {record.meetingStatus}
    </span>
  )

  const renderContactThatCancelled = (record) => {
    if (isNil(record.cancellationReasons)) return
    const {
      userThatCancelledOrDeclined,
      cancellationReasonId,
      cancellationReasonText,
    } = record.cancellationReasons
    return (
      userThatCancelledOrDeclined && (
        <div style={{ display: 'flex', flexDirection: 'flex-start' }}>
          <ContactName
            marginTop="0px"
            name={userThatCancelledOrDeclined.contactName}
            imageUrl={userThatCancelledOrDeclined.imageUrl}
            fontClass="cc-body-text"
          />
          {cancellationReasonId > 0 && (
            <span style={{ marginLeft: '8px' }}>
              <Tooltip title={cancellationReasonText}>
                <FontAwesomeIcon color="#92949C" icon={faComment} />
              </Tooltip>
            </span>
          )}
        </div>
      )
    )
  }

  const handleInfiniteOnLoad = useCallback(() => {
    if (allDeclinedList?.length > 0 && hasNext) {
      loadPage(page + 1, pageSize)
    }
  }, [allDeclinedList, hasNext, loadPage, page, pageSize])

  const getColumns = () => {
    const columns = [
      {
        key: '1',
        title: 'MEETING WITH',
        width: 340,
        render: (record) => (
          <div>
            <Link
              to={{
                pathname: `/meeting/${meetingType.toLowerCase()}/${
                  record.meetingId
                }`,
                state: { isReceived: false },
              }}
            >
              <span className="cc-body-text">{record.company.companyName}</span>
            </Link>
            <div className="ReceivedRequestsTable-category">
              {record.categoryName}
            </div>
          </div>
        ),
      },
      {
        key: '2',
        title: 'PARTICIPANTS',
        width: 350,
        render: renderParticipantsAvatars,
      },
      {
        key: '3',
        cardType: DATE,
        align: 'center',
        title: 'Meeting Date & Time',
        dataIndex: 'meetingDateTime',
        width: 230,
        render: (meetingDateTime, meeting) =>
          moment(meetingDateTime).isValid()
            ? getFormatedMeetingTime(
                meeting.meetingTimeZone,
                meeting.virtualSummit,
                meetingDateTime,
                'YYYY-MM-DD h:mm A'
              )
            : 'TBD',
      },
      {
        key: '4',
        title: 'DECLINED/CANCELLED BY',
        render: renderContactThatCancelled,
        align: 'left',
        notRenderInCard: true, //fix for later
      },
      {
        key: '5',
        title: 'STATUS',
        render: renderDeclinedStatus,
        align: 'center',
      },
    ]

    const eventName = {
      title: 'Summit',
      className: 'cc-body-text',
      dataIndex: 'eventName',
    }

    if (!fromSummit && meetingTypeId === 1) columns.splice(3, 0, eventName)
    return columns
  }

  if (width < 1025 && !allowMultipleCompaniesMeeting) {
    if (total === 0 && !isLoading)
      return <Empty description="No declined/cancelled requests." />
    else
      return (
        <Loading spinning={isLoading}>
          <ResponsiveCard
            columns={getColumns()}
            requests={
              !isNil(declinedRequestsList) ? declinedRequestsList.results : []
            }
            meetingType={meetingType}
            tableType="Declined"
            displayTimezone={displayTimezone}
            setDisplayTimezone={setDisplayTimezone}
            displayTimezoneLabel={displayTimezoneLabel}
            setDisplayTimezoneLabel={setDisplayTimezoneLabel}
          />
        </Loading>
      )
  } else {
    return (
      <div
        className={
          allowMultipleCompaniesMeeting
            ? 'DeclinedRequestsTableCfn'
            : 'DeclinedRequestsTable'
        }
      >
        <Loading spinning={isLoading}>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px',
            }}
          >
            <h4
              className={`${
                allowMultipleCompaniesMeeting
                  ? 'DeclinedRequestsTableCfn'
                  : 'DeclinedRequestsTable'
              }-title cc-heading4`}
            >
              Declined Requests
            </h4>

            <SearchBox onSearch={searchByText} allowClear={true} />
          </Row>
          {!allowMultipleCompaniesMeeting && (
            <Table
              locale={{
                emptyText: (
                  <EmptyEventTablesState activeSummit={activeSummit} />
                ),
              }}
              columns={getColumns()}
              rowKey={(record) => record.meetingId}
              dataSource={declinedRequestsList.results}
              pagination={{
                current: page,
                pageSize,
                total,
              }}
              onChange={(pagination) => {
                loadPage(pagination.current, pagination.pageSize)
              }}
            />
          )}
          {allDeclinedList && allowMultipleCompaniesMeeting && (
            <MeetingsCardView
              meetingList={allDeclinedList}
              hasMore={hasNext}
              isSearching={searchQuery && !isEmpty(searchQuery)}
              isScrollingAllowed={page > 0}
              handleInfiniteOnLoad={handleInfiniteOnLoad}
              renderActions={renderDeclinedStatus}
              renderRatings={renderContactThatCancelled}
              type="Declined"
              loading={isLoading}
            />
          )}
        </Loading>
      </div>
    )
  }
}

DeclinedRequestsTable.propTypes = {
  activeSummit: PropTypes.bool,
  meetingType: PropTypes.string.isRequired,
  companyEventId: PropTypes.number,
  fromSummit: PropTypes.bool,
  width: PropTypes.number.isRequired,
  refreshTable: PropTypes.bool,
  allowMultipleCompaniesMeeting: PropTypes.bool,
}

export default DeclinedRequestsTable
