import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { api as http } from '~/api/services'
import { GEOCODING_API_KEY, GEOCODING_API_URL } from '~/config'
import { generateId } from '~/utils'

const { Search } = Input

const SearchField = ({
  id = null,
  name,
  value,
  className,
  filterBy,
  transform,
  onFocus,
  onChange,
  onSearchResults,
}) => {
  const [searching, setSearching] = useState(false)

  const handleSearch = useCallback(
    (val) => {
      setSearching(true)

      const params = {
        address: `${val},${filterBy}`,
        key: GEOCODING_API_KEY,
        sessiontoken: generateId(10),
      }
      http
        .get(GEOCODING_API_URL, {
          params,
          transformRequest: [
            (data, headers) => {
              delete headers.common.Authorization
              return data
            },
          ],
        })
        .then((response) => {
          const { results } = response.data
          const extractedResults = transform(results)
          if (!isNil(extractedResults) && !isEmpty(extractedResults)) {
            onSearchResults(extractedResults)
          }
        })
        .finally(() => setSearching(false))
    },
    [filterBy, transform, onSearchResults]
  )

  return (
    <Search
      id={isNil(id) ? name : id}
      name={name}
      className={className}
      value={value}
      loading={searching}
      onFocus={onFocus}
      onBlur={(e) => console.log('BLUR:', e)}
      onChange={onChange}
      onSearch={handleSearch}
    />
  )
}

SearchField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  filterBy: PropTypes.object,
  transform: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onSearchResults: PropTypes.func,
}

export default SearchField
