import PropTypes from 'prop-types'
import { Descriptions, Popover, Typography } from 'antd'
import isNil from 'lodash/isNil'
import moment from 'moment-timezone'

const ORIGINAL = 'original'
const LOCAL = 'local'
const UTC = 'utc'

const TIMEZONE_DISPLAY_OPTIONS = [ORIGINAL, LOCAL, UTC]
const { Text } = Typography

const TimeDisplay = ({
  time,
  timezone,
  localTimezone = null,
  displayInTimezone = ORIGINAL,
  displayFormat = 'YYYY-MM-DD h:mm A',
  showUtc = false,
}) => {
  const originalTime = moment.utc(time)
  const validTime = originalTime.isValid()

  if (isNil(localTimezone)) {
    localTimezone = moment.tz.guess()
  }

  const originalTimeDisplay = validTime
    ? originalTime.tz(timezone).format(displayFormat)
    : 'TBD'
  const localTimeDisplay = validTime
    ? originalTime.tz(localTimezone).format(displayFormat)
    : 'TBD'
  const utcTimeDisplay = validTime
    ? originalTime.utc().format(displayFormat)
    : 'TBD'

  let timeDisplay = originalTimeDisplay

  if (displayInTimezone === LOCAL) {
    timeDisplay = localTimeDisplay
  } else if (displayInTimezone === UTC) {
    timeDisplay = utcTimeDisplay
  }

  const content = (
    <Descriptions
      column={1}
      title="Meeting Date & Time"
      style={{ 'max-width': 300 }}
    >
      <Descriptions.Item
        label={<Text strong={displayInTimezone === ORIGINAL}>{timezone}</Text>}
      >
        <Text strong={displayInTimezone === ORIGINAL}>
          {originalTimeDisplay}
        </Text>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Text strong={displayInTimezone === LOCAL}>{'Local Timezone'}</Text>
        }
      >
        <Text strong={displayInTimezone === LOCAL}>{localTimeDisplay}</Text>
      </Descriptions.Item>
      {showUtc && (
        <Descriptions.Item label="UTC">{utcTimeDisplay}</Descriptions.Item>
      )}
    </Descriptions>
  )

  return (
    <Popover content={content} trigger="hover">
      <span className="TimeDisplay">{timeDisplay}</span>
    </Popover>
  )
}

TimeDisplay.propTypes = {
  time: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  localTimezone: PropTypes.string,
  displayInTimezone: PropTypes.oneOf(TIMEZONE_DISPLAY_OPTIONS),
  displayFormat: PropTypes.string,
  showUtc: PropTypes.bool,
}

TimeDisplay.ORIGINAL = ORIGINAL
TimeDisplay.LOCAL = LOCAL
TimeDisplay.UTC = UTC
TimeDisplay.TIMEZONE_DISPLAY_OPTIONS = TIMEZONE_DISPLAY_OPTIONS

export default TimeDisplay
