const CloudMeetFrame = ({ src, title = 'cloudmeet' }) => (
  <iframe
    title={title}
    src={src}
    width="100%"
    height="100%"
    style={{ border: 'none' }}
    allow="camera; microphone; fullscreen; display-capture; autoplay; clipboard-write;"
  />
)

export default CloudMeetFrame
