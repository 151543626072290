import PropTypes from 'prop-types'
import { Modal } from 'antd'

const ProfileModal = ({
  visible,
  header,
  body,
  footer = null,
  onClose = () => null,
}) => {
  return (
    <div className="discover-service-provider-details-container">
      <Modal
        bodyStyle={{ padding: '0' }}
        visible={visible}
        title={header}
        width={960}
        footer={footer}
        onCancel={onClose}
      >
        {body}
      </Modal>
    </div>
  )
}

ProfileModal.propTypes = {
  visible: PropTypes.bool,
  header: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
}

export default ProfileModal
