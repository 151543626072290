import PropTypes from 'prop-types'
import { Tooltip } from '@context365/popovers'

const meetingStatusColor = {
  Pending: '#008DCA',
  Confirmed: '#24C477',
  Cancelled: '#F19F00',
  Expired: '#92949c',
  default: '#D62B34',
}
function getMeetingStatusColor(status) {
  return meetingStatusColor[status] ?? meetingStatusColor.default
}

const MeetingBadge = ({ meeting = {} }) => {
  const { meetingStatus } = meeting
  if (!meetingStatus) {
    return null
  }

  const meetingStatusColor = getMeetingStatusColor(meetingStatus)
  return (
    <Tooltip text={`Meeting is ${meetingStatus}`}>
      <div
        className="inline-block ml-1 h-3 w-3 rounded-full"
        style={{ backgroundColor: meetingStatusColor }}
      />
    </Tooltip>
  )
}

MeetingBadge.propTypes = {
  meeting: PropTypes.shape({
    meetingStatus: PropTypes.string,
  }),
}

export default MeetingBadge
