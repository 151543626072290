import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Select, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import throttle from 'lodash/throttle'
import { useSelector } from 'react-redux'
import { getContactsForSearch } from '~/actions/messages'
import { getCompanyId } from '~/selectors/auth'
import ProfilePictureGroup from '../ProfilePictureGroup'
import './SelectCompanyUsers.less'

const { Option } = Select

const SelectCompanyUsers = ({
  firstContact,
  index,
  onUserItemChanged,
  errors,
  userInvitesIds,
  colleagues,
}) => {
  const [autofillContacts, setAutofillContacts] = useState([])
  const [autoFillLoading, setAutoFillLoading] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const companyId = useSelector(getCompanyId)

  const error = (shortName, key) =>
    isNil(errors.userInvites && errors.userInvites[key])
      ? {}
      : {
          help: errors.userInvites[key][shortName],
          validateStatus: errors.userInvites[key][shortName] && 'error',
        }

  const searchContactsHandler = async (e) => {
    if (e === '' || e.length < 3) return
    setAutoFillLoading(true)
    try {
      const contactsRaw = await getContactsForSearch(e)
      let selectedContacts = []
      contactsRaw.data.result.map((c) =>
        c.companies.map(
          (company) =>
            (selectedContacts = [
              ...selectedContacts,
              {
                contactName: `${c.firstName} ${c.lastName}`,
                imageUrl: c.imageUrl,
                companyName: company.name,
                companyType: company.category,
                contactId: c.contactId,
                companyId: company.companyId,
                companyContactID: `${c.contactId}${company.companyId}`,
              },
            ])
        )
      )
      setAutofillContacts(
        selectedContacts.filter(
          (c) =>
            c.companyId !== companyId &&
            !userInvitesIds.includes(c.contactId) &&
            !colleagues.includes(c.contactId)
        )
      )
    } catch (error) {
      message.error('There was an error while searching for contacts')
    } finally {
      setAutoFillLoading(false)
    }
  }

  const delayedQuery = throttle((q) => searchContactsHandler(q), 1000)

  const searchHandler = (e) => {
    delayedQuery(e)
  }

  const contactSelectedHandler = useCallback(
    async (e) => {
      if (isNil(e)) {
        setAutofillContacts([])
        onUserItemChanged(index, { name: null })
      } else {
        const contact = autofillContacts.find((x) => x.companyContactID === e)
        onUserItemChanged(index, {
          name: contact.contactName,
          company: contact.companyName,
          contactId: contact.contactId,
          companyId: contact.companyId,
          role: 3,
        })
      }
    },
    [autofillContacts, index, onUserItemChanged]
  )

  const renderOption = (contact) => (
    <Option
      name={contact.contactName.toLowerCase()}
      value={contact.companyContactID}
    >
      <div className="messages-compose-option-contact">
        <div className="messages-compose-option-contact-image">
          <ProfilePictureGroup
            diameter={32}
            maxImages={1}
            profiles={[contact]}
          />
        </div>
        <div className="messages-compose-contact-option-text">
          <div className="cc-body-text messages-compose-option-contact-name">
            {contact.contactName}
          </div>
          <div className="cc-xsmall-font messages-compose-option-company-name">
            {contact.companyName}
          </div>
        </div>
        <div className="cc-xsmall-font messages-compose-option-company-category">
          {contact.companyType}
        </div>
      </div>
    </Option>
  )

  const renderValue = (contact) => (
    <div className="messages-compose-option-contact">{contact.name}</div>
  )

  return (
    <div className="grid grid-cols-2 gap-4 mt-2 mb-2 w-full">
      <Form.Item {...error('name', index)}>
        <Select
          allowClear={true}
          loading={autoFillLoading}
          showSearch
          className="cc-shadow-box SelectCompanyUsers"
          placeholder="Select a person"
          optionFilterProp="value"
          filterOption={(input, option) =>
            option.props.name.indexOf(input.toLowerCase()) >= 0
          }
          onChange={contactSelectedHandler}
          onSearch={searchHandler}
          value={!isEmpty(firstContact) && renderValue(firstContact)}
          onKeyDown={(event) => {
            if (
              event.key === 'Enter' &&
              (autofillContacts.length < 1 || !dropdownOpen)
            ) {
              setDropdownOpen(true)
            }
          }}
          open={dropdownOpen}
          onDropdownVisibleChange={(open) => setDropdownOpen(open)}
        >
          {autofillContacts.map((x) => renderOption(x))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Input
          className="cc-shadow-box text-md h-12"
          value={firstContact?.company}
          disabled
        />
      </Form.Item>
    </div>
  )
}

SelectCompanyUsers.propTypes = {
  onUserItemChanged: PropTypes.func,
  index: PropTypes.number,
  firstContact: PropTypes.object,
  errors: PropTypes.object,
  userInvitesIds: PropTypes.array,
  colleagues: PropTypes.object,
}

export default SelectCompanyUsers
