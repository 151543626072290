import * as React from 'react'
import PropTypes from 'prop-types'
import {
  CancelOutlined,
  Check,
  Info,
  PersonAddAlt as PersonAdd,
  PersonRemove,
} from '@context365/icons'
import cx from 'classnames'
import { listify } from '~/utils'
import { format, parseDate } from '../utils/dateFormatters'

const statuses = {
  info: 'text-primary-100',
  success: 'text-green-100',
  error: 'text-red-100',
}

export default function SystemMessage({
  messageDateTime,
  status,
  title,
  icon,
  children,
}) {
  return (
    <div className="p-6 text-center">
      <div className="type-body-regular-xs text-grey-600">
        {formatEventDate(messageDateTime)}
      </div>
      <div
        className={cx(
          'flex items-center justify-center gap-2 mt-2',
          statuses[status]
        )}
      >
        {React.cloneElement(icon, { size: 18 })}
        <span className="type-body-semibold-sm">{title}</span>
      </div>
      {children && (
        <div className="type-body-regular-sm mt-2" style={{ lineHeight: 1.5 }}>
          {children}
        </div>
      )}
    </div>
  )
}

SystemMessage.propTypes = {
  messageDateTime: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['info', 'success', 'error']).isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node,
}

SystemMessage.MeetingRequested = MeetingRequested
SystemMessage.MeetingAccepted = MeetingAccepted
SystemMessage.MeetingDeclined = MeetingDeclined
SystemMessage.MeetingScheduled = MeetingScheduled
SystemMessage.MeetingRescheduled = MeetingRescheduled
SystemMessage.MeetingCancelled = MeetingCancelled
SystemMessage.ParticipantsAdded = ParticipantsAdded
SystemMessage.ParticipantLeft = ParticipantLeft

function MeetingRequested({
  messageDateTime,
  contactName,
  companyName,
  meetingType,
}) {
  return (
    <SystemMessage
      messageDateTime={messageDateTime}
      status="info"
      title="Meeting Requested"
      icon={<Info />}
    >
      <span>
        <b>{contactName}</b> from <b>{companyName}</b> has sent{' '}
        {withArticle(meetingType ? `${meetingType} meeting` : 'meeting')}{' '}
        request.
      </span>
    </SystemMessage>
  )
}

MeetingRequested.propTypes = {
  messageDateTime: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  meetingType: PropTypes.string,
}

function MeetingAccepted({
  messageDateTime,
  contactName,
  companyName,
  meetingDate,
}) {
  return (
    <SystemMessage
      messageDateTime={messageDateTime}
      status="success"
      title="Meeting Accepted"
      icon={<Check />}
    >
      <span>
        <b>{contactName}</b> from <b>{companyName}</b> has accepted the meeting
        request.
        {meetingDate && (
          <>
            <br />
            Meeting will take place: <b>{formatEventDate(meetingDate)}</b>
          </>
        )}
      </span>
    </SystemMessage>
  )
}

MeetingAccepted.propTypes = {
  messageDateTime: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  meetingDate: PropTypes.string,
}

function MeetingDeclined({ messageDateTime, contactName }) {
  return (
    <SystemMessage
      messageDateTime={messageDateTime}
      status="error"
      title="Meeting Declined"
      icon={<CancelOutlined />}
    >
      <span>
        <b>{contactName}</b> has declined the meeting request.
      </span>
    </SystemMessage>
  )
}

MeetingDeclined.propTypes = {
  messageDateTime: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
}

function MeetingScheduled({
  messageDateTime,
  contactName,
  companyName,
  meetingDate,
}) {
  return (
    <SystemMessage
      messageDateTime={messageDateTime}
      status="info"
      title="Meeting Scheduled"
      icon={<Info />}
    >
      <span>
        <b>{contactName}</b> from <b>{companyName}</b> has scheduled the meeting
        for
        <br />
        <b>{formatEventDate(meetingDate)}</b>
      </span>
    </SystemMessage>
  )
}

MeetingScheduled.propTypes = {
  messageDateTime: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  meetingDate: PropTypes.string.isRequired,
}

function MeetingRescheduled({
  messageDateTime,
  contactName,
  companyName,
  meetingDate,
}) {
  return (
    <SystemMessage
      messageDateTime={messageDateTime}
      status="info"
      title="Meeting Rescheduled"
      icon={<Info />}
    >
      <span>
        <b>{contactName}</b> from <b>{companyName}</b> has rescheduled the
        meeting.
        <br />
        Rescheduled to: <b>{formatEventDate(meetingDate)}</b>
      </span>
    </SystemMessage>
  )
}

MeetingRescheduled.propTypes = {
  messageDateTime: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  meetingDate: PropTypes.string.isRequired,
}

function MeetingCancelled({
  messageDateTime,
  contactName,
  companyName,
  cancellationReason,
}) {
  return (
    <SystemMessage
      messageDateTime={messageDateTime}
      status="error"
      title="Meeting Cancelled"
      icon={<CancelOutlined />}
    >
      <span>
        <b>{contactName}</b> from <b>{companyName}</b> has cancelled the meeting
        {cancellationReason ? 'for the following reason:' : '.'}
        {cancellationReason && (
          <>
            <br />
            <b>{cancellationReason}</b>
          </>
        )}
      </span>
    </SystemMessage>
  )
}

MeetingCancelled.propTypes = {
  messageDateTime: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  cancellationReason: PropTypes.string,
}

function ParticipantsAdded({ messageDateTime, contactName, inviteeNames }) {
  return (
    <SystemMessage
      messageDateTime={messageDateTime}
      status="info"
      title="New people added to the conversation"
      icon={<PersonAdd />}
    >
      <span>
        <b>{listify(inviteeNames)}</b>{' '}
        {inviteeNames.length > 1 ? 'have' : 'has'} been added to the
        conversation by <b>{contactName}</b>.
      </span>
    </SystemMessage>
  )
}

ParticipantsAdded.propTypes = {
  messageDateTime: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  inviteeNames: PropTypes.arrayOf(PropTypes.string).isRequired,
}

function ParticipantLeft({ messageDateTime, contactName }) {
  return (
    <SystemMessage
      messageDateTime={messageDateTime}
      status="info"
      title={`${contactName} left the conversation`}
      icon={<PersonRemove />}
    />
  )
}

ParticipantLeft.propTypes = {
  messageDateTime: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
}

function formatEventDate(messageDateTime) {
  return format.systemEventDateTime(parseDate.fromUTC(messageDateTime))
}

function withArticle(text) {
  const vowels = ['a', 'e', 'i', 'o', 'u']
  const article = vowels.includes(text.toLowerCase()[0]) ? 'an' : 'a'
  return `${article} ${text}`
}
