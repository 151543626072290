import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'
import Profile from '~/components/Profile'
import CompanyCategories from '~/constants/companyCategories'
import ContactProfileCompanies from './Tabs/ContactProfileCompanies'
import ContactProfileConnections from './Tabs/ContactProfileConnections'
import ContactProfileFunds from './Tabs/ContactProfileFunds'
import ContactProfileInvestorPreferences from './Tabs/ContactProfileInvestorPreferences'
import ContactProfileMandates from './Tabs/ContactProfileMandates'
import ContactProfileOverview from './Tabs/ContactProfileOverview'

export default function ContactProfileBody({ contact }) {
  const tabs = [
    {
      id: 'overview',
      label: 'Overview',
      content: <ContactProfileOverview contact={contact} />,
    },
    some(
      contact?.companies,
      (c) => c.categoryId === CompanyCategories.Allocator
    ) && {
      id: 'investorPreferences',
      label: 'Investor Preferences',
      content: (
        <ContactProfileInvestorPreferences contactId={contact.contactId} />
      ),
    },
    !isEmpty(contact?.companies) && {
      id: 'companies',
      label: 'Companies',
      content: <ContactProfileCompanies companies={contact?.companies} />,
    },
    !isEmpty(contact.funds) && {
      id: 'funds',
      label: 'Funds',
      content: <ContactProfileFunds funds={contact?.funds} />,
    },
    !isEmpty(contact.mandates) && {
      id: 'mandates',
      label: 'Mandates',
      content: <ContactProfileMandates mandates={contact?.mandates} />,
    },
    !isEmpty(contact.connections) && {
      id: 'connections',
      label: 'Connections',
      content: <ContactProfileConnections connections={contact.connections} />,
    },
  ]

  return <Profile.Body tabs={filter(tabs, Boolean)} />
}

ContactProfileBody.propTypes = { contact: PropTypes.object }
