import * as React from 'react'
import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Compare, PlaylistAdd } from '@context365/icons'
import { useFlags } from 'launchdarkly-react-client-sdk'
import filter from 'lodash/filter'
import find from 'lodash/find'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { logProfileView } from '~/actions/company'
import { useDiscoverLists } from '~/hooks/discoverLists'
import { DiscoverListsPopover } from '../DiscoverLists'
import FundDetailsModalContainer from '../FundDetailsModalContainer'
import ShortenedText from '../ShortenedText'
import {
  AnnualReturns,
  Aum,
  BroadStrategy,
  Contacts,
  ContextSignals,
  InceptionDate,
  Matchmaking,
  Minimum,
  Sharpe,
  Substrategy,
  YearToDateReturn,
} from './DiscoverFundsColumns'
import DiscoverTable from './DiscoverTable'
import { useSelectedRows } from './selectRowsContext'

const COMPARE_FUNDS_MAX = 20

const DiscoverFundsTable = ({
  fundList,
  loadPage,
  currentPage,
  total,
  selectedFundId,
  onChangeSelectedFundId,
  onAddFundsToList,
  onCompareFunds,
  pageSize,
}) => {
  const { trackEvent } = useTracking()

  const { discoverLists } = useDiscoverLists()

  const [fund, setFund] = useState()
  const { selectedRows: selectedFunds } = useSelectedRows()

  const { role } = useSelector((state) => state.auth)

  const showDetailsModal = React.useCallback(
    (fund) => {
      onChangeSelectedFundId(fund.fundId)
      const profile = {
        companyId: fund.companyId,
        fundId: fund.fundId,
      }
      logProfileView(profile)
    },
    [onChangeSelectedFundId]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'FUND',
        id: 'Fund Name',
        // eslint-disable-next-line react/no-unstable-nested-components -- it's memoized but the plugin can't tell that
        accessor: (fund) => {
          return (
            <Button
              variant="link"
              onClick={() => {
                setFund(fund)
                showDetailsModal(fund)
              }}
            >
              <ShortenedText text={fund.name} maxLength={35} />
            </Button>
          )
        },
      },
      Matchmaking,
      {
        id: 'Discover Lists',
        Header: '',
        // eslint-disable-next-line react/no-unstable-nested-components -- it's memoized but the plugin can't tell that
        accessor: (fund) => {
          const lists = filter(discoverLists, (list) =>
            find(list.discoverListLinks, ['toFundId', fund.fundId])
          )
          return (
            lists &&
            lists.length > 0 && (
              <DiscoverListsPopover
                lists={lists}
                targetObject={fund}
                getTargetModel={() => {
                  return { toFundIds: [fund.fundId] }
                }}
                onAddToList={() => onAddFundsToList([fund])}
              />
            )
          )
        },
      },
      ContextSignals,
      Contacts,
      BroadStrategy,
      Substrategy,
      Aum,
      InceptionDate,
      Minimum,
      AnnualReturns,
      YearToDateReturn,
      Sharpe,
    ],
    [discoverLists, showDetailsModal, onAddFundsToList]
  )

  const addToListAction = {
    title: 'Add to List',
    icon: <PlaylistAdd className="mr-2" />,
    onClick: () => {
      trackEvent({ eventName: 'click', element: 'BulkAddToList' })
      onAddFundsToList(selectedFunds)
    },
    isDisabled: () => false,
  }

  const compareFundsAction = {
    title: 'Compare Funds',
    icon: <Compare className="mr-2" />,
    onClick: () => {
      trackEvent({ eventName: 'click', element: 'CompareFunds' })
      onCompareFunds()
    },
    isDisabled: (rows) => rows.length > COMPARE_FUNDS_MAX,
  }

  const { compareFunds } = useFlags()

  const bulkActions = compareFunds
    ? [addToListAction, compareFundsAction]
    : [addToListAction]

  return (
    <>
      {fund && (
        <FundDetailsModalContainer
          fund={fund}
          showFundProfile={selectedFundId === fund.fundId}
          arePointsNear={() => false}
          onClose={() => onChangeSelectedFundId(null)}
          showDataroom={false}
          canSendMessage={role !== 'Service Provider'}
        />
      )}
      <DiscoverTable
        columns={columns}
        data={fundList}
        currentPage={currentPage}
        total={total}
        loadPage={loadPage}
        bulkActions={bulkActions}
        tableTitle="DiscoverFundsTable"
        useColumnCustomization={true}
        pageSize={pageSize}
      />
    </>
  )
}

DiscoverFundsTable.propTypes = {
  fundList: PropTypes.array.isRequired,
  currentPage: PropTypes.number,
  total: PropTypes.number,
  loadPage: PropTypes.func.isRequired,
  selectedFundId: PropTypes.number,
  onChangeSelectedFundId: PropTypes.func,
  onAddFundsToList: PropTypes.func.isRequired,
  onCompareFunds: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
}

export default DiscoverFundsTable
