import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'
import moment from 'moment'
import DISPLAY_MODES from '~/constants/displayModes'
import VALUE_TYPES from '~/constants/valueTypes'
import ProfileDetails from '../ProfileDetails/ProfileDetails'
import './FundDetailsDetails.less'

const FundDetailsStaticValues = ({ answers }) => {
  const fundDetails = [
    {
      displayMode: DISPLAY_MODES.COLUMN,
      groupName: 'Description',
      width: '50%',
      sections: [
        {
          key: 'fundAum',
          label: 'Fund AUM USD',
          value: answers.FundAUM,
          valueType: VALUE_TYPES.CURRENCY,
        },
        {
          key: 'inceptionDate',
          label: 'Inception Date',
          value: answers.FundInceptionDate,
          valueType: VALUE_TYPES.DATETIME,
        },
        {
          key: 'domicile',
          label: 'Domicile',
          value: answers.Domicile,
        },
        {
          key: 'attributes',
          label: 'Attributes',
          value: answers.FundAttributes,
        },
      ],
    },
    {
      groupName: 'Strategy',
      width: '50%',
      displayMode: DISPLAY_MODES.COLUMN,
      sections: [
        {
          key: 'broadStrategy',
          label: 'Broad Strategy',
          value: answers.BroadStrategy,
        },
        {
          key: 'subStrategy',
          label: 'Sub Strategy',
          value: answers.SubStrategy,
        },
        answers.NetDollarExposure && {
          key: 'netDollarExposure',
          label: 'Net Dollar Exposure',
          value: answers.NetDollarExposure,
        },
        answers.PrimaryDriverOfInvestmentDecisionProcess && {
          key: 'primaryDriverOfInvestmentDecisionProcess',
          label: 'Investment Decision Process',
          value: answers.PrimaryDriverOfInvestmentDecisionProcess,
        },
        answers.PrimaryInstruments && {
          key: 'primaryInstruments',
          label: 'Liquid Instruments',
          value: answers.PrimaryInstruments,
        },
      ],
    },
    {
      groupName: 'Fees',
      displayMode: DISPLAY_MODES.ROW,
      width: '50%',
      sections: [
        {
          key: 'managementFee',
          label: 'Management',
          value: answers.ManagementFee,
          valueType: VALUE_TYPES.PERCENT,
        },
        {
          key: 'performanceFee',
          label: 'Performance',
          value: answers.PerformanceFee,
          valueType: VALUE_TYPES.PERCENT,
        },
      ],
    },
    {
      groupName: 'Drawdown',
      displayMode: DISPLAY_MODES.ROW,
      width: '50%',
      sections: [
        {
          key: 'deepestDrawdown',
          label: 'Deepest',
          value: answers.DeepestDrawdown,
          valueType: VALUE_TYPES.PERCENT,
        },
        {
          key: 'lengthOfLongestDrawdown',
          label: 'Length of Longest',
          value: answers.LengthOfLongestDrawdown,
          valueType: VALUE_TYPES.MONTHS,
        },
      ],
    },
    {
      groupName: 'Portfolio Exposures',
      displayMode: DISPLAY_MODES.COLUMN,
      width: '50%',
      sections: [
        {
          key: 'geographicConcentration',
          label: 'Geographic Concentration',
          value: answers.GeographicConcentration,
        },
        {
          key: 'sectorConcentration',
          label: 'Sector Concentration',
          value: answers.SectorConcentration,
        },
      ],
    },
    {
      groupName: 'Investment Terms',
      displayMode: DISPLAY_MODES.ROW,
      width: '50%',
      sections: [
        {
          key: 'minimumInvestment',
          label: 'Minimum',
          value: answers.MinimumInvestment,
          valueType: VALUE_TYPES.CURRENCY,
        },
        {
          key: 'redemptions',
          label: 'Redemption Notice',
          value: answers.DaysAdvanceNoticeNeededForRedemption,
          valueType: VALUE_TYPES.DAYS,
        },
      ],
    },
    {
      groupName: 'Risk Metrics',
      displayMode: DISPLAY_MODES.ROW,
      width: '50%',
      sections: [
        {
          key: 'sharpe',
          label: 'Sharpe',
          value: answers.Sharpe,
          valueType: VALUE_TYPES.DECIMAL,
        },
        {
          key: 'sortino',
          label: 'Sortino',
          value: answers.Sortino,
          valueType: VALUE_TYPES.DECIMAL,
        },
        {
          key: 'sterling',
          label: 'Sterling',
          value: answers.Sterling,
          valueType: VALUE_TYPES.DECIMAL,
        },
      ],
    },
    {
      groupName: 'Returns',
      displayMode: DISPLAY_MODES.ROW,
      width: '50%',
      sections: [
        {
          key: 'annualizedReturn',
          label: 'Annualized Return',
          value: answers.AnnualizedReturn,
          valueType: VALUE_TYPES.PERCENT_MULTIPLIED_100,
          width: '50%',
        },
        {
          key: 'annualizedVolatility',
          label: 'Annual Geometric Standard Deviation',
          value: answers.AnnualizedVolatility,
          valueType: VALUE_TYPES.PERCENT_MULTIPLIED_100,
          width: '50%',
        },
        {
          key: 'ytdReturn',
          label: `${moment().format('YYYY')} YTD Return`,
          value: answers.YtdReturn,
          valueType: VALUE_TYPES.PERCENT,
          width: '33%',
        },
        {
          key: 'previousYtdReturn',
          label: `${moment().format('YYYY') - 1} YTD Return`,
          value: answers.PreviousYtdReturn,
          valueType: VALUE_TYPES.PERCENT,
          width: '33%',
        },
        {
          key: 'lastYtdReturn',
          label: `${moment().format('YYYY') - 2} YTD Return`,
          value: answers.LastYtdReturn,
          valueType: VALUE_TYPES.PERCENT,
          width: '33%',
        },
      ],
    },
    {
      groupName: 'Lockup',
      displayMode: DISPLAY_MODES.COLUMN,
      width: '50%',
      sections: [
        {
          key: 'durationOfCapitalLockUp',
          label: 'Duration Of Lockup',
          value: answers.DurationOfCapitalLockup,
          valueType: VALUE_TYPES.DAYS,
        },
        {
          key: 'postLockUpLiquidity',
          label: 'Liquidity After Lockup',
          value: answers.PostLockupLiquidity,
        },
      ],
    },
    {
      groupName: 'Service Providers',
      displayMode: DISPLAY_MODES.COLUMN,
      width: '50%',
      sections: [
        {
          key: 'primeBroker',
          label: 'Prime Broker',
          value: [
            answers.PrimeBroker1,
            answers.PrimeBroker2,
            answers.PrimeBroker3,
          ]
            .filter((x) => !isNil(x))
            .join(', '),
        },
        {
          key: 'adminFirm',
          label: 'Admin Firm',
          value: answers.FundAdministrator,
        },
        {
          key: 'auditFirm',
          label: 'Audit Firm',
          value: answers.Auditor,
        },
        {
          key: 'lawFirm',
          label: 'Law Firm',
          value: answers.ExternalLegalCounsel,
        },
      ],
    },
  ]

  return <ProfileDetails valueGroups={fundDetails} />
}

FundDetailsStaticValues.propTypes = {
  answers: PropTypes.object.isRequired,
}

export default FundDetailsStaticValues
