import { TabButton, TabList, TabPanel, Tabs } from '@context365/tabs'
import map from 'lodash/map'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom-v5-compat'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import Loading from '~/components/Loading'
import useSearchParams from '~/hooks/useSearchParams'
import Sidebar from './Sidebar'
import Allocations from './Tabs/Allocations'
import Managers from './Tabs/Managers'
import Overview from './Tabs/Overview'
import People from './Tabs/People'
import Returns from './Tabs/Returns'

const AllocatorProfile = () => {
  const { trackEvent } = useTracking({ component: 'AllocatorProfile' })
  const trackClick = (label) =>
    trackEvent({ eventName: 'click', element: 'AllocatorProfileTabs', label })

  const [searchParams, setSearchParams] = useSearchParams()

  const { companyId } = useParams()
  const { data: allocator, isLoading } = useQuery(
    ['allocatorProfile', companyId],
    () => api.allocators.getAllocatorCompanyProfile(companyId)
  )

  if (isLoading) {
    return <Loading />
  }

  const tabs = [
    {
      id: 'overview',
      label: 'overview',
      content: <Overview allocator={allocator} />,
    },
    {
      id: 'allocations',
      label: 'Allocations',
      content: <Allocations />,
    },
    {
      id: 'returns',
      label: 'Returns',
      content: <Returns />,
    },
    {
      id: 'managers',
      label: 'Managers',
      content: <Managers />,
    },
    {
      id: 'people',
      label: 'People',
      content: <People />,
    },
  ]

  return (
    <div>
      <Tabs
        onSelect={(tab) => setSearchParams({ tab })}
        activeTab={
          tabs.find((tab) => tab.id === searchParams.tab)?.id ?? tabs[0].id
        }
      >
        <div
          className="grid gap-8 items-baseline"
          style={{
            gridTemplateColumns: 'minmax(14rem, 1fr) 6fr minmax(14rem, 1fr)',
          }}
        >
          <TabList variant="vertical">
            {map(tabs, (tab) => (
              <TabButton
                id={tab.id}
                key={tab.id}
                label={tab.label}
                onClick={() => trackClick(tab.label)}
              />
            ))}
          </TabList>
          <div>
            <div className="type-header-xs text-primary-100 mb-4">
              {allocator.companyName}
            </div>
            {map(tabs, (tab) => (
              <TabPanel key={tab.id} tabId={tab.id} renderMode={tab.renderMode}>
                {tab.content}
              </TabPanel>
            ))}
          </div>
          <Sidebar allocator={allocator} />
        </div>
      </Tabs>
    </div>
  )
}

export default AllocatorProfile
