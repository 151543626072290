import PropTypes from 'prop-types'
import { BASE_URL } from '~/config'
import FloatingInput from '../../FloatingInput'
import { UploadFileField } from '../../FundWizard'

const ClientsField = ({ item, onItemChanged, itemIndex }) => (
  <div className="ClientsField-container">
    <div className="ClientsField-fields-column">
      <div className="ClientsField-fields-upper">
        <div className="ClientsField-name">
          <FloatingInput
            onChange={(e) => {
              onItemChanged(itemIndex, 'name', e.target.value)
            }}
            value={item.name}
            name="Client Name"
          />
        </div>
        <div className="ClientsField-position">
          <UploadFileField
            accept=".jpg, .png, .bmp"
            listType="picture"
            hint="File Types: .PNG, .JPG Max File Size: 4MB"
            action={`${BASE_URL}/uploads`}
            value={item.imageUrl}
            name="Logo"
            onChange={(e) => {
              onItemChanged(itemIndex, 'imageUrl', e)
            }}
          />
        </div>
      </div>
    </div>
  </div>
)

ClientsField.propTypes = {
  item: PropTypes.object.isRequired,
  onItemChanged: PropTypes.func.isRequired,
  itemIndex: PropTypes.number.isRequired,
}
export default ClientsField
