import { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'antd'
import isEmpty from 'lodash/isEmpty'
import FormCtx from '~/utils/contexts/FormCtx'
import useFormAnswers from '~/utils/hooks/useFormAnswers'
import '../Fields.less'

const ConditionalCheckboxField = ({ questionAnswerId, selectedQuestion }) => {
  const { answers } = useContext(FormCtx)

  const { answer, handleOptionsAnswerChange } = useFormAnswers(selectedQuestion)

  const filteredAnswer = answers.find(
    (a) => a.questionId === selectedQuestion.questionId
  ) || { questionAnswerIds: [] }

  const checkboxQuestions = selectedQuestion.questionAnswers?.filter(
    (questionAnswer) =>
      questionAnswer.conditionalQuestionAnswerId === questionAnswerId
  )

  const filteredCheckboxQuestionIds = checkboxQuestions.map(
    (c) => c.questionAnswerId
  )

  const onChange = useCallback(
    (e) =>
      handleOptionsAnswerChange([
        ...filteredAnswer.questionAnswerIds.filter(
          (q) => !filteredCheckboxQuestionIds.includes(q)
        ),
        ...e,
      ]),
    [
      filteredAnswer.questionAnswerIds,
      filteredCheckboxQuestionIds,
      handleOptionsAnswerChange,
    ]
  )

  return (
    !isEmpty(checkboxQuestions) && (
      <div className="CheckboxField-box-conditional">
        <Checkbox.Group
          className="CheckboxField-box"
          value={answer.questionAnswerIds}
          onChange={(e) => onChange(e)}
        >
          {checkboxQuestions.map((q, key) => (
            <Checkbox key={key} value={q.questionAnswerId}>
              {q.answer}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </div>
    )
  )
}

ConditionalCheckboxField.propTypes = {
  questionAnswerId: PropTypes.number,
  selectedQuestion: PropTypes.object,
}

export default ConditionalCheckboxField
