import { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  faComments,
  faInfoCircle,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Radio } from 'antd'
import './ChannelSwitch.less'

const ChannelSwitch = ({
  onChange = () => {},
  unreadMessage,
  showConversation = false,
}) => {
  const onTabChange = useCallback(
    (e) => {
      onChange(e.target.value)
    },
    [onChange]
  )

  // The three tabs users can choose what conversation list to currently display
  return (
    <div className="switch-conversations-header cc-heading5">
      <Radio.Group
        className={`switch-conversation-radio-group ${
          showConversation ? 'one-third-width' : 'fifty-fifty-width'
        }`}
        defaultValue="1"
        buttonStyle="solid"
        onChange={onTabChange}
      >
        <Radio.Button value="1">
          <span className="cc-small-font switch-conversation-radio">
            <FontAwesomeIcon
              icon={faUsers}
              className="switch-conversation-radio-icon"
            />
          </span>
        </Radio.Button>
        {showConversation && (
          <Radio.Button value="2">
            <span className="cc-small-font switch-conversation-radio">
              <FontAwesomeIcon
                icon={faComments}
                className="switch-conversation-radio-icon"
              />
            </span>
            {unreadMessage > 0 && (
              <span className="switch-conversation-radio-badge">
                <Badge count={unreadMessage}>
                  <a href="#" className="badge-head" />
                </Badge>
              </span>
            )}
          </Radio.Button>
        )}
        <Radio.Button value="3">
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="switch-conversation-radio-icon"
          />
        </Radio.Button>
      </Radio.Group>
    </div>
  )
}

ChannelSwitch.propTypes = {
  onChange: PropTypes.func,
  unreadMessage: PropTypes.number.isRequired,
  showConversation: PropTypes.bool,
}

export default ChannelSwitch
