import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { reset } from '~/actions/auth'
import { api } from '~/api/services'
import { StorageKeys } from '~/config'

export const cacheAuthTokens = (store, history) => () => {
  const { accessToken, refreshToken, claims, contact, company, role } =
    store.getState().auth

  const { token: deviceToken } = store.getState().notifications
  const logout = localStorage.getItem(StorageKeys.logout)

  if (isNil(accessToken) || isEmpty(accessToken) || logout === 'true') {
    localStorage.removeItem(StorageKeys.accessToken)
    delete api.defaults.headers.common.Authorization
  } else {
    localStorage.setItem(StorageKeys.accessToken, accessToken)
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    api.interceptors.response.use(null, (error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch(reset())
        history.push('/login')
      }
      return Promise.reject(error)
    })
  }

  if (isNil(refreshToken) || isEmpty(refreshToken)) {
    localStorage.removeItem(StorageKeys.refreshToken)
  } else {
    localStorage.setItem(StorageKeys.refreshToken, refreshToken)
  }

  if (isNil(deviceToken) || isEmpty(deviceToken)) {
    localStorage.removeItem(StorageKeys.deviceToken)
  } else {
    localStorage.setItem(StorageKeys.deviceToken, deviceToken)
  }

  if (isNil(contact)) {
    localStorage.removeItem(StorageKeys.contact)
  } else {
    localStorage.setItem(StorageKeys.contact, JSON.stringify(contact))
  }

  if (isNil(company)) {
    localStorage.removeItem(StorageKeys.company)
  } else {
    localStorage.setItem(StorageKeys.company, JSON.stringify(company))
  }

  if (isNil(role) || isEmpty(role)) {
    localStorage.removeItem(StorageKeys.role)
  } else {
    localStorage.setItem(StorageKeys.role, role)
  }

  if (isNil(claims)) {
    localStorage.removeItem(StorageKeys.claims)
  } else {
    localStorage.setItem(StorageKeys.claims, JSON.stringify(claims))
  }
}
