import * as React from 'react'
import PropTypes from 'prop-types'
import { DetailedAvatar } from '@context365/avatar'
import { Button } from '@context365/button'
import { Add } from '@context365/icons'
import { Modal } from '@context365/modals'
import { message } from 'antd'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { useMutation, useQuery } from 'react-query'
import { useTracking } from 'react-tracking'
import { useCheckbox } from 'reakit/Checkbox'
import * as api from '~/api'
import { getInitials } from '~/utils'
import useLiveCallback from './utils/useLiveCallback'

export default function InviteColleaguesModal({
  conversationId,
  visible,
  onClose,
}) {
  const { Track, trackEvent } = useTracking({
    component: 'InviteColleaguesModal',
    conversationId,
  })
  const [selectedIds, setSelectedIds] = React.useState([])

  function close() {
    setSelectedIds([])
    onClose()
  }

  function cancel() {
    trackEvent({ eventName: 'click', element: 'cancel' })
    close()
  }

  const { mutate: inviteColleagues, isLoading } = useMutation(
    () =>
      api.messages.inviteColleagues({
        conversationId,
        contactIDs: selectedIds,
      }),
    {
      onMutate: () => {
        trackEvent({ eventName: 'click', element: 'invite', selectedIds })
      },
      onSuccess: () => {
        message.success(
          `${
            selectedIds.length === 1 ? 'User has' : 'Users have'
          } been added to the conversation.`
        )
        close()
      },
    }
  )

  return (
    <Track>
      <Modal
        title="Invite Colleagues"
        visible={visible}
        footerRight={
          <>
            <Button status="secondary" onClick={cancel}>
              Cancel
            </Button>
            <Button
              variant="filled"
              disabled={selectedIds.length === 0 || isLoading}
              onClick={() => inviteColleagues()}
            >
              Send
            </Button>
          </>
        }
        onDismiss={cancel}
      >
        {/* Set a consistent width even when there's not enough content to fill it */}
        <div className="hidden md:block" style={{ width: 850 }} />
        <SelectColleagues
          conversationId={conversationId}
          selectedIds={selectedIds}
          onChange={setSelectedIds}
        />
      </Modal>
    </Track>
  )
}

InviteColleaguesModal.propTypes = {
  conversationId: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

function SelectColleagues({ conversationId, selectedIds, onChange }) {
  const { data: contacts, isLoading } = useQuery(
    ['colleagues-to-invite', conversationId],
    () => api.messages.getColleaguesToInvite(conversationId)
  )

  if (isLoading) {
    return <div className="py-10 text-center">Fetching your colleagues...</div>
  }

  if (isEmpty(contacts)) {
    return (
      <div className="py-10 text-center">
        There are no new colleagues that can be added to this conversation.
      </div>
    )
  }

  return (
    <div>
      <div className="type-body-semibold-md mb-3">
        Select the colleagues you&apos;d like to invite to the conversation.
      </div>
      <div className="flex flex-wrap gap-3">
        {contacts.map((contact) => (
          <Colleague
            key={contact.contactId}
            contactId={contact.contactId}
            contactName={contact.contactName}
            imageUrl={contact.imageUrl}
            selectedIds={selectedIds}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  )
}

function Colleague({
  contactId,
  contactName,
  imageUrl,
  selectedIds,
  onChange,
}) {
  const { trackEvent } = useTracking()

  const checkbox = useCheckbox({
    value: contactId,
    state: selectedIds,
    setState: useLiveCallback((state) => {
      trackEvent({
        eventName: 'click',
        element: 'colleague',
        contactId,
        selected: !checkbox.checked,
      })
      onChange(state)
    }),
  })

  return (
    <div
      {...checkbox}
      className={cx(
        'flex items-center gap-2 rounded-full border p-1 pr-2 cursor-pointer transition-colors',
        checkbox.checked
          ? 'border-secondary-5 bg-secondary-5'
          : 'bg-white hover:bg-secondary-2'
      )}
    >
      <DetailedAvatar
        initials={getInitials(contactName)}
        name={contactName}
        profileImageUrl={imageUrl}
      />
      <Add
        size={20}
        className={cx(
          'transition',
          checkbox.checked ? 'transform rotate-45' : 'text-secondary-100'
        )}
      />
    </div>
  )
}
