import PropTypes from 'prop-types'
import { Avatar } from 'antd'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { getInitials } from '~/utils'
import './ContactList.less'

const ContactList = ({ className, contacts }) => (
  <div className={cx('contact-list', className)}>
    {map(contacts, (contact) => (
      <div className="contact-list-contact" key={contact.contactId}>
        <Avatar
          size="small"
          src={isEmpty(contact.imageUrl) ? undefined : contact.imageUrl}
        >
          {getInitials(contact.contactName)}
        </Avatar>
        <span className="contact-list-contact-name">{contact.contactName}</span>
      </div>
    ))}
  </div>
)

ContactList.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.number.isRequired,
      imageUrl: PropTypes.string,
      contactName: PropTypes.string.isRequired,
    })
  ),
}

export default ContactList
