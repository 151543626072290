import * as Yup from 'yup'
import * as api from '~/api'
import {
  Form,
  InputField,
  SelectField,
  TextAreaField,
  UnsavedChangesAlert,
} from '~/components/Form'
import Loading from '~/components/Loading'
import { ProfilePictureForm } from '../forms/ProfilePictureForm'
import { useOnboardingQuery } from '../hooks'
import { useOnboardingContext } from '../OnboardingContext'
import {
  NextButton,
  OnboardingFooter,
  OnboardingLayout,
  OnboardingSteps,
} from './OnboardingLayout'

const schema = Yup.object().shape({
  imageUrl: Yup.string().nullable().url(),
  firstName: Yup.string().nullable().required().min(1),
  lastName: Yup.string().nullable().required().min(1),
  email: Yup.string().nullable().required().email(),
  jobTitle: Yup.string().nullable().required().min(1),
  jobLevelId: Yup.number().nullable().required(),
  jobFunctionId: Yup.number().nullable().required(),
  bio: Yup.string().nullable(),
})

export function ContactIdentification({ steps }) {
  const { options, onNext } = useOnboardingContext()
  const { answers, isLoading, submit } = useOnboardingQuery(
    ['contact-identification'],
    {
      getAnswers: () => api.onboarding.getContactIdentityAnswers(),
      saveAnswers: (_, answers) =>
        api.onboarding.saveContactIdentityAnswers(answers),
    }
  )

  if (isLoading) {
    return (
      <OnboardingLayout
        header={<OnboardingSteps steps={steps} />}
        body={
          <div className="flex items-center justify-center">
            <Loading />
          </div>
        }
      />
    )
  }

  return (
    <OnboardingLayout
      header={<OnboardingSteps steps={steps} />}
      body={
        <Form
          id="contact-identification"
          className="mt-12 onboarding-form"
          schema={schema}
          defaultValues={answers}
          onSubmit={submit}
          onSuccess={onNext}
        >
          <div className="type-header-md text-center mb-4">
            Enter your personal information
          </div>
          <ProfilePictureForm />
          <InputField name="firstName" label="First Name" />
          <InputField name="lastName" label="Last Name" />
          <InputField name="email" type="email" label="Email" />
          <InputField name="jobTitle" label="Job Title" />
          <SelectField
            name="jobLevelId"
            label="Job Level"
            options={options.jobLevels}
          />
          <SelectField
            name="jobFunctionId"
            label="Job Function"
            options={options.jobFunctions}
          />
          <TextAreaField name="bio" label="Bio" />
          <UnsavedChangesAlert />
        </Form>
      }
      footer={
        <OnboardingFooter
          rightContent={
            <NextButton type="submit" form="contact-identification" />
          }
        />
      }
    />
  )
}
