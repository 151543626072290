import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Steps } from 'antd'
import filter from 'lodash/filter'
import map from 'lodash/map'
import MissingFundDetails from './MissingFundDetails'
import MissingMarketingMaterials from './MissingMarketingMaterials'
import MissingReturns from './MissingReturns'

const { Step } = Steps

const steps = [
  {
    title: 'Fund Data',
    component: MissingFundDetails,
    isVisible: (fundFreshness) => fundFreshness.isAumMissing,
  },
  {
    title: 'Returns',
    component: MissingReturns,
    isVisible: (fundFreshness) =>
      fundFreshness.areMonthlyReturnsMissing ||
      fundFreshness.areTVPIReturnsMissing ||
      fundFreshness.areIRRReturnsMissing,
  },
  {
    title: 'Marketing Materials',
    isVisible: (fundFreshness) => fundFreshness.areMarketingMaterialsMissing,
    component: MissingMarketingMaterials,
  },
]

const MissingDetails = ({ fundId, fundFreshness, onComplete }) => {
  const [stepNumber, setStepNumber] = useState(0)

  const availableSteps = filter(steps, (step) => step.isVisible(fundFreshness))
  const step = availableSteps[stepNumber]
  const Component = step.component

  const onStepComplete = useCallback(() => {
    if (stepNumber === availableSteps.length - 1) {
      onComplete()
    } else {
      setStepNumber(stepNumber + 1)
    }
  }, [stepNumber, setStepNumber, availableSteps, onComplete])

  return (
    <div className="MissingDetails w-full grid grid-cols-12">
      <div className="MissingDetails-steps col-span-2 p-10">
        <Steps current={stepNumber} direction="vertical">
          {map(availableSteps, (step, i) => (
            <Step key={i} title={step.title} />
          ))}
        </Steps>
      </div>
      <div
        className="p-5 h-full col-span-10 space-between overflow-y-auto"
        style={{ height: '75vh' }}
      >
        <Component
          fundId={fundId}
          onNext={() => onStepComplete()}
          isLast={stepNumber === availableSteps.length - 1}
          {...fundFreshness}
        />
      </div>
    </div>
  )
}

MissingDetails.propTypes = {
  fundId: PropTypes.number.isRequired,
  fundFreshness: PropTypes.shape({
    isAumMissing: PropTypes.bool,
    areMarketingMaterialsMissing: PropTypes.bool,
    areMonthlyReturnsMissing: PropTypes.bool,
    missingMonthlyReturns: PropTypes.array,
    areTVPIReturnsMissing: PropTypes.bool,
    areIRRReturnsMissing: PropTypes.bool,
  }).isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default MissingDetails
