import { Button } from '@context365/button'
import cx from 'classnames'
import { Link } from 'react-router-dom'

const CardButton = ({ children, className, href, ...rest }) => {
  const as = href ? (href.startsWith('http') ? 'a' : Link) : 'div'
  const props = href
    ? href.startsWith('http')
      ? { href, ...rest }
      : { to: href, ...rest }
    : rest

  return (
    <Button
      as={as}
      className={cx(
        'cursor-pointer rounded type-body-regular-sm bg-white border p-3 hover:border-secondary-100 hover:bg-secondary-2 hover:shadow-0 transition duration-300 ease-in',
        !className?.includes('border') && 'border-white',
        className
      )}
      variant="none"
      {...props}
    >
      {children}
    </Button>
  )
}

export default CardButton
