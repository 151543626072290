import { useCallback, useEffect, useState } from 'react'
import { Button, Checkbox, Radio, Tooltip, message } from 'antd'
import isNil from 'lodash/isNil'
import set from 'lodash/set'
import numeral from 'numeral'
import { useSelector } from 'react-redux'
import { Prompt, useHistory } from 'react-router-dom'
import * as api from '~/api'
import { INVALID_URL } from '~/constants/validationMessages'
import { validURL } from '~/utils/helpers'
import CCUpload from '../CCUpload'
import FloatingInput from '../FloatingInput'
import FloatingSelect from '../FloatingSelect'
import FloatingTextArea from '../FloatingTextArea'
import Loading from '../Loading'
import DomicileSection from '../OnboardingComponents/DomicileSection'
import OnboardingAddressSection from '../OnboardingComponents/OnboardingAddressSection'
import './CompanyForm.less'

const CompanyForm = () => {
  const [company, setCompany] = useState({})
  const [spCategories, setSpCategories] = useState([])
  const [fundAttributes, setFundAttributes] = useState([])
  const [primaryInvCategories, setPrimaryInvCategories] = useState([])
  const [secondaryInvCategories, setSecondaryInvCategories] = useState([])
  const [investmentVehicles, setInvestmentVehicles] = useState([])
  const [loading, setLoading] = useState(false)
  const [canPost, setCanPost] = useState(false)
  const [canPostCompany, setCanPostCompany] = useState(false)
  const [contentChanged, setContentChanged] = useState(false)
  const isCFNAllocator = useSelector(
    (state) => state.auth.contact.isCFNAllocator
  )
  const role = useSelector((state) => state.auth.role)

  const history = useHistory()

  useEffect(() => {
    const getCompanyData = async () => {
      setLoading(true)
      try {
        const companyRaw = await api.settings.getCompanySettings()
        setCompany(companyRaw.data.result.company)
        setFundAttributes(companyRaw.data.result.referenceData.FundAttributes)
        setPrimaryInvCategories(
          companyRaw.data.result.referenceData.PrimaryInvestorCategory
        )
        setSecondaryInvCategories(
          companyRaw.data.result.referenceData.SecondaryInvestorCategory
        )
        setSpCategories(
          companyRaw.data.result.referenceData.ServiceProviderCategory
        )
        setInvestmentVehicles(
          companyRaw.data.result.referenceData.InvestmentVehicle
        )
      } catch (error) {
        message.error(
          'There was an error while attempting to fetch company data'
        )
      } finally {
        setLoading(false)
      }
    }

    getCompanyData()
  }, [])

  const inputFieldChanged = useCallback(
    (name, e) => {
      setContentChanged(true)
      let value = !isNil(e.target) ? e.target.value : e
      if (
        name === 'companyAllocator.portfolioSize' ||
        name === 'companyManager.firmAum' ||
        name === 'companyManager.cik' ||
        name === 'companyManager.crd'
      )
        value = numeral(value).format('0')

      set(company, name, value)
      setCompany({ ...company })
    },
    [company]
  )

  const textAreaChanged = useCallback(
    (e, name) => {
      setContentChanged(true)
      set(company, name, e)
      setCompany({ ...company })
    },
    [company]
  )

  const dropdownFieldChanged = useCallback(
    (e, name) => {
      setContentChanged(true)
      set(company, name, e)
      setCompany({ ...company })
    },
    [company]
  )

  const checkboxFieldChanged = useCallback(
    (name, e) => {
      setContentChanged(true)
      if (isNil(name)) return
      let value
      if (name === 'companyAllocator.secondaryInvestorCategory') {
        if (e.length > 2) return
        value = e.join(',')
      }

      if (
        name === 'companyAllocator.investmentVehicle' ||
        name === 'companyManager.fundAttributes'
      ) {
        value = e.join(',')
      }

      set(company, name, value)
      setCompany({ ...company })
    },
    [company]
  )

  const imageChanged = useCallback(
    (base64Image) => {
      setContentChanged(true)
      company.imageURL = base64Image
      company.imageBase64 = base64Image
      setCompany({ ...company })
    },
    [company]
  )

  const addressChanged = useCallback(
    (address) => {
      setContentChanged(true)
      company.address = address
      setCompany({ ...company })
    },
    [company]
  )

  const domicileSectionChanged = useCallback(
    (val) => {
      setContentChanged(true)
      company.companyAllocator.fundDomicileInterest = val
      setCompany({ ...company })
    },
    [company]
  )

  const firstLossCapitalChanged = useCallback(
    (e) => {
      setContentChanged(true)
      company.companyAllocator.firstLossCapital = e.target.value
      setCompany({ ...company })
    },
    [company]
  )

  const onCompanySaved = useCallback(async () => {
    try {
      setLoading(true)
      await api.settings.updateCompany(company)
      message.success('Settings have been successfully saved')
    } catch {
      message.error('There was an error while attempting to save')
    } finally {
      setLoading(false)
      setContentChanged(false)
    }
  }, [company])

  const onCancel = useCallback(() => {
    history.goBack()
  }, [history])

  const isValid = (e) => !(e === -1 || e === '' || isNil(e))

  const getServiceProviderFormSection = () => (
    <FloatingSelect
      name="Service Provider Category *"
      id="companyServiceProvider.serviceProviderCategory"
      onChange={dropdownFieldChanged}
      value={company.companyServiceProvider.serviceProviderCategory}
      key="serviceProviderCategory"
      options={spCategories.map((x) => ({
        value: x,
        text: x,
      }))}
    />
  )

  const getManagerFormSection = () => (
    <>
      <FloatingInput
        onChange={inputFieldChanged}
        value={numeral(company.companyManager.firmAum).format('0,0[.]00')}
        key="FirmAUM"
        name="Firm AUM *"
        id="companyManager.firmAum"
        handleChange={true}
        validationMessage="Enter the unabbreviated number. Must be 0 or greater than $1,000,000."
        isValid={
          parseInt(company.companyManager.firmAum) === 0 ||
          company.companyManager.firmAum >= 1000000
        }
      />
      <FloatingInput
        onChange={inputFieldChanged}
        value={numeral(company.companyManager.crd).format('0,0[.]00')}
        key="CRD"
        name="CRD"
        id="companyManager.crd"
        handleChange={true}
      />
      <FloatingInput
        onChange={inputFieldChanged}
        value={company.companyManager.cik}
        key="CIK"
        name="CIK"
        id="companyManager.cik"
        handleChange={true}
      />
      <div className="cc-generic-form-checkbox-group">
        <span className="cc-heading6 cc-generic-form-checkbox-header">
          Fund Attributes
        </span>
        <Checkbox.Group
          value={
            isNil(company.companyManager.fundAttributes)
              ? []
              : company.companyManager.fundAttributes.split(',')
          }
          onChange={checkboxFieldChanged.bind(
            null,
            'companyManager.fundAttributes'
          )}
          options={fundAttributes.map((x) => ({
            label: x,
            value: x,
          }))}
        />
      </div>
    </>
  )

  const getAllocatorFormSection = () =>
    company.categoryId === 2 || isCFNAllocator ? (
      <>
        <FloatingSelect
          name="Primary Investor Category *"
          id="companyAllocator.primaryInvestorCategory"
          onChange={dropdownFieldChanged}
          value={company.companyAllocator?.primaryInvestorCategory}
          key="PrimaryInvestorCategory"
          options={primaryInvCategories.map((x) => ({
            value: x,
            text: x,
          }))}
        />
        <div className="cc-generic-form-checkbox-group">
          <span className="cc-heading6 cc-generic-form-checkbox-header">
            Secondary Investor Category
          </span>
          <Checkbox.Group
            value={
              isNil(company.companyAllocator?.secondaryInvestorCategory)
                ? []
                : company.companyAllocator?.secondaryInvestorCategory
                    .split(',')
                    .slice(0, 2)
            }
            onChange={checkboxFieldChanged.bind(
              null,
              'companyAllocator.secondaryInvestorCategory'
            )}
            options={secondaryInvCategories.map((x) => ({
              label: x,
              value: x,
            }))}
          />
        </div>
        <FloatingInput
          name="Portfolio Size *"
          id="companyAllocator.portfolioSize"
          onChange={inputFieldChanged}
          value={numeral(company.companyAllocator?.portfolioSize).format(0, 0)}
          key="PortfolioSize"
          handleChange={true}
        />

        <DomicileSection
          onChange={domicileSectionChanged}
          value={company.companyAllocator?.fundDomicileInterest}
          isRequired={true}
        />

        <div className="cc-generic-form-checkbox-group">
          <span className="cc-heading6 cc-generic-form-checkbox-header">
            Investment Vehicle Interest
          </span>
          <Checkbox.Group
            value={
              isNil(company.companyAllocator?.investmentVehicle)
                ? []
                : company.companyAllocator?.investmentVehicle.split(',')
            }
            onChange={checkboxFieldChanged.bind(
              null,
              'companyAllocator.investmentVehicle'
            )}
            options={investmentVehicles.map((x) => ({
              label: x,
              value: x,
            }))}
          />
        </div>
        <div className="cc-generic-form-radio-group">
          <span className="cc-heading6 cc-generic-form-radio-header">
            First Loss Capital *
          </span>

          <div className="cc-body-text cc-generic-form-radio-header-capital">
            Do you only provide first loss capital?
          </div>
          <Radio.Group
            onChange={firstLossCapitalChanged}
            value={company.companyAllocator?.firstLossCapital === true}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>
      </>
    ) : null

  const getSectionByCategoryId = () => {
    switch (company.categoryId) {
      case 1:
        return getManagerFormSection()
      case 2:
      case 6:
        return getAllocatorFormSection()
      case 3:
        return getServiceProviderFormSection()
      default:
        return null
    }
  }

  useEffect(() => {
    setCanPostCompany(
      isValid(company.companyDescription) &&
        ((role.toLowerCase() !== 'allocator' && role.toLowerCase() !== 'cfn') ||
          isValid(company.companyAllocator?.fundDomicileInterest))
    )
  }, [setCanPostCompany, company, role])

  return (
    <Loading spinning={loading}>
      <Prompt
        message="Are you sure you want to leave without saving?"
        when={contentChanged}
      />
      <div className="form-container">
        <div className="form-container-header">
          <p className="form-container-title">Company Settings</p>
          <p className="form-container-description">
            Edit your company information
          </p>
        </div>
        <div className="form-section">
          <div className="form-column-grouping cc-heading5">
            Company Information
          </div>
          <div className="form-column-inputs">
            <FloatingInput
              onChange={inputFieldChanged}
              autoComplete="off"
              value={company.companyName}
              isValid={isValid(company.companyName)}
              handleChange={true}
              name="Company Name *"
              id="name"
            />
            <FloatingInput
              onChange={inputFieldChanged}
              value={company.companyWebsite}
              handleChange={true}
              name="Company Website"
              id="companyWebsite"
              validationMessage={INVALID_URL}
              isValid={validURL(company.companyWebsite)}
            />
            <FloatingTextArea
              onChange={textAreaChanged}
              value={company.companyDescription}
              isValid={isValid(company.companyDescription)}
              name="CompanyDescription *"
              id="companyDescription"
              handleChange={true}
            />
            {getSectionByCategoryId()}
          </div>

          <div className="form-column-upload">
            <CCUpload
              placeholder="Upload Logo"
              onChange={imageChanged}
              imageUrl={company.imageURL}
            />
          </div>
        </div>
        <div className="form-section">
          <div className="form-column-grouping cc-heading5">Address</div>
          <div className="form-column-inputs">
            {isNil(company.address) ? null : (
              <OnboardingAddressSection
                value={company.address}
                inputNamePrefix="Company."
                onChange={addressChanged}
                enableNext={(enabled) => {
                  setCanPost(enabled)
                }}
              />
            )}
          </div>
          <div className="form-column-upload" />
        </div>

        <div className="form-section-submit">
          <div className="empty-column-left" />
          <div className="form-section-submit-buttons">
            <Button
              type="default"
              className="form-section-btn-cancel"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Tooltip
              title={
                (!canPost || !canPostCompany) &&
                'Required fields are not completed.'
              }
            >
              <div>
                <Button
                  type="primary"
                  className="form-section-btn-save"
                  onClick={onCompanySaved}
                  disabled={
                    !canPost ||
                    !validURL(company.companyWebsite) ||
                    !canPostCompany
                  }
                >
                  Save
                </Button>
              </div>
            </Tooltip>
          </div>
          <div className="empty-column-right" />
        </div>
      </div>
    </Loading>
  )
}

CompanyForm.propTypes = {}

export default CompanyForm
