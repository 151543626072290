import { useFlags } from 'launchdarkly-react-client-sdk'
import ProfileViewer from '~/components/ProfileViewer'

const ProfileViewerProvider = ({ children, ...props }) => {
  const { contentProfiles } = useFlags()
  if (!contentProfiles) {
    return children
  }

  return <ProfileViewer {...props}>{children}</ProfileViewer>
}

export default ProfileViewerProvider
