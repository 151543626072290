import PropTypes from 'prop-types'
import get from 'lodash/fp/get'
import AddToListModal from './AddToListModal'

export default function AddFundToListModal({ visible, funds, onClose }) {
  return (
    <AddToListModal
      visible={visible}
      onClose={onClose}
      targetItems={funds}
      getTargetId={get('fundId')}
      getTargetName={get('name')}
      listLinkName="toFundId"
      isTogglingListDisabled={(list, isChecked) =>
        funds.length > 1 && isChecked
      }
    />
  )
}

AddFundToListModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  funds: PropTypes.arrayOf(
    PropTypes.shape({
      fundId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
}
