import { Modal, Takeover } from '@context365/modals'
import { useNavigate } from 'react-router-dom-v5-compat'
import ContactSettings from './ContactSettings'

const ContactSettingsTakeover = () => {
  const navigate = useNavigate()
  return (
    <Takeover.Container visible={true} tabIndex={0}>
      <Modal.Header className="p-3 md:p-6">
        Fill out your user profile
      </Modal.Header>
      <Modal.Body>
        <ContactSettings onFinish={() => navigate('/community/directory')} />
      </Modal.Body>
    </Takeover.Container>
  )
}

export default ContactSettingsTakeover
