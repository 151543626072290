import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { ArrowForward } from '@context365/icons'
import { Menu, MenuButton } from '@context365/menu'
import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom-v5-compat'
import * as api from '~/api'
import useAuth from '~/hooks/useAuth'
import '../ApplyButton.css'

const ONE_HOUR_IN_MILLISECONDS = 1000 * 60 * 60

const getApplicationUrl = (campaignId, applicationId, wasSent) => {
  const params = new URLSearchParams()
  if (applicationId) {
    params.set('applicationId', applicationId)
  }
  if (wasSent) {
    params.set('isSent', true)
  }
  return `/workspace/${campaignId}/pipeline?${params}`
}

const ViewApplicationButton = ({ campaignEntity }) => {
  const { company } = useAuth()

  const { data } = useQuery(
    ['myCampaigns', company.companyId],
    () => api.campaigns.getMyCampaigns(company.companyId, { pageSize: 25 }),
    {
      staleTime: ONE_HOUR_IN_MILLISECONDS,
      cacheTime: ONE_HOUR_IN_MILLISECONDS,
    }
  )

  const campaigns = data?.results

  const appliedCampaigns = filter(
    map(campaigns, (c) => ({
      ...c,
      application: find(
        campaignEntity.campaignApplications,
        (ca) => ca.campaignId === c.campaignId
      ),
    })),
    (c) => !!c.application
  )

  if (appliedCampaigns?.length > 1) {
    return (
      <Menu
        trigger={
          <MenuButton className="interested-button" variant="outlined">
            View In Pipeline
          </MenuButton>
        }
      >
        {map(appliedCampaigns, (campaign) => (
          <Menu.Item
            key={campaign.campaignId}
            as={Link}
            to={getApplicationUrl(
              campaign.campaignId,
              campaign.application.applicationId,
              campaign.application.wasSent
            )}
          >
            View in {campaign.name} pipeline
          </Menu.Item>
        ))}
      </Menu>
    )
  } else if (appliedCampaigns?.length === 1) {
    const appliedCampaign = appliedCampaigns[0]
    return (
      <Button
        className="interested-button"
        variant="outlined"
        iconRight={<ArrowForward />}
        as={Link}
        to={getApplicationUrl(
          appliedCampaign.campaignId,
          appliedCampaign.application.applicationId,
          appliedCampaign.application.wasSent
        )}
      >
        View In Pipeline
      </Button>
    )
  } else {
    return null
  }
}

ViewApplicationButton.propTypes = {
  campaignEntity: PropTypes.shape({
    campaignId: PropTypes.number.isRequired,
    campaignName: PropTypes.string.isRequired,
    campaignApplications: PropTypes.arrayOf(
      PropTypes.shape({
        campaignId: PropTypes.number.isRequired,
        applicationId: PropTypes.number.isRequired,
        wasSent: PropTypes.bool.isRequired,
      })
    ).isRequired,
  }).isRequired,
}

export default ViewApplicationButton
