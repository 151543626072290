import { useCallback, useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'
import trim from 'lodash/trim'
import moment from 'moment'
import qs from 'qs'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { changeCompany, reset } from '~/actions/auth'
import Loading from '~/components/Loading'
import { FeatureFlags, StorageKeys } from '~/config'
import { isAuthenticated } from '~/selectors/auth'
import { generateFlagsIdentity } from '~/utils/flags'
import './SSO.less'

const SSOContainer = () => {
  const dispatch = useDispatch()

  const ldClient = useLDClient()

  const authenticated = useSelector(isAuthenticated)

  const { search } = useLocation()

  const [, setLoading] = useState(false)
  const [hasResetStore, setHasResetStore] = useState(false)
  const [hasSwitched, setHasSwitched] = useState(false)
  const [hasOnBoarded, setHasOnBoarded] = useState(true)
  const [subscriptionAgreementApproved, setSubscriptionAgreementApproved] =
    useState(true)

  const { token } = qs.parse(search, { ignoreQueryPrefix: true })

  const resetStore = useCallback(() => {
    localStorage.setItem(StorageKeys.logout, 'false')
    dispatch(reset())
    setHasResetStore(true)
  }, [dispatch])

  const handleSwitch = useCallback(
    (companyId, token) => {
      dispatch(changeCompany(companyId, token)).then(({ payload }) => {
        if (FeatureFlags.enabled && ldClient) {
          const { claims, contact, company } = payload
          const identity = generateFlagsIdentity(claims, contact, company)
          ldClient.identify(identity)
        }
        setHasSwitched(true)
      })
    },
    [dispatch, ldClient]
  )

  useEffect(() => {
    resetStore()

    setLoading(true)

    if (isNil(token) || isEmpty(trim(token))) {
      setLoading(false)
      return
    }

    if (hasResetStore) {
      let claims = {}

      try {
        claims = jwtDecode(token)
      } catch (e) {
        setLoading(false)
        return
      }

      const { exp, company_id, HasOnBoarded, SubscriptionAgreementApproved } =
        claims

      if (
        moment.utc((exp || 0) * 1000).isBefore(moment.utc()) ||
        !/^\d+$/g.test(company_id)
      ) {
        setLoading(false)
        return
      }

      setHasOnBoarded(HasOnBoarded === 'True')
      setSubscriptionAgreementApproved(SubscriptionAgreementApproved === 'True')

      const companyId = parseInt(company_id, 10)
      if (isNumber(companyId)) {
        handleSwitch(companyId, token)
      }
    }
  }, [token, hasResetStore, resetStore, handleSwitch, search])

  if (hasResetStore && hasSwitched && authenticated) {
    if (!hasOnBoarded) {
      const onboardingLink = '/onboard'
      const agreementLink = '/agreement'
      if (subscriptionAgreementApproved && !search.includes(onboardingLink)) {
        return <Redirect to={onboardingLink} />
      } else if (
        !subscriptionAgreementApproved &&
        !search.includes(agreementLink)
      ) {
        return <Redirect to={agreementLink} />
      }
    }
    return <Redirect to="/" />
  } else {
    return (
      <Loading>
        <div>Signing in ...</div>
      </Loading>
    )
  }
}

export default SSOContainer
