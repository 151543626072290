import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Search } from '@context365/forms'
import { ArrowDownward, ArrowUpward } from '@context365/icons'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import times from 'lodash/times'
import { useParams } from 'react-router-dom-v5-compat'
import { useTracking } from 'react-tracking'
import { useVirtual } from 'react-virtual'
import SimpleBar from 'simplebar-react'
import * as yup from 'yup'
import useSearchParams from '~/hooks/useSearchParams'
import ConversationListItem from './ConversationListItem'

export default function ConversationList({ conversations }) {
  const { trackEvent, Track } = useTracking({ component: 'ConversationList' })
  const { conversationId } = useParams()

  const [{ search = '', sortDir = 'desc' }, setSearchParams] = useSearchParams(
    yup.object().shape({
      search: yup.string(),
      sortDir: yup.string().oneOf(['asc', 'desc']),
    })
  )

  const conversationsToDisplay = React.useMemo(() => {
    if (isEmpty(conversations)) {
      return []
    }

    const searchPattern = new RegExp(search, 'i')
    const filteredConversations = isEmpty(search)
      ? conversations
      : filter(
          conversations,
          (conversation) =>
            conversation.title?.match(searchPattern) ||
            conversation.contacts.some((contact) =>
              contact.contactName?.match(searchPattern)
            )
        )

    return orderBy(
      filteredConversations,
      (conversation) => {
        const sortDate =
          conversation.sortDate ?? conversation.lastMessage?.timeSent
        return sortDate ? new Date(sortDate) : -1
      },
      sortDir
    )
  }, [conversations, search, sortDir])

  const parentRef = React.useRef()
  const rowVirtualizer = useVirtual({
    size: conversationsToDisplay.length,
    parentRef,
    estimateSize: React.useCallback(() => 112, []), //  h-28
    overscan: 5,
  })

  const { scrollToIndex } = rowVirtualizer
  React.useEffect(() => {
    if (!conversationId || isEmpty(conversationsToDisplay)) {
      return
    }

    const selectedId = parseInt(conversationId, 10)
    const index = conversationsToDisplay.findIndex(
      (conversation) => conversation.conversationID === selectedId
    )

    if (index === -1) {
      return
    }

    scrollToIndex(index, { align: 'center' })
  }, [conversationsToDisplay, conversationId, scrollToIndex])

  return (
    <Track>
      <div className="flex flex-col flex-shrink-0 bg-white w-full h-full md:border-t md:border-l md:w-1/4 lg:w-1/5">
        <div className="p-3 flex-shrink-0">
          <Search
            placeholder="Search conversations"
            onSearch={(search) => {
              trackEvent({
                eventName: 'click',
                element: 'Search conversations',
              })
              setSearchParams({ search })
            }}
          />
          <Button
            className="mt-2"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            variant="link"
            status="secondary"
            iconRight={sortDir === 'desc' ? <ArrowDownward /> : <ArrowUpward />}
            onClick={() => {
              const newSortDir = sortDir === 'desc' ? 'asc' : 'desc'
              trackEvent({
                eventName: 'click',
                element: 'Sort conversations',
                sortDir: newSortDir,
              })
              setSearchParams({ sortDir: newSortDir })
            }}
          >
            Most Recent
          </Button>
        </div>
        <SimpleBar
          className="flex-1 h-full overflow-y-auto"
          scrollableNodeProps={{
            ref: parentRef,
            style: { scrollBehavior: 'smooth' },
          }}
        >
          <ul
            className="list-none p-0 m-0 divide-y divide-grey-200"
            style={{ height: rowVirtualizer.totalSize, position: 'relative' }}
          >
            {rowVirtualizer.virtualItems.map((virtualRow) => {
              const conversation = conversationsToDisplay[virtualRow.index]
              return (
                <li
                  key={virtualRow.index}
                  className="absolute top-0 left-0 w-full"
                  style={{
                    height: virtualRow.size,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  <ConversationListItem
                    conversationId={conversation.conversationID}
                    campaigns={conversation.campaigns}
                    contacts={conversation.contacts}
                    title={conversation.title}
                    lastMessage={conversation.lastMessage?.body}
                    lastActivityDate={conversation.sortDate}
                    hasUnreadMessages={conversation.totalUnreadMessageCount > 0}
                  />
                </li>
              )
            })}
          </ul>
        </SimpleBar>
      </div>
    </Track>
  )
}

ConversationList.propTypes = {
  conversations: PropTypes.arrayOf(
    PropTypes.shape({
      conversationID: PropTypes.number.isRequired,
      contacts: PropTypes.array.isRequired,
      title: PropTypes.string,
      lastMessage: PropTypes.shape({
        body: PropTypes.string,
      }),
      sortDate: PropTypes.string,
      totalUnreadMessageCount: PropTypes.number,
    })
  ),
}

function Loading() {
  return (
    <div className="flex flex-col flex-shrink-0 bg-white w-full h-full md:w-1/4 lg:w-1/5">
      <div className="p-3 flex-shrink-0">
        <Search placeholder="Search conversations" disabled />
        <Button
          className="mt-2"
          style={{ paddingLeft: 0, paddingRight: 0 }}
          variant="link"
          status="secondary"
          iconRight={<ArrowDownward />}
          disabled
        >
          Most Recent
        </Button>
      </div>
      <div className="flex-1 overflow-hidden animate-pulse">
        {times(15, (i) => (
          <ConversationListItem.Loading key={i} />
        ))}
      </div>
    </div>
  )
}

ConversationList.Loading = Loading
