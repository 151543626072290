import PropTypes from 'prop-types'
import cx from 'classnames'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/isEmpty'
import Select from 'react-select'

export function ChartControls({ children }) {
  return <div className="flex space-x-4 mb-2">{children}</div>
}

export function ChartContainer({ className, children }) {
  return <div className={cx(className, 'w-max mx-auto')}>{children}</div>
}

export function BenchmarkSelect({
  benchmarks,
  id,
  label,
  indicatorColor,
  selectedId,
  onChange,
}) {
  const findBenchmark = (id) => benchmarks?.find((b) => b.fundIndexId === id)
  const loading = isEmpty(benchmarks)

  return (
    <div className="w-80">
      <label htmlFor={id} className="type-body-semibold-xs">
        {label}
      </label>
      <div
        className="rounded p-0.5"
        style={{ backgroundColor: indicatorColor }}
      >
        <Select
          isClearable
          inputId={id}
          placeholder={loading ? 'Loading...' : undefined}
          isDisabled={loading}
          options={benchmarks}
          getOptionValue={get('fundIndexId')}
          getOptionLabel={get('name')}
          value={findBenchmark(selectedId)}
          onChange={(option) => onChange(option?.fundIndexId)}
        />
      </div>
    </div>
  )
}

BenchmarkSelect.propTypes = {
  benchmarks: PropTypes.array,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  indicatorColor: PropTypes.string,
  selectedId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

const timeFrameOptions = [
  { value: 12, label: '12 months' },
  { value: 24, label: '24 months' },
  { value: 36, label: '36 months' },
  { value: 48, label: '48 months' },
  { value: 60, label: '60 months' },
]

export function TimeSpanSelect({ value, onChange }) {
  return (
    <div className="w-40">
      <label htmlFor="time-span-select" className="type-body-semibold-xs">
        Time Span
      </label>
      <div className="rounded p-0.5">
        <Select
          inputId="time-span-select"
          options={timeFrameOptions}
          value={timeFrameOptions.find((o) => o.value === value)}
          onChange={(option) => onChange(option?.value)}
        />
      </div>
    </div>
  )
}
