import { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { acceptSummitMeetingAllocator } from '~/actions/contextMeetings'
import { getFormatedMeetingTime, isElevated } from '~/utils/meetingActions'
import Loading from '../Loading'
import SelectOnlineMeetingDateTime from '../SelectOnlineMeetingDateTime'

const AcceptMeetingComponent = ({
  visible,
  closeModal,
  meetingType,
  company,
  categoryName,
  meetingId,
  eventId,
  onAccept,
  meetingDateTime,
  participants,
  toParticipants,
  isOptimized = false,
  isVirtualSummit = false,
  timezone,
}) => {
  const [acceptingInProgress, setAcceptingInProgress] = useState(false)
  const [selectedDateTime, setSelectedDateTime] = useState({})
  const [duration, setDuration] = useState(30)
  const [selectedHour, setSelectedHour] = useState({})
  const userRole = useSelector((state) => state.auth.role)

  const handleModalCancel = () => {
    setSelectedDateTime({})
    closeModal()
  }

  const handleAcceptMethod = () => {
    setAcceptingInProgress(true)
    acceptSummitMeetingAllocator(
      meetingId,
      selectedDateTime.startDateTime,
      duration,
      eventId
    )
      .then(() => {
        message.success('The meeting has been accepted.')
        onAccept(selectedDateTime.startDateTime)
      })
      .catch((err) => {
        message.warn(
          get(err.response, 'data.message', 'Could not accept meeting.')
        )
      })
      .finally(() => {
        setAcceptingInProgress(false)
        handleModalCancel()
      })
  }
  const onDurationChanged = (value) => {
    setDuration(value)
  }

  const onTimeChanged = (changed, newDate) => {
    if (changed === 'date') {
      setSelectedHour({})
    }
    if (changed === 'hour') {
      setSelectedHour(newDate)
    }
    if (!isNil(newDate)) {
      setSelectedDateTime({
        startDateTime: newDate.startTime,
        endDateTime: newDate.endTime,
      })
    }
  }
  const haveToChooseMeetingTime =
    isNil(meetingDateTime) &&
    ((!isVirtualSummit && isOptimized) ||
      (isVirtualSummit && isElevated(userRole, categoryName)))

  return (
    <Modal
      destroyOnClose
      className="cc-modal-accept"
      title="Accept Meeting Request"
      visible={visible}
      okText="Accept Meeting"
      onOk={handleAcceptMethod} // needs to be changed in order to actually accept the request
      onCancel={handleModalCancel}
      okButtonProps={{
        loading: acceptingInProgress,
        disabled: haveToChooseMeetingTime
          ? isEmpty(selectedDateTime) || isEmpty(selectedHour)
          : false,
        style: { backgroundColor: '#24C477', borderStyle: 'none' },
      }}
      cancelButtonProps={{ disabled: false }}
      width="758px"
      bodyStyle={{ padding: '30px' }}
    >
      {haveToChooseMeetingTime ? (
        <Loading spinning={acceptingInProgress}>
          <p>
            Are you sure you want to accept the {meetingType} Meeting with{' '}
            {company}?
          </p>
          <SelectOnlineMeetingDateTime
            onTimeChanged={onTimeChanged}
            onDurationChanged={onDurationChanged}
            toParticipants={toParticipants}
            participants={participants}
            eventId={eventId}
            physicalSummit={!isVirtualSummit && !isNil(eventId)}
            defaultTimezone={timezone}
            meetingId={meetingId}
          />
        </Loading>
      ) : (
        <p>
          Are you sure you want to accept the {meetingType} Meeting with{' '}
          {company}
          {moment(meetingDateTime).isValid() &&
          !isNil(meetingDateTime) &&
          (isOptimized || isVirtualSummit)
            ? ` on
           ${getFormatedMeetingTime(
             timezone,
             isVirtualSummit,
             meetingDateTime,
             'MMMM D, YYYY [at] h:mm A'
           )}?`
            : '?'}
        </p>
      )}
    </Modal>
  )
}

AcceptMeetingComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  meetingType: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  meetingId: PropTypes.number.isRequired,
  onAccept: PropTypes.func.isRequired,
  eventId: PropTypes.number,
  meetingDateTime: PropTypes.string,
  participants: PropTypes.array.isRequired,
  toParticipants: PropTypes.array.isRequired,
  categoryName: PropTypes.string,
  isOptimized: PropTypes.bool,
  isVirtualSummit: PropTypes.bool,
  timezone: PropTypes.string,
}

export default AcceptMeetingComponent
