import PropTypes from 'prop-types'
import {
  Checkbox,
  CheckboxGroup,
  FormLabel,
  FormMessage,
} from '@context365/forms'
import cx from 'classnames'
import { useController } from 'react-hook-form'
import { useFieldSchema } from '../Form'
import {
  getLabel,
  getValue,
  optionShape,
  useOptionColumns,
} from './option-utils'

export function CheckboxGroupField({
  className,
  name,
  label,
  helpMessage,
  options,
  columns,
  ...props
}) {
  const {
    field: { value = [], ...field },
    fieldState: { error },
  } = useController({ name })
  const rules = useFieldSchema(name, label)

  return (
    <CheckboxGroup
      as="fieldset"
      className={cx(className, 'checkbox-group')}
      {...props}
      {...field}
      selected={value}
    >
      {label && (
        <FormLabel as="legend" required={rules.required}>
          {label}
        </FormLabel>
      )}
      <FormMessage helpMessage={helpMessage} errorMessage={error?.message} />
      <div
        className="checkbox-group__options"
        style={{ columns: useOptionColumns({ options, columns }) }}
      >
        {options.map((option) => (
          <Checkbox
            className="block"
            key={getValue(option)}
            value={getValue(option)}
            label={getLabel(option)}
            disabled={
              value.length >= rules.max && !value.includes(getValue(option))
            }
          />
        ))}
      </div>
    </CheckboxGroup>
  )
}

CheckboxGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpMessage: PropTypes.string,
  options: PropTypes.arrayOf(optionShape).isRequired,
  columns: PropTypes.number,
}
