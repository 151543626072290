import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Checkbox, Divider } from 'antd'
import cx from 'classnames'
import take from 'lodash/take'
import '../DiscoverFilters.less'

const Spacer = () => <div style={{ height: 4 }} />
const SHOW_MORE_THRESHOLD = 4

const CheckboxGroupFilter = ({
  className,
  style,
  id,
  label,
  group,
  options,
  value = [],
  showClearAll = false,
  onChange,
}) => {
  const handleChange = (selectedValues) =>
    onChange({ value: selectedValues, id, group, label, type: 'Select' })
  const handleClearAll = () =>
    onChange({ value: [], id, group, label, type: 'Select' })
  const [showingAll, setShowingAll] = useState(false)
  const [showingLess, setShowingLess] = useState(false)

  let visibleOptions = options
  const needsShowAll =
    !showingAll && options && options.length > SHOW_MORE_THRESHOLD

  const needsShowLess = showingAll && options.length > SHOW_MORE_THRESHOLD

  if (needsShowAll) {
    visibleOptions = take(options, SHOW_MORE_THRESHOLD - 1)
  }

  showClearAll = showClearAll && value.length > 0

  const toggleOptionVisibility = () => {
    setShowingAll(!showingAll)
    setShowingLess(!showingLess)
  }

  return (
    <>
      <Checkbox.Group
        className={cx(className, 'DiscoverFilters-FilterCheckbox')}
        style={style}
        name={label}
        options={visibleOptions}
        value={value}
        onChange={handleChange}
      />

      {/*Only need spaces after checkboxes if we're going to display the 'Show All' or 'Clear' options*/}
      {(needsShowAll || showClearAll || needsShowLess) && <Spacer />}

      {needsShowAll && (
        <Button variant="link" size="small" onClick={toggleOptionVisibility}>
          See All
        </Button>
      )}
      {needsShowLess && (
        <Button variant="link" size="small" onClick={toggleOptionVisibility}>
          Show Less
        </Button>
      )}

      {/*Put divider in between options if we're showing both*/}
      {(needsShowAll || needsShowLess) && showClearAll && (
        <Divider type="vertical" />
      )}

      {showClearAll && (
        <Button
          variant="link"
          status="error"
          size="small"
          onClick={handleClearAll}
        >
          Clear
        </Button>
      )}
    </>
  )
}

CheckboxGroupFilter.propTypes = {
  style: PropTypes.object,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      }),
    ])
  ).isRequired,
  value: PropTypes.array,
  showClearAll: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default CheckboxGroupFilter
