import PropTypes from 'prop-types'
import { Confirmation } from '@context365/modals'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useTracking } from 'react-tracking'
import { useMessagesContext } from './context'
import { useLeaveConversation } from './queries'

export default function LeaveConversationConfirmation({
  conversationId,
  visible,
  onClose,
}) {
  const { trackEvent } = useTracking({
    component: 'LeaveConversationConfirmation',
    conversationId,
  })
  const navigate = useNavigate()
  const { baseUrl } = useMessagesContext()

  const { leaveConversation } = useLeaveConversation(conversationId, {
    onSuccess: () => {
      message.success('You have left the conversation.')
      navigate(baseUrl, { replace: true })
      onClose()
    },
  })

  return (
    <Confirmation
      visible={visible}
      title="Leave Conversation"
      message="Are you sure you want to leave the conversation?"
      destructive
      confirmText="Leave"
      onCancel={() => {
        trackEvent({ eventName: 'click', element: 'cancel' })
        onClose()
      }}
      onConfirm={() => {
        trackEvent({ eventName: 'click', element: 'Leave' })
        leaveConversation()
      }}
    />
  )
}

LeaveConversationConfirmation.propTypes = {
  conversationId: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
