import PropTypes from 'prop-types'

const MissingSection = ({ title, children }) => (
  <div className="pb-4">
    <div className="text-primary-100 type-body-semibold-md pb-4">
      {title}
      <span style={{ color: 'red' }}>{' *'}</span>
    </div>
    {children}
  </div>
)

MissingSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default MissingSection
