import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@context365/alert'
import { Button } from '@context365/button'
import { ChevronLeft } from '@context365/icons'
import isEmpty from 'lodash/isEmpty'
import ContextForm from '~/components/ContextForm/ContextForm'
import { useMandateWizardContext } from '../MandateWizardContext'

const Step = ({
  title,
  alertMessage,
  questions,
  answers,
  isLast = false,
  isFirst = false,
  onSetAnswers,
  onNextStep,
  onPrevStep,
}) => {
  const {
    handleSubmit,
    invalidAnswers,
    setInvalidAnswers,
    isEdit,
    stepNumbers,
    requiredQuestionsForApplication,
    setRequiredQuestionsForApplication,
  } = useMandateWizardContext()

  const buttonComponent = () => {
    const disabled = !isEmpty(
      invalidAnswers.filter((a) => stepNumbers.includes(a.stepNumber))
    )
    return (
      <div className="flex justify-between w-full">
        <div>
          {!isFirst && (
            <Button
              variant="link"
              iconLeft={<ChevronLeft />}
              onClick={onPrevStep}
            >
              Back
            </Button>
          )}
        </div>
        <div>
          {isLast ? (
            <Button
              variant="filled"
              type="submit"
              disabled={disabled}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          ) : (
            <Button variant="filled" onClick={onNextStep}>
              Next
            </Button>
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div
      className="Step"
      style={{
        alignItems: title === 'Mandate Overview' ? 'center' : 'flex-start',
        padding: title === 'Mandate Overview' ? '0px' : '0px 100px',
      }}
    >
      <div className="Step-title cc-heading3">{title}</div>
      {alertMessage && (
        <Alert status="info" className="mb-4">
          {alertMessage}
        </Alert>
      )}
      <div
        className="Step-form"
        style={{
          maxWidth: title === 'Mandate Overview' ? '1000px' : '100%',
          width: title === 'Mandate Overview' ? 'inherit' : '100%',
        }}
      >
        <ContextForm
          questions={questions}
          answers={answers}
          invalidAnswers={invalidAnswers}
          onSetAnswers={onSetAnswers}
          onSetInvalidAnswers={setInvalidAnswers}
          requiredQuestionsForApplication={requiredQuestionsForApplication}
          onSetRequiredQuestionsForApplication={
            setRequiredQuestionsForApplication
          }
          buttonComponent={buttonComponent}
          isEdit={isEdit}
        />
      </div>
    </div>
  )
}

Step.propTypes = {
  title: PropTypes.string,
  alertMessage: PropTypes.string,
  questions: PropTypes.array,
  answers: PropTypes.array,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  onSetAnswers: PropTypes.func,
  onNextStep: PropTypes.func,
  onPrevStep: PropTypes.func,
}

export default Step
