import * as React from 'react'
import { Empty } from 'antd'
import isNil from 'lodash/isNil'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getTearSheet } from '~/actions/fundList'
import FundTearSheet from '~/components/FundTearSheet'
import Loading from '~/components/Loading'

const FundTearSheetContainer = () => {
  const { fundId } = useParams()
  const [loadingCharts, setLoadingCharts] = React.useState(true)
  const { isLoading: loadingDetails, data: tearsheetDetails } = useQuery(
    ['tearsheetDetails', fundId],
    () => getTearSheet(fundId),
    { select: (res) => res.data.result }
  )

  const loading = loadingCharts || loadingDetails

  return (
    <Loading spinning={loading} style={{ marginTop: '32px' }}>
      {!loading && isNil(tearsheetDetails) && (
        <Empty description="No tearsheet available for this fund." />
      )}
      {!isNil(tearsheetDetails) && (
        <FundTearSheet
          fundId={fundId}
          details={tearsheetDetails}
          visible={!loading}
          onLoaded={() => setLoadingCharts(false)}
        />
      )}
    </Loading>
  )
}

export default FundTearSheetContainer
