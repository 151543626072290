import PropTypes from 'prop-types'
import { DetailedAvatar } from '@context365/avatar'
import { Button } from '@context365/button'
import map from 'lodash/map'
import { Link } from 'react-router-dom'
import CardButton from '~/components/CardButton'
import { getInitials } from '~/utils'

const ColleaguePreview = ({ colleague }) => (
  <CardButton
    href={`/profile/contact/${colleague.contactId}`}
    target="_blank"
    className={colleague.canView ? 'shadow-0 border' : ''}
    disabled={!colleague.canView}
  >
    <DetailedAvatar
      initials={getInitials(colleague.contactName)}
      name={colleague.contactName}
      profileImageUrl={colleague.imageUrl}
      company={colleague.jobTitle}
      size="medium"
      className="p-2"
    />
  </CardButton>
)

const ContactProfileCompanies = ({ companies }) => {
  return map(companies, (company, i) => (
    <div className="mb-4" key={i}>
      <Button
        variant="link"
        as={Link}
        to={`/profile/company/${company.companyId}`}
        target="_blank"
      >
        {company.name}
      </Button>
      <div className="px-3">{company.category}</div>
      <div className="p-3 grid grid-cols-1 lg:grid-cols-3 gap-2">
        {map(company.colleagues, (colleague) => (
          <ColleaguePreview key={colleague.contactId} colleague={colleague} />
        ))}
      </div>
    </div>
  ))
}

ContactProfileCompanies.propTypes = {
  companies: PropTypes.array,
}

export default ContactProfileCompanies
