export const PRESENTATION_ROLES = [
  [
    'Moderators can present the presentation.',
    'Presenters can present the presentation.',
    'Attendees can watch your presentation or even present themselves. They have audio and video available anytime.',
    'Admin can start, present and end the presentation. The admin is the only one that can create breakout sessions.',
  ],
  [
    'Moderators can presenst the presentation.',
    'Presenters can present the presentation.',
    'Attendees cannot present or moderate. They can watch your presentation and ask offline or live questions. In live questions, they can go live with the moderator and presenters with audio and video.',
    'Admin can start, present and end the presentation. The admin is the only one that can manage questions of attendees.',
  ],
  [
    'Moderators can present the presentation.',
    'Presenters can present the presentation.',
    'Attendees cannot present or moderate. They can watch your presentation and ask offline or live questions. In live questions, they can go live with the moderator and presenters with audio and video.',
    'Admin can start, present and end the presentation. The admin is the only one that can manage questions of attendees.',
  ],
]
