import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Input, Modal, Radio, message } from 'antd'
import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'
import {
  addColleaguesToConversation,
  getColleaguesToAdd,
} from '~/actions/messages'
import ParticipantsCheckbox from '../../ParticipantsCheckbox'
import './AddPeopleToConversation.less'

const { TextArea } = Input

const AddPeopleToConversation = ({
  conversationID,
  visible = false,
  onContactsAdded = () => {},
  onClose = () => {},
}) => {
  const [toExistingConversation, setToExistingConversation] = useState(true)
  const [selectedColleagueIDs, setSelectedColleagueIDs] = useState([])
  const [availableColleagues, setAvailableColleagues] = useState([])
  const [messageBody, setMessageBody] = useState(null)
  const [loadingAdd, setLoadingAdd] = useState(false)

  const role = useSelector((state) => state.auth.role)

  //First render loads list of available contacts
  useEffect(() => {
    if (availableColleagues.length === 0 && visible) {
      const fetchColleagues = async () => {
        const colleaguesRaw = await getColleaguesToAdd(conversationID)
        setAvailableColleagues(colleaguesRaw.data.result)
      }

      fetchColleagues()
    }
  }, [conversationID, availableColleagues, visible])

  //User clicks cancel or closes modal
  const cancelClickHandler = useCallback(() => {
    setSelectedColleagueIDs([])
    setAvailableColleagues([])
    setMessageBody(null)
    onClose()
  }, [onClose])

  //User clicks OK and eventually adds people to conversation
  const okClickedHandler = useCallback(async () => {
    try {
      setLoadingAdd(true)
      const addColleaguesResponse = await addColleaguesToConversation(
        conversationID,
        selectedColleagueIDs,
        !toExistingConversation,
        messageBody
      )
      message.success(addColleaguesResponse.data.message)
      onContactsAdded(
        availableColleagues.filter((x) =>
          selectedColleagueIDs.includes(x.contactId)
        )
      )
      cancelClickHandler()
    } catch (error) {
      message.error('There was an error while attempting to add colleagues')
    } finally {
      setLoadingAdd(false)
    }
  }, [
    conversationID,
    selectedColleagueIDs,
    availableColleagues,
    onContactsAdded,
    toExistingConversation,
    cancelClickHandler,
    messageBody,
  ])

  //User changes their selection which determines wether the conversation
  //will be created or the existing one will be used
  const radioChangedHandler = useCallback(
    (e) => {
      setToExistingConversation(e.target.value)
      if (e.target.value === true) {
        setMessageBody(null)
      }
    },
    [setToExistingConversation]
  )

  //Selected users change (either added or removed)
  const participantsCheckedHandler = useCallback(
    (e) => {
      if (e.target.checked) {
        setSelectedColleagueIDs([...selectedColleagueIDs, e.target.value])
      } else {
        setSelectedColleagueIDs(
          selectedColleagueIDs.filter((p) => p !== e.target.value)
        )
      }
    },
    [selectedColleagueIDs]
  )

  return (
    <Modal
      title="Add more people"
      visible={visible}
      okButtonProps={{
        disabled: !(
          selectedColleagueIDs &&
          selectedColleagueIDs.length > 0 &&
          ((!isNil(messageBody) && messageBody !== '') ||
            toExistingConversation)
        ),
        loading: loadingAdd,
      }}
      onOk={okClickedHandler}
      onCancel={cancelClickHandler}
    >
      <span className="cc-heading5">Your Colleagues</span>

      {availableColleagues && availableColleagues.length > 0 && (
        <div className="messages-chat-colleagues-to-add">
          {/* list of users taken from the response */}
          {availableColleagues.map((x) => (
            <ParticipantsCheckbox
              key={x.contactId}
              contactId={x.contactId}
              contactName={x.contactName}
              imageUrl={x.imageUrl}
              participants={selectedColleagueIDs}
              checked={participantsCheckedHandler}
            />
          ))}

          {/* if the user is an allocator, they can choose wether a new conversation
            will be created or the existing one will be used */}
          {(role.toLowerCase() === 'allocator' ||
            role.toLowerCase() === 'context') && (
            <div>
              <span className="cc-heading5 chat-conversation-preference-label">
                Conversation Preferences
              </span>
              <div
                className="messages-chat-colleagues-to-add"
                style={{ marginTop: '10px' }}
              >
                <Radio.Group
                  onChange={radioChangedHandler}
                  value={toExistingConversation}
                >
                  <Radio value={true}>Add to existing conversation</Radio>
                  <Radio value={false}>Create new conversation</Radio>
                </Radio.Group>
              </div>

              {!toExistingConversation && (
                <div className="messages-chat-colleagues-to-add-messagebox">
                  <TextArea
                    value={messageBody}
                    onChange={(e) => {
                      setMessageBody(e.target.value)
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {/* in case there are no colleagues, the following message is displayed instead of the interface;
        no colleagues means there's nobody else available to be added to this conversation */}
      {availableColleagues && availableColleagues.length === 0 && (
        <div className="chat-conversation-no-colleagues">
          There are no new colleagues that can be added to this conversation
        </div>
      )}
    </Modal>
  )
}

AddPeopleToConversation.propTypes = {
  conversationID: PropTypes.number.isRequired,
  onContactsAdded: PropTypes.func,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
}

export default AddPeopleToConversation
