import { useState } from 'react'
import { Badge } from '@context365/badge'
import { Button } from '@context365/button'
import { ChevronRight, Help } from '@context365/icons'
import isNil from 'lodash/isNil'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import * as api from '~/api'
import InfoPopover from '~/components/InfoPopover'
import UpgradeModal from '~/components/UpgradeModal'
import { Free } from '~/constants/tiers'
import { getTier } from '~/selectors/auth'

const CreditsIndicator = ({ campaignEntityType, fundId = 0 }) => {
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false)
  const tier = useSelector(getTier)
  const { data: credits = null } = useQuery(
    ['discoverRemainingCredits', fundId, campaignEntityType],
    () => api.campaigns.getRemainingCredits(campaignEntityType, fundId),
    { refetchOnWindowFocus: false, enabled: tier === Free }
  )
  return (
    <>
      {!isNil(credits) && (
        <div className="flex flex-row items-center pb-4">
          <Badge status={credits <= 0 ? 'error' : 'default'}>{`Free Tier: ${
            credits < 0 ? 0 : credits
          } Left`}</Badge>
          <InfoPopover
            title="Credits"
            interactive
            popoverBody={
              <div>
                <p>
                  On the Free Plan, you can indicate interest in a few pipelines
                  per month.
                </p>
                <p className="flex flex-row justify-end items-center">
                  <Button
                    variant="link"
                    onClick={() => setUpgradeModalVisible(true)}
                  >
                    How to get more credits
                    <ChevronRight />
                  </Button>
                </p>
              </div>
            }
          >
            <Help size={16} className="text-primary-100 ml-1.5 cursor-help" />
          </InfoPopover>
        </div>
      )}
      <UpgradeModal
        closable
        visible={upgradeModalVisible}
        onClose={() => {
          setUpgradeModalVisible(false)
        }}
      />
    </>
  )
}

export default CreditsIndicator
