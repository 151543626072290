import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Drawer } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'
import { getConversation } from '~/actions/messages'
import FundsAndDocuments from '../../Messages/FundsAndDocuments'
import MessagesPanel from '../../Messages/MessagesPanel'
import ChannelSwitch from '../ChannelSwitch'
import EmptyChannelList from '../EmptyChannelList'
import './Sidebar.less'

const tabs = {
  1: 'participants',
  2: 'messages',
  3: 'information',
}

const Sidebar = ({
  participants,
  meetingCompanyName,
  visibleDrawer,
  fixedDominant,
  setVisibleDrawer,
  documents,
  conversationID,
  meetingType,
}) => {
  const [currentTab, setCurrentTab] = useState('participants')
  const [conversation, setConversation] = useState(null)
  const [unreadMessage, setUnreadMessage] = useState(0)
  const twilioClient = useSelector((state) => state.messaging.twilioClient)

  const tabSwitchHandler = (tabValue) => {
    if (tabs[tabValue] === 'messages' && conversation) {
      conversation.unreadMessages = 0
      setUnreadMessage(0)
    }
    setCurrentTab(tabs[tabValue])
  }

  const twilioMessageAdded = useCallback(
    (twilioMessage) => {
      if (twilioMessage.channel.sid !== conversation.channelSID) return
      conversation.unreadMessages = conversation.unreadMessages || 0
      currentTab !== 'messages' &&
        setUnreadMessage(++conversation.unreadMessages)
      currentTab === 'messages' && setUnreadMessage(0)
    },
    [conversation, currentTab]
  )

  // subscribe / unsubscribe from Twilio client on mount / unmount
  useEffect(() => {
    if (!isNil(twilioClient))
      twilioClient.on('messageAdded', twilioMessageAdded)
    return () => {
      if (!isNil(twilioClient))
        twilioClient.off('messageAdded', twilioMessageAdded)
    }
  }, [twilioClient, twilioMessageAdded])

  useEffect(() => {
    const fetchConversation = async () => {
      const rawConversation = await getConversation(conversationID, true, true)
      const conversation = rawConversation.data.result
      setConversation(conversation)
    }
    fetchConversation()
  }, [conversationID])

  return (
    <Drawer
      getContainer={false}
      placement="right"
      style={{ width: '25%' }}
      maskClosable={false}
      mask={false}
      closable={fixedDominant}
      onClose={() => setVisibleDrawer(false)}
      visible={visibleDrawer}
      width="100%"
      title={
        <div>
          <div className="Sidebar-company">
            <div className="Sidebar-company-name">{meetingCompanyName}</div>
            <div className="Sidebar-company-type">
              <span
                className={`Sidebar-company-type-span Sidebar-company-type-${meetingType.toLowerCase()}`}
              >
                {meetingType.toUpperCase()}
              </span>
            </div>
          </div>
          <ChannelSwitch
            onChange={tabSwitchHandler}
            unreadMessage={unreadMessage}
            showConversation={!isNil(conversationID)}
          />
        </div>
      }
    >
      {currentTab === 'participants' && (
        <div className="Sidebar-participants">{participants}</div>
      )}
      {!isNil(conversationID) && currentTab === 'messages' && (
        <div className="Sidebar-messages">
          <MessagesPanel conversationID={conversationID} simpleBoard={true} />
        </div>
      )}
      {currentTab === 'information' && (
        <div className="Sidebar-info">
          {!isEmpty(documents) ? (
            <div className="Sidebar-info-documents">
              <p className="cc-meeting-lobby-body">Meeting with:</p>
              <h3>{meetingCompanyName}</h3>
              <FundsAndDocuments
                funds={documents.map((doc) => ({
                  fundID: doc.fundID,
                  documents: doc.documents,
                  name: doc.fundName,
                }))}
                companyName={meetingCompanyName}
              />
            </div>
          ) : (
            <EmptyChannelList type="information" />
          )}
        </div>
      )}
    </Drawer>
  )
}

Sidebar.propTypes = {
  participants: PropTypes.node,
  conversationID: PropTypes.number.isRequired,
  meetingCompanyName: PropTypes.string.isRequired,
  visibleDrawer: PropTypes.bool.isRequired,
  fixedDominant: PropTypes.bool,
  setVisibleDrawer: PropTypes.func.isRequired,
  documents: PropTypes.array,
  meetingType: PropTypes.string.isRequired,
}

export default Sidebar
