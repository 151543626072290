import PropTypes from 'prop-types'
import { Table } from 'antd'
import isEmpty from 'lodash/isEmpty'
import numeral from 'numeral'

const PerformanceTable = ({
  fundMonthlyReturns = [],
  fundYtdReturns = [],
  ...tableProps
}) => {
  if (isEmpty(fundMonthlyReturns) || isEmpty(fundYtdReturns)) {
    return null
  }

  return (
    <div>
      <Table
        className="cc-table-th"
        columns={getMonthlyReturnColumns(fundMonthlyReturns)}
        dataSource={getMonthlyReturnData(fundMonthlyReturns, fundYtdReturns)}
        pagination={false}
        rowClassName={() => 'cc-table-row'}
        {...tableProps}
      />
    </div>
  )
}

PerformanceTable.propTypes = {
  fundMonthlyReturns: PropTypes.array,
  fundYtdReturns: PropTypes.array,
}

export default PerformanceTable

function getMonthlyReturnData(fundMonthlyReturns, fundYtdReturns) {
  const fundYears = getFundYears(fundMonthlyReturns)
  const fundMonths = getFundMonths(fundMonthlyReturns)

  return fundYears.map((year) => {
    const yearReturns = fundMonths.reduce(
      (returns, month) => ({
        ...returns,
        [month]: formatMonthlyReturn(
          fundMonthlyReturns.find(
            (x) => x.year === year && x.month_name === month
          )?.return
        ),
      }),
      { year, key: year }
    )
    if (!isEmpty(fundYtdReturns)) {
      yearReturns.Ytd = formatYTDReturn(
        fundYtdReturns.find((x) => x.Year === parseInt(year, 10))?.YTD_Return
      )
    }
    return yearReturns
  })
}

function formatMonthlyReturn(monthlyReturn) {
  if (monthlyReturn == null) {
    return ''
  }

  return numeral(monthlyReturn * 100).format('0,0[.]00')
}

function formatYTDReturn(ytdReturn) {
  if (ytdReturn == null) {
    return ''
  }

  return numeral(ytdReturn).format('0,0[.]00')
}

function getMonthlyReturnColumns() {
  return [
    {
      title: '',
      key: 'year',
      width: 50,
      fixed: 'left',
      dataIndex: 'year',
      className: 'cc-table-th',
    },
    {
      title: 'Jan',
      key: 'Jan',
      dataIndex: 'January',
      className: 'cc-table-th',
    },
    {
      title: 'Feb',
      key: 'Feb',
      dataIndex: 'February',
      className: 'cc-table-th',
    },
    {
      title: 'Mar',
      key: 'Mar',
      dataIndex: 'March',
      className: 'cc-table-th',
    },
    {
      title: 'Apr',
      key: 'Apr',
      dataIndex: 'April',
      className: 'cc-table-th',
    },
    {
      title: 'May',
      key: 'May',
      dataIndex: 'May',
      className: 'cc-table-th',
    },
    {
      title: 'Jun',
      key: 'Jun',
      dataIndex: 'June',
      className: 'cc-table-th',
    },
    {
      title: 'Jul',
      key: 'Jul',
      dataIndex: 'July',
      className: 'cc-table-th',
    },
    {
      title: 'Aug',
      key: 'Aug',
      dataIndex: 'August',
      className: 'cc-table-th',
    },
    {
      title: 'Sep',
      key: 'Sep',
      dataIndex: 'September',
      className: 'cc-table-th',
    },
    {
      title: 'Oct',
      key: 'Oct',
      dataIndex: 'October',
      className: 'cc-table-th',
    },
    {
      title: 'Nov',
      key: 'Nov',
      dataIndex: 'November',
      className: 'cc-table-th',
    },
    {
      title: 'Dec',
      key: 'Dec',
      dataIndex: 'December',
      className: 'cc-table-th',
    },
    {
      title: 'YTD',
      key: 'YTD',
      dataIndex: 'Ytd',
      className: 'cc-table-th',
    },
  ]
}

function getFundYears(data) {
  return data
    .map((x) => x.year)
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => b - a)
}

function getFundMonths(data) {
  return data
    .map((x) => x.month_name)
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => b - a)
}
