export const Step = {
  CompanyDetails: 'company-details',
  CompanyInterests: 'company-interests',
  ContactIdentification: 'contact-identification',
  ContactLocation: 'contact-location',
  ContactEducation: 'contact-education',
  ContactInterest: 'contact-interest',
  ContactSocialMedia: 'contact-social',
  InvestorStatus: 'investor-status',
  InvestorDetails: 'investor-details',
}

export function getPreviousStep(steps, currentStep) {
  const index = steps.indexOf(currentStep)
  return steps[index - 1] ?? '..'
}

export function getNextStep(steps, currentStep, stepOffset) {
  const index = steps.indexOf(currentStep)
  return steps[index + 1 + (stepOffset ?? 0)]
}

export function makeGetNextPagePath(steps) {
  return ({ currentPage }) => {
    return getNextStep(steps, currentPage)
  }
}

export function makeGetPreviousPagePath(steps) {
  return ({ currentPage }) => {
    return getPreviousStep(steps, currentPage) ?? '..'
  }
}
