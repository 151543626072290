import { useContext } from 'react'
import { Divider, Switch } from 'antd'
import PresentationWizardCtx from '~/utils/contexts/PresentationWizardCtx'

const AdditionalOptions = () => {
  const { options, setOptions, displayData, isPublished } = useContext(
    PresentationWizardCtx
  )

  const handleOptions = (e, key) => {
    if (e) {
      setOptions((prevOptions) => [...prevOptions, key + 1])
    } else {
      setOptions((prevOptions) => prevOptions.filter((p) => p !== key + 1))
    }
  }

  return (
    <div className="flex flex-col">
      <div className="text-grey-900 type-body-regular-sm mb-2">
        Do you want to record the presentation and allow attendees to keep
        notes?
      </div>
      <Divider />
      {displayData.options.map((s, key) => (
        <div key={key}>
          <div className="flex justify-between">
            <div>
              <div className="text-grey-900 type-body-regular-sm">{s.name}</div>
              {s.description && (
                <div className="type-body-regular-xs text-grey-500">
                  {s.description}
                </div>
              )}
            </div>
            <div className="flex items-center">
              <Switch
                defaultChecked={options.includes(key + 1)}
                onChange={(e) => handleOptions(e, key)}
                disabled={isPublished}
              />
            </div>
          </div>
          <Divider />
        </div>
      ))}
    </div>
  )
}

export default AdditionalOptions
