import * as React from 'react'
import { Checkbox, FormLabel, FormMessage } from '@context365/forms'
import partition from 'lodash/partition'
import { useController } from 'react-hook-form'
import { useFieldSchema } from '~/components/Form'
import { useOnboardingContext } from '../OnboardingContext'

export default function StrategyPreferencesForm({ className }) {
  const rules = useFieldSchema('fundStrategyIds', 'Strategy')
  const { options } = useOnboardingContext()
  const [liquidOptions, illiquidOptions] = React.useMemo(
    () => partition(options.fundStrategies, 'isLiquid'),
    [options]
  )

  const {
    field: { value = [], onChange, ref },
    fieldState: { error },
  } = useController({ name: 'fundStrategyIds' })

  const [liquidSelected, setLiquidSelected] = React.useState(() => {
    return value.some((id) => liquidOptions.some((option) => option.id === id))
  })
  const [illiquidSelected, setIlliquidSelected] = React.useState(() => {
    return value.some((id) =>
      illiquidOptions.some((option) => option.id === id)
    )
  })

  function handleRootStrategyChange(event, setState, linkedOptions) {
    setState(event.target.checked)
    if (!event.target.checked) {
      onChange(
        value.filter(
          (id) =>
            !linkedOptions.some(
              (option) =>
                option.id === id ||
                option.children.some((child) => child.id === id)
            )
        )
      )
    }
  }

  return (
    <div ref={ref} className={className}>
      <FormLabel
        className="type-body-semibold-md mb-0"
        required={rules.required}
      >
        Strategy
      </FormLabel>
      <FormMessage
        className="mt-0 mb-1 border-b"
        helpMessage="Select at least one strategy and one sub-strategy."
        errorMessage={error?.message}
      />
      <div className="flex flex-col">
        <Checkbox
          checked={liquidSelected}
          onChange={(e) =>
            handleRootStrategyChange(e, setLiquidSelected, liquidOptions)
          }
          label="Liquid Strategy"
        />
        {liquidSelected && (
          <div className="flex flex-col">
            {liquidOptions.map((option) => (
              <BroadStrategyOption
                key={option.id}
                {...option}
                selectedIds={value}
                onChange={onChange}
              />
            ))}
          </div>
        )}
        <Checkbox
          checked={illiquidSelected}
          onChange={(e) =>
            handleRootStrategyChange(e, setIlliquidSelected, illiquidOptions)
          }
          label="Illiquid Strategy"
        />
        {illiquidSelected && (
          <div className="flex flex-col">
            {illiquidOptions.map((option) => (
              <BroadStrategyOption
                key={option.id}
                {...option}
                selectedIds={value}
                onChange={onChange}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

function BroadStrategyOption({ id, name, children, selectedIds, onChange }) {
  function handleBroadStrategyChange(event) {
    if (event.target.checked) {
      onChange([...selectedIds, id])
    } else {
      onChange(
        selectedIds.filter((s) => s !== id && !children.some((c) => c.id === s))
      )
    }
  }

  function handleSubStrategyChange(event) {
    if (event.target.checked) {
      onChange([...selectedIds, event.target.value])
    } else {
      onChange(selectedIds.filter((x) => x !== event.target.value))
    }
  }

  const checked = selectedIds.includes(id)

  return (
    <div className="px-8">
      <Checkbox
        checked={checked}
        value={id}
        label={name}
        onChange={handleBroadStrategyChange}
      />
      {checked && (
        <div className="flex flex-col px-8">
          {children.map((child) => (
            <Checkbox
              key={child.id}
              checked={selectedIds.includes(child.id)}
              value={child.id}
              label={child.name}
              onChange={handleSubStrategyChange}
            />
          ))}
        </div>
      )}
    </div>
  )
}
