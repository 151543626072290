import PropTypes from 'prop-types'
import { Circle } from '@context365/icons'
import { Menu, MenuButton } from '@context365/menu'
import { message } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import { CAMPAIGN_STATUS } from '~/constants/campaignStatus'

function getCampaignStatusOption(status) {
  switch (status) {
    case CAMPAIGN_STATUS.DRAFT:
      return (
        <MenuButton iconLeft={<Circle className="text-gold-100" />}>
          Draft
        </MenuButton>
      )
    case CAMPAIGN_STATUS.OPEN:
      return (
        <MenuButton iconLeft={<Circle className="text-green-90" />}>
          Open
        </MenuButton>
      )
    case CAMPAIGN_STATUS.CLOSED:
      return (
        <MenuButton iconLeft={<Circle className="text-secondary-100" />}>
          Closed
        </MenuButton>
      )
    default:
      return null
  }
}

const CampaignStatusSelector = ({ campaignId, campaignStatusId }) => {
  const client = useQueryClient()
  const { trackEvent } = useTracking({ page: 'CampaignDetails' })
  const trackClick = (label) =>
    trackEvent({ element: 'CampaignStatus', eventName: 'click', label })

  const { mutateAsync: updateCampaignStatus } = useMutation(
    (campaignStatusId) =>
      api.campaigns.updateCampaignStatus(campaignId, campaignStatusId),
    {
      onSuccess: () => {
        client.invalidateQueries('campaign', campaignId)
        client.invalidateQueries('dashboardCampaigns')
      },
      onError: (err) => {
        const errorMessage =
          err?.response?.status === 400 ? err?.response?.data : null
        message.error(errorMessage || 'Could not change status.')
      },
    }
  )

  return (
    <Menu trigger={getCampaignStatusOption(campaignStatusId)}>
      <Menu.Item
        className="type-body-regular-sm"
        onClick={() => {
          trackClick('Draft')
          updateCampaignStatus(CAMPAIGN_STATUS.DRAFT)
        }}
      >
        <Circle className="text-gold-100 mr-2" size={18} />
        Draft
      </Menu.Item>
      <Menu.Item
        className="type-body-regular-sm"
        onClick={() => {
          trackClick('Open')
          updateCampaignStatus(CAMPAIGN_STATUS.OPEN)
        }}
      >
        <Circle className="text-green-90 mr-2" size={18} />
        Open
      </Menu.Item>
      <Menu.Item
        className="type-body-regular-sm"
        onClick={() => {
          trackClick('Closed')
          updateCampaignStatus(CAMPAIGN_STATUS.CLOSED)
        }}
      >
        <Circle className="text-secondary-100 mr-2" size={18} />
        Closed
      </Menu.Item>
    </Menu>
  )
}

CampaignStatusSelector.propTypes = {
  campaignId: PropTypes.number.isRequired,
  campaignStatusId: PropTypes.number,
}

export default CampaignStatusSelector
