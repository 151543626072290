import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MultiSelect, Select } from '@context365/forms'
import { isEmpty } from 'lodash'
import forEach from 'lodash/forEach'
import isNil from 'lodash/isNil'
import map from 'lodash/map'

const DropDownField = ({
  name,
  value,
  label,
  setFieldValue,
  options,
  getOptionsMethod,
  mappingMethod,
  showSearch = false,
  mode,
  clearFieldsOnChange = [],
  allowClear = false,
  errorMessage,
  helpMessage,
  required = false,
}) => {
  const [selectOptions, setSelectOptions] = useState([])

  useEffect(() => {
    let mappedOptions = []

    if (isNil(options)) {
      !isNil(getOptionsMethod) &&
        getOptionsMethod().then((res) => {
          mappedOptions = mappingMethod(res.data.result)
          setSelectOptions(mappedOptions)
        })
    } else {
      mappedOptions = isNil(mappingMethod)
        ? map(options, (x) => ({ label: x.name, value: x.id }))
        : mappingMethod(options)

      setSelectOptions(mappedOptions)
    }
  }, [options])

  const handleChange = (val) => {
    setFieldValue(name, val)
    forEach(clearFieldsOnChange, (x) => setFieldValue(x, null))
  }

  return isEmpty(selectOptions) ? null : mode === 'multiple' ? (
    <MultiSelect
      label={label}
      name={name}
      value={value}
      onChange={(val) => handleChange(val)}
      errorMessage={errorMessage}
      helpMessage={helpMessage}
      required={required}
      clearable={allowClear}
    >
      {selectOptions.map((option) => (
        <MultiSelect.Option
          key={option.value}
          className="py-2"
          value={option.value}
        >
          {option.label}
        </MultiSelect.Option>
      ))}
    </MultiSelect>
  ) : showSearch ? (
    <Select.AutoComplete
      label={label}
      name={name}
      value={value}
      onChange={(val) => handleChange(val)}
      errorMessage={errorMessage}
      helpMessage={helpMessage}
      required={required}
      clearable={allowClear}
    >
      {({ inputValue }) =>
        selectOptions
          .filter((s) =>
            s.label.toLowerCase().includes(inputValue.toLowerCase())
          )
          .map((option) => (
            <Select.Option
              key={option.value}
              className="py-2"
              value={option.value}
            >
              {option.label}
            </Select.Option>
          ))
      }
    </Select.AutoComplete>
  ) : (
    <Select
      label={label}
      name={name}
      value={value}
      onChange={(val) => handleChange(val)}
      errorMessage={errorMessage}
      helpMessage={helpMessage}
      required={required}
      clearable={allowClear}
    >
      {selectOptions.map((option) => (
        <Select.Option key={option.value} className="py-2" value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  )
}

DropDownField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  helpMessage: PropTypes.string,
  required: PropTypes.bool,
  setFieldValue: PropTypes.func,
  options: PropTypes.array,
  getOptionsMethod: PropTypes.func,
  mappingMethod: PropTypes.func,
  showSearch: PropTypes.bool,
  mode: PropTypes.string,
  clearFieldsOnChange: PropTypes.arrayOf(PropTypes.string),
  allowClear: PropTypes.bool,
}

export default DropDownField
