import { api } from './services'

export const getEntries = async () => {
  const response = await api.get('/contentEntries')
  return response.data.result
}

export const getCategories = async () => {
  const response = await api.get('/contentEntries/categories')
  return response.data.result
}

export const getReactionTypes = async () => {
  const response = await api.get('/contentEntries/reactionTypes')
  return response.data.result
}

export const toggleReaction = async (contentEntryId, reactionTypeId) => {
  const response = await api.post('/contentEntries/react', {
    contentEntryId,
    reactionTypeId,
  })
  return response.data.result
}

export const getEntry = async (contentEntryId) => {
  const response = await api.get(`/contentEntries/${contentEntryId}`)
  return response.data.result
}
