import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import isNil from 'lodash/isNil'
import moment from 'moment'
import numeral from 'numeral'

const StatRow = ({ label, value, visible }) =>
  visible ? (
    <Row className="StatRow">
      <Col span={16}>{label}</Col>
      <Col span={8} className="StatValue">
        {value}
      </Col>
    </Row>
  ) : null

const StatsSideBar = ({ details, stats, bmStats, showOnPrint = false }) => {
  return (
    <div className={showOnPrint ? 'StatBox-Print' : 'StatBox'}>
      {(!isNil(details?.broadStrategy) || !isNil(details?.subStrategy)) && (
        <>
          <Row>
            <h1>STRATEGIES</h1>
          </Row>
          <Row>
            <div>{details.broadStrategy}</div>
          </Row>
          <Row>
            <div>{details.subStrategy}</div>
          </Row>
        </>
      )}
      {!isNil(details?.netDollarExposure) && (
        <>
          <Row>
            <h1>MARKET EXPOSURE</h1>
          </Row>
          <Row>
            <div>{details.netDollarExposure}</div>
          </Row>
        </>
      )}
      {!isNil(details?.primaryDriverOfInvestmentDecisionProcess) && (
        <>
          <Row>
            <h1>DECISION PROCESS</h1>
          </Row>
          <Row>
            <div>{details.primaryDriverOfInvestmentDecisionProcess}</div>
          </Row>
        </>
      )}
      {!isNil(details?.primaryInstruments) && (
        <>
          <Row>
            <h1>INSTRUMENTS</h1>
          </Row>
          <Row>
            <div>{details.primaryInstruments}</div>
          </Row>
          <br />
        </>
      )}
      <Row>
        <h1>STATISTICS</h1>
      </Row>
      {!details?.narrative && (
        <>
          <StatRow
            visible={!isNil(stats?.AnnualCompoundReturn)}
            label="Annualized Return"
            value={`${stats?.AnnualCompoundReturn?.toFixed(2)}%`}
          />
          <StatRow
            visible={!isNil(stats?.AnnualVolatility)}
            label="Annualized Standard Deviation"
            value={`${stats?.AnnualVolatility?.toFixed(2)}%`}
          />
        </>
      )}
      <StatRow
        visible={!isNil(details?.fundAum)}
        label="AUM in USD"
        value={numeral(details?.fundAum)?.format('$0,0')}
      />
      <StatRow
        visible={!isNil(details?.fundInceptionDate)}
        label="Inception Date"
        value={moment.utc(details?.fundInceptionDate).format('YYYY-M-DD')}
      />
      {!details?.narrative && (
        <>
          <StatRow
            visible={!isNil(stats?.SharpeRatio)}
            label="Sharpe Ratio"
            value={stats?.SharpeRatio?.toFixed(2)}
          />
          <StatRow
            visible={!isNil(stats?.CalmarRatio)}
            label="Calmar Ratio"
            value={stats?.CalmarRatio?.toFixed(2)}
          />
          <StatRow
            visible={!isNil(bmStats?.HistoricalBetaSPX)}
            label="Historical Regression Beta to SPX"
            value={bmStats?.HistoricalBetaSPX?.toFixed(2)}
          />
          <StatRow
            visible={!isNil(stats?.MaxDrawdown)}
            label="Deepest Drawdown"
            value={stats?.MaxDrawdown?.toFixed(2)}
          />
          <StatRow
            visible={!isNil(stats?.LongestDrawdown)}
            label="Length of Longest Drawdown (months)"
            value={stats?.LongestDrawdown}
          />
          <StatRow
            visible={!isNil(stats?.CMGR)}
            label="Geometric Average Monthly Return"
            value={`${stats?.CMGR?.toFixed(2)}%`}
          />
          <StatRow
            visible={!isNil(stats?.DollarWeightedAverageMonthlyReturn)}
            label="Dollar Weighted Average Monthly Return"
            value={`${stats?.DollarWeightedAverageMonthlyReturn?.toFixed(2)}%`}
          />
          <StatRow
            visible={!isNil(stats?.MedianGain)}
            label="Median Gain"
            value={`${stats?.MedianGain?.toFixed(2)}%`}
          />
          <StatRow
            visible={!isNil(stats?.MedianLoss)}
            label="Median Loss"
            value={`${stats?.MedianLoss?.toFixed(2)}%`}
          />
          <StatRow
            visible={!isNil(stats?.BiasRatio)}
            label="Bias Ratio"
            value={stats?.BiasRatio?.toFixed(2)}
          />
          <Row>
            <h1>INDEX CORRELATIONS</h1>
          </Row>
          <StatRow
            visible={!isNil(stats?.CorrelationToSPX)}
            label="S&amp;P 500"
            value={stats?.CorrelationToSPX?.toFixed(2)}
          />
          <StatRow
            visible={
              !isNil(details?.customIndex) && !isNil(bmStats?.CorrelationToALT)
            }
            label={details.customIndex}
            value={bmStats?.CorrelationToALT?.toFixed(2)}
          />
        </>
      )}
      <Row>
        <h1>TERMS</h1>
      </Row>
      <StatRow
        visible={!isNil(details?.postLockupLiquidity)}
        label="Redemptions"
        value={details.postLockupLiquidity}
      />
      <StatRow
        visible={!isNil(details?.durationOfCapitalLockup)}
        label="Lockup"
        value={`${details.durationOfCapitalLockup} days`}
      />
      <StatRow
        visible={!isNil(details?.daysAdvanceNoticeNeededForRedemption)}
        label="Advance Notice"
        value={`${details.daysAdvanceNoticeNeededForRedemption} days`}
      />
    </div>
  )
}

StatsSideBar.propTypes = {
  details: PropTypes.object,
  stats: PropTypes.object,
  bmStats: PropTypes.object,
  showOnPrint: PropTypes.bool,
}
export default StatsSideBar
