import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import { TabButton, TabList, TabPanel, Tabs } from '@context365/tabs'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import map from 'lodash/map'
import moment from 'moment'
import { numberFormat } from '~/utils/formatters'

const INVESTMENT_REALIZED = 'Realized'
const DEAL_LEAD = 'Lead Investor'

const CompanyProfileInvestments = ({ company }) => {
  return (
    <Tabs>
      <div className="grid grid-cols-8 gap-8">
        <div className="col-span-1">
          <TabList variant="vertical">
            <TabButton id="interests" key="interests" label="Interests" />
            <TabButton id="historical" key="historical" label="Historical" />
          </TabList>
        </div>
        <div className="col-span-7">
          <TabPanel key="interests" tabId="interests">
            <div className="flex flex-col space-y-4">
              {!isEmpty(company.industryInterests) && (
                <div>
                  <div className="type-body-semibold-sm">Sector Interests</div>
                  <div>{join(company.industryInterests, ', ')}</div>
                </div>
              )}
              {!isEmpty(company.geographicInterests) && (
                <div>
                  <div className="type-body-semibold-sm">
                    Geograhpic Interests
                  </div>
                  <div>{join(company.geographicInterests, ', ')}</div>
                </div>
              )}
              {!isEmpty(company.vehicleInterests) && (
                <div>
                  <div className="type-body-semibold-sm">Vehicle Interests</div>
                  <div>{join(company.vehicleInterests, ', ')}</div>
                </div>
              )}
              {!isEmpty(company.verticalInterests) && (
                <div>
                  <div className="type-body-semibold-sm">
                    Vertical Interests
                  </div>
                  <div>{join(company.verticalInterests, ', ')}</div>
                </div>
              )}
              <div className="mb-4 grid grid-cols-2 gap-4">
                {!isNil(company.minimumInvestmentSize) && (
                  <div>
                    <div className="type-body-semibold-sm">
                      Minimum Investment Size
                    </div>
                    <div>
                      {numberFormat.currencyLarge(
                        company.minimumInvestmentSize
                      )}
                    </div>
                  </div>
                )}
                {!isNil(company.averageInvestmentSize) && (
                  <div>
                    <div className="type-body-semibold-sm">
                      Average Investment Size
                    </div>
                    <div>
                      {numberFormat.currencyLarge(
                        company.averageInvestmentSize
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel key="historical" tabId="historical">
            {!isNil(company.firmAum) && (
              <div className="mb-4">
                <div className="type-body-semibold-sm">Firm AUM</div>
                <div>{numberFormat.currencyLarge(company.firmAum)}</div>
              </div>
            )}
            {!isEmpty(company.investments) && (
              <div className="mb-4">
                <div className="grid grid-cols-3 text-center text-grey-700 type-body-semibold-sm bg-grey-200 p-2 border border-solid">
                  <div>Top Holdings</div>
                  <div>Security Type</div>
                  <div>Investment Date</div>
                </div>
                {map(company.investments, (x, i) => (
                  <div
                    key={i}
                    className="grid grid-cols-3 text-center text-grey-700 p-2 border border-solid even:bg-grey-100"
                  >
                    <div>
                      {x.name}
                      <Badge
                        className="ml-1"
                        size="small"
                        status={
                          x.investmentStatus === INVESTMENT_REALIZED
                            ? 'success'
                            : 'info'
                        }
                      >
                        {x.investmentStatus}
                      </Badge>
                    </div>
                    <div>{x.securityType}</div>
                    <div>{moment(x.investmentDate).format('MM/DD/YYYY')}</div>
                  </div>
                ))}
              </div>
            )}
            {!isEmpty(company.deals) && (
              <div className="mb-4">
                <div className="grid grid-cols-3 text-center text-grey-700 type-body-semibold-sm bg-grey-200 p-2 border border-solid">
                  <div>Deals</div>
                  <div>Security Type</div>
                  <div>Investment Date</div>
                </div>
                {map(company.deals, (x, i) => (
                  <div
                    key={i}
                    className="grid grid-cols-3 text-center text-grey-700 p-2 border border-solid even:bg-grey-100"
                  >
                    <div>
                      {x.name}
                      {x.dealLeadStatus === DEAL_LEAD && (
                        <Badge className="ml-1" size="small" status="warning">
                          Led
                        </Badge>
                      )}
                    </div>
                    <div>{x.securityType}</div>
                    <div>{moment(x.investmentDate).format('MM/DD/YYYY')}</div>
                  </div>
                ))}
              </div>
            )}
            {!isEmpty(company.partners) && (
              <div>
                <div className="grid grid-cols-2 text-center text-grey-700 type-body-semibold-sm bg-grey-200 p-2 border border-solid">
                  <div>Common Partners</div>
                  <div>Investor Type</div>
                </div>
                {map(company.partners, (x, i) => (
                  <div
                    key={i}
                    className="grid grid-cols-2 text-center text-grey-700 p-2 border border-solid even:bg-grey-100"
                  >
                    <div>{x.name}</div>
                    <div>{x.investorCategory}</div>
                  </div>
                ))}
              </div>
            )}
          </TabPanel>
        </div>
      </div>
    </Tabs>
  )
}

CompanyProfileInvestments.propTypes = {
  company: PropTypes.shape({
    investments: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        investmentDate: PropTypes.object.isRequired,
        investmentStatus: PropTypes.string.isRequired,
        securityType: PropTypes.string.isRequired,
      })
    ),
    deals: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        investmentDate: PropTypes.object.isRequired,
        dealLeadStatus: PropTypes.string.isRequired,
        securityType: PropTypes.string.isRequired,
      })
    ),
    partners: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        investorCategory: PropTypes.string.isRequired,
      })
    ),
    industryInterests: PropTypes.arrayOf(PropTypes.string),
    geographicInterests: PropTypes.arrayOf(PropTypes.string),
    verticalInterests: PropTypes.arrayOf(PropTypes.string),
    vehicleInterests: PropTypes.arrayOf(PropTypes.string),
    firmAum: PropTypes.number,
    minimumInvestmentSize: PropTypes.number,
    averageInvestmentSize: PropTypes.number,
  }).isRequired,
}

export default CompanyProfileInvestments
