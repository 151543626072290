import * as React from 'react'

export default function useInnerScrolling() {
  React.useLayoutEffect(() => {
    const root = document.querySelector('[data-layout="app"]')
    const main = document.querySelector('main')
    root.classList.add('h-screen')
    main.classList.add('overflow-y-hidden')

    return () => {
      root.classList.remove('h-screen')
      main.classList.remove('overflow-y-hidden')
    }
  }, [])
}
