import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, message } from 'antd'
import { getCFNContactQuestions, postCFNContact } from '~/actions/onboarding'
import FamilyNetworkDealsForm from '~/components/OnboardingComponents/FamilyNetworkDealsForm'
import '~/components/CompanyForm/CompanyForm.less'
import '~/views/settings/InvestorPreferences.less'

const CFNAllocatorDealsForm = ({ onNextClicked }) => {
  const [nextEnabled, setNextEnabled] = useState(false)
  const [answers, setAnswers] = useState(null)
  const [posting, setPosting] = useState(false)

  const onChange = useCallback((answer) => {
    setAnswers(answer)
  }, [])

  const postData = useCallback(() => {
    setPosting(true)
    postCFNContact(3, answers)
      .then(() => {
        message.success('Settings have been successfully saved')
        setNextEnabled(false)
        onNextClicked()
      })
      .catch(() => message.error('There was an error while attempting to save'))
      .finally(() => setPosting(false))
  }, [answers, onNextClicked])

  return (
    <div>
      <div className="settings-investor-preferences-board">
        <div className="form-container-header">
          <p className="form-container-title">Investor Status</p>
          <p className="form-container-description">Set your investor status</p>
        </div>
        <div className="form-section ">
          <FamilyNetworkDealsForm
            fromCfnSettings={true}
            enableNext={(value) => setNextEnabled(value)}
            currentQuestions={answers}
            step={{
              innerStep: 3,
              title: 'Deals',
              renderAction: getCFNContactQuestions,
              customRender: true,
              customRenderParameter: 3,
            }}
            onChange={onChange}
            showInterestOptions={false}
          />
        </div>
        <div>
          <Button
            onClick={() => postData()}
            disabled={!nextEnabled}
            type="primary"
            className="form-section-btn-save"
            loading={posting}
          >
            {`Next`}
          </Button>
        </div>
      </div>
    </div>
  )
}

CFNAllocatorDealsForm.propTypes = {
  onNextClicked: PropTypes.func.isRequired,
}
export default CFNAllocatorDealsForm
