import PropTypes from 'prop-types'
import map from 'lodash/map'
import './Profiles.less'

const Profiles = ({ profiles, maxImages = 5 }) => {
  let slicedImages = false

  if (profiles.length > maxImages) {
    profiles = profiles.slice(0, maxImages)
    slicedImages = true
  }

  return (
    <span className="round-profile-imagegroup">
      {map(profiles, (profile, i) => (
        <img
          key={profile}
          alt=""
          style={{ position: 'relative', zIndex: 999 - i }}
          className="round-profile-thumbnail"
          src={profile}
        />
      ))}
      <span>{slicedImages === true ? '•••' : ''}</span>
    </span>
  )
}

Profiles.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  maxImages: PropTypes.number,
}

export default Profiles
