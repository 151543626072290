import PropTypes from 'prop-types'
import get from 'lodash/fp/get'
import AddToListModal from './AddToListModal'

export default function AddMandateToListModal({ visible, mandates, onClose }) {
  return (
    <AddToListModal
      visible={visible}
      onClose={onClose}
      targetItems={mandates}
      getTargetId={get('mandateId')}
      getTargetName={get('title')}
      listLinkName="toMandateId"
      isTogglingListDisabled={(list, isChecked) =>
        mandates.length > 1 && isChecked
      }
    />
  )
}

AddMandateToListModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  mandates: PropTypes.arrayOf(
    PropTypes.shape({
      mandateId: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
}
