import { useState } from 'react'
import { Button } from '@context365/button'
import { Launch } from '@context365/icons'
import { format } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import * as api from '~/api'
import Loading from '~/components/Loading'
import ClassActionContactModal from '../../marketplace/ClassActionContactModal'
import WidgetBlock from './WidgetBlock'

const ClassActions = () => {
  const { data = {}, isLoading: areClassActionsLoading } = useQuery(
    ['dashboardClassAction'],
    () => api.classActions.getClassActionCases(1, 5, '-classActionId')
  )
  const classActionCases = data.results

  const [isContactModalVisible, setIsContactModalVisible] = useState(false)
  const [selectedCaseId, setSelectedCaseId] = useState()

  return (
    <WidgetBlock
      headerLeft={
        <>
          <div className="type-body-semibold-md">
            Recent Class Action Data (by Battea)
          </div>
          <div className="type-body-regular-xs">
            Contact for more information
          </div>
        </>
      }
      headerRight={
        <Button
          variant="link"
          as={Link}
          to="/marketplace/classActions"
          iconRight={<Launch />}
          style={{ padding: 0 }}
          status="secondary"
        >
          See All
        </Button>
      }
    >
      {isEmpty(classActionCases) ? (
        <div>No recent class action suits.</div>
      ) : (
        <Loading spinning={areClassActionsLoading}>
          <div className="divide-y -my-3">
            {map(classActionCases, (classActionCase, index) => (
              <div key={index} className="flex justify-between py-3">
                <span className="w-1/3">
                  <div className="type-subtitle-xs mb-1">
                    {classActionCase.name}
                  </div>
                  <div className="type-body-regular-xs">
                    {classActionCase.industry}
                  </div>
                </span>
                <span>
                  <div className="type-body-semibold-xs">
                    Class Period Start
                  </div>
                  <div className="type-body-regular-xs">
                    {format(
                      new Date(classActionCase.classPeriodStart),
                      'MMM do, y'
                    )}
                  </div>
                </span>
                <span>
                  <div className="type-body-semibold-xs">Class Period End</div>
                  <div className="type-body-regular-xs">
                    {format(
                      new Date(classActionCase.classPeriodEnd),
                      'MMM do, y'
                    )}
                  </div>
                </span>
                <Button
                  variant="link"
                  onClick={() => {
                    setSelectedCaseId(classActionCase.classActionId)
                    setIsContactModalVisible(true)
                  }}
                >
                  Contact
                </Button>
              </div>
            ))}
          </div>

          <ClassActionContactModal
            classActionCase={classActionCases.find(
              (classActionCase) =>
                classActionCase.classActionId === selectedCaseId
            )}
            visible={isContactModalVisible}
            onClose={() => setIsContactModalVisible(false)}
          />
        </Loading>
      )}
    </WidgetBlock>
  )
}

export default ClassActions
