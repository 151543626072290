import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import isNil from 'lodash/isNil'
import * as api from '~/api'
import { api as http } from '~/api/services'
import { FUND_CONFIRMATION } from '~/constants/disclaimer'
import { ILLIQUID_FUND_TYPE, LIQUID_FUND_TYPE } from '~/constants/funds'
import FundTypeStep from './FundTypeStep'
import { IlliquidFundWizard, LiquidFundWizard } from './New'
import './FundWizard.less'

const { confirm } = Modal

const FundWizardModal = ({
  onWizardClosed = () => {},
  onSave = () => {},
  fundId = null,
  defaultStepNumber = 0,
  visible = false,
}) => {
  const [newFundTypeId, setNewFundTypeId] = useState(null)
  const [loading, setLoading] = useState(true)
  const [needsConfirmation, setNeedsConfirmation] = useState(false)

  useEffect(() => {
    if (isNil(fundId)) {
      setLoading(false)
    } else {
      api.funds
        .getFundType(fundId)
        .then((res) => {
          setNewFundTypeId(res.data.result.id)
        })
        .finally(() => setLoading(false))
    }
  }, [fundId])

  const markAsConfirmed = () => setNeedsConfirmation(false)

  const markAsEdited = () => {
    if (!needsConfirmation) {
      setNeedsConfirmation(true)
    }
  }

  const showConfirmDisclaimer = useCallback(
    (afterConfirm) => {
      confirm({
        title: FUND_CONFIRMATION,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          setNeedsConfirmation(false)
          afterConfirm()
        },
        onCancel() {
          http
            .patch(`/funds/invisible/${fundId}`, {
              isInvisible: true,
            })
            .finally(() => afterConfirm())
        },
      })
    },
    [fundId]
  )

  return loading ? null : (
    <Modal
      visible={visible}
      onCancel={() => {
        if (fundId && needsConfirmation) {
          showConfirmDisclaimer(onWizardClosed)
        } else {
          onWizardClosed()
        }
      }}
      title={isNil(fundId) ? 'Create New Fund' : 'Editing Fund'}
      destroyOnClose
      footer={null}
      className="FundWizardModal"
      width="100%"
    >
      {isNil(newFundTypeId) ? (
        <FundTypeStep onSave={setNewFundTypeId} />
      ) : newFundTypeId === ILLIQUID_FUND_TYPE ? (
        <IlliquidFundWizard
          fundId={fundId}
          onSave={onSave}
          defaultStepNumber={defaultStepNumber}
          markAsEdited={markAsEdited}
          markAsConfirmed={markAsConfirmed}
          onSwitchFundType={() => setNewFundTypeId(null)}
        />
      ) : newFundTypeId === LIQUID_FUND_TYPE ? (
        <LiquidFundWizard
          fundId={fundId}
          onSave={onSave}
          defaultStepNumber={defaultStepNumber}
          markAsEdited={markAsEdited}
          markAsConfirmed={markAsConfirmed}
          onSwitchFundType={() => setNewFundTypeId(null)}
        />
      ) : null}
    </Modal>
  )
}

FundWizardModal.propTypes = {
  onWizardClosed: PropTypes.func,
  onSave: PropTypes.func,
  fundId: PropTypes.number,
  defaultStepNumber: PropTypes.number,
  visible: PropTypes.bool,
}

export default FundWizardModal
